import {Region} from "./region.model";

export class FetchRegions {
  static readonly type = 'FETCH_REGIONS';
  readonly type = FetchRegions.type;
}

export class LoadRegions {
  static readonly type = 'LOAD_REGIONS';
  readonly type = LoadRegions.type;

  constructor(public regions: Region[]) {}
}


export type All = FetchRegions | LoadRegions
