import { handleError } from 'app/shared/utils/errors';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from "@ngrx/store";
import { AppState } from 'app/reducers';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { Tab, Demographic, DemographicConfig } from './insights.models';
import * as actions from './insights.actions';
import { fetchResource } from 'app/shared/utils/fetch-state';
import { INSIGHTS_CONTEXTS, InsightsContextType, ResourceType } from 'app/insights/insights.constants';
import { objToHttpParams } from 'app/shared/utils/http-utils';
import { bulkFetchResource } from 'app/shared/utils/bulk-fetch-state';
import { MarketLevelDemographic } from 'app/insights/insights-components/market-level-demographics/market-level-demographic.interface';
import { MarketLevelSurvey } from "app/insights/insights-components/market-level-survey/market-level-survey.interface";
import { fullContext } from "app/hierarchy/hierarchy.reducers";
import { defaultIndexBase } from 'app/insights/insights.reducer';
import { flatMap } from "lodash";
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  marketLevelDemographicUrl,
  marketLevelDemographicByResourceUrl,
  shareThisUrl,
  surveysUrl,
  tabAdminUrl,
  topCountsDataUrl,
  userPreferencesCustomTabUrl,
  tabsUrl,
  demographicsUrl,
  userPreferencesUrl,
  tabConfigsUrl,
  demographicsIndexUrl
} from 'app/shared/constants/insights.urls';

@Injectable()
export class InsightsService {

  @Effect()
  fetchTabs$ = this.actions$.pipe(
    ofType(actions.FetchTabs.type),
    fetchResource(
      action => this.fetchTabs(action.resourceId, action.resourceType, action.insightsContext).pipe(map(tabs => new actions.LoadTabs(tabs, action.insightsContext)))
    )
  )

  @Effect()
  fetchMarketLevelDemographics$ = this.actions$.pipe(
    ofType(actions.FetchMarketLevelDemographics.type),
    (
      fetchResource(
        action => this.getMarketLevelDemographics(action.resourceId, action.resourceType).pipe(map(marketLevelDemographics => new actions.LoadMarketLevelDemographics(action.insightsContext, marketLevelDemographics)))
      )
    ))

  @Effect()
  fetchDemographics$ = this.actions$.pipe(
    ofType(actions.FetchDemographics.type),
    (
      fetchResource(
        action => this.getDemographics(action.insightsContext, action.resourceId, action.resourceType).pipe(map(demographics => new actions.LoadDemographics(demographics, action.insightsContext)))
      )
    ));

  @Effect()
  saveMarketLevelDemographic$ = this.actions$.pipe(
    ofType(actions.SaveMarketLevelDemographic.type),
    (
      fetchResource(
        action => this.saveMarketLevelDemographic(action.marketLevelDemographic).pipe(map(marketLevelDemographic => new actions.LoadMarketLevelDemographic(action.insightsContext, marketLevelDemographic)))
      )
    ))

  @Effect()
  destroyMarketLevelDemographic$ = this.actions$.pipe(
    ofType(actions.DestroyMarketLevelDemographic.type),
    (
      fetchResource(
        action => this.destroyMarketLevelDemographic(action.marketLevelDemographicId)
      )
    ))

  @Effect()
  saveTabConfig$ = this.actions$.pipe(
    ofType(actions.SaveTabConfig.type),
    fetchResource(
      action => this.saveTabConfig(action.tabConfig, action.resourceId, action.resourceType, action.insightsContext).pipe(map(tabs => new actions.LoadTabs(tabs, action.insightsContext)))
    )
  )

  @Effect()
  destroyDemographic$ = this.actions$.pipe(
    ofType(actions.DestroyDemographic.type),
    (
      fetchResource(
        ({ demographic }) => this.destroyDemographic(demographic)
      )
    ));

  @Effect()
  upsertDemographic$ = this.actions$.pipe(
    ofType(actions.UpsertDemographic.type),
    (
      fetchResource(
        action => this.upsertDemographic(action.demographic)
          .pipe(
            map(demographic => new actions.LoadDemographic(demographic, action.insightsContext)),
            catchError(errorResponse => {
              const body = errorResponse.error;
              const errorMessage = body?.error ? `- ${body.error}` : '';
              this.snackbar.open(`Demographic widget failed to save ${errorMessage}`, "OK", {panelClass: ['danger'], duration: 6000})
              return handleError(errorMessage);
            }), )
      )
    ));

  @Effect()
  saveCustomTab$ = this.actions$.pipe(
    ofType(actions.SaveCustomTab.type),
    (
      fetchResource(
        action => this.saveCustomTab(action.customTab).pipe(map(tabs => new actions.LoadTabs(tabs, action.insightsContext)))
      )
    ));

  @Effect()
  destroyCustomTab$ = this.actions$.pipe(
    ofType(actions.DestroyCustomTab.type),
    fetchResource(
      action => this.destroyCustomTab(action.customTabId)
    )
  )

  @Effect()
  loadCustomTabConfigs$ = this.actions$.pipe(
    ofType(actions.FetchCustomTabsConfig.type),
    (
      fetchResource(
        action => this.getCustomTabsConfig(action.resourceId, action.resourceType).pipe(map(config => new actions.LoadCustomTabConfig(config, action.insightsContext)))
      )
    ));

  @Effect()
  fetchDemographicsConfig$ = this.actions$.pipe(
    ofType(actions.FetchDemographicsConfig.type),
    (
      bulkFetchResource(
        action => this.fetchDemographicsConfig(action.configType, action.resourceId, action.resourceType).pipe(map(config => new actions.LoadDemographicsConfig(config, action.configType, action.insightsContext)))
      )
    ))

  @Effect()
  saveDemographicsConfig$ = this.actions$.pipe(
    ofType(actions.SaveDemographicsConfig.type),
    bulkFetchResource(
      action => this.saveDemographicsConfig(action.config, action.configType, action.resourceId, action.resourceType).pipe(map(config => new actions.LoadDemographicsConfig(config, action.configType, action.insightsContext)))
    )
  )

  @Effect()
  fetchShareThisData$ = this.actions$.pipe(
    ofType(actions.FetchShareThisData.type),
    fetchResource(
      action => this.fetchShareThisData(action.shareThisPeriod, action.verticalName).pipe(map(shareThisData => new actions.LoadShareThisData(shareThisData, action.insightsContext)))
    )
  )

  @Effect()
  fetchTopDomainData$ = this.actions$.pipe(
    ofType(actions.FetchShareThisTopDomains.type),
    fetchResource(
      action => this.fetchShareThisTopDomainData(action.shareThisPeriod, action.verticalKey).pipe(map(topDomainsData => new actions.LoadShareThisDataForDomains(topDomainsData, action.insightsContext)))
    )
  )

  @Effect()
  fetchVerticalOverlapData$ = this.actions$.pipe(
    ofType(actions.FetchShareThisVerticalOverlap.type),
    fetchResource(
      action => this.fetchShareThisVerticalOverlapData(action.shareThisPeriod, action.verticalKey).pipe(map(verticalOverlapData => new actions.LoadShareThisVerticalOverlapData(verticalOverlapData, action.insightsContext)))
    )
  )

  @Effect()
  fetchMarketLevelSurveys$ = this.actions$.pipe(
    ofType(actions.FetchMarketLevelSurveys.type),
    fetchResource(
      action => this.fetchMarketLevelSurveys(action.resourceId, action.resourceType).pipe(map(surveys => new actions.LoadMarketLevelSurveys(surveys, action.insightsContext)))
    )
  )

  @Effect()
  resetIndexMode$ = fullContext(this.store).pipe(
    mergeMap(context => {
      const actionsToDispatch = flatMap(INSIGHTS_CONTEXTS, insightsContext => {
        return [
          new actions.SetIndexBase(defaultIndexBase(), insightsContext),
          new actions.ResetIndexMode(insightsContext)
        ];
      });
      return actionsToDispatch;
    })
  )

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private http: HttpClient,
    private snackbar: MatSnackBar) { }

  fetchTabs(resource_id: number, resource_type: ResourceType, insights_context: string): Observable<Tab[]> {
    const params = objToHttpParams({
      insights_context,
      resource_id,
      resource_type
    })
    return this.http.get(tabsUrl(), {params}) as Observable<Tab[]>;
  }

  getDemographics(insightsContext: InsightsContextType, resourceId: number, resourceType: ResourceType): Observable<Demographic[]> {
    return this.http.get(demographicsIndexUrl(insightsContext, resourceId, resourceType)) as Observable<Demographic[]>;
  }

  saveTabConfig(tabConfig: Tab[], resourceId: number, resourceType: ResourceType, insightsContext: InsightsContextType): Observable<Tab[]> {
    return this.http.put(tabConfigsUrl(resourceId, resourceType) + `&insights_context=${insightsContext}`, {config: tabConfig}) as Observable<Tab[]>;
  }

  fetchDemographicsConfig(configType: string, resourceId: number, resourceType: ResourceType): Observable<DemographicConfig[]> {
    return this.http.get(userPreferencesUrl(configType, resourceId, resourceType)) as Observable<DemographicConfig[]>;
  }

  saveDemographicsConfig(config: DemographicConfig[], configType: string, resourceId: number, resourceType: ResourceType): Observable<DemographicConfig[]> {
    return this.http.put(userPreferencesUrl(configType, resourceId, resourceType), {config}) as Observable<DemographicConfig[]>
  }

  upsertDemographic(demo: Demographic): Observable<Demographic> {
    return this.http[demo.id ? 'put' : 'post'](
      demographicsUrl(demo.resource_id, demo.resource_type, demo.id), {demographic: this.transformDemoNestedAttributes(demo)}
    ) as Observable<Demographic>
  }

  destroyDemographic(demo: Demographic): Observable<boolean> {
    return this.http.delete(demographicsUrl(demo.resource_id, demo.resource_type, demo.id)) as Observable<boolean>
  }

  saveCustomTab(customTab: Tab): Observable<Tab[]> {
    return this.http[customTab.id ? 'put' : 'post'](
      tabsUrl(customTab.id), {tab: customTab}
    ) as Observable<Tab[]>
  }

  destroyCustomTab(customTabId: number): Observable<Tab[]> {
    return this.http.delete(tabsUrl(customTabId)) as Observable<Tab[]>;
  }

  // TODO return type
  copyCustomTabs(tab_ids: number[], resources: {type: string; id: number}[]): Observable<{message: string}> {
    return this.http.post<{message: string}>(tabsUrl() + '/copy', {tab_ids, resources})
  }

  getCustomTabsConfig(resourceId: number, resourceType: ResourceType): Observable<any> {
    return this.http.get(userPreferencesCustomTabUrl(resourceId, resourceType)) as Observable<any>;
  }

  getMarketLevelDemographics(resourceId: number, resourceType: string): Observable<MarketLevelDemographic[]> {
    return this.http.get(marketLevelDemographicByResourceUrl(resourceId, resourceType)) as Observable<MarketLevelDemographic[]>;
  }

  saveMarketLevelDemographic(marketLevelDemographic: MarketLevelDemographic): Observable<MarketLevelDemographic> {
    return this.http[marketLevelDemographic.id ? "put" : "post"](marketLevelDemographicUrl(marketLevelDemographic.id), marketLevelDemographic) as Observable<MarketLevelDemographic>;
  }

  destroyMarketLevelDemographic(marketLevelDemographicId: number) {
    return this.http.delete(marketLevelDemographicUrl(marketLevelDemographicId))
  }

  fetchShareThisData(period: 1|4, verticalName: string): Observable<any> {
    return this.http.get(shareThisUrl(period, verticalName))
  }

  fetchShareThisTopDomainData(period: 1|4, verticalKey: string): Observable<any> {
    return this.http.get(topCountsDataUrl(period, 'Domain', verticalKey))
  }

  fetchShareThisVerticalOverlapData(period: 1|4, verticalKey: string): Observable<any> {
    return this.http.get(topCountsDataUrl(period, 'Vertical Overlap', verticalKey))
  }

  transformDemoNestedAttributes(demo: Demographic) {
    return {
      ...demo,
      demographic_buckets_attributes: demo.buckets
    }
  }

  fetchMarketLevelSurveys(resourceId: number, resourceType: string): Observable<MarketLevelSurvey[]> {
    return this.http.get(surveysUrl(resourceId, resourceType)) as Observable<MarketLevelSurvey[]>;
  }
  fetchInsightsAdminTabs(): Observable<Tab[]> {
    return this.http.get(tabAdminUrl()) as Observable<Tab[]>;
  }
}

export const hasSubFeatureAccess = (featureModules, region, featureName) => {
  const booleanArr = featureModules.map(feature => feature.name == featureName && (region.feature_exclusions || []).includes(feature.id + ''));
  return !booleanArr.includes(true);
}

export interface BarChartData {
  bucket: string;
  percent: number;
  selected?: boolean;
  id: number;
  order: number;
}

export const DEFAULT = 'default'
export const ASC = 'asc'
export const DESC = 'desc'
