import { Component, OnDestroy } from '@angular/core';
import { select, Store } from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { UploadAdminService } from "app/admin/upload-admin/upload-admin.service";
import { EditDiscussionClusterNode } from 'app/insights/insights-components/market-level-discussions/discussion.actions';
import { DiscussionClusterNode, newDiscussionClusterNode } from 'app/insights/insights-components/market-level-discussions/discussion-cluster-nodes.interface';
import { AppState } from 'app/reducers';
import { values } from 'lodash';
import { FetchCurrentUser } from 'app/users/user.actions';

@UntilDestroy()
@Component({
  selector: 'ppc-discussions-upload-admin',
  templateUrl: './discussions-upload-admin.component.html',
  styleUrls: ['./discussions-upload-admin.component.sass']
})

export class DiscussionsUploadAdminComponent implements OnDestroy {
  clusterNodes$ = this.store.select("discussions", "clusterNodes").pipe(map(values));
  clusterUnderEdit: DiscussionClusterNode;

  constructor(
    public uploadAdminService: UploadAdminService,
    public store: Store<AppState>
  ) {
    this.store.select("discussions", "discussionClusterNodeUnderEdit").pipe(untilDestroyed(this))
      .subscribe(clusterUnderEdit => this.clusterUnderEdit = clusterUnderEdit);
    this.store.dispatch(new FetchCurrentUser({ping: true}));
  }

  ngOnDestroy() {}

  openEditPane() {
    this.store.dispatch(new EditDiscussionClusterNode(this.clusterUnderEdit || newDiscussionClusterNode()));
  }
}
