import { Injectable, Inject, OnDestroy } from '@angular/core';
import { InsightsCountService } from '../../insights-count.service';
import { BehaviorSubject, combineLatest as observableCombineLatest, Observable, ReplaySubject } from 'rxjs';
import { Demographic } from "app/insights/insights.models";
import { Store, select } from '@ngrx/store';
import { AppState } from "app/reducers";
import { INSIGHTS_CONTEXT, InsightsContextType, PercentCalculationType, MoleculaSegmentContext } from 'app/insights/insights.constants';
import { pluck, map, filter, switchMap, tap, debounceTime } from 'rxjs/operators';
import { isDefined } from '../../../shared/utils/utils';
import { isEmpty, reduce, map as _map, forEach, get, groupBy, sortBy, sumBy, find, tail } from 'lodash';
import { SegmentContext } from '../../insights.constants';
import { buildQueryFromContext, buildIndexingVendorTypesQuery, getVendorTypeAggregationKey, buildMoleculaContext, buildMoleculaIndexContext, insightsContextIsExplore, isMolecula } from '../../insights.utils';
import { compareTargets, selectAllDemographics } from '../../insights.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { INDEX_VALUES } from '../../../shared/utils/constants';

@UntilDestroy()
@Injectable()
export class PersonLevelCompareService implements OnDestroy {
  activeDemographicIds$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  activeDemographicId$: BehaviorSubject<number> = new BehaviorSubject(null);
  demographic$: Observable<Demographic> = observableCombineLatest(
    this.activeDemographicId$,
    this.store.pipe(select(selectAllDemographics(this.insightsContext))),
    this.store.select("insights", this.insightsContext, "tabs").pipe(
      map(tabs => get(find(tabs, {tab_key: "top_level_person"}), "children", []))
    )
  ).pipe(
    map(([activeDemographicId, allDemographics, personLevelTabs]) => {
      const demographic = find(allDemographics, {id: activeDemographicId});
      return {
        ...demographic,
        tab: find(personLevelTabs, {id: get(demographic, "custom_tab_id")}) || find(personLevelTabs, {tab_key: "person_level_demographics"})
      }
    }),
  )
  demographic: Demographic;
  countsChanged$ = new ReplaySubject(1);
  countData: {[identifier: string]: {[identifier: string]: number}};
  compareIdentifiers$: Observable<string[]>;
  compareIdentifiers: string[];
  loading$ = new BehaviorSubject(false);
  canCompare$ = this.store.pipe(select(compareTargets(this.insightsContext)), map(targets => targets.length > 0 && targets.length < 4))
  canCompare: boolean;
  percentCalculationType: PercentCalculationType;

  constructor(private counts: InsightsCountService, private store: Store<AppState>, @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {
    observableCombineLatest(
      this.store.select("insights", this.insightsContext, "segmentContexts").pipe(
        filter(isDefined),
        pluck("primary"),
        filter(isDefined),
      ),
      this.demographic$.pipe(filter(isDefined)),
      this.store.select("insights", this.insightsContext, "filters"),
      this.store.select("insights", this.insightsContext, "indexMode"),
      this.counts.fetchingSegments$,
      this.store.select("insights", this.insightsContext, "segmentContexts"),
      this.canCompare$,
      this.store.select("insights", this.insightsContext, "indexBase")
    ).pipe(
      filter(([primaryContext, demographic, filters, indexMode, fetchingSegments, segmentContexts, canCompare, indexBase]) => !fetchingSegments && canCompare),
      debounceTime(100),
      untilDestroyed(this),
      map(([primaryContext, demographic, filters, indexMode, fetchingSegments, segmentContexts, canCompare, indexBase]) => {
        let splitSegmentContexts;
        const filterIdentifiers =  _map(groupBy(sortBy(filters, "shortId"), "demographicId"), group => _map(group, "shortId"));
        const isExplore = insightsContextIsExplore(this.insightsContext);
        if (isExplore) {
          if (isMolecula()) {
            splitSegmentContexts = [primaryContext, ..._map(primaryContext["compareContext"], context => context.primary)];
          } else {
            const orGroups = [primaryContext];
            const additionalCompareContexts = primaryContext.compareContext;
            if (!isEmpty(additionalCompareContexts)) {forEach(additionalCompareContexts, context => orGroups.push(context.primary)); }
            splitSegmentContexts = orGroups;
          }
        } else {
          if (isMolecula()) {
            if (this.insightsContext === 'journey') {
              splitSegmentContexts = tail(primaryContext.contexts).map(
                (context: MoleculaSegmentContext) => ({
                  rootKey: context.context.included[0][0],
                  contexts: [
                    {
                      ...context,
                      allowIdentifiers: true,
                      context: { ...context.context, key: "total_count" },
                    },
                  ],
                })
              );
            } else {
              splitSegmentContexts = primaryContext.context.included.flatMap(contextGroup => (contextGroup.map(i => ([i]))));
            }
          } else {
            splitSegmentContexts = (primaryContext.or || primaryContext.and).map(contextGroup => ({or: [contextGroup], isModeled: primaryContext.isModeled} )) as SegmentContext[]
          }
        }
        let splitQueries;
        if (isMolecula()) {
          splitQueries = reduce(splitSegmentContexts, (queries, segmentContext) => {
            if (this.insightsContext === 'journey') {
              const compareIdentifier = segmentContext.rootKey;
              return Object.assign(queries, {[compareIdentifier]: buildMoleculaContext(segmentContext, _map(demographic.buckets, "short_id"), filterIdentifiers, [], indexMode, this.counts.segments, this.counts.modelingUniverseProviders, this.insightsContext, compareIdentifier)})
            } else {
              const compareIdentifier = isExplore ? `persona-compare-${segmentContext.personaId}` : segmentContext[0];
              const context = isExplore ? segmentContext : { context: { included: [segmentContext] } };
              return Object.assign(queries, {[compareIdentifier]: buildMoleculaContext(context, _map(demographic.buckets, "short_id"), filterIdentifiers, [], indexMode, this.counts.segments, this.counts.modelingUniverseProviders, this.insightsContext, compareIdentifier)})
            }
          }, {})
        } else {
          splitQueries =  reduce(splitSegmentContexts, (queries, segmentContext) => {
            const compareIdentifier = isExplore ? `persona-compare-${segmentContext.personaId}` : segmentContext.or[0].include[0][0];
            return Object.assign(queries, {[compareIdentifier]: buildQueryFromContext(segmentContext, _map(demographic.buckets, "short_id"), filterIdentifiers, [], {}, indexMode, this.counts.segments, this.counts.modelingUniverseProviders)})
          }, {})
        }
        if (indexMode) {
          forEach(splitSegmentContexts, segmentContext => {
            const demographicIdentifiers = _map(demographic.buckets, "short_id");
            if (isMolecula()) {
              const compareIdentifier = isExplore ? `persona-compare-${segmentContext.personaId}` : segmentContext[0];
              const context = isExplore ? segmentContext : { context: { included: [segmentContext] } };
              const query = buildMoleculaContext(context, demographicIdentifiers, filterIdentifiers, [], indexMode, this.counts.segments, this.counts.modelingUniverseProviders, this.insightsContext, compareIdentifier);
              splitQueries[`${INDEX_VALUES}:${compareIdentifier}`] = buildMoleculaIndexContext(query, this.insightsContext, indexBase);
            } else {
              const compareIdentifier = isExplore ? `persona-compare-${segmentContext.personaId}` : segmentContext.or[0].include[0][0];
              const key = `${compareIdentifier}-context-vendor-types`;
              splitQueries[key] = buildIndexingVendorTypesQuery(segmentContext, demographicIdentifiers, this.counts.segments, filterIdentifiers, this.counts.modelingUniverseProviders, indexBase)
            }
          })
        }
        return splitQueries
      }),
      tap(() => {
        this.loading$.next(true)
      }),
      switchMap(splitQueries => this.counts.fetchInsights(splitQueries))
    ).subscribe(data => {
      this.countData = data;
      this.loading$.next(false);
      this.countsChanged$.next(data);
    })

    this.store.pipe(select(compareTargets(this.insightsContext))).pipe(untilDestroyed(this)).subscribe(compareTargets => this.compareIdentifiers = _map(compareTargets, "identifier"))
    this.canCompare$.pipe(untilDestroyed(this)).subscribe(canCompare => this.canCompare = canCompare)
    this.demographic$.pipe(untilDestroyed(this)).subscribe(demographic => this.demographic = demographic);

    this.store.select("insights", this.insightsContext, "percentCalculationType").pipe(
      untilDestroyed(this)
    ).subscribe(percentCalculationType => this.percentCalculationType = percentCalculationType);
  }

  ngOnDestroy() { }

  getCount(compareIdentifier: string, bucketIdentifier: string): number {
    if (!this.canCompare) {return 0; }
    return get(this.countData, [compareIdentifier, bucketIdentifier], 0)
  }

  getTotalForCompareIdentifier(compareIdentifier: string): number {
    return sumBy(this.demographic.buckets, bucket => this.getCount(compareIdentifier, bucket.short_id))
  }

  getPercentage(compareIdentifier: string, bucketIdentifier: string): number {
    if (!this.canCompare) {return 0; }
    const totalCount = this.percentCalculationType === PercentCalculationType.INSIGHTS_CONTEXT
      ? this.totalCount
      : this.getTotalForCompareIdentifier(compareIdentifier);
    return this.getCount(compareIdentifier, bucketIdentifier) / (totalCount || 1) * 100;
  }

  getIndex(compareIdentifier: string, bucketIdentifier: string): number {
    const segment = get(this.counts.segments, bucketIdentifier);
    if (!segment) {return null; }
    if (isMolecula()) {
      // Molecula: API service calculates and returns index value
      const segmentIndex = get(this.countData, [`${INDEX_VALUES}:${compareIdentifier}`, bucketIdentifier]);
      return segmentIndex;
    }
    const vendorType = getVendorTypeAggregationKey(segment);
    const segmentCountInContext = get(this.countData, [compareIdentifier, bucketIdentifier]);
    const vendorCountInContext = get(this.countData, [compareIdentifier, vendorType])
    if (segmentCountInContext == null) {return null; }
    const segmentCountAgainstContextTypes = get(this.countData, [`${compareIdentifier}-context-vendor-types`, bucketIdentifier]);
    const segmentTypeAgainstContextTypes = get(this.countData, [`${compareIdentifier}-context-vendor-types`, vendorType]);
    return ((segmentCountInContext / vendorCountInContext) / (segmentCountAgainstContextTypes / segmentTypeAgainstContextTypes)) * 100;
  }

  hasIndex(compareIdentifier: string, bucketIdentifier: string): boolean {
    const index = this.getIndex(compareIdentifier, bucketIdentifier);
    return index != null && !Number.isNaN(index);
  }

  get totalCount() {
    if (!this.canCompare) {return 0; }
    return sumBy(this.compareIdentifiers, identifier => get(this.countData, [identifier, "total_count"]));
  }
}
