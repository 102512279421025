import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { MenuItem } from '../menu-item.interface';

@Component({
  selector: 'app-admin-action-menu-item',
  templateUrl: './admin-action-menu-item.component.html',
  styleUrls: ['./admin-action-menu-item.component.sass']
})
export class AdminActionMenuItemComponent implements OnInit {

  @Input() menuItem: MenuItem;

  constructor(private router: Router) {}

  ngOnInit() { }

  navigateToLink() {
    this.router.navigate(this.menuItem.link);
  }
}
