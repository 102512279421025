import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'ppc-icon',
  templateUrl: './ppc-icon.component.html',
  styleUrls: ['./ppc-icon.component.sass']
})
export class PpcIconComponent implements OnInit {
  @Input() value: string;

  constructor() {}

  ngOnInit() {}

  get isSvg(): boolean {
    return this.value && this.value.includes('.svg');
  }
}
