import { Observable } from "rxjs";
import { map, filter } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { HttpClient } from '@angular/common/http';
import * as actions from 'app/insights/insights-components/market-level-discussions/discussion.actions';
import { fetchResource } from "app/shared/utils/fetch-state";
import { Store } from "@ngrx/store";
import { DiscussionClusterNode } from 'app/insights/insights-components/market-level-discussions/discussion-cluster-nodes.interface';
import { AppState } from 'app/reducers';
import { clusterNodesUrl } from "app/shared/constants/insights.urls";

@Injectable()
export class DiscussionService {
  @Effect()
  fetchClusterNodesForSubMarketUnderEdit$ = this.store.select("mekkos", "subMarketUnderEdit").pipe(
    filter(Boolean),
    map(() => new actions.FetchClusterNodes())
  )

  @Effect()
  fetchClusterNodesForJourneySubMarketUnderEdit$ = this.store.select("journey", "journeys").pipe(
    filter(Boolean),
    map(() => new actions.FetchClusterNodes())
  )

  @Effect()
  fetchClusterNode$ = this.actions$.pipe(
    ofType(actions.FetchClusterNode.type),
    fetchResource(action => this.fetchClusterNode(action.clusterNodeId).pipe(map(clusterNode => new actions.LoadClusterNode(clusterNode))))
  )

  @Effect()
  fetchClusterNodes$ = this.actions$.pipe(
    ofType(actions.FetchClusterNodes.type),
    fetchResource(action => this.fetchClusterNodes().pipe(map(clusterNodes => new actions.LoadClusterNodes(clusterNodes))))
  )

  @Effect()
  saveDiscussionClusterNode$ = this.actions$.pipe(
    ofType(actions.SaveDiscussionClusterNode.type),
    fetchResource(action => this.saveClusterNode(action.clusterNode).pipe(map(clusterNode => new actions.LoadClusterNode(clusterNode))))
  );

  @Effect()
  destroyDiscussionClusterNode$ = this.actions$.pipe(
    ofType(actions.DestroyDiscussionClusterNode.type),
    fetchResource(action => this.destroyDiscussionClusterNode(action.clusterNodeId).pipe(map(clusterNode => new actions.RemoveDiscussionClusterNode(clusterNode))))
  )

  constructor(private http: HttpClient, private actions$: Actions, private store: Store<AppState>) { }

  fetchClusterNode(clusterNodeId: number): Observable<DiscussionClusterNode> {
    return this.http.get(clusterNodesUrl(clusterNodeId)) as Observable<DiscussionClusterNode>;
  }

  fetchClusterNodes(): Observable<DiscussionClusterNode[]> {
    return this.http.get(clusterNodesUrl()) as Observable<DiscussionClusterNode[]>;
  }

  saveClusterNode(clusterNode: DiscussionClusterNode): Observable<DiscussionClusterNode> {
    return this.http[clusterNode.id ? 'put' : 'post'](clusterNodesUrl(clusterNode.id), { discussion_cluster_node: clusterNode }) as Observable<DiscussionClusterNode>;
  }

  destroyDiscussionClusterNode(clusterNodeId: number): Observable<DiscussionClusterNode> {
    return this.http.delete(clusterNodesUrl(clusterNodeId)) as Observable<DiscussionClusterNode>;
  }
}
