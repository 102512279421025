import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EpcModalComponent } from 'app/shared/components/epc-modal/epc-modal.component';

/*
  Only add components that are shared between pages and don't contain any
  business logic. Also please keep alphabetized!
*/
@NgModule({
  declarations: [
    EpcModalComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EpcModalComponent,
  ]
})
export class EpcComponentsModule { }
