import {
  Component,
  OnInit,
  ContentChildren,
  ViewChildren,
  ElementRef,
  QueryList,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  OnChanges,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PpcTabComponent } from '../ppc-tab/ppc-tab.component';
import { PpcTabsService } from './ppc-tabs.service';
import { findIndex, find, first, forEach, map, reduce, sumBy, debounce } from 'lodash';
import { rectanglesOverlap } from 'app/shared/utils/utils';

@Component({
  selector: 'ppc-tabs',
  templateUrl: './ppc-tabs.component.html',
  styleUrls: ['./ppc-tabs.component.sass']
})
export class PpcTabsComponent implements AfterViewInit, OnDestroy, OnChanges, OnInit {
  @Output() tabChange = new EventEmitter<string>();
  @ContentChildren(PpcTabComponent) _tabs: QueryList<PpcTabComponent>;
  @ViewChildren("header") headers: any;
  @ViewChild("tabGroupHeader", { static: true }) tabGroupHeader: ElementRef;
  @ViewChild("leftPaginator") leftPaginator: ElementRef;
  @ViewChild("rightPaginator") rightPaginator: ElementRef;
  @Input() tabSpacing: number = 0;
  @Input() activeTab: string;
  @Input() fixedRightHeaderTemplate: TemplateRef<any>;
  underlineWidth = 0;
  ngUnsubscribe = new Subject();
  isDestroyed = false;
  focusedTab = 0;

  constructor(private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private tabsService: PpcTabsService) { }

  ngOnInit() {
    this.tabsService.renderEvents$.subscribe(() => {
      this.tabsChanged();
      this.detectChanges();
    })
  }

  ngOnChanges(changes) {
    if (changes.activeTab && this.tabs.length) {
      this.tabsChanged();
      this.detectChanges();
    }
  }

  ngAfterViewInit() {
    if (this.activeTab) {
      this.setActive(this.activeTab);
    } else {
      this.setActive(this.tabs[0].name);
    }

    // when tabs change and focus is beyond x-overflow, adjust focus
    this._tabs.changes.pipe(delay(0)).subscribe(queryList => {
      // no need for any adjustments when there are is no overflow
      if (!this.hasOverflow) { return; }

      const tabIndex = findIndex(queryList.toArray(), { name: this.activeTab });

      if (this.focusedTab > tabIndex) {
        while (this.canPaginateLeft && !this.activeTabInView) { this.focusedTab--; }
      } else {
        while (!this.activeTabInView && this.focusedTab < queryList.length) { this.focusedTab++; }
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.isDestroyed = true;
  }

  isTabActive(tab) {
    return this.activeTab == tab.name
  }

  detectChanges() {
    if (!this.headers || !this.activeTab) {
      this.underlineWidth = 100;
    };
    const mapped = map(this.headers.toArray(), (header: ElementRef) => {
      return {
        name: header.nativeElement.id,
        element: header
      }
    })

    const currentTab = find(mapped, { name: this.activeTab }) as PpcTabComponent;

    if (!currentTab) {
      // this is the result of tabs being added and removed. We just trust that
      // eventually the component will find the active tab
      return;
    }
    this.underlineWidth = currentTab.element.nativeElement.clientWidth;
    if (!this.isDestroyed) {this.changeDetector.detectChanges(); }
  }

  setActive(tab: string, clickEvent?: any) {
    if (!find(this.tabs, {name: tab})) {
      this.activeTab = this.tabs[0].name;
    } else {
      this.activeTab = tab;
    }
    this.tabsChanged();
    this.detectChanges();

    this.tabChange.emit(this.activeTab);
    if (clickEvent) {
      const {nativeElement} = find(this.headers.toArray(), ({nativeElement}) => nativeElement.id == this.activeTab);
      const clickedTabRect: DOMRect = nativeElement.getBoundingClientRect();
      const leftPaginatorRect = this.leftPaginator && this.leftPaginator.nativeElement.getBoundingClientRect();
      const rightPaginatorRect = this.rightPaginator && this.rightPaginator.nativeElement.getBoundingClientRect();

      if (leftPaginatorRect && rectanglesOverlap(clickedTabRect, leftPaginatorRect)) {
        this.focusedTab--;
      }
      if (rightPaginatorRect && rectanglesOverlap(clickedTabRect, rightPaginatorRect)) {
        this.focusedTab++;
      }
    }

  }

  tabsChanged() {
    // If a tab is very tall it will cause scrolling on the page even when its
    // not the active tab. This fixes that.
    forEach(this.tabs, tab => {
      if (tab.name !== this.activeTab) {
        tab.element.nativeElement.style.height = "0px";
        // We don't want a user to be able to press tab to focus an item on a hidden tab.
        // This fixes that
        tab.element.nativeElement.style.visibility = "hidden"
      } else {
        tab.element.nativeElement.style.height = null;
        tab.element.nativeElement.style.visibility = "visible"
      }
    });
  }

  get tabsTransform(): SafeStyle | void {
    const idx = findIndex(this.tabs, {name: this.activeTab});
    return this.sanitizer.bypassSecurityTrustStyle(`translateX(${(-100 / this.tabs.length) * idx}%)`);
  }

  get tabs(): PpcTabComponent[] {
    return (this._tabs && this._tabs.toArray()) || [];
  }

  get underlineDisplay(): SafeStyle {
    if (this.tabs.every(t => t.disabled)) {return "none"; }

    return "inherit";
  }

  get underlineTransform(): SafeStyle {
    if (!this.headers) {return this.sanitizer.bypassSecurityTrustStyle(`translateX(0px)`); }

    let haventHit = true
    const xOffset = reduce(this.headers.toArray(), (offset, header: ElementRef) => {
      if (header.nativeElement.id != this.activeTab && haventHit) {
        return offset + header.nativeElement.clientWidth + this.tabSpacing
      } else {
        haventHit = false
        return offset
      }
    }, 0)
    return this.sanitizer.bypassSecurityTrustStyle(`translateX(${xOffset}px)`)
  }

  get tabGroupHeaderTranslation(): number {
    if (!this.hasOverflow || !this.headers) {return 0; }
    const tabs: ElementRef[] = this.headers.toArray().slice(0, this.focusedTab);
    return sumBy(tabs, tab => tab.nativeElement.clientWidth)
  }

  get tabGroupHeaderTransform(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`translateX(-${this.tabGroupHeaderTranslation}px)`)
  }

  get hasOverflow(): boolean {
    if (!this.tabGroupHeader) {return false; }
    const {scrollWidth, clientWidth} = this.tabGroupHeader.nativeElement
    return scrollWidth > clientWidth;
  }

  get canPaginateRight(): boolean {
    if (!this.tabGroupHeader) {return false; }
    const {scrollWidth, clientWidth} = this.tabGroupHeader.nativeElement
    return clientWidth + this.tabGroupHeaderTranslation < scrollWidth
  }

  get canPaginateLeft(): boolean {
    if (!this.tabGroupHeader) {return false; }
    return this.tabGroupHeaderTranslation > 0
  }

  get activeTabInView(): boolean {
    if (!this.tabGroupHeader) { return false; }
    // if no active tab or no tabs displayed on ui, return default of true
    if (!this.headers || !this.activeTab) { return true; }

    const { clientWidth } = this.tabGroupHeader.nativeElement;
    const tabWidth = (first(this.headers.toArray()) as ElementRef).nativeElement.clientWidth;
    const focusedTabOffset = this.tabGroupHeaderTranslation;
    const activeTabIndex = findIndex(this.headers.toArray(), ({nativeElement}) => nativeElement.id === this.activeTab);
    const activeTabOffset = (tabWidth + this.tabSpacing) * activeTabIndex;
    const offset = activeTabOffset - focusedTabOffset;

    if (offset < 0) { return false; }
    return clientWidth > (offset + tabWidth);
  }

  paginate(direction: "right" | "left") {
    if (!this.canPaginateLeft && direction == "left") {return; }
    if (!this.canPaginateRight && direction == "right") {return; }
    this.focusedTab += direction == "right" ? 1 : -1;
  }

  onScroll = debounce(event => {
    if (event.deltaX > 0) {
      this.paginate("right");
    } else {
      this.paginate("left")
    }
  }, 100, {leading: true, trailing: false})

  projectedTabsChanged() {
    if (!find(this.tabs, {name: this.activeTab})) {
      this.focusedTab = 0;
      this.activeTab = this.tabs[0].name;
      this.tabChange.emit(this.activeTab);
    }
  }
}
