import { chain, keyBy, reject, values, filter as _filter } from 'lodash';
import { createSelector } from '@ngrx/store';

import { IndexReportTask, TaskType } from 'app/report-tasks/report-tasks.model';
import * as actions from 'app/index-report/index-report.actions';

export interface State {
  indexReports: {[id: number]: IndexReportTask};
  actionsInProgress: number[];
}

const defaultState: State = {
  indexReports: {},
  actionsInProgress: [],
}

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.LoadIndexReports.type:
      return { ...state, indexReports: keyBy(action.tasks, 'id') };
    case actions.LoadIndexReport.type:
      const updatedReports = {
        ...state.indexReports,
        [action.task.id]: action.task
      };
      return { ...state, indexReports: updatedReports };
    case actions.AddActionInProgress.type:
      state.actionsInProgress.push(action.taskId);
      return state;
    case actions.RemoveActionInProgress.type:
      const updatedInProgress = reject(state.actionsInProgress, a => a === action.taskId);
      return { ...state, actionsInProgress: updatedInProgress };
    default:
      return state;
  }
}

export const selectIndexReportTasks = createSelector(
  (state: State) => state.indexReports,
  (tasks) => _filter(values(tasks), ['report_type', TaskType.TASK_TYPE_INDEX_REPORT])
)
