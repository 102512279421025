import { Component, OnChanges, Input, ElementRef, ViewChild, HostBinding } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';


@Component({
  selector: 'ppc-measure-v3-outcome-change-overlay',
  templateUrl: './measure-v3-outcome-change-overlay.component.html',
  styleUrls: ['./measure-v3-outcome-change-overlay.component.sass']
})
export class MeasureV3OutcomeChangeOverlayComponent implements OnChanges {
  @ViewChild("changeLabel", { static: true }) changeLabel: ElementRef;
  @ViewChild("change", { static: true }) changeElement: ElementRef;
  @Input() visiblePercentChange: number;
  @Input() actualPercentChange: number;
  @HostBinding("class.no-pointer-events") get noPointerEvents() {
    return this.hasRoomForLabelInside || this.hasRoomForLabelOutside
  }

  elementHeight: number;
  changeHeight: number;
  elementWidth: number;

  constructor(private el: ElementRef,
    private sanitizer: DomSanitizer) { }

  ngOnChanges() {
    setTimeout(() => {
      this.elementHeight = this.el.nativeElement.clientHeight;
      this.changeHeight = this.changeElement.nativeElement.clientHeight;
      this.elementWidth = this.el.nativeElement.clientWidth;
    }, 405);
  }

  getTop(): string {
    if (!this.visiblePercentChange) {return "100%"; }
    return this.isPositive ? `${(100 - this.visiblePercentChange)}%` : `${this.absolutePercentChange}%`;
  }

  getLeft(): string {
    if (!this.visiblePercentChange) {return "100%"; }
    return this.isPositive ? `${(100 - this.visiblePercentChange)}%` : `${this.absolutePercentChange}%`;
  }

  get labelSpace(): number {
    return this.elementHeight - this.changeHeight;
  }

  get hasRoomForLabelInside(): boolean {
    return this.changeHeight > 25 && this.hasEnoughWidth;
  }

  get hasRoomForLabelOutside(): boolean {
    return this.labelSpace > 25 && this.hasEnoughWidth;
  }

  get hasEnoughWidth() {
    return this.elementWidth > 65;
  }

  get isPositive(): boolean {
    return this.visiblePercentChange > 0;
  }

  get isNegative(): boolean {
    return this.visiblePercentChange < 0;
  }

  get absolutePercentChange(): number {
    return Math.abs(this.visiblePercentChange);
  }

  get height(): SafeStyle {
    return `calc(${this.absolutePercentChange}% - 1px)`;
  }

  get clipPath(): SafeStyle {
    if (this.isPositive) {
      return this.sanitizer.bypassSecurityTrustStyle(`polygon(0 ${this.visiblePercentChange}%, 100% ${this.visiblePercentChange}%, 100% 100%, 0% 100%)`);
    } else {
      return this.sanitizer.bypassSecurityTrustStyle(`polygon(0 0, 100% 0%, 100% 100%, 0% 100%)`);
    }
  }

}
