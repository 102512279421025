import { take, takeUntil, map, filter, pluck, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store, select } from '@ngrx/store';
import { Actions } from "@ngrx/effects";
import { Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { compact, size, cloneDeep, map as _map, groupBy, find, get, first, flatten, isEqual, isEmpty, flatMap } from 'lodash';
import { GrowV3FocusType, selectActiveWeboramaNodes } from 'app/insights/grow-v3/grow.reducer';
import * as actions from 'app/insights/grow-v3/grow.actions';
import { GrowCountService } from 'app/insights/grow-v3/grow-count.service';
import { Subject } from "rxjs";
import * as Cookies from "js-cookie";
import { SubMarket, Mekko, selectActiveMekko, NewMekko, selectSelectedSubMarkets, selectedSubMarkets, selectMekkos, newMekko, selectActiveMekkoSubMarkets } from 'app/mekko/mekko.reducer';
import * as mekkoActions from 'app/mekko/mekko.actions';
import * as insightsActions from 'app/insights/insights.actions';
import { isCompareMode } from 'app/insights/insights.reducer';
import { AppState } from 'app/reducers';
import { Demographic } from '../insights.models';
import { INSIGHTS_CONTEXT, InsightsContextType, SegmentContexts, MoleculaSegmentContext, SegmentContext } from '../insights.constants';
import { InsightsCountService } from "app/insights/insights-count.service";
import { fetchOutcome, isFetchInFlight } from 'app/shared/utils/fetch-state';
import { buildMarketCountAggregations, buildSecondaryContexts, buildSubMarketCountAggregations, buildPrimaryContext, IS_COMPARE_MODE } from './grow.utils';
import { ActivatedRoute, Params } from '@angular/router';
import { Go } from 'app/router/router.actions';
import { buildUrlRelative, isDefined } from "app/shared/utils/utils";
import { AudienceV2 } from 'app/audiences-v2/audience-v2.model';
import { AudienceRule } from '../../audiences-v2/audience-v2.model';
import { AudienceMapper } from '../../audience-builder/audience-mappers/segments-api';
import { SetPrebuiltAudience } from '../../audience-builder/audience-builder.actions';
import { environment } from 'environments/environment';
import { PersonLevelCompareService } from '../insights-components/person-level-compare/person-level-compare.service';
import { JourneyCountService } from 'app/journey/journey-count.service';
import { SegmentV2Service } from '../../segments-v2/segment-v2.service';
import { OverlapInsightsExportService } from 'app/insights/insights-components/insights-export/overlap-insights-export.service';
import { isPermissioned } from 'app/services/data_permissions.service';
import { SegmentLike } from 'app/models/segment-like.model';
import { InsightsExportService } from 'app/insights/insights-components/insights-export/insights-export.service';
import { ACTION_NOT_PERMITTED, AUDIENCE_INCLUDES_NON_SEGMENTS, isMolecula, validForCustomAudience } from 'app/insights/insights.utils';

@Component({
  selector: 'ppc-grow-v3',
  templateUrl: './grow-v3.component.html',
  styleUrls: ['./grow-v3.component.sass'],
  providers: [
    {
      provide: INSIGHTS_CONTEXT,
      useValue: "grow"
    },
    InsightsCountService,
    GrowCountService,
    PersonLevelCompareService,
    JourneyCountService,
    OverlapInsightsExportService,
    InsightsExportService,
  ]
})
export class GrowV3Component implements OnInit, OnDestroy {
  focusedComponent$: Observable<GrowV3FocusType>;
  tooltipText = "";
  selectedSubMarkets: SubMarket[] = [];
  openTimer: NodeJS.Timer;
  mekkos: Mekko[];
  selectedMekkoId: number;
  ngUnsubscribe = new Subject();
  growTabState$ = this.store.select("grow", "growTabState");
  mekkoUnderEdit$: Observable<NewMekko>;
  weboramaDiscussionUnderEdit$ = this.store.select("grow", "weboramaDiscussionUnderEdit").pipe(map(cloneDeep))
  weboramaNodes$ = this.store.pipe(select(selectActiveWeboramaNodes))
  activeMekko: Mekko;
  isTier3 = environment.isTier3;
  regionDemographicUnderEdit$ = this.store.select("insights", "grow", "regionDemographicUnderEdit");
  permissions$ = this.store.select("permissions", "mekkos");
  loading$ = observableCombineLatest(
    this.counts.loadingCounts$,
    this.store.select("fetchStates", mekkoActions.SaveMekko.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", mekkoActions.CopyMekko.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", mekkoActions.DestroyMekko.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", mekkoActions.FetchMekkos.type).pipe(select(isFetchInFlight)),
    this.compareService.loading$
  ).pipe(map(loadingStates => loadingStates.some(Boolean)));
  marketLevelDemographicUnderEdit$ = this.store.select("insights", "grow", "marketLevelDemographicUnderEdit").pipe(map(cloneDeep));
  marketLevelOnly$ = this.store.select("insights", "grow", "tabs").pipe(
    map(tabs => {
      const tab = find(tabs, {tab_key: "top_level_person"});
      return !tab || !get(tab, "visible")
    })
  );

  segmentContexts$: Observable<SegmentContexts> = observableCombineLatest(
    this.store.select("mekkos").pipe(select(selectActiveMekkoSubMarkets)),
    this.store.select("mekkos").pipe(select(selectSelectedSubMarkets)),
    this.store.select("grow", "growTabState"),
    this.marketLevelOnly$,
  ).pipe(
    map(([subMarkets, selectedSubMarkets, growTabState, marketLevelOnly]) => {
      if (marketLevelOnly) {return {}; }
      const groupedSubMarkets = groupBy(subMarkets, "market_id");
      if (isMolecula()) {
        return {
          primary: buildPrimaryContext(selectedSubMarkets, growTabState, subMarkets) as MoleculaSegmentContext,
          secondary: buildSecondaryContexts(selectedSubMarkets, growTabState),
        };
      }
      return {
        primary: buildPrimaryContext(selectedSubMarkets, growTabState),
        aggregations: {
          ...buildMarketCountAggregations(groupedSubMarkets, growTabState),
          ...buildSubMarketCountAggregations(subMarkets, growTabState)
        },
        secondary: buildSecondaryContexts(selectedSubMarkets, growTabState)
      }
    }),
  )

  audience$: Observable<AudienceV2>;
  segments$: Observable<SegmentLike[]>;
  audienceIncludesNonSegments$: Observable<boolean>;
  hasCreateAudiencePermission$: Observable<boolean>;
  dataPermissions$ = this.store.select("dataPermissions");
  saveAudienceTooltip$: Observable<string>;
  isCompareMode$ = this.store.select("insights", this.insightsContext).pipe(select(isCompareMode));
  saveAudienceDisabled$: Observable<boolean>;

  constructor(public store: Store<AppState>,
    public counts: InsightsCountService,
    private growCounts: GrowCountService,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType,
    private audienceMapper: AudienceMapper,
    private segmentService: SegmentV2Service,
    private compareService: PersonLevelCompareService) {

    const activeIdentifiers$ = observableCombineLatest(
      this.segmentContexts$.pipe(pluck("primary"), filter(isDefined), map(context => {
        return isMolecula() ? compact(flatMap((context as MoleculaSegmentContext), c => get(c, "included"))) : flatMap((context as SegmentContext).or, "include");
      })),
      this.store.select("insights", "grow", "filters").pipe(map(filters => _map(groupBy(filters, "demographicId"), group => _map(group, "shortId")))),
    ).pipe(map(([subMarketIdentifiers, filterIdentifiers]) => [flatten(subMarketIdentifiers)].concat(filterIdentifiers) as string[][]))

    this.segments$ = activeIdentifiers$.pipe(
      map((identifiers) => flatten(identifiers).sort()),
      distinctUntilChanged(isEqual),
      filter(identifiers => !isEmpty(identifiers)),
      switchMap(identifiers => {
        return this.segmentService.fetchByIdentifiers(identifiers)
      }),
    );

    this.audienceIncludesNonSegments$ = this.segments$.pipe(
      map(segments => validForCustomAudience(segments))
    );

    this.hasCreateAudiencePermission$ = observableCombineLatest(
      this.segments$,
      this.dataPermissions$,
      this.store.select('permissions', 'audiences', 'create')
    ).pipe(
      map(([segments, dataPermissions, createAudienceUserPermission]) => segments.every(segment => isPermissioned(segment, dataPermissions, 'create_audience_single')) && createAudienceUserPermission)
    );

    const saveAudienceValidations$ = observableCombineLatest(
      this.audienceIncludesNonSegments$,
      this.hasCreateAudiencePermission$,
      this.isCompareMode$,
    );

    this.saveAudienceDisabled$ = saveAudienceValidations$.pipe(
      map(([audienceIncludesNonSegments, hasCreateAudiencePermission, isCompareMode]) => {
        return audienceIncludesNonSegments || !hasCreateAudiencePermission || isCompareMode;
      })
    );

    this.saveAudienceTooltip$ = saveAudienceValidations$.pipe(
      map(([audienceIncludesNonSegments, hasCreateAudiencePermission, isCompareMode]) => {
        let error;
        if (audienceIncludesNonSegments) {
          error = AUDIENCE_INCLUDES_NON_SEGMENTS;
        } else if (!hasCreateAudiencePermission) {
          error = ACTION_NOT_PERMITTED;
        } else if (isCompareMode) {
          error = IS_COMPARE_MODE
        };
        return error;
      })
    );

    this.audience$ = observableCombineLatest(
      this.segmentContexts$.pipe(pluck("primary"), filter(isDefined)),
      this.store.select("insights", "grow", "filters").pipe(map(filters => _map(groupBy(filters, "demographicId"), group => _map(group, "shortId")))),
    ).pipe(
      map(([context, filterIdentifiers]) => {
        const subMarketIdentifiers = isMolecula() ? flatMap(get(context, "context.included")) : flatMap((context as SegmentContext).or, "include");
        return {
          name: "Test Audience",
          description: "",
          rules: {
            include: {and: [flatten(subMarketIdentifiers)].concat(filterIdentifiers).map(group => ({or: group}))} as AudienceRule,
            exclude: {and: [{or: []}]}
          }
        }
      }),
    )
  }

  ngOnInit() {
    this.store.select("mekkos").pipe(select(selectActiveMekko), filter(Boolean))
      .subscribe((activeMekko: Mekko) => this.activeMekko = activeMekko);

    this.store.dispatch(new actions.SetGrowV3Active(true));

    observableCombineLatest(
      observableCombineLatest(
        this.store.select("mekkos", "selectedMekkoId").pipe(filter(isDefined)),
        this.store.select("mekkos", "mekkos")
      ).pipe(
        map(([selectedMekkoId, mekkos]) => mekkos[selectedMekkoId] ? selectedMekkoId : null)
      ),
      this.route.params
    ).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([selectedMekkoId, params]: [number, Params]) => {
        this.selectedMekkoId = selectedMekkoId;
        this.store.dispatch(new insightsActions.FetchTabs(selectedMekkoId, "Mekko", this.insightsContext))
        this.store.dispatch(new insightsActions.FetchDemographics(selectedMekkoId, "Mekko", this.insightsContext))
        this.store.dispatch(new Go({path: buildUrlRelative(params, `insights/grow${this.selectedMekkoId ? "/" + this.selectedMekkoId : ""}`)}));
      });

    this.focusedComponent$ = this.store.select("grow", "growV3Focus");

    this.store.select("mekkos").pipe(
      select(selectedSubMarkets),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((selectedSubMarkets) => {
      this.selectedSubMarkets = selectedSubMarkets
    })

    this.store.select("mekkos").pipe(
      select(selectSelectedSubMarkets),
      select(size),
      filter(size => size > 1),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(() =>
      this.showTooltip("You have multiple Audiences selected. To view Insights, click on Show Insights")
    );

    this.showTooltip("To view Insights, click on Show Insights");

    this.mekkoUnderEdit$ = this.store.select("mekkos", "mekkoUnderEdit");
    this.store.select("mekkos").pipe(
      select(selectMekkos),
      takeUntil(this.ngUnsubscribe))
      .subscribe(mekkos => this.mekkos = mekkos);
  }

  onMekkoChange(mekko: Mekko) {
    if (!mekko) {return; }
    this.store.dispatch(new mekkoActions.SetSelectedMekko(mekko));
  }

  onEditMekkoClick(mekko: Mekko) {
    this.store.dispatch(new mekkoActions.EditMekko(mekko));
  }

  onCreateMekkoClick() {
    this.store.dispatch(new mekkoActions.EditMekko(newMekko()));
  }

  showTooltip(message: string) {
    if (!Cookies.get(message)) {
      this.tooltipText = message;
      Cookies.set(message, 'true', { expires: 365, path: 'insights/grow-v3' });
      this.openTimer && clearTimeout(this.openTimer);
      this.openTimer = setTimeout(() => this.tooltipText = "", 10000);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.store.dispatch(new actions.SetGrowV3Active(false));
    this.store.dispatch(new insightsActions.EditRegionDemographic(this.insightsContext, null));
    this.growCounts.ngOnDestroy();
  }

  setFocus(focus: GrowV3FocusType) {
    this.store.dispatch(new actions.SetGrowV3Focus(focus))
  };

  toggleFilter(filter) {
    if (filter.type === 'audience') {
      this.store.dispatch(new mekkoActions.ToggleSubMarket(filter.subMarketId));
    } else {
      this.store.dispatch(new insightsActions.ToggleFilter(filter, this.insightsContext));
    }
  }

  get hasMekkos(): boolean {
    return this.mekkos && !!this.mekkos.length;
  }

  toFilter(subMarket) {
    const market = this.activeMekko.markets.find(market => market.id === subMarket.market_id);
    return {
      pathParts: [market.name, subMarket.name],
      type: "audience",
      subMarketId: subMarket.id,
      tab: "Sub Market"
    }
  }

  onSaveAudienceClick() {
    this.audience$.pipe(
      take(1),
      switchMap(audience => this.audienceMapper.fromJsonPersona(audience)),
      withLatestFrom(this.route.params)
    ).subscribe(([builderAudience, params]) => {
      this.store.dispatch(new SetPrebuiltAudience(builderAudience));
      this.store.dispatch(new Go({path: buildUrlRelative(params, "audiences/builder"), query: {referer: "Grow", returnUrl: "insights/grow"}}))
    });
  }

  get selectedSubMarketsAndFilters() {
    const filters = this.counts.filters || []
    return filters.concat(this.selectedSubMarkets.map((sm) => this.toFilter(sm)).reverse()).reverse()
  }

  clearFilters() {
    this.selectedSubMarketsAndFilters.forEach(filter => {
      if (filter.type === 'audience') {
        this.store.dispatch(new mekkoActions.ToggleSubMarket(filter.subMarketId));
      } else {
        this.store.dispatch(new insightsActions.ToggleFilter(filter, this.insightsContext));
      }
    })
  }

  copyMekko() {
    this.store.dispatch(new mekkoActions.CopyMekko(this.activeMekko));
    this.actions$.pipe(fetchOutcome(mekkoActions.CopyMekko.type), take(1), )
      .subscribe(
        saveOutcome => {
          this.store.dispatch(new mekkoActions.SetSelectedMekko(get(saveOutcome, ['result', 'mekko'])));
          this.alertSuccess("Copied");
        },
        () => this.alertWarn(`Grow chart could not be copied. Please try again.`)
      );
  }

  destroyMekko() {
    this.store.dispatch(new mekkoActions.DestroyMekko(this.activeMekko));
    this.actions$.pipe(fetchOutcome(mekkoActions.DestroyMekko.type), take(1), )
      .subscribe(
        () => {
          this.store.dispatch(new mekkoActions.FetchDefaultMekko());
          this.alertSuccess("Deleted");
        },
        () => this.alertWarn(`Grow chart could not be deleted. Please try again.`)
      );
  }

  alertSuccess(message: string): void {
    this.snackbar.open(message, null, {
      duration: 2500,
      panelClass: ['check']
    });
  }

  alertWarn(message: string): void {
    this.snackbar.open(message, null, {
      duration: 2500,
      panelClass: ['danger']
    });
  }
}

export type FocusedComponentType = "mekko" | "insights"
