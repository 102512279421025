<app-launch>
  <div class="content layout vertical">
    <div *ngIf="email">
      <p class="gold">{{email}} has been confirmed</p>
      <p>Logging into PeopleCloud Discovery...</p>
      <mat-progress-spinner mode="indeterminate" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
    </div>
    <div *ngIf="invalidToken">
      <p class="gold">Confirmation error</p>
      <p>For assistance, please reach out to <a href="mailto:productsupport@publicisgroupe.com">Product Support</a></p>
    </div>
  </div>
  <div class="baseline">
    <p *ngIf="email">For assistance, please reach out to <a href="mailto:productsupport@publicisgroupe.com">Product Support</a></p>
  </div>
</app-launch>
