import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { sumBy } from 'lodash';

@Component({
  selector: 'ppc-bar-chart',
  templateUrl: './ppc-bar-chart.component.html',
  styleUrls: ['./ppc-bar-chart.component.sass']
})
export class PpcBarChartComponent implements OnInit {
  @Input() vertical: boolean = false;
  @Input() displayCounts: boolean = false;
  @Input() round: string = '1.0-0';
  @Input() colorOverride: string;
  @Input() reverse: boolean = false;
  @Input() data: Array<{bucket: string, getCount: Function, getCountLabel: Function, percent: number, selected: boolean, id: string}> = [];
  @Output() barClicked = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
  }

  getPercentage(datum): number {
    if (datum.percent) {
      return datum.percent
    }
    const totalCount = sumBy(this.data, bucket => bucket.getCount());
    const percentage = (datum.getCount() / totalCount) * 100;
    return isNaN(percentage) ? 0 : percentage;
  }

  onBarClicked(datum: {bucket: string, getCount: Function, selected: boolean}) {
    this.barClicked.emit(datum.bucket);
  }

  trackById(index, item) {
    return item.id
  }
}
