import { filter, map, switchMap } from 'rxjs/operators';
import { Component, OnChanges, OnDestroy, Input, SimpleChanges, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Actions } from "@ngrx/effects";
import { get, find, concat, compact, map as _map, keyBy, every, filter as _filter } from 'lodash';

import * as chroma from "chroma-js";
import { AppState } from 'app/reducers';
import { Comparison, ComparisonSegment, FocusedCellType } from "app/comparisons/comparisons.reducer";
import * as actions from 'app/comparisons/comparisons.actions';
import { ComparisonsService } from 'app/comparisons/comparisons.service';
import { chartDomain, chartColors, indexColors, indexDomain } from 'app/insights/grow-v3/grow-v3.constants';
import { selectRegion } from 'app/hierarchy/hierarchy.reducers';
import { SegmentV2Service } from '../../segments-v2/segment-v2.service';
import { HierarchyRegion } from 'app/hierarchy/hierarchy.interface';
import { fixCounts } from 'app/insights/insights.models';
import {COUNT_TYPE_INDEX, COUNT_TYPE_MATCHED, COUNT_TYPE_MODELED} from "../../insights/insights.constants";
import { environment } from 'environments/environment';
import { SegmentLike } from '../../models/segment-like.model';
import { ComparisonChartCellDisplayComponent } from './comparison-chart-cell-display/comparison-chart-cell-display.component';
import { Observable, merge as observableMerge, of as observableOf, combineLatest as observableCombineLatest } from 'rxjs';
import { OverlapInsightsExportService } from 'app/insights/insights-components/insights-export/overlap-insights-export.service';
import { dataServiceType } from 'app/shared/utils/utils';

@UntilDestroy()
@Component({
  selector: 'ppc-comparison-chart',
  templateUrl: './comparison-chart.component.html',
  styleUrls: ['./comparison-chart.component.sass']
})
export class ComparisonChartComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChildren(ComparisonChartCellDisplayComponent) cellDisplays: QueryList<ComparisonChartCellDisplayComponent>;

  @Input() comparison: Comparison;
  fetchedIndexes = false;
  region: HierarchyRegion;
  hoverCoords: {x: number; y: number};
  segments: {[identifier: string]: SegmentLike} = {};
  focusedCells: FocusedCellType[];
  isTier3 = environment.isTier3;
  cellsOverflow$: Observable<boolean>;

  constructor(private store: Store<AppState>,
    public counts: ComparisonsService,
    private segmentV2Service: SegmentV2Service,
    private sanitizer: DomSanitizer,
    private overlapInsightsExportService: OverlapInsightsExportService,
    public actions$: Actions) {
    this.store.select('hierarchy').pipe(
      select(selectRegion), filter(Boolean), untilDestroyed(this)
    ).subscribe((region: HierarchyRegion) => this.region = region);

    this.store.select("comparisons", "focusedCells").pipe(
      untilDestroyed(this)
    ).subscribe(focusedCells => this.focusedCells = focusedCells);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.comparison && !isEqual(changes.comparison.previousValue, changes.comparison.currentValue)) {
      this.fetchComparisonCounts();
      this.fetchSegments();
    }
  }

  ngAfterViewInit() {
    this.cellsOverflow$ = observableMerge(
      observableOf(this.cellDisplays),
      this.cellDisplays.changes
    ).pipe(
      map(queryList => queryList.toArray()),
      switchMap(cellDisplays => {
        return observableCombineLatest(
          ..._map(cellDisplays, "hasOverflow$")
        ).pipe(
          map(overflows => overflows.some(Boolean))
        );
      })
    )
  }

  ngOnDestroy() {}

  fetchComparisonCounts() {
    if (!this.canRender) {return; }
    this.fetchedIndexes = this.isIndex;
    this.store.dispatch(new actions.FetchComparisonCounts({
      x_axis_ids: this.comparison.x_segments.map(x => x.short_id),
      y_axis_ids: this.comparison.y_segments.map(y => y.short_id),
      is_index: this.isIndex,
      is_modeled: this.isModeled,
      service_type: dataServiceType() as string
    }, this.comparison.id.toString()))
  }

  toggleFocusedCell(x: ComparisonSegment, y: ComparisonSegment) {
    const focusedCell: FocusedCellType = {
      x: x.short_id,
      y: y.short_id,
      name: `${x.name} <span class="intersection">&cap;</span> ${y.name}`
    }
    this.store.dispatch(new actions.ToggleFocusedCell(focusedCell));
  }

  setHoverCoords(x, y) {
    this.hoverCoords = {x, y};
  }

  clearHoverCoords() {
    this.hoverCoords = null;
  }

  get comparisonSegments(): ComparisonSegment[] {
    return concat(this.comparison.x_segments, this.comparison.y_segments);
  }

  get allSegmentIdentifiers(): string[] {
    return compact(_map(this.comparisonSegments, "short_id"));
  }

  fetchSegments() {
    if (this.allSegmentIdentifiers.length) {
      this.segmentV2Service.fetchByIdentifiers(this.allSegmentIdentifiers)
        .subscribe(fetchedSegments => {
          const fixedCountSegments = fixCounts(fetchedSegments, this.region);
          this.segments = keyBy(fixedCountSegments, "identifier");
        })
    }
  }

  getSegment(short_id) {
    return find(this.segments, {identifier: short_id});
  }

  getCountForSegment(segment) {
    if (this.isModeled) {
      return get(segment, ['count', 'modeled']);
    } else {
      return get(segment, ['count', 'matched']);
    }
  }

  getSegmentCount(segment, countType) {
    return this.getCountForSegment(this.getSegment(segment));
  }

  getCount(xShortId: string, yShortId: string): number {
    return this.counts.getCountData(this.comparison.id, xShortId, yShortId).count;
  }

  getPercent(xShortId: string, yShortId: string): number {
    return this.counts.getCountData(this.comparison.id, xShortId, yShortId).percent_value || 0;
  }

  getIndex(xShortId: string, yShortId: string): number {
    return this.counts.getCountData(this.comparison.id, xShortId, yShortId).index_value;
  }

  getColor(xShortId: string, yShortId: string): chroma.Color | string {
    if (!this.canRender) {return}
    if (!this.isIndex) {
      const percent = this.getPercent(xShortId, yShortId) / 100;
      return chartColors(percent).hex();
    } else {
      const index = this.getIndex(xShortId, yShortId);
      const t = index / 2; // standard range for index values are 0-2
      return indexColors(t).hex();
    }
  }

  get canRender() {
    return this.canRenderX && this.canRenderY;
  }

  get canRenderX() {
    return this.comparison.x_segments.length
  }

  get canRenderY() {
    return this.comparison.y_segments.length
  }

  get dummyX() {
    return new Array(12);
  }

  get dummyY() {
    return new Array(6);
  }

  get xValues() {
    return this.canRenderX ? this.comparison.x_segments : this.dummyX;
  }

  get yValues() {
    return this.canRenderY ? this.comparison.y_segments : this.dummyY;
  }

  get isIndex() {
    return this.comparison.mode == COUNT_TYPE_INDEX;
  }

  get isModeled() {
    return this.comparison.mode == COUNT_TYPE_MODELED;
  }

  get isMatched() {
    return this.comparison.mode == COUNT_TYPE_MATCHED;
  }

  get isOverlap() {
    return !this.isIndex;
  }

  get isPeopleCount() {
    return this.comparison.is_people_count;
  }

  get overlapGradient(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(0.25turn, ${chartDomain.map(t => `${chartColors(t).hex()} ${t * 100}%`).join(", ")})`)
  }

  get indexGradient(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(0.25turn, ${indexDomain.map(t => `${indexColors(t).hex()} ${t * 100}%`).join(", ")})`)
  }

  cellFocused(x: ComparisonSegment, y: ComparisonSegment, focusedCells: FocusedCellType[]): boolean {
    return this.canRender && !!find(focusedCells, {x: x.short_id, y: y.short_id})
  }

  toggleColumn(x: ComparisonSegment) {
    if (every(this.comparison.y_segments, y => !!find(this.focusedCells, {y: y.short_id, x: x.short_id}))) {
      this.comparison.y_segments.forEach(y => this.toggleFocusedCell(x, y))
    } else {
      _filter(this.comparison.y_segments, y => !find(this.focusedCells, {y: y.short_id, x: x.short_id})).forEach(y => this.toggleFocusedCell(x, y));
    }
  }

  toggleRow(y: ComparisonSegment) {
    if (every(this.comparison.x_segments, x => !!find(this.focusedCells, {y: y.short_id, x: x.short_id}))) {
      this.comparison.x_segments.forEach(x => this.toggleFocusedCell(x, y))
    } else {
      _filter(this.comparison.x_segments, x => !find(this.focusedCells, {y: y.short_id, x: x.short_id})).forEach(x => this.toggleFocusedCell(x, y));
    }
  }

  export() {
    this.overlapInsightsExportService.exportOnlyChart();
  }
}
