import { map, filter, switchMap, distinctUntilChanged, take, withLatestFrom } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, Inject, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest as observableCombineLatest, of as observableOf } from 'rxjs';
import { Actions } from "@ngrx/effects";
import { cloneDeep, difference, filter as _filter, find, flatten, get, isEmpty, isEqual, map as _map, orderBy } from 'lodash';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as actions from 'app/explore/explore.actions';
import { activePersonaIsAllData, newPersona, Persona, selectActivePersona, selectAllDataPersona, selectAllDataPersonaId, selectPersonas, selectDefaultPersona, selectSelectedPersonas } from "app/explore/explore.reducer";
import * as insightsActions from 'app/insights/insights.actions';
import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType, SegmentContexts } from "app/insights/insights.constants";
import { InsightsCountService } from "app/insights/insights-count.service";
import { fetchIfUnfetched, isFetchInFlight, fetchOutcome } from 'app/shared/utils/fetch-state';
import { buildUrlRelative, isDefined } from "app/shared/utils/utils";
import { Filter } from "app/insights/insights.models";
import { selectClient } from '../hierarchy/hierarchy.reducers';
import { HierarchyClient } from '../hierarchy/hierarchy.interface';
import { SegmentsHierarchyService } from '../segments-hierarchy/segments-hierarchy.service';
import { PERMISSION_INSIGHTS } from '../shared/utils/constants';
import { GrowCountService } from 'app/insights/grow-v3/grow-count.service';
import {JourneyCountService} from "../journey/journey-count.service";
import { PersonLevelCompareService } from '../insights/insights-components/person-level-compare/person-level-compare.service';
import { OverlapInsightsExportService } from 'app/insights/insights-components/insights-export/overlap-insights-export.service';
import { InsightsExportService } from 'app/insights/insights-components/insights-export/insights-export.service';
import { environment } from 'environments/environment';
import { SegmentLike } from 'app/models/segment-like.model';
import { isPermissioned } from 'app/services/data_permissions.service';
import { SegmentV2Service } from "app/segments-v2/segment-v2.service";
import { AudienceV2 } from 'app/audiences-v2/audience-v2.model';
import { AudienceMapper } from 'app/audience-builder/audience-mappers/segments-api';
import { SetPrebuiltAudience } from 'app/audience-builder/audience-builder.actions';
import { Go } from 'app/router/router.actions';
import { ACTION_NOT_PERMITTED, allPersonaIdentifiers, allSegmentIdentifiers, ALL_DATA_CANNOT_BE_COPIED, ALL_DATA_CANNOT_BE_DELETED, ALL_DATA_CANNOT_BE_EDITED, AUDIENCE_INCLUDES_NON_SEGMENTS, buildOrGroups, buildSegmentContext, CREATE_EDIT_TOOLTIP_TEXT, getFilterIdentifiers, IS_ALL_DATA, IS_ALL_DATA_PERSONA, PERSONA_COPIED, PERSONA_DELETED, removeDeletedSegments, setPersonaAudienceRules, UNABLE_TO_COPY_PERSONA, UNABLE_TO_DELETE_PERSONA } from 'app/explore/explore.utils';
import { validForCustomAudience } from 'app/insights/insights.utils';
import { EpcModalComponent } from 'app/shared/components/epc-modal/epc-modal.component';

@UntilDestroy()
@Component({
  selector: 'ppc-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.sass'],
  providers: [
    {
      provide: INSIGHTS_CONTEXT,
      useValue: "explore"
    },
    InsightsCountService,
    GrowCountService,
    JourneyCountService,
    PersonLevelCompareService,
    OverlapInsightsExportService,
    InsightsExportService,
  ]
})

export class ExploreComponent implements OnInit, OnDestroy {
  personas$ = observableCombineLatest(
    this.store.select("explore").pipe(select(selectPersonas), filter(isDefined)),
    this.store.select("explore").pipe(select(selectAllDataPersonaId)),
    this.store.select("explore").pipe(select(selectDefaultPersona)),
  ).pipe(
    map(([personas, allDataPersonaId, defaultPersona]) => {
      // have any defaults been set?
      personas.map(p => p[`hasDefault`] = !!defaultPersona);
      return orderBy(personas, {id: allDataPersonaId}, "desc")}
    ),
  );
  activePersona$ = this.store.select("explore").pipe(select(selectActivePersona), filter(isDefined));
  loading$ = observableCombineLatest(
    this.counts.loadingCounts$,
    this.store.select("fetchStates", insightsActions.FetchDemographics.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", insightsActions.FetchDemographicsConfig.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", insightsActions.FetchShareThisData.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", insightsActions.FetchShareThisTopDomains.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", insightsActions.FetchShareThisVerticalOverlap.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.FetchPersonas.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.SavePersona.type).pipe(select(isFetchInFlight)),
    this.compareService.loading$
  ).pipe(map(loadingStates => loadingStates.some(Boolean)));
  vendors$ = this.store.select("hierarchy").pipe(
    select(selectClient),
    filter(Boolean),
    switchMap((client: HierarchyClient) => this.segmentsHierarchyService.getListOfVendors([PERMISSION_INSIGHTS], client.slug)),
    map(vendors => _map(vendors, cv => `${cv.vendor_name}-${cv.vendor_type}${cv.owner_name ? "-" + cv.owner_name : ''}`)),
  );
  marketLevelOnly$ = this.store.select("insights", "explore", "tabs").pipe(
    map(tabs => {
      const tab = find(tabs, {tab_key: "top_level_person"});
      return !tab || !get(tab, "visible")
    })
  );
  segmentContexts$: Observable<SegmentContexts> = observableCombineLatest(
    this.vendors$,
    this.store.select("insights", this.insightsContext, "filters"),
    this.marketLevelOnly$,
    this.activePersona$,
    this.store.select("explore").pipe(select(selectSelectedPersonas), filter(isDefined))
  ).pipe(
    map(([vendors, filters, marketLevelOnly, activePersona, selectedPersonas]) => {
      if (marketLevelOnly) { return }
      return buildSegmentContext(vendors, filters, activePersona, _filter(selectedPersonas, persona => persona.id !== activePersona.id));
    })
  );
  personaUnderEdit$ = this.store.select("explore", "personaUnderEdit");
  marketLevelDemographicUnderEdit$ = this.store.select("insights", "explore", "marketLevelDemographicUnderEdit").pipe(map(cloneDeep));
  regionDemographicUnderEdit$ = this.store.select("insights", "explore", "regionDemographicUnderEdit");
  selectedFilters: Filter[];
  exportOpen$ = this.store.select("insights", this.insightsContext, "exportOpen");
  isTier3 = environment.isTier3;
  topLevelTab$ = this.store.select("insights", this.insightsContext, "topLevelTab");
  audience$: Observable<AudienceV2>;
  segments$: Observable<SegmentLike[]>;
  audienceIncludesNonSegments$: Observable<boolean>;
  createAndEditDisabled = false;
  hasCreateAudiencePermission$: Observable<boolean>;
  dataPermissions$ = this.store.select("dataPermissions");
  saveAudienceTooltip$: Observable<string>;
  saveAudienceDisabled: boolean;
  selectedPersonaId: number;
  permissions$ = this.store.select("permissions", "explore");
  canCreateIndexReport$ = this.store.select("permissions", "index_report", "create");
  canRequestMotivationAudience$ = this.store.select("permissions", "request_motivation_audiences", "create");
  activePersona: Persona;
  indexReportFormOpen = false;
  @ViewChild('personaHasDeletedSegmentsModal', { static: true }) personaHasDeletedSegmentsModal: EpcModalComponent;
  deletedActivePersonaSegmentIds: string[];
  allDataPersona: Persona;

  constructor(
    public store: Store<AppState>,
    public counts: InsightsCountService,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType,
    private actions$: Actions,
    private route: ActivatedRoute,
    private audienceMapper: AudienceMapper,
    private segmentService: SegmentV2Service,
    private segmentsHierarchyService: SegmentsHierarchyService,
    private snackbar: MatSnackBar,
    private compareService: PersonLevelCompareService
  ) {
    const filterIdentifiers$ = this.store.select("insights", "explore", "filters").pipe(
      map(filters => getFilterIdentifiers(filters))
    );

    const filterSegments$ = filterIdentifiers$.pipe(
      map(identifiers => flatten(identifiers).sort()),
      distinctUntilChanged(isEqual),
      switchMap(identifiers => isEmpty(identifiers) ? observableOf([]) as Observable<SegmentLike[]> : this.segmentService.fetchByIdentifiers(identifiers)),
    );

    this.segments$ = this.activePersona$.pipe(
      map(persona => persona.identifiers),
      map(identifiers => allPersonaIdentifiers(identifiers)),
      distinctUntilChanged(isEqual),
      switchMap(identifiers => isEmpty(identifiers) ? observableOf([]) as Observable<SegmentLike[]> : this.segmentService.fetchByIdentifiers(identifiers)),
    );

    this.audienceIncludesNonSegments$ = this.segments$.pipe(
      map(segments => validForCustomAudience(segments))
    );

    filterSegments$.pipe(
      map(segments => validForCustomAudience(segments)),
      untilDestroyed(this)
    ).subscribe(createAndEditDisabled => this.createAndEditDisabled = createAndEditDisabled);

    this.hasCreateAudiencePermission$ = observableCombineLatest(
      this.segments$,
      this.dataPermissions$,
      this.store.select('permissions', 'audiences', 'create')
    ).pipe(
      map(([segments, dataPermissions, createAudienceUserPermission]) => segments.every(segment => isPermissioned(segment, dataPermissions, 'create_audience_single')) && createAudienceUserPermission)
    );

    const saveAudienceValidations$ = observableCombineLatest(
      this.audienceIncludesNonSegments$,
      this.hasCreateAudiencePermission$,
      this.store.select("explore").pipe(select(activePersonaIsAllData))
    );

    saveAudienceValidations$.pipe(
      map(([audienceIncludesNonSegments, hasCreateAudiencePermission, isAllDataPersona]) => {
        return audienceIncludesNonSegments || !hasCreateAudiencePermission || isAllDataPersona;
      }),
      untilDestroyed(this)
    ).subscribe(saveAudienceDisabled => this.saveAudienceDisabled = saveAudienceDisabled);

    this.saveAudienceTooltip$ = saveAudienceValidations$.pipe(
      map(([audienceIncludesNonSegments, hasCreateAudiencePermission, isAllDataPersona]) => {
        let error;
        if (isAllDataPersona) {
          error = IS_ALL_DATA_PERSONA;
        } else if (audienceIncludesNonSegments) {
          error = AUDIENCE_INCLUDES_NON_SEGMENTS;
        } else if (!hasCreateAudiencePermission) {
          error = ACTION_NOT_PERMITTED;
        };
        return error;
      })
    );

    this.audience$ = observableCombineLatest(
      filterIdentifiers$,
      this.personaUnderEdit$,
      this.activePersona$,
      this.store.select('explore').pipe(select(selectDefaultPersona)),
    ).pipe(
      map(([filterIdentifiers, personaUnderEdit, activePersona, defaultPersona]) => {
        const emptyOr = [{or: []}];
        let groups;
        if (activePersona.is_all_data) { // Creating a persona from All Data persona
          groups = {include: emptyOr, exclude: emptyOr};
        } else if (isEmpty(personaUnderEdit)) {  // Creating an audience
          groups = buildOrGroups(activePersona.identifiers);
        } else if (personaUnderEdit.id) { // Editing a persona
          groups = buildOrGroups(activePersona.identifiers);
        } else { // Creating a persona
          groups = {include: emptyOr, exclude: emptyOr};
        };

        // extra paranoid, we might not have a default or active persona set yet
        const defaultBool = !!((get(activePersona, 'id') && get(defaultPersona, 'id')) && activePersona.id === defaultPersona.id);

        return setPersonaAudienceRules("Test Audience", defaultBool, groups);
      }),
    )
  }

  ngOnInit() {
    fetchIfUnfetched(this.store, new actions.FetchPersonas(), this);

    observableCombineLatest(
      observableCombineLatest(
        this.store.select("explore", "selectedPersonaId").pipe(filter(isDefined)),
        this.store.select("explore", "personas")
      ).pipe(
        map(([selectedPersonaId, personas]) => personas[selectedPersonaId] ? personas[selectedPersonaId] : null)
      ),
      this.route.params
    ).pipe(
      filter(([selectedPersona]) => selectedPersona && this.selectedPersonaId !== selectedPersona.id),
      untilDestroyed(this)
    ).subscribe(([selectedPersona, params]: [Persona, Params]) => {
      this.selectedPersonaId = selectedPersona.id;
      this.store.dispatch(new insightsActions.FetchTabs(this.selectedPersonaId, "Persona", "explore"));
      this.store.dispatch(new Go({path: buildUrlRelative(params, `insights/explore${this.selectedPersonaId ? "/" + this.selectedPersonaId : ""}`)}));
    });

    this.counts.filters$.pipe(untilDestroyed(this)).subscribe(filters => {
      this.selectedFilters = filters || [];
    });

    this.activePersona$.pipe(untilDestroyed(this)).subscribe(persona => this.activePersona = persona);

    this.store.select("explore").pipe(
      select(selectAllDataPersona), filter(isDefined), untilDestroyed(this)
    ).subscribe(persona => this.allDataPersona = persona);

    this.segments$.pipe(
      map((segments) => allSegmentIdentifiers(segments)),
      filter((segments) => !isEmpty(segments)),
      withLatestFrom(this.activePersona$.pipe(
        map((persona) => persona.identifiers),
        map((identifiers) => allPersonaIdentifiers(identifiers)),
      ))
    ).pipe(untilDestroyed(this)).subscribe(([segmentIdentifiers, activePersonaIdentifiers]) => {
        const deletedSegments = difference(activePersonaIdentifiers, segmentIdentifiers);
        if (deletedSegments.length > 0 && !this.activePersona.deletedSegmentIds) {
          this.store.dispatch(new actions.SetActivePersonaDeletedSegmentIds(this.activePersona, deletedSegments));
        }
        if (!this.activePersona.deletedSegmentsChecked) {
          this.store.dispatch(new actions.SetActivePersonaDeletedSegmentsChecked(this.activePersona, true));
        }
      });

    this.activePersona$.pipe(map(persona => persona.deletedSegmentIds)).pipe(
      filter(isDefined), untilDestroyed(this)
    ).subscribe(deletedActivePersonaSegmentIds => {
      this.deletedActivePersonaSegmentIds = deletedActivePersonaSegmentIds;
      if (this.deletedActivePersonaSegmentIds.length > 0) {
        this.personaHasDeletedSegmentsModal.show();
      }
    });
  }

  ngOnDestroy() {}

  clearFilters() {
    this.selectedFilters.forEach(filter => {
      if (filter.type === 'audience') { return };

      this.store.dispatch(new insightsActions.ToggleFilter(filter, this.insightsContext));
    });
  }

  toggleFilter(toggledFilter: Filter) {
    if (toggledFilter.type === 'audience') { return };

    this.store.dispatch(new insightsActions.ToggleFilter(toggledFilter, this.insightsContext));
  }

  toggleExportPane() {
    this.store.dispatch(new insightsActions.ToggleExport(this.insightsContext));
  }

  onSaveAudienceClick(action?: string, name?: string, id?: number) {
    this.audience$.pipe(
      take(1),
      switchMap(audience => this.audienceMapper.fromJsonPersona(audience)),
      withLatestFrom(this.route.params),
    ).subscribe(([builderAudience, params]) => {
      if (!id) { builderAudience = {...builderAudience, default: false}; }
      this.store.dispatch(
        new SetPrebuiltAudience({...builderAudience, name: name || builderAudience.name, id: id})
      );
      const returnUrl = `insights/explore${id ? '/' + id : ''}`;
      const nonAudienceAction = action ? (action === "requestMotivationAudience" ? "requestMotivationAudience" : "savePersona") : null;
      const query = {action: nonAudienceAction, referer: "Explore", returnUrl: returnUrl};
      const navAction = nonAudienceAction ? `insights/${nonAudienceAction === 'savePersona' ? 'explore' : 'motivation-audience'}` : 'audiences';
      const navPath = `${navAction + '/builder'}` + `${nonAudienceAction && id ? '/' + id : ''}`;
      this.store.dispatch(new Go({path: buildUrlRelative(params, navPath), query: query}));
    });
  }

  onRequestMotivationAudienceClick() {
    this.store.dispatch(new actions.EditPersona(cloneDeep(this.activePersona)));
    this.onSaveAudienceClick("requestMotivationAudience", this.activePersona.name, this.activePersona.is_all_data ? null : this.activePersona.id);
  }

  onPersonaChange(persona: Persona) {
    if (!persona) { return };
    this.store.dispatch(new actions.SetSelectedPersona(persona));
  }

  onCreatePersonaClick() {
    this.store.dispatch(new actions.EditPersona(newPersona()));
    this.onSaveAudienceClick("create", "Unnamed Persona");
  }

  onEditPersonaClick(persona: Persona) {
    this.store.dispatch(new actions.EditPersona(persona));
    this.onSaveAudienceClick("edit", persona.name, persona.id);
  }

  dispatchDestroyPersona() {
    if (get(this.activePersona, [IS_ALL_DATA], true)) { return; }
    this.store.dispatch(new actions.DestroyPersona(this.activePersona));
    return this.actions$.pipe(
      fetchOutcome(actions.DestroyPersona.type),
      take(1),
    )
  }
  
  destroyPersona() {
    this.dispatchDestroyPersona().subscribe(
      () => {
        this.store.dispatch(new actions.FetchDefaultPersona());
        this.alertSuccess(PERSONA_DELETED);
      },
      () => this.alertWarn(UNABLE_TO_DELETE_PERSONA)
    );
  }

  destroyDeletedSegmentPersona() {
    this.dispatchDestroyPersona().subscribe(
      () => {
        this.personaHasDeletedSegmentsModal.close();
        this.alertSuccess(PERSONA_DELETED);
      },
      () => this.alertWarn(UNABLE_TO_DELETE_PERSONA)
    );
  }

  copyPersona() {
    if (get(this.activePersona, [IS_ALL_DATA], true)) { return; }
    this.store.dispatch(new actions.CopyPersona(this.activePersona.id))
    this.actions$.pipe(
      fetchOutcome(actions.CopyPersona.type),
      take(1)
    ).subscribe(
      ({ result: { persona } }) => {
        this.alertSuccess(PERSONA_COPIED)
        this.store.dispatch(new actions.SetSelectedPersona(persona))
      },
      err => this.alertWarn(UNABLE_TO_COPY_PERSONA)
    )
  }

  editDeletedSegmentPersona(persona: Persona) {
    if (get(persona, [IS_ALL_DATA], true)) {
      return;
    }
    this.onEditPersonaClick(removeDeletedSegments(persona, this.deletedActivePersonaSegmentIds));
  }

  toggleIndexReportForm(): void {
    this.indexReportFormOpen = !this.indexReportFormOpen;
  }

  alertSuccess(message: string, duration = 2500): void {
    this.snackbar.open(message, null, {
      duration,
      panelClass: ['check']
    });
  }

  alertWarn(message: string, duration = 2500): void {
    this.snackbar.open(message, null, {
      duration,
      panelClass: ['danger']
    });
  }

  deleteTooltipText(isAllData: boolean): string {
    return isAllData ? ALL_DATA_CANNOT_BE_DELETED : 'Delete';
  }

  tooltipText(type: string, isAllData: boolean): string {

    return type === 'Edit' ? (isAllData ? ALL_DATA_CANNOT_BE_EDITED : (this.createAndEditDisabled ? CREATE_EDIT_TOOLTIP_TEXT : "Edit"))
      :  ( type === 'Create' ? (this.createAndEditDisabled ? CREATE_EDIT_TOOLTIP_TEXT : "Create") : "Edit");
  }

  copyTooltipText(isAllData: boolean): string {
    return isAllData ? ALL_DATA_CANNOT_BE_COPIED : 'Copy'
  }

  get indexReportItemDetails() {
    return Object.assign({}, this.activePersona, { type: 'persona' });
  }
}
