<div class="destinations-page">
  <div class="destinations-upper-page">
    <div class="destinations-overlay-controls">
      <h1 class="destinations-header">Add Destinations</h1>

      <button class="close-btn fa fa-2x fa-times" (click)="onClose.emit()"></button>
    </div>

    <div class="destination-body">
      <p>Fill out the fields below and submit your request to Product Support</p>

      <div class="section-header">
        <h4 *ngIf="partner">{{partner.name}}</h4>
      </div>
      <form #destinationForm="ngForm" class="margin-top-10">
        <ng-container *ngIf="isTradeDesk">
          <div>
            <mat-label>The Trade Desk Partner ID</mat-label>
            <p>
              <mat-form-field appearance="outline" class="material-color">
                <input matInput required
                  (change)="partnerSpecifics.ttd_partner_id = partnerSpecifics.ttd_partner_id.trim()"
                  [(ngModel)]="partnerSpecifics.ttd_partner_id"
                  name="ttdPartnerId"
                >
                <mat-error *ngIf="partnerSpecifics.ttd_partner_id?.length == 0">
                  <i class="fa fa-exclamation-triangle"></i>
                  The Trade Desk Partner ID is required
                </mat-error>
              </mat-form-field>
              <span class="margin-left-20">
                Do not include hyphens.
              </span>
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="isDV360">
          <div>
            <mat-label>Entity ID</mat-label>
            <p>
              <mat-form-field appearance="outline">
                <input required matInput
                  (change)="partnerSpecifics.entity_id = partnerSpecifics.entity_id.trim()"
                  [(ngModel)]="partnerSpecifics.entity_id"
                  name="entityId"
                >
                <mat-error *ngIf="partnerSpecifics.entity_id?.length == 0">
                  <i class="fa fa-exclamation-triangle"></i>
                  Entity ID is required
                </mat-error>
              </mat-form-field>
              <span class="margin-left-20">
                Do not include hyphens.
              </span>
            </p>
          </div>
          <div>
            <mat-label>Product</mat-label>
            <p>
              <mat-form-field appearance="outline">
                <input matInput
                  (change)="partnerSpecifics.product = partnerSpecifics.product.trim()"
                  [(ngModel)]="partnerSpecifics.product"
                  name="product"
                >
              </mat-form-field>
            </p>
          </div>
          <div>
            <mat-label>Whitelist Confirmation</mat-label>
            <p>
              <mat-form-field appearance="outline" ppcTooltip="Google has to whitelist LiveRamp to enable us to send data to particular Google DV360 account. Usually your Google Account Manager would be able to help with this request">
                <mat-select [(ngModel)]="partnerSpecifics.whitelist_confirmation" [ngModelOptions]="{standalone: true}">
                  <mat-option [value]=true>Yes</mat-option>
                  <mat-option [value]=false>No</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </div>
        </ng-container>
        <div *ngIf="isTradeDesk">
          <mat-label>The Trade Desk Partner Name</mat-label>
          <div class='flex-direction'>
            <mat-form-field appearance="outline">
              <input matInput required type="text"
                [(ngModel)]="destName"
                (change)="destName=destName.trim()"
                name="name"
              >
              <mat-error *ngIf="destName?.length == 0">
                <i class="fa fa-exclamation-triangle"></i>
                The Trade Desk Partner Name is required
              </mat-error>
            </mat-form-field>
            <span class="side-detail">
              The Trade Desk Partner names must be less than 50 characters.
            </span>
          </div>
        </div>
        <div *ngIf="!isTradeDesk">
          <mat-label>Destination Account Name</mat-label>
          <div class='flex-direction'>
            <mat-form-field appearance="outline">
              <input matInput required type="text"
                [(ngModel)]="destName"
                (change)="destName=destName.trim()"
                name="name"
              >
              <mat-error *ngIf="destName?.length == 0">
                <i class="fa fa-exclamation-triangle"></i>
                Destination Name is required
              </mat-error>
            </mat-form-field>
            <span class="side-detail">
              Account names must be less than 50 characters.
            </span>
          </div>
        </div>
      </form>
    </div>

    <ng-container *ngIf="errors?.length > 0">
      <div class="error-message">
        <mat-error><i class="fa fa-exclamation-triangle"></i> Could not create destination. Please find the errors below: </mat-error>
        <div *ngFor="let error of errors">
          <mat-error><i class="fa fa-exclamation-triangle"></i>* {{ error }}</mat-error>
        </div>
      </div>
    </ng-container>

    <div class='footer'>
      <button mat-button class="button-transparent" (click)="onClose.emit()">
        Cancel
      </button>
      <button mat-button [ngClass]="{ 'button-gray': !isFormValid(), 'button-active': isFormValid() }"
              (click)="createDestination()"
              [disabled]="!isFormValid()">
        Add Destination
      </button>
    </div>
  </div>
</div>
