<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>

<ng-container *ngIf="(activeOutcomeAudience$ | async) as activeOutcomeAudience">
  <div class="outcome-audience">
    <div class="outcome-audience-header-container">
      <div class="outcome-audience-header">
        <div class="page-title">
          <span class="category">Insights</span> Explore Outcome Audiences
        </div>
      </div>
      <div class="header-dropdown">
        <div class="dropdown-container">
          <div class="outcome-audience-dropdown-label">Outcome Audience</div>
          <div class="outcome-audience-dropdown layout horizontal">
            <ppc-searchable-dropdown #outcomeAudienceDropdown
                                      itemLabelProp="name"
                                      mapFromProperty="id"
                                      [allowClear]="false"
                                      [(ngModel)]="selectedOutcomeAudienceId"
                                      iconPrefix="user"
                                      [items]="outcomeAudiences$ | async">
              <ng-template #itemTemplate let-item="item">
                <div class="outcome-audience-item" [class.selected]="activeOutcomeAudience === item">
                  <button class="single-select-item"
                    (click)="onOutcomeAudienceChange(item); outcomeAudienceDropdown.open = false"
                    *ppcCheckOverflow="let hasOverflow=hasXOverflow"
                    [ppcTooltip]="item?.name"
                    [tooltipDisabled]="!hasOverflow"
                    tabIndex="0">
                    {{ item?.name }}
                  </button>
                </div>
              </ng-template>
            </ppc-searchable-dropdown>
            <div class="actions">
              <ppc-icon-button
                *ngIf="canCreateOutcomeAudience$ | async"
                icon="plus"
                [ppcTooltip]="'New Outcome Audience'"
                (click)="toggleOutcomeAudienceForm()">
              </ppc-icon-button>
            </div>
          </div>
        </div>
        <div class="count-display" *ngIf="!!selectedOutcomeAudienceId">
          <i class="fa fa-user fa-lg"></i> Matched: {{ (counts.totalCount | number) || 0 }}
        </div>
      </div>
    </div>
    <ppc-filter-bar
      [filters]="selectedFilters"
      [class.show]="selectedFilters.length"
      (clearFilters)="clearFilters()"
      (toggleFilter)="toggleFilter($event)">
    </ppc-filter-bar>
    <div class="outcome-audience-widgets-container" *ngIf="selectedOutcomeAudienceId;else empty_content">
      <ppc-insights [segmentContexts$]="segmentContexts$"></ppc-insights>
    </div>
  </div>
</ng-container>

<ng-template #empty_content>
  <div class="full-height layout horizontal center center-justified">
    <button
      *ngIf="canCreateOutcomeAudience$ | async"
      class="pds-btn pds-btn--primary"
      ppcThrottleClick
      [throttleTime]="1000"
      (throttledClick)="toggleOutcomeAudienceForm()">
      Create New
    </button>
  </div>
</ng-template>

<app-slide-in-overlay
  class="outcome-audience-slide-form"
  [active]="!!outcomeAudienceFormOpen"
  [percentOfParentCovered]="100">
  <ppc-outcome-audience-form
    [stateChange]="outcomeAudienceFormOpen"
    (close)="toggleOutcomeAudienceForm()">
  </ppc-outcome-audience-form>
</app-slide-in-overlay>
