import { take, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions } from "@ngrx/effects";
import { DragulaService } from 'ng2-dragula';
import { find, values, remove, sumBy, first, get, filter } from 'lodash';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject, combineLatest as observableCombineLatest } from 'rxjs';

import * as actions from "app/insights/insights.actions";
import * as journeyActions from "app/journey/journey.actions"
import { MarketLevelDemographic, MarketLevelDemographicBucket, newMarketLevelDemographicBucket } from "app/insights/insights-components/market-level-demographics/market-level-demographic.interface";
import {fetchOutcome} from "app/shared/utils/fetch-state";
import { selectActiveMekkoSubMarkets, selectActiveMekkoMarkets, Market } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';
import { JourneyBrand } from 'app/journey/journey.models';
import { selectSelectedJourney } from 'app/journey/journey.reducer';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';
import { ResourceSubMarket } from 'app/insights/insights.reducer';
import { Persona, selectActivePersona } from 'app/explore/explore.reducer';
import { ChartTypes } from 'app/insights/insights.models';
import { ErrorMessages, ToolTips } from 'app/app.common-messages';
@Component({
  selector: 'ppc-market-level-demographic-form',
  templateUrl: './market-level-demographic-form.component.html',
  styleUrls: ['./market-level-demographic-form.component.sass']
})
export class MarketLevelDemographicFormComponent extends InsightsResourceTracker implements OnInit, OnDestroy {
  @Input() marketLevelDemographic: MarketLevelDemographic;
  bagName = "market-level-demographic-buckets";
  persona?: Persona[];
  subMarkets?: ResourceSubMarket[];
  subMarketNames?: {id: number, name: string}[];
  selectedSubMarketId: number;
  ngUnsubscribe = new Subject();
  bucketsToRemove: MarketLevelDemographicBucket[] = [];
  brands?: JourneyBrand[];
  brandId?: number;
  marketId?: number;
  markets?: Market[];
  percentageTooltip: string;

  chartTypes = ChartTypes;
  errorMessages = ErrorMessages;
  toolTips = ToolTips;
  constructor(public store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private dragula: DragulaService,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {  super(store, insightsContext) }

  ngOnInit() {
    this.dragula.setOptions(this.bagName, {
      moves: (el, container, handle) => /drag-handle/.test(handle.className)
    })

    this.setPercentageTooltip()

    if (this.resourceType == "Mekko") {
      observableCombineLatest(
        this.store.select('mekkos').pipe(select(selectActiveMekkoSubMarkets)),
        this.store.select("mekkos").pipe(select(selectActiveMekkoMarkets)),
      ).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(([subMarkets, markets]) => {

          this.subMarkets = subMarkets
          this.markets = markets;
          if (!this.marketId) { this.marketId = this.markets[0].id }
          this.selectedSubMarketId = get(first(filter(this.subMarkets, {market_id: this.marketId})), "id");

          this.subMarketNames = this.subMarkets.filter((sm) => sm.market_id == this.marketId).map((sm) => {
            return {id: sm.id, name: `${sm.name}`};
          })
        })
    } else if (this.resourceType == "Journey") {
      observableCombineLatest(
        this.store.select("journey").pipe(select(selectSelectedJourney)),
        this.store.select("journey", "selectedBrandId"),
      ).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(([journey, brandId]) => {
          this.subMarkets = journey.sub_markets
          this.brands = journey.brands;
          this.brandId = brandId;
          const selectedSubMarkets = journey.sub_markets.filter((sm) => sm.journey_brand_id == brandId)

          if (this.selectedSubMarketId) {
            const stageId = journey.sub_markets.find((sm) => sm.id == this.selectedSubMarketId).journey_stage_id;
            this.selectedSubMarketId = selectedSubMarkets.find((sm) => sm.journey_stage_id == stageId).id;
          } else {
            this.selectedSubMarketId = get(first(filter(this.subMarkets, {journey_brand_id: this.brandId})), "id");
          }

          this.subMarketNames = selectedSubMarkets.map((sm) => {
            const stage = journey.stages.find((s) => s.id == sm.journey_stage_id)
            return {id: sm.id, name: `${stage.name}`};
          })
        });
    } else if (this.resourceType == "Persona") {
      this.store.select("explore").pipe(select(selectActivePersona))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(persona => {
          this.persona = [persona]
          this.selectedSubMarketId = persona.id;
        });
    }
  }

  ngOnDestroy() {
    this.dragula.destroy(this.bagName);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  brandChange() {
    this.store.dispatch(new journeyActions.SetSelectedBrandId(this.brandId));
  }

  marketChange() {
    const subMarkets = this.subMarkets.filter((sm) => sm.market_id == this.marketId)
    this.subMarketNames = subMarkets.map((sm) => {
      return {id: sm.id, name: `${sm.name}`};
    });
    this.selectedSubMarketId = subMarkets[0].id;
  }

  addBucket() {
    const items = (this.insightsContext !== 'explore') ?
      this.subMarkets : this.persona;
    this.marketLevelDemographic.market_level_demographic_buckets.push(newMarketLevelDemographicBucket(items, this.resourceType));
  }

  removeBucket(bucket: MarketLevelDemographicBucket) {
    if (bucket.id) {
      bucket._destroy = true;
      this.bucketsToRemove.push(bucket)
    }
    remove(this.marketLevelDemographic.market_level_demographic_buckets, (mldb) => mldb === bucket )
  }

  save() {
    this.marketLevelDemographic.market_level_demographic_buckets.forEach((bucket, idx) => bucket.position = idx)
    this.marketLevelDemographic.market_level_demographic_buckets = this.marketLevelDemographic.market_level_demographic_buckets.concat(this.bucketsToRemove)
    this.marketLevelDemographic.market_level_demographic_buckets.forEach(marketLevelDemographicBucket => {
      marketLevelDemographicBucket.sub_market_bucket_entries_attributes = values(marketLevelDemographicBucket.sub_market_bucket_entries).filter((smbe) => {
        if (this.resourceType == 'Journey') {
          return smbe.resource_type == 'JourneySubMarket'
        } else if (this.resourceType == 'Mekko') {
          return smbe.resource_type == 'SubMarket'
        } else if (this.resourceType == 'Persona') {
          return smbe.resource_type == 'Persona'
        }
      })
    })
    this.marketLevelDemographic.market_level_demographic_buckets_attributes = this.marketLevelDemographic.market_level_demographic_buckets

    this.store.dispatch(new actions.SaveMarketLevelDemographic(this.insightsContext, this.marketLevelDemographic));
    this.actions$.pipe((fetchOutcome(actions.SaveMarketLevelDemographic.type)),
      take(1), )
      .subscribe(
        () => this.store.dispatch(new actions.EditMarketLevelDemographic(this.insightsContext, null)),
        () => this.snackbar.open("Something went wrong saving this demographic. Please try again in a few minutes", "OK", {
          panelClass: ["danger"],
          duration: 6000
        })
      )
  }

  cancel() {
    this.store.dispatch(new actions.EditMarketLevelDemographic(this.insightsContext, null));
  }

  get buckets() {
    return this.marketLevelDemographic.market_level_demographic_buckets.filter(bucket => !bucket._destroy);
  }

  get subMarketBucketEntryTotal(): number {
    if (!this.selectedSubMarketId) {return 0; }
    return sumBy(this.marketLevelDemographic.market_level_demographic_buckets, mldb => mldb.sub_market_bucket_entries[this.selectedSubMarketId].value || 0)
  }

  get subMarketTotalPopulation(): number {
    if (!this.selectedSubMarketId) {return 0; }
    return find(this.subMarkets, {id: this.selectedSubMarketId}).population;
  }

  get isValid(): boolean {
    const hasName = !!this.marketLevelDemographic.name;
    const bucketsAreValid = !!this.buckets.length &&
                            this.buckets.every(bucket => {
                              return !!bucket.name && values(bucket.sub_market_bucket_entries).every(sme => sme.value >= 0)
                            });
    return hasName && bucketsAreValid && !this.hasTooManyBucketsForBarVertical
  }

  get hasTooManyBucketsForBarVertical(): boolean {
    return this.marketLevelDemographic.chart_type == this.chartTypes.BarVertical && this.buckets.length > 9
  }

  setPercentageTooltip() {
    if (this.resourceType == 'Persona') {
      this.percentageTooltip = this.toolTips.PersonaPercentage;
    } else {
      this.percentageTooltip = this.toolTips.NonPersonaPercentage;
    }
  }

}
