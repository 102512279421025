import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fullContext } from 'app/hierarchy/hierarchy.reducers';
import { isEmpty } from 'lodash';
import { ES, MOLECULA } from 'app/shared/utils/constants';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { dataServiceType, setDataServiceType } from 'app/shared/utils/utils';
import { isMolecula } from 'app/insights/insights.utils';
import { userCan } from 'app/authorization/permissions/permissions.reducers';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class DataSourceService {
  dataSources$ = new BehaviorSubject<string[]>([]);

  constructor(private store: Store<AppState>) {
    fullContext(this.store)
      .pipe(untilDestroyed(this))
      .subscribe(({ region }) => {
        this.dataSources$.next([]);
        const moleculaIndex = region.molecula_index;
        const esIndex = region.es_index;
        if (moleculaIndex) {
          this.dataSources$.next([moleculaIndex]);
        }
        if (esIndex) {
          this.dataSources$.next([...this.dataSources$.value, esIndex]);
        }
        // If dataServiceType is unset OR user doesn't have access to multiple data sources, default to Molecula when possible
        if (isEmpty(dataServiceType()) || !this.hasMoleculaAccess) {
          const indexType = moleculaIndex ? MOLECULA : ES;
          setDataServiceType(indexType);
        }
      })
  }

  get serviceTypeText() {
    return isMolecula() ? 'ES' : 'Molecula';
  }

  get hasMoleculaAccess() {
    return userCan('read', 'molecula_access', this.store);
  }

  get canToggleDataSource() {
    return this.hasMoleculaAccess && this.dataSources$.value.length > 1;
  }

  public toggleService(): void {
    const currentServiceType = dataServiceType();
    const nextServiceType = this.serviceTypeText.toLowerCase();
    console.log('toggling, current type = ', currentServiceType, ', setting to ', nextServiceType);
    setDataServiceType(nextServiceType);
    window.location.reload();
  }
}
