import { Component, OnInit } from '@angular/core';
import {apiUrl} from "../services/ppc_http.service";
import {HttpClient} from "@angular/common/http";
import {
  AUTH_API_PATH,
  CLIENTS_API_PATH,
  ID_ANALYTICS_PATH,
  INSIGHTS_API_PATH,
  PLANS_API_PATH,
  SEGMENTS_API_PATH
} from '../shared/utils/constants';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.sass']
})
export class HealthComponent implements OnInit {
  gateway: string = '';
  auth_service: string = '';
  clients_service: string = '';
  insights_service: string = '';
  id_service: string = '';
  toolkit_service: string = '';
  plans_service: string = '';
  segments_service: string = '';

  ERROR_MESSAGE: string = 'Error';

  constructor(private http: HttpClient) {}

  ngOnInit() {

    this.handleRequest(this.http.get(apiUrl("/api-gateway-health-check")), 'gateway');
    this.handleRequest(this.http.get(apiUrl(`${AUTH_API_PATH}/health`)), 'auth');
    this.handleRequest(this.http.get(apiUrl(`${ID_ANALYTICS_PATH}/health`)), 'id');
    this.handleRequest(this.http.get(apiUrl(`${INSIGHTS_API_PATH}/health`)), 'insights');
    this.handleRequest(this.http.get(apiUrl(`${PLANS_API_PATH}/health`)), 'plans');
    this.handleRequest(this.http.get(apiUrl(`${SEGMENTS_API_PATH}/health`)), 'segments');
    this.handleRequest(this.http.get(apiUrl(`${CLIENTS_API_PATH}/health`)), 'clients');
    this.handleRequest(this.http.get(apiUrl("toolbox/health")), 'toolbox');
  }

  handleRequest(response, fieldType) {
    return response.subscribe(r => {
      this.setFieldStatus(fieldType, r ? r['data'] : this.ERROR_MESSAGE);
    }, error => {
      console.log(error);
      this.setFieldStatus(fieldType, this.ERROR_MESSAGE);
    });
  }

  setFieldStatus(fieldType, message) {
    if (fieldType === 'gateway') {
      this.gateway = message;
    } else if (fieldType === 'auth') {
      this.auth_service = message;
    } else if (fieldType === 'id') {
      this.id_service = message;
    } else if (fieldType === 'insights') {
      this.insights_service = message;
    } else if (fieldType === 'plans') {
      this.plans_service = message;
    } else if (fieldType === 'clients') {
      this.clients_service = message;
    } else if (fieldType === 'segments') {
      this.segments_service = message;
    } else if (fieldType === 'toolbox') {
      this.toolkit_service = message;
    } else {
      console.log('Invalid fieldType');
    }
  }
}
