import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { sortBy, values, map, get, filter, find } from 'lodash';
import * as moment from 'moment';

import { AppState } from 'app/reducers';
import { OutcomeTimeframe, selectTimeframeField, Timeframe,
  SubmarketOutcome, selectTimeframesInRange } from 'app/measure-v3/measure-v3.reducer';
import { StackedBarChartColors } from 'app/measure-v3/measure-v3.constants';
import { toMillions } from 'app/insights/insights.models'
import * as actions from 'app/measure-v3/measure-v3.actions';
import { SubMarket, Market, selectActiveMekkoSubMarkets } from 'app/mekko/mekko.reducer';

@UntilDestroy()
@Component({
  selector: 'ppc-measure-v3-stacked',
  templateUrl: './measure-v3-stacked.component.html',
  styleUrls: ['./measure-v3-stacked.component.sass'],
})
export class MeasureV3StackedComponent implements OnInit, OnDestroy {
  toMillions = toMillions;
  mekkoColors = StackedBarChartColors;
  topYAxisCount: number;
  selectedSubMarketId: number;
  subMarkets: SubMarket[];
  selectedMarket: Market;
  @Input() legendSubMarkets: SubMarket[];
  @Input() markets: Market[];
  @Input() groupBy: string;
  outcomeTimeframes: OutcomeTimeframe[];
  yLegend: string[];
  xLegend: string[];
  timeFrames: Timeframe[];
  timeframeField: string;
  currentTimeframe: OutcomeTimeframe;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store.select("measureV3", "selectedSubMarket").pipe(untilDestroyed(this), )
      .subscribe(selectedSubMarket => this.selectedSubMarketId = selectedSubMarket && selectedSubMarket.id)

    this.store.select("measureV3", "selectedMarket").pipe(untilDestroyed(this), )
      .subscribe(selectedMarket => this.selectedMarket = selectedMarket)

    this.store.select("measureV3", "currentTimeframe")
      .pipe(untilDestroyed(this))
      .subscribe(currentTimeframe => this.currentTimeframe = currentTimeframe)

    this.store.select("mekkos").pipe(
      select(selectActiveMekkoSubMarkets),
      untilDestroyed(this)
    ).subscribe(subMarkets => {
      const filteredSubMarkets = filter(subMarkets, subMarket => map(this.markets, "id").includes(subMarket.market_id));
      this.subMarkets = sortBy(filteredSubMarkets, subMarket => subMarket.name.toLowerCase());
    });

    observableCombineLatest(
      this.store.select("measureV3").pipe(select(selectTimeframeField)),
      this.store.select("measureV3").pipe(select(selectTimeframesInRange))
    ).pipe(untilDestroyed(this), )
      .subscribe(([tab, outcomeTimeframes]) => {
        const subMarketIds = map(this.subMarkets, 'id')

        this.timeframeField = tab;
        this.outcomeTimeframes = outcomeTimeframes

        let highestSubMarketsComboCount = 0
        this.timeFrames = this.outcomeTimeframes.slice(0, 13).map((ot) => {
          const subMarketsOutcomes = values(ot.submarket_outcomes).filter((smo) => subMarketIds.includes(smo.sub_market_id))

          const subMarketComboCount = subMarketsOutcomes.reduce((a, sm) => a + sm[this.timeframeField], 0)
          if (highestSubMarketsComboCount < subMarketComboCount) {
            highestSubMarketsComboCount = subMarketComboCount
          }

          return {
            date: moment(`${ot.year}-${ot.month + 1}-01`).format('MMM YYYY'),
            subMarketsOutcomes,
            subMarketComboCount
          }
        })

        this.generateYLegend(highestSubMarketsComboCount)
      });
  }

  ngOnDestroy() {}

  generateYLegend(highestSubMarketsComboCount: number) {
    let legend = []
    const adder = Math.round((highestSubMarketsComboCount * 1.2) / 12)
    if (adder < 3) {
      legend = [33, 30, 27, 24, 21, 18, 15, 12, 9, 6, 3, 0]
    } else {
      for (let i = 0; i < 12; i++) { legend = [adder * i, ...legend] }
    }
    this.topYAxisCount = legend[0]
    this.yLegend = legend
  }

  getColor(subMarket: SubMarket): string {
    return this.mekkoColors[this.subMarkets.indexOf(subMarket)]
  }

  tooltip(subMarketOutcome: SubmarketOutcome): string {
    const subMarket = this.subMarkets && this.subMarkets.find((subMarket) => subMarket.id === subMarketOutcome.sub_market_id);
    if (subMarket) {
      return `${subMarket.name} | ${subMarketOutcome[this.timeframeField].toLocaleString()}`;
    } else {
      return subMarketOutcome[this.timeframeField].toLocaleString();
    }
  }

  getBackgroundColor(subMarketOutcome: SubmarketOutcome) {
    return this.mekkoColors[this.legendSubMarkets.indexOf(this.legendSubMarkets.find((subMarket) => subMarket.id == subMarketOutcome.sub_market_id))];
  }

  sortedSubMarketOutcomes(subMarketOutcomes: SubmarketOutcome[]) {
    if (this.subMarkets) {
      return subMarketOutcomes.sort((a, b) => {
        const first = this.subMarkets.indexOf(this.subMarkets.find((sm) => sm.id == a.sub_market_id))
        const last = this.subMarkets.indexOf(this.subMarkets.find((sm) => sm.id == b.sub_market_id))
        return first - last;
      })
    }
  }

  notSelected(subMarketOutcome: SubmarketOutcome) {
    const subMarket = this.subMarkets.find((subMarket) => subMarket.id == subMarketOutcome.sub_market_id);
    return (this.selectedSubMarketId && this.selectedSubMarketId !== subMarket.id) ||
      (this.selectedMarket && this.selectedMarket.id !== subMarket.market_id);
  }

  toggleSelectedSubMarket(subMarketOutcome: SubmarketOutcome) {
    const subMarket = this.subMarkets.find((subMarket) => subMarket.id == subMarketOutcome.sub_market_id);
    this.store.dispatch(new actions.ToggleSelectedSubMarket(subMarket))
  }

  getSubMarketHeight(subMarketOutcome: SubmarketOutcome, timeframe: Timeframe): number {
    return subMarketOutcome[this.timeframeField] / timeframe.subMarketComboCount;
  }

  getMarketPopulation(market: Market): number {
    return get(find(this.currentTimeframe.market_outcomes, {market_id: market.id}), this.timeframeField, 0)
  }
}
