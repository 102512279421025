<div class="demographics-header">
    <ppc-index-base *ngIf="isIndexMode" [insightsContext]="insightsContext"></ppc-index-base>
    <ppc-percent-calculation-select></ppc-percent-calculation-select>
    <div
      class="index-option"
      [ppcTooltip]="personaHasExcludedSegmentsTooltip"
      [tooltipDisabled]="!(disableShowIndex$ | async)">
      <ppc-checkbox [(ngModel)]="isIndexMode" (change)="toggleIndexMode()" [disabled]="disableShowIndex$ | async">Show Indices</ppc-checkbox>
    </div>
</div>
  <div class="packery-container"
    [packery]="bagName"
    [packeryModel]="demographics"
    [forceLayout$]="forceLayout$"
    (orderChanged)="saveDemographicConfig($event)">
      <ppc-grow-v3-demographic-widget
        class="packery-item"
        *ngFor="let demographic of visibleDemographics; trackBy: trackByDemographicId"
        [demographic]="demographic"
        (hideDemographic)="hideDemographic(demographic)"
        [attr.data-id]="demographic.id"
        [segmentCounts]="getSegmentCounts(demographic)"
        ></ppc-grow-v3-demographic-widget>
  </div>
