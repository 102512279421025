
import { Component, Inject, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppState } from "app/reducers";
import {
  InsightsContextType,
  INSIGHTS_CONTEXT,
  PercentCalculationType,
} from "app/insights/insights.constants";
import * as insightsActions from "app/insights/insights.actions";

@Component({
  selector: 'ppc-percent-calculation-select',
  templateUrl: './percent-calculation-select.component.html',
  styleUrls: ['./percent-calculation-select.component.sass']
})
export class PercentCalculationSelectComponent implements OnInit {

  percentCalculationOptions = [
    { label: "Percent of Widget", id: PercentCalculationType.WIDGET },
    { label: "Percent of Selected", id: PercentCalculationType.INSIGHTS_CONTEXT }
  ];
  ngUnsubscribe = new Subject();
  selectedPercentCalculationType$ = new BehaviorSubject('');

  constructor(private store: Store<AppState>,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) { }

  ngOnInit(): void {
    this.store.select("insights", this.insightsContext, "percentCalculationType")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(percentCalculationtype => {
        this.selectedPercentCalculationType$.next(percentCalculationtype);
      });
  }

  percentCalculationTypeUpdate(value) {
    this.selectedPercentCalculationType$.next(value);
    this.store.dispatch(new insightsActions.SetPercentCalculationType(value, this.insightsContext))
  }

}
