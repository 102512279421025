import { Action } from "@ngrx/store";
import { OutcomeAudience } from "app/outcome-audience/outcome-audience.reducer";

export class FetchOutcomeAudiences implements Action {
  static readonly type = "OUTCOME_AUDIENCES:FETCH_OUTCOME_AUDIENCES";
  readonly type = FetchOutcomeAudiences.type;
}

export class LoadOutcomeAudiences implements Action {
  static readonly type = "OUTCOME_AUDIENCES:LOAD_OUTCOME_AUDIENCES";
  readonly type = LoadOutcomeAudiences.type;

  constructor(public outcomeAudiences: OutcomeAudience[]) {}
}

export class SetSelectedOutcomeAudience implements Action {
  static readonly type = "OUTCOME_AUDIENCES:SET_SELECTED_OUTCOME_AUDIENCE";
  readonly type = SetSelectedOutcomeAudience.type;

  constructor(public outcomeAudience: OutcomeAudience) {}
}

export type ReducerActions = FetchOutcomeAudiences | LoadOutcomeAudiences | SetSelectedOutcomeAudience
