<ppc-dialog #user [dismissOnBlur]="false" >
  <h3 *ngIf="toCreate()" header class="app-modal-header">Create User</h3>
  <h3 *ngIf="toEdit()" header class="app-modal-header">{{(toActivate$ | async) ? 'Activate' : 'Edit'}} User</h3>
  <div body class="layout vertical">
    <ppc-input
      *ngIf="toEdit()"
      disabled
      type="input"
      id="name"
      name="name"
      [ngModel]="targetUser.first_name + ' ' + targetUser.last_name"
    >
      <label>Full Name</label>
    </ppc-input>
    <ppc-input
      *ngIf="toCreate()"
      [(ngModel)]="userForm.first_name"
      type="input"
      (keypress)="shouldSubmit($event)"
      [validators]="nameValidator"
    >
      <label>First Name</label>
      <span suffix class="required-suffix" *ngIf="!isFirstNameValid()">*</span>
    </ppc-input>
    <ppc-input
      *ngIf="toCreate()"
      [(ngModel)]="userForm.last_name"
      type="input"
      (keypress)="shouldSubmit($event)"
      [validators]="nameValidator"
    >
      <label>Last Name</label>
      <span suffix class="required-suffix" *ngIf="!isLastNameValid()">*</span>
    </ppc-input>
    <ppc-input
      [disabled]="toEdit()"
      [(ngModel)]="userForm.email"
      type="email"
      #email
      (keypress)="shouldSubmit($event)"
      [validators]="emailValidator"
    >
      <label>Email</label>
      <span suffix class="required-suffix" *ngIf="!email.value">*</span>
    </ppc-input>
    <ppc-input
      [(ngModel)]="userForm.lion_login"
      (keypress)="shouldSubmit($event)"
      type="text"
      value=""
      [validators]="lionLoginValidator"
    >
      <label>Lion Login</label>
    </ppc-input>
    <ppc-input
      [(ngModel)]="userForm.okta_login"
      (keypress)="shouldSubmit($event)"
      type="text"
      value=""
      [validators]="oktaLoginValidator"
    >
      <label>Okta Login</label>
    </ppc-input>
    <div class="layout horizontal justified">
      <div class="multi-roles-container">
        <mat-form-field>
          <mat-select
            [(ngModel)]="userForm.role_ids"
            [ngModelOptions]="{trackBy: '$value.id'}"
            [placeholder]="'Select a role'"
            required
            multiple="true"
          >
            <mat-option *ngFor="let role of roles" [value]="role.id" >{{role.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <span suffix class="required-suffix" *ngIf="!userForm.role_ids.length">*</span>
        <p class="smaller" *ngIf="!userForm.role_ids.length">No role has been selected.</p>
        <p class="smaller" *ngIf="userForm.role_ids.length">
          Selected Roles: <span *ngFor="let role of selectedRoles(userForm.role_ids, roles); let l = last">{{role.name}}<span *ngIf="!l">, </span></span>
        </p>
      </div>
      <div>
        <mat-form-field>
          <mat-select
            [(ngModel)]="userForm.agency_id"
            [ngModelOptions]="{trackBy: '$value.id'}"
            [placeholder]="'Select an agency'"
            required
            #agencyId
          >
            <mat-option *ngFor="let agency of agencies" [value]="agency.id" >{{agency.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <ppc-input [(ngModel)]="userForm.product_ids" type="hidden" #productIds value=""></ppc-input>
  </div>
  <div footer>
    <button mat-button (click)="onCancelClick()">Cancel</button>
    <button
      *ngIf="toCreate()"
      mat-button
      class="action-btn-solid"
      (click)="createUser()"
      [disabled]="!isCreateUserFormValid"
      type="submit"
    >
      Create
    </button>
    <button
      *ngIf="toEdit()"
      mat-button
      class="action-btn-solid"
      (click)="updateUser()"
      [disabled]="!isFormChanged || !isEditUserFormValid"
      type="submit"
    >
      {{(toActivate$ | async) ? 'Activate' : 'Update'}}
    </button>
  </div>
</ppc-dialog>
