import {Action} from "@ngrx/store";
import { Snapshot } from "app/snapshots/snapshots.reducer";

export class SaveSnapshot implements Action {
  static readonly type = "SNAPSHOTS:SAVE_SNAPSHOT";
  readonly type = SaveSnapshot.type;

  constructor(public snapshot: Snapshot) {}
}

export class LoadSnapshot implements Action {
  static readonly type = "SNAPSHOTS:LOAD_SNAPSHOT";
  readonly type = LoadSnapshot.type

  constructor(public snapshot: Snapshot) {}
}

export class DestroySnapshot implements Action {
  static readonly type = "SNAPSHOTS:DESTROY_SNAPSHOT";
  readonly type = DestroySnapshot.type;

  constructor(public snapshot: Snapshot) {}
}

export class RemoveSnapshot implements Action {
  static readonly type = "SNAPSHOTS:REMOVE_SNAPSHOT";
  readonly type = RemoveSnapshot.type;

  constructor(public snapshot: Snapshot) {}
}

export class FetchSnapshots implements Action {
  static readonly type = "SNAPSHOTS:FETCH_SNAPSHOTS";
  readonly type = FetchSnapshots.type;

  constructor(public mekko_id: number) {}
}

export class LoadSnapshots implements Action {
  static readonly type = "SNAPSHOTS:LOAD_SNAPSHOTS";
  readonly type = LoadSnapshots.type;

  constructor(public snapshots: Snapshot[]) {}
}

export class SetActiveSnapshot implements Action {
  static readonly type = "SNAPSHOTS:SET_ACTIVE_SNAPSHOT";
  readonly type = SetActiveSnapshot.type;

  constructor(public snapshot: Snapshot) { }
}

export class FetchSnapshot implements Action {
  static readonly type = "SNAPSHOTS:FETCH_SNAPSHOT"
  readonly type = FetchSnapshot.type;

  constructor(public id: number) {}
}

export class SaveCurrentConfidence implements Action {
  static readonly type = "SNAPSHOTS:SAVE_CURRENT_CONFIDENCE";
  readonly type = SaveCurrentConfidence.type;

  constructor(public snapshotId: number, public subMarketId: number, public currentConfidence: Number) { }
}

export class ClearActiveSnapShot implements Action {
  static readonly type = "SNAPSHOTS:CLEAR_ACTIVE_SNAPSHOT"
  readonly type = ClearActiveSnapShot.type;

  constructor() {}
}

export class LoadCurrentConfidence implements Action {
  static readonly type = "SNAPSHOTS:LOAD_CURRENT_CONFIDENCE";
  readonly type = LoadCurrentConfidence.type;

  constructor(public snapshotId: number, public subMarketId: number, public currentConfidence: Number) { }
}


export type ReducerActions = FetchSnapshots | SaveSnapshot | LoadSnapshot | LoadSnapshots | DestroySnapshot
| SetActiveSnapshot | FetchSnapshot | LoadCurrentConfidence | ClearActiveSnapShot
| RemoveSnapshot
