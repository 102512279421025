<h2>Activation Management</h2>
<div class="filter">
  <div class="filter-option-wrapper">
    <div>Source System</div>
    <mat-select #sourceSystemDropdown [value]="'all'" class="filter-option">
      <mat-option value="all">All</mat-option>
      <mat-option
        *ngFor="let sourceSystem of sourceSystems$ | async"
        [value]="sourceSystem.name"
        >{{sourceSystem.name}}</mat-option
      >
    </mat-select>
  </div>
  <div class="filter-option-wrapper">
    <div>Company ID</div>
    <mat-select #companyIdDropdown [value]="'all'" class="filter-option">
      <mat-option value="all">All</mat-option>
      <mat-option
        *ngFor="let company of companies$ | async"
        [value]="company.id"
        >{{company.id}}</mat-option
      >
    </mat-select>
  </div>
  <div class="filter-option-wrapper">
    <div>State</div>
    <mat-select #stateDropdown [value]="'all'" class="filter-option">
      <mat-option value="all">All</mat-option>
      <mat-option
        *ngFor="let state of selectableStates"
        [value]="state"
        >{{state | titlecase}}</mat-option
      >
    </mat-select>
  </div>
  <div class="filter-option-wrapper search-wrapper">
    <input
      class="search filter-option"
      placeholder="Search for Audience Name"
      [(ngModel)]="searchTerm"
      (ngModelChange)="search($event)"
    />
  </div>
</div>
<ng-container *ngIf="pageInfo$ | async as pageInfo">
  <ngx-datatable
    class="data-table"
    [loadingIndicator]="loading$ | async"
    [rows]="activations$ | async"
    [headerHeight]="43"
    [rowHeight]="55"
    [footerHeight]="50"
    [reorderable]="false"
    [columnMode]="'flex'"
    [externalSorting]="true"
    [cssClasses]="{sortAscending: 'fa fa-sort-asc', sortDescending: 'fa fa-sort-desc', sortUnset: 'fa-solid fa-arrows-up-down'}"
    (sort)="onSort($event)"
    [externalPaging]="true"
    [count]="pageInfo.totalCount"
    [offset]="pageInfo.pageNumber"
    [limit]="pageInfo.limit"
    (page)="changePage($event, selectedPage$)"
  >
    <ngx-datatable-column
      name="Audience Name"
      prop="audience.name"
      cellClass="data-column name-column-cell"
      [resizeable]="false"
      [flexGrow]="4"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span [ppcTooltip]="value">{{ value }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Company ID"
      prop="audience.companyId"
      cellClass="data-column"
      [resizeable]="false"
      [flexGrow]="2"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Source System"
      prop="audience.sourceSystem"
      cellClass="data-column"
      [resizeable]="false"
      [flexGrow]="2"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Partner"
      prop="platformName"
      cellClass="data-column name-column-cell"
      [resizeable]="false"
      [flexGrow]="2"
    >
      <ng-template
        let-row="row.activationDestinations[0].destination.platform.name"
        ngx-datatable-cell-template
      >
        <span [ppcTooltip]="row">{{ row }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Destination"
      prop="destinationName"
      cellClass="data-column name-column-cell"
      [resizeable]="false"
      [flexGrow]="3"
    >
      <ng-template
        let-row="row.activationDestinations[0].destination.name"
        ngx-datatable-cell-template
      >
        <span [ppcTooltip]="row">{{ row }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="CAS ID"
      prop="audience.casAttributeId"
      cellClass="data-column"
      [resizeable]="false"
      [flexGrow]="2"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Date Requested"
      prop="createdAt"
      cellClass="data-column"
      [resizeable]="false"
      [flexGrow]="2"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{value | date: 'shortDate'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="State"
      prop="state"
      cellClass="data-column"
      [resizeable]="false"
      [flexGrow]="2"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span class="color" [style.background]="getStateColor(value)"></span>
        {{ getState(value) | titlecase}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [sortable]="false"
      cellClass="change-state-column"
      [resizeable]="false"
      [flexGrow]="2"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <app-confirmation
          [isButton]="true"
          [buttonClass]="'btn-complete'"
          [buttonLabel]="'Complete'"
          [modalTitle]="'Are you sure you want to change this activation to complete? This action cannot be undone.'"
          [buttonContinueLabel]="'Yes'"
          [kontinue]="updateActivationState.bind(this, row.id)"
          *ngIf="isPending(row.state)"
        >
        </app-confirmation>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</ng-container>
