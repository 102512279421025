<ppc-loading-overlay *ngIf="uploadAdminService.loading$ | async"></ppc-loading-overlay>
<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
  <h1><span class="object-name">Surveys Upload</span></h1>
</header>
<div class="upload-container">
  <div class="flex">
    <div ppcRadioGroup [(ngModel)]="surveyType" class="survey-type-radio-container">
      <div class="survey-type-radio">
        <span>Survey Type:</span>
        <ppc-radio-button value="region" ppcTooltip="Upload a survey that spans across all products and is not tied to a mekko audience or journey stage" (click)="surveyTypeChanged('region')">
          Region Level
        </ppc-radio-button>
        <ppc-radio-button value="audience" ppcTooltip="Upload survey tied to specific Grow chart audiences for each product" (click)="surveyTypeChanged('audience')">
          Audience Level
        </ppc-radio-button>
        <ppc-radio-button value="journeyAudience" ppcTooltip="Upload survey tied to specific journey stages for each product" (click)="surveyTypeChanged('journeyAudience')">
          Stage Level
        </ppc-radio-button>
      </div>
    </div>
    <div class="download flex-column">
      <h2>Step 1: Download Template</h2>
      Download a template with all of your current {{ isJourneySurvey ? 'stage' : 'audience' }} survey data.
      <div>
        <button (click)="downloadTemplate()" class="btn">Download</button>
      </div>
    </div>
    <div class="edit flex-column">
      <h2>Step 2: Edit</h2>
      <div class="edit-instruction">
        Open template in Excel or other .xls compatible program. Edit the fields to add, modify, or delete {{ isJourneySurvey ? 'stage' : 'audience' }} survey data.
      </div>
    </div>
    <div class="upload flex-column">
      <h2>Step 3: Upload Edited File</h2>
      <div class="flex">
        <div class="half-col">
          <form #templateForm>
            <span>Upload your modified template.</span>
            <div class="file-upload-container">
              <label for="file-upload" class="btn">Choose File</label>
              <input type="file" name="template" id="file-upload" accept=".xls" (change)="uploadTemplate($event, templateForm)"/>
              <span *ngIf="fileName" class="file-name">{{fileName}}</span>
            </div>
          </form>
          <div *ngIf="errors.length" class="err-col">
            <div class="errors-found-container">
              <div class="exclamation-container"><i class="fa fa-exclamation-circle"></i></div>
              <div class="errors-found">Errors found in Uploaded Template</div>
              <div class="caret-container" (click)="errorListCollapsed = !errorListCollapsed"><i class="fa fa-caret-down"></i></div>
            </div>
            <table *ngIf="!errorListCollapsed" class="template">
              <tr>
                <th>Column</th><th>Row</th><th>Error</th>
              </tr>
              <tr *ngFor="let error of errors">
                <td>{{error.column || '-'}}</td>
                <td>{{error.row || '-'}}</td>
                <td [innerHTML]="getSanitizedValue(error.message)"></td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="isRegionSurvey && !!updatedSurveys?.length" class="table-col">
          <div class="updated-markets-container">
            <div class="check-container"><i class="fa fa-check"></i></div>
            <div class="updated-markets">Updated Survey</div>
            <div class="caret-container" (click)="updatesListCollapsed = !updatesListCollapsed"><i class="fa fa-caret-down"></i></div>
          </div>
          <table *ngIf="!updatesListCollapsed">
            <tr>
              <th>Survey Name</th>
            </tr>
            <tr *ngFor="let survey of updatedSurveys">
              <td>{{survey.name}}</td>
            </tr>
          </table>
        </div>
        <div *ngIf="isAudienceSurvey && !!updatedSurveys?.length" class="table-col">
          <div class="updated-markets-container">
            <div class="check-container"><i class="fa fa-check"></i></div>
            <div class="updated-markets">Updated Markets</div>
            <div class="caret-container" (click)="updatesListCollapsed = !updatesListCollapsed"><i class="fa fa-caret-down"></i></div>
          </div>
          <table *ngIf="!updatesListCollapsed">
            <tr>
              <th>Chart Name</th>
              <th>Market</th>
              <th>Audience</th>
              <th>Survey Name</th>
            </tr>
            <tr *ngFor="let market of updatedSurveys">
              <td>{{market.mekko_name}}</td>
              <td>
                <ul>
                  <li>{{market.name}}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li *ngFor="let sub_market of market.sub_markets">{{sub_market.name}}</li>
                </ul>
              </td>
              <td>
                <ul *ngFor="let sub_market of market.sub_markets">
                  <li *ngFor="let survey of sub_market.surveys">{{survey.name}}</li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="isJourneySurvey && !!updatedSurveys?.length" class="table-col">
          <div class="updated-markets-container">
            <div class="check-container"><i class="fa fa-check"></i></div>
            <div class="updated-markets">Updated Journeys</div>
            <div class="caret-container" (click)="updatesListCollapsed = !updatesListCollapsed"><i class="fa fa-caret-down"></i></div>
          </div>
          <table *ngIf="!updatesListCollapsed">
            <tr>
              <th>Journey</th>
              <th>Brand</th>
              <th>Stage</th>
              <th>Survey Name</th>
            </tr>
            <tr *ngFor="let brand of updatedSurveys">
              <td>{{brand.journey_name}}</td>
              <td>
                <ul>
                  <li>{{brand.name}}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li *ngFor="let sub_market of brand.sub_markets">{{sub_market.stage_name}}</li>
                </ul>
              </td>
              <td>
                <ul *ngFor="let sub_market of brand.sub_markets">
                  <li *ngFor="let survey of sub_market.surveys">{{survey.name}}</li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="rules-list">
    <h3>Rules for Bulk Survey Uploader</h3>
    <ul>
      <li *ngFor="let rule of isRegionSurvey ? regionSurveyRules : isAudienceSurvey ? audienceSurveyRules : journeySurveyRules">{{rule}}</li>
    </ul>
  </div>
</div>
