import { Directive, Input, ContentChildren, QueryList, AfterViewInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject ,  Subscription, Observable, BehaviorSubject } from 'rxjs';

import { RadioButtonComponent } from './radio-button.component';

@Directive({
  selector: '[ppcRadioGroup]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupDirective),
      multi: true
    }
  ]
})
export class RadioGroupDirective implements AfterViewInit, ControlValueAccessor {
  @ContentChildren(RadioButtonComponent, {descendants: true}) radioButtons: QueryList<RadioButtonComponent>;

  value$: ReplaySubject<any> = new ReplaySubject(1);
  value: any;
  subscriptions: Map<RadioButtonComponent, Subscription> = new Map();
  disabled$ = new BehaviorSubject(false);

  constructor() { }

  ngAfterViewInit() {
    this.radioButtons.changes.subscribe((changes) => {
      this.radioButtons.forEach(radioButtonComponent => {
        if (!this.subscriptions.get(radioButtonComponent)) {
          this.registerRadioButton(radioButtonComponent);
        }
      })
    });
    this.radioButtons.forEach(this.registerRadioButton.bind(this));
  }

  registerRadioButton(radioButtonComponent: RadioButtonComponent) {
    radioButtonComponent.registerWithRadioGroup(this.value$, () => {
      this.subscriptions.get(radioButtonComponent).unsubscribe();
      this.subscriptions.delete(radioButtonComponent);
    }, this.disabled$);
    this.subscriptions.set(radioButtonComponent, radioButtonComponent.selected.subscribe(value => {
      this.writeValue(value);
    }))
  }

  // ControlValueAccessor Implementation

  onChange = event => {};
  onTouched = event => {};

  writeValue(value: any) {
    this.value = value;
    this.onChange(value);
    this.value$.next(value);
  }

  registerOnChange(onChange) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled$.next(disabled);
  }

  // end ControlValueAccessor implementation

}
