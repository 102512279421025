import { Component, OnInit, OnDestroy } from '@angular/core';
import {sortBy} from "../../shared/utils/utils";
import {HttpClient} from "@angular/common/http";
import {IndexReportTask} from "../../report-tasks/report-tasks.model";
import ColorUtil from "../../shared/utils/color-util";
import * as d3 from 'd3';
import 'd3-selection-multi';
import {
  adminAllESCountsUrl,
  adminAllIndexReportsUrl,
  adminIndexReportsUrl
} from '../../shared/constants/id_analytics.urls';
import { clientsAdminUrl } from '../../shared/constants/clients.urls';

@Component({
  selector: 'app-admin-common',
  templateUrl: './admin-common.component.html',
  styleUrls: ['./admin-common.component.sass']
})
export class AdminCommonComponent implements OnInit, OnDestroy {

  // These flags are only useful while development.
  showPendingIndexReports = true;
  showAllIndexReports = true;
  // We don't want to show clients yet.
  showAllClients = false;
  showESCounts = true;

  // These flags are used to show/hide section(s)
  pendingIndexReportPanelOpenState = false;
  allIndexReportPanelOpenState = false;
  detailIndexReportPanelOpenState = false;
  esCountPanelOpenState = false;

  // These flags are used to track columns while sorting
  pendingIndexReportSortedBy: string = 'time_diff';
  allIndexReportSortedBy: string = 'env';
  clientSortedBy: string = 'name';

  clients = [];
  tasks: IndexReportTask[];
  allTasks: IndexReportTask[];
  filteredTasks: IndexReportTask[];
  esCounts: [];
  missingEnvs: [];

  uniqEnvs = {};
  uniqUsers = {};
  uniqTypes = {};
  internalUsers = ['jerry.kobeszko@publicismedia.com', 'chhuong.yav@publicismedia.com',
    'paresh.morker@publicisgroupe.com', 'paresh.morker@publicismedia.com', 'jerrykobes@gmail.com',
    'emily.imbo@publicismedia.com', 'kelley.maves@publicisgroupe.com', 'nelson.kendall@publicismedia.com',
    'lincoln.adams@publicismedia.com', 'mark.sugg@publicisgroupe.com', 'joseph.turner@publicisgroupe.com'];

  finalPosition = 10;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    if (this.showPendingIndexReports) {
      this.http.get(adminIndexReportsUrl())
        .subscribe(
          (resp) => {
            const tasks = resp['data']
            this.missingEnvs = resp['error_envs'];
            this.tasks = sortBy(tasks, this.pendingIndexReportSortedBy, false);
          },
          error => console.log('error fetching pending index reports', error)
        );
    }

    if (this.showAllIndexReports) {
      this.http.get(adminAllIndexReportsUrl())
        .subscribe(
          (resp) => {
            const tasks = resp['data']
            this.allTasks = sortBy(tasks, this.allIndexReportSortedBy, false);


            this.uniqEnvs = this.allTasks
              .map(({env, created_by}) => [env, created_by])
              .reduce((envs, [env, created_by]) => {
                if (!envs[env]) {
                  envs[env] = {'total': 0, 'external': 0};
                }
                const count = envs[env]['total'] || 0;
                const externalCount = envs[env]['external'] || 0;
                envs[env]['total'] = count + 1;

                if (!this.internalUsers.includes(created_by)) {
                  envs[env]['external'] = externalCount + 1;
                }
                return envs;
              }, {});

            this.filteredTasks = this.allTasks.filter((item) => !this.internalUsers.includes(item.created_by));

            this.uniqUsers = this.filteredTasks
              .map(({env, created_by}) => [env, created_by])
              .reduce((envs, [env, created_by]) => {
                if (!envs[created_by]) {
                  envs[created_by] = {'total': 0, 'ens': new Set()};
                }
                const count = envs[created_by]['total'] || 0;
                envs[created_by]['total'] = count + 1;
                envs[created_by]['ens'].add(env);

                return envs;
              }, {});

            this.uniqTypes = this.filteredTasks
              .map(({resource_type}) => resource_type)
              .reduce((envs, resource_type) => {
                if (!envs[resource_type]) {
                  envs[resource_type] = {'total': 0};
                }
                const count = envs[resource_type]['total'] || 0;
                envs[resource_type]['total'] = count + 1;

                return envs;
              }, {});
          },
          error => console.log('error fetching pending index reports', error)
        );
    }

    if (this.showAllClients) {
      this.http.get(clientsAdminUrl())
        .subscribe(
          (resp) => {
            const clients = resp['data']
            this.clients = sortBy(clients, this.clientSortedBy, false);
          },
          error => console.log('error fetching pending clients', error)
        );
    }

    if (this.showESCounts) {
      this.http.get(adminAllESCountsUrl())
        .subscribe(
          (resp) => {
            this.esCounts = resp['data'];
            const envKeys = Object.keys(resp['data'])
            const maxValue = Math.max.apply(Math, Object.keys(this.esCounts).map(key => this.esCounts[key]));

            envKeys.map((x, i) => {
              this.drawBubble(this.esCounts[x], i, maxValue, x);
            })
          },
          error => console.log('error fetching es counts', error)
        );
    }

  }

  drawBubble(data, ind, maxValue, txt) {
    const ratio = (data / maxValue) * 100;
    const startingPoint = this.finalPosition + (ratio / 6);
    this.finalPosition = startingPoint + (ratio / 5) + 2;

    const svg = d3.select("#viz_area")
    const svgContainer = d3.scaleLinear()
      .domain([0, 100])         // This is the min and the max of the data: 0 to 100 if percentages
      .range([0, 800]);       // This is the corresponding value in Pixel

    svg.append("circle")
      .attr("cx", svgContainer(startingPoint)).attr("cy", 100).attr("r", ratio).style("fill", this.getColor(ind));

    svg.append("text")
      .attr("dx", svgContainer(startingPoint) - 5)
      .attr("dy", (ratio < 15 ? 120 : 102)) // if size is too small then let text be below circle
      .attr('fill', 'white')
      .text(txt);
  }

  ngOnDestroy() {
  }

  sortIndexReportTable(fieldName: string) {
    sortBy(this.filteredTasks, fieldName, fieldName !== this.allIndexReportSortedBy);
    this.allIndexReportSortedBy = fieldName !== this.allIndexReportSortedBy ? fieldName : '';
  }

  sortPendingIndexReportTable(fieldName: string) {
    sortBy(this.tasks, fieldName, fieldName !== this.pendingIndexReportSortedBy);
    this.pendingIndexReportSortedBy = fieldName !== this.pendingIndexReportSortedBy ? fieldName : '';
  }

  sortClientsTable(fieldName: string) {
    sortBy(this.clients, fieldName, fieldName !== this.clientSortedBy);
    this.clientSortedBy = fieldName !== this.clientSortedBy ? fieldName : '';
  }

  toCsv(setObject) {
    return Array.from(setObject).sort();
  }

  getColor(i) {
    return ColorUtil.getMediaTypeColor(i);
  }

}
