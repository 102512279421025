import {Component, Inject, Input, ViewChild, ContentChild, ContentChildren, TemplateRef, QueryList} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatExpansionModule, MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'ppc-accordion',
  templateUrl: './ppc-accordion.component.html',
  styleUrls: ['./ppc-accordion.component.sass']
})
export class PpcAccordionComponent {
  currentStep: number;
  @Input() initialStep;
  @Input() resetAccordionState;
  @Input() drawerContent;

  // This should provide a more repeatable way to a solution, but currently not certain how to implement
  // @ContentChildren('panel') statusTemplate: QueryList<TemplateRef<any>>;
  @ContentChild('statusTemplate') statusTemplate: TemplateRef<any>;
  @ContentChild('permissionsTemplate') permissionsTemplate: TemplateRef<any>;
  @ViewChild('matAccordion', { static: true }) accordion: MatAccordion;

  constructor() { }

  ngOnInit() {
    this.currentStep = this.initialStep;
  }

  ngOnChanges(changes) {
    if (changes.resetAccordionState) {
      this.currentStep = this.initialStep
    }
  }

  next(index) {
    this.currentStep = this.currentStep + 1;
  }

  previous(index) {
    this.currentStep = this.currentStep - 1;
  }

  setStep(index: number) {
    this.currentStep = index;
  }

}
