import { Component, OnDestroy } from '@angular/core';
import { Observable } from "rxjs";
import { filter, map, take } from 'rxjs/operators';
import { Actions } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { values, get, first } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppState } from 'app/reducers';
import { fetchOutcome } from "app/shared/utils/fetch-state";
import * as actions from 'app/insights/insights-components/market-level-discussions/discussion.actions';
import * as moment from 'moment';
import { DiscussionClusterNode } from 'app/insights/insights-components/market-level-discussions/discussion-cluster-nodes.interface';
import { LocalStorageService } from 'app/services/local-storage.service';
import { selectClient } from "app/hierarchy/hierarchy.reducers";

const TABLE_SORTS_KEY = "grow-discussion-library-table-sorts";

@UntilDestroy()
@Component({
  selector: 'ppc-discussion-cluster-node-library',
  templateUrl: './discussion-cluster-node-library.component.html',
  styleUrls: ['./discussion-cluster-node-library.component.sass']
})

export class DiscussionClusterNodeLibraryComponent implements OnDestroy {
  clusterNodes$: Observable<{[clusterNodeId: number]: DiscussionClusterNode}>;
  tableSorts = this.localStorage.getValue(TABLE_SORTS_KEY) || [{prop: 'file_uploaded_at', dir: 'desc'}];

  constructor(public store: Store<AppState>, private localStorage: LocalStorageService, private actions$: Actions) {
    this.store.select('hierarchy').pipe(
      select(selectClient), filter(Boolean), untilDestroyed(this)
    ).subscribe(client => this.fetchClusterNodes());
  }

  ngOnDestroy() {}

  tableSortChanged(event) {
    this.localStorage.setValue(TABLE_SORTS_KEY, event.sorts);
  }

  fetchClusterNodes() {
    this.store.dispatch(new actions.FetchClusterNodes());
    this.actions$.pipe(fetchOutcome(actions.FetchClusterNodes.type), take(1)).subscribe(
      success => { this.clusterNodes$ = this.store.select("discussions", "clusterNodes").pipe(map(values)); }
    )
  }

  editClusterNode(clusterNode) {
    this.store.dispatch(new actions.EditDiscussionClusterNode(clusterNode));
  }

  dateComparator(date1: string, date2: string) {
    const date1Moment = moment(date1).startOf("day").valueOf() || Number.NEGATIVE_INFINITY;
    const date2Moment = moment(date2).startOf("day").valueOf() || Number.NEGATIVE_INFINITY;
    return date1Moment - date2Moment;
  }
}
