<div class="journey-stage-mini-panel">
  <div class="journey-stage-mini-panel-caret"></div>
  <div class="journey-stage-mini-panel-chart">
    <div class="journey-stage-mini-panel-brand" *ngFor="let brand of brands; trackBy: trackByIndex" ppcTooltip [tooltipTemplate]="tooltipTemplate">
      <div class="brand-stage-percentage">{{getPercentageForBrand(brand) | number:"1.0-0"}}%</div>
      <div class="brand-stage-bar" [style.height.px]="getHeightForBrand(brand)" [style.background]="getColorForBrand(brand)"></div>
      <ng-template #tooltipTemplate>
        <div class="brand-tooltip">
            <div class="brand-tooltip-name">{{brand.name}}</div>
            <div class="brand-tooltip-id-count"><i class="fa fa-user"></i> {{getCountStringForBrand(brand)}}</div>
            <div class="brand-tooltip-percentage">{{getPercentageForBrand(brand) | number:"1.2-2"}}%</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
