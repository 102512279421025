import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JourneyComponent } from './journey.component';
import { InsightsModule } from 'app/insights/insights.module';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { NgLetModule } from 'ng-let';
import { StoreModule } from '@ngrx/store';
import { reducer } from './journey.reducer';
import { JourneyService } from './journey.service';
import { EffectsModule } from '@ngrx/effects';
import { JourneyBuilderComponent } from './journey-builder/journey-builder.component';
import { JourneySubMarketFormComponent } from './journey-builder/journey-sub-market-form/journey-sub-market-form.component';
import { JourneyChartComponent } from './journey-chart/journey-chart.component';
import { JourneyFillGaugeComponent } from './journey-chart/journey-fill-gauge/journey-fill-gauge.component';
import { JourneyTransitionInfoComponent } from './journey-chart/journey-transition-info/journey-transition-info.component';
import { JourneyStageComponent } from './journey-chart/journey-stage/journey-stage.component';
import { JourneyStageMiniPanelComponent } from './journey-chart/journey-stage/journey-stage-mini-panel/journey-stage-mini-panel.component';

@NgModule({
  declarations: [
    JourneyComponent,
    JourneyBuilderComponent,
    JourneySubMarketFormComponent,
    JourneyChartComponent,
    JourneyFillGaugeComponent,
    JourneyTransitionInfoComponent,
    JourneyStageComponent,
    JourneyStageMiniPanelComponent
  ],
  imports: [
    CommonModule,
    InsightsModule,
    PpcComponentsModule,
    NgLetModule,
    StoreModule.forFeature("journey", reducer),
    EffectsModule.forFeature([
      JourneyService
    ]),
  ]
})
export class JourneyModule { }
