import * as actions from 'app/outcomes-v1/outcomes-v1.actions';
import { OutcomeV1 } from 'app/outcomes-v1/outcomes-v1.model';
import { createSelector } from '@ngrx/store';
import { findIndex, indexOf } from 'lodash';

export interface State {
  outcomesV1: OutcomeV1[];
}

const defaultState = {
  outcomesV1: []
}

export function reducer(state: State = defaultState, action: actions.All): State {
  switch (action.type) {
    case actions.LoadOutcomes.type:
      return {
        ...state,
        outcomesV1: action.outcomes,
      }
    case actions.ClearOutcomes.type:
      return {
        ...state,
        outcomesV1: []
      };
    case actions.LoadUpdatedOutcome.type:
      const indexOfItem = findIndex(state.outcomesV1, {identifier: action.outcome.identifier})
      return { ...state,
        outcomesV1: [...state.outcomesV1.slice(0, indexOfItem), action.outcome, ...state.outcomesV1.slice(indexOfItem + 1)]
      }
    default:
      return state;
  }
}

export const selectOutcomesWithType = createSelector(
  (state: State) => state.outcomesV1,
  (outcomes) => outcomes.map(outcome => ({...outcome, type: "outcome"}))
)
