<div class="instructions">Explore online browsing data provided by ShareThis. Results are based off a time period of one week.</div>

<ppc-tabs *ngIf="isActiveTab$ | async"  (tabChange)="setShareThisTab($event)">
  <ng-container *ngFor="let tab of tab.children">
    <ng-template #labelTemplate>
      <div class="label-template">
        <img class="white-icon" [src]="iconFolder + tabIcons[tab.tab_key] + '.png'">
        <img class="grey-icon" [src]="iconFolder + tabIcons[tab.tab_key] + '_gr.png'">
        <div class="tab-name">{{tab.name}}</div>
      </div>
    </ng-template>
    <ppc-tab [labelTemplate]="labelTemplate" [name]="tab.tab_key">
      <ndc-dynamic [ndcDynamicComponent]="componentConfig[tab.tab_key]" *ngIf="tab.tab_key == currentTabKey"></ndc-dynamic>
    </ppc-tab>
  </ng-container>
</ppc-tabs>
