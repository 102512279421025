import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, OnDestroy } from '@angular/core';
import {Brand} from '../brand.model';
import {Product} from './product.model';
import {ProductService} from './product.service';
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers";
import {HierarchyClient} from "app/hierarchy/hierarchy.interface";
import { Region } from 'app/admin/region/region.model';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { getRegions } from 'app/admin/region/region.reducers';
import { filter } from 'lodash';
import { CONVERSANT_COMPANY_ID_MAX, CONVERSANT_COMPANY_ID_MIN, ERROR_MSG_CONVERSANT_COMPANY_ID_INVALID } from 'app/shared/utils/constants';

const DELETE_PRODUCT_FAIL_MSG = "We were unable to delete that product. Please try again.";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.sass']
})
export class ProductComponent implements OnInit, OnChanges, OnDestroy {

  @Input() client: HierarchyClient;
  @Input() brand: Brand;
  @Input() product: Product;
  @Input() validators: ProductValidators;
  @Input() brandProductsNameUpdated$: Subject<undefined>;
  @Output() deleteProduct: EventEmitter<Product> = new EventEmitter();
  @Output() productNameUpdate: EventEmitter<string> = new EventEmitter();
  regionOptions: Region[] = [];
  responseMessage: string;
  errorMessages = new Set<string>();
  ngUnsubscribe = new Subject();
  productRegions: Region[] = [];
  isProductNameDuplicate: boolean = false;

  readonly CONVERSANT_COMPANY_ID_MIN = CONVERSANT_COMPANY_ID_MIN;
  readonly CONVERSANT_COMPANY_ID_MAX = CONVERSANT_COMPANY_ID_MAX;
  readonly ERROR_MSG_CONVERSANT_COMPANY_ID_INVALID = ERROR_MSG_CONVERSANT_COMPANY_ID_INVALID;

  constructor(
    private productService: ProductService,
    private store: Store<AppState>
  ) {
    this.store.select('regions').pipe(
      map(getRegions),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((regions) => {
      this.regionOptions = regions;
      if (this.productRegions && this.productRegions.length) {
        this.productRegions = filter(this.regionOptions, (r) => this.product.region_ids.includes(+r.id));
      }
    });

    this.destroyProduct = this.destroyProduct.bind(this);
  }

  ngOnInit(): void {

    this.brandProductsNameUpdated$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.isProductNameDuplicate = this.product.name
          && this.validators.isProductNameAlreadyTaken(this.product.name);
      });
  }

  ngOnChanges(): void {
    this.productRegions = filter(this.regionOptions, (r) => this.product.region_ids.includes(+r.id));
  }

  editProduct(): void {
    this.product.open();
  }

  clearResponseMessage() {
    setTimeout(() => this.responseMessage = '', 5000);
  }

  destroyProduct(event): void {
    this.deleteProduct.emit(this.product);
    event.stopPropagation();

    if (!!this.product.id) {
      this.productService.deleteProduct(this.product.id)
        .subscribe(
          () => this.errorMessages.delete(DELETE_PRODUCT_FAIL_MSG),
          () => this.errorMessages.add(DELETE_PRODUCT_FAIL_MSG)
        )
    }
  }

  onUpdate(): void {
    this.product.region_ids = this.productRegions.reduce((acc, curr) => [...acc, curr.id], []);
  }

  onConversantIdUpdate(event: any) {
    // This prevents the clearing the input due to strange behavior with
    // input type="number". We don't currently support exponential notation anyway
    if (event.srcElement.type === "number" && event.code === "KeyE") {
      event.preventDefault();
    }

    // Prevent user from inputting minus signs (charCode == 45) if input type is
    // number and min (if defined) is a positive or zero value
    if (event.srcElement.type === "number" && event.charCode === 45 && event.srcElement.min >= 0) {
      event.preventDefault();
    }
  }

  onNameChange(event: any) {
    this.productNameUpdate.emit(this.product.name);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

/**
 * For injecting validators which needs to run on a higher-order
 */
export interface ProductValidators {
  isProductNameAlreadyTaken: Function;
}
