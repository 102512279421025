import {combineLatest, Subject, BehaviorSubject} from 'rxjs';
import {map, startWith, debounceTime, filter} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, Input} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers/index";
import {AuthPermission} from 'app/shared/interfaces/auth-permission';
import {HierarchyService} from "app/services/hierarchy.service";
import {MenuItem} from '../shared/admin-action-menu/menu-item.interface';
import {activeContext} from "app/hierarchy/hierarchy.reducers";
import {HierarchyContext, ContextSlugs} from "app/hierarchy/hierarchy.interface";
import {extendContext, getNavPaths, getContext} from "app/hierarchy/hierarchy.utils";
import {currentUser} from 'app/users/user.reducer'
import {generateAdminDashboardTileData} from 'app/admin/tiled-dashboard/admin-dashboard'
import { chain, sortBy } from 'lodash';

@Component({
  selector: 'app-tiled-dashboard',
  templateUrl: './tiled-dashboard.component.html',
  styleUrls: ['./tiled-dashboard.component.sass']
})
export class TiledDashboardComponent {
  searchInput$ = new BehaviorSubject<string>('');

  navPaths$ = combineLatest(
    activeContext(this.store),
    this.hierarchyService.savedContextSlugs$,
    this.hierarchyService.defaultContextSlugs$,
    this.hierarchyService.loadedHierarchy$
  ).pipe(
    map(([active, saved, defaultContext, hierarchy]) =>
      getNavPaths([extendContext(active),
        extendContext(getContext(hierarchy, saved)),
        extendContext(getContext(hierarchy, defaultContext))]))
  )

  allMenuItems$ = combineLatest(
    this.store.select("permissions"),
    currentUser(this.store).pipe(map(user => user.is_admin)),
    this.navPaths$,
  ).pipe(
    map(([ perms, isAdmin, navPaths ]) => generateAdminDashboardTileData(perms, navPaths, isAdmin))
  )

  activeMenuItems$ = combineLatest(
    this.allMenuItems$,
    this.searchInput$.pipe(debounceTime(100), map(x => x.toLowerCase()))
  ).pipe(
    map(([allMenuItems, searchQuery]) =>
      chain(allMenuItems)
        .filter('enabled')
        .filter(item =>
          !searchQuery ||
          item.title.toLowerCase().includes(searchQuery) ||
          item.summary.toLowerCase().includes(searchQuery)
        )
        .value()
    )
  )

  adminCategories$ = this.activeMenuItems$.pipe(
    map(items => chain(items)
      .groupBy('category')
      .map((v, k) => ({ name: k, items: sortBy(v, 'title') }))
      .filter('items.length')
      .value()
    )
  )

  constructor(
    private store: Store<AppState>,
    private hierarchyService: HierarchyService
  ) { }

  searchUpdate(input) {
    this.searchInput$.next(input);
  }

}
