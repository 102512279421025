import { SegmentCounts } from 'app/audiences/discover/segment-v2.model';
import { AudienceRule } from 'app/audiences-v2/audience-v2.model';
import { keys } from 'lodash';

export interface LookalikeV2 {
  id?: string;
  id_space?: string;
  identifier?: string;
  name: string;
  description: string;
  expiration?: string;
  created_at?: string;
  updated_at?: string;
  seed_identifier: string;
  seed_rules: {
    include: AudienceRule;
    exclude: AudienceRule;
  };
  seed_name: string;
  seed_counts?: SegmentCounts;
  job_status: string;
  include_seed_in_model: boolean;
  current_confidence?: number;
  current_confidence_pid_count?: number;
  confidence_sizes?: {[confidence_size: number]: {[field: string]: number}};
  universe_permissions?: string[];
  send_to_tardiis?: string;
  count?: SegmentCounts;
  count_status?: string;
  type?: string;
}

export function getLookalikeCount(lal: LookalikeV2, field: string):  number {
  return lal.count[field] && lal.count[field].global;
}

export function getLookalikeCurrentPeople(lal: LookalikeV2): number {
  return lal.confidence_sizes[lal.current_confidence].people;
}

export function convertConfidenceSizesToArray(lookalike: LookalikeV2): {confidence: number, reach: number}[] {
  return keys(lookalike.confidence_sizes).map(key => ({
    reach: lookalike.confidence_sizes[key]["people"],
    confidence: parseInt(key),
  })
  );
}
