import { Channel } from './channel.model'
import * as moment from 'moment'
import {Moment} from "moment";
import {MediaTypeAllocation} from "../scenario-dialog/media-type-table/media-type-goal";
import {entries} from "app/shared/utils/utils";

export class Plan {
  _id: string;
  advertiserName: string;
  planName: string;
  planYear: string;
  startDate: Moment;
  endDate: Moment;
  numChannels: number;
  allocation: number;
  sales: number;
  roi: number;
  mediaTypes: MediaTypes;
  mediaTypeUnit: "Percentage" | "Currency";
  currency: string;
  goal: number;
  projectId: string;

  status: string;
  errorMessage: string;
  // TODO: plan should have 1 to 1 relationship with channels.  total budget needs to be updated to reflect this as well (now it's just stored)
  channels: Channel[];
  channelId: string;
  curveType: number;
  curveTypeName: string;
  goalType: string;
  aggregatedReach: number;
  frequency: number;
  universe: number;
  totalBudget: number;

  imported: boolean;

  constructor(data: any) {
    data.allocation = data.allocation || 0;
    this.totalBudget = data.total_budget;
    this._id = data.id;
    this.advertiserName = data.advertiser_name || (data.advertiser && data.advertiser.name);
    this.planName = data.plan_name || "";
    this.planYear = data.plan_year;
    this.startDate = moment.utc(data.from_date);
    this.endDate = moment.utc(data.to_date);
    this.allocation = Math.round(data.allocation / 100000) / 10;
    this.channels = (data.media_type_allocations || data.channels || [])
      .map(function(channel) {
        return new Channel({universe: data.universe, ...channel});
      });
    this.sales = data.aggregated_sales;
    const roi = this.sales / this.totalBudget;
    this.roi = Math.round(roi * 100) / 100;
    this.numChannels = this.channels.length;
    this.currency = data.currency;
    this.mediaTypeUnit = data.media_type_unit;
    this.mediaTypes = data.media_types;
    this.status = data.status;
    this.errorMessage = data.error_message;
    this.channelId = data.channel_id;
    this.curveType = data.curve_type;
    this.curveTypeName = data.curve_type_name || "";
    this.goalType = data.goal_type;
    this.aggregatedReach = data.aggregated_reach;
    this.frequency = data.frequency;
    this.goal = data.goal;
    this.imported = data.imported;
    this.universe = data.universe;

    // TODO: Delete this once reach and penetration are separate curve types PAUD-1009
    if (data.override) {
      this.goalType = data.goal_type.replace('Reach', 'Penetration');
      this.curveTypeName = data.curve_type_name.replace('Reach', 'Penetration');
    }
  }

  getStartDateString(): string {
    return this.startDate.endOf('day').format("Y-MM-DD")
  }

  getEndDateString(): string {
    return this.endDate.endOf('day').format("Y-MM-DD")
  }

  get allocatedBudget(): number {
    return this.channels
      .reduce(function(acc, channel) {
        return acc + channel.budget;
      }, 0);
  }

  get hasError(): boolean {
    return this.status === "Error" || this.status === "Failed";
  }

  get isPending(): boolean {
    return !this.isComplete && !this.hasError;
  }

  get isComplete(): boolean {
    return this.status === "Complete";
  }

  toJson(): SerializedPlan {
    return {
      _id: this._id,
      status: "Pending",
      name: this.planName,
      from_date: this.startDate.format('ddd MMM DD Y'),
      to_date: this.endDate.format('ddd MMM DD Y'),
      currency: this.currency,
      media_type_unit: this.mediaTypeUnit,
      media_types: this.mediaTypes,
      curve_type: this.curveType,
      curve_type_name: this.curveTypeName,
      goal: this.goal,
      goal_type: this.goalType,
      universe: this.universe
    }
  }

  findCurveTypeName(curveTypeMap: CurveTypeMap): string {
    const curveTypeName =  entries(curveTypeMap).find(ctn => ctn[1] == this.curveType) || [null, null];
    return curveTypeName[0];
  }

}
export interface MediaTypes {[mediaType: string]: MediaTypeAllocation}
export interface CurveTypeMap {[curveType: string]: number}

export interface SerializedPlan {
  _id: string;
  status: string;
  name: string;
  from_date: string;
  to_date: string;
  currency: string;
  media_type_unit: string;
  media_types: MediaTypes;
  curve_type: number;
  curve_type_name: string;
  goal: number;
  goal_type: string;
  universe: number;
}

export const GENERIC_MEDIA_TYPES_NAMES = [
  "TV",
  "Social",
  "Out of Home",
  "Print",
  "Radio",
  "Mobile Display",
  "Online Display",
  "Partnership",
  "Cinema",
  "Digital Commerce",
  "Video Seeding",
  "VOD",
  "Search"
];
