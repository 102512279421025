// The inject-env-vars.js script that runs during prebuild:metagraph will interpolate these values
// based on environment variables and throw an error if any aren't present
export const environment = {
  production: true,
  envName: "us-ms-dev",
  apiUrl: "https://us-ms-dev-api.discovery.peoplecloud.epsilon.com",
  showLionLogin: true,
  viewAs: true,
  isTier3: false,
};
