<div class="journey-stage" [class.dim]="shouldDim$ | async">
    <div class="journey-stage-container">
        <ppc-journey-fill-gauge  [id]="stage.id" [value]="value" [color]="color" [personCount]="personCount" (click)="toggleStage()"></ppc-journey-fill-gauge>
        <ppc-journey-transition-info  *ngIf="transitionElements"
                                     [host]="transitionElements[0]"
                                     [target]="transitionElements[1]"
                                     [color]="color"
                                     [horizontal]="true"
                                     [hostStage]="stage"
                                     [targetStage]="nextStage"
                                     [(expanded)]="transitionInfoExpanded"
                                     [class.dim]="shouldDimArrow$ | async"></ppc-journey-transition-info>
    </div>
    <div class="journey-stage-icon">
      <ppc-icon [value]="stage.icon"></ppc-icon>
    </div>
    <div class="journey-stage-label"
      (click)="showMiniPanel = !showMiniPanel"
      >
      <div class="journey-stage-compare-bubble" *ngIf="getCompareColor(stage)" [style.background-color]="getCompareColor(stage)"></div>
      <div
        ppcTooltip
        [tooltipTemplate]="journeyInfo"
        >
        <ppc-zero-count-warning-icon
          *ngIf="hasZeroCount()"
          ppcTooltip="Click to learn why this stage has a zero count.">
        </ppc-zero-count-warning-icon>
        {{truncate(stage.name)}}
      </div>
      <div mat-icon-button class="mini-panel-expansion-button" [class.rotate-180]="showMiniPanel"  *ngIf="brands.length > 1 && !(shouldDim$ | async) && !(isCompareMode$ | async)">
        <i class="fa fa-chevron-down"></i>
      </div>
    </div>
    <ng-template #journeyInfo>
      <div class="journey-info-tooltip">
        <div> {{stage.name}} </div>
        <div>
          <i class="fa fa-user"></i> {{personCount | number}}
        </div>
        <div>{{getJourneySubmarketPath(journeySubMarket)}}</div>
      </div>
    </ng-template>
    <ppc-journey-stage-mini-panel [class.invisible]="!showMiniPanel || (shouldDim$ | async)" [stage]="stage" *ngIf="brands.length > 1  && !(isCompareMode$ | async)"></ppc-journey-stage-mini-panel>
    <ppc-journey-transition-info *ngIf="transitionElements"
                                 [host]="transitionElements[0]"
                                 [target]="transitionElements[1]"
                                 [color]="color"
                                 [horizontal]="false"
                                 [hostStage]="stage"
                                 [targetStage]="nextStage"
                                 [(expanded)]="transitionInfoExpanded"
                                 [class.dim]="shouldDimArrow$ | async"></ppc-journey-transition-info>
  </div>
