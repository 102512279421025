<header>
  <h5 class="nav" (click)="closeSelf()">
    <i class="fa fa-arrow-left"></i>
    <div>Audiences</div>
  </h5>
  <div class="warning-container">
    <ppc-error
      [isZeroCount]="true"
      [audienceCopy]="true"
      [topLevelMessage]="warningText"
      [visible]="zeroCountWarningActive"
      (closeAlert)="closeWarning()"
    >
    </ppc-error>
  </div>
  <h4 class="page-title">Audience Detail</h4>
  <ng-container>
    <h1 class="audience-name">
      <i class="fa fa-user" ppcTooltip="Audience"></i>
      <div *ngIf="audience">{{audience.name}}</div>
    </h1>
  </ng-container>
</header>
<main>
  <div class="info">
    <div class="audience-info-pane" *ngIf="audience">
      <div class="info-set">
        <div class="info-container">
          <div
            class="info-title"
            [ppcTooltip]="tooltipMessages['peopleCount']"
          >
            People Count
          </div>
          <div class="info-value">
            {{ isPeopleCountEstimated(audience) ? "~" : ""}}{{audience.count.people.global | number}}
          </div>
        </div>
        <div
          class="info-container"
          *ngIf="canDisplayAddressableCounts">
          <div class="info-title">
            Addressable Count
          </div>
          <div class="info-value">
            {{ addressableCountDisplay() }}
          </div>
        </div>
        <div class="info-container" *ngIf="!hideTVPanelistColumn(activeRegion)">
          <div
            class="info-title"
            [ppcTooltip]="panelistDataSourceMessage()"
            [tooltipDisabled]="regionIsNotAustralia"
          >
            TV Panelists
          </div>
          <div class="info-value">
            {{panelistCount}}
          </div>
        </div>
        <div class="info-container">
          <div class="info-title">
            Status
          </div>
          <div class="info-value">
            <span [class]="jobStatusClass(audience.job_status)"></span>
            {{jobStatusDisplay(audience.job_status)}}
          </div>
        </div>
        <div class="info-container">
          <div class="info-title">
            Created
          </div>
          <div class="info-value">
            {{audience.created_at | date: 'shortDate'}}
          </div>
        </div>
        <div class="info-container">
          <div class="info-title">
            Refresh End
          </div>
          <div class="info-value">
            <div [class.red]="expiredDate(audience.refresh_end_date)" >{{audience.refresh_end_date | date: 'shortDate'}}</div>
          </div>
        </div>
        <div class="info-container">
          <div class="info-title">
            Expiration
          </div>
          <div class="info-value">
            <div [class.red]="expiredDate(audience.expiration)" >{{audience.expiration | date: 'shortDate'}}</div>
          </div>
        </div>
        <div class="action-menu-container">
          <ppc-audience-action-menu
            [audience]="audience"
            [isDetailView]="true"
            [activeRegion]="activeRegion"
            (openActivationOverlay)="openActivationOverlay.emit(audience)"
            (openIndexReportForm)="openIndexReportForm.emit(audience)"
            (errorRibbonCloseEvent)="closeWarning()">
          </ppc-audience-action-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="info-lower">
    <ppc-audience-rules-detail
      [audience]="audience"
      (zeroCountSegmentDetection)="hasZeroCount()">
    </ppc-audience-rules-detail>
    <div class="audience-details-pane">
      <div class="audience-details">
        <div class="detail-section">
          <ppc-detail-permissions [ppcObject]="audience" 
                                  [drawerContent]="accordionData" 
                                  [initialStep]="initialStep"
                                  [resetAccordionState]="resetAccordionState">
          </ppc-detail-permissions>
        </div>
      </div>
    </div>
  </div>
</main>
