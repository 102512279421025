<div class="audience-builder-rule-groups" cdkDropList #dropList="cdkDropList"
  (cdkDropListDropped)="dropListDropped($event)" [cdkDropListConnectedTo]="dragService.allDropLists$ | async"
  (mouseenter)="dropListMouseEnter(dropList)" (cdkDropListEntered)="dropListEntered($event)" [cdkDropListEnterPredicate]="isDropAllowed.bind(this)"
  [cdkDropListData]="ruleGroup.items" [class.accepts-segments]="ruleGroup.acceptsSegments">
  <div class="audience-builder-rule-group {{group.type}}" *ngFor="let group of ruleGroup.items; index as groupIdx;"
    cdkDrag [cdkDragData]="group" (cdkDragStarted)="dragStarted($event)" (cdkDragEntered)="dragEntered($event)"
    (cdkDragDropped)="ruleGroupDropped($event)">
    <div *cdkDragPlaceholder></div>
    <div *ngIf="isEntrySegment(group)" class="audience-builder-group-segment">
      <div class="drag-icon"><i class="fa fa-ellipsis-v" *ngFor="let i of [1,2]"></i></div>
      <div class="segment-path">{{getSegmentPath(group)}}</div>
      <div class='segment-count'>
        <div class="warning-icon-holder" *ngIf="getSegmentCount(group) === 0">
          <ppc-zero-count-warning-icon></ppc-zero-count-warning-icon>
        </div>
        {{ getSegmentCount(group) | number }}</div>
      <div class="remove-icon" (click)="remove(groupIdx)">
        <img src="assets/icons/close.svg" />
      </div>
      <div class="audience-builder-group-segment" *cdkDragPreview
        [style.width.px]="dragService.hoveredDropListWidth$ | async">
        <div class="drag-icon"><i class="fa fa-ellipsis-v" *ngFor="let i of [1,2]"></i></div>
        <div class="segment-path">{{getSegmentPath(group)}}</div>
        <div class="remove-icon">
          <img src="assets/icons/close.svg" />
        </div>
      </div>
    </div>
    <div *ngIf="!isEntrySegment(group)" class="audience-builder-group-container {{group.type}}">
      <div class="audience-builder-rule-group-bracket">
        <div class="bracket">
        </div>
        <div class="group-info" #groupInfo [class.elevated]="expandable.open">
          <div class="group-info-label" ppcExpandable #expandable="ppcExpandable"
            [ppcExpandableTrigger]="expandableTrigger" [ppcExpandableDisabled]="!(isGroupDeleteAllowed(group.type) || createService.groupTypeChangeAllowed)">
            <div class="drag-icon"><i class="fa fa-ellipsis-v" *ngFor="let i of [1,2]"></i></div>
            <div class="label-text" #expandableTrigger>
              <div>{{group.type}}</div>
              <div class="menu-icon" [class.rotate-180]="expandable.open" *ngIf="isGroupDeleteAllowed(group.type) || createService.groupTypeChangeAllowed">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>

          </div>
          <div class="group-info-options-container" *ngIf="expandable.open">
            <svg class="group-info-options-caret" viewbox="0 0 15 7.5">
              <path d="m 0 7.5 l 7.5 -7.5 l 7.5 7.5" stroke="#5a5a5a" fill="#212121"></path>
            </svg>
            <div class="group-info-options">
              <div class="group-info-option" *ngFor="let option of getOtherBooleanTypesForGroup(group)"
                (click)="changeType(group, option)">
                {{option}}
              </div>
              <div class="group-info-option" (click)="remove(groupIdx)" *ngIf="isGroupDeleteAllowed(group.type)">delete</div>
            </div>
          </div>
        </div>
      </div>
      <ppc-audience-builder-group [ruleGroup]="group" [nestLevel]="nestLevel + 1" [path]="path + '.items.' + groupIdx" [parent]="ruleGroup">
      </ppc-audience-builder-group>
    </div>
  </div>
</div>
