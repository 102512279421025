<div class="logo">
  <div class="logo-bar"></div>
  <img src="/assets/images/ppc_logo/ppc_logo_white.svg" alt="PUBLICIS PEOPLECLOUD">
</div>

<div class="reset-password">
  <div *ngIf="success">
    <p>We have sent an email to {{userEmail}} with instructions for changing your password.</p>
    <p>Click <a routerLink="/login">here</a> to return to the login page.</p>
  </div>
  <ng-container *ngIf="!success">
    <h2>Forgot your password?</h2>
    <mat-form-field class="reset-password-form">
      <input matInput
             type="text"
             name="userEmail"
             spellcheck="false"
             required
             [(ngModel)]="userEmail"
             (blur)="checkEmailValidity()">
      <mat-placeholder>
        Email
      </mat-placeholder>
      <mat-hint class="login-error" *ngIf="errors.size > 0">
        <div *ngFor="let error of errors">
          {{ error }}
        </div>
      </mat-hint>
    </mat-form-field>
    <div>
      <button class="btn btn-save" [disabled]="!ableToSubmit()" (click)="submit()">Submit</button>
    </div>
  </ng-container>
</div>
