<div class="timeframe-selector" [class.open]="open">
  <div class="timeframe-selector-trigger">
      <div class="timeframe-selector-label grey">Time Period</div>
      <div class="timeframe-selector-container"  (click)="open = true">
          
          <div class="full-trigger big">
              <img src="assets/icons/date-compare.svg" *ngIf="!isRangeMode">
              <img src="assets/icons/date-range.svg" *ngIf="isRangeMode">
              {{previousTimeframeString}} {{isRangeMode ? "-" : "to"}} {{currentTimeframeString}}
          </div>
          <div class="timeframe-selector-dropdown" (click)="$event.stopPropagation()">
              <div class="timeframe-selector-dropdown-left">
                  <div class="timeframe-selector-error" *ngIf="rangeError">
                      <div class="timeframe-selector-error-icon">
                        <i class="fa fa-fw fa-warning"></i>
                      </div>
                      <div class="timeframe-selector-error-text">
                        <span class="bold">Time period too long to chart by month.</span><span>Please decrease the time period.</span>
                      </div>
                    </div>
                    <div class="timeframe-selector-mode-toggles">
                      <div class="mode-button" [class.selected]="!isRangeMode" (click)="setRangeMode(false)">Comparison</div>
                      <div class="mode-button" [class.selected]="isRangeMode" (click)="setRangeMode(true)">Range</div>
                    </div>
                    <div class="timeframe-selector-options" >
                        <div class="timeframe-selector-year">
                            <div class="year-header">
                                <div class="year-selector" [class.invisible]="!canGoBack" (click)="canGoBack && setYear(year - 2)">
                                  <i class="fa fa-chevron-left"></i>
                                </div>
                                <div class="current-year">{{year - 1}}</div>
                                <div class="year-selector invisible" (click)="canGoForward && setYear(year + 1)">
                                  <i class="fa fa-chevron-right"></i>
                                </div>
                              </div>
                              <div class="month-options">
                                <div *ngFor="let month of months"
                                      class="month"
                                      [class.disabled]="isMonthDisabled(month, year - 1)"
                                      [class.selected]="isMonthSelectedOrHovered(month, year - 1)"
                                      [class.is-previous]="isMonthSelectedOrHovered(month, year - 1) && isMonthPrevious(month, year - 1) && hasTwoUniqueTimeframes"
                                      [class.is-current]="isMonthSelectedOrHovered(month, year - 1) && isMonthCurrent(month, year - 1) && hasTwoUniqueTimeframes"
                                      (mouseenter)="setHoveredSelection(month, year - 1)"
                                      (click)="!isMonthDisabled(month, year - 1) && selectMonth(month, year - 1)">
                                  <div class="month-label">
                                    {{ getMonthLabel(month) }}
                                  </div>
                                  <div class="month-selected-background">
                
                                  </div>
                                  <div class="month-between-background" [class.hide]="!isMonthBetweenSelections(month, year - 1)">
                
                                  </div>
                                  <div class="month-selected-bridge right" *ngIf="isRangeMode"  [class.is-end-month]="isEndMonth(month)"></div>
                                  <div class="month-selected-bridge left" *ngIf="isRangeMode"  [class.is-end-month]="isEndMonth(month)"></div>
                                </div>
                              </div>
                        </div>
                        <div class="timeframe-selector-year">
                            <div class="year-header">
                                <div class="year-selector invisible" (click)="canGoBack && setYear(year - 1)">
                                  <i class="fa fa-chevron-left"></i>
                                </div>
                                <div class="current-year">{{year}}</div>
                                <div class="year-selector" [class.invisible]="!canGoForward" (click)="canGoForward && setYear(year + 2)">
                                  <i class="fa fa-chevron-right"></i>
                                </div>
                              </div>
                              <div class="month-options">
                                <div *ngFor="let month of months"
                                      class="month"
                                      [class.disabled]="isMonthDisabled(month, year)"
                                      [class.selected]="isMonthSelectedOrHovered(month, year)"
                                      [class.is-previous]="isMonthSelectedOrHovered(month, year) && isMonthPrevious(month, year) && hasTwoUniqueTimeframes"
                                      [class.is-current]="isMonthSelectedOrHovered(month, year) && isMonthCurrent(month, year) && hasTwoUniqueTimeframes"
                                      (mouseenter)="setHoveredSelection(month, year)"
                                      (click)="!isMonthDisabled(month, year) && selectMonth(month, year)">
                                  <div class="month-label">
                                    {{ getMonthLabel(month) }}
                                  </div>
                                  <div class="month-selected-background">
                
                                  </div>
                                  <div class="month-between-background" [class.hide]="!isMonthBetweenSelections(month, year)">
                
                                  </div>
                                  <div class="month-selected-bridge right" *ngIf="isRangeMode" [class.is-end-month]="isEndMonth(month)"></div>
                                  <div class="month-selected-bridge left" *ngIf="isRangeMode" [class.is-end-month]="isEndMonth(month)"></div>
                                </div>
                              </div>
                        </div>
                        </div>
                        <div class="timeframe-selector-buttons">
                            <div class="open-cms-button" (click)="openCmsClicked()" *ngIf="canEditOutcomes$ | async">Timeframe Settings</div>
                        </div>
              </div>
              <div class="timeframe-selector-dropdown-right">
                <div class="timeframe-selector-presets">
                  <div class="timeframe-selector-preset" *ngFor="let preset of presets" (click)="selectFromPreset(preset.getEndpoints())" [class.disabled]="!isPresetValid(preset.getEndpoints())">
                    {{ preset.name }}
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
