import { map } from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {UserResource} from "app/admin/role-admin/role.interface";
import {PpcHttpService} from "app/services/ppc_http.service";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {fetchResource} from "app/shared/utils/fetch-state";
import {
  FetchUserResources, LoadUserResources
} from "app/admin/role-admin/service/user-role.actions";
import { userResourcesUrl } from 'app/shared/constants/auth.urls';

@Injectable()
export class UserResourceService {

  @Effect()
  fetchResources$ = this.actions$.pipe(
    ofType(FetchUserResources.type),
    (fetchResource(
      action => this.getResources().pipe(map(resources => new LoadUserResources(resources)))
    )));

  constructor(private http: PpcHttpService, private actions$: Actions) {}

  getResources(): Observable<UserResource[]> {
    return this.http.get(userResourcesUrl());
  }
}
