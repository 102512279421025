import {Component, Input, OnDestroy} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Store, select} from "@ngrx/store";
import {combineLatest, Observable, BehaviorSubject, of as observableOf} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import {AppState} from "app/reducers";
import {Router} from "@angular/router";
import { comparisons} from 'app/comparisons/comparisons.reducer';
import { compareKey, dataServiceType } from 'app/shared/utils/utils';
import { Mekko, SubMarket } from 'app/mekko/mekko.reducer';
import {compact, map as _map, groupBy, mapValues, flatMap, values, sumBy} from 'lodash'
import { SegmentSearchResponse } from 'app/insights/grow-v3/grow-count.service'
import { segmentSearchCountUrl } from '../../../shared/constants/id_analytics.urls';

@Component({
  selector: 'ppc-charts-box',
  templateUrl: './charts-box.component.html',
  styleUrls: ['../../home.component.sass']
})
export class ChartsBoxComponent implements OnDestroy {
  @Input() hierarchyPath: string;
  @Input() isFullContext: boolean;

  subMarkets: SubMarket[];
  selectOptions = [{label: "Last 5", value: 5}, {label: "Last 10", value: 10}];
  tableFilterLength$ = new BehaviorSubject(5);

  mekkosWithCounts$ = combineLatest(
    this.store.select('mekkos', 'mekkos'),
    this.store.select('mekkos', 'subMarkets')
  ).pipe(
    switchMap(([mekkos, subMarkets]) =>
      combineLatest(
        _map(mekkos,
          mekko =>
            this.isFullContext ? this.getMekkoCounts(mekko, values(subMarkets))
              .pipe(map(counts => ({ ...mekko, ...counts }))) : [mekko]))))

  charts$ = combineLatest(
    this.store.select("comparisons").pipe(select(comparisons)),
    this.mekkosWithCounts$,
    this.store.select("journey", "journeys").pipe(map(values)),
    this.tableFilterLength$,
  ).pipe(
    map(([comparisons, mekkos, journeys, filter]) =>  {
      return [...comparisons, ...mekkos, ...journeys].sort(compareKey('created_at', false)).slice(0, filter)
    })
  )

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnDestroy() {
  }

  filterLengthSelect(value) {
    this.tableFilterLength$.next(value);
  }

  getMekkoSegmentIds(mekko: Mekko, subMarkets: SubMarket[]) {
    const marketIds = mekko.markets.map(market => market.id)
    const filteredSubMarkets = subMarkets.filter((sm) => marketIds.includes(sm.market_id))
    return {
      matched_short_ids: mapValues(groupBy(filteredSubMarkets, 'market_id'),
        subMarkets => compact(_map(subMarkets, 'matched_short_id'))),
      modeled_short_ids: mapValues(groupBy(filteredSubMarkets, 'market_id'),
        subMarkets => compact(_map(subMarkets, 'modeled_short_id'))),
      focused_matched_short_ids: compact(_map(filteredSubMarkets, 'matched_short_id')),
      focused_modeled_short_ids: compact(_map(filteredSubMarkets, 'modeled_short_id')),
    }
  }

  getMekkoCounts(mekko: Mekko, subMarkets: SubMarket[]): Observable<{total_matched_count: number, total_modeled_count: number, total_population_count: number}> {
    const serviceType = dataServiceType();
    const payload = {
      ...this.getMekkoSegmentIds(mekko, subMarkets),
      feature: 'home-screen',
      demo_as_segments: true,
      current_view: 'All',
      group_short_ids: {},
      service_type: serviceType
    };

    const allShortIds = flatMap(payload.matched_short_ids, values)
      .concat(flatMap(payload.modeled_short_ids, values))

    if (allShortIds.length === 0) {return observableOf({ total_matched_count: null, total_modeled_count: null, total_population_count: this.getMekkoTotalPopulation(mekko, subMarkets)})}

    return this.http.post<SegmentSearchResponse>(segmentSearchCountUrl(), payload).pipe(
      map(res => ({ total_matched_count: res.total_matched_count,
        total_modeled_count: res.total_modeled_count,
        total_population_count: this.getMekkoTotalPopulation(mekko, subMarkets)
      })))
  }

  getMekkoTotalPopulation(mekko: Mekko, subMarkets: SubMarket[]): number {
    const marketIds = mekko.markets.map(market => market.id)
    const filteredSubMarkets = subMarkets.filter((sm) => marketIds.includes(sm.market_id))
    return sumBy(filteredSubMarkets, 'population');
  }
}
