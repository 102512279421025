import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, tap } from 'rxjs/operators';
import { PpcHttpService } from "app/services/ppc_http.service";
import {
  discussionTemplateUrl,
  journeySurveyTemplateUrl,
  journeyTemplateUrl,
  mekkoSurveyTemplateUrl,
  mekkoTemplateUrl,
  personaTemplateUrl,
  regionSurveyTemplateUrl,
} from 'app/shared/constants/insights.urls';

@Injectable()
export class UploadAdminService {
  private loadingSource = new Subject<boolean>();
  loading$ = this.loadingSource.asObservable();

  loading(value: boolean) {
    this.loadingSource.next(value);
  }

  constructor(private http: PpcHttpService) {}

  downloadTemplate() {
    return this.http.getDownload(mekkoTemplateUrl()).pipe(
      tap(res => this.http.downloadFile(res, 'GrowChart_Template.csv')));
  }

  downloadSurveyTemplate() {
    return this.http.getDownload(mekkoSurveyTemplateUrl()).pipe(
      tap(res => this.http.downloadFile(res, 'GrowChart_Survey_Template.csv')));
  }

  downloadJourneySurveyTemplate() {
    return this.http.getDownload(journeySurveyTemplateUrl()).pipe(
      tap(res => this.http.downloadFile(res, 'Journey_Survey_Template.csv')));
  }

  downloadRegionSurveyTemplate() {
    return this.http.getDownload(regionSurveyTemplateUrl()).pipe(
      tap(res => this.http.downloadFile(res, 'Region_Survey_Template.csv')));
  }

  downloadDiscussionTemplate(nodeId: number) {
    return this.http.getDownload(discussionTemplateUrl(nodeId)).pipe(
      tap(res => this.http.downloadFile(res, 'Discussion_Template.csv')));
  }

  downloadJourneyTemplate() {
    return this.http.getDownload(journeyTemplateUrl()).pipe(
      tap(res => this.http.downloadFile(res, 'Journey_Template.csv')));
  }

  downloadPersonaTemplate() {
    return this.http.getDownload(personaTemplateUrl()).pipe(
      tap(res => this.http.downloadFile(res, 'Persona_Template.csv')));
  }

  uploadTemplate(templateFile: FormData): Observable<MekkoUploadReport> {
    return this.http.upload(mekkoTemplateUrl(), templateFile).pipe(
      map( res => {
        return { mekkos: res.data.updated, errors: res.errors }
      })
    ).pipe(tap(() => this.loadingSource.next(false)));
  }

  uploadSurveyTemplate(templateFile: FormData): Observable<SurveyTemplateUploadReport> {
    return this.http.upload(mekkoSurveyTemplateUrl(), templateFile)
      .map(res => { return { mekkos: res.data.updated, errors: res.errors } }
      ).pipe(tap(() => this.loadingSource.next(false)));
  }

  uploadJourneySurveyTemplate(templateFile: FormData): Observable<SurveyTemplateUploadReport> {
    return this.http.upload(journeySurveyTemplateUrl(), templateFile)
      .map(res => { return { journeys: res.data.updated, errors: res.errors } }
      ).pipe(tap(() => this.loadingSource.next(false)));
  }

  uploadRegionSurveyTemplate(templateFile: FormData): Observable<SurveyTemplateUploadReport> {
    return this.http.upload(regionSurveyTemplateUrl(), templateFile)
      .map(res => { return { surveys: res.data.updated, errors: res.errors } }
      ).pipe(tap(() => this.loadingSource.next(false)));
  }

  uploadDiscussionTemplate(templateFile: FormData, nodeId: number) {
    return this.http.upload(discussionTemplateUrl(nodeId), templateFile)
      .map(res => { return { clusters: res.data.updated, errors: res.errors } }
      ).pipe(tap(() => this.loadingSource.next(false)));
  }

  uploadJourneyTemplate(templateFile: FormData): Observable<JourneyUploadReport> {
    return this.http.upload(journeyTemplateUrl(), templateFile).pipe(
      map( res => { return { journeys: res.data.updated, errors: res.errors } })
    ).pipe(tap(() => this.loadingSource.next(false)));
  }

  uploadPersonaTemplate(templateFile: FormData) {
    return this.http.upload(personaTemplateUrl(), templateFile).pipe(
      map( res => { return { resources: res.data.updated, errors: res.errors } })
    ).pipe(tap(() => this.loadingSource.next(false)));
  }
}

export interface MekkoUploadReport {
  mekkos?: { name: string, markets?: { name: string, sub_markets: { name: string, demographics: { name: string, values: number[] }[] }[] } }[],
  errors?: { column: string, row: string, message: string }[]
}

export interface JourneyUploadReport {
  journeys?: { name: string, brands?: { journey_name: string, name: string, stages?: { name: string }[] }[] }[],
  errors?: { column: string, row: string, message: string }[]
}

export interface SurveyTemplateUploadReport {
  mekkos?: { name: string, markets?: { name: string, sub_markets: { name: string, surveys: { name: string }[] }[] } }[],
  journeys?: { name: string, brands?: { journey_name: string, name: string, sub_markets?: { name: string }[] }[] }[],
  surveys?: { name: string }[],
  errors?: { column: string, row: string, message: string }[]
}
