import {Role, UserPermissionResource, blankPermission, ResourceRole} from "app/admin/role-admin/role.interface";
import {All, LoadRoles, LoadUserResources, UpdateRole} from "app/admin/role-admin/service/user-role.actions";
import {keyBy} from 'lodash';
import {createSelector} from "@ngrx/store";

export interface State {
  roles: Role[],
  resources: UserPermissionResource[]
}

export const initialState: State = {
  roles: [],
  resources: []
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case LoadRoles.type:
      return {...state, roles: action.roles};
    case LoadUserResources.type:
      return {...state, resources: action.resources};
    case UpdateRole.type:
      return {...state, roles: updateRole(state.roles, action.role)};
    default:
      return state;
  }

}

function updateRole(roles: Role[], updatedRole: Role): Role[] {
  const affectedRole = !!updatedRole && roles.find(role => role.id == updatedRole.id);
  !!affectedRole && Object.assign(affectedRole, updatedRole);
  return roles;
}

export const selectRoles = (state: State) => state.roles;

export const selectResources = (state: State) => state.resources;

export const selectRolesWithResources = createSelector(
  selectRoles,
  selectResources,
  (roles, resources) => roles.map( role => {
    return new ResourceRole(role, resources);
  })
);
