<div
  *ngIf="permissionRows.length > 0"
  class='table-wrapper'
>
  <button
    class='add-entry-button btn coreUIButton-secondary'
    [disabled]='newEntrydisabled()'
    (click)='addNewEntry()'
  >
    Add Entry
  </button>

  <div class="table">
    <div class="row row-header">
      <div class="column column-header">
        Name
      </div>
      <div class="column column-header" *ngFor="let useCase of classReference.useCases">
        {{useCase.header}}
      </div>
    </div>
    <div class="row" [class.deleted-row]="permissionRow.deleted" *ngFor="let permissionRow of permissionRows">
  
      <div class="column">
        <span
          *ngIf='!isNewClient(permissionRow)'
        >
          {{clientName(permissionRow.clientSlug)}}
        </span>
  
        <mat-form-field
          *ngIf='isNewClient(permissionRow)'
          class='dropdown'
        >
          <mat-select
            placeholder='Select Client'
            class='dropdown-select'
            (selectionChange)='setNewClient($event)'
          >
            <mat-option
              class='select-option'
              *ngFor='let client of clients'
              [disabled]='clientAssigned(client)'
              [value]='client'
            >
              {{client.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="column" *ngFor="let useCase of classReference.useCases">
        <div [ngClass]="{ 'checkbox': true, 'editing': editableRows[permissionRow.clientSlug] }" (click)="toggle(permissionRow.dataPermissions, permissionRow.clientSlug, useCase.key)">
          <span *ngIf="permissionRow.dataPermissions[useCase.key]">{{"\u2713"}}</span>
          <span *ngIf="!permissionRow.dataPermissions[useCase.key]">{{"\u2716"}}</span>
        </div>
      </div>
      <div class="action-column">
        <ng-container *ngIf="!permissionRow.deleted">
          <span
            *ngIf="!disableCopy"
            class='copy-button'
          >
            <a (click)="addNewEntry(permissionRow)">Copy</a>
          </span>
          <span
            *ngIf="!editableRows[permissionRow.clientSlug]"
            class='edit-button'
          >
            <a (click)="toggleEditable(permissionRow.clientSlug)">Edit</a>
          </span>
          <span
            *ngIf="editableRows[permissionRow.clientSlug] && !permissionRow.newEntry"
            class='reset-button'
          >
            <a (click)="resetPermissions(permissionRow.dataPermissions, permissionRow.clientSlug)">Reset</a>
          </span>
        </ng-container>
        <span
          *ngIf="!isPublicClient(permissionRow)"
          class='delete-button'
        >
          <a (click)="toggleDelete(permissionRow)">{{getToggleDeleteText(permissionRow)}}</a>
        </span>
      </div>
    </div>
  </div>
  <div>* All changes will be reflected on submit.</div>
</div>
