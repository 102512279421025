<div class="look-a-like-form-wrapper">
  <div class="page-title">
    <h1 class="look-a-like-header"><span class="object-name">{{ isNewLookalike ? "Create" : "Edit" }}</span> Lookalike Audience</h1>
    <button class="close-btn fa fa-2x fa-times" (click)="closeOverlay()" ></button>
  </div>
  <h5 class="red" *ngIf="isProcessing">Cannot edit lookalike while it is being processed</h5>
  <h5 class="dim-white" *ngIf="isProcessed">Note: Changes to confidence size or including seed audience will result in the creation of a new lookalike audience</h5>
  <div class="lal-prefix" *ngIf="isNewLookalike">
    {{ lookalikeNamePrefix }}
  </div>

  <div class="input-container">
    <ppc-input
      #nameInput
      [(ngModel)]="lookalike.name"
      [class.disabled]="isProcessing"
    >
      <label>
        Name
      </label>
    </ppc-input>
  </div>

  <div class="description-container">
    <ppc-input
      #descriptionInput
      [(ngModel)]="lookalike.description"
      [class.disabled]="isProcessing"
    >
      <label>
        Description
      </label>
    </ppc-input>
  </div>

  <div class="lal-source">
    <span> Lookalike from {{ lookalike.seed_name }} </span>
  </div>

  <div class="addressable-container">
    <span class="addressable-modeled">
      <span>Modeled Audience Count</span>
      <h3>{{ hasConfidenceSizes ? (modeledAddressable | number) : "--" }}</h3>
    </span>

    <span class="addressable-matched">
      <span>Seed Audience Count</span>
      <h3>{{ matchedAddressable | number }}</h3>
    </span>
  </div>

  <div *ngIf="hasConfidenceSizes; else pendingGraph" class="graph">
    <app-lookalike-graph-v2
      [lookalike]="lookalike"
      (updateCountAndConfidence)="updateCountAndConfidence($event)"
      >
    </app-lookalike-graph-v2>
  </div>

  <ng-template #pendingGraph>
    <div class="pending-container">
      <div class="pending-text">
        <p>Please return in 24 hours to view completed confidence vs. reach graph.</p>
      </div>
    </div>
  </ng-template>

  <div class="seedCheckbox">
    <ppc-checkbox [(ngModel)]="lookalike.include_seed_in_model">
      Include Seed Audience
    </ppc-checkbox>
  </div>

  <div [ngSwitch]="jobStatus" class="buttons-container">
    <span *ngSwitchCase="'new'" class="btn btn-save" (click)="onSave()">Save</span>
    <span *ngSwitchCase="'pending'" class="btn btn-save" (click)="onUpdate()">Save</span>
    <span *ngSwitchCase="'processing'" [ppcTooltip]="processingTooltip">
      <span class="btn btn-save disabled">Save</span>
    </span>
    <span *ngSwitchDefault>
        <ng-container *ngIf="lookalikeWillClone; else updateButton">
          <app-confirmation
            class="mat-button btn btn-save"
            buttonContinueLabel="CONFIRM"
            [isCustomTemplate]="true"
            [kontinue]="onSave.bind(this)"
            [modalTitle]="confirmMessage"
          >
            Edit and Clone
          </app-confirmation>
        </ng-container>
        <ng-template #updateButton>
          <div class="btn btn-save" (click)="onUpdate()">Edit</div>
        </ng-template>
    </span>

    <span *ngIf="hasConfidenceSizes" class="btn btn-download" (click)="onDownloadDataClicked($event)">
      <i class="fa fa-download hoverable"></i>
      DOWNLOAD EXCEL
    </span>
  </div>
</div>
