import {Currency} from "../services/currency.service";
import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {compareKey} from "app/shared/utils/utils";
import * as currencyActions from "./currencies.actions";

export type State = EntityState<Currency>

const adapter: EntityAdapter<Currency> = createEntityAdapter<Currency>({
  sortComparer: compareKey('name')
});

const initialState = adapter.getInitialState();

export function reducer(state = initialState, action: currencyActions.AllActions) {
  switch (action.type) {
    case currencyActions.LoadCurrencies.type:
      return adapter.addMany(action.currencies, state);
    default:
      return state
  }
}

export const {
  selectEntities: getCurrenciesMap,
  selectAll: getCurrencies
} = adapter.getSelectors();
