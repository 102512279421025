import {Injectable} from "@angular/core";
import {Observable,  BehaviorSubject } from "rxjs";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";

@Injectable()
export class LoggingService {
  private errorMessage$ = new BehaviorSubject("");

  constructor() {}

  logError(message: string): void {
    this.errorMessage$.next(message);
  }

  getError(): Observable<string> {
    return this.errorMessage$.asObservable();
  }

  clearError(): void {
    this.errorMessage$.next("");
  }
}
