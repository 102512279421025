import { Directive, Input, HostListener, TemplateRef } from '@angular/core';
import { MaterialModalService } from 'app/shared/components/material-modal/material-modal.service';

@Directive({
  selector: '[ppcConfirmation]'
})
export class ConfirmationDirective {
  @Input() modalContent?: string;
  @Input() buttonClass = 'btn btn-save';
  @Input() modalTitle = 'Are you sure you want to delete this?';
  @Input() buttonCancelLabel = 'CANCEL';
  @Input() buttonContinueLabel = 'DELETE';
  @Input() kontinue: ($event) => void;
  @Input() disable = false;
  @Input() dialogTemplate?: TemplateRef<any>;

  constructor(private modalService: MaterialModalService) { }

  @HostListener("click")
  click($event) {
    if ( this.disable ) { return; }
    this.modalService.confirm(this.modalTitle, this.modalContent, this.buttonCancelLabel, this.buttonContinueLabel, this.dialogTemplate).subscribe(
      kontinue => kontinue && this.kontinue($event)
    )
  }

}
