<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
</header>
<div class="page-container">
  <ol class="steps">
    <li>
      Upload your creative assets in the Creative Upload page within the Creative Module. You can do this
      <a routerLink="{{product.slug_full}}/creative/assets">here</a>
    </li>
    <li>
      Download the Creative Genome CSV Template
      <a href="#" (click)="downloadDataTemplate($event)">
        Download Template
        <i class="fa fa-download"></i>
      </a>
    </li>
    <li>
      Create or Update the score for attributes and assets using the CSV template.
      <ol>
        <li>Each row contains a single attribute, while each column contains a specific creative asset you have uploaded.</li>
        <li>For any questions using the template, contact productsupport@publicisgroupe.com</li>
      </ol>
    </li>
    <li>
      <form (submit)="uploadTemplate($event)">
        <span>Upload your modified template here -></span>
        <input type="file" name="template" accept=".csv" />
        <button type="submit" class="btn btn-save">Submit</button>
      </form>
      <mat-progress-spinner *ngIf="isUploading" mode="indeterminate"></mat-progress-spinner>
    </li>
    <li *ngIf="uploadReport">
      Check this summary report for errors
      <div class="template-report">
        <div>
          We processed scores for {{uploadReport.total_creative_cols - uploadReport.bad_creative_ids.length}} creatives
          across {{uploadReport.total_attribute_rows - uploadReport.bad_attribute_ids.length}} attributes.
        </div>
        <div>
          Total creative columns: {{uploadReport.total_creative_cols}}
        </div>
        <div>
          Total attribute rows: {{uploadReport.total_attribute_rows}}
        </div>
        <div *ngIf="hasErrors()">
          Any errors reported below are due to one of three things:
          <ul>
            <li>A creative id was not found in the database. This probably means it was accidentally edited.</li>
            <li>An attribute id was not found in the database. This probably means it was accidentally edited.</li>
            <li>A score was invalid. Scores must be numbers between 0 and 1 inclusive.</li>
          </ul>
        </div>
        <div *ngIf="uploadReport.bad_creative_ids.length">
          Unprocessed creative columns: {{uploadReport.bad_creative_ids.length}}
          <ul>
            <li *ngFor="let id of uploadReport.bad_creative_ids">ID: {{id}}</li>
          </ul>
        </div>
        <div *ngIf="uploadReport.bad_attribute_ids.length">
          Unprocessed attribute rows: {{uploadReport.bad_attribute_ids.length}}
          <ul>
            <li *ngFor="let id of uploadReport.bad_attribute_ids">ID: {{id}}</li>
          </ul>
        </div>
        <div *ngIf="uploadReport.bad_score_numbers.length">
          Unprocessed scores: {{uploadReport.bad_score_numbers.length}}
          <div>
            The coordinates of unprocessed scores are below
          </div>
          <table>
            <thead>
              <tr>
                <th>Attribute ID</th>
                <th>Creative ID</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let coords of uploadReport.bad_score_numbers">
                <td>{{coords.attribute_id}}</td>
                <td>{{coords.creative_id}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </li>
  </ol>
</div>
