import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {Actions, Effect, ofType} from "@ngrx/effects";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import {fetchResource} from "../shared/utils/fetch-state";
import {FetchPermissions, LoadPermissions} from "../authorization/permissions/permissions.actions";
import {PpcHttpService} from "app/services/ppc_http.service";
import {AuthPermission} from "../shared/interfaces/auth-permission";
import {LoadCurrentUser} from "app/users/user.actions";
import { permissionsUrl } from 'app/shared/constants/auth.urls';

@Injectable()
export class AuthorizationService {

  @Effect()
  newCurrentUser$ = this.actions$.pipe(
    ofType(LoadCurrentUser.type),
    map(() => new FetchPermissions()))

  @Effect()
  fetchPermissions$ = this.actions$.pipe(
    ofType(FetchPermissions.type),
    (fetchResource(
      () => this.authorize().pipe(map(permissions => new LoadPermissions(permissions)))
    )));

  constructor(private http: PpcHttpService, private actions$: Actions) {}

  authorize(): Observable<{[name: string]: AuthPermission}> {
    return this.http.get(permissionsUrl());
  }
}
