import * as actions from 'app/insights/grow-v3/grow.actions';
import {get, find, findIndex, sortBy} from 'lodash';
import {createSelector} from "@ngrx/store";
import { WeboramaNodes, WeboramaDiscussion, newWeboramaDiscussion } from 'app/insights/insights-components/market-level-discussions/weborama-nodes.interface'
import { MarketLevelSurvey } from "app/insights/insights-components/market-level-survey/market-level-survey.interface";
import { userPreferenceKeys } from './grow.constants';

import { AppState } from 'app/reducers';
import { selectActiveWeboramaId } from 'app/mekko/mekko.reducer';

export interface Barbs {
  const: number;
  n_pids: number;
  n_pids_sqr?: number;
  maximum?: number;
  minimum?: number;
}

export type GrowTabState = "Total Population" | "Matched Addressable" | "Modeled Addressable";

export type GrowV3FocusType = "mekko" | "insights" | null;

export interface State {
  growTabState: GrowTabState;
  growV3Focus: GrowV3FocusType;
  weboramaNodes: {[weboramaId: string]: WeboramaNodes};
  weboramaDiscussionUnderEdit?: WeboramaDiscussion;
  growV3Active: boolean;
  barbs?: Barbs;
}

const defaultState: State = {
  growTabState: "Matched Addressable",
  growV3Focus: null,
  weboramaNodes: {},
  growV3Active: false,
  barbs: null,
};

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.ResetInsightsState.type:
      return defaultState;
    case actions.ChangeGrowTabState.type:
      return {...state, growTabState: action.growTabState};
    case actions.LoadBarbs.type:
      return { ...state, barbs: action.barbs }
    case actions.SetGrowV3Focus.type:
      return {...state, growV3Focus: state.growV3Focus ? null : action.focus};
    case actions.LoadWeboramaNodes.type:
      return {
        ...state,
        weboramaNodes: {
          ...state.weboramaNodes,
          [action.weboramaId]: action.weboramaNodesResponse
        }
      }
    case actions.LoadWeboramaDiscussion.type:
      const toReplace = state.weboramaNodes[action.weboramaDiscussion.weborama_id]
      toReplace.weborama_discussion = action.weboramaDiscussion;
      return {
        ...state,
        weboramaNodes: {
          ...state.weboramaNodes,
          [action.weboramaDiscussion.weborama_id]: toReplace
        }
      }
    case actions.EditWeboramaDiscussion.type:
      return {
        ...state,
        weboramaDiscussionUnderEdit: action.weboramaNodes ? action.weboramaNodes.weborama_discussion || newWeboramaDiscussion(action.weboramaNodes) : null
      }
    case actions.SetGrowV3Active.type:
      return {
        ...state, growV3Active: action.growV3Active
      }
    case actions.ResetWeboramaDiscussions.type:
      return { ...state, weboramaNodes: {}}
    default:
      return state;
  }
}

export const selectActiveWeboramaNodes = createSelector(
  (state: AppState) => selectActiveWeboramaId(state.mekkos),
  (state: AppState) => state.grow.weboramaNodes,
  (weboramaId, weboramaNodes) => weboramaNodes[weboramaId]
)
