export const tooltipMessage = {
  // Scenarios / Partners
  createScenario: "This audience does not have permissions for use in the plans tool",
  sendToPartner: "This audience does not have permission for 3rd party activation",
  // Tardiis Messages
  alreadySentToTardiis: "This audience has been sent to Tardiis",
  disabledDeleteTardiis: "Cannot delete an audience that has been sent to Tardiis",
  disabledEditTardiis: "Cannot edit once audience has been sent to Tardiis",
  sendToTardiisPermissions: "This audience does not have permissions for Tardiis",
  disabledTardiisIncomplete: 'Cannot send to Tardiis unless audience is complete',
  disabledTardiisAlreadySent: 'This audience has been sent to Tardiis',
  disabledTardiisNotPermissioned: 'This audience is not allowed for TV Planning and cannot be sent to Tardiis',
  // Lookalike Messages
  createLAL: "Lookalike cannot be made at this time",
  createLALPermission: "This audience does not have permissions for modeling",
  disabledDeleteAssociatedLAL: "Cannot delete a Segment that has an associated Lookalike",
  disabledDeleteLAL: "Cannot delete a Lookalike used in a Custom Audience",
  disabledEditByLAL: "Cannot edit an audience used in a lookalike",
  disabledEditLAL: "Cannot update a Lookalike used in a Custom Audience",
  featureForLookalikePermission: "This audience does not have permissions to include seed",
  lookalikeCreationDisabled: 'Cannot create lookalike unless audience is complete',
  lookalikeNotPermissioned: 'This audience is not allowed to be used to create a lookalike',
  lookalikeUpdating: "Lookalike Currently Being Updated",
  // Count Messages
  matchedAddressableCount: "Missing the matched addressable count",
  missingPeopleCount: "Missing the people count",
  peopleCount: "Total unique people available for activation",
  totalIdCount: "Total IDs associated with the audience or segment",
  // Other
  marketSegment: "One or more segments are missing the matched addressable count",
  audienceCreation: "Not permitted for custom audience creation",
  disabledEditOrDelete: "Segment is pushed to TTD or is used in a Scenario",
  addAudienceToOverview: "Add audience to overview",
  removeAudienceFromOverview: "Remove audience from overview",
  comingSoon: 'Coming Soon',
  disabledDeleteProcessing: "Cannot delete a processing audience",
  disabledDeleteSeed: "Cannot delete an audience that has been associated with a lookalike",
};

export const tooltipMessageV2 = {
  audienceCreation: "Not permitted for custom audience creation",
  comingSoon: 'Coming Soon',
  // lookalikes
  disabledEditProccessingLal: "Cannot edit a processing lookalike",
  lookalikeCreationDisabled: 'Cannot create lookalike unless audience is complete',
  lookalikeNotUserPermissioned: 'Action Not Permitted. Contact Product Support.',
  lookalikeNotDataPermissioned: 'This audience is not allowed to be used to create a lookalike',
  lookalikeMissingCounts: 'Must have People Count greater than 0 to create a lookalike audience',
  // column header tooltips
  audienceType: "Type",
  audienceName: "Name",
  audienceStatus: "Status",
  audienceCreated: "Created",
  audienceRefreshEnd: "Refresh End",
  audienceExpiration: "Expiration",
  // counts
  matchedAddressableCount: "Missing the matched addressable count",
  panelistCount: "TV Panelists",
  estimatedPanelistCount: "Estimated TV Panelists",
  unavailablePanelistCount: "Could not estimate TV Panelists",
  peopleCount: "People Count: Total unique people",
  totalIdCount: "Total IDs associated with the audience or segment",
  estimatedPeopleCount: "Estimated",
  failedEstimatedPeopleCount: "Could Not Estimate PeopleCount",
  addressableCount: "Estimated Digital Addressable Count",
  addressableCountUnavailable: "Cannot Estimate Digital Addressable Count",
  segmentCount: "Total unique segments",


  // disabled Edit
  disabledEditNotUserPermissioned: 'Action Not Permitted. Contact Product Support.',

  // disabled Clone
  disabledCloneNotUserPermissioned: 'Action Not Permitted. Contact Product Support.',

  // disabled Tardiis
  disabledTardiisIncomplete: 'Cannot send to Tardiis unless audience is complete',
  disabledTardiisAlreadySent: 'This audience has been sent to Tardiis',
  disabledTardiisNotUserPermissioned: 'Action Not Permitted. Contact Product Support.',
  disabledTardiisNotDataPermissioned: 'This audience is not allowed for TV Planning and cannot be sent to Tardiis',

  // disabled Send to Partner
  sendToPartnerIncomplete: 'Cannot send to partner unless audience is complete',
  sendToPartnerNotUserPermissioned: "Action Not Permitted. Contact Product Support.",
  sendToPartnerNotDataPermissioned: "This audience is not permitted for activation",
  sendToPartnerMissingCounts: 'Must have People Count greater than 0 to Send to Partner',

  // disabled Deletes
  disabledDeleteProcessing: "Cannot delete a processing audience",
  disabledDeleteNotUserPermissioned: "Action Not Permitted. Contact Product Support.",
  disabledDeleteSeed: "Cannot delete an audience that has been associated with a lookalike",
  disabledDeleteTardiis: "Cannot delete an audience that has been sent to Tardiis",

  // Message for AU panelist header tooltip data source
  panelistHeaderTooltipAU: "Data Source: C28 TSV",

  // index report
  indexReportCreationDisabled: 'Cannot run index report unless audience is complete.',
  indexReportNotUserPermissioned: 'Action Not Permitted. Contact Product Support.',
  indexReportInvalidFileName: 'Cannot create index report with invalid name.',
  indexReportNoSelection: 'Cannot create index report with no persona selected'
}

export const snackbarMessage = {
  identicalAudience: "This custom audience is identical to another audience!",
  customAudienceSaveError: "There were error(s) encountered while saving custom audience: ",
  selectIncludeExclude: "Please select an include or exclude group to add this segment to it",
  noSearchResults: "No results found! Please try another search.",
  addedToOverview: " has been added to Audience overview!",
  removedFromOverview: " has been removed from Audience overview!",
  addedToSegments: " has been added to your segments!",
  removedFromSegments: " has been removed from your segments!",
  cannotLoadSegment: "Cannot load segment from server, please try again later.",
  cannotLoadDSP: "Cannot fetch DSP accounts. Please try again later.",
  sentToTardiis: " has been sent to Tardiis.",
  sentToPartner: " has been sent to partner.",
  duplicateAudienceForTardiis: "This audience has already been sent to Tardiis!",
  cannotSendToTardiis: "Audience cannot be sent to Tardiis.",
  createSuccess: "Your Audience will show in the Audience Overview Section"
};

export const snackbarMessageV2 = {
  noSearchResults: "No results found! Please try another search.",
  addedToSegments: " has been added to your segments!",
  removedFromSegments: " has been removed from your segments!",
  sentToTardiis: " has been sent to Tardiis.",
  sentToPartner: " has been sent to partner.",
}

export const audienceType = {
  audience: 'audience',
  lookalike: 'lookalike',
  motivation: 'motivation',
  outcome: 'outcome',
}

export const audienceStatus = {
  pending: 'pending',
  inProgress: 'in progress',
  preliminary: 'preliminary',
  complete: 'complete',
}

export const ALL = 'all';
