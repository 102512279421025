import * as chroma from 'chroma-js';

export const chartDomain = [0, 0.2469, 0.413, 0.584, 0.7555, 1];
export const chartColors = chroma.scale([
  "#C1DAF6",
  "#78A5DB",
  "#3E7ECA",
  "#205493",
  "#153C6A",
]).domain(chartDomain);

export const indexDomain = [0, 0.09090909090909091, 0.18181818181818182, 0.2727272727272727, 0.4, 0.5, 0.5454545454545454, 0.6363636363636364, 0.7272727272727273, 0.8181818181818182, 0.9090909090909092, 1];
export const indexColors = chroma.scale([
  "#021021",
  "#0d2a4c",
  "#184275",
  "#3f6ba0",
  "#8fa6c3",
  "#ecebeb",
  "#d7a5b3",
  "#c06079",
  "#9c4059",
  "#75253a",
  "#470617"

]).domain(indexDomain);

export const propensitySlides =
  [
    {
      title: 'Propensity - Overview',
      description: 'Identify Growth Audiences Using Machine Learning',
      listDescription: 'Shown on the right is an example of selecting 3 segments.',
      image: 'assets/images/propensity_carousel/overview.svg',
      listItems: ['Select to change the default model confidence %. The higher %, the lower the confidence.',
        'See quick preview of Current to Projected audience calculation.',
        'See estimated reach.']
    },
    {
      title: 'Propensity - Detail',
      description: 'Identify Growth Audiences Using Machine Learning',
      listDescription: 'Shown on the right is an example of selecting 3 segments.',
      image: 'assets/images/propensity_carousel/detail.svg',
      listItems: ['Switch to detail view that will allow you understand underlying data.',
        'Select the segments to populate below - up to five.',
        "A circle's color and size represents a segment's likelihood to be associated to IDs in the seed audience, with Large Green circles being Highly likely, Small Green Circles being Somewhat Likely, Small Red Circles being Somewhat Unlikely, Large Red Circles being Highly Unlikely and gray circles being the range in between Red and Green without statistically significant scores to be classified Likely or Unlikely to be in that seed Audience.",
        'These segments are ranked according to scores provided by machine learning. Highest at the top.']
    },
    {
      title: 'Propensity - Outputs',
      description: 'Identify Growth Audiences Using Machine Learning',
      image: 'assets/images/propensity_carousel/outputs.svg',
      listItems: ['The typical run will take around three hours and be available in this pulldown. Here shown is a library where you will locate all created and processing models.',
        'Top two segments are shown processing and would be available once a checkmark appears next to them.',
        "All the segments with corresponding dates you've ran in last 30 days.",
        'In order to make room for older segments that might be more important - you may delete those that are no longer important.']
    }
  ]
