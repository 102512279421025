<div class="segment-picker-search">
  <div class="segment-picker-search-input">
    <i class="fa fa-search"></i>
    <input #searchInputElement placeholder="Enter Search Terms"/>
    <mat-spinner *ngIf="loading" [diameter]="16"></mat-spinner>
  </div>
  <div class="segment-picker-search-no-results" *ngIf="searchCount === 0 && !loading">
    <i class="fa fa-warning"></i>
    <span>Results 0. No results for {{searchInput | json}}.</span>
  </div>
  <div class="search-count" [class.invisible]="(searchCount == null || loading) || (searchCount === 0 && !loading)">
    Results {{searchCount}}</div>
  <div class="top-level-filter-picker">
    <div class="top-level-element">
      <img class="filter-icon" src="assets/icons/filter-white.svg" />
    </div>
    <div class="top-level-element">
      <span class="top-level-filter">Vendors</span>
    </div>
    <div class="reset-filters-spacer"></div>
    <div class="top-level-element">
      <span class="reset-filters" (click)="resetFilters()">Reset filters</span>
    </div>
  </div>
  <ppc-vendor-filter-picker #vendorPicker></ppc-vendor-filter-picker>
  <div class="segment-picker-search-header" *ngIf="searchCount">
    <div class="name">Segment Name</div>
    <div class="count">People Count</div>
  </div>
  <div class="segment-picker-search-results" cdkDropList [cdkDropListData]="(searchResults$ | async)"
    #dropList="cdkDropList" [cdkDropListSortingDisabled]="true"
    [cdkDropListConnectedTo]="dragService.allDropLists$ | async">
    <div class="search-result" *ngFor="let segment of (searchResults$ | async)" cdkDrag #searchResult="cdkDrag"
      (cdkDragEntered)="dragEntered($event)" (cdkDragStarted)="dragStarted($event, segment)"
      (cdkDragDropped)="dragDropped($event)" [cdkDragData]="getDragData(segment)"
      [cdkDragDisabled]="!canSelectSegment(segment)"
      [ppcTooltip]="dragInstructions"
      [tooltipDisabled]="hideDragInstructionsTooltip" [tooltipDelayTime]="0"
      [class.disabled]="!canSelectSegment(segment)" [class.multi-selected]="segmentIsSelected(segment)" (click)="multiSelectSegment(segment)">
      <div class="checkbox-container" *ngIf="canSelectSegment(segment)">
        <i class="fa fa-lg" [ngClass]="segmentIsSelected(segment) ? 'fa-check-square' : 'fa-square-o'"></i>
      </div>
      <div class="drag-icon" [class.disabled]="!canSelectSegment(segment)">
        <i class="fa fa-ellipsis-v" *ngFor="let i of [1,2]"></i>
      </div>
      <div class="segment-path" [innerHTML]="segment.headline"></div>
      <div class="segment-count">
        <ppc-zero-count-warning-icon *ngIf="getSegmentCount(segment) === 0"></ppc-zero-count-warning-icon>
        {{ getSegmentCount(segment) | number }}
      </div>
      <div class="audience-builder-group-segment" *cdkDragPreview
        [style.width.px]="dragService.hoveredDropListWidth$ | async">
        <div class="drag-icon">
          <i class="fa fa-ellipsis-v" *ngFor="let i of [1,2]"></i>
        </div>
        <div class="segment-path">{{getSegmentPath(segment)}}</div>
        <div class="segment-count">{{ getSegmentCount(segment) | number }}</div>
        <div class="selected-segment-count-badge" *ngIf="hasMultipleSelectedSegments(segment)">
          {{ selectedSegments.length }}
        </div>
        <div class="remove-icon">
          <img src="assets/icons/close.svg" />
        </div>
      </div>
    </div>
  </div>
  <mat-paginator [length]="searchCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvents$.next($event)" *ngIf="searchCount">
  </mat-paginator>
</div>
