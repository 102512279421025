import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from "@ngrx/store";
import { Workbook } from 'exceljs/dist/exceljs';
import { saveAs } from "file-saver";

import { AppState } from "app/reducers";
import { PpcHttpService } from 'app/services/ppc_http.service';
import { fetchResource } from 'app/shared/utils/fetch-state';
import { IndexReport } from 'app/index-report/index-report.model';
import { IndexReportTask } from 'app/report-tasks/report-tasks.model';
import * as actions from 'app/index-report/index-report.actions';
import { IndexReportExport } from 'app/index-report/index-report-export.model';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';
import { tasksUrl } from '../shared/constants/id_analytics.urls';

// 5 minutes
export const CRON_TIMER = 5 * 1000 * 60;

@Injectable()
export class IndexReportService {

  @Effect()
  fetchIndexReports$ = this.actions$.pipe(
    ofType(actions.FetchIndexReports.type),
    fetchResource(
      action => this.getIndexReports().pipe(
        map(reports => new actions.LoadIndexReports(reports))
      )
    )
  );

  @Effect()
  fetchIndexReport$ = this.actions$.pipe(
    ofType(actions.FetchIndexReport.type),
    fetchResource(
      action => this.getIndexReport(action.id).pipe(
        map(report => new actions.LoadIndexReport(report))
      )
    )
  )

  @Effect()
  initiateIndexReport$ = this.actions$.pipe(
    ofType(actions.InitiateIndexReport.type),
    fetchResource(
      action => this.initiateIndexReport(action.resources).pipe(
        map(report => new actions.LoadIndexReport(report))
      )
    )
  )

  constructor(private http: PpcHttpService,
    private actions$: Actions,
    private store: Store<AppState>) {
  }

  generateWorkbook(
    data: IndexReport,
    vendorNames: VendorDisplayName[],
    layoutType,
    context,
    taskId: number,
    allow_dataset_skew: boolean,
    allow_unknown_rate_skew: boolean,
    ui_name: string,
    createFile = true) {
    const helper = new IndexReportExport(data,
      vendorNames,
      context,
      layoutType,
      allow_dataset_skew,
      allow_unknown_rate_skew);
    const fileName = helper.getWorkbookName(ui_name);
    const wb = new Workbook();

    const reportDetailsWs = wb.addWorksheet('Report Details');
    helper.addReportDetails(reportDetailsWs);

    const indexReportWs = wb.addWorksheet('Index Report');
    helper.addIndexReport(indexReportWs);

    if (createFile) {
      wb.xlsx.writeBuffer().then(d => {
        const blob = new Blob([d], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, fileName)
      });
    }
    this.store.dispatch(new actions.RemoveActionInProgress(taskId));
  }

  initiateIndexReport(resources: Partial<IndexReportTask>[]) {
    return this.http.post(tasksUrl(), resources) as Observable<IndexReportTask>;
  }

  getIndexReports(): Observable<IndexReportTask[]> {
    return this.http.get(tasksUrl()) as Observable<IndexReportTask[]>;
  }

  getIndexReport(id: number): Observable<IndexReportTask> {
    return this.http.get(`${tasksUrl()}/${id}`) as Observable<IndexReportTask>;
  }

  downloadIndexReport(requestId: string) {
    const params = { request_id: requestId };

    return this.http.get(`${tasksUrl()}/download_file`, { params }) as Observable<IndexReport>;
  }
}
