export const MediaTypeColors = {
  "TV": "#894C7B",
  "Online Display": "#BDDF93",
  "Online Video": "#E98841",
  "Mobile": "#81CACF",
  "VOD": "#BE5873",
  "Social": "#205493",
  "Partnership": "#F0CBE8",
  "Out of Home": "#F6F5DE",
  "Radio": "#FFE0C9",
  "Video Seeding": "#E6FCFE",
  "Mobile Display": "#FFDDE6",
  "Digital Commerce": "#C1DAF6",
  "Cinema": "#532D4A",
  "Print": "#9D9421",
  "Search": "#AE632D"
}
