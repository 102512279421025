
import {Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnInit} from "@angular/core";
import {AttributeAnalysis, Creative, ScoredCreative} from "../creative.reducers";
import {Observable} from "rxjs";
import {PpcHttpService} from "app/services/ppc_http.service";
import {creativeImageUrl} from "app/services/creative.service";
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers";
import {fullContext} from "app/hierarchy/hierarchy.reducers";

@Component({
  selector: 'app-genome-attribute-detail',
  templateUrl: './genome-attribute-detail.component.html',
  styleUrls: ['./genome-attribute-detail.component.sass']
})
export class GenomeAttributeDetailComponent implements OnChanges, OnInit {
  @Output()
  closeClick = new EventEmitter();

  @Input()
  attributeAnalyses$: Observable<AttributeAnalysis[]>;
  attributeAnalyses: AttributeAnalysis[];
  @Input()
  attributeId: string;
  @Input()
  competitorName: string;
  @Input()
  clientName: string;
  @Input()
  clientCreatives: ScoredCreative[];
  @Input()
  competitorCreatives: ScoredCreative[];

  sortedClientCreatives: ScoredCreative[];
  sortedCompetitorCreatives: ScoredCreative[];

  selectedCreative: ScoredCreative;

  productId: string;

  constructor(
    private store: Store<AppState>,
    private http: PpcHttpService
  ) {
    fullContext(this.store).subscribe(
      hierarchy => this.productId = hierarchy.product && hierarchy.product.id
    )
  }

  ngOnInit(): void {
    this.attributeAnalyses$.subscribe(analyses => {
      this.attributeAnalyses = analyses;
      this.sortedClientCreatives = this.sortCreatives( this.clientCreatives, this.attributeAnalysis ).slice(0, 10);
      this.sortedCompetitorCreatives = this.sortCreatives( this.competitorCreatives, this.attributeAnalysis).slice(0, 10);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.attributeAnalysis) {return; }

    let updatedClientCreatives, updatedCompetitorCreatives;

    if (changes['clientCreatives'] && this.attributeAnalysis) {
      this.sortedClientCreatives = this.sortCreatives(changes['clientCreatives'].currentValue, this.attributeAnalysis).slice(0, 10);
      updatedClientCreatives = changes['clientCreatives'].currentValue;
    }
    if (changes['competitorCreatives'] && this.attributeAnalysis) {
      this.sortedCompetitorCreatives = this.sortCreatives(changes['competitorCreatives'].currentValue, this.attributeAnalysis).slice(0, 10);
      updatedCompetitorCreatives = changes['competitorCreatives'].currentValue;
    }
  }

  getCreativeScore(creative: ScoredCreative): number {
    return !!this.attributeAnalysis ? creative.scores[this.attributeAnalysis.id] : null;
  }

  selectCreative(creative: ScoredCreative) {
    this.selectedCreative = creative;
  }

  unselectCreative() {
    this.selectedCreative = null;
  }

  creativeImageUrl(creative: Creative): string {
    return creativeImageUrl(creative.id, this.productId)
  }

  private sortCreatives(creatives: ScoredCreative[], attributeAnalysis: AttributeAnalysis): ScoredCreative[] {
    return attributeAnalysis ? creatives.sort( (a, b) => b.scores[attributeAnalysis.id] - a.scores[attributeAnalysis.id]) : []
  }

  get attributeAnalysis(): AttributeAnalysis {
    return this.attributeAnalyses ? this.attributeAnalyses.find(a => a.id == this.attributeId) : null;
  }

}
