
import {distinctUntilChanged} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { forEach, debounce, remove } from 'lodash';
import { getYOffset } from 'app/shared/utils/utils';

@Injectable()
export class PopupService {
  elements: {element: HTMLElement, openUpwards: Subject<boolean>}[] = [];
  contentWindow: HTMLElement;
  constructor() {
    this.contentWindow = document.querySelector("#sidenav-content") as HTMLElement;

    if (!this.contentWindow) {return; }

    this.contentWindow.addEventListener("scroll", event => {
      this.checkElements();
    });
    this.contentWindow.addEventListener("click", event => {
      // clicking something might toggle an accordion or something
      this.checkElements();
    });
    window.addEventListener("resize", event => {
      this.checkElements();
    });
  }

  register(element: HTMLElement) {
    const openUpwards = new Subject<boolean>();
    const elementData = {openUpwards, element};
    this.elements.push(elementData);
    this.checkElements();
    return openUpwards.pipe(distinctUntilChanged());
  }

  unregister(element: HTMLElement) {
    remove(this.elements, {element: element})
  }

  checkElements = debounce(() => {
    if (!this.contentWindow) {return; }
    forEach(this.elements, elementData => {
      const { element, openUpwards } = elementData;
      const {top, bottom} = this.getContentBounds();
      const elementOffsetY = getYOffset(element);
      const elementOffsetYBottom = elementOffsetY + element.clientHeight + 40 // room for a tooltip;
      if (elementOffsetY > top && elementOffsetY < bottom) {
        // The elements origin is within bounds so it should be visible one way
        // or another
        if (elementOffsetYBottom > bottom) {
          openUpwards.next(true);
        } else {
          openUpwards.next(false);
        }
      }
    })
  }, 10)

  getContentBounds() {
    const offset = getYOffset(this.contentWindow);
    return {
      top: this.contentWindow.scrollTop + offset,
      bottom: this.contentWindow.clientHeight + this.contentWindow.scrollTop + offset
    };
  }

}
