<div class="radio-button-container" (click)="onSelected($event)" #radioButton [class.disabled]="(disabled$ | async)">
  <div class="radio-button" matRipple [matRippleCentered]="true" [matRippleDisabled]="noRipple">
    <div class="radio-button-radio">
    </div>
    <div class="radio-button-radio-circle" [class.selected]="(value$ | async) == value || isSelected">
    </div>
  </div>
  <div class="radio-button-label"
       [class.ellipsize]="ellipsize">
    <ng-content></ng-content>
  </div>
</div>
