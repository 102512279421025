import { combineLatest, filter, map, mergeMap } from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output, OnChanges, OnDestroy} from "@angular/core";
import {BehaviorSubject, Observable } from "rxjs";
import {Creative, CreativeAttribute, ScoredCreative, selectScoredCreatives} from "app/creative/creative.reducers";
import {PpcHttpService} from "app/services/ppc_http.service";
import {creativeImageUrl} from "app/services/creative.service";
import {ScoredAttribute} from "app/creative/creative-attribute-list/creative-attribute-list.component";
import {AppState} from "app/reducers";
import {Store, select} from "@ngrx/store";
import {filter as _filter, find, map as _map} from 'lodash';
import {brandColor} from "app/creative/creative.component";
import {FetchScores} from "app/creative/creative.actions";
import { isDefined } from 'app/shared/utils/utils';

@Component({
  selector: 'ppc-creative-detail-attribute-list',
  templateUrl: './creative-detail-attribute-list.component.html',
  styleUrls: ['./creative-detail-attribute-list.component.sass']
})
export class CreativeDetailAttributeListComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  productId: string;

  @Input()
  selectedCreative: Creative;
  selectedCreative$ = new BehaviorSubject<Creative>(null);

  @Output()
  closeClicked = new EventEmitter();

  scoredAttributes$: Observable<ScoredAttribute[]>;

  constructor(private http: PpcHttpService, private store: Store<AppState>) {}

  ngOnChanges(changes) {
    if (changes.selectedCreative) {
      this.selectedCreative$.next(changes.selectedCreative.currentValue)
    }
  }

  ngOnInit() {
    this.selectedCreative$.pipe(filter(c => c && c.id != void(0))).subscribe(c => {
      this.store.dispatch(new FetchScores([c.id]));


    });
    const scoredCreative$ = this.selectedCreative$.pipe(filter(isDefined),
      mergeMap(
        creative => this.store.select('creative').pipe(
          select(selectScoredCreatives),
          map(scoredCreatives => find(scoredCreatives, {id: creative.id})),
        )
      ),
      filter(isDefined), );

    this.scoredAttributes$ = scoredCreative$.pipe(
      combineLatest(this.store.select('creative', 'attributes')),
      map(([creative, attrs]) => {
        const attrsWithScores = _filter(attrs, attr => !!creative.scores[attr.id]);
        return [creative, attrsWithScores]
      }),
      map(([creative, attrs]: [ScoredCreative, CreativeAttribute[]]) =>
        _map(attrs, attr => ({
          ...attr,
          scores: [{ color: brandColor, val: creative.scores[attr.id] }]
        }))
          .sort((a, b) => b.scores[0].val - a.scores[0].val)
      ), )
  }

  ngOnDestroy() {
    this.selectedCreative$.complete();
  }

  get imageUrl(): string {
    return creativeImageUrl(this.selectedCreative.id, this.productId)
  }
}
