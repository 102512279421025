<div class="ppc-carousel-wrapper">
  <div class="ppc-carousel open">
    <div class="ppc-carousel-header">
      <div class="header-left">
        <h1>{{linkSet.title}}</h1>
        <div>{{linkSet.description}}</div>
      </div>
      <div class="header-right">
        <i class="fa fa-close fa-lg hoverable" (click)="dialogRef.close()"></i>
      </div>
    </div>
    <div class="ppc-carousel-body">
      <div class="icon-list">
        <ul class="layout horizontal">
          <li class="icon-item" *ngFor="let item of linkSet.links">
            <i class="fa" [ngClass]="item.icon"></i>
            <a target="_blank" [href]="item.url">{{item.title}}
              <i *ngIf="item.external" class="fa fa-external-link"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="ppc-carousel-footer">
      <div>
      <div class="right-content">
        <div>
          <button class="btn button" (click)="dialogRef.close()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>