import {Component, Input, Output, EventEmitter} from "@angular/core";
import {DecimalPipe, NgSwitch, NgSwitchCase, NgSwitchDefault} from "@angular/common";
import { tooltipMessageV2 } from 'app/audiences/shared/audience.constants';
import {
  jobStatusClass,
  jobStatusDisplay,
  isPeopleCountEstimated, hideTVPanelistColumn, displayPeopleCountEstimate, displayTvPanelists, canEstimatePanelists, expiredDate} from 'app/audiences/shared/audience.utils';
import {get} from "lodash";
import { AddressableCountMetadataService } from "../../../../models/addressable-count-metadata/addressable-count-metadata.service";
import {Barbs} from "../../../../insights/grow-v3/grow.reducer";

@Component({
  selector: 'ppc-detail-info-pane',
  templateUrl: './detail-info-pane.component.html',
  styleUrls: ['./detail-info-pane.component.sass']
})

export class DetailInfoPaneComponent {
  @Input() ppcObject;
  @Input() panelistDataSourceMessage;
  @Input() regionIsNotAustralia;
  @Input() canDisplayAddressableCounts;
  @Input() activeRegion;
  @Input() panelistCount;
  @Output() openActivationOverlay = new EventEmitter()
  @Output() sendToTardiis = new EventEmitter()
  @Output() openIndexReportForm = new EventEmitter()

  canEstimate: boolean = false;
  panelistConstants: Barbs;
  lookalikeConfidence;

  jobStatusClass = jobStatusClass;
  jobStatusDisplay = jobStatusDisplay;
  tooltipMessages = tooltipMessageV2;
  isPeopleCountEstimated = isPeopleCountEstimated;
  hideTVPanelistColumn = hideTVPanelistColumn;
  displayPeopleCountEstimate = displayPeopleCountEstimate;
  expiredDate = expiredDate;

  constructor(
    private addressableCountsService: AddressableCountMetadataService,
    private decimalPipe: DecimalPipe,
  ) {}

  canShowConfidence() {
    return this.ppcObject.type === 'lookalike';
  }

  handleSendToPartner() {
    this.openActivationOverlay.emit(this.ppcObject)
  }

  handleSendToTardiis() {
    this.sendToTardiis.emit(this.ppcObject)
  }

  handleCreateIndexReport() {
    this.openIndexReportForm.emit(this.ppcObject)
  }

  get calculatedPeopleCount(): string {
    const count = this.ppcObject?.count?.people?.global?.toLocaleString();
    const estimatedCount = displayPeopleCountEstimate(count)

    return isPeopleCountEstimated(this.ppcObject) ? estimatedCount : count
  }

  ngOnChanges() {
    if (this.ppcObject) {
      this.lookalikeConfidence = this.ppcObject.current_confidence + "%";
    }
  }

  addressableCountDisplay() {
    const peopleCount = get(this.ppcObject, ['count', 'people', 'global'], 0)
    return this.addressableCountsService.displayCalculatedAddressableCounts(peopleCount)
  }


}
