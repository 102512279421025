import { Component, OnInit, Input } from '@angular/core';
import { get } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { AudienceRuleGroup } from 'app/audience-builder/audience-builder.models';
import { isEntrySegment } from 'app/audience-builder/audience-builder.utils';
import { SegmentV2, prettyPathParts } from '../../../discover/segment-v2.model';
import { getStoreState } from 'app/shared/utils/utils';

@Component({
  selector: 'ppc-audience-rules-detail-group',
  templateUrl: './audience-rules-detail-group.component.html',
  styleUrls: ['./audience-rules-detail-group.component.sass']
})
export class AudienceRulesDetailGroupComponent implements OnInit {
  @Input() ruleGroup: AudienceRuleGroup;
  isEntrySegment = isEntrySegment

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
  }

  getSegmentPath(segment: SegmentV2) {
    const vendorDisplayNames = get(getStoreState(this.store), ['segmentsHierarchy', 'vendorDisplayNames']);
    return prettyPathParts(segment, vendorDisplayNames).join(" > ");
  }

  getSegmentCount(segment: SegmentV2) {
    return get(segment, ["count", "people", "global"], 0);
  }

}
