import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Activation } from './activation.model';
import { ActivationActions, LoadActivations, LoadUpdatedActivation } from './activation-management.actions';
import { PageInfo } from 'app/models/paginable.model';

export interface State extends EntityState<Activation> {
  pageInfo: PageInfo;
  companyIds?: number[];
  sourceSystems?: string[];
}

const adapter: EntityAdapter<Activation> = createEntityAdapter<Activation>();

const initialState: State = adapter.getInitialState({ pageInfo: {} });

export function reducer(state: State = initialState, action: ActivationActions): State {
  switch (action.type) {
    case LoadActivations.type:
      return adapter.setAll(action.activations, {
        ...state,
        pageInfo: action.pageInfo,
      });
    case LoadUpdatedActivation.type:
      return adapter.updateOne({ id: action.activation.id, changes: action.activation }, state);
    default:
      return state;
  }
}

const { selectAll } = adapter.getSelectors();

export const getActivationsList = createSelector(selectAll, (activationsList) => activationsList);
export const getPageInfo = createSelector(
  ({ pageInfo }) => pageInfo,
  (pageInfo) => ({ ...pageInfo, pageNumber: Math.floor(pageInfo.offset / pageInfo.limit) })
);
