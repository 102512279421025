import { Component } from '@angular/core';

@Component({
  selector: 'app-launch',
  templateUrl: './launch.component.html',
  styleUrls: ['./launch.component.sass']
})
export class LaunchComponent {

  constructor() {}
}
