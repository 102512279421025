import { apiUrl } from '../../services/ppc_http.service';
import { AUTH_API_PATH } from '../utils/constants';

export function apiKeysUrl(id = null): string {
  return apiUrl(id ? `${AUTH_API_PATH}/api_keys/${id}` : `${AUTH_API_PATH}/api_keys`);
}

export function userResourcesUrl(resourceId?: string): string {
  const url = `${AUTH_API_PATH}/resources/${resourceId || ''}`;
  return apiUrl(url);
}

export function roleUrl(roleId: number): string {
  return apiUrl(`${AUTH_API_PATH}/roles/${roleId}`);
}

export function rolesUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/roles.json`);
}

export function selfUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/users/self.json`);
}

export function confirmAccountUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/confirmations.json`);
}

export function agencyUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/agencies.json`);
}

export function requestAccessUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/request_access`);
}

export function directLoginUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/direct_login`);
}

export function permissionsUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/permissions`);
}

export function userReportUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/users/report?csv=true`);
}

export function loginReportUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/login_records`);
}

export function getAllUsersUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/users.json`);
}

export function getUserUrl(id): string {
  return apiUrl(`${AUTH_API_PATH}/users/${id}.json`);
}

export function userUrl(id = null): string {
  return apiUrl(id ? `${AUTH_API_PATH}/users/${id}` : `${AUTH_API_PATH}/users`);
}

export function getUpdatePasswordUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/users/self/update_password.json`);
}

export function getResetPasswordUrl(): string {
  return apiUrl(`${AUTH_API_PATH}/users/password`);
}
