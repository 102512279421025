import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { selectSelectedJourney, selectSelectedBrand } from '../journey.reducer';
import { merge as observableMerge, of as observableOf } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from "@angular/material/dialog";

import { overviewSlides } from "app/journey/journey.constants";
import { PpcCarouselComponent } from "app/shared/components/ppc-carousel/ppc-carousel.component";
import { Journey, JourneyTabType, JourneyStage, JourneyBrand } from '../journey.models';
import { JourneyCountService } from '../journey-count.service';
import * as actions from "../journey.actions";
import { reduce, get, find, truncate } from 'lodash';
import { JourneyStageComponent } from './journey-stage/journey-stage.component';
import { TopLevelTabType, Tab } from 'app/insights/insights.models';
import * as insightsActions from "app/insights/insights.actions";
import { isCompareMode } from '../../insights/insights.reducer';

export const JOURNEY_BRAND_COLORS = ["#205493", "#BE5873", "#6f3d64", "#6baeb2"]

@UntilDestroy()
@Component({
  selector: 'ppc-journey-chart',
  templateUrl: './journey-chart.component.html',
  styleUrls: ['./journey-chart.component.sass']
})
export class JourneyChartComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(JourneyStageComponent) stageElements: QueryList<JourneyStageComponent>;
  journey: Journey;
  activeTab$ = this.store.select("journey", "journeyTab");
  brandColors = JOURNEY_BRAND_COLORS;
  selectedBrand: JourneyBrand;
  selectedBrandId: number;
  focusedComponent$ = this.store.select("insights", "journey", "splitScreenFocus");
  stagePairs: {[stageId: number]: [JourneyStageComponent, JourneyStageComponent]} = {};
  insightsTab: TopLevelTabType;
  tabs: Tab[];
  brandSelectInfoTooltipDismissed: boolean = false;
  isCompareMode$ = this.store.select("insights", "journey").pipe(select(isCompareMode));

  constructor(private store: Store<AppState>, public counts: JourneyCountService, private matDialog: MatDialog) {
    store.select("journey").pipe(
      select(selectSelectedJourney),
      filter(Boolean),
      untilDestroyed(this)
    ).subscribe((journey: Journey) => {
      if (!this.journey || journey.id != this.journey.id) {
        this.store.dispatch(new actions.SetSelectedBrandId(journey.brands[0].id))
      }
      this.journey = journey
    })

    store.select("journey").pipe(
      select(selectSelectedBrand),
      filter(Boolean),
      untilDestroyed(this)
    ).subscribe((selectedBrand: JourneyBrand) => {
      this.selectedBrand = selectedBrand;
      this.selectedBrandId = selectedBrand.id;
    })

    store.select("insights", "journey", "topLevelTab").pipe(
      untilDestroyed(this)
    ).subscribe(topLevelTab => this.insightsTab = topLevelTab)

    store.select("insights", "journey", "tabs").pipe(
      untilDestroyed(this)
    ).subscribe(tabs => this.tabs = tabs)

  }

  ngOnInit() {
  }

  openCarousel() {
    this.matDialog.open(PpcCarouselComponent, {data: overviewSlides, panelClass: "no-padding"})
  }

  ngAfterViewInit() {
    observableMerge(
      observableOf(this.stageElements),
      this.stageElements.changes
    ).pipe(
      map(queryList => queryList.toArray()),
      map(elements => reduce(elements, (pairs, element: JourneyStageComponent, idx: number) => {
        if (idx === elements.length - 1) {return pairs; }
        return {...pairs, [element.stage.id]: [element, elements[idx + 1]]}
      }, {})),
      untilDestroyed(this)
    ).subscribe(stagePairs => this.stagePairs = stagePairs)
  }

  ngOnDestroy() { }

  getPercentageForGauge(stage: JourneyStage) {
    const stageCount = this.counts.getCountForStage(stage);
    if (stageCount == null) {return null; }
    const percentage = stageCount / this.counts.totalCount;
    return percentage * 100;
  }

  tabChange(tab: JourneyTabType) {
    this.store.dispatch(new actions.SetJourneyTab(tab));
    switch (this.insightsTab) {
      case "Market Level":
        if (tab != "Total Population") {
          this.store.dispatch(new insightsActions.SetTopLevelTab("Person Level", "journey"))
        }
        break;
      case "Person Level":
        if (tab == "Total Population") {
          this.store.dispatch(new insightsActions.SetTopLevelTab("Market Level", "journey"))
        }
        break;
    }
  }

  selectBrandId(brandId: number) {
    this.store.dispatch(new actions.SetSelectedBrandId(brandId));
  }

  isTabTypeAllowed(tabType: TopLevelTabType): boolean {
    if (!this.tabs) {return true; }
    return get(find(this.tabs, {name: tabType}), "visible")
  }

  truncate(name: string): string {
    return truncate(name, {length: 20});
  }

  nameTemplate(name: string): string {
    if (name.length > 20) {
      return name;
    }
  }

  isDisabled(name: string): boolean {
    return name.length <= 20
  }

  getClassName() {
    const totalWidth = 300 * this.journey.stages.length;
    return (totalWidth > window.innerWidth) ? "justify-left" : "justify-center";
  }
}
