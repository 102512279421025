import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { JourneyStage, Journey, JourneyBrand } from 'app/journey/journey.models';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { selectSelectedJourney } from 'app/journey/journey.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { sortBy } from 'lodash';
import { JOURNEY_BRAND_COLORS } from '../../journey-chart.component';
import { JourneyCountService } from 'app/journey/journey-count.service';
import { toMillions } from 'app/insights/insights.models';

@UntilDestroy()
@Component({
  selector: 'ppc-journey-stage-mini-panel',
  templateUrl: './journey-stage-mini-panel.component.html',
  styleUrls: ['./journey-stage-mini-panel.component.sass']
})
export class JourneyStageMiniPanelComponent implements OnInit, OnDestroy {
  @Input() stage: JourneyStage;
  journey: Journey;

  constructor(private store: Store<AppState>, private counts: JourneyCountService) { }

  ngOnInit() {
    this.store.select("journey").pipe(
      select(selectSelectedJourney),
      untilDestroyed(this)
    ).subscribe(journey => this.journey = journey)
  }

  ngOnDestroy() { }

  trackByIndex(index, item) {
    return index
  }

  get brands() {
    return sortBy(this.journey.brands, "order");
  }

  getHeightForBrand(brand: JourneyBrand) {
    if (this.counts.loadingCounts) {return "null"; }
    const brandCount = this.counts.getCountForBrandStage(brand, this.stage);
    const result = brandCount / this.counts.getTotalCountForBrand(brand) * 100
    return Number.isFinite(result) ? result : 0;
  }

  getCountStringForBrand(brand: JourneyBrand) {
    return toMillions(this.counts.getCountForBrandStage(brand, this.stage));
  }

  getPercentageForBrand(brand: JourneyBrand) {
    const count = this.counts.getCountForBrandStage(brand, this.stage) || 0;
    const result = count / this.counts.getTotalCountForBrand(brand) * 100;
    return Number.isFinite(result) ? result : 0;
  }

  getColorForBrand(brand: JourneyBrand) {
    return JOURNEY_BRAND_COLORS[brand.order];
  }

}
