import {
  ERROR_MSG_CONVERSANT_COMPANY_ID_INVALID,
  CONVERSANT_COMPANY_ID_MIN,
  CONVERSANT_COMPANY_ID_MAX
} from './../../shared/utils/constants';
import {Component, EventEmitter, Input, Output, ViewChildren, QueryList, OnChanges} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import {Actions} from "@ngrx/effects";
import {filter, find, get, countBy, values} from 'lodash';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {Brand} from "app/brand-admin/brand/brand.model";
import {Product} from "app/brand-admin/brand/product/product.model";
import {BrandService} from "./brand.service";
import {ProductComponent, ProductValidators} from "./product/product.component";
import {FetchHierarchy} from "../../hierarchy/hierarchy.actions";
import {AppState} from "../../reducers";
import {Store} from "@ngrx/store";
import { Region } from 'app/admin/region/region.model';
import { getRegions } from 'app/admin/region/region.reducers';
import {alphabetizedRegionNamesList} from "app/hierarchy/hierarchy.utils";
import { InputValidator } from "app/shared/components/ppc-input/ppc-input.component";
import { tabAdminUrl } from 'app/shared/constants/insights.urls';

const DELETE_BRAND_FAIL_MSG = "We were unable to delete that brand. Please try again.";
const SAVE_BRAND_FAIL_MSG = "We were unable to save that brand. Please try again.";
const MEKKO_SUCCESS = "Successfully created Vertical Grow Chart";
const MEKKO_FAILURE = "Vertical Grow Chart creation failed. Please try again.";

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.sass']
})
export class BrandComponent implements OnChanges {

  @ViewChildren(ProductComponent) productComps: QueryList<ProductComponent>;

  @Input() client;
  @Input() region;
  @Input() brandIndex: number;
  @Input() brand: Brand;
  @Output() deleteBrand: EventEmitter<number> = new EventEmitter();

  formInSubmitting: boolean = false;
  listCollapsed: boolean = true;
  errorMessages = new Set<string>();
  ngUnsubscribe = new Subject();
  regions: Region[] = [];
  regionNamesList: string = 'None';
  marketLevelOnlyRegions: string[] = [];
  brandProductsNameUpdated$ = new Subject<undefined>();
  productValidators: ProductValidators = {
    isProductNameAlreadyTaken: this.isProductNameAlreadyTaken.bind(this)
  };

  readonly CONVERSANT_COMPANY_ID_MIN = CONVERSANT_COMPANY_ID_MIN;
  readonly CONVERSANT_COMPANY_ID_MAX = CONVERSANT_COMPANY_ID_MAX;

  conversantIdInputValidators: InputValidator[] = [
    {
      errorMessage: ERROR_MSG_CONVERSANT_COMPANY_ID_INVALID,
      isValid: value => value ? this.isConversantCompanyIdValid(value) : true
    }
  ];

  constructor(
    private brandService: BrandService,
    private store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private http: HttpClient
  ) {
    this.store.select('regions').pipe(
      map(getRegions),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((regions) => {
      this.regions = regions;
    });

    this.destroyBrand = this.destroyBrand.bind(this);

    this.http.get(tabAdminUrl()).subscribe(tabs => {
      const personLevelTab = find(tabs, {tab_key: "top_level_person", insights_context: 'grow'});
      this.marketLevelOnlyRegions = get(personLevelTab, "excluded_regions")
    })
  }

  ngOnChanges(): void {
    this.regionNamesList = alphabetizedRegionNamesList(this.brand.products, this.client.regions);
  }

  editBrand(event): void {
    this.brand.open();
    this.listCollapsed = true;
    event.stopPropagation();
  }

  exitEditBrand(event): void {
    this.brand.close();
    this.listCollapsed = true;
    event.stopPropagation();
  }

  applyBrand(): void {
    this.brand.close();
    this.brand.openAllProducts();
  }

  destroyBrand(event): void {
    this.deleteBrand.emit(this.brandIndex);
    event.stopPropagation();

    if (!!this.brand.id) {
      this.brandService.deleteBrand(this.brand.id)
        .subscribe(
          () => {
            this.errorMessages.delete(DELETE_BRAND_FAIL_MSG);
            this.store.dispatch(new FetchHierarchy());
          },
          () => this.errorMessages.add(DELETE_BRAND_FAIL_MSG)
        )
    }
  }

  saveAll(): void {
    this.formInSubmitting = true;
    const payload = this.getPayload();
    if (!this.brand.id) {
      this.createBrand(payload);
    } else {
      this.updateBrand(this.brand.id, payload);
    }
  }

  addProduct(event): void {
    const product = new Product();
    product.open();
    this.brand.products.push(product);
    this.listCollapsed = false;
    event.stopPropagation();
  }

  onProductNameUpdated(updatedName: string): void {
    this.brandProductsNameUpdated$.next();
  }

  onDeleteProduct(product: Product): void {
    const index = this.brand.products.findIndex((candidate) => candidate.id === product.id);
    this.brand.products.splice(index, 1);
  }

  toggleList(): void {
    if (this.brand.isClosed) {
      this.listCollapsed = !this.listCollapsed;
    }
  }

  applyDisabled(): boolean {
    return !this.brand.name || (this.brand.conversant_company_id
      && !this.isConversantCompanyIdValid(this.brand.conversant_company_id));
  }

  saveDisabled(): boolean {
    return this.brand.invalid
      || !(this.allProductRegionsAssigned() && this.allProductNamesUniq())
      || this.formInSubmitting
      || !this.areAllConversantIdsValid();
  }

  private isConversantCompanyIdValid(id: number): boolean {
    return (id >= CONVERSANT_COMPANY_ID_MIN && id <= CONVERSANT_COMPANY_ID_MAX);
  }

  private isProductNameAlreadyTaken(name: string): boolean {
    const currentProductName =  name.toLowerCase();
    const productsWithSameName = this.brand.products
      .filter(product => product.name.toLowerCase() === currentProductName);
    return productsWithSameName.length > 1;
  }

  private areAllConversantIdsValid(): boolean {
    let isValid: boolean = true;
    const products = filter(this.brand.products, p => p.conversant_company_id) as Product[];

    if (this.brand.conversant_company_id) {
      isValid = this.isConversantCompanyIdValid(this.brand.conversant_company_id);
    }

    if (products.length) {
      isValid = products.every(p => p.conversant_company_id && this.isConversantCompanyIdValid(p.conversant_company_id));
    }

    return isValid;
  }

  private allProductNamesUniq(): boolean {
    const repeated = countBy(this.brand.products, function(attr) {
      return attr['name'] && attr['name'].toLowerCase().replace(' ', '+');
    });
    return values(repeated).every((v) => v === 1);
  }

  private allProductRegionsAssigned(): boolean {
    return this.brand.products.every((p) => p.region_ids.length > 0)
  }

  private createBrand(payload): void {
    this.brandService.createBrand(payload)
      .subscribe(
        (brand: Brand) => {
          this.errorMessages.delete(SAVE_BRAND_FAIL_MSG);
          brand.openAllProducts();
          this.store.dispatch(new FetchHierarchy());
          this.brand.closeAllProducts();
        },
        error => {
          this.errorMessages.add(SAVE_BRAND_FAIL_MSG);
        },
        () => {
          this.formInSubmitting = false;
        }
      );
  }

  private updateBrand(id: string, payload): void {
    this.brandService.updateBrand(id, payload)
      .subscribe(
        (brand: Brand) => {
          this.errorMessages.delete(SAVE_BRAND_FAIL_MSG);
          this.store.dispatch(new FetchHierarchy());
          this.brand.closeAllProducts();
        },
        error => {
          this.errorMessages.add(SAVE_BRAND_FAIL_MSG);
        },
        () => {
          this.formInSubmitting = false;
        }
      );
  }

  private getPayload(): any {
    const productsPayload = this.brand.products.map(p => {
      const dat = {
        name: p.name,
        conversant_company_id: p.conversant_company_id,
        brand_id: this.brand.id,
        region_ids: p.region_ids
      };

      if (p.id) {
        dat['id'] = p.id;
      }

      return dat;
    });

    return {
      brand: {
        id: this.brand.id,
        name: this.brand.name,
        category: this.brand.category,
        conversant_company_id: this.brand.conversant_company_id,
        client_id: this.client.id,
        products_attributes: productsPayload
      }
    };
  }

}
