import {map, filter, catchError, tap, take, refCount, publishReplay} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PpcHttpService, apiUrl } from '../services/ppc_http.service';
import { UserForm } from '../user-settings/user-settings.component';
import {Observable, combineLatest, of as observableOf} from 'rxjs';
import { UpdatePasswordResponse } from '../shared/interfaces/update-password-response.interface';
import { User } from './user.interface';
import { Actions, Effect, ROOT_EFFECTS_INIT, ofType } from '@ngrx/effects';
import { fetchResource } from '../shared/utils/fetch-state';
import { FetchAllUsers, FetchCurrentUser, LoadCurrentUser, LoadUsers } from './user.actions';
import { Store, select } from "@ngrx/store";
import { AppState } from "app/reducers";
import { loggedInUser } from "app/users/user.reducer";
import { LocalStorageService } from 'app/services/local-storage.service';
import {environment} from "environments/environment";
import {
  getAllUsersUrl,
  getResetPasswordUrl,
  getUpdatePasswordUrl,
  getUserUrl,
  selfUrl,
  userUrl,
} from 'app/shared/constants/auth.urls';

export interface ChangePasswordForm {
  password: string;
  new_password: string;
  new_password_confirmation: string;
}

export interface ResetPasswordForm {
  user: {
    reset_password_token: string;
    password: string;
    password_confirmation: string;
  };
}

export interface RequestPasswordResetForm {
  user: { email: string };
}

export interface UpdateUserForm {
  id: number;
  agency_id: number;
  role_ids: number[];
  lion_login: string;
  okta_login: string;
  deleted_at?: string
}

@Injectable()
export class UserService {

  @Effect()
  fetchUsers$ = this.actions$.pipe(
    ofType(FetchAllUsers.type),
    fetchResource(
      () => this.getAllUsers().pipe(map(users => new LoadUsers(users)))
    )
  )

  @Effect()
  fetchCurrentUser$ = this.actions$
    .pipe(
      ofType(FetchCurrentUser.type),
      fetchResource(
        ({ping}) => this.fetchCurrentUser(ping).pipe(
          map(user => new LoadCurrentUser(user)),
          catchError(() => observableOf({})),
        )
      )
    ) // silently filter out failed attempts


  @Effect()
  getUser$ = this.actions$.pipe(ofType(ROOT_EFFECTS_INIT), map(() => new FetchCurrentUser()))

  isUserPosing$ = this.store.select('users').pipe(
    select(loggedInUser),
    map(user => {
      const parentUser = JSON.parse(this.localStorage.getValue('parentUser'))
      return !!(user && parentUser && parentUser.id && user.id !== parentUser.id)
    }),
    publishReplay(1),
    refCount()
  )

  constructor(
    private http: PpcHttpService,
    private actions$: Actions,
    private store: Store<AppState>,
    private localStorage: LocalStorageService
  ) { }

  loginAttempts = () => combineLatest(
    this.store.select('users').pipe(select(loggedInUser)),
    this.store.select('fetchStates', FetchCurrentUser.type).pipe(
      map(x => x || {}),
      filter(({ lastErrorTime, lastLoadTime }) => !!lastErrorTime || !!lastLoadTime))
  ).pipe(map(([ user ]) => user))

  fetchCurrentUser(ping): Observable<User> {
    const params = ping ? {params: ping} : {params: null};
    return this.http.get(selfUrl(), params);
  }

  disableUser(id: number): Observable<any> {
    return this.http.delete(userUrl(id))
  }

  createUser(form: UserForm): Observable<User> {
    return this.http.post(userUrl(), form)
  }

  updateUser(updatedUser: UpdateUserForm) {
    return this.http.put(userUrl(updatedUser.id), updatedUser)
  }

  changePassword(form: ChangePasswordForm): Observable<UpdatePasswordResponse> {
    return this.http.post(getUpdatePasswordUrl(), form)
  }

  getUser(id: number): Observable<User> {
    return this.http.get(getUserUrl(id));
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get(getAllUsersUrl());
  }

  resetPassword(form: ResetPasswordForm): Observable<any> {
    return this.http.put(getResetPasswordUrl(), form);
  }

  requestPasswordReset(form: RequestPasswordResetForm): Observable<any> {
    return this.http.post(getResetPasswordUrl(), form);
  }

  switchUser(userId: number) {
    if (!environment.viewAs) {return; }
    return combineLatest(
      this.http.get(apiUrl(`/switch-user?userId=${userId}`), {observe: 'response', responseType: 'text'}),
      this.store.select('users').pipe(take(1), select(loggedInUser))
    ).pipe(
      tap(([_, parentUser]) => {
        this.localStorage.setValue('parentUser', JSON.stringify(parentUser))
        window.location.href = '/'
      })
    );
  }

  revertUserSwitch(): Observable<void> {
    if (!environment.viewAs) {return; }
    return this.http.get(apiUrl(`/revert-user-switch`), {observe: 'response', responseType: 'text'}).pipe(
      tap(() => {
        localStorage.removeItem('parentUser')
        window.location.href = '/global/admin/user-settings'
      })
    );
  }
}
