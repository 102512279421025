import {combineLatest as observableCombineLatest, merge as observableMerge} from 'rxjs';
import {takeUntil, map, filter, debounceTime} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, HostBinding, Input, Inject, OnChanges } from '@angular/core';
import { Store, select } from "@ngrx/store";
import { DragulaService } from 'ng2-dragula';
import { Subject, Observable } from 'rxjs';
import { filter as _filter, get } from 'lodash';

import { selectMarketLevelCustomTabDemographics } from 'app/insights/insights.reducer';
import { newMarketLevelDemographic, MarketLevelDemographic, marketLevelDemographicHasSubMarketEntry } from "app/insights/insights-components/market-level-demographics/market-level-demographic.interface";
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { SubMarket, selectActiveMekkoSubMarkets, selectSelectedSubMarkets } from 'app/mekko/mekko.reducer';
import { selectSelectedJourney, selectSelectedJourneySubMarkets } from 'app/journey/journey.reducer';
import { JourneySubMarket } from 'app/journey/journey.models';
import { Persona, selectActivePersona } from 'app/explore/explore.reducer';
import { AppState } from 'app/reducers';
import { Tab } from 'app/insights/insights.models';
import * as insightsActions from 'app/insights/insights.actions';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';

@Component({
  selector: 'ppc-market-level-custom-tab-demographics',
  templateUrl: './market-level-custom-tab-demographics.component.html',
  styleUrls: ['./market-level-custom-tab-demographics.component.sass']
})
export class MarketLevelCustomTabDemographicsComponent extends InsightsResourceTracker implements OnInit, OnDestroy, OnChanges {
  @Input() tab: Tab;

  marketLevelDemographics: MarketLevelDemographic[];
  bagName: string;
  ngUnsubscribe = new Subject();
  resourceSubMarkets?: SubMarket[] | JourneySubMarket[];
  allResourceItems: SubMarket[] | JourneySubMarket[] | Persona[];
  permissions$ = this.store.select("permissions", "market_level_demographics");
  @HostBinding("class.jumbo") insightsFocused: boolean;
  forceLayout$: Observable<{}>;
  isActiveTab$: Observable<boolean>;

  constructor(public store: Store<AppState>, private dragula: DragulaService, @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super(store, insightsContext)
  }

  ngOnInit() {
    this.forceLayout$ = observableMerge(
      this.store.select("insights", this.insightsContext, "splitScreenFocus"),
      this.store.pipe(select(selectMarketLevelCustomTabDemographics(this.insightsContext, this.tab.id)))
    ).pipe(debounceTime(450))

    observableCombineLatest([
      this.store.pipe(select(selectMarketLevelCustomTabDemographics(this.insightsContext, this.tab.id))),
      this.store.select("mekkos").pipe(select(selectActiveMekkoSubMarkets)),
      this.store.select("mekkos").pipe(select(selectSelectedSubMarkets)),
      this.store.select("journey").pipe(select(selectSelectedJourney)),
      this.store.select("journey").pipe(select(selectSelectedJourneySubMarkets)),
      this.store.select("explore").pipe(select(selectActivePersona)),
    ]).pipe(
      filter(([marketLevelDemographics, subMarkets, selectedSubMarkets, journey, selectSelectedJourneySubMarkets, persona]) => {
        return marketLevelDemographics && (
          (this.resourceType == 'Mekko' && !!subMarkets) ||
        (this.resourceType == 'Journey' && !!journey) ||
        (this.resourceType == 'Persona' && !!persona)
        )
      }),
      map(([marketLevelDemographics, subMarkets, selectedSubMarkets, journey, selectSelectedJourneySubMarkets, persona]) => {
      // Generalizing `allResourceItems` to fit for both submarkets[] and persona[]
        let allResourceItems;
        let resourceSubMarkets;

        switch (this.resourceType) {
          case 'Mekko':
            allResourceItems = subMarkets;
            resourceSubMarkets = selectedSubMarkets.length ? selectedSubMarkets : subMarkets;
            break;
          case 'Journey':
            allResourceItems = journey.sub_markets;
            resourceSubMarkets = selectSelectedJourneySubMarkets;
            break;
          case 'Persona':
            allResourceItems = [persona];
            resourceSubMarkets = [persona];
            break;
        }

        return [ marketLevelDemographics, resourceSubMarkets, allResourceItems ]
      }),
      filter(([marketLevelDemographics, resourceSubMarkets, allResourceItems]) => {
      // subMarkets and market level demographics sub market bucket entries should be in sync at
      // all times. There is some logic to keep them in sync but there may be invalid intermediate states
      // while the async ops are happening. Its solved here by simply rejecting invalid states
        return resourceSubMarkets.every(subMarket => {
          return marketLevelDemographics.every(mld => marketLevelDemographicHasSubMarketEntry(mld, subMarket))
        })
      }), )
      .subscribe(
        ([marketLevelDemographics, resourceSubMarkets, allResourceItems]: [MarketLevelDemographic[], JourneySubMarket[] | SubMarket[], JourneySubMarket[] | SubMarket[] | Persona[]]) => {
          this.marketLevelDemographics = marketLevelDemographics;
          this.resourceSubMarkets = resourceSubMarkets;
          this.allResourceItems = allResourceItems;
        }
      )

    this.bagName = `market-level-widgets-custom-${this.tab.id}`

    this.dragula.setOptions(this.bagName, {
      moves: (el, container, handle) => /drag-handle/.test(handle.className)
    })

    this.dragula.dropModel.pipe(
      filter(([bagName]) => bagName === this.bagName), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.saveConfig();
      })

    this.store.select("grow", "growV3Focus").pipe(
      map(focus => focus == "insights"),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(insightsFocused => this.insightsFocused = insightsFocused)
  }

  ngOnChanges() {
    this.isActiveTab$ = this.store.select("insights", this.insightsContext, "marketLevelTab").pipe(map(marketLevelTab => marketLevelTab == get(this.tab, "name")))
  }

  ngOnDestroy() {
    this.dragula.destroy(this.bagName);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    super.ngOnDestroy();
  }

  hideDemographic(demographic: MarketLevelDemographic) {
    demographic.visible = false;
    this.saveConfig();
  }

  addWidget() {
    this.store.dispatch(new insightsActions.EditMarketLevelDemographic(this.insightsContext, newMarketLevelDemographic(this.resourceId, this.allResourceItems, this.tab.id, this.resourceType)))
  }

  saveConfig(marketLevelDemographics?: MarketLevelDemographic[]) {
    this.store.dispatch(new insightsActions.SaveDemographicsConfig((marketLevelDemographics || this.marketLevelDemographics).map((demographic) => {
      return {
        id: demographic.id,
        visible: !!demographic.visible
      }
    }), userPreferenceKeys.customTab(this.tab.id), this.resourceId, this.resourceType, this.insightsContext))
  }

  trackById(index, item) {
    return item.id;
  }

  get visibleDemographics(): MarketLevelDemographic[] {
    return _filter(this.marketLevelDemographics, "visible");
  }

}
