import { NgModule } from '@angular/core';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { EpcComponentsModule } from 'app/modules/epc-components.module';

import { CreateLookalikeModalComponent } from './create-lookalike-modal/create-lookalike-modal.component';

@NgModule({
  imports: [
    PpcComponentsModule,
    EpcComponentsModule,
  ],
  exports: [
    CreateLookalikeModalComponent,
  ],
  declarations: [
    CreateLookalikeModalComponent,
  ]
})
export class CreateLookalikeModule { }
