import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CheckboxStatus} from "../components/ppc-checkbox/ppc-checkbox.component";

// This component differs from tree-multi-select by allowing the selection of any node in the tree
// instead of just leaf nodes.
// This component can handle any sort of tree as long as you can implement getChildren and getStatus.
// This component is stateless, any clicks are propagated to the top level where the host element must
// deal with storing any resulting state and passing it back in via getStatus.

@Component({
  selector: 'tree-node-select',
  templateUrl: './tree-node-select.html',
  styleUrls: ['./tree-node-select.sass']
})
export class TreeNodeSelectComponent {

  @Input()
  getChildren: (node: any) => any[] = node => node.children || [];

  @Input()
  getName: (node: any) => string = node => node.name;

  @Input()
  getId: (node: any) => string = node => node.id;

  @Input()
  getStatus: (path: string[], node: any) => CheckboxStatus = () => 'unchecked';

  @Input()
  path: any[] = [];

  @Input()
  node: any;

  @Output()
  change = new EventEmitter<{path: any[], checked: boolean}>();

  open: boolean = false;

  constructor() { }

  get nextPath() {
    return this.path.concat(this.node || [])
  }

  get status() {
    return this.getStatus(this.nextPath, this.node)
  }

  onCheck(event: boolean) {
    this.change.emit({checked: event, path: this.nextPath})
  }

}
