import { Category } from 'app/toolbox/category.interface';
import { ElementRef, Renderer2, Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Unit } from '../unit.interface';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { toHTML } from 'app/shared/utils/utils';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { cloneDeep } from 'lodash';
import { EditUnit, HideUnit} from 'app/toolbox/toolbox.actions';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.sass'],
})
export class UnitComponent implements OnInit, AfterViewInit {
  @ViewChild('meta', { static: true }) metaDomElement: ElementRef;
  @Input() unit: Unit;
  @Input() unitIndex: number;
  @Input() category: Category;
  @Input() collapsed: boolean;
  @Input() editMode: boolean;
  @Input() width: any;
  @Output() toggled = new EventEmitter<Unit>();
  @Output() modalMode: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _scrollToService: ScrollToService,
    private renderer: Renderer2,
    private store: Store<AppState>
  ) {
  }

  interval: number = 230;
  toHtml = toHTML;

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.placeMetaInfo();
  }

  leftCalc(index) {
    return `${(index % 5 * -this.interval)}px`;
  }

  placeMetaInfo() {
    this.renderer.setStyle(this.metaDomElement.nativeElement, 'width', `${this.width}px`);
  }

  toggleMetaInfo(event) {
    // don't allow toggling of meta info in configuration
    if (this.editMode) {
      return false;
    }
    const catId = `category-${this.category.id}`;
    this.toggled.emit(this.unit);
    this.triggerScrollTo(catId);
  }

  triggerScrollTo(target: string) {
    const config: ScrollToConfigOptions = {
      target: target
    };
    this._scrollToService.scrollTo(config);
  }

  renderTooltip(): string {
    return 'Are you sure you want to hide this?'
  }

  editUnit(unit: Unit) {
    this.store.dispatch(new EditUnit(cloneDeep(unit)))
  }

  hideUnit(unit: Unit) {
    this.store.dispatch(new HideUnit(unit))
  }

  renderHtml(str: string): HTMLElement {
    return (!this.editMode) ? this.toHtml(str) : str;
  }

  passModalState(state: boolean) {
    this.modalMode.emit(state);
  }
}
