export const StackedBarChartColors = [
  '#DA9FCC',
  '#FFFBCE',
  '#F9C7A3',
  '#C6FBFF',
  '#FFA6BE',
  '#78A5DB',
  '#894C7B',
  '#BDDF93',
  '#E98841',
  '#81CACF',
  '#BE5873',
  '#205493',
  '#532D4A',
  '#9D9421',
  '#AE632D',
  '#497578',
  '#743344',
  '#0C213A',
  '#B16BA1',
  '#FFF9AC',
  '#FBA566',
  '#A8F0F5',
  '#E67593',
  '#3E7ECA',
  '#6F3D64',
  '#A9C783',
  '#C97437',
  '#6BAEB2',
  '#A1475F',
  '#153C6A',
  '#F0CBE8',
  '#F6F5DE',
  '#FFE0C9',
  '#E6FCFE',
  '#FFDDE6',
  '#C1DAF6',

  '#DA9FCC',
  '#FFFBCE',
  '#F9C7A3',
  '#C6FBFF',
  '#FFA6BE',
  '#78A5DB',
  '#894C7B',
  '#BDDF93',
  '#E98841',
  '#81CACF',
  '#BE5873',
  '#205493',
  '#532D4A',
  '#9D9421',
  '#AE632D',
  '#497578',
  '#743344',
  '#0C213A',
  '#B16BA1',
  '#FFF9AC',
  '#FBA566',
  '#A8F0F5',
  '#E67593',
  '#3E7ECA',
  '#6F3D64',
  '#A9C783',
  '#C97437',
  '#6BAEB2',
  '#A1475F',
  '#153C6A',
  '#F0CBE8',
  '#F6F5DE',
  '#FFE0C9',
  '#E6FCFE',
  '#FFDDE6',
  '#C1DAF6',

  '#DA9FCC',
  '#FFFBCE',
  '#F9C7A3',
  '#C6FBFF',
  '#FFA6BE',
  '#78A5DB',
  '#894C7B',
  '#BDDF93',
  '#E98841',
  '#81CACF',
  '#BE5873',
  '#205493',
  '#532D4A',
  '#9D9421',
  '#AE632D',
  '#497578',
  '#743344',
  '#0C213A',
  '#B16BA1',
  '#FFF9AC',
  '#FBA566',
  '#A8F0F5',
  '#E67593',
  '#3E7ECA',
  '#6F3D64',
  '#A9C783',
  '#C97437',
  '#6BAEB2',
  '#A1475F',
  '#153C6A',
  '#F0CBE8',
  '#F6F5DE',
  '#FFE0C9',
  '#E6FCFE',
  '#FFDDE6',
  '#C1DAF6',

  '#DA9FCC',
  '#FFFBCE',
  '#F9C7A3',
  '#C6FBFF',
  '#FFA6BE',
  '#78A5DB',
  '#894C7B',
  '#BDDF93',
  '#E98841',
  '#81CACF',
  '#BE5873',
  '#205493',
  '#532D4A',
  '#9D9421',
  '#AE632D',
  '#497578',
  '#743344',
  '#0C213A',
  '#B16BA1',
  '#FFF9AC',
  '#FBA566',
  '#A8F0F5',
  '#E67593',
  '#3E7ECA',
  '#6F3D64',
  '#A9C783',
  '#C97437',
  '#6BAEB2',
  '#A1475F',
  '#153C6A',
  '#F0CBE8',
  '#F6F5DE',
  '#FFE0C9',
  '#E6FCFE',
  '#FFDDE6',
  '#C1DAF6',
]
