import {fromEvent as observableFromEvent} from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  Component, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild
} from '@angular/core';
import { curveLinear, extent, line, quantile, scaleLinear } from 'd3';
import { interpolate } from "d3-interpolate";
import { Observable, Subject, Subscription } from "rxjs";

import { AttributeAnalysis } from "../creative.reducers";
import { Force, forceCollide, forceSimulation, Simulation, SimulationNodeDatum } from "d3-force";
import { brandColor, competitorColor } from "../creative.component";


interface GraphNode extends SimulationNodeDatum {
  x: number;
  y: number;
  r: number;
  id: string;
  targetX: number;
  data: AttributeAnalysis;
  fill: string;
}

@Component({
  selector: 'app-differentiators-chart',
  templateUrl: './differentiators-chart.component.html',
  styleUrls: ['./differentiators-chart.component.sass']
})
export class DifferentiatorsChartComponent implements OnInit, OnDestroy {

  width = 1200;
  height = 450;
  margin = {
    top: 40,
    right: 40,
    bottom: 40,
    left: 40
  };

  brandColor = brandColor;
  competitorColor = competitorColor;
  colors: (scoreDiff: number) => string;
  graphNodes: GraphNode[] = [];
  forceSim: Simulation<GraphNode, undefined>;
  selectedNode: GraphNode;
  tooltipText: string;

  private resize$: Subscription;
  private ngUnsubscribe: Subject<boolean> = new Subject();

  @ViewChild('svg')
  svg: ElementRef;

  @Output()
  selectAttribute = new EventEmitter<AttributeAnalysis>();

  @Output()
  hoverAttribute = new EventEmitter<string>();

  @Input()
  selectedAttributes: Set<string>;

  @Input()
  attributeAnalyses$: Observable<AttributeAnalysis[]>;
  attributeAnalyses: AttributeAnalysis[] = [];

  constructor() { }

  ngOnInit() {
    this.resize$ = observableFromEvent(window, 'resize').pipe(
      debounceTime(100),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(() => this.drawChart());

    this.attributeAnalyses$
      .subscribe(analyses => {
        this.attributeAnalyses = analyses;
        const [minDiff, maxDiff] = extent(analyses.map(scoreDifference));
        this.colors = scaleLinear()
          .domain([
            minDiff,
            interpolate(minDiff, 0)(.8),
            0,
            interpolate(0, maxDiff)(.2),
            maxDiff
          ])
          .range([
            brandColor,
            'rgb(127,165,190)',
            'rgb(163, 163, 163)',
            'rgb(127,174,167)',
            competitorColor
          ]);
        this.drawChart();
      })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  drawChart() {
    this.setDimensions();
    this.drawNodes();
    this.packNodes();
    // reset tooltip & selected node
    this.fieldClicked();
  }

  setDimensions() {
    const minWidth = 800;
    const maxHeight = 450;
    this.width = Math.max(minWidth, this.svg.nativeElement.getBoundingClientRect().width);
    this.height = Math.min(this.width * 3 / 8, maxHeight);
  }

  drawNodes() {
    this.graphNodes = this.attributeAnalyses
      .map(analysis => {
        const x = this.xScale(scoreDifference(analysis));
        const y = this.yScale(Math.floor(Math.random() * 100));
        const r = analysis.avgScore * 100 / (this.attributeAnalyses.length * .02);

        return {
          targetX: x,
          x: x,
          y: y,
          r: r,
          id: analysis.id,
          data: analysis,
          fill: this.colors(scoreDifference(analysis)),
        }
      });
  }

  packNodes() {
    this.forceSim && this.forceSim.stop();
    this.forceSim = forceSimulation(this.graphNodes)
      .force('maintainXConvergeY', maintainXConvergeY(this.yScale(50), .5))
      .force('collide', forceCollide<GraphNode>().radius(d => d.r + 3).strength(.9))
      .restart()
  }

  nodeClicked(e: Event, node: GraphNode) {
    this.selectAttribute.emit(node.data);
    e.stopPropagation();
  }

  fieldClicked() {
    this.selectedNode = undefined;
    this.selectAttribute.emit(null);
  }

  nodeHovered(e: Event, node: GraphNode) {
    this.selectedNode = node;
    this.hoverAttribute.emit(node.id);
  }

  hoverLeave() {
    this.selectedNode = undefined;
    this.hoverAttribute.emit(null);
  }

  get xScale(): (number) => number {
    const [xMin, xMax] = extent(this.attributeAnalyses.map(scoreDifference));
    const xExtreme = Math.max(Math.abs(xMin), Math.abs(xMax));
    return scaleLinear()
      .domain([-xExtreme, xExtreme])
      .range([this.margin.left, this.width - this.margin.right]);
  }

  get yScale(): (number) => number {
    return scaleLinear().domain([0, 100]).range([this.height - this.margin.bottom, this.margin.top]);
  }

  nodeTransform(node: GraphNode): string {
    return `translate(${node.x}, ${node.y})`
  }

  // returns an appropriate string for the 'd' attr of an svg:path
  getMidline(): string {
    if (!this.attributeAnalyses.length) {return ''; }
    return line()
      .x(d => d.x)
      .y(d => d.y)
      .curve(curveLinear)
      ([
        {x: this.xScale(0), y: this.height},
        {x: this.xScale(0), y: 0}
      ])
  }
}

// Accessor for getting graph x value from an AttributeAnalysis
const scoreDifference = (d: AttributeAnalysis) => d.competitorScore - d.brandScore;

// This force nudges nodes towards the vertical center
// It also nudges them towards their target X location
// which is necessary because collisions can throw nodes off course
const maintainXConvergeY = function(center: number, strength: number) {
  let nodes = [];
  const minThreshold = 1;

  const force: Force<GraphNode, undefined> = () =>
    nodes.forEach(node => {
      Math.abs(node.y - center) < minThreshold
        ? (node.y = center, node.vy = 0)
        : node.vy += node.y > center ? -strength : strength;
      Math.abs(node.x - node.targetX) < minThreshold
        ? (node.x = node.targetX, node.vx = 0)
        : node.vx += node.x > node.targetX ? -strength * 2 : strength * 2;
    });

  force.initialize = inputNodes => nodes = inputNodes;

  return force
};
