import { get } from 'lodash';

import { LALACTIONMENU, MTVACTIONMENU, OUTACTIONMENU } from 'app/audiences/action-menu/action-menu.constants';
import { ActionMenuItem } from 'app/audiences/action-menu/action-menu-item.model';
import { tooltipMessage as lookalikeTooltipMessages } from 'app/audiences/shared/lookalike.constants';
import { tooltipMessage as motivationTooltipMessages } from 'app/audiences/shared/motivation.constants';
import { tooltipMessage as outcomeTooltipMessages } from 'app/audiences/shared/outcome.constants';

export function menuTemplate(ppcItem): any {
  const type = ppcItem.type
  const MENUTYPE = {
    lookalike: LALACTIONMENU,
    motivation: MTVACTIONMENU,
    outcome: OUTACTIONMENU
  }
  return MENUTYPE[type]
}

export function filterDetailView(isDetailView, ppcItem) {
  const template: ActionMenuItem[] = menuTemplate(ppcItem)

  if (isDetailView) {
    return template.filter(item => (item.type !== "ViewDetail"))
  }

  return template
}

export function filterJobStatus(menu, jobStatus) {
  return menu.filter(item => {
    if ((!item.viewableJobStatus) ||
        (item.viewableJobStatus && item.viewableJobStatus.includes(jobStatus))) {
      return item
    }
  })
}

export function disableCreateLookalike(isEmpty: boolean, jobStatus: string, hasGlobalCounts: boolean, userCanCreateLookalike: boolean,
  dataIsPermissioned: boolean) {
  if (!isEmpty && jobStatus === "complete" && hasGlobalCounts && userCanCreateLookalike && dataIsPermissioned) {
    return false
  }
  return true
}

export function disableSendToPartner(jobStatus, userCanSendToPartner, dataIsPermissioned, counts) {
  if (jobStatus === "complete" && userCanSendToPartner && dataIsPermissioned && counts > 0) {
    return false
  }
  return true
}

export function disableSendToTardiis(jobStatus, alreadySentToTardiis, userCanSendToTardiis, dataIsPermissioned) {
  if (jobStatus === "complete" && !alreadySentToTardiis && userCanSendToTardiis && dataIsPermissioned) {
    return false
  }
  return true
}

export function disableCreateIndexReport(jobStatus, userCanCreateIndexReport) {
  if (jobStatus === "complete" && userCanCreateIndexReport) {
    return false
  }
  return true
}

export function disableDownloadExcel(jobStatus, isEmptyConfidenceSizes) {
  if ((jobStatus === "preliminary" || jobStatus === "complete") && !isEmptyConfidenceSizes) {
    return false
  }
  return true
}

export function disableDelete(ppcItem, jobStatus, userCanDelete, sentToTardiis) {
  if (ppcItem.deletable === false) {
    return true
  }

  if ((jobStatus === "pending" || jobStatus === "complete") && userCanDelete && !sentToTardiis) {
    return false
  }
  return true
}

// TOOLTIP MESSAGES =================================

export function getTooltipMessage(messageKey: string, type) {
  let tooltipMessage
  if (!messageKey) { return }
  if (type === 'lookalike') {
    tooltipMessage = lookalikeTooltipMessages;
  } else if (type === 'motivation') {
    tooltipMessage = motivationTooltipMessages;
  } else if (type === 'outcome') {
    tooltipMessage = outcomeTooltipMessages;
  }

  return tooltipMessage[messageKey]
}

export function createLookalikeTooltipMessage(jobStatus: string, hasGlobalCounts: boolean, userCanCreateLookalike: boolean,
  dataIsPermissioned: boolean, type: string) {
  let option = ''

  if (jobStatus !== 'complete') {
    option = 'CreationDisabled';
  } else if (!userCanCreateLookalike) {
    option = 'NotUserPermissioned';
  } else if (!dataIsPermissioned) {
    option = 'NotDataPermissioned';
  } else if (!hasGlobalCounts) {
    option = 'MissingCounts'
  }

  return getTooltipMessage(`lookalike${option}`, type)
}

export function sendToPartnerTooltipMessage(jobStatus, userCanSendToPartner, dataIsPermissioned, type, counts) {
  let option = ""

  if (jobStatus !== "complete") {
    option = "Incomplete";
  } else if (!userCanSendToPartner) {
    option = "NotUserPermissioned"
  } else if (!dataIsPermissioned) {
    option = "NotDataPermissioned"
  } else if (counts < 1) {
    option = "MissingCounts"
  }

  return getTooltipMessage(`sendToPartner${option}`, type)
}

export function sendToTardiisTooltipMessage(jobStatus, sendToTardiis, userCanSendToTardiis, dataIsPermissioned, type) {
  let option = "";

  if (jobStatus !== "complete") {
    option = "Incomplete";
  } else if (sendToTardiis) {
    option = "AlreadySent";
  } else if (!userCanSendToTardiis) {
    option = "NotUserPermissioned"
  } else if (!dataIsPermissioned) {
    option = "NotPermissioned"
  }

  return getTooltipMessage(`disabledTardiis${option}`, type);
}

export function indexReportTooltipMessage(jobStatus, userCanCreateIndexReport, type) {
  let option = ""

  if (jobStatus !== 'complete') {
    option = "CreationDisabled"
  } else if (!userCanCreateIndexReport) {
    option = 'NotUserPermissioned';
  }

  return getTooltipMessage(`indexReport${option}`, type);
}

export function downloadExcelTooltipMessage(jobStatus, isEmptyConfidenceSizes, type) {
  let option = ""

  if (jobStatus === "pending" || jobStatus === "processing") {
    option = "disabledDownloadExcel"
  } else if (isEmptyConfidenceSizes) {
    option = "disabledDownloadExcel"
  }

  return getTooltipMessage(option, type)
}

export function deleteTooltipMessage(ppcItem, jobStatus, userCanDelete, sentToTardiis, type) {
  if (get(ppcItem, ['delete_disallow_reasons', 'length'], 0) > 0) {
    return ppcItem.delete_disallow_reasons[0];
  }

  let option;

  if (jobStatus === "processing" || jobStatus === "preliminary") {
    option = "Processing";
  } else if (!userCanDelete) {
    option = "NotUserPermissioned";
  } else if (sentToTardiis) {
    option = "Tardiis";
  }

  return getTooltipMessage(`disabledDelete${option}`, type);
}
