import { Demographic } from "../insights.models";

export function createStandardDemographicsConfig(demographics: Demographic[]) {
  return demographics.map(demographic => {
    return {
      id: demographic.id,
      visible: !!demographic.visible,
      widget_type: demographic.widget_type,
      is_id_count: demographic.is_id_count,
      description: demographic.description,
    }
  })
}
