<div class="edit-pane" (submit)="upsertSubMarket()" *ngIf="subMarket">

  <div class="edit-pane-header">
    <h3 class="edit-pane-header-text">{{subMarket.id ? "Edit" : "Create"}} Audience</h3>
  </div>

  <div class="edit-pane-section name-container" [formGroup]="subMarketFormGroup">
    <ppc-input [(ngModel)]="subMarket.name" formControlName="name" required [disabled]="isVerticalMekko$ | async">
      <label>Name</label>
      <span suffix class="required-suffix" [hidden]="subMarketFormGroup.controls.name.valid">*</span>
    </ppc-input>
    <div class="name-error-container" *ngIf="subMarket.name && subMarketFormGroup.controls.name.errors?.duplicateName">
      {{subMarketFormGroup.controls.name.errors.duplicateName}}
    </div>
  </div>

  <div class="edit-pane-section color-identifier-container">
    <label ppcTooltip="Categorization of Audience">Color Identifier</label>
    <div ppcRadioGroup [(ngModel)]="subMarket.tag">
      <ppc-radio-button value="owned" ppcTooltip="Brand consumers">
        <div class="circle" [style.background]="mekkoColors.owned"></div>
        Owned
      </ppc-radio-button>
      <ppc-radio-button value="opportunity" ppcTooltip="Possible switchers">
        <div class="circle" [style.background]="mekkoColors.opportunity"></div>
        Opportunity
      </ppc-radio-button>
      <ppc-radio-button value="none" ppcTooltip="Non-considering consumers">
        <div class="circle" [style.background]="mekkoColors.none"></div>
        None
      </ppc-radio-button>
    </div>
  </div>

  <div class="edit-pane-section audiences-container" *ngIf="!(marketLevelOnly$ | async)">
    <div class="edit-pane-section-header">
      <h4 class="edit-pane-section-header-text">Attach Audiences</h4>
    </div>

    <div class="matched-audience-container">
      <label>Matched Audience</label>

      <div class="layout horizontal center-center ">
        <ppc-input [value]="segments[matchedShortId]?.name" (focusin)="openSegmentPicker('matched')" (input)="$event.preventDefault()" [disabled]="isVerticalMekko$ | async">
          <label>{{getSegmentPath(segments[matchedShortId])}}</label>
          <div suffix *ngIf="matchedShortId && !(isVerticalMekko$ | async)" (click)="subMarket.matched_short_id = null"><i class="fa fa-close"></i></div>
        </ppc-input>

        <ppc-zero-count-warning-icon
          *ngIf="hasZeroCount(subMarket.matched_short_id)"
          ppcTooltip="This audience has a zero count. Select another audience to replace it."
        >
        </ppc-zero-count-warning-icon>

        <button class="btn" (click)="openSegmentPicker('matched')" *ngIf="!(isVerticalMekko$ | async)">SELECT</button>
      </div>

    </div>

    <div class="modeled-audience-container" *ngIf="!isTier3">
      <label>Modeled Audience</label>

      <div class="layout horizontal center-center">
        <ppc-input [value]="segments[modeledShortId]?.name" (focusin)="openSegmentPicker('modeled')" (input)="$event.preventDefault()">
            <label>{{getSegmentPath(segments[modeledShortId])}}</label>
            <div suffix *ngIf="modeledShortId" (click)="subMarket.modeled_short_id = null"><i class="fa fa-close"></i></div>
        </ppc-input>

        <ppc-zero-count-warning-icon
          *ngIf="hasZeroCount(subMarket.modeled_short_id)"
          ppcTooltip="This audience has a zero count. Select another audience to replace it."
        >
        </ppc-zero-count-warning-icon>

        <button class="btn" (click)="openSegmentPicker('modeled')">SELECT</button>
      </div>
    </div>
  </div>

  <div class="edit-pane-section market-level-container">
    <div class="edit-pane-section-header">
      <h4 class="edit-pane-section-header-text">Market-level Data</h4>
    </div>
    <span [formGroup]="subMarketFormGroup">
      <ppc-input type="number" [(ngModel)]="subMarket.population" formControlName="population" required>
        <label>Audience Population</label>
        <span suffix class="required-suffix" [hidden]="subMarketFormGroup.controls.population.valid">*</span>
        <span help-text>Count to display for Total Population</span>
      </ppc-input>
    </span>

    <ppc-input type="text" *ngIf="hasWeboramaAccess$ | async"
               [(ngModel)]="subMarket.webo_cluster_id">
      <label>Weborama</label>
      <span help-text>Weborama segment ID to map to Audience</span>
    </ppc-input>

    <div class="dropdown-container">
      <ppc-searchable-dropdown #clusterNodeDropdown *ngIf="(clusterNodes$ | async)?.length > 0"
                               showSearch="false"
                               label="Discussion"
                               itemLabelProp="name"
                               mapFromProperty="id"
                               [showInputUnderline]="true"
                               [(ngModel)]="subMarket.discussion_cluster_node_id"
                               [items]="clusterNodes$ | async">
      </ppc-searchable-dropdown>
      <span class="dropdown-help-text" *ngIf="(clusterNodes$ | async)?.length">Discussion to map to Discussions tab for Audience</span>
      <span class="dropdown-help-text no-discussions" *ngIf="!(clusterNodes$ | async)?.length">
        This client has no discussion uploaded for it. To upload a Discussion via the bulk uploader, <a (click)="navigateToDiscussionsBulkUpload()">click here.</a>
      </span>
    </div>
  </div>

  <div class="edit-pane-actions">
    <ng-template #warningDialog>
      <div class="edit-message">
        <h4>Are you sure you want to edit '{{this.subMarket?.name}}'?</h4>
        <div class="edit-message-body">
          <div class="propensity-warning">
            This audience is used in a Prediction Model. If you edit this audience, it will delete the model in the Predictions tab.
          </div>
        </div>
      </div>
    </ng-template>

    <app-confirmation
      *ngIf="isInSnapshotModel && matchedShortIdChanged"
      [isButton]="true"
      buttonLabel="Save"
      buttonContinueLabel="Continue"
      modalTitle="Edit Audience"
      [kontinue]="upsertSubMarket.bind(this)"
      [disabled]="!subMarketFormGroup.valid"
      [dialogTemplate]="warningDialog">
    </app-confirmation>

    <button *ngIf="!(isInSnapshotModel && matchedShortIdChanged)"
      type="submit"
      class="btn"
      (click)="upsertSubMarket()"
      [disabled]="!subMarketFormGroup.valid"
      >
      Save
    </button>

    <ng-template #dialogTemplate>
      <div class="delete-message">
        <h4>Are you sure you want to delete '{{this.subMarket?.name}}'?</h4>
        <div class="delete-message-body">
          <div class="propensity-warning" *ngIf="isInSnapshotModel">
            This audience is used in a Prediction Model. If you delete from this Grow chart, it will delete the model in the Predictions tab.
          </div>
          <div class="list-title">This audience will also be deleted from:</div>
          <ul>
            <li>Outcomes: Past Timeframes</li>
          </ul>
        </div>
      </div>
    </ng-template>

    <app-confirmation
      *ngIf="subMarket.id && !(isVerticalMekko$ | async) && (canDestroyMekkos$ | async)"
      [isButton]="true"
      modalTitle="Delete Audience"
      [kontinue]="destroySubMarket.bind(this)"
      [dialogTemplate]="dialogTemplate"
      >
    </app-confirmation>

    <button mat-button (click)="cancel()">Cancel</button>
  </div>
</div>
