import {Action} from "@ngrx/store";
import {AudienceFetchOptions} from "app/audiences-v2/audience-v2.service";
import {AudienceV2} from "app/audiences-v2/audience-v2.model";

export class FetchAudiences implements Action {
  static readonly type = 'FETCH_AUDIENCES';
  readonly type = FetchAudiences.type;

  constructor(public options: AudienceFetchOptions = {}) {}
}

export class FetchOverviewAudiences implements Action {
  static readonly type = 'FETCH_OVERVIEW_AUDIENCES';
  readonly type = FetchOverviewAudiences.type;

  constructor(public options: AudienceFetchOptions = {}) {}
}

export class LoadAudiences implements Action {
  static readonly type = 'LOAD_AUDIENCES';
  readonly type = LoadAudiences.type;

  constructor(public audiences: AudienceV2[]) {}
}

export class EditAudience implements Action {
  static readonly type = 'EDIT_AUDIENCE';
  readonly type = EditAudience.type;

  constructor(public audience: AudienceV2) {}
}

export class ClearAudiences implements Action {
  static readonly type = 'CLEAR_AUDIENCES';
  readonly type = ClearAudiences.type;
}

export class UpdateAudience implements Action {
  static readonly type = 'UPDATE_AUDIENCES';
  readonly type = UpdateAudience.type;

  constructor(public audience: AudienceV2) {}
}

export class DestroyAudience implements Action {
  static readonly type = "DESTROY_AUDIENCE";
  readonly type = DestroyAudience.type;

  constructor(public audience: AudienceV2, options: object) {}
}

export class RemoveAudience implements Action {
  static readonly type = "REMOVE_AUDIENCE";
  readonly type = RemoveAudience.type;

  constructor(public data) {}
}

export class ChangeAudiencePeopleCount implements Action {
  static readonly type = 'CHANGE_AUDIENCE_PEOPLE_COUNT';
  readonly type = ChangeAudiencePeopleCount.type;

  constructor(public id: number, public peopleCount: number) { }
}

export type All = FetchAudiences | FetchOverviewAudiences | LoadAudiences | EditAudience | ClearAudiences
| UpdateAudience | DestroyAudience | RemoveAudience | ChangeAudiencePeopleCount;
