import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: "[ppc-autofocus]"
})
export class PpcAutoFocusDirective implements OnInit {
  /*
    NOTE: Sourced from https://stackoverflow.com/a/58317049/4803039
    This directive applies autofocus on form elements (i.e. buttons, inputs, textareas, etc)
    that were dynamically added to the template through use of template directives like
    ngFor or ngIf.

    Usage:
    Apply directive on an html element that can accept the "autofocus" attribute.
    <input ppc-autofocus />
  */

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    });
  }
}
