import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { MeasureV3Component } from './measure-v3.component';
import { MeasureV3CmsComponent } from './measure-v3-cms/measure-v3-cms.component';
import { MeasureV3Service } from './measure-v3.service';
import { OutcomeTimeframeComponent } from './outcome-timeframe/outcome-timeframe.component';
import { MeasureV3MekkoComponent } from './measure-v3-mekko/measure-v3-mekko.component';
import { MeasureV3StackedContainerComponent } from './measure-v3-stacked-container/measure-v3-stacked-container.component';
import { MeasureV3StackedComponent } from './measure-v3-stacked/measure-v3-stacked.component';
import { MeasureV3StackedLegendComponent } from './measure-v3-stacked-legend/measure-v3-stacked-legend.component';
import { MeasureV3OutcomeChangeOverlayComponent } from './measure-v3-mekko/measure-v3-outcome-change-overlay/measure-v3-outcome-change-overlay.component';
import { TimeframeSelectorComponent } from './timeframe-selector/timeframe-selector.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PpcComponentsModule,
    RouterModule
  ],
  declarations: [
    MeasureV3Component,
    MeasureV3StackedContainerComponent,
    MeasureV3StackedComponent,
    MeasureV3StackedLegendComponent,
    MeasureV3CmsComponent,
    OutcomeTimeframeComponent,
    MeasureV3MekkoComponent,
    MeasureV3OutcomeChangeOverlayComponent,
    TimeframeSelectorComponent,
  ],
  providers: [
    MeasureV3Service
  ]
})
export class MeasureV3Module { }
