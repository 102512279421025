import {takeUntil, map, tap} from 'rxjs/operators';
import { PpcHttpService } from "./ppc_http.service";
import {Injectable} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";
import {FetchCurrencies, LoadCurrencies} from "../currencies/currencies.actions";
import {Actions, Effect, ROOT_EFFECTS_INIT, ofType} from "@ngrx/effects";
import {fetchResource} from "../shared/utils/fetch-state";
import {keyBy} from 'lodash';
import { currenciesUrl } from '../shared/constants/clients.urls';

@Injectable()
export class CurrencyService {
  private currencies$ = new ReplaySubject<Array<Currency>>(1);
  private currenciesMap$ = new ReplaySubject<{ [id: string]: Currency; }>(1);

  @Effect()
  effectsReady$ = this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    map(() => new FetchCurrencies()));

  // Fetches currency metadata. Once it has been loaded, subsequent FETCH_CURRENCIES calls will be ignored
  // (they shouldn't change during a user's session, and if they do a full refresh will allow another fetch)
  @Effect()
  fetchCurrencies$ = this.actions$.pipe(
    ofType(FetchCurrencies.type),
    takeUntil(this.actions$.pipe(ofType(LoadCurrencies.type))),
    (fetchResource(
      () => this.getCurrenciesMetadata().pipe(map(currencies => new LoadCurrencies(currencies)))
    )), );

  // NOTE: this is just to support the deprecated currencies$ and currenciesMap$ observables
  // once we have fully switched over to subscribing to the store instead we can delete this and those 2 observables
  @Effect({dispatch: false})
  loadCurrencies$ = this.actions$.pipe(
    ofType(LoadCurrencies.type),
    tap(({ currencies }: LoadCurrencies) => {
      this.currencies$.next(currencies);
      this.currenciesMap$.next(keyBy(currencies, 'id') as {[id: string]: Currency})
    }));

  constructor(private http: PpcHttpService, private actions$: Actions) { }

  private getCurrenciesMetadata(): Observable<Array<Currency>> {
    return this.http.get(currenciesUrl());
  }

  // Deprecated: subscribe to store instead
  // id is a lowercase version of ISO 4712 code, e.g. 'usd', 'eur', 'gbp'
  getCurrency(id: string): Observable<Currency> {
    return this.currenciesMap$.pipe(map(
      map => map[id]
    ));
  }

  // Deprecated: subscribe to store instead
  getCurrencies(): Observable<Array<Currency>> {
    return this.currencies$.asObservable();
  }
}

// This model comes straight from Ruby Money gem
export interface Currency {
  id: string;
  name: string;

  symbol: string;
  symbol_first: boolean;
  thousands_separator: string;
  decimal_mark: string;

  iso_code: string;
}

export const GBP: Currency = {
  id: "gbp",
  name: "British Pound",
  symbol: "£",
  symbol_first: true,
  thousands_separator: ",",
  decimal_mark: ".'",
  iso_code: "GBP"
};
