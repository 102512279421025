import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GwiWidgetComponent } from 'app/insights/insights-components/gwi-widget/gwi-widget.component';
import { GwiSurveyQuestionComponent } from 'app/insights/insights-components/gwi-widget/gwi-survey-question/gwi-survey-question.component';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';

@NgModule({
  imports: [
    CommonModule,
    PpcComponentsModule,
  ],
  declarations: [
    GwiSurveyQuestionComponent,
    GwiWidgetComponent
  ],
  exports: [
    GwiSurveyQuestionComponent,
    GwiWidgetComponent
  ]
})
export class GwiWidgetModule { }
