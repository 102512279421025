<div class="create-new-propensity" *ngIf="!saved">
    <div class="form-container" [formGroup]="snapshotForm">
      <div class="errors">
        <mat-error *ngIf="name.errors">
          Model Name is required.
        </mat-error>

        <mat-error *ngIf="subMarkets.errors?.required || subMarkets.errors?.minlength || !allSubMarketHaveCounts">
          Must have at least two Sub Markets with IDs selected to run Propensity.
        </mat-error>

        <mat-error *ngIf="subMarkets.errors?.maxlength">
          Must have five or less Sub Markets selected in order to run Propensity.
        </mat-error>

        <mat-error *ngIf="hasDuplicateName">
          A Propensity Snapshot with the same name already exists. Please choose a different name.
        </mat-error>
      </div>

      <div>
        <div>Create New Model</div>

          <div class="form-field">
            <ppc-input class="hide" [value]="subMarkets" [(ngModel)]="snapshot.sub_markets" formControlName="subMarkets"></ppc-input>
            <div>Audiences:</div>
            <div class="muted">
              <div *ngIf="!subMarketNames || subMarketNames.length == 0">None Selected</div>
              {{subMarketNames}}
            </div>
          </div>

          <div class="form-field">
            <ppc-input class="hide" [(ngModel)]="snapshot.filters" formControlName="filters"></ppc-input>
            <div>Filters:</div>
            <div class="muted">
              <div *ngIf="!filterNames || filterNames.length == 0">None Selected</div>
              {{filterNames}}
            </div>
          </div>

          <div class="segment-checkbox">
            <ppc-checkbox [(ngModel)]="snapshot.include_first_party_segments" formControlName="include_first_party_segments">
              Include 1st Party Segments
              <span (click)="toggleSegmentsInfo()"><i class="fa fa-question-circle"></i></span>
            </ppc-checkbox>
          </div>

          <div class="form-field name">
            <ppc-input [minLength]="2" [(ngModel)]="snapshot.name" formControlName="name">
              <label>Model Name</label>
            </ppc-input>
          </div>

          <div class="form-field run">
            <button class="header-btn" [disabled]="!valid" mat-button (click)="submit($event)" type="submit">Run</button>
          </div>
      </div>
    </div>
  </div>

  <div class="create-new-propensity" *ngIf="saved">
    <div>
      <div><i class="fa fa-clock-o"></i> Propensity Modeling has started successfully</div>

      <div class="form-field">
        <div>Time Estimate:</div>
        <div class="muted">
          Please return in 24 hours to view completed Propensity results.
        </div>
      </div>

      <div class="form-field">
        <div>Model Name:</div>
        <div class="muted">
          {{snapshot.name}}
        </div>
      </div>

      <div class="form-field">
        <div>Market Segments:</div>
        <div class="muted">
          {{subMarketNames}}
        </div>
      </div>

      <div class="form-field">
        <div>1st Party Segments:</div>
        <div class="muted">
          <div *ngIf="snapshot.include_first_party_segments">Have been included</div>
          <div *ngIf="!snapshot.include_first_party_segments">Have not been included</div>
        </div>
      </div>

      <div class="form-field">
        <div>Filters:</div>
        <div class="muted">
          <div *ngIf="!filterNames || filterNames.length == 0">None</div>
          {{filterNames}}
        </div>
      </div>

      <div class="form-field got-it" (click)="reset()">
        <span class="right">Got it!</span>
      </div>
    </div>
  </div>
