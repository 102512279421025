import { Component, HostBinding, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ppc-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.sass']
})
export class LoadingOverlayComponent implements OnInit {
  @HostBinding("class.show") show = false;
  @HostBinding("class.full-screen") @Input() fullScreen = true;
  @HostBinding("class.with-background") @Input() withBackground = true;

  constructor() { }

  ngOnInit() {
    setTimeout(() => this.show = true, 1)
  }

}
