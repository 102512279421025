import { map, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { find, get } from 'lodash';

import { ChangeGrowTabState } from 'app/insights/grow-v3/grow.actions';
import { GrowTabState, GrowV3FocusType } from 'app/insights/grow-v3/grow.reducer';
import { MekkoColors } from 'app/insights/grow-v3/grow.constants';
import { selectActiveMekko } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';
import { Tab, TopLevelTabType } from 'app/insights/insights.models';
import * as insightsActions from "app/insights/insights.actions";
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { InsightsCountService } from "app/insights/insights-count.service";
import { GrowCountService } from 'app/insights/grow-v3/grow-count.service';
import { environment } from 'environments/environment';
import { isCompareMode } from '../../insights.reducer';

@Component({
  selector: 'ppc-grow-v3-mekko',
  templateUrl: './grow-v3-mekko.component.html',
  styleUrls: ['./grow-v3-mekko.component.sass']
})
export class GrowV3MekkoComponent implements OnInit {
  activeTab$: Observable<GrowTabState>;
  focusedComponent$: Observable<GrowV3FocusType>;
  mekkoColors = MekkoColors;
  ngUnsubscribe = new Subject();
  topLevelTab: 'Predictions' | 'Market Level' | 'Person Level';
  tabs: Tab[];
  isPeopleCount$: Observable<boolean>;
  isTier3 = environment.isTier3;
  isCompareMode$ = this.store.select("insights", this.insightsContext).pipe(select(isCompareMode));


  constructor(private store: Store<AppState>,
    public counts: InsightsCountService,
    public growCounts: GrowCountService,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {
    this.activeTab$ = this.store.select("grow", "growTabState");

    this.focusedComponent$ = this.store.select("grow", "growV3Focus");
    this.store.select("insights", this.insightsContext, "tabs").pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(tabs => this.tabs = tabs)

    this.store.select("insights", this.insightsContext, "topLevelTab").pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(topLevelTab => this.topLevelTab = topLevelTab)

    this.isPeopleCount$ = this.store.select("mekkos").pipe(
      select(selectActiveMekko),
      map(mekko => mekko && (mekko.count_type == 'people_count')), )

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changeTab(newTab: GrowTabState) {
    this.store.dispatch(new ChangeGrowTabState(newTab));

    if (newTab == 'Total Population') {
      this.store.dispatch(new insightsActions.SetTopLevelTab('Market Level', this.insightsContext));
    } else if (this.topLevelTab == 'Market Level') {
      this.store.dispatch(new insightsActions.SetTopLevelTab('Person Level', this.insightsContext));
    }
  }

  isTabTypeAllowed(tabType: TopLevelTabType): boolean {
    if (!this.tabs) {return true; }
    return get(find(this.tabs, {name: tabType}), "visible")
  }

  isBelowTvMinimum(count: number): boolean {
    return this.growCounts.isBelowTvCountMinimum(count);
  }
}
