import { Component, EventEmitter, Output } from '@angular/core';
import { MotivationAudienceRequest } from 'app/motivation-audience/motivation-audience.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { every, get, isBoolean, isEmpty, isString, values } from 'lodash';
import { SaveMotivation } from 'app/models/motivations/motivations-v1.actions';
import { Store } from '@ngrx/store';
import { Actions } from "@ngrx/effects";
import { AppState } from 'app/reducers';
import { fetchOutcome } from 'app/shared/utils/fetch-state';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ppc-motivation-audience-form',
  templateUrl: './motivation-audience-form.component.html',
  styleUrls: ['./motivation-audience-form.component.sass']
})

export class MotivationAudienceFormComponent {
  @Output() close = new EventEmitter<any>();
  motivationAudienceJSON: string = '';
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private snackbar: MatSnackBar, public store: Store<AppState>, private actions$: Actions) {}

  isValid() {
    let motivationAudienceJSON;
    if (isEmpty(this.motivationAudienceJSON)) {return false; }
    try {
      motivationAudienceJSON = JSON.parse(this.motivationAudienceJSON) as MotivationAudienceRequest;
    } catch (e) {
      return false;
    };
    const { custom_survey, custom_survey_file, ...motivationAudience } = motivationAudienceJSON;
    if (!isString(custom_survey_file) || !isBoolean(custom_survey)) {return false; }
    return values(motivationAudience) && every(values(motivationAudience), value => !isEmpty(value));
  }

  create() {
    this.loading$.next(true);
    this.store.dispatch(new SaveMotivation(JSON.parse(this.motivationAudienceJSON) as MotivationAudienceRequest));
    this.actions$.pipe(fetchOutcome(SaveMotivation.type), take(1)).subscribe(
      response => {
        this.loading$.next(false);
        this.snackbar.open("This Motivation Audience has been created! Please allow up to 48 hours for processing.", "OK", {
          panelClass: ["success"],
          duration: 5000
        })
        this.cancel();
      },
      error => {
        this.loading$.next(false);
        const message = get(error, ["error", "error", "message"], "Something went wrong creating this audience. Please try again later.");
        this.snackbar.open(message, "OK", {
          panelClass: ["danger"]
        })
      }
    );
  }

  cancel() {
    this.close.emit();
  }
}
