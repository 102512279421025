<iframe #frame (load)="iFrameLoaded$.next();loaded($event)" [src]="safeUrl$ | async"></iframe>
<div [class.loaded]="fullyLoaded" id="frame-loading">
  <div>
    <div class="layout horizontal">
      <i class="fa fa-circle-o-notch fa-5x fa-spin"></i>
      <div class="ml-20">
        <b>Loading...</b>
        <p>If this page takes longer than 1 minute to load, please reach out to <a target="_blank" rel="noopener" href="https://jira.publicismedia.com/servicedesk/customer/portal/3/create/79">Product Support</a> for assistance.</p>
      </div>
    </div>
  </div>
</div>
