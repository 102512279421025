<div class="measure-v3-stacked">
  <div class="chart-container">
    <div class="chart-left">
      <div class="y-legend-label">
        <div class="count">Count</div>
      </div>
      <div class="y-legend">
        <div class="legend" *ngFor="let legend of yLegend">
          <div class="number" >
            {{toMillions(legend)}}
          </div>
        </div>
      </div>
    </div>
    <div class="chart-right">
      <div class="stacked-chart">
        <div class="stacked-column"  *ngFor="let timeframe of timeFrames; let i = index" [style.height.%]="timeframe.subMarketComboCount / topYAxisCount * 100">
          <div class="stack"
              *ngFor="let subMarketOutcome of sortedSubMarketOutcomes(timeframe.subMarketsOutcomes); let j = index"
              [style.flex]="getSubMarketHeight(subMarketOutcome, timeframe)"
              [style.background-color]="getBackgroundColor(subMarketOutcome)"
              [class.not-selected]="notSelected(subMarketOutcome)"
              (click)="toggleSelectedSubMarket(subMarketOutcome)"
              [ppcTooltip]="tooltip(subMarketOutcome)"
              [tooltipOffset]="j == 0 ? {y: -20} : {y: -40}"></div>
        </div>
      </div>
      <div class="x-legend">
        <div class="legend"  *ngFor="let timeframe of timeFrames; let i = index">{{timeframe.date}}</div>
      </div>

      <div class="x-legend-label">
        <div *ngIf="groupBy == 'none'">Date</div>
        <div *ngIf="groupBy == 'market'">{{ markets[0]?.name}}</div>
        <div *ngIf="groupBy == 'market'" class='market-population'>
          <i class="fa fa-user"></i>{{ getMarketPopulation(markets[0]) }}
        </div>
      </div>
    </div>
  </div>
</div>
