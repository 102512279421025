<epc-modal
  #personaHasDeletedSegmentsModal
  title="Persona is Invalid"
  [dismissable]="false"
  [dismissOnBlur]="false"
  primaryButtonTitle="Edit"
  (onPrimaryButtonClick)="editDeletedSegmentPersona(activePersona)"
  [primaryButtonDisabled]="activePersona?.is_all_data || !(permissions$ | async)?.create"
  secondaryButtonTitle="Delete"
  (onSecondaryButtonClick)="destroyDeletedSegmentPersona()"
  [secondaryButtonDisabled]="activePersona?.is_all_data || !(permissions$ | async)?.destroy"
  (onClose)="onPersonaChange(allDataPersona)"
>
  <div body>
    This persona contains {{deletedActivePersonaSegmentIds?.length}} segment(s) no longer available from the provider. The persona cannot be selected in this state. If Edit is pressed, the Persona Builder will open with the missing segments automatically removed.
  </div>
</epc-modal>
<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>
<app-slide-in-overlay [width]="650" [active]="!!(regionDemographicUnderEdit$ | async)">
    <ppc-region-demographic-form></ppc-region-demographic-form>
</app-slide-in-overlay>

<app-slide-in-overlay
  [active]="!!indexReportFormOpen"
  [width]="550">
  <ppc-index-report-form
    originator="persona"
    [resources]="personas$ | async"
    [item]="indexReportItemDetails"
    (close)="toggleIndexReportForm()">
  </ppc-index-report-form>
</app-slide-in-overlay>

<ng-container *ngIf="(activePersona$ | async) as activePersona">
  <div class="persona">
    <div class="persona-header-container">
      <div class="persona-header">
        <div class="page-title">
          <span class="category">Insights</span> Explore
        </div>
      </div>
      <div class="header-dropdown">
        <div class="dropdown-container" *ngLet="(permissions$ | async) as permissions">
          <div class="persona-dropdown-label">Persona</div>
          <div class="persona-dropdown layout horizontal">
            <ppc-searchable-dropdown #personaDropdown
                                      itemLabelProp="name"
                                      mapFromProperty="id"
                                      [allowClear]="false"
                                      [(ngModel)]="selectedPersonaId"
                                      [iconPrefix]="activePersona.icon"
                                      [items]="personas$ | async">
              <ng-template #itemTemplate let-item="item">
                <div class="persona-item" [class.selected]="activePersona === item">
                  <button class="single-select-item"
                    (click)="onPersonaChange(item); personaDropdown.open = false"
                    *ppcCheckOverflow="let hasOverflow=hasXOverflow"
                    [ppcTooltip]="item?.name"
                    [tooltipDisabled]="!hasOverflow"
                    tabIndex="0">
                    <i class="fa fa-flag-o" [class.invisible]="!(item.is_all_data && !item.hasDefault) && !item.default"></i>{{ item?.name }}
                  </button>
                </div>
              </ng-template>
            </ppc-searchable-dropdown>
            <div *ngIf="!(marketLevelOnly$ | async)" class="persona-actions">
              <ppc-icon-button
               *ngIf="permissions?.create"
               icon="plus"
               [class.dim]="createAndEditDisabled"
               [ppcTooltip]="tooltipText('Create', false)"
               (click)="!createAndEditDisabled && onCreatePersonaClick()"></ppc-icon-button>
              <ppc-icon-button
                *ngIf="permissions?.update"
                icon="pencil"
                [ppcTooltip]="tooltipText('Edit', activePersona?.is_all_data)"
                (click)="!activePersona?.is_all_data && !createAndEditDisabled && onEditPersonaClick(activePersona)"
                [class.dim]="activePersona?.is_all_data || createAndEditDisabled"></ppc-icon-button>
              <ppc-icon-button
                *ngIf="activePersona && permissions?.create"
                icon="clone"
                [ppcTooltip]="copyTooltipText(activePersona?.is_all_data)"
                [class.dim]="activePersona?.is_all_data"
                (click)="copyPersona()"
              ></ppc-icon-button>
              <ppc-icon-button
                *ngIf="permissions?.destroy"
                icon="trash"
                [ppcTooltip]="deleteTooltipText(activePersona?.is_all_data)"
                [class.dim]="activePersona?.is_all_data"
                ppcConfirmation
                [disable]="activePersona?.is_all_data"
                [kontinue]="destroyPersona.bind(this)"
                ></ppc-icon-button>
              <ppc-icon-button
                icon="ellipsis-v"
                ppcTooltip="Actions"
                [matMenuTriggerFor]="ellipsisMenu"
                mat-icon-button>
              </ppc-icon-button>
            </div>
          </div>
        </div>
        <div class="count-display" *ngIf="(topLevelTab$ | async) == 'Person Level'">
          <i class="fa fa-user fa-lg"></i> Matched: {{ (counts.totalCount | number) || 0 }}
        </div>
      </div>
    </div>
    <ppc-filter-bar
      [filters]="selectedFilters"
      [class.show]="selectedFilters.length"
      (clearFilters)="clearFilters()"
      (toggleFilter)="toggleFilter($event)">
    </ppc-filter-bar>
    <div class="persona-widgets-container">
      <ppc-insights [segmentContexts$]="segmentContexts$"></ppc-insights>
    </div>
  </div>

  <mat-menu #ellipsisMenu="matMenu">
    <button
      (click)="toggleIndexReportForm()"
      [disabled]="!(canCreateIndexReport$ | async) || activePersona?.is_all_data"
      mat-menu-item>
      Create Index Report
    </button>
    <button
      *ngIf="(topLevelTab$ | async) != 'Market Level' && !isTier3"
      (click)="!saveAudienceDisabled && onSaveAudienceClick()"
      [ppcTooltip]="saveAudienceTooltip$ | async"
      [class.soft-disabled]="saveAudienceDisabled"
      [tooltipDisabled]="!saveAudienceDisabled"
      mat-menu-item>
      Convert to Audience
    </button>
    <button *ngIf="isTier3 && (canRequestMotivationAudience$ | async)"
      (click)="onRequestMotivationAudienceClick()"
      mat-menu-item>
      Request Motivation Audience <i class="fa fa-external-link fa-lg"></i>
    </button>
  </mat-menu>

  <app-slide-in-overlay [active]="!!(marketLevelDemographicUnderEdit$ | async)" [width]="800">
    <ppc-market-level-demographic-form *ngIf="marketLevelDemographicUnderEdit$ | async" [marketLevelDemographic]="marketLevelDemographicUnderEdit$ | async"></ppc-market-level-demographic-form>
  </app-slide-in-overlay>
</ng-container>
