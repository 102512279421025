<ppc-dialog #apiKeySecret
  [dismissOnBlur]="true"
  (onClose)="resetApiKeySecret()"
  >
  <h3 header class="app-modal-header">Api Key Secret</h3>
  <div body class="layout vertical">
    <p>This <code>api_secret_key</code> will only be displayed once.</p>
    <p>Keep this secret key in a safe place as it won’t be displayed again.</p>
    <ppc-input
      [ngModel]="api_key_id"
      type="input"
      #apiKeyId
      [class.copy-cursor]="apiKeyId.value"
      (click)="copyText(apiKeyId.value)"
      (click)="copyText(apiKeyId.value);copied[0] = true; copied[1] = false"
      >
      <label>api_key_id</label>
    </ppc-input>
    <ppc-input
      [ngModel]="api_secret_key"
      type="input"
      [class.copy-cursor]="apiSecretKey.value"
      (click)="copyText(apiSecretKey.value)"
      (click)="copyText(apiSecretKey.value);copied[1] = true; copied[0] = false"
      #apiSecretKey
      >
      <label>api_secret_key</label>
    </ppc-input>
    <p *ngIf="!(copied[0] || copied[1])"><b>Click value to copy to the clipboard.</b></p>
    <p *ngIf="copied[0]"><code>api_key_id</code> copied to clipboard.</p>
    <p *ngIf="copied[1]"><code>api_secret_key</code> copied to clipboard.</p>
  </div>
  <div footer>
    <button mat-button (click)="onCancelClick()">Close</button>
  </div>
</ppc-dialog>
