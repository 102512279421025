import * as actions from 'app/segments-hierarchy/segments-hierarchy.action'

export interface State {
  vendorDisplayNames: VendorDisplayName[]
}

export const initialState: State = {
  vendorDisplayNames: []
}

export function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case actions.LoadVendorDisplayNames.type:
      return Object.assign({}, state, { vendorDisplayNames: action.vendorDisplayNames });
    case actions.LoadAdminPermissionTriples.type:
      return action.adminPermissionTriples;
    default:
      return state;
  }
}

export interface VendorDisplayName {
  value: string,
  label: string
}
