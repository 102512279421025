import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {clearAuthToken} from "../shared/utils/auth-utils";
import { PpcHttpService, apiUrl } from 'app/services/ppc_http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { confirmAccountUrl } from "app/shared/constants/auth.urls";

@Component({
  selector: 'app-confirm-account',
  styleUrls: [ './confirm-account.component.sass' ],
  templateUrl: './confirm-account.component.html'
})

export class ConfirmAccountComponent implements OnInit {
  params: any = {};
  email: string = null;
  invalidToken: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: PpcHttpService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    clearAuthToken();
    this.verifyToken();
  }

  verifyToken() {
    const token = this.route.snapshot.params["confirmationToken"];
    const params = { user: { confirmation_token: token } };
    this.http.post(confirmAccountUrl(), params, { observe: 'response' }).subscribe(
      response => {
        const body = response.body;
        if (response.status == 200) {
          this.email = body.email;
          const lionLoginUrl = `${apiUrl('/lion-login')}?RelayState=${btoa(JSON.stringify('/'))}`
          setTimeout(() => {
            window.location.href = lionLoginUrl;
          }, 3000)
        } else {
          this.invalidToken = true;
          this.snackbar.open(`Something went wrong. Please try again in a few minutes.`, null, {
            duration: 5000,
            panelClass: [
              "danger"
            ]
          })
        }
      },
      error => {
        this.invalidToken = true;
        console.error(error);
      }
    );
  }
}
