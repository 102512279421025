import {combineLatest, Observable} from 'rxjs';
import {tap, filter, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {select, Store} from "@ngrx/store";
import {AppState} from "app/reducers";
import {Go} from "app/router/router.actions";
import {FetchClientFeatureAccess, FetchFeatureModules} from "app/feature-access/feature-access.actions";
import {isLoaded} from "app/shared/utils/fetch-state";
import {FetchHierarchy} from "app/hierarchy/hierarchy.actions";
import {canClientRegionAccessFeature, featuresForClientRegion} from "app/feature-access/feature-access.reducers";
import {ContextSlugs} from "app/hierarchy/hierarchy.interface";
import { getContextSlugs, getSlugs } from 'app/hierarchy/hierarchy.utils'
import { MatSnackBar } from '@angular/material/snack-bar';

export const NO_FEATURE_ACCESS_MESSAGE = "You were redirected here because the requested client doesn't have access to the requested feature of Publicis PeopleCloud";

@Injectable()
export class ClientFeatureAccessGuard implements CanActivateChild, CanActivate {

  constructor(private store: Store<AppState>, private snackbar: MatSnackBar) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest(
      ...[FetchClientFeatureAccess.type, FetchFeatureModules.type, FetchHierarchy.type].map(
        actionType => this.store.select('fetchStates').pipe(
          select(actionType),
          select(isLoaded)
        )
      )).pipe(
      filter(loadedData => loadedData.every(Boolean)),
      mergeMap(() => this.checkClientFeatureAccess(next.data.requiredClientFeatureAccess,
        getContextSlugs(next.params))))
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(next, state);
  }

  checkClientFeatureAccess(featureName: string, contextSlugs: ContextSlugs): Observable<boolean> {
    return canClientRegionAccessFeature(this.store,
      contextSlugs.clientSlug,
      contextSlugs.regionSlug,
      featureName)
      .pipe(
        tap(canAccess => {
          if (!canAccess) {
            this.store.dispatch(new Go({ path: [...getSlugs(contextSlugs), 'home'] }))
            this.snackbar.open(NO_FEATURE_ACCESS_MESSAGE, 'Ok', { duration: 10000,
              panelClass: ['warning'] })
          }
        })
      )
  }
}
