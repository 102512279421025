import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { isArray, isString, join } from "lodash";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import * as actions from "app/admin/destination-management/destination-management.actions";
import { PageInfo } from 'app/models/paginable.model';
import { Destination } from "app/admin/destination-management/destination.model";
import { apiUrl, PpcHttpService } from "app/services/ppc_http.service";
import { ACTIVATION_API_PATH, V1 } from 'app/shared/utils/constants';
import { fetchResource } from "app/shared/utils/fetch-state";

interface DestinationsPage {
  data: Destination[];
  page: PageInfo;
}

function destinationsUrl(id?: number): string {
  return apiUrl(`${ACTIVATION_API_PATH}/${V1}/destinations/${id || ''}`);
}

function destinationAdminUrl() {
  return `${destinationsUrl()}admin`;
}

@Injectable()
export class DestinationManagementService {
  @Effect()
  fetchDestinations$ = this.actions$.pipe(
    ofType(actions.FetchDestinations.type),
    (
      fetchResource(
        (action) => this.getDestinations(action.fetchOptions).pipe(map(res => new actions.LoadDestinations(res.data, res.page)))
      )
    )
  );

  @Effect()
  savePartialDestination$ = this.actions$.pipe(
    ofType(actions.SavePartialDestination.type),
    fetchResource(
      action => this.savePartialDestination(action.id, action.destination).pipe(map(destination => {
        return new actions.LoadPartialDestination(destination)
      }))
    )
  );

  constructor(
    private http: PpcHttpService,
    private actions$: Actions,
  ) {}

  getAllDestinations(): Observable<DestinationsPage> {
    return this.http.get(destinationAdminUrl()) as Observable<DestinationsPage>;
  }

  getDestinations(fetchOptions?: actions.FetchOptions): Observable<DestinationsPage> {
    let url = destinationAdminUrl();
    if (fetchOptions) {
      url = url + '?';
      if (fetchOptions.limit !== undefined) {
        url = url + `limit=${fetchOptions.limit}`;
      }
      if (fetchOptions.offset !== undefined) {
        url = url + `&offset=${fetchOptions.offset}`;
      }
      if (fetchOptions.companyId !== undefined) {
        url = url + `&companyId=${fetchOptions.companyId}`;
      }
      if (fetchOptions.state !== undefined) {
        url = url + `&state=${fetchOptions.state}`;
      }
      if (isString(fetchOptions.searchTerm) && fetchOptions.searchTerm.length > 0) {
        url = url + `&searchTerm=${fetchOptions.searchTerm}`;
      }
      if (isArray(fetchOptions.sort) && fetchOptions.sort.length > 0) {
        url = url + `&sort=${join(fetchOptions.sort, ',')}`;
      }
    }
    return this.http.get(url) as Observable<DestinationsPage>;
  }

  savePartialDestination(id: number, destination: Partial<Destination>): Observable<Destination> {
    const body = {
      ...destination,
      destinationId: id
    }
    return this.http.patch(destinationsUrl(), body) as Observable<Destination>;
  }

  getCompanies() {
    return this.http.get(apiUrl(`${ACTIVATION_API_PATH}/${V1}/companies`));
  }
}
