import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, OnChanges,
  SimpleChanges
} from "@angular/core";
import {cloneDeep, isEqual} from "lodash";
import {Moment} from "moment";
import {Subscription, Observable} from "rxjs";

import {MediaTypes, Plan, SerializedPlan} from "../plans/plan.model";
import {HierarchyService} from "../services/hierarchy.service";
import {CurrencyService, Currency} from "../services/currency.service";
import {ScenarioDialogService, GoalTypeDetails} from "./scenario-dialog.service";
import {omit} from "app/shared/utils/utils";
import {CurveService} from "../services/curves.service";
import {Curve} from "../admin/plans-admin/curve";
import {MediaTypeGoal, MediaTypeAllocation} from "./media-type-table/media-type-goal";
import {toMap} from "app/shared/utils/utils";
import {PlanInputs} from "./plan-inputs/plan-inputs";
import {PlanInputsComponent} from "./plan-inputs/plan-inputs.component";
import {MediaTypeTableComponent} from "./media-type-table/media-type-table.component";

@Component({
  selector: 'app-scenario-dialog',
  templateUrl: './scenario-dialog.component.html',
  styleUrls: ['./scenario-dialog.component.sass']
})
export class ScenarioDialogComponent implements OnInit, OnDestroy {
  CATCH_ALL_ERROR_MSG = "Something went wrong, please refresh and try again";

  plan: Plan;
  currencySymbol: string;
  mediaTypeGoals: MediaTypeGoal[] = [];
  curves: Curve[];

  errorMsg: string;
  currencyCode: string;
  originalPlanCopy: Plan;
  planInputs: PlanInputs;
  isValid: boolean = true;
  defaultPlanInputs: PlanInputs;
  curveTypes: {name: string, id: number}[] = [];
  goalTypes: {[goalTypeName: string]: GoalTypeDetails} = {};
  importedPlan: boolean = false;
  nonEditable: boolean = false;

  @ViewChild('planInputsForm', { static: true }) planInputsForm: PlanInputsComponent;
  @ViewChild('mediaTypeTable', { static: true }) mediaTypeTable: MediaTypeTableComponent;

  @Input('onConfirm') save: (plan: Plan, callback: (success) => void, error: (error) => void) => void;
  @Input() isNewPlan: boolean;
  @Input() planNames: string[];

  // Emits true if save was successful and false otherwise
  @Output() completedSave: EventEmitter<Plan> = new EventEmitter<Plan>();
  @Output() onClose = new EventEmitter<void>();

  subscriptions: Subscription[] = [];

  constructor(
    private hierarchyService: HierarchyService,
    private currencyService: CurrencyService,
    private scenarioDialogService: ScenarioDialogService,
    private curveService: CurveService
  ) {
    this.plan = new Plan({});

    this.defaultPlanInputs = {
      name: null,
      goalType: '',
      goal: null,
      curveType: {name: null, id: null},
      universe: null
    };

    this.planInputs = cloneDeep(this.defaultPlanInputs);

    const hierarchySub = this.hierarchyService.fullContext$.subscribe(
      ({region}) => {
        this.currencyCode = region.currency;
        this.setBrand();
      },
      error => this.errorMsg = this.CATCH_ALL_ERROR_MSG
    );

    this.subscriptions = [hierarchySub];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public setPlan(plan: Plan) {
    this.plan = cloneDeep(plan);
    this.originalPlanCopy = cloneDeep(plan);
    this.currencySymbol = "";
    const currencySub = this.currencyService.getCurrency(this.currencyCode)
      .subscribe(
        currency => this.currencySymbol = currency && currency.symbol
      );

    const dateModel = {
      start: plan.startDate,
      end: plan.endDate
    };

    this.planInputs = {
      name: this.plan.planName,
      flightDates: dateModel,
      goalType: this.plan.goalType,
      goal: parseFloat(this.plan.goal.toFixed(2)),
      curveType: {name: this.plan.curveTypeName, id: plan.curveType},
      oldName: this.plan.planName,
      universe: this.plan.universe
    };
    this.importedPlan = plan.imported;

    this.updateOptions();
    this.mediaTypeTable.isPercent = plan.mediaTypeUnit === "Percentage";
    const multiplier = this.mediaTypeTable.isPercent ? 100 : 1;
    const mediaTypeNames = Object.keys(plan.mediaTypes);
    this.mediaTypeGoals.forEach(mtg => {
      if (mediaTypeNames.indexOf(mtg.name) >= 0) {
        mtg.min = plan.mediaTypes[mtg.name].Minimum * multiplier;
        mtg.max = plan.mediaTypes[mtg.name].Maximum * multiplier;
        mtg.selected = true;
      } else {
        mtg.selected = false;
      }
    });

    currencySub.unsubscribe();
  }

  public setBrand() {
    const currencySub = this.currencyService.getCurrency(this.currencyCode)
      .subscribe(
        currency => {
          this.currencySymbol = currency && currency.symbol;
          this.plan.currency = currency && currency.id;
        }
      );
    this.curveService.getCurves().subscribe(
      curves => {
        this.curves = curves;
        this.updateForCurves(curves);
      },
      error => this.errorMsg = this.CATCH_ALL_ERROR_MSG
    );
    this.curveService.getCurveTypes().subscribe(
      curveTypes => {
        this.curveTypes = Object.keys(curveTypes).map( ct => {return {name: ct, id: curveTypes[ct]} });
      },
      error => this.errorMsg = this.CATCH_ALL_ERROR_MSG
    );
    const goalTypeSub = this.scenarioDialogService.getGoalTypes().subscribe(
      goalTypes => {
        this.goalTypes = goalTypes;
      },
      error => this.errorMsg = this.CATCH_ALL_ERROR_MSG
    );

    this.subscriptions.push(currencySub, goalTypeSub);
  }

  ngOnInit() {
    this.mediaTypeTable.resetGoals();
  }

  public setProduct() {
    this.currencyService.getCurrency(this.currencyCode)
      .subscribe(
        currency => {
          this.currencySymbol = currency && currency.symbol;
          this.plan.currency = currency && currency.id;
        }
      );
  }

  checkTooltip(segmentName: string) {
    return segmentName.length > 32 ? segmentName : "";
  }

  validateForm() {
    const inputsValid = this.importedPlan ? this.planInputsForm.nameValid : this.planInputsForm.isValid();
    const mediaTypesValid =  this.importedPlan || this.mediaTypeTable.isValid();
    return inputsValid && mediaTypesValid;
  }

  confirmForm() {
    this.isValid = this.validateForm();
    this.nonEditable = this.isValid;
    if (this.isValid) {
      this.plan.planName = this.planInputs.name;
      if (!this.importedPlan) {
        this.plan.goalType = this.planInputs.goalType;
        this.plan.goal = this.planInputs.goal;
        this.plan.curveTypeName = this.planInputs.curveType.name;
        this.plan.curveType = this.planInputs.curveType.id;
        this.plan.universe = this.planInputs.universe;
        this.plan.mediaTypeUnit = this.mediaTypeTable.isPercent ? "Percentage" : "Currency";
        this.plan.mediaTypes = this.mediaTypeGoalsToMediaTypes(this.mediaTypeGoals);
        this.setPlanDates(this.planInputs.flightDates);
      }
      this.savePlan();
    }
  }

  clearForm() {
    this.plan = new Plan({});
    this.setBrand();
    this.mediaTypeTable.isPercent = true;
    this.mediaTypeTable.resetGoals();
    this.planInputsForm.resetValidations();
    this.planInputs = cloneDeep(this.defaultPlanInputs);
    this.isValid = true;
    this.importedPlan = false;
    this.nonEditable = false;
  }

  closeForm() {
    this.errorMsg = null;
    this.clearForm();
    this.onClose.emit();
  }

  updateOptions() {
    if (this.planInputs.curveType && this.curves) {
      const filteredCurves = this.filterCurves(
        this.curves,
        {curveType: this.planInputs.curveType.name}
      );
      this.updateForCurves(filteredCurves);
    }
  }

  get planNeedsToBeSubmitted(): boolean {
    const propertiesThatDontNecessitateResubmission: (keyof SerializedPlan)[] = ['name'];

    const edited = omit(propertiesThatDontNecessitateResubmission, this.plan.toJson());
    const original = omit(propertiesThatDontNecessitateResubmission, this.originalPlanCopy.toJson());

    // imported plans should never be submitted
    return !this.plan.imported && !isEqual(
      edited,
      original
    )
  }

  get isReachGoal(): boolean {
    return (this.planInputs.goalType == 'Reach' || this.planInputs.goalType == 'Penetration')
  }

  private mediaTypeGoalsToMediaTypes(mediaTypeGoals: MediaTypeGoal[]): MediaTypes {
    const isPercent = this.mediaTypeTable.isPercent;
    return toMap<MediaTypeAllocation>( mtg => mtg.MediaType,
      mediaTypeGoals
        .filter(mtg => mtg.selected)
        .map( mtg => mtg.toMediaType(isPercent) ) );
  }

  private updateForCurves(curves: Curve[]) {
    const mediaTypes = new Set(curves.map(c => c.media_type));
    this.setMediaTypeSelections(Array.from(mediaTypes));
  }

  private setMediaTypeSelections(mediaTypes: string[]) {
    this.mediaTypeGoals = mediaTypes.sort()
      .map(mt => {
        return new MediaTypeGoal({
          name: mt,
          min: 0,
          max: 100,
          displayMin: "",
          displayMax: "",
          selected: true
        })
      });
  }

  private filterCurves(
    curves: Curve[],
    selections: {curveType?: string }
  ): Curve[] {
    return curves.filter(curve => {
      return (!selections.curveType || curve.curve_type_name == selections.curveType )
    })
  }

  private setPlanDates(flightDates: {start: Moment, end: Moment}) {
    this.plan.startDate.set({
      year: flightDates.start.get("year"),
      month: flightDates.start.get("month"),
      date: flightDates.start.get("date")
    });
    this.plan.endDate.set({
      year: flightDates.end.get("year"),
      month: flightDates.end.get("month"),
      date: flightDates.end.get("date")
    });
  }

  private savePlan() {
    const save$: Observable<Plan> = this.isNewPlan ? this.scenarioDialogService.saveNewScenario(this.plan)
      : this.scenarioDialogService.updateScenario(this.plan);

    save$.subscribe(
      plan => {
        if (this.isNewPlan || this.planNeedsToBeSubmitted) {
          this.scenarioDialogService.submitScenario(plan._id).subscribe(
            plan => {
              this.plan = plan;
              this.completedSave.emit(this.plan);
              this.closeForm();
            }
          );
        } else {
          this.completedSave.emit(this.plan);
          this.closeForm();
        }
      },
      error => {
        console.error('error saving plan');
        this.completedSave.emit(null);
        const errArr = JSON.parse(error._body);
        if (errArr && errArr.error_messages != null) {
          this.errorMsg = errArr.error_messages[0];
        } else {
          this.errorMsg = "Something went wrong, please try again"
        }
        this.isValid = false;
      }
    );
  }

}
