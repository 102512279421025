import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Node } from './root-nodes/root-node.interface';
import { isEqual } from 'lodash';
import { AppState } from 'app/reducers';
import { activeContext } from 'app/hierarchy/hierarchy.reducers';
import { isDefined } from 'app/shared/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class SegmentPickerContextStateService {
  insightsContext$: BehaviorSubject<string> = new BehaviorSubject('');
  componentStates: { [context: string]: SegmentPickerState } = {};

  constructor(private store: Store<AppState>) {
    activeContext(this.store).pipe(
      filter(isDefined),
      distinctUntilChanged(isEqual),
    ).subscribe(() => this.resetContextState());
  }

  getContextState() {
    return this.componentStates[this.insightsContext$.getValue()];
  }

  updateContextState(levels: Node[][], selections: Partial<Node>[]) {
    this.componentStates[this.insightsContext$.getValue()] = { levels, selections };
  }

  resetContextState() {
    this.componentStates = {};
  }
}

export interface SegmentPickerState {
  levels: Node[][];
  selections: Partial<Node>[];
}
