
import {throwError as observableThrowError, Observable} from 'rxjs';

export function handleError(error: any) {
  let errMsg: string;
  const body = error || '';
  const err = body.error || JSON.stringify(body);
  errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
  console.error(errMsg);
  return observableThrowError(errMsg);
}

export function impossible(term: never): never {
  throw new Error();
}
