import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export class Go implements Action {
  static readonly type = '[Router] Go';
  readonly type = Go.type;

  constructor(public payload: {
    path: any[];
    query?: object;
    extras?: NavigationExtras;
  }) {}
}

export class Back implements Action {
  static readonly type = '[Router] Back';
  readonly type = Back.type;
}

export class Forward implements Action {
  static readonly type = '[Router] Forward';
  readonly type = Forward.type;
}

export type Actions
  = Go
  | Back
  | Forward;
