<ng-container *ngIf="!rotate">
  <div *ngIf="name" class="progress-name">{{name}}</div>

  <div
    class="progress-bar"
    [style.background-color]="backgroundColor"
    [style.border-radius]="rounded ? '6px' : ''"
    [style.height.px]="thickness"
  >
    <span class="core"
      [class.animate]="shouldAnimate"
      [style.border-radius]="rounded ? '6px' : '3px'"
      [style.width.%]="shouldAnimate ? endPercent : percent"
      [style.background-color]="color"
      [style.opacity]="opacity || 1"
      [style.border-color]="lineColor"
    >
    </span>

    <span *ngIf="!hideText && !showOuterText" class="progress-number-inner text-inside">
      {{percent}}%
    </span>
  </div>

  <div *ngIf="!hideText && showOuterText" class="progress-number-outer text-outside">
    {{percent}}%
  </div>
</ng-container>

<ng-container *ngIf="rotate">
  <div class="vertical-name">
    <span class="rotate">
      {{name}}
    </span>
  </div>

  <div class="vertical" [style.width.px]="thickness">
    <div>
      {{percent}}%
    </div>
    <div class="progress-bar-vertical" [style.background-color]="backgroundColor" [style.border-radius]="rounded ? '6px' : ''">
      <div class="core-vertical"
        [style.border-radius]="rounded ? '6px' : '3px'"
        [style.height.%]="shouldAnimate ? endPercent : percent"
        [style.width]="'100%'"
        [style.background-color]="color"
        [style.opacity]="opacity || 1"
        [style.border-color]="lineColor">
      </div>
    </div>
  </div>
</ng-container>
