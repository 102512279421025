import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {createSelector} from "@ngrx/store"
import {compareKey} from "app/shared/utils/utils";
import {map} from 'lodash';

import {Plan} from "./plan.model";
import * as actions from './plans.actions'

export type State = EntityState<Plan>

const adapter: EntityAdapter<Plan> = createEntityAdapter<Plan>({
  selectId: plan => plan._id,
  sortComparer: compareKey('name')
});

const initialState: State = adapter.getInitialState();

export function reducer(state = initialState, action: actions.All): State {
  switch (action.type) {
    case actions.LoadPlans.type:
      return adapter.addMany(action.plans, state);
    case actions.RemovePlan.type:
      return adapter.removeOne(action.plan._id, state);
    case actions.UpsertPlan.type:
      return state.entities[action.plan._id] ? adapter.updateOne({id: action.plan._id, changes: action.plan}, state)
        : adapter.addOne(action.plan, state);
    case actions.ClearPlans.type:
      return adapter.removeAll(state);
    case actions.SetCurveTypeNames.type:
      // TODO: this should just happen on the back end
      return adapter.updateMany(
        getPlansList(state)
          .map(plan => ({
            id: plan._id,
            changes: {curveTypeName: plan.findCurveTypeName(action.curveTypes)}
          })),
        state
      );
    default:
      return state
  }
}

const { selectEntities, selectAll } = adapter.getSelectors();

// These are necessary because the entity adapter is meant for plain data not class instances and it
// doesn't maintain prototype info on updates.
// They simply wrap the adapter selectors and assign the data to instances of Plan objects
export const getPlansMap = createSelector(
  selectEntities,
  (plansMap) => map(plansMap, plan => Object.assign(new Plan({}), plan))
);

export const getPlansList = createSelector(
  selectAll,
  (plansList) => plansList.map(plan => Object.assign(new Plan({}), plan))
);
