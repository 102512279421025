<div class="instructions"  *ngIf="(discussionClusterHasNodes || weboramaNodes) && !(clusterNotFound$ | async)">
  Explore content consumption patterns from {{insightsContext == 'grow' && (hasWeboramaAccess$ | async) ? "Weborama's Big Fish tool" : 'uploaded Discussions'}}.
</div>

<div class="instructions no-weborama-nodes" *ngIf="!discussionClusterHasNodes && !weboramaNodes">
  Select {{insightsContext == 'grow' ? 'an Audience in the Chart' : 'a Stage in the Journey'}} to explore content consumption patterns from uploaded Discussions{{insightsContext == 'grow' && (hasWeboramaAccess$ | async) ? " or Weborama's Big Fish tool. All Weborama queries are from a global database." : '.'}}
</div>

<div class="red instructions" *ngIf="clusterNotFound$ | async">
  The remote ID provided is not valid, please check you have added the correct ID number.
</div>
<div class="red instructions" *ngIf="errorMessage$ | async">
  {{ errorMessage$ | async }}
</div>

<div class="market-level-discussions fa-3x" *ngIf="hasDiscussion ? !discussionClusterHasNodes && (loading$ | async) : !weboramaNodes && (loading$ | async)">
  <div class="vertical-center">
    <i class="fa fa-spinner fa-pulse"></i>
  </div>
</div>

<div class="market-level-discussions" *ngIf="(hasDiscussion ? discussionClusterHasNodes : weboramaNodes) && !(clusterNotFound$ | async)">
  <app-popup [rightPct]="0" [widthPx]="150" *ngIf="!hasDiscussion">
    <i class="fa fa-ellipsis-v menu-icon hoverable" popupTrigger></i>
    <div popupBody>
      <div class="popup-button" (click)="editWeboramaGroup()">Edit</div>
    </div>
  </app-popup>
  <ppc-market-level-discussions-chart [nodes]="hasDiscussion ? discussionClusters : weboramaNodes" *ngIf="hasDiscussion ? discussionClusterHasNodes : weboramaNodes" (clusterChange)="onClusterChange($event)"></ppc-market-level-discussions-chart>
  <div class="market-level-discussions-info">
    <div class="market-level-discussions-info-header">
      <div class="info-field">
        <div class="info-field-header">
          Name
        </div>
        <div class="info-field-value">
          {{ name }}
        </div>
      </div>
      <div class="info-field">
        <div class="info-field-header">
          Percent of Audience
        </div>
        <div class="info-field-value">
          {{reachablePeoplePercent | percent:'1.0-2'}}
        </div>
      </div>
    </div>
    <div class="market-level-discussions-info-description" *ngIf="description">
      <div class="description-header">
        Description
      </div>
      <p>
        {{ description }}
      </p>
    </div>
  </div>
</div>
