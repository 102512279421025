import { SegmentCounts } from 'app/audiences/discover/segment-v2.model';
import { AudienceRule, newAudience, AudienceV2 } from 'app/audiences-v2/audience-v2.model';
import { MotivationV1 } from 'app/models/motivations/motivations-v1.model';
import { keys } from 'lodash';
import {Transaction} from 'app/models/transaction.model';
import {DataPermissions} from "../data_permissions/data_permissions.model";

export interface LookalikeV3 {
  id?: string;
  id_space?: string;
  identifier?: string;
  name: string;
  description: string;
  expiration?: string;
  created_at?: string;
  updated_at?: string;
  seed_identifier: string;
  seed_rules: {
    include: AudienceRule;
    exclude: AudienceRule;
  };
  seed_name: string;
  seed_counts?: SegmentCounts;
  job_status: string;
  include_seed_in_model: boolean;
  current_confidence?: number;
  current_confidence_pid_count?: number;
  confidence_sizes?: {[confidence_size: number]: {[field: string]: number}};
  universe_permissions?: string[];
  send_to_tardiis?: string;
  count?: SegmentCounts;
  count_status?: string;
  panelist_count?: number;
  type?: string;
  activated?: object;
  transactions?: Transaction[],
  permissions?: DataPermissions["permissions"];
  deletable?: boolean;
  delete_disallow_reasons?: string[];
}

export function newLookalikeV3(audience?: AudienceV2 | MotivationV1) {
  const seed_identifier = audience ? audience.identifier : "";
  const seed_name = audience ? audience.name : "";
  const name = audience ? audience.name : "";
  const seed_rules = audience ? (audience as AudienceV2).rules : {
    include: newAudience,
    exclude: newAudience
  };
  const seed_counts = audience ? (audience as AudienceV2).count : "";
  const className = audience ? audience.type.charAt(0).toUpperCase() + audience.type.slice(1) : "";

  return <LookalikeV3>{
    count_status: 'unknown',
    current_confidence: 80,
    description: "",
    id_space: 'metagraph',
    include_seed_in_model: false,
    job_status: 'new',
    name: name,
    seed_counts: seed_counts,
    seed_identifier: seed_identifier,
    seed_type: className,
    seed_name: seed_name,
    seed_rules: seed_rules,
    type: "lookalike",
    universe_permissions: [],
  }
}

export function getLookalikeCount(lal: LookalikeV3, field: string):  number {
  return lal.count[field] && lal.count[field].global;
}

export function getLookalikeCurrentPeople(lal: LookalikeV3): number {
  return lal.confidence_sizes[lal.current_confidence].people;
}

export function convertConfidenceSizesToArray(lookalike: LookalikeV3): {confidence: number, reach: number}[] {
  return keys(lookalike.confidence_sizes).map(key => ({
    reach: lookalike.confidence_sizes[key]["people"],
    confidence: parseInt(key),
  })
  );
}
