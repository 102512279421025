import { reduce, get } from 'lodash';
import { ResourceSubMarket } from 'app/insights/insights.reducer';
import { Persona } from 'app/explore/explore.reducer';
import { ChartTypes, WidgetType } from 'app/insights/insights.models';

export interface MarketLevelDemographic {
  name: string;
  position?: number;
  id?: number;
  chart_type: WidgetType;
  widget_type?: WidgetType;
  market_level_demographic_buckets: MarketLevelDemographicBucket[];
  market_level_demographic_buckets_attributes?: MarketLevelDemographicBucket[];
  visible: boolean;
  resource_id: number;
  resource_type: string;
  id_count?: string;
  custom_tab_id?: number;
  description?: string;
  created_at?: string;
}

export interface MarketLevelDemographicBucket {
  name: string;
  id?: number;
  position?: number;
  _destroy?: boolean;
  sub_market_bucket_entries?: {[resourceId: number]: SubMarketBucketEntry};
  sub_market_bucket_entries_attributes?: SubMarketBucketEntry[];
}

export interface SubMarketBucketEntry {
  value: number;
  market_level_demographic_bucket_id?: number;
  resource_id: number;
  resource_type: string;
}

export function newMarketLevelDemographic(resourceId: number, resourceItems: ResourceSubMarket[] | Persona[], customTabId: number, resourceType: string): MarketLevelDemographic {
  const defaultIdCount = (resourceType == 'Persona') ? 'absolute' : 'percentage';
  return {
    name: "",
    chart_type: ChartTypes.BarHorizontal,
    market_level_demographic_buckets: [newMarketLevelDemographicBucket(resourceItems, resourceType)],
    visible: true,
    resource_id: resourceId,
    resource_type: resourceType,
    id_count: defaultIdCount,
    custom_tab_id: customTabId,
  }
}

export function newMarketLevelDemographicBucket(resourceItems, resourceType): MarketLevelDemographicBucket {
  return {
    name: "",
    sub_market_bucket_entries: reduce(resourceItems, (bucket, resource) => {
      bucket[resource.id] = newSubMarketBucket(resource.id, resourceType)
      return bucket
    }, {})
  }
}

export function newSubMarketBucket(resourceId, resourceType): SubMarketBucketEntry {
  let resource_type: string;

  switch (resourceType) {
    case 'Mekko':
      resource_type = 'SubMarket';
      break;
    case 'Journey':
      resource_type = 'JourneySubMarket';
      break;
    case 'Persona':
      resource_type = 'Persona';
      break;
    default:
      resource_type = null;
  }
  return {
    value: 0,
    resource_id: resourceId,
    resource_type
  }
}

export function marketLevelDemographicHasSubMarketEntry(marketLevelDemographic, resource) {
  return marketLevelDemographic.market_level_demographic_buckets.every(mldb => {
    return !!get(mldb.sub_market_bucket_entries, resource.id)
  })
}
