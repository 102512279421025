<div *ngIf="!!selectedCreative" class="wrapper">
  <div class="details">
    <i class="fa fa-times pull-right" (click)="closeClicked.emit()"></i>
    <img [src]="imageUrl" class="pull-left">
    <dl class="pull-left">
      <dt>File Name</dt>
      <dd>{{selectedCreative.filename}}</dd>
      <dt>Last Processed by Genome at</dt>
      <dd>{{selectedCreative.processed_at | date:'short'}}</dd>
    </dl>
  </div>
  <div *ngIf="scoredAttributes$">
    <app-creative-attribute-list #attrList
      [scoredAttributes$]="scoredAttributes$"
    ></app-creative-attribute-list>
  </div>
</div>
