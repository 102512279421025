import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpClient, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {getPath} from 'app/hierarchy/hierarchy.utils';
import {activeContext} from 'app/hierarchy/hierarchy.reducers';
import {getAllRouteParams} from "app/shared/utils/utils";

@Injectable()
export class HierarchyInterceptor implements HttpInterceptor {

  constructor(private store: Store<AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return activeContext(this.store).pipe(
      take(1),
      switchMap(context => next.handle(
        request.clone({ setHeaders: { 'X-Context': request.headers.get('X-Context') || getPath(context) } }))))
  }
}
