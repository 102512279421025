<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
  <h1>
    <span class="object-name">Segment Permissions</span>
  </h1>
</header>
<mat-form-field
  class='dropdown'
  *ngIf='dataSources'
>
  <mat-select
    placeholder='Select Data Source'
    class='dropdown-select-data-sources'
    [(value)]='selectedDataSource'
    (selectionChange)='handleDataSourceSelectedEvent()'
  >
    <mat-option
      class='select-option-data-sources'
      *ngFor='let permission of dataSources'
      [value]='permission'
    >
      {{permission.attributes.vendor_name}} - {{permission.attributes.vendor_type}}
      <span
        *ngIf='permission.attributes.segment_owner_name'
      >
        - {{permission.attributes.segment_owner_name}}
      </span>
    </mat-option>
  </mat-select>
</mat-form-field>

<ppc-segment-permissions-table
  #permissionsTable
  [clients]='clients'
  [selectedDataSource]='selectedDataSource'
  (newRowEvent)='handleNewRowEvent()'
  (clientSelectedEvent)='handleClientSelectedEvent()'
  (newRowDeletedEvent)='handleNewRowDeletedEvent()'
>
</ppc-segment-permissions-table>

<button
  buttonContinueLabel='Submit'
  class='submit-button pds-btn'
  [disabled]='!canSubmit()'
  [kontinue]='save.bind(this)'
  modalTitle='Are you sure?'
  modalContent='You are currently trying to update a permission set. This action cannot be undone.'
  ppcConfirmation
>
  Submit
</button>
