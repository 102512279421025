import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { get, find, difference } from 'lodash';

declare const Packery;
declare const Draggabilly;

@Injectable()
export class PackeryService {
  bags: {[bagName: string]: PackeryBag} = {};

  constructor() { }

  initialize(data: {element: HTMLElement, forceLayout$: Observable<{}>, id: string}): Subject<any> {
    const {element, forceLayout$, id} = data;

    const grid = this.createGrid(element);
    const onReorder$ = new Subject();
    const gridDestroyed$ = new Subject();

    this.bags[id] = {
      grid,
      onReorder$,
      gridDestroyed$,
      element,
      children: Array.from(element.children)
    };

    grid.getItemElements().forEach(itemElem => makeDraggable(grid, itemElem));

    grid.on('dragItemPositioned', layout => {
      const items = grid.getItemElements();
      onReorder$.next(items);
    });

    forceLayout$.pipe(takeUntil(gridDestroyed$)).subscribe(() => {
      this.layout(id);
    })

    return onReorder$
  }

  createGrid(element: HTMLElement) {
    const columnWidth = get(find(element.children, "clientWidth"), "clientWidth", 0);
    return new Packery(element, {columnWidth, gutter: 10})
  }

  layout(id: string) {
    const {grid, element, children} = this.bags[id];

    // This line is the result of over 8 hours of debugging and, in the end,
    // was just a lucky guess. Remove at your own peril.
    // Basically you can't layout the grid mid-drag
    if (grid.dragItemCount) {return; }

    // reload new elements from the DOM
    grid.reloadItems();

    // Set the column width in case item sizes changed
    grid.options.columnWidth = get(find(element.children, "clientWidth"), "clientWidth", 0);

    // Need to add drag functionality to new elements
    const newChildren = difference(element.children, children).filter(element => !element.className.includes("packery-drop-placeholder"));
    newChildren.forEach(itemElem => makeDraggable(grid, itemElem));

    if (!newChildren.every((child: HTMLElement) => !!child.dataset.id)) {
      return console.error(`
        Found a packery element without an id. All elements within a bag must have a unique identifier
        set on the element like this: [attr.data-id]="<item id>"
      `)
    }

    this.bags[id].children = Array.from(element.children);
    grid.layout()

  }

  destroy(id: string) {
    const {grid, onReorder$, gridDestroyed$} = this.bags[id];
    grid.destroy();
    gridDestroyed$.next();
    gridDestroyed$.complete();
    onReorder$.complete();
    delete this.bags[id];
  }

}

export interface PackeryBag {
  onReorder$: Subject<{}>;
  gridDestroyed$: Subject<{}>;
  grid: any; // Packery doesn't have types
  element: HTMLElement;
  children: Element[];
}

function makeDraggable(grid, itemElem ) {
  const draggie = new Draggabilly( itemElem, {
    handle: '.drag-handle'
  });
  grid.bindDraggabillyEvents( draggie );
}
