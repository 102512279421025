
import {combineLatest as observableCombineLatest, Observable, Subject} from 'rxjs';

import {takeUntil, map} from 'rxjs/operators';
import {Component, OnDestroy, ViewChild, AfterViewInit} from "@angular/core";
import {Plan} from "../plans/plan.model";
import { MatDialog } from "@angular/material/dialog";
import {SlideInOverlayComponent} from "../shared/components/slide-in-overlay/slide-in-overlay.component";
import {ScenarioDialogComponent} from "../scenario-dialog/scenario-dialog.component";
import * as moment from 'moment';
import { Moment } from 'moment';
import {Store} from "@ngrx/store";
import { Actions, ofType } from '@ngrx/effects';
import {CompleteFetch} from 'app/shared/utils/fetch-state';
import {AppState} from '../reducers'
import {fullContext} from "../hierarchy/hierarchy.reducers";
import {getCurrenciesMap} from '../currencies/currencies.reducers'
import {getPlansList} from '../plans/plans.reducers';
import {FetchPlans, RemovePlan, UpsertPlan} from "app/plans/plans.actions";
import {hasError} from "../shared/utils/fetch-state";
import {FetchCurveTypes} from "../plans/curves/curves.actions";
import {DateRangePickerComponent} from "../shared/components/date-range-picker/date-range-picker.component";

@Component({
  selector: 'app-plans-summary',
  templateUrl: './plans-summary.component.html',
  styleUrls: ['./plans-summary.component.sass']
})
export class PlansSummaryComponent implements AfterViewInit, OnDestroy {
  productId: string;
  advertiserName: string;
  plans: Array<Plan> = [];
  dateModel: {start: Moment, end: Moment} = {
    start: moment().startOf('year'),
    end: moment().endOf('year')
  }
  datePickerOptions = {
    opens: 'left'
  };

  searchQuery: string = "";

  plansLoaded = false;
  error: {isError: boolean, buttonFunction?: () => void} = {isError: false};

  // for scenario form overlay
  @ViewChild('overlay', { static: true }) private overlay: SlideInOverlayComponent;
  @ViewChild('scenarioForm', { static: true }) private scenarioForm: ScenarioDialogComponent;
  @ViewChild('dateRangePicker', { static: true }) private dateRangePicker: DateRangePickerComponent;

  newScenario: boolean = true;
  isFormOpen: boolean = false;
  currencyCode: string;
  loading: boolean = false;
  completedActions: string[] = []

  ngUnsubscribe = new Subject();

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private actions$: Actions
  ) {
    observableCombineLatest(
      this.store.select('currencies').pipe(map(getCurrenciesMap)),
      fullContext(this.store),
      this.store.select('plans').pipe(map(getPlansList)),
      this.store.select('fetchStates')
    ).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(
        ([currencies, hierarchyContext, plans, fetchStates = {}]) => {
          const { product, region } = hierarchyContext;

          this.productId = product.id;

          const currency = currencies[region.currency];
          this.currencyCode = currency && currency.iso_code;

          this.plans = plans;

          const plansError = hasError(fetchStates[FetchPlans.type]);
          const curvesError = hasError(fetchStates[FetchCurveTypes.type]);

          this.error.isError = plansError || curvesError;
          if (this.error.isError) {
            this.error.buttonFunction =
              curvesError    ? () => this.store.dispatch(new FetchCurveTypes()) :
                plansError     ? () => this.store.dispatch(new FetchPlans()) :
                  () => location.reload()
          }
        }
      );

    this.actions$
      .pipe(
        ofType(CompleteFetch.type),
        takeUntil(this.ngUnsubscribe))
      .subscribe((completedFetchAction: CompleteFetch) => {
        if (!this.completedActions.includes(completedFetchAction.fetchAction.type)) {
          this.completedActions.push(completedFetchAction.fetchAction.type)
        }
        if ([ FetchPlans.type ].every(type => this.completedActions.includes(type))) {
          this.loading = false;
        }
      });

    fullContext(this.store).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        ({ product }) => {
          this.loading = true;
          this.completedActions = [];
          store.dispatch(new FetchPlans());
        }
      );

    this.store.dispatch(new FetchCurveTypes())
  }

  ngAfterViewInit() {
    this.dateRangePicker.setTense('plansSummaryDefault');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  isPlanInDateRange(plan: Plan, dateRange: {start: Moment, end: Moment}): boolean {
    const {start, end} = dateRange || {} as any;
    return !start
           || !end
           || (plan.startDate > start && plan.startDate < end)
           || (plan.endDate > start && plan.endDate < end)
  }

  onDeletePlan(plan: Plan) {
    this.store.dispatch(new RemovePlan(plan));
  }

  get searchResultPlans(): Plan[] {
    return this.plans.filter(
      plan => this.isPlanInDateRange(plan, this.dateModel)
              && (!this.searchQuery || plan.planName.toLowerCase().includes(this.searchQuery.toLowerCase()))
    );
  }

  get planNames(): string[] {
    return this.plans.map(plan => plan.planName)
  }

  openCreateScenarioDialog() {
    this.newScenario = true;
    this.overlay.toggleState();
    this.dateRangePicker.setTense('future');
    // allow time for animation
    setTimeout(x => this.isFormOpen = true, 500);
  }

  openEditScenarioDialog(plan: Plan) {
    this.newScenario = false;
    this.scenarioForm.setPlan(plan);
    this.overlay.toggleState();
    // allow time for animation
    setTimeout(x => this.isFormOpen = true, 500);
  }

  onFormClose() {
    this.isFormOpen = false;
    this.overlay.toggleState();
    this.dateRangePicker.setTense('plansSummaryDefault');
    this.store.dispatch(new FetchPlans());
  }

  onErrorButtonClick() {
    this.error.buttonFunction && this.error.buttonFunction();
  }

  onPlanSave(plan: Plan) {
    this.store.dispatch(new UpsertPlan(plan));
  }

}
