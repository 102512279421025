import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest as observableCombineLatest, BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { difference, get, find, keys, sortBy, keyBy, map, cloneDeep } from 'lodash';
import { PpcDialogComponent } from 'app/shared/components/ppc-dialog/ppc-dialog.component';
import { Region } from '../region/region.model';
import { RegionService } from '../region/region.service';
import { Tab } from 'app/insights/insights.models';
import { INSIGHTS_CONTEXTS } from "app/insights/insights.constants";
import { AdminTabData } from 'app/admin/insights-tabs-admin/insights-tabs-admin.model';
import { tabAdminUrl } from 'app/shared/constants/insights.urls';

@UntilDestroy()
@Component({
  selector: 'ppc-grow-tabs-admin',
  templateUrl: './insights-tabs-admin.component.html',
  styleUrls: ['./insights-tabs-admin.component.sass']
})
export class InsightsTabsAdminComponent implements OnInit, OnDestroy {
  @ViewChild(PpcDialogComponent, { static: true }) dialog: PpcDialogComponent;
  private tabs$: BehaviorSubject<Tab[]> = new BehaviorSubject([]);

  tabs: Tab[];
  tabUnderEdit: Tab;
  regions: Region[] = [];
  regionsBySlug: {[slug: string]: Region} = {};
  insightsContexts = INSIGHTS_CONTEXTS;
  tableData: AdminTabData[];
  tableSorts = [{prop: 'insights_context', dir: 'asc'}];

  constructor(private http: HttpClient, private regionsService: RegionService) {
    this.http.get<Tab[]>(tabAdminUrl()).subscribe(tabs => this.tabs$.next(tabs));
  }

  ngOnInit() {
    observableCombineLatest(
      this.regionsService.getAllRegions(),
      this.tabs$,
    ).pipe(
      untilDestroyed(this)
    ).subscribe(([regions, tabs]: [Region[], Tab[]]) => {
      this.regions = sortBy(regions, 'name');
      this.regionsBySlug = keyBy(sortBy(regions, 'name'), 'slug');
      this.tabs = tabs;
      this.tableData = this.getTableData();
    });
  }

  ngOnDestroy() { }

  getTabParentName(tab: Tab) {
    return get(find(this.tabs, {id: tab.parent_id}), "name", "N/A")
  }

  getExcludedRegionsString(tab: Tab) {
    return map(this.getExcludedRegionSlugs(tab), slug => get(this.regionsBySlug[slug], "name")).join(", ") || 'N/A';
  }

  getIncludedRegionsString(tab: Tab) {
    const excludedRegions = this.getExcludedRegionSlugs(tab);
    return excludedRegions.length ? this.includedRegions(excludedRegions).join(", ") : 'All';
  }

  getExcludedRegionSlugs(tab: Tab) {
    return tab.excluded_regions.filter(region => keys(this.regionsBySlug).includes(region));
  }

  getTableData() {
    return this.tabs.map(tab => ({
      id: tab.id,
      insights_context: get(tab, 'insights_context'),
      name: get(tab, 'name'),
      parent_name: this.getTabParentName(tab),
      excluded_regions: this.getExcludedRegionsString(tab),
      included_regions: this.getIncludedRegionsString(tab)
    }));
  }

  includedRegions(excludedRegions: string[]) {
    return map(difference(keys(this.regionsBySlug), excludedRegions), slug => get(this.regionsBySlug[slug], "name"));
  }

  editTab(row: AdminTabData) {
    const tab = this.tabs.find(tab => tab.id == row.id);
    this.tabUnderEdit = cloneDeep(tab);
    this.dialog.show();
  }

  saveTab() {
    this.http.put(tabAdminUrl(), {tab: this.tabUnderEdit}).subscribe(
      (tabs: Tab[]) => {
        this.tabs$.next(tabs);
        this.tabUnderEdit = null;
        this.dialog.close();
      }
    )
  }

  cancelEdit() {
    this.tabUnderEdit = null;
    this.dialog.close();
  }
}
