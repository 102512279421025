import { map, filter } from 'rxjs/operators';
import { Component, OnDestroy, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter as _filter, find, map as _map, orderBy } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TogglePersona } from 'app/explore/explore.actions';
import { Persona, selectActivePersona, selectPersonas, selectSelectedPersonas } from "app/explore/explore.reducer";
import { ToggleIndexMode } from 'app/insights/insights.actions';
import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType, SegmentContexts } from "app/insights/insights.constants";
import { isDefined } from "app/shared/utils/utils";
import { hasExcludedSegments } from 'app/explore/explore.utils';
import { COMPARE_COLORS } from 'app/insights/insights-components/person-level-compare/person-level-compare.constants';

@UntilDestroy()
@Component({
  selector: 'ppc-persona-compare-dropdown',
  templateUrl: './persona-compare-dropdown.component.html',
  styleUrls: ['./persona-compare-dropdown.component.sass'],
})

export class PersonaCompareDropdownComponent implements OnDestroy {
  activePersona$ = this.store.select("explore").pipe(select(selectActivePersona), filter(isDefined));
  activePersona: Persona;
  personas$ = this.store.select("explore").pipe(select(selectPersonas), filter(isDefined));
  personas: Persona[];
  selectedPersonas$ = observableCombineLatest(
    this.store.select("explore").pipe(select(selectSelectedPersonas), filter(isDefined)),
    this.activePersona$,
  ).pipe(map(([selectedPersonas, activePersona]) => orderBy(selectedPersonas, {id: activePersona.id}, "desc")));
  selectedPersonas: Persona[];
  compareColors = COMPARE_COLORS;
  isIndexMode: boolean;

  constructor(
    public store: Store<AppState>,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType,
  ) {
    observableCombineLatest(
      this.personas$,
      this.activePersona$,
      this.selectedPersonas$
    ).pipe(untilDestroyed(this)).subscribe(([personas, activePersona, selectedPersonas]) => {
      this.activePersona = activePersona;
      this.personas = _filter(personas, persona => persona.id !== activePersona.id);
      this.selectedPersonas = selectedPersonas;
      if (activePersona && find(personas, persona => persona.id === activePersona.id) && !find(selectedPersonas, persona => persona.id === activePersona.id)) {
        this.store.dispatch(new TogglePersona(activePersona.id));
      };
    });

    this.store.select("insights", this.insightsContext, "indexMode").pipe(
      untilDestroyed(this),
    ).subscribe(indexMode => this.isIndexMode = indexMode);
  }

  ngOnDestroy() {}

  toggleSelectedPersona(personaId: number) {
    if (this.isIndexMode && hasExcludedSegments(find(this.personas, {id: personaId}))) {this.toggleIndexMode(); }
    this.store.dispatch(new TogglePersona(personaId));
  }

  toggleIndexMode() {
    this.store.dispatch(new ToggleIndexMode(this.insightsContext));
  }

  isActivePersona(persona: Persona): boolean {
    return this.activePersona && persona.id === this.activePersona.id;
  }

  isChecked(persona: Persona): boolean {
    return this.isActivePersona(persona) || _map(this.selectedPersonas, "id").includes(persona.id);
  }

  isDisabled(persona: Persona): boolean {
    return this.isActivePersona(persona) || (this.selectedPersonas && this.selectedPersonas.length > 2 && !this.isChecked(persona));
  }

  isDisabledTooltip(persona: Persona): string {
    return this.isActivePersona(persona) ? 'Cannot deselect the current persona' : 'Up to 3 personas can be compared at once';
  }
}
