import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { EpcComponentsModule } from "app/modules/epc-components.module";
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgLetModule } from 'ng-let';

import { ExploreComponent } from 'app/explore/explore.component';
import { ExploreService } from 'app/explore/explore.service';
import { InsightsModule } from 'app/insights/insights.module';
import { IndexReportModule } from 'app/index-report/index-report.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PpcComponentsModule,
    EpcComponentsModule,
    RouterModule,
    NgLetModule,
    InsightsModule,
    IndexReportModule,
  ],
  declarations: [
    ExploreComponent,
  ],
  exports: [
    ExploreComponent
  ],
  providers: [
    ExploreService
  ]
})
export class ExploreModule { }
