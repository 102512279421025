
import {combineLatest as observableCombineLatest, Observable, Subject} from 'rxjs';

import {filter, takeUntil, map} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';

import {Region} from "../region/region.model";
import {Vendor} from "../vendors/vendor";
import {VendorPermission} from "../vendors/vendor-permission.model";
import {ClientRegionRules} from "./models/client-region-rules.model";
import {PermissionOption} from "./models/permission-option.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import {Store, select} from "@ngrx/store";
import {AppState} from "../../reducers/index";
import {
  getVendors, getVendorPermissions,
  selectPermissionOptions
} from "../../services/vendors/vendors.reducers";
import {
  FetchVendors, FetchVendorPermissionOptions,
  FetchVendorPermissions, UpdateVendorPermissions, FetchVendorPermissionsByClientRegion
} from "../../services/vendors/vendors.actions";
import {CompleteFetch, ErrorFetch} from "../../shared/utils/fetch-state";
import {Actions, ofType} from "@ngrx/effects";
import {RulesRowContext, SegmentPermissionsAdminService} from "./segment-permissions-admin.service";
import {getRegions} from "../region/region.reducers";
import {FetchRegions} from "../region/regions.actions";
import {selectHierarchy, selectActiveClients} from "../../hierarchy/hierarchy.reducers";
import {HierarchyClient} from "app/hierarchy/hierarchy.interface";
import {FetchHierarchy} from "../../hierarchy/hierarchy.actions";
import {PpcTabComponent} from "../../shared/components/ppc-tab/ppc-tab.component";
import {PpcTabsComponent} from "../../shared/components/ppc-tabs/ppc-tabs.component";
import {sortBy} from "app/shared/utils/utils";

@Component({
  selector: 'app-segment-permissions',
  templateUrl: './segment-permissions.component.html',
  styleUrls: ['./segment-permissions.component.sass']
})
export class SegmentPermissionsComponent implements OnInit, OnDestroy {
  @ViewChild("tabs", { static: true }) tabs: PpcTabsComponent;
  @ViewChild("editTab", { static: true }) editTab: PpcTabComponent;

  ALL = ClientRegionRules.ALL;

  PERMISSION_DROPDOWN_OPTIONS = [
    {value: VendorPermission.ALLOW, display: 'Allow'},
    {value: VendorPermission.DENY, display: 'Deny'},
    {value: VendorPermission.INHERIT, display: 'Use Default', notOnDefault: true}
  ];

  clientOptions: HierarchyClient[] = [];
  regionOptions: Region[] = [];
  vendorOptions: Vendor[] = [];

  permissionOptions: PermissionOption[];
  permissions: PermissionOption[] = [];
  allPermissions: VendorPermission[];

  usageCtx: RulesRowContext = {source: null, rows: [], defaultRow: null, options: []};
  modelingCtx: RulesRowContext = {source: null, rows: [], defaultRow: null, options: []};

  selectedSource: Vendor;
  unsavedChanges: boolean = false;

  ngUnsubscribe = new Subject();

  constructor(private store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar
  ) {
    observableCombineLatest(
      this.store.select('vendors').pipe(map(getVendors)),
      this.store.select('vendors').pipe(map(selectPermissionOptions))
    ).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(
        ([vendors, permissionOptions]) => {
          this.vendorOptions = sortBy(vendors, 'name');
          this.permissionOptions = permissionOptions;
          this.permissions = SegmentPermissionsAdminService.makePermissionOptions(permissionOptions);
          this.usageCtx.options = this.usageOptions;
          this.modelingCtx.options = this.modelingOptions;
        }
      );

    this.store.select('vendors').pipe(map(getVendorPermissions),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(
        permissions => {
          this.selectedSource && this.handlePermissionsLoad(this.selectedSource, permissions);
          this.allPermissions = permissions;
        }
      );

    observableCombineLatest(
      this.store.select('hierarchy').pipe(map(selectHierarchy)).pipe(select(selectActiveClients)),
      this.store.select('regions').pipe(map(getRegions))
    ).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        ([hierarchy, regions]) => {
          hierarchy && (this.clientOptions = hierarchy.clients);
          this.regionOptions = regions;
        }
      );

    this.actions$
      .pipe(
        ofType(CompleteFetch.type),
        filter((completedFetchAction: CompleteFetch) => completedFetchAction.fetchAction.type === UpdateVendorPermissions.type),
        takeUntil(this.ngUnsubscribe), )
      .subscribe(
        _ => {
          this.alertSuccess("Saved");
          this.unsavedChanges = false;
        }
      );

    this.actions$
      .pipe(
        ofType(ErrorFetch.type),
        filter((errorFetchAction: ErrorFetch) => errorFetchAction.fetchAction.type === UpdateVendorPermissions.type),
        takeUntil(this.ngUnsubscribe), )
      .subscribe(
        _ => this.alertWarn("Something went wrong, rules could not be saved.  Please try again")
      );
  }

  ngOnInit() {
    this.store.dispatch( new FetchHierarchy() );
    this.store.dispatch( new FetchRegions() );
    this.store.dispatch( new FetchVendors() );
    this.store.dispatch( new FetchVendorPermissionOptions() );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  trackByVendor(index, vendor: Vendor) {
    return vendor.id
  }

  private handlePermissionsLoad(source, permissions: VendorPermission[]) {
    this.permissions = SegmentPermissionsAdminService.makePermissionOptions(this.permissionOptions, permissions, source.id);

    const usageRows = SegmentPermissionsAdminService.makeSourceRuleRows(source, this.usageOptions, permissions);
    const modelingRows = SegmentPermissionsAdminService.makeSourceRuleRows(source, this.modelingOptions, permissions);

    this.usageCtx = SegmentPermissionsAdminService.makecontext(source, usageRows, this.usageOptions);
    this.modelingCtx = SegmentPermissionsAdminService.makecontext(source, modelingRows, this.modelingOptions);

    this.usageCtx.rows.forEach(row => this.setRowClientRegionNames(row));

    this.usageCtx.rows.sort(this.rowSort)
  }

  private rowSort(a: ClientRegionRules, b: ClientRegionRules): number {
    /* sort order:

     selected region / all clients
     all region / selected client
     selected region / selected client
     - clashes are sorted alphabetically -
     */
    const ALL = ClientRegionRules.ALL;

    if (a.client === ALL) {
      return b.client === ALL ? a.regionDisplay.localeCompare(b.regionDisplay) : -1
    }

    if (a.region === ALL) {
      if (b.client === ALL) {return 1}
      return b.region === ALL ? a.clientDisplay.localeCompare(b.clientDisplay) : -1
    }

    if (b.region === ALL || b.client === ALL) {return 1}
    return a.clientDisplay.localeCompare(b.clientDisplay)
  }

  selectSource(source: Vendor) {
    this.unsavedChanges = false;
    this.store.dispatch(new FetchVendorPermissions(source.id));
  }

  selectSourceFromView(source: Vendor) {
    this.selectedSource = source;
    this.selectSource(source);
    this.tabs.setActive(this.editTab.name);
  }

  setRowClientRegionNames(row: ClientRegionRules) {
    const client = this.clientOptions.find(c => c.id === row.client);
    const region = this.regionOptions.find(r => r.id === row.region);
    row.clientDisplay =  client ? client.name : "";
    row.regionDisplay =  region ? region.name : "";
  }

  get usageOptions(): PermissionOption[] {
    return this.permissionOptions.filter(o => o.option_category === PermissionOption.USAGE);
  }

  get modelingOptions(): PermissionOption[] {
    return this.permissionOptions.filter(o => o.option_category === PermissionOption.MODELING);
  }

  get canAddRow(): boolean {
    return this.usageCtx.defaultRow && this.usageCtx.defaultRow.filled && this.modelingCtx.defaultRow && this.modelingCtx.defaultRow.filled;
  }

  addRuleRow(context: RulesRowContext, source: Vendor) {
    const row = new ClientRegionRules(SegmentPermissionsAdminService.makePermissionOptions(context.options, [], source.id, VendorPermission.INHERIT), null, null);
    context.rows.push(row);
  }

  deleteRuleRow(ruleIndex: number) {
    const rule = this.usageCtx.rows.splice(ruleIndex, 1)[0];
    this.alertUndo("Rule removed. Save to apply.", () => this.usageCtx.rows = this.usageCtx.rows.slice(0, ruleIndex).concat(rule, ...this.usageCtx.rows.slice(ruleIndex)));
    this.unsavedChanges = true;
  }

  get canSave(): boolean {
    const modelingDefaultFilled = this.modelingCtx.defaultRow && this.modelingCtx.defaultRow.filled;
    const usageDefaultFilled = this.usageCtx.defaultRow && this.usageCtx.defaultRow.filled;
    const validateRow = (row) => row.filled && this.ruleError(row.client, row.region) === "";
    const usageValid = this.usageCtx.rows.reduce( (result, row) => result && validateRow(row), true);

    return modelingDefaultFilled && usageDefaultFilled && usageValid;
  }

  savePermissions() {
    if (this.canSave) {
      const permissions = [];
      permissions.push(...SegmentPermissionsAdminService.rowToPermissions(this.usageCtx.defaultRow, this.usageCtx.source.id));
      permissions.push(...SegmentPermissionsAdminService.rowToPermissions(this.modelingCtx.defaultRow, this.usageCtx.source.id));
      this.usageCtx.rows.forEach( row => permissions.push(...SegmentPermissionsAdminService.rowToPermissions(row, this.usageCtx.source.id)));

      this.store.dispatch(new UpdateVendorPermissions(this.usageCtx.source.id, permissions))
    } else {
      this.alertWarn("Please correct all errors in rules form and try again")
    }
  }

  determineInheritedValue(client: string, region: string, option: PermissionOption): string {
    const context = this.usageCtx;

    let inheritedValue = context.defaultRow.getOption(option.permission).value;

    const singleClient = client !== this.ALL;
    const singleRegion = region !== this.ALL;

    let parentRule;
    if (singleClient) {
      // find rules applicable to all clients and this region
      parentRule = context.rows.find(row => row.client == this.ALL && row.region == region);
    }
    if (singleRegion) {
      // find rules applicable to all regions and this client
      parentRule = context.rows.find(row => row.region == this.ALL && row.client == client) || parentRule;
    }

    inheritedValue = parentRule ? parentRule.getOption(option.permission).value : inheritedValue;

    if (parentRule && inheritedValue === VendorPermission.INHERIT) {
      inheritedValue = this.determineInheritedValue(parentRule.client, parentRule.region, option);
    }

    return inheritedValue;
  }

  // snackbar alerts

  alertSuccess(message: string): void {
    this.snackbar.open(message, null, {
      duration: 2500,
      panelClass: ['check']
    })
  }

  alertWarn(message: string): void {
    this.snackbar.open(message, null, {
      duration: 2500,
      panelClass: ['danger']
    })
  }

  alertUndo(message: string, onAction: () => void): void {
    this.snackbar.open(message, 'undo', {
      panelClass: ['danger'],
      duration: 4000
    }).onAction().subscribe( _ => onAction() );
  }

  // validation messaging

  readonly validationMessages = {
    clientRegionInUse: "This Client Region combination is already in use.  ",
    vagueInheritance: "A rule applying to all clients and a rule applying to all regions both apply to this rule.  ",
    allClientsVague: "Another rule is defined for all regions.  Inheritance discrepancies will default to THAT rule.  ",
    allRegionsVague: "Another rule is defined for all clients.  Inheritance discrepancies will default to THIS rule.  "
  };

  public ruleMessage(client: string, region: string): {warning: string, error: string} {
    return  {
      warning: this.ruleWarning(client, region),
      error: this.ruleError(client, region)
    };
  }

  ruleError(client: string, region: string): string {
    let msg = "";
    if (this.clientRegionComboUsed(client, region)) {
      msg += this.validationMessages.clientRegionInUse;
    }
    return msg;
  }

  ruleWarning(client: string, region: string): string {
    let msg = "";

    if (this.allClientsAndAllRegionsVague(client, region)) {
      msg += client == this.ALL ? this.validationMessages.allClientsVague : this.validationMessages.allRegionsVague;
    }

    if (this.inheritanceIsVague(client, region)) {
      msg += this.validationMessages.vagueInheritance;
    }

    return msg;
  }

  private allClientsAndAllRegionsVague(client: string, region: string): boolean {
    const context = this.usageCtx;
    if (client == this.ALL) {
      return context.rows.find(row => row.region == this.ALL) != null;
    }

    if (region == this.ALL) {
      return context.rows.find(row => row.client == this.ALL) != null;
    }

    return false;
  }

  private inheritanceIsVague(client: string, region: string): boolean {
    const context = this.usageCtx;

    const allClientsThisRegionRule = context.rows.find(row => row.client === this.ALL && row.region === region) != null;
    const allRegionsThisClientRule = context.rows.find(row => row.region === this.ALL && row.client === client) != null;

    return allClientsThisRegionRule && allRegionsThisClientRule;
  }

  private clientRegionComboUsed(client: string, region: string): boolean {
    const similarCombo = this.usageCtx.rows.filter(row => row.client == client && row.region == region).length > 1;

    return ClientRegionRules.isDefault(client, region) || similarCombo;
  }

}
