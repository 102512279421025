<table>
  <tr>
    <th>Media Types</th>
    <th>Curve Type</th>
    <th>Curve Parameters</th>
  </tr>

  <ng-container *ngFor="let curve of uploadedCurves; let i = index">
    <tr [className]="tableClass(curve)" *ngIf="!curve.is_generic">
      <td>
       <ppc-input [(ngModel)]="curve.media_type" placeholder="Media Type"></ppc-input>
      </td>
      <td>
        <mat-form-field  class="shift-select-down">
            <mat-select placeholder="Curve Type" [(ngModel)]="curve.curve_type_name" name="curveTypeDD">
                <mat-option [value]="curveType" *ngFor="let curveType of curveTypeNames">{{curveType}}</mat-option>
              </mat-select>
        </mat-form-field>
      </td>
      <td>
        <div class="curve_params">
          <ppc-input placeholder="A: Base" [validators]="numberValidations" [(ngModel)]="curve.params.Base"></ppc-input>
          <ppc-input placeholder="B: Slope" [validators]="numberValidations" [(ngModel)]="curve.params.Slope"></ppc-input>
          <ppc-input placeholder="C: Acceleration" [validators]="numberValidations" [(ngModel)]="curve.params.Acceleration"></ppc-input>
          <ppc-input placeholder="D: Asymptote" [validators]="numberValidations" [(ngModel)]="curve.params.Asymptote"></ppc-input>
          <ppc-input placeholder="E: Asymmetry" [validators]="numberValidations" [(ngModel)]="curve.params.Asymmetry"></ppc-input>
          <ng-container *ngIf="curve.isTRP">
            <mat-form-field  class="dropdown">
                <mat-select placeholder="Cost Method" [(ngModel)]="curve.params.CppOrCpm">
                    <mat-option [value]="option.value" *ngFor="let option of COST_METHODS">{{option.display}}</mat-option>
                  </mat-select>
            </mat-form-field>
            <ppc-input [validators]="cppValidators" [(ngModel)]="curve.params.CPP">
              <div prefix>$</div>
            </ppc-input>
          </ng-container>

          <app-confirmation
            *ngIf="curve.id"
            isIcon="true"
            [disabled]="deleteDisabled(curve, i)"
            buttonClass="fa fa-times pointer delete-curve"
            [kontinue]="deleteCurve.bind(this,i)"
          ></app-confirmation>
          <i *ngIf="!curve.id" class="fa fa-times pointer delete-curve" (click)="deleteCurve(i)"></i>
        </div>
      </td>

    </tr>
    <div>
      <mat-error *ngFor="let error of curveErrors[curve.id]">{{error}}</mat-error>
    </div>
  </ng-container>
</table>

<div class="pointer" (click)="addEmptyCurveParameters()" *ngIf="showAddNew">
  <i class="fa fa-plus"></i> Add New Set
</div>
