import * as actions from 'app/feature-access/feature-access.actions'
import {LoadClientFeatureAccess, LoadFeatureModules} from 'app/feature-access/feature-access.actions'
import {find, groupBy, keyBy, mapValues, chain, filter, get} from 'lodash';
import {AppState} from "app/reducers";
import {select, Store} from "@ngrx/store";
import {activeContext} from "app/hierarchy/hierarchy.reducers";
import {combineLatest, Observable} from "rxjs";
import {filter as rxFilter, switchMap, map as rxMap} from "rxjs/operators";
import {HierarchyClient} from "app/hierarchy/hierarchy.interface";
import {memoAndShare, getStoreState} from "app/shared/utils/utils";
import { isDefined } from 'app/shared/utils/utils';
import { environment } from 'environments/environment';

export interface FeatureModule {
  id: string;
  category?: string;
  name: string;
  display_name?: string;
  rank?: number;
}

export interface ClientFeatureAccess {
  feature_module_id: string;
  client_id: string;
}

export interface State {
  features: {[id: string]: FeatureModule};
  accessByClient: {[clientId: string]: string[]}; // clientId -> featureIds[]
}

const defaultState: State = {
  features: {},
  accessByClient: {}
};

export function reducer(state: State = defaultState, action: actions.All) {
  switch (action.type) {
    case LoadFeatureModules.type:
      return {...state, features: keyBy(action.features, 'id')};
    case LoadClientFeatureAccess.type:
      return {
        ...state,
        accessByClient: mapValues(
          groupBy(action.accesses, 'client_id'),
          accesses => accesses.map(a => a.feature_module_id)
        )
      };
    default:
      return state;
  }
}

export const NAV_ORDER = ['insights', 'audiences', 'creative', 'plans', 'outcomes', 'toolbox', 'analytics'];

// if the user role permission should apply in filtering accessible features, add to list here
// i.e. if user (user role) does not have read access to 'explore-outcome-audiences', do not show
//      on the UI (i.e. left-hand-nav, home page) for the selected client
const userRolePermissionMap: Array<{featureName: string, permissionName: string}> = [
  {
    featureName: 'explore-outcome-audiences',
    permissionName: 'outcome_audiences',
  }
]

// This checks against the current hierarchy context
export const canAccessFeature = memoAndShare((store: Store<AppState>, featureName: string): Observable<boolean> =>
  accessibleFeatures(store).pipe(select(features => !!find(features, {name: featureName}))))

// This gets all accessible features for the current hierarchy context
export const accessibleFeatures = memoAndShare((store: Store<AppState>): Observable<FeatureModule[]> =>
  activeContext(store).pipe(
    rxFilter(({client, region}) => !!(client && region)),
    switchMap(({client, region}) => featuresForClientRegion(store, client.slug, region.slug)),
    rxMap(features =>
      filter(features, feature => {
        const permissionCheck = find(userRolePermissionMap, p => p.featureName === feature.name);
        if (permissionCheck) {
          const hasPermission = get(getStoreState(store), ['permissions', permissionCheck.permissionName, 'read'], false);
          if (!hasPermission) { return false; }
        }
        return true;
      })
    )
  )
)

// We need to consider region because certain regions are "Emerging Markets" and thus don't support
// "audiences" features
export function canClientRegionAccessFeature(store: Store<AppState>,
  clientSlug: string,
  regionSlug: string,
  featureName: string): Observable<boolean> {
  return featuresForClientRegion(store, clientSlug, regionSlug).pipe(
    select(features => !!find(features, {name: featureName})))
}

// Use this if you don't care about region rules
export function canClientAccessFeature(store: Store<AppState>,
  clientSlug: string,
  featureName: string): Observable<boolean> {
  return featuresForClient(store, clientSlug).pipe(
    select(features => !!find(features, {name: featureName})))
}

// We need to consider region because certain regions are "Emerging Markets" and thus don't support
// "audiences" features
export function featuresForClientRegion(store: Store<AppState>, clientSlug: string, regionSlug: string): Observable<FeatureModule[]> {
  return combineLatest(
    featuresForClient(store, clientSlug),
    clientBySlug(store, clientSlug).pipe(rxFilter(isDefined),
      select(({ regions }) => find(regions, {slug: regionSlug})),
      rxFilter(isDefined)),
  ).pipe(
    select(([ features, region ]) =>
      features.filter(feature => !((environment.isTier3 && feature.category === 'audiences')
                                   || (region.feature_exclusions || []).includes(feature.id + ''))))
  )
}

// Use this if you don't care about region rules
function featuresForClient(store: Store<AppState>, clientSlug: string): Observable<FeatureModule[]> {
  return combineLatest(
    store.select('featureAccess'),
    clientBySlug(store, clientSlug),
  ).pipe(
    select(([ featureState, client ]) =>
      chain(featureState.accessByClient[client && client.id] || [])
        .map(featId => featureState.features[featId])
        .compact()
        .sortBy([feature => NAV_ORDER.indexOf(feature.category), 'rank'])
        .value())
  )
}

const clientBySlug = memoAndShare((store: Store<AppState>, clientSlug: string): Observable<HierarchyClient> =>
  store.select('hierarchy', 'hierarchy', 'clients').pipe(select(cs => find(cs, {slug: clientSlug}))))
