import {throwError as observableThrowError, ReplaySubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { fullContext } from './../hierarchy/hierarchy.reducers';
import { HierarchyClient } from './../hierarchy/hierarchy.interface';
import {AppState} from 'app/reducers/index';
import {Injectable} from "@angular/core";
import {Store} from '@ngrx/store';
import { PpcHttpService } from "../services/ppc_http.service";
import {Plan} from "../plans/plan.model";
import { AuthPermission } from '../shared/interfaces/auth-permission';
import { scenarioPlannerUrl } from '../shared/constants/plans.urls';

@Injectable()
export class ScenarioDialogService {
  permissions: AuthPermission;
  activeClient: HierarchyClient = null;

  constructor(
    private http: PpcHttpService,
    private store: Store<AppState>
  ) {

    this.store.select("permissions").subscribe(permissions => {
      this.permissions = permissions["plans"];
    });

    fullContext(this.store).subscribe(
      ({client}) => this.activeClient = client
    );

  }

  plansGoalTypeUrl(): string {
    return `${scenarioPlannerUrl()}/goal_types`;
  }

  updateScenarioURL(planId): string {
    return `${scenarioPlannerUrl()}/${planId}`;
  }

  submitScenarioURL(planId): string {
    return `${scenarioPlannerUrl()}/${planId}/submit`;
  }

  public saveNewScenario(plan: Plan): Observable<Plan> {
    let planOverride = {};
    // TODO: Delete this once reach and penetration are separate curve types #PAUD-1010
    if (this.activeClient.curve_label_override) {
      planOverride = {
        curveTypeName: plan.curveTypeName.replace('Penetration', 'Reach'),
        goalType: plan.goalType.replace('Penetration', 'Reach')
      };
    }
    if (this.permissions.create) {
      return this.http.post(scenarioPlannerUrl(), Object.assign(plan, planOverride).toJson()).pipe(
        map(res => new Plan(res.data)));
    } else {
      return this.throwJsonError("Not allowed to create scenarios");
    }
  }

  public updateScenario(plan: Plan) {
    let planOverride = {};
    // TODO: Delete this once reach and penetration are separate curve types #PAUD-1010
    if (this.activeClient.curve_label_override) {
      planOverride = {
        curveTypeName: plan.curveTypeName.replace('Penetration', 'Reach'),
        goalType: plan.goalType.replace('Penetration', 'Reach')
      };
    }
    if (this.permissions.update) {
      return this.http.put(this.updateScenarioURL(plan._id), Object.assign(plan, planOverride).toJson()).pipe(
        map(res => new Plan(res.data)));
    } else {
      return this.throwJsonError("Not allowed to update scenarios");
    }
  }

  public submitScenario(planId: string): Observable<Plan> {
    return this.http.post(this.submitScenarioURL(planId), {}).pipe(
      map(res => new Plan(res.data)));
  }

  public getGoalTypes(): Observable<{ [goalTypeName: string]: GoalTypeDetails}> {
    let activeClient = null;
    fullContext(this.store).subscribe(
      ({client}) => activeClient = client
    );

    return this.http.get(this.plansGoalTypeUrl()).pipe(
      map(res => {
        const data = res.data;
        // TODO: Delete this once reach and penetration are separate curve types PR#1490
        if (activeClient.curve_label_override) {
          Object.keys(data).forEach((key) => {
            if (key.startsWith('Reach')) {
              data[key.replace('Reach', 'Penetration')] = data[key];
              delete data[key];
            }
          });
        }
        return data;
      }))
  }

  private throwObservableError(errMsg) {
    return observableThrowError(errMsg);
  }

  private throwJsonError(errMsg: string) {
    const error = {error_messages: [errMsg]};
    const jsonError = {_body: JSON.stringify(error)};
    return this.throwObservableError(jsonError);
  }

}

export interface GoalTypeDetails {
  curve_types: number[],
  unit: string
}
