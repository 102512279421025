<div class="demographics-header">
  <div class="instructions">{{ tab.description }}</div>
  <span class="new-widget-plus"
      *ngIf="marketLevelDemographics?.length > 0 && (permissions$ | async)?.create"
      (click)="addWidget()">
    <i class="fa fa-plus"></i> New Widget
  </span>
</div>

<div class="create-new-widget-container" *ngIf="marketLevelDemographics?.length == 0 && (permissions$ | async)?.create">
  <div class="create-new-widget header-btn" (click)="addWidget()">
    <img src="assets/icons/add-white.svg"/>
    New Widget
  </div>
  Click above to create a new custom widget.
</div>

<div class="packery-container" [packery]="bagName" [packeryModel]="marketLevelDemographics" [forceLayout$]="forceLayout$" (orderChanged)="saveConfig($event)" *ngIf="isActiveTab$ | async">
    <ppc-market-level-demographic-widget  class="packery-item"
                                          *ngFor="let marketLevelDemographic of visibleDemographics; trackBy: trackById"
                                          [marketLevelDemographic]="marketLevelDemographic"
                                          [selectedSubMarkets]="resourceSubMarkets"
                                          [insightsContext]="insightsContext"
                                          (hideDemographic)="hideDemographic(marketLevelDemographic)"
                                          [attr.data-id]="marketLevelDemographic.id">
    </ppc-market-level-demographic-widget>
</div>
