<div class="logo">
  <img src="/assets/images/ppc_logo/ppc_logo_white.svg" alt="People Cloud Login Logo">
</div>

<form (submit)="submit($event)" class="form-container">
  <div class="login-error-message" *ngFor="let error of getErrorMessages()">{{error}}</div>
  <div>
    <h3 id="confirm-header">Choose a new password</h3>
    <mat-form-field>
      <input matInput [(ngModel)]="form.user.password"
             type="password"
             [ngModelOptions]="{standalone: true}"
      >
      <mat-placeholder>Enter Password - minimum of 6 characters</mat-placeholder>
      <div suffix *ngIf="form.user.password?.length > 5">
        <i class="fa fa-check"></i>
      </div>
    </mat-form-field>
  </div>
  <mat-form-field>
    <input matInput [(ngModel)]="form.user.password_confirmation"
           type="password"
           errorMessage="Passwords do not match"
           [ngModelOptions]="{standalone: true}"
    >
    <mat-placeholder>Confirm Password</mat-placeholder>
    <div suffix *ngIf="form.user.password_confirmation?.length > 5
                       && form.user.password == form.user.password_confirmation">
      <i class="fa fa-check"></i>
    </div>
    <div suffix *ngIf="form.user.password?.length > 5
                       && form.user.password_confirmation?.length >= form.user.password.length
                       && form.user.password_confirmation != form.user.password">
      <i>
        <mat-error class="fa fa-close"> Passwords do not match</mat-error>
      </i>
    </div>
  </mat-form-field>
  <button mat-button (click)="submit($event)" [disabled]="!arePasswordsValid()" type="submit">
    Change Password
  </button>
</form>

<p class="support-text">For assistance, please reach out to Product Support at</p>
<p class="support-email">
  <a href="mailto:productsupport@publicisgroupe.com">productsupport@publicisgroupe.com</a>
</p>
