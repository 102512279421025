import { ERROR_MSG_CONVERSANT_COMPANY_ID_INVALID,
  CONVERSANT_COMPANY_ID_MIN,
  CONVERSANT_COMPANY_ID_MAX } from './../../../shared/utils/constants';
import { InputValidator } from 'app/shared/components/ppc-input/ppc-input.component';
import { filter, take } from 'rxjs/operators';
import { CLIENT_LEVELS, ClientLevel, CLIENT_ID_SPACE, CLIENT_VERTICALS } from 'app/admin/client/client.constants';
import { Component, EventEmitter, Input, Output, ViewChild, OnInit, OnChanges } from "@angular/core";
import { Region } from "app/admin/region/region.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ClientFeatureAccessComponent } from "app/feature-access/client-feature-access/client-feature-access.component";
import { HierarchyClient, HierarchyRegion } from "app/hierarchy/hierarchy.interface";
import { AppState } from "app/reducers";
import { Store } from "@ngrx/store";
import { SaveClient } from "app/hierarchy/hierarchy.actions";
import { Actions, ofType } from "@ngrx/effects";
import { PutClientFeatureAccess } from "app/feature-access/feature-access.actions";
import { CompleteFetch } from "app/shared/utils/fetch-state";
import { MatSnackBar } from "@angular/material/snack-bar";
import { alphabetizedRegionNamesList, uniqueProducts } from "app/hierarchy/hierarchy.utils";
import { environment } from 'environments/environment';

@Component({
  selector: 'app-client-row',
  templateUrl: './client-row.component.html',
  styleUrls: ['./client-row.component.sass']
})
export class ClientRowComponent implements OnInit, OnChanges {

  @Output() edit = new EventEmitter<HierarchyClient>();
  @Output() cancelEdit = new EventEmitter();

  @Input() client: HierarchyClient;
  @Input() isEditing: boolean;
  @Input() regions: { [id: string]: Region } = {};
  @Input() marketLevelOnlyRegions: string[];

  @ViewChild(ClientFeatureAccessComponent) featureAccessComponent;

  isSourceListVisible = false;
  clientForm: FormGroup;
  existingSourceAccountsControl: FormControl;
  clientLevels: ClientLevel[] = CLIENT_LEVELS;
  clientIdSpaces: ClientLevel[] = CLIENT_ID_SPACE;
  clientVerticals = CLIENT_VERTICALS.filter((cv) => cv.value !== 'energy');
  regionNamesList: string = 'None';
  isTier3: boolean = environment.isTier3;

  readonly CONVERSANT_COMPANY_ID_MIN = CONVERSANT_COMPANY_ID_MIN;
  readonly CONVERSANT_COMPANY_ID_MAX = CONVERSANT_COMPANY_ID_MAX;

  clientEmailInputValidators: InputValidator[] = [
    {
      errorMessage: `Email must be a valid email address`,
      isValid: (value) => {
        // EMAIL_PATTERN might need to be revisited if much stricter regEx needs to be matched
        const EMAIL_PATTERN = /^\S+@\S+\.\S+$/;
        return value ? EMAIL_PATTERN.test(value) : true;
      }
    }
  ];

  conversantIdInputValidators: InputValidator[] = [
    {
      errorMessage: ERROR_MSG_CONVERSANT_COMPANY_ID_INVALID,
      isValid: value => value ? this.isConversantIdValid(value) : true
    }
  ];

  canDeactivateClient$ = this.store.select('permissions', 'clients', 'destroy')

  constructor(private store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar) {
    this.clientForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      curve_label_override: new FormControl(''),
      emerging_market_verticals: new FormControl(''),
      contact_email: new FormControl('', [Validators.email]),
      conversant_id: new FormControl(null, [Validators.min(CONVERSANT_COMPANY_ID_MIN), Validators.max(CONVERSANT_COMPANY_ID_MAX)]),
    });

    this.existingSourceAccountsControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
  }

  ngOnInit(): void {
    this.client = {...this.client,
      id_space: this.client.id_space || 'metagraph',
      visibility_level: this.client.visibility_level || 'client',
      curve_label_override: this.client.curve_label_override || false
    };
  }

  ngOnChanges(): void {
    this.regionNamesList = alphabetizedRegionNamesList(uniqueProducts(this.client), this.client.regions);
  }

  selectClientLevel(visibility_level: string): void {
    this.client = {...this.client, visibility_level};
  }

  get clientRegions(): HierarchyRegion[] {
    return this.client.regions
  }

  toActivate(e) {
    this.client = {...this.client, active: !this.client.active};
    this.saveClient(e);
  }

  beginEdit(e) {
    e.stopPropagation();
    this.edit.emit(this.client)
  }

  saveClient(e) {
    e.stopPropagation();
    const saveAction = new SaveClient(this.client);
    this.store.dispatch(saveAction);
    this.actions$.pipe(
      ofType(CompleteFetch.type),
      filter((a: CompleteFetch) => a.fetchAction === saveAction),
      take(1)
    )
      .subscribe(({ result }: CompleteFetch) => {
        if (this.featureAccessComponent) {
          this.store.dispatch(new PutClientFeatureAccess(result.id,
            this.featureAccessComponent.accessibleFeatureIds));
        }
        this.cancelEdit.emit();
      });
  }

  get isEmergingMarketAndNotMarketLevelOnly(): boolean {
    return this.client.regions.some((r) => this.isTier3 && !this.marketLevelOnlyRegions.includes(r.slug))
  }


  private isConversantIdValid(value: number) {
    return value >= CONVERSANT_COMPANY_ID_MIN && value <= CONVERSANT_COMPANY_ID_MAX;
  }
}
