<div class="grow-v3-donut-chart" [class.hide]="!hasData" [class.jumbo]="jumbo$ | async">
  <div class="donut-chart-container">
    <ppc-donut-chart [donutChartData]="donutChartData" (sliceClicked)="toggleFilter($event)" [showCounts]="isIdCount"></ppc-donut-chart>
  </div>
  <div class="legends">
    <div class="legend" *ngFor="let bucket of donutChartData" [class.selected]="bucket.selected" (click)="toggleFilter(bucket)">
      <div class="legend-color">
        <div class="background" [style.background-color]="bucket.color"></div>
      </div>
      <div class="legend-name" *ppcCheckOverflow="let hasXOverflow=hasXOverflow;" [ppcTooltip]="bucket.label"
        [tooltipDisabled]="!hasXOverflow">
        {{ bucket.label }}
      </div>
      <div class="legend-percent">
        <ppc-zero-count-warning-icon
          [shift]="'right'"
          *ngIf="hasZeroCount(bucket)"
          ppcTooltip="Click to learn why this attribute has a zero count."
          [tooltipOffset]="{x: 28, y: 13}"
          ></ppc-zero-count-warning-icon>
        <div *ngIf="!isIdCount">
          {{ getPercentage(bucket) | number:'1.0-0'}}%
        </div>
        <div *ngIf="isIdCount">
          {{ bucket.countLabel }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="no-data" [class.hide]="hasData">
    {{ isTier3 ? 'Data is unavailable in this market' : 'Data Unavailable' }}
</div>
