import { Action } from "@ngrx/store";
import { Persona } from "app/explore/explore.reducer";

export class FetchPersonas implements Action {
  static readonly type = "EXPLORE:FETCH_PERSONAS";
  readonly type = FetchPersonas.type;
}

export class LoadPersonas implements Action {
  static readonly type = "EXPLORE:LOAD_PERSONAS";
  readonly type = LoadPersonas.type;

  constructor(public personas: Persona[]) {}
}

export class FetchPersona implements Action {
  static readonly type = "EXPLORE:FETCH_PERSONA";
  readonly type = FetchPersona.type;
}

export class LoadPersona implements Action {
  static readonly type = "EXPLORE:LOAD_PERSONA";
  readonly type = LoadPersona.type;

  constructor(public persona: Persona) {}
}

export class SavePersona implements Action {
  static readonly type = "EXPLORE:SAVE_PERSONA";
  readonly type = SavePersona.type;

  constructor(public persona: Persona) {}
}

export class CopyPersona implements Action {
  static readonly type = "EXPLORE:COPY_PERSONA";
  readonly type = CopyPersona.type;

  constructor(public id: number) {}
}

export class FetchDefaultPersona implements Action {
  static readonly type = "EXPLORE:FETCH_DEFAULT_PERSONA";
  readonly type = FetchDefaultPersona.type;
}

export class LoadDefaultPersona implements Action {
  static readonly type = "EXPLORE:LOAD_DEFAULT_PERSONA";
  readonly type = LoadDefaultPersona.type;

  constructor(public defaultPersonaId: number) {}
}

export class SaveDefaultPersona implements Action {
  static readonly type = "EXPLORE:SAVE_DEFAULT_PERSONA";
  readonly type = SaveDefaultPersona.type;

  constructor(public defaultPersonaId: number) {}
}

export class SetSelectedPersona implements Action {
  static readonly type = "EXPLORE:SET_SELECTED_PERSONA";
  readonly type = SetSelectedPersona.type;

  constructor(public persona: Persona) {}
}

export class EditPersona implements Action {
  static readonly type = "EXPLORE:EDIT_PERSONA";
  readonly type = EditPersona.type;

  constructor(public persona: Persona) {}
}

export class DestroyPersona implements Action {
  static readonly type = "EXPLORE:DESTROY_PERSONA";
  readonly type = DestroyPersona.type;

  constructor(public persona: Persona) {}
}

export class RemovePersona implements Action {
  static readonly type = "EXPLORE:REMOVE_PERSONA";
  readonly type = RemovePersona.type;

  constructor(public persona: Persona) {}
}

export class TogglePersona implements Action {
  static readonly type = "EXPLORE:TOGGLE_PERSONA";
  readonly type = TogglePersona.type;

  constructor(public id: number) { }
}

export class ClearSelectedPersonas implements Action {
  static readonly type = "EXPLORE:CLEAR_SELECTED_PERSONAS";
  readonly type = ClearSelectedPersonas.type;
}

export class SetActivePersonaDeletedSegmentIds implements Action {
  static readonly type = "EXPLORE:SET_ACTIVE_PERSONA_DELETED_SEGMENT_IDS";
  readonly type = SetActivePersonaDeletedSegmentIds.type;

  constructor(public persona: Persona, public deletedSegmentIds: string[]) {}
}

export class SetActivePersonaDeletedSegmentsChecked implements Action {
  static readonly type = "EXPLORE:SET_ACTIVE_PERSONA_DELETED_SEGMENTS_CHECKED";
  readonly type = SetActivePersonaDeletedSegmentsChecked.type;

  constructor(public persona: Persona, public deletedSegmentsChecked: boolean) {}
}

export type ReducerActions = FetchPersonas | LoadPersonas | FetchPersona | LoadPersona |
SavePersona | SetSelectedPersona | EditPersona | DestroyPersona | RemovePersona |
FetchDefaultPersona | LoadDefaultPersona | SaveDefaultPersona | TogglePersona | ClearSelectedPersonas |
SetActivePersonaDeletedSegmentIds | SetActivePersonaDeletedSegmentsChecked
