import { apiUrl } from '../../services/ppc_http.service';
import { INSIGHTS_API_PATH, V1 } from '../utils/constants';
import { InsightsContextType, ResourceType } from 'app/insights/insights.constants';

export function tabsUrl(tabId?: number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/tabs${tabId ? "/" + tabId : ""}`);
}

export function tabConfigsUrl(resourceId: number, resourceType: ResourceType): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/tab_configs?resource_id=${resourceId}&resource_type=${resourceType}`);
}

export function demographicsIndexUrl(insightsContext: InsightsContextType, resourceId?: number, resourceType?: ResourceType): string {
  return apiUrl(
    `${INSIGHTS_API_PATH}/${V1}/demographics?insights_context=${insightsContext}&resource_id=${resourceId}&resource_type=${resourceType}`
  );
}

export function demographicsUrl(resourceId: number, resourceType: ResourceType, id?: number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/demographics/${id || ''}?resource_id=${resourceId}&resource_type=${resourceType}`);
}

export function userPreferencesUrl(config_type: string, resourceId?: number, resourceType?: string): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/user_preferences/${config_type}?resource_id=${resourceId}&resource_type=${resourceType}`);
}


export function pendingSnapshotsUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/pending_snapshots`);
}

export function tabAdminUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/tabs/admin`);
}

export function insightEntitiesUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/insight_entities`);
}

export function insightEntitiesCountUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/insight_entities_count`);
}

export function insightEntitiesCountByUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/insight_entities_count_by`);
}

export function mekkoTemplateUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/mekkos/template`);
}

export function mekkoSurveyTemplateUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/mekkos/survey_template`);
}

export function regionSurveyTemplateUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/surveys/region_survey_template`);
}

export function journeySurveyTemplateUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/surveys/journey_survey_template`);
}

export function discussionTemplateUrl(nodeId: number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/discussion_cluster_nodes/cluster_template?node_id=${nodeId || ''}`);
}

export function journeyTemplateUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/journeys/template`);
}

export function personaTemplateUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/personas/template`);
}

export function comparisonsUrl(id?: number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/comparisons/${id || ''}`)
}

export function copyComparisonsUrl(id: number): string {
  return comparisonsUrl(id) + '/copy';
}

export function updateComparisonsUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/comparisons/update_comparisons`);
}

export function personasUrl(id?: number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/personas/${id || ''}`);
}

export function personasExportUrl(tabIds, verticalKey, personaId): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/personas/export?tab_ids=${tabIds}&vertical_key=${verticalKey}&persona_id=${personaId}`);
}

export function topLevelTabsUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/tabs/top_level_tabs`);
}

export function userPreferencesCustomTabUrl(resourceId: number, resourceType: string): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/user_preferences/custom_tabs?resource_id=${resourceId}&resource_type=${resourceType}`);
}

export function marketLevelDemographicUrl(routeParam?: number | string): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/market_level_demographics/${routeParam || ''}`);
}

export function marketLevelDemographicByResourceUrl(resourceId: number, resourceType: string): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/market_level_demographics?resource_id=${resourceId}&resource_type=${resourceType}`);
}


export function shareThisUrl(period: 1|4, verticalName: string): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/browsing?period=${period}&vertical=${verticalName}`);
}

export function topCountsDataUrl(period: 1|4, demographic_name, verticalKey: string): string {
  return apiUrl(
    `${INSIGHTS_API_PATH}/${V1}/browsing/top_counts_by_vertical?period=${period}&demographic_name=${demographic_name}&vertical=${encodeURIComponent(
      verticalKey
    )}`
  );
}

export function topCountsAllVerticalDataUrl(period: 1|4): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/browsing/top_counts_for_all_verticals?period=${period}`);
}

export function surveysUrl(resourceId: number, resourceType: string): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/surveys?resource_id=${resourceId}&resource_type=${resourceType}`);
}

export function barbsUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/panelist_constants`);
}


export function weboramaNodesUrl(weboramaId: string): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/sub_markets/weborama_nodes?weborama_id=${weboramaId}`);
}

export function weboramaGroupsUrl(weboramaId: number) {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/weborama_discussions/${weboramaId}`);
}

export function regionDemographicsUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/region_demographics`);
}

export function clusterNodesUrl(clusterNodeId?: number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/discussion_cluster_nodes${clusterNodeId ? "/" + clusterNodeId : ""}`);
}

export function journeyUrl(id?: number | string): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/journeys${id ? "/" + id : ""}`);
}

export function outcomeTimeframeUrl(opts: {mekkoId?: number, id?: number}): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/outcome_timeframes/${ opts.id ? opts.id : ''}?mekko_id=${ opts.mekkoId }`);
}

export function subMarketsUpdateOrderUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/sub_markets/update_order`);
}

export function mekkosUpdateOrderUrl(): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/mekkos/update_order`);
}

export function mekkosUrl(id?: number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/mekkos/${id || ''}`);
}

export function mekkosCopyUrl(id: number): string {
  return `${mekkosUrl(id)}/copy`;
}

export function subMarketsUrl(id?: number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/sub_markets/${id || ''}`);
}

export function motivationAudiencesUrl(id?: number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/motivation_audiences/${id || ''}`);
}

export function outcomeAudiencesUrl(id?: number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/outcome_audiences/${id || ''}`);
}

export function pveRequestUrl(id?: number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/pve_requests/${id || ''}`);
}

export function snapshotsUrl(mekko_id?:  number): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/snapshots?mekko_id=${mekko_id}`);
}

export function snapshotUrl(path?:  number | string): string {
  return apiUrl(`${INSIGHTS_API_PATH}/${V1}/snapshots/${path}`);
}
