import { Component, Input, Output, EventEmitter, ViewChild, OnDestroy, } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { get, isEmpty } from 'lodash';

import { AudienceV2, hasGlobalCounts, isAudienceCloneableOnly } from 'app/audiences-v2/audience-v2.model';
import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import { getFullProductSlug } from "app/hierarchy/hierarchy.utils";
import { NAV_AUDIENCES } from "app/shared/utils/constants";
import { AppState } from 'app/reducers';
import { tooltipMessageV2 as tooltipMessages } from 'app/audiences/shared/audience.constants';
import { OverviewV3Service } from 'app/audiences/overview-v3/overview-v3.service';
import { PopupComponent } from 'app/shared/components/popup/popup.component';
import { DataPermissions } from 'app/data_permissions/data_permissions.model';
import { isPermissioned } from 'app/services/data_permissions.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { userCan } from 'app/authorization/permissions/permissions.reducers'
import { AUDIENCES_SUB_NAV_BUILDER } from '../../../shared/utils/constants';
import { errorCodeMessages } from '../../shared/audience.error.codes';

@UntilDestroy()
@Component({
  selector: 'ppc-audience-action-menu',
  templateUrl: './audience-action-menu.component.html',
  styleUrls: ['./audience-action-menu.component.sass']
})
export class AudienceActionMenuComponent implements OnDestroy {
  @ViewChild('popupComponent', { static: true })popupComponent: PopupComponent;

  @Input() audience?: AudienceV2;
  @Input() isDetailView: boolean;
  @Input() activeRegion: string;
  @Input() activationErrorCode: string;
  @Output() openAudienceDetail = new EventEmitter<AudienceV2>();
  @Output() openActivationOverlay = new EventEmitter<AudienceV2>();
  @Output() errorRibbonCloseEvent = new EventEmitter();
  @Output() openIndexReportForm = new EventEmitter<AudienceV2>();

  private permissions: {[identifier: string]: DataPermissions};
  tooltipMessages = tooltipMessages;

  constructor(
    private router: Router,
    public store: Store<AppState>,
    private overviewService: OverviewV3Service) {
    store.select('dataPermissions').pipe(untilDestroyed(this))
      .subscribe(dataPermissions => {
        this.permissions = dataPermissions;
      });
  }

  ngOnDestroy() {}

  get userCanEditOrClone(): boolean {
    return (isAudienceCloneableOnly(this.audience))
      ? userCan('create', 'audiences', this.store) : userCan('update', 'audiences', this.store);
  }

  get canEdit(): boolean {
    return this.audience.job_status === 'pending'
  }

  get editAlias(): string {
    return (isAudienceCloneableOnly(this.audience))
      ? 'Clone' : 'Edit';
  }

  get editDisabled(): boolean {
    return this.audience.job_status === 'fail' ||
           !this.audience.job_status ||
           !userCan('update', 'audiences', this.store);
  }

  get cloneTooltipMessage(): string {
    const action = 'Clone';
    const option = 'NotUserPermissioned';
    return tooltipMessages[`disabled${action}${option}`];
  }

  get editTooltipMessage(): string {
    const action = 'Edit';
    const option = 'NotUserPermissioned';
    return tooltipMessages[`disabled${action}${option}`];
  }

  cloneAudience() {
    this.router.navigate([getFullProductSlug(), NAV_AUDIENCES, AUDIENCES_SUB_NAV_BUILDER, this.audience.id], {queryParams: { audienceCloned: true }});
  }

  editAudience() {
    this.router.navigate([getFullProductSlug(), NAV_AUDIENCES, AUDIENCES_SUB_NAV_BUILDER, this.audience.id]);
  }

  get userCanCreateLookalike(): boolean {
    return userCan('create', 'lookalikes', this.store)
  }

  get canSeedLookalike(): boolean {
    return !isEmpty(this.audience) && this.audience.job_status == "complete" && hasGlobalCounts(this.audience) && this.userCanCreateLookalike && isPermissioned(this.audience, this.permissions, 'modeling_seed');
  }

  delete() {
    if (this.isDetailView) {
      this.closeErrorRibbon();
    }

    this.overviewService.deletePpcItem(this.audience);
  }

  createLookalike() {
    this.overviewService.createLookalike(this.audience);
  }

  sendToTardiis() {
    this.overviewService.sendToTardiis(this.audience);
  }

  get isComplete(): boolean {
    return this.audience.job_status === "complete";
  }

  get userCanSendToTardiis(): boolean {
    return userCan('create', 'send_to_tardiis', this.store);
  }

  get canSendToTardiis(): boolean {
    return (!this.audience.send_to_tardiis && this.isComplete && this.userCanSendToTardiis && isPermissioned(this.audience, this.permissions, 'tv_planning') && this.auMinCountPass(this.audience));
  }

  get userCanSendToPartner(): boolean {
    return userCan('create', 'send_to_partner', this.store)
  }

  get canSendToPartner(): boolean {
    return (
      this.isComplete &&
      this.userCanSendToPartner &&
      isPermissioned(this.audience, this.permissions, 'activation') &&
      this.audience.count.people.global > 0 &&
      !this.activationErrorCode
    );
  }

  get userCanInitiateIndexReport(): boolean {
    return userCan('create', 'index_report', this.store);
  }

  get canInitiateIndexReport(): boolean {
    return this.isComplete && this.userCanInitiateIndexReport
  }

  get sendToPartnerTooltipMessage(): string {
    let option = "";
    if (!this.isComplete) {
      option = "Incomplete";
    } else if (!this.userCanSendToPartner) {
      option = "NotUserPermissioned";
    } else if (!isPermissioned(this.audience, this.permissions, 'activation')) {
      option = "NotDataPermissioned";
    } else if (this.audience.count.people.global < 1) {
      option = "MissingCounts";
    } else if (this.activationErrorCode) {
      return errorCodeMessages[this.activationErrorCode];
    }
    return tooltipMessages[`sendToPartner${option}`]
  }

  get tardiisTooltipMessage(): string {
    let option = "";
    if (!this.isComplete) {
      option = "Incomplete";
    } else if (!this.userCanSendToTardiis) {
      option = "NotUserPermissioned";
    } else if (!isPermissioned(this.audience, this.permissions, 'tv_planning')) {
      option = "NotDataPermissioned";
    } else if (!this.auMinCountPass(this.audience)) {
      const fusionResults = this.audience.panelist_count;
      return this.disabledTardiisAUWarning(fusionResults);
    } else if (this.audience.send_to_tardiis) {
      option = "AlreadySent";
    }
    return tooltipMessages[`disabledTardiis${option}`];
  }

  get lookalikeTooltipMessage(): string {
    let option = "";
    if (!this.isComplete) {
      option = "CreationDisabled";
    } else if (!this.userCanCreateLookalike) {
      option = "NotUserPermissioned";
    } else if (!isPermissioned(this.audience, this.permissions, 'modeling_seed')) {
      option = "NotDataPermissioned";
    } else if (!hasGlobalCounts(this.audience)) {
      option = "MissingCounts"
    }
    return tooltipMessages[`lookalike${option}`];
  }

  get indexReportTooltipMessage(): string {
    let option = '';
    if (!this.userCanInitiateIndexReport) {
      option = 'NotUserPermissioned';
    } else if (!this.isComplete) {
      option = 'CreationDisabled';
    }
    return tooltipMessages[`indexReport${option}`];
  }

  auMinCountPass(audience: AudienceV2 | LookalikeV3): boolean {
    if (this.regionIsNotAustralia()) { return true }
    if (audience.panelist_count && audience.panelist_count < 100) { return false }

    return true
  }

  disabledTardiisAUWarning(fusionResults): string {
    return `Warning: Lower than 100 sample size. There are only ${fusionResults} TV panelists for the selected segment. This is below the threshold of 100, which OzTAM recommends for statistical reliability. The All-industry Technical Committee of OzTAM regards audiences measures based on such small samples, unreliable for the planning, buying and post analysis of television advertising. When using these results the sample size must be shown at all times`
  }

  get userCanDelete(): boolean {
    return userCan('destroy', 'audiences', this.store)
  }

  get canDelete(): boolean {
    return !this.isProcessing &&
           this.userCanDelete &&
           !this.audience.send_to_tardiis &&
           !this.audience.used_as_seed &&
           this.audience.deletable;
  }

  get isProcessing(): boolean {
    return this.audience.job_status === "processing";
  }

  get deleteConfirmationMessage(): string {
    return `Are you sure you want to delete this Audience? This action cannot be undone.`;
  }

  deleteTooltipMessage(): string {
    if (get(this.audience, ['delete_disallow_reasons', 'length'], 0) > 0) {
      return this.audience.delete_disallow_reasons[0];
    }

    let option = "";
    if (this.isProcessing) {
      option = "Processing";
    } else if (!this.userCanDelete) {
      option = "NotUserPermissioned";
    } else if (this.audience.send_to_tardiis) {
      option = "Tardiis";
    } else if (this.audience.used_as_seed) {
      option = "Seed";
    }

    return tooltipMessages[`disabledDelete${option}`];
  }

  canOpenUpwards(): boolean {
    return !this.isDetailView;
  }

  getWidthPixels(): number {
    if (!this.isDetailView ) {
      return 170
    }
  }

  regionIsNotAustralia() {
    return this.activeRegion !== 'au';
  }

  closeErrorRibbon() {
    this.errorRibbonCloseEvent.emit();
  }

  sendToPartner(audience) {
    if (this.isDetailView) {
      this.closeErrorRibbon();
    }

    this.openActivationOverlay.emit(audience)
  }

  get sendToTardiisWarningMessage(): string {
    return "Warning: Sample Size may be lower than 100, which OzTAM recommends for statistical reliability. The All-industry Technical Committee of OzTAM regards audiences measures based on such small samples, unreliable for the planning, buying and post analysis of television advertising. When using these results the sample size must be shown at all times."
  }

  get buttonContinueLabel(): string {
    return 'Send to Tardiis'
  }

  createIndexReport(audience) {
    if (this.isDetailView) {
      this.closeErrorRibbon();
    }
    this.openIndexReportForm.emit(audience)
  }
}
