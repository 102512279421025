import { Store } from '@ngrx/store';
import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Category } from './../category.interface';
import { Unit } from '../unit.interface';
import { AppState } from 'app/reducers';
import { EditUnit } from "app/toolbox/toolbox.actions";
import { cloneDeep } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-unit-dialog',
  templateUrl: './unit-dialog.component.html',
  styleUrls: ['./unit-dialog.component.sass']
})
export class UnitDialogComponent {
  @ViewChild('unitDialog', { static: true }) dialog: any;
  @Input() unit: Unit;
  @Input() category: Category;
  @Input() mode: string;
  @Input() solo: boolean;
  @Output() modalMode: EventEmitter<boolean> = new EventEmitter<boolean>();

  cmpPrnt = this; // to allow closing modal from form context

  constructor(
    private store: Store<AppState>
  ) { }

  editUnit(unit: Unit) {
    this.store.dispatch(new EditUnit(cloneDeep(unit)))
  }

  close() {
    this.dialog.close();
  }

  handleModalMode(mode: boolean) {
    this.modalMode.emit(mode);
  }

}
