import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "percentFormatter"})
export class PercentFormatterPipe implements PipeTransform {


  constructor() {
  }

  public transform(value: string | number, hasPercentSign: boolean = true): string {
    if (value == void(0) || value === "") {return null; }

    let resultValue: string;

    if (typeof value === "string") {

      resultValue = parseFloat(value).toFixed(2);
    } else {

      resultValue = value.toFixed(2);
    }
    if (resultValue === "NaN") {

      return "";
    } else {

      // remove trailing zeroes
      if (resultValue.includes(".00")) {
        resultValue = resultValue.replace(".00", "");
      }

      return hasPercentSign ? resultValue + "%" : resultValue
    }
  }

  public parse(value: string): string {
    return value.replace("%", "");
  }

  public parseFloat(value: string): number {
    return (parseFloat(this.parse(value)) / 100);
  }

}
