import { BehaviorSubject } from 'rxjs';
import { OnDestroy, Injectable, Inject } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store, select } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { chain, concat, flatten, forEach, keys, map as _map, tail, groupBy } from 'lodash';
import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import * as insightsActions from 'app/insights/insights.actions';
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';
import { fullContext } from "app/hierarchy/hierarchy.reducers";
import { HierarchyContext } from "app/hierarchy/hierarchy.interface";
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Workbook, Worksheet } from 'exceljs/dist/exceljs';
import { saveAs } from "file-saver";

@UntilDestroy()
@Injectable()
export class InsightsExportService extends InsightsResourceTracker implements OnDestroy {
  context: HierarchyContext;
  isTier3 = environment.isTier3;
  loading$ = new BehaviorSubject(false);
  counts: {[exportOption: string]: {[identifier: string]: number}};

  constructor(public store: Store<AppState>,
    private snackbar: MatSnackBar,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super(store, insightsContext)

    fullContext(this.store).pipe(untilDestroyed(this))
      .subscribe(context => this.context = context);
  }

  personLevelExport(chartData, exportType: string, workBookName?: string, headers?: []) {
    const chartDetails = concat(this.chartDetailsSharedHeaders(), chartData);
    const fileName = this.setFileName(workBookName || (exportType.split(" ")[0].toLowerCase() + '_personlevel_export'));
    const wb: Workbook = this.addChartDetailsSheet(chartDetails);
    // TODO: generate person level worksheets here
    return { chartDetails: chartDetails, wb: wb };
  }

  addFiltersToChartDetails(filters, isMarketLevel, data, chartData) {
    const chartFilters = this.generateChartWidgetFilters(filters);
    if (!isMarketLevel && chartFilters.length > 0) {data["Widget Filters"].push(chartFilters[0][1]); }

    const finalChartData = chartData.concat(_map(keys(data), key => flatten([key, data[key]])));
    const finalChartDataWithFilters = finalChartData.concat(tail(chartFilters));

    return isMarketLevel ? finalChartData : finalChartDataWithFilters;
  }

  generateChartWidgetFilters(filters): string[][] {
    return chain(filters)
      .filter('pathParts')
      .groupBy('demographicId')
      .map((filters, demoId) => ['', filters.map(f => f.pathParts.join(' > ')).join(', ')])
      .value();
  }

  chartDetailsSharedHeaders() {
    return [
      ["Date", new Date().toLocaleDateString()],
      ["Client", this.context.client.name],
      ["Region", this.context.region.name],
      ["Brand", this.context.brand.name],
      ["Product", this.context.product.name],
    ];
  }

  setFileName(fileName: string): string {
    const formattedDate = moment(new Date()).format('YYYY-MM-DD');
    const contextKeyword = this.insightsContext == "compare" ? "overlap" : this.insightsContext;
    return formattedDate + '_' + this.context.client.slug + '_' + this.context.region.slug + '_' + contextKeyword + '_' + fileName;
  }

  addChartDetailsSheet(chartDetails) {
    const wb: Workbook = new Workbook();
    const ws = this.setWorksheet(wb, "Chart Details");
    forEach(chartDetails, row => {
      const wsRow = ws.addRow(row);
    });
    return wb;
  }

  setWorksheet(workbook: Workbook, sheetName: string): Worksheet {
    const worksheet = workbook.addWorksheet(sheetName);
    this.modifySheetName(worksheet);
    return worksheet;
  }

  // handle Excel's native worksheet limitations
  modifySheetName(worksheet: Worksheet) {
    worksheet.name = worksheet.orderNo + ". " + worksheet.name;
    if (worksheet.name.length > 31) {worksheet.name = worksheet.name.slice(0, 31); }
    const forbiddenCharacters = [":", "\\", "/", "?", "*", "[", "]"];
    const forbiddenCharactersInName = forbiddenCharacters.filter(character => worksheet.name.includes(character));
    forEach(forbiddenCharactersInName, character => worksheet.name = worksheet.name.replace(character, "_"));
  }

  writeFile(workbook, fileName) {
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, fileName);
    })
  }

  boldRow(row) {
    row.font = { bold: true };
  }

  handleIndexColor(row, index, value) {
    const color = value > 99 ? '228b22' : 'ff0000' // green : red
    row._cells[index].font = { ...row._cells[index].font, ...{ color: { argb: color } } };
  }

  finishExport(togglePane = true, message?: string) {
    const snackbarMessage = message || 'Successfully exported Insights data files';
    this.loading$.next(false);
    this.snackbar.open(snackbarMessage, 'ok', { duration: 4000, panelClass: ['check'] });
    if (togglePane) {this.toggleExportPane(); }
  }

  toggleExportPane() {
    this.store.dispatch(new insightsActions.ToggleExport(this.insightsContext));
  }
}
