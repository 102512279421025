<div class="outcome-timeframe" [class.current]="outcomeTimeframe.current">
  <div (click)="expanded = !expanded">
    <div class="outcome-timeframe-current-timeframe-header" *ngIf="outcomeTimeframe.current">Current Timeframe</div>
    <div class="outcome-timeframe-header">
      <i class="fa fa-caret-right" [class.rotate-90]="expanded" [class.invisible]="editMode"></i>
      <span>{{ month }} {{ outcomeTimeframe.year }}</span>
    </div>
  </div>
  <div class="outcome-timeframe-header-actions" *ngIf="!outcomeTimeframe.current">
    <button class="edit-button" (click)="editMode = true" *ngIf="!editMode">Edit</button>
    <app-confirmation *ngIf="canDestroyOutcomes$ | async" buttonClass="delete-button" [isButton]="true" [kontinue]="destroy.bind(this)"></app-confirmation>
  </div>
  <div class="outcome-timeframe-markets" *ngIf="expanded || editMode">
    <div class="outcome-timeframe-market mekko">
      <div class="outcome-timeframe-market-header">
          <div class="outcome-timeframe-market-header-labels">
              <div class="outcome-timeframe-market-header-label">
                Chart Name
              </div>
              <div class="outcome-timeframe-market-header-label numeric">
                Total Population
              </div>
              <div class="outcome-timeframe-market-header-label numeric" *ngIf="!(marketLevelOnly$ | async)">
                Matched
              </div>
              <div class="outcome-timeframe-market-header-label numeric" *ngIf="!(marketLevelOnly$ | async)">
                Modeled
              </div>
            </div>
            <div class="outcome-timeframe-market-header-values">
                <div class="outcome-timeframe-market-header-value">{{ (activeMekko$ |async).name }}</div>
                <div class="outcome-timeframe-market-header-value numeric" *ngIf="!editMode">{{ outcomeTimeframe?.total | number}}</div>
                <div class="outcome-timeframe-market-header-value numeric" *ngIf="!editMode && !(marketLevelOnly$ | async)">{{ outcomeTimeframe?.matched | number}}</div>
                <div class="outcome-timeframe-market-header-value numeric" *ngIf="!editMode && !(marketLevelOnly$ | async)">{{ outcomeTimeframe?.modeled | number}}</div>
                <div class="outcome-timeframe-market-header-value numeric" *ngIf="editMode"><input *ngIf="editMode" #input (input)="input.value = input.valueAsNumber" [(ngModel)]="outcomeTimeframe.total" type="number"></div>
                <div class="outcome-timeframe-market-header-value numeric" *ngIf="editMode && !(marketLevelOnly$ | async)"><input *ngIf="editMode" #input (input)="input.value = input.valueAsNumber" [(ngModel)]="outcomeTimeframe.matched" type="number"></div>
                <div class="outcome-timeframe-market-header-value numeric" *ngIf="editMode && !(marketLevelOnly$ | async)"><input *ngIf="editMode" #input (input)="input.value = input.valueAsNumber" [(ngModel)]="outcomeTimeframe.modeled" type="number"></div>
              </div>
      </div>
    </div>
    <div class="outcome-timeframe-market" *ngFor="let market of marketsArray">
      <div class="outcome-timeframe-market-header" (click)="expandedMarkets[market.id] = !expandedMarkets[market.id]">
        <div class="outcome-timeframe-market-header-labels">
          <i class="fa fa-caret-right" [class.rotate-90]="expandedMarkets[market.id]" [class.invisible]="editMode"></i>
          <div class="outcome-timeframe-market-header-label">
            Market
          </div>
          <div class="outcome-timeframe-market-header-label numeric">
            Total Population
          </div>
          <div class="outcome-timeframe-market-header-label numeric" *ngIf="!(marketLevelOnly$ | async)">
            Matched
          </div>
          <div class="outcome-timeframe-market-header-label numeric"  *ngIf="!(marketLevelOnly$ | async)">
            Modeled
          </div>
        </div>
        <div class="outcome-timeframe-market-header-values">
          <div class="outcome-timeframe-market-header-value">{{ market.name }}</div>
          <div class="outcome-timeframe-market-header-value numeric" *ngIf="!editMode">{{ marketMap[market.id].total | number}}</div>
          <div class="outcome-timeframe-market-header-value numeric" *ngIf="!editMode && !(marketLevelOnly$ | async)">{{ marketMap[market.id].matched | number}}</div>
          <div class="outcome-timeframe-market-header-value numeric" *ngIf="!editMode && !(marketLevelOnly$ | async)">{{ marketMap[market.id].modeled | number}}</div>
          <div class="outcome-timeframe-market-header-value numeric" *ngIf="editMode"><input *ngIf="editMode" #input (input)="input.value = input.valueAsNumber" [(ngModel)]="marketMap[market.id].total" type="number"></div>
          <div class="outcome-timeframe-market-header-value numeric" *ngIf="editMode && !(marketLevelOnly$ | async)"><input *ngIf="editMode" #input (input)="input.value = input.valueAsNumber" [(ngModel)]="marketMap[market.id].matched" type="number"></div>
          <div class="outcome-timeframe-market-header-value numeric" *ngIf="editMode && !(marketLevelOnly$ | async)"><input *ngIf="editMode" #input (input)="input.value = input.valueAsNumber" [(ngModel)]="marketMap[market.id].modeled" type="number"></div>
        </div>
      </div>
      <div class="outcome-timeframe-market-sub-markets" *ngIf="expandedMarkets[market.id] || editMode">
        <div class="outcome-timeframe-market-sub-market" *ngFor='let subMarket of getSubMarketsForMarket(market)'>
          <div class="sub-market-section">
            <div class="sub-market-section-header">
              Audience
            </div>
            <div class="sub-market-section-value">
              {{ subMarket.name }}
            </div>
          </div>
          <div class="sub-market-section">
            <div class="sub-market-section-header numeric">
              Total Population
            </div>
            <div class="sub-market-section-value numeric">
              <span *ngIf="!editMode">{{submarketsMap[market.id][subMarket.id].total | number}}</span>
              <input *ngIf="editMode" #input (input)="input.value = input.valueAsNumber" [(ngModel)]="submarketsMap[market.id][subMarket.id].total" type="number">
            </div>
          </div>
          <div class="sub-market-section" *ngIf="!(marketLevelOnly$ | async)">
            <div class="sub-market-section-header numeric">
              Matched
            </div>
            <div class="sub-market-section-value numeric">
              <span *ngIf="!editMode">{{submarketsMap[market.id][subMarket.id].matched | number}}</span>
              <input *ngIf="editMode" #input (input)="input.value = input.valueAsNumber" [(ngModel)]="submarketsMap[market.id][subMarket.id].matched" type="number">
            </div>
          </div>
          <div class="sub-market-section" *ngIf="!(marketLevelOnly$ | async)">
            <div class="sub-market-section-header numeric">
              Modeled
            </div>
            <div class="sub-market-section-value numeric">
              <span *ngIf="!editMode">{{submarketsMap[market.id][subMarket.id].modeled | number}}</span>
              <input *ngIf="editMode" #input (input)="input.value = input.valueAsNumber" [(ngModel)]="submarketsMap[market.id][subMarket.id].modeled" type="number">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="outcome-timeframe-actions" *ngIf="editMode">
    <button matRipple class="save-button btn" (click)="save()">
      Save
    </button>
    <button mat-button (click)="cancel()">
      Cancel
    </button>
  </div>
</div>
