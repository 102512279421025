import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store, select} from "@ngrx/store";
import {AppState} from "app/reducers";
import {Observable, Subject} from "rxjs";
import {FeatureModule} from "app/feature-access/feature-access.reducers";
import {entries, filter, groupBy} from 'lodash';
import {NAV_ORDER} from "app/feature-access/feature-access.reducers";
import {map, take, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ppc-client-feature-access',
  templateUrl: './client-feature-access.component.html',
  styleUrls: ['./client-feature-access.component.sass']
})
export class ClientFeatureAccessComponent implements OnInit, OnDestroy {

  @Input()
  clientId: string;

  featureModules: {[id: string]: FeatureModule};
  accessibleFeatureIds = new Set<string>();

  featureModules$: Observable<[string, FeatureModule[]][]>;

  ngUnsubscribe = new Subject<null>();

  constructor(private store: Store<AppState>) {
    this.featureModules$ = this.store.select('featureAccess').pipe(
      select('features'),
      map(access => entries(groupBy(access, 'category'))
        .sort((a, b) => NAV_ORDER.indexOf(a[0]) - NAV_ORDER.indexOf(b[0]))
        .map(i => [
          i[0], i[1].sort((a, b) => a.rank - b.rank)
        ] as [string, FeatureModule[]])
      ),
    )

    this.store.select('featureAccess').pipe(
      select('features'),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(features => this.featureModules = features);
  }

  ngOnInit() {
    this.syncFromStore();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleAccess(featureId: string) {
    this.accessibleFeatureIds.has(featureId) ? this.accessibleFeatureIds.delete(featureId)
      : this.accessibleFeatureIds.add(featureId);
  }

  toggleCategory(categoryName: string) {
    const features = filter(this.featureModules, {category: categoryName});
    const newState = !features.every(f => this.accessibleFeatureIds.has(f.id));

    newState ? features.forEach(f => this.accessibleFeatureIds.add(f.id))
      : features.forEach(f => this.accessibleFeatureIds.delete(f.id));
  }

  syncFromStore() {
    this.store.select('featureAccess').pipe(
      select('accessByClient'),
      take(1),
    ).subscribe(
      accessByClient => this.accessibleFeatureIds = new Set(accessByClient[this.clientId])
    )
  }

  isCategoryChecked(categoryName: string): boolean {
    return filter(this.featureModules, {category: categoryName})
      .some(f => this.accessibleFeatureIds.has(f.id));

  }

  isCategoryIndeterminate(categoryName: string): boolean {
    return !filter(this.featureModules, {category: categoryName})
      .every(f => this.accessibleFeatureIds.has(f.id));
  }
}
