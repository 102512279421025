import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {Actions} from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "app/reducers";
import {map, tap, withLatestFrom} from 'rxjs/operators';
import { activeContext } from 'app/hierarchy/hierarchy.reducers';
import { LoadPartners } from 'app/models/partners/partner.actions';
import {catchError} from 'rxjs/operators';
import { handleError } from "app/shared/utils/http-utils";
import { Observable } from "rxjs";
import { destinationsUrl, fetchPartnerUrl } from "app/shared/constants/segments.urls";

@Injectable()
export class ActivationOverlayService {
  constructor(private http: HttpClient, private actions$: Actions, private store: Store<AppState>) { }

  fetchPartners(context): Observable<any> {
    return this.http.get(fetchPartnerUrl(), { params: { context: context } })
      .pipe(
        tap((res: any) => {
          this.store.dispatch(new LoadPartners(res.data))
        }),
        catchError(handleError), );
  }

  fetchDestinations(partner_id): Observable<any> {
    return this.http.get(destinationsUrl(), { params: { partner_id: partner_id } })
      .pipe(
        map((res: any) => res.data),
        catchError(handleError), );
  }

  fetchActivationDestinations(partner_id, audience_id): Observable <any> {
    return this.http.get(destinationsUrl(), { params: {identifier: audience_id, partner_id: partner_id} })
      .pipe(
        map((res: any) => res.data),
        catchError(handleError), );
  }

  fetchAdminActivationDestinations(adminContext, partner_id): Observable <any> {
    return this.http.get(destinationsUrl() + '/admin', {
      params: {
        client: adminContext.client,
        region: adminContext.region,
        brand: adminContext.brand,
        product: adminContext.product,
        partner_id: partner_id
      }
    })
      .pipe(
        map((res: any) => res.data),
        catchError(handleError), );
  }


  createDestination(params): Observable<any> {
    return this.http.post(destinationsUrl(), params)
      .pipe(
        withLatestFrom(
          activeContext(this.store)
        ),
        map(([res, context]) => {
          this.fetchPartners(context.client.slug).subscribe();
          return res
        }));
  }

}
