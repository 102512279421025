import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgLetModule } from 'ng-let';

import { OutcomeAudienceComponent } from 'app/outcome-audience/outcome-audience.component';
import { OutcomeAudienceService } from 'app/outcome-audience/outcome-audience.service';
import { InsightsModule } from 'app/insights/insights.module';
import { IndexReportModule } from 'app/index-report/index-report.module';
import { OutcomeAudienceFormComponent } from './outcome-audience-form/outcome-audience-form.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PpcComponentsModule,
    RouterModule,
    NgLetModule,
    InsightsModule,
    IndexReportModule,
  ],
  declarations: [
    OutcomeAudienceComponent,
    OutcomeAudienceFormComponent
  ],
  exports: [
    OutcomeAudienceComponent
  ],
  providers: [
    OutcomeAudienceService
  ]
})
export class OutcomeAudienceModule { }
