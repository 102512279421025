<ng-content select="[left]"></ng-content>
<div class="split-screen-gutter" *ngIf="showGutter">
  <div *ngIf="focus != 'left'" class="left-button" (click)="setFocus('left')" >
    <div class="rotated" [class.down]="!focus" [class.up]="focus">
      <span>{{focus == 'right' ? 'Show' : 'Hide'}} {{leftLabel}}</span>
    </div>
  </div>
  <div *ngIf="focus != 'right'" class="right-button" (click)="setFocus('right')" >
    <div class="rotated" [class.up]="!focus" [class.down]="focus">
      <span>{{focus == 'left' ? 'Show' : 'Hide'}} {{rightLabel}}</span>
    </div>
  </div>
  <ng-content select="[gutterOverlay]"></ng-content>
</div>
<ng-content select="[right]"></ng-content>
