
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {Store, select} from "@ngrx/store";
import { Subject } from 'rxjs';

import * as mekkoActions from 'app/mekko/mekko.actions';
import { selectActiveMekko, Market, Mekko } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';

@Component({
  selector: 'ppc-market-form',
  templateUrl: './market-form.component.html',
  styleUrls: ['./market-form.component.sass']
})

export class MarketFormComponent implements OnInit, OnDestroy {
  market: Market;
  mekko: Mekko;
  ngUnsubscribe = new Subject();
  savedMarketName = '';
  canDestroyMekkos$ = this.store.select('permissions', 'mekkos', 'destroy');

  constructor(private store: Store<AppState>) {
    store.select("mekkos", "marketUnderEdit").pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(market => this.market = market)

    store.select("mekkos").pipe(
      select(selectActiveMekko),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(mekko => this.mekko = mekko);
  }

  ngOnInit() {
    this.savedMarketName = this.market.name || '';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  upsertMarket() {
    this.store.dispatch(new mekkoActions.UpsertMarket(this.market));
    this.store.dispatch(new mekkoActions.EditMarket(null));
  }

  destroyMarket() {
    this.store.dispatch(new mekkoActions.DestroyMarket(this.market));
    this.store.dispatch(new mekkoActions.EditMarket(null));
  }

  cancel() {
    this.store.dispatch(new mekkoActions.EditMarket(null));
  }

  duplicateNames() {
    const name = this.market.name ? this.market.name.trim().toLowerCase() : '';
    return name !== this.savedMarketName.trim().toLowerCase() && this.mekko.markets.find(m => m.name.trim().toLowerCase() === name);
  }
}
