import {
  Component,
  OnInit,
  Input,
  Renderer2,
  HostListener,
  forwardRef,
  AfterViewChecked,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from "@angular/animations";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounce, map, find, compact} from 'lodash';

/*
  Component for a simple select box. Allows multiple selection by input 'multi'.
*/

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.sass'],
  animations: [
    trigger('state', [
      state('open', style({
        height: '*',
      })),
      state('closed', style({
        height: '0px',
      })),
      transition('closed => open', animate('100ms ease-in')),
      transition('open => closed', animate('100ms ease-out'))
    ])
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectBoxComponent),
      multi: true
    }
  ]
})
export class SelectBoxComponent implements OnInit, ControlValueAccessor, AfterViewChecked {
  @ViewChild("trigger", { static: true }) trigger: ElementRef;
  @Input() placeholder: string;
  @Input() items: any[];
  @Input() multi: boolean;
  @Input() valuePath: string = "value";
  @Input() labelPath: string = "label";
  disabled: boolean;
  open: boolean = false;
  value: any;
  dropdownWidth: number = 0;


  constructor(private renderer: Renderer2, private changeDetector: ChangeDetectorRef, private elem: ElementRef) {
  }

  ngOnInit() {
    this.renderer.listen('window', 'click', event => {
      if (!this.elem.nativeElement.contains(event.target)) {
        this.open = false;
      }
    })
    if (this.multi) {
      this.value = [];
    }

  }

  ngAfterViewChecked() {
    this.dropdownWidth = this.trigger.nativeElement.clientWidth + 4;
    this.changeDetector.detectChanges();
  }

  // CONTROL VALUE ACCESSOR IMPLEMENTATION

  // Function to call when the value changes.
  onChange = (value: any) => {
  };

  // Function to call when the input is touched.
  onTouched = () => {
  };

  // Allows Angular to update the model.
  // Update the model and changes needed for the view here.
  writeValue(value: any): void {
    this.value = value;
    this.onChange(this.value);
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input. Not currently used to this component but necessary to implement ControlValueAccessor
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // END CONTROL VALUE ACCESSOR IMPLEMENTATION

  // toggles the select-box. Debounced because it triggers on both focus and click
  toggle = debounce(() => {
    this.open = !this.open;
  }, 300, {
    'leading': true,
    'trailing': false
  });

  select(event: any, item: any) {

    // if its a single select we should close the select box on selection
    if (!this.multi) {
      this.open = false;
      this.value = item[this.valuePath];
    } else {
      if (Array.isArray(this.value) && this.value.indexOf(item[this.valuePath]) > -1) {

        this.value.splice(this.value.indexOf(item[this.valuePath]), 1);

      } else {

        if (Array.isArray(this.value)) {
          this.value.push(item[this.valuePath]);
        } else {
          this.value = [item[this.valuePath]];
        }

      }
    }
    this.writeValue(this.value);

  }

  isSelected(item: any) {
    // used to determine whether to show an option as selected
    if (!this.multi) {
      return this.value == item[this.valuePath];
    } else {
      if (Array.isArray(this.value)) {
        return this.value.indexOf(item[this.valuePath]) > -1;
      } else {
        return false;
      }
    }
  }

  hasValue() {
    return (!this.multi && this.value != null) || (this.multi && this.value && this.value.length);
  }

  getText() {
    if (this.multi) {
      const selected = compact(map(this.value, value => {
        const item = find(this.items, item => {
          return item[this.valuePath] == value
        })
        return item ? item[this.labelPath] : null;
      }))
      return selected.join(', ');
    } else {
      const item = find(this.items, item => this.value == item[this.valuePath]);
      return item ? item[this.labelPath] : "";
    }
  }

  @HostListener('keypress', ['$event'])
  keypress(event) {
    // Close the select box if they press enter + (ctrl|shift)
    if (event.which === 13 && (event.shiftKey || event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      this.open = false;
    }
  }

}
