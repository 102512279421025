<div class="widget-body" [class.chart-vertical]="vertical">
  <div [class.progress-bar-vertical-container]="vertical"
       [class.progress-bar-container]="!vertical">
    <div *ngFor="let da of data;trackBy: trackById"
        [class.progress-bar-vertical-wrapper]="vertical"
        [class.progress-bar-wrapper]="!vertical">
      <ppc-progress-bar
          (click)="onBarClicked(da)"
          [percentage]="getPercentage(da)"
          [label]="da.bucket"
          [inlineLabel]="false"
          [colorOverride]="colorOverride"
          [rotateText]="vertical"
          [vertical]="vertical"
          [selected]="da.selected"
          [round]="round"
          [showCounts]="displayCounts"
          [count]="displayCounts ? da.getCount() : null"
          [countLabel]="displayCounts ? da.getCountLabel() : null">
      </ppc-progress-bar>
    </div>
  </div>
</div>
