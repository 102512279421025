<button class="btn" (click)="editCompetitors.show();">Edit COMPETITORS</button>

<ppc-dialog #editCompetitors>
  <h3 header class="app-modal-header">Edit Competitors</h3>
  <div body class="layout vertical">
    <table class="competitors">
      <thead>
        <tr>
          <td></td>
          <td>Actions</td>
        </tr>
      </thead>
      <tr>
        <td colspan="3">
          <form (submit)="saveNewCompetitor()" class="new-competitor-form">
            <ppc-input #name
                       name="newCompetitorName"
                       [(ngModel)]="newCompetitor.name"
                       style="display: inline-block"
                       [validators]="[newCompetitorValidator]">
              <label>Competitor Name</label>
            </ppc-input>
            <button class="btn btn-save btn-sm" [disabled]="!isValidNewCompetitorName(newCompetitor.name)">Save</button>
          </form>

        </td>
      </tr>
      <tr *ngFor="let competitor of nonClientCompetitors">
        <td width="100%">
          <ppc-input [(ngModel)]="competitor.name"
                     [disabled]="!competitor.editing"
                     [noLabel]="true"
                     [validators]="[existingCompetitorValidator]">
          </ppc-input>
        </td>
        <td class="actions" style="text-align: center; width: 75px;">
          <span *ngIf="competitor.editing">
            <i class="fa fa-save" (click)="editCompetitor(competitor)"></i>
            &nbsp;
            <i class="fa fa-ban" (click)="cancelCompetitorEditing(competitor)"></i>
          </span>
          <span *ngIf="!competitor.editing">
            <i (click)="toggleCompetitorEditing(competitor, true)" class="fa fa-pencil"></i>
            &nbsp;
            <app-confirmation
              isIcon="true"
              buttonLabel=""
              buttonClass="fa fa-times"
              [modalTitle]="'Are you sure you want to delete ' + competitor.name + '?  '"
              modalContent="This action cannot be undone."
              [kontinue]="deleteCompetitor.bind(this, competitor.id)"
            ></app-confirmation>
          </span>
        </td>
      </tr>
    </table>
  </div>
  <div footer class="app-modal-footer">
    <button mat-button (click)="this.resetName(); editCompetitors.close();">Close</button>
  </div>
</ppc-dialog>
