<div class="markets-bar" #bar [class.expanded]="expanded">
  <div class="color-identifier-container">
    <a (click)="expanded = true" [class.hide]="!hasHiddenColors || expanded" class="see-more">See more</a>
      <div class="market-colors" *ngFor="let market of markets">
        <div class="market-name"
             *ngIf="groupBy == 'none'"
             (click)="toggleSelectedMarket(market)">
          {{ market.name }}
        </div>
        <span *ngFor="let subMarket of market.sub_markets">
          <span><span class="circle" [style.background-color]="getColor(subMarket)"></span>{{subMarket.name}}</span>
        </span>
      </div>
    <div class="see-less-container"  *ngIf="expanded">
      <a (click)="expanded = false" class="see-less">See less</a>
    </div>
  </div>
</div>
