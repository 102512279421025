import {Action} from "@ngrx/store";

import { Comparison, ComparisonCountResponse, ComparisonCountRequest, FocusedCellType } from 'app/comparisons/comparisons.reducer';
import { BulkFetchAction } from "app/shared/utils/bulk-fetch-state";
import { SetFocus } from "app/insights/insights.actions";

export class FetchComparisons implements Action {
  static readonly type = "COMPARE:FETCH_COMPARISONS";
  readonly type = FetchComparisons.type;
}

export class SaveComparison implements Action {
  static readonly type = "COMPARE:SAVE_COMPARISON";
  readonly type = SaveComparison.type;

  constructor(public comparison: Comparison | Partial<Comparison>) { }
}

export class LoadComparisons implements Action {
  static readonly type = "COMPARE:LOAD_COMPARISONS";
  readonly type = LoadComparisons.type;

  constructor(public comparisons: Comparison[]) { }
}

export class LoadComparison implements Action {
  static readonly type = "COMPARE:LOAD_COMPARISON";
  readonly type = LoadComparison.type;

  constructor(public comparison: Comparison) { }
}

export class DestroyComparison implements Action {
  static readonly type = "COMPARE:DESTROY_COMPARISON";
  readonly type = DestroyComparison.type;

  constructor(public comparison: Comparison) { }
}

export class RemoveComparison implements Action {
  static readonly type = "COMPARE:REMOVE_COMPARISON";
  readonly type = RemoveComparison.type;

  constructor(public comparison: Comparison) { }
}

export class EditComparison implements Action {
  static readonly type = "COMPARE:EDIT_COMPARISON";
  readonly type = EditComparison.type;

  constructor(public comparison: Comparison) { }
}

export class CopyComparison implements Action {
  static readonly type = "COMPARE:COPY_COMPARISON";
  readonly type = CopyComparison.type;

  constructor(public comparisonId: number) { }
}

export class FetchComparisonCounts implements BulkFetchAction {
  static readonly type = "COMPARE:FETCH_COMPARISON_COUNTS";
  readonly type = FetchComparisonCounts.type;

  constructor(public request: ComparisonCountRequest, public requestId: string) { }
}

export class LoadComparisonCounts implements Action {
  static readonly type = "COMPARE:LOAD_COMPARISON_COUNTS";
  readonly type = LoadComparisonCounts.type;

  constructor(public comparisonId: number, public comparisonCounts: ComparisonCountResponse) { }
}

export class SetSelectedComparison implements Action {
  static readonly type = "COMPARE:SET_SELECTED_COMPARISON";
  readonly type = SetSelectedComparison.type;

  constructor(public comparisonId: number) { }
}

export class FetchDefaultComparison implements Action {
  static readonly type = "COMPARE:FETCH_DEFAULT_COMPARISON";
  readonly type = FetchDefaultComparison.type;
}

export class SaveDefaultComparison implements Action {
  static readonly type = "COMPARE:SAVE_DEFAULT_COMPARISON";
  readonly type = SaveDefaultComparison.type;

  constructor(public comparisonId: number) { }
}

export class LoadDefaultComparison implements Action {
  static readonly type = "COMPARE:LOAD_DEFAULT_COMPARISON";
  readonly type = LoadDefaultComparison.type;

  constructor(public comparisonId: number) { }
}

export class ToggleFocusedCell implements Action {
  static readonly type = "COMPARE:SET_FOCUSED_CELL";
  readonly type = ToggleFocusedCell.type;

  constructor(public focusedCell: Partial<FocusedCellType>) { }
}

export class ClearFocusedCells implements Action {
  static readonly type = "COMPARE:CLEAR_FOCUSED_CELLS";
  readonly type = ClearFocusedCells.type;
}

export type ReducerActions =   FetchComparisons | SaveComparison | LoadComparisons
| LoadComparison  | EditComparison | CopyComparison
| FetchComparisonCounts | LoadComparisonCounts
| RemoveComparison | SetSelectedComparison | LoadDefaultComparison
| ToggleFocusedCell | ClearFocusedCells
