import { takeUntil, map, debounceTime, filter } from 'rxjs/operators';
import { Subject, Observable, merge as observableMerge, BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { chain, filter as _filter, get, keyBy, map as _map, mapValues } from 'lodash';

import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { AppState } from 'app/reducers';
import { Tab, Demographic, newDemographic, fixCounts } from 'app/insights/insights.models';
import { selectCustomTabDemographics } from 'app/insights/insights.reducer';
import { INSIGHTS_CONTEXT, InsightsContextType, PercentCalculationType } from 'app/insights/insights.constants';
import * as insightsActions from 'app/insights/insights.actions';
import { InsightsCountService } from "app/insights/insights-count.service";
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { SegmentLike } from 'app/models/segment-like.model';
import { isDefined } from 'app/shared/utils/utils';
import { selectRegion } from 'app/hierarchy/hierarchy.reducers';
import { HierarchyRegion } from "app/hierarchy/hierarchy.interface";
import { activePersonaHasExcludedIds } from 'app/explore/explore.reducer';
import { TOOLTIP_PERSONA_EXCLUDE_INDEX } from 'app/explore/explore.utils';

@UntilDestroy()
@Component({
  selector: 'ppc-custom-tab-demographic-widgets',
  templateUrl: './custom-tab-widgets.component.html',
  styleUrls: ['./custom-tab-widgets.component.sass']
})

export class CustomTabWidgetsComponent extends InsightsResourceTracker implements OnInit, OnDestroy {
  @Input() tab: Tab;
  bagName: string;
  ngUnsubscribe = new Subject();
  customDemographics: Demographic[];
  demographicsPermission$ = this.store.select("permissions", "demographics");
  jumbo$ = this.store.select("insights", this.insightsContext, "splitScreenFocus").pipe(map(focus => focus == "right"))
  forceLayout$: Observable<{}>;
  isIndexMode: boolean;
  segments: {[identifier: string]: SegmentLike} = {};
  region: HierarchyRegion;
  disableShowIndex$ = this.store.select("explore").pipe(
    select(activePersonaHasExcludedIds),
    map(activePersonaHasExcludedIds => activePersonaHasExcludedIds && this.insightsContext === 'explore')
  );
  personaHasExcludedSegmentsTooltip = TOOLTIP_PERSONA_EXCLUDE_INDEX;

  constructor(private counts: InsightsCountService,
    public store: Store<AppState>,
    private segmentService: SegmentV2Service,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super(store, insightsContext)

    this.store.select("insights", this.insightsContext, "indexMode")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(isIndexMode => this.isIndexMode = isIndexMode);

    this.store.select('hierarchy').pipe(
      select(selectRegion),
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(region => this.region = region);
  }

  ngOnInit() {
    this.forceLayout$ = observableMerge(
      this.store.select("insights", this.insightsContext).pipe(select(selectCustomTabDemographics(this.tab.id))),
      this.store.select("insights", this.insightsContext, "splitScreenFocus"),
      this.counts.countsChanged$,
      this.store.select("insights", this.insightsContext, "indexMode"),
    ).pipe(debounceTime(450))

    this.store.select("insights", this.insightsContext).pipe(select(selectCustomTabDemographics(this.tab.id)) , takeUntil(this.ngUnsubscribe))
      .subscribe((demographics) => {
        this.customDemographics = demographics;

        const ids = chain(this.customDemographics)
          .map(demographic => get(demographic, 'buckets'))
          .flatten()
          .map('short_id')
          .compact()
          .uniq()
          .value();
        if (ids.length) {
          this.segmentService.fetchByIdentifiers(ids).subscribe(segments => {
            const fixedSegments = fixCounts(segments, this.region);
            this.segments = keyBy(fixedSegments, 'identifier');
          });
        }
      });

    this.bagName = `custom-widgets-${this.tab.id}`;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    super.ngOnDestroy();
  }

  addWidget() {
    this.store.dispatch(new insightsActions.EditDemographic(newDemographic(this.tab.id, this.resourceId, this.resourceType), this.insightsContext));
  }

  saveDemographicsConfig(demographicsFromEvent?: Demographic[]) {
    this.store.dispatch(new insightsActions.SaveDemographicsConfig((demographicsFromEvent || this.customDemographics).map((demographic) => {
      return {
        id: demographic.id,
        visible: !!demographic.visible
      }
    }), userPreferenceKeys.customTab(this.tab.id), this.resourceId, this.resourceType, this.insightsContext))
  }

  trackByDemographicId(index, demographic: Demographic) {
    return demographic.id
  }

  hideDemographic(demographic: Demographic) {
    demographic.visible = false;
    this.saveDemographicsConfig();
  }

  toggleIndexMode() {
    this.store.dispatch(new insightsActions.ToggleIndexMode(this.insightsContext));
  }


  getSegmentCounts(demographic: Demographic) {
    const demographicIds = _map(demographic.buckets, bucket => get(bucket, 'short_id'));

    return mapValues(keyBy(demographicIds), id => {
      const segment = this.segments[id];
      return get(segment, ['count', 'matched']);
    });
  }

  get visibleCustomDemographics(): Demographic[] {
    return _filter(this.customDemographics, "visible");
  }
}
