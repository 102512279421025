<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>

<div class="container layout vertical flex-grow-1">
  <h3>Create Outcome Audiences</h3>

  <div class="form-container flex-grow-1" [formGroup]="formGroup">
    <div class="layout vertical">
      <div class="layout horizontal">
        <label>Name the outcome model</label>
        <span class="required-suffix" [hidden]="formGroup.controls.name.valid">*</span>
      </div>
      <ppc-input
        placeholder="Input the name"
        formControlName="name"
        spellcheck=false
        [markAsUntouched]="stateChange"
        [maxLength]="255"
        [validators]="nameValidator"
        >
        <div suffix>
          <i class="fa fa-pencil"></i>
        </div>
      </ppc-input>
    </div>

    <div class="layout vertical half-width margin-bottom">
      <div class="layout horizontal">
        <label>What goal do you want to achieve?</label>
        <span class="required-suffix" [hidden]="goalSelectIsValid()">*</span>
      </div>
      <ppc-dropdown #goalDropdown>
        <div currentlySelected>
          {{ (goalSelection$ | async) || 'Select a goal' }}
        </div>
        <div dropdown>
          <div class="layout vertical options">
            <button mat-button *ngFor="let item of goalList" (click)="updateGoalSelection(item); goalDropdown.open = false">
              {{ item }}
            </button>
          </div>
        </div>
      </ppc-dropdown>
    </div>

    <div class="layout vertical half-width">
      <div class="layout horizontal">
      <label>What audience size?</label>
        <span class="required-suffix" [hidden]="formGroup.controls.audienceSize.valid">*</span>
      </div>
      <ppc-input
        type="number"
        formControlName="audienceSize"
        [markAsUntouched]="stateChange"
        [validators]="numberValidator">
      </ppc-input>
    </div>

    <div class="layout vertical half-width margin-bottom">
      <div class="layout horizontal">
        <label>What type of consumer?</label>
        <span class="required-suffix" [hidden]="consumerSelectIsValid()">*</span>
      </div>
      <ppc-dropdown #consumerTypeDropdown>
        <div currentlySelected class="currently-selected">
          {{ consumerTypeSelection$ | async }}
        </div>
        <div dropdown>
          <div class="layout vertical options">
            <button mat-button *ngFor="let item of consumerTypes" (click)="updateConsumerTypeSelection(item); consumerTypeDropdown.open = false">
              {{ item }}
            </button>
          </div>
        </div>
      </ppc-dropdown>
    </div>

    <div class="layout vertical half-width">
      <ng-container
        *ngIf="(consumerTypeSelection$ | async) === 'Loyal Buyers'"
        [ngTemplateOutlet]="seenDaysField"
        [ngTemplateOutletContext]="{form: formGroup}">
      </ng-container>
    </div>

    <div class="layout vertical half-width">
      <ng-container
        *ngIf="(consumerTypeSelection$ | async) === 'Lapsed Buyers'"
        [ngTemplateOutlet]="notSeenDaysField"
        [ngTemplateOutletContext]="{form: formGroup}">
      </ng-container>
    </div>

    <div class="layout horizontal">
      <button
        class="pds-btn pds-btn--primary"
        matRipple
        ppcConfirmation
        buttonContinueLabel="Create"
        modalTitle="Create Outcome Audience"
        [dialogTemplate]="createModalContent"
        [disabled]="!formGroup.valid || !requiredFieldsSet()"
        [kontinue]="create.bind(this)">
        Create
      </button>

      <button
        class="pds-btn pds-btn--tertiary"
        ppcThrottleClick
        (throttledClick)="cancel()">
        Cancel
      </button>
    </div>

  </div>
</div>

<ng-template #seenDaysField let-form="form">
  <div class="layout vertical">
    <div class="layout horizontal">
      <label>Seen within the last</label>
      <span class="required-suffix" [hidden]="form.get('seenDays').valid">*</span>
    </div>
    <div class="layout horizontal day-input">
      <ppc-input
        type="number"
        showErrorsIfUntouched=true
        [formControl]="form.get('seenDays')"
        [validators]="numberValidator">
      </ppc-input>
      <span class="day-suffix">days</span>
    </div>
  </div>
</ng-template>

<ng-template #notSeenDaysField let-form="form">
  <ng-container
    [ngTemplateOutlet]="seenDaysField"
    [ngTemplateOutletContext]="{form: form}">
  </ng-container>
  <div class="layout vertical">
    <div class="layout horizontal">
      <label>Not seen within the last</label>
      <span class="required-suffix" [hidden]="form.get('notSeenDays').valid">*</span>
    </div>
    <div class="layout horizontal day-input">
      <ppc-input
        type="number"
        showErrorsIfUntouched=true
        [formControl]="form.get('notSeenDays')"
        [validators]="notSeenValidator">
      </ppc-input>
      <span class="day-suffix">days</span>
    </div>
  </div>
</ng-template>

<ng-template #createModalContent>
  <div class="create-modal-content">
    <p>Warning: Creating these audiences is expensive. Please be sure you have authorization before creating them.</p>
    <p>Note: Processing can take up to 72 hours. You will be alerted in the Notification area when processing is complete.</p>
  </div>
</ng-template>
