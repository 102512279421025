import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import {select, Store} from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {combineLatest, Observable} from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatDialog } from "@angular/material/dialog";
import { AppState } from 'app/reducers';
import { cloneDeep, concat, debounce, get } from 'lodash';

import { PpcDragService } from 'app/shared/drag/ppc-drag.service';
import { AudienceBuilderService, MAX_AUDIENCE_NAME_LENGTH } from '../audience-builder.service';
import { CustomCreateService } from '../custom-create.service';
import { InputValidator } from 'app/shared/components/ppc-input/ppc-input.component';
import { PpcCarouselComponent } from 'app/shared/components/ppc-carousel/ppc-carousel.component';
import { LocalStorageService } from 'app/services/local-storage.service';
import {getSegmentCount, isEntrySegment} from "../audience-builder.utils";
import {
  FetchAddressableCountsMetadata,
  LoadAddressableCountsMetadata
} from "../../models/addressable-count-metadata/addressable-count-metadata.actions";
import {isLoaded} from "../../shared/utils/fetch-state";
import {AddressableCountMetadata} from "../../models/addressable-count-metadata/addressable-count-metadata.model";
import { AddressableCountMetadataService } from 'app/models/addressable-count-metadata/addressable-count-metadata.service';

@UntilDestroy()
@Component({
  selector: 'ppc-audience-builder-form',
  templateUrl: './audience-builder-form.component.html',
  styleUrls: ['./audience-builder-form.component.sass']
})

export class AudienceBuilderFormComponent implements OnInit, OnDestroy {
  @Output() zeroCountSegmentDetection = new EventEmitter();
  rules
  canDisplayAddressableCounts: boolean = false;

  nameValidations: InputValidator[] = [
    {
      isValid: (value: string) => this.builderService.hasName,
      errorMessage: `Please name your ${this.builderService.subject}`
    },
    {
      isValid: (value: string) => {
        return this.builderService.hasUniqueName;
      },
      errorMessage: `Duplicate ${this.builderService.subject} Name`
    }
  ]
  maxNameLength = MAX_AUDIENCE_NAME_LENGTH;
  maxNameLengthErrorMessage = `Cannot have more than ${this.maxNameLength} characters`;
  addressableCountsMetadata;
  addressableCount$: Observable<string>;
  addressableCountTooltipDisabled$: Observable<boolean>;
  estimatedPeopleCountTooltip: string;
  pageTitle: string;
  placeholder: string;
  subject: string;
  isPersonaBuilder: boolean;
  isMotivationBuilder: boolean;

  constructor(private store: Store<AppState>,
    public dragService: PpcDragService,
    public builderService: AudienceBuilderService,
    public createService: CustomCreateService,
    private matDialog: MatDialog,
    private localStorage: LocalStorageService,
    private addressableCountsService: AddressableCountMetadataService,
  ) {
    store.dispatch(new FetchAddressableCountsMetadata());

    combineLatest(
      store.select('hierarchy', 'contextSlugs'),
      store.select('addressableCountsMetadata')
    ).pipe(untilDestroyed(this)).subscribe(([context, metadata]) => {
      addressableCountsService.addressableCounts(metadata, context.regionSlug);
      this.canDisplayAddressableCounts = addressableCountsService.canGetAddressableCounts()
    })
  }

  ngOnInit() {
    this.setSeenLearnMoreStatus();

    this.builderService.audience$.pipe(untilDestroyed(this)).subscribe(aud => {
      this.rules = aud.rules
      this.rules && this.recursiveZeroCountCheck(this.rules)
    })

    this.addressableCount$ = combineLatest(
      this.builderService.count$,
      this.store.select('addressableCountsMetadata')
    ).map(([count, addressableCountsMetadata]) => {
      return this.addressableCountsService.displayCalculatedAddressableCounts(count);
    });

    this.addressableCountTooltipDisabled$ = this.addressableCount$.map((displayCount) => {
      return !!displayCount && displayCount !== '0' && displayCount !== '~0' && displayCount !== '-';
    });

    this.estimatedPeopleCountTooltip = this.builderService.estimatedPeopleCountTooltip;
    this.pageTitle = this.builderService.pageTitle;
    this.placeholder = this.builderService.placeholder;
    this.subject = this.builderService.subject;
    this.isPersonaBuilder = this.subject === 'Persona';
    this.isMotivationBuilder = !this.isPersonaBuilder && this.subject === 'Motivation Audience';
  }

  ngOnDestroy() { }

  recursiveZeroCountCheck(item) {
    if (isEntrySegment(item)) {
      if (getSegmentCount(item) === 0) {
        this.zeroCountSegmentDetection.emit()
      }
    } else if (item.items) {
      item.items.forEach((innerItem) => {
        this.recursiveZeroCountCheck(innerItem);
      })
    }
  }

  onDrop(event: CdkDragDrop<any, any>) {
    if (event.previousContainer === event.container) {return; }
    const clone = cloneDeep(event.previousContainer.data[event.previousIndex]);
    event.container.data.splice(event.currentIndex, 0, clone);
  }

  nameInputDefaultCheck($event) {
    const name = $event.target.value;
    if (name.length === 0) {
      this.builderService.audience.name = this.builderService.placeholder;
      this.builderService.checkChanges();
    }
  }

  debouncedCheckAudienceChanges = debounce(() => {
    this.builderService.checkChanges();
  }, 500)

  openHelpCenter() {
    this.matDialog.open(PpcCarouselComponent, {data: this.helpCenterSlides(), panelClass: "no-padding"});
  }

  setSeenLearnMoreStatus() {
    const key = `has-seen-${this.builderService.subject.toLowerCase()}-builder`;
    if (!this.localStorage.getValue(key)) {
      this.openHelpCenter();
      this.localStorage.setValue(key, true);
    };
  }

  helpCenterSlides() {
    const title = `Introducing your ${this.builderService.subject} Builder`;
    const subject = this.builderService.subject.toLowerCase().split(" ")[0];
    const height = "300px";
    const width = "450px";
    const slides = [
      {
        title: title,
        listDescription: 'Add Segments',
        image: `assets/images/${subject}s/builder/addSegment.gif`,
        imageHeight: height,
        useSlideNumber: true,
        listItems: [
          `Drag and drop “<div class="drag-icon"><i class="fa fa-ellipsis-v"></i><i class="fa fa-ellipsis-v"></i></div>segments” to ${this.builderService.subject} Builder.`
        ]
      },
      {
        title: title,
        listDescription: 'Add logic',
        image: `assets/images/${subject}s/builder/addGroup.gif`,
        imageHeight: height,
        useSlideNumber: true,
        listItems: [
          `Drag and drop “<span class="drag-icon"><i class="fa fa-ellipsis-v"></i><i class="fa fa-ellipsis-v"></i></span>OR” to ${this.builderService.subject} Builder.`
        ]
      },
      {
        title: title,
        listDescription: 'Move segments',
        useSlideNumber: true,
        image: `assets/images/${subject}s/builder/moveSegment.gif`,
        imageHeight: height,
        listItems: [
          `Drag and drop “<span class="drag-icon"><i class="fa fa-ellipsis-v"></i><i class="fa fa-ellipsis-v"></i></span>segment” to move within ${this.builderService.subject} Builder.`
        ]
      }
    ];

    if (this.isPersonaBuilder) {
      slides.forEach(slide => Object.assign(slide, {imageWidth: width}));
      const personaSlides = [
        {
          title: title,
          listDescription: "Move Explore Filters",
          useSlideNumber: true,
          image: "assets/images/personas/builder/moveFilters.gif",
          imageHeight: height,
          imageWidth: width,
          listItems: [
            "Select 'Move Explore Filters' to auto-populate the previously selected filters from Explore into the Persona builder."
          ]
        },
        {
          title: title,
          listDescription: "Set Default Persona",
          useSlideNumber: true,
          image: "assets/images/personas/builder/setDefault.gif",
          imageHeight: height,
          imageWidth: width,
          listItems: [
            "Enable the 'Make this my default persona' option to designate a persona as your Explore default when starting a new session."
          ]
        }
      ]
      return concat(slides, personaSlides);
    }

    if (this.isMotivationBuilder) {
      slides.forEach(slide => Object.assign(slide, {imageWidth: width}));
      const motivationSlides = [
        {
          title: title,
          listDescription: "Move Explore Filters",
          useSlideNumber: true,
          image: "assets/images/motivations/builder/moveFilters.gif",
          imageHeight: height,
          imageWidth: width,
          listItems: [
            "Select 'Move Explore Filters' to auto-populate the previously selected filters from Explore into the Motivation builder."
          ]
        }
      ]
      return concat(slides, motivationSlides);
    }

    return slides;
  }
}
