import { Component, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import * as validator from 'email-validator';
import { get, pick, isEqual, values, filter as _filter } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../users/user.service';
import { UserForm } from '../user-settings.component';
import { fullName, User, uniqUserHash } from '../../users/user.interface';
import { Agency } from '../../agencies/agency.interface';
import { Role } from '../../admin/role-admin/role.interface';
import { AddUser, EditUser, SetEditUser } from "../../users/user.actions";
import { AppState } from "../../reducers";
import { Store } from "@ngrx/store";
import { take } from 'rxjs/operators';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

type LoginType = 'lion_login' | 'okta_login';

@UntilDestroy()
@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.sass']
})
export class UserFormComponent implements OnInit, OnDestroy {
  @ViewChild('user', { static: true }) dialog: any;
  @Input() targetUser: Partial<User>;
  @Input() roles: Role[];
  @Input() agencies: Agency[];

  users: User[];
  toActivate$ = this.store.select('users', 'toActivate');

  public userForm: UserForm = {
    first_name: '',
    last_name: '',
    email: '',
    role_ids: [],
    agency_id: null,
    lion_login: '',
    okta_login: '',
    product_ids: []
  };

  // field validators
  nameValidator = [
    {
      isValid: name => name.trim().length,
      errorMessage: 'This field is required'
    }
  ]
  emailValidator = [
    {
      isValid: email => !this.isEmailTaken(email),
      errorMessage: 'Email has already been taken'
    },
    {
      isValid: email => validator.validate(email),
      errorMessage: 'Invalid email address'
    }
  ];
  lionLoginValidator = [
    {
      isValid: lionLogin => !this.isLoginTaken(lionLogin, 'lion_login'),
      errorMessage: 'Lion login has already been taken'
    }
  ];
  oktaLoginValidator = [
    {
      isValid: oktaLogin => !this.isLoginTaken(oktaLogin, 'okta_login'),
      errorMessage: 'Okta login has already been taken'
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private store: Store<AppState>,
    private _scrollToService: ScrollToService
  ) {
    this.store.select('users', 'users')
      .pipe(untilDestroyed(this))
      .subscribe(users => this.users = values(users));
  }

  public toCreate() {
    return !get(this.targetUser, "id");
  }

  public toEdit() {
    return !!get(this.targetUser, "id");
  }

  ngOnInit() {
    if (this.toEdit() && this.targetUser) {
      Object.assign(this.userForm, this.targetUser);
    }
    if (this.toEdit() && this.targetUser.roles) {
      this.userForm.role_ids = this.targetUser.roles.map(r => r.id)
    }
    this.dialog.show();
  }

  ngOnDestroy() { }

  selectedRoles(roleIds: number[], roles: Role[]): Role[] {
    if (!roleIds || !roles) { return };

    return roles.filter((role) => {
      delete role.permissions;
      return !!roleIds.includes(role.id);
    });
  }

  shouldSubmit(event: any): void {
    if (event.which === 13) {
      if (this.toEdit() && this.isEditUserFormValid) {
        this.updateUser();
      } else if (this.toCreate() && this.isCreateUserFormValid) {
        this.createUser();
      }
    }
  }

  get isFormChanged(): boolean {
    const pickAttrs = obj => pick(obj, 'lion_login', 'okta_login', 'role_ids', 'agency_id');
    const targetPick = pickAttrs(this.targetUser);
    const formPick = pickAttrs(this.userForm);

    return !isEqual(targetPick, formPick);
  }

  get isEditUserFormValid(): boolean {
    return this.userForm.role_ids.length > 0
           && this.userForm.agency_id > 0
           && !this.isLoginTaken(this.userForm.lion_login, 'lion_login')
           && !this.isLoginTaken(this.userForm.okta_login, 'okta_login');
  }

  updateUser(): void {
    const updatedUser: any = {
      id: this.targetUser.id,
      role_ids: this.userForm.role_ids,
      agency_id: this.userForm.agency_id,
      lion_login: this.adjustLoginInput(this.userForm.lion_login),
      okta_login: this.adjustLoginInput(this.userForm.okta_login),
    };

    this.store.select('users', 'toActivate').pipe(take(1)).subscribe(toActivate => {
      if (toActivate) { updatedUser.deleted_at = null };

      this.userService.updateUser(updatedUser).subscribe(
        ({ user }) => {
          const message = toActivate ?
            `${fullName(user)} activated successfully.` :
            `Updated ${fullName(user)} successfully!`;

          this.store.dispatch(new EditUser(user));
          this.alertSuccess(message);
          this.store.dispatch(new SetEditUser(null));
          this.scrollTime(user);
        },
        err => this.alertFailed(err.error.message)
      )
    });
  }

  get isCreateUserFormValid(): boolean {
    return !!(this.userForm.email
      && this.isFirstNameValid()
      && this.isLastNameValid()
      && validator.validate(this.userForm.email)
      && !this.isEmailTaken(this.userForm.email)
      && this.userForm.role_ids.length
      && this.userForm.agency_id > 0)
      && !this.isLoginTaken(this.userForm.lion_login, 'lion_login')
      && !this.isLoginTaken(this.userForm.okta_login, 'okta_login');
  }

  createUser(): void {
    const updatedFields = {
      lion_login: this.adjustLoginInput(this.userForm.lion_login),
      okta_login: this.adjustLoginInput(this.userForm.okta_login),
    }
    const updatedUser = Object.assign({}, this.userForm, updatedFields)

    this.userService.createUser(updatedUser).subscribe(
      (user: User) => {
        if (user.id) {
          this.store.dispatch(new AddUser(user))
          this.alertSuccess(`${fullName(user)} successfully created!`);
          this.resetCreateUserForm();
          this.store.dispatch(new SetEditUser(null));
          this.scrollTime(user)
        }
      },
      err => this.alertFailed(err.error.message)
    )
  }

  scrollTime(user: User): void {
    this.router.navigate(['./'], { relativeTo: this.route, queryParams: { uniqUser: uniqUserHash(user) } });
  }

  onCancelClick(): void {
    this.store.dispatch(new SetEditUser(null));
  }

  private isFirstNameValid(): boolean {
    return !!this.userForm.first_name.trim();
  }

  private isLastNameValid(): boolean {
    return !!this.userForm.last_name.trim();
  }

  private isEmailTaken(email: string): boolean {
    return _filter(this.users, u => u.email.toLowerCase() === email.trim().toLowerCase()).length > 0;
  }

  private isLoginTaken(login: string, loginType: LoginType): boolean {
    const adjustedLogin = this.adjustLoginInput(login);
    return this.adjustLoginInput(this.targetUser[loginType]) !== adjustedLogin
      && _filter(this.users, u => this.adjustLoginInput(u[loginType]) === adjustedLogin).length > 0;
  }

  private adjustLoginInput(login: string): string {
    // if defined, lion/okta login should be case-insensitive unique and saved lowercased
    return login && login.trim().toLowerCase();
  }

  private alertSuccess(message: string) {
    this.snackbar.open(message, null, {
      duration: 4000,
      panelClass: ['check']
    });
  }

  private alertFailed(message: string) {
    this.snackbar.open(message, null, {
      duration: 4000,
      panelClass: ['danger']
    });
  }

  private resetCreateUserForm(): void {
    this.userForm = {
      first_name: '',
      last_name: '',
      email: '',
      lion_login: '',
      okta_login: '',
      role_ids: [],
      agency_id: null,
      product_ids: []
    };
  }
}
