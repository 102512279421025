<div class="index-base-header">
  <div class="index-base-header-label">
    Index Base
  </div>
  <ppc-dropdown #indexBaseDropdown>
    <div currentlySelected
         class="currently-selected"
         *ppcCheckOverflow="let hasOverflow=hasXOverflow"
         [ppcTooltip]="indexBase?.name"
         [tooltipDisabled]="!hasOverflow">
      {{indexBase?.name}}
    </div>
    <div dropdown>
      <button mat-button (click)="setIndexBase(); indexBaseDropdown.open = false">Region (Default)</button>
      <button mat-button (click)="openPickerIndex()">Custom</button>
    </div>
  </ppc-dropdown>
  <div class="learn-more-icon"
       [ppcTooltip]="tooltipMessage"
       [tooltipOffset]="{x: 2}">
    <i class="fa fa-question-circle-o"></i>
  </div>
</div>
