import { Component, OnChanges, Input, Output, EventEmitter, Inject, SimpleChanges } from '@angular/core';
import { DonutChartData } from 'app/shared/components/ppc-donut-chart/ppc-donut-chart.component';
import { widgetColors } from 'app/insights/grow-v3/shared/widget-colors.constants';
import * as chroma from 'chroma-js';
import { Store } from '@ngrx/store';
import { max } from 'lodash';

import * as actions from 'app/insights/insights.actions';
import { AppState } from 'app/reducers';
import { ChartTypes, toMillions } from 'app/insights/insights.models';
import { RegionDemographic } from 'app/insights/insights.reducer';
import { InsightsContextType, INSIGHTS_CONTEXT } from 'app/insights/insights.constants';
import { BarChartData } from 'app/insights/insights.service';

@Component({
  selector: 'ppc-region-level-demographic-widget',
  templateUrl: './region-level-demographic-widget.component.html',
  styleUrls: ['./region-level-demographic-widget.component.sass']
})
export class RegionLevelDemographicWidgetComponent implements OnChanges {
  @Input() regionLevelDemographic: RegionDemographic;
  @Output() hideDemographic = new EventEmitter();
  barChartData: BarChartData[];
  donutChartData: DonutChartData[];
  colorScheme: string[];
  maxBucketCount: number;

  chartTypes = ChartTypes;

  constructor(private store: Store<AppState>, @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.regionLevelDemographic && changes.regionLevelDemographic.currentValue) {

      this.maxBucketCount = max(this.regionLevelDemographic.region_demographic_buckets.map((b) => b.count));
      this.colorScheme = chroma.scale(widgetColors).colors(this.regionLevelDemographic.region_demographic_buckets.length);
      this.donutChartData = this.regionLevelDemographic.region_demographic_buckets.map((bucket, idx) => {
        return {
          label: bucket.name,
          count: bucket.percentage,
          countLabel: this.regionLevelDemographic.id_count == 'percentage' ? `${bucket.percentage.toFixed(1)}%` : toMillions(bucket.count),
          id: bucket.id,
          color: this.colorScheme[idx],
        }
      })
      this.barChartData = this.regionLevelDemographic.region_demographic_buckets.map((bucket, index) => {
        return {
          bucket: bucket.name,
          percent: this.regionLevelDemographic.id_count == 'absolute' ? (bucket.count / this.maxBucketCount * 100) : bucket.percentage,
          id: bucket.id,
          getCount: () => bucket.count,
          getCountLabel: () => toMillions(bucket.count),
          order: index
        }
      })
    }
  }

  getColor(idx: number): string {
    return this.colorScheme[idx];
  }

  get idCountTooltip(): string {
    if (this.regionLevelDemographic.id_count === 'percentage') {
      if (this.insightsContext === 'explore') {
        return 'Widget data shown as percentages. Edit the widget to change.'
      } else {
        return 'Widget percentage data by chart. Edit the widget to change.';
      }
    } else if (this.regionLevelDemographic.id_count === 'absolute') {
      if (this.insightsContext === 'explore') {
        return 'Widget data shown as counts. Edit the widget to change.'
      } else {
        return 'Widget data shows absolute counts. Edit the widget to change.';
      }
    }
  }

  get vertical(): boolean {
    return /vertical/.test(this.regionLevelDemographic.widget_type)
  }

  hideDemo() {
    this.hideDemographic.emit(this.regionLevelDemographic)
  }

  editDemo() {
    this.store.dispatch(new actions.EditRegionDemographic(this.insightsContext, this.regionLevelDemographic));
  }
}
