import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { apiUrl } from 'app/services/ppc_http.service';
import { selectActiveClients } from 'app/hierarchy/hierarchy.reducers';
import { ClientMapping } from './client-mapping.model';
import { CLIENTS_API_PATH, V1 } from 'app/shared/utils/constants';
import { map } from 'rxjs/operators';
import { HierarchyClient } from 'app/hierarchy/hierarchy.interface';

@Injectable({
  providedIn: 'root'
})
export class ClientMappingsService {

  clientMappings$: BehaviorSubject<ClientMapping[]> = new BehaviorSubject([]);
  clientSlug$ = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient, private store: Store<AppState>) { }

  getClientMappingsOnInit() {
    return combineLatest([
      this.clientMappings$,
      this.store.select('hierarchy', 'hierarchy').pipe(select(selectActiveClients), select('clients')),
      this.clientSlug$
    ]).pipe(
      map(([clientMappings, clients, clientSlug]: [ClientMapping[], HierarchyClient[], string]) => {
        return [
          clientMappings.filter((clientMapping) =>
            clients.map((client) => client.id).includes(clientMapping.client_id)
          ),
          clients,
          clientSlug
        ];
      })
    );
  }

  getClientMappings() {
    return this.http.get<ClientMapping[]>(this.clientMappingsUrl());
  }

  createClientMapping(clientMappingUnderEdit: ClientMapping) {
    return this.http.post<ClientMapping>(this.clientMappingsUrl(), clientMappingUnderEdit);
  }

  deleteClientMapping(id: number) {
    return this.http.delete(this.clientMappingsUrl(id));
  }

  clientMappingsUrl(id?: number): string {
    return apiUrl(`${CLIENTS_API_PATH}/${V1}/client_mappings/${id || ''}`);
  }
}
