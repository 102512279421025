<div class="filter-bar" #bar [class.expanded]="expanded">
  <div class="filter-bar-actions">
    <a (click)="expanded = true" [class.hide]="!hasHiddenFilters || expanded" class="see-more">See more</a>
    <button matRipple class="header-btn" (click)="clearFilters.emit(null)">
      <div class="header-btn-container">
          Clear Filters
      </div>
    </button>
    <ng-content select="[saveAudienceButton]"></ng-content>
  </div>
  <div class="filter-bar-filters">
    <div class="filter-bar-chip" *ngFor="let filter of filters; trackBy: trackByFilterId">
      <div matRipple [matRippleCentered]="true" class="filter-bar-chip-action" (click)="toggleFilter.emit(filter)" *ngIf="allowToggle">
        <img src="assets/icons/close.svg" />
      </div>
      <div class="filter-bar-chip-label"  [tooltipDisabled]="!filter.truncatedDisplay" [ppcTooltip]="getTooltip(filter)">
        <span [innerHTML]="getName(filter)">
        </span>
      </div>
    </div>
    <div class="see-less-container"  *ngIf="expanded">
      <a (click)="expanded = false" class="see-less">See less</a>
    </div>
  </div>
</div>
