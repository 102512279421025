<div class="widget">
    <div class="widget-header">
      <i class="fa fa-bars drag-handle"></i>
      <span class="widget-name"
        *ppcCheckOverflow="let hasOverflowedName=hasXOverflow"
        [ppcTooltip]="regionLevelDemographic?.name"
        [tooltipDisabled]="!hasOverflowedName"
        >
        {{regionLevelDemographic?.name}}
      </span>
      <span class="widget-type">
        <ppc-sort *ngIf="regionLevelDemographic.widget_type === chartTypes.BarHorizontal" [sortData]="barChartData"></ppc-sort>
      </span>
      <button class="fa fa-ellipsis-v menu-icon hoverable" mat-icon-button [matMenuTriggerFor]="appMenu" [ppcTooltip]="idCountTooltip"></button>
      <mat-menu #appMenu="matMenu">
        <button (click)="editDemo()" mat-menu-item>Edit</button>
        <button (click)="hideDemo()" mat-menu-item>Hide</button>
      </mat-menu>
    </div>

    <ng-container [ngSwitch]="regionLevelDemographic.widget_type">
        <ng-container *ngSwitchCase="chartTypes.Doughnut">
          <div class="grow-v3-donut-chart" [class.jumbo]="jumbo">
            <div class="donut-chart-container">
              <ppc-donut-chart [donutChartData]="donutChartData"></ppc-donut-chart>
            </div>
            <div class="legends">
              <div class="legend" *ngFor="let bucket of donutChartData; let i=index">
                <div class="legend-color">
                  <div class="background"  [style.background-color]="getColor(i)"></div>
                </div>
                <div class="legend-name" *ppcCheckOverflow="let hasXOverflow=hasXOverflow;" [ppcTooltip]="bucket.label"
                  [tooltipDisabled]="!hasXOverflow">
                  {{ bucket.label }}
                </div>
                <div class="legend-percent">
                  <div>
                    {{ bucket.countLabel }}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <ppc-bar-chart [data]="barChartData" colorOverride="rgba(130,202,208,0.8)"
            [vertical]="vertical" [class.vertical]="vertical" round="1.1-1" [displayCounts]="regionLevelDemographic.id_count !== 'percentage'"></ppc-bar-chart>
        </ng-container>
      </ng-container>

  </div>
