export const builderSlides =
  [
    {
      title: 'Journey - Design Journey',
      description: 'Add journey stages and brands to design the journey.',
      listDescription: "By creating your journey brands and stages within the builder, you'll be able to attach segments to these assets later.",
      image: 'assets/images/journey/Build_1.png',
      listItems: ['Main brand based on the product selected at the top of the screen.',
        'Click on the Add Brand text to add competitor brands.',
        'Click on the plus to add stages before or after existing stages.',
        'Click on the pencil icon to change the name of a stage.',
        'Click on the stage icon to select another icon to represent the stage.']
    },
    {
      title: 'Journey - Map Journey',
      description: 'Associate an audience with each brand and stage combination',
      listDescription: 'The mapped audiences and the numbers entered for audience population enable the full journey visualization in the Journey view.',
      image: 'assets/images/journey/Build_2.png',
      listItems: ['Click Insert in a cell to map an audience to each stage and brand combination.',
        'After all the cells are mapped, save and close the journey.'],
      note: 'The journey can still be viewed without mapping segments to each brand and stage, however, there may be missing data in some tabs or stages.'
    }
  ]

export const overviewSlides =
  [
    {
      title: 'Journey - View Overview',
      description: 'Identify trends between stages and brands with audiences',
      listDescription: 'Shown to the right is an example with five stages (only three showing) and two brands.',
      image: 'assets/images/journey/View_1.png',
      listItems: ['Selects the primary brand for the visualization - data for other brands are available in expandable panels.',
        'Both the circle size and the level in the gauge show the percentage and count per stage for the primary brand.',
        'Stage name and icon. Click the down arrow to display a panel showing the data for all brands.',
        'Transition data. Click the down arrow to see transition data for all brands.']
    },
    {
      title: 'Journey - View Details',
      description: 'Detailed information about stages and transitions for all brands',
      listDescription: 'Shown to the right is an example of the detailed information showing for one stage and one transition.',
      image: 'assets/images/journey/View_2.png',
      listItems: ['Stage Data. A panel showing information for one stage across multiple brands.',
        'Transition data. Shows transition data for all brands between two stages.']
    },
    {
      title: 'Journey - Calculations',
      description: 'Specific calculations are used to dedupe IDs and provide helpful transition percentages',
      listDescription: 'Shown to the right is an example of a Journey with stage and transition data.',
      image: 'assets/images/journey/View_3.png',
      listItems: ['Stage ID calculation.\nStages are deduped right to left. For example, IDs in stage 5 are part of stage 5.\nStage 4 includes the IDs in stage 4 minus IDs in stage 5.\nStage 3 includes the IDs in stage 3 minus IDs in stage 4 and 5.',
        'Transition calculation.\n((stage to the right minus stage to the left) divided by stage to the right) multiplied by 100']
    },
    {
      title: 'Journey - Accessing the Builder',
      description: 'Configure the Journey view with the Journey builder',
      listDescription: 'The Journey view is configured when the Journey is created or edited',
      image: 'assets/images/journey/View_4.png',
      listItems: ['Create a new Journey chart',
        'Edit an existing journey chart'],
      note: 'See the Learn More link in the builder for more detailed information on the Journey Builder'
    }
  ]
