
import {interval as observableInterval, Subscription, Subject} from 'rxjs';

import {map, filter, takeUntil, takeWhile} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {Observable} from "rxjs/Rx";
import {HierarchyService} from "app/services/hierarchy.service";
import {Plan} from "../../plans/plan.model";
import {Channel} from "../../plans/channel.model";
import {PlanService} from "../../plans/plan.service";
import { MatMenuTrigger } from "@angular/material/menu";
import {NAV_PLANS, PLANS_SUB_NAV_SCENARIOS, PLANS_SUB_NAV_CHANNELS} from "../../shared/utils/constants";
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers/index";
import {fullContext} from "app/hierarchy/hierarchy.reducers";
import {curveLabelOutput} from 'app/shared/utils/utils';
import { canAccessFeature } from 'app/feature-access/feature-access.reducers';
import { isDefined } from 'app/shared/utils/utils';
import { FullPermissions } from 'app/shared/interfaces/auth-permission';
import { tooltipMessageV2 as tooltipMessages } from 'app/audiences/shared/audience.constants';
import { UpsertPlan } from 'app/plans/plans.actions';

@Component({
  selector: 'app-plan-summary-card',
  templateUrl: './plan-summary-card.component.html',
  styleUrls: ['./plan-summary-card.component.sass']
})
export class PlanSummaryCardComponent implements OnInit, OnDestroy {
  // private metric = "sales";
  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;

  @Input() plan: Plan;
  @Input() show: boolean;
  @Input() openCreateDialog: (plan: Plan) => void;
  @Input() currencyCode: string;

  errorMsg: string;
  filteredChannels: Array<Channel>;
  colors = ["#29f597", "#e68dfc", "#0e39e8", "#b445fb", "#279acd", "#f98aaf", "#f7ca31", "#e14c51", "#50cbf9", "#25e135", "#bec7ce"];

  curveOverride: boolean = false;
  curveLabelOutput = curveLabelOutput;
  canAccessChannels: boolean;
  destroyed$ = new Subject();

  private userPermissions$: Observable<FullPermissions> = this.store.select('permissions');
  canDeleteScenario$ = this.userPermissions$.pipe(
    map(permission => permission.plans.destroy) );
  deleteDisabledTooltip = tooltipMessages['disabledDeleteNotUserPermissioned'];

  @Output() deletePlan: EventEmitter<Plan> = new EventEmitter();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private hierarchyService: HierarchyService,
    private planService: PlanService
  ) {
    this.destroyPlan = this.destroyPlan.bind(this);
    fullContext(this.store).pipe(takeUntil(this.destroyed$)).subscribe(
      ({client}) => this.curveOverride = client.curve_label_override
    );

    canAccessFeature(this.store, 'channels').pipe(filter(isDefined), takeUntil(this.destroyed$))
      .subscribe(
        canAccess => this.canAccessChannels = canAccess
      );
  }

  onSelectPlan(plan: Plan): void {
    this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_SCENARIOS, plan._id]);
  }

  onSelectChannel(plan: Plan): void {
    this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_CHANNELS, plan.channelId]);
  }

  destroyPlan(event): void {
    event.stopPropagation();
    if (!this.canDeleteScenario$) {return}

    if (!!this.plan._id) {
      this.planService.deletePlan(this.plan._id)
        .subscribe(
          data => {
            this.deletePlan.emit(this.plan);
          },
          error => {
            this.errorMsg = 'Something went wrong while deleting Plan';
            this.trigger.closeMenu();
          }
        )
    }
  }

  ngOnInit() {
    observableInterval(5000).pipe(takeUntil(this.destroyed$),
      takeWhile(() => this.plan.isPending))
      .subscribe(() => this.getPlanData())

    this.filteredChannels = this.plan.channels.sort(this.allocationShareComparator).slice(0, 5);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getPlanData() {
    if (this.plan.isPending) {
      this.planService.getPlan(this.plan._id)
        .subscribe(
          plan => {
            this.plan = plan;
            if (this.plan.isComplete) {
              this.store.dispatch(new UpsertPlan(plan));
              this.filteredChannels = this.plan.channels.sort(this.allocationShareComparator).slice(0, 5);
            }
          },
          error => {
            console.error(error);
          }
        );
    }
  }

  allocationShareComparator(a, b) {
    return  b.allocationShare - a.allocationShare;
  }

  planErrorMessage() {
    return this.plan.errorMessage
      ? (this.plan.errorMessage.indexOf('ScenarioPlannerBeta/api/', 0) > 0 ? "Something went wrong, please try again." :
        this.plan.errorMessage.slice(0, 250))
      : ""
  }

  isEditable() {
    return (this.plan.isComplete || this.plan.hasError);
  }

  gotoAllocationsPage() {
    if (this.plan.isComplete) {
      this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_SCENARIOS, this.plan._id]);
    }
  }

  get displayValues(): { computed: boolean, frequency: boolean} {
    const curveTypeName = (this.plan.curveTypeName || "").toLowerCase();
    const computed =  !curveTypeName.includes("sales");
    const frequency =  computed && curveTypeName.includes("trp");

    return {computed: computed, frequency: frequency}
  }
}
