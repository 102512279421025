import { Component, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { filter, sortBy } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppState } from 'app/reducers';
import { SubMarket, Market, selectActiveMekko, selectActiveMekkoSubMarkets } from 'app/mekko/mekko.reducer';

@UntilDestroy()
@Component({
  selector: 'ppc-measure-v3-stacked-container',
  templateUrl: './measure-v3-stacked-container.component.html',
  styleUrls: ['./measure-v3-stacked-container.component.sass']
})
export class MeasureV3StackedContainerComponent implements OnDestroy {
  subMarkets: SubMarket[];
  markets: Market[];
  groupBy = 'none';

  constructor(private store: Store<AppState>) {
    store.select("mekkos").pipe(
      select(selectActiveMekkoSubMarkets),
      untilDestroyed(this)
    ).subscribe(subMarkets => this.subMarkets = sortBy(subMarkets, subMarket => subMarket.name.toLowerCase()));

    store.select("mekkos").pipe(
      select(selectActiveMekko),
      untilDestroyed(this),
    ).subscribe(activeMekko => {
      this.markets = activeMekko.markets.map(market => {
        return {...market, sub_markets: filter(this.subMarkets, subMarket => subMarket.market_id == market.id)};
      })
    });
  }

  ngOnDestroy() {}

  setGroupBy(option: string) {
    this.groupBy = option;
  }
}
