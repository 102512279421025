<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>

<app-slide-in-overlay [width]="650" [active]="!!(regionDemographicUnderEdit$ | async)">
    <ppc-region-demographic-form></ppc-region-demographic-form>
</app-slide-in-overlay>

<ng-container *ngIf="areJourneysFetched$ | async">
    <ng-container *ngIf="(journeys$ | async)?.length">
        <div class="journey" *ngLet="{
          permissions: (permissions$ | async),
          filters: (filters$ | async)} as opts">
          <div class="journey-header-container">
              <div class="journey-header">
                  <div class="page-title">
                    <span class="category">Insights</span> Journey
                  </div>
                </div>
                <div class="journey-picker-row">
                  <ppc-searchable-dropdown #journeyPicker
                                          [items]="journeys$ | async"
                                          [(ngModel)]="selectedJourneyId"
                                          [allowClear]="false"
                                          itemLabelProp="name"
                                          mapFromProperty="id">
                      <ng-template #itemTemplate let-item="item">
                        <div class="journey-item" [class.selected]="selectedJourneyId === item.id">
                          <button class="single-select-item" (click)="onJourneySelect(item.id); journeyPicker.open = false"
                            *ppcCheckOverflow="let hasOverflow=hasXOverflow" [ppcTooltip]="item?.name" [tooltipDisabled]="!hasOverflow"
                            tabIndex="0">
                            <i class="fa fa-flag-o" [class.invisible]="!item.default"></i>{{ item?.name }}
                          </button>
                        </div>
                      </ng-template>
                    </ppc-searchable-dropdown>
                    <div class="journey-actions">
                      <ppc-icon-button icon="plus" ppcTooltip="Create" *ngIf="opts.permissions?.create" (click)="create()"></ppc-icon-button>
                      <ppc-icon-button icon="pencil" ppcTooltip="Edit" *ngIf="selectedJourneyId && opts.permissions?.update" (click)="edit()"></ppc-icon-button>
                      <ppc-icon-button icon="clone" ppcTooltip="Copy" *ngIf="selectedJourneyId && opts.permissions?.create" (click)="duplicate()"></ppc-icon-button>
                      <ppc-icon-button ppcConfirmation icon="trash" ppcTooltip="Delete" *ngIf="selectedJourneyId && opts.permissions?.destroy" [kontinue]="delete.bind(this)"></ppc-icon-button>
                    </div>
                </div>
          </div>
          <div class="journey-filter-bar">
            <ppc-filter-bar *ngIf="filters?.length"
                            [filters]="filters"
                            (clearFilters)="clearFilters()"
                            (toggleFilter)="toggleFilter($event)"></ppc-filter-bar>
          </div>
          <div class="journey-chart-container">
            <ppc-split-screen [showGutter]="true" leftLabel="Chart" rightLabel="Insights" (focusChange)="setSplitScreenFocus($event)" [focus]="splitScreenFocus$ | async">
              <ppc-journey-chart left></ppc-journey-chart>
              <ppc-insights right [segmentContexts$]="segmentContexts$" (topLevelTabChange)="changeTabs($event)"></ppc-insights>
            </ppc-split-screen>
          </div>
        </div>

    </ng-container>
    <ng-container *ngIf="!(journeys$ | async)?.length">
      <div class="no-journeys">
          <div class="no-journeys-container" *ngIf="(permissions$ | async)?.create">
              <h1>No Journey Charts</h1>
              <p>No journey charts have been created for this product. Click on the button below to create one.</p>
              <button mat-button class="create-button" (click)="create()">Create Journey</button>
            </div>
            <div class="no-journeys-container" *ngIf="!(permissions$ | async)?.create">
                <h1>No Journey Charts</h1>
                <p>No journey charts have been created for this product and you do not have permission to create one. Please check back later.</p>
              </div>
      </div>
    </ng-container>

    <app-slide-in-overlay [active]="!!(marketLevelDemographicUnderEdit$ | async)" [width]="800">
      <ppc-market-level-demographic-form *ngIf="marketLevelDemographicUnderEdit$ | async" [marketLevelDemographic]="marketLevelDemographicUnderEdit$ | async"></ppc-market-level-demographic-form>
    </app-slide-in-overlay>
</ng-container>
