import { Store } from "@ngrx/store";
import { AppState } from "app/reducers";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import {
  INSIGHTS_CONTEXT,
  InsightsContextType,
  ResourceType,
} from "../insights.constants";
import { Observable, of as observableOf } from "rxjs";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class InsightsResourceTracker implements OnDestroy {
  resourceType: ResourceType;
  resourceId$: Observable<number>;
  resourceId: number;

  constructor(
    public store: Store<AppState>,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType
  ) {
    switch (this.insightsContext) {
      case "grow":
        this.resourceId$ = this.store.select("mekkos", "selectedMekkoId");
        this.resourceType = "Mekko";
        break;
      case "compare":
        this.resourceId$ = this.store.select(
          "comparisons",
          "selectedComparisonId"
        );
        this.resourceType = "Comparison";
        break;
      case "journey":
        this.resourceId$ = this.store.select("journey", "selectedJourneyId");
        this.resourceType = "Journey";
        break;
      case "explore":
        this.resourceId$ = this.store.select("explore", "selectedPersonaId");
        this.resourceType = "Persona";
        break;
      case "outcome-audience":
        this.resourceId$ = this.store.select(
          "outcomeAudiences",
          "selectedOutcomeAudienceId"
        );
        this.resourceType = "Outcome Audience";
        break;
      default:
        this.resourceId$ = observableOf(null);
    }
    this.resourceId$
      .pipe(untilDestroyed(this))
      .subscribe((resourceId) => (this.resourceId = resourceId));
  }

  ngOnDestroy() {}
}
