import { Component, OnInit, Input, ElementRef, ViewChild, HostBinding } from '@angular/core';

@Component({
  selector: 'ppc-progress-bar',
  templateUrl: './ppc-progress-bar.component.html',
  styleUrls: ['./ppc-progress-bar.component.sass']
})
export class PpcProgressBarComponent implements OnInit {
  @ViewChild("labelRef") labelRef: ElementRef;
  @Input() percentage: number;
  @Input() label: string;
  @Input() vertical = false;
  @Input() round: string = '1.0-0';
  @Input() colorOverride: string;
  @Input() reverse: boolean;
  @Input() rotateText: boolean = false;
  @Input() count: number;
  @Input() countLabel: string;
  @Input() selected: boolean;
  @Input() inlineLabel: boolean = true;
  @Input() showCounts: boolean = false;
  @Input() hasZeroCountBool: boolean = false;

  @HostBinding("class.animate")
  @Input() animate = true;

  ready = false;
  constructor() { }

  ngOnInit() {
    if (this.animate) {
      setTimeout(() => {
        this.ready = true
      }, 200)
    } else {
      this.ready = true;
    }
  }

  getPercentage() {
    if (this.ready) {
      return this.percentage
    } else {
      return 0
    }
  }
}
