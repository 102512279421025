import {ActionReducer, ActionReducerMap, MetaReducer, Action, RuntimeChecks} from "@ngrx/store";

import {environment} from "environments/environment";
import * as fromCurrencies from "../currencies/currencies.reducers";
import * as fromHierarchy from '../hierarchy/hierarchy.reducers';
import * as fromPermissions from '../authorization/permissions/permissions.reducers';
import * as fromPlans from '../plans/plans.reducers';
import * as fromFetch from "../shared/utils/fetch-state";
import * as fromBulkFetch from "../shared/utils/bulk-fetch-state";
import * as fromAudiences from '../audiences/audiences.reducers';
import * as fromAudiencesV2 from 'app/audiences-v2/audience-v2.reducers';
import * as fromSegmentsV2 from 'app/segments-v2/segment-v2.reducers'
import * as fromCurves from '../plans/curves/curves.reducer';
import * as fromVendors from '../services/vendors/vendors.reducers';
import * as fromRegions from '../admin/region/region.reducers';
import * as fromUsers from '../users/user.reducer';
import * as fromHierarchyPermissions from '../hierarchy-permissions/hierarchy-permissions.reducer';
import * as fromCreatives from '../creative/creative.reducers';
import * as fromRoles from 'app/admin/role-admin/service/user-role.reducer';
import * as fromFeatureAccess from 'app/feature-access/feature-access.reducers';
import * as fromToolbox from 'app/toolbox/toolbox.reducers';
import * as fromGrow from 'app/insights/grow-v3/grow.reducer';
import * as fromSnapshots from 'app/snapshots/snapshots.reducer';
import * as fromComparisons from 'app/comparisons/comparisons.reducer';
import * as fromMeasureV3 from 'app/measure-v3/measure-v3.reducer';
import * as fromLookalikesV3 from 'app/lookalikes-v3/lookalike-v3.reducers';
import * as fromLookalikes from 'app/lookalikes-v2/lookalike-v2.reducers';
import * as fromApiKeys from 'app/admin/api-keys/api-keys.reducer';
import * as fromDataPermissions from 'app/data_permissions/data_permissions.reducers';
import * as fromMekkos from 'app/mekko/mekko.reducer';
import * as fromInsights from 'app/insights/insights.reducer';
import * as fromJourneys from 'app/journey/journey.reducer';
import * as fromDiscussions from 'app/insights/insights-components/market-level-discussions/discussion.reducer';
import * as fromExplore from 'app/explore/explore.reducer';
import * as fromPartners from 'app/models/partners/partner.reducer';
import * as fromIndexReports from 'app/index-report/index-report.reducer';
import * as fromAddressableCountMetadata from 'app/models/addressable-count-metadata/addressable-count-metadata.reducers'
import * as fromSegmentsHierarchy from 'app/segments-hierarchy/segments-hierarchy.reducer';
import * as fromOutcomeAudiences from 'app/outcome-audience/outcome-audience.reducer';
import * as fromOutcomesV1 from 'app/outcomes-v1/outcomes-v1.reducers';
import * as fromMotivationsV1 from 'app/models/motivations/motivations-v1.reducer';
import * as fromAudienceBuilder from 'app/audience-builder/audience-builder.reducer';
import * as fromClientAdmin from 'app/admin/client/client-admin/client-admin.reducer';
import * as fromDestinationManagement from 'app/admin/destination-management/destination-management.reducer';
import * as fromActivationManagement from 'app/admin/activation-management/activation-management.reducer';
import { StoreConfig } from "@ngrx/store/src/store_module";

export interface AppState {
  currencies: fromCurrencies.State;
  hierarchy: fromHierarchy.State;
  permissions: fromPermissions.State;
  plans: fromPlans.State;
  audiences: fromAudiences.State;
  audiencesV2: fromAudiencesV2.State;
  lookalikesV3: fromLookalikesV3.State;
  lookalikes: fromLookalikes.State;
  outcomesV1: fromOutcomesV1.State;
  motivationsV1: fromMotivationsV1.State;
  segmentsV2: fromSegmentsV2.State;
  segmentsHierarchy: fromSegmentsHierarchy.State;
  dataPermissions: fromDataPermissions.State;
  fetchStates: fromFetch.MetaFetchState;
  bulkFetchStates: fromBulkFetch.MetaBulkFetchState;
  curves: fromCurves.State;
  vendors: fromVendors.State;
  regions: fromRegions.State;
  users: fromUsers.State;
  hierarchyPermissions: fromHierarchyPermissions.State;
  creative: fromCreatives.State;
  roles: fromRoles.State;
  featureAccess: fromFeatureAccess.State;
  toolbox: fromToolbox.State;
  grow: fromGrow.State;
  snapshots: fromSnapshots.State;
  comparisons: fromComparisons.State;
  measureV3: fromMeasureV3.State;
  apiKeys: fromApiKeys.State;
  mekkos: fromMekkos.State;
  insights: fromInsights.MetaInsightsState;
  journey: fromJourneys.State;
  discussions: fromDiscussions.State;
  explore: fromExplore.State;
  partners: fromPartners.State;
  indexReports: fromIndexReports.State;
  addressableCountsMetadata: fromAddressableCountMetadata.State;
  outcomeAudiences: fromOutcomeAudiences.State;
  audienceBuilder: fromAudienceBuilder.State;
  clientAdmin: fromClientAdmin.State;
  destinationManagement: fromDestinationManagement.State;
  activationManagement: fromActivationManagement.State;
}

export const reducers: ActionReducerMap<AppState> = {
  currencies: fromCurrencies.reducer,
  hierarchy: fromHierarchy.reducer,
  permissions: fromPermissions.reducer,
  plans: fromPlans.reducer,
  audiences: fromAudiences.reducer,
  audiencesV2: fromAudiencesV2.reducer,
  lookalikesV3: fromLookalikesV3.reducer,
  lookalikes: fromLookalikes.reducer,
  outcomesV1: fromOutcomesV1.reducer,
  motivationsV1: fromMotivationsV1.reducer,
  segmentsV2: fromSegmentsV2.reducer,
  segmentsHierarchy: fromSegmentsHierarchy.reducer,
  dataPermissions: fromDataPermissions.reducer,
  fetchStates: fromFetch.fetchMetaReducer,
  bulkFetchStates: fromBulkFetch.bulkFetchMetaReducer,
  curves: fromCurves.reducer,
  vendors: fromVendors.reducer,
  regions: fromRegions.reducer,
  users: fromUsers.reducer,
  hierarchyPermissions: fromHierarchyPermissions.reducer,
  creative: fromCreatives.reducer,
  roles: fromRoles.reducer,
  featureAccess: fromFeatureAccess.reducer,
  toolbox: fromToolbox.reducer,
  grow: fromGrow.reducer,
  snapshots: fromSnapshots.reducer,
  comparisons: fromComparisons.reducer,
  measureV3: fromMeasureV3.reducer,
  apiKeys: fromApiKeys.reducer,
  mekkos: fromMekkos.reducer,
  insights: fromInsights.metaReducer,
  journey: fromJourneys.reducer,
  discussions: fromDiscussions.reducer,
  explore: fromExplore.reducer,
  partners: fromPartners.reducer,
  indexReports: fromIndexReports.reducer,
  addressableCountsMetadata: fromAddressableCountMetadata.reducer,
  outcomeAudiences: fromOutcomeAudiences.reducer,
  audienceBuilder: fromAudienceBuilder.reducer,
  clientAdmin: fromClientAdmin.reducer,
  destinationManagement: fromDestinationManagement.reducer,
  activationManagement: fromActivationManagement.reducer,
};

// Just a demonstration of root meta-reducers, not really necessary with the redux devtools extension
export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function(state: AppState, action: any): AppState {
    if (environment.envName !== 'prod') {
      console.log('state', state);
      console.log('action', action);
    }

    return reducer(state, action);
  };
}

// https://ngrx.io/guide/store/configuration/runtime-checks
export const runtimeChecks: Partial<RuntimeChecks> = {
  // TODO: tech-debt, immutability checks should be enabled to follow best practices
  // See also: https://github.com/ngrx/platform/issues/2404
  strictStateImmutability: false,
  strictActionImmutability: false,
}

export const metaReducers: MetaReducer<AppState>[] = []; // [logger]; change to this to demonstrate logger meta-reducer

export const storeConfig: StoreConfig<AppState, Action> = {
  metaReducers: metaReducers,
  initialState: {}
}
