import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-route-not-found',
  templateUrl: './route-not-found.component.html',
  styleUrls: ['./route-not-found.component.sass']
})
export class RouteNotFoundComponent {

  constructor(public router: Router) { }
}
