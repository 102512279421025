<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
  <h1><span class="object-name">Insights Tab Admin</span></h1>
  <p>Note: Changes made here may require a page refresh to take effect.</p>
</header>

<div class="admin-container">
  <ngx-datatable [rows]="tableData" [rowHeight]="'100%'" columnMode="flex" [headerHeight]="40" [footerHeight]="100" [reorderable]=false sortType="multiple" [sorts]="tableSorts">

    <ngx-datatable-column name="Insights Context" prop="insights_context" [flexGrow]="1" headerClass="text" cellClass="text" [resizeable]="false">
    </ngx-datatable-column>

    <ngx-datatable-column name="Parent" prop="parent_name" [flexGrow]="1" headerClass="text" cellClass="text" [resizeable]="false">
    </ngx-datatable-column>

    <ngx-datatable-column name="Name" prop="name" [flexGrow]="1" headerClass="text" cellClass="text" [resizeable]="false">
    </ngx-datatable-column>

    <ngx-datatable-column name="Excluded Regions" prop="excluded_regions" [flexGrow]="2.5" headerClass="text" cellClass="text" [resizeable]="false">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span class="cell-content-overflow">
          {{ value }}
        </span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Included Regions" prop="included_regions" [flexGrow]="2.5" headerClass="text" cellClass="text" [resizeable]="false">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span class="cell-content-overflow">
          {{ value }}
        </span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="" [flexGrow]="0.5" cellClass="amount" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <button mat-icon-button (click)="editTab(row)">
          <i class="fa fa-pencil"></i>
        </button>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</div>

<ppc-dialog>
  <div header>Edit Tab</div>
  <div body *ngIf="tabUnderEdit">
    <mat-form-field>
      <input matInput [(ngModel)]="tabUnderEdit.name" disabled placeholder="Name" />
    </mat-form-field>
    <mat-form-field>
      <input matInput [(ngModel)]="tabUnderEdit.insights_context" disabled placeholder="Insights Context" />
    </mat-form-field>
    <mat-form-field>
      <mat-select [(value)]="tabUnderEdit.excluded_regions" multiple placeholder="Excluded Regions">
        <mat-option *ngFor="let region of regions" [value]="region.slug">{{region.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div footer>
    <button mat-button (click)="saveTab()">Save</button>
    <button mat-button (click)="cancelEdit()">Cancel</button>
  </div>
</ppc-dialog>
