<div class="top-scrollbar" *ngIf="segmentBrowser && segmentBrowser.browserLevels">
  <div [style.width.px]="segmentBrowser.browserLevels.nativeElement.scrollWidth"></div>
</div>
<ppc-segment-browser #segmentBrowser [root]="root$ | async" insightsContext="builder"
  [showHeaders]="false">
  <ng-template #leaf let-node="node">
    <div class="drop-list" cdkDropList [cdkDropListData]="[node]"
      [cdkDropListConnectedTo]="dragService.allDropLists$ | async">
      <div class="audience-builder-group-segment" cdkDrag [cdkDragData]="getDragData(node)" (cdkDragEntered)="dragEntered($event)"
        (cdkDragStarted)="dragStarted($event, node)" (cdkDragDropped)="dragDropped($event)"
        [cdkDragDisabled]="!canSelectSegment(node)" [ppcTooltip]="dragInstructions"
        [tooltipDisabled]="hideDragInstructionsTooltip" [tooltipDelayTime]="0" [class.disabled]="!canSelectSegment(node)"
        [class.multi-selected]="nodeIsSelected(node)" (click)="multiSelectNode(node)">
        <div class="checkbox-container" *ngIf="canSelectSegment(node)">
          <i class="fa fa-lg" [ngClass]="nodeIsSelected(node) ? 'fa-check-square' : 'fa-square-o'"></i>
        </div>
        <div class="drag-icon" [class.disabled]="!canSelectSegment(node)">
          <i class="fa fa-ellipsis-v" *ngFor="let i of [1,2]"></i>
        </div>
        <div class="segment-path">{{node.name}}</div>
        <div class="segment-count node-data-column">
          <div class="node-data-column-header">People Count</div>
          <div class="node-data-column-number">
            <ppc-zero-count-warning-icon *ngIf="getCount(node, 'matched') === 0"></ppc-zero-count-warning-icon>
            {{ getCount(node, "matched" ) | number }}
          </div>
        </div>
        <div class="audience-builder-group-segment" *cdkDragPreview
          [style.width.px]="dragService.hoveredDropListWidth$ | async">
          <div class="drag-icon">
            <i class="fa fa-ellipsis-v" *ngFor="let i of [1,2]"></i>
          </div>
          <div class="segment-path">{{node.name}}</div>
          <div class="segment-count">
            <div class="node-data-column-number">{{ getCount(node, "matched" ) | number }}</div>
          </div>
          <div class="selected-node-count-badge" *ngIf="hasMultipleSelectedNodes(node)">
            {{ selectedNodes.length }}
          </div>
        </div>
      </div>

    </div>
  </ng-template>
  <ng-template #parent let-node="node">
    <div class="category" (click)="clearSelectedNodes()">{{node.name}}</div><i class="fa fa-refresh fa-spin loading-children-indicator"
      *ngIf="node.loadingChildren"></i>
  </ng-template>
</ppc-segment-browser>
