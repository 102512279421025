import {combineLatest as observableCombineLatest, Subject, merge as observableMerge} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Injectable, Inject, OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from "@ngrx/store";
import { flatMap, compact, map as _map, sumBy, get } from 'lodash';
import { GrowTabState, Barbs } from 'app/insights/grow-v3/grow.reducer';

import { selectRegion } from 'app/hierarchy/hierarchy.reducers';
import { SubMarket, Mekko, selectActiveMekko, selectSubMarketsByMarketId, selectSelectedSubMarkets } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType } from '../insights.constants';
import { InsightsCountService } from '../insights-count.service';
import { marketAggregationKey } from './grow.utils';
import { environment } from 'environments/environment';
import { isCompareMode } from '../insights.reducer';
import { PersonLevelCompareService } from '../insights-components/person-level-compare/person-level-compare.service';

export interface SegmentSearchResponse {
  segment_counts: {[shortId: string]: number},
  group_counts: {[shortId: string]: number},
  market_counts: {
    matched: {[marketId: number]: number},
    modeled: {[marketId: number]: number}
  },
  total_matched_count: number;
  total_modeled_count: number;
}

@Injectable()
export class GrowCountService implements OnDestroy {

  public growTabState: GrowTabState;
  public selectedSubMarkets: SubMarket[];
  subMarketsByMarketId: {[marketId: number]: SubMarket[]};
  mekko: Mekko;
  segmentCounts: {[shortId: string]: number};
  public totalMatchedCount: number = 0;
  public totalModeledCount: number = 0;
  personLevelTab: string;
  barbs: Barbs;
  isTier3: boolean = environment.isTier3;
  ngUnsubscribe$ = new Subject();
  region: string;
  isCompareMode: boolean;

  constructor(private store: Store<AppState>,
    private http: HttpClient,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType,
    private insightsCounts: InsightsCountService,
    private compareService: PersonLevelCompareService) {

    const mekko$ = store.select("mekkos").pipe(select(selectActiveMekko), filter(Boolean));

    const subMarkets$ = store.select("mekkos").pipe(select(selectSubMarketsByMarketId));

    const selectedSubMarkets$ = store.select("mekkos").pipe(select(selectSelectedSubMarkets));

    const growTabState$ = store.select("grow", "growTabState");

    const regionSlug$ = store.select('hierarchy').pipe(select(selectRegion));

    const personLevelTab$ = store.select("insights", this.insightsContext, "personLevelTab");

    const barbs$ = store.select("grow", "barbs");

    const isCompareMode$ = store.select("insights", "grow").pipe(select(isCompareMode))

    const countsChanged$ = observableMerge(this.insightsCounts.countsChanged$, this.compareService.countsChanged$)

    observableCombineLatest([
      countsChanged$,
      mekko$,
      subMarkets$,
      selectedSubMarkets$,
      growTabState$,
      personLevelTab$,
      barbs$,
      regionSlug$,
      isCompareMode$,
    ]).pipe(
      takeUntil(this.ngUnsubscribe$),
    ).subscribe((
      [countData, mekko, subMarkets, selectedSubMarkets, growTabState, personLevelTab, barbs, regionSlug, isCompareMode]: [ any, Mekko, {marketId: SubMarket[]}, SubMarket[], GrowTabState, string, Barbs, string, boolean]) => {
      this.segmentCounts = countData
      this.mekko = mekko;
      this.subMarketsByMarketId = subMarkets;
      this.selectedSubMarkets = selectedSubMarkets;
      this.growTabState = growTabState;
      this.personLevelTab = personLevelTab;
      this.barbs = barbs
      this.region = regionSlug
      this.isCompareMode = isCompareMode
    })
    this.insightsCounts.countsChanged$.pipe(
      takeUntil(this.ngUnsubscribe$)).subscribe(() => this.setTotalCounts())
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  setTotalCounts() {
    switch (this.growTabState) {
      case "Total Population":
        this.totalMatchedCount = get(this.segmentCounts, ["matched", "total_count"], 0)
        this.totalModeledCount = get(this.segmentCounts, ["modeled", "total_count"], 0)

        if (this.isPanelistCount) {
          this.totalMatchedCount  = this.barbTransform(this.totalMatchedCount)
          this.totalModeledCount = this.barbTransform(this.totalModeledCount)
        }

        return;
      case "Matched Addressable":
        this.totalMatchedCount = get(this.segmentCounts, ["primary", "total_count"], 0)
        this.totalModeledCount = get(this.segmentCounts, ["modeled", "total_count"], 0)

        if (this.isPanelistCount) {
          this.totalMatchedCount  = this.barbTransform(this.totalMatchedCount)
          this.totalModeledCount = this.barbTransform(this.totalModeledCount)
        }

        return;
      case "Modeled Addressable":
        this.totalMatchedCount = get(this.segmentCounts, ["matched", "total_count"], 0)
        this.totalModeledCount = get(this.segmentCounts, ["primary", "total_count"], 0)

        if (this.isPanelistCount) {
          this.totalMatchedCount  = this.barbTransform(this.totalMatchedCount)
          this.totalModeledCount = this.barbTransform(this.totalModeledCount)
        }

        return;
    }
  }

  subMarketCount(subMarket: SubMarket, countType: GrowTabState, tvTransform = true) {
    switch (countType) {
      case "Total Population":
        if (tvTransform && this.isPanelistCount) {
          return this.barbTransform(subMarket.population);
        } else {
          return subMarket.population;
        }
      case "Matched Addressable":
        if (tvTransform && this.isPanelistCount) {
          return this.barbTransform(this.getSegmentCount(subMarket.matched_short_id));
        } else {
          return this.getSegmentCount(subMarket.matched_short_id);
        }
      case "Modeled Addressable":
        const shortId = this.isTier3 ? subMarket.matched_short_id : subMarket.modeled_short_id;

        if (tvTransform && this.isPanelistCount) {
          return this.barbTransform(this.getSegmentCount(shortId))
        } else {
          return this.getSegmentCount(shortId);
        }
    }
  }

  getSegmentCount(shortId: string): number {
    if (this.isCompareMode) {
      return this.compareService.getCount(shortId, "total_count");
    } else {
      return get(this.segmentCounts, ["primary", shortId], 0);
    }
  }

  get mekkoSubMarkets() {
    return compact(flatMap(this.mekko.markets, market => this.subMarketsByMarketId[market.id]));
  }

  barbTransform(number) {
    let output = (this.barbs.n_pids * number) + ((this.barbs.n_pids ** 2) * this.barbs.n_pids_sqr);
    if (number > 0) {
      output += this.barbs.const;
    }

    if (this.barbs.maximum && output > this.barbs.maximum) {
      output = this.barbs.maximum;
    }

    return Math.round(output);
  }

  get totalPopulation() {
    if (this.isPanelistCount && this.barbs.maximum && this.getSum("Total Population") > this.barbs.maximum) {
      return this.barbs.maximum;
    }

    return this.getSum('Total Population');
  }

  getMarketCount(marketId: number) {
    let marketCount;
    if (this.growTabState == "Total Population") {
      marketCount = sumBy(this.subMarketsByMarketId[marketId], "population") || 0;
    } else {
      marketCount = this.insightsCounts.getCountByKeys(["primary", marketAggregationKey(marketId)]);
    }

    if (this.isPanelistCount) {
      return this.barbTransform(marketCount);
    } else {
      return marketCount;
    }
  }

  getSum(countType: GrowTabState) {
    if (!this.mekko || !this.selectedSubMarkets || !this.subMarketsByMarketId) { return; }
    const sum = sumBy(this.selectedSubMarkets.length ? this.selectedSubMarkets : this.mekkoSubMarkets,
      subMarket => this.subMarketCount(subMarket, countType, false));

    if (this.isPanelistCount) {
      return this.barbTransform(sum);
    } else {
      return sum;
    }
  }

  get isPanelistCount(): boolean {
    return this.mekko && this.mekko.count_type === 'tv_panelists';
  }

  checkMinimumTvCount() {
    return this.isPanelistCount && this.barbs && this.barbs.minimum;
  }

  isBelowTvCountMinimum(count: number): boolean {
    return this.checkMinimumTvCount() && this.barbs && (count < this.barbs.minimum);
  }
}
