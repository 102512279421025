import {Component} from '@angular/core';
import {ResetPasswordForm, UserService} from "../users/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {values} from "app/shared/utils/utils";

@Component({
  selector: 'ppc-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../confirm-account/confirm-account.component.sass']
})
export class ResetPasswordComponent  {

  form: ResetPasswordForm = {
    user: {
      reset_password_token: '',
      password: '',
      password_confirmation: ''
    }
  };

  errors: any = {};

  constructor(private route: ActivatedRoute,
    private userService: UserService,
    private router: Router) {
    const { resetPasswordToken } = this.route.snapshot.params;
    this.form.user.reset_password_token = resetPasswordToken;
  }

  submit(e) {
    e.preventDefault();
    this.userService.resetPassword(this.form)
      .subscribe(
        () => this.router.navigate(['/login'], { queryParams: { passwordReset: true } }),
        res => this.errors = res.error.errors
      )
  }

  arePasswordsValid(): boolean {
    const { password, password_confirmation } = this.form.user;
    return password.length > 5 && password === password_confirmation
  }

  getErrorMessages(): any[] {
    return values(this.errors)
  }
}
