import {Subject, throwError as observableThrowError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "app/services/authentication.service";
import {apiUrl} from "app/services/ppc_http.service";
import {UserService} from "app/users/user.service";
import {environment} from "environments/environment";


@Component({
  selector: 'ppc-legacy-login-form',
  templateUrl: './legacy-login-form.component.html',
  styleUrls: ['./legacy-login-form.component.sass']
})
export class LegacyLoginFormComponent implements OnInit, OnDestroy {
  model: any = {};
  loading: boolean = false;
  error: string = '';
  wasPasswordReset: boolean = false;
  lionLoginUrl: string;
  returnUrl: string;

  ngUnsubscribe = new Subject();

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.model.username, this.model.password).pipe(
      catchError(() => {
        this.error = 'Username or password is incorrect';
        this.loading = false;
        return observableThrowError('Login Failure')
      }))
      .subscribe(
        () => this.router.navigateByUrl(this.returnUrl),
        console.error
      )
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
