import {filter} from 'rxjs/operators';
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import { PpcHttpService } from "app/services/ppc_http.service";
import {Observable} from "rxjs";
import {HierarchyClient} from "app/hierarchy/hierarchy.interface";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {FetchHierarchy, SaveClient} from "app/hierarchy/hierarchy.actions";
import {CompleteFetch, fetchResource} from "app/shared/utils/fetch-state";
import { clientsUrl } from '../../shared/constants/clients.urls';

@Injectable()
export class ClientService {

  @Effect()
  saveClient$ = this.actions$.pipe(
    ofType(SaveClient.type),
    (
      fetchResource(
        ({ client }) => this.saveClient(client)
      )
    ));

  @Effect()
  clientSaved$ = this.actions$.pipe(
    ofType(CompleteFetch.type),
    filter(({ fetchAction }: CompleteFetch) => fetchAction.type === SaveClient.type),
    map(() => new FetchHierarchy()), );

  constructor(private http: PpcHttpService, private actions$: Actions) {}

  saveClient(client: HierarchyClient): Observable<HierarchyClient> {
    const method = client.id ? "put" : "post";
    const url = client.id ? `${clientsUrl()}/${client.id}` : clientsUrl();
    const payload = { client: client };
    return this.http[method](url, payload)
  }
}
