import { map, filter } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest as observableCombineLatest, of as ObservableOf } from 'rxjs';
import { Actions } from "@ngrx/effects";
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import * as actions from 'app/outcome-audience/outcome-audience.actions';
import { OutcomeAudience, selectActiveOutcomeAudience, selectOutcomeAudiences } from "app/outcome-audience/outcome-audience.reducer";
import * as insightsActions from 'app/insights/insights.actions';
import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType, SegmentContexts } from "app/insights/insights.constants";
import { InsightsCountService } from "app/insights/insights-count.service";
import { fetchIfUnfetched, isFetchInFlight } from 'app/shared/utils/fetch-state';
import { buildUrlRelative, isDefined } from "app/shared/utils/utils";
import { Filter } from "app/insights/insights.models";
import { GrowCountService } from 'app/insights/grow-v3/grow-count.service';
import { JourneyCountService } from "../journey/journey-count.service";
import { PersonLevelCompareService } from '../insights/insights-components/person-level-compare/person-level-compare.service';
import { OverlapInsightsExportService } from 'app/insights/insights-components/insights-export/overlap-insights-export.service';
import { InsightsExportService } from 'app/insights/insights-components/insights-export/insights-export.service';
import { Go } from 'app/router/router.actions';

@UntilDestroy()
@Component({
  selector: 'ppc-outcome-audience',
  templateUrl: './outcome-audience.component.html',
  styleUrls: ['./outcome-audience.component.sass'],
  providers: [
    {
      provide: INSIGHTS_CONTEXT,
      useValue: "outcome-audience"
    },
    InsightsCountService,
  ]
})

export class OutcomeAudienceComponent implements OnInit, OnDestroy {
  outcomeAudiences$ = this.store.select("outcomeAudiences").pipe(select(selectOutcomeAudiences), filter(isDefined));
  activeOutcomeAudience$ = this.store.select("outcomeAudiences").pipe(select(selectActiveOutcomeAudience));
  loading$ = observableCombineLatest(
    this.counts.loadingCounts$,
    this.store.select("fetchStates", insightsActions.FetchDemographics.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", insightsActions.FetchDemographicsConfig.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.FetchOutcomeAudiences.type).pipe(select(isFetchInFlight)),
  ).pipe(map(loadingStates => loadingStates.some(Boolean)));

  segmentContexts$: Observable<SegmentContexts> = observableCombineLatest(
    this.store.select("insights", this.insightsContext, "filters"),
    this.activeOutcomeAudience$
  ).pipe(
    map(([filters, activeOutcomeAudience]) => {
      if (!activeOutcomeAudience.id) { return {}; }
      return {
        primary: {
          or: [{
            include: [[activeOutcomeAudience.identifier]]
          }],
          isModeled: false,
        },
        secondary: {}
      };
    })
  );
  selectedFilters: Filter[];
  selectedOutcomeAudienceId: number;
  activeOutcomeAudience: OutcomeAudience;
  outcomeAudienceFormOpen = false;
  canCreateOutcomeAudience$ = this.store.select("permissions", "outcome_audiences", "create");

  constructor(
    public store: Store<AppState>,
    public counts: InsightsCountService,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType,
    private actions$: Actions,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    fetchIfUnfetched(this.store, new actions.FetchOutcomeAudiences(), this);

    observableCombineLatest(
      observableCombineLatest(
        this.store.select("outcomeAudiences", "selectedOutcomeAudienceId").pipe(filter(isDefined)),
        this.store.select("outcomeAudiences", "outcomeAudiences")
      ).pipe(
        map(([selectedOutcomeAudienceId, outcomeAudiences]) => outcomeAudiences[selectedOutcomeAudienceId] ? outcomeAudiences[selectedOutcomeAudienceId] : null)
      ),
      this.route.params
    ).pipe(
      untilDestroyed(this)
    ).subscribe(([selectedOutcomeAudience, params]: [OutcomeAudience, Params]) => {
      this.selectedOutcomeAudienceId = selectedOutcomeAudience && selectedOutcomeAudience.id;
      this.store.dispatch(new Go({path: buildUrlRelative(params, `insights/explore-outcome-audiences${this.selectedOutcomeAudienceId ? "/" + this.selectedOutcomeAudienceId : ""}`)}));
    });

    this.counts.filters$.pipe(untilDestroyed(this)).subscribe(filters => {
      this.selectedFilters = filters || [];
    });

    this.activeOutcomeAudience$.pipe(untilDestroyed(this)).subscribe(outcomeAudience => this.activeOutcomeAudience = outcomeAudience);
  }

  ngOnDestroy() {}

  toggleOutcomeAudienceForm() {
    this.outcomeAudienceFormOpen = !this.outcomeAudienceFormOpen;
  }

  clearFilters() {
    this.selectedFilters.forEach(f => {
      if (f.type === 'audience') { return };

      this.store.dispatch(new insightsActions.ToggleFilter(f, this.insightsContext));
    });
  }

  toggleFilter(toggledFilter: Filter) {
    if (toggledFilter.type === 'audience') { return };

    this.store.dispatch(new insightsActions.ToggleFilter(toggledFilter, this.insightsContext));
  }

  onOutcomeAudienceChange(outcomeAudience: OutcomeAudience) {
    if (!outcomeAudience) { return };
    this.store.dispatch(new actions.SetSelectedOutcomeAudience(outcomeAudience));
  }
}
