import { Theme, COLORS } from './symbols';

export const lightTheme: Theme = {
  name: 'light',
  properties: {
    // COLORS
    '--background': COLORS.white,
    '--on-background': COLORS.black,
    '--primary': COLORS.pictonBlue,
    '--primary-alt': COLORS.curiousBlue,
    '--primary-alt-2': COLORS.seagull,
    '--on-primary': COLORS.white,
    '--secondary': COLORS.window,
    '--on-secondary': COLORS.pictonBlue,
    '--tertiary': COLORS.gallery,
    '--on-tertiary': COLORS.curiousBlue,
    '--accent': COLORS.diSierra,
    '--disabled': COLORS.mercury,
    '--on-disabled': COLORS.gray,
    '--line': `1px solid ${COLORS.alto}`,
    // MISC
    '--ppc-logo-svg-path': 'url(/assets/images/ppc_logo/ppc_logo_black.svg)',
    // COMPONENT SPECIFIC
    // naming convention (follow sass hierarchy with _)
    // class_(subclass|psuedo)_css-property
    '--pds-tab-group-header-window_border-color': COLORS.alto,
    '--pds-tab-group-header-tab_color': COLORS.gray,
    '--pds-tab-group-header-tab_hover_border-color': COLORS.mercury,
    '--pds-tab-group-header-tab_selected_color': COLORS.black,
    '--pds-tab-group-header-tab_selected_border-color': COLORS.black,
  }
};
