<div class="widget">
  <div class="widget-title">
    Top Domains
    <span class="select-top">
      <select (change)="onCountChange($event.target.value)" matNativeControl>
        <option *ngFor="let countOption of countOptions" [value]="countOption">Top {{countOption}}</option>
      </select>
      <span class="entry-date" [ppcTooltip]="'Date of last data file received'" *ngIf="topDomains?.length">
        {{topDomains[0]?.entry_date | date}}
      </span>
    </span>
  </div>
  <div class="widget-bucket">
    <div class="widget-bucket-children">
      <div class="widget-child-bucket">
        <div class="widget-child-bucket-label"
             *ngFor="let vertical of verticals;"
             [ngClass]="{'bold': vertical.key === activeVertical}">
          <div class="vertical-center" (click)="setVertical(vertical.key)">
            {{vertical.display}}
          </div>
        </div>
      </div>
      <div class="widget-bucket-domain-children" *ngIf="topDomains?.length">
        <div class="widget-child-domain" *ngFor="let domain of topDomains;">
          <div class="widget-child-bucket-label">
            <div class="vertical-center">
              <span class="count">{{prettyCount(domain.count)}}</span>
              <span class="name">{{domain.name}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="unavailable-data" *ngIf="!topDomains?.length">
        Data is unavailable for this vertical.
      </div>
    </div>
  </div>
</div>
