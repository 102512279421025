import {filter, map, take, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, Inject} from '@angular/core';
import {Store, select} from "@ngrx/store";
import { Actions } from "@ngrx/effects";

import { fetchOutcome } from "app/shared/utils/fetch-state";
import {AbstractControl, FormBuilder, Validators} from "@angular/forms";
import { Observable, Subject} from "rxjs";
import { MekkoColors } from 'app/insights/grow-v3/grow.constants';
import {filter as _filter, capitalize, compact, keyBy, find, flatMap, orderBy, values, get} from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { SegmentPickerService } from 'app/segment-picker/segment-picker.service';
import { prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { Mekko, SubMarket, isVerticalMekko, selectActiveMekko } from 'app/mekko/mekko.reducer';
import * as mekkoActions from 'app/mekko/mekko.actions';
import { FetchSnapshots } from 'app/snapshots/snapshots.actions';
import { AppState } from 'app/reducers';
import { NAV_ADMIN, NAV_ADMIN_UPLOAD, NAV_ADMIN_DISCUSSIONS_UPLOAD } from "app/shared/utils/constants";
import {INSIGHTS_CONTEXT, INSIGHTS_SUB_FEATURE_WEBORAMA, InsightsContextType} from 'app/insights/insights.constants';
import { Snapshot } from 'app/snapshots/snapshots.reducer';
import { environment } from 'environments/environment';
import { SegmentLike } from '../../../models/segment-like.model';
import {canAccessFeature} from "../../../feature-access/feature-access.reducers";
import {getStoreState} from 'app/shared/utils/utils'
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';

@Component({
  selector: 'ppc-sub-market-form',
  templateUrl: './sub-market-form.component.html',
  styleUrls: ['./sub-market-form.component.sass']
})

export class SubMarketFormComponent implements OnDestroy, OnInit {
  subMarket: SubMarket;
  subMarketNames: string[];
  mekkoColors = MekkoColors;
  savedSubMarketName = '';
  subMarketFormGroup = this.fb.group({
    name:       ['', [blankValidator, uniqueNameValidator.bind(this)]],
    population: ['', [Validators.pattern('^[0-9]*$'), Validators.min(0)]]
  });
  ngUnsubscribe = new Subject();
  segments: {[identifier: string]: SegmentLike} = {};
  isTier3 = environment.isTier3;
  isVerticalMekko$ = this.store.select("mekkos").pipe(select(isVerticalMekko));
  canDestroyMekkos$ = this.store.select('permissions', 'mekkos', 'destroy');
  marketLevelOnly$ = this.store.select("insights", "grow", "tabs").pipe(
    map(tabs => !find(tabs, {tab_key: "top_level_person"}))
  )
  originalMatchedShortId: string;
  isInSnapshotModel: boolean;
  activeMekko: Mekko;
  hasWeboramaAccess$: Observable<boolean>;
  vendorDisplayNames: VendorDisplayName[];
  clusterNodes$ = this.store.select("discussions", "clusterNodes").pipe(
    map(nodes => _filter(orderBy(nodes, node => node.name.toLowerCase()), node => node.discussion_clusters && node.discussion_clusters.length)));

  constructor(public store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private segmentPickerService: SegmentPickerService,
    private segmentV2Service: SegmentV2Service,
    private fb: FormBuilder,
    private actions$: Actions,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {
  }

  ngOnInit() {
    this.store.select("mekkos", "subMarketUnderEdit").pipe(
      filter(Boolean), takeUntil(this.ngUnsubscribe))
      .subscribe((subMarket: SubMarket) => {
        this.subMarket = subMarket;
        this.originalMatchedShortId = this.subMarket.matched_short_id;
      }
      );

    this.store.select("mekkos", "subMarkets").pipe(
      map(subMarkets => _filter(subMarkets, sm => sm.market_id === this.subMarket.market_id)),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(subMarkets => this.subMarketNames = subMarkets.map(sm => sm.name.trim().toLowerCase()))

    this.savedSubMarketName = this.subMarket && this.subMarket.name ? this.subMarket.name : '';

    if (this.identifiers.length) {
      this.segmentV2Service.fetchByIdentifiers(this.identifiers)
        .subscribe(segments => this.segments = keyBy(segments, "identifier"))
    }

    this.store.select("snapshots", "snapshotLibrary").pipe(
      filter(Boolean), takeUntil(this.ngUnsubscribe)
    ).subscribe((snapshots: Snapshot[]) => this.isInSnapshotModel = flatMap(snapshots, 'sub_markets').some(sm => sm.id == this.subMarket.id));

    this.store.select("mekkos").pipe(
      select(selectActiveMekko),
      filter(Boolean),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((mekko: Mekko) => this.activeMekko = mekko);

    this.store.select('segmentsHierarchy', 'vendorDisplayNames').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(vendorDisplayNames => this.vendorDisplayNames = vendorDisplayNames);

    this.hasWeboramaAccess$ = canAccessFeature(this.store, INSIGHTS_SUB_FEATURE_WEBORAMA)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openSegmentPicker(type: "matched" | "modeled") {
    const currentlySelected = this.segments[this.subMarket[`${type}_short_id`]];
    this.segmentPickerService.open({
      multi: false,
      title1: `Audience`,
      title2: capitalize(type),
      currentlySelected:  currentlySelected ? [currentlySelected] : [],
      insightsContext: this.insightsContext,
    }).subscribe((segments) => {
      if (segments) {
        const [segment] = segments;
        this.segments[segment.identifier] = segment;
        this.subMarket[`${type}_short_id`] = segment.identifier;
      }
    })
  }

  upsertSubMarket() {
    this.store.dispatch(new mekkoActions.SaveSubMarket(this.subMarket));
    this.store.dispatch(new mekkoActions.CancelSubMarketEdit());
    this.actions$.pipe(fetchOutcome(mekkoActions.SaveSubMarket.type), take(1)).subscribe(
      success => { if (this.isInSnapshotModel && this.matchedShortIdChanged) {this.store.dispatch(new FetchSnapshots(this.activeMekko.id)); } }
    )
  }

  destroySubMarket() {
    this.store.dispatch(new mekkoActions.DestroySubMarket(this.subMarket));
    this.store.dispatch(new mekkoActions.CancelSubMarketEdit());
    this.actions$.pipe(fetchOutcome(mekkoActions.DestroySubMarket.type), take(1)).subscribe(
      success => { if (this.isInSnapshotModel) {this.store.dispatch(new FetchSnapshots(this.activeMekko.id)); } }
    );
  }

  cancel() {
    this.store.dispatch(new mekkoActions.CancelSubMarketEdit())
  }

  getSegmentPath(segment: SegmentLike): string {
    if (!segment) {return; }
    return prettyPathParts(segment, this.vendorDisplayNames).join(" > ")
  }

  get identifiers(): string[] {
    return compact([this.subMarket.matched_short_id, this.subMarket.modeled_short_id])
  }

  get matchedShortId(): string {
    return this.subMarket.matched_short_id
  }

  get modeledShortId(): string {
    return this.subMarket.modeled_short_id
  }

  get matchedShortIdChanged(): boolean {
    return this.matchedShortId !== this.originalMatchedShortId;
  }

  navigateToDiscussionsBulkUpload() {
    const [ _ , clientSlug, brandSlug] = window.location.pathname.split("/");
    this.router.navigate([clientSlug, brandSlug, NAV_ADMIN_UPLOAD, NAV_ADMIN_DISCUSSIONS_UPLOAD, NAV_ADMIN]);
  }

  hasZeroCount(short_id: string): boolean {
    const regionSlug = environment.isTier3
      ? get(getStoreState(this.store), 'hierarchy.contextSlugs.regionSlug')
      : 'global'

    if (!regionSlug) {return false}

    const seg = this.segments[short_id]
    const hasCount = !!get(seg, 'count.people')

    return hasCount && get(seg, ['count', 'people', regionSlug], 0) === 0;
  }
}

export function uniqueNameValidator(control: AbstractControl) {
  const name = control.value ? control.value.trim().toLowerCase() : '';
  const uniqueName = name === this.savedSubMarketName.trim().toLowerCase() || !this.subMarketNames.includes(name);
  return uniqueName ? null : { duplicateName: 'Audience name already used for this Market' };
}

export function blankValidator(control: AbstractControl) {
  return control.value && control.value.trim() ? null : { blankName: 'Cannot be blank' }
}
