<div class="journey-transition-info" [style.color]="!counts.loadingCounts && color">
  <div class="journey-transition-info-arrow">
    <div class="stem"></div>
    <div class="head"></div>
  </div>
  <div class="journey-transition-info-brands" *ngIf="journey.has_transition_data && !(isCompareMode$ | async)">
    <div class="journey-transition-info-brand-chip"
         *ngFor="let brand of brands;index as brandIdx; first as firstBrand; last as lastBrand; trackBy: trackByIndex"
         [style.background]="!counts.loadingCounts && getBrandColor(brand)"
         [class.hide]="!expanded && !firstBrand"
         (click)="firstBrand && toggleExpanded()">
      <div class="journey-transition-info-brand-chip-text">
          <div class="transition-percentage">
              <span>{{getPercentageChangeForBrand(brand)}}</span>
          </div>
          <div class="expand-button"  *ngIf="firstBrand && !lastBrand">
              <i class="fa" [class.fa-chevron-down]="!expanded" [class.fa-chevron-up]="expanded"></i>
          </div>
      </div>
    </div>
  </div>
</div>

