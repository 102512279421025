<div class="segment-picker">
  <div class="segment-picker-header">
    <i class="fa fa-search"></i>
    <span>Find Segments</span>
  </div>
  <mat-tab-group>
      <mat-tab label="Browse"><ppc-segment-picker-browse></ppc-segment-picker-browse></mat-tab>
      <mat-tab label="Search"><ppc-segment-picker-search></ppc-segment-picker-search></mat-tab>
    </mat-tab-group>
</div>
