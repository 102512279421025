<div class="detail-body">
  <div class="addressable-container">

    <span class="addressable-matched addressable">
      <span class="addressable-label">Seed Audience Count</span>
      <span class="addressable-number">{{ matchedAddressable | number }}</span>
    </span>

    <span class="addressable-modeled addressable">
      <span class="addressable-label">Modeled Audience Count</span>
      <span class="addressable-number">{{ hasConfidenceSizes ? (modeledAddressable | number) : "--" }}</span>
    </span>

  </div>

  <div *ngIf="hasConfidenceSizes; else pendingGraph" class="graph">
    <app-lookalike-graph-v2
      [lookalike]="ppcObject"
      (updateCountAndConfidence)="updateCountAndConfidence($event)"
    >
    </app-lookalike-graph-v2>
  </div>

  <ng-template #pendingGraph>
    <div class="pending-container">
      <div class="pending-text">
        <p>Please return in 24 hours to view completed confidence vs. reach graph.</p>
      </div>
    </div>
  </ng-template>

  <div class="detail-graph-lower-actions">
    <div class="seedCheckbox">
      <ppc-checkbox [(ngModel)]="newPPCObject.include_seed_in_model">
        Include Seed Audience
      </ppc-checkbox>
    </div>

    <div [ngSwitch]="jobStatus" class="buttons-container" [class.invisible]="!userCanEdit">
      <span *ngSwitchCase="'pending'" class="btn btn-save" (click)="onUpdate()">Save</span>
      <span *ngSwitchCase="'processing'" [ppcTooltip]="processingTooltip">
        <span class="btn btn-save disabled">Save</span>
      </span>
      <span *ngSwitchDefault>
        <ng-container *ngIf="lookalikeWillClone; else updateButton">
          <button class="mat-button btn btn-save" (click)="showDialog()">Edit and Clone</button>
        </ng-container>
        <ng-template #updateButton>
          <div class="btn btn-save" (click)="onUpdate()">Edit</div>
        </ng-template>
      </span>
    </div>
  </div>
  <epc-modal #modal
  [description]="confirmMessage"
  primaryButtonTitle="CONFIRM"
  [primaryButtonDisabled]="!clonedNameValid"
  (onPrimaryButtonClick)="onClone()"
  (onClose)="onDialogClose()"
  >
    <div body>
      <ppc-input class="cloned-name" 
      [(ngModel)]="clonedLookalikeName" 
      (input)="$event.preventDefault()"
      [validators]="clonedInputNameValidator">
        <label>Clone Name (Optional)</label>
      </ppc-input>
    </div>
  </epc-modal>


</div>
