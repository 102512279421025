<div class="page-level">
  <div class="layout vertical center-center bullseye">
    <div class="layout horizontal div-logo">
      <div class="padded-client-logo">
        <div class="client-logo"><h1>Epsilon PeopleCloud Discovery</h1></div>
      </div>
      <div class="padded-epsilon-logo">
        <div class="epsilon-logo"><h1>Epsilon PeopleCloud Discovery</h1></div>
      </div>
    </div>

    <div class="content">
      <ppc-tabs #tabs>
        <ppc-tab name="Current Users" #currentTab>
          <h2>&nbsp;</h2>
          <a class="btn coreUIButton-primary" [href]="lionLoginUrl">Login with LionLogin</a>
          <a class="btn coreUIButton-secondary" target="_blank" href="https://update.publicisgroupe.net/pm824/psf.exe#2">Reset LionLogin</a>
        </ppc-tab>
        <ppc-tab name="Request Access" #requestTab>
          <ppc-request-access-wizard></ppc-request-access-wizard>
        </ppc-tab>
      </ppc-tabs>
    </div>
  </div>
  <footer>
  </footer>
</div>
