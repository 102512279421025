import { keyBy, omit, sortBy, values, filter, map, compact, concat, uniqBy, groupBy, find, reject } from 'lodash';
import { Omit } from "ts-essentials";
import * as actions from './mekko.actions';
import { createSelector } from '@ngrx/store';
import { updateIndex } from 'app/shared/utils/utils';
import { CLIENT_VERTICALS } from 'app/admin/client/client.constants';
import { getPath, getContextSlugs } from 'app/hierarchy/hierarchy.utils'

export interface Mekko {
  id: number;
  name: string;
  markets?: Market[];
  market_ids?: number[];
  count_type: string;
  default?: boolean;
  product_slug?: string;
  vertical_type?: string;
  measurement_label?: string;
  created_at?: string;
  updated_at?: string;
}
export interface MekkoPayload extends Omit<Mekko, "id" | "markets"| "name"> {
  id?: number;
  name?: string;
  markets?: NewMarket[];
  markets_attributes?: MarketPayload[];
}


export interface MarketPayload extends Omit<Market, "mekko_id" | "id" | "name"> {
  id?: number;
  name?: string;
  sub_markets_attributes?: SubMarket[];
}

export interface NewMekko extends Omit<Mekko, "id" | "markets" | "name"> {
  id?: number;
  name?: string;
  markets?: NewMarket[];
  vertical_type?: string;
}

export interface NewMarket extends Omit<Market, "id" | "mekko_id" | "name"> {
  id?: number;
  mekko_id?: number;
  name?: string;
  sub_markets?: SubMarket[];
}

export interface Market {
  id: number;
  name: string;
  mekko_id: number;
  order?: number;
  created_at?: string;
}

type SubMarketTag = 'owned' | 'opportunity' | 'none';

export interface SubMarket {
  id?: number;
  name: string;
  market_id: number;
  matched_short_id?: string;
  modeled_short_id?: string;
  population: number;
  order: number;
  tag: SubMarketTag;
  webo_cluster_id?: string;
  discussion_cluster_node_id?: number;
  matched_count?: number;
  unique_count?: number;
}

export interface State {
  selectedMekkoId?: number;
  marketUnderEdit?: Market;
  mekkoUnderEdit?: NewMekko;
  subMarketUnderEdit?: SubMarket;
  mekkos: {[id: number]: Mekko};
  subMarkets: {[id: number]: SubMarket};
  editMekko: boolean;
  defaultMekko?: number;
  selectedSubMarketIds: number[];
}

const defaultState: State = {
  mekkos: {},
  subMarkets: {},
  editMekko: false,
  selectedSubMarketIds: [],
}

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.LoadMekkos.type:
      const newMekkos = keyBy(action.mekkos, 'id');
      return {...state, mekkos: newMekkos };

    case actions.LoadMekko.type:
      return {
        ...state,
        mekkos: {...state.mekkos, [action.mekko.id]: action.mekko},
        selectedMekkoId: state.selectedMekkoId ? state.selectedMekkoId : action.mekko.id
      };

    case actions.SetSelectedMekko.type:
      return {
        ...state,
        selectedMekkoId: action.mekko && action.mekko.id,
        selectedSubMarketIds: []
      };
    case actions.RemoveMekko.type:
      return {...state,
        mekkos: omit(state.mekkos, action.mekko.id)
      };

    case actions.DestroyMarket.type:
      const mekko = state.mekkos[action.market.mekko_id];
      return {
        ...state,
        mekkos: {
          ...state.mekkos,
          [mekko.id]: {
            ...mekko,
            markets: mekko.markets.filter(m => m.id !== action.market.id)
          }
        }
      };
    case actions.EditMarket.type:
      return {...state, marketUnderEdit: action.market}
    case actions.EditMekko.type:
      return {...state, mekkoUnderEdit: action.mekko}
    case actions.UpsertMarket.type: {
      const selectedMekko = state.mekkos[state.selectedMekkoId];
      const oldMarketIndex = (selectedMekko.markets || []).findIndex(m => m.id === action.market.id);
      return {
        ...state,
        mekkos: {
          ...state.mekkos,
          [selectedMekko.id]: {
            ...selectedMekko,
            markets: oldMarketIndex > -1
              ? updateIndex(selectedMekko.markets, oldMarketIndex, oldMarket => ({...oldMarket, ...action.market}))
              : (selectedMekko.markets || []).concat(action.market)
          }
        }
      }
    }
    case actions.EditSubMarket.type:
      return {...state, subMarketUnderEdit: action.subMarket};
    case actions.UpdateSubMarket.type:
      return {
        ...state,
        subMarkets: {
          ...state.subMarkets,
          [action.subMarket.id]: action.subMarket
        }
      };
    case actions.UpdateSubMarketUnderEdit.type:
      return {
        ...state,
        subMarketUnderEdit: {...state.subMarketUnderEdit, ...action.subMarket}
      };
    case actions.DestroySubMarket.type:
      return {
        ...state,
        subMarkets: omit(state.subMarkets, action.subMarket.id),
        selectedSubMarketIds: reject(state.selectedSubMarketIds, id => id === action.subMarket.id)
      };
    case actions.UpdateSubMarketOrder.type:
      return {
        ...state,
        subMarkets: keyBy(action.subMarkets.map(({ id, order }) => ({...state.subMarkets[id], order})), "id")
      }
    case actions.UpdateMarketOrder.type:
      const selectedMekko = state.mekkos[state.selectedMekkoId];
      const markets = state.mekkos[state.selectedMekkoId].markets;
      return {
        ...state,
        mekkos: {
          ...state.mekkos,
          [selectedMekko.id]: {
            ...selectedMekko,
            markets: action.markets
          }
        }
      }
    case actions.LoadSubMarkets.type:
      return {...state, subMarkets: keyBy(action.subMarkets, 'id')};
    case actions.LoadSubMarket.type:
      return {
        ...state,
        subMarkets: {
          ...state.subMarkets,
          [action.subMarket.id]: action.subMarket
        }
      };
    case actions.CancelSubMarketEdit.type:
      return {...state, subMarketUnderEdit: null};
    case actions.ToggleEditMekko.type:
      return {...state, editMekko: !state.editMekko};
    case actions.SetEditMekko.type:
      return {...state, editMekko: action.editMekko};
    case actions.ToggleSubMarket.type:
      return {
        ...state,
        selectedSubMarketIds: state.selectedSubMarketIds.includes(action.id) ?
          state.selectedSubMarketIds.filter(id => id != action.id) :
          state.selectedSubMarketIds.concat([action.id])
      };
    case actions.ClearSelectedSubMarkets.type:
      return {...state, selectedSubMarketIds: []}
    case actions.LoadDefaultMekko.type:
      return { ...state, defaultMekko: action.defaultMekko }
    default:
      return state;
  }
}


export const selectActiveMekko = createSelector(
  (state: State) => state.mekkos,
  (state: State) => state.selectedMekkoId,
  (state: State) => state.defaultMekko,
  (mekkos, selectedMekkoId, defaultId) => {
    if (mekkos[selectedMekkoId]) {
      return { ...mekkos[selectedMekkoId], default: selectedMekkoId === defaultId }
    }
  }
)


export const selectMekkos = createSelector(
  (state: State) => state.mekkos,
  (state: State) => state.defaultMekko,
  (mekkos, defaultId) => sortBy(values(mekkos).map(mekko => {
    return {
      ...mekko,
      default: mekko.id == defaultId
    }
  }), mekko => mekko.name.toLowerCase())
)

export const selectActiveMekkoSubMarkets = createSelector(
  selectActiveMekko,
  (state: State) => state.subMarkets,
  (activeMekko, subMarkets) => {
    if (!activeMekko) {return []; }
    const marketIds = activeMekko.markets.map(market => market.id)
    return filter(values(subMarkets), (subMarket: SubMarket) => marketIds.includes(subMarket.market_id))
  }
)

export const selectActiveMekkoMarkets = createSelector(
  selectActiveMekko,
  selectActiveMekkoSubMarkets,
  (activeMekko, subMarkets) => {
    if (!activeMekko) {return []; }
    return activeMekko.markets.map(market => {
      return {
        ...market,
        sub_markets: filter(subMarkets, (subMarket: SubMarket) => subMarket.market_id === market.id)
      }
    })
  }
)

export const selectSelectedSubMarkets = createSelector(
  (state: State) => state.selectedSubMarketIds,
  (state: State) => state.subMarkets,
  selectActiveMekkoSubMarkets,
  (ids, subMarkets, activeMekkoSubMarkets) => {
    return ids.length && map(ids, id => subMarkets[id]) || activeMekkoSubMarkets || []
  }
);

export const selectedSubMarkets = createSelector(
  (state: State) => state.selectedSubMarketIds,
  (state: State) => state.subMarkets,
  (ids, subMarkets) => {
    return ids.length && map(ids, id => subMarkets[id]) || []
  }
);

export const selectActiveWeboramaId = createSelector(
  selectSelectedSubMarkets,
  (selectedSubMarkets) => {
    if (uniqBy(selectedSubMarkets, "webo_cluster_id").length == 1) {
      return selectedSubMarkets[0].webo_cluster_id
    }
  }
)

export const selectAllShortIds = createSelector(
  (state: State) => state.subMarkets,
  (subMarkets: {[id: string]: SubMarket}) => selectShortIds(subMarkets)
)

export const selectSubMarketsByMarketId = createSelector(
  (state: State) => state.subMarkets,
  (subMarkets) => groupBy(sortBy(subMarkets, 'order'), 'market_id')
)

export const isVerticalMekko = createSelector(
  selectActiveMekko,
  mekko => mekko && mekko.vertical_type !== null
)

export function newMekko(): NewMekko {
  return {
    id: null,
    name: 'Unnamed Chart',
    count_type: 'people_count',
    vertical_type: null,
    measurement_label: "Penetration",
    markets: [
      {
        id: null,
        name: 'New Market',
        mekko_id: null,
        sub_markets: [],
        order: 0
      }
    ]
  }
}

export function newMarket(newIndex): Market {
  return {
    id: null,
    name: null,
    mekko_id: null,
    order: newIndex
  }
}

export function newSubMarket(market_id: number, order: number): SubMarket {
  return {
    id: null,
    name: null,
    population: 0,
    tag: 'none',
    order,
    market_id,
  }
}

export function selectShortIds(subMarkets) {
  return compact(concat(map(subMarkets, 'matched_short_id'),
    map(subMarkets, 'modeled_short_id')));
}
