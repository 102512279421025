<div class="gwi-survey-question">
  <div class="gwi-survey-question-question" #questionElement (click)="expanded ?  expanded = false : expandQuestion()">
    <div class="gwi-survey-question-question-icon"  [class.has-selected-response]="isResponseSelected" matRipple [matRippleCentered]="true" [matRippleTrigger]="questionElement">
      <i class="fa fa-refresh fa-spin fa" *ngIf="loading"></i>
      <i class="fa fa-caret-right" *ngIf="!loading"
         [class.rotate-90]="expanded">
       </i>
    </div>
     <span class="gwi-survey-question-question-text" [class.has-selected-response]="isResponseSelected">{{index}}. {{question.name}}</span>
  </div>
  <div class="gwi-survey-question-responses" [class.expanded]="expanded && !loading">
    <ng-container *ngIf="question?.has_leaf_children">
      <div class="gwi-survey-question-response"
           *ngFor="let response of question?.children;"
           [class.selected]="isGWISegmentSelected(response)"
           (click)="toggleSegment(response)">
        <ppc-progress-bar [percentage]="getPercentage(response)" 
                          [reverse]="true" 
                          colorOverride="#82CAD0" 
                          [showCounts]="showCounts" 
                          [countLabel]="getCountLabel(response)" 
                          [count]="getCount(response)"></ppc-progress-bar>
        <span>{{response.name}}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="!question?.has_leaf_children">
      <ppc-gwi-survey-question *ngFor="let segment of question?.children; let i=index"
                               (setActiveGWIQuestions)="setActiveGWIQuestions.emit()"
                               [question]="segment"
                               [categoryName]="categoryName"
                               [showCounts]="showCounts"
                               [index]="getLetter(i)">
      </ppc-gwi-survey-question>
    </ng-container>

  </div>
</div>
