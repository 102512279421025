import { SubMarket } from 'app/mekko/mekko.reducer';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { JourneyCountService } from 'app/journey/journey-count.service';
import { JourneyStage, JourneyBrand, JourneySubMarket, JourneyTabType } from 'app/journey/journey.models';
import { selectActiveJourneySubMarkets } from 'app/journey/journey.reducer';
import { JourneyFillGaugeComponent } from '../journey-fill-gauge/journey-fill-gauge.component';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import * as actions from 'app/journey/journey.actions';
import { Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { compact, truncate, get, find, keyBy, map as _map } from 'lodash';
import { isCompareMode } from 'app/insights/insights.reducer';
import { compareTargets, CompareTarget } from '../../../insights/insights.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'environments/environment';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { SegmentLike } from 'app/models/segment-like.model';
import { selectRegion } from "app/hierarchy/hierarchy.reducers";
import { HierarchyRegion } from "app/hierarchy/hierarchy.interface";
import { fixCounts } from 'app/insights/insights.models';
import { prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';

@UntilDestroy()
@Component({
  selector: 'ppc-journey-stage',
  templateUrl: './journey-stage.component.html',
  styleUrls: ['./journey-stage.component.sass']
})
export class JourneyStageComponent implements OnInit, OnDestroy {
  @ViewChild(JourneyFillGaugeComponent, { static: true }) gauge: JourneyFillGaugeComponent;
  @Input() stage: JourneyStage;
  @Input() nextStage: JourneyStage; // null for last stage
  @Input() color: string;
  @Input() transitionElements: [JourneyStageComponent, JourneyStageComponent];
  @Input() value: number;
  @Input() horizontal: boolean;
  @Input() brands: JourneyBrand[];
  @Input() journeyTab: JourneyTabType;
  transitionInfoExpanded = false;
  showMiniPanel = false;
  shouldDim$: Observable<boolean>;
  shouldDimArrow$: Observable<boolean>;
  isCompareMode$ = this.store.select("insights", "journey").pipe(select(isCompareMode));
  compareTargets: CompareTarget[];
  journeySubMarket: JourneySubMarket;
  isTier3 = environment.isTier3;
  segments: {[identifier: string]: SegmentLike} = {};
  region: HierarchyRegion;
  vendorDisplayNames: VendorDisplayName[];

  constructor(private counts: JourneyCountService,
    private segmentService: SegmentV2Service,
    private store: Store<AppState>) {
    this.store.pipe(select(compareTargets("journey")), untilDestroyed(this))
      .subscribe(compareTargets => this.compareTargets = compareTargets)

    this.store.select('hierarchy').pipe(
      select(selectRegion), untilDestroyed(this)
    ).subscribe(region => this.region = region);

    store.select('segmentsHierarchy', 'vendorDisplayNames').pipe(
      untilDestroyed(this)
    ).subscribe(vendorDisplayNames => this.vendorDisplayNames = vendorDisplayNames);
  }

  ngOnInit() {
    this.store.select("journey").pipe(
      select(selectActiveJourneySubMarkets), untilDestroyed(this)
    ).subscribe(journeySubMarkets => {
      this.journeySubMarket = find(journeySubMarkets, {journey_stage_id: this.stage.id});
      const segmentIdentifiers = this.journeySubMarket ? compact([this.journeySubMarket.matched_id, this.journeySubMarket.modeled_id]) : [];

      if (segmentIdentifiers.length) {
        this.segmentService.fetchByIdentifiers(segmentIdentifiers)
          .subscribe(segments => this.segments = keyBy(fixCounts(segments, this.region), "identifier"));
      }
    });

    this.shouldDim$ = this.store.select("journey", "selectedStageIds").pipe(
      map(selectedStageIds => selectedStageIds.length && !selectedStageIds.includes(this.stage.id))
    )
    this.shouldDimArrow$ = this.store.select("journey", "selectedStageIds").pipe(
      map(selectedStageIds => selectedStageIds.length && (!selectedStageIds.includes(this.stage.id) || !selectedStageIds.includes(this.nextStage.id))),
    )
  }

  ngOnDestroy() { }

  get personCount() {
    return this.counts.getCountForStage(this.stage)
  }

  toggleStage() {
    this.store.dispatch(new actions.ToggleStage(this.stage))
  }

  truncate(name: string): string {
    return truncate(name, {length: 20});
  }

  nameTemplate(name: string): string {
    if (name.length > 20) {
      return name;
    }
  }

  isDisabled(name: string): boolean {
    return name.length <= 20
  }

  getCompareColor(stage) {
    return get(find(this.compareTargets, {id: stage.id}), "legendColor")
  }

  hasZeroCount(): boolean {
    switch (this.journeyTab) {
      case "Matched":
        return this.getZeroCount(this.journeySubMarket.matched_id, "matched");
      case "Modeled":
        if (this.isTier3) {
          return this.getZeroCount(this.journeySubMarket.matched_id, "matched");
        } else {
          return this.getZeroCount(this.journeySubMarket.modeled_id, "modeled");
        };
      default:
        return null;
    }
  }

  getZeroCount(identifier, countQuery: "matched" | "modeled"): boolean {
    return identifier && get(this.segments[identifier], `count.${countQuery}`, 0) === 0;
  }

  getJourneySubmarketPath(subMarket: SubMarket): string {
    switch (this.journeyTab) {
      case "Matched":
        return prettyPathParts(this.segments[subMarket[`matched_id`]], this.vendorDisplayNames).join(" > ");
      case "Modeled":
        if (this.isTier3) {
          return prettyPathParts(this.segments[subMarket[`matched_id`]], this.vendorDisplayNames).join(" > ");
        } else {
          return prettyPathParts(this.segments[subMarket[`modeled_id`]], this.vendorDisplayNames).join(" > ");

        };
      default:
        return null;
    }
  }
}
