import {takeUntil, map, filter} from 'rxjs/operators';
import {Component, OnDestroy, Inject} from '@angular/core';

import {Store, select} from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {keyBy, cloneDeep, remove, map as _map, compact, merge, find, filter as _filter, get, concat, forEach} from 'lodash';
import {Subject} from "rxjs";
import {DragulaService} from "ng2-dragula";

import {AppState} from "app/reducers";
import {Bucket, ChartTypes, Demographic, fixCounts, RadarChartAccess} from "app/insights/insights.models";
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { SegmentPickerService } from 'app/segment-picker/segment-picker.service';
import { selectRegion } from "app/hierarchy/hierarchy.reducers";
import { HierarchyRegion } from 'app/hierarchy/hierarchy.interface';
import * as insightsActions from 'app/insights/insights.actions';
import { getUiInsightContext, INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import {InsightsCountService} from "app/insights/insights-count.service";
import { userPreferenceKeys } from "app/insights/grow-v3/grow.constants";
import { selectStandardDemographics } from "app/insights/insights.reducer";
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';
import { createStandardDemographicsConfig } from '../insights-components.utils';
import { isDefined } from 'app/shared/utils/utils';
import { environment } from 'environments/environment';
import { isCompareMode } from '../../insights.reducer';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';
import { ErrorMessages } from 'app/app.common-messages';

@UntilDestroy()
@Component({
  selector: 'ppc-widget-form',
  templateUrl: './widget-form.component.html',
  styleUrls: ['./widget-form.component.sass']
})
export class WidgetFormComponent extends InsightsResourceTracker implements OnDestroy {
  demographic: Demographic;
  standardDemographics: Demographic[];
  segments: {[identifier: string]: any} = {};
  bucketsToDestroy: Bucket[] = [];
  bagName = 'WIDGET-FORM-BUCKET-ORDER';
  ngUnsubscribe = new Subject();
  isTier3 = environment.isTier3;
  region: HierarchyRegion;
  vendorDisplayNames: VendorDisplayName[];
  maxAttributeLimit = 15;
  tabBucketLimit: number;
  attributeCountInTab: number;
  indexMode$ = this.store.select("insights", this.insightsContext, "indexMode");
  isCompareMode$ = this.store.select("insights", this.insightsContext).pipe(select(isCompareMode));
  radarDataSetsMin=3;
  radarDataSetsMax=7;
  barVerticalDataSetsMax=9;
  nameValidators = [{
    isValid: (name: string) => !!name,
    errorMessage: "Please provide a name for this widget"
  }];
  // used in HTML for chart type reference
  chartTypes = ChartTypes;
  errorMessages = ErrorMessages;
  radarChartAccess = RadarChartAccess;

  constructor(public store: Store<AppState>,
    private segmentPickerService: SegmentPickerService,
    private dragula: DragulaService,
    private segmentV2Service: SegmentV2Service,
    private counts: InsightsCountService,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super(store, insightsContext);

    store.select('hierarchy').pipe(
      select(selectRegion), filter(isDefined), untilDestroyed(this)
    ).subscribe(region => {
      this.region = region
      this.tabBucketLimit = region.tab_bucket_limit;
    });

    store.pipe(
      select(selectStandardDemographics(this.insightsContext)),
      untilDestroyed(this)
    ).subscribe(demographics => {
      this.standardDemographics = demographics;
    });

    store.select("insights", this.insightsContext, "demoUnderEdit").pipe(filter(isDefined), map(cloneDeep)).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe(
      demographic => {
        this.demographic = demographic;
        this.fetchSegments();
      }
    );

    store.select('segmentsHierarchy', 'vendorDisplayNames').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(vendorDisplayNames => this.vendorDisplayNames = vendorDisplayNames);

    dragula.setOptions(this.bagName, {
      moves: (el, container, handle) => handle.classList.contains("fa-bars")
    });

    store.select('permissions', 'attribute_widget_limit', 'update').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(limitPermission => {
      if (limitPermission) {
        this.maxAttributeLimit = 25;
      }
    });

    store.select("insights", this.insightsContext, "demographics").pipe(
      takeUntil(this.ngUnsubscribe)).subscribe(
      widgets => {
        const widgetsInCurrentTab = widgets.filter(w => w.custom_tab_id === this.demographic.custom_tab_id);
        this.attributeCountInTab = widgetsInCurrentTab.reduce((attributesCount, widget) => attributesCount + widget.buckets.length, 0);
        // this.horizontalBarAttributeLimit = (this.region.tab_bucket_limit || this.horizontalBarAttributeLimit) - this.attributeCountInTab;
      }
    );

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.dragula.find(this.bagName) && this.dragula.destroy(this.bagName);
    super.ngOnDestroy();
  }

  openSegmentPicker() {
    this.segmentPickerService.open({
      multi: true,
      title1: "Custom Widget",
      title2: "Select Segments/Audiences",
      currentlySelected: compact(_map(this.bucketIdentifiers, identifier => this.segments[identifier])),
      insightsContext: this.insightsContext,
    }).subscribe(segments => {
      if (segments) {
        const attributesInWidget = this.demographic.buckets.length;
        // Remove segments that are no longer a part of their selection (they removed them)
        forEach(_filter(this.demographic.buckets, bucket => !find(segments, {identifier: bucket.short_id})), this.removeBucket.bind(this))

        // Create a bucket for new segments and add them to the demographic buckets
        this.demographic.buckets = concat(this.demographic.buckets, _map( _filter(segments,
          segment => !find(this.demographic.buckets, {short_id: segment.identifier})), newSegment => {
          return {
            name: newSegment.name,
            short_id: newSegment.identifier
          }
        }))
        const updatedAttributeCount = this.demographic.buckets.length - attributesInWidget;
        this.attributeCountInTab += updatedAttributeCount;

        this.segments = merge(this.segments, keyBy(segments, 'identifier'))
      }
    })
  }

  fetchSegments() {
    if (this.bucketIdentifiers.length) {
      this.segmentV2Service.fetchByIdentifiers(this.bucketIdentifiers)
        .subscribe(segments => {
          const fixedSegments = fixCounts(segments, this.region);
          this.segments = keyBy(fixedSegments, "identifier");
        });
    }
  }

  get isValid(): boolean {
    if (!this.demographic.is_standard &&
      this.attributeCountInTab > this.tabBucketLimit) {
      return false;
    }
    switch (this.demographic.widget_type) {
      case this.chartTypes.BarVertical:
        return (
          this.demographic.buckets.length > 0 &&
          this.demographic.buckets.length <= 9 &&
          this.demographic.name.length > 0
        );
      case this.chartTypes.BarHorizontal:
        return (
          this.demographic.buckets.length > 0 &&
          this.demographic.name.length > 0 &&
          (this.demographic.is_standard ||
            (!this.demographic.is_standard &&
              this.attributeCountInTab <=
                this.region.tab_bucket_limit))
        );
      case this.chartTypes.Radar:
        return (
          (!this.isRadarOutOfLimits) &&
            this.demographic.name.length > 0 &&
            (this.demographic.is_standard ||
              (!this.demographic.is_standard &&
                this.attributeCountInTab <=
                  this.region.tab_bucket_limit))
        );

      default:
        return (
          this.demographic.buckets.length > 0 &&
          this.demographic.name.length > 0 &&
          (this.demographic.is_standard ||
            (!this.demographic.is_standard &&
              this.demographic.buckets.length <= this.maxAttributeLimit))
        );
    }
  }

  get isRadarOutOfLimits(): boolean {
    return this.demographic.widget_type == this.chartTypes.Radar && (this.demographic.buckets.length > this.radarDataSetsMax || this.demographic.buckets.length < this.radarDataSetsMin );
  }

  get isBarVerticalOutOfLimits(): boolean {
    return this.demographic.widget_type == this.chartTypes.BarVertical && this.demographic.buckets.length > this.barVerticalDataSetsMax;
  }

  get isDoughnutOutOfLimits(): boolean {
    return this.demographic.widget_type == this.chartTypes.Doughnut && this.demographic.buckets.length > this.maxAttributeLimit;
  }

  getPathStringByShortId(shortId: string) {
    const segment = this.segments[shortId];
    if (segment) {
      return prettyPathParts(segment, this.vendorDisplayNames).join(" > ")
    }
  }

  removeBucket(bucket: Bucket) {
    bucket._destroy = true;
    remove(this.demographic.buckets, bucket);
    this.bucketsToDestroy.push(bucket);
    this.attributeCountInTab--;
  }

  get bucketIdentifiers(): string[] {
    return _map(this.demographic.buckets, "short_id")
  }

  cancelEdit() {
    this.store.dispatch(new insightsActions.EditDemographic(null, this.insightsContext));
  }

  upsertDemographic() {
    if (this.demographic.is_standard) {
      const changedWidget = find(this.standardDemographics, {id: this.demographic.id})
      changedWidget.widget_type = this.demographic.widget_type;
      changedWidget.is_id_count = this.demographic.is_id_count;
      changedWidget.description = this.demographic.description;

      this.store.dispatch(new insightsActions.SaveDemographicsConfig(createStandardDemographicsConfig(this.standardDemographics),
        userPreferenceKeys.standardDemographics(this.insightsContext), this.resourceId, this.resourceType, this.insightsContext))

    } else {
      this.demographic.buckets.forEach((bucket, idx) => bucket.order = idx);
      this.demographic.buckets = this.demographic.buckets.concat(this.bucketsToDestroy);
      this.store.dispatch(new insightsActions.UpsertDemographic(this.demographic, this.insightsContext));
    }

    this.store.dispatch(new insightsActions.EditDemographic(null, this.insightsContext));
  }

  getCount(segment, countType: "matched" | "modeled") {
    return get(segment, ["count", countType]);
  }

  getSegmentCount(bucket, countType) {
    return this.getCount(find(this.segments, {identifier: bucket.short_id}), countType);
  }

  renderTooltip(): string {
    return this.isTier3 ? 'Unique panel IDs' : 'PIDs that belong to the segment/audience and have a LiveRamp IDL';
  }

  contextTitle(): string {
    return getUiInsightContext(this.insightsContext);
  }

}
