<div *ngIf="channel.isAvailable()" class="card">
  <div class="card-wrapper">
    <div *ngIf="channel.output.points.length === 0" class="card-content">
      <div *ngIf="tardiisError" class="error-message">Something went wrong, please try again</div>

      <div *ngIf="channel.name" title="{{channel.name}}" class="title">{{channel.name}}</div>
      <div *ngIf="!channel.name" title="Project ID: {{channel.template._id}}" class="title">Project ID: {{channel.template._id}}</div>

      <div *ngIf="!tardiisError && channel.output.status !== 'FinishedWithErrors'" class="no-data">
        This channel has no data points associated with it.
      </div>
      <div *ngIf="!tardiisError && channel.output.status === 'FinishedWithErrors'" class="no-data">
        There was an issue running your project. Please try again.
      </div>

    </div>
    <div *ngIf="channel.output.points.length > 0" class="card-content">
      <div *ngIf="tardiisError" class="error-message">Something went wrong, please try again</div>
      <div *ngIf="error" class="error-message">{{error.errorMessage}}</div>

      <button mat-icon-button [matMenuTriggerFor]="menu" class="card_menu">
        <i class="fa fa-ellipsis-v"></i>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="onSelectChannel(channel._id)" class="view_button">
          View Channel
        </button>
        <button mat-menu-item (click)="edit(channel._id)" class="edit_button">
          Edit Channel
        </button>
        <app-confirmation
          [isCustomTemplate]="true"
          [kontinue]="delete.bind(this, channel._id)"
          >
          <button mat-menu-item class="delete_scenario_button" *ngIf="canDeleteChannel">
            Delete Channel
          </button>
        </app-confirmation>

        <ng-container *checkFeatureAccess="let canAccessScenarios=canAccess; feature: 'scenarios'">
          <button mat-menu-item
                  *ngIf="canAccessScenarios && attachedScenarioId"
                  (click)="gotoAttachedScenario()"
                  class="view_scenario_button"
          >
            View Scenario
          </button>
        </ng-container>
      </mat-menu>

      <div class="title title-link" (click)="gotoAllocationsPage()">
        <span [title]="channel.name">{{channel.name}}</span>
      </div>

      <div class="info-row">
        <div>
          <div class="info-label">Total {{channel.hasBudgetInfo() ? "Budget" : "TRP"}}</div>
          <div class="info-data">
            <span *ngIf="channel.hasBudgetInfo(); else useTRPInfo">
              {{channel.lastPoint().budget | currency:currencyCode?.toUpperCase():'symbol-narrow'}}
            </span>
            <ng-template #useTRPInfo>{{channel.lastPoint().trp | number:'1.0-0'}}</ng-template>
          </div>
        </div>
        <div>
          <div class="info-label">Total Reach</div>
          <div class="info-data">{{channel.lastPoint().reach / 100 | percent:'1.2-2'}}</div>
        </div>
      </div>
      <div class="graph-container">
        <svg id="graph-{{channel._id}}"></svg>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!channel.isAvailable()" class="card">
  <div class="card-wrapper">
    <div class="card-content">
      <ppc-error  
        [visible]="showErrorBox"
        [topLevelMessage]="primaryErrorMessage"
        (closeAlert)="closeErrorBox()"
      ></ppc-error>

      <button *ngIf="tardiisError || canDeleteChannel" mat-icon-button [matMenuTriggerFor]="menu" class="card_menu">
        <i class="fa fa-ellipsis-h"></i>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="edit(channel._id)" class="edit_button" *ngIf="tardiisError">
          Edit Channel
        </button>
        <button mat-menu-item (click)="run(channel._id)" class="edit_button" *ngIf="tardiisError">
          Retry
        </button>
        <app-confirmation
          [isCustomTemplate]="true"
          [kontinue]="delete.bind(this, channel._id)"
        >
          <button mat-menu-item class="delete_scenario_button" *ngIf="canDeleteChannel">
            Delete Channel
          </button>
        </app-confirmation>
      </mat-menu>

      <div *ngIf="tardiisError && !primaryErrorMessage" class="error-message">Something went wrong, please try again</div>

      <div *ngIf="channel.name" title="{{channel.name}}" class="title">{{channel.name}}</div>
      <div *ngIf="!channel.name" title="Project ID: {{channel.template._id}}" class="title">Project ID: {{channel.template._id}}</div>

      <mat-spinner *ngIf="!tardiisError"></mat-spinner>
      <div *ngIf="!tardiisError" class="please-wait">
        We're working on your request. This may take up to a few hours.
      </div>
    </div>
  </div>
</div>
