<div class="pull-right layout center-center vertical">
  <ppc-input #search
    [ngModel]="searchInput$ | async"
    (ngModelChange)="searchUpdate($event)"
    [autofocus]="true"
    [tabindex]="1"
    [noLabel]="true"
    [noErrorContainer]="true"
  >
    <div prefix>
      <i class="fa fa-search"></i>
    </div>
    <div suffix [class.hide]="(searchInput$ | async).length === 0"
      (click)="searchInput$.next('');search.focus()">
      <i class="fa fa-close"></i>
    </div>
    <input type="text" />
  </ppc-input>
</div>

<div class="no-results" *ngIf="(activeMenuItems$ | async)?.length == 0">
  <h4>No admin modules match that query</h4>
</div>

<div *ngFor="let category of (adminCategories$ | async)">
  <header>
    <h1 class="object-name">{{category.name}}</h1>
  </header>
  <div>
    <mat-grid-list cols="5" rows="1" [gutterSize]="0" rowHeight="220px">
      <mat-grid-tile *ngFor="let menuItem of category.items">
        <app-admin-action-menu-item [menuItem]="menuItem"></app-admin-action-menu-item>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
