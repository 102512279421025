import { AppState } from 'app/reducers';
import * as actions from 'app/snapshots/snapshots.actions';
import {get, keyBy, reject, omit} from 'lodash';
import {createSelector} from "@ngrx/store";
import { SubMarket } from 'app/mekko/mekko.reducer';

export interface Snapshot {
  id?: number;
  name: string;
  status?: string;
  include_first_party_segments: boolean;
  mekko_id: number;
  request_id?: string;
  sub_markets?: SubMarket[];
  filters?: {shortId: string, pathParts: string[], type: string, demographicId: string, tab: string}[];
  created_at?: string;
  confidence_counts?: any;
  feature_importance?: {request_id: string, segment_id_scores: FeatureImportanceEntry[]};
  snapshots_sub_markets?: SnapshotsSubMarket[];
}

export interface SnapshotsSubMarket {
  sub_market_id: number;
  current_confidence: number;
  matched_count: number;
}

export interface FeatureImportanceEntry {
  segment_identifier: string;
  segment_score: number;
  label_scores: {[subMarketId: string]: number}
}

export interface SnapshotDetail {
  id?: number;
  name: string;
  mekko_id: number;
  mekko_name: string;
  request_id?: string;
  created_at: string;
  updated_at: string;
  created_by?: number;
  product_slug: string;
  time_diff: number;
  pending_since: string;
}

export interface State {
  snapshotLibrary: {[id: number]: Snapshot};
  activeSnapshotId?: number;
}

const defaultState: State = {
  snapshotLibrary: [],
};

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.LoadSnapshots.type:
      return {...state, snapshotLibrary: keyBy(action.snapshots, 'id') };
    case actions.LoadSnapshot.type:
      return {
        ...state,
        snapshotLibrary: {...state.snapshotLibrary, [action.snapshot.id]: action.snapshot},
      };
    case actions.SetActiveSnapshot.type:
      return { ...state, activeSnapshotId: get(action.snapshot, "id")}
    case actions.ClearActiveSnapShot.type:
      return { ...state, activeSnapshotId: null }
    case actions.RemoveSnapshot.type:
      return {...state, snapshotLibrary: omit(state.snapshotLibrary, action.snapshot.id) };
    case actions.LoadCurrentConfidence.type:
      const snapshot = {...state.snapshotLibrary[action.snapshotId]};
      snapshot.snapshots_sub_markets = [
        ...reject(snapshot.snapshots_sub_markets, {sub_market_id: action.subMarketId}),
        {sub_market_id: action.subMarketId, current_confidence: action.currentConfidence}
      ] as SnapshotsSubMarket[]
      return { ...state, snapshotLibrary: {
        ...state.snapshotLibrary,
        [action.snapshotId]: snapshot
      }}
    default:
      return state;
  }
}

export const selectActiveSnapshot = createSelector(
  (state: State) => state.activeSnapshotId,
  (state: State) => state.snapshotLibrary,
  (activeSnapshotId, snapshots) => {
    return get(snapshots, activeSnapshotId)
  }
)
