import { Router } from '@angular/router';
import { isFetchInFlight } from 'app/shared/utils/fetch-state';
import { PpcHttpService } from 'app/services/ppc_http.service';
import { ApiKeysService } from 'app/admin/api-keys/api-keys.service';
import { FetchAllApiKeys, RemoveApiKey, EditApiKey, SetApiKeySecret } from 'app/admin/api-keys/api-keys.actions'
import { FetchAllUsers } from "app/users/user.actions";
import { Component } from '@angular/core';
import { BehaviorSubject, combineLatest as observableCombineLatest} from 'rxjs';
import {select, Store} from "@ngrx/store";
import {map} from "rxjs/operators";
import {AppState} from "app/reducers";
import {keyBy, values, get} from "lodash";
import { NAV_ADMIN_GLOBAL, NAV_ADMIN_ROLES, NAV_ADMIN } from 'app/shared/utils/constants';
import { rolesUrl } from 'app/shared/constants/auth.urls';

@Component({
  selector: 'ppc-api-keys-admin',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.sass']
})
export class ApiKeysComponent {

  isLoading$ = this.store.select("fetchStates", FetchAllApiKeys.type).pipe(select(isFetchInFlight));
  roles$ = this.http.get(rolesUrl());
  apiKeySecret$ = this.store.select('apiKeys', 'apiKeySecret');
  apiKeysStates = ['Active', 'Disabled', 'All'];
  apiKeysState$ = new BehaviorSubject('Active');
  apiKeysList$ = observableCombineLatest(
    this.store.select('apiKeys', 'apiKeys'),
    this.store.select('users', 'users'),
    this.apiKeysState$,
    this.roles$
  ).pipe(
    map(([ apiKeys,  users, apiKeysState, roles ]) => {
      const rolesById = keyBy(roles, 'id');
      return values(apiKeys).map(apiKey => (
        {...apiKey,
          roleName: get(rolesById, [apiKey.role_id, 'name'], 'Unknown'),
          userFullName: `${get(users, [apiKey.user_id, 'first_name'], '')} ${get(users, [apiKey.user_id, 'last_name'], '')}`
        }))
        .filter(apiKey => apiKeysState == 'Active'   ? apiKey.active
          : apiKeysState == 'Disabled' ? !apiKey.active
            : true)
    })
  )
  constructor(
    private http: PpcHttpService,
    private store: Store<AppState>,
    private apiKeysService: ApiKeysService,
    private router: Router,
  ) {
    this.store.dispatch(new FetchAllApiKeys());
    this.store.dispatch(new FetchAllUsers());
  }

  updateStatusSelect(status) {
    this.apiKeysState$.next(status);
  }

  onDisableKey(key) {
    this.store.dispatch(new EditApiKey({...key, active: false}));
  }
  onActivateKey(key) {
    this.store.dispatch(new EditApiKey({...key, active: true}));
  }
  onConfirmDeleteKey(key) {
    this.store.dispatch(new RemoveApiKey(key.id));
  }

  onConfirmReissueKey(key) {
    const successMsg = `Api Key Reissued Successfully.`;
    const failMsg = `Api Key Reissue Failed Spectacularly.`;
    this.apiKeysService.reissueApiKey(key.id).subscribe(
      res => {
        this.store.dispatch(new SetApiKeySecret(res));
        console.info(successMsg);
      },
      err => console.error(failMsg)
    );
  }

  navigateToRoles() {
    this.router.navigate([NAV_ADMIN_GLOBAL, NAV_ADMIN, NAV_ADMIN_ROLES])
  }

}
