import { compact } from 'lodash';
import { FetchOverviewAudiences } from './../audiences-v2/audience-v2.actions';
import { Component, OnDestroy } from "@angular/core";
import { currentUser } from "app/users/user.reducer";
import { select, Store } from "@ngrx/store";
import { filter, map } from "rxjs/operators";
import { AppState } from "app/reducers";
import { UserService } from 'app/users/user.service';
import { combineLatest} from 'rxjs';
import { isFetchInFlight } from "app/shared/utils/fetch-state";
import { FetchComparisons } from '../comparisons/comparisons.actions';
import { canAccessFeature } from "app/feature-access/feature-access.reducers";
import { activeContext } from "app/hierarchy/hierarchy.reducers";
import { isFullContext, getContextSlugs, getPath } from "app/hierarchy/hierarchy.utils";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FetchMekkos } from 'app/mekko/mekko.actions';
import { CUSTOMER_SUPPORT_EXTERNAL_URL, ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER, V3 } from "app/shared/utils/constants";
import { brandAdminRequiredPermissions } from 'app/admin/shared/admin-permission-service';
import {checkPermissions} from 'app/authorization/permissions/permissions.reducers'
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {PpcCarouselComponent, Slide} from "../shared/components/ppc-carousel/ppc-carousel.component";
import { isDefined } from 'app/shared/utils/utils';
import { FetchJourneys } from 'app/journey/journey.actions';
import { FetchOutcomes } from 'app/outcomes-v1/outcomes-v1.actions';
import { FetchOverviewLookalikes } from 'app/lookalikes-v3/lookalike-v3.actions';


const dataLoadingActions = [ FetchComparisons, FetchMekkos, FetchJourneys, FetchOutcomes];
const dataLoadingActionsV3 = [ FetchOverviewAudiences, FetchOverviewLookalikes ];

@UntilDestroy()
@Component({
  selector: 'app-home',
  styleUrls: [ './home.component.sass' ],
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnDestroy {

  hierarchyPath: string;
  slides: Slide[];
  isFullContext: boolean;

  userFullName$ = currentUser(this.store).pipe(filter(isDefined),
    map(u => `${u.first_name} ${u.last_name}`));

  loading$ = combineLatest(
    [...dataLoadingActionsV3, ...dataLoadingActions].map(({ type }) =>
      this.store.select('fetchStates', type).pipe(select(isFetchInFlight)))
  ).pipe(
    map(fetchStates => fetchStates.some(Boolean)));

  canAccessAudiences$ = canAccessFeature(this.store, 'overview');
  canAccessGrow$ = canAccessFeature(this.store, 'grow');

  dialogRef: MatDialogRef<PpcCarouselComponent>;

  constructor(
    public userService: UserService,
    private store: Store<AppState>,
    private router: Router,
    private dialog: MatDialog,
  ) {
    activeContext(this.store).pipe(map(isFullContext)).subscribe(result => this.isFullContext = result)
    activeContext(this.store).pipe(
      filter(isFullContext),
      untilDestroyed(this)
    ).subscribe(() => {
      dataLoadingActionsV3.forEach(action => store.dispatch(new action({ urlVersionNumber: V3 })));
      dataLoadingActions.forEach(action => store.dispatch(new action()));
    })

    combineLatest(activeContext(this.store), this.store.select('permissions'), currentUser(this.store))
      .pipe(untilDestroyed(this))
      .subscribe(([hierarchyContext, permissions, user]) =>  {
        this.hierarchyPath = getPath(hierarchyContext)
        const { clientSlug, regionSlug, brandSlug, productSlug } = getContextSlugs(hierarchyContext)
        this.slides = checkPermissions(permissions, brandAdminRequiredPermissions, user.is_admin)
          ? this.hasBrandAdminSlides(clientSlug, regionSlug)
          : this.noBrandAdminSlides()
        this.dialogRef && this.dialogRef.close()
        if (!productSlug && clientSlug) {
          this.openWidget('brandAndProduct')
        }
      })
  }

  ngOnDestroy() {}

  openWidget(widget: string) {
    this.dialog.closeAll();
    switch (widget) {
      case 'brandAndProduct':
        this.dialogRef = this.dialog.open(PpcCarouselComponent, {data: this.slides, panelClass: "no-padding"})
        break;
      case 'help':
        this.dialogRef = this.dialog.open(PpcCarouselComponent, {data: this.helpSlides(), maxWidth: "65%", maxHeight: "65%", panelClass: "no-padding"})
        break;
      case 'home':
        this.dialogRef = this.dialog.open(PpcCarouselComponent, {data: this.homeScreenSlides(), maxWidth: "65%", maxHeight: "65%", panelClass: "no-padding"})
        break;
    }
  }

  helpSlides() {
    return [{
      title: "Accessing the Help Center",
      image: 'assets/images/home/help_widget-2.png',
      listItems: [
        'To access the Help Center, select your name in the upper right-hand corner of the platform to display your account menu.',
        'Hover your mouse over “Help Center” to display your available options.',
        '<strong>User Guides</strong> - Provides access to the most up-to-date platform user guides housed within LionBox.',
        '<strong>Toolbox Analytics FAQ</strong> - Provides access to the latest Toolbox Analytics FAQ document hosted on LionBox.',
        '<strong>Discovery Support</strong> - Opens a menu of PeopleCloud Discovery support requests available within the Product Support portal allowing submission of questions, defects, and more.',
        '<strong>Toolbox Analytics Support</strong> - Opens a menu of Toolbox Analytics support requests available within the Product Support portal allowing submission for access, questions, defects, and more.'],
      links: [this.supportLink(), this.okButton()]
    }]
  }

  homeScreenSlides() {
    const links = [this.supportLink(), this.okButton()]
    return [{
      title: "Introducing your Home Page",
      image: 'assets/images/home/learning_widget.png',
      listItems: ['Your Home Page is tailored to your individual products to provide a collaborative space to help you and your team understand recent changes and activity within the Insights and Audiences modules.',
        'When new charts are created, in Grow and Overlap, they will appear on your Home Page making it easier for you to find the next time you login.', 'When you create custom or look-a-like audiences in PeopleCloud Discovery, they will also appear on your Home Page within the Recently Created Audiences table.',
        'We’ve also added a support column to the right of the tables to display helpful tips and tricks within the platform, such as this one.'],
      links: links
    }]
  }

  hasBrandAdminSlides(clientSlug: string, regionSlug: string) {
    return [
      {
        title: 'Brand and Product Setup',
        image: 'assets/images/home/widget.png',
        listItems: [
          'Below, use "SETUP BRAND" to navigate to the Brand Management page.',
          'On the Brand Management page, use "ADD BRAND" to enter your brand information.',
          'After you setup your brand, use "ADD PRODUCT" under your new brand to enter your product information.',
          'Use "SAVE" to finish',
          'Click on <i class="fa fa-home"></i> on the left navigation bar to return to your landing page.'
        ],
        links: [
          {
            text: "Setup Brand",
            click: () => {
              this.dialogRef.close()
              this.router.navigate(compact([clientSlug, regionSlug, "brands", "admin"]))
            }
          },
          this.okButton()
        ]
      }
    ]
  }

  supportLink() {
    return {
      text: "Contact Support",
      href: CUSTOMER_SUPPORT_EXTERNAL_URL,
      openInNewTab: true
    }
  }

  okButton() {
    return {
      text: "OK",
      click: () => this.dialogRef.close(),
      classNames: "btn button"
    }
  }

  noBrandAdminSlides() {
    return [
      {
        title: 'Brand and Product Setup',
        image: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/brand-setup.png`,
        imageHeight: "70%",
        height: 'small-height',
        listItems: [
          'It looks like this client and region do not have any brand or products setup.',
          "Please reach out to your client's team admin for assistance.",
          'For general client inquiries, reach out to Product Support.'
        ],
        links: [
          {
            text: "Contact Support",
            href: CUSTOMER_SUPPORT_EXTERNAL_URL,
            openInNewTab: true
          },
          {
            text: "OK",
            click: () => this.dialogRef.close(),
            classNames: "btn button"
          }
        ]
      }
    ]
  }
}
