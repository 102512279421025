import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {VendorPermission} from "../../vendors/vendor-permission.model";
import {groupBy, keyBy, map} from "lodash";
import {PermissionOption} from "../models/permission-option.model";
import {Vendor} from "../../vendors/vendor";
import {Region} from "../../region/region.model";
import {VendorsService} from "app/services/vendors/vendors.service";
import {sortBy} from "app/shared/utils/utils";
import {HierarchyClient} from "app/hierarchy/hierarchy.interface";

interface TableRow {
  vendor: Vendor,
  rules: VendorPermission[],
  selected: boolean
}

@Component({
  selector: 'vendor-permission-table',
  templateUrl: './vendor-permission-table.component.html',
  styleUrls: ['./vendor-permission-table.component.sass']
})
export class VendorPermissionTableComponent implements OnInit, OnChanges {
  @Input() permissions: VendorPermission[];
  @Input() permissionOptions: PermissionOption[];
  @Input() vendors: Vendor[];
  @Input() clients: HierarchyClient[];
  @Input() regions: Region[];
  @Output() selectSource = new EventEmitter<Vendor>();

  public permissionRows: TableRow[];
  public selectedClient: HierarchyClient = null;
  public selectedRegion: Region = null;
  public searchQuery: string = "";
  public totalRow: TableRow;

  public optionCategories: {category: string, options: PermissionOption[]}[];

  constructor(
    private vendorService: VendorsService
  ) {}

  ngOnInit() {
    this.fetchPermissions(null, null);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['permissionOptions']) {
      this.optionCategories = this.prepareOptionCategories(changes['permissionOptions'].currentValue);
      this.preparePermissionRows()
    }
  }

  fetchPermissions(clientId: string, regionId: string) {
    // in this case we want vendor permission data without effecting the store (which the other tab uses)
    this.vendorService.getVendorPermissionsByClientRegion(clientId, regionId).subscribe(
      vendorPermissions => {
        this.permissions = vendorPermissions;
        this.preparePermissionRows();
      }
    )
  }

  assignVendors(vendorIds: string[]): Vendor[] {
    const vendorMap = keyBy(this.vendors, vendor => vendor.id);
    return vendorIds.map( id => vendorMap[id]) as Vendor[];
  }

  emitSelectSource(source: Vendor) {
    this.selectSource.emit(source);
  }

  preparePermissionRows() {
    const vendorPermissions = groupBy(this.permissions, 'vendor_id');
    const permissionKeys = Object.keys(vendorPermissions);

    let permissionRows = sortBy(this.assignVendors(permissionKeys), "name").map(vendor => {
      return {
        vendor: vendor,
        rules: this.preparePermissionRow(vendorPermissions[vendor.id], vendor.id ),
        selected: false
      }
    });
    if (this.searching) {
      permissionRows = permissionRows.filter(row => row.vendor.name.toUpperCase().indexOf(this.searchQuery.toUpperCase()) >= 0);
    }

    this.permissionRows = permissionRows;
    this.totalRow = null;
  }

  selectClientRegion() {
    this.fetchPermissions(this.selectedClientId, this.selectedRegionId);
  }

  permissionRowValues(options: PermissionOption[], permissions: VendorPermission[], vendorId: string) {
    return options.map( option => this.findOrDefault(permissions, option.permission, vendorId));
  }

  selectVendorRowForCombination(row: TableRow) {
    row.selected = !row.selected;

    const selectedRows = this.permissionRows.filter(row => row.selected);
    if (selectedRows.length > 0) {
      const combinedRules = this.combineRowRules(selectedRows);
      const combinedName = selectedRows.reduce( (name, row, i) => i == 0 ? row.vendor.name : name + " & " + row.vendor.name, "");
      this.totalRow = {
        vendor: {name: combinedName} as Vendor,
        rules: combinedRules,
        selected: false
      };
    } else {
      this.totalRow = null;
    }
  }

  private combineRowRules(rows: TableRow[]): VendorPermission[] {
    return rows.map(row => row.rules)
      .reduce( (result, row) => {
        return row.map((rule, i) => {
          const allowed = rule.state == VendorPermission.ALLOW && result[i].state == VendorPermission.ALLOW;
          return new VendorPermission({permission: rule.permission, state: allowed ? VendorPermission.ALLOW : VendorPermission.DENY});
        })
      });
  }

  get selectedClientId(): string {
    return this.selectedClient ? this.selectedClient.id : null;
  }

  get selectedRegionId(): string {
    return this.selectedRegion ? this.selectedRegion.id : null;
  }

  get searching(): boolean {
    return this.searchQuery != null && this.searchQuery != "";
  }

  private preparePermissionRow(permissions: VendorPermission[], vendorId: string): VendorPermission[] {
    return this.permissionOptions.map( option => {
      return this.findOrDefault(permissions, option.permission, vendorId);
    })
  }

  private prepareOptionCategories(options: PermissionOption[]) {
    return map(
      groupBy(options, option => option.option_category ),
      (options, category) => {
        return {
          category: category,
          options: options
        };
      });
  }

  private findOrDefault(permissions: VendorPermission[], permissionCode: string, vendorId: string): VendorPermission {
    const permission = permissions.find( p => p.permission == permissionCode);
    const defaultPermission = {
      vendor_id: vendorId,
      client_id: this.selectedClientId,
      region_id: this.selectedRegionId,
      permission: permissionCode,
      state: VendorPermission.DENY
    } as VendorPermission;
    return permission || defaultPermission;
  }

}
