import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { assign, filter as _filter, find, forEach, get, keys, map as _map } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';

import { AppState } from 'app/reducers';
import * as actions from 'app/index-report/index-report.actions';
import { IndexReportTask, TaskType, ReportTask, OutcomeTask } from 'app/report-tasks/report-tasks.model';
import { IndexReport } from 'app/index-report/index-report.model';
import { taskInProgress } from 'app/report-tasks/report-tasks.utils';
import { IndexReportService } from 'app/index-report/index-report.service';
import { SegmentLike } from 'app/models/segment-like.model';
import { prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { getHierarchyContextFromString, slugsForPath } from 'app/hierarchy/hierarchy.utils';
import { SegmentsHierarchyService } from 'app/segments-hierarchy/segments-hierarchy.service';
import { PERMISSION_INSIGHTS, NAV_AUDIENCES, AUDIENCES_SUB_NAV_OVERVIEW } from 'app/shared/utils/constants';
import { Hierarchy } from 'app/hierarchy/hierarchy.interface';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';
import { ReportTaskService } from 'app/report-tasks/report-tasks.service';

interface NotificationItem {
  taskId: number,
  reportType: string,
  icon: string,
  name: string,
  hierarchy: string,
  date: string,
  actions: Array<{ tooltip: string, icon: string, callback: Function, disabled: boolean }>,
  highlight: boolean,
}

@UntilDestroy()
@Component({
  selector: 'ppc-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.sass']
})
export class NotificationCenterComponent implements OnDestroy {
  @Output() onClose = new EventEmitter();

  list: Array<NotificationItem> = [];
  tasks: Array<ReportTask> = [];
  hierarchy: Hierarchy;
  indexReportActionsInProgress: Array<number>;

  ICON_TOOLTIP_MAP = {
    hourglass: 'Index Report',
    'user-circle-o': 'Outcome Audience',
    question: 'Unknown',
  };

  indexReportActionsInProgress$ = this.store.select('indexReports', 'actionsInProgress');
  hasReportTaskAdminCreatePermission$ = this.store.select('permissions', 'report_task_admin', 'create');

  constructor(
    public store: Store<AppState>,
    public indexReportService: IndexReportService,
    private segmentsHierarchyService: SegmentsHierarchyService,
    private reportTaskService: ReportTaskService,
    private router: Router,
  ) {
    this.store.select('hierarchy', 'hierarchy')
      .pipe(untilDestroyed(this))
      .subscribe(hierarchy => this.hierarchy = hierarchy);

    reportTaskService.tasks$
      .pipe(untilDestroyed(this))
      .subscribe(tasks => {
        this.tasks = tasks;
        // list is used to display notification items on UI
        this.list = this.formNotificationItems(tasks)
      });

    this.store.select('indexReports', 'actionsInProgress')
      .pipe(untilDestroyed(this))
      .subscribe(inProgress => this.indexReportActionsInProgress = inProgress);
  }

  ngOnDestroy() { }

  cancel() {
    this.onClose.emit();
  }

  actionInProgress(item: NotificationItem) {
    if (item.reportType === TaskType.TASK_TYPE_INDEX_REPORT) {
      return this.indexReportActionsInProgress.includes(item.taskId);
    }
    return false;
  }

  downloadIndexReport(item: NotificationItem) {
    this.store.dispatch(new actions.AddActionInProgress(item.taskId));
    const task = find(this.tasks, { id: item.taskId, report_type: item.reportType }) as IndexReportTask;

    forkJoin(
      this.indexReportService.downloadIndexReport(task.request_id),
      this.segmentsHierarchyService.getVendor([PERMISSION_INSIGHTS], task.product_slug)
    ).pipe(
      map(([data, vendorNames]) => {
        forEach(keys(data.segments), identifier => {
          const segmentData = get(data, ['segments', identifier]);
          const segment = {
            name: segmentData.name,
            identifier,
            vendor_name: segmentData.vendor_name,
            vendor_type: segmentData.vendor_type,
            type: segmentData.type,
            path: {
              names: get(data, ['segments', identifier, 'path'])
            },
          } as SegmentLike;
          const partialSegmentPath = this.getPartialSegmentPath(segment, vendorNames);

          assign(segmentData, { partialSegmentPath })
        });
        return [data, vendorNames];
      })
    ).subscribe(data => {
      const [indexReportData, vendorNames] = data;
      const hierarchyContext = this.getHierarchyContext(task.product_slug);
      this.indexReportService.generateWorkbook(
        indexReportData as IndexReport,
        vendorNames as VendorDisplayName[],
        task.layout_type,
        hierarchyContext,
        item.taskId,
        task.allow_dataset_skew,
        task.allow_unknown_rate_skew,
        task.ui_name
      );
      this.store.dispatch(new actions.FetchIndexReport(task.id));
    });
  }

  goToOutcomeAudience(item: NotificationItem) {
    const task = find(this.tasks, { id: item.taskId, report_type: item.reportType }) as OutcomeTask;
    if (!task.read_at) {
      this.reportTaskService.markNotificationAsRead(task.id);
    }
    this.router.navigateByUrl(`/${task.product_slug}/home`, { skipLocationChange: true })
      .then(() => this.router.navigate([task.product_slug, NAV_AUDIENCES, AUDIENCES_SUB_NAV_OVERVIEW]));
  }

  downloadPastDownloadsReport() {
    this.reportTaskService.getPastDownloads().subscribe(res => {
      forEach(res, entry => {
        const context = this.getHierarchyContext(entry.product_slug);
        const slug = `${context.client}/${context.region}/${context.brand}/${context.product}`;
        Object.assign(entry, { product_slug: slug });
      });
      this.reportTaskService.generatePastDownloadsWorkbook(res);
    });
  }

  private formNotificationItems(tasks: Array<ReportTask>): Array<NotificationItem> {
    return _map(tasks, task => {
      const { client, region, brand, product } = this.getHierarchyContext(task.product_slug);
      const taskName = this.getTaskName(task);
      const inProgress = taskInProgress(task);
      return {
        taskId: task.id,
        reportType: task.report_type,
        icon: this.getIconName(task.report_type),
        name: inProgress ? `IN-PROGRESS: ${taskName}` : taskName,
        hierarchy: `${client}/${region}/${brand}/${product}`,
        date: task.created_at,
        actions: this.getActions(task.report_type, inProgress),
        // this is to determine if the list item should be highlighted (white text, lighter background) on UI
        highlight: this.shouldHighlight(task),
      }
    });
  }

  private getHierarchyContext(slug: string) {
    const hierarchyContext = getHierarchyContextFromString(this.hierarchy, slug);
    const productSlug = slugsForPath(slug);

    const client = get(hierarchyContext, 'client.name', productSlug[0]);
    const region = get(hierarchyContext, 'region.name', productSlug[1]);
    const brand = get(hierarchyContext, 'brand.name', productSlug[2]);
    const product = get(hierarchyContext, 'product.name', productSlug[3]);

    return { client, region, brand, product };
  }

  private getTaskName(task: ReportTask): string {
    if (task.report_type === TaskType.TASK_TYPE_INDEX_REPORT) {
      const uiName = (task as IndexReportTask).ui_name;
      return (uiName && uiName.trim()) || task.name.split('|').join(', ');
    }
    return task.name;
  }

  private shouldHighlight(task: ReportTask): boolean {
    if (task.report_type === TaskType.TASK_TYPE_INDEX_REPORT) {
      return !(task as IndexReportTask).downloaded_at;
    } else if (task.report_type === TaskType.TASK_TYPE_OUTCOME_COMPLETED) {
      return !(task as OutcomeTask).read_at;
    }
    return true;
  }

  private getIconName(reportType: string): string {
    if (reportType === TaskType.TASK_TYPE_INDEX_REPORT) {
      return 'hourglass';
    } else if (reportType === TaskType.TASK_TYPE_OUTCOME_COMPLETED) {
      return 'user-circle-o';
    }
    // default unknown type
    return 'question';
  }

  private getActions(reportType: string, inProgress: boolean) {
    if (reportType === TaskType.TASK_TYPE_INDEX_REPORT) {
      return [{
        tooltip: inProgress ? 'Report is currently in-progress' : 'Download',
        icon: 'download',
        callback: this.downloadIndexReport.bind(this),
        disabled: inProgress,
      }];
    } else if (reportType === TaskType.TASK_TYPE_OUTCOME_COMPLETED) {
      return [{
        tooltip: 'Go To',
        icon: 'arrow-right',
        callback: this.goToOutcomeAudience.bind(this),
        disabled: false,
      }];
    }
    return [];
  }

  // note: segment name not included for the download sheet
  private getPartialSegmentPath(segment: SegmentLike, vendorNames: VendorDisplayName[]): string {
    if (!segment) { return };
    return _filter(prettyPathParts(segment, vendorNames), part => part !== segment.name).join(' > ');
  }
}
