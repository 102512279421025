<ppc-detail-header
  [ppcObject]="ppcObject"
  ppcObjectType="Motivation"
  (onClose)="closeSelf()"
></ppc-detail-header>

<main>
  <ppc-detail-info-pane
    [activeRegion]="activeRegion"
    [ppcObject]="ppcObject"
    [panelistDataSourceMessage]="panelistDataSourceMessage"
    [regionIsNotAustralia]="regionIsNotAustralia"
    [canDisplayAddressableCounts]="canDisplayAddressableCounts"
    [panelistCount]="panelistCount"
    (openActivationOverlay)="handleSendToPartner()"
    (sendToTardiis)="handleSendToTardiis()"
    (openIndexReportForm)="handleCreateIndexReport()"
  >
  </ppc-detail-info-pane>
  <div class="lower-info">
    <div class="detail-body-host">
      <div class="detail-body">
      </div>
    </div>

    <div class="details-pane">
      <div class="details">
        <div class="detail-section">
          <ppc-detail-permissions [ppcObject]="ppcObject" 
                                  [drawerContent]="accordionData" 
                                  [initialStep]="initialStep"
                                  [resetAccordionState]="resetAccordionState" >
          </ppc-detail-permissions>
        </div>
      </div>
    </div>
  </div>
</main>
