import { Component, OnInit, OnDestroy } from '@angular/core';
import { map as _map, debounce, merge, keyBy, get, orderBy, omitBy, isNull } from 'lodash';
import { filter, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SegmentPickerService, SegmentPickerOptions } from './segment-picker.service';
import { prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { AppState } from 'app/reducers';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import {SegmentsHierarchyService} from 'app/segments-hierarchy/segments-hierarchy.service';
import { segmentToNode } from './segment-picker.utils';
import { Node } from './root-nodes/root-node.interface';
import {PERMISSION_INSIGHTS} from "../shared/utils/constants";
import { selectClient } from "app/hierarchy/hierarchy.reducers";
import { environment } from 'environments/environment';
import { SegmentLike } from '../models/segment-like.model';
import { isDefined } from '../shared/utils/utils';
import { selectRegion } from '../hierarchy/hierarchy.reducers';
import { segmentToNode as tier3SegmentToNode } from './root-nodes/tier-3-root';
import { SegmentV2 } from '../audiences/discover/segment-v2.model';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';

@UntilDestroy()
@Component({
  selector: 'ppc-segment-picker',
  templateUrl: './segment-picker.component.html',
  styleUrls: ['./segment-picker.component.sass']
})
export class SegmentPickerComponent implements OnInit, OnDestroy {
  value: Set<string> = new Set<string>();
  segments: {[identifier: string]: SegmentLike} = {};
  options: SegmentPickerOptions;
  vendorFilters: string[];
  searchInput: string;
  pageSize: 25 | 50 = 25;
  searchOffset = 0;
  searchResults: Node[];
  searchError = false;
  searchLoading = false;
  searchCount: number;
  vendorOptions: { label: string, value: string, selected: boolean }[]
  isTier3: boolean = environment.isTier3;
  regionSlug: string;
  vendorDisplayNames: VendorDisplayName[];

  debouncedSearch = debounce((searchInput) => {
    if (!searchInput) { return };
    if (searchInput.length < 3) {
      this.snackbar.open("Please enter a search term that is at least 3 characters long", null, {
        duration: 3000,
        panelClass: ["danger"]
      });
      return;
    }
    this.searchResults = null;
    this.searchError = false;
    this.searchLoading = true;
    this.searchOffset = 0;
    this.search();
  }, 800);

  constructor(
    public service: SegmentPickerService,
    private snackbar: MatSnackBar,
    private store: Store<AppState>,
    private http: HttpClient,
    private segmentService: SegmentV2Service,
    private segmentsHierarchyService: SegmentsHierarchyService
  ) {
    store.select('hierarchy').pipe(
      select(selectClient), filter(isDefined), untilDestroyed(this)
    ).subscribe(() => this.fetchVendors());

    store.select('hierarchy').pipe(
      select(selectRegion), filter(isDefined), untilDestroyed(this)
    ).subscribe(region => this.regionSlug = region.slug);

    store.select('segmentsHierarchy', 'vendorDisplayNames').pipe(
      untilDestroyed(this)
    ).subscribe(vendorDisplayNames => this.vendorDisplayNames = vendorDisplayNames);
  }

  ngOnInit() {
    this.service.options$.subscribe(options => {
      this.options = options;
      const currentlySelected = _map(options.currentlySelected, selected => omitBy(selected, isNull))
      this.segments = merge(this.segments, keyBy(currentlySelected, "identifier"));
      this.value = new Set(_map(options.currentlySelected, "identifier"))
      this.clearSearch();
    });
  }

  ngOnDestroy() {}

  fetchVendors() {
    if (this.isTier3) {
      this.vendorFilters = []; // Vendor dropdown not available for tier 3, show all results
    } else {
      this.segmentsHierarchyService.getVendor([PERMISSION_INSIGHTS])
        .subscribe(vendors => {
          this.vendorDisplayNames = vendors;
          this.vendorOptions = _map(vendors, vendor => ({...vendor, selected: true}))
          this.vendorFilters = _map(this.vendorOptions, option => option.value);
        });
    }
  }

  search() {
    this.searchLoading = true;

    return this.segmentService.searchSegments(
      this.searchInput,
      this.pageSize,
      this.searchOffset,
      this.vendorFilters,
      PERMISSION_INSIGHTS,
      this.isTier3
    ).pipe(
      map(({segments, searchCount, hits}) => ({
        segments: this.isTier3 ? _map(segments, s => tier3SegmentToNode(s, this.http, this.regionSlug))
          : _map(segments, s => segmentToNode(s, this.http, [PERMISSION_INSIGHTS], this.regionSlug)),
        searchCount,
        hitsRegEx: new RegExp(`(${orderBy(hits, "length", "desc").join("|")})`, "gi")
      })),
      map(({segments, searchCount, hitsRegEx}) => {
        const highlightedSegments = _map(segments, s => {
          const pathString = prettyPathParts(s as SegmentV2, this.vendorDisplayNames).join(" > ")
          const highlightedPath = pathString.replace(hitsRegEx, "<span class='search-hit'>$1</span>")
          return {
            ...s,
            highlightedPath
          }
        })
        return {segments: highlightedSegments, searchCount}
      })
    )
      .subscribe(
        ({segments, searchCount}) => {
          this.searchCount = searchCount;
          this.searchResults = segments;
          if (searchCount === 0) {
            this.snackbar.open("No results found!", null, {
              duration: 2000,
              panelClass: [
                "danger"
              ]
            });
          }
          this.searchLoading = false;
        },
        error => {
          this.searchLoading = false;
          this.searchResults = null;
          this.searchError = true;
          console.error(error);
        }
      );
  }

  clearSearch() {
    this.searchInput = null;
    this.searchResults = null;
    this.searchError = false;
    this.vendorFilters = _map(this.vendorOptions, option => option.value);
  }

  onCancelClick() {
    this.service.close(null);
    this.value = new Set<string>();
  }

  onSaveClick() {
    this.service.close(Array.from(this.value).map(segment_identifier => this.segments[segment_identifier]));
    this.value = new Set<string>();
  }

  toggleSegment(segment: SegmentLike) {
    if (this.getCount(segment, 'matched') === 0 && !this.value.has(segment.identifier)) {
      // disabled
      return;
    }
    if (this.options.multi) {
      if (this.value.has(segment.identifier)) {
        this.value.delete(segment.identifier);
      } else {
        this.value.add(segment.identifier)
      }
    } else {
      this.value.clear();
      this.value.add(segment.identifier);
    }

    this.segments[segment.identifier] = segment
  }

  get currentlySelectedLabel(): string {
    return this.selectedSegmentIds.map(id => this.segments[id]).map(segment => segment.name).join(", ")
  }

  get selectedSegmentIds(): string[] {
    return Array.from(this.value);
  }

  get isValid(): boolean {
    return !!Array.from(this.value).length
  }

  getCount(node, countType: "matched" | "modeled") {
    return get(node, ["count", countType], 0)
  }

}
