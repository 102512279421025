<div class="icon-picker" (click)="open = !open">
  <div class="selected-icon">
    <ppc-icon [value]="value"></ppc-icon>
  </div>
  <div class="icon-picker-caret">
    <i class="fa fa-caret-down"></i>
  </div>
</div>

<div class="icon-picker-options" [class.open]="open">
  <svg class="icon-picker-options-caret" viewbox="0 0 15 7.5">
      <path d="m 0 7.5 l 7.5 -7.5 l 7.5 7.5" stroke="#5a5a5a" fill="#1D1D1D"></path>
    </svg>
    <div class="icons">
        <div class="icon" *ngFor="let icon of icons" (click)="selectIcon(icon)">
          <ppc-icon [value]="icon"></ppc-icon>
        </div>
      </div>
</div>
