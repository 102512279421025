
import {combineLatest as observableCombineLatest} from 'rxjs';

import { combineLatest, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import {Component, OnDestroy} from "@angular/core";
import {Store, select} from "@ngrx/store";
import {Subject} from "rxjs";
import {AppState} from "app/reducers";
import {
  AttributeAnalysis,
  Competitor,
  ScoredCreative,
  selectActiveCompetitor,
  selectAttributeAnalyses,
  selectAttributeAnalysesForOneCompetitor,
  selectClientCompetitor,
  selectCompetitorId,
  selectCompetitorsForGraph,
  selectScoredCreatives,
  selectAttributeAverages
} from "./creative.reducers";
import {
  ChangeCompetitor,
  FetchCompetitors,
  FetchCreativeAttributes,
  FetchAttributeAverages,
  FetchCreativesByAttribute
} from "./creative.actions";
import {Observable} from "rxjs/Rx";
import {isLoaded, isNotYetFetched} from "app/shared/utils/fetch-state";
import {find, orderBy} from "lodash";
import {BehaviorSubject} from "rxjs";
import {ScoredAttribute} from "app/creative/creative-attribute-list/creative-attribute-list.component";
import {ModelType, modelTypes} from "app/creative/creative.reducers";
import {ChangeModelType} from "app/creative/creative.actions";

type Tab = 'all' | 'top' | 'differentiated' | 'shared';

@Component({
  selector: 'app-creative',
  templateUrl: './creative.component.html',
  styleUrls: ['./creative.component.sass']
})
export class CreativeComponent implements OnDestroy {

  attributeAnalyses$: Observable<AttributeAnalysis[]>;
  hoveredAttribute: string;
  selectedAttributes = new Set<string>();

  competitorBrands: Competitor[] = [];
  selectedCompetitor: Competitor;

  clientCompetitor: Competitor;

  competitorHasData$: Observable<boolean>;
  isDataLoaded$: Observable<boolean>;

  competitorCreatives: ScoredCreative[] = [];
  clientCreatives: ScoredCreative[] = [];

  selectedTab$ = new BehaviorSubject<Tab>('all');
  clearSearch$ = new Subject();
  scoredAttributeList$: Observable<ScoredAttribute[]>;

  modelType$: Observable<ModelType>;
  modelTypes = modelTypes;

  selectedGenomeDetail: {
    attributeId: string,
    show: boolean,
    topClientCreatives?: ScoredCreative[],
    topCompetitorCreatives?: ScoredCreative[],
    analysis?: AttributeAnalysis
  } = {attributeId: null, show: false};

  ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(private store: Store<AppState>) {
    [FetchCompetitors, FetchCreativeAttributes].forEach(
      action => this.store.select('fetchStates', action.type).pipe(
        takeUntil(this.ngUnsubscribe),
        filter(isNotYetFetched), )
        .subscribe(() => this.store.dispatch(new action())));

    this.isDataLoaded$ = this.store.select('fetchStates').pipe(map(
      fetchStates => [FetchCompetitors, FetchCreativeAttributes, FetchAttributeAverages]
        .every(action => isLoaded(fetchStates[action.type]))
    ));

    this.store.select('creative').pipe(
      select(selectAttributeAverages),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(creative_avgs => {
      if (creative_avgs['all'] == void(0)) {
        this.store.dispatch(new FetchAttributeAverages('all'));
      }
      if (creative_avgs['nonClient'] == void(0)) {
        this.store.dispatch(new FetchAttributeAverages('nonClient'));
      }
    });

    observableCombineLatest(
      this.store.select('creative').pipe(select(selectCompetitorsForGraph)),
      this.store.select('creative').pipe(select(selectScoredCreatives)),
      this.store.select('creative').pipe(select(selectCompetitorId)),
      this.store.select('creative').pipe(select(selectClientCompetitor))
    ).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(([competitors, scoredCreatives, selectedCompetitorId, clientCompetitor]) => {
        this.clientCompetitor = clientCompetitor;
        this.selectedCompetitor = find(competitors, {id: selectedCompetitorId});
        if (this.clientCompetitor && this.selectedCompetitor) {

          this.selectedGenomeDetail = {
            show: this.selectedGenomeDetail.show,
            attributeId: this.selectedGenomeDetail.attributeId,
            topClientCreatives: this.filterScoredCreatives(scoredCreatives, this.clientCompetitor.id),
            topCompetitorCreatives: this.filterScoredCreatives(scoredCreatives, this.selectedCompetitor.id)
          }

          this.clientCreatives = this.filterScoredCreatives(scoredCreatives, this.clientCompetitor.id);
          this.competitorCreatives = this.filterScoredCreatives(scoredCreatives, this.selectedCompetitor.id);
        }
      });

    observableCombineLatest(
      this.store.select('creative').pipe(map(selectCompetitorsForGraph)),
      this.store.select('creative').pipe(map(selectCompetitorId)),
    ).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(([allCompetitors, selectedCompetitorId]) => {
        this.competitorBrands = allCompetitors;
        if (!find(allCompetitors, {id: selectedCompetitorId})) {
          this.store.dispatch(new ChangeCompetitor(allCompetitors && allCompetitors[0] && allCompetitors[0].id))
        }
      });


    /* switchMap here is used to alternate between which selector we are using based on whether we want
    to compare the average of all brands or just a singular brand. The observable un and resubscribes
    itself according to the selector. */

    this.attributeAnalyses$ = this.store.select('creative').pipe(
      select(selectActiveCompetitor),
      takeUntil(this.ngUnsubscribe),
      switchMap(activeCompetitor => {
        return this.store.select('creative').pipe(map(
          activeCompetitor && activeCompetitor.id !== null ? selectAttributeAnalysesForOneCompetitor
            : selectAttributeAnalyses
        ))
      }),
    );

    this.scoredAttributeList$ = this.attributeAnalyses$.pipe(
      combineLatest(this.selectedTab$),
      map(([analyses, tab]) => selectList(analyses, tab).map(
        analysis => ({
          ...analysis,
          scores: [
            {color: brandColor, val: analysis.brandScore},
            {color: competitorColor, val: analysis.competitorScore}
          ]
        })
      )
      ), );

    observableCombineLatest(
      this.attributeAnalyses$,
      this.selectedTab$
    ).pipe(
      map(([analyses, tab]) => tab === 'all' ? [] : selectList(analyses, tab)),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(analyses => this.selectAttributes(analyses));

    this.competitorHasData$ = this.attributeAnalyses$.pipe(map(
      analyses => analyses.every(analysis => !isNaN(analysis.competitorScore))));

    this.modelType$ = this.store.select('creative', 'attrModelType')
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  selectCompetitor(e) {
    this.store.dispatch(new ChangeCompetitor(e.value.id))
  }

  selectAttribute(data: AttributeAnalysis) {
    if (data) {
      this.selectedGenomeDetail.show = true;
      this.selectedGenomeDetail.attributeId = data.id;
      this.selectedGenomeDetail.analysis = data;

      const selectedCompetitorId = this.selectedCompetitor.id ? this.selectedCompetitor.id : 'nonClient';

      this.store.dispatch(
        new FetchCreativesByAttribute(data.id, [selectedCompetitorId, this.clientCompetitor.id])
      );
    }
  }

  closeGenomeDetail() {
    this.selectedGenomeDetail.show = false;
  }

  hoverAttribute(id: string) {
    this.hoveredAttribute = id;
  }

  selectAttributes(analyses: AttributeAnalysis[]) {
    this.selectedAttributes = new Set(analyses.map(analysis => analysis.id));
  }

  get highlightedAttributes(): Set<string> {
    return this.hoveredAttribute ? new Set([this.hoveredAttribute])
      : this.selectedAttributes;
  }

  private filterScoredCreatives(scoredCreatives: ScoredCreative[], competitorId: string): ScoredCreative[] {
    return scoredCreatives.filter(
      creative => {
        return competitorId ? creative.competitor_id == competitorId
          : creative.competitor_id != this.clientCompetitor.id;
      }
    )
  }

  changeModelType(modelType: ModelType) {
    return this.store.dispatch(new ChangeModelType(modelType))
  }
}

export const brandColor = 'rgb(74,169,233)';
export const competitorColor = 'rgb(75,193,174)';
const filteredListLength = 10;

function selectList(attributeAnalyses: AttributeAnalysis[], tab): AttributeAnalysis[] {
  switch (tab) {
    case 'all':
      return orderBy(attributeAnalyses, ['avgScore'], ['desc']);
    case 'top':
      return orderBy(attributeAnalyses, ['brandScore'], ['desc']).slice(0, filteredListLength);
    case 'differentiated':
      return topDifferentiated(attributeAnalyses);
    case 'shared':
      return topShared(attributeAnalyses);
    default:
      return attributeAnalyses;
  }
}

function topDifferentiated(analyses: AttributeAnalysis[]): AttributeAnalysis[] {
  return orderBy(analyses, [scoreDifference], ['desc'])
    .slice(0, filteredListLength)
}

function topShared(analyses: AttributeAnalysis[]): AttributeAnalysis[] {
  return orderBy(analyses, [scoreDifference], ['asc'])
    .slice(0, filteredListLength)
}

function scoreDifference(a: AttributeAnalysis): number {
  return Math.abs(a.competitorScore - a.brandScore)
}
