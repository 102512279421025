<epc-modal
  #modal
  title="Create Lookalike Audience"
  primaryButtonTitle="Create"
  [primaryButtonDisabled]="isLookalikeInvalid$ | async"
  (onPrimaryButtonClick)="onSave()"
>
  <div body>
    <div class="lookalike-from">
      <span><span class="prefix">Lookalike from</span> {{ lookalike.seed_name }} </span>
    </div>
    <div class="form">
      <div class="row name">
        <div class="left">
          <i class="fa fa-users"></i>
        </div>
        <div class="right name-field-container">
          <ppc-input
            #nameInput
            [(ngModel)]="lookalike.name"
            [maxLength]="maxNameLength"
            [softEnforceMaxLength]="true"
            [errorMessage]="maxNameLengthErrorMessage"
            [validators]="nameValidations"
            showErrorsIfUntouched=true
            noLabel=true
            spellcheck=false
          >
            <div suffix>
              <i class="fa fa-pencil"></i>
            </div>
          </ppc-input>
        </div>
      </div>
      <div class="row include-seed">
        <div class="left"></div>
        <div class="right">
          <ppc-checkbox [(ngModel)]="lookalike.include_seed_in_model">
            Include Seed Audience
          </ppc-checkbox>
        </div>
      </div>
    </div>
  </div>
</epc-modal>
