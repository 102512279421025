import { environment } from 'environments/environment';
import {Component, OnDestroy, AfterViewChecked} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject, combineLatest, Subject, Observable} from "rxjs";
import {Store, select} from "@ngrx/store";
import {pick, reject} from 'lodash';
import {apiUrl} from "app/services/ppc_http.service";
import {filter, map, take, takeUntil} from "rxjs/operators";
import {AppState} from "app/reducers";
import {HttpClient} from "@angular/common/http";
import {loggedInUser} from "app/users/user.reducer";
import { HierarchyEntity } from 'app/hierarchy/hierarchy.interface';
import { isDefined } from 'app/shared/utils/utils';
import { agencyUrl, requestAccessUrl } from 'app/shared/constants/auth.urls';

interface Agency {
  id: number;
  name: string;
}
type HierarchyEntityPlus = HierarchyEntity & {parent: HierarchyEntity, fullSlug: string, fullName: string}

@Component({
  selector: 'ppc-request-access-wizard',
  templateUrl: './request-access-wizard.component.html',
  styleUrls: ['./request-access-wizard.component.sass']
})
export class RequestAccessWizardComponent implements OnDestroy, AfterViewChecked {

  curStepIdx$ = new BehaviorSubject(0)
  steps$ = new BehaviorSubject<NodeListOf<HTMLElement>>(null)

  isHierarchySelectorOpen = false

  loggedIn$ = this.store.select('users').pipe(select(loggedInUser));

  llData$ = this.route.queryParams.pipe(
    select(params => pick(params, ['llUsername', 'firstName', 'lastName', 'email', 'jobTitle'])),
    filter(data => !!data.llUsername));

  agencies$: Observable<{id: number; name: string}[]>;

  lionLoginUrl = apiUrl('/lion-login');

  requestForm = {
    firstName: '',
    lastName: '',
    llUsername: '',
    email: '',
    agency: {} as Agency,
    jobTitle: '',
    hierarchyNodes: new Set<{fullName: string}>(),
    extraInfo: ''
  }

  destroyed$ = new Subject();

  allHierarchyNode = {fullName: "all", fullSlug: "all", id: "all", name: "all", parent: null, slug: 'all'} as HierarchyEntityPlus;
  iDontKnowHierarchyNode = {fullName: "I Don't Know", fullSlug: "I don't Know", id: "I don't Know", name: "I don't Know", parent: null, slug: 'idk'} as HierarchyEntityPlus;
  isTier3: boolean = environment.isTier3;

  constructor(public route: ActivatedRoute, private store: Store<AppState>, private http: HttpClient) {
    this.curStepIdx$.pipe(filter(step => step > 0), take(1))
      .subscribe(() => {
        this.agencies$ = this.http.get<Agency[]>(agencyUrl()).pipe(
          map(agencies => this.isTier3 ? reject(agencies, {name: 'Publicis PeopleCloud Internal'}) : agencies))
      })

    // Move past the lion login step if we have the required data
    this.llData$.pipe(takeUntil(this.destroyed$))
      .subscribe(userData => {
        this.updateStep(step => Math.max(1, step))
        Object.assign(this.requestForm, userData)
      })

    this.loggedIn$.pipe(takeUntil(this.destroyed$), filter(isDefined))
      .subscribe(userData => {
        this.updateStep(step => Math.max(3, step))
        Object.assign(this.requestForm, {
          firstName: userData.first_name,
          lastName: userData.last_name,
          llUsername: userData.lion_login,
        })
      });

    combineLatest(this.curStepIdx$, this.steps$).pipe(takeUntil(this.destroyed$))
      .subscribe(([ idx, steps ]) =>
        steps && Array.from(steps).forEach(
          (step, i) => {
            step.style.setProperty('transform', `translateX(-${(idx * 100).toFixed(0)}%)`)
            step.style.setProperty('height', idx !== i ? '0px' : '100%')
          }))
  }

  ngAfterViewChecked() {
    const newSteps: NodeListOf<HTMLElement> = document.querySelectorAll('.wizard-container > *')
    const oldSteps = this.steps$.getValue()
    if (oldSteps && oldSteps.length === newSteps.length) {return}
    this.steps$.next(newSteps)
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  lastValidStep(): number {
    let i = 0
    const {firstName, lastName, llUsername, email, agency, jobTitle, hierarchyNodes } = this.requestForm

    if ([firstName, lastName, llUsername, email].every(Boolean)) {i = 1}
    if (agency.name && jobTitle) {i = 2}
    if (hierarchyNodes.size) {i = 3}

    return i
  }

  updateStep(fn: (number) => number) {
    this.curStepIdx$.next(fn(this.curStepIdx$.getValue()))
  }

  nextStep() {
    const step = this.curStepIdx$.getValue()
    this.updateStep(x => Math.min(x + 1,
      this.steps$.getValue().length - 1))
  }

  prevStep() {
    this.updateStep(x => Math.max(x - 1, 0))
  }

  submit() {
    this.http.post(requestAccessUrl(), {
      ...this.requestForm,
      agency: this.requestForm.agency.name,
      hierarchyNames: Array.from(this.requestForm.hierarchyNodes).map(node => node.fullName)
    }).subscribe(
      () => this.nextStep()
    )
  }

  toggleNode(isChecked: boolean, node: HierarchyEntityPlus) {
    this.requestForm.hierarchyNodes[isChecked ? 'delete' : 'add'](node)
  }
}
