import { AudienceV2 } from '../audiences-v2/audience-v2.model';
import { Action } from '@ngrx/store';
import { BuilderAudience } from './audience-builder.models';


export class SetPrebuiltAudience implements Action {
  static readonly type = "AUDIENCE_BUILDER:SET_PREBUILT_AUDIENCE";
  readonly type = SetPrebuiltAudience.type;

  constructor(public audience: BuilderAudience) { }
}

export type ReducerActions = SetPrebuiltAudience;
