import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import * as actions from "app/lookalikes-v3/lookalike-v3.actions";
import { differenceBy, findIndex, values } from 'lodash';
import { createSelector } from "@ngrx/store";
import { groupBy, keyBy } from 'lodash';

export interface State {
  [identifier: string]: LookalikeV3
}

const defaultState = {};

export function reducer(state: State = defaultState, action: actions.All): State {
  switch (action.type) {
    case actions.LoadLookalikes.type:
      const new_lookalikes = keyBy(action.lookalikes, 'id')
      return { ...state, ...new_lookalikes };
    case actions.LoadLookalike.type:
      return { ...state, [action.lookalike.id]: action.lookalike };
    case actions.ClearLookalikes.type:
      return defaultState;
    case actions.LoadUpdatedLookalike.type:
      return { ...state, [action.lookalike.id]: action.lookalike };
    case actions.RemoveLookalike.type:
      delete state[action.data.id];
      return { ...state };
    default:
      return state;
  }
}

export const selectLookalikes = (state: State) => values(state);

export const selectLookalikesByAudience = createSelector(
  selectLookalikes,
  (lookalikes: LookalikeV3[]) => groupBy(lookalikes, lookalike => lookalike.seed_identifier) as {[seedIdentifier: string]: LookalikeV3[]}
);

export const selectLookalikesWithType = createSelector(
  selectLookalikes,
  (lookalikes) => lookalikes.map(lookalike => ({...lookalike, type: "lookalike"}))
);
