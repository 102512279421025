import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { AudienceRowComponent } from './audiences/audience-row/audience-row.component';
import { MekkoRowComponent } from './mekkos/mekko-row/mekko-row.component';
import { ChartsBoxComponent } from './mekkos/charts-box/charts-box.component';
import { AudiencesBoxComponent } from './audiences/audiences-box/audiences-box.component';
import { ProjectsComponent } from './projects/projects.component';

@NgModule({
  imports: [
    RouterModule,
    PpcComponentsModule,
  ],
  declarations: [
    AudienceRowComponent,
    MekkoRowComponent,
    HomeComponent,
    AudiencesBoxComponent,
    ChartsBoxComponent,
    ProjectsComponent
  ],
})

export class HomeModule { }
