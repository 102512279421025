import {Action} from "@ngrx/store";
import { GrowTabState, GrowV3FocusType } from "app/insights/grow-v3/grow.reducer";
import {ChangeContext} from "app/hierarchy/hierarchy.actions";
import { WeboramaNodes, WeboramaDiscussion } from 'app/insights/insights-components/market-level-discussions/weborama-nodes.interface';

export class ResetInsightsState implements Action {
  static readonly type = "GROW:RESET_INSIGHTS_STATE";
  readonly type = ResetInsightsState.type;
}

export class ChangeGrowTabState implements Action {
  static readonly type = "GROW:CHANGE_GROW_TAB_STATE";
  readonly type = ChangeGrowTabState.type;

  constructor(public growTabState: GrowTabState) { }
}

export class SetGrowV3Focus implements Action {
  static readonly type = "GROW:SET_GROW_V3_FOCUS";
  readonly type = SetGrowV3Focus.type;

  constructor(public focus: GrowV3FocusType) { }
}

export class FetchWeboramaNodes implements Action {
  static readonly type = "GROW:FETCH_WEBORAMA_NODES";
  readonly type = FetchWeboramaNodes.type;

  constructor(public weboramaId: string) { }
}

export class LoadWeboramaNodes implements Action {
  static readonly type = "GROW:LOAD_WEBORAMA_NODES";
  readonly type = LoadWeboramaNodes.type;

  constructor(public weboramaId: string, public weboramaNodesResponse: WeboramaNodes) { }
}

export class SaveWeboramaDiscussion implements Action {
  static readonly type = "GROW:SAVE_WEBORAMA_GROUP";
  readonly type = SaveWeboramaDiscussion.type;

  constructor(public weboramaDiscussion: WeboramaDiscussion) { }
}

export class LoadWeboramaDiscussion implements Action {
  static readonly type = "GROW:LOAD_WEBORAMA_GROUP";
  readonly type  = LoadWeboramaDiscussion.type;

  constructor(public weboramaDiscussion: WeboramaDiscussion) { }
}

export class EditWeboramaDiscussion implements Action {
  static readonly type = "GROW:EDIT_WEBORAMA_GROUP";
  readonly type = EditWeboramaDiscussion.type;

  constructor(public weboramaNodes: WeboramaNodes) { }
}

export class SetGrowV3Active implements Action {
  static readonly type = "GROW:SET_GROW_V3_ACTIVE";
  readonly type = SetGrowV3Active.type;

  constructor(public growV3Active: boolean) { }
}

export class ResetWeboramaDiscussions implements Action {
  static readonly type = "GROW:RESET_WEBORAMA_DISCUSSIONS";
  readonly type = ResetWeboramaDiscussions.type;
}

export class FetchBarbs implements Action {
  static readonly type = "GROW:FETCH_BARBS";
  readonly type = FetchBarbs.type;

  constructor() {}
}

export class LoadBarbs implements Action {
  static readonly type = "GROW:LOAD_BARBS";
  readonly type = LoadBarbs.type;

  constructor(public barbs: any) {}
}

export type ReducerActions =
                | ChangeContext | ResetInsightsState
                | ChangeGrowTabState | SetGrowV3Focus
                | LoadWeboramaNodes | LoadWeboramaDiscussion
                | EditWeboramaDiscussion | SetGrowV3Active  | ResetWeboramaDiscussions
                | FetchBarbs | LoadBarbs
