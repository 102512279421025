<div class="ppc-slide-toggle">
  <div class="ppc-slide-toggle-label" [class.active]="!value" (click)="writeValue(false)">
      <ng-content select="[left]"></ng-content>
  </div>
  <div class="ppc-slide-toggle-container" (click)="writeValue(!value)">
    <div class="ppc-slide-toggle-slide" [class.active]="value"></div>
  </div>
  <div class="ppc-slide-toggle-label" [class.active]="value" (click)="writeValue(true)">
      <ng-content select="[right]"></ng-content>
  </div>
</div>
