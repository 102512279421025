<div class="ppc-carousel-wrapper">
  <div class="ppc-carousel" [class.open]="currentSlide == i" *ngFor="let slide of slides; let i=index">
    <div class="ppc-carousel-header">
      <div class="header-left">
        <h1>{{slide.title}}</h1>
        <div>{{slide.description}}</div>
      </div>
      <div class="header-right">
        <i class="fa fa-close fa-lg hoverable" (click)="close()"></i>
      </div>
    </div>
    <div class="ppc-carousel-body" [class.small-height]="slide.height === 'small-height'">
      <div class="nav nav-left" (click)="previous()" [class.invisible]="i === 0">
        <i class="fa fa-lg fa-chevron-left hoverable"></i>
      </div>
      <div class="info-list" *ngIf="slide.listItems">
        <h5 [class.hidden]="!slide.listDescription">{{slide.listDescription}}</h5>
        <div class="list-items-container" *ngFor="let listItem of slide.listItems; let j=index">
          <div class="list-number" *ngIf="!slide.useSlideNumber">{{j + 1}}</div>
          <div class="list-number" *ngIf="slide.useSlideNumber">{{i + 1}}</div>
          <div class="list-description" [innerHTML]="listItem"></div>
        </div>
        <div class="note" *ngIf="slide.note"><b>Note:</b> {{slide.note}}</div>
      </div>
      <div class="carousel-image" [class.full-size]="!slide.listItems">
        <img src="{{slide.image}}" [style.height]="slide.imageHeight" [style.width]="slide.imageWidth" />
      </div>
      <div class="nav nav-right" (click)="next()" [class.invisible]="i === slides.length - 1">
        <i class="fa fa-lg fa-chevron-right hoverable"></i>
      </div>
    </div>
    <div class="ppc-carousel-footer">
      <div class="right-content" *ngIf="slide.links && slide.links.length > 0">
        <div>
          <a *ngFor="let link of slide.links" [ngClass]="link.classNames" (click)="link.click && link.click()"
            [attr.href]="link.href ? link.href : null" [attr.target]="link.openInNewTab ? '_blank' : null">
            {{link.text}}
          </a>
        </div>
      </div>
      <ul class="nav-bubbles" *ngIf="slides.length > 1">
        <li *ngFor="let bubble of slides; let i=index" (click)="currentSlide = i" class="bubble"
          [class.selected]="currentSlide == i"></li>
      </ul>
    </div>
  </div>
</div>
