import {User} from "app/users/user.interface";
import {keyBy} from 'lodash';

export interface Role {
  name: string
  permissions?: UserPermission[]
  users?: User[]
  id?: number
  user_type?: string
}

export interface UserResource {
  name: string
  id?: string
}

export interface UserPermissionResource extends UserResource {
  permission?: UserPermission
}

export interface UserPermission {
  create_access: boolean
  read_access: boolean
  update_access: boolean
  destroy_access: boolean
  resource_id?: string
  role_id?: number
  id?: string
}

export function blankPermission(resource_id: string, role_id: number): UserPermission {
  return {
    create_access: false,
    read_access: false,
    update_access: false,
    destroy_access: false,
    resource_id: resource_id,
    role_id: role_id
  }
}

export class ResourceRole implements Role {
  public name: string;
  public permissions: UserPermission[];
  public resources: UserPermissionResource[];
  public users: User[];
  public id: number;
  public user_type: string;

  constructor(public role: Role, resources: UserPermissionResource[]) {
    this.name = role.name;
    this.users = role.users;
    this.id = role.id;
    this.user_type = role.user_type;

    this.resources = this.prepareResources(role, resources)
  }

  private prepareResources(role: Role, resources: UserPermissionResource[]) {
    const resourceIdToPermission = keyBy(role.permissions, permission => permission.resource_id);
    const role_resources = resources.map(resource => {
      let permission = resourceIdToPermission[resource.id];
      permission = permission || blankPermission(resource.id, role.id);
      return {...resource, permission: permission}
    });
    return role_resources;
  }

  public toRole(): Role {
    return {
      name: this.name,
      id: this.id,
      users: this.users,
      permissions: this.resources.map(resource => resource.permission)
    } as Role
  }
}
