<span *ngIf="isIcon"
      [class]="buttonClass"
      [class.disabled]="disabled"
      (click)="$event.stopPropagation(); !disabled && openDialog($event)">
</span>

<button *ngIf="isButton"
        [disabled]="disabled"
        [class]="buttonClass"
        (click)="$event.stopPropagation(); openDialog($event)">
  {{buttonLabel}}
</button>

<span *ngIf="isCustomTemplate" (click)="openDialog($event)" >
  <ng-content #itemTemplate></ng-content>
</span>