
import { combineLatest as observableCombineLatest, Subject, Observable } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Component, Inject, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { find, uniqBy, map as _map } from 'lodash';
import { buildDiscussionClusters, DiscussionClusters } from 'app/insights/insights-components/market-level-discussions/discussion-cluster-nodes.interface';
import { selectActiveDiscussionClusterNodeId } from 'app/insights/insights-components/market-level-discussions/discussion.reducer'
import * as actions from 'app/insights/insights-components/market-level-discussions/discussion.actions'

import { selectActiveWeboramaNodes } from 'app/insights/grow-v3/grow.reducer';
import { EditWeboramaDiscussion, FetchWeboramaNodes } from 'app/insights/grow-v3/grow.actions';
import { WeboramaNodes } from 'app/insights/insights-components/market-level-discussions/weborama-nodes.interface';
import { isFetchInFlight } from "app/shared/utils/fetch-state";
import { selectSelectedSubMarkets, selectActiveWeboramaId } from 'app/mekko/mekko.reducer';
import { selectSelectedJourneySubMarkets, selectSelectedStages } from 'app/journey/journey.reducer';
import { JourneySubMarket } from 'app/journey/journey.models';
import { SubMarket } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';
import {isDefined} from 'app/shared/utils/utils';
import {INSIGHTS_CONTEXT, INSIGHTS_SUB_FEATURE_WEBORAMA, InsightsContextType} from 'app/insights/insights.constants';
import {canAccessFeature} from "../../../feature-access/feature-access.reducers";

@Component({
  selector: 'ppc-market-level-discussions',
  templateUrl: './market-level-discussions.component.html',
  styleUrls: ['./market-level-discussions.component.sass']
})
export class MarketLevelDiscussionsComponent implements OnDestroy {
  clusterData: any;
  ngUnsubscribe = new Subject();
  weboramaNodes: WeboramaNodes;
  defaultName: string;
  clusterId: string;
  loading$ = observableCombineLatest(
    this.store.select("fetchStates", FetchWeboramaNodes.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.FetchClusterNode.type).pipe(select(isFetchInFlight))
  ).pipe(map(loadingStates => loadingStates.some(Boolean)));
  errorMessage$: Observable<string>;
  clusterNotFound$: Observable<boolean>;
  discussionClusters: DiscussionClusters;
  hasDiscussion: boolean;
  activeDiscussionClusterNodeId: number;
  hasOneDiscussion: boolean;
  selectedSubMarkets$: Observable<(SubMarket | JourneySubMarket)[]>;
  hasWeboramaAccess$: Observable<boolean>;

  constructor(private store: Store<AppState>, @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {
    if (this.insightsContext == "grow") {
      this.selectedSubMarkets$ = this.store.select("mekkos").pipe(select(selectSelectedSubMarkets));
    } else {
      this.selectedSubMarkets$ = this.store.select("journey").pipe(select(selectSelectedJourneySubMarkets));
    }

    this.store.pipe(
      select(selectActiveDiscussionClusterNodeId(this.insightsContext)),
      filter(Boolean),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((activeDiscussionClusterNodeId: number) => {
      this.discussionClusters = null;
      this.activeDiscussionClusterNodeId = activeDiscussionClusterNodeId;
      if (this.activeDiscussionClusterNodeId) {this.store.dispatch(new actions.FetchClusterNode(this.activeDiscussionClusterNodeId)); }
    });

    const hasDiscussion$ = this.selectedSubMarkets$.pipe(map(selectedSubMarkets => selectedSubMarkets && _map(selectedSubMarkets, "discussion_cluster_node_id").includes(this.activeDiscussionClusterNodeId)));
    hasDiscussion$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(hasDiscussion => this.hasDiscussion = hasDiscussion);
    const hasOneDiscussion$ = this.selectedSubMarkets$.pipe(map(selectedSubMarkets => selectedSubMarkets && uniqBy(selectedSubMarkets, "discussion_cluster_node_id").length == 1));
    hasOneDiscussion$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(hasOne => this.hasOneDiscussion = hasOne);

    this.store.select("discussions", "clusterNodes").pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(discussionClusterNodes => {
        const cluster = find(discussionClusterNodes, {id: this.activeDiscussionClusterNodeId});
        this.discussionClusters = buildDiscussionClusters(cluster ? cluster.discussion_clusters : [])
      })

    if (this.insightsContext == "grow") {
      this.store.select("mekkos").pipe(
        select(selectActiveWeboramaId),
        filter(isDefined),
      ).pipe(takeUntil(this.ngUnsubscribe), withLatestFrom(this.store.select("grow", "weboramaNodes")))
        .subscribe(([activeWeboramaId, weboramaNodes]) => {
          if (!weboramaNodes[activeWeboramaId]) {this.store.dispatch(new FetchWeboramaNodes(activeWeboramaId)); }
        });

      const weboramaNodes$ = store.pipe(
        select(selectActiveWeboramaNodes),
        takeUntil(this.ngUnsubscribe),
      )

      weboramaNodes$.subscribe(weboramaNodes => this.weboramaNodes = weboramaNodes)

      this.clusterNotFound$ = observableCombineLatest(
        weboramaNodes$.pipe(filter(isDefined)),
        store.select("mekkos").pipe(select(selectActiveWeboramaId)),
      ).pipe(map(([weboramaNodes, activeWeboramaId]) => activeWeboramaId && !weboramaNodes.nodes.length))

      this.selectedSubMarkets$.pipe(takeUntil(this.ngUnsubscribe), filter(subMarkets => subMarkets && !!subMarkets.length))
        .subscribe(selectedSubMarkets => this.defaultName = selectedSubMarkets[0].name);
    }

    this.errorMessage$ = this.selectedSubMarkets$.pipe(
      filter(subMarkets => subMarkets && !!subMarkets.length),
      withLatestFrom(store.select("mekkos", "selectedSubMarketIds"), hasDiscussion$),
      map(([selectedSubMarkets, selectedSubMarketIds, hasDiscussion]) => {
        const subMarketsHaveSameNodeId = !selectedSubMarkets.every(subMarket => hasDiscussion ? subMarket.discussion_cluster_node_id == selectedSubMarkets[0].discussion_cluster_node_id :
          subMarket.webo_cluster_id === selectedSubMarkets[0].webo_cluster_id);
        if ((this.insightsContext == "grow" ? selectedSubMarketIds.length : _map(selectedSubMarkets, "id").length) && subMarketsHaveSameNodeId) {
          return "Cannot view clusters when two audiences have different remote IDs.";
        }
        if (!selectedSubMarkets[0].discussion_cluster_node_id && (this.insightsContext == "grow" ? !selectedSubMarkets[0].webo_cluster_id : true)) {
          return "Please attach a remote ID to view your clusters.";
        }
      }),
    )

    if (this.insightsContext == "journey") {
      this.store.select("journey").pipe(
        select(selectSelectedStages),
        filter(selectedStages => selectedStages && !!selectedStages.length),
        takeUntil(this.ngUnsubscribe)
      ).subscribe(selectedStages => this.defaultName = selectedStages[0].name);
    }

    this.hasWeboramaAccess$ = canAccessFeature(this.store, INSIGHTS_SUB_FEATURE_WEBORAMA)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  editWeboramaGroup() {
    this.store.dispatch(new EditWeboramaDiscussion(this.weboramaNodes));
  }

  get name() {
    if (this.clusterId && this.hasDiscussion) {
      return this.discussionClusters.names[this.clusterId];
    } else if (this.clusterId && this.weboramaNodes && this.weboramaNodes.weborama_discussion) {
      const subcluster = this.weboramaNodes.weborama_discussion.weborama_subclusters.find(subcluster => Number(subcluster.cluster_id).toString() === this.clusterId)
      if (subcluster) {return subcluster.name; }
    } else if (this.weboramaNodes && this.weboramaNodes.weborama_discussion) {
      return this.weboramaNodes.weborama_discussion.name
    }
    return this.defaultName;
  }

  get reachablePeoplePercent() {
    if (this.clusterId) {
      const reachablePeople = this.hasDiscussion ? this.discussionClusters.reachable_people : this.weboramaNodes.reachable_people;
      return this.clusterId ? reachablePeople[Number(this.clusterId)] : 1;
    }

    return 1;
  }

  get description() {
    if (!this.weboramaNodes || !this.weboramaNodes.weborama_discussion) {return null}
    return this.weboramaNodes.weborama_discussion.description
  }

  get discussionClusterHasNodes() {
    return this.hasOneDiscussion && this.discussionClusters && this.discussionClusters.nodes.length > 0;
  }

  onClusterChange(clusterId: string) {
    this.clusterId = clusterId;
  }
}
