<div class="segment-picker-breadcrumbs" *ngIf="breadcrumbs.length">
  <ng-container  *ngFor="let breadcrumb of breadcrumbs; let last=last; let idx=index">
      <ng-container  *ngIf="idx === 1 && truncatedBreadcrumbs.length">
        <div class="segment-picker-breadcrumb">
            <div class="breadcrumb-dropdown-trigger" [matMenuTriggerFor]="breadcrumbMenu">...</div>
            <mat-menu #breadcrumbMenu="matMenu">
              <button mat-menu-item *ngFor="let breadcrumb of truncatedBreadcrumbs; let idx=index" (click)="sliceLevelsTo(idx + 3)">
                  {{ breadcrumb.name }}
                </button>
            </mat-menu>
        </div>
        <div class="segment-picker-breadcrumb-divider"> > </div>
      </ng-container>
      <div class="segment-picker-breadcrumb" (click)="sliceLevelsTo(idx + 2 + (idx == 0 ? 0 : truncatedBreadcrumbs.length))" [class.last]="last">{{breadcrumb.name}}</div>
      <div class="segment-picker-breadcrumb-divider" *ngIf="!last"> > </div>
  </ng-container>
</div>

<div class="scrollbar-top" #scrollbar *ppcCheckOverflow="let hasXOverflow = hasXOverflow" [class.pad-for-scrollbar]="!hasXOverflow">
  <div [style.width.px]="browserLevels.nativeElement.scrollWidth" *ngIf="browserLevels"></div>
</div>

<div class="browser-levels" *ngIf="levels" #browserLevels>
  <div *ngFor="let level of levels; let i=index" class="browser-level"  [class.has-leaves]="hasLeaves(level)">
    <div class="browser-level-header" *ngIf="showHeaders">
      {{ getLevelHeader(i) }}
    </div>
    <div class="browser-level-options">
      <div *ngIf="!level?.length && !selections[i]?.loadingChildren" class="no-segments">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{{selections[i]?.no_children_message || "No segments found."}}
      </div>
      <div *ngFor="let node of level" 
            class="browser-level-option"  
            (click)="onOptionClick(node, i + 1)"
            [class.is-leaf]="node.path?.is_leaf"
            [class.selected]="selectedSegmentIds?.includes(node.identifier) || selections?.includes(node)">

        <ng-container *ngIf="!node.path?.is_leaf" [ngTemplateOutlet]="parentTemplate" [ngTemplateOutletContext]="{ node: node }"></ng-container>

        <ng-container *ngIf="node.path?.is_leaf" [ngTemplateOutlet]="leafTemplate"
                      [ngTemplateOutletContext]="{ node: node}"></ng-container>

      </div>
    </div>
  </div>
</div>
