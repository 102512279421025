import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewChild,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store, select } from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppState } from "app/reducers";
import { ActivationOverlayService } from './activation-overlay.service';
import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import { AudienceV2 } from 'app/audiences-v2/audience-v2.model';
import { isDV360, Partner } from 'app/models/partners/partner.model';
import { filter, findIndex } from 'lodash';
import { snackbarMessageV2 } from 'app/audiences/shared/audience.constants';
import {ContextSlugs} from "app/hierarchy/hierarchy.interface";
import {SlideInOverlayComponent} from "app/shared/components/slide-in-overlay/slide-in-overlay.component";
import { switchMap } from "rxjs/operators";
import { LoadPartners } from 'app/models/partners/partner.actions';
import { getPartners } from 'app/models/partners/partner.reducer';


@UntilDestroy()
@Component({
  selector: 'ppc-activation-overlay',
  templateUrl: './activation-overlay.component.html',
  styleUrls: ['./activation-overlay.component.sass']
})

export class PpcActivationOverlay implements OnInit, OnChanges, OnDestroy {
  @ViewChild('activationTile') activationTile: any;
  @ViewChild('destinationsOverlay', { static: true }) destinationsOverlay: SlideInOverlayComponent;
  @Input() ppcObject: (LookalikeV3 | AudienceV2);
  @Output() onClose = new EventEmitter();
  @Output() openDestinationsWithPartner = new EventEmitter<any>();
  @Output() addNewDestination = new EventEmitter<any>();
  @Input() itemLabelProp: string = "label";
  @Input() client: string = "";
  @Input() overviewContext;

  partners: Partner[];
  snackbarMessage: { [name: string]: string } = snackbarMessageV2;
  query: string = '';
  searchResults: Partner[];
  serviceError: boolean = false;
  searchInput: string = "";
  currentPartner: Partner;
  isSearching: boolean = false;
  contextSlugs: ContextSlugs = null;

  constructor(
    private activationService: ActivationOverlayService,
    private snackbar: MatSnackBar,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.store.select('hierarchy', 'contextSlugs').pipe(untilDestroyed(this)).subscribe(slugs => {
      this.contextSlugs = slugs;
    });

    this.store.select('partners').pipe(select(getPartners), untilDestroyed(this)).subscribe((partners) => {
      this.partners = partners;
    });
  }

  ngOnChanges(changes) {
    if (changes.overviewContext) {
      this.activationService.fetchPartners(this.overviewContext).subscribe();
    }
  }

  ngOnDestroy() {}

  get ppcObjectName(): string {
    if (this.ppcObject && this.ppcObject.name) {
      return this.ppcObject.name;
    }
    return '';
  }

  get weightedPartners(): Partner[] {
    return filter(this.partners, function(partner) {
      return partner.weight >= 10 || partner.destination_count > 0;
    })
  }

  get filteredItems() {
    const input = this.searchInput;

    if (input === '') {
      return [];
    }

    return filter(this.partners, (item) => {
      return !!input && item['name'].toLowerCase().match(input.toLowerCase()) && !item.removed;
    })
  }

  selectPartner(searchResult: Partner) {
    this.searchInput = searchResult.name;
    this.currentPartner = searchResult;
    this.searchOff();
  }

  setupPartner(): void {
    const index = findIndex(this.partners, { id: this.currentPartner.id });
    const updatedPartner = this.partners[index];
    updatedPartner.weight = 10;

    this.partners.splice(index, 1, updatedPartner);

    this.openDestinations(updatedPartner);
    this.searchInput = '';
    this.currentPartner = undefined;
  }

  clearSearch() {
    this.searchInput = '';
    this.currentPartner = undefined;
  }

  isAudience(ppcItem) {
    return ppcItem.type === "audience";
  }

  isLookalike(ppcItem) {
    return ppcItem.type === "lookalike";
  }

  searchOn() {
    this.isSearching = true;
  }

  searchOff() {
    this.isSearching = false;
  }

  openDestinations(partner) {
    this.openDestinationsWithPartner.emit({partner: partner, ppcObject: this.ppcObject});
  }

  closeOverlay() {
    this.onClose.emit();
    this.clearSearch();
    this.searchOff();
  }

  addDestination(partner) {
    if (partner.name === "The Trade Desk" || isDV360(partner)) {
      this.addNewDestination.emit({partner: partner});
    } else {
      this.activationService.createDestination(this.destData(partner)).subscribe();
    }
  }

  destData(partner) {
    const result = {
      partner_id: partner.id,
      name: partner.name,
      product_permission: {
        client: this.contextSlugs.clientSlug,
        brand: this.contextSlugs.brandSlug,
        region: this.contextSlugs.regionSlug,
        product: this.contextSlugs.productSlug,
      }
    };
    return result;
  }
}
