import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { SegmentBrowserComponent } from './segment-browser/segment-browser.component';
import { SegmentPickerComponent } from './segment-picker.component';
import { SegmentPickerService } from './segment-picker.service';
import { VendorsService } from 'app/services/vendors/vendors.service';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { SegmentsHierarchyService } from "app/segments-hierarchy/segments-hierarchy.service";

@NgModule({
  imports: [
    CommonModule,
    PpcComponentsModule
  ],
  declarations: [
    SegmentBrowserComponent,
    SegmentPickerComponent
  ],
  exports: [
    SegmentBrowserComponent,
    SegmentPickerComponent,
  ],
  providers: [ SegmentPickerService, VendorsService, SegmentV2Service, SegmentsHierarchyService ]
})
export class SegmentPickerModule { }
