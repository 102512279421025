import { Component, OnInit, Input, OnDestroy, Inject, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, Subject } from 'rxjs';
import { sumBy, get, isEmpty, cloneDeep } from 'lodash';

import { Demographic, Tab, Bucket, toMillions } from 'app/insights/insights.models';

import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType, PercentCalculationType } from 'app/insights/insights.constants';
import * as insightsActions from 'app/insights/insights.actions';
import {InsightsCountService} from "app/insights/insights-count.service";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'environments/environment';
import { SortDirection, PpcSortService } from 'app/shared/components/ppc-sort/ppc-sort.service';


@UntilDestroy()
@Component({
  selector: 'ppc-grow-v3-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.sass']
})
export class BarChartComponent implements OnInit, OnDestroy, OnChanges {
  @Input() demographic: Demographic;
  @Input() tab: Tab;
  @Input() segmentCounts: {[identifer: string]: number} = {};
  @Input() sortDirection: SortDirection = 'default';
  @Output() demographicWithPercentEvent = new EventEmitter();

  countsChangedSubscription: Subscription;
  ngUnsubscribe = new Subject();
  isTier3 = environment.isTier3;
  indexMode: boolean;
  percentCalculationType: PercentCalculationType;
  demographicDataList: Demographic['buckets'];

  constructor(public counts: InsightsCountService, private store: Store<AppState>,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType,
    private ppcSortService: PpcSortService) {
    this.store.select("insights", this.insightsContext, "indexMode").pipe(
      untilDestroyed(this)
    ).subscribe(indexMode => this.indexMode = indexMode);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['demographic']) {
      this.refreshDemographicList();
    }
  }

  ngOnInit() {
    this.store.select("insights", this.insightsContext, "percentCalculationType").pipe(
      untilDestroyed(this)
    ).subscribe(percentCalculationType => this.percentCalculationType = percentCalculationType);
    this.refreshDemographicList();
  }
  ngOnDestroy() {}

  get demographicData(): Demographic['buckets'] {
    this.updatePercentage();
    this.demographicWithPercentEvent.emit(this.demographicDataList);
    this.preserveSort();
    return this.demographicDataList;
  }

  updatePercentage() {
    this.demographicDataList.forEach( demoBucket => {
      demoBucket.individualBucketCount = this.getCount(demoBucket);
      demoBucket.individualBucketCountLabel = toMillions(demoBucket.individualBucketCount);
    });
    this.demographicDataList.forEach( demoBucket => {
      demoBucket.percent = this.getPercentage(demoBucket);
    });
  }

  getPercentage(bucket: Bucket) {
    const total = this.percentCalculationType === PercentCalculationType.INSIGHTS_CONTEXT
      ? this.counts.totalCount
      : sumBy(this.demographicDataList, bucket_item => bucket_item.individualBucketCount);
    if (total === 0) {return 0; }
    return bucket.individualBucketCount / total * 100;
  }

  get vertical() {
    if (!this.demographic || this.indexMode) {return false; }
    return /vertical/.test(this.demographic.widget_type);
  }

  toFilter(bucket: Bucket) {
    return {
      shortId: bucket.short_id,
      pathParts: [this.demographic.name, bucket.name],
      type: "demographic-segment",
      demographicId: this.demographic.id,
      tab: this.tab ? this.tab.name : "Demographics"
    }
  }

  toggleFilter(bucket: Bucket) {
    this.store.dispatch(new insightsActions.ToggleFilter(this.toFilter(bucket), this.insightsContext));
  }

  get hasData() {
    if (!this.demographic.buckets.length) {return false; }
    return this.demographic.buckets.some(bucket => this.getCount(bucket) > 0);
  }

  isSelected(bucket: Bucket) {
    return this.counts.hasFilter({shortId: bucket.short_id})
  }

  getCount(bucket: Bucket) {
    return this.counts.getSegmentCount(bucket.short_id)
  }

  get isIdCount() {
    return this.demographic.is_id_count
  }

  trackById(index, item) {
    return item.id
  }

  hasZeroCount(bucket: Bucket) {
    return get(this.segmentCounts, bucket.short_id) === 0;
  }

  preserveSort() {
    if (!isEmpty( this.sortDirection )) {
      this.ppcSortService.sortBarChartData(this.sortDirection, this.demographicDataList);
    }
  }

  refreshDemographicList() {
    this.demographicDataList =  cloneDeep(this.demographic.buckets);
  }
}
