import {throwError as observableThrowError, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Router} from '@angular/router';
import {logout} from 'app/shared/utils/auth-utils';
import {apiUrl} from "app/services/ppc_http.service";
import {get} from 'lodash';
import * as Cookies from "js-cookie";
import { environment } from 'environments/environment'
import { selfUrl } from 'app/shared/constants/auth.urls';

function isLoginError(res: Response): boolean {
  return res.url.includes(apiUrl(''))
         && get(res, 'error.error.refresh_login')
}

function isCsrfError(res: Response): boolean {
  return res.url.includes(apiUrl(''))
         && get(res, 'error.error.message', '').toUpperCase().includes('CSRF')
}

const loginErrorMessages = [
  'Missing JWT token from Authorization header',
  'Invalid or expired JWT',
  'You must be logged in to perform this action'
]

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const csrfToken = Cookies.get('csrf-token') || localStorage.getItem('csrf-token') || ''
    const domain = environment.envName === 'dev' ? 'localhost' : 'publicispeoplecloud.com'
    Cookies.remove('csrf-token', {domain})
    localStorage.setItem('csrf-token', csrfToken)

    if (request.url.includes(apiUrl(''))) {
      request = request.clone({ setHeaders: { 'x-csrf-token': csrfToken },
        withCredentials: true });
    }

    return next.handle(request).pipe(
      catchError(res => {
        if (isLoginError(res) && !this.router.url.includes('/login') && res.url.includes(`self.json?ping=true`)) {
          logout(this.router.url)
        }
        if (isLoginError(res) && !this.router.url.includes('/login') && !res.url.includes(selfUrl().slice(0, -5))) {
          logout(this.router.url)
        }
        if (isCsrfError(res)) {
          localStorage.removeItem('csrf-token')
        }
        return observableThrowError(res);
      }))
  }
}
