<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
</header>
<main>
  <div class="wrapper">

    <h3>Client Management</h3>

    <div class="pull-right right-side">
      <mat-select class="dropdown mat-dropdown" [ngModel]="statusFilter$ | async" (ngModelChange)="statusFilterUpdate($event)">
        <mat-option *ngFor="let option of statusFilterOptions" [value]="option.id">
          {{option.label}}
        </mat-option>
      </mat-select>
    </div>

    <span *ngIf="isClientDemo" ppcTooltip="Please create new Demo Accounts in the Production Environment. Contact Support if any assistance is needed">
      <button class="add-client btn" title="Add Client" [disabled]="true">
        Add Client
      </button>
    </span>

    <button *ngIf="!isClientDemo" class="add-client btn" title="Add Client" (click)="addClient()">
      Add Client
    </button>

    <mat-error *ngFor="let err of errorMessages">
      {{err}}
    </mat-error>

    <!--New Client-->
    <app-client-row *ngIf="clientUnderEdit && !clientUnderEdit.id"
      [isEditing]="true"
      [client]="clientUnderEdit"
      [regions]="regions"
      (cancelEdit)="cancelEdit()"
      [marketLevelOnlyRegions]="marketLevelOnlyRegions">
    </app-client-row>

    <!--Existing Clients-->
    <app-client-row *ngFor="let client of clients$ | async; trackBy: getClientId"
      [isEditing]="client.id === clientUnderEdit?.id"
      [client]="client.id === clientUnderEdit?.id ? clientUnderEdit : client"
      [regions]="regions"
      (edit)="edit($event)"
      (cancelEdit)="cancelEdit()"
      [marketLevelOnlyRegions]="marketLevelOnlyRegions">
    </app-client-row>

  </div>
</main>
