import {Action} from "@ngrx/store";
import {Role, UserPermissionResource, UserPermission, UserResource} from "app/admin/role-admin/role.interface";

export class FetchRoles implements Action {
  static readonly type = 'FETCH_ROLES';
  readonly type = FetchRoles.type;

  constructor() {}
}

export class LoadRoles implements Action {
  static readonly type = 'LOAD_ROLES';
  readonly type = LoadRoles.type;

  constructor(public roles: Role[]) {}
}

export class FetchUserPermissions implements Action {
  static readonly type = 'FETCH_USER_PERMISSIONS';
  readonly type = FetchUserPermissions.type;

  constructor() {}
}

export class LoadUserPermissions implements Action {
  static readonly type = 'LOAD_USER_PERMISSIONS';
  readonly type = LoadUserPermissions.type;

  constructor(public permissions: UserPermission[]) {}
}

export class CreateRole implements Action {
  static readonly type = 'CREATE_ROLE';
  readonly type = CreateRole.type;

  constructor(public role: Role) {}
}

export class FetchUpdateRole implements Action {
  static readonly type = 'FETCH_UPDATE_ROLE';
  readonly type = FetchUpdateRole.type;

  constructor(public role: Role) {}
}

export class UpdateRole implements Action {
  static readonly type = 'UPDATE_ROLE';
  readonly type = UpdateRole.type;

  constructor(public role: Role) {}
}

export class DestroyRole implements Action {
  static readonly type = 'DESTROY_ROLE';
  readonly type = DestroyRole.type;

  constructor(public role: Role) {}
}

export class FetchUserResources implements Action {
  static readonly type = 'FETCH_USER_RESOURCES';
  readonly type = FetchUserResources.type;

  constructor() {}
}

export class LoadUserResources implements Action {
  static readonly type = 'LOAD_USER_RESOURCES';
  readonly type = LoadUserResources.type;

  constructor(public resources: UserPermissionResource[]) {}
}

export type All = FetchRoles | CreateRole | DestroyRole | UpdateRole | LoadRoles
| FetchUserResources | LoadUserResources
| FetchUserPermissions | LoadUserPermissions

