<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>
<header class="measure-v3-header">
  <div class="header-text">
    <div>
      <span class="gold">Outcomes </span><span>Measure</span>
    </div>
  </div>
  <div class="header-selects">
    <div class="header-select">
      <div class="mekko-select-label">Chart</div>
      <ppc-searchable-dropdown #mekkoDropdown *ngIf="mekkos$ | async" label="Select Chart to View" [items]="mekkos$ | async" itemLabelProp="name"
        [(ngModel)]="activeMekkoId" mapFromProperty="id" [allowClear]="false">
        <ng-template #itemTemplate let-item="item">
          <div class="mekko-item" [class.selected]="activeMekkoId === item.id">
            <button class="single-select-item"
                    (click)="setActiveMekko(item); mekkoDropdown.open = false"
                    *ppcCheckOverflow="let hasOverflow=hasXOverflow"
                    [ppcTooltip]="item?.name"
                    [tooltipDisabled]="!hasOverflow"
                    tabIndex="0">
              <i class="fa fa-flag-o" [class.invisible]="!item.default"></i>{{ item?.name }}
            </button>
            </div>
          </ng-template>
      </ppc-searchable-dropdown>
    </div>
    <div class="header-select">
      <ppc-timeframe-selector [timeframes]="outcomeTimeframes"
                              [(ngModel)]="selectedTimeframes"
                              (openCms)="openCms()"
                              (ngModelChange)="setSelectedTimeframes()"></ppc-timeframe-selector>
    </div>
  </div>
</header>

<ppc-filter-bar
  [filters]="filters"
  [allowToggle]="selectedSubMarket"
  [class.show]="filters.length"
  (clearFilters)="clearFilters()"
  (toggleFilter)="toggleFilter($event)">
</ppc-filter-bar>

<div class="measure-v3">
  <div class="measure-v3-tab-header">
    <ppc-tabs class="tabs" [tabSpacing]="10" (tabChange)="changeTab($event)" [activeTab]="measureTab$ | async">
      <ng-template #totalPopulationTabLabelTemplate>
        <div ppcTooltip="Total amount of consumers from data provider" [tooltipDelayTime]="500">
          <div class="tab-label top">
            Total Population
          </div>
          <div class="tab-label bottom">
            <i class="fa fa-user"></i><span>{{ (total$ | async) | number }}</span>
          </div>
        </div>
      </ng-template>
      <ppc-tab name="Total Population" [labelTemplate]="totalPopulationTabLabelTemplate" *ngIf="marketLevelTabsAllowed$ | async"></ppc-tab>
      <ng-template #matchedAddressableTabLabelTemplate>
        <div [ppcTooltip]="isTier3 ? 'Unique Panel IDs' : 'Unique PID matches'" [tooltipDelayTime]="500">
          <div class="tab-label top">
            Matched
          </div>
          <div class="tab-label bottom">
            <i class="fa fa-user"></i><span>{{ (matched$ | async) | number }}</span>
          </div>
        </div>
      </ng-template>
      <ppc-tab name="Matched Addressable" [labelTemplate]="matchedAddressableTabLabelTemplate" *ngIf="personLevelTabsAllowed$ | async"></ppc-tab>
      <ng-template #modeledAddressableTabLabelTemplate>
        <div [ppcTooltip]="isTier3 ? 'Modeled Weights on Panel IDs' : 'Lookalike modeling on PIDs'" [tooltipDelayTime]="500">
          <div class="tab-label top">
            Modeled
          </div>
          <div class="tab-label bottom">
            <i class="fa fa-user"></i><span>{{ (modeled$ | async) | number }}</span>
          </div>
        </div>
      </ng-template>
      <ppc-tab name="Modeled Addressable" [labelTemplate]="modeledAddressableTabLabelTemplate" *ngIf="personLevelTabsAllowed$ | async"></ppc-tab>
    </ppc-tabs>
    <div class="measure-v3-population-info">
      <div class="population">
        <div class="header">{{ previousMonth }} {{ previousTimeframe?.year }} Population</div>
        <div class="value"><i class="fa fa-user"></i> {{ (previousPopulation$ | async) | number }}</div>
      </div>
      <div class="change">
        <div class="header">Population Change</div>
        <div class="value">
          <i class="fa fa-user"></i>
          <span>{{ ((currentPopulation$ | async) - (previousPopulation$ | async)) | number}}</span>
          <i *ngIf="populationChange$ | async" class="fa fa-caret-{{(populationChange$ | async) > 0 ? 'up' : 'down' }}"
            [class.green]="(populationChange$ | async) > 0"
            [class.red]="(populationChange$ | async) < 0"></i>
          <i class="fa fa-unsorted" *ngIf="!(populationChange$ | async)"></i>
          <span>{{(populationChange$ | async) | number:"1.0-1"}}%</span>
        </div>
      </div>
    </div>
  </div>

  <ppc-measure-v3-stacked-container *ngIf="selectedTimeframes?.isRangeMode"></ppc-measure-v3-stacked-container>

  <div class="measure-v3-mekko" *ngIf="!selectedTimeframes?.isRangeMode">
    <div class="color-identifier-container">
      <span class="circle" [style.background]="mekkoColors.owned"></span>Owned
      <span class="circle" [style.background]="mekkoColors.opportunity"></span>Opportunity
      <span class="circle" [style.background]="mekkoColors.none"></span>None
    </div>
    <div class="mekko-container">
      <div class="y-legend">
        <div class="y-legend-label">
          {{ activeMekko?.measurement_label || "Penetration" }}
        </div>
        <div class="y-legend-numbers">
          <div class="legend" *ngFor="let legend of [100,80,60,40,20,0]">
            <div class="number" [ngStyle]="getYLegendPosition(legend)">
              {{legend}}%
            </div>
          </div>
        </div>
      </div>
      <ppc-measure-v3-mekko></ppc-measure-v3-mekko>
    </div>
    <div class="x-legend">
      <div class="legend" *ngFor="let legend of [0,20,40,60,80,100]">
        <span class="number" [ngStyle]="getXLegendPosition(legend)">
          {{legend}}%
        </span>
      </div>
    </div>
  </div>


</div>

<app-slide-in-overlay [active]='cmsOpen' [width]="800">
  <ppc-measure-v3-cms *ngIf="cmsOpen" (closeCms)="closeCms()"></ppc-measure-v3-cms>
</app-slide-in-overlay>
