<ppc-dropdown>
    <div currentlySelected>
      <ng-container *ngIf="!(activeSnapshot$ | async)">Model Library</ng-container>
      <ng-container *ngIf="activeSnapshot$ | async">{{ (activeSnapshot$ | async).name }}</ng-container>
    </div>
    <div dropdown>
        <div *ngIf="!(snapshots$ | async)?.length">No Models have been run yet.</div>
        <ngx-datatable *ngIf="(snapshots$ | async)?.length"
                        [rows]="snapshots$ | async"
                        [rowHeight]="39"
                        columnMode="flex"
                        [rowClass]="getRowClasses"
                        [reorderable]="false"
                        sortType="multi"
                        (select)="select($event)"
                        selectionType="single"
                        [sorts]="tableSorts"
                        (sort)="tableSortChanged($event)">
          <ngx-datatable-column
            prop="status"
            name="Status"
            [flexGrow]="0.65"
            headerClass="amount"
            cellClass="amount"
            [comparator]="statusComparator">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <i class="fa fa-{{getStatusIcon(value)}}" [ppcTooltip]="getStatusTooltip(value)"></i>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Name"
            prop="name"
            [flexGrow]="2"
            headerClass="text"
            [sortable]="false"
            cellClass="text"
            [resizeable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span class="cell-content-overflow"
                    *ppcCheckOverflow="let hasOverflow=hasXOverflow"
                    [ppcTooltip]="value"
                    [tooltipDisabled]="!hasOverflow">
                {{ value }}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Created"
            prop="created_at"
            [flexGrow]="1"
            headerClass="numeric"
            cellClass="numeric"
            [resizeable]="false"
            [comparator]="dateComparator">
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{ formatDate(value) }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [flexGrow]="0.5"
            cellClass="amount"
            [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <button ppcConfirmation [kontinue]="delete.bind(this, row)" *ngIf="canDeleteSnapshot$ | async" mat-menu-item>
                <div class="delete-button">
                    <svg viewbox="0 0 12 13">
                        <path d="m 0 0 l 12 13 m 0 -13 l -12 13" stroke="#51C0E8"></path>
                      </svg>
                </div>
              </button>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
    </div>
  </ppc-dropdown>
