import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "app/reducers";
import { map } from 'rxjs/operators';
import { catchError, tap } from 'rxjs/operators';
import { LoadPartners } from 'app/models/partners/partner.actions';
import { handleError } from "app/shared/utils/http-utils";
import { Observable } from "rxjs";
import { destinationsUrl, fetchAdminPartnersUrl } from 'app/shared/constants/segments.urls';

@Injectable()
export class DestinationsAdminService {
  constructor(private http: HttpClient, private actions$: Actions, private store: Store<AppState>) { }

  fetchAdminPartners(client_context): Observable<any> {
    return this.http.get(fetchAdminPartnersUrl(), { params: client_context })
      .pipe(
        tap((res: any) => {
          this.store.dispatch(new LoadPartners(res.data))
        }),
        catchError(handleError));
  }

  fetchDestinations(partner_id): Observable<any> {
    return this.http.get(destinationsUrl(), { params: { partner_id: partner_id } })
      .pipe(
        map((res: any) => res.data),
        catchError(handleError), );
  }

  fetchActivationDestinations(partner_id, audience_id): Observable <any> {
    return this.http.get(destinationsUrl(), { params: {identifier: audience_id, partner_id: partner_id} })
      .pipe(
        map((res: any) => res.data),
        catchError(handleError), );
  }

  createDestination(params): Observable<any> {
    return this.http.post(destinationsUrl() + '/admin_create', params)
      .map((res) => {
        this.fetchAdminPartners(params.product_permission).subscribe();
        return res
      });
  }
}
