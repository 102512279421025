import {Injectable, TemplateRef} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {Observable} from "rxjs";
import {MaterialModalComponent} from "./material-modal.component";

@Injectable()
export class MaterialModalService {

  constructor(private dialog: MatDialog) {
  }

  public confirm(dialogTitleText: string, dialogBodyText: string, dialogCancelText: string, dialogConfirmText: string, dialogTemplate?: TemplateRef<any>): Observable<boolean> {
    const dialogRef = this.dialog.open(MaterialModalComponent);
    dialogRef.componentInstance.dialogTitleText = dialogTitleText;
    dialogRef.componentInstance.dialogBodyText = dialogBodyText;
    dialogRef.componentInstance.dialogCancelText = dialogCancelText;
    dialogRef.componentInstance.dialogConfirmText = dialogConfirmText;
    dialogRef.componentInstance.dialogTemplate = dialogTemplate;
    return dialogRef.afterClosed();
  }

}
