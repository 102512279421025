import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { debounceTime, delay, distinctUntilChanged, filter, map, scan, startWith } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';

import { getActivationsList, getPageInfo } from './activation-management.reducer';
import { AppState } from 'app/reducers';
import { isFetchInFlight } from 'app/shared/utils/fetch-state';
import { FetchActivations, UpdateActivationState } from './activation-management.actions';
import { PageInfo } from 'app/models/paginable.model';
import { MatSelect } from '@angular/material/select';
import { ActivationManagementService } from './activation-management.service';
import { sortBy } from 'lodash';
import { ActivationSelectableState } from './activation.constants';
import { stateColors } from '../activation-v2-common/activation-v2-common.constants';
import { changePage } from '../activation-v2-common/activation-v2-common.utils';

type Page = Pick<PageInfo, 'offset' | 'limit'>;

@UntilDestroy()
@Component({
  selector: 'ppc-activation-management',
  templateUrl: './activation-management.component.html',
  styleUrls: ['./activation-management.component.sass', '../activation-v2-common/management.sass'],
})
export class ActivationManagementComponent implements AfterViewInit, OnDestroy {
  @ViewChild('sourceSystemDropdown') sourceSystemDropdown: MatSelect;
  @ViewChild('companyIdDropdown') companyIdDropdown: MatSelect;
  @ViewChild('stateDropdown') stateDropdown: MatSelect;

  sourceSystems$ = this.activationManagementService
    .getSourceSystems()
    .pipe(map((sourceSystems) => sortBy(sourceSystems, 'name')));
  companies$ = this.activationManagementService.getCompanies().pipe(map((companies) => sortBy(companies, 'id')));

  activations$ = this.store.select('activationManagement').pipe(map(getActivationsList));
  pageInfo$ = this.store.select('activationManagement').pipe(map(getPageInfo));
  loading$ = combineLatest([
    this.store.select('fetchStates', FetchActivations.type).pipe(select(isFetchInFlight)),
    this.store.select('fetchStates', UpdateActivationState.type).pipe(select(isFetchInFlight)),
  ]).pipe(map((loadingStates) => loadingStates.some(Boolean)));
  sort$ = new BehaviorSubject('');
  selectedPage$ = new BehaviorSubject<Page>({});
  changePage = changePage;
  searchTerm$ = new Subject<string>();
  searchTerm: string;
  selectableStates = [ActivationSelectableState.PENDING, ActivationSelectableState.COMPLETE, ActivationSelectableState.ERROR];

  constructor(private store: Store<AppState>, private activationManagementService: ActivationManagementService) {}

  ngAfterViewInit(): void {
    const getSelectedDropdownItem = (matSelect: MatSelect) =>
      matSelect.optionSelectionChanges.pipe(
        filter((event) => event.isUserInput),
        map((event) => event.source.value),
        startWith(ActivationSelectableState.ALL)
      );

    const selectedSourceSystem$ = getSelectedDropdownItem(this.sourceSystemDropdown);
    const selectedCompanyId$ = getSelectedDropdownItem(this.companyIdDropdown);
    const selectedState$ = getSelectedDropdownItem(this.stateDropdown);

    const convertAllToNull = (value) => (value === ActivationSelectableState.ALL ? null : value);

    combineLatest([
      this.sort$,
      this.selectedPage$,
      selectedSourceSystem$,
      selectedCompanyId$.pipe(distinctUntilChanged()),
      selectedState$,
      this.searchTerm$.pipe(debounceTime(300), startWith('')),
    ])
      .pipe(
        delay(0),
        map(([sort, pageInfo, sourceSystem, companyId, state, searchTerm]) => ({
          ...pageInfo,
          sort,
          sourceSystem: convertAllToNull(sourceSystem),
          companyId: convertAllToNull(companyId),
          state: convertAllToNull(state),
          searchTerm,
        })),
        scan((prev, curr) => {
          for (const name of ['sort', 'sourceSystem', 'companyId', 'state', 'searchTerm']) {
            if (prev[name] !== curr[name]) {
              return { ...curr, offset: 0 };
            }
          }
          return curr;
        }),
        untilDestroyed(this)
      )
      .subscribe((fetchOptions) => this.store.dispatch(new FetchActivations(fetchOptions)));
  }

  ngOnDestroy(): void {}

  getState(state: string): string {
    if (state === 'attribute_created' || state === 'membership_delivered_to_adp') {
      return 'pending';
    }
    return state;
  }

  getStateColor(state: string) {
    return stateColors[this.getState(state)];
  }

  isPending(state: string) {
    return this.getState(state) === 'pending';
  }

  updateActivationState(id: number) {
    this.store.dispatch(new UpdateActivationState(id, 'complete'));
  }

  onSort({ column, newValue }) {
    const propSortMapping = {
      'audience.sourceSystemAudienceId': 'audienceId',
      'audience.name': 'audienceName',
      'audience.companyId': 'companyId',
      'audience.sourceSystem': 'sourceSystem',
      'audience.casAttributeId': 'casAttributeId',
      id: 'activationId',
      createdAt: 'createdAt',
      state: 'state',
      destinationName: 'destinationName',
      platformName: 'platformName',
    };
    let sortDescriptor = propSortMapping[column?.prop];
    if (newValue === 'desc') {
      sortDescriptor = `-${sortDescriptor}`;
    }
    this.sort$.next(sortDescriptor);
  }

  search(event) {
    this.searchTerm$.next(event);
  }
}
