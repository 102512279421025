<ng-container>
  <div class="activation-tile" (click)="sendToPartner(partner)" [ngClass]="statusColorClass">
    <div class="logo-container">
      <img *ngIf="partner.logo_url" [src]="partner.logo_url" />

      <div class="generic-partner-logo" *ngIf="!partner.logo_url">{{logoLetter}}</div>
    </div>

    <div class="partner-name-container">

      <h3>{{partner.name}}</h3>
      <p>{{partner.number_of_destinations}}</p>

    </div>
  </div>
</ng-container>
