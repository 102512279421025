<div class="client-region-select" ><p>Select Client and Region</p>
  <mat-form-field>
      <mat-select placeholder="Client" [(ngModel)]="selectedClient" name="clientSelect" (selectionChange)="selectClientRegion()">
          <mat-option [value]="null">ALL</mat-option>
          <mat-option *ngFor="let client of clients" [value]="client">{{client.name}}</mat-option>
        </mat-select>
  </mat-form-field>
  <mat-form-field>
      <mat-select placeholder="Region" [(ngModel)]="selectedRegion" name="regionSelect" (selectionChange)="selectClientRegion()">
          <mat-option [value]="null">ALL</mat-option>
          <mat-option *ngFor="let region of regions" [value]="region">{{region.name}}</mat-option>
        </mat-select>
  </mat-form-field>
  
</div>

<table>
  <th> Source
    <input matInput type="text" [(ngModel)]="searchQuery" placeholder="Search" (ngModelChange)="preparePermissionRows()">
  </th>
  <ng-container *ngFor="let category of optionCategories">
    <th *ngFor="let option of category.options; let header_index = index" [class.vertical-line]="header_index == 0">
      <p *ngIf="header_index == 0">{{category.category}}</p>
      {{option.name}}
    </th>
  </ng-container>
  <tr *ngFor="let row of permissionRows; let i = index">
    <td>
      <span (click)="emitSelectSource(row.vendor)">{{row.vendor.name}}</span>
      <ppc-checkbox [checked]="row.selected" (change)="selectVendorRowForCombination(row)"></ppc-checkbox>
    </td>
    <td *ngFor="let value of permissionRowValues(permissionOptions, row.rules, row.vendor.id); let i = index" >
      <p class="hide-unless-hover">{{permissionOptions[i].name}}</p>
      <i class="fa"
         [class.fa-times]="value.state == 'deny'"
         [class.fa-check-circle]="value.state == 'allow'"
      ></i>
      <span
        class="hide-unless-hover"
        [class.red]="value.state == 'deny'"
        [class.green]="value.state == 'allow'"
      >{{value.state}}</span>
    </td>
  </tr>
  <!-- row showing combined permissions for selected vendors -->
  <tr *ngIf="totalRow">
    <td><span class="combine-row">{{totalRow.vendor.name}}</span></td>
    <td *ngFor="let value of permissionRowValues(permissionOptions, totalRow.rules, ''); let i = index" >
      <p class="hide-unless-hover">{{permissionOptions[i].name}}</p>
      <i class="fa"
         [class.fa-times]="value.state == 'deny'"
         [class.fa-check-circle]="value.state == 'allow'"
      ></i>
      <span
        class="hide-unless-hover"
        [class.red]="value.state == 'deny'"
        [class.green]="value.state == 'allow'"
      >{{value.state}}</span>
    </td>
  </tr>
</table>
