import {merge as observableMerge} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Role, UserPermissionResource, ResourceRole} from "app/admin/role-admin/role.interface";
import {AppState} from "app/reducers/index";
import {Store} from "@ngrx/store";
import {
  FetchRoles, FetchUpdateRole,
  FetchUserResources, DestroyRole, CreateRole
} from "app/admin/role-admin/service/user-role.actions";
import {selectRolesWithResources} from "app/admin/role-admin/service/user-role.reducer";
import {Subject} from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import {fetchOutcome} from "app/shared/utils/fetch-state";
import {Actions} from "@ngrx/effects";
import { ApiKeysService } from 'app/admin/api-keys/api-keys.service';
import { SetApiKeySecret } from 'app/admin/api-keys/api-keys.actions'
import { get } from 'lodash';
import { MatTable, MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ppc-role-admin',
  templateUrl: './role-admin.component.html',
  styleUrls: ['./role-admin.component.sass']
})
export class RoleAdminComponent implements OnInit, OnDestroy {

  @ViewChild(MatTable) table!: MatTable<UserPermissionResource>;
  dataSource: MatTableDataSource<UserPermissionResource>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['Resource Name', 'Create', 'Read', 'Update', 'Delete', 'Description'];

  private ngUnsubscribe = new Subject();

  roles: ResourceRole[];
  selectedRole: ResourceRole;
  newRoleName: string;
  newRoleUserType: string;
  newRoleFormOpen = false;
  resourcesQuery = '';
  apiKeySecret$ = this.store.select('apiKeys', 'apiKeySecret');
  userId: number;

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private apiKeysService: ApiKeysService,
  ) {
    this.store.select('roles').pipe(
      takeUntil(this.ngUnsubscribe),
      map(selectRolesWithResources), ).subscribe(
      roles => {
        this.roles = roles;
        if (this.selectedRole) {this.refreshSelectedRole(); }
      });

    observableMerge(
      this.actions$.pipe((fetchOutcome(CreateRole.type))),
      this.actions$.pipe((fetchOutcome(DestroyRole.type))),
      this.actions$.pipe((fetchOutcome(FetchUpdateRole.type)))
    ).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (completedFetch) => {
          let message;
          switch (completedFetch.fetchAction.type) {
            case CreateRole.type:
              message = "Role Created";
              if (completedFetch.fetchAction['role'] &&
                completedFetch.fetchAction['role']['user_type'] == "machine") {
                this.createApiKey(completedFetch);
                message += ". Api Key Generated."
              }
              break;
            case DestroyRole.type: message = "Role Deleted"; break;
            case FetchUpdateRole.type: message = "Role Saved"; break;
          }
          !!message && this.snackbarSuccess(message);
        },
        (completedFetch) => {
          let message;
          switch (completedFetch.fetchAction.type) {
            case CreateRole.type: message = "Failed to Create Role"; break;
            case DestroyRole.type: message = "Failed to Delete Role"; break;
            case FetchUpdateRole.type: message = "Failed to Save Role"; break;
          }
          !!message && this.snackbarFailure(message);
        }
      );
  }



  ngOnInit() {
    this.store.dispatch(new FetchRoles());
    this.store.dispatch(new FetchUserResources());
    this.store.select('users', 'loginUserId')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(id => this.userId = id);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addRole() {
    this.newRoleName = "";
    // Only roles for api keys are allowed to be dynamically created.
    // User roles must be created through backend data processes.
    this.newRoleUserType = 'machine';
    this.newRoleFormOpen = true;
  }

  saveNewRole() {
    if (this.isInputNameValid(this.newRoleName) && this.newRoleUserType !== 'human') {
      this.store.dispatch(new CreateRole({name: this.newRoleName.trim(), user_type: this.newRoleUserType} as Role));
      this.removeNewRole();
    }
  }

  removeNewRole() {
    this.newRoleName = null;
    this.newRoleFormOpen = false;
  }

  destroyRole(role: ResourceRole) {
    this.store.dispatch(new DestroyRole(role));
  }

  updateRole(role: ResourceRole) {
    this.store.dispatch(new FetchUpdateRole(role.toRole()));
  }

  isInputNameValid(inputName: string): boolean {
    return !!inputName && !!inputName.trim();
  }

  trackById(element: {id: string}): string {
    return element.id;
  }

  toggleCRUD(resource: UserPermissionResource) {
    resource.permission.create_access = !resource.permission.create_access;
    resource.permission.destroy_access = !resource.permission.destroy_access;
    resource.permission.read_access = !resource.permission.read_access;
    resource.permission.update_access = !resource.permission.update_access;
  }

  isMachine(role: ResourceRole): boolean {
    return get(role, 'user_type') === 'machine'
  }

  private createApiKey(completedFetch) {
    const newMachineRole = completedFetch.fetchAction['role'];
    const role_id = completedFetch['result']['roles'].find(r => r.name == newMachineRole.name).id;
    const user_id = this.userId;
    const name = `${newMachineRole.name} APIKEY`;
    this.apiKeysService.addApiKey({role_id, user_id, name}).subscribe(
      res => {
        this.store.dispatch(new SetApiKeySecret(res));
        console.info(`Api Key Issued Successfully.`);
      },
      err => console.error(`Api Key Issue Failed Spectacularly. ${err}`)
    )
  }

  private filterQuery(query: string, subject: string): boolean {
    return subject.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  }

  private refreshSelectedRole() {
    this.selectedRole = this.roles.find(role => role.id == this.selectedRole.id);
    this.resourcesQuery = "";
  }

  private snackbarSuccess(message: string) {
    this.snackbar.open(message, "OK", {duration: 3000, panelClass: ['check']});
  }

  private snackbarFailure(message: string) {
    this.snackbar.open(message, "OK", {duration: 3000, panelClass: ['danger']})
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  get getDataSource(): MatTableDataSource<UserPermissionResource> {
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource(this.selectedRole?.resources);
      return this.dataSource;
    } else {
      return this.dataSource;
    }
  }

}
