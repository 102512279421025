<div class="grow-v3-mekko" *ngLet="(isCompareMode$ | async) as isCompareMode">
  <ppc-tabs class="tabs"
           [tabSpacing]="10"
           (tabChange)="changeTab($event)"
           [activeTab]="activeTab$ | async">
    <ng-template #totalPopulationTabLabelTemplate>
      <div ppcTooltip="Total amount of consumers from data provider" [tooltipDelayTime]="500">
        <div class="tab-label top">
          Total Population
        </div>
        <div class="tab-label bottom" *ngIf="!isCompareMode">
          <i class="fa fa-user" *ngIf="isPeopleCount$ | async"></i>
          <i class="fa fa-tv" *ngIf="!(isPeopleCount$ | async)"></i>
          <span>
            {{ isBelowTvMinimum(growCounts.totalPopulation) ? "—" : (growCounts.totalPopulation | number) }}
          </span>
        </div>
      </div>
    </ng-template>
    <ppc-tab name="Total Population" [labelTemplate]="totalPopulationTabLabelTemplate" *ngIf="isTabTypeAllowed('Market Level')"></ppc-tab>
      <ng-template #matchedAddressableTabLabelTemplate>
      <div [ppcTooltip]="isTier3 ? 'Unique Panel IDs' : 'Unique PID matches'" [tooltipDelayTime]="500">
        <div class="tab-label top">
          Matched
        </div>
        <div class="tab-label bottom" *ngIf="!isCompareMode">
          <i class="fa fa-user" *ngIf="isPeopleCount$ | async"></i>
          <i class="fa fa-tv" *ngIf="!(isPeopleCount$ | async)"></i>
          <span>
            {{ isBelowTvMinimum(growCounts.totalMatchedCount) ? "—" : (growCounts.totalMatchedCount | number) }}
          </span>
        </div>
      </div>
    </ng-template>
    <ppc-tab name="Matched Addressable" [labelTemplate]="matchedAddressableTabLabelTemplate"  *ngIf="isTabTypeAllowed('Person Level')"></ppc-tab>
    <ng-template #modeledAddressableTabLabelTemplate>
      <div [ppcTooltip]="isTier3 ? 'Modeled Weights on Panel IDs' : 'Lookalike modeling on PIDs'" [tooltipDelayTime]="500">
        <div class="tab-label top">
          Modeled
        </div>
        <div class="tab-label bottom" *ngIf="!isCompareMode">
          <i class="fa fa-user" *ngIf="isPeopleCount$ | async"></i>
          <i class="fa fa-tv" *ngIf="!(isPeopleCount$ | async)"></i>
          <span>
            {{ isBelowTvMinimum(growCounts.totalModeledCount) ? "—" : (growCounts.totalModeledCount | number) }}
          </span>
        </div>
      </div>
    </ng-template>
    <ppc-tab name="Modeled Addressable" [labelTemplate]="modeledAddressableTabLabelTemplate"  *ngIf="isTabTypeAllowed('Person Level')"></ppc-tab>
  </ppc-tabs>

  <div class="mekko-chart mekko-focused"  [class.mekko-focused]="(focusedComponent$ | async) == 'mekko'">

    <div class="color-identifier-container">
      <span class="circle" [style.background]="mekkoColors.owned"></span>Owned
      <span class="circle" [style.background]="mekkoColors.opportunity"></span>Opportunity
      <span class="circle" [style.background]="mekkoColors.none"></span>None
    </div>

    <ppc-mekko-builder></ppc-mekko-builder>
  </div>
</div>
