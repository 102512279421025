import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { apiUrl, PpcHttpService } from 'app/services/ppc_http.service';
import { ACTIVATION_API_PATH, V1 } from 'app/shared/utils/constants';
import { fetchResource } from 'app/shared/utils/fetch-state';
import { Activation } from './activation.model';
import {
  FetchActivations,
  LoadActivations,
  UpdateActivationState,
  LoadUpdatedActivation,
} from './activation-management.actions';
import { PaginableData } from '../../models/paginable.model';
import { FetchOptions } from './activation-management.actions';
import { forEach, isString, omit, tap } from 'lodash';

export function adminActivationsUrl(): string {
  return apiUrl(`${ACTIVATION_API_PATH}/${V1}/activations/admin`);
}

export function activationsUrl(): string {
  return apiUrl(`${ACTIVATION_API_PATH}/${V1}/activations`);
}

@Injectable()
export class ActivationManagementService {
  @Effect()
  fetchActivation$ = this.actions$.pipe(
    ofType(FetchActivations.type),
    fetchResource((action) =>
      this.getActivations(action.fetchOptions).pipe(map(({ data, page }) => new LoadActivations(data, page)))
    )
  );

  @Effect()
  updateActivationState$ = this.actions$.pipe(
    ofType(UpdateActivationState.type),
    fetchResource((action) =>
      this.updateActivationState(action.activationId, action.state).pipe(
        map((activation) => new LoadUpdatedActivation(activation))
      )
    )
  );

  constructor(private http: PpcHttpService, private actions$: Actions) {}

  getActivations(fetchOptions: FetchOptions = {}): Observable<PaginableData<Activation>> {
    const queryParams: string[] = [];
    const addQueryParams = (value: string | number, name: string) => {
      if (value == null) {
        return;
      }
      if (isString(value)) {
        value = encodeURIComponent(value);
      }
      queryParams.push(`${name}=${value}`);
    };

    forEach(omit(fetchOptions, 'state'), addQueryParams);

    const { state } = fetchOptions;
    const apiState = state === 'pending' ? `${state},attribute_created,membership_delivered_to_adp` : state;
    addQueryParams(apiState, 'state');

    const queryString = queryParams.join('&');
    const url = adminActivationsUrl() + '?' + queryString;

    return this.http.get(url);
  }

  updateActivationState(activationId: number, state: string): Observable<Activation> {
    const body = {
      activationId,
      state,
    };
    return this.http.patch(activationsUrl(), body);
  }

  getCompanies() {
    return this.http.get(apiUrl(`${ACTIVATION_API_PATH}/${V1}/companies`));
  }

  getSourceSystems() {
    return this.http.get(apiUrl(`${ACTIVATION_API_PATH}/${V1}/sourceSystems`));
  }
}
