import { Action } from "@ngrx/store";

import { AuthPermission } from "../../shared/interfaces/auth-permission";

export class FetchPermissions implements Action {
  static readonly type = 'FETCH_PERMISSIONS';
  readonly type = FetchPermissions.type;
}

export class LoadPermissions implements Action {
  static readonly type = 'LOAD_PERMISSIONS';
  readonly type = LoadPermissions.type;

  constructor(public permissions: { [name: string]: AuthPermission }) {}
}

export type All = FetchPermissions | LoadPermissions;
