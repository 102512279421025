
import { map, takeUntil } from 'rxjs/operators';
import {Component, Input, Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import {PlanImportStatus} from "./plan-import-status";
import * as moment from 'moment'
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { AppState } from "app/reducers/index";

@Component({
  selector: 'import-scenario-plan',
  templateUrl: './import-scenario-plan.component.html',
  styleUrls: ['./import-scenario-plan.component.sass']
})
export class ImportScenarioPlanComponent implements OnInit, OnDestroy {

  @Input() importedPlans: PlanImportStatus[] = [];
  @Output() planImport = new EventEmitter<string>();
  @Output() planImportDelete = new EventEmitter<string>();
  scenarioPlannerId: string;
  ngUnsubscribe: Subject<boolean> = new Subject();
  hasDeletePermission: boolean;

  constructor(private store: Store<AppState>) { };

  ngOnInit() {
    this.store.select("permissions").pipe(
      map(permission => permission.plans),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(
        (permission) => {
          if (permission) {this.hasDeletePermission = permission.destroy; }
        })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  statusClass(planStatus: string): string {
    switch (planStatus) {
      case "Complete" : return "complete";
      case "Submitted" : return "importing";
      case "Failed" : return "failed";
      default: return "";
    }
  }

  importPlan() {
    this.planImport.emit(this.scenarioPlannerId);
  }

  deleteImport(spPlanId: string) {
    if (!this.hasDeletePermission) {return}
    this.planImportDelete.emit(spPlanId);
  }

  planImportStatus(importedPlan: PlanImportStatus): string {
    if (importedPlan.import_success == void(0)) {
      return "Submitted";
    } else {
      return importedPlan.import_success ? "Complete" : "Failed";
    }
  }

  tooltipText(planImport: PlanImportStatus): string {
    if (planImport.import_success) {
      const createDate = moment(planImport.created_at);
      return `Imported on ${createDate.format('ddd DD MMM YYYY')}`
    } else if (planImport.id) {
      return planImport.message;
    } else {
      return "Submitted";
    }
  }

  get canImport(): boolean {
    return this.scenarioPlannerId != void(0)
        && this.scenarioPlannerId != ""
  }

}
