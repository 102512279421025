import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { merge, map, pick } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { V1 } from '../../shared/utils/constants';
import { insightEntitiesCountByUrl, insightEntitiesCountUrl } from '../../shared/constants/insights.urls';
import { clientsListAdminUrl } from 'app/shared/constants/clients.urls';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'ppc-admin-stats',
  templateUrl: './stats-admin.component.html',
  styleUrls: ['./stats-admin.component.sass']
})
export class StatsAdminComponent implements OnInit, OnDestroy {
  entityNames = ['Comparison', 'Journey', 'Mekko', 'Persona'];
  clients = [];
  entitiesCount: {}
  entitiesCountByProductSlug: {}
  entitiesCountByUser: {}
  filter = {
    name: 'All',
    value: 'all'
  };
  filters: {
    name: string;
    value: string;
  }[] = [
    {
      name: 'All',
      value: 'all'
    },
    {
      name: 'Current Month',
      value: 'current_month'
    },
    {
      name: 'Current Quarter',
      value: 'current_quarter'
    },
    {
      name: 'Current Year',
      value: 'current_year'
    }
  ];
  filterDate: string = '';

  messageTable = {
    emptyMessage: '',
    totalMessage: 'row(s)'
  }

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.fetchCounts();
  }

  fetchCounts(): void {
    this.clients = [];
    this.entitiesCountByProductSlug = {};
    this.entitiesCountByUser = {};

    this.fetchEntityCounts();

    this.http.get(clientsListAdminUrl(V1)).pipe(untilDestroyed(this))
      .subscribe((resp) => {
          this.clients = map(resp, x=> pick(x, ['name', 'slug']));
          this.fetchEntitiesByProduct();
          this.fetchEntitiesByUser();
        },
        error => console.log('error fetching pending clients', error)
      );
  }

  ngOnDestroy() {}

  onFilterChange(mdSelect): void {
    this.filter = this.filters.find(e => e.value === mdSelect.value);
    this.filterDate = this.calcFilterDate();
    this.fetchCounts();
  }

  calcFilterDate(): string {
    let date: moment.Moment;
    switch (this.filter.value) {
      case 'current_month':
        date = moment();
        break;
      case 'current_quarter':
        date = moment().startOf('quarter');
        break;
      case 'current_year':
        date = moment().startOf('year');
        break;
      default:
        date = moment('2015-01-01');
    }
    return date.format("YYYY-MM-01")
  }

  getStatsUrl(url: string, operator: string = '&'): string {
    return url + operator + 'from=' + this.filterDate
  }

  fetchEntityCounts() {
    this.http.get(this.getStatsUrl(insightEntitiesCountUrl(), '?')).pipe(untilDestroyed(this))
      .subscribe((entities: []) => this.entitiesCount = entities,
        error => console.log('insight_entities error', error) );
  }

  fetchEntitiesByUser() {
    this.entityNames.forEach(ent => {
      this.http.get(this.getStatsUrl(`${insightEntitiesCountByUrl()}?top_by=created_by&resource_type=${ent}`)).pipe(untilDestroyed(this))
        .subscribe((entities: []) => this.entitiesCountByUser = merge(this.entitiesCountByUser, { [ent]: entities }),
          error => console.log('insight_entities error', error) );
    })
  }

  fetchEntitiesByProduct() {
    this.entityNames.forEach(ent => {
      this.http.get(this.getStatsUrl(`${insightEntitiesCountByUrl()}?resource_type=${ent}`)).pipe(untilDestroyed(this))
        .subscribe((entities: []) => {
            const clientEntities = entities.map(x => {
              return { name: this.clients.find(client => client.slug == x['name']).name, count: x['count'] }
            });
            this.entitiesCountByProductSlug = merge(this.entitiesCountByProductSlug, { [ent]: clientEntities })
          },
          error => console.log('insight_entities error', error) );
    })
  }
}
