import { Injectable } from '@angular/core';
import { Subject ,  Observable }    from 'rxjs';

@Injectable()
export class ScrollService {

  private source = new Subject<{element?: HTMLElement, direction: string}>();

  scrollSource$ = this.source.asObservable();

  scrollToBottom(element: HTMLElement = null) {
    this.source.next({element: element, direction: "right"});
  }

  scrollToRight(element: HTMLElement = null) {
    this.source.next({element: element, direction: "right"});
  }
}
