<ppc-loading-overlay *ngIf="loading"></ppc-loading-overlay>

<div class="plans-top-row">
  <div class="info-left">
    <div class="page-title">
      <span class="gold-title">Channels</span> Summary
    </div>
  </div>

  <div class="info-right">
    <div class="search-inputs">
      <div class="text-search">
        <i class="fa fa-search"></i>
        <input type="text" name="searchQuery" [(ngModel)]="searchQuery" placeholder="Search by name"/>
      </div>
    </div>

    <div class="import-dropdown-container">
      <button class="action-btn" mat-button (click)="openImportChannelDialog()">Import Channels</button>
      <div *ngIf="showImportDialog" class="import-dropdown">
        <div *ngIf="importError" class="error-message">Please input a valid Project ID</div>
        <mat-form-field class="import-input-field">
          <label for="tardisProjectId">Project ID</label>
          <input id="tardisProjectId" matInput [(ngModel)]="tardiisProjectId" type="text">
        </mat-form-field>
        <div class="button-row">
          <button mat-button (click)="closeImportChannelDialog()">Cancel</button>
          <button mat-button (click)="importProject()">Import</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="cards-container">
  <span class="mid-message" *ngIf="channelsReceived && channels.length === 0">Please import channels</span>

  <div *ngIf="error.isError" class="error-card">
    <div class="error-card-message">{{error.errorMessage}}</div>
    <div *ngIf="error.hasButton" class="error-button" (click)="onErrorButtonClick()">{{error.buttonTitle}}</div>
  </div>

  <span class="mid-message" *ngIf="channelsReceived && channels.length > 0 && getFilteredChannels().length === 0">
    Your search criteria does not match any existing channels
  </span>

  <div *ngFor="let channel of getFilteredChannels()" class="card-container">
    <app-channel-summary-card
      [tardiisError]="tardiisErrorsByChannelId[channel._id]"
      [error]="errorsByChannelId[channel._id]"
      [channel]="channel"
      [currencyCode]="currency"
      (editClicks)="onEditClick($event)"
      (deleteClicks)="onDeleteClick($event)"
      (channelUpdates)="updateChannel($event)"
      [attachedScenarioId]="attachedPlanIds[channel._id]">
    </app-channel-summary-card>
  </div>
</div>

<app-slide-in-overlay #overlay>
  <app-channel-form
    *ngIf="selectedChannel"
    (updateEvent)="handleUpdate($event)"
    (runEvent)="handleUpdate($event)"
    [channel]="selectedChannel"
    (closeEvent)="onEditClose()"
    (tardiisErrorEvent)="handleTardiisError($event)">
  </app-channel-form>
</app-slide-in-overlay>
