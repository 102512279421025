<ppc-loading-overlay *ngIf="isLoading"></ppc-loading-overlay>
<header>
    <app-admin-breadcrumb></app-admin-breadcrumb>
    <h1><span class="object-name">Client Mapping</span></h1>
    <p>Note: Changes made here may require a page refresh to take effect.</p>
    <div>
        <button (click)="addClientMapping()" class="btn btn-save">Add Client Mapping</button>
    </div>
</header>



<div class="admin-container">
    <ngx-datatable [rows]="tableData" [rowHeight]="'100%'" columnMode="flex" [headerHeight]="40" [footerHeight]="100"
        [reorderable]=false sortType="multiple" [sorts]="tableSorts">

        <ngx-datatable-column name="Client Name" prop="client_name" [flexGrow]="2" headerClass="text"
            cellClass="text" [resizeable]="false">
        </ngx-datatable-column>

        <ngx-datatable-column name="Client Slug" prop="client_slug" [flexGrow]="1" headerClass="text" cellClass="text"
            [resizeable]="false">
        </ngx-datatable-column>

        <ngx-datatable-column name="Company ID" prop="app_company_id" [flexGrow]="1" headerClass="text" cellClass="text"
            [resizeable]="false">
        </ngx-datatable-column>

        <ngx-datatable-column name="Instance ID" prop="instance_id" [flexGrow]="1" headerClass="text" cellClass="text"
            [resizeable]="false">
        </ngx-datatable-column>

        <ngx-datatable-column name="App Company Name" prop="app_company_name" [flexGrow]="2" headerClass="text" cellClass="text"
            [resizeable]="false">
        </ngx-datatable-column>

        <ngx-datatable-column name="App Brand Name" prop="app_brand" [flexGrow]="2" headerClass="text"
            cellClass="text" [resizeable]="false">
        </ngx-datatable-column>

        <ngx-datatable-column name="" [flexGrow]="0.5" cellClass="amount" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <ppc-icon-button icon="trash" ppcConfirmation [kontinue]="deleteClientMapping.bind(this, row)">
                </ppc-icon-button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>

<ppc-dialog>
    <div header>Add Client Mapping</div>
    <div body *ngIf="clientMappingUnderEdit">

        <mat-form-field>
            <mat-label>Client Name</mat-label>
            <mat-select [ngModel]="client.slug" (ngModelChange)="clientSlugSelection($event)" >
                <mat-option *ngFor="let client of clients" [value]="client.slug">{{client.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input matInput [(ngModel)]="client.id" disabled placeholder="Client ID" />
        </mat-form-field>
        <mat-form-field>
            <input matInput [(ngModel)]="client.slug" disabled placeholder="Client Slug" />
        </mat-form-field>
        <mat-form-field>
            <input matInput [(ngModel)]="clientMappingUnderEdit.app_company_id" placeholder="Company ID" required />
        </mat-form-field>
        <mat-form-field>
            <input matInput [(ngModel)]="clientMappingUnderEdit.instance_id" placeholder="Instance ID" required />
        </mat-form-field>
        <mat-form-field>
            <input matInput [(ngModel)]="clientMappingUnderEdit.app_company_name" placeholder="App Company Name" />
        </mat-form-field>
        <mat-form-field>
            <input matInput [(ngModel)]="clientMappingUnderEdit.app_brand" placeholder="App Brand Name" />
        </mat-form-field>
    </div>
    <div footer>
        <button mat-button (click)="saveClientMapping()" [disabled]="isNotValid()">Save</button>
        <button mat-button (click)="cancelEdit()">Cancel</button>
    </div>
</ppc-dialog>
