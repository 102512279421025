import {Action} from '@ngrx/store';
import {Competitor, Creative, CreativeAttribute, ModelType, Score, AttributeAverage} from './creative.reducers';
import {TemplateUploadReport} from '../services/creative.service';

export class FetchCompetitors implements Action {
  static readonly type = 'FETCH_COMPETITORS';
  readonly type = FetchCompetitors.type;

  constructor() {}
}

export class FetchScores implements Action {
  static readonly type = 'FETCH_SCORES';
  readonly type = FetchScores.type;

  constructor(public creativeIds?: string[]) {}
}

export class FetchCreatives implements Action {
  static readonly type = 'FETCH_CREATIVES';
  readonly type = FetchCreatives.type;

  constructor(public ids?: string[]) {}
}

export class FetchCreativeAttributes implements Action {
  static readonly type = 'FETCH_CREATIVE_ATTRIBUTES';
  readonly type = FetchCreativeAttributes.type;

  constructor() {}
}

export class ChangeCompetitor implements Action {
  static readonly type = 'CHANGE_COMPETITOR';
  readonly type = ChangeCompetitor.type;

  constructor(public competitorId: string) {}
}

export class LoadCompetitors implements Action {
  static readonly type = 'LOAD_COMPETITORS';
  readonly type = LoadCompetitors.type;

  constructor(public competitors: Competitor[]) {}
}

export class LoadScores implements Action {
  static readonly type = 'LOAD_SCORES';
  readonly type = LoadScores.type;

  constructor(public scores: Score[]) {}
}

export class LoadCreatives implements Action {
  static readonly type = 'LOAD_CREATIVES';
  readonly type = LoadCreatives.type;

  constructor(public creatives: Creative[]) {}
}

export class LoadCreativeAttributes implements Action {
  static readonly type = 'LOAD_CREATIVE_ATTRIBUTES';
  readonly type = LoadCreativeAttributes.type;

  constructor(public creativeAttributes: CreativeAttribute[]) {}
}

export class CreateCompetitor implements Action {
  static readonly type = 'CREATE_COMPETITOR';
  readonly type = CreateCompetitor.type;

  constructor(public name: string) {}
}
export class EditCompetitor implements Action {
  static readonly type = 'EDIT_COMPETITOR';
  readonly type = EditCompetitor.type;

  constructor(public competitor: Competitor) {}
}
export class DeleteCompetitor implements Action {
  static readonly type = 'DELETE_COMPETITOR';
  readonly type = DeleteCompetitor.type;

  constructor(public id: string) {}
}

export class LoadCompetitor implements Action {
  static readonly type = 'LOAD_COMPETITOR';
  readonly type = LoadCompetitor.type;

  constructor(public competitor: Competitor) {}
}

export class UploadCreatives implements Action {
  static readonly type = 'UPLOAD_CREATIVES';
  readonly type = UploadCreatives.type;

  constructor(public formData: FormData) {}
}

export class DeleteCreative implements Action {
  static readonly type = 'DELETE_CREATIVE';
  readonly type = DeleteCreative.type;

  constructor(public id: string) {}
}

export class UploadCreativeScoreTemplate implements Action {
  static readonly type = 'UPLOAD_CREATIVE_SCORE_TEMPLATE';
  readonly type = UploadCreativeScoreTemplate.type;

  constructor(public formData: FormData) {}
}

export class LoadTemplateUploadReport implements Action {
  static readonly type = 'LOAD_TEMPLATE_UPLOAD_REPORT';
  readonly type = LoadTemplateUploadReport.type;

  constructor(public report: TemplateUploadReport) {}
}

export class ResetCreativesData implements Action {
  static readonly type = 'RESET_CREATIVES_DATA';
  readonly type = ResetCreativesData.type;

  constructor() {}
}

export class ImportCreatives implements Action {
  static readonly type = 'IMPORT_CREATIVES';
  readonly type = ImportCreatives.type;

  constructor() {}
}

export class ChangeModelType implements Action {
  static readonly type = 'CHANGE_MODEL_TYPE';
  readonly type = ChangeModelType.type;

  constructor(public modelType: ModelType) {}
}

export class FetchAttributeAverages implements Action {
  static readonly type = 'FETCH_ATTRIBUTE_AVERAGES';
  readonly type = FetchAttributeAverages.type;

  constructor(public competitorId: String) {}
}

export class LoadAttributeAverages implements Action {
  static readonly type = 'LOAD_ATTRIBUTE_AVERAGES';
  readonly type = LoadAttributeAverages.type;

  constructor(public averages: AttributeAverage[], public competitorId: string) {}
}

export class FetchCreativesByAttribute implements Action {
  static readonly type = 'FETCH_CREATIVES_BY_ATTRIBUTE';
  readonly type = FetchCreativesByAttribute.type;

  constructor(public attributeId: string, public competitorIds: string[]) {}
}

export type All = LoadCompetitors | LoadCreativeAttributes | LoadCreatives | LoadScores | ChangeCompetitor
| FetchCompetitors | FetchCreativeAttributes | FetchCreatives | FetchScores | LoadTemplateUploadReport
| ResetCreativesData | LoadCompetitor | DeleteCreative | DeleteCompetitor| EditCompetitor
| ImportCreatives  | ChangeModelType | FetchAttributeAverages | LoadAttributeAverages | FetchCreativesByAttribute;

