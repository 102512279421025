import { Component, OnInit, Input, EventEmitter, OnDestroy, HostBinding, Output } from '@angular/core';
import { JourneyStageComponent } from '../journey-stage/journey-stage.component';
import { sortBy } from 'lodash';
import { JourneyCountService } from 'app/journey/journey-count.service';
import { JourneyStage, Journey, JourneyBrand } from 'app/journey/journey.models';
import { AppState } from 'app/reducers';
import { Store, select } from '@ngrx/store';
import { selectSelectedJourney } from 'app/journey/journey.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { JOURNEY_BRAND_COLORS } from '../journey-chart.component';
import { isCompareMode } from 'app/insights/insights.reducer';

@UntilDestroy()
@Component({
  selector: 'ppc-journey-transition-info',
  templateUrl: './journey-transition-info.component.html',
  styleUrls: ['./journey-transition-info.component.sass']
})
export class JourneyTransitionInfoComponent implements OnInit, OnDestroy {
  @Input() host: JourneyStageComponent;
  @Input() target: JourneyStageComponent;

  @Input() hostStage: JourneyStage;
  @Input() targetStage: JourneyStage;

  @HostBinding("class.horizontal")
  @Input()
  horizontal: boolean;

  @HostBinding("class.vertical")
  get vertical() {
    return !this.horizontal;
  }

  @Input() color: string;
  observer: MutationObserver;

  @HostBinding("style.left.px")
  left: number;

  @HostBinding("style.width.px")
  width: number;

  journey: Journey;
  selectedBrandId: number;

  @Input() expanded;
  @Output() expandedChange = new EventEmitter();

  isCompareMode$ = this.store.select("insights", "journey").pipe(select(isCompareMode))

  constructor(private store: Store<AppState>, public counts: JourneyCountService) {
    store.select("journey").pipe(
      select(selectSelectedJourney),
      untilDestroyed(this)
    ).subscribe(selectedJourney => this.journey = selectedJourney)

    store.select("journey", "selectedBrandId").pipe(untilDestroyed(this))
      .subscribe(selectedBrandId => this.selectedBrandId = selectedBrandId)

    store.select("insights", "journey", "splitScreenFocus").pipe(
      untilDestroyed(this)
    ).subscribe(() => this.draw())
  }

  ngOnInit() {
    if (this.horizontal) {
      this.observer = new MutationObserver(() => this.draw());
      this.observer.observe(this.host.gauge.svg.nativeElement, {
        childList: false,
        attributes: true,
      });
      this.observer.observe(this.target.gauge.svg.nativeElement, {
        childList: false,
        attributes: true,
      });

    }
  }

  ngOnDestroy() {
    this.observer && this.observer.disconnect();
  }

  toggleExpanded() {
    this.expandedChange.emit(!this.expanded);
  }

  trackByIndex(index) {
    return index
  }

  draw() {
    const animation = setInterval(() => {
      requestAnimationFrame(() => {
        const hostSvgBox = this.host.gauge.svg.nativeElement.getBoundingClientRect();
        const targetSvgBox = this.target.gauge.svg.nativeElement.getBoundingClientRect();

        var y = (hostSvgBox.width / 2) - 37.5;
        var hypotenuse = hostSvgBox.width / 2;
        var x = Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(y, 2));
        this.left = hostSvgBox.width - (hostSvgBox.width / 2 - x) + 2.5;

        const leftEdge = hostSvgBox.x + this.left;

        var y = (targetSvgBox.width / 2) - 37.5;
        var hypotenuse = targetSvgBox.width / 2;
        var x = Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(y, 2));

        const rightEdge = targetSvgBox.x + (targetSvgBox.width / 2 - x) - 2.5;

        this.width = rightEdge - leftEdge;
      })
    }, 3)

    setTimeout(() => {
      clearInterval(animation)
    }, 400)
  }

  get brands() {
    return sortBy(this.journey.brands, brand => brand.id == this.selectedBrandId ? Number.NEGATIVE_INFINITY : brand.order);
  }

  getBrandColor(brand: JourneyBrand) {
    return JOURNEY_BRAND_COLORS[brand.order];
  }

  getPercentageChangeForBrand(brand: JourneyBrand) {
    const was = this.counts.getCountForBrandStage(brand, this.hostStage);
    const then = this.counts.getCountForBrandStage(brand, this.targetStage);
    const result = ((then - was) / then * 100);
    if (!Number.isFinite(result)) {return "N/A"; }
    return Math.round(result).toLocaleString() + "%"
  }

}
