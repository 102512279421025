import { AfterContentInit, EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EpcModalComponent } from 'app/shared/components/epc-modal/epc-modal.component';


interface EntityNode {
  type?: string;
  name?: string;
  product?: string;
  submarket?: string;
  widget?: string;
  attribute?: string;
}

export interface DependantError {
  error?: {
    error?: {
      data?: DependantData, 
        entityName?: string, 
        entityType?: string
      }
    } 
}

interface DependantData{
  comparisons?: [...any],
  personas?: [...any],
  mekkos?: [...any],
  journeys?: [...any]
}

@Component({
  selector: 'ppc-insight-dependancy',
  templateUrl: './insight-dependancy.component.html',
  styleUrls: ['./insight-dependancy.component.sass']
})
export class InsightDependancyComponent implements OnInit, AfterContentInit {
  @ViewChild('modal', { static: true }) modal: EpcModalComponent;
  @Input() dependantError: DependantError = {
    error: {error: {data: {}, entityName: '', entityType: ''}}
  };
  @Output() onDialogClose = new EventEmitter();
  entityNodeList: EntityNode[] = [];
  entityName: string = "";
  entityType: string = "";

  constructor() { }

  ngOnInit(): void {
    this.entityName = this.dependantError.error.error['entityName'];
    this.entityType = this.dependantError.error.error['entityType'];
    this.constructTableData(this.dependantError.error.error['data']);
  }

  ngAfterContentInit() {
    this.modal.show();
  }

  constructTableData = (dependantsData: DependantData) => {
    this.generateEntityNodeList(dependantsData, 'personas');
    this.generateEntityNodeList(dependantsData, 'comparisons');
    this.generateEntityNodeList(dependantsData, 'mekkos');
    this.generateEntityNodeList(dependantsData, 'journeys');

  }

  generateEntityNodeList(dependantsData: DependantData, entityType: string ) {
    if (dependantsData?.[entityType].length > 0) {
      dependantsData[entityType].forEach((ele: any) => {
        const entityNodeItem: {
          type: string, name: string, product: string, submarket: string, widget: string, attribute: string
        } = {
          type: entityType, name: '', product: ele.product_slug, submarket: "-", widget: "-", attribute: "-"
        };
        switch (entityType) {
          case 'comparisons': {
            entityNodeItem.name = ele.chart_name;
            break;
          }
          case 'personas': {
            entityNodeItem.name = ele.persona_name;
            break;
          }
          case 'mekkos': {
            entityNodeItem.name = ele.mekko_name;
            if (!!ele.sub_market_id) {
              entityNodeItem.submarket = ele.sub_market_name;
            }
            break;
          }
          case 'journeys': {
            entityNodeItem.name = ele.journey_name;
            if (!!ele.sub_market_id) {
              entityNodeItem.submarket = ele.sub_market_name;
            }
            break;
          }
          default: {break; }
        }
        if (!!ele.bucket_id) {
          entityNodeItem.widget = ele.widget_name;
          entityNodeItem.attribute = ele.bucket_name;
        }
        this.entityNodeList.push(entityNodeItem);
      });
    }
  }

  handleOnClose(): void{
    this.onDialogClose.emit();
  }
}
