<div class="propensity-header">
  <ppc-snapshot-library></ppc-snapshot-library>
  <div class="action-buttons-container">
    <button class="create-model-icon"
            *ngIf="(activeSnapshot$ | async) && (canCreateSnapshot$ | async)"
            (click)="goToCreateNewModel()"
            ppcTooltip="Create New Model"
            [tooltipOffset]="{x: 1}">
      <i class="fa fa-plus"></i>
    </button>
    <div class="learn-more-icon"
         (click)="openCarousel()"
         ppcTooltip="Learn More"
         [tooltipOffset]="{x: 2}">
      <i class="fa fa-question-circle-o"></i>
    </div>
  </div>
</div>

<ppc-propensity-form *ngIf="!(activeSnapshot$ | async) && (canCreateSnapshot$ | async)"></ppc-propensity-form>
<span class="read-only-user-info" *ngIf="!(activeSnapshot$ | async) && !(canCreateSnapshot$ | async)">
  You may view existing in-progress and completed model runs using the Model Library above.
</span>
<ppc-snapshot-detail *ngIf="activeSnapshot$ | async"></ppc-snapshot-detail>
