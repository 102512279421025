import { Component, OnInit, ElementRef, Renderer2, Input } from '@angular/core';

import { getXOffsetToTarget, getYOffsetToTarget } from 'app/shared/utils/utils';

@Component({
  selector: 'ppc-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.sass']
})
export class ContextMenuComponent implements OnInit {
  show = false;
  position: {top?: number, left?: number} = {};

  // if the parent has padding you'll have to offset the position
  @Input() offsetX: number = 0;
  @Input() offsetY: number = 0;

  constructor(
    private elem: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.renderer.listen(this.elem.nativeElement.parentElement, "contextmenu", event => {
      this.position = {
        top: getYOffsetToTarget(this.elem.nativeElement.parentElement, event.target) + event.offsetY + this.offsetY,
        left: getXOffsetToTarget(this.elem.nativeElement.parentElement, event.target) + event.offsetX + this.offsetX
      }
      event.preventDefault();
      this.show = true;
    })
    this.renderer.listen(document, "click", event => {
      this.show = false;
    })
  }

  onClick(event) {
    event.stopPropagation();
    setTimeout(() => {
      this.show = false
    }, 200)
  }
}
