import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { filter as _filter, find, get, slice } from "lodash";

import { AppState } from "app/reducers";
import { LocalStorageService } from "app/services/local-storage.service";
import { accessibleFeatures } from "app/feature-access/feature-access.reducers";
import { activeContext } from "app/hierarchy/hierarchy.reducers";
import { getPath } from "app/hierarchy/hierarchy.utils";

// local storage key name
const HIDE_PROJECTS_STORAGE_KEY = 'home-hide-projects-section';

@Component({
  selector: 'ppc-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.sass']
})
export class ProjectsComponent {
  hierarchyPath: string;
  hide: boolean;

  accessibleProjects$ = accessibleFeatures(this.store).pipe(
    map(features => slice(_filter(this.projects, project => {
      const permission = get(project, 'permission');
      return find(features, { name: permission.name, category: permission.category });
    }), 0, 3))
  );

  projects = [
    {
      title: 'Create Outcome Model',
      body: 'Achieve your goals by creating an outcome model.',
      permission: {
        name: 'explore-outcome-audiences',
        category: 'insights'
      },
      urlPath: '/insights/explore-outcome-audiences'
    },
    {
      title: 'Discover and Build New Audiences',
      body: 'Bringing new solutions to market helps drive growth for your company.',
      permission: {
        name: 'overview',
        category: 'audiences'
      },
      urlPath: '/audiences/overview'
    },
    {
      title: 'Explore & Build Personas',
      body: 'Sell more of the same product to existing customers (up-sell) or sell new solutions to existing customers (cross-sell).',
      permission: {
        name: 'explore',
        category: 'insights'
      },
      urlPath: '/insights/explore'
    },
    {
      title: 'Build Grow Chart to Understand Markets',
      body: 'Raising your awareness and choose a resulting indicator as a measurement for awareness, like inbound leads, web traffic, or positive media mentions.',
      permission: {
        name: 'grow',
        category: 'insights'
      },
      urlPath: '/insights/grow'
    }
  ];

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private localStorage: LocalStorageService,
  ) {
    activeContext(this.store).subscribe(hierarchyContext => {
      this.hierarchyPath = getPath(hierarchyContext);
    });

    this.hide = localStorage.getValue(HIDE_PROJECTS_STORAGE_KEY) || false;
  }

  toggleHide() {
    this.hide = !this.hide;
    this.localStorage.setValue(HIDE_PROJECTS_STORAGE_KEY, this.hide);
  }

  navigateTo(project) {
    this.router.navigate([`${this.hierarchyPath}${project.urlPath}`]);
  }

}
