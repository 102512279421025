<div class="bottom-level-tab">
  <i class="fa fa-bars bottom-drag-handle"></i>
  <i class="fa fa-caret-right"
    [class.rotated]="tab.expanded"
    [class.invisible]="demographics?.length == 0 "
    (click)="tab.expanded = !tab.expanded"></i>
  <ppc-checkbox [checked]="tab.visible" (change)="handleVisibility(tab)" [disabled]="cantUnselect">
    {{ tab.name }}
    <span class="description">Custom</span>
  </ppc-checkbox>
  <span *ngIf="canUpdateCustomTabs$ | async" class="fa fa-pencil" (click)="editCustomTab()"></span>
</div>
<div class="widget-container"
      [class.hide]="!tab.expanded && !(isDragging$ | async)"
      [dragula]="bagName"
      [dragulaModel]="isPersonLevelTab ? customDemographics : marketLevelDemographics">
  <div class="widget" *ngFor="let widget of demographics" [attr.data-id]="widget.id">
    <i class="fa fa-bars widget-drag-handle"></i>
    <ppc-checkbox [checked]="widget.visible" (change)="handleWidgetVisibility(widget)">
      {{widget.name}}
    </ppc-checkbox>
  </div>
</div>

