import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { sortBy as sortBy } from "lodash";
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, scan, startWith } from 'rxjs/operators';

import * as actions from 'app/admin/destination-management/destination-management.actions';
import { getDestinationsList } from 'app/admin/destination-management/destination-management.reducer';
import { AppState } from 'app/reducers';
import { fetchIfUnfetched, isFetchInFlight } from 'app/shared/utils/fetch-state';
import { assignIn, isEqual } from 'lodash';
import { stateColors } from '../activation-v2-common/activation-v2-common.constants';
import { DestinationManagementService } from './destination-management.service';
import { changePage } from '../activation-v2-common/activation-v2-common.utils';
import { DestinationSelectableState } from './destination.constants';
import { SelectableCompany } from '../activation-v2-common/activation-v2-common.constants';

@UntilDestroy()
@Component({
  selector: 'app-destination-management',
  templateUrl: './destination-management.component.html',
  styleUrls: ['./destination-management.component.sass', '../activation-v2-common/management.sass'],
})
export class DestinationManagementComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('companyIdDropdown') companyIdDropdown: MatSelect;
  @ViewChild('stateDropdown') stateDropdown: MatSelect;
  searchField = new FormControl('');

  destinations$ = this.store.select('destinationManagement').pipe(map(getDestinationsList));
  pageInfo$ = this.store.select('destinationManagement', 'pageInfo').pipe(
    map((pageInfo) => {
      return {...pageInfo, pageNumber: Math.floor(pageInfo.offset / pageInfo.limit) };
    })
  );
  selectedPage$ = new BehaviorSubject({ limit: 20, offset: 0 });
  changePage = changePage;
  sortBy$ = new BehaviorSubject([]);

  companies$ = this.destinationManagementService.getCompanies().pipe(map((companies) => sortBy(companies, 'id')));
  selectedCompanyId = SelectableCompany.ALL;

  selectableStates = [DestinationSelectableState.PENDING, DestinationSelectableState.COMPLETE];
  selectedState = DestinationSelectableState.ALL;
  stateColors = stateColors;

  loading$ = combineLatest([
    this.store.select("fetchStates", actions.FetchDestinations.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.SavePartialDestination.type).pipe(select(isFetchInFlight)),
  ]).pipe(map(loadingStates => loadingStates.some(Boolean)));

  constructor(
    private store: Store<AppState>,
    private destinationManagementService: DestinationManagementService,
  ) {
  }

  ngOnInit(): void {
    fetchIfUnfetched(this.store, new actions.FetchDestinations(), this);
  }

  ngAfterViewInit() {
    const selectedCompanyId$ = this.companyIdDropdown.optionSelectionChanges.pipe(
      map(event => event.source.value),
      startWith(SelectableCompany.ALL)
    );

    const selectedState$ = this.stateDropdown.optionSelectionChanges.pipe(
      map(event => event.source.value),
      startWith(DestinationSelectableState.ALL)
    );

    const searchTerm$ = this.searchField.valueChanges.pipe(
      debounceTime(300),
      startWith('')
    );

    const filterBy$ = combineLatest([
      selectedCompanyId$,
      selectedState$,
      searchTerm$,
    ]).pipe(
      map(([selectedCompanyid, selectedState, searchTerm]) => {
        return {selectedCompanyid, selectedState, searchTerm};
      })
    );

    combineLatest([
      this.selectedPage$,
      filterBy$,
      this.sortBy$,
    ]).pipe(
      map(([selectedPage, filterBy, sortByFields]) => {
        return {selectedPage, filterBy, sortByFields}
      }),
      scan((prev, curr) => {
        const result = assignIn({}, curr);
        if (!isEqual(prev.filterBy, curr.filterBy) || !isEqual(prev.sortByFields, curr.sortByFields)) {
          result.selectedPage.offset = 0;
        }
        return result;
      }),
      untilDestroyed(this)
    ).subscribe(({selectedPage, filterBy, sortByFields}) => {
      this.store.dispatch(new actions.FetchDestinations({
        limit: selectedPage.limit,
        offset: selectedPage.offset,
        companyId: filterBy.selectedCompanyid === SelectableCompany.ALL ? undefined : filterBy.selectedCompanyid,
        state: filterBy.selectedState === DestinationSelectableState.ALL ? undefined : filterBy.selectedState,
        searchTerm: filterBy.searchTerm,
        sort: sortByFields
      }));
    });
  }

  ngOnDestroy() {}

  updateDestination(id: number, newState: string) {
    this.store.dispatch(new actions.SavePartialDestination(id, { state: newState }));
  }

  changeSort(event) {
    const sortByFields = event.sorts.map((sort) => {
      const direction = sort.dir === 'desc' ? '-' : '';
      const sortMap = {
        'platform.name': 'platformName',
        'name': 'name',
        'accountId': 'accountId',
        'companyId': 'companyId',
        'state': 'state'
      }
      return `${direction}${sortMap[sort.prop]}`;
    });
    this.sortBy$.next(sortByFields);
  }
};
