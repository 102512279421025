import {apiUrl} from "app/services/ppc_http.service";

export function clearAuthToken(): void {
  localStorage.removeItem('auth-token');
}

export function getAuthToken(): string {
  return localStorage.getItem('auth-token')
}

export function setAuthToken(token: string): void {
  localStorage.setItem('auth-token', token)
}

function logoutUrl(returnUrl = '') {
  return apiUrl('/adfs/logout') + (returnUrl && `?returnUrl=${encodeURIComponent(returnUrl)}`)
}

export function logout(returnUrl = ''): void {
  // leave this here for a while to make sure every client cleans itself up
  localStorage.removeItem('parentUser');
  localStorage.removeItem('currentUser');
  localStorage.removeItem('csrf-token')
  clearAuthToken();

  // Notify any iFramed applications of the logout
  Array.from(document.querySelectorAll('iFrame')).forEach(
    (frame: any) => frame.contentWindow.postMessage({type: 'logout'}, "*"))

  // Delay the redirect a bit so that iFramed applications have a chance to
  // respone to the asynchronous postMessage call
  setTimeout(() => window.location.href = logoutUrl(returnUrl),
    200)
}
