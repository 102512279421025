import { map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter as _filter, get } from 'lodash';

import { TopLevelTabType, Tab } from 'app/insights/insights.models';
import * as growActions from 'app/insights/grow-v3/grow.actions';
import { PersonLevelSurveyComponent } from 'app/insights/insights-components/person-level-survey/person-level-survey.component';
import { PersonLevelDemographicsComponent } from 'app/insights/insights-components/person-level-demographics/person-level-demographics.component';
import { MarketLevelCustomTabDemographicsComponent } from 'app/insights/insights-components/market-level-custom-tab-demographics/market-level-custom-tab-demographics.component'
import { MarketLevelDiscussionsComponent } from 'app/insights/insights-components/market-level-discussions/market-level-discussions.component';
import { MarketLevelSurveyComponent } from 'app/insights/insights-components/market-level-survey/market-level-survey.component';
import { PredictionsLevelPropensityComponent } from 'app/snapshots/predictions-level-propensity/predictions-level-propensity.component'
import { CustomTabWidgetsComponent } from "app/insights/insights-components/custom-tab-widgets/custom-tab-widgets.component"
import { RegionLevelDemographicsComponent } from 'app/insights/insights-components/region-level-demographics/region-level-demographics.component';
import { ShareThisComponent } from 'app/insights/insights-components/share-this/share-this.component';
import { PersonLevelSharedInterestsComponent } from 'app/insights/insights-components/person-level-shared-interests/person-level-shared-interests.component';
import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import * as insightsActions from 'app/insights/insights.actions';

import { SegmentContexts } from 'app/insights/insights.constants';
import { InsightsService } from 'app/insights/insights.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from "rxjs";
import { environment } from 'environments/environment';
import { PersonLevelCompareComponent } from './insights-components/person-level-compare/person-level-compare.component';
import { activePersonaIsAllData as activePersonaIsAllDataSelector } from "app/explore/explore.reducer";

@UntilDestroy()
@Component({
  selector: 'ppc-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.sass'],
  providers: [
    InsightsService
  ]
})
export class InsightsComponent implements OnInit, OnDestroy {
  @Input() segmentContexts$: Observable<SegmentContexts>;
  @Output() topLevelTabChange = new EventEmitter<TopLevelTabType>();

  topLevelTab$ = this.store.select("insights", this.insightsContext, "topLevelTab");
  isEditingWidget$ = this.store.select("insights", this.insightsContext, 'demoUnderEdit').pipe(map(Boolean));

  personLevelTab: string;
  marketLevelTab: string;
  predictionsLevelTab: string;
  growTabState: string;
  canAccessCaas: boolean = false;
  manageOpen$ = this.store.select("insights", this.insightsContext, "manageOpen");
  exportOpen$ = this.store.select("insights", this.insightsContext, "exportOpen");
  activePersonaIsAllData: boolean;
  isCopyTabOpen: boolean = false;
  customTabToCopy = null;

  _tabs: Tab[];

  componentConfig = {
    person_level_demographics: PersonLevelDemographicsComponent,
    person_level_survey: PersonLevelSurveyComponent,
    person_level_custom: CustomTabWidgetsComponent,
    person_level_shared_interests: PersonLevelSharedInterestsComponent,
    person_level_compare: PersonLevelCompareComponent,
    market_level_discussions: MarketLevelDiscussionsComponent,
    market_level_survey: MarketLevelSurveyComponent,
    market_level_census: RegionLevelDemographicsComponent,
    market_level_share_this: ShareThisComponent,
    market_level_custom: MarketLevelCustomTabDemographicsComponent,
    predictions_propensity: PredictionsLevelPropensityComponent,
  };

  inputs = { }

  canCreateTab$ = this.store.select('permissions', 'tabs', 'create')

  constructor(public store: Store<AppState>,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {

    this.store.select("insights", this.insightsContext, "tabs")
      .pipe(untilDestroyed(this))
      .subscribe(tabs => this._tabs = tabs);

    this.store.select("grow", "growTabState")
      .pipe(untilDestroyed(this))
      .subscribe(growTabState => this.growTabState = growTabState)

    this.store.select("insights", this.insightsContext, "personLevelTab")
      .pipe(untilDestroyed(this))
      .subscribe(personLevelTab => this.personLevelTab = personLevelTab)

    this.store.select("insights", this.insightsContext, "marketLevelTab")
      .pipe(untilDestroyed(this))
      .subscribe(marketLevelTab => this.marketLevelTab = marketLevelTab)

    this.store.select("insights", this.insightsContext, "predictionsLevelTab")
      .pipe(untilDestroyed(this))
      .subscribe(predictionsLevelTab => this.predictionsLevelTab = predictionsLevelTab)

    this.store.select('permissions', 'caas', 'read')
      .pipe(untilDestroyed(this))
      .subscribe(caasPermission => this.canAccessCaas = caasPermission);

    this.store.select("explore")
      .pipe(select(activePersonaIsAllDataSelector), untilDestroyed(this))
      .subscribe(activePersonaIsAllData => this.activePersonaIsAllData = activePersonaIsAllData);
  }

  get tabs(): Tab[] {
    return _filter(this._tabs, tab => {
      return tab.visible && this.showTab(tab, "top_level_predictions") && this.showTab(tab, "top_level_market");
    });
  }

  showTab(tab: Tab, tabKey: string): boolean {
    switch (tabKey) {
      case "top_level_predictions":
        return (tab.tab_key !== tabKey || this.canAccessCaas);
      case "top_level_market":
        return this.insightsContext !== "explore" || (this.activePersonaIsAllData || tab.tab_key !== tabKey);
    }
  }

  renderTooltip(tab) {
    if (tab === "Market Level") {
      if (environment.isTier3) {
        return 'Census and Custom Imported Data';
      } else if (this.insightsContext === 'explore') {
        return 'Non-PID and Census Data';
      } else {
        return 'Non-PID total population data';
      }
    } else if (tab === "Person Level") {
      return environment.isTier3 ? 'Unique Panel ID Data' : 'PID matched data';
    }
  }

  ngOnInit() {
    this.segmentContexts$.pipe(
      untilDestroyed(this)
    ).subscribe(segmentContexts => {
      this.store.dispatch(new insightsActions.SetSegmentContexts(segmentContexts, this.insightsContext))
    });

  }

  getComponentName(topLevelTab) {
    return topLevelTab === "Person Level" ? CustomTabWidgetsComponent : MarketLevelCustomTabDemographicsComponent;
  }

  getInputs(bottomLevelTab: Tab) {
    return get(this.inputs, bottomLevelTab.tab_key, {tab: bottomLevelTab})
  }

  ngOnDestroy() { }

  changeTab(topLevelTab: TopLevelTabType) {
    this.store.dispatch(new insightsActions.SetTopLevelTab(topLevelTab, this.insightsContext));
    this.topLevelTabChange.emit(topLevelTab);

    if (this.insightsContext === "grow") {
      if (topLevelTab === 'Market Level') {
        this.store.dispatch(new growActions.ChangeGrowTabState('Total Population'))
      } else if (this.growTabState === 'Total Population') {
        this.store.dispatch(new growActions.ChangeGrowTabState('Matched Addressable'))
      }
    }
  }

  changeBottomLevelTab(topLevelTab: Tab, newTabName: string) {
    if (topLevelTab.name === "Person Level") {
      this.store.dispatch(new insightsActions.SetPersonLevelTab(newTabName, this.insightsContext))
    } else if (topLevelTab.name === "Market Level") {
      this.store.dispatch(new insightsActions.SetMarketLevelTab(newTabName, this.insightsContext))
    } else {
      this.store.dispatch(new insightsActions.SetPredictionsLevelTab(newTabName, this.insightsContext))
    }
  }

  getBottomLevelTab(topLevelTab: Tab) {
    if (topLevelTab.name === "Person Level") {
      return this.personLevelTab;
    } else if (topLevelTab.name === "Market Level") {
      return this.marketLevelTab;
    } else {
      return this.predictionsLevelTab;
    }
  }

  trackById(index, item: Tab) {
    return item.id
  }

  visibleChildren(tab: Tab): Tab[] {
    return _filter(tab.children, "visible");
  }

  toggleEditTabConfig() {
    this.store.dispatch(new insightsActions.ToggleManage(this.insightsContext));
  }

  toggleExportPane() {
    this.store.dispatch(new insightsActions.ToggleExport(this.insightsContext));
  }

  copyTab(tab: Tab) {
    this.isCopyTabOpen = true
    this.customTabToCopy = tab
  }

  canShowTabMenu(tab: Tab) {
    return tab.tab_key === 'person_level_custom' && this.insightsContext !== 'outcome-audience';
  }
}
