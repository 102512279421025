import { Directive, EventEmitter, Input, Output, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[ppcThrottleClick]'
})
export class PpcThrottleClickDirective implements OnInit, OnDestroy {
  /*
    NOTE: Sourced from https://stackoverflow.com/a/57499185/4803039
    Adding this directive to a clickable UI component or HTML element prevents multiple click
    events from occurring, up to the `throttleTime` (good for preventing double clicks).
    RxJS `throttleTime` allows the first click, and blocks subsequent clicks up to `throttleTime`.

    Usage:
    Apply directive on an html element, passing an optional `throttleTime`.
    <button ppcThrottleClick [throttleTime]="1000" (throttledClick)="submit()">Submit</button>
  */
  @Input() throttleTime = 500;
  @Output() throttledClick = new EventEmitter();

  private clicks$ = new Subject();

  constructor() { }

  ngOnInit() {
    this.clicks$.pipe(
      throttleTime(this.throttleTime),
      untilDestroyed(this)
    ).subscribe(e => this.throttledClick.emit(e));
  }

  ngOnDestroy() { }

  @HostListener('click', ['$event'])
  click(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks$.next(event);
  }
}
