import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudienceBuilderComponent } from './audience-builder.component';
import { AudienceBuilderFormComponent } from './audience-builder-form/audience-builder-form.component';
import { AudienceBuilderService } from './audience-builder.service';
import { SegmentPickerV2Module } from 'app/segment-picker-v2/segment-picker-v2.module';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { AudienceBuilderGroupComponent } from './audience-builder-form/audience-builder-group/audience-builder-group.component';
import { NgLetModule } from 'ng-let';

@NgModule({
  declarations: [
    AudienceBuilderComponent,
    AudienceBuilderFormComponent,
    AudienceBuilderGroupComponent,
  ],
  exports: [
    AudienceBuilderComponent,
  ],
  imports: [
    CommonModule,
    SegmentPickerV2Module,
    PpcComponentsModule,
    NgLetModule,
  ]
})
export class AudienceBuilderModule { }
