import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { takeUntil, map } from 'rxjs/operators';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, HostBinding, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { chain } from 'lodash';
import { AppState } from 'app/reducers';
import { Store } from '@ngrx/store';

import { ChartTypes, Demographic, RadarChartAccess, Tab } from 'app/insights/insights.models';
import { AuthPermission } from "app/shared/interfaces/auth-permission";
import * as insightsActions from 'app/insights/insights.actions';
import { INSIGHTS_CONTEXT, InsightsContextType, PercentCalculationType } from 'app/insights/insights.constants';
import { InsightsCountService } from "app/insights/insights-count.service";
import { SortDirection } from 'app/shared/components/ppc-sort/ppc-sort.service';
import { BarChartData, DEFAULT } from 'app/insights/insights.service';


@UntilDestroy()
@Component({
  selector: 'ppc-grow-v3-demographic-widget',
  templateUrl: './demographic-widget.component.html',
  styleUrls: ['./demographic-widget.component.sass']
})
export class DemographicWidgetComponent implements OnInit, OnDestroy {
  @Input() demographic: Demographic;
  @Output() hideDemographic = new EventEmitter();
  @Input() tab: Tab;
  @Input() segmentCounts: {[identifer: string]: number} = {};

  chartTypes = ChartTypes;
  radarChartAccess = RadarChartAccess;

  demographicsPermission: AuthPermission;
  ngUnsubscribe = new Subject();
  percentCalculationType: PercentCalculationType;
  @HostBinding("class.jumbo") insightsFocused: boolean;
  indexMode$ = this.store.select("insights", this.insightsContext, "indexMode");
  demographicWithPercent: BarChartData[] = [];
  sortDirection: SortDirection = DEFAULT;



  constructor(private counts: InsightsCountService,
    private store: Store<AppState>,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {
    store.select("permissions", "demographics").pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(permission => this.demographicsPermission = permission)

    store.select("insights", this.insightsContext, "splitScreenFocus").pipe(
      map(focus => focus == "right"),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(insightsFocused => this.insightsFocused = insightsFocused)
  }

  ngOnInit() {
    this.store.select("insights", this.insightsContext, "percentCalculationType").pipe(
      untilDestroyed(this)
    ).subscribe(percentCalculationType => this.percentCalculationType = percentCalculationType)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  editDemo() {
    this.store.dispatch(new insightsActions.EditDemographic(this.demographic, this.insightsContext))
  }

  hideDemo() {
    this.hideDemographic.emit();
  }

  destroyDemo() {
    this.store.dispatch(new insightsActions.DestroyDemographic(this.demographic, this.insightsContext))
  }

  hasZeroCountId(demographic: Demographic) {
    return chain(demographic)
      .get('buckets')
      .map(b => this.segmentCounts[b.short_id])
      .some(count => count === 0)
      .value();
  }

  get is_id_count(): boolean {
    return this.demographic.is_id_count;
  }

  setDemographicWithPercent(demographicWithPercent) {
    this.demographicWithPercent = demographicWithPercent;
  }

  setSortDirection(sortDirectionChange) {
    this.sortDirection = sortDirectionChange;
  }

  get displaySortIcons() {
    return this.demographic?.widget_type === this.chartTypes.BarHorizontal || ( this.percentCalculationType === 'selected' && this.demographic?.widget_type != this.chartTypes.BarVertical );
  }
}
