import {Action} from "@ngrx/store";
import {Plan} from "./plan.model";

export class LoadPlans implements Action {
  static readonly type = 'LOAD_PLANS';
  readonly type = LoadPlans.type;

  constructor(public plans: Plan[]) {}
}

export class ClearPlans implements Action {
  static readonly type = 'CLEAR_PLANS';
  readonly type = ClearPlans.type;
}

export class FetchPlans implements Action {
  static readonly type = 'FETCH_PLANS';
  readonly type = FetchPlans.type;
}

export class ErrorPlans implements Action {
  static readonly type = 'ERROR_PLANS';
  readonly type = ErrorPlans.type;

  constructor(public error) {}
}

export class RemovePlan implements Action {
  static readonly type = 'REMOVE_PLAN';
  readonly type = RemovePlan.type;

  constructor(public plan: Plan) {}
}

export class UpsertPlan implements Action {
  static readonly type = 'UPSERT_PLAN';
  readonly type = UpsertPlan.type;

  constructor(public plan: Plan) {}
}

export class SetCurveTypeNames implements Action {
  static readonly type = 'SET_CURVE_TYPE_NAMES';
  readonly type = SetCurveTypeNames.type;

  constructor(public curveTypes: {[name: string]: number}) {}
}

export type All = LoadPlans | ClearPlans | FetchPlans | ErrorPlans | RemovePlan | SetCurveTypeNames | UpsertPlan
