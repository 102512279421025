import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { FetchAdminClients, LoadAdminClients } from './client-admin.action';
import { fetchResource } from 'app/shared/utils/fetch-state';
import { V1 } from '../../../shared/utils/constants';
import { clientsListAdminUrl } from '../../../shared/constants/clients.urls';

@Injectable()
export class ClientAdminService {

  @Effect()
  fetchAdminClients$ = this.actions$.pipe(
    ofType(FetchAdminClients.type),
    (
      fetchResource(
        action => this.getClients(action.fullSlug, action.version).pipe(
          map(adminClients => new LoadAdminClients(adminClients))
        )
      )
    )
  )

  constructor(private http: HttpClient, private actions$: Actions) { }

  getClients(contextHeader?: string, versionNumber?: string) {
    return getAdminClients(this.http, contextHeader, versionNumber);
  }
}

export function getAdminClients(http: HttpClient, contextHeader?: string, versionNumber?: string) {
  const headers = contextHeader ? { 'x-context': contextHeader } : {};

  return http.get<{data: any[]}>(clientsListAdminUrl(versionNumber || V1), {headers}).pipe(
    map(response => response)
  );
}
