import { Component, EventEmitter, Input, Output, OnChanges, ViewChild, OnDestroy } from "@angular/core";
import { getLookalikeCurrentPeople } from "app/lookalikes-v2/lookalike-v2.model";
import { LookalikeV3 } from "app/lookalikes-v3/lookalike-v3.model";
import { isEmpty } from "lodash";
import * as actions from "app/lookalikes-v3/lookalike-v3.actions";
import { V1, V5 } from "app/shared/utils/constants";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../reducers";
import { Actions, ofType } from "@ngrx/effects";
import * as fetchState from "../../../../shared/utils/fetch-state";
import { takeUntil } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject } from "rxjs";
import { userCan } from 'app/authorization/permissions/permissions.reducers'
import { CreateLookalikeService } from "../../create-lookalike/create-lookalike.service";
import { EpcModalComponent } from 'app/shared/components/epc-modal/epc-modal.component';
import { InputValidator } from "app/shared/components/ppc-input/ppc-input.component";

@Component({
  selector: 'ppc-lookalike-detail-graph',
  templateUrl: './lookalike-detail-graph.component.html',
  styleUrls: ['./lookalike-detail-graph.component.sass']
})

export class LookalikeDetailGraphComponent implements OnChanges, OnDestroy {
  @Input() ppcObject;
  @Input() ppcObjectType;
  @Output() onClose = new EventEmitter();
  @ViewChild('modal', { static: true }) modal: EpcModalComponent;

  originalPPCObject: LookalikeV3;
  newPPCObject: LookalikeV3;
  ngUnsubscribe: Subject<boolean> = new Subject();
  clonedLookalikeName: string = "";

  modeledAddressable: number;
  clonedNameValid: boolean = true;

  confirmMessage = "This will create a clone of the lookalike with new values. The original lookalike cannot be changed."
  processingTooltip = "Cannot save while lookalike is processing";
  userCanEdit = userCan('update', 'lookalikes', this.store)

  clonedInputNameValidator: InputValidator[] = [
    {
      errorMessage: `Lookalike already present with this name`,
      isValid: (value) => {
        return value ? this.checkUniqueClonedName("validator") : true;
      }
    }
  ];

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private createLookalikeService: CreateLookalikeService
  ) {
    actions$
      .pipe(
        ofType(actions.LoadLookalike.type, actions.LoadUpdatedLookalike.type, fetchState.ErrorFetch.type),
        takeUntil(this.ngUnsubscribe)
      ).subscribe((res: actions.LoadLookalike | actions.LoadUpdatedLookalike | fetchState.ErrorFetch) => {
        this.handleLookalikeActionResponse(res)
      });
  }

  ngOnChanges() {
    this.originalPPCObject = Object.assign({}, this.ppcObject);
    this.newPPCObject = Object.assign({}, this.ppcObject);

    if (this.hasConfidenceSizes) {
      this.modeledAddressable = getLookalikeCurrentPeople(this.ppcObject);
    }
  }

  ngDoCheck() {
    if (this.clonedLookalikeName.trim() === "") {
      this.clonedNameValid = true;
    }
  }

  get hasConfidenceSizes(): boolean {
    return this.ppcObject && !isEmpty(this.ppcObject.confidence_sizes);
  }

  get lookalikeWillClone(): boolean {
    return this.originalPPCObject.current_confidence !== this.newPPCObject.current_confidence
      || this.originalPPCObject.include_seed_in_model !== this.newPPCObject.include_seed_in_model;
  }

  get jobStatus(): string {
    if (this.ppcObject) {
      return this.ppcObject.job_status;
    }
  }

  updateCountAndConfidence(graphData) {
    this.newPPCObject.current_confidence = graphData.confidence;
    this.newPPCObject.current_confidence_pid_count = graphData.people;
    this.modeledAddressable = graphData.people;
  }

  get matchedAddressable(): number {
    if (this.ppcObject) {
      return this.ppcObject.seed_counts.people["global"];
    }
    return 0
  }

  onUpdate() {
    this.store.dispatch(new actions.UpdateLookalike(this.newPPCObject, {urlVersionNumber: V1}));
  }

  onClone() {
    this.resetLookalikeFieldsToDefault();
    this.renameCloneLookalike();
    this.store.dispatch(new actions.CreateLookalike(this.newPPCObject, {urlVersionNumber: V5}));
    this.resetClonedName();
    this.modal.close();
  }

  showDialog() {
    this.modal.show();
  }

  onDialogClose() {
    this.resetClonedName();
  }

  resetLookalikeFieldsToDefault() {
    this.newPPCObject.identifier = '';
    this.newPPCObject.id = null;
    this.newPPCObject.job_status = "pending";
    this.newPPCObject.confidence_sizes = {};
  }

  resetClonedName() {
    this.newPPCObject.name = this.originalPPCObject.name;
    this.clonedLookalikeName = "";
  }

  renameCloneLookalike() {
    // TODO: Remove dependency on createLookalikeService, move to separate service or LookalikeService
    if (this.clonedLookalikeName.trim() !== "") {
      this.newPPCObject.name = this.clonedLookalikeName;
    } else {
      this.resetClonedName();
      let num = this.setCloneNumber()
      const name = this.setCloneName()
      if (this.checkUniqueClonedName("rename_lookalike")) { return }

      while (!this.checkUniqueClonedName("rename_lookalike")) {
        this.newPPCObject.name = name + num;
        num++;
      }
    }
  }

  setCloneName(): string {
    const originalName = this.newPPCObject.name
    const appendix = originalName.split('_').splice(-1)

    return !Number(appendix) || Number(appendix) >= 10 ? originalName + '_' : originalName.slice(0, -1)
  }

  setCloneNumber(): number {
    const originalName = this.newPPCObject.name
    const appendix = originalName.split('_').splice(-1)
    const num_appendix = Number(appendix)

    if (num_appendix && num_appendix < 10) {
      return num_appendix + 1;
    } else {
      return 1
    }
  }

  checkUniqueClonedName(source: string): boolean {
    const service = this.createLookalikeService;
    switch (source) {
      case "validator":
        service.lookalike.name = this.clonedLookalikeName;
        break;
      default:
        service.lookalike = this.newPPCObject;
        break;
    }
    this.clonedNameValid = service.hasUniqueName;
    return this.clonedNameValid;
  }

  sendSnackbarMessage(message: string, panelClass: string) {
    this.snackbar.open(message, null, { duration: 5000, panelClass: [panelClass]});
  }

  handleLookalikeActionResponse(res): void {
    if (res.type === actions.LoadUpdatedLookalike.type) {
      this.closeOverlay();
      this.sendSnackbarMessage(`${res.lookalike.name} successfully updated`, 'check')
    } else {
      const error = res.error.error.error;
      const message = res.error.error.full_error_message;
      if (!!error && !!message) {this.sendSnackbarMessage(`${error}: ${message}`, 'danger')}
    }
  }

  closeOverlay() {
    this.onClose.emit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
