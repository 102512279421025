import { get, find, omit, uniqBy, concat, compact, map, keyBy, sortBy, sumBy, filter, cloneDeep, isEqual, reject } from "lodash";
import { JourneyBrand, JourneyStage, JourneySubMarket, Journey, newStage, newBrand } from "app/journey/journey.models";
import { SegmentV2Service } from "app/segments-v2/segment-v2.service";
import { SegmentLike } from '../../models/segment-like.model';
import { fixCounts } from 'app/insights/insights.models';
import { HierarchyRegion } from "app/hierarchy/hierarchy.interface";

export class JourneyBuilder {
  name: string;
  id?: number;
  default: boolean;
  has_transition_data: boolean
  brands: JourneyBrand[];
  stages: JourneyStage[];
  subMarkets: JourneySubMarket[];
  public brandUnderEdit: JourneyBrand;
  public stageUnderEdit: JourneyStage;
  public segments: {[identifier: string]: SegmentLike} = {};
  stageNameUnderEdit: JourneyStage;
  brandNameUnderEdit: JourneyBrand;
  original: Journey;
  constructor(journey: Journey, region: HierarchyRegion, private segmentV2Service: SegmentV2Service) {
    this.id = journey.id;
    this.name = journey.name;
    this.default = journey.default || false;
    this.has_transition_data = journey.has_transition_data;
    this.brands = sortBy(journey.brands, "order");
    this.stages = sortBy(journey.stages, "priority");
    this.subMarkets = journey.sub_markets.map(subMarket => {
      return {
        ...subMarket,
        brand: subMarket.brand || find(this.brands, { id: subMarket.journey_brand_id }) as JourneyBrand,
        stage: subMarket.stage || find(this.stages, { id: subMarket.journey_stage_id }) as JourneyStage
      };
    });
    if (this.segmentIdentifiers.length) {
      this.segmentV2Service.fetchByIdentifiers(this.segmentIdentifiers)
        .subscribe(segments => this.segments = keyBy(fixCounts(segments, region), "identifier"));
    }
    this.original = cloneDeep(this.toJson());
  }

  getSubMarket(brand: JourneyBrand, stage: JourneyStage): JourneySubMarket {
    return find(this.subMarkets, { brand, stage });
  }

  addStage(idx: number) {
    const stage = newStage(this.stages);
    this.stages.splice(idx, 0, stage);
    this.subMarkets.push(...this.brands.map(brand => ({ brand, stage })));
  }

  addBrand() {
    const brand = newBrand(this.brands);
    this.brands.push(brand);
    this.subMarkets.push(...this.stages.map(stage => ({ stage, brand })));
  }

  removeBrand(brand) {
    return () => {
      this.brands = this.brands.filter(b => b !== brand)
      this.subMarkets = this.subMarkets.filter(subMarket => subMarket.brand !== brand)
    }
  }

  removeStage(stage) {
    return () => {
      this.stages = this.stages.filter(b => b !== stage)
      this.subMarkets = this.subMarkets.filter(subMarket => subMarket.stage !== stage)
    }
  }

  moveStageLeft(idx) {
    const [stage] = this.stages.splice(idx, 1);
    this.stages.splice(idx - 1, 0, stage);
  }

  moveStageRight(idx) {
    const [stage] = this.stages.splice(idx, 1);
    this.stages.splice(idx + 1, 0, stage);
  }

  moveBrandUp(idx) {
    const [brand] = this.brands.splice(idx, 1);
    this.brands.splice(idx - 1, 0, brand);
  }

  moveBrandDown(idx) {
    const [brand] = this.brands.splice(idx, 1);
    this.brands.splice(idx + 1, 0, brand);
  }

  editStageName(stage) {
    this.stageNameUnderEdit = stage;
  }

  editBrandName(brand) {
    this.brandNameUnderEdit = brand;
  }

  getCountForStage(stage) {
    return sumBy(filter(this.subMarkets, {stage}), (subMarket: JourneySubMarket) => subMarket.population)
  }

  noSegmentsForSubMarket(brand, stage) {
    const subMarket = this.getSubMarket(brand, stage);
    return !subMarket.matched_id && !subMarket.modeled_id;
  }

  editSubMarket(subMarket) {
    this.brandUnderEdit = subMarket.brand;
    this.stageUnderEdit = subMarket.stage;
  }

  isStageNameUsedMoreThanOnce(stageName: string) {
    return filter(this.stages, stage => stage.name.trim().toLowerCase() === stageName.trim().toLowerCase()).length > 1;
  }

  isStageNameEmpty(stageName: string) {
    return stageName.trim().length === 0;
  }

  isBrandNameUsedMoreThanOnce(brandName: string) {
    return filter(this.brands, brand => brand.name.trim().toLowerCase() === brandName.trim().toLowerCase()).length > 1;
  }

  isBrandNameEmpty(brandName: string) {
    return brandName.trim().length === 0;
  }

  hasZeroCount(identifier: string, countQuery: "matched" | "modeled") {
    return identifier && get(this.segments[identifier], `count.${countQuery}`, 0) === 0;
  }

  get isEditing() {
    return this.brandUnderEdit && this.stageUnderEdit;
  }

  toJson() {
    const brands = this.brands.map((brand, idx) => {
      return Object.assign(brand, { new_id: brand.id ? null : idx, order: idx });
    });
    const stages = this.stages.map((stage, idx) => {
      return Object.assign(stage, { new_id: stage.id ? null : idx, priority: idx });
    });

    const subMarkets = this.subMarkets.map((subMarket, idx) => {
      return Object.assign(subMarket, {
        population: subMarket.population || 0,
        new_journey_brand_id: subMarket.brand ? subMarket.brand.new_id : null,
        journey_brand_id: subMarket.brand ? subMarket.brand.id : null,
        new_journey_stage_id: subMarket.stage.new_id,
        journey_stage_id: subMarket.stage.id,
      });
    });

    return {
      ...{ id: this.id, name: this.name, has_transition_data: this.has_transition_data, default: this.default },
      brands: brands,
      stages: stages,
      sub_markets: subMarkets.map(subMarket => omit(subMarket, ["brand", "stage"]))
    };
  }

  get valid() {
    return uniqBy(this.brands, brand => brand.name.trim().toLowerCase()).length === this.brands.length &&
           uniqBy(this.stages, stage => stage.name.trim().toLowerCase()).length === this.stages.length &&
           reject(this.brands, brand => !brand.name.trim().length).length == this.brands.length &&
           reject(this.stages, stage => !stage.name.trim().length).length == this.stages.length &&
           this.name.trim().length > 0 &&
           this.brands.length < 5 &&
           this.stages.length < 11
  }

  get subMarketUnderEdit() {
    return find(this.subMarkets, {brand: this.brandUnderEdit, stage: this.stageUnderEdit})
  }

  get segmentIdentifiers() {
    return concat(
      compact(map(this.subMarkets, "matched_id")),
      compact(map(this.subMarkets, "modeled_id"))
    )
  }

  get isChanged() {
    return !isEqual(this.original, this.toJson())
  }
}
