import { NgModule } from '@angular/core';
import { ActivationTile } from './activation-tile.component';
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [
    ActivationTile,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ActivationTile,
  ]
})

export class ActivationTileModule { }
