<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>
<div class="form-container">
  <h3 class="section-header">
    {{insightsContext == 'explore' ? 'Explore' : 'Insights'}}
  </h3>
  <p>
    A separate Excel spreadsheet file containing the data from the segments displayed in {{insightsContext == 'explore' ? 'Explore' : 'Insights'}} will be created for each selected data source.
  </p>
  <div class="options-container">
    <ppc-checkbox
      *ngIf="(topLevelMarketLevelTab$ | async) && (!activePersona || activePersona.is_all_data)"
      class="option"
      [(ngModel)]="exportOptions['Total Population']"
      (ngModelChange)="setCanExport()">
      {{insightsContext == 'explore' ?
        'Market Level Data (All Data Persona)' :
        'Total Population Data (Market Level Only)'}}
    </ppc-checkbox>
    <ppc-checkbox
      *ngIf="(topLevelPersonLevelTab$ | async) && exportOptionsHas('Matched Addressable')"
      class="option"
      [(ngModel)]="exportOptions['Matched Addressable']"
      (ngModelChange)="setCanExport()">
      Person Level Data (Matched Data)
    </ppc-checkbox>
    <ppc-checkbox
      *ngIf="(topLevelPersonLevelTab$ | async) && exportOptionsHas('Modeled Addressable')"
      class="option"
      [(ngModel)]="exportOptions['Modeled Addressable']"
      (ngModelChange)="setCanExport()">
      Modeled Data (Person Level Only)
    </ppc-checkbox>
    <ppc-checkbox
      *ngIf="(topLevelPersonLevelTab$ | async) && exportOptionsHas('Person Level')"
      class="option"
      [(ngModel)]="exportOptions['Person Level']"
      (ngModelChange)="setCanExport()">
      Person Level Data
    </ppc-checkbox>
  </div>
  <div
    class="index-container"
    [class.invisible]="!exportOptions['Modeled Addressable'] && !exportOptions['Matched Addressable'] && !exportOptions['Person Level']"
    [ppcTooltip]="personaHasExcludedSegmentsTooltip"
    [tooltipDisabled]="!(personaHasExcludedSegments$ | async)"
    >
    <mat-slide-toggle
      [(ngModel)]="includeIndexes"
      [disabled]="personaHasExcludedSegments$ | async">
      Include Indices With Person Level Data
    </mat-slide-toggle>
  </div>
  <div class="export-error" [class.invisible]="canExport">
    Must select at least one data source to export.
  </div>
  <div class="button-container">
    <button type="submit"
            class="btn"
            (click)="export()"
            [disabled]="!canExport">
      Export
    </button>
    <button mat-button (click)="insightsExportService.toggleExportPane()">Cancel</button>
  </div>
</div>
