import {catchError} from 'rxjs/operators';

import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import {PpcHttpService, apiUrl} from "../ppc_http.service";
import {handleError} from "../../shared/utils/errors";
import {Vendor} from "../../admin/vendors/vendor";
import {VendorPermission} from "../../admin/vendors/vendor-permission.model";
import {Effect, Actions, ofType} from "@ngrx/effects";
import {
  FetchVendors, LoadVendors, FetchVendorPermissionOptions, LoadVendorPermissionOptions,
  FetchVendorPermissions, LoadVendorPermissions, UpdateVendorPermissions, UpdateVendorPermissionsComplete,
  UpdateVendorPermissionsFail, FetchVendorPermissionsByClientRegion
} from "./vendors.actions";
import {fetchResource} from "../../shared/utils/fetch-state";
import {PermissionOption} from "../../admin/segment-permissions/models/permission-option.model";
import { V3 } from '../../shared/utils/constants';

@Injectable()
export class VendorsService {
  @Effect()
  fetchVendors$ = this.actions$.pipe(
    ofType(FetchVendors.type),
    (fetchResource(
      () => this.getVendors().pipe(map(vendors => new LoadVendors(vendors)))
    )));

  @Effect()
  fetchVendorPermissionOptions$ = this.actions$.pipe(
    ofType(FetchVendorPermissionOptions.type),
    (fetchResource(
      () => this.getVendorPermissionOptions().pipe(map(options => new LoadVendorPermissionOptions(options)))
    )));

  @Effect()
  fetchVendorPermissions$ = this.actions$.pipe(
    ofType(FetchVendorPermissions.type),
    (fetchResource(
      action => this.getVendorPermissions(action.vendorId).pipe(map(permission => new LoadVendorPermissions(permission)))
    )));

  @Effect()
  fetchVendorPermissionsByClientRegion$ = this.actions$.pipe(
    ofType(FetchVendorPermissionsByClientRegion.type),
    (fetchResource(
      action => this.getVendorPermissionsByClientRegion(action.clientId, action.regionId).pipe(map(permission => new LoadVendorPermissions(permission)))
    )));

  @Effect()
  updateVendorPermissions$ = this.actions$.pipe(
    ofType(UpdateVendorPermissions.type),
    (fetchResource(
      action => this.updateVendorPermissions(action.vendorId, action.permissions).pipe(map(res => new UpdateVendorPermissionsComplete()))
    )));

  constructor(private http: PpcHttpService, private actions$: Actions) { }

  vendorsUrl(): string {
    return apiUrl(`/api/${V3}/vendors`);
  }

  vendorPermissionsUrl(vendor_id: string): string {
    return apiUrl(`/api/${V3}/vendor_permissions/${vendor_id}`)
  }

  vendorPermissionsByClientRegionUrl(clientId: string, regionId: string): string {
    return apiUrl(`/api/${V3}/vendor_permissions/client_region/${clientId}/${regionId}`)
  }

  vendorPermissionOptionsUrl(): string {
    return apiUrl('/api/${V3}/vendor_permissions/options')
  }

  optionCategoryVendorPermissions(clientId: string, regionId: string, option: string): string {
    return apiUrl(`/api/${V3}/clients/${clientId}/regions/${regionId}/option_categories/${option}/vendor_permissions`);
  }

  getVendors(): Observable<Vendor[]> {
    return this.http.get(this.vendorsUrl()).pipe(
      map(res => res.data
        .map(data => new Vendor(data))
      ),
      catchError(handleError), );
  }

  getVendorPermissionOptions(): Observable<PermissionOption[]> {
    return this.http.get(this.vendorPermissionOptionsUrl()).pipe(
      map(res => res.data
        .map(data => new PermissionOption(data))
      ),
      catchError(handleError), )
  }

  getVendorPermissions(vendor_id: string): Observable<VendorPermission[]> {
    return this.http.get(this.vendorPermissionsUrl(vendor_id)).pipe(
      map(res => res.data),
      catchError(handleError), )
  }

  getVendorPermissionsByClientRegion(clientId: string, regionId: string): Observable<VendorPermission[]> {
    return this.http.get(this.vendorPermissionsByClientRegionUrl(clientId, regionId)).pipe(
      map(res => res.data),
      catchError(handleError), )
  }

  updateVendorPermissions(vendor_id: string, permissions: VendorPermission[]): Observable<any> {
    return this.http.put(this.vendorPermissionsUrl(vendor_id), {permissions: permissions})
  }

  getOptionCategoryVendorPermissions(clientId: string, regionId: string, optionCategory: string): Observable<VendorPermission[]> {
    return this.http.get(this.optionCategoryVendorPermissions(clientId, regionId, optionCategory)).pipe(
      map(res => res.map(vp => new VendorPermission(vp))));
  }

}
