import { Component, OnInit, ViewChild, ElementRef, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { map, isEqual, sumBy } from 'lodash';
import { toMillions } from 'app/insights/insights.models';
import {  Chart, ChartConfiguration } from 'chart.js';

@Component({
  selector: 'ppc-donut-chart',
  templateUrl: './ppc-donut-chart.component.html',
  styleUrls: ['./ppc-donut-chart.component.sass']
})
export class PpcDonutChartComponent implements OnInit, OnChanges {
  @ViewChild("donut", { static: true }) doughnutChartElement: ElementRef<HTMLCanvasElement>;
  @Input() donutChartData: DonutChartData[];
  @Input() showCounts: boolean = false;
  donutChart: Chart;
  @Output() sliceClicked = new EventEmitter<DonutChartData>();

  constructor() { }

  ngOnInit() {
    const that = this;

    Chart.defaults.font.family = "'Roboto', sans-serif";

    const customTooltip = function (context) {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip');

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = "<table></table>";
        document.body.appendChild(tooltipEl);
      }

      // Hide if no tooltip
      if (context.tooltip.opacity === 0) {
        tooltipEl.style.transform = 'scale(0)';
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (context.tooltip.yAlign) {
        tooltipEl.classList.add(context.tooltip.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      function getLabel(tooltipModel) {
        return tooltipModel.dataPoints[0].label;
      }
      function getValue(tooltipModel) {
        return tooltipModel.dataPoints[0].parsed;

      }
      // Set Text
      const tooltipModel = context.tooltip;
      if (tooltipModel.body) {
        const tooltipLabel = getLabel(tooltipModel);
        const tooltipLabelValue = getValue(tooltipModel);
        const colors = tooltipModel.labelColors[0];
        const convertedTxt = that.showCounts ? toMillions(tooltipLabelValue) : `${that.getPercentage(tooltipLabelValue)} %`;
        const innerHtml = `
        <div class="hovered-color" style="background: ${colors.backgroundColor}"></div>
        <div class="hovered-name">${tooltipLabel}</div>
        <div class="hovered-count">${convertedTxt}</div>
        `
        tooltipEl.innerHTML = innerHtml;
      }
      const position = context.chart.canvas.getBoundingClientRect();
      // Display, position, and set styles for font
      tooltipEl.style.opacity = '1';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
      tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
      tooltipEl.style.pointerEvents = 'none';

      // Display, position, and set styles for font
      tooltipEl.style.transform = 'scale(1)';
      tooltipEl.style.left = position.left + window.pageXOffset + context.tooltip.caretX + 'px';
      tooltipEl.style.top = position.top + window.pageYOffset + context.tooltip.caretY + 'px';
    };

    const config: ChartConfiguration = {
      type: 'doughnut',
      data: this.formattedDonutChartData,
      options: {
        responsive: true,
        elements: {
          arc: {
            borderJoinStyle: 'miter',
            borderWidth: 1,
          },
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            //   // Disable the on-canvas tooltip
            enabled: false,
            usePointStyle: true,
            backgroundColor: 'white',
            external: customTooltip
          }
        },

      }
    };

    this.donutChart = new Chart(this.doughnutChartElement.nativeElement, config);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.donutChartData && !changes.donutChartData.firstChange && !isEqual(changes.donutChartData.previousValue, changes.donutChartData.currentValue)) {
      const newData = this.formattedDonutChartData;
      this.donutChart.data.datasets.forEach((dataset, index) => {
        const newDataset = newData.datasets[index];
        dataset.data = newDataset.data
        dataset.backgroundColor = newDataset.backgroundColor;
        dataset.borderColor = newDataset.borderColor;
        dataset.hoverBorderColor = newDataset.hoverBorderColor;
      });
      this.donutChart.data.labels = newData.labels;
      this.donutChart.update();
    }

  }

  get formattedDonutChartData() {
    return {
      datasets: [{
        data: map(this.donutChartData, "count"),
        backgroundColor: map(this.donutChartData, "color"),
        borderColor: map(this.donutChartData, d => "#212121"),
        hoverBorderColor: map(this.donutChartData, d => d.color),
        hoverOffset: 1,
        cutout: '78%',
        animation: {
          animateRotate: true
        },
      }],
      labels: map(this.donutChartData, "label")
    }
  }

  chartClicked(event) {

    const points = this.donutChart.getElementsAtEventForMode(event, 'index', { intersect: true }, true);

    if (points.length) {
      const firstPoint = points[0];
      this.sliceClicked.emit(this.donutChartData[firstPoint.index]);
    }

    // always remove tooltip after click event
    const tooltipEl = document.getElementById('chartjs-tooltip');
    if (tooltipEl) {
      tooltipEl.remove();
    }
  }

  getPercentage(count) {
    if (typeof count == 'string') {
      return (+count).toFixed(1);
    }
    return ((count / sumBy(this.donutChartData, "count")) * 100).toFixed(1)
  }

}

export interface DonutChartData {
  label: string;
  count: number;
  color: string;
  selected?: boolean;
}
