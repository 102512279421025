import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { DestinationModalComponent } from 'app/audiences/overview-v3/destination-modal/destination-modal.component';

@NgModule({
  declarations: [
    DestinationModalComponent
  ],
  imports: [
    CommonModule,
    PpcComponentsModule,
    FormsModule
  ],
  exports: [
    DestinationModalComponent
  ],
  providers: [
    HttpClientModule,
  ]
})

export class DestinationModalModule { }
