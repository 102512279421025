import { Component, OnInit, ElementRef, ViewChild, Input, HostBinding } from '@angular/core';
import { loadLiquidFillGauge, liquidFillGaugeDefaultSettings } from './fill-gauge';
import * as d3 from 'd3';
import { clamp } from 'lodash';

export const FILL_GAUGE_MIN_RADIUS = 90;
export const FILL_GAUGE_MAX_RADIUS = 250;
export const FILL_GAUGE_SIZE_SCALE = d3.scaleLinear()
  .range([FILL_GAUGE_MIN_RADIUS, FILL_GAUGE_MAX_RADIUS])
  .domain([0, 100])

@Component({
  selector: 'ppc-journey-fill-gauge',
  templateUrl: './journey-fill-gauge.component.html',
  styleUrls: ['./journey-fill-gauge.component.sass']
})
export class JourneyFillGaugeComponent implements OnInit {
  @ViewChild("svg", { static: true }) svg: ElementRef;
  @Input() value: number;
  @Input() personCount: number;
  @Input() id: string;
  @Input() color: string;

  @HostBinding("style.width.px")
  @HostBinding("style.height.px")
  diameter: number;

  gaugeConfig = liquidFillGaugeDefaultSettings();
  sizeScale = FILL_GAUGE_SIZE_SCALE;
  gaugeUpdater: any;
  settleInterval: NodeJS.Timer;
  settleTimeout: NodeJS.Timer;

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.diameter = this.sizeScale(this.value || 0);
      this.gaugeConfig.circleColor = this.color;
      this.gaugeConfig.waveColor = this.color;
      this.gaugeUpdater = loadLiquidFillGauge(this.svg.nativeElement, this.value, this.gaugeConfig, this.sizeScale, this.personCount);
    }, 50)
  }

  ngOnChanges(changes) {
    if ((changes.color || changes.value || changes.personCount) && this.gaugeUpdater && this.value != null) {
      if (changes.value) {
        if (Number.isNaN(this.value)) {
          this.value = 0;
        };
        if (this.settleInterval) {clearInterval(this.settleInterval); }
        if (this.settleTimeout) {clearTimeout(this.settleTimeout); }
        this.settleTimeout = setTimeout(() => {
          if (this.value == null) {return; }
          this.gaugeConfig.waveHeight = 0;
          this.gaugeUpdater.update(this.value, this.personCount);
          if (this.settleInterval) {clearInterval(this.settleInterval); }
        }, 8001)
        this.settleInterval = setInterval(() => {
          if (this.value == null) {return; }
          this.gaugeConfig.waveHeight *= 0.5;
          this.gaugeConfig.waveAnimateTime *= 1.1;
          this.gaugeConfig.waveAnimateTime = clamp(this.gaugeConfig.waveAnimateTime, 800, 3000);
          this.gaugeUpdater.update(this.value, this.personCount);
        }, 400)
        this.gaugeConfig.waveAnimateTime = 800;
        this.gaugeConfig.waveHeight = clamp(Math.abs(Number.isNaN(changes.value.previousValue) ? 0 : changes.value.previousValue - this.value) / 200, 0.03, 0.3);
      }
      this.diameter = this.sizeScale(this.value || 0);
      this.gaugeConfig.circleColor = this.color;
      this.gaugeConfig.waveColor = this.color;
      this.gaugeUpdater.update(this.value, this.personCount);
    }
  }

}
