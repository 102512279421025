<div class="wrapper">

  <div class="header">
    <h1>Segment<span *ngIf="!audienceCopy">/Audience</span> with zero counts</h1>
    <i class="fa fa-close fa-lg hoverable" (click)="dialog.close()"></i>
  </div>

  <div class="body">
    <div class="subtitle">
      <img src="assets/icons/warning-orange.svg" />
      Segment<span *ngIf="!audienceCopy"> and audience</span> counts may display as zero due to the following reasons:
    </div>

    <ul>
      <li>
        <span class="bold">Expiration:</span>
        No new data has been received from the vendor for this segment.
      </li>
      <li>
        <span class="bold">Membership:</span>
        No IDs were received for this segment based off the vendor definition.
      </li>
    </ul>
  </div>

  <p>
    Please
    <a href="https://support.epsilon.com/csm?id=sc_cat_item&sys_id=c747668bdb37ec50e8d7fe1b689619d8&t=sc_discovery,kb_discovery" target="_blank">
      contact support
    </a>
    for further questions.
  </p>
</div>
