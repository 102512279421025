<header>
  <h5 class="nav" (click)="closeSelf()">
    <i class="fa fa-arrow-left"></i>
    <div>Audiences</div>
  </h5>
  <h4 class="page-title">{{ppcObjectType}} Detail</h4>
  <ng-container>
    <h1 class="ppc-object-name">
      <i *ngIf="ppcObject && isAudience(ppcObject)" class="fa fa-user" [ppcTooltip]="ppcObjectType"></i>
      <i *ngIf="ppcObject && isLookalike(ppcObject)" class="fa fa-users" [ppcTooltip]="ppcObjectType"></i>
      <i *ngIf="ppcObject && isMotivation(ppcObject)" class="fa fa-trophy" [ppcTooltip]="ppcObjectType"></i>

      <div *ngIf="ppcObject">{{ppcObject.name}}</div>
    </h1>
  </ng-container>
</header>


