import { Injectable, OnDestroy } from "@angular/core";
import { Observable, of as observableOf, iif } from "rxjs";
import { ActivatedRoute } from '@angular/router';
import { PpcHttpService } from "app/services/ppc_http.service";
import { MotivationAudience } from 'app/motivation-audience/motivation-audience.model';
import { map, concatMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getAudienceIdentifiers } from 'app/explore/explore.utils';
import { first, get } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { customGWISurveySegments, getCustomSurveyRoot } from 'app/audience-builder/audience-builder.utils';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { motivationAudiencesUrl } from "app/shared/constants/insights.urls";

@UntilDestroy()
@Injectable()
export class MotivationAudienceService implements OnDestroy {
  isMotivation$ = this.route.queryParams.pipe(map(queryParams => queryParams && queryParams.action === "requestMotivationAudience"));
  isMotivation: boolean;

  constructor(
    private http: PpcHttpService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private segmentsV2Service: SegmentV2Service
  ) {
    this.isMotivation$.pipe(untilDestroyed(this)).subscribe(isMotivation => this.isMotivation = isMotivation);
  }

  ngOnDestroy() {}

  saveMotivationAudience(motivationAudience: MotivationAudience): Observable<MotivationAudience> {
    return this.http.post(
      motivationAudiencesUrl(), {motivation_audience: motivationAudience}
    ) as Observable<MotivationAudience>;
  }

  requestMotivationAudience(payloadAudience, builderAudience, returnAction) {
    const audienceIds = { included_ids: getAudienceIdentifiers(payloadAudience, "include"), excluded_ids: getAudienceIdentifiers(payloadAudience, "exclude") };
    const customSurveyRoot = getCustomSurveyRoot(first(customGWISurveySegments(builderAudience)));

    iif(
      () => !!customSurveyRoot,
      this.segmentsV2Service.fetchByIdentifiers([customSurveyRoot]),
      observableOf([null])
    ).pipe(
      concatMap(([customSurveyRootSegment]) => {
        const motivationAudience = {
          name: payloadAudience.name,
          identifiers: audienceIds,
          custom_survey_root: customSurveyRootSegment?.vendor_remote_id
        } as MotivationAudience;
        return this.saveMotivationAudience(motivationAudience);
      })
    ).subscribe(
      response => {
        this.snackbar.open("This Motivation Audience has been requested! Please allow up to 48 hours for processing.", "OK", {
          panelClass: ["success"],
          duration: 5000
        })
        returnAction();
      },
      error => {
        const message = get(error, ["error", "error", "message"], "Something went wrong requesting this audience. Please try again later.");
        this.snackbar.open(message, "OK", {
          panelClass: ["danger"]
        })
      }
    );
  }

  public get subject() {
    return this.isMotivation ? "Motivation Audience" : null;
  }

  public get pageTitle() {
    return this.isMotivation ? "Request Motivation Audience" : null;
  }

  public get estimatedPeopleCountTooltip() {
    return this.isMotivation ? 'Count of unique people in this motivation audience' : null;
  }

  public get placeholder() {
    return null;
  }
}
