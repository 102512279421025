import { MenuItem } from '../shared/admin-action-menu/menu-item.interface';
import {checkPermissions} from "app/authorization/permissions/permissions.reducers";
import {
  NAV_ADMIN,
  NAV_ADMIN_GLOBAL,
  NAV_ADMIN_REPORTS,
  NAV_ADMIN_UPLOAD,
  ADMIN_DASHBOARD_IMAGE_FOLDER,
  ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER,
  NAV_PLANS,
  NAV_ADMIN_BRANDS,
  NAV_ADMIN_CLIENTS,
  NAV_ADMIN_REGIONS,
  NAV_ADMIN_STATS,
  NAV_ADMIN_CLIENT_MAPPINGS
} from '../../shared/utils/constants';

import { environment } from "environments/environment";
import {getSlugs} from 'app/hierarchy/hierarchy.utils'

export const FEATURE_MODULES_CATEGORY = "Feature Modules"
export const PLATFORM_CATEGORY = "Platform"
export const ACCESS_CATEGORY = "Access"
export const STATISTICS_CATEGORY = "Statistics"

const isTier3: boolean = environment.isTier3;

export function generateAdminDashboardTileData(permissions, navPaths, isAdmin): MenuItem[] {

  return [
    {
      title: 'Plans Setup',
      category: FEATURE_MODULES_CATEGORY,
      summary: 'Select media types, define curve parameters, input Scenario Planner IDs',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/plans-CMS.png`,
      link: [...getSlugs(navPaths.product), NAV_PLANS, NAV_ADMIN],
      enabled: permissions.plans && permissions.plans.create
    },
    {
      title: 'Upload: Discussions',
      category: FEATURE_MODULES_CATEGORY,
      summary: 'Discussions Creation',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/discussion.svg`,
      link: [...getSlugs(navPaths.region), 'upload', 'discussions_upload', 'admin'],
      enabled: navPaths.region && checkPermissions(permissions,
        {upload: 'CRUD', upload_discussions: 'CRUD'},
        isAdmin)
    },
    {
      title: 'Upload: Grow',
      category: FEATURE_MODULES_CATEGORY,
      summary: 'Chart and Custom Widget Creation',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/widget.svg`,
      link: [...getSlugs(navPaths.region), 'upload', 'mekkos_upload', 'admin'],
      enabled: navPaths.region && checkPermissions(permissions,
        {upload: 'CRUD', upload_mekkos: 'CRUD'},
        isAdmin)
    },
    {
      title: 'Upload: Surveys',
      category: FEATURE_MODULES_CATEGORY,
      summary: 'Market-level Survey Creation',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/survey.svg`,
      link: [...getSlugs(navPaths.region), 'upload', 'surveys_upload', 'admin'],
      enabled: navPaths.region && checkPermissions(permissions,
        {upload: 'CRUD', upload_surveys: 'CRUD'},
        isAdmin)
    },
    {
      title: 'Upload: Journey',
      category: FEATURE_MODULES_CATEGORY,
      summary: 'Journey and Widget Creation',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/journey-upload.svg`,
      link: [...getSlugs(navPaths.region), 'upload', 'journeys_upload', 'admin'],
      enabled: navPaths.region && checkPermissions(permissions,
        {upload: 'CRUD', upload_journeys: 'CRUD'},
        isAdmin)
    },
    {
      title: 'Upload: Explore',
      category: FEATURE_MODULES_CATEGORY,
      summary: 'Persona and Custom Widget Creation',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/explore.png`,
      link: [...getSlugs(navPaths.region), 'upload', 'personas_upload', 'admin'],
      enabled: navPaths.region && checkPermissions(permissions,
        {upload: 'CRUD', upload_personas: 'CRUD'},
        isAdmin)
    },
    {
      title: 'Setup Destinations',
      category: FEATURE_MODULES_CATEGORY,
      summary: 'Create multi-level Audience Destinations for Activation',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/admin-setup-dest.svg`,
      link: [NAV_ADMIN_GLOBAL, NAV_ADMIN, 'destinations-admin'],
      enabled: permissions.destinations && permissions.destinations.create && !isTier3
    },
    {
      title: 'Brand Setup',
      category: PLATFORM_CATEGORY,
      summary: 'Create brands and products, define currency',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/brand-setup.png`,
      link: [...getSlugs(navPaths.client), NAV_ADMIN_BRANDS, NAV_ADMIN],
      enabled: permissions.brands && permissions.brands.create
    },
    {
      title: 'Client Setup',
      category: PLATFORM_CATEGORY,
      summary: 'Create client, assign tab access',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/client-setup.png`,
      link: [NAV_ADMIN_GLOBAL, NAV_ADMIN, NAV_ADMIN_CLIENTS],
      enabled: checkPermissions(permissions, {clients: 'CRU'}, isAdmin)
    },
    {
      title: 'Region Setup',
      category: PLATFORM_CATEGORY,
      summary: 'Create regions, map to countries, exclude tab access',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/region-setup.png`,
      link: [NAV_ADMIN_GLOBAL, NAV_ADMIN, NAV_ADMIN_REGIONS],
      enabled: checkPermissions(permissions, {regions: 'CRU'}, isAdmin)
    },
    {
      title: 'Client Mapping',
      category: PLATFORM_CATEGORY,
      summary: 'Map Propsect instance IDs to Discovery clients',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/region-setup.png`,
      link: [NAV_ADMIN_GLOBAL, NAV_ADMIN, NAV_ADMIN_CLIENT_MAPPINGS],
      enabled: checkPermissions(permissions, { client_mappings: 'CRUD' }, isAdmin)
    },
    {
      title: 'Api Keys Admin',
      category: ACCESS_CATEGORY,
      summary: 'Audit available API keys in platform',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/api-keys.png`,
      link: [NAV_ADMIN_GLOBAL, NAV_ADMIN, 'api-keys'],
      enabled: checkPermissions(permissions, {api_keys: 'CRU'}, isAdmin)
    },
    {
      title: 'Roles Setup',
      category: ACCESS_CATEGORY,
      summary: 'Create and edit roles and permissions',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/user-setup.png`,
      link: [NAV_ADMIN_GLOBAL, NAV_ADMIN, 'roles'],
      enabled: checkPermissions(permissions, {roles: 'R'}, isAdmin)
    },
    {
      title: 'User Settings',
      category: ACCESS_CATEGORY,
      summary: 'Login details, update password, view roles and client assignments',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/user-settings.png`,
      link: [NAV_ADMIN_GLOBAL, NAV_ADMIN, 'user-settings'],
      enabled: checkPermissions(permissions, {users: 'R', roles: 'R'}, isAdmin)
    },
    {
      title: 'Segment Permissions',
      category: ACCESS_CATEGORY,
      summary: 'Modify segment permissions',
      imageSrc: `${ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER}/SegPermissions.svg`,
      link: [NAV_ADMIN_GLOBAL, NAV_ADMIN, 'segment-permissions-v2'],
      enabled: checkPermissions(permissions, {segment_permissions_v2: 'U'}, isAdmin)
    },
    {
      title: 'Statistics',
      category: STATISTICS_CATEGORY,
      summary: 'Insights statistics',
      imageSrc: `${ADMIN_DASHBOARD_IMAGE_FOLDER}/Global.png`,
      link: [NAV_ADMIN_GLOBAL, NAV_ADMIN, NAV_ADMIN_STATS],
      enabled: checkPermissions(permissions, {statistics: 'R'}, isAdmin)
    },
  ]
}
