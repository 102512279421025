import * as actions from './audiences.actions'
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {createSelector} from '@ngrx/store'
import {ClearAudienceHierarchies, LoadAudienceHierarchies} from "./audiences.actions";
import {AudienceHierarchy} from "../shared/segments/audience_hierarchy.interface";
import {compareStrings} from "app/shared/utils/utils";

export type State = EntityState<AudienceHierarchy>

const adapter = createEntityAdapter<AudienceHierarchy>();

const initialState = adapter.getInitialState();

export function reducer(state: State = initialState, action: actions.All) {
  switch (action.type) {
    case LoadAudienceHierarchies.type:
      return adapter.addMany(action.audienceHierarchies, state);
    case ClearAudienceHierarchies.type:
      return adapter.removeAll(state);
    default:
      return state
  }
}

export const {
  selectEntities: getAudienceHierarchiesMap,
  selectAll: getAudienceHierarchiesList
} = adapter.getSelectors();

export const getAddressableAudienceHierarchies = createSelector(
  getAudienceHierarchiesList,
  ahs => ahs.filter(ah => ah.is_addressable).sort(compareStrings('name'))
);
