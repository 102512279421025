<div class="instructions">
  Visualize census gathered data sets for this market.
</div>

<div class="packery-container" [packery]="bagName" [packeryModel]="regionLevelDemographics" [forceLayout$]="forceLayout$"  (orderChanged)="saveConfig($event)">
    <ppc-region-level-demographic-widget class="packery-item"
                                        [class.jumbo]="jumbo$ | async"
                                        *ngFor="let regionLevelDemographic of visibleDemographics; trackBy: trackById" 
                                        [regionLevelDemographic]="regionLevelDemographic" 
                                        (hideDemographic)="hideDemographic($event)" 
                                        [attr.data-id]="regionLevelDemographic.id"></ppc-region-level-demographic-widget>
</div>