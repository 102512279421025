import { ConfirmationComponent } from "app/shared/components/confirmation/confirmation.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatOptionModule, MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { ObserversModule } from "@angular/cdk/observers";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NguCarouselModule } from "@ngu/carousel";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { Daterangepicker } from "ng2-daterangepicker";
import { NgLetModule } from 'ng-let';

import { ContextMenuComponent } from "app/shared/components/context-menu/context-menu.component";
import { DonutChartComponent } from "app/shared/d3/donut-chart/donut-chart.component";
import { FilterBarComponent } from "app/insights/insights-components/filter-bar/filter-bar.component";
import { LoadingOverlayComponent } from "app/shared/components/loading-overlay/loading-overlay.component";
import { MaterialModalComponent } from "app/shared/components/material-modal/material-modal.component";
import { MaterialModalService } from "app/shared/components/material-modal/material-modal.service";
import { MediaTypeTableComponent } from "app/scenario-dialog/media-type-table/media-type-table.component";
import { Paginator } from "app/shared/components/paginator/paginator.component";
import { PlanInputsComponent } from "app/scenario-dialog/plan-inputs/plan-inputs.component";
import { PopupComponent } from "app/shared/components/popup/popup.component";
import { PopupService } from "app/shared/components/popup/popup.service";
import { PpcAccordionComponent } from "app/shared/components/ppc-accordion/ppc-accordion.component";
import { PpcBarChartComponent } from "app/shared/components/ppc-bar-chart/ppc-bar-chart.component";
import { PpcCarouselComponent } from "app/shared/components/ppc-carousel/ppc-carousel.component";
import { PpcCheckboxComponent } from "app/shared/components/ppc-checkbox/ppc-checkbox.component";
import { PpcDialogComponent } from "app/shared/components/ppc-dialog/ppc-dialog.component";
import { PpcDonutChartComponent } from "app/shared/components/ppc-donut-chart/ppc-donut-chart.component";
import { PpcIconButtonComponent } from "app/shared/components/ppc-icon-button/ppc-icon-button.component";
import { PpcInputComponent } from "app/shared/components/ppc-input/ppc-input.component";
import { PpcErrorComponent } from "app/shared/components/ppc-error/ppc-error.component";
import { PpcProgressBarComponent } from "app/shared/components/ppc-progress-bar/ppc-progress-bar.component";
import { PpcTabComponent } from "app/shared/components/ppc-tab/ppc-tab.component";
import { PpcTabsComponent } from "app/shared/components/ppc-tabs/ppc-tabs.component";
import { PpcTabsService } from "app/shared/components/ppc-tabs/ppc-tabs.service";
import { PpcTextareaComponent } from "app/shared/components/ppc-textarea/ppc-textarea.component";
import { PpcTooltipComponent } from "app/shared/components/ppc-tooltip/ppc-tooltip.component";
import { PpcTooltipDirective } from "app/shared/components/ppc-tooltip/ppc-tooltip.directive";
import { PpcTooltipService } from "app/shared/components/ppc-tooltip/ppc-tooltip.service";
import { PpcVirtualScrollComponent } from "app/shared/components/ppc-virtual-scroll/ppc-virtual-scroll.component";
import { RadioButtonComponent } from "app/shared/components/radio-button/radio-button.component";
import { RadioGroupDirective } from "app/shared/components/radio-button/radio-group.directive";
import { PackeryDirective } from "app/shared/directives/packery.directive";
import { PackeryService } from "app/services/packery.service";
import { ScenarioDialogComponent } from "app/scenario-dialog/scenario-dialog.component";
import { SearchableDropdownComponent } from "app/shared/components/searchable-dropdown/searchable-dropdown.component";
import { SelectBoxComponent } from "app/shared/components/select-box/select-box.component";
import { SlideInOverlayComponent } from "app/shared/components/slide-in-overlay/slide-in-overlay.component";
import { TreeBrowserComponent } from "app/shared/components/tree-browser/tree-browser.component";
import { TreeBrowserBreadcrumbComponent } from "app/shared/components/tree-browser-breadcrumb/tree-browser-breadcrumb.component";
import { TreeLevelComponent } from "app/shared/components/tree-browser/tree-level/tree-level.component";
import { TreeMultiSelect } from "app/shared/components/tree-multi-select/tree-multi-select.component";
import { TreeNodeSelectComponent } from "app/shared/tree-node-select/tree-node-select.component";
import { DateFromTimestampPipe } from "app/shared/formatters/date-from-timestamp.pipe";
import { CheckOverflowDirective } from "app/shared/directives/check-overflow.directive";
import { HttpClientModule } from "@angular/common/http";
import { ConfirmationDirective } from "app/shared/directives/confirmation.directive";
import { CSVService } from "app/services/csv/csv.service";
import { PdsTabsComponent } from "app/shared/components/pds-tabs/pds-tabs.component";
import { HierarchySelectorComponent } from "app/hierarchy-selector/hierarchy-selector.component";
import { DateRangePickerComponent } from "app/shared/components/date-range-picker/date-range-picker.component";
import { CheckFeatureAccessDirective } from "app/require-feature-access.directive";
import { PpcDropdownComponent } from "app/shared/components/ppc-dropdown/ppc-dropdown.component";
import { PpcSlideToggleComponent } from "app/shared/components/ppc-slide-toggle/ppc-slide-toggle.component";
import { SubjectModelDirective } from "app/shared/directives/subject-model.directive";
import { PpcSplitScreenComponent } from "app/shared/components/ppc-split-screen/ppc-split-screen.component";
import { IconPickerComponent } from "app/shared/components/icon-picker/icon-picker.component";
import { PpcVirtualScrollItemComponent } from "app/shared/components/ppc-virtual-scroll/ppc-virtual-scroll-item/ppc-virtual-scroll-item.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PpcExpandableDirective } from "app/shared/directives/ppc-expandable.directive";
import { PpcAutoFocusDirective } from "app/shared/directives/autofocus.directive";
import { MillionsFormatterPipe } from "app/shared/formatters/millions-formatter.pipe";
import { PpcIconComponent } from "../shared/components/ppc-icon/ppc-icon.component";
import { ZeroCountInfoComponent } from "app/zero-counts/zero-count-info/zero-count-info.component";
import { ZeroCountWarningIconComponent } from "app/zero-counts/zero-count-warning-icon/zero-count-warning-icon.component";
import { ClientContextSelectorComponent } from "app/shared/components/client-context-selector/client-context-selector.component";
import { PpcThrottleClickDirective } from "app/shared/directives/throttle-click.directive";
import { PersonaCompareDropdownComponent } from "app/explore/persona-compare-dropdown/persona-compare-dropdown.component";
import { NumericRangeDirective } from "app/shared/directives/numeric-range.directive";
import { WrapParenthesisPipe } from "app/shared/formatters/wrap-parenthesis.pipe";
import { PpcSortComponent } from "app/shared/components/ppc-sort/ppc-sort.component";
import { PpcSortService } from "app/shared/components/ppc-sort/ppc-sort.service";

/*
  Only add components that are shared between pages and don't contain any
  business logic. Also please keep alphabetized!
*/

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    Daterangepicker,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatCardModule,
    MatGridListModule,
    MatSliderModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTabsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatExpansionModule,
    HttpClientModule,
    NguCarouselModule,
    NgxDatatableModule,
    ObserversModule,
    DragDropModule,
    NgLetModule,
  ],
  exports: [
    MillionsFormatterPipe,
    CheckFeatureAccessDirective,
    CheckOverflowDirective,
    ConfirmationComponent,
    ConfirmationDirective,
    BrowserAnimationsModule,
    CommonModule,
    ContextMenuComponent,
    DateRangePickerComponent,
    DateFromTimestampPipe,
    DonutChartComponent,
    FilterBarComponent,
    FormsModule,
    HierarchySelectorComponent,
    IconPickerComponent,
    LoadingOverlayComponent,
    MaterialModalComponent,
    MediaTypeTableComponent,
    NguCarouselModule,
    NgxDatatableModule,
    PackeryDirective,
    Paginator,
    PlanInputsComponent,
    PopupComponent,
    PpcAccordionComponent,
    PpcBarChartComponent,
    PpcCarouselComponent,
    PpcCheckboxComponent,
    PpcDialogComponent,
    PpcDonutChartComponent,
    PpcDropdownComponent,
    PpcExpandableDirective,
    PpcIconButtonComponent,
    PpcInputComponent,
    PpcErrorComponent,
    PpcProgressBarComponent,
    PpcSlideToggleComponent,
    PpcSplitScreenComponent,
    PpcTabComponent,
    PpcTabsComponent,
    PdsTabsComponent,
    PpcTextareaComponent,
    PpcTooltipComponent,
    PpcTooltipDirective,
    PpcIconComponent,
    RadioButtonComponent,
    RadioGroupDirective,
    ReactiveFormsModule,
    ScenarioDialogComponent,
    SearchableDropdownComponent,
    SelectBoxComponent,
    SlideInOverlayComponent,
    SubjectModelDirective,
    TreeBrowserComponent,
    TreeBrowserBreadcrumbComponent,
    TreeLevelComponent,
    TreeMultiSelect,
    TreeNodeSelectComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatCardModule,
    MatGridListModule,
    MatSliderModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTabsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatExpansionModule,
    HttpClientModule,
    NgxDatatableModule,
    PpcVirtualScrollComponent,
    PpcVirtualScrollItemComponent,
    DragDropModule,
    PpcAutoFocusDirective,
    ZeroCountInfoComponent,
    ZeroCountWarningIconComponent,
    ClientContextSelectorComponent,
    PersonaCompareDropdownComponent,
    PpcThrottleClickDirective,
    NumericRangeDirective,
    WrapParenthesisPipe,
    PpcSortComponent
  ],
  declarations: [
    MillionsFormatterPipe,
    CheckFeatureAccessDirective,
    CheckOverflowDirective,
    ConfirmationComponent,
    ConfirmationDirective,
    ContextMenuComponent,
    DateRangePickerComponent,
    DateFromTimestampPipe,
    DonutChartComponent,
    FilterBarComponent,
    HierarchySelectorComponent,
    IconPickerComponent,
    LoadingOverlayComponent,
    MaterialModalComponent,
    MediaTypeTableComponent,
    PackeryDirective,
    Paginator,
    PlanInputsComponent,
    PopupComponent,
    PpcAccordionComponent,
    PpcBarChartComponent,
    PpcCarouselComponent,
    PpcCheckboxComponent,
    PpcDialogComponent,
    PpcDonutChartComponent,
    PpcDropdownComponent,
    PpcExpandableDirective,
    PpcIconButtonComponent,
    PpcInputComponent,
    PpcErrorComponent,
    PpcProgressBarComponent,
    PpcSlideToggleComponent,
    PpcSplitScreenComponent,
    PpcTabComponent,
    PpcTabsComponent,
    PdsTabsComponent,
    PpcTextareaComponent,
    PpcTooltipComponent,
    PpcTooltipDirective,
    PpcIconComponent,
    RadioButtonComponent,
    RadioGroupDirective,
    ScenarioDialogComponent,
    SearchableDropdownComponent,
    SelectBoxComponent,
    SlideInOverlayComponent,
    SubjectModelDirective,
    TreeBrowserComponent,
    TreeBrowserBreadcrumbComponent,
    TreeLevelComponent,
    TreeMultiSelect,
    TreeNodeSelectComponent,
    PpcVirtualScrollComponent,
    PpcVirtualScrollItemComponent,
    PpcAutoFocusDirective,
    ZeroCountInfoComponent,
    ZeroCountWarningIconComponent,
    ClientContextSelectorComponent,
    PpcThrottleClickDirective,
    PersonaCompareDropdownComponent,
    NumericRangeDirective,
    WrapParenthesisPipe,
    PpcSortComponent
  ],
  providers: [
    MaterialModalService,
    PackeryService,
    PopupService,
    PpcTabsService,
    PpcTooltipService,
    CSVService,
    PpcSortService
  ],
})
export class PpcComponentsModule {}
