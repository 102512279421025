export class Vendor {
  id: string;
  name: string;
  provider_code: string;
  type: string;

  checked?: boolean;

  constructor(args) {
    Object.assign(this, args);
  }

  get isThirdParty(): boolean {
    return this.type === THIRD_PARTY;
  }

  get isFirstParty(): boolean {
    return this.type === FIRST_PARTY;
  }

}

const THIRD_PARTY = "third party";
const FIRST_PARTY = "first party";

