<div class="edit-pane" *ngIf="marketLevelDemographic">
  <div class="edit-pane-header">
    <div class="edit-pane-header-text">
      <div class="edit-pane-title">
        Create New Custom Widget
      </div>
    </div>
    <div class="edit-pane-header-close" (click)="cancel()">
      <img src="assets/icons/close.svg" />
    </div>
  </div>
  <div class="edit-pane-section">
    <ppc-input #input [(ngModel)]="marketLevelDemographic.name">
      <label>Name</label>
      <div suffix class="required-suffix" *ngIf="!input.value">
        *
      </div>
    </ppc-input>
    <div class="edit-pane-section">
      <ppc-textarea [(ngModel)]="marketLevelDemographic.description"><label>Description</label></ppc-textarea>
    </div>
  </div>
  <div class="edit-pane-section">
    <div class="market-level-demographics-submarket-bg"></div>
    <div class="market-level-demographics-header">
      <div class="market-level-demographics-header-text">
        <div class="edit-pane-section-header">
          Variables
        </div>
        <div class="edit-pane-section-instructions">
          Attach variables and counts to your {{ this.resourceType.toLowerCase() }} below.
        </div>
      </div>
      <div class="market-level-demographics-header-select" *ngIf="resourceType != 'Persona'">
        <div class="market-level-demographics-header-text" *ngIf="resourceType == 'Journey'">
          <div class="edit-pane-section-header">
            Brand
          </div>
        </div>
        <app-select-box *ngIf="resourceType == 'Journey'" [items]="brands" labelPath="name" valuePath="id" [(ngModel)]="brandId" (ngModelChange)="brandChange()"></app-select-box>

        <div class="market-level-demographics-header-text" *ngIf="resourceType == 'Mekko'">
          <div class="edit-pane-section-header">
            Market
          </div>
        </div>
        <app-select-box *ngIf="resourceType == 'Mekko'" [items]="markets" labelPath="name" valuePath="id" [(ngModel)]="marketId" (ngModelChange)="marketChange()"></app-select-box>

        <div class="market-level-demographics-header-text" *ngIf="resourceType == 'Mekko'">
          <div class="edit-pane-section-header journey-dropdown">
            Audience
          </div>
        </div>

        <div class="market-level-demographics-header-text" *ngIf="resourceType == 'Journey'">
          <div class="edit-pane-section-header journey-dropdown">
            Stage
          </div>
        </div>
        <app-select-box [items]="subMarketNames" labelPath="name" valuePath="id" [(ngModel)]="selectedSubMarketId"></app-select-box>
      </div>
    </div>
    <div class="edit-pane-list-section" *ngIf="selectedSubMarketId">
      <div class="edit-pane-list-section-header">
        <div class="name">
          Variable Name
        </div>
        <div class="value">
          Value
        </div>
      </div>
      <div class="edit-pane-list-section-items" [dragula]="bagName" [dragulaModel]="marketLevelDemographic.market_level_demographic_buckets">
        <div *ngFor="let bucket of buckets; let i=index" class="edit-pane-list-section-item" [class.hide]="bucket._destroy">
          <div class="bucket-name-container">
            <i class="fa fa-bars drag-handle"></i>
            <ppc-input placeholder="Untitled variable" class="name" [(ngModel)]="bucket.name" [noLabel]="true" [noErrorContainer]="true" [tabindex]="i + 1">
              <div suffix *ngIf="!bucket.name" class="required-suffix">
                *
              </div>
            </ppc-input>
            <img src="assets/icons/close.svg" (click)="removeBucket(bucket)"/>
          </div>
          <ppc-input placeholder="Count" class="value" type="number" [min]="0" [(ngModel)]="bucket.sub_market_bucket_entries[selectedSubMarketId].value" [noLabel]="true"  [noErrorContainer]="true" [tabindex]="i + 1 + buckets.length">
            <div suffix *ngIf="!bucket.sub_market_bucket_entries[selectedSubMarketId].value" class="required-suffix">
              *
            </div>
          </ppc-input>

        </div>
      </div>
      <div class="edit-pane-list-section-item sub-market-entry-info">
        <div matRipple class="edit-pane-list-section-add-item" (click)="addBucket()">
          Add Variable
        </div>
        <div class="sub-market-total" *ngIf="resourceType != 'Persona'">
          <span [class.red]="subMarketBucketEntryTotal > subMarketTotalPopulation"
                [class.gold]="subMarketBucketEntryTotal < subMarketTotalPopulation"
                [class.green]="subMarketBucketEntryTotal == subMarketTotalPopulation">{{ subMarketBucketEntryTotal | number}}</span> / {{ subMarketTotalPopulation | number }}
        </div>
      </div>
    </div>
  </div>
  <div class="edit-pane-section">
    <div class="edit-pane-section-header">
      Chart Type
    </div>
    <div ppcRadioGroup [(ngModel)]="marketLevelDemographic.chart_type">
      <ppc-radio-button value="{{chartTypes.BarHorizontal}}">Bar (Horizontal)</ppc-radio-button>
      <ppc-radio-button value="{{chartTypes.BarVertical}}">Bar (Vertical)</ppc-radio-button>
      <ppc-radio-button value="{{chartTypes.Doughnut}}">Pie Chart</ppc-radio-button>
    </div>
  </div>
  <div class="edit-pane-section">
    <div class="edit-pane-section-header">Metric</div>
    <div ppcRadioGroup [(ngModel)]="marketLevelDemographic.id_count">
      <ppc-radio-button value="percentage" [ppcTooltip]="percentageTooltip">
        Percentage
      </ppc-radio-button>
      <ppc-radio-button value="scaled" *ngIf="resourceType != 'Persona'" [ppcTooltip]="toolTips.NonPersonaScaled">
        Scaled
      </ppc-radio-button>
      <ppc-radio-button value="absolute" [ppcTooltip]="toolTips.NonPersonaAbsolute">
        {{ resourceType == 'Persona' ? 'Count' : 'Absolute' }}
      </ppc-radio-button>
    </div>
  </div>
  <div class="edit-pane-error" *ngIf="hasTooManyBucketsForBarVertical">
      {{errorMessages.BarChartVerticalLimits}}
  </div>
  <div class="edit-pane-actions">
    <button matRipple class="btn" (click)="save()" [disabled]="!isValid">
      Save
    </button>
    <button mat-button (click)="cancel()">
      Cancel
    </button>
  </div>
</div>
