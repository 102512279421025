import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent as observableFromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ppc-dropdown',
  templateUrl: './ppc-dropdown.component.html',
  styleUrls: ['./ppc-dropdown.component.sass']
})
export class PpcDropdownComponent implements OnInit, OnDestroy {
  open = false;

  constructor(private elem: ElementRef) { }

  ngOnInit() {
    observableFromEvent(document, "click").pipe(
      untilDestroyed(this)
    ).subscribe(event => {
      if (!this.elem.nativeElement.contains(event.target)) {
        this.close();
      }
    })
  }

  ngOnDestroy() { }

  close() {
    this.open = false;
  }

}
