import * as Actions from './api-keys.actions';
import { ApiKey } from './api-keys.model';
import {keyBy, omit} from 'lodash';

export interface State {
  apiKeys: { [apiKeyId: number]: ApiKey };
  apiKeySecret: {};
}

const initialState: State = {
  apiKeys: null,
  apiKeySecret: null
};

export function reducer(state: State = initialState, action: Actions.All): State {
  switch (action.type) {
    case Actions.LoadApiKeys.type:
      return { ...state, apiKeys: keyBy(action.apiKeys, 'id') };
    case Actions.AddApiKey.type:
    case Actions.EditApiKey.type:
      return { ...state, apiKeys: { ...state.apiKeys, [action.apiKey.id]: action.apiKey } }
    case Actions.RemoveApiKey.type:
      return { ...state, apiKeys: omit(state.apiKeys, [action.id]) }
    case Actions.SetApiKeySecret.type:
      return { ...state, apiKeySecret: action.apiKeySecret }
    default:
      return state;
  }
}
