import {Action} from "@ngrx/store";
import { Partner } from 'app/models/partners/partner.model';

export class FetchPartners implements Action {
  static readonly type = 'FETCH_PARTNERS';
  readonly type = FetchPartners.type;

  constructor() {}
}

export class LoadPartners implements Action {
  static readonly type = 'LOAD_PARTNERS';
  readonly type = LoadPartners.type;

  constructor(public partners: Partner[]) {}
}

export type All = FetchPartners | LoadPartners;
