<div class="edit-pane">
  <div class="edit-pane-header">
    <div class="edit-pane-header-text">
      <div class="edit-pane-title">
        Manage Insights
      </div>
      <div class="edit-pane-instructions">
        Hide/display your widgets and adjust the order.
      </div>
    </div>
    <div class="edit-pane-header-close" (click)="cancel()">
      <img src="assets/icons/close.svg" />
    </div>
  </div>

  <div class="tab-config" *ngIf="tabs">
    <div class="top-level-tab-config" [dragula]="topLevelTabsBag" [dragulaModel]="tabs">
      <div *ngFor="let topLevelTab of tabs" class="top-level-tab-container">
        <div class="top-level-tab-header">
          <i class="fa fa-bars top-drag-handle"></i>
          <ppc-checkbox [checked]="topLevelTab.visible" (change)="toggleVisibility(topLevelTab)" *ngIf="tabs.length != 1">{{ topLevelTab.name }}</ppc-checkbox>
          <div *ngIf="tabs.length == 1" class="unhideable-tab-name">{{ topLevelTab.name }}</div>
          <span *ngIf="(notPredictions(topLevelTab) && (canCreateCustomTabs$ | async) && canCreateCustomTabForParent(topLevelTab))" (click)="onAddCustomTabClick(topLevelTab)" class="new-custom-tab"><i class="fa fa-plus"></i>New Custom Tab</span>
        </div>
        <div class="top-level-tab-contents" [dragula]="getBagName(topLevelTab)" [dragulaModel]="topLevelTab.children">
          <div *ngFor="let bottomLevelTab of topLevelTab.children" class="bottom-level-tab-container">
            <ppc-custom-tab-config *ngIf="isTabCustom(bottomLevelTab)" [tab]="bottomLevelTab" [parent]="topLevelTab" (toggleVisibility)="toggleVisibility($event)" [cantUnselect]="cantUnselect(topLevelTab.children, bottomLevelTab.visible)">
            </ppc-custom-tab-config>
            <div class="bottom-level-tab" *ngIf="!isTabCustom(bottomLevelTab)">
              <i class="fa fa-bars bottom-drag-handle"></i>
              <i class="fa fa-caret-right"
                [class.rotated]="bottomLevelTab.expanded"
                [class.invisible]="!getWidgets(bottomLevelTab)?.length"
                (click)="bottomLevelTab.expanded = !bottomLevelTab.expanded"></i>
              <ppc-checkbox [checked]="bottomLevelTab.visible"
                            (change)="toggleVisibility(bottomLevelTab)"
                            [disabled]="cantUnselect(topLevelTab.children, bottomLevelTab.visible)">
                {{ bottomLevelTab.name }}
                <span class="description" *ngIf="isTabCustom(bottomLevelTab) && bottomLevelTab.description">{{ bottomLevelTab.description }}</span>
              </ppc-checkbox>
            </div>
            <div  *ngIf="getWidgets(bottomLevelTab)?.length && !bottomLevelTab.custom_id"
                  class="widget-container"
                  [class.hide]="!bottomLevelTab.expanded"
                  [dragula]="getWidgetBagName(topLevelTab, bottomLevelTab)"
                  [dragulaModel]="getWidgets(bottomLevelTab)">
              <div class="widget" *ngFor="let widget of getWidgets(bottomLevelTab)">
                <i class="fa fa-bars widget-drag-handle"></i>
                <ppc-checkbox [checked]="widget.visible"
                              (change)="toggleWidgetVisibility(widget, bottomLevelTab)">
                  {{widget.name}}
                  <span class="description" *ngIf="bottomLevelTab.name == 'Survey' && topLevelTab.name == 'Market Level' && widget.resource_id">
                    {{getMarketLevelSurveyPath(widget, (parentResource$ | async), (subMarkets$ | async), insightsContext)}}
                  </span>
                </ppc-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-config-form-error" *ngIf="isInvalid">
    Changes not saved. Cannot hide both Person Level and Market Level tabs.
  </div>

<app-slide-in-overlay
  [active]="!!(customTabUnderEdit$ | async)"
  [width]="650"
>
  <ppc-custom-tab-form *ngIf="customTabUnderEdit$ | async"></ppc-custom-tab-form>
</app-slide-in-overlay>
