import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {combineLatest, share, filter, map} from 'rxjs/operators';
import {
  AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit,
  ViewChild
} from '@angular/core';
import {PpcTooltipService} from './ppc-tooltip.service';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {Observable, Subject} from "rxjs";
import {clamp} from 'lodash';

@UntilDestroy()
@Component({
  selector: 'ppc-tooltip',
  templateUrl: './ppc-tooltip.component.html',
  styleUrls: ['./ppc-tooltip.component.sass']
})
export class PpcTooltipComponent implements OnInit, AfterViewChecked, OnDestroy {

  @ViewChild("tooltip", { static: true }) tooltip: ElementRef;

  content;
  contentWindow: HTMLElement;
  delayTime: number = 250;
  isOpen: boolean = false;
  openTimer: NodeJS.Timer;
  triangleHeight = 12;
  usesTemplate: boolean;
  tooltipTransform$: Observable<SafeStyle>;
  tooltipSize$ = new Subject<{width: number; height: number}>();
  triangleLeft$: Observable<number>;
  triangleTop$: Observable<number>;
  flipTriangleY$: Observable<boolean>;

  constructor(private tooltipService: PpcTooltipService,
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.contentWindow = document.querySelector("body") as HTMLElement;

    const tooltipEvents$ = this.tooltipService.getTooltipEventListener().pipe(share());
    tooltipEvents$.pipe(untilDestroyed(this)).subscribe(event => {
      this[event.action](event);
    });

    const opens$ = tooltipEvents$
      .pipe(untilDestroyed(this), filter(({ action }) => action === 'open'));

    const tooltipCoords$ = opens$.pipe(
      combineLatest(this.tooltipSize$),
      map(([{ target, offset = {}, alignment = '' }, { width, height }]) => {
        const { left: contentWindowLeft,
          width: contentWindowWidth,
          bottom: contentWindowBottom } = this.contentWindow.getBoundingClientRect();

        const {left: targetLeft,
          right: targetRight,
          top: targetTop,
          bottom: targetBottom,
          width: targetWidth} = target.getBoundingClientRect();
        const shouldOpenUpwards = targetBottom + this.triangleHeight + height > contentWindowBottom;
        let x;
        switch (alignment) {
          case "left":
            x = targetLeft - contentWindowLeft - (width / 2);
            break;
          case "right":
            x = targetRight - contentWindowLeft - (width / 2)
            break;
          default:
            x = targetLeft - contentWindowLeft - (width / 2) + (targetWidth / 2);
            break;
        }

        const y = shouldOpenUpwards ? targetTop - this.triangleHeight - height
          : targetBottom + this.triangleHeight;

        return {
          x: clamp(x + (offset.x || 0), 0, contentWindowWidth - width),
          y: y + (offset.y || 0),
          target,
          width,
          height
        }
      }), );

    this.tooltipTransform$ = tooltipCoords$.pipe(map(({x, y}) =>
      this.sanitizer.bypassSecurityTrustStyle(
        `translate(${x}px, ${y}px)`
      )));

    this.triangleLeft$ = tooltipCoords$.pipe(
      combineLatest(opens$),
      map(([{ target, x }, {offset = {}, alignment = ''}]) => {
        const {left: targetLeft, right: targetRight, width: targetWidth} = target.getBoundingClientRect();
        const {left: contentWindowLeft} = this.contentWindow.getBoundingClientRect();
        switch (alignment) {
          case "right":
            return targetRight - contentWindowLeft - x + (offset.x || 0);
          case "left":
            return targetLeft - contentWindowLeft - x + (offset.x || 0);
          default:
            return targetLeft - contentWindowLeft - x + (offset.x || 0) + targetWidth / 2;
        }
      }));

    this.triangleTop$ = tooltipCoords$.pipe(map(
      ({ target, y, height }) => {
        const { top } = target.getBoundingClientRect();
        return top > y ? height + this.triangleHeight - 1
          : -this.triangleHeight + 1
      }));

    this.flipTriangleY$ = tooltipCoords$.pipe(map(({ target, y }) => target.getBoundingClientRect().top > y))
  }

  ngAfterViewChecked() {
    const {width, height} = this.tooltip.nativeElement.getBoundingClientRect();
    this.tooltipSize$.next({width, height});
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {}

  open(event) {
    this.usesTemplate = event.usesTemplate;
    this.content = event.content;
    this.delayTime = event.delayTime ? event.delayTime : 250;
    if (event.delayOpen) {
      this.openTimer = setTimeout(() => {
        this.isOpen = true;
      }, this.delayTime);
    } else {
      this.isOpen = true;
    }
  }

  close(event) {
    this.content = '';
    this.isOpen = false;
    clearTimeout(this.openTimer);
  }

}
