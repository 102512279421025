<div class="form-container" *ngIf="customTab">
  <h3 class="section-header">{{customTab.id ? "Edit" : "Create"}} Custom Tab</h3>
  <div class="name-container">
    <ppc-input #customTabName
      [(ngModel)]="customTab.name"
      [validators]="nameValidators"
      [forceValidation]="true"
    >
      <label>Name</label>
    </ppc-input>
  </div>

  <div class="name-container">
    <ppc-textarea #customTabDescription
      [(ngModel)]="customTab.description"
      class="full-width"
    >
      <label>Description</label>
    </ppc-textarea>
  </div>

  <div class="button-container">
    <button
      type="submit"
      class="btn"
      (click)="onSaveClick()"
      [disabled]="!isValid"
    >
      Save
    </button>

    <button mat-button (click)="cancelTab()">Cancel</button>

    <ng-container *ngIf="canDelete$ | async">
      <ng-template #dialogTemplate>
        <div class="delete-message">
          <h4>Are you sure you want to delete '{{this.customTab?.name}}' ?</h4>
        </div>
      </ng-template>
      <app-confirmation
        *ngIf="customTab.id"
        [isButton]="true"
        modalTitle="Delete Custom Tab"
        [kontinue]="deleteCustomTab.bind(this)"
        [dialogTemplate]="dialogTemplate"
      ></app-confirmation>
    </ng-container>
  </div>
</div>
