import { capitalize, concat, each, filter as _filter, flatten, forEach, get, keys, map, omitBy, sortBy } from 'lodash';
import * as moment from 'moment';
import { Worksheet, Row } from 'exceljs';

import {
  IndexReportLayoutType,
  IndexReport,
  IndexReportSegment,
  IndexReportResource,
  IndexReportSegmentDetail,
  AUDIENCE_COLUMNS,
  AUDIENCE_COLUMNS_2,
  MERGE_TO_COLUMN_1,
  MERGE_TO_COLUMN_2,
  SIMPLIFIED_NUMBER_FORMAT,
  ADVANCED_NUMBER_FORMAT,
  SIMPLIFIED_COLUMNS_TO_COLOR,
  ADVANCED_COLUMNS_TO_COLOR,
  INDEX_REPORT_COLORS,
  UNKNOWN_RATE_SKEW_DEF,
  DATASET_SKEW_DEF
} from 'app/index-report/index-report.model';
import { prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { SegmentLike } from 'app/models/segment-like.model';
import { fetchRowIdxByLastCellValue, groupTransformWS } from 'app/explore/explore.utils';
import { ResourceDefinedSegments } from 'app/insights/insights.models';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';
import { BLUE, ColorObj, colorText, DEFAULT, GREEN, PURPLE, RED } from 'app/shared/utils/color-util';
import { invalidFileRegex } from 'app/shared/utils/utils';

interface DefinitionParams {
  datasetSkew?: boolean;
  unknownRateSkew?: boolean;
  advanced?: boolean;
  mode?: string;
  resourceType?: string;
}

export class IndexReportExport {
  public vendorNames: VendorDisplayName[];
  public context: { client: string, region: string, brand: string, product: string };
  public allowDatasetSkew: boolean;
  public allowUnknownSetSkew: boolean;

  private _indexReportData: { [identifier: string]: IndexReportSegment };
  private _resource: IndexReportResource;
  private _resources: IndexReportResource[];
  private _resourceType: string;
  private _layoutType: IndexReportLayoutType;
  private data;
  private advanced: boolean;
  private capResourceType: string;

  constructor(data: IndexReport, vendorNames: VendorDisplayName[], context, layoutType: IndexReportLayoutType,
    allow_dataset_skew: boolean, allow_unknown_set_skew: boolean) {
    this._resources = map(omitBy(data, (resource, key) => key === "segments"), "resource");
    this._resource = this._resources[0];
    this._resourceType = this._resource.type.toLowerCase();
    this._layoutType = layoutType;
    // data shown on Index Report worksheet
    this._indexReportData = data.segments;
    this.data = omitBy(data, (resource, key) => key === "segments");
    this.vendorNames = vendorNames;
    this.context = context;
    this.allowDatasetSkew = allow_dataset_skew;
    this.allowUnknownSetSkew = allow_unknown_set_skew;
    this.advanced = layoutType === "advanced";
    this.capResourceType = capitalize(this._resource.type);
  }

  getWorkbookName(ui_name: string) {
    if (!ui_name) {
      const date = moment(new Date()).format('YYYY-MM-DD');
      const time = moment(new Date()).format('HH:mm:ss').replace(/:/g, '-');
      const [client, region] = this._resource.product_slug.split('/').slice(1);
      const type = this._resource.type === 'persona' ? 'explore' : this._resource.type;
      const fileName = this._resources.length > 1 ? `multi${this._resourceType}` : this._resource.name;
      return `${date}_${time}_${client}_${region}_${fileName}_${type}_indexreport_export`;
    } else {
      return ui_name.replace(new RegExp(invalidFileRegex, invalidFileRegex.flags + 'g'), "")
    }
  }

  addReportDetails(ws: Worksheet) {
    const detailsHeader = this.detailsHeader();
    const reportDefinitions = this.reportDefinitions();
    const allRows = [...detailsHeader];
    const resourceStartRowNumbers = [9];
    forEach(this.data, data => {
      const resourceDefinedSegments = this.setResourceDefinedSegments(get(data, "segment_details"));
      const resourceType = get(data, ['resource', 'type']);
      const detailsDetails = this.detailsDetails(get(data, "resource"), resourceType);
      // relies on setResourceDefinedSegments
      const resourceTypeDefinition = this.resourceTypeDefinition(resourceDefinedSegments, resourceType);

      resourceStartRowNumbers.push(
        resourceStartRowNumbers[resourceStartRowNumbers.length - 1] + detailsDetails.length + resourceTypeDefinition.length
      );
      allRows.push(...detailsDetails, ...resourceTypeDefinition);
    });

    allRows.push(...reportDefinitions);
    allRows.push(...this.addSkewDefinitions());
    ws.addRows(allRows);

    // setting number format to be comma separated
    forEach(resourceStartRowNumbers.slice(0, resourceStartRowNumbers.length - 1), row => ws.getRow(row + 2).getCell(2).numFmt = '#,##0');

    // set column widths manually
    const columnWidths = [17, 27, 95, 80]
    each(columnWidths, (columnWidth, index) => {
      const column = ws.getColumn(index + 1);
      column.width = columnWidth;
      column.alignment = { wrapText: false }; // arbitrary segment path widths
      if (index === 3) {
        column.alignment = { wrapText: true }; // formulas
      }
    });

    // EMBOLDEN rows
    ['Segment Path', 'Exclude', 'Formula'].forEach(key => {
      const indices = fetchRowIdxByLastCellValue(allRows, key);
      forEach(indices, index => ws.getRow(index).font = { bold: true });
    });
  }

  addBorder(cell) {
    cell.border = {
      ...cell.border, ...{
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
        top: { style: 'thin' }
      }
    };
  }

  styleHeaderCell(cell) {
    cell.alignment = { horizontal: 'center' };
    this.addBorder(cell);
  }

  colorCell(cell, color) {
    cell.fill = { fgColor: { argb: color }, type: 'pattern', pattern: 'solid' };
  }

  addIndexReport(ws: Worksheet) {
    ws.addRow(['Segment Details']);
    ws.getRow(1).font = { bold: true };
    this.styleHeaderCell(ws.getCell('A1'));
    const startColumn = this._layoutType === "simplified" ? AUDIENCE_COLUMNS : AUDIENCE_COLUMNS_2;
    const mergeToColumn = this._layoutType === "simplified" ? MERGE_TO_COLUMN_1 : MERGE_TO_COLUMN_2;
    const columnColors = INDEX_REPORT_COLORS;
    ws.mergeCells('A1:C1');
    forEach(this._resources, (resource, indx) => {
      const cell = ws.getRow(1).getCell(`${startColumn[indx]}`);
      cell.value = resource.name;
      this.styleHeaderCell(cell);
      this.colorCell(cell, columnColors[indx]);
      ws.mergeCells(`${startColumn[indx]}1:${mergeToColumn[indx]}1`);
    });
    this.addIndexReportHeaders(ws);

    // sort by 'Segment Path' column and 'Segment Name' column
    const indexReportRowData: IndexReportSegment[] = sortBy(this._indexReportData, 'partialSegmentPath', 'name');
    forEach(indexReportRowData, indexReportRow => {
      this.addIndexReportRow(ws, indexReportRow);
    });

    this.colorColumns(ws);
  }

  addIndexReportRow(ws: Worksheet, indexReportRow: IndexReportSegment) {
    const rowLine = [];
    rowLine.push([
      indexReportRow.name,                // A: segment name
      indexReportRow.partialSegmentPath,  // B: segment path (excluding segment name)
      indexReportRow.count,               // C: segment size
    ]);
    keys(this.data).forEach(resourceKey => {
      const entry = indexReportRow[resourceKey];
      if (this._layoutType === "simplified") {
        rowLine.push([
          entry.numerator || 0,          // D: numerator
          entry.nn,                      // E: resource AND segment
          entry.denominator || 0,        // F: denominator
          entry.index_value * 100 || 0,  // G: index value
        ]);
      } else {
        rowLine.push([
          entry.nn,                      // D: resource AND segment
          entry.nd,                      // E: resource AND segment dataset
          entry.numerator || 0,          // F: (COLUMN D / COLUMN E), numerator
          entry.dn,                      // G: resource dataset AND segment
          entry.dd,                      // H: resource dataset AND segment dataset
          entry.denominator || 0,        // I: (COLUMN G / COLUMN H), denominator
          entry.index_value * 100 || 0,  // J: (COLUMN F / COLUMN I) * 100, index value
        ]);
      };
    });
    const row: Row = ws.addRow(flatten(rowLine));

    this.formatRow(row);
  }

  formatRow(row: Row) {
    row.numFmt = '#,##0';

    for (const col of ['A', 'B']) {
      row.getCell(col).numFmt = 'General';
    }

    let numFmts = [];
    if (this._layoutType === "simplified") {
      numFmts = SIMPLIFIED_NUMBER_FORMAT;
    } else {
      numFmts = ADVANCED_NUMBER_FORMAT;
    };

    numFmts.forEach(cell => {
      row.getCell(cell[0]).numFmt = '0%';
      row.getCell(cell[1]).numFmt = '0%';
    });
  }

  colorColumns(ws: Worksheet) {
    ['A', 'B', 'C'].forEach(cell => {
      ws.getCell(`${cell + ws.rowCount}`).border = { ...ws.getCell(`${cell + ws.rowCount}`).border, ...{ bottom: { style: 'thin' } } };
    });

    const startColumn = this._layoutType === "simplified" ? AUDIENCE_COLUMNS : AUDIENCE_COLUMNS_2;
    const mergeToColumn = this._layoutType === "simplified" ? MERGE_TO_COLUMN_1 : MERGE_TO_COLUMN_2;
    let columnsToColor;

    if (this._layoutType === "simplified") {
      columnsToColor = SIMPLIFIED_COLUMNS_TO_COLOR;
    } else {
      columnsToColor = ADVANCED_COLUMNS_TO_COLOR;
    };
    const columnColors = INDEX_REPORT_COLORS;
    this._resources.forEach((resource, indx) => {
      columnsToColor[indx].forEach((col, i) => {
        ws.getColumn(col).eachCell(function (cell, rowNumber) {
          this.colorCell(cell, columnColors[indx]);
          if (i === 0) {
            ws.getCell(`${startColumn[indx] + rowNumber}`).border = { ...ws.getCell(`${startColumn[indx] + rowNumber}`).border,
              ...{ left: { style: 'thin' } } };
          } else if (i === columnsToColor.length - 1) {
            ws.getCell(`${mergeToColumn[indx] + rowNumber}`).border = { ...ws.getCell(`${mergeToColumn[indx] + rowNumber}`).border,
              ...{ right: { style: 'thin' } } };
          }
        }.bind(this));
        ws.getCell(`${col + ws.rowCount}`).border = { ...ws.getCell(`${col + ws.rowCount}`).border,
          ...{ bottom: { style: 'thin' } } };
      });
    });
  }

  private detailsHeader() {
    const { client, region, brand, product } = this.context;

    return [
      ['Date', new Date().toLocaleDateString()],  // MM/DD/YYYY
      ['Client', client],
      ['Region', region],
      ['Brand', brand],
      ['Product', product],
      ['Dataset Skew', this.allowDatasetSkew ? "Yes" : "No"],
      ['Unknown Rate Skew', this.allowUnknownSetSkew ? "Yes" : "No"],
      []
    ];
  }

  private detailsDetails(resource, resourceType) {
    const type = capitalize(resourceType);
    let idxBase = 'Region (Default)';
    const details = [];
    const idxBaseSegment = resource[`index_base_segment`];
    if (idxBaseSegment) {
      const segment = this.buildSegmentLikeObject(idxBaseSegment);
      idxBase = this.getSegmentPath(segment);
    };

    [
      [`${type} Name`, resource.name],
      ['Index Base', idxBase],
      [`${type} Size`, resource.matched_count],
      [],
    ].forEach(r => details.push(r));

    return details;
  }

  private resourceTypeDefinition(resourceDefinedSegments: ResourceDefinedSegments, resourceType) {
    if (resourceType === 'persona') {
      const personaRows = [
        [
          'Persona Definition'
        ],
        ...groupTransformWS(resourceDefinedSegments),
        [],
      ];
      return personaRows;
    } else if (resourceType === 'audience') {
      const audienceRows = [
        [
          'Audience Definition'
        ],
        ...groupTransformWS(resourceDefinedSegments),
        []
      ]
      return audienceRows;
    }
    return [];
  }

  private reportDefinitions() {
    const alphabet = "ABCDEFGHIJ".split("");
    const reportDefinitions = [];
    reportDefinitions.push([
      'Report Definitions'
    ],
    [
      'Column ID',
      'Column Name',
      'Description',
      'Formula'
    ]);
    const definitions = this.getDefinitions(
      {datasetSkew: this.allowDatasetSkew,
        unknownRateSkew: this.allowUnknownSetSkew,
        advanced: this.advanced,
        mode: 'ReportDefinitions'
      });

    forEach(definitions, (definition, i) => definition.unshift(alphabet[i]));

    return reportDefinitions.concat(definitions);
  }

  private addIndexReportHeaders(ws: Worksheet) {
    let combinedHeaders = [];
    const singleHeaders = this.getDefinitions({mode: 'SingleHeaders'});

    this._resources.forEach(resource => {
      const type = capitalize(resource.type);
      const headers = this.getDefinitions(
        {datasetSkew: this.allowDatasetSkew,
          unknownRateSkew: this.allowUnknownSetSkew,
          advanced: this.advanced,
          mode: 'Headers',
          resourceType: type
        });
      combinedHeaders.push(headers)
    });

    combinedHeaders = concat(singleHeaders, flatten(combinedHeaders));
    ws.addRow(combinedHeaders);

    // set column widths
    forEach(ws.columns, (column, index: number) => {
      if (index === 0) {
        column.width = 30;
      } else if (index === 1) {
        column.width = 100;
      } else {
        const headerWidth = combinedHeaders[index].length;
        column.width = headerWidth < 10 ? 10 : headerWidth;
      }
    });
  }

  private setResourceDefinedSegments(segmentDetails) {
    /*
      {
        include: [
          [
            segment_a,
            segment_b
          ],
          [
            ...
          ]
        ],
        exclude: [...]
      }
    */
    if (this._resourceType === 'persona') {
      const includeGroup = this.buildSegmentPathGroups(segmentDetails, 'persona', 'included_segments');
      const excludeGroup = this.buildSegmentPathGroups(segmentDetails, 'persona', 'excluded_segments');
      return { include: includeGroup, exclude: excludeGroup };
    } else {
      // custom audience rule identifier is its own identifier
      const customId = keys(segmentDetails)[0];
      let includeGroup, excludeGroup;

      // if single identifier, i.e. Segment
      if (!get(segmentDetails, [customId, 'include_segments'])) {
        const segment = this.buildSegmentLikeObject(get(segmentDetails, customId));
        includeGroup = [[ this.getSegmentPath(segment) ]];
        excludeGroup = [];
      } else {
        includeGroup = this.buildSegmentPathGroups(segmentDetails, customId, 'include_segments');
        excludeGroup = this.buildSegmentPathGroups(segmentDetails, customId, 'exclude_segments');
      }

      return { include: includeGroup, exclude: excludeGroup };
    }
  }

  private buildSegmentPathGroups(segmentDetails, key, segment_type) {
    // key === object key identifier in `this._segmentDetails`
    // persona: segment_type == included_segments | excluded_segments
    // audience: segment_type === include_segments | exclude_segments
    // currently, persona only supports includes (mocked excludes)
    const segments = get(segmentDetails, [key, segment_type]);
    return map(segments, andSegments => {
      return map(andSegments, orSegment => {
        const segment = this.buildSegmentLikeObject(orSegment);
        return this.getSegmentPath(segment);
      });
    });
  }

  private buildSegmentLikeObject(segmentDetail: IndexReportSegmentDetail) {
    return {
      name: segmentDetail.name,
      identifier: segmentDetail.identifier,
      vendor_name: segmentDetail.vendor_name,
      vendor_type: segmentDetail.vendor_type,
      type: segmentDetail.type,
      path: {
        names: segmentDetail.path,
      }
    } as SegmentLike;
  }

  private getSegmentPath(segment: SegmentLike) {
    if (!segment) { return };
    return prettyPathParts(segment, this.vendorNames).join(' > ');
  }

  private getDefinitions(defParams: DefinitionParams): any[] {

    const singleHeaderArr: Row[] = this.generateSingleHeaders();

    if (defParams.mode === 'SingleHeaders') {
      return singleHeaderArr.map(item => item[0]);
    }

    const headersArr = this.generateHeaders(defParams);

    switch (defParams.mode) {
      case 'Headers': {
        return headersArr.map(item => item[0]);
      }
      case 'ReportDefinitions': {
        return [...singleHeaderArr, ...headersArr];
      }
    }
  }

  private generateSingleHeaders(): any[] {
    return [
      [
        'Attribute Name',
        'Name of the attribute indexed in this row.'
      ],
      [
        'Attribute Path',
        'Path in Discovery for the attribute indexed in this row.'
      ],
      [
        'Attribute Size',
        'Count of people with this attribute.',
        'attribute'
      ]
    ];
  }

  private appendCommonFormulae(defParams: DefinitionParams, conditionalTextArr: Array<ColorObj[]>) {
    if (defParams.advanced) {
      conditionalTextArr[0].push(...[
        {text: `\n(COLUMN F = `, color: DEFAULT},
        {text: `COLUMN D `, color: RED},
        {text: ` / `, color: DEFAULT},
        {text: `COLUMN E`, color: GREEN},
        {text: `)`, color: DEFAULT}]
      );
      conditionalTextArr[1].push(...[
        {text: `\n(COLUMN I = `, color: DEFAULT},
        {text: `COLUMN G `, color: BLUE},
        {text: ` / `, color: DEFAULT},
        {text: `COLUMN H`, color: PURPLE},
        {text: `)`, color: DEFAULT}]
      );
      conditionalTextArr[2].push(...[
        {text: `\n(COLUMN J = ( COLUMN F / COLUMN I ) * 100 )`, color: DEFAULT}]
      );
    }
  }

  private generateHeaders(defParams: DefinitionParams): Row[] {

    const headersArr = [];

    const conditionalTextArr: Array<ColorObj[]> = this.addConditionalElements(defParams);

    this.appendCommonFormulae(defParams, conditionalTextArr);

    const headerResourceType = defParams.resourceType ? defParams.resourceType : this.capResourceType;

    if (!defParams.datasetSkew && !defParams.unknownRateSkew) {
      headersArr.push([
        `${headerResourceType} & THIS Attribute Overlap`,
        colorText([
          {text: `Count of people from the input ${this._resourceType} who possess this attribute. `, color: DEFAULT},
          {text: `Numerator's Numerator in formula`, color: RED}]),
        colorText([{text: `${this._resourceType} AND this attribute`, color: RED}])
      ],
      [
        `${headerResourceType} Attribute Proportion`,
        `Proportion of people from the input ${this._resourceType} with any attribute who possess this attribute. Numerator of formula.`,
        colorText(conditionalTextArr[0])
      ],
      [
        'Base Population Attribute Proportion',
        'Proportion of people from base population who possess this attribute. Denominator in formula.',
        colorText(conditionalTextArr[1])
      ],
      [
        'Index',
        'Final index value for this row.',
        colorText(conditionalTextArr[2])
      ]);

      if (defParams.advanced) {

        headersArr.splice(1, 0, [
          `${headerResourceType} & ANY Attribute Overlap`,
          colorText([
            {text: `Count of people from the input ${this._resourceType} who possess any same-provider sourced attribute. `,
              color: DEFAULT},
            {text: `Numerator's Denominator in formula.`, color: GREEN}]),
          colorText([{text: `${this._resourceType} AND provider_attributes`, color: GREEN}])
        ]);
        headersArr.splice(3, 0, [
          'Base Population & THIS Attribute Overlap',
          colorText([
            {text: `Count of people from base population who possess this attribute. `, color: DEFAULT},
            {text: `Denominator's Numerator in formula.`, color: BLUE}]),
          colorText([{text: `base_population AND this attribute`, color: BLUE}])
        ]);
        headersArr.splice(4, 0, [
          'Base Population & ANY Attribute Overlap',
          colorText([
            {text: `Count of people from base population who possess any same-provider sourced attribute. `, color: DEFAULT},
            {text: `Denominator's Denominator in formula.`, color: PURPLE}]),
          colorText([{text: `base_population AND provider_attributes`, color: PURPLE}])
        ]);
      }
    }

    if (defParams.datasetSkew && !defParams.unknownRateSkew) {
      headersArr.push([
        `${headerResourceType} & THIS Attribute Overlap`,
        colorText([
          {text: `Count of people from the input ${this._resourceType} who possess this attribute. `, color: DEFAULT},
          {text: `Numerator's Numerator in formula.`, color: RED}]),
        colorText([{text: `${this._resourceType} AND attribute`, color: RED}])
      ],
      [
        `${headerResourceType} Attribute Proportion`,
        `Proportion of people from the input ${this._resourceType} with any attribute who possess this attribute. Numerator of formula.`,
        colorText(conditionalTextArr[0])
      ],
      [
        'Base Adjusted Population Attribute Proportion',
        'Proportion of people from skew-adjusted population who possess this attribute. Denominator in formula.',
        colorText(conditionalTextArr[1])
      ],
      [
        'Index',
        'Final index value for this row.',
        colorText(conditionalTextArr[2])
      ]);

      if (defParams.advanced) {

        headersArr.splice(1, 0, [
          `${headerResourceType} & ANY Attribute Overlap`,
          colorText([
            {text: `Count of people from the input ${this._resourceType} who possess any same-provider sourced attribute. `,
              color: DEFAULT},
            {text: `Numerator's Denominator in formula.`, color: GREEN}]),
          colorText([{text: `${this._resourceType} AND provider_attributes`, color: GREEN}])
        ]);
        headersArr.splice(3, 0, [
          'Base Adjusted Population & THIS Attribute Overlap',
          colorText([
            {text: `Count of people from skew-adjusted population who possess this attribute. `, color: DEFAULT},
            {text: `Denominator's Numerator in formula.`, color: BLUE}]),
          colorText([{text: `${this._resourceType}_dataset AND base_population AND this attribute`, color: BLUE}])
        ]);
        headersArr.splice(4, 0, [
          'Base Adjusted Population & ANY Attribute Overlap',
          colorText([
            {text: `Count of people from skew-adjusted population who possess any same-provider sourced attribute. `, color: DEFAULT},
            {text: `Denominator's Denominator in formula.`, color: PURPLE}]),
          colorText([{text: `${this._resourceType}_dataset AND base_population AND provider_attributes`, color: PURPLE}])
        ]);
      }
    }

    if (!defParams.datasetSkew && defParams.unknownRateSkew) {
      headersArr.push([
        `${headerResourceType} & THIS Attribute Overlap`,
        colorText([
          {text: `Count of people from the input ${this._resourceType} who possess this attribute. `, color: DEFAULT},
          {text: `Numerator's Numerator in formula.`, color: RED}]),
        colorText([{text: `${this._resourceType} AND attribute`, color: RED}])
      ],
      [
        `${headerResourceType} Attribute Proportion`,
        `Proportion of people from the input ${this._resourceType} with any attribute who possess this attribute. Numerator of formula.`,
        colorText(conditionalTextArr[0])
      ],
      [
        'Base Population Attribute Proportion',
        'Proportion of people the base population who possess this attribute. Denominator in formula.',
        colorText(conditionalTextArr[1])
      ],
      [
        'Index',
        'Final index value for this row.',
        colorText(conditionalTextArr[2])
      ]);

      if (defParams.advanced) {

        headersArr.splice(1, 0, [
          `${headerResourceType} & ANY Attribute Overlap`,
          colorText([
            {text: `Count of people from the input ${this._resourceType} who possess any same-parent attribute. `, color: DEFAULT},
            {text: `Numerator's Denominator in formula.`, color: GREEN}]),
          colorText([{text: `${this._resourceType} AND [all attributes within attribute parent]`, color: GREEN}])
        ]);
        headersArr.splice(3, 0, [
          'Base Population & THIS Attribute Overlap',
          colorText([
            {text: `Count of people from the base population who possess this attribute. `, color: DEFAULT},
            {text: `Denominator's Numerator in formula.`, color: BLUE}]),
          colorText([{text: `base_population AND attribute`, color: BLUE}])
        ]);
        headersArr.splice(4, 0, [
          'Base Population & ANY Attribute Overlap',
          `Count of people from the base population who possess any same-parent attribute.`,
          colorText([{text: `base_population AND [all attributes within attribute parent]`, color: PURPLE}])
        ]);
      }
    }

    if (defParams.datasetSkew && defParams.unknownRateSkew) {
      headersArr.push([
        `${headerResourceType} & THIS Attribute Overlap`,
        colorText([
          {text: `Count of people from the input ${this._resourceType} who possess this attribute. `, color: DEFAULT},
          {text: `Numerator's Numerator in formula.`, color: RED}]),
        colorText([{text: `${this._resourceType} AND attribute`, color: RED}])
      ],
      [
        `${headerResourceType} Attribute Proportion`,
        `Proportion of people from the input ${this._resourceType} with any attribute who possess this attribute. Numerator of formula.`,
        colorText(conditionalTextArr[0])
      ],
      [
        'Base Adjusted Population Attribute Proportion',
        'Proportion of people from skew-adjusted population who possess this attribute. Denominator in formula.',
        colorText(conditionalTextArr[1])
      ],
      [
        'Index',
        'Final index value for this row.',
        colorText(conditionalTextArr[2])
      ]
      );

      if (defParams.advanced) {

        headersArr.splice(1, 0, [
          `${headerResourceType} & ANY Attribute Overlap`,
          colorText([
            {text: `Count of people from the input ${this._resourceType} who possess any same-parent attribute. `, color: DEFAULT},
            {text: `Numerator's Denominator in formula.`, color: GREEN}]),
          colorText([{text: `${this._resourceType} AND [all attributes within attribute parent]`, color: GREEN}])
        ]);
        headersArr.splice(3, 0, [
          'Base Adjusted Population & THIS Attribute Overlap',
          colorText([
            {text: `Count of people from skew-adjusted population who possess this attribute. `, color: DEFAULT},
            {text: `Denominator's Numerator in formula.`, color: BLUE}]),
          colorText([{text: `${this._resourceType}_dataset AND base_population AND this attribute`, color: BLUE}])
        ]);
        headersArr.splice(4, 0, [
          'Base Adjusted Population & ANY Attribute Overlap',
          colorText([
            {text: `Count of people from skew-adjusted population who possess any same-parent attribute. `,
              color: DEFAULT},
            {text: `Denominator's Denominator in formula.`, color: PURPLE}]),
          colorText([{text: `${this._resourceType}_dataset AND base_population AND [all attributes within attribute parent]`,
            color: PURPLE}])
        ]);
      }
    }
    return headersArr;
  }

  private addSkewDefinitions(): string[][] {
    return [[''], ['Skew Definitions'], UNKNOWN_RATE_SKEW_DEF, DATASET_SKEW_DEF]
  }

  private addConditionalElements(defParams: DefinitionParams): Array<ColorObj[]> {

    const conditionalTextArr: Array<ColorObj[]> = [];

    if (!defParams.datasetSkew && !defParams.unknownRateSkew) {
      conditionalTextArr.push([
        {text: `${this._resourceType} AND this attribute`, color: RED},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType} AND provider_attributes`, color: GREEN}]);
      conditionalTextArr.push([
        {text: `base_population AND this attribute`, color: BLUE},
        {text: ` / `, color: DEFAULT},
        {text: `base_population AND provider_attributes`, color: PURPLE}]);
      conditionalTextArr.push([
        {text: `((`, color: DEFAULT},
        {text: `${this._resourceType} AND this attribute`, color: RED},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType} AND provider_attributes`, color: GREEN},
        {text: `) / (`, color: DEFAULT},
        {text: `base_population AND this attribute`, color: BLUE},
        {text: ` / `, color: DEFAULT},
        {text: `base_population AND provider_attributes`, color: PURPLE},
        {text: `)) X 100 `, color: DEFAULT}]);
    }

    if (defParams.datasetSkew && !defParams.unknownRateSkew) {

      conditionalTextArr.push([
        {text: `${this._resourceType} AND attribute`, color: RED},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType} AND provider_attributes`, color: GREEN}]);
      conditionalTextArr.push([
        {text: `${this._resourceType}_dataset AND base_population AND this attribute`, color: BLUE},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType}_dataset AND base_population AND provider_attributes`, color: PURPLE}]);
      conditionalTextArr.push([
        {text: `((`, color: DEFAULT},
        {text: `${this._resourceType} AND attribute`, color: RED},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType} AND provider_attributes`, color: GREEN},
        {text: `) / (`, color: DEFAULT},
        {text: `${this._resourceType}_dataset AND base_population AND this attribute`, color: BLUE},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType}_dataset AND base_population AND provider_attributes`, color: PURPLE},
        {text: `)) X 100 `, color: DEFAULT}]);
    }

    if (!defParams.datasetSkew && defParams.unknownRateSkew) {
      conditionalTextArr.push([
        {text: `${this._resourceType} AND attribute`, color: RED},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType} AND [all attributes within attribute parent]`, color: GREEN}]);
      conditionalTextArr.push([
        {text: `base_population AND attribute`, color: BLUE},
        {text: ` / `, color: DEFAULT},
        {text: `base_population AND [all attributes within attribute parent]`, color: PURPLE}]);
      conditionalTextArr.push([
        {text: `((`, color: DEFAULT},
        {text: `${this._resourceType} AND attribute`, color: RED},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType} AND [all attributes within attribute parent]`, color: GREEN},
        {text: `) / (`, color: DEFAULT},
        {text: `base_population AND attribute`, color: BLUE},
        {text: ` / `, color: DEFAULT},
        {text: `base_population AND [all attributes within attribute parent]`, color: PURPLE},
        {text: `)) X 100 `, color: DEFAULT}]);
    }

    if (defParams.datasetSkew && defParams.unknownRateSkew) {
      conditionalTextArr.push([
        {text: `${this._resourceType} AND attribute`, color: RED},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType} AND [all attributes within attribute parent]`, color: GREEN}]);
      conditionalTextArr.push([
        {text: `${this._resourceType}_dataset AND base_population AND attribute`, color: BLUE},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType}_dataset AND base_population AND [all attributes within attribute parent]`, color: PURPLE}]);
      conditionalTextArr.push([
        {text: `((`, color: DEFAULT},
        {text: `${this._resourceType} AND attribute`, color: RED},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType} AND [all attributes within attribute parent]`, color: GREEN},
        {text: `) / (`, color: DEFAULT},
        {text: `${this._resourceType}_dataset AND base_population AND attribute `, color: BLUE},
        {text: ` / `, color: DEFAULT},
        {text: `${this._resourceType}_dataset AND base_population AND [all attributes within attribute parent]`, color: PURPLE},
        {text: `)) X 100 `, color: DEFAULT}]);
    }

    return conditionalTextArr;
  }
}
