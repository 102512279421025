<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
</header>

<header>
  <h3 class="object-name">Roles</h3>
</header>

<main>
  <div class="cols">
    <div class="col role-col">
      <mat-radio-group name="componentProperty" [(ngModel)]="selectedRole" labelPosition="start">
        <mat-radio-button *ngFor="let role of roles; trackBy: trackById" [value]="role">
          <span [title]="role.name">{{role.name}}&nbsp;<small>{{role.user_type}}</small></span>
        </mat-radio-button>
      </mat-radio-group>

      <div *ngIf="newRoleFormOpen">
        <input name="newRoleInput" mat-input type="text" placeholder="Api Key Role Name"
               [(ngModel)]="newRoleName">
        <button mat-button (click)="saveNewRole()"
                           [disabled]="!isInputNameValid(newRoleName)">
          Save
        </button>
        <button mat-button (click)="removeNewRole()">
          Delete
        </button>
      </div>

      <button *ngIf="!newRoleFormOpen" mat-button (click)="addRole()">+ Generate Api Key</button>

    </div>
    <div class="col resource-col">
      <ng-template #noSelectedRole><div>Please select a role</div></ng-template>
      <div class="scrollable-table" *ngIf="selectedRole; else noSelectedRole">
        <table mat-table [dataSource]="getDataSource" class="full-width-table" matSort>
          <ng-container matColumnDef="Resource Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Resource Name
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="filter resources / desc">
            </th>
            <td mat-cell *matCellDef="let resource">{{resource.name}}</td>
          </ng-container>
          <ng-container matColumnDef="Create">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Create</th>
            <td mat-cell *matCellDef="let resource">
              <ppc-checkbox [(ngModel)]="resource.permission.create_access"
                                [disabled]="!isMachine(selectedRole)">
              </ppc-checkbox>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="Read">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Read</th>
            <td mat-cell *matCellDef="let resource">
              <ppc-checkbox [(ngModel)]="resource.permission.read_access"
                                [disabled]="!isMachine(selectedRole)">
              </ppc-checkbox>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="Update">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Update</th>
            <td mat-cell *matCellDef="let resource">
              <ppc-checkbox [(ngModel)]="resource.permission.update_access"
                                [disabled]="!isMachine(selectedRole)">
              </ppc-checkbox>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Delete</th>
            <td mat-cell *matCellDef="let resource">
              <ppc-checkbox [(ngModel)]="resource.permission.destroy_access"
                                [disabled]="!isMachine(selectedRole)">
              </ppc-checkbox>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
            <td mat-cell *matCellDef="let resource">{{resource.description}}</td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let resource; columns: displayedColumns;"></tr>
        </table>
      </div>



      <div class="pull-right-wrapper">
        <button mat-button class="save-btn"
                *ngIf="isMachine(selectedRole)"
                (click)="updateRole(selectedRole)"
                [disabled]="!isMachine(selectedRole)">
          Save Changes
        </button>
        <span *ngIf="selectedRole && !isMachine(selectedRole)">
          Only machine roles can be edited within role admin
        </span>
      </div>
    </div>
  </div>

  <app-api-keys-secret
    *ngIf="apiKeySecret$ | async"
    [apiKeySecret]="apiKeySecret$ | async"
  >
  </app-api-keys-secret>


</main>
