import { ViewChildren, EventEmitter, Output, Input, Component, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store'
import { AppState } from 'app/reducers'
import { getSlugs, getChildren, getContext, getContextSlugs, getPath, HierarchyType } from 'app/hierarchy/hierarchy.utils'
import { ContextSlugs, HierarchyEntity } from 'app/hierarchy/hierarchy.interface'
import { combineLatest, BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { find, filter } from 'lodash'
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'ppc-client-context-selector',
  templateUrl: './client-context-selector.component.html',
  styleUrls: ['./client-context-selector.component.sass']
})
export class ClientContextSelectorComponent implements OnChanges {

  @ViewChildren(MatSelect) selectElements;

  @Input() clientContext: ContextSlugs;
  @Input() autoSelectLoneChildren = false;
  @Input() autoOpenNext = false;
  @Input() lockedLevels: HierarchyType[] = []
  @Output() change = new EventEmitter<HierarchyEntity>()

  clientContext$ = new BehaviorSubject<ContextSlugs>({})

  clients$ = this.store.select('hierarchy', 'hierarchy', 'clients').pipe(
    map(clients => filter(clients, {active: true})))

  regions$ = combineLatest(
    this.clients$,
    this.clientContext$
  ).pipe(
    map(([ clients, context ]) => {
      const client = find(clients, {slug: context.clientSlug})
      return client ? client.regions : []
    }))

  brands$ = combineLatest(
    this.regions$,
    this.clientContext$
  ).pipe(
    map(([ regions, context ]) => {
      const region = find(regions, {slug: context.regionSlug})
      return region ? region.brands : []
    }))

  products$ = combineLatest(
    this.brands$,
    this.clientContext$
  ).pipe(
    map(([ brands, context ]) => {
      const brand = find(brands, {slug: context.brandSlug})
      return brand ? brand.products : []
    }))

  constructor(public store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clientContext) {this.clientContext$.next(this.clientContext)}
  }

  setClientContext(entity: HierarchyEntity) {
    if (this.autoSelectLoneChildren) {
      let children = getChildren(entity)
      while (children && children.length === 1) {
        entity = children[0]
        children = getChildren(entity)
      }
    }

    this.clientContext$.next(getContextSlugs(getContext(entity)))
    this.change.emit(entity)

    if (this.autoOpenNext) {
      const nextIndex = getSlugs(getContext(entity)).length
      if (nextIndex < this.selectElements.length) {
        // We use setTimeout here to give the regions$/brands$/products$ arrays time to update
        // before we open the select element
        setTimeout(() => this.selectElements.toArray()[nextIndex].open())
      }
    }
  }

}
