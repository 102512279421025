
import { combineLatest as observableCombineLatest, Subject } from 'rxjs';

import { map, takeUntil } from 'rxjs/operators';

import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { AppState } from "app/reducers";
import { Store } from "@ngrx/store";
import {
  selectNonClientCompetitors,
  Competitor,
  EditableCompetitor
} from '../creative.reducers';
import { CreateCompetitor, DeleteCompetitor, EditCompetitor } from '../creative.actions';
import { PpcInputComponent } from '../../shared/components/ppc-input/ppc-input.component';

@Component({
  selector: 'app-competitor-admin',
  templateUrl: './competitor-admin.component.html',
  styleUrls: ['./competitor-admin.component.sass']
})
export class CompetitorAdminComponent implements OnInit {

  @Input() activeCompetitor: Partial<Competitor>;
  @ViewChild('manageCompetitors') dialog: any;
  @ViewChild('name', { static: true }) newCompetitorForm: PpcInputComponent;

  nonClientCompetitors: EditableCompetitor[];
  ngUnsubscribe: Subject<boolean> = new Subject();
  tempCompetitor: Partial<Competitor>;
  newCompetitor: Partial<Competitor> = {
    name: ''
  };
  nameRequirementsMsg = 'Must be at least 3 characters and have a unique name';

  newCompetitorValidator = {
    isValid: (name) => {
      if (name && !this.newCompetitorForm.focused) { this.newCompetitorForm.touched = true; }
      const valid = name && name.length > 2
        && !this.nonClientCompetitors.find(c => c.name === name);
      return valid;
    },
    errorMessage: this.nameRequirementsMsg
  };

  existingCompetitorValidator = {
    isValid: (name) => {
      const valid = name && name.length > 2
        && this.nonClientCompetitors.filter(c => c.name === name).length < 2;
      return valid;
    },
    errorMessage: this.nameRequirementsMsg
  };

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    observableCombineLatest(
      this.store.select('creative').pipe(map(selectNonClientCompetitors)),
    ).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(([nonClientCompetitors]) => {
        this.nonClientCompetitors = nonClientCompetitors
          .map((competitor) => Object.assign(competitor, { editing: false }));
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  isValidNewCompetitorName(name: string) {
    return name && name.length > 2
           && !this.nonClientCompetitors.find(c => c.name === name);
  }

  saveNewCompetitor() {
    this.store.dispatch(new CreateCompetitor(this.newCompetitor.name));
    this.newCompetitorForm.touched = false;
    this.newCompetitor.name = '';
  }

  toggleCompetitorEditing(competitor, origin = null) {
    if (origin) {
      this.tempCompetitor = Object.assign({}, competitor);
      this.nonClientCompetitors.forEach((c) => { c.editing = false; });
    }
    return competitor.editing = !competitor.editing;
  }

  cancelCompetitorEditing(competitor) {
    if (competitor && this.tempCompetitor) {
      return Object.assign(competitor, this.tempCompetitor);
    }
  }

  editCompetitor(competitor) {
    if (!this.existingCompetitorValidator.isValid(competitor.name)) { return; }
    delete competitor['editing'];
    this.store.dispatch(new EditCompetitor(competitor));
    this.toggleCompetitorEditing(competitor);
  }

  resetName() {
    this.newCompetitorForm.value = '';
    this.newCompetitorForm.touched = false;
  }

  deleteCompetitor(id: string): void {
    this.store.dispatch(new DeleteCompetitor(id));
  }
}
