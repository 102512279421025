import { takeUntil } from 'rxjs/operators';
import {Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers";
import {canAccessFeature} from "app/feature-access/feature-access.reducers";
import {Subject} from "rxjs";

/*
Lets a user select feature access from the store inline in a template. Can be used like so (similar to *ngFor syntax)
<button *checkFeatureAccess="let canAccessX=canAccess; feature: 'x'" [disabled]="!canAccessX">
  This button disabled if can't access feature x
</button
 */
@Directive({
  selector: '[checkFeatureAccess]'
})
export class CheckFeatureAccessDirective implements OnDestroy {

  @Input()
  checkFeatureAccessFeature: string; // has to have this silly name because of the way the *directive="" syntax works

  ngUnsubscribe$ = new Subject();

  constructor(private store: Store<AppState>,
    private templateRef: TemplateRef<{canAccess: boolean}>,
    private viewContainer: ViewContainerRef) {
  }

  ngOnInit() {
    canAccessFeature(this.store, this.checkFeatureAccessFeature).pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        canAccess => this.viewContainer.createEmbeddedView(this.templateRef, {canAccess})
      )
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
