import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import { CurrencyPipe, DecimalPipe, PercentPipe } from "@angular/common";
import { PpcErrorHandler } from "./ppc-error-handler";
import { MatBadgeModule } from "@angular/material/badge";
// theme
import { ThemeModule, lightTheme, darkTheme } from "./theme";
// shared
import { AuthInterceptor } from "app/authorization/auth.interceptor";
import { HierarchyInterceptor } from "app/hierarchy/hierarchy.interceptor";
import { LoggingService } from "./services/logging.service";
import { PpcHttpService } from "./services/ppc_http.service";
import { CSVService } from "./services/csv/csv.service";
import { ProgressBarComponent } from "./shared/components/progress-bar/progress-bar.component";
import { PpcMultilineSnackbarComponent } from "app/shared/components/ppc-multiline-snackbar/ppc-multiline-snackbar.component";
import { UserInfoComponent } from "./shared/user-info/user-info.component";
import { BarColumnComponent } from "./shared/components/bar-column/bar-column.component";
import { DropdownWithButtonsComponent } from "./shared/components/dropdown-with-buttons/dropdown-with-buttons.component";
import { PercentFormatterPipe } from "./shared/formatters/percent-formatter.pipe";
import { AbsValueFormatterPipe } from "./shared/formatters/abs-value-formatter.pipe";
import { PercentInputFormatter } from "./shared/formatters/percent-input-formatter";
import { NumberArrowComponent } from "./shared/components/number-arrow/number-arrow.component";
import { HierarchyService } from "./services/hierarchy.service";
import { ScrollService } from "./services/scroll.service";
import { Daterangepicker } from "ng2-daterangepicker";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
// app
import { AppComponent } from "./app.component";
import { LeftHandNavComponent } from "./left-hand-nav/left-hand-nav.component";
import { BrandSelectorComponent } from "./brand-selector/brand-selector.component";
import { HealthComponent } from "./health/health.component";
// home
import { HomeComponent } from "./home/home.component";
// login
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./guards/auth.guard";
import { AuthenticationService } from "./services/authentication.service";
import { AuthorizationService } from "./services/authorization.service";
// account confirmation from auth service
import { ConfirmAccountComponent } from "./confirm-account/confirm-account.component";
// admin
import { AdminBreadcrumbComponent } from "./admin/shared/admin-breadcrumb/admin-breadcrumb.component";
import { TiledDashboardComponent } from "./admin/tiled-dashboard/tiled-dashboard.component";
import { AdminActionMenuComponent } from "./admin/shared/admin-action-menu/admin-action-menu.component";
import { AdminActionMenuItemComponent } from "./admin/shared/admin-action-menu/admin-action-menu-item/admin-action-menu-item.component";
import { SegmentPermissionsComponent } from "./admin/segment-permissions/segment-permissions.component";
import { SegmentPermissionsV2Component } from "./admin/segment-permissions-v2/segment-permissions-v2.component";
import { ClientMappingsComponent } from './admin/client-mappings/client-mappings.component';
import { ClientMappingsService } from "./admin/client-mappings/client-mappings.service";
import { SegmentPermissionsV2Module } from "./admin/segment-permissions-v2/segment-permissions-v2.module";
import { AudiencesAdminModule } from "./admin/audiences-admin/audiences-admin.module";
import { DestinationsAdminModule } from "./admin/destinations-admin/destinations-admin.module";
import { DestinationManagementModule } from './admin/destination-management/destination-management.module';
import { ActivationManagementModule } from './admin/activation-management/activation-management.module';
import { ActivationManagementComponent } from './admin/activation-management/activation-management.component';
import { ActivationManagementService } from './admin/activation-management/activation-management.service';
// brand admin
import { BrandAdminComponent } from "./brand-admin/brand-admin.component";
import { BrandComponent } from "./brand-admin/brand/brand.component";
import { ProductComponent } from "./brand-admin/brand/product/product.component";
import { BrandService } from "./brand-admin/brand/brand.service";
import { ProductService } from "./brand-admin/brand/product/product.service";
// plans
import { PlansComponent } from "./plans/plans.component";
import { PlanService } from "./plans/plan.service";
import { PlansSummaryComponent } from "./plans-summary/plans-summary.component";
import { PlanSummaryCardComponent } from "./plans-summary/plan-summary-card/plan-summary-card.component";
import { ChannelsSummaryComponent } from "./channels-summary/channels-summary.component";
import { ChannelSummaryCardComponent } from "./channels-summary/channel-summary-card/channel-summary-card.component";
import { ScenarioDialogService } from "./scenario-dialog/scenario-dialog.service";
import { PlansLoadingComponent } from "./plans-loading/plans-loading.component";
import { ChannelFormComponent } from "./plans/channel-form/channel-form.component";
import { ChannelsService } from "./plans/channels.service";
import { ChannelComponent } from "./plans/channel/channel.component";
// outcomes: paths
import { LanguageService } from "./services/language.service";
import { CurrencyService } from "./services/currency.service";
import { CurrencySymbolFormatterPipe } from "./shared/formatters/currency-symbol-formatter.pipe";
import { RevisionInfoComponent } from "./shared/components/revision-info/revision-info.component";
import { DragulaModule } from "ng2-dragula";
// Client and Regions Admin
import { ClientAdminComponent } from "./admin/client/client-admin/client-admin.component";
import { RegionComponent } from "./admin/region/region.component";
import { ClientService } from "./admin/client/client.service";
import { RegionService } from "./admin/region/region.service";
import { CountriesService } from "./admin/countries/countries.service";
import { ClientRowComponent } from "./admin/client/client-row/client-row.component";
import { RequireFullHierarchyGuard } from "./guards/require-full-hierarchy.guard";
// Upload Admin
import { MekkosUploadAdminComponent } from "./admin/upload-admin/mekkos-upload-admin/mekkos-upload-admin.component";
import { SurveysUploadAdminComponent } from "./admin/upload-admin/surveys-upload-admin/surveys-upload-admin.component";
import { DiscussionsUploadAdminComponent } from "./admin/upload-admin/discussions-upload-admin/discussions-upload-admin.component";
import { DiscussionsUploadAdminFormComponent } from "./admin/upload-admin/discussions-upload-admin/discussions-upload-admin-form/discussions-upload-admin-form.component";
import { JourneysUploadAdminComponent } from "./admin/upload-admin/journeys-upload-admin/journeys-upload-admin.component";
import { PersonasUploadAdminComponent } from "./admin/upload-admin/personas-upload-admin/personas-upload-admin.component";
import { UploadAdminService } from "./admin/upload-admin/upload-admin.service";

import { LocalStorageService } from "./services/local-storage.service";
import { PlansAdminComponent } from "./admin/plans-admin/plans-admin.component";
import { CurveParameterTableComponent } from "./admin/plans-admin/curve-parameter-table/curve-parameter-table.component";
import { CurveService } from "./services/curves.service";

import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { MetricFormatterPipe } from "./campaigns/shared/metric-metadata";

import { ImportScenarioPlanComponent } from "./admin/plans-admin/import-scenario-plan/import-scenario-plan.component";
import { PagesComponent } from "./shared/components/pages/pages.component";

import { ScenarioImportService } from "./admin/plans-admin/import-scenario-plan/scenario-import-service";
import { VendorsService } from "./services/vendors/vendors.service";
import { CreativeComponent } from "./creative/creative.component";

import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { metaReducers, reducers, runtimeChecks } from "app/reducers";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { RouteNotFoundComponent } from "./route-not-found/route-not-found.component";
import { UserService } from "./users/user.service";
import { HierarchyPermissionsService } from "./hierarchy-permissions/hierarchy-permissions.service";

import { RequestPasswordResetComponent } from "./login/request-password-reset/request-password-reset.component";

import { PpcComponentsModule } from "app/modules/ppc-components.module";
import { DifferentiatorsChartComponent } from "./creative/differentiators-chart/differentiators-chart.component";
import { CreativeService } from "./services/creative.service";
import { CreativeAttributeListComponent } from "./creative/creative-attribute-list/creative-attribute-list.component";
import { RouterService } from "./router/router.service";
import { PermissionsGuard } from "./guards/permissions.guard";
import { brandAdminRequiredPermissions } from "./admin/shared/admin-permission-service";
import { VendorPermissionTableComponent } from "./admin/segment-permissions/vendor-permission-table/vendor-permission-table.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { AssetSetupComponent } from "./creative/asset-setup/asset-setup.component";
import { CreativeCmsComponent } from "./creative/creative-cms/creative-cms.component";
import { CreativeUploadComponent } from "./creative/creative-upload/creative-upload.component";
import { GenomeAttributeDetailComponent } from "./creative/genome-attribute-detail/genome-attribute-detail.component";
import { CreativeDetailAttributeListComponent } from "./creative/genome-attribute-detail/creative-detail-attribute-list/creative-detail-attribute-list.component";
import { RoleAdminComponent } from "./admin/role-admin/role-admin.component";
import { UserRoleService } from "app/admin/role-admin/service/user-role-service";

import { UserResourceService } from "app/admin/role-admin/service/user-resource.service";
import { UserFormModule } from "./user-settings/user-form/user-form.module";
import { UserDetailComponent } from "./user-settings/user-detail/user-detail.component";
import { CompetitorAdminComponent } from "./creative/competitor-admin/competitor-admin.component";
import { FeatureAccessService } from "app/feature-access/feature-access.service";
import { ClientFeatureAccessComponent } from "app/feature-access/client-feature-access/client-feature-access.component";
import { ClientFeatureAccessGuard } from "app/guards/client-feature-access.guard";
import { ResourceContextGuard } from "app/guards/resource-context.guard";
import { ToolboxComponent } from "./toolbox/toolbox.component";
import { ToolboxService } from "./services/toolbox.service";
import { UnitComponent } from "./toolbox/unit/unit.component";
import { GwiWidgetModule } from "app/insights/insights-components/gwi-widget/gwi-widget.module";

import { GrowV3Component } from "app/insights/grow-v3/grow-v3.component";
import { GrowV3Module } from "app/insights/grow-v3/grow-v3.module";
import { UnitDialogComponent } from "./toolbox/unit-dialog/unit-dialog.component";
import { UnitFormModule } from "./toolbox/unit-form/unit-form.module";
import { MultiUnitDialogComponent } from "./toolbox/multi-unit-dialog/multi-unit-dialog.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ExploreModule } from "app/explore/explore.module";
import { ExploreComponent } from "app/explore/explore.component";
import { ExploreService } from "app/explore/explore.service";
import { OutcomeAudienceModule } from "app/outcome-audience/outcome-audience.module";
import { OutcomeAudienceComponent } from "app/outcome-audience/outcome-audience.component";
import { OutcomeAudienceService } from "app/outcome-audience/outcome-audience.service";
import { MotivationAudienceService } from "app/motivation-audience/motivation-audience.service";
import { ComparisonsModule } from "./comparisons/comparisons.module";
import { ComparisonsComponent } from "./comparisons/comparisons.component";
import { ComparisonsService } from "app/comparisons/comparisons.service";
import { AudienceV2Service } from "app/audiences-v2/audience-v2.service";
import { SegmentV2Service } from "app/segments-v2/segment-v2.service";
import { DataPermissionsService } from "app/services/data_permissions.service";
import { IndexReportService } from "app/index-report/index-report.service";
import { ReportTaskService } from "app/report-tasks/report-tasks.service";
import { DestinationManagementService } from "app/admin/destination-management/destination-management.service";

import { MeasureV3Module } from "app/measure-v3/measure-v3.module";
import { MeasureV3Service } from "app/measure-v3/measure-v3.service";
import { MeasureV3Component } from "app/measure-v3/measure-v3.component";

import { IframePageComponent } from "./toolbox/iframe-page/iframe-page.component";
import { IframePageGuard } from "app/guards/iframe-page.guard";
import { LookalikeV3Service } from "app/lookalikes-v3/lookalike-v3.service";
import { LookalikeV2Service } from "app/lookalikes-v2/lookalike-v2.service";
import { SegmentPickerModule } from "app/segment-picker/segment-picker.module";
import { LegacyLoginFormComponent } from "./login/legacy-login-form/legacy-login-form.component";
import { RequestAccessWizardComponent } from "./login/request-access-wizard/request-access-wizard.component";
import { InsightsTabsAdminComponent } from "app/admin/insights-tabs-admin/insights-tabs-admin.component";
import { ApiKeysComponent } from "./admin/api-keys/api-keys.component";
import { ApiKeysSecretComponent } from "./admin/api-keys/api-keys-secret.component";
import { ApiKeysService } from "./admin/api-keys/api-keys.service";
import { NoticeComponent } from "./notice/notice.component";

import { OverviewV3Component } from "./audiences/overview-v3/overview-v3.component";
import { OverviewV3Module } from "./audiences/overview-v3/overview-v3.module";
import { LaunchComponent } from "./launch/launch.component";
import { NgLetModule } from 'ng-let';
import { UrlService } from "app/services/url.service";
import { MekkoService } from "./mekko/mekko.service";
import { SnapshotsService } from "./snapshots/snapshots.service";
import { DiscussionService } from "app/insights/insights-components/market-level-discussions/discussion.service";
import { HomeModule } from "./home/home.module";
import { HomeContextGuard } from "app/guards/home-context.guard";
import { InsightsModule } from "./insights/insights.module";
import { JourneyComponent } from "./journey/journey.component";
import { JourneyModule } from "./journey/journey.module";
import { JourneyBuilderComponent } from "./journey/journey-builder/journey-builder.component";
import { ActiveClientGuard } from "app/guards/active-client.guard";
import { ScriptService } from "./scripts/script.service";
import { XRequestIDInterceptor } from "./shared/utils/x-request-id-interceptor.interceptor";
import { DataDogService } from "./scripts/datadog.service";
import { AudienceBuilderComponent } from "./audience-builder/audience-builder.component";
import { AudienceBuilderModule } from "./audience-builder/audience-builder.module";
import { OrphanEntitiesAdminComponent } from "./admin/orphan-entities/orphan-entities-admin.component";
import { AudiencesAdminComponent } from "./admin/audiences-admin/audiences-admin.component";
import { AdminCommonComponent } from "./admin/admin-common/admin-common.component";
import { DestinationsAdminComponent } from "./admin/destinations-admin/destinations-admin.component";
import { DestinationManagementComponent } from "./admin/destination-management/destination-management.component";
import { ActivationTileModule } from "./audiences/overview-v3/activation-tile/activation-tile.module";
import { SupportLinksModalComponent } from "./shared/user-info/support-links-modal/support-links-modal.component";
import { NotificationCenterComponent } from "app/notification-center/notification-center.component";
import { AddressableCountMetadataService } from "./models/addressable-count-metadata/addressable-count-metadata.service";
import { ClientLoginComponent } from "./client-login/client-login.component";
import { SegmentsHierarchyService } from "./segments-hierarchy/segments-hierarchy.service";
import { OutcomesV1Service } from "./outcomes-v1/outcomes-v1.service";
import { MotivationsV1Service } from "./models/motivations/motivations-v1.service";
import { ClientAdminService } from "./admin/client/client-admin/client-admin.service";
import { MatTableModule } from '@angular/material/table';
import { StatsAdminComponent } from './admin/stats/stats-admin.component';

import {  registerables , Chart } from 'chart.js';

Chart.register(...registerables);
const appRoutes: Routes = [
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  {
    path: "",
    canActivateChild: [AuthGuard],
    children: [
      { path: "admin-dashboard", component: TiledDashboardComponent },
      { path: "notice", component: NoticeComponent },
      {
        path: "global/admin/tab-admin",
        canActivate: [PermissionsGuard],
        component: InsightsTabsAdminComponent,
        data: {
          requiredPermissions: { tab_admin: "CRUD" },
        },
      },
      {
        path: "global/admin/orphan-entities",
        canActivate: [PermissionsGuard],
        component: OrphanEntitiesAdminComponent,
        data: {
          requiredPermissions: { orphan_entities_report: "CRUD" },
        },
      },
      {
        path: "global/admin/stats",
        canActivate: [PermissionsGuard],
        component: StatsAdminComponent,
        data: {
          requiredPermissions: { statistics: "R" },
        },
      },
      {
        path: "global/admin/clients",
        canActivate: [PermissionsGuard],
        component: ClientAdminComponent,
        data: { requiredPermissions: { clients: "CRU" } },
      },
      {
        path: "global/admin/regions",
        canActivate: [PermissionsGuard],
        component: RegionComponent,
        data: { requiredPermissions: { regions: "CRU" } },
      },
      {
        path: "global/admin/client-mappings",
        canActivate: [PermissionsGuard],
        component: ClientMappingsComponent,
        data: { requiredPermissions: { client_mappings: "CRUD" } },
      },
      {
        path: "global/admin/user-settings",
        canActivate: [PermissionsGuard],
        children: [
          { path: "", component: UserSettingsComponent },
          { path: ":user_id", component: UserDetailComponent },
        ],
        data: { requiredPermissions: { users: "R" } },
      },
      {
        path: "global/admin/segment-permissions",
        canActivate: [PermissionsGuard],
        component: SegmentPermissionsComponent,
        data: { requiredPermissions: { segment_permissions: "CRUD" } },
      },
      {
        path: "global/admin/segment-permissions-v2",
        canActivate: [PermissionsGuard],
        component: SegmentPermissionsV2Component,
        data: { requiredPermissions: { segment_permissions_v2: "U" } },
      },
      {
        path: "global/admin/roles",
        canActivate: [PermissionsGuard],
        component: RoleAdminComponent,
        data: { requiredPermissions: { roles: "R" } },
      },
      {
        path: "global/admin/api-keys",
        canActivate: [PermissionsGuard],
        component: ApiKeysComponent,
        data: {
          requiredPermissions: { api_keys: "CRUD" },
        },
      },
      {
        path: "global/admin/audiences-admin",
        canActivate: [PermissionsGuard],
        component: AudiencesAdminComponent,
        data: {
          requiredPermissions: { audiences_admin: "CRUD" },
        },
      },
      {
        path: "global/admin/admin-common",
        canActivate: [PermissionsGuard],
        component: AdminCommonComponent,
        data: {
          requiredPermissions: { tab_admin: "R" },
        },
      },
      {
        path: "global/admin/destinations-admin",
        canActivate: [PermissionsGuard],
        component: DestinationsAdminComponent,
        data: {
          requiredPermissions: { destinations: "CRU" },
        },
      },
      {
        path: "global/admin/destination-management",
        canActivate: [PermissionsGuard],
        component: DestinationManagementComponent,
        data: {
          requiredPermissions: { activation_destinations: "U" },
        },
      },
      {
        path: "global/admin/activation-management",
        canActivate: [PermissionsGuard],
        component: ActivationManagementComponent,
        data: {
          requiredPermissions: { activation_destinations: "U" },
        },
      },
      {
        path: ":clientSlug/brands/admin",
        canActivate: [ActiveClientGuard, PermissionsGuard],
        component: BrandAdminComponent,
        data: { requiredPermissions: brandAdminRequiredPermissions },
      },
      {
        path: "home",
        canActivate: [HomeContextGuard],
        component: HomeComponent,
      },
      {
        path: ":clientSlug/home",
        canActivate: [ActiveClientGuard, HomeContextGuard],
        component: HomeComponent,
      },
      {
        path: ":clientSlug/:regionSlug/home",
        canActivate: [ActiveClientGuard, HomeContextGuard],
        component: HomeComponent,
      },
      {
        path: ":clientSlug/:regionSlug/:brandSlug/home",
        canActivate: [ActiveClientGuard, HomeContextGuard],
        component: HomeComponent,
      },
      {
        path: ":clientSlug/:regionSlug/brands/admin",
        canActivate: [ActiveClientGuard, PermissionsGuard],
        component: BrandAdminComponent,
        data: { requiredPermissions: brandAdminRequiredPermissions },
      },
      {
        path: ":clientSlug/:regionSlug/upload/mekkos_upload/admin",
        canActivate: [PermissionsGuard],
        component: MekkosUploadAdminComponent,
        data: {
          requiredPermissions: { upload: "CRUD", upload_mekkos: "CRUD" },
        },
      },
      {
        path: ":clientSlug/:regionSlug/upload/surveys_upload/admin",
        canActivate: [PermissionsGuard],
        component: SurveysUploadAdminComponent,
        data: {
          requiredPermissions: { upload: "CRUD", upload_surveys: "CRUD" },
        },
      },
      {
        path: ":clientSlug/:regionSlug/upload/discussions_upload/admin",
        canActivate: [PermissionsGuard],
        component: DiscussionsUploadAdminComponent,
        data: {
          requiredPermissions: { upload: "CRUD", upload_discussions: "CRUD" },
        },
      },
      {
        path: ":clientSlug/:regionSlug/upload/journeys_upload/admin",
        canActivate: [PermissionsGuard],
        component: JourneysUploadAdminComponent,
        data: {
          requiredPermissions: { upload: "CRUD", upload_journeys: "CRUD" },
        },
      },
      {
        path: ":clientSlug/:regionSlug/upload/personas_upload/admin",
        canActivate: [PermissionsGuard],
        component: PersonasUploadAdminComponent,
        data: {
          requiredPermissions: { upload: "CRUD", upload_personas: "CRUD" },
        },
      },
      {
        path: ":clientSlug/:regionSlug/toolbox-app/:unitName",
        component: IframePageComponent,
        canActivate: [ActiveClientGuard, IframePageGuard],
      },
      {
        path: ":clientSlug/:regionSlug/:brandSlug/:productSlug",
        canActivateChild: [ActiveClientGuard, RequireFullHierarchyGuard],
        children: [
          { path: "home", component: HomeComponent },
          { path: "audiences/overviewTemp", component: OverviewV3Component },
          {
            path: "audiences/overview",
            canActivateChild: [ClientFeatureAccessGuard],
            data: { requiredClientFeatureAccess: "overview" },
            children: [
              { path: "", component: OverviewV3Component },
              { path: ":audience_id", component: OverviewV3Component },
            ],
          },
          {
            path: "audiences/builder",
            canActivateChild: [ClientFeatureAccessGuard],
            data: { requiredClientFeatureAccess: "overview" },
            children: [
              {
                path: "",
                component: AudienceBuilderComponent,
                canActivate: [PermissionsGuard],
                data: { requiredPermissions: { audiences: "C" } },
              },
              {
                path: ":id",
                component: AudienceBuilderComponent,
                canActivate: [PermissionsGuard],
                data: { requiredPermissions: { audiences: "U" } },
              },
            ],
          },
          {
            path: "insights/explore/builder",
            canActivateChild: [ClientFeatureAccessGuard],
            data: { requiredClientFeatureAccess: "explore" },
            children: [
              {
                path: "",
                component: AudienceBuilderComponent,
                canActivate: [PermissionsGuard],
                data: { requiredPermissions: { explore: "C" } },
              },
              {
                path: ":personaId",
                component: AudienceBuilderComponent,
                canActivate: [PermissionsGuard],
                data: { requiredPermissions: { explore: "U" } },
              },
            ],
          },
          {
            path: "insights/motivation-audience/builder",
            canActivateChild: [ClientFeatureAccessGuard],
            data: { requiredClientFeatureAccess: "explore" },
            children: [
              {
                path: "",
                component: AudienceBuilderComponent,
                canActivate: [PermissionsGuard],
                data: {
                  requiredPermissions: { request_motivation_audiences: "C" },
                },
              },
              {
                path: ":personaId",
                component: AudienceBuilderComponent,
                canActivate: [PermissionsGuard],
                data: {
                  requiredPermissions: { request_motivation_audiences: "C" },
                },
              },
            ],
          },
          {
            path: "insights/overlap",
            canActivateChild: [ClientFeatureAccessGuard],
            data: {
              requiredClientFeatureAccess: "overlap",
              requiredPermissions: { comparisons: "R" },
            },
            children: [
              { path: ":comparisonId", component: ComparisonsComponent },
              { path: "", component: ComparisonsComponent },
            ],
          },
          {
            path: "insights/explore",
            canActivateChild: [ClientFeatureAccessGuard],
            canActivate: [PermissionsGuard],
            data: {
              requiredPermissions: { explore: "R" },
              requiredClientFeatureAccess: "explore",
            },
            children: [
              { path: ":personaId", component: ExploreComponent },
              { path: "", component: ExploreComponent },
            ],
          },
          {
            path: "insights/explore-outcome-audiences",
            canActivateChild: [ClientFeatureAccessGuard],
            canActivate: [PermissionsGuard],
            data: {
              requiredPermissions: { outcome_audiences: "R" },
              requiredClientFeatureAccess: "explore-outcome-audiences",
            },
            children: [
              {
                path: ":outcomeAudienceId",
                component: OutcomeAudienceComponent,
              },
              { path: "", component: OutcomeAudienceComponent },
            ],
          },
          {
            path: "insights/grow",
            canActivateChild: [ClientFeatureAccessGuard],
            canActivate: [PermissionsGuard],
            data: {
              requiredPermissions: { mekkos: "R" },
              requiredClientFeatureAccess: "grow",
            },
            children: [
              { path: ":mekkoId", component: GrowV3Component },
              { path: "", component: GrowV3Component },
            ],
          },
          {
            path: "insights/journey-builder",
            canActivateChild: [ClientFeatureAccessGuard],
            canActivate: [PermissionsGuard],
            data: {
              requiredClientFeatureAccess: "journey",
              requiredPermissions: { journeys: "CRU" },
            },
            children: [
              { path: ":journeyId", component: JourneyBuilderComponent },
              { path: "", component: JourneyBuilderComponent },
            ],
          },
          {
            path: "insights/journey",
            canActivateChild: [ClientFeatureAccessGuard],
            data: {
              requiredClientFeatureAccess: "journey",
              requiredPermissions: { journeys: "R" },
            },
            children: [
              { path: ":journeyId", component: JourneyComponent },
              { path: "", component: JourneyComponent },
            ],
          },
          {
            path: "outcomes/measure",
            canActivateChild: [ClientFeatureAccessGuard],
            data: { requiredClientFeatureAccess: "measure" },
            children: [
              { path: ":mekkoId", component: MeasureV3Component },
              { path: "", component: MeasureV3Component },
            ],
          },
          {
            path: "plans/admin",
            component: PlansAdminComponent,
            canActivate: [ClientFeatureAccessGuard, PermissionsGuard],
            data: {
              requiredClientFeatureAccess: "scenarios",
              requiredPermissions: { curves: "CRU" },
            },
          },
          {
            path: "plans/scenarios",
            canActivateChild: [ResourceContextGuard, ClientFeatureAccessGuard],
            data: { requiredClientFeatureAccess: "scenarios" },
            children: [
              { path: ":id", component: PlansComponent },
              { path: "", component: PlansSummaryComponent },
            ],
          },
          {
            path: "plans/channels",
            canActivateChild: [ResourceContextGuard, ClientFeatureAccessGuard],
            data: { requiredClientFeatureAccess: "channels" },
            children: [
              { path: "", component: ChannelsSummaryComponent },
              { path: ":id", component: ChannelComponent },
            ],
          },

          {
            path: "creative/assets",
            component: AssetSetupComponent,
            canActivate: [PermissionsGuard, ClientFeatureAccessGuard],
            data: {
              requiredPermissions: { creatives: "CR", competitors: "CR" },
              requiredClientFeatureAccess: "assets",
            },
          },
          {
            path: "creative/competitors",
            component: CreativeComponent,
            canActivate: [PermissionsGuard, ClientFeatureAccessGuard],
            data: {
              requiredPermissions: {
                creatives: "R",
                competitors: "R",
                creative_attribute_scores: "R",
              },
              requiredClientFeatureAccess: "competitors",
            },
          },
          {
            path: "creative/admin",
            component: CreativeCmsComponent,
            canActivate: [PermissionsGuard],
            data: {
              requiredPermissions: {
                creatives: "R",
                creative_attribute_scores: "CRUD",
              },
            },
          },
          {
            path: "toolbox",
            canActivate: [PermissionsGuard, ClientFeatureAccessGuard],
            data: {
              requiredPermissions: { toolbox_client_configuration: "R" },
              requiredClientFeatureAccess: "applications",
            },
            children: [
              { path: "", component: ToolboxComponent },
              {
                path: ":type",
                children: [
                  { path: "", component: ToolboxComponent },
                  {
                    path: ":conf",
                    component: ToolboxComponent,
                    canActivate: [PermissionsGuard],
                    data: {
                      requiredPermissions: {
                        toolbox_core_configuration: "CRUD",
                        toolbox_client_configuration: "CRUD",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "toolbox-app/:unitName",
            component: IframePageComponent,
            canActivate: [IframePageGuard],
          },
        ],
      },
      { path: "", redirectTo: "/home", pathMatch: "full" },
    ],
  },
  { path: "login", component: LoginComponent },
  { path: "health", component: HealthComponent },
  { path: "charlesjesse", component: ClientLoginComponent },
  { path: "request-password-reset", component: RequestPasswordResetComponent },
  { path: "confirm/:confirmationToken", component: ConfirmAccountComponent },
  {
    path: "reset-password/:resetPasswordToken",
    component: ResetPasswordComponent,
  },
  { path: "**", component: RouteNotFoundComponent },
];

@NgModule({
  declarations: [
    AdminBreadcrumbComponent,
    AdminCommonComponent,
    AppComponent,
    BarColumnComponent,
    BrandAdminComponent,
    BrandComponent,
    BrandSelectorComponent,
    ChannelComponent,
    ChannelFormComponent,
    ChannelSummaryCardComponent,
    ChannelsSummaryComponent,
    DropdownWithButtonsComponent,
    HealthComponent,
    LeftHandNavComponent,
    LoginComponent,
    ClientLoginComponent,
    ConfirmAccountComponent,
    MetricFormatterPipe,
    PercentFormatterPipe,
    PercentInputFormatter,
    PlansAdminComponent,
    PlanSummaryCardComponent,
    PlansComponent,
    PlansLoadingComponent,
    PlansSummaryComponent,
    ProductComponent,
    ProgressBarComponent,
    UserInfoComponent,
    ChannelComponent,
    DropdownWithButtonsComponent,
    PercentFormatterPipe,
    CurrencySymbolFormatterPipe,
    RevisionInfoComponent,
    ClientAdminComponent,
    RegionComponent,
    ClientRowComponent,
    AbsValueFormatterPipe,
    CurveParameterTableComponent,
    TiledDashboardComponent,
    AdminActionMenuComponent,
    AdminActionMenuItemComponent,
    UserSettingsComponent,
    PagesComponent,
    SegmentPermissionsComponent,
    SegmentPermissionsV2Component,
    ImportScenarioPlanComponent,
    RouteNotFoundComponent,
    CreativeComponent,
    DifferentiatorsChartComponent,
    CreativeAttributeListComponent,
    UserDetailComponent,
    VendorPermissionTableComponent,
    RequestPasswordResetComponent,
    ResetPasswordComponent,
    GenomeAttributeDetailComponent,
    CreativeDetailAttributeListComponent,
    AssetSetupComponent,
    CreativeCmsComponent,
    CreativeUploadComponent,
    RoleAdminComponent,
    CompetitorAdminComponent,
    ClientFeatureAccessComponent,
    NumberArrowComponent,
    ToolboxComponent,
    UnitComponent,
    UnitDialogComponent,
    MultiUnitDialogComponent,
    PrivacyPolicyComponent,
    IframePageComponent,
    PpcMultilineSnackbarComponent,
    LegacyLoginFormComponent,
    RequestAccessWizardComponent,
    InsightsTabsAdminComponent,
    OrphanEntitiesAdminComponent,
    StatsAdminComponent,
    LaunchComponent,
    ApiKeysComponent,
    ApiKeysSecretComponent,
    NoticeComponent,
    MekkosUploadAdminComponent,
    SurveysUploadAdminComponent,
    DiscussionsUploadAdminComponent,
    DiscussionsUploadAdminFormComponent,
    JourneysUploadAdminComponent,
    PersonasUploadAdminComponent,
    SupportLinksModalComponent,
    NotificationCenterComponent,
    ClientMappingsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    Daterangepicker,
    RouterModule.forRoot(appRoutes),
    DragulaModule,
    ScrollToModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 50 }), // Max age is the total number of past states the extension will store
    EffectsModule.forRoot([
      PlanService,
      HierarchyService,
      CurrencyService,
      AuthenticationService,
      AuthorizationService,
      CurveService,
      LoggingService,
      FeatureAccessService,
      UserService,
      HierarchyPermissionsService,
      VendorsService,
      RegionService,
      RouterService,
      CreativeService,
      UserRoleService,
      UserResourceService,
      ClientService,
      ToolboxService,
      ComparisonsService,
      MeasureV3Service,
      SegmentV2Service,
      ComparisonsService,
      ExploreService,
      OutcomeAudienceService,
      MotivationAudienceService,
      AudienceV2Service,
      LookalikeV3Service,
      LookalikeV2Service,
      ApiKeysService,
      DataPermissionsService,
      MekkoService,
      SnapshotsService,
      DiscussionService,
      IndexReportService,
      AddressableCountMetadataService,
      SegmentsHierarchyService,
      ReportTaskService,
      OutcomesV1Service,
      MotivationsV1Service,
      ClientAdminService,
      DestinationManagementService,
      ActivationManagementService,
    ]),
    StoreRouterConnectingModule.forRoot(),
    UserFormModule,
    UnitFormModule,
    PpcComponentsModule,
    GwiWidgetModule,
    GrowV3Module,
    ExploreModule,
    OutcomeAudienceModule,
    ComparisonsModule,
    MeasureV3Module,
    SegmentPickerModule,
    OverviewV3Module,
    ThemeModule.forRoot({
      themes: [lightTheme, darkTheme],
      active: "dark",
    }),
    NgLetModule,
    HomeModule,
    InsightsModule,
    JourneyModule,
    AudienceBuilderModule,
    AudiencesAdminModule,
    DestinationsAdminModule,
    ActivationTileModule,
    SegmentPermissionsV2Module,
    MatBadgeModule,
    MatTableModule,
    DestinationManagementModule,
    ActivationManagementModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HierarchyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XRequestIDInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: PpcErrorHandler },
    PpcHttpService,
    AuthGuard,
    AuthenticationService,
    AuthorizationService,
    LoggingService,
    BrandService,
    ChannelsService,
    CSVService,
    CurrencyPipe,
    DecimalPipe,
    PercentPipe,
    HierarchyService,
    LanguageService,
    CurrencyService,
    PercentFormatterPipe,
    MetricFormatterPipe,
    CurrencySymbolFormatterPipe,
    PlanService,
    ProductService,
    ScenarioDialogService,
    ScrollService,
    ClientService,
    RegionService,
    RequireFullHierarchyGuard,
    LocalStorageService,
    CurveService,
    ScenarioImportService,
    CountriesService,
    UserService,
    HierarchyPermissionsService,
    VendorsService,
    RouterService,
    PermissionsGuard,
    FeatureAccessService,
    ClientFeatureAccessGuard,
    ResourceContextGuard,
    UploadAdminService,
    AudienceV2Service,
    LookalikeV3Service,
    LookalikeV2Service,
    DataPermissionsService,
    UrlService,
    IframePageGuard,
    HomeContextGuard,
    ActiveClientGuard,
    ScriptService,
    DataDogService,
    OutcomesV1Service,
    MotivationsV1Service,
    ClientMappingsService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
