import {map, flatMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from "@ngrx/effects";
import {
  FetchHierarchyPermissions, LoadHierarchyPermissions,
  SaveHierarchyPermissions
} from "./hierarchy-permissions.actions";
import { FetchHierarchy } from "app/hierarchy/hierarchy.actions";
import { FetchClientFeatureAccess } from "app/feature-access/feature-access.actions";
import {fetchResource} from "../shared/utils/fetch-state";
import { PpcHttpService } from "../services/ppc_http.service";
import {Observable} from "rxjs";
import {HierarchyPermissions, PermissionSlugs} from "./hierarchy-permissions.interface";
import { permissionsUrl } from '../shared/constants/clients.urls';

@Injectable()
export class HierarchyPermissionsService {

  @Effect()
  fetchHierarchyPermissions$ = this.actions$.pipe(
    ofType(FetchHierarchyPermissions.type),
    (fetchResource(
      action => this.getUserHierarchyPermissionsSlugs(action.userId).pipe(
        map(permissions => new LoadHierarchyPermissions(action.userId, permissions)))
    )));

  @Effect()
  saveHierarchyPermissions$ = this.actions$.pipe(
    ofType(SaveHierarchyPermissions.type),
    fetchResource(
      action => this.saveUserHierarchyPermissions(action.userId, action.slugs).pipe(
        flatMap(permissions => [new LoadHierarchyPermissions(action.userId, permissions),
          new FetchHierarchy(),
          new FetchClientFeatureAccess()]))
    ));

  constructor(private http: PpcHttpService, private actions$: Actions) { }

  permissionURL(userId: string): string {
    return `${permissionsUrl()}/${encodeURIComponent(userId)}`
  }

  getUserHierarchyPermissions(userId: string | number): Observable<HierarchyPermissions> {
    return this.http.get(this.permissionURL(userId.toString()))
  }

  getUserHierarchyPermissionsSlugs(userId: string | number): Observable<HierarchyPermissions> {
    return this.http.get(this.permissionURL(userId.toString()) + `/slugs`)
  }

  saveUserHierarchyPermissions(userId: string | number,
    slugs: string[]): Observable<HierarchyPermissions> {
    return this.http.put(this.permissionURL(userId.toString()), {user_id: userId, slugs})
  }

}
