<div class="person-level-compare-widget-header">
  <div class="widget-header-name"
       *ppcCheckOverflow="let hasOverflow=hasXOverflow"
       [ppcTooltip]="demographic.name"
       [tooltipDisabled]="!hasOverflow">{{demographic.name}}</div>
  <div class="widget-header-options" [matMenuTriggerFor]="widgetOptions" matRipple [matRippleCentered]="true" *ngIf="canEditWidget$ | async">
    <i class="fa fa-ellipsis-v"></i>
  </div>
  <mat-menu #widgetOptions="matMenu">
    <div mat-menu-item (click)="editDemographic()" *ngIf="canEditWidget$ | async">
      Edit
      <img class="warning-icon" [class.hide]="!demographicHasZeroCount" src="assets/icons/warning-orange.svg" />
    </div>
  </mat-menu>
</div>
<div class="person-level-compare-widget-buckets">
    <div *ngFor="let bucket of demographic.buckets" class="person-level-compare-widget-bucket" (click)="toggleFilter(bucket)" [class.selected]="isFiltered(bucket)">
      <div class="bucket-label"
           *ppcCheckOverflow="let hasOverflow=hasXOverflow"
           [ppcTooltip]="bucket.name"
           [tooltipDisabled]="!hasOverflow">
        <ppc-zero-count-warning-icon
          *ngIf="bucketHasZeroCount(bucket)"
          ppcTooltip="Click to learn why this attribute has a zero count.">
        </ppc-zero-count-warning-icon>
        {{ bucket.name }}
      </div>
      <ng-container *ngIf="!(isIndexMode$ | async)">
        <div *ngFor="let compareTarget of compareTargets; let idx=index" class="compare-segment">
          <ppc-progress-bar
            [percentage]="compareService.getPercentage(compareTarget.identifier, bucket.short_id)"
            [colorOverride]="compareTarget.legendColor"
            round="1.0-0"
            [showCounts]="isIdCount"
            [count]="isIdCount ? compareService.getCount(compareTarget.identifier, bucket.short_id) : null"
            [countLabel]="isIdCount ? getCountLabel(compareTarget.identifier, bucket.short_id) : null"
            >
          </ppc-progress-bar>
        </div>
      </ng-container>
      <ng-container *ngIf="isIndexMode$ | async">
        <div *ngFor="let compareTarget of compareTargets; let idx=index" class="compare-segment">
          <ng-container *ngIf="compareService.hasIndex(compareTarget.identifier, bucket.short_id)">
            <div class="color-bubble" [style.background-color]="compareTarget.legendColor"></div>
            <ppc-index-bar [index]="compareService.getIndex(compareTarget.identifier, bucket.short_id)"></ppc-index-bar>
          </ng-container>
        </div>
      </ng-container>

    </div>
</div>
