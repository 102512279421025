import { PveConsumerType, PveConsumerTypeRequestParam, PveGoal, PveGoalRequestParam } from "./outcome-audience.reducer";

export function consumerTypeRequestMap(type: PveConsumerType): PveConsumerTypeRequestParam {
  const requestMap = {
    'Anyone': 'anyone',
    'Loyal Buyers': 'loyal_buyers',
    'Lapsed Buyers': 'lapsed_buyers',
    'New to file': 'new_file'
  };
  return requestMap[type] as PveConsumerTypeRequestParam;
}

export function goalRequestMap(goal: PveGoal): PveGoalRequestParam {
  const requestMap = {
    'Increase Buyers': 'binary',
    'Increase Conversions': 'number',
    'Increase Revenue': 'value'
  };
  return requestMap[goal] as PveGoalRequestParam;
}
