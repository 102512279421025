import {Component, OnDestroy, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import {ActivatedRoute, Params} from "@angular/router";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { insightEntitiesUrl } from 'app/shared/constants/insights.urls';

@UntilDestroy()
@Component({
  selector: 'ppc-grow-tabs-admin',
  templateUrl: './orphan-entities-admin.component.html',
  styleUrls: ['./orphan-entities-admin.component.sass']
})
export class OrphanEntitiesAdminComponent implements OnInit, OnDestroy {
  params: Params;
  listOfEntities: {};
  tableSorts = [{prop: 'id', dir: 'desc'}];

  messageTable = {
    emptyMessage: '',
    totalMessage: 'row(s)'
  }

  constructor(private http: HttpClient, private route: ActivatedRoute, ) { }

  ngOnInit() {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => this.params = params);

    this.http.get(insightEntitiesUrl() + (this.params['all'] ? "?all=" + this.params['all'] : ""))
      .subscribe((entities: []) => this.listOfEntities = entities['data'],
        error => console.log('insight_entities error', error) );
  }

  dateComparator(date1: string, date2: string) {
    const date1Moment = moment(date1).startOf("day").valueOf();
    const date2Moment = moment(date2).startOf("day").valueOf();
    return date1Moment - date2Moment;
  }

  textComparator(str1: string, str2: string) {
    return str1.localeCompare(str2)
  }

  tableSortChanged(event) {
    this.tableSorts = event.sorts;
  }

  ngOnDestroy() {}
}
