import {
  ContextSlugs, Hierarchy, HierarchyBrand, HierarchyClient, HierarchyContext, HierarchyEntity, HierarchyProduct,
  HierarchyRegion
} from "app/hierarchy/hierarchy.interface";
import {mapTree, leaves, ancestors} from "app/shared/utils/utils";
import {
  zip,
  filter,
  map,
  takeWhile,
  maxBy,
  chain,
  get,
  find,
  last,
  has,
  uniqBy,
  uniq,
  flatMap,
  forEach,
  head
} from 'lodash';
import {Region} from "app/admin/region/region.model";
import {Product} from "app/brand-admin/brand/product/product.model";

export function emptyHierarchy(): Hierarchy {
  return {
    clients: []
  };
}

export function emptyHierarchyClient(): HierarchyClient {
  return {
    id: '',
    name: '',
    slug: '',
    slug_full: '',
    conversant_company_id: null,
    regions: []
  };
}

export function emptyHierarchyRegion(): HierarchyRegion {
  return {
    id: '',
    name: '',
    slug: '',
    brands: [],
    currency: 'gbp',
    feature_exclusions: []
  };
}

export function emptyHierarchyBrand(): HierarchyBrand {
  return {
    id: '',
    name: '',
    slug: '',
    slug_full: '',
    category: '',
    conversant_company_id: null,
    client_id: '',
    region_id: '',
    products: []
  };
}

export function emptyHierarchyProduct(): HierarchyProduct {
  return {
    id: '',
    name: '',
    slug: '',
    slug_full: '',
    conversant_company_id: null,
    brand_id: '',
    region_ids: []
  };
}

export function emptyHierarchyContext(): HierarchyContext {
  return {
    client: emptyHierarchyClient(),
    region: emptyHierarchyRegion(),
    brand: emptyHierarchyBrand(),
    product: emptyHierarchyProduct()
  };
}

export type HierarchyType = 'client' | 'region' | 'brand' | 'product';
const HIERARCHY_TYPES: HierarchyType[]  = ['client', 'region', 'brand', 'product'];
const HIERARCHY_LEVELS = HIERARCHY_TYPES.map(type => type + 's');
const HIERARCHY_SLUGS  = HIERARCHY_TYPES.map(type => type + 'Slug');

export function isFullContext({client, region, brand, product}: HierarchyContext): boolean {
  return !!(client && region && brand && product)
}

export function isFullContextSlugs(context: ContextSlugs): boolean {
  return context && HIERARCHY_SLUGS.every(slug => !!context[slug] && slug !== '___')
}

export function getDefaultHierarchyContext(hierarchy: Hierarchy): HierarchyContext {
  const result = [];
  let context = {} as HierarchyContext;

  hierarchy.clients.find(client => {
    context = {client} as HierarchyContext
    if (client.regions.length === 0) {result.push(context); }
    return client.active && !!client.regions.find(region => {
      context = {client, region } as HierarchyContext
      if (region.brands.length === 0) {result.push(context); }
      return !!region.brands.find(brand => {
        context = { client, region, brand } as HierarchyContext
        if (brand.products.length === 0) {result.push(context); }
        return !!brand.products.find(product => {
          context = {client, region, brand, product} as HierarchyContext
          result.push(context);
          return !!product
        })
      })
    })
  })
  // sort based on quantity of keys for each context
  return maxBy(result, context => Object.keys(context).length) || {}
}

// This function attempts to construct a valid path for each level of the hierarchy
// For each level it will pick the first context that has that level, from left to right
export function getNavPaths(contexts: HierarchyContext[]): {[key in HierarchyType]: string} {
  const getPath = type => contexts.map(context => get(context, [type, 'fullSlug'])).find(Boolean)
  return chain(HIERARCHY_TYPES).map(type => [type, getPath(type)])
    .fromPairs()
    .value() as {[key in HierarchyType]: string}
}

// This takes an incomplete context and returns a context starts with the same nodes and goes as
// deep as possible
export function extendContext(context: HierarchyContext): HierarchyContext {
  const mostSpecific = last(getEntities(context))
  if (getChildren(mostSpecific).length === 0) {return context}
  return getContext(findDeepestLeaf(mostSpecific))
}

function findDeepestLeaf(node): HierarchyEntity {
  return maxBy(leaves(node, getChildren), depth)
}

function pathForSlugs(slugs: string[]): string {
  return takeWhile(slugs, Boolean).map(x => '/' + x).join('')
}

export function slugsForPath(path: string): string[] {
  return chain(path).split('/').drop(1).takeWhile(Boolean).value()
}

function entitiesForContext(context: HierarchyContext): HierarchyEntity[] {
  return takeWhile(HIERARCHY_TYPES.map(type => context[type]), Boolean) as HierarchyEntity[]
}

function contextForEntities(entities: HierarchyEntity[]): HierarchyContext {
  // The any here is to force TS to allow us to cast Dictionary<HierarchyEntity> to HierarchyContext
  return chain(HIERARCHY_TYPES).zip(entities)
    .takeWhile(([type, ent]) => !!type && !!ent)
    .fromPairs()
    .value() as any
}

function slugsForContextSlugs(contextSlugs: ContextSlugs): string[] {
  return takeWhile(HIERARCHY_SLUGS.map(key => contextSlugs[key]), Boolean) as string[]
}

function contextSlugsForSlugs(slugs: string[]): ContextSlugs {
  return chain(HIERARCHY_SLUGS).zip(slugs)
    .takeWhile(([k, slug]) => !!k && !!slug)
    .fromPairs()
    .value()
}

function getEntities(context: HierarchyContext): HierarchyEntity[];
function getEntities(hierarchy: Hierarchy, path: string): HierarchyEntity[];
function getEntities(hierarchy: Hierarchy, path: string[]): HierarchyEntity[];
function getEntities(entity: HierarchyEntity): HierarchyEntity[];
function getEntities(hierarchy: Hierarchy | HierarchyContext | HierarchyEntity, path?: string | string[]): HierarchyEntity[] {
  if (isContext(hierarchy)) {return entitiesForContext(hierarchy)}
  if (isHierarchyEntity(hierarchy)) {return [...ancestors(hierarchy), hierarchy]}
  const slugs = Array.isArray(path) ? path : slugsForPath(path)
  let node: Hierarchy | HierarchyEntity = hierarchy;
  const entities = slugs.map(slug => {
    const child = find(getChildren(node), {slug})
    node = child
    return child
  })
  return takeWhile(entities, Boolean) as HierarchyEntity[]
}

export function getContext(entities: HierarchyEntity[]): HierarchyContext;
export function getContext(entity: HierarchyEntity): HierarchyContext;
export function getContext(hierarchy: Hierarchy, path: string): HierarchyContext;
export function getContext(hierarchy: Hierarchy, path: string[]): HierarchyContext;
export function getContext(hierarchy: Hierarchy, contextSlugs: ContextSlugs): HierarchyContext;
export function getContext(hierarchy: Hierarchy | HierarchyEntity[] | HierarchyEntity,
  path?: string | string[] | ContextSlugs): HierarchyContext {
  if (isHierarchyEntity(hierarchy)) {return contextForEntities(getEntities(hierarchy))}
  if (isEntities(hierarchy)) {return contextForEntities(hierarchy); }
  if (typeof path === 'string') {return contextForEntities(getEntities(hierarchy, path))}
  if (isContextSlugs(path)) {return contextForEntities(getEntities(hierarchy,
    slugsForContextSlugs(path)))}
  return contextForEntities(getEntities(hierarchy, path))
}

export function getPath(entities: HierarchyEntity[]): string;
export function getPath(context: HierarchyContext): string;
export function getPath(contextSlugs: ContextSlugs): string;
export function getPath(slugs: string[]): string;
export function getPath(path: string): string;
export function getPath(x: HierarchyEntity[] | HierarchyContext | ContextSlugs | string[] | string): string {
  if (isEntities(x)) {return pathForSlugs(getSlugs(x))}
  if (isContext(x)) {return pathForSlugs(getSlugs(x))}
  if (isContextSlugs(x)) {return pathForSlugs(getSlugs(x))}
  if (typeof x === 'string') {return x}
  return pathForSlugs(x)
}

export function getSlugs(entities: HierarchyEntity[]): string[];
export function getSlugs(context: HierarchyContext): string[];
export function getSlugs(contextSlugs: ContextSlugs): string[];
export function getSlugs(path: string): string[];
export function getSlugs(slugs: string[]): string[];
export function getSlugs(x: HierarchyEntity[] | HierarchyContext | ContextSlugs | string | string[]): string[] {
  if (isEntities(x)) {return takeWhile(map(x, 'slug'), Boolean) as string[]}
  if (isContext(x)) {return takeWhile(map(entitiesForContext(x), 'slug'), Boolean) as string[]}
  if (isContextSlugs(x)) {return slugsForContextSlugs(x)}
  if (typeof x === 'string') {return slugsForPath(x)}
  if (Array.isArray(x)) {return takeWhile(x, Boolean) as string[]}
  return []
}

export function getContextSlugs(entities: HierarchyEntity[]): ContextSlugs;
export function getContextSlugs(context: HierarchyContext): ContextSlugs;
export function getContextSlugs(slugs: string[]): ContextSlugs;
export function getContextSlugs(path: string): ContextSlugs;
export function getContextSlugs(params: {[key: string]: string}): ContextSlugs;
export function getContextSlugs(x: HierarchyEntity[] | HierarchyContext | string[] | string | {[key: string]: string}): ContextSlugs {
  if (!x) {return}
  if (typeof x === 'string') {return contextSlugsForSlugs(getSlugs(x))}
  if (isContext(x)) {return contextSlugsForSlugs(getSlugs(x))}
  if (isEntities(x)) {return contextSlugsForSlugs(getSlugs(x))}
  if (Array.isArray(x)) {return contextSlugsForSlugs(takeWhile(x, Boolean) as string[])}
  return contextSlugsForSlugs(takeWhile(HIERARCHY_SLUGS.map(k => x[k]), Boolean) as string[])
}

export function isPrefix(a: HierarchyEntity[] | HierarchyContext | ContextSlugs | string[] | string,
  b: HierarchyEntity[] | HierarchyContext | ContextSlugs | string[] | string): boolean {
  // Had to kind of strong arm the type checker here
  const aSlugs = getSlugs(a as string[])
  const bSlugs = getSlugs(b as string[])
  if (aSlugs.length > bSlugs.length) {return false}

  return zip(aSlugs, bSlugs.slice(0, aSlugs.length)).every(([a, b]) => a === b)
}

export function getChildren(hierarchyLevel = {}): HierarchyEntity[] {
  return HIERARCHY_LEVELS.reduce(
    (children, prop) => (children || hierarchyLevel[prop]) as HierarchyEntity[],
    null as HierarchyEntity[]
  ) || [];
}

export function setChildren(node, children: HierarchyEntity[]) {
  return HIERARCHY_LEVELS.reduce(
    (node, prop) => node[prop] ? {...node, [prop]: children} : node,
    node
  );
}

export function assignFullSlugs(hierarchy: HierarchyEntity) {
  return mapTree(
    hierarchy,
    (node, _, parent) => ({...node, fullSlug: `${parent ? parent['fullSlug'] : ""}/${node['slug']}`}),
    getChildren,
    setChildren
  )
}

export function assignFullNames(hierarchy: HierarchyEntity) {
  return mapTree(
    hierarchy,
    (node, _, parent) => ({...node, fullName: `${parent ? parent['fullName'] : ""}/${node['name']}`}),
    getChildren,
    setChildren
  )
}

// This adds a 'uniqueKey' prop to each hierarchy entity. For regions it is a combo key
// of the form `${client.id}--${region.id}`. For everything else it's just the normal id
export function assignUniqueKeys(hierarchy: HierarchyEntity) {
  return mapTree(
    hierarchy,
    (node, _, parent) => ({...node, uniqueKey: node['brands'] ? `${parent.id}--${node.id}` : `${node.id}`}),
    getChildren,
    setChildren
  )
}

export function addParentLinks(hierarchy: HierarchyEntity) {
  return mapTree(
    hierarchy,
    (node, _, parent) => ({...node, parent}),
    getChildren,
    setChildren
  )
}

export function addHierarchyLevelTags(hierarchy: HierarchyEntity) {
  return mapTree(
    hierarchy,
    (node) => ({
      ...node,
      hierarchyEntityType: getHierarchyEntityType(node)
    }),
    getChildren,
    setChildren
  )
}

export function validateSlugs(hierarchy: Hierarchy, slugs: string[]): boolean {
  const entities = hierarchy && slugs && getEntities(hierarchy, slugs)
  if (!entities || entities.length !== slugs.length) {return false}

  const [client] = entities
  return client && client['active']
}

export function getFullProductSlug() {
  return head(location.pathname.match(/^(\/[^/]*){4}/));
}

export function notInternalClient({visibility_level}: HierarchyClient): boolean {
  return visibility_level !== 'internal';
}

function getHierarchyEntityType(entity: HierarchyEntity): HierarchyType {
  if (isClient(entity)) {return 'client'; }
  if (isRegion(entity)) {return 'region'; }
  if (isBrand(entity)) {return 'brand'; }
  if (isProduct(entity)) {return 'product'; }
}

function isHierarchyEntity(x): x is HierarchyEntity {
  return !!getHierarchyEntityType(x)
}

function isContext(context): context is HierarchyContext {
  context = context || {}
  const presentTypes = takeWhile(HIERARCHY_TYPES, type => !!context[type])
  return presentTypes.length > 0
         && presentTypes.every(type => getHierarchyEntityType(context[type]) === type)
}

function isContextSlugs(x): x is ContextSlugs {
  x = x || {}
  const slugs = takeWhile(HIERARCHY_SLUGS.map(key => x[key]), Boolean)
  return slugs.length && slugs.every(s => typeof s === 'string')
}

function isEntities(x): x is HierarchyEntity[] {
  return x && Array.isArray(x) && x.every(isHierarchyEntity)
}

function isClient(x: any): x is HierarchyClient {
  return x && depth(x) === 1
}

function isRegion(x: any): x is HierarchyRegion {
  return x && (x.brands || x.brand_ids);
}

function isBrand(x: any): x is HierarchyBrand {
  return x && (x.products || x.product_ids);
}

function isProduct(x: any): x is HierarchyProduct {
  return x && filter(x.slug_full || [], char => char === '/').length === 4
}

function depth(x: HierarchyEntity): number {
  if (!has(x, 'fullSlug')) {return 0}
  return x['fullSlug'].replace(/[^/]*/g, '').length
}

export function uniqueRegionIds(products: Product[] | HierarchyProduct[]): string[] {
  return uniq(flatMap(products, 'region_ids'))
}

export function uniqueRegions(products: Product[], regions: Region[]): Region[] {
  const uniqueIds = uniqueRegionIds(products)
  return regions.filter(r => uniqueIds.includes(r.id))
}

export function regionNames(regions: Region[]): string {
  return regions.map(r => r.name)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    .join(', ') || 'None'
}

export function uniqueProducts(client: HierarchyClient): HierarchyProduct[] {
  return chain(client.regions).flatMap('brands').flatMap('products').uniqBy('id').value()
}

export function alphabetizedRegionNamesList(products, regions): string {
  const uniqueRidsForProducts = uniqueRegionIds(products);
  const uniqueRegionsForProducts = regions.filter(r => uniqueRidsForProducts.includes(r['id']));
  return regionNames(uniqueRegionsForProducts);
}

export function uniqueProductsByHierarchyClient(client: HierarchyClient): HierarchyProduct[] {
  const clientProducts = new Set();
  client.regions.map(r => r.brands.map(b => b.products.map(p => clientProducts.add(p))));
  return uniqBy(<HierarchyProduct[]>Array.from(clientProducts), 'id');
}

// ex slug: "/my-client/my-region/my-brand/my-product"
export function getHierarchyContextFromString(hierarchy: Hierarchy, slug: string): HierarchyContext | undefined {
  const hierarchyContext = {
    client: undefined,
    region: undefined,
    brand: undefined,
    product: undefined,
  };
  const s = slug.substr(1).split('/');

  if (s.length !== 4) { return; }

  let data = hierarchy;
  forEach(HIERARCHY_LEVELS, (key, index) => {
    const context = find(data[key], d => d.slug === s[index]);
    // break early
    if (!context) { return false };

    hierarchyContext[key.slice(0, -1)] = context;
    data = context;
  });

  return isFullContext(hierarchyContext) ? hierarchyContext : undefined;
}
