import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { select, Store } from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { flatMap } from 'lodash';
import { AppState } from 'app/reducers';
import { UploadAdminService } from "app/admin/upload-admin/upload-admin.service";
import { selectRegion } from "app/hierarchy/hierarchy.reducers";
import { ResetFetchState } from 'app/shared/utils/fetch-state';
import * as journeyActions from "app/journey/journey.actions";
import { FetchCurrentUser } from 'app/users/user.actions';

@UntilDestroy()
@Component({
  selector: 'ppc-journeys-upload-admin',
  templateUrl: './journeys-upload-admin.component.html',
  styleUrls: ['./journeys-upload-admin.component.sass']
})

export class JourneysUploadAdminComponent implements OnDestroy {
  public updatedJourneys = [];
  public errors = [];
  fileName = '';
  errorListCollapsed = false;
  updatesListCollapsed = false;

  public rules = [
    "The template will only affect your current client and region.",
    "Each row must contain a value in columns A-H; columns I-O are optional.",
    "To add multiple demographics to a Stage, add a line for each attribute value with matching Brand, Product, Journey Name, Brand Name, and Stage Name.",
    "A list of all updated Journeys and Stages will appear after uploading.",
    "If any errors are found, they will appear below the upload section with a corresponding row and column (if applicable). Errors do not stop the upload process (provided file has valid brand(s), product(s) and format), but the field or row in error will not be updated.",
    "All available Brands and Products will appear in columns to the right of your downloaded template.",
    "Position columns are numerical. Stages are left to right starting with 1. Brands and Attributes are top to bottom starting with 1.",
    "Percentage values should be entered into the 'Attribute Percent' column.",
    "Enter values into either 'Attribute Value' or 'Attribute Percent' column. Do not use both columns when adding new data."
  ]

  constructor(
    public uploadAdminService: UploadAdminService,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar,
    public store: Store<AppState>
  ) {
    this.store.select('hierarchy').pipe(
      select(selectRegion), filter(Boolean), untilDestroyed(this)
    ).subscribe(region => this.clearUploadMessages());
    this.store.dispatch(new FetchCurrentUser({ping: true}));
  }

  ngOnDestroy() {}

  downloadTemplate() {
    this.uploadAdminService.downloadJourneyTemplate().subscribe();
  }

  uploadTemplate(event, form: HTMLFormElement) {
    if (!event.target.files[0]) {return; }
    this.fileName = event.target.files[0].name;
    this.clearUploadMessages();
    this.uploadAdminService.loading(true);
    this.uploadAdminService.uploadJourneyTemplate(new FormData(form)).subscribe(
      response => this.handleSuccessResponse(flatMap(response.journeys, "brands"), response.errors),
      error => this.snackbar.open(error, null, { duration: 6000, panelClass: ['danger'] })
    );

    event.target.value = '';
  }

  handleSuccessResponse(response, errors) {
    this.store.dispatch(new ResetFetchState(journeyActions.FetchJourneys));
    this.updatedJourneys = response;
    this.errors = errors;
    this.snackbar.open("Upload Complete", 'ok', { duration: 4000, panelClass: ['check'] });
  }

  getSanitizedValue(val): SafeStyle {
    return this.sanitizer.bypassSecurityTrustHtml(val);
  }

  clearUploadMessages() {
    if (this.updatedJourneys.length) {this.updatedJourneys = []; }
    if (this.errors.length) {this.errors = []; }
  }
}
