import {Vendor} from "../../admin/vendors/vendor";

import * as actions from './vendors.actions'
import {createSelector} from "@ngrx/store";
import {VendorPermission} from "../../admin/vendors/vendor-permission.model";
import {PermissionOption} from "../../admin/segment-permissions/models/permission-option.model";


export interface State {
  vendors: Vendor[],
  permissionOptions: PermissionOption[],
  permissions: VendorPermission[]
}

export const initialState: State = {
  vendors: [],
  permissionOptions: [],
  permissions: []
};

export function reducer(state = initialState, action): State {
  switch (action.type) {
    case actions.LoadVendors.type:
      return Object.assign({}, state, { vendors: action.vendors });
    case actions.LoadVendorPermissionOptions.type:
      return Object.assign({}, state, { permissionOptions: action.permissionOptions });
    case actions.LoadVendorPermissions.type:
      return Object.assign({}, state, { permissions: action.vendorPermissions });
    default:
      return state
  }
}

export const selectVendors = (state: State) => state.vendors;
export const selectPermissionOptions = (state: State) => state.permissionOptions;
export const selectVendorPermissions = (state: State) => state.permissions;

export const getVendors = createSelector(
  selectVendors,
  (vendors) => vendors.map(vendor => new Vendor(vendor))
);

export const getVendorPermissions = createSelector(
  selectVendorPermissions,
  (permissions) => permissions.map(permission => new VendorPermission(permission))
);
