<div class="ppc-dropdown">
  <div class="ppc-dropdown-value" (click)="open = !open">
    <div class="ppc-dropdown-value-text" #text>
      <ng-content select="[currentlySelected]"></ng-content>
    </div>
    <i class="fa fa-chevron-down" [class.rotate-180]="open"></i>
  </div>
  <div class="ppc-dropdown-dropdown" [class.open]="open">
    <svg class="ppc-dropdown-dropdown-caret" viewbox="0 0 15 7.5" [style.left.px]="text.clientWidth + 20">
      <path d="m 0 7.5 l 7.5 -7.5 l 7.5 7.5" stroke="#5a5a5a" fill="#313131"></path>
    </svg>
    <ng-content select="[dropdown]"></ng-content>
  </div>
</div>
