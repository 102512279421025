import { Component, OnInit, OnChanges, Input } from '@angular/core';
import Timer = NodeJS.Timer;

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.sass']
})
export class ProgressBarComponent implements OnInit, OnChanges {

  @Input() percent: number;
  @Input() color: string;
  @Input() thickness = 15;
  @Input() rotate?: boolean;
  @Input() name?: string;
  @Input() hideText?: boolean;
  @Input() backgroundColor: string = "#161616";
  @Input() opacity?: number;
  @Input() lineColor?: string;
  @Input() waitToAnimate?: boolean = true;
  @Input() rounded?: boolean = true;
  @Input() showOuterText?: boolean = false;
  @Input() shouldAnimate?: boolean = true;

  endPercent: number = 0;
  show: boolean = false;

  timeout: Timer;

  constructor() { }

  ngOnInit() {
    this.lineColor = this.lineColor || 'transparent';
  }

  ngOnChanges(changes) {
    if (this.shouldAnimate) {
      if (this.waitToAnimate) {
        this.timeout && clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.endPercent = this.percent, 200);
      } else {
        this.endPercent = this.percent
      }
    }
  }

}
