import {Component, Input, OnInit} from '@angular/core';
import {AudienceV2} from 'app/audiences-v2/audience-v2.model';
import {LookALike} from 'app/look-a-like-dialog/look-a-like';
import {get} from 'lodash'

@Component({
  selector: '[ppc-home-audience-row]',
  templateUrl: './audience-row.component.html',
  styleUrls: ['../../home.component.sass']
})

export class AudienceRowComponent implements OnInit {
  @Input() row: AudienceV2 | LookALike;
  @Input() hierarchyPath: string;
  count = 0;
  icon: string;
  tooltipTex: string;

  constructor() {
  }

  ngOnInit() {
    this.count = get(this.row, 'count.people.global')
    const type = get(this.row, 'type');

    if (type === 'lookalike') {
      this.icon = 'fa fa-users';
      this.tooltipTex = 'Lookalike';
    } else if (type === 'outcome') {
      this.icon = 'fa fa-user-circle-o';
      this.tooltipTex = 'Outcome Audience';
    } else {
      this.icon = 'fa fa-user';
      this.tooltipTex = 'Audience';
    }
  }
}
