<div class="paginator">
  <div class="paginator-page-size">
    <div class="paginator-page-size-label">Items per page:</div>

    <div class="paginator-page-size-select">
      <mat-select  [ngModel]="pageSize"
                  (selectionChange)="changePageSize($event.value)">
        <mat-option *ngFor="let pageSizeOption of pageSizeOptions" [value]="pageSizeOption">
          {{pageSizeOption}}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="paginator-range-actions">
    <div class="paginator-range-label">
      {{rangeLabel}}
    </div>

    <button mat-icon-button
            [disabled]="!hasPreviousPage()"
            (click)="previousPage()">
      <svg class="paginator-icon" focusable="false" viewBox="0 0 24 24">
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
      </svg>
    </button>
    <button mat-icon-button
            [disabled]="!hasNextPage()"
            (click)="nextPage()">
      <svg class="paginator-icon" focusable="false" viewBox="0 0 24 24">
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
      </svg>
    </button>
  </div>
</div>
