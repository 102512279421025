import { Action } from "@ngrx/store";

import { PageInfo } from 'app/models/paginable.model';
import { Destination } from 'app/admin/destination-management/destination.model';

export interface FetchOptions {
  limit?: number;
  offset?: number;
  companyId?: string;
  state?: string;
  searchTerm?: string;
  sort?: string[];
}

export class FetchDestinations implements Action {
  static readonly type = "DESTINATION_MANAGEMENT:FETCH_DESTINATIONS";
  readonly type = FetchDestinations.type;

  constructor(public fetchOptions?: FetchOptions) {}
}

export class LoadDestinations implements Action {
  static readonly type = "DESTINATION_MANAGEMENT:LOAD_DESTINATIONS";
  readonly type = LoadDestinations.type;

  constructor(public destinations: Destination[], public pageInfo: PageInfo) {}
}

export class SavePartialDestination implements Action {
  static readonly type = "DESTINATION_MANAGEMENT:SAVE_PARTIAL_DESTINATION";
  readonly type = SavePartialDestination.type;

  constructor(public id: number, public destination: Partial<Destination>) {}
}

export class LoadPartialDestination implements Action {
  static readonly type = "DESTINATION_MANAGEMENT:LOAD_PARTIAL_DESTINATION";
  readonly type = LoadPartialDestination.type;

  constructor(public destination: Partial<Destination>) {}
}

export type ReducerActions = FetchDestinations | LoadDestinations | SavePartialDestination | LoadPartialDestination
