<div (click)="toggleList()" class="brand-container">
  <ng-template #conversantIdTooltip>
    <div class="custom-brand-tooltip">
      <div class="name">
        Conversant Company Id
      </div>
      <div class="description">
        <div>A value set here may override an id set for the client if this brand's hierarchy context is passed to PVE. An id set within its product may also override an id set here if its hierarchy context is used</div>
      </div>
    </div>
  </ng-template>
  <div *ngIf="brand.isOpen" class="form-container">
    <div class="form-header">
      Brand
      <span (click)="exitEditBrand($event)" class="fa fa-close hoverable"></span>
    </div>

    <div class="brand-form layout horizontal center start-justified flex">
      <div class="flex-1">
        <ppc-input
          [minLength]="1"
          [(ngModel)]="brand.name"
          required
          >
          <label>Name</label>
          <div suffix class="required-suffix" *ngIf="!brand.name">*</div>
        </ppc-input>
      </div>
      <div class="flex-1">
        <ppc-input
          [(ngModel)]="brand.category"
          name="brandCategory"
          >
          <label>Category</label>
        </ppc-input>
      </div>
      <div class="flex-1">
        <ppc-input disabled [value]="regionNamesList">
          <label>Regions</label>
        </ppc-input>
      </div>
      <div class="flex-2 brand-conversant-id-container">
        <div class="flex conversant-id">
          <ppc-input
            class="conversant-id-field"
            type="number"
            name="conversantIdCategory"
            [min]="CONVERSANT_COMPANY_ID_MIN"
            [max]="CONVERSANT_COMPANY_ID_MAX"
            [(ngModel)]="brand.conversant_company_id"
            [validators]="conversantIdInputValidators"
            placeholder="{{client.conversant_company_id | wrapParenthesis}}" 
            showErrorsIfUntouched=true
            alwaysFloatLabel=true>
            <label>Conversant Company Id</label>
          </ppc-input>
        </div>
        <div class="flex">
          <img src="assets/icons/question-circle.svg" ppcTooltip [tooltipTemplate]="conversantIdTooltip"/>
        </div>
      </div>

      <div class="save-btn-wrapper flex-2">
        <ng-template #applyBtnTooltip>
          <div class="custom-brand-tooltip">
            <div class="name">
              Apply
            </div>
            <div class="description">
              <div>This action does not persist changes. It only opens the brand's products for continued editing. To finalize changes click save below</div>
            </div>
          </div>
        </ng-template>
        <div ppcTooltip
          [tooltipTemplate]="applyBtnTooltip"
          tooltipHideEvent="click">
          <button (click)="applyBrand()"
          [disabled]="applyDisabled()"
          class="btn btn-save">APPLY</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="brand.isClosed" class="layout horizontal">
    <div class="flex-1">
      <div class="label">Brand Name</div>
      <div class="text">{{brand.name}}</div>
    </div>
    <div class="flex-1">
      <div class="label">Category</div>
      <div class="text">{{brand.category}}</div>
    </div>
    <div class="flex-1">
      <div class="label">Regions</div>
      <div class="text">{{regionNamesList}}</div>
    </div>
    <div class="flex-1">
      <div class="collapsed-brand-conversant-id-container" *ngIf="brand.conversant_company_id || client.conversant_company_id">
        <div>
          <div class="label">Conversant Company Id</div>
          <div class="text" [ngClass]="{ 'collapsed-parent-conversant-id' : !brand.conversant_company_id }">
            {{brand.conversant_company_id || (client.conversant_company_id | wrapParenthesis)}}
          </div>
        </div>
        <img src="assets/icons/question-circle.svg" ppcTooltip [tooltipTemplate]="conversantIdTooltip"/>
      </div>
    </div>

    <div>
      <button (click)="editBrand($event)" class="btn btn-save">EDIT</button>
      &nbsp;
      <app-confirmation
        [isButton]="true"
        [kontinue]="destroyBrand"
      ></app-confirmation>
    </div>
  </div>

  <div *ngIf="brand.isClosed" class="add-product-btn-wrapper">
    <a (click)="addProduct($event)" class="btn btn-add">
      <span class="fa fa-plus"></span>
      ADD PRODUCT
    </a>
  </div>
</div>

<ul *ngIf="brand.products.length > 0 && !listCollapsed" class="product-list">
  <li *ngFor="let product of brand.products; index as i" class="product-list-item">
    <app-product
      [client]="client"
      [brand]="brand"
      [product]="product"
      [validators]="productValidators"
      [brandProductsNameUpdated$]="brandProductsNameUpdated$"
      (productNameUpdate)="onProductNameUpdated($event)"
      (deleteProduct)="onDeleteProduct($event)">
    </app-product>
  </li>
  <li *ngIf="brand.isAnyProductOpen" class="product-list-action">
    <button
      (click)="saveAll()"
      [disabled]="saveDisabled()"
      class="btn btn-save">SAVE</button>
  </li>
  <li class="align-right">
    <mat-error *ngFor="let err of errorMessages">
      {{err}}
    </mat-error>
  </li>
</ul>
