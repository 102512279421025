import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, tap, pluck, take } from 'rxjs/operators';
import { chain, find, get, intersection } from 'lodash';
import {AppState} from "app/reducers";
import {Store, select} from "@ngrx/store";
import {Go} from "app/router/router.actions";
import {ToolboxService} from 'app/services/toolbox.service';
import {HierarchyService} from 'app/services/hierarchy.service'
import {FetchAllUnits} from 'app/toolbox/toolbox.actions'
import {isNotYetFetched} from 'app/shared/utils/fetch-state'

@Injectable()
export class IframePageGuard implements CanActivate {

  constructor(private store: Store<AppState>,
    private toolboxService: ToolboxService,
    private hierarchyService: HierarchyService) {}

  // If we are iFraming a Unit that shouldn't be iFramed or isn't available in the current
  // region then navigate to the default route
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.store.select('fetchStates', FetchAllUnits.type).pipe(map(isNotYetFetched), take(1))
      .subscribe(needsFetch =>
        needsFetch && this.store.dispatch(new FetchAllUnits({clientSlug: next.params['clientSlug'],
          type: 'applications'})))

    const nextUnit$ = this.toolboxService.loadedUnits$.pipe(
      map(units => find(units, {name: next.params['unitName']})))

    const nextRegionId$ = this.hierarchyService.loadedHierarchy$.pipe(
      map(hierarchy => get(chain(hierarchy.clients).flatMap('regions')
        .find({slug: next.params['regionSlug']})
        .value(), "id")))

    return combineLatest(nextUnit$, nextRegionId$).pipe(
      map(([ unit, regionId ]) =>
        get(unit, 'iframed')
          && unit.client_slug === next.params['clientSlug']
          && intersection(unit.region_ids, ['all', regionId]).length > 0),
      tap(isValid => !isValid && this.store.dispatch(new Go({path: ['home']})))
    )
  }
}
