<div class="user-info-container">
  <button mat-button [matMenuTriggerFor]="menu" class="menu-first-name">
    <span *ngIf="userService.isUserPosing$ | async">Viewing as </span>
    <span *ngIf="userFirstName$ | async">{{userFirstName$ | async}}</span>
  </button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="redirectToAdminDashboard()">Admin</button>
    <button mat-menu-item (click)="redirectToServiceUrl()">Request Support</button>
    <button mat-menu-item (click)="toggleService()" *ngIf="canToggleDataSource">Toggle to {{ serviceTypeText }}</button>
    <button *ngIf="userService.isUserPosing$ | async;else canLogout" mat-menu-item (click)="stopViewAs()">Stop Viewing As</button>
    <ng-template #canLogout>
      <button mat-menu-item (click)="logout()">Logout</button>
    </ng-template>
  </mat-menu>

</div>
