import { BehaviorSubject, Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { map, takeUntil, tap, distinctUntilChanged, combineLatest } from 'rxjs/operators';
import { Actions } from '@ngrx/effects';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Subject } from "rxjs";
import { AppState } from 'app/reducers';
import { Store } from "@ngrx/store";
import { Unit } from './unit.interface';
import { Category } from './category.interface';
import { values, orderBy } from 'lodash';
import { compareKey, sortByMultiKeys, toHTML } from 'app/shared/utils/utils';
import {
  FetchAllUnits,
  FetchAllCategories,
  CreateCategory,
  UpdateCategory,
  DestroyCategory,
  UpdateCategoryPriorities,
  UpdateUnit
} from './toolbox.actions';
import { AuthPermission } from '../shared/interfaces/auth-permission';
import { CustomUnit } from './unit/custom-unit.model';
import { CustomCategory } from './category/custom-category.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fetchOutcome } from '../shared/utils/fetch-state';
import { DragulaService } from 'ng2-dragula';
import { isFetchInFlight } from "app/shared/utils/fetch-state";
import { fullContext } from "app/hierarchy/hierarchy.reducers";
import { HierarchyRegion, ContextSlugs } from 'app/hierarchy/hierarchy.interface';

@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.sass']
})
export class ToolboxComponent implements OnInit, OnDestroy {
  units: Unit[] = null;
  categories: Category[] = null;
  selectedUnit: Unit = null;
  toHtml = toHTML;
  toolboxCorePerms: AuthPermission;
  toolboxClientPerms: AuthPermission;
  isConfig: boolean = false;
  clientId: number = null;
  unitsTypeId: number = null;
  ngUnsubscribe = new Subject();
  isLoading$: Observable<boolean>;
  clientRegions: Partial<HierarchyRegion>[] = null;
  contextSlugs: ContextSlugs = null;
  currentRegion: Partial<HierarchyRegion> = null;
  BAG: string = "UNIT_BAG";
  modalView: boolean = false;
  invalidCategoryName: boolean = true;
  categoryNameRequest: string = `Please choose a unique name.`;
  unitStates: Array<string> = ['Show All', 'Embedded Components'];
  unitState$ = new BehaviorSubject('Show All')
  units$: Observable<Unit[]>;

  public constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private _sanitizer: DomSanitizer,
    private location: Location,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private dragulaService: DragulaService
  ) {
    this.location = location;
    this.actions$.pipe((fetchOutcome(DestroyCategory.type)),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(
        res => this.alertSuccess({message: 'Category deleted! All containing components are now hidden.'}),
        err => this.alertFailure({message: 'Unable to delete! Does it exist?'})
      );

    this.isLoading$ = this.store.select('fetchStates', UpdateUnit.type).pipe(map(isFetchInFlight))

    // https://goo.gl/fdbkNY
    dragulaService.drop.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (this.isConfig) {
          this.onDrop(value.slice(1));
        }
      });
  }

  ngOnInit() {

    const clientChanges$ = this.route.params.pipe(
      map(params => params.clientSlug),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe), );

    const typeChanges$ = this.route.params.pipe(
      map(params => params.type || 'applications'),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe), );

    typeChanges$.pipe(combineLatest(clientChanges$),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(([type, clientSlug]) => this.store.dispatch(new FetchAllUnits({clientSlug, type})));

    clientChanges$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(clientSlug => this.store.dispatch(new FetchAllCategories({clientSlug})));

    this.units$ = observableCombineLatest(this.store.select('toolbox', 'units'), this.unitState$)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(([ units, unitState ]) => {
          return values(units).map(unit => ({...unit}))
            .filter(unit => unitState == 'Embedded Components' ? unit.iframed : true)
            .filter(unit => (this.isConfig || unit.show_in_applications) ? true : false)
        }),
        map(units => orderBy(values(units), ['priority', 'updated_at'], ['asc', 'desc'])),
        tap(units => this.units = units)
      )

    this.store.select('toolbox', 'categories').pipe(
      map(categories => values(categories).sort(compareKey('priority'))),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(categories => this.categories = categories)

    this.store.select('permissions').pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(permissions => {
        this.toolboxCorePerms = permissions['toolbox_core_configuration'];
        this.toolboxClientPerms = permissions['toolbox_client_configuration'];
      });

    this.store.select('toolbox', 'clientId').pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe( clientId => { this.clientId = clientId; });

    this.store.select('toolbox', 'unitTypeId').pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe( unitsTypeId => { this.unitsTypeId = unitsTypeId; });

    this.store.select('hierarchy', 'contextSlugs').pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(slugs => {
        this.contextSlugs = slugs;
      });

    fullContext(this.store).pipe(takeUntil(this.ngUnsubscribe)).subscribe(hierarchy => {
      if (hierarchy.client) {
        this.clientRegions = hierarchy.client.regions.slice(0);
        this.currentRegion = this.clientRegions.find((region) => region.slug === this.contextSlugs.regionSlug) || {};
      }
    });

    this.isConfig = this.isEditable(this.location);

    // prevent dragging when not in config mode
    this.dragulaService.setOptions(this.BAG, {
      moves: function (el: any): boolean {
        return !(el.classList.contains('sansdrag'));
      }
    });

    this.invalidCategoryNameCheck(this.categories);
  }

  unitStateUpdate(unitState) {
    this.unitState$.next(unitState);
  }

  regionCheck(unit: Unit, currentRegion: Partial<HierarchyRegion>, isConfig: boolean): boolean {
    // in  config mode, we don't mask any units
    if (isConfig || unit.region_ids.includes('all')) {
      return true;
    }
    // if the unit belongs to the client region then render √
    if (unit.region_ids.length) {
      return !!(unit.region_ids.indexOf(currentRegion.id + '') >= 0);
    }
    return false;
  }

  categoryById(category) {
    return category.id;
  }

  availableUnits(categoryId) {
    return this.units.filter(unit => {
      return unit.category_id === categoryId && unit.status &&
        this.regionCheck(unit, this.currentRegion, this.isConfig)
    })
  }

  // This function is important for calculating the indexes of each _rendered_ unit to the screen.
  // It is used to calculate the offset of the .box-info which gets placed relative to the unit's
  // placement horizontally in the row below.
  nonHiddenIndexes(categoryId) {
    if (this.isConfig) { return 0; } // .box-info is not rendered on configuration
    const indexes = {};
    this.availableUnits(categoryId).forEach((unit, i) => {
      indexes[unit.id] = i
    })
    return indexes;
  }

  customUnit(categoryId: number): Unit {
    if (categoryId && this.clientId && this.unitsTypeId) {
      return new CustomUnit(categoryId, this.clientId, this.unitsTypeId);
    }
  }

  createCustomCategory(name = '', lastPriority: number): void {
    let customCategory = null;
    if (lastPriority !== undefined) {
      customCategory = new CustomCategory(name, this.clientId, lastPriority + 1);
      this.store.dispatch(new CreateCategory(customCategory))
    }
  }

  adjustPriorities(myCat: Category, categories: Category[], direction: number) {
    const { priority } = myCat;
    return categories.map((cat) => {
      if (cat.priority === priority) {
        cat.priority = cat.priority + direction
      } else if ((cat.priority - direction) === priority) {
        cat.priority = cat.priority + (direction * -1)
      }
      return cat;
    });
  }

  moveCategoryPriority(cat: Category, direction: number) {
    const payload = this.adjustPriorities(cat, this.categories, direction);
    this.store.dispatch(new UpdateCategoryPriorities(payload));
  }

  onUpdateCategory($event, category: Category): void {
    const newCategoryName = this._sanitizer
      .sanitize(SecurityContext.HTML, category.name.trim());
    const exists = this.categories.filter(cat => {
      // matches entry value OR entry sanitized value
      return cat.name.toLowerCase() === category.name.toLowerCase() ||
        cat.name.toLowerCase() === newCategoryName.toLowerCase();
    });
    if (exists.length === 1 && newCategoryName && newCategoryName !== this.categoryNameRequest) {
      this.store.dispatch(new UpdateCategory({...category, name: newCategoryName}));
      $event.target.blur();
    } else {
      category.name = this.categoryNameRequest;
    }
    this.invalidCategoryNameCheck(this.categories);
  }

  onCategoryKeydown($event, category: Category) {
    if ($event.which === 13) {
      this.onUpdateCategory($event, category);
    }
  }

  onCategoryFocus($event): void {
    if ($event.target.value === this.categoryNameRequest) {
      $event.preventDefault();
      $event.target.select();
    }
  }

  onDestroyCategory(category: Category): any {
    if (category.id) {
      this.store.dispatch(new DestroyCategory(category))
      this.invalidCategoryNameCheck(this.categories);
    }
  }

  canEdit(permission: AuthPermission): boolean {
    if (permission) {
      return !!(permission.create && permission.update && permission.destroy);
    }
  }

  isEditable(location: Location): boolean {
    return !!location.path(true).includes('configuration');
  }

  ngOnDestroy() {
    this.dragulaService.destroy(this.BAG);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  focusOn(id: string) {
    const el: HTMLElement = document.getElementById(id) as HTMLElement;
    el.focus();
    document.execCommand('selectAll', false, null);
  }

  onToggled(unit: Unit) {
    if (unit === this.selectedUnit) {
      this.selectedUnit = null;
    } else {
      this.selectedUnit = unit;
    }
  }

  renderHtml(str: string): HTMLElement {
    return (!this.isConfig) ? this.toHtml(str) : str;
  }

  invalidCategoryNameCheck(categories: Category[]): void {
    this.invalidCategoryName = this.categories.some(cat => !cat.name || (cat.name === this.categoryNameRequest));
  }

  hasRenderedUnits(units: Unit[]): boolean {
    return units.some(unit => unit.show_in_applications)
  }

  private alertSuccess(success): void {
    this.snackbar.open(`Success! ${success.message}`, null, {
      duration: 4000,
      panelClass: ['check']
    });
  }

  private alertFailure(error): void {
    this.snackbar.open(`Failure! ${error.message}`, null, {
      duration: 4000,
      panelClass: ['danger']
    });
  }

  // Dragula
  private buildPriorityUpdateArray (els: HTMLCollection, el: HTMLElement) {
    const bagCategoryId = +el.dataset.bagcat;
    return Array.from(els).map((e: HTMLElement, idx) => {
      return {id: +e.dataset.unit, priority: idx, category_id: bagCategoryId}
    });
  }

  private onDrop(args) {
    const [e, el] = args;
    const unitPriority = this.buildPriorityUpdateArray(e.parentNode.children, el);
    unitPriority.forEach((unit: Partial<Unit>) => {
      this.store.dispatch(new UpdateUnit(unit));
    });
  }

}
