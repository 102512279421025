import { apiUrl } from '../../services/ppc_http.service';
import { CLIENTS_API_PATH, V1 } from '../utils/constants';

export function clientsAdminUrl() {
  return apiUrl(CLIENTS_API_PATH + `/${V1}/admin/clients`);
}

export function clientsListAdminUrl(versionNumber) {
  return apiUrl(CLIENTS_API_PATH + `/${versionNumber}/client_list`);
}

export function clientsUrl() {
  return apiUrl(CLIENTS_API_PATH + `/${V1}/clients`);
}

export function regionsUrl() {
  return apiUrl(CLIENTS_API_PATH + `/${V1}/regions`);
}

export function brandsUrl() {
  return apiUrl(CLIENTS_API_PATH + `/${V1}/brands`);
}

export function productsUrl() {
  return apiUrl(CLIENTS_API_PATH + `/${V1}/products`);
}

export function countriesUrl() {
  return apiUrl(CLIENTS_API_PATH + `/${V1}/countries`);
}

export function featureModulesUrl() {
  return apiUrl(CLIENTS_API_PATH + `/${V1}/feature_modules`);
}

export function clientFeaturePermissionsUrl() {
  return apiUrl(CLIENTS_API_PATH + `/${V1}/clients_feature_permissions`);
}

export function permissionsUrl() {
  return apiUrl(CLIENTS_API_PATH + `/${V1}/permissions`);
}

export function hierarchyTreeUrl() {
  return apiUrl(CLIENTS_API_PATH + `/${V1}/hierarchy/tree`);
}

export function currenciesUrl() {
  return apiUrl(CLIENTS_API_PATH + `/${V1}/currencies`);
}
