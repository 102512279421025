import { first, difference, find, map } from 'lodash';

export type JourneyTabType = "Total Population" | "Matched" | "Modeled";

export const STAGE_ICONS = [ "blank", "eye-slash", "low-vision", "eye", "group",
  "heartbeat", "heart", "meh-o", "thumbs-up", "thumbs-down", "key", "shopping-cart",
  "comments", "clock-o", "bullhorn", "magnet", "star", "map-marker", "flag",
  "question-circle",  "envelope", "envelope-open", "tachometer", "calendar", "balance-scale",
  "circle-1.svg", "circle-2.svg", "circle-3.svg", "circle-4.svg", "circle-5.svg",
  "circle-6.svg", "circle-7.svg", "circle-8.svg", "circle-9.svg", "circle-10.svg" ]

export interface Journey {
  id?: number;
  name?: string;
  brands?: JourneyBrand[];
  stages?: JourneyStage[];
  sub_markets?: JourneySubMarket[];
  default?: boolean;
  has_transition_data: boolean;
  created_at?: string;
  updated_at?: string;
}

export interface JourneyBrand {
  id?: number;
  journey_id?: number;
  name: string;
  order?: number;
  new_id?: number;
}

export interface JourneyStage {
  id?: number;
  journey_id?: number;
  name: string;
  icon?: string;
  priority?: number;
  new_id?: number;
}

export interface JourneySubMarket {
  id?: number;
  journey_brand_id?: number;
  journey_stage_id?: number;
  name?: string;
  order?: number;
  tag?: 'owned' | 'opportunity' | 'none';
  market_id?: number;
  matched_id?: string;
  modeled_id?: string;
  population?: number;
  brand?: JourneyBrand;
  stage?: JourneyStage;
  new_journey_brand_id?: number;
  new_journey_stage_id?: number;
  discussion_cluster_node_id?: number;
  webo_cluster_id?: string;
}

export function newJourney(currentBrand: string): Journey {
  const brand = newBrand([], currentBrand);
  const stage = newStage([]);
  return {
    name: "",
    has_transition_data: true,
    brands: [
      brand
    ],
    stages: [
      stage
    ],
    sub_markets: [
      {
        brand,
        stage
      }
    ]
  }
}

export function newBrand(brands: JourneyBrand[], currentBrand?: string): JourneyBrand {
  const brandIds = brands.map(brand => brand.id || brand.new_id);
  const newId = first(difference([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], brandIds));
  return {
    name: currentBrand || getNewName(brands, 'New Brand'),
    new_id: newId
  }
}

export function newStage(stages: JourneyStage[]): JourneyStage {
  const stageIds = stages.map(stage => stage.id || stage.new_id);
  const newId = first(difference([0, 1, 2, 3], stageIds));
  return {
    name: getNewName(stages, 'New Stage'),
    new_id: newId,
    icon: STAGE_ICONS[1]
  }
}

// get a new default brand or stage name
function getNewName(list: Array<JourneyBrand|JourneyStage>, keyword: string) {
  const names = map(list, item => item.name.toLowerCase())
  let number = 1;
  let name = `${keyword} ${number}`;

  while (names.includes(name.toLowerCase())) {
    number += 1;
    name = `${keyword} ${number}`;
  }

  return name;
}
