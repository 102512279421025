import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { selectActiveSnapshot, FeatureImportanceEntry } from 'app/snapshots/snapshots.reducer';
import { prettyPathParts, SegmentV2 } from 'app/audiences/discover/segment-v2.model';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap, filter } from 'rxjs/operators';
import { map as _map, keyBy, get, filter as _filter, find } from 'lodash';
import { SubMarket } from 'app/mekko/mekko.reducer';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { isDefined } from 'app/shared/utils/utils';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';

@UntilDestroy()
@Component({
  selector: 'ppc-feature-importance',
  templateUrl: './feature-importance.component.html',
  styleUrls: ['./feature-importance.component.sass']
})
export class FeatureImportanceComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  tableSorts = [{prop: 'segment_score', dir: 'desc'}]
  featureImportanceRows: FeatureImportanceEntry[];
  segments: {[identifier: string]: SegmentV2} = {};
  subMarkets: any; // Sub markets with extra "visible" field
  nameComparator = this._nameComparator.bind(this);
  vendorDisplayNames: VendorDisplayName[];

  constructor(private store: Store<AppState>, private segmentService: SegmentV2Service) { }

  ngOnInit() {
    this.store.select("snapshots").pipe(
      select(selectActiveSnapshot),
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(snapshot => {
      this.subMarkets = _map(snapshot.sub_markets, (subMarket: SubMarket) => ({...subMarket, visible: true}))
      this.featureImportanceRows = snapshot.feature_importance.segment_id_scores;
    })

    this.store.select("snapshots").pipe(
      select(selectActiveSnapshot),
      select(activeSnapshot => activeSnapshot && activeSnapshot.feature_importance),
      filter(isDefined),
      map(rows => _map(rows.segment_id_scores, "segment_identifier")),
      switchMap(segmentIds => this.segmentService.fetchSegmentsV3({identifiers: segmentIds, id_space: "metagraph", use_cases: ["insights"]}),
      ),
      untilDestroyed(this),
    ).subscribe(segments => {
      this.segments = keyBy(segments, "identifier")
    });

    this.store.select('segmentsHierarchy', 'vendorDisplayNames').pipe(
      untilDestroyed(this)
    ).subscribe(vendorDisplayNames => this.vendorDisplayNames = vendorDisplayNames);
  }

  ngOnDestroy() { }

  getName(row: FeatureImportanceEntry): string {
    return get(this.segments, [row.segment_identifier, "name"], "") as string;
  }

  displayPath(segmentId: string): string {
    if (!this.segments[segmentId]) {return null; }
    return prettyPathParts(this.segments[segmentId], this.vendorDisplayNames).join(" > ");
  }

  tableSortChanged(event) {
    this.tableSorts = event.sorts;
  }

  unsortBySubMarket(subMarket: SubMarket) {
    if (find(this.tableSorts, {prop: `label_scores.${subMarket.id}`})) {
      this.tableSorts = [];
    }
  }

  _nameComparator(blank1: "", blank2: "", row1: FeatureImportanceEntry, row2: FeatureImportanceEntry) {
    return this.getName(row1).localeCompare(this.getName(row2))
  }

  bubbleScore(row: FeatureImportanceEntry, subMarket: SubMarket): number {
    const score = this.rowScore(row, subMarket)
    let bubbleScore = .033
    if (score >= this.max) {
      bubbleScore = 1;
    } else if (score <= this.red) {
      bubbleScore = (score - this.red) / (0 - this.red)
    } else if (score >= this.green) {
      bubbleScore = (score - this.green) / (this.max - this.green)
    }

    return bubbleScore >= .033 ? bubbleScore : .033;
  }

  rowScore(row: FeatureImportanceEntry, subMarket: SubMarket): number {
    return row.label_scores[subMarket.id];
  }

  get visibleRows() {
    return _filter(this.featureImportanceRows, row => this.segments[row.segment_identifier]);
  }

  get visibleSubMarkets() {
    return _filter(this.subMarkets, {visible: true});
  }

  get rowLimit() {
    return Math.floor((this.table.element.clientHeight - 66) / 33);
  }

  get factor(): number {
    return 0.005
  }

  get N(): number {
    return this.subMarkets.length;
  }

  get green(): number {
    return (1 / this.N) + this.factor
  }

  get red(): number {
    return (1 / this.N) - this.factor
  }

  get max(): number {
    return (1 / this.N) * 2;
  }


}
