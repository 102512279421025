import { Action } from "@ngrx/store";
import { LookalikeV2 } from "app/lookalikes-v2/lookalike-v2.model";

export class CreateLookalike implements Action {
  static readonly type = 'CREATE_LOOKALIKE';
  readonly type = CreateLookalike.type;

  constructor(public lookalike: LookalikeV2) { }
}

export class CreateLookalikeFailure implements Action {
  static readonly type = 'CREATE_LOOKALIKE_FAILURE';
  readonly type = CreateLookalikeFailure.type;

  constructor(public error) { }
}

export class FetchLookalikes implements Action {
  static readonly type = 'FETCH_LOOKALIKES';
  readonly type = FetchLookalikes.type;

  constructor() {}
}

export class LoadLookalike implements Action {
  static readonly type = 'LOAD_LOOKALIKE';
  readonly type = LoadLookalike.type;

  constructor(public lookalike: LookalikeV2) { }
}

export class LoadLookalikes implements Action {
  static readonly type = 'LOAD_LOOKALIKES';
  readonly type = LoadLookalikes.type;

  constructor(public lookalikes: LookalikeV2[]) {}
}

export class ClearLookalikes implements Action {
  static readonly type = 'CLEAR_LOOKALIKES';
  readonly type = ClearLookalikes.type;
}

export class UpdateLookalike implements Action {
  static readonly type = 'UPDATE_LOOKALIKE';
  readonly type = UpdateLookalike.type;

  constructor(public lookalike: LookalikeV2) {}
}

export class LoadUpdatedLookalike implements Action {
  static readonly type = 'LOAD_UPDATED_LOOKALIKE';
  readonly type = LoadUpdatedLookalike.type;

  constructor(public lookalike: LookalikeV2) {}
}

export class DestroyLookalike implements Action {
  static readonly type = 'DESTROY_LOOKALIKE';
  readonly type = DestroyLookalike.type;

  constructor(public lookalike: LookalikeV2) {}
}

export class RemoveLookalike implements Action {
  static readonly type = 'REMOVE_LOOKALIKE';
  readonly type = RemoveLookalike.type;

  constructor(public data) {}
}

export type All = FetchLookalikes | LoadLookalikes | ClearLookalikes | CreateLookalike
| CreateLookalikeFailure | LoadLookalike | LoadUpdatedLookalike
| DestroyLookalike | RemoveLookalike;
