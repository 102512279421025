import {Injectable} from "@angular/core";

declare let document: any;
@Injectable()
export class ScriptService {

  // private scripts: any = {};

  private scripts = {
    'datadogLogs': {
      src: 'https://www.datadoghq-browser-agent.com/datadog-logs-us.js',
      loaded: false
    }
  };

  constructor() {}

  load(...scripts: string[]) {
    return Promise.all(
      scripts.map((script) => this.loadScript(script))
    );
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name]) {
        throw `Failed to load script "${name}" as its not defined in ScriptsToLoad constant. `
      }
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  // IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {  // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => reject({script: name, loaded: false, status: 'Error'});
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
