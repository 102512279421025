import { Pipe, PipeTransform } from '@angular/core';
import { RequiredPermissions } from 'app/authorization/permissions/permissions.reducers';
import {PermissionsService} from 'app/services/permissions.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'hasPermission'
})
export class HasPermissionPipe implements PipeTransform {

  constructor(private permissionsService: PermissionsService) {}

  transform(value: RequiredPermissions): Observable<boolean> {
    return this.permissionsService.canAccess(value);
  }

}
