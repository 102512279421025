<div class="destinations-admin-container">
  <div class="destinations-admin destinations-admin-left">
    <div (click)="returnToAdmin()" >Admin</div>

    <div class="partner-destination-wrapper">
      <div class="page-title">
        <h1 class="partner-destination-header">Partner Destination</h1>
      </div>

      <div class="partner-source">
        <span>In order to send a partner, the first step is to setup a destination. To setup and send an audience, choose from a Frequently used partners below. More partners coming soon!</span>
      </div>

      <mat-label>Destination Setup Level</mat-label>
      <div>
        <span>
          <mat-form-field appearance="outline">
            <span>Client</span>
            <mat-select [(ngModel)]="clientSlug">
              <mat-option *ngFor="let client of allClients" [value]="client.slug" (click)="setAllRegions()">
                {{client.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span>
          <mat-form-field *ngIf="allRegions?.length > 0" appearance="outline">
            <span>Regions</span>
            <mat-select [(ngModel)]="regionSlug">
              <mat-option *ngFor="let region of allRegions" [value]="region.slug" (click)="setAllBrands()">
                {{region.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span>
          <mat-form-field *ngIf="allBrands?.length > 0" appearance="outline">
            <span>Brand</span>
            <mat-select [(ngModel)]="brandSlug">
              <mat-option *ngFor="let brand of allBrands" [value]="brand.slug" (click)="setAllProducts()">
                {{brand.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span>
          <mat-form-field *ngIf="allProducts?.length > 0" appearance="outline">
            <span>Products</span>
            <mat-select [(ngModel)]="productSlug">
              <mat-option *ngFor="let product of allProducts" [value]="product.slug" (click)="setProduct()">
                {{product.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>

      <div class="section-header">
        <h4 class="partner-list-title">Frequently Used Partners</h4>
      </div>

      <div class="tiles-container">
        <activation-tile
          *ngFor="let partner of weightedPartners"
          [partner]="partner"
          (click)="toggleAddDestinationOverlay(partner)"
          (addDestinationEvent)="addDestination(partner)"
        >
        </activation-tile>
      </div>

      <div class="section-header">
        <h4 class="partner-list-title">Other Partner Search</h4>
      </div>

      <div class="search-container">
        <span>Start typing a partner name:</span>

        <div class="partner-search">
          <div class="ppc-searchable-dropdown-search-box">
            <ppc-input #search [(ngModel)]="searchInput"
                       [noLabel]="true"
                       [noErrorContainer]="true"
                       (focusin)="searchOn()"
            >
              <div prefix>
                <i class="fa fa-search"></i>
              </div>
              <div suffix [class.hide]="!searchInput"
                   (click)="searchInput = '';search.focus()">
                <i class="fa fa-close"></i>
              </div>
              <input type="text" />
            </ppc-input>
          </div>
          <div class="results-container" *ngIf="isSearching">
            <div class="ppc-searchable-dropdown-error" *ngIf="filteredItems.length == 0">
              <span class="list-item">No Matches!</span>
            </div>
            <ul class="ppc-searchable-dropdown-items" *ngIf="filteredItems.length > 0">
              <li *ngFor="let item of filteredItems">
                <div class="list-item" (click)="selectPartner(item)">
                  <span>{{item.name}}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="setup-button-container" *ngIf="!!currentPartner">
          <a class="btn button" (click)="setupPartner()">Set up selected</a>
        </div>
      </div>
    </div>

  </div>

  <div class="destinations-admin destinations-admin-right">
    <div class="instruction-box">
      <h3>Instructions for Destinations Setup</h3>
      <p>You may setup destinations for audiences to be created in multiple brands, products, and/or projects at once.</p>
      <p>Start with selecting a Partner on the left, then select a directory for a new destination,
        either Client, Brand or Product level to share a new destination. Choose Client level if you'd like to create this new destination throughout all Brands and Products or limit the scope by choosing the Brand or lower directory.</p>
      <p>New Destinations usually will take 24-48 hrs to setup and are available once they turn green.</p>
    </div>
  </div>

</div>

<app-slide-in-overlay [percentOfParentCovered]="50" #activationDestinationsOverlay>
  <ppc-activation-destinations-overlay
    [isAdmin]="true"
    [clientSlug]="clientSlug"
    [regionSlug]="regionSlug"
    [brandSlug]="brandSlug"
    [productSlug]="productSlug"
    [partner]="currentPartner"
    (onSendObjectToPartner)="sendObjectToDestinations($event)"
    (onRedirectToNewDestination)="closeDestinationsOpenAddDestination($event)"
    (onClose)="activationDestinationsOverlay.toggleState()"
    (reopenSendToPartnerOverlay)="reopenPartnerOverlay()"
  >
  </ppc-activation-destinations-overlay>
</app-slide-in-overlay>

<app-slide-in-overlay [percentOfParentCovered]="50" #addDestinationOverlay>
  <ppc-add-destination
    [partner]="currentPartner"
    [clientSlug]="clientSlug"
    [regionSlug]="regionSlug"
    [brandSlug]="brandSlug"
    [productSlug]="productSlug"
    (partnerFetchFromStore)="partnerFetchFromStore()"
    (onClose)="addDestinationOverlay.toggleState()"
    (openDestinations)="openDestinations($event)"
    #addDestinationMenu
  >
  </ppc-add-destination>
</app-slide-in-overlay>
