import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { clamp } from 'lodash';
import * as d3 from "d3";
import { toMillions } from "app/insights/insights.models";

@Component({
  selector: 'propensity-overview-graph',
  templateUrl: './propensity-overview-graph.component.html',
  styleUrls: ['./propensity-overview-graph.component.sass']
})

export class PropensityOverviewGraphComponent implements AfterViewInit {
  @ViewChild('svg', { static: true }) _svg: ElementRef<HTMLElement>;
  @ViewChild('clipPath', { static: true }) _clipPath: ElementRef<HTMLElement>;

  // In the future, this could theoretically be device counts or cookies
  current_field: string = 'people';
  y;
  x;
  data: {confidence: number, [field: string]: number}[];

  presets = {
    2: { range: [50, 100], tickValues: [55, 70, 85, 100], slicer: 49 },
    3: { range: [33, 100], tickValues: [40, 60, 80, 100], slicer: 32 },
    4: { range: [25, 100], tickValues: [30, 50, 75, 100], slicer: 24 },
    5: { range: [20, 100], tickValues: [25, 50, 75, 100], slicer: 19 },
  }

  @Input() confidenceSizes: any;
  @Input() id: string;
  @Input() audienceCount: number;
  @Input() confidence: number;
  @Output() updateCountAndConfidence = new EventEmitter<any>();
  @Output() saveCurrentConfidence = new EventEmitter<null>();

  ngAfterViewInit(): void {
    this.data = this.convertGraphData(this.current_field);
    this.drawGraph();
  }

  drawGraph(): void {
    const margin = {top: 0, right: 20, bottom: 20, left: 35},
      width = +this.svg.attr("width") - margin.left - margin.right,
      height = +this.svg.attr("height") - margin.top - margin.bottom,
      g = this.svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    this.x = d3.scaleLinear()
      .rangeRound([0, width]);

    this.y = d3.scaleLinear()
      .rangeRound([height, 0]);
    const area = d3.area()
      .x((d) => { return this.x(d.confidence) })
      .y1((d) => { return this.y(d[this.current_field]) })
      .y0(this.y(0));


    const yTop = Math.round(this.data[0].people + (this.data[0].people / 15))
    const yBottom = Math.round(this.data[this.data.length - 1].people - (this.data[this.data.length - 1].people / 15))

    this.x.domain(this.presets[this.audienceCount].range);
    this.y.domain([yBottom, yTop]);

    // fixed graph
    g.append("path")
      .datum(this.data)
      .attr("fill", "rgba(126,196,202, 0.4)")
      .attr("stroke-width", "3")
      .attr("d", area);

    // dynamic graph overlay
    const myClipId = `myClip-${this.id}`
    g.append("path")
      .datum(this.data)
      .attr("fill", "rgba(126,196,202, 1)")
      .attr("clip-path", `url(#${myClipId})`)
      .attr("d", area);

    // invisible rect used to size the dynamic graph overlay
    this.clipPath
      .attr("id", myClipId)
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.x(this.confidence))
      .attr("height", 500);

    // x axis text
    g.append("g")
      .attr("id", "x-axis")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(this.x).tickValues(this.presets[this.audienceCount].tickValues).tickSize(-7).tickFormat(d => `${d}%`))

    const adder = Math.round((yTop - yBottom) / 4)
    const yTickValues = [yBottom, yBottom + adder, yBottom + (adder * 2), yTop - adder, yTop]

    // y axis text
    g.append("g")
      .attr("id", "y-axis")
      .call(d3.axisLeft(this.y).tickValues(yTickValues).tickSize(-width).tickFormat(d => toMillions(d, 1)))

    const slider = g.append("g");

    const dragStart = this.presets[this.audienceCount].range[0]
    const graph = this;

    slider
      .style("transform", `translateX(${this.x(this.confidence)}px)`)
      .call(d3.drag()
        .on('drag', function() {
          const parent = d3.select(this);
          const circle = d3.select(this).select('circle');
          const newX = +parent.style("transform").match(/\-?\d+/)[0] + d3.event.dx;
          const newConfidence = clamp(graph.x.invert(newX), dragStart, 100);

          circle.attr('cy', graph.getYByConfidence(newConfidence));
          parent.style("transform", `translateX(${graph.x(newConfidence)}px)`)
          graph.updateGraph(newConfidence)
        })
        .on('end', function(d) {
          graph.saveCurrentConfidence.emit();
        }))

    slider
      .append("line")
      .attr("x1", 0)
      .attr("x2", 0)
      .attr("y1", 0)
      .attr("y2", height)
      .attr("stroke", "#FFFFFF")
      .attr("stroke-dasharray", 6)
      .style("padding", "0 3px")

    slider
      .append("circle")
      .attr("fill", "#FFFFFF")
      .attr("r", 10)
      .attr("cy", this.getYByConfidence(this.confidence))
      .style("z-index", 10)
      .style("cursor", "grab")

    this.updateGraph(this.confidence);
  }

  getYByConfidence(confidence) {
    const bisectData = d3.bisector((d) => d.confidence).left;
    const index = bisectData(this.data, confidence);
    return this.y(this.roundValue(index, confidence).people);

  }

  updateGraph(confidence): void {
    const bisectData = d3.bisector((d) => d.confidence).left;
    const myClipid = `#myClip-${this.id} rect`
    const myClipRect = d3.select(myClipid);

    const index = bisectData(this.data, confidence);

    const currentValue = this.roundValue(index, confidence);

    const xValue = this.x(currentValue.confidence);
    myClipRect.attr("width", xValue);

    this.updateMetrics(currentValue);
  }

  private convertGraphData(field): {confidence: number, [field: string]: number}[] {
    const confidences = Object.keys(this.confidenceSizes).slice(this.presets[this.audienceCount].slicer).map(key => parseInt(key));
    return confidences
      .map(confidence => ({confidence: confidence, [field]: this.confidenceSizes[confidence][field]}))
      .sort((a, b) => a.confidence - b.confidence)
  }

  private roundValue(index: number, newConfidence: number): {confidence: number, [field: string]: number} {
    const datum1 = this.data[index - 1] || this.data[index];
    const datum2 = this.data[index];
    return newConfidence - datum1.confidence > datum2.confidence - newConfidence ? datum2 : datum1;
  }

  updateMetrics(d): void {
    this.updateCountAndConfidence.emit(d);
  }

  get svg() {
    return d3.select(this._svg.nativeElement);
  }

  get clipPath() {
    return d3.select(this._clipPath.nativeElement);
  }
}

