import {map, switchMap, filter, tap} from 'rxjs/operators';
import {activeContext} from './../hierarchy/hierarchy.reducers';
import {featuresForClientRegion} from 'app/feature-access/feature-access.reducers';
import { Category } from 'app/toolbox/category.interface';
import { Injectable } from '@angular/core';
import { PpcHttpService, apiUrl } from '../services/ppc_http.service';
import { Observable, combineLatest } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { fetchResource } from 'app/shared/utils/fetch-state';
import {
  LoadUnit,
  LoadUnits,
  FetchAllUnits,
  LoadCategory,
  LoadCategories,
  FetchAllCategories,
  CreateUnit,
  UpdateUnit,
  DestroyUnit,
  HideUnit,
  CreateCategory,
  UpdateCategory,
  UpdateCategoryPriorities,
  DestroyCategory, ResetToolbox
} from 'app/toolbox/toolbox.actions';
import { Unit } from 'app/toolbox/unit.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Store, select} from '@ngrx/store';
import {AppState} from 'app/reducers/index';
import {find} from 'lodash';
import {UrlService} from 'app/services/url.service'
import {isLoaded} from "app/shared/utils/fetch-state";
import {toProperty} from 'app/shared/utils/utils'

@Injectable()
export class ToolboxService {

  constructor(
    private http: PpcHttpService,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private store: Store<AppState>,
    private urlService: UrlService
  ) {}

  loadedUnits$ = this.store.select('fetchStates', FetchAllUnits.type).pipe(
    select(isLoaded),
    filter(Boolean),
    switchMap(() => this.store.select('toolbox', 'units')),
    toProperty())

  iFramedUnit$ = combineLatest(
    this.urlService.currentUrl$.pipe(map(url => url.includes('/toolbox-app/'))),
    this.urlService.pathParams$.pipe(map(params => params['unitName'])),
    this.loadedUnits$
  ).pipe(
    map(([ onIFramePage, unitName, units ]) => onIFramePage ? find(units, {name: unitName})
      : null),
    toProperty())

  unitsURL(params): string {
    return apiUrl(`/toolbox/units/${params.id || ''}`);
  }

  categoriesURL(params): string {
    return apiUrl(`/toolbox/categories/${params.id || ''}`);
  }

  bulkCategoriesURL(params): string {
    return apiUrl(`/toolbox/categories/${params.id}/bulk`);
  }

  unitTypesByClientURL(type: string, clientSlug: string): string {
    return apiUrl(`/toolbox/units/type/${type}/client/${clientSlug}`);
  }

  categoriesByClientURL(clientSlug: string): string {
    return apiUrl(`/toolbox/categories/client/${clientSlug}`);
  }

  @Effect()
  fetchUnits$ = this.actions$.pipe(
    ofType(FetchAllUnits.type),
    (fetchResource(
      (action: any) => this.getUnitTypesByClient(action.params).pipe(map(units => new LoadUnits(units)))
    )));

  @Effect()
  fetchCategories$ = this.actions$.pipe(
    ofType(FetchAllCategories.type),
    (fetchResource(
      (action: any) => this.getCategories(action.params).pipe(map(categories => new LoadCategories(<any>categories)))
    )));

  @Effect()
  createUnit$ = this.actions$.pipe(
    ofType(CreateUnit.type),
    (
      fetchResource(
        action => this.createUnit(action.unit).pipe(
          map(unit => new LoadUnit(unit)))
      )
    ));

  @Effect()
  updateUnit$ = this.actions$.pipe(
    ofType(UpdateUnit.type),
    (
      fetchResource(
        action => this.updateUnit(action.unit)
        , false)
    ));

  @Effect()
  destroyUnit$ = this.actions$.pipe(
    ofType(DestroyUnit.type),
    (
      fetchResource(
        action => this.destroyUnit(action.unit)
      )
    ));

  @Effect()
  hideUnit$ = this.actions$.pipe(
    ofType(HideUnit.type),
    (
      fetchResource(
        action => this.hideUnit(action.unit).pipe(
          map(unit => new LoadUnit(unit)))
      )
    ));

  @Effect()
  createCategory$ = this.actions$.pipe(
    ofType(CreateCategory.type),
    (
      fetchResource(
        action => this.createCategory(action.category).pipe(
          map(category => new LoadCategory(category)))
      )
    ));

  @Effect()
  updateCategory$ = this.actions$.pipe(
    ofType(UpdateCategory.type),
    (
      fetchResource(
        action => this.updateCategory(action.category)
      )
    ));

  @Effect()
  updateCategoryPriorities$ = this.actions$.pipe(
    ofType(UpdateCategoryPriorities.type),
    (
      fetchResource(
        action => this.updateCategoryPriorities(action.categories)
      )
    ))

  @Effect()
  destroyCategory$ = this.actions$.pipe(
    ofType(DestroyCategory.type),
    (
      fetchResource(
        action => this.destroyCategory(action.category)
      )
    ));

  @Effect()
  unitsForLeftNav$ = activeContext(this.store).pipe(
    filter(context => !!context.client && !!context.region),
    switchMap(({ client, region }) =>
      featuresForClientRegion(this.store, client.slug, region.slug).pipe(
        select(features => find(features, {category: 'toolbox'})),
        map(feature => feature ? new FetchAllUnits({clientSlug: client.slug, type: feature.name})
          : new ResetToolbox()))
    )
  )

  getCategories(params): Observable<Category[]> {
    return this.http.get(this.categoriesByClientURL(params.clientSlug));
  }

  getUnitTypesByClient(params): Observable<Unit[]> {
    return this.http.get(this.unitTypesByClientURL(params.type, params.clientSlug));
  }

  createUnit(unit): Observable<Unit> {
    delete unit.id
    unit.status = true;
    return this.http.post(this.unitsURL(unit), unit);
  }

  updateUnit(unit): Observable<Partial<Unit>> {
    return this.http.put(this.unitsURL(unit), unit);
  }

  destroyUnit(unit): Observable<Unit> {
    return this.http.delete(this.unitsURL(unit));
  }

  hideUnit(unit): Observable<Unit> {
    return this.http.put(this.unitsURL(unit), {status: false, feature_module_category: null, iframed: false});
  }

  createCategory(category): Observable<Category> {
    delete category.id
    category.status = true;
    return this.http.post(this.categoriesURL(category), category);
  }

  updateCategory(category): Observable<Category> {
    return this.http.put(this.categoriesURL(category), category);
  }

  updateCategoryPriorities(categories): Observable<Category[]> {
    let catId = null;
    const bulkCategories = categories.map(cat => {
      catId = cat.id;
      return {id: cat.id, priority: cat.priority};
    });
    return this.http.put(this.bulkCategoriesURL({id: catId}), bulkCategories);
  }

  destroyCategory(category): Observable<Category> {
    return this.http.delete(this.categoriesURL(category));
  }
}
