import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { ShareThisWidgetsComponent } from 'app/insights/insights-components/share-this/share-this-widgets.component';
import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';

@Component({
  selector: 'ppc-share-this-device',
  templateUrl: '../share-this-widgets.template.html',
  styleUrls: ['./share-this-device.component.sass']
})
export class ShareThisDeviceComponent extends ShareThisWidgetsComponent implements OnInit {

  constructor(public store: Store<AppState>, @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super("Device", store, insightsContext);
  }

  ngOnInit() {
  }

}
