import { InjectionToken } from "@angular/core";

// TODO: use following constants for insights context
export const INSIGHTS_CONTEXT_GROW = "grow";
export const INSIGHTS_CONTEXT_COMPARE = "compare";
export const INSIGHTS_CONTEXT_OVERLAP = "overlap";
export const INSIGHTS_CONTEXT_AUDIENCE_OVERVIEW = "audience-overview";
export const INSIGHTS_CONTEXT_JOURNEY = "journey";
export const INSIGHTS_CONTEXT_EXPLORE = "explore";
export const INSIGHTS_CONTEXT_OUTCOME_AUDIENCE = "outcome-audience";
export type InsightsContextType = "grow" | "compare" | "audience-overview" | "journey" | "explore" | "outcome-audience";

export const INSIGHTS_SUB_FEATURE_WEBORAMA = "weborama";

export const INSIGHTS_CONTEXTS: InsightsContextType[] = [INSIGHTS_CONTEXT_GROW, INSIGHTS_CONTEXT_COMPARE,
  INSIGHTS_CONTEXT_AUDIENCE_OVERVIEW, INSIGHTS_CONTEXT_JOURNEY, INSIGHTS_CONTEXT_EXPLORE, INSIGHTS_CONTEXT_OUTCOME_AUDIENCE];
export const CONTEXT_WITH_CUSTOM_TABS = [INSIGHTS_CONTEXT_GROW, INSIGHTS_CONTEXT_JOURNEY, INSIGHTS_CONTEXT_EXPLORE,
  INSIGHTS_CONTEXT_OUTCOME_AUDIENCE];
export const COUNT_TYPE_MATCHED = "matched";
export const COUNT_TYPE_MODELED = "modeled";
export const COUNT_TYPE_INDEX = "index";
export const UI_INSIGHTS_CONTEXT = {
  'compare': INSIGHTS_CONTEXT_OVERLAP
};

export interface AndAggregation {
  filter: {
    term: {
      segments: string | string[];
    }
  }
}

export interface OrAggregation {
  filter: {
    bool: {
      should: {
        term: {
          segments: string;
        }
      }[]
    }
  }
}

export type Aggregation = AndAggregation | OrAggregation;

export interface SegmentContexts {
  primary?: SegmentContext | MoleculaSegmentContext | JourneyMoleculaSegmentContext;
  secondary?: {[contextIdentifier: string]: SegmentContext | MoleculaSegmentContext | JourneyMoleculaSegmentContext};
  aggregations?: {[aggregationIdentifier: string]: Aggregation};
}

export interface SegmentContext {
  or?: ContextGroup[];
  isModeled?: boolean;
  aggregations?: {[aggregationIdentifier: string]: Aggregation};
  field?: string;
  isNestedQuery?: boolean;
  compareContext?: SegmentContexts[];
}

export interface MoleculaSegmentContext {
  context?: {
    included?: string[][],
    excluded?: string[][],
    filters?: string[][],
    key?: string,
    useAnds?: boolean,
  };
  serviceType?: string;
  isVendorQuery?: boolean;
  includeIndexValues?: boolean;
  indexBase?: string;
  identifiers?: { [id: number]: any }[];
  compareContext?: SegmentContexts[];
  allowIdentifiers?: boolean;
}

export interface JourneyMoleculaSegmentContext {
  rootKey: string;
  contexts: MoleculaSegmentContext[];
  compareContext?: SegmentContexts[];
}

export interface ContextGroup {
  include: string[][];
  exclude?: string[];
}

export enum PercentCalculationType {
  WIDGET = "widget",
  INSIGHTS_CONTEXT = "selected"
}

export const INSIGHTS_CONTEXT = new InjectionToken<string>('InsightsContext');

export type ResourceType = "Mekko" | "Comparison" | "Journey" | "Persona" | "Outcome Audience" | null;

export function getUiInsightContext(contextType: InsightsContextType) {
  const uiType = UI_INSIGHTS_CONTEXT[contextType];
  if (uiType) {
    return uiType;
  }

  return contextType;
}

// Example context = {
//     and: [
//       {abc: {
//         include: [["abc"]],
//         exclude: ["def", "ghb"]
//       }},
//       {def: {
//         include: [["def"]],
//         exclude: ["ghb"],
//       }},
//       {ghb: {
//         include: [["ghb"]]
//       }}
//     ],
//     field: "segments"
// }
