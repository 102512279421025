
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { compact, find, map as _map, flatten, flatMap, get, filter } from "lodash";
import { leaves } from 'app/shared/utils/utils';
import { Store } from "@ngrx/store";
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AppState } from "app/reducers";
import { fullContext } from "app/hierarchy/hierarchy.reducers";
import { Node } from 'app/segment-picker/root-nodes/root-node.interface';
import { LocalStorageService } from 'app/services/local-storage.service';
import * as insightsActions from 'app/insights/insights.actions';
import {INSIGHTS_CONTEXT, InsightsContextType} from 'app/insights/insights.constants';
import {InsightsCountService} from "app/insights/insights-count.service";
import {PERMISSION_INSIGHTS} from "../../../shared/utils/constants";
import {getSegmentsHierarchyChildren, SegmentHierarchy} from "../../../segment-picker/segment-picker.utils";

const GWI_WIDGET_SHOW_COUNTS_KEY = "grow-gwi-widget-show-counts";

@Component({
  selector: 'ppc-gwi-widget',
  templateUrl: './gwi-widget.component.html',
  styleUrls: ['./gwi-widget.component.sass']
})
export class GwiWidgetComponent implements OnInit, OnDestroy {

  categoryLevels: Node[][] = [];
  activeCategory: Node;
  questions: Node[];
  breadcrumbs: string[] = ["Root"];
  showCounts = this.localStorage.getValue(GWI_WIDGET_SHOW_COUNTS_KEY) || false;
  gwiSegmentHierarchy: SegmentHierarchy = {node_name: 'GWI', identifier: 'ppc_gwi_hierarchy',
    parent_id_for_query: '', hierarchy_leaf: false, vendor_name: 'ppc_hierarchy', vendor_type: 'gwi'};

  private ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(public http: HttpClient,
    private sanitizer: DomSanitizer,
    private counts: InsightsCountService,
    private store: Store<AppState>,
    private localStorage: LocalStorageService,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) { }

  ngOnInit() {
    fullContext(this.store).pipe(
      takeUntil(this.ngUnsubscribe),
      distinctUntilChanged(), )
      .subscribe(({client, region}) => {
        getSegmentsHierarchyChildren(this.gwiSegmentHierarchy, this.http, [PERMISSION_INSIGHTS], region.slug)
          .subscribe(firstLevelGwiSegmentHierarchies => {this.categoryLevels = [firstLevelGwiSegmentHierarchies]})
        this.breadcrumbs = ["Root"]
        this.questions = null;
      })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  clearAllFilters() {
    filter(this.counts.filters, { type: "GWI" })
      .forEach(filter => this.store.dispatch(new insightsActions.ToggleFilter(filter, this.insightsContext)));
  }

  clearFilters(category: Node) {
    return leaves(category).forEach(
      seg => {
        const filter = find(this.counts.filters, {shortId: seg.identifier});
        if (filter) {this.store.dispatch(new insightsActions.ToggleFilter(filter, this.insightsContext))}
      }
    );
  }

  setActiveGWIQuestions() {
    this.store.dispatch(new insightsActions.SetActiveGWIQuestions(this.flatQuestions, this.insightsContext))
  }

  isChildSelected(segment: Node) {
    return leaves(segment).some(seg => this.isGWISegmentSelected(seg));
  }

  isGWISegmentSelected(node: Node): boolean {
    return this.counts.hasFilter({ shortId: node.identifier })
  }

  removeGWISegment(shortId: string) {
    this.store.dispatch(new insightsActions.ToggleFilter(find(this.counts.filters, { shortId }), this.insightsContext))
  }

  setActiveCategory(segment: Node) {
    this.activeCategory = segment;
    if (!this.activeCategory.children) {
      this.activeCategory.children$.subscribe(children => {
        segment.children = children;
        this.onLevelChange(segment);
      })
    } else {
      this.onLevelChange(segment)
    }
    if (this.activeGWIResponses.length) {
      this.setActiveGWIQuestions();
    }
  }

  onLevelChange(segment: Node) {
    if (segment.children.some(s => s.has_leaf_children) && segment.children.some(s => !s.has_leaf_children)) {
      this.questions = segment.children.filter(s => s.has_leaf_children);
      this.questions = this.questions.concat(segment.children.filter(s => !s.has_leaf_children))
    } else {
      this.questions = segment.children.filter(s => s.has_leaf_children);
      if (!this.questions.length) {
        this.breadcrumbs.push(segment.name);
      }
      const newCategories = segment.children.filter(s => !s.has_leaf_children);
      if (newCategories.length) {
        this.categoryLevels.push(newCategories)
      }
    }
  }

  goTo(newLength) {
    this.categoryLevels = this.categoryLevels.slice(0, newLength );
    this.breadcrumbs = this.breadcrumbs.slice(0, newLength);
    this.questions = null;
  }

  setShowCountsPreference() {
    this.localStorage.setValue(GWI_WIDGET_SHOW_COUNTS_KEY, this.showCounts);
  }

  get levelsTransform(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`translateX(-${(this.categoryLevels.length - 1) / (this.categoryLevels.length) * 100 }%)`)
  }

  getIdentifiersForChildren(segment: Node): string[] {
    return  _map(leaves(segment).filter(s => get(s, ['path', 'is_leaf'])), 'identifier');
  }

  get activeGWIResponses() {
    return compact(flatten(this.getIdentifiersForChildren(this.activeCategory)));
  }

  get flatQuestions(): Node[] {
    return compact(flatMap(this.questions, getQuestions))
  }
}

function getQuestions(segment: Node) {
  if (segment.has_leaf_children) {
    return segment
  } else {
    return compact(flatMap(segment.children, getQuestions))
  }
}
