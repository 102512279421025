import { Injectable, OnDestroy } from '@angular/core';
import { BuilderAudience } from './audience-builder.models';
import { Store, select } from "@ngrx/store";
import { Subject, Observable, of as observableOf, BehaviorSubject, combineLatest as observableCombineLatest } from 'rxjs';
import { distinctUntilChanged, map, switchMap, debounceTime, tap, pluck, combineLatest, filter, take, publishReplay, refCount } from 'rxjs/operators';
import { isEqual, cloneDeep, get, map as _map, reject, find, uniq } from 'lodash';
import { customGWISurveySegments, getCustomSurveyRoot, includesAtLeastOneSegment, rulesToQuery, newAudience, buildBuilderAudienceContext } from './audience-builder.utils';
import { HttpClient } from '@angular/common/http';
import { AppState } from 'app/reducers';
import { fullContext } from "app/hierarchy/hierarchy.reducers";
import { buildAudiencePrefix, AudienceV2, isAudienceCloneableOnly } from 'app/audiences-v2/audience-v2.model';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectAudience, selectAudiences } from '../audiences-v2/audience-v2.reducers';
import { AudienceMapper } from './audience-mappers/audience-mapper';
import { undoableScan } from '../shared/utils/undoable';
import { FetchOverviewAudiences } from '../audiences-v2/audience-v2.actions';
import { isLoaded } from '../shared/utils/fetch-state';
import { SetPrebuiltAudience } from './audience-builder.actions';
import { PersonaBuilderService } from 'app/explore/persona-builder.service';
import { MOLECULA, PERMISSION_CREATE_AUDIENCE_SINGLE } from '../shared/utils/constants';
import { segmentCountsUrl, segmentCountsV3Url } from '../shared/constants/id_analytics.urls';
import { dataServiceType } from 'app/shared/utils/utils';

export const MAX_AUDIENCE_NAME_LENGTH = 256;

@UntilDestroy()
@Injectable()
export class AudienceBuilderService implements OnDestroy {

  public prefix: string;
  public audience: BuilderAudience;
  params: Params;

  audience$: Observable<BuilderAudience>;
  audienceName$: Observable<string>;
  hasName$: Observable<boolean>;
  hasUniqueName$: Observable<boolean>;
  isNameWithinCharacterLimit$: Observable<boolean>;
  hasMultiCustomGWISurveySegments$: Observable<boolean>;
  count$: Observable<number>;
  loading$ = new BehaviorSubject<boolean>(false);
  otherAudiences: AudienceV2[];
  canUndo$: Observable<boolean>;
  canRedo$: Observable<boolean>;
  undo$ = new Subject();
  redo$ = new Subject();
  hasChanges$: Observable<boolean>;
  useCase$: Observable<string>;
  audienceCloned: boolean;
  private checkAudienceChanges$ = new Subject();
  private audienceHistory$;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    public personaService: PersonaBuilderService,
    private audienceMapper: AudienceMapper
  ) {
    route.queryParams.subscribe(value => {
      this.audienceCloned = value.audienceCloned;
    });
    this.audience$ = fullContext(store).pipe(
      tap(context => this.prefix = `${buildAudiencePrefix(context)}_`),
      switchMap(context => {
        return observableCombineLatest(
          route.params.pipe(
            tap(params => this.params = params),
            map(({id}) => +id)
          ),
          this.store.select("audienceBuilder", "prebuiltAudience"),
          this.personaService.isPersonaAudience$,
          this.personaService.audience$,
          this.store.select("fetchStates", FetchOverviewAudiences.type).pipe(
            select(isLoaded),
            filter(Boolean)
          ),
        ).pipe(
          switchMap(([id, prebuiltAudience, isPersonaAudience, personaBuilderAudience]) => this.store.select('audiencesV2')
            .pipe(
              select(selectAudience(id)),
              switchMap(audience => {
                if (isPersonaAudience && personaBuilderAudience) {
                  return observableCombineLatest(observableOf(personaBuilderAudience));
                };
                if (prebuiltAudience) {
                  return observableCombineLatest(
                    observableOf({...prebuiltAudience, name: this.prefix}),
                  )
                }
                if (audience) {
                  const isClone = isAudienceCloneableOnly(audience);
                  return observableCombineLatest(
                    this.audienceMapper.fromJsonPersona(audience),
                    observableOf(isClone)
                  );
                } else {
                  return observableCombineLatest(
                    observableOf(newAudience(this.prefix)),
                  );
                }
              }),
              map(([audience, isClone]) => {
                if (this.audienceCloned) {
                  audience.name = audience.name + '_copy';
                } else if (isClone) {
                  audience.name = audience.name + '_copy';
                }
                return audience;
              })
            )
          ),
          combineLatest(
            this.store.select('audiencesV2').pipe(
              select(selectAudiences),
            )
          ),
          tap(([audienceUnderEdit, otherAudiences]) => {
            this.audience = audienceUnderEdit;

            this.audienceHistory$ = undoableScan(
              this.checkAudienceChanges$,
              (previous, current) => cloneDeep(current),
              cloneDeep(this.audience),
              this.undo$,
              this.redo$,
              {historySize: 20}
            )

            this.canUndo$ = this.audienceHistory$.pipe(
              pluck("past"),
              map((past: BuilderAudience[]) => !!past.length)
            )

            this.canRedo$ = this.audienceHistory$.pipe(
              pluck("future"),
              map((future: BuilderAudience[]) => !!future.length)
            )

            this.hasChanges$ = this.audienceHistory$.pipe(
              map(({past, present}) => !isEqual(past[0], present))
            )

            this.otherAudiences = reject(otherAudiences, {id: get(this.audience, "id")});

          }),
          switchMap(() => this.audienceHistory$.pipe(pluck("present")) as Observable<BuilderAudience>)
        )
      }),
      publishReplay(),
      refCount(),
    );

    this.hasChanges$ = observableCombineLatest(
      this.audience$.pipe(take(1)),
      this.audience$
    ).pipe(
      map(([initial, current]) => !isEqual(initial, current)),
    )

    this.audience$.pipe(map(cloneDeep), untilDestroyed(this)).subscribe(audience => this.audience = audience)

    observableCombineLatest(
      this.audience$.pipe(map(cloneDeep)),
      this.personaService.isPersonaAudience$.pipe(filter(Boolean)),
    ).pipe(
      filter(([audience, isPersonaAudience]: [BuilderAudience, boolean]) => audience && isPersonaAudience),
      untilDestroyed(this),
    ).subscribe(([audience]) => this.personaService.audienceBuilderAudience$.next(audience))

    this.useCase$ = this.personaService.isPersonaAudience$.pipe(
      // TODO: Update to use new Insights excluding Non-segments use case when available for Persona Builder
      map(isPersonaAudience => PERMISSION_CREATE_AUDIENCE_SINGLE)
    );

    this.count$ = this.audience$.pipe(
      map(({rules}) => cloneDeep(rules)),
      distinctUntilChanged((oldRules, newRules) => isEqual(rulesToQuery(oldRules), rulesToQuery(newRules))),
      debounceTime(500),
      switchMap(rules => {
        if (!includesAtLeastOneSegment(rules)) {
          return observableOf(0);
        }
        let query, payload, url;
        this.loading$.next(true);
        if (dataServiceType() === MOLECULA) {
          query = buildBuilderAudienceContext(rules);
          payload = query;
          url = segmentCountsV3Url();
        } else {
          query = rulesToQuery(rules);
          payload = {queries: {primary: query}};
          url = segmentCountsUrl();
        }
        return this.http.post(url, payload);
      }),
      map(response => {
        this.loading$.next(false);
        const result = get(response, ["primary", "total_count"])
        return typeof result === "number" ? result : 0
      }),
      publishReplay(),
      refCount(),
    );

    this.audienceName$ = this.audience$.pipe(
      map((audience) => audience.name),
    );

    this.hasName$ = this.audienceName$.pipe(
      map((audienceName) => audienceName.length > 0)
    );

    this.hasUniqueName$ = observableCombineLatest(
      this.audienceName$,
      this.personaService.isPersonaAudience$,
      this.personaService.isPersonaContext$
    ).pipe(
      map(([audienceName, isPersonaAudience, isPersonaContext]) => {
        if (isPersonaAudience) {
          return !isPersonaContext || !find(this.personaService.otherPersonas, persona => persona.name.trim().toLowerCase() === this.audience.name.trim().toLowerCase());
        } else {
          return !find(this.otherAudiences, audience => audience.name.trim().toLowerCase() === audienceName.trim().toLowerCase());
        }
      })
    );

    this.isNameWithinCharacterLimit$ = this.audienceName$.pipe(
      map((audienceName) => audienceName.trim().length && audienceName.length <= MAX_AUDIENCE_NAME_LENGTH)
    );

    this.hasMultiCustomGWISurveySegments$ = this.audience$.pipe(
      map(audience => {
        const customSurveySegments = customGWISurveySegments(audience);
        return uniq(_map(customSurveySegments, segment => getCustomSurveyRoot(segment))).length > 1;
      })
    );
  }

  ngOnDestroy() {
    this.store.dispatch(new SetPrebuiltAudience(null));
  }

  public checkChanges() {
    this.checkAudienceChanges$.next(this.audience);
  }

  public get hasUniqueName() {
    if (this.personaService.persona) {
      return this.personaService.hasUniqueName(this.audience);
    } else {
      return !find(this.otherAudiences, audience => audience.name.trim().toLowerCase() === this.audience.name.trim().toLowerCase())
    }
  }

  public get hasName() {
    return this.audience.name.trim().length > 0;
  }

  public get subject() {
    return this.personaService.subject || "Audience";
  }

  public get pageTitle() {
    return this.personaService.pageTitle || "Audience Builder";
  }

  public get estimatedPeopleCountTooltip() {
    return this.personaService.estimatedPeopleCountTooltip || "Audience counts will be estimates for the first 48 hours after creation";
  }

  public get placeholder() {
    return this.personaService.placeholder || this.prefix;
  }
}
