import { flatMap, keyBy, mapValues } from 'lodash';
import { SubMarket } from 'app/mekko/mekko.reducer';
import { JourneySubMarket } from 'app/journey/journey.models';

export interface DiscussionClusters {
  names: {[clusterId: string]: string};
  nodes: DiscussionSubcluster[];
  reachable_people: {[clusterId: string]: number};
  discussion_cluster_node_id: string;
}

export interface DiscussionClusterNode {
  id?: number;
  name: string;
  description?: string;
  updated_at?: string;
  file_uploaded_at?: string;
  discussion_clusters?: DiscussionCluster[];
  sub_markets?: SubMarket[];
  journey_sub_markets?: JourneySubMarket[];
}

export interface DiscussionCluster {
  id: number;
  name: string;
  reachable_people: number;
  discussion_cluster_node_id: string;
  nodes: DiscussionSubcluster[];
}

export interface DiscussionSubcluster {
  id: number;
  sz: number;
  ct: number;
  nm: string;
  discussion_cluster_id: string;
}

export function newDiscussionClusterNode(): DiscussionClusterNode {
  return {
    id: null,
    name: '',
    description: ''
  }
}

export function buildDiscussionClusters(discussionClusters: DiscussionCluster[]): DiscussionClusters {
  return {
    names: mapValues(keyBy(discussionClusters, "id"), "name"),
    nodes: flatMap(discussionClusters, "discussion_subclusters"),
    reachable_people: mapValues(keyBy(discussionClusters, "id"), "reachable_people"),
    discussion_cluster_node_id: discussionClusters.length ? discussionClusters[0].discussion_cluster_node_id : null
  }
}
