import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HierarchyClient } from 'app/hierarchy/hierarchy.interface';
import { PublicPermission } from './segment-permissions-v2.interface';
import { segmentPermissionsSetsV1Url, segmentPermissionsSetsV2Url } from 'app/shared/constants/segments.urls';


@Injectable()
export class SegmentPermissionsV2Service {
  constructor(private http: HttpClient) {}

  addClientToDataSource(client: HierarchyClient | PublicPermission, dataSource): Observable<any> {
    const params = {
      client_name: client.slug,
      vendor_name: dataSource.vendor_name,
      vendor_type: dataSource.vendor_type,
      segment_owner_name: dataSource.segment_owner_name
    };
    const path = '/assign';

    return this.http.post(segmentPermissionsSetsV1Url(path), params);
  }

  updateDataSource(dataSource, permissions): Observable<any> {
    const params = {
      vendor_name: dataSource.attributes.vendor_name,
      vendor_type: dataSource.attributes.vendor_type,
      segment_owner_name: dataSource.attributes.segment_owner_name,
      permissions: permissions
    };
    const path = '/update';

    return this.http.put(segmentPermissionsSetsV2Url(path), params);
  }
};
