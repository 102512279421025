import { InputValidator } from '../../shared/components/ppc-input/ppc-input.component';

export class CppValidator implements InputValidator {
  // HACK: store error state; ideally InputValidor would return a ValidationError object directly when validating
  private _errorMessage?: string = null;

  isValid(param) {
    if (param === null || isNaN(param) || param === '') {
      this._errorMessage = 'Please enter a number';
      return false;
    } else if (param == 0 || param < 0) {
      this._errorMessage = 'CPP must be greater than 0';
      return false;
    }
    return true;
  }

  get errorMessage() {
    return this._errorMessage;
  }
}
