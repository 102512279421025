import {get, head} from 'lodash';
import {interval as observableInterval, Subscription, Observable, Subject} from 'rxjs';

import {mergeMap, filter, takeUntil} from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from "@angular/core";
import {Router} from "@angular/router";

import {HierarchyService} from "app/services/hierarchy.service";
import * as d3 from "d3";
import {Channel} from "app/plans/tardiis-channel.model";
import {ChannelsService} from "app/plans/channels.service";
import {getScales, getYAxisWidth, Point} from "app/shared/utils/charts";
import {NAV_PLANS, PLANS_SUB_NAV_CHANNELS, PLANS_SUB_NAV_SCENARIOS} from "app/shared/utils/constants";
import { canAccessFeature } from 'app/feature-access/feature-access.reducers';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { isDefined } from 'app/shared/utils/utils';
import { userCan } from 'app/authorization/permissions/permissions.reducers';

@Component({
  selector: 'app-channel-summary-card',
  templateUrl: './channel-summary-card.component.html',
  styleUrls: ['./channel-summary-card.component.sass']
})
export class ChannelSummaryCardComponent implements OnChanges, AfterViewInit {

  @Input() channel: Channel;
  @Input() currencyCode: string;
  @Input() tardiisError;
  @Input() error;
  @Input() attachedScenarioId: string;

  @Output() editClicks = new EventEmitter<string>();
  @Output() deleteClicks = new EventEmitter<any>();
  @Output() channelUpdates = new EventEmitter<Channel>();

  channel$: Subscription;
  destroyed$ = new Subject();
  canAccessScenarios: boolean;
  primaryErrorMessage: string;
  showErrorBox: boolean = false;

  canDeleteChannel: boolean;
  HOURS_BEFORE_RUNNING_CHANNEL_CAN_BE_DELETED: number = 3;

  constructor(
    private ref: ElementRef,
    private hierarchyService: HierarchyService,
    private channelService: ChannelsService,
    private router: Router,
    private store: Store<AppState>,
  ) {
    canAccessFeature(this.store, 'scenarios').pipe(filter(isDefined), takeUntil(this.destroyed$))
      .subscribe(
        canAccess => this.canAccessScenarios = canAccess
      );
  }

  onSelectChannel(channel_id: string): void {
    this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_CHANNELS, channel_id]);
  }

  waitForChannelToBeAvailable() {
    this.channel$ && this.channel$.unsubscribe();

    let errorCount = 0;

    this.channel$ = observableInterval(15000).pipe(
      mergeMap(_ => this.channelService.getChannel(this.channel._id)))
      .subscribe(
        channel => {
          if (channel.status === "available") {
            this.channelUpdates.emit(channel);
            this.channel = channel;
            setTimeout(_ => this.drawLine());
            this.channel$.unsubscribe()
          }
        },
        err => {
          console.error(err);
          errorCount += 1;
          if (errorCount > 3) {this.channel$.unsubscribe()}
        }
      )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.channel) {
      const {status, id} = changes.channel.currentValue;
      if (status === "running") {this.waitForChannelToBeAvailable(); }
      if (status === "new")     {this.run(id); }
    }
    this.drawLine()
  }

  ngOnDestroy() {
    this.channel$ && this.channel$.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.setChannelDeleteFlag();
  }

  ngAfterViewInit() {
    this.drawLine()
  }

  edit(id: string) {
    this.editClicks.emit(id)
  }

  delete(id: string) {
    this.deleteClicks.emit(id);
  }

  run(id: string) {
    this.tardiisError = null;
    this.channelService.runProject(id)
      .subscribe(
        _ => this.waitForChannelToBeAvailable(),
        err => {
          this.tardiisError = err;
          const primaryErrorMessage = head(get(err.error, ['error_messages'], []));
          if (primaryErrorMessage === 'Cannot accept project with invalid or expired TARDIIS credentials.') {
            this.primaryErrorMessage = primaryErrorMessage;
            this.showErrorBox = true;
          }
        }
      )
  }

  closeErrorBox() {
    this.showErrorBox = false;
  }

  drawLine() {
    const selectedMetric = this.channel.hasBudgetInfo() ? 'budget' : 'trp';
    // Make sure container and data exist
    if (!this.channel.output) {return; }
    const {output: {points, pdr}} = this.channel;

    const graphContainer = this.ref.nativeElement.querySelector(`.graph-container`);
    if (!graphContainer) {return; }

    // Clear previous draing
    const svg = d3.select(`svg#graph-${this.channel._id}`);
    svg.selectAll("*").remove();

    // Prepare the data
    const lineData = points.map(p => ({x: p[selectedMetric], y: p.reach}));

    // Other variables
    const {width} = graphContainer.getBoundingClientRect();
    const color = '#4898c8';

    // Build yAxis and yScale first because xAxis will need to be pushed over by the width of the xAxis
    const {yScale} = getScales(lineData, graphContainer, {yMax: 100});
    const yAxis = d3.axisLeft(yScale)
      .tickValues([25, 50, 75, 100])
      .tickFormat(x => x + "%");

    const yAxisSvg: d3.Selection<SVGGraphicsElement> = svg.append("g")
      .attr("class", "axis y-axis")
      .call(yAxis);

    const yAxisWidth = getYAxisWidth(yAxisSvg);
    yAxisSvg.attr('transform', `translate(${yAxisWidth}, 0)`);
    yAxisSvg.selectAll('line').attr('x2', width - yAxisWidth);
    yAxisSvg.selectAll('text').attr('x', 0);

    // Now build the xAxis and xScale using the yAxisWidth as input
    const {xScale} = getScales(lineData, graphContainer, {yMax: 100, leftPadding: yAxisWidth});
    const xAxis = d3.axisBottom(xScale)
      .tickSize(0)
      .ticks(5)
      .tickFormat(d3.format(',.2s'));

    svg.append("g")
      .attr("class", "axis xAxis")
      .attr('transform', `translate(0, ${yScale(0)})`)
      .call(xAxis);

    // Draw a line and the area under it
    const line = d3.line<Point>()
      .curve(d3.curveBasis)
      .x(p => xScale(p.x))
      .y(p => yScale(p.y));

    const area = d3.area<Point>()
      .curve(d3.curveBasis)
      .x(p => xScale(p.x))
      .y0(yScale(0))
      .y1(p => yScale(p.y));

    svg.append("path")
      .datum(lineData)
      .attr("fill", "none")
      .attr("stroke", color)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "none")
      .attr("stroke-width", 3)
      .attr("d", line);

    svg.append("path")
      .datum(lineData)
      .attr("fill", color)
      .attr("opacity", .5)
      .attr("d", area);

    // Don't draw pdr if it is outside the bounds of the graph
    if (pdr[selectedMetric].x > lineData[lineData.length - 1].x) {return; }

    svg.append("circle")
      .attr("fill", "black")
      .attr("stroke", color)
      .attr("stroke-width", 1.5)
      .attr("cx", xScale(pdr[selectedMetric].x))
      .attr("cy", yScale(pdr[selectedMetric].reach))
      .attr("r", 5);

    svg.append("text")
      .attr("text-anchor", "middle")
      .attr("x", xScale(pdr[selectedMetric].x))
      .attr("y", yScale(pdr[selectedMetric].reach) - 10)
      .text("PDR")
      .attr("fill", "white");
  }

  gotoAttachedScenario() {
    this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_SCENARIOS, this.attachedScenarioId]);
  }

  gotoAllocationsPage() {
    if (this.channel.isAvailable()) {
      this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_CHANNELS, this.channel._id]);
    }
  }

  private setChannelDeleteFlag() {
    // Channel can be deleted if it was updated more than 3 hours ago. Ideally PPC should get callback
    // from Tardiis within couple of an hours

    const channelUpdatedTime = new Date(this.channel.updated_at);
    const currentTime = new Date();
    const timeDiff = Math.abs(currentTime.getTime() - channelUpdatedTime.getTime());
    const diffHours = Math.ceil(timeDiff / (1000 * 3600));

    this.canDeleteChannel = !this.channel.isRunning() ||
      (this.channel.isRunning() && (diffHours > this.HOURS_BEFORE_RUNNING_CHANNEL_CAN_BE_DELETED));

    this.canDeleteChannel = this.canDeleteChannel && userCan('destroy', 'channels', this.store)
  }
}
