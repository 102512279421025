import { Component, OnInit, ChangeDetectorRef, Input, ViewChild, ElementRef, HostListener, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { forEach } from 'lodash';

import { InputValidator } from '../ppc-input/ppc-input.component';


@Component({
  selector: 'ppc-textarea',
  templateUrl: './ppc-textarea.component.html',
  styleUrls: ['./ppc-textarea.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PpcTextareaComponent),
      multi: true
    }
  ]
})
export class PpcTextareaComponent implements OnInit {

  @ViewChild('label') label: ElementRef;
  @ViewChild('input', { static: true }) input: ElementRef;

  @Input() validators: InputValidator[] = [];
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() errorMessage: string;
  @Input() placeholder: string;
  @Input() spellcheck: boolean = true;
  @Input() value: string;
  @Input() disabled: boolean = false;
  @Input() noLabel: boolean = false;
  @Input() noErrorContainer: boolean = false;
  @Input() alwaysFloatLabel: boolean = false;

  public errors: string[];
  public focused: boolean = false;
  public touched: boolean = false;
  private rowHeight: number;
  private ready: boolean = false;

  constructor(private sanitizer: DomSanitizer, private changeDetector: ChangeDetectorRef, public elem: ElementRef) {

  }

  ngOnInit() {
    this.rowHeight = this.input.nativeElement.clientHeight;
    setTimeout(() => {
      this.ready = true;
    }, 10)
  }


  onBlur(): void {
    this.focused = false;
    this.touched = true;
  }

  getTransform(): SafeStyle {
    let transform;
    if (!this.input.nativeElement.value && !this.alwaysFloatLabel && this.ready) {
      transform = `translate3d(0px, ${this.input.nativeElement.clientHeight}px,0)`
    } else {
      transform = 'scale(0.7)';
    }
    return this.sanitizer.bypassSecurityTrustStyle(transform);
  }

  onInput(event: any ): void {
    if (event.inputType == "deleteContentBackward") {
      this.input.nativeElement.style.height = `${this.rowHeight}px`
    }

    this.writeValue(event.target.value);
    this.errors = [];
    if (!this.validators.length) {return; }
    forEach(this.validators, validator => {
      if (!validator.isValid(event.target.value)) {
        this.errors.push(validator.errorMessage);
      }
    })

  }

  onCut(event: any) {
    this.input.nativeElement.style.height = `0px`;
    setTimeout(_ => this.checkSize())
  }

  checkSize(): void {
    if (this.input.nativeElement.clientHeight < this.input.nativeElement.scrollHeight) {
      this.input.nativeElement.style.height = `${this.input.nativeElement.scrollHeight}px`
    }
  }

  public clear(): void {
    this.onInput('')
  }

  // CONTROL VALUE ACCESSOR IMPLEMENTATION

  // Function to call when the value changes.
  onChange = (value: any) => {};

  // Function to call when the input is touched.
  onTouched = () => {};

  // Allows Angular to update the model.
  // Update the model and changes needed for the view here.
  writeValue(value: string): void {
    this.value = value;
    this.onChange(this.value)
    this.checkSize()
    if (!this.ready) { // this means we just rendered. Give it a little time
      setTimeout(() => {
        this.checkSize()
      }, 1)
    }
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input. Not currently used to this component but necessary to implement ControlValueAccessor
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // END CONTROL VALUE ACCESSOR IMPLEMENTATION

}
