import { Injectable } from "@angular/core";
import { BuilderAudience, AudienceRuleGroup, AudienceRuleEntry } from '../audience-builder.models';
import { AudienceV2, getAllSegmentIdentifiers, AudienceRule } from '../../audiences-v2/audience-v2.model';
import { find, isEmpty, keyBy, map as _map, get, pick, chain } from 'lodash';
import { SegmentV2Service } from '../../segments-v2/segment-v2.service';
import { map, withLatestFrom } from 'rxjs/operators';
import { PERMISSION_CREATE_AUDIENCE_SINGLE } from '../../shared/utils/constants';
import { SegmentV2 } from '../../audiences/discover/segment-v2.model';
import { isEntrySegment } from '../audience-builder.utils';
import { fixCounts } from 'app/segment-picker/segment-picker.utils';
import { Observable, of as observableOf } from 'rxjs';
import { Store, select } from "@ngrx/store";
import { AppState } from 'app/reducers';
import { selectRegion } from "app/hierarchy/hierarchy.reducers";

@Injectable()
export class AudienceMapper {
  constructor(private segmentService: SegmentV2Service,
    private store: Store<AppState>) {}

  public toJson(audience: BuilderAudience): AudienceV2 {
    return {
      ...audience,
      rules: {
        exclude: this.groupToJson(find(audience.rules.items, {type: "not"}) as AudienceRuleGroup),
        include: this.groupToJson(find(audience.rules.items, {type: "and"}) as AudienceRuleGroup),
      }
    }
  }

  public fromJson(audience: AudienceV2): Observable<BuilderAudience> {
    const identifiers = getAllSegmentIdentifiers(audience);
    return this.segmentService.fetchSegmentsV4({identifiers, use_cases: [PERMISSION_CREATE_AUDIENCE_SINGLE]}).pipe(
      map(segments => this.fromJsonBuilderAudience(audience, keyBy(segments, "identifier")))
    )
  }

  // fetches non-segment audiences when navigating from explore to persona builder
  public fromJsonPersona(audience: AudienceV2): Observable<BuilderAudience> {
    const identifiers = getAllSegmentIdentifiers(audience);
    if (isEmpty(identifiers)) {return observableOf(this.fromJsonBuilderAudience(audience, {})); }
    return this.segmentService.fetchByIdentifiers(identifiers).pipe(
      withLatestFrom(this.store.select('hierarchy').pipe(select(selectRegion))),
      map(([segments, region]) => {
        return {...this.fromJsonBuilderAudience(audience, keyBy(fixCounts(segments, region), "identifier"))};
      })
    )
  }

  fromJsonBuilderAudience(audience: AudienceV2, segmentsByIdentifier: {[identifier: string]: SegmentV2}) {
    const builderAudience = {
      ...audience,
      rules: {
        type: "and",
        items: [
          {
            type: "and",
            items: this.groupItemsFromJson(audience.rules.include, segmentsByIdentifier),
          },
          {
            type: "not",
            items: this.groupItemsFromJson(audience.rules.exclude, segmentsByIdentifier),
          },
        ]
      }
    } as BuilderAudience
    return get(builderAudience, "job_status") === "pending" ? builderAudience : pick(builderAudience, ["default", "rules", "name", "description"])
  }

  public fromJsonBulk(audiences: AudienceV2[]): Observable<BuilderAudience[]> {
    const identifiers = chain(audiences)
      .flatMap(getAllSegmentIdentifiers)
      .uniq()
      .value();
    return this.segmentService.fetchSegmentsV4({identifiers, use_cases: [PERMISSION_CREATE_AUDIENCE_SINGLE]}).pipe(
      map(segments => {
        const segmentsByIdentifier = keyBy(segments, "identifier");
        return chain(audiences).map(audience => {
          return {
            ...audience,
            rules: {
              type: "and",
              items: [
                {
                  type: "and",
                  items: this.groupItemsFromJson(audience.rules.include, segmentsByIdentifier),
                },
                {
                  type: "not",
                  items: this.groupItemsFromJson(audience.rules.exclude, segmentsByIdentifier),
                },
              ]
            }
          } as BuilderAudience
        }).value()
      })
    )

  }

  private groupItemsFromJson(rule: AudienceRule, segments: {[identifier: string]: SegmentV2}) {
    return (rule.and as AudienceRule[]).map((orGroup: AudienceRule) => {
      return {
        type: "or",
        items: (orGroup.or as string[]).map(identifier => segments[identifier]),
        acceptsSegments: true,
      }
    })
  }

  private groupToJson(group: AudienceRuleGroup): AudienceRule {
    return {
      [group.type === "not" ? "and" : group.type]: _map(group.items, (item: AudienceRuleEntry) => isEntrySegment(item) ?
        (item as SegmentV2).identifier :
        this.groupToJson(item as AudienceRuleGroup))};
  }
}
