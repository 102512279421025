import { Component, OnInit } from "@angular/core";

import { Store, select } from "@ngrx/store";
import { cloneDeep, find, get, map as _map, filter } from "lodash";
import { RegionService } from "../../region/region.service";
import { Region } from "../../region/region.model";
import { toMap } from "app/shared/utils/utils";
import { AppState } from "app/reducers";
import { combineLatest, Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { HierarchyClient } from "app/hierarchy/hierarchy.interface";
import { emptyHierarchyClient } from "app/hierarchy/hierarchy.utils";
import { HttpClient } from "@angular/common/http";
import { InsightsService } from "app/insights/insights.service";

const LOAD_REGIONS_FAIL =
  "An error occurred while loading regions. Please try again.";

@Component({
  selector: "app-client",
  templateUrl: "./client-admin.component.html",
  styleUrls: ["./client-admin.component.sass"],
})
export class ClientAdminComponent implements OnInit {
  clients$: Observable<HierarchyClient[]>;
  regions: { [id: string]: Region } = {};
  statusFilterOptions = [
    { label: "Active", id: "active" },
    { label: "Deactivated", id: "deactivated" },
    { label: "All", id: "all" },
  ];

  statusFilter$ = new BehaviorSubject("active");

  clientUnderEdit: HierarchyClient;
  errorMessages = new Set<string>();
  isClientDemo = false;
  marketLevelOnlyRegions: string[];

  constructor(
    private regionService: RegionService,
    private store: Store<AppState>,
    private http: HttpClient,
    private insightsService: InsightsService
  ) {
    this.isClientDemo = window.location.href.includes("demo.ppc.publicismedia");
  }

  ngOnInit() {
    this.clients$ = combineLatest([
      this.store.select("hierarchy", "hierarchy", "clients"),
      this.statusFilter$,
    ]).pipe(
      map(([clients, statusFilter]) => {
        switch (statusFilter) {
          case "active":
            return filter(clients, ["active", true]);
          case "deactivated":
            return filter(clients, ["active", false]);
          case "all":
            return clients;
        }
      })
    );

    this.insightsService.fetchInsightsAdminTabs().subscribe((tabs) => {
      const personLevelTab = find(tabs, {
        tab_key: "top_level_person",
        insights_context: "grow",
      });
      this.marketLevelOnlyRegions = get(personLevelTab, "excluded_regions");
    });

    this.regionService.getAllRegions().subscribe(
      (regions) => {
        this.errorMessages.delete(LOAD_REGIONS_FAIL);
        this.regions = toMap((r) => r.id, regions);
      },
      () => this.errorMessages.add(LOAD_REGIONS_FAIL)
    );
  }

  statusFilterUpdate(statusFilter) {
    this.statusFilter$.next(statusFilter);
  }

  edit(client: HierarchyClient) {
    this.clientUnderEdit = cloneDeep({
      ...client,
      region_ids: _map(client.regions, "id"),
    });
  }

  cancelEdit() {
    this.clientUnderEdit = null;
  }

  addClient() {
    this.clientUnderEdit = emptyHierarchyClient();
  }

  getClientId(i, client) {
    return client.id;
  }
}
