import {InsightsContextType} from "app/insights/insights.constants";

export const MekkoColors = {
  owned: "#82CAD0",
  opportunity: "#BDDE94",
  none: "#2262B1",
  undefined: "#000000"
}

export const userPreferenceKeys = {
  standardDemographics: (insightsContext: InsightsContextType) => `standard-demographics-${insightsContext}`,
  surveys: "market-level-surveys",
  customTab: (customTabId: number) => `custom-tab-${customTabId}-demographics`,
  regionLevelDemographics: (demographicType: "census" | "share-this") => `region-level-${demographicType}-demographics`
}
