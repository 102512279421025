import {Component, OnInit, Input, ElementRef, TemplateRef} from '@angular/core';

@Component({
  selector: 'ppc-tab',
  templateUrl: './ppc-tab.component.html',
  styleUrls: ['./ppc-tab.component.sass']
})
export class PpcTabComponent implements OnInit {
  @Input() name: string;
  @Input() disabled: boolean = false;
  @Input() labelTemplate: TemplateRef<any>;
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() tooltipContent: string;

  constructor(public element: ElementRef) {
  }

  ngOnInit() {
  }

}
