<div class="row-container">
  <div [ngClass]="statusColorClass"></div>

  <ppc-checkbox
    [disabled]="isDisabled"
    [checked]="checked"
    (change)="selectRow()">
  </ppc-checkbox>
  <div class="row-information" (click)="selectRow()">
    <p class="destination-name">{{destination?.name}}</p>
    <span>{{destination?.status}}</span>
  </div>
  <mat-form-field class="dropdown">
    <mat-select
      placeholder="Select Use Case"
      [(value)]="useCase"
      [disabled]="isDisabled || !checked"
      (selectionChange)="selectUseCase()"
    >
      <mat-option value="mediaTargeting">Media Targeting</mat-option>
      <mat-option value="suppression">Suppression</mat-option>
      <mat-option value="siteOptimization">Site Optimization</mat-option>
      <mat-option value="dataEnrichment">Data Enrichment</mat-option>
      <mat-option value="tv">TV Planning</mat-option>
      <mat-option value="other">Other</mat-option>
    </mat-select>
  </mat-form-field>
</div>
