import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { combineLatest as observableCombineLatest } from "rxjs";
import { find, values, truncate, map as _map, groupBy } from "lodash"
import * as moment from 'moment';
import { Actions } from '@ngrx/effects';

import {Store, select} from "@ngrx/store";
import { AppState } from "app/reducers"
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SegmentsInfoComponent } from '../segments-info.component'
import { fetchOutcome } from "app/shared/utils/fetch-state";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as actions from 'app/snapshots/snapshots.actions';
import * as insightActions from 'app/insights/insights.actions';
import * as mekkoActions from 'app/mekko/mekko.actions';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import {InsightsCountService} from "app/insights/insights-count.service";
import { Snapshot } from 'app/snapshots/snapshots.reducer';
import { selectActiveMekkoMarkets, Market, SubMarket } from 'app/mekko/mekko.reducer'
import { isDefined } from 'app/shared/utils/utils';

@UntilDestroy()
@Component({
  selector: 'ppc-propensity-form',
  templateUrl: './propensity-form.component.html',
  styleUrls: ['./propensity-form.component.sass']
})
export class PropensityFormComponent implements OnInit, OnDestroy {

  snapshot = {
    name: '',
    sub_markets: [],
    filters: [],
    mekko_id: null,
    include_first_party_segments: false
  }

  snapshotForm: FormGroup;
  subMarketNames: string;
  dialogRef: MatDialogRef<any>;
  saved = false;
  mekkoId: number;
  snapshots: {[id: number]: Snapshot};
  filterNames: string;

  constructor(private store: Store<AppState>,
    private actions$: Actions,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private insightCounts: InsightsCountService,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {

    this.snapshotForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      mekko_id: new FormControl(this.snapshot.mekko_id),
      filters: new FormControl(this.snapshot.filters),
      include_first_party_segments: new FormControl(this.snapshot.include_first_party_segments),
      subMarkets: new FormControl(this.snapshot.sub_markets, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]),
    });

    observableCombineLatest(
      store.select("mekkos", "selectedSubMarketIds"),
      store.select("mekkos", "subMarkets"),
      store.select("mekkos").pipe(select(selectActiveMekkoMarkets)),
      insightCounts.countsChanged$,
    ).pipe(untilDestroyed(this))
      .subscribe(([selectedSubMarketIds, subMarkets, markets, _]) => {
        const sub_markets = values(subMarkets).filter((sm) => sm.matched_short_id && selectedSubMarketIds.includes(sm.id));
        this.snapshot.sub_markets = sub_markets.map((sm) => {
          return { ...sm, matched_count: this.getCount(sm) }
        })

        this.subMarketNames = this.snapshot.sub_markets.map((sm) => this.buildName(sm, markets)).join(', ');
      })

    store.select("insights", this.insightsContext, "filters").pipe(untilDestroyed(this), filter(isDefined), )
      .subscribe((filters) => {
        this.snapshot.filters = _map(groupBy(filters, "demographicId"));
        this.filterNames = _map(filters, f => f.pathParts.join(' > ')).join(', ');
      })

    store.select("mekkos", "selectedMekkoId").pipe(untilDestroyed(this), filter(isDefined), )
      .subscribe(selectedMekkoId => {
        this.mekkoId = selectedMekkoId
        this.snapshot.mekko_id = this.mekkoId
      })

    this.store.select("snapshots", "snapshotLibrary").pipe(untilDestroyed(this))
      .subscribe(snapshots => this.snapshots = snapshots);
  }

  ngOnInit() {
  }

  ngOnDestroy() { }

  get subMarkets() { return this.snapshotForm.get('subMarkets'); }
  get name() { return this.snapshotForm.get('name'); }

  date(date: string): string {
    return moment(date).format('L');
  }

  buildName(subMarket: SubMarket, markets: Market[]): string {
    const market = markets && markets.find((m) => m.id == subMarket.market_id)
    if (market) {
      return `${market.name}: ${subMarket.name}`;
    } else {
      return subMarket.name;
    }
  }

  getCount(subMarket: SubMarket): number {
    return this.insightCounts.getSegmentCount(subMarket.matched_short_id);
  }

  truncate(name: string): string {
    return truncate(name, {length: 50, separator: ' '});
  }

  get allSubMarketHaveCounts() {
    return this.snapshot.sub_markets.every((sm) => sm.matched_count && sm.matched_count > 0);
  }

  get valid() {
    return this.snapshotForm.valid && this.allSubMarketHaveCounts && !this.hasDuplicateName;
  }

  get hasDuplicateName() {
    return this.snapshot.name && find(this.snapshots, snapshot => {
      return !snapshot.name.toUpperCase().trim().localeCompare(this.snapshot.name.toUpperCase().trim());
    });
  }

  submit(e) {
    e.preventDefault();

    if (this.valid) {
      this.store.dispatch(new actions.SaveSnapshot(this.snapshot));
      this.actions$.pipe((fetchOutcome(actions.SaveSnapshot.type)), take(1), )
        .subscribe(
          saveOutcome => this.saved = true,
          error => this.alertWarn('Propensity could not be saved. Please try again.')
        );
    }
  }

  alertWarn(message: string): void {
    this.snackbar.open(message, null, {
      duration: 2500,
      panelClass: ['danger']
    });
  }

  toggleSegmentsInfo() {
    this.dialogRef = this.dialog.open(SegmentsInfoComponent)
  }

  reset() {
    this.saved = false
    this.store.dispatch(new mekkoActions.ClearSelectedSubMarkets());
    this.store.dispatch(new insightActions.ClearFilters(this.insightsContext));

    this.snapshot = {
      name: '',
      sub_markets: [],
      filters: [],
      mekko_id: this.mekkoId,
      include_first_party_segments: false
    }
  }

  closeDialog(result: boolean) {
    this.dialogRef && this.dialogRef.close(result);
  }
}
