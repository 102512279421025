<p *ngIf="!(rootWidgetData$ | async)?.length">Data is temporarily unavailable. Please check back in a few days.</p>
<div class="packery-container" [packery]="bagName" [forceLayout$]="forceLayout$">
    <ppc-share-this-widget class="packery-item"
                            [widgetData]="widgetData$ | async"
                            widgetType="Base"
                            [rootWidgetName]="rootWidgetName"
                            [attr.data-id]="'base-widget'"
                            [class.jumbo]="insightsFocused$ | async"></ppc-share-this-widget>
    <ppc-share-this-widget class="packery-item"
                            [widgetData]="widgetData$ | async"
                            widgetType="Domain"
                            [rootWidgetName]="rootWidgetName"
                            [attr.data-id]="'base-widget'"
                            [class.jumbo]="insightsFocused$ | async"></ppc-share-this-widget>
    <ppc-share-this-widget class="packery-item"
                            [widgetData]="widgetData$ | async"
                            widgetType="Categories"
                            [rootWidgetName]="rootWidgetName"
                            [attr.data-id]="'categories-widget'"
                            [class.jumbo]="insightsFocused$ | async"></ppc-share-this-widget>
</div>
