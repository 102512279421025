
import {fromEvent as observableFromEvent,  Observable } from 'rxjs';

import {debounceTime} from 'rxjs/operators';
import { Injectable } from '@angular/core';


@Injectable()
export class PpcTabsService {

  renderEvents$ = observableFromEvent(document, "mouseup").pipe(debounceTime(400))

  constructor() { }
}
