import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './services/logging.service';

export const DEFAULT_ERROR_MSG = "Something went wrong with this page. Please refresh and try again";

@Injectable()
export class PpcErrorHandler extends ErrorHandler {
  constructor(private loggingService: LoggingService) {
    super();
  }

  handleError(error) {
    let errMsg: string;

    if (error.status === 403) {
      const body = error;
      errMsg = body.error.message;
    } else {
      console.error(error);
      errMsg = DEFAULT_ERROR_MSG;
    }

    this.loggingService.logError(errMsg);
  }
}
