import { DataPermissions } from "app/data_permissions/data_permissions.model";
import { getPermissionRawDataName } from 'app/services/data_permissions.service';

export function displayFusion(audience) {
  const date = Date.parse(audience.fusion_complete_date);
  if (isNaN(date)) { return null }

  const transactions = (audience.transactions || []).filter(tx => tx.action === 'send_to_tardiis');

  if (transactions.length === 0) { return null }
  const tardiis_date = Date.parse(transactions[0].created_at);

  if (tardiis_date > date) { return null }

  return {
    date: new Date(audience.fusion_complete_date),
    user: '',
    action: 'Fusion Complete'
  }
}

export function constructPermissionsFromDisplay(displayPermissions: object[]): DataPermissions['permissions'] {
  const permObj: DataPermissions['permissions'] = {
    activation: false,
    client_sharing: false,
    insights: false,
    measurement: false,
    modeling_seed: false,
    modeling_universe: false,
    tv_planning: false
  };
  displayPermissions.forEach(permission => {
    permObj[getPermissionRawDataName(permission['displayName'])] = permission['isPermissible'];
  });
  return permObj;
}
