export const tooltipMessage = {
  disabledEditProcessing: "Cannot edit a processing lookalike",
  disabledEditNotUserPermissioned: "Action Not Permitted. Contact Product Support.",
  processing: "Lookalike currently running",
  pending: "Pending",
  estimated: "Estimated Reach",
  fail: "Lookalike process failed",
  estimatedPeopleCount: 'Estimated',
  failedEstimatedPeopleCount: 'Failed to estimate',
  disabledTardiisIncomplete: 'Cannot send to Tardiis unless lookalike is complete',
  disabledTardiisAlreadySent: 'This lookalike has been sent to Tardiis',
  disabledTardiisNotUserPermissioned: "Action Not Permitted. Contact Product Support",
  disabledTardiisNotPermissioned: 'This lookalike is not allowed for TV Planning and cannot be sent to Tardiis',

  disabledDeleteProcessing: "Cannot delete a processing lookalike",
  disabledDeleteNotUserPermissioned: "Action Not Permitted. Contact Product Support.",
  disabledDeleteTardiis: "Cannot delete a lookalike that has been sent to Tardiis",
  disabledDownloadExcel: "Not Permitted for Download at this time",
  sendToPartnerIncomplete: 'Cannot send to partner unless lookalike is complete',
  sendToPartnerNotUserPermissioned: "Action Not Permitted. Contact Product Support.",
  sendToPartnerNotDataPermissioned: "This lookalike is not permitted for activation",
  sendToPartnerMissingCounts: 'Must have People Count greater than 0 to Send to Partner',
  // index report
  indexReportCreationDisabled: 'Cannot run index report unless lookalike is complete.',
  indexReportNotUserPermissioned: 'Action Not Permitted. Contact Product Support.',
};
