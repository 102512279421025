export class ClusterConstant {
  public static readonly WIDTH: number = 900;
  public static readonly HEIGHT: number = 800;
  public static readonly PADDING: number = 3;
  public static readonly CLUSTER_PADDING: number = 10;
  public static readonly MAX_RADIUS: number = 20;
  public static readonly GRAVITY: number = 0.005;
  public static readonly COLLIDE: number = 0.3;
  public static readonly DURATION: number = 200;
  public static readonly RADIUS_RATIO: number = 1.3;
  public static readonly RADIUS_THRESHOLD: number = 15;
  public static readonly COLOR_DOMAIN: string[] = [
    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"
  ];
  public static readonly COLOR_SCHEME: string[] = [
    "#01F794", "#E689FF", "#002AEB", "#B400FF", "#48CAFA",
    "#07E220", "#1D99CF", "#FB88AF", "#F7CA0F", "#E24B4D"
  ];
  public static readonly CLUSTER_OPACITY: number = 0.2;
  // audience card
  public static readonly CARD_WIDTH: number = 380;
}
