<div class="box">
  <div class="box-header">
    <h1 class="box-title">Recently Created Audiences</h1>
    <!-- <div class="created-by-filter">
      <span>Created by me</span> |
      <span class="gray-disabled">Team</span>
    </div> -->
    <div class="box-title-right">
      <mat-select class="dropdown mat-dropdown" [ngModel]="tableFilterLength$ | async" (ngModelChange)="filterLengthSelect($event)">
        <mat-option *ngFor="let option of selectOptions" [value]="option.value">{{option.label}}</mat-option>
      </mat-select>
    </div>
  </div>
  <div class="box-container">
    <main class="dark ppc-table">
      <table>
        <thead>
          <tr>
            <th><div>Type</div></th>
            <th class="ultra-wide"><div>Name</div></th>
            <th><div>People Count</div></th>
            <th>Created</th>
            <th><!--Action column--></th>
          </tr>
        </thead>
        <ng-container>
          <ng-container *ngIf="isFullContext && rows && rows.length > 0">
            <!--Have to use attribute components here because otherwise the extra html tag
            that gets injected for normal components would mess up the table formatting-->
            <tbody
              *ngFor="let row of rows"
              ppc-home-audience-row
              [row]="row"
              [hierarchyPath]="hierarchyPath">
            </tbody>

            <tbody>
              <tr class="link">
                <td colspan="4">
                  <a routerLink="{{hierarchyPath}}/audiences/overview">Go to Audiences</a>
                </td>
                <td>
                  <img routerLink="{{hierarchyPath}}/audiences/overview" src="assets/icons/baseline-arrow_forward-24px.svg" class="blue-icon" />
                </td>
              </tr>
            </tbody>
          </ng-container>

          <tbody *ngIf=" !isFullContext || !rows || rows.length === 0">
            <tr><td colspan="5" class="data-unavailable">Data unavailable</td></tr>
            <tr class="link">
              <td colspan="4">
                <a routerLink="{{hierarchyPath}}/audiences/overview">Go to Audiences</a>
              </td>
              <td>
                <img routerLink="{{hierarchyPath}}/audiences/overview" src="assets/icons/baseline-arrow_forward-24px.svg" class="blue-icon" />
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </main>
  </div>
</div>
