<ngx-datatable *ngIf="(clusterNodes$ | async)?.length"
                [rows]="clusterNodes$ | async"
                [rowHeight]="40"
                columnMode="flex"
                [reorderable]="false"
                sortType="multi"
                [sorts]="tableSorts"
                (sort)="tableSortChanged($event)">
  <ngx-datatable-column
    name="ID"
    prop="id"
    [flexGrow]="0.5"
    headerClass="numeric"
    cellClass="numeric"
    [resizeable]="false">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="Name"
    prop="name"
    [flexGrow]="2"
    headerClass="text"
    cellClass="text"
    [resizeable]="false">
    <ng-template let-value="value" ngx-datatable-cell-template>
      <span
        class="cell-content-overflow"
        *ppcCheckOverflow="let hasOverflow=hasXOverflow"
        [ppcTooltip]="value"
        [tooltipDisabled]="!hasOverflow">
        {{ value }}
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="Description"
    prop="description"
    [flexGrow]="2"
    headerClass="text"
    cellClass="text"
    [resizeable]="false">
    <ng-template let-value="value" ngx-datatable-cell-template>
      <span
        class="cell-content-overflow"
        *ppcCheckOverflow="let hasOverflow=hasXOverflow"
        [ppcTooltip]="value"
        [tooltipDisabled]="!hasOverflow">
        {{ value }}
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="Updated"
    prop="file_uploaded_at"
    [flexGrow]="1"
    headerClass="numeric"
    cellClass="numeric"
    [resizeable]="false"
    [comparator]="dateComparator">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value | date: 'shortDate' }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [flexGrow]="0.5"
    cellClass="amount"
    [sortable]="false">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="edit-button" (click)="editClusterNode(row); $event.stopPropagation()">Edit</div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
