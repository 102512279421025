import {takeUntil, mapTo, startWith, filter, debounceTime} from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy, ElementRef, Inject, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, merge as observableMerge } from 'rxjs';
import { DragulaService } from "ng2-dragula";
import { find, cloneDeep, isEqual } from 'lodash';

import { MarketLevelDemographic } from 'app/insights/insights-components/market-level-demographics/market-level-demographic.interface';
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { AppState } from 'app/reducers';
import { Tab, Demographic } from 'app/insights/insights.models';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { selectCustomTabDemographics, selectMarketLevelCustomTabDemographics } from 'app/insights/insights.reducer';
import * as insightsActions from 'app/insights/insights.actions';
import * as growActions from 'app/insights/grow-v3/grow.actions';
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';

@Component({
  selector: 'ppc-custom-tab-config',
  templateUrl: './insights-custom-tab-config.component.html',
  styleUrls: ['./insights-custom-tab-config.component.sass']
})
export class InsightsCustomTabConfigComponent extends InsightsResourceTracker implements OnInit, OnDestroy {
  @Input() tab: Tab;
  @Input() parent: Tab;
  @Output() toggleVisibility: EventEmitter<any> = new EventEmitter<Tab>();
  @Input() cantUnselect: boolean;

  ngUnsubscribe = new Subject();
  bagName: string;
  customDemographics: Demographic[] = []
  marketLevelDemographics: MarketLevelDemographic[] = []
  oldCustomDemographics: Demographic[];
  oldMarketLevelDemographics: MarketLevelDemographic[];
  canUpdateCustomTabs$ = this.store.select('permissions', 'custom_tabs', 'update');

  dragStart$ = this.dragulaService.drag.pipe(filter(([bagName]) => bagName == this.bagName), mapTo(true));
  dragEnd$ = this.dragulaService.dragend.pipe(filter(([bagName]) => bagName == this.bagName), mapTo(false));
  isDragging$ = observableMerge(this.dragStart$, this.dragEnd$).pipe(startWith(false));

  constructor(public store: Store<AppState>,
    private dragulaService: DragulaService,
    private element: ElementRef,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super(store, insightsContext)
  }

  ngOnInit() {

    this.store.select("insights", this.insightsContext).pipe(
      select(selectCustomTabDemographics(this.tab.id)),
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    )
      .subscribe((demographics) => {
        this.customDemographics = demographics;
        this.oldCustomDemographics = cloneDeep(demographics);
      })

    this.store.pipe(
      select(selectMarketLevelCustomTabDemographics(this.insightsContext, this.tab.id)),
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    )
      .subscribe((demographics) => {
        this.marketLevelDemographics = demographics;
        this.oldMarketLevelDemographics = cloneDeep(demographics);
      })

    this.bagName = `manage-widgets-custom-${this.tab.tab_key}`;

    if (!this.dragulaService.find(this.bagName)) {
      this.dragulaService.setOptions(this.bagName, {
        moves: (el, container, handle) => /fa-bars/.test(handle.className)
      })
    }

    this.dragulaService.dropModel.pipe(
      filter(([bagName, widgetElement, targetContainer, fromContainer]) => {
        return this.element.nativeElement.contains(targetContainer) || this.element.nativeElement.contains(fromContainer)
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([bagName, widgetElement, targetContainer, fromContainer]) => {
      this.saveConfig();
      const id = +widgetElement.dataset.id;
      if (this.element.nativeElement.contains(targetContainer)) {
        this.tab.expanded = true;
        const demographic = find(this.demographics, {id});
        if (this.isPersonLevelTab) {
          this.store.dispatch(new insightsActions.UpsertDemographic({...demographic as Demographic, custom_tab_id: this.tab.id}, this.insightsContext))
        } else {
          this.store.dispatch(new insightsActions.SaveMarketLevelDemographic(this.insightsContext, {...demographic as MarketLevelDemographic, custom_tab_id: this.tab.id}))
        }
      } else if (this.element.nativeElement.contains(fromContainer) && !this.demographics.length) {
        this.tab.expanded = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.dragulaService.find(this.bagName)) {this.dragulaService.destroy(this.bagName); }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    super.ngOnDestroy();
  }

  editCustomTab() {
    this.store.dispatch(new insightsActions.EditCustomTab(this.tab, this.parent, this.insightsContext))
  }

  handleVisibility(tab) {
    this.toggleVisibility.emit(tab);
  }

  handleWidgetVisibility(widget) {
    widget.visible = !widget.visible
    this.saveConfig()
  }

  saveConfig() {
    if (this.isPersonLevelTab && !isEqual(this.customDemographics, this.oldCustomDemographics)) {
      this.store.dispatch(new insightsActions.SaveDemographicsConfig(this.customDemographics.map((demographic) => {
        return {
          id: demographic.id,
          visible: demographic.visible
        }
      }), userPreferenceKeys.customTab(this.tab.id), this.resourceId, this.resourceType, this.insightsContext))
    } else if (!this.isPersonLevelTab && !isEqual(this.marketLevelDemographics, this.oldMarketLevelDemographics)) {
      this.store.dispatch(new insightsActions.SaveDemographicsConfig(this.marketLevelDemographics.map((demographic) => {
        return {
          id: demographic.id,
          visible: demographic.visible
        }
      }), userPreferenceKeys.customTab(this.tab.id), this.resourceId, this.resourceType, this.insightsContext))
    }
  }

  get isPersonLevelTab(): boolean {
    return /person_level/.test(this.tab.tab_key);
  }

  get demographics(): MarketLevelDemographic[] | Demographic[] {
    if (this.isPersonLevelTab) {
      return this.customDemographics;
    }
    return this.marketLevelDemographics;
  }

}
