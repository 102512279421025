import { apiUrl } from '../../services/ppc_http.service';
import { ID_ANALYTICS_PATH, V2, V3 } from '../utils/constants';

export function segmentSearchCountUrl() {
  return apiUrl(ID_ANALYTICS_PATH + `/${V2}/segments/search`);
}

export function segmentCountsV1Url() {
  return apiUrl(ID_ANALYTICS_PATH + "/segments/segment_counts");
}

export function segmentCountsUrl() {
  return apiUrl(ID_ANALYTICS_PATH + `/${V2}/segments/counts`)
}

export function segmentCountsV3Url() {
  return apiUrl(ID_ANALYTICS_PATH + `/${V3}/segments/counts`);
}

export function segmentSimpleCountsUrl() {
  return apiUrl(ID_ANALYTICS_PATH + `/${V3}/segments/simple_counts`);
}

export function segmentJourneyCountsUrl() {
  return apiUrl(ID_ANALYTICS_PATH + `/${V3}/segments/journey_counts`);
}

export function segmentCountsV2Url() {
  return apiUrl(ID_ANALYTICS_PATH + `/${V2}/segments`)
}

export function taskHistoryAdminUrl() {
  return apiUrl(ID_ANALYTICS_PATH + `/tasks/task_history_admin`);
}

export function tasksUrl() {
  return apiUrl(ID_ANALYTICS_PATH + `/tasks`);
}

export function comparisonCountsUrl(): string {
  return apiUrl(ID_ANALYTICS_PATH + `/${V2}/segments/comparisons_with_index`);
}

export function pendingTasksAdminUrl(): string {
  return apiUrl(ID_ANALYTICS_PATH + `/tasks/pending_tasks_admin`);
}

export function adminIndexReportsUrl(): string {
  return apiUrl(ID_ANALYTICS_PATH + `/admin/index_reports`);
}

export function adminAllIndexReportsUrl(): string {
  return apiUrl(ID_ANALYTICS_PATH + `/admin/all_index_reports`);
}

export function adminAllESCountsUrl(): string {
  return apiUrl(ID_ANALYTICS_PATH + `/admin/all_es_counts`);
}
