export const tooltipMessage = {
  processing: "Outcome currently running",
  disabledTardiisIncomplete: 'Cannot send to Tardiis unless outcome is complete',
  disabledTardiisAlreadySent: 'This outcome has been sent to Tardiis',
  disabledTardiisNotUserPermissioned: "Action Not Permitted. Contact Product Support",
  disabledTardiisNotPermissioned: 'This outcome is not allowed for TV Planning and cannot be sent to Tardiis',
  disabledDownloadExcel: "Not Permitted for Download at this time",
  estimatedPeopleCount: "Estimated",
  fail: "Outcome process failed",
  failedEstimatedPeopleCount: "Cannot Estimate People Count",
  sendToPartnerIncomplete: 'Cannot send to partner unless outcome is complete',
  sendToPartnerNotUserPermissioned: "Action Not Permitted. Contact Product Support.",
  sendToPartnerNotDataPermissioned: "This outcome is not permitted for activation",
  sendToPartnerMissingCounts: 'Must have People Count greater than 0 to Send to Partner',
  // index report
  indexReportCreationDisabled: 'Cannot run index report unless outcome is complete.',
  indexReportNotUserPermissioned: 'Action Not Permitted. Contact Product Support.',
};
