import { Component, OnChanges, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'ppc-activation-destination',
  templateUrl: './activation-destination.component.html',
  styleUrls: ['./activation-destination.component.sass']
})
export class ActivationDestinationComponent implements OnChanges {
  @Input() public destination: any;
  @Input() isAdmin: boolean = false;
  @Output() selectDestination = new EventEmitter();
  @Output() unselectDestination = new EventEmitter();
  checked: boolean = false;
  statusColorClass: string = null;
  isDisabled: boolean = true;
  useCase: string;

  constructor() { }

  ngOnChanges(changes) {
    if (changes.destination && changes.destination.currentValue) {
      this.statusColorClass = this.getStatusColor(this.destination.status);
      this.useCase = this.destination.useCase
      if (!this.isAdmin) {
        this.isDisabled = this.destination.active !== true
      }
    }
  }

  reset() {
    this.checked = false;
    this.useCase = null;
  }

  getStatusColor(status) {
    switch (status) {
      case 'Destination Creation in Progress':
        return 'yellow-color-band';
      case 'Destination failed':
        return 'red-color-band';
      default:
        return 'green-color-band';
    }
  }

  selectRow() {
    if (!this.isDisabled) {
      this.checked = !this.checked;

      if (this.checked) {
        this.selectDestination.emit({destinationId: this.destination.id, useCase: null});
      } else {
        this.useCase = null;
        this.unselectDestination.emit({destinationId: this.destination.id});
      }
    }
  }

  selectUseCase() {
    if (this.checked) {
      this.selectDestination.emit({ destinationId: this.destination.id, useCase: this.useCase });
    }
  }
}
