import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { map as _map } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { HierarchyRegion } from '../hierarchy/hierarchy.interface';
import { objToHttpParams } from '../shared/utils/http-utils';
import { segmentHierarchyToNode } from 'app/segment-picker/segment-picker.utils';
import { SegmentHierarchy } from '../segment-picker/segment-picker.utils';
import { segmentsHierarchyUrl } from 'app/shared/constants/segments.urls';

@Injectable()
export class BaseSegmentPicker {

  constructor(public http: HttpClient) {}

  getRoot(region: HierarchyRegion, useCases: string[]) {
    const params = objToHttpParams({use_cases: useCases})
    return this.http.get<{data: SegmentHierarchy[]}>(segmentsHierarchyUrl(), {params}).pipe(
      map(response => response.data),
      map(segmentHierarchies => _map(segmentHierarchies,
        segmentHierarchy => segmentHierarchyToNode(segmentHierarchy, this.http, useCases, region.slug)))
    )
  }
}
