import { combineLatest,  Observable } from 'rxjs';
import {switchMap, map, tap, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AppState} from "../reducers";
import {Store} from "@ngrx/store";
import {Go} from "../router/router.actions";
import {FetchPermissions} from "../authorization/permissions/permissions.actions";
import {isLoaded} from "../shared/utils/fetch-state";
import { checkPermissions, RequiredPermissions } from 'app/authorization/permissions/permissions.reducers'
import { currentUser } from 'app/users/user.reducer'
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class PermissionsGuard implements CanActivate {

  constructor(private store: Store<AppState>, private snackbar: MatSnackBar) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
    const requiredPermissions: RequiredPermissions = next.data.requiredPermissions;
    if (!requiredPermissions) {throw 'Please provide required permissions as a data property of the route when using PermissionsGuard'; }

    return this.store.select('fetchStates').pipe(
      filter(fetchStates => isLoaded(fetchStates[FetchPermissions.type])),
      switchMap(() =>
        combineLatest(this.store.select('permissions'), currentUser(this.store)).pipe(
          map(([ perms, user ]) => checkPermissions(perms, requiredPermissions, user.is_admin)),
          tap(isPermitted => {
            if (isPermitted) {return}
            this.store.dispatch(new Go({ path: ['home'] }))
            this.snackbar.open(PERMISSION_REDIRECT_MESSAGE, 'Ok', { duration: 10000,
              panelClass: ['warning'] })
          }))))

  }
}

export const PERMISSION_REDIRECT_MESSAGE = 'You were redirected here because you attempted to navigate '
                                         + 'to a page that you do not have access to';
