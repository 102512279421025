<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>

<h2>Destination Management</h2>
<div class="filter">
  <div class="filter-option-wrapper">
    <div>Company ID</div>
    <mat-select #companyIdDropdown [value]="'all'" class="filter-option">
      <mat-option value="all">All</mat-option>
      <mat-option
        *ngFor="let company of companies$ | async"
        [value]="company.id"
        >{{company.id}}</mat-option
      >
    </mat-select>
  </div>
  <div class="filter-option-wrapper">
    <div>
      State
    </div>
    <mat-select #stateDropdown [(value)]="selectedState" class="filter-option">
      <mat-option value="all">All</mat-option>
      <mat-option *ngFor="let state of selectableStates" [value]="state">{{state | titlecase}}</mat-option>
    </mat-select>
  </div>
  <div class="filter-option-wrapper search-wrapper">
    <input class="search filter-option" placeholder="Search for Platform/Destination" [formControl]="searchField"/>
  </div>
</div>
<ng-container *ngIf="pageInfo$ | async as pageInfo">
  <ngx-datatable
    class="data-table"
    [rows]="destinations$ | async"
    [headerHeight]="43"
    [footerHeight]="60"
    [rowHeight]="55"
    [columnMode]="'force'"
    [reorderable]="false"
    [externalPaging]="true"
    [count]="pageInfo.totalCount"
    [offset]="pageInfo.pageNumber"
    [limit]="pageInfo.limit"
    (page)="changePage($event, selectedPage$)"
    [cssClasses]="{sortAscending: 'fa fa-sort-asc', sortDescending: 'fa fa-sort-desc'}"
    [externalSorting]="true"
    sortType="single"
    (sort)="changeSort($event)"
  >
    <ngx-datatable-column
      name="Platform"
      prop="platform.name"
      [sortable]="true"
      cellClass="data-column"
    ></ngx-datatable-column>
    <ngx-datatable-column
      name="Destination"
      prop="name"
      [sortable]="true"
      cellClass="data-column"
    ></ngx-datatable-column>
    <ngx-datatable-column
      name="Account ID"
      prop="accountId"
      [sortable]="true"
      cellClass="data-column"
    ></ngx-datatable-column>
    <ngx-datatable-column
      name="Client"
      prop="companyId"
      [sortable]="true"
      cellClass="data-column"
    ></ngx-datatable-column>
    <ngx-datatable-column
      name="State"
      prop="state"
      [sortable]="true"
      cellClass="data-column"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span class="color" [style.background]="stateColors[value]"></span>
        {{value | titlecase}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [sortable]="false"
      cellClass="change-state-column"
      [width]="150"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <button class="btn-complete" (click)="updateDestination(row.id, row.state === 'complete' ? 'pending' : 'complete')">
          {{row.state === 'complete' ? 'Back to Pending' : 'Complete'}}
        </button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</ng-container>
