<div class="mekko-builder">
  <div class="mekko-builder-header" [class.invisible]="!permissions?.update">
    View
    <mat-slide-toggle [checked]="editingMekko" (change)="toggleEditMode()" class="edit-mekko-toggle">
    </mat-slide-toggle>
    Edit
  </div>
  <div class="mekko-chart-container">
    <div class="mekko-chart">
      <div class="y-legend" [class.invisible]="editingMekko">
        <div class="y-legend-label">
          {{ mekko?.measurement_label || "Penetration" }}
        </div>
        <div class="y-legend-numbers">
          <div class="legend" *ngFor="let legend of [100,80,60,40,20,0]">
            <div class="number" [ngStyle]="getYLegendPosition(legend)">
              {{legend}}%
            </div>
          </div>
        </div>
      </div>
      <div class="mekko-columns" [dragula]="dragulaBagName" [dragulaModel]="mekko?.markets">
        <ppc-mekko-column *ngFor="let market of mekko?.markets"
                          [market]="market"
                          [style.width.%]="getWidthForMarket(market)"></ppc-mekko-column>
      </div>
      <div class="add-market-button" (click)="addMarket()" *ngIf="editingMekko && mekko?.vertical_type === null" matRipple>
        <i class="fa fa-plus"></i>Add Market
      </div>
    </div>
    <div class="x-legend" [class.invisible]="editingMekko">
      <div class="legend" *ngFor="let legend of [0,20,40,60,80,100]">
        <span class="number" [ngStyle]="getXLegendPosition(legend)">
          {{legend}}%
        </span>
      </div>
    </div>
  </div>


  <app-slide-in-overlay
    [active]="!!(marketUnderEdit$ | async)"
    [width]="450"
  >
    <ppc-market-form *ngIf="marketUnderEdit$ | async"></ppc-market-form>
  </app-slide-in-overlay>
  <app-slide-in-overlay
    [active]="!!(subMarketUnderEdit$ | async)"
    [width]="650"
  >
    <ppc-sub-market-form *ngIf="subMarketUnderEdit$ | async"></ppc-sub-market-form>
  </app-slide-in-overlay>
</div>

<ng-template #invalidSubMarketsSelected>
  <div class="invalid-sub-markets-selected-dialog">
      <div class="dialog-header">Invalid Audiences Selected!</div>
      <p *ngIf="(growTabState$ | async) == 'Matched Addressable'">You have Audiences selected which do not have a Matched segment attached. Please attach a Matched segment to continue filtering by these Audiences.</p>
      <p *ngIf="(growTabState$ | async) == 'Modeled Addressable'">You have Audiences selected which do not have a Modeled segment attached. Please attach a Modeled segment to continue filtering by these Audiences.</p>
      <button mat-button (click)="closeInvalidSubMarketsDialog()">OK</button>
  </div>
</ng-template>
