export interface ClientLevel {
  value: string;
  display: string;
}

export const CLIENT_VERTICALS = [
  { value: 'auto',    display: 'Automotive',              key: 'Automotive' },
  { value: 'cpg',     display: 'Consumer Packaged Goods', key: 'CPG' },
  { value: 'energy',  display: 'Energy and Utilities',    key: 'Energy & Utilities' },
  { value: 'finance', display: 'Financial Services',      key: 'Financial Services' },
  { value: 'health',  display: 'Healthcare',              key: 'Healthcare' },
  { value: 'qsr',     display: 'Quick Serve Restaurants', key: 'QSR' },
  { value: 'retail',  display: 'Retail',                  key: 'Retail' },
  { value: 'telco',   display: 'Telecommunications',      key: 'Telecom' },
  { value: 'travel',  display: 'Travel and Tourism',      key: 'Travel & Tourism' },
]

export const CLIENT_LEVELS: ClientLevel[] = [
  {
    value: 'internal',
    display: 'Internal'
  }, {
    value: 'demo',
    display: 'Demo'
  }, {
    value: 'poc',
    display: 'POC'
  }, {
    value: 'client',
    display: 'Client'
  }
]

export const CLIENT_ID_SPACE: ClientLevel[] = [
  {
    value: 'metagraph',
    display: 'Metagraph'
  },
]
