<div class="menu-item-container"
    (click)="navigateToLink()">

  <div class="scaleable-container">
    <div class="menu-item-image">
      <img [src]="menuItem.imageSrc" />
    </div>

    <h3 class="menu-item-title">
      {{ menuItem.title }}
    </h3>

    <p class="menu-item-summary">
      {{ menuItem.summary }}
    </p>
  </div>
</div>
