<div class="vendor-filter-picker">
  <ul>
    <li [class.selected]="isSelectionCleared" (click)="selectVendor(null)">All</li>
    <li 
      *ngFor="let vendor of vendorOptions"
      [class.selected]="vendorSelected[vendor.value]"
      (click)="selectVendor(vendor)"
    >
      {{ vendor.label }}
    </li>
  </ul>
</div>
