import * as actions from './partner.actions'
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import { LoadPartners } from './partner.actions';
import {Partner} from 'app/models/partners/partner.model';

export type State = EntityState<Partner>

const adapter = createEntityAdapter<Partner>();

const initialState = adapter.getInitialState();

export function reducer(state: State = initialState, action: actions.All) {
  switch (action.type) {
    case LoadPartners.type:
      return adapter.setAll(action.partners, state);
    default:
      return state
  }
}

const { selectEntities, selectAll } = adapter.getSelectors();

export const getPartners = selectAll;
