import * as d3 from "d3";

export interface Point {x: number; y: number}

export function getScales(data: Point[], container: Element, options) {
  const {width: containerWidth, height: containerHeight} = container.getBoundingClientRect();

  const { leftPadding = 0
    , rightPadding = 0
    , topPadding = 0
    , bottomPadding = 0
    , xMin = 0
    , yMin = 0
    , xMax = Math.max(...data.map(p => p.x))
    , yMax = Math.max(...data.map(p => p.y))
    , height = containerHeight
    , width = containerWidth
  } = options;

  return {
    xScale: d3.scaleLinear().domain([xMin, xMax]).range([0 + leftPadding, width - rightPadding]),
    yScale: d3.scaleLinear().domain([yMin, yMax]).range([height - topPadding, 0 + bottomPadding])
  };
}

export function getYAxisWidth(axisSvg: d3.Selection<SVGGraphicsElement>): number {
  const labels = axisSvg.selectAll('.tick text').nodes();
  return Math.max(...labels.map((text: SVGTextElement) => text.getComputedTextLength()));
}
