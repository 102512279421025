import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { compact, chain, filter as _filter, find, keyBy, forEach, get } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { AppState } from 'app/reducers';
import * as actions from 'app/index-report/index-report.actions';
import { fetchOutcome } from 'app/shared/utils/fetch-state';
import { IndexReportLayoutType } from 'app/index-report/index-report.model';

import { Persona } from 'app/explore/explore.reducer';
import { AudienceV2 } from 'app/audiences-v2/audience-v2.model';
import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import { IndexReportResourceType } from '../index-report.model';
import { SegmentPickerService } from 'app/segment-picker/segment-picker.service';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { SegmentV2, prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { SegmentLike } from 'app/models/segment-like.model';
import { LookALike } from 'app/look-a-like-dialog/look-a-like';
import { getStoreState, isNotValidFileName } from 'app/shared/utils/utils';
import { IS_ALL_DATA } from 'app/explore/explore.utils';
import { InputValidator } from 'app/shared/components/ppc-input/ppc-input.component';
import { tooltipMessageV2 } from 'app/audiences/shared/audience.constants';
import { defaultIndexBase, IndexBase } from 'app/insights/insights.reducer';
import { isMolecula } from '../../insights/insights.utils';
import { ES, MOLECULA } from '../../shared/utils/constants';

const MAX_SELECTIONS = 10;
const MIN_SELECTIONS = 1;

interface ResourceHeader {
  main: string;
  sub: string;
}

@Component({
  selector: 'ppc-index-report-form',
  templateUrl: './index-report-form.component.html',
  styleUrls: ['./index-report-form.component.sass']
})
export class IndexReportFormComponent implements OnInit, OnChanges {
  @Input() item: any;
  @Input() originator: string;  // audience, persona
  @Input() resources: Array<Persona|AudienceV2|LookalikeV3>;
  @Output() close = new EventEmitter<any>();
  layoutType: IndexReportLayoutType = "simplified";
  indexBase: IndexBase;
  list: Array<any>;
  segments: {[identifier: string]: SegmentLike|SegmentV2} = {};
  selections = Array.from({ length: MIN_SELECTIONS }, () => new BehaviorSubject<any>(null));
  uiName: string;
  allow_dataset_skew: boolean = true;
  allow_unknown_rate_skew: boolean = false;
  canAccessIndexVariations$ = this.store.select("permissions", "access_index_variations", "read");
  currentSkewSelection: string = "";
  resourceHeaderArr: ResourceHeader[] = [];
  allowAdd: boolean = true;
  addedSelections: number = 1;
  tooltipMessage: string;
  nameValidations: InputValidator[] = [
    {
      isValid: (value: string) => !this.isNotValid(),
      errorMessage: `Name cannot contain the following characters: % & { } \ < > * ? / $ ! ' " : @ |`
    }
  ];
  datasetSkew = {
    title: 'Dataset Skew',
    definition: ':  Some datasets are collected in a way that inherently biases the sample, leading to false results when indexing. Dataset Skew adjusts for these biases to provide more accurate index values.'
  };
  unknownRateSkew = {
    title: 'Unknown Rate Skew',
    definition: ':  Some attributes that logically should be mutually exclusive and cover the whole ID space are not. Many IDs have "Unknown" attributes and distort the index. Unknown Rate Skew adjusts for these "Unknown" attributes and provides more accurate index values.'
  };
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private segmentPickerService: SegmentPickerService,
    private segmentV2Service: SegmentV2Service) {
  }

  ngOnInit() {
    this.addResourceHeader();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resources && changes.resources.currentValue && this.originator) {
      if (this.originator === 'persona') {
        this.list = chain(changes.resources.currentValue)
          .reject(IS_ALL_DATA)
          .map(this.addIndexReportDetails)
          .value();
      } else {
        const identifiers = chain(changes.resources.currentValue)
          .filter(['job_status', 'complete'])
          .map('identifier')
          .compact()
          .value();

        if (identifiers.length) {
          this.segmentV2Service.fetchByIdentifiers(identifiers)
            .subscribe(segments => this.segments = keyBy(segments, 'identifier'));
        }

        this.list = chain(changes.resources.currentValue)
          .filter(['job_status', 'complete'])
          .map(this.addIndexReportDetails)
          .compact()
          .orderBy('name')
          .value();
      }
    }
    if (changes.item && changes.item.currentValue
      && JSON.stringify(changes.item.currentValue) !== JSON.stringify(changes.item.previousValue)) {
      const defaultItem = this.addIndexReportDetails(changes.item.currentValue);
      if (defaultItem && defaultItem.id) {
        this.resetSelections();
        this.resetResourceHeaders();
      }
    }
  }

  get filteredList() {
    const selections = compact(this.selections.map(resource$ => resource$.getValue()));

    return _filter(this.list, resource => !find(selections, selection => selection.id === resource.id));
  }

  addResourceHeader() {
    const resHeader: ResourceHeader = {
      main: this.getHeaderIndex(this.selections.length),
      sub: this.getSubLabelFromHeader(this.selections.length)
    };
    this.resourceHeaderArr.push(resHeader);
  }

  addSelection() {
    this.addedSelections++;
    this.selections.push(new BehaviorSubject<any>(null));
    this.addResourceHeader();
    if (this.addedSelections >= MAX_SELECTIONS ) {
      this.allowAdd = false;
    }
  }

  getHeaderIndex(length: number): string {
    let headerIndex: string;
    switch (length) {
      case 1:
        headerIndex = 'First';
        break;
      case 2:
        headerIndex = 'Second';
        break;
      case 3:
        headerIndex = 'Third';
        break;
      case 4:
        headerIndex = 'Fourth';
        break;
      case 5:
        headerIndex = 'Fifth';
        break;
      case 6:
        headerIndex = 'Sixth';
        break;
      case 7:
        headerIndex = 'Seventh';
        break;
      case 8:
        headerIndex = 'Eighth';
        break;
      case 9:
        headerIndex = 'Ninth';
        break;
      case 10:
        headerIndex = 'Tenth';
        break;
      default:
        headerIndex = 'First';
        break;
    }
    return this.originator === 'persona' ? `${headerIndex} Persona` : `${headerIndex} Audience`;
  }

  getSubLabelFromHeader(length: number): string {
    return length === 1 ? 'Required' : 'Optional';
  }

  resetResourceHeaders() {
    this.selections.splice(1, this.selections.length - 1);
    this.resourceHeaderArr.splice(1, this.resourceHeaderArr.length - 1);
    this.addedSelections = 1;
    this.allowAdd = true;
  }

  create() {
    if (!this.selections[0].getValue()) { return; }

    const selections = compact(this.selections.map(resource$ => resource$.getValue())).map(resource => {
      const resourceId = (resource.type === 'persona') ? resource.id : resource.identifier;
      return {
        name: resource.name,
        ui_name: this.uiName && this.uiName.trim(),
        rules: this.buildRulesObject(resource.identifiers, [this.indexBase.shortId]),
        resource_type: resource.type,
        resource_id: resourceId,
        layout_type: this.layoutType,
        allow_dataset_skew: this.allow_dataset_skew,
        allow_unknown_rate_skew : this.allow_unknown_rate_skew,
        service_type: isMolecula() ? MOLECULA : ES,
      };
    });
    const successMsg = 'Report initiated. Once the report completes, you will receive a notification to download and view the results.';
    const errorMsg = 'Index Report could not be initiated. Please try again.';

    this.store.dispatch(new actions.InitiateIndexReport(selections));
    this.actions$.pipe(
      fetchOutcome(actions.InitiateIndexReport.type),
      take(1),
    ).subscribe(
      res => this.snackbar.open(successMsg, null, {
        duration: 4000,
        panelClass: ['check']
      }),
      err => this.snackbar.open(errorMsg, null, {
        duration: 4000,
        panelClass: ['danger']
      }),
      () => {
        this.resetSelections();
        this.resetResourceHeaders();
        this.close.emit()
      }
    )
  }

  updateSelection(resource, index) {
    const selection$ = this.selections[index];
    selection$.next(this.addIndexReportDetails(resource));
  }

  clearSelection(index) {
    const selection$ = this.selections[index];
    selection$.next(null);
  }

  resetSelections() {
    this.uiName = null;
    this.indexBase = defaultIndexBase();
    this.allow_dataset_skew = true;
    this.allow_unknown_rate_skew = true;
    this.layoutType = "simplified";
    forEach(this.selections, (resource$, idx) => {
      if (idx === 0) {
        resource$.next(this.addIndexReportDetails(this.item));
      } else {
        resource$.next(null);
      }
    });
    this.getCurrentSkewSelection();
  }

  getCurrentSkewSelection() {
    const currSel: Array<String> = [];
    this.allow_dataset_skew && !(currSel.indexOf(" Dataset Skew") > -1) ?
      this.addValueCurrSelection(currSel, " Dataset Skew") :
      this.removeValueCurrSelection(currSel, " Dataset Skew");
    this.allow_unknown_rate_skew && !(currSel.indexOf(" Unknown Rate Skew") > -1) ?
      this.addValueCurrSelection(currSel, " Unknown Rate Skew") :
      this.removeValueCurrSelection(currSel, " Unknown Rate Skew");
    this.currentSkewSelection = currSel.length > 0 ? currSel.join() : "Select";
  }

  removeValueCurrSelection(currSel: Array<String>, value: string) {
    return currSel.splice(currSel.indexOf(value), 0);
  }

  addValueCurrSelection(currSel: Array<String>, value: string) {
    return currSel.push(value);
  }

  openSegmentPicker(index) {
    let currentlySelected;
    const selection = this.selections[index].getValue();
    if (selection) {
      currentlySelected = this.segments[selection.identifier];
    }
    this.segmentPickerService.open({
      multi: false,
      title1: 'Index Report',
      title2: 'Select Segments(s)/Audience(s)',
      currentlySelected: currentlySelected ? [currentlySelected] : [],
      insightsContext: 'indexReport',
    }).subscribe(segments => {
      if (!segments) { return; }
      const [segment] = segments;
      this.segments[segment.identifier] = segment;
      // add a type to the segment object, where segments default to 'audience' for index reports
      const typedObject = Object.assign({}, segment, { type: this.isLookALike(segment) ? 'lookalike' : 'audience' });
      this.updateSelection(typedObject, index);
    });
  }

  getSegmentPath(segment: SegmentLike|SegmentV2): string {
    if (!segment) { return; }
    const vendorDisplayNames = get(getStoreState(this.store), ['segmentsHierarchy', 'vendorDisplayNames']);
    return prettyPathParts(segment, vendorDisplayNames).join(' > ');
  }

  cancel() {
    this.resetSelections();
    this.resetResourceHeaders();
    this.close.emit();
  }

  checkBoxChangeEvent() {
    this.getCurrentSkewSelection();
  }

  isNotValid() {
    if (!this.selections[0]) {
      this.tooltipMessage = tooltipMessageV2['indexReportNoSelection'];
      return true
    } else if (isNotValidFileName(this.uiName)) {
      this.tooltipMessage = tooltipMessageV2['indexReportInvalidFileName'];
      return true
    } else {
      return false
    }
  }

  private isLookALike(object: any): object is LookALike {
    return 'current_confidence' in object;
  }

  private addIndexReportDetails(resource) {
    if (!resource.identifier && !resource.identifiers) { return; }

    const indexReportDetails = {
      type: (resource.object_type || resource.type || 'persona') as IndexReportResourceType,
      identifiers: resource.identifiers || { 'included_ids': [[resource.identifier]] }
    }
    return Object.assign({}, resource, indexReportDetails);
  }

  private buildRulesObject(rules: string[][], index_base: string[]): any {
    const includedRules = rules['included_ids'].map(rule => ({ ids: rule }));
    const excludedRules = rules['excluded_ids'] ? rules['excluded_ids'].map(rule => ({ excluded_ids: rule })) : [];
    return [...includedRules, {index_base}, ...excludedRules];
  }
}
