import { CppValidator } from './cpp-validator';

export class Curve {
  id?: string;
  media_type: string;
  params: {
    Base: number,
    Slope: number,
    Acceleration: number,
    Asymptote: number,
    Asymmetry: number,
    CPP: number,
    Universe: number,
    CppOrCpm: number
  };
  is_generic?: boolean;
  error_messages?: string[];
  // TODO: Delete this once reach and penetration are separate curve types PR#1490
  override?: boolean;

  private cppValidator = new CppValidator();
  private _curve_type_name?: string = null;

  constructor(args?: any) {

    this.params = {
      Base: null,
      Slope: null,
      Acceleration: null,
      Asymptote: null,
      Asymmetry: null,
      CPP: null,
      Universe: null,
      CppOrCpm: 0
    };

    if (args) {
      this.id = args.id;
      this.media_type = args.media_type;
      this.is_generic = args.is_generic;
      this.curve_type_name = args.curve_type_name;
      args.params != void(0) && (this.params = args.params);
      // TODO: Delete this once reach and penetration are separate curve types PR#1490
      if (args.override) {
        this.curve_type_name = this.CURVE_TYPE_NAMES_ALT[args.params.CurveType]
      }
    }

  }

  equals(curve: Curve): boolean {
    return curve
      && this.media_type === curve.media_type
      && this.curve_type_name === curve.curve_type_name
  }

  toJSON() {
    // Needed to include curve_type_name, exclude private variables and constants
    const result = {
      id: this.id,
      is_generic: this.is_generic,
      media_type: this.media_type,
      curve_type_name: this.curve_type_name,
      params: this.params,
    };
    return result;
  }

  get curve_type_name(): string {
    return this._curve_type_name;
  }

  set curve_type_name(curve_type_name: string) {
    this._curve_type_name = curve_type_name;
    if (!this.isTRP) {
      this.params.CPP = null;
    }
  }

  get isTRP(): boolean {
    return this.curve_type_name && this.curve_type_name.indexOf("TRP") > -1
  }

  get isValid(): boolean {
    return !!this.media_type
      && !!this.curve_type_name
      && Curve.validateParam(this.params.Base)
      && Curve.validateParam(this.params.Slope)
      && Curve.validateParam(this.params.Acceleration)
      && Curve.validateParam(this.params.Asymptote)
      && Curve.validateParam(this.params.Asymmetry)
      && (!this.isTRP || this.validateCPP());
  }

  private validateCPP(): boolean {
    return this.cppValidator.isValid(this.params.CPP);
  }

  get anyFilled(): boolean {
    return !!this.media_type
      || !!this.curve_type_name
      || Curve.validateParam(this.params.Base)
      || Curve.validateParam(this.params.Slope)
      || Curve.validateParam(this.params.Acceleration)
      || Curve.validateParam(this.params.Asymptote)
      || Curve.validateParam(this.params.Asymmetry)
      || Curve.validateParam(this.params.CPP)
  }

  static validateParam(param): boolean {
    return param == 0 || (param != "" && !isNaN(param) && param != void(0));
  }

  CURVE_TYPE_NAMES = {
    3: "Sales/Cost",
    4: "Sales/TRP",
    1: "Reach/Cost",
    2: "Reach/TRP"
  };
  // TODO: Delete this once reach and penetration are separate curve types PR#1490
  CURVE_TYPE_NAMES_ALT = {
    3: "Sales/Cost",
    4: "Sales/TRP",
    1: "Penetration/Cost",
    2: "Penetration/TRP"
  };
  ALL_OPTION = "-- ALL --";

}

