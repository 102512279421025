import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { Component, ViewChild, ElementRef, AfterViewChecked, Input } from '@angular/core';

import { StackedBarChartColors } from 'app/measure-v3/measure-v3.constants';
import { SubMarket, Market } from 'app/mekko/mekko.reducer';
import * as actions from 'app/measure-v3/measure-v3.actions';

const COLOR_BAR_HEIGHT = 55;

@Component({
  selector: 'ppc-measure-v3-stacked-legend',
  templateUrl: './measure-v3-stacked-legend.component.html',
  styleUrls: ['./measure-v3-stacked-legend.component.sass']
})
export class MeasureV3StackedLegendComponent implements AfterViewChecked {
  mekkoColors = StackedBarChartColors;
  @Input() markets: Market[];
  @Input() legendSubMarkets: SubMarket[];
  expanded = false;
  hasHiddenColors = false
  @Input() groupBy: string;
  @ViewChild("bar", { static: true }) barElement: ElementRef;

  constructor(private store: Store<AppState>) {}

  ngAfterViewChecked() {
    if (this.barElement.nativeElement.scrollHeight == COLOR_BAR_HEIGHT) {
      this.expanded = false;
    }

    this.hasHiddenColors = this.barElement && this.barElement.nativeElement.scrollHeight - this.barElement.nativeElement.clientHeight > 5;
  }

  getColor(subMarket: SubMarket): string {
    return this.mekkoColors[this.legendSubMarkets.indexOf(subMarket)]
  }

  toggleSelectedMarket(market: Market) {
    this.store.dispatch(new actions.ToggleSelectedMarket(market))
  }
}
