<script src="overview-v3.module.ts"></script>
<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>

<header>
  <h1>
    <span class="object-name">Audiences</span><span>Overview</span>
  </h1>
</header>

<main>
  <div class="actions-top-row">
    <div class="top-row-left">
      <div class="filter-option-wrapper">
        <div>Type</div>
        <mat-select [ngModel]="typeFilter$ | async" (ngModelChange)="updateFilter($event, 'type')" class="filter-option">
          <mat-option *ngFor="let type of types" [value]="type">{{type | titlecase}}</mat-option>
        </mat-select>
      </div>
      <div class="filter-option-wrapper">
        <div>Status</div>
        <mat-select [ngModel]="statusFilter$ | async" (ngModelChange)="updateFilter($event, 'status')" class="filter-option">
          <mat-option *ngFor="let status of statuses" [value]="status">{{status | titlecase}}</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="top-row-right">
      <app-popup class="search" [rightPct]="0">
        <i popupTrigger class="fa fa-search top-row"></i>
        <div popupBody class="popup-items">
          <ng-container>
            <ppc-input #search class="search-body" placeholder="Search" [noLabel]="true" [noErrorContainer]="true"
              [(ngModel)]="searchQuery">
              <div prefix class="search-prefix">
                <i class="fa fa-search"></i>
              </div>
              <div suffix [class.hide]="!searchQuery" (click)="resetSearch()">
                <i class="fa fa-close"></i>
              </div>
            </ppc-input>
          </ng-container>
        </div>
      </app-popup>
      
      <button *ngIf="userCanCreateAudience" class="audience-builder-action" (click)="openCreateAudience()"
        ppcTooltip="Build New">
        <i class="fa fa-plus button-content button-icon"></i>
      </button>
      
      <button class="audience-builder-action" (click)="downloadTableAsExcel()" ppcTooltip="Export to .XLSX">
        <i class="fa fa-download button-content button-icon"></i>
      </button>
      
      <button *ngIf="userCanCreateMotivationAudience" class="audience-builder-action" (click)="toggleMotivationAudienceForm()"
        ppcTooltip="Add Motivation">
        <i class="fa fa-trophy button-content button-icon"></i>
      </button>
    </div>
  </div>

  <ngx-datatable
    #dataTable
    class="data-table"
    [columnMode]="'force'"
    [rows]="visibleRows"
    [headerHeight]="60"
    [footerHeight]="60"
    [rowHeight]="50"
    [reorderable]="false"
    [cssClasses]="{sortAscending: 'fa fa-sort-asc', sortDescending: 'fa fa-sort-desc'}"
    [sorts]="[{prop: 'created_at', dir: 'desc'}]"
    [limit]="20"
  >
    <ngx-datatable-column
      name="type"
      [width]=60
      [minWidth]=50
      [maxWidth]=80
      [resizeable]="true"
    >
      <ng-template ngx-datatable-header-template
        let-column="column" 
        let-sort="sortFn"
        let-sortDir="sortDir">
        <span class="datatable-header-cell-label" [ppcTooltip]="audienceTooltipMessage['audienceType']" (click)="sort($event, sortDir, sortFn)">
          {{column.name}}
        </span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div>
          <div [ngSwitch]="row.type" class="margin-left-10" >
            <span
              *ngSwitchCase="'audience'"
              ppcTooltip="Audience">
              <i class="fa fa-user"></i>
            </span>
            <span
              *ngSwitchCase="'lookalike'"
              ppcTooltip="Lookalike">
              <i class="fa fa-users"></i>
            </span>
            <span
              *ngSwitchCase="'outcome'"
              ppcTooltip="Outcome">
              <i class="fa fa-user-circle-o"></i>
            </span>
            <span
              *ngSwitchCase="'motivation'"
              ppcTooltip="Motivation">
              <i class="fa fa-trophy"></i>
            </span>
            <div *ngSwitchDefault>{{row.type}}</div>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="name"
      prop="name"
      headerClass="name-column-header"
      cellClass="name-column-cell"
      [width]=500
      [minWidth]=300
      [maxWidth]=600
      [resizeable]="true"
    >
      <ng-template ngx-datatable-header-template 
                   let-column="column"
                   let-sort="sortFn"
                   let-sortDir="sortDir">
        <span class="datatable-header-cell-label" [ppcTooltip]="audienceTooltipMessage['audienceName']" (click)="sort($event, sortDir, sortFn)">
          {{column.name}}
        </span>
      </ng-template>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <span
          [class]="'hoverable'"
          [ppcTooltip]="value"
          (click)="determineRowClickAction(row)"
        >
          {{value}}
        </span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="status"
      prop="job_status"
      [minWidth]=50
      headerClass="middle-column-header"
      [resizeable]="true"
      [comparator]="compareStatus"
    >
      <ng-template ngx-datatable-header-template 
                   let-column="column"
                   let-sort="sortFn"
                   let-sortDir="sortDir">
        <span class="datatable-header-cell-label" [ppcTooltip]="audienceTooltipMessage['audienceStatus']" (click)="sort($event, sortDir, sortFn)">
          {{column.name}}
        </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span [class]="jobStatusClass(value)"></span>
        {{jobStatusDisplay(value)}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="created"
      prop="created_at"
      [minWidth]=50
      headerClass="middle-column-header numeric-header"
      cellClass="numeric-cell"
      [resizeable]="true"
    >
      <ng-template ngx-datatable-header-template 
                   let-column="column"
                   let-sort="sortFn"
                   let-sortDir="sortDir">
        <span class="datatable-header-cell-label" [ppcTooltip]="audienceTooltipMessage['audienceCreated']" (click)="sort($event, sortDir, sortFn)">
          {{column.name}}
        </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{value | date: 'shortDate'}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="refresh end"
      prop="refresh_end_date"
      [minWidth]=50
      headerClass="middle-column-header numeric-header"
      cellClass="numeric-cell"
      [resizeable]="true"
    >
      <ng-template ngx-datatable-header-template 
                   let-column="column"
                   let-sort="sortFn"
                   let-sortDir="sortDir">
        <span class="datatable-header-cell-label" [ppcTooltip]="audienceTooltipMessage['audienceRefreshEnd']" (click)="sort($event, sortDir, sortFn)">
          {{column.name}}
        </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template >
          <div [class.red]="expiredDate(value)">{{value | date: 'shortDate'}}</div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="expiration"
      prop="expiration"
      [minWidth]=50
      headerClass="middle-column-header numeric-header"
      cellClass="numeric-cell"
      [resizeable]="true"
    >
      <ng-template ngx-datatable-header-template 
                   let-column="column"
                   let-sort="sortFn"
                   let-sortDir="sortDir">
        <span class="datatable-header-cell-label" [ppcTooltip]="audienceTooltipMessage['audienceExpiration']" (click)="sort($event, sortDir, sortFn)">
          {{column.name}}
        </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div [class.red]="expiredDate(value)">{{value | date: 'shortDate'}}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="people count"
      prop="count.people.global"
      [minWidth]=50
      headerClass="middle-column-header numeric-header"
      cellClass="numeric-cell"
      [resizeable]="true"
    >
      <ng-template ngx-datatable-header-template
                   let-column="column"
                   let-sort="sortFn"
                   let-sortDir="sortDir">
        <span class="datatable-header-cell-label"
             [ppcTooltip]="audienceTooltipMessage['peopleCount']"
             (click)="sort($event, sortDir, sortFn)">
          {{column.name}}
        </span>
      </ng-template>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div *ngIf="!isPeopleCountEstimated(row); else estimated">
          {{ value | number }}
        </div>
        <ng-template #estimated>
          <div
            [ppcTooltip]="estimatedPeopleCountTooltipMessage(value, row)"
            tooltipAlignment="right"
            [tooltipOffset]="{x: -10}"
            >
            {{ displayPeopleCountEstimate(value) }}
          </div>
        </ng-template>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Addressable Counts"
      prop="addressable_count"
      [minWidth]=50
      headerClass="middle-column-header numeric-header"
      cellClass="numeric-cell"
      [resizeable]="true"
      [comparator]="compareAddressableCount"
      *ngIf="canDisplayAddressableCounts"
    >
      <ng-template
        ngx-datatable-header-template
        let-column="column"
        let-sort="sortFn"
        let-sortDir="sortDir"
      >
        <span
          class="datatable-header-cell-label"
          (click)="sort($event, sortDir, sortFn)"
          [ppcTooltip]="audienceTooltipMessage['addressableCount']">
          {{column.name}}
        </span>
      </ng-template>
      <ng-template
        let-row="row"
        let-value="value"
        ngx-datatable-cell-template
      >
        <div
          [ppcTooltip]="addressableCountTooltip(row)"
          tooltipAlignment="right"
          [tooltipOffset]="{x: -10}"
        >
          {{ addressableCountsDisplay(row) }}
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="TV Panelists"
      prop="panelist_count"
      [minWidth]=50
      headerClass="middle-column-header numeric-header"
      cellClass="numeric-cell"
      [resizeable]="true"
      [comparator]="compareTvPanelists"
      *ngIf="!hideTVPanelistColumn(activeRegion)"
      
      >
      <ng-template ngx-datatable-header-template
                   let-column="column"
                   let-sort="sortFn"
                   let-sortDir="sortDir">
        <span class="datatable-header-cell-label"
              [ppcTooltip]="tvPanelistsTooltip()"
              (click)="sort($event, sortDir, sortFn)"         
              >
          {{column.name}}
        </span>
      </ng-template>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div
          [ppcTooltip]="panelistTooltip(row)"
          [tooltipDisabled]="panelistTooltipDisabled(row)"
          tooltipAlignment="right"
          [tooltipOffset]="{x: -10}"
        >
          {{ tvPanelistsDisplay(row) }}
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Segments Count"
      prop="rules"
      [minWidth]=50
      headerClass="middle-column-header numeric-header"
      cellClass="numeric-cell"
      [resizeable]="true"
      [sortable]="false"
      *ngIf="canViewSegmentsCount"
      >
      <ng-template
        ngx-datatable-header-template
        let-column="column"
      >
        <span class="datatable-header-cell-label"
          [ppcTooltip]="audienceTooltipMessage['segmentCount']">
          {{column.name}}
        </span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div>
          {{ displaySegmentCount(row) }}
        </div>
      </ng-template>
    </ngx-datatable-column>


    <ngx-datatable-column
      name="actions"
      [minWidth]=80
      [maxWidth]=90
      headerClass="actions-column-header"
      cellClass="actions-column-cell"
      [resizeable]="true"
      [sortable]="false"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ppc-audience-action-menu
          *ngIf="isAudience(row)"
          [audience]="row"
          [isDetailView]="false"
          [activeRegion]="activeRegion"
          [activationErrorCode]="activationErrorCode"
          (openAudienceDetail)="openAudienceDetail($event)"
          (openActivationOverlay)="openActivationOverlay($event)"
          (openIndexReportForm)="openIndexReportForm($event)"
        >
        </ppc-audience-action-menu>
        <!-- TODO Move *ngIf logic out to the action-menu for audience -->
        <ppc-action-menu
          *ngIf="!isAudience(row)"
          [ppcItem]="row"
          [isDetailView]="false"
          [activeRegion]="activeRegion"
          (openDetail)="handleViewDetail($event)"
          (openActivationOverlay)="openActivationOverlay($event)"
          (sendToTardiis)="sendToTardiis($event)"
          (openIndexReportForm)="openIndexReportForm($event)"
        ></ppc-action-menu>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</main>

<app-slide-in-overlay class="audience-detail" [percentOfParentCovered]="100" #audienceDetailOverlay>
  <ppc-audience-detail
    [audience]="audienceDetailAudience"
    [panelistDataSourceMessage]="panelistDataSourceMessage"
    [regionIsNotAustralia]="regionIsNotAustralia()"
    [canDisplayAddressableCounts]="canDisplayAddressableCounts"
    (onClose)="audienceDetailOverlay.toggleState()"
    (openActivationOverlay)="toggleDetailAndActivation($event)"
    (openIndexReportForm)="openIndexReportForm($event, true)"
  >
  </ppc-audience-detail>
</app-slide-in-overlay>

<app-slide-in-overlay class="lookalike-detail" [percentOfParentCovered]="100" #lookalikeDetailOverlay>
  <ppc-lookalike-detail
    [ppcObject]="detailObject"
    [panelistDataSourceMessage]="panelistDataSourceMessage"
    [regionIsNotAustralia]="regionIsNotAustralia()"
    [canDisplayAddressableCounts]="canDisplayAddressableCounts"
    (onClose)="lookalikeDetailOverlay.toggleState()"
    (openActivationOverlay)="toggleDetailAndActivation($event)"
    (sendToTardiis)="sendToTardiis($event)"
    (openIndexReportForm)="openIndexReportForm($event, true)"
  >
  </ppc-lookalike-detail>
</app-slide-in-overlay>

<app-slide-in-overlay class="motivation-detail" [percentOfParentCovered]="100" #motivationDetailOverlay>
  <ppc-motivation-detail
    [ppcObject]="detailMotivation"
    [panelistDataSourceMessage]="panelistDataSourceMessage"
    [regionIsNotAustralia]="regionIsNotAustralia()"
    [canDisplayAddressableCounts]="canDisplayAddressableCounts"
    (onClose)="motivationDetailOverlay.toggleState()"
    (openActivationOverlay)="toggleDetailAndActivation($event)"
    (sendToTardiis)="sendToTardiis($event)"
    (openIndexReportForm)="openIndexReportForm($event, true)"
  >
  </ppc-motivation-detail>
</app-slide-in-overlay>

<app-slide-in-overlay [percentOfParentCovered]="50" #lookalikeOverlay>
  <app-lookalike-overlay
    *ngIf="lookalikeOverlay?.isPositionOn"
    [lookalikeUnderEdit]="lookalikeUnderEdit"
    (onClose)="lookalikeOverlay.toggleState()"
  >
  </app-lookalike-overlay>
</app-slide-in-overlay>

<ppc-create-lookalike-modal
  #createLookalikeModal
  [lookalikeUnderEdit]="lookalikeUnderEdit"
>
</ppc-create-lookalike-modal>

<app-slide-in-overlay [percentOfParentCovered]="50" #activationOverlay>
  <ppc-activation-overlay
    [ppcObject]="activationObject"
    [client]="activeClient"
    [overviewContext]="overviewContext$ | async"
    (onClose)="activationOverlay.toggleState()"
    (openDestinationsWithPartner)="openDestinationsOverlay($event)"
    (addNewDestination)="createDestinationOverlay($event)"
  >
  </ppc-activation-overlay>
</app-slide-in-overlay>

<app-slide-in-overlay [percentOfParentCovered]="50" #activationDestinationsOverlay>
  <ppc-activation-destinations-overlay
    [ppcObject]="destinationsObject"
    [partner]="destinationsPartner"
    (onSendObjectToPartner)="sendObjectToDestinations($event)"
    (onRedirectToNewDestination)="createDestinationOverlay($event)"
    (onClose)="activationDestinationsOverlay.toggleState()"
    (reopenSendToPartnerOverlay)="reopenPartnerOverlay()"
  >
  </ppc-activation-destinations-overlay>
</app-slide-in-overlay>

<app-slide-in-overlay [percentOfParentCovered]="50" #newDestinationOverlay>
  <ppc-new-destination-overlay
    [ppcObject]="activationObject"
    [partner]="destinationsPartner"
    (onClose)="onNewDestinationClose($event)"
    (openDestinationsWithPartner)="openDestinationsOverlay($event)"
    #newDestinationMenu
  >
  </ppc-new-destination-overlay>
</app-slide-in-overlay>

<app-slide-in-overlay
  [active]="!!indexReportFormOpen"
  [width]="550">
  <ppc-index-report-form
    originator="audience"
    [resources]="rows"
    [item]="indexReportSelection$.getValue()"
    (close)="toggleIndexReportForm()">
  </ppc-index-report-form>
</app-slide-in-overlay>

<app-slide-in-overlay [active]="!!motivationAudienceFormOpen" [percentOfParentCovered]="50">
  <ppc-motivation-audience-form *ngIf="motivationAudienceFormOpen" (close)="toggleMotivationAudienceForm()"></ppc-motivation-audience-form>
</app-slide-in-overlay>

<div *ngIf="openInsightsDependancyModal">
  <ppc-insight-dependancy [dependantError]="dependantError" (onDialogClose)="handleDialogClose()"></ppc-insight-dependancy>
</div>
