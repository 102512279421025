import { UrlService } from 'app/services/url.service';
import { Injectable } from '@angular/core';
import { ScriptService } from './script.service';
import { User } from 'app/users/user.interface';
import { filter } from 'rxjs/operators';
import { Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { loggedInUser } from 'app/users/user.reducer';
import {environment} from "environments/environment";
import { isDefined } from 'app/shared/utils/utils';

@Injectable()
export class DataDogService {
  navigationLogger: any;
  DD_LOGS: any;
  previousUrl: string;

  constructor(private scriptService: ScriptService, private urlService: UrlService, private actions$: Actions, private store: Store<AppState>) {
    this.isActive() && this.scriptService.load('datadogLogs').then(data => {
      if (data[0]['loaded']) {
        this.DD_LOGS = window['DD_LOGS'];
        this.DD_LOGS.init({
          clientToken: 'pub99cd7d68038656ad6488a442f0369350',
          forwardErrorsToLogs: true,
        });
        this.navigationLogger = window['DD_LOGS'].createLogger('navigationLogger');
        this.navigationLogger.addContext("env", environment.envName);
        this.navigationLogger.addContext("service", "ppc-ui");
        this.store.select('users').pipe(select(loggedInUser), filter(isDefined)).subscribe((user) => {
          this.addUserValuesToLogs(user);
        })
        this.urlService.currentUrl$.subscribe(url => {
          const message = this.previousUrl && this.previousUrl.length > 0 ? `Navigation: from: ${this.previousUrl} to: ${url}` : `Initial Navigation: to: ${url}`
          this.navigationLogger.info(message, { to_url: url, from_url: this.previousUrl })
          this.previousUrl = url;
        })
      }
    })
  }

  addUserValuesToLogs(user: User) {
    if (!this.isActive()) {return; }
    this.DD_LOGS.addLoggerGlobalContext('email', user.email)
    if (user.roles && user.roles.length > 0) {
      this.DD_LOGS.addLoggerGlobalContext(
        'roles', user.roles.map(role => role.name).join(",")
      )
    }
    this.DD_LOGS.addLoggerGlobalContext('role_ids', user.role_ids)
    this.DD_LOGS.addLoggerGlobalContext('user_id', user.id)
    this.DD_LOGS.addLoggerGlobalContext('lion_login', user.lion_login)
    this.DD_LOGS.addLoggerGlobalContext('okta_login', user.okta_login)
    this.DD_LOGS.addLoggerGlobalContext('full_name', `${user.first_name} ${user.last_name}`)
  }

  isActive() {
    return environment.envName !== 'dev'
  }
}
