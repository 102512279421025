import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { find } from 'lodash';

import { AppState } from "app/reducers";
import { InputValidator } from 'app/shared/components/ppc-input/ppc-input.component';

import { LookalikeV2 } from 'app/lookalikes-v2/lookalike-v2.model';
import { selectLookalikesWithType } from 'app/lookalikes-v3/lookalike-v3.reducers';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CreateLookalikeService implements OnDestroy {
  lookalike: LookalikeV2;
  lookalike$: Observable<LookalikeV2>;
  lookalikes: LookalikeV2[];

  nameValidations: InputValidator[] = [
    {
      isValid: (value: string) => this.lookalike.name && this.lookalike.name.length,
      errorMessage: `Please name your lookalike`
    },
    {
      isValid: (value: string) => {
        return this.hasUniqueName;
      },
      errorMessage: `Duplicate Lookalike Name`
    },
    {
      isValid: (value: string) => {
        return value.trim().length > 0 || value.length === 0;
      },
      errorMessage: `Name cannot be all whitespace`
    }
  ];

  constructor(
    store: Store<AppState>
  ) {
    store.select('lookalikesV3').pipe(select(selectLookalikesWithType))
      .pipe(untilDestroyed(this))
      .subscribe(lookalikes => {
        this.lookalikes = lookalikes;
      });
  }

  ngOnDestroy() {
  }

  public get hasUniqueName() {
    return !find(this.lookalikes, lookalike => lookalike.name.trim().toLowerCase() === this.lookalike.name.trim().toLowerCase());
  }
}
