import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, merge as observableMerge, combineLatest as observableCombineLatest } from 'rxjs';
import { map, mergeMap, filter, take, withLatestFrom, switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { find, first } from 'lodash';

import { Mekko, SubMarket, selectActiveMekko, Market, selectMekkos, MekkoPayload, NewMekko } from './mekko.reducer';
import { fetchResource, ResetFetchState } from 'app/shared/utils/fetch-state';
import * as actions from './mekko.actions';
import { AppState } from 'app/reducers';
import * as insightsActions from 'app/insights/insights.actions';
import { fullContext } from 'app/hierarchy/hierarchy.reducers';
import { ChangeContext } from 'app/hierarchy/hierarchy.actions';
import { UrlService } from 'app/services/url.service';
import { userPreferencesUrl, mekkosUpdateOrderUrl, mekkosCopyUrl, mekkosUrl, subMarketsUpdateOrderUrl, subMarketsUrl } from 'app/shared/constants/insights.urls';

@Injectable({
  providedIn: 'root'
})
export class MekkoService {

  @Effect()
  fetchMekkos$ = this.actions$.pipe(
    ofType(actions.FetchMekkos.type),
    (
      fetchResource(
        action => this.getMekkosForProduct(action.product_slug).pipe(
          map(mekkos => new actions.LoadMekkos(mekkos)))
      )
    ));

  @Effect()
  saveMekko$ = this.actions$.pipe(
    ofType(actions.SaveMekko.type),
    fetchResource(
      action => this.saveMekko(action.mekko).pipe(map(mekko => new actions.LoadMekko(mekko)))
    )
  );

  @Effect()
  copyMekko$ = this.actions$.pipe(
    ofType(actions.CopyMekko.type),
    (
      fetchResource(
        action => this.copyMekko(action.mekko).map(mekko => new actions.LoadMekko(mekko)))
    )
  );


  @Effect()
  destroyMekko$ = this.actions$.pipe(
    ofType(actions.DestroyMekko.type),
    fetchResource(
      ({ mekko }) => this.destroyMekko(mekko).map(mekko => new actions.RemoveMekko(mekko))
    )
  );

  @Effect()
  fetchSubMarkets$ = this.actions$.pipe(
    ofType(actions.FetchSubMarkets.type),
    (
      fetchResource(
        () => this.getSubMarketsForProduct().pipe(map(sms => new actions.LoadSubMarkets(sms)))
      )
    ));


  @Effect()
  saveSubMarket$ = this.actions$.pipe(
    ofType(actions.SaveSubMarket.type),
    (
      fetchResource(
        action => this.saveSubMarket(action.subMarket).pipe(map(sm => new actions.LoadSubMarket(sm)))
      )
    ));

  @Effect()
  destroySubMarket$ = this.actions$.pipe(
    ofType(actions.DestroySubMarket.type),
    (
      fetchResource(
        action => this.destroySubMarket(action.subMarket).pipe(map(subMarket => new insightsActions.RemoveSubMarketBucketEntries("grow", subMarket)))
      )
    ));

  @Effect()
  upsertSubMarket$ = this.actions$.pipe(
    ofType(actions.UpdateSubMarket.type),
    map((action: actions.UpdateSubMarket) => new actions.SaveSubMarket(action.subMarket)));


  @Effect()
  upsertMarket$ = this.actions$.pipe(
    ofType(actions.UpsertMarket.type),
    mergeMap(() => this.store.select("mekkos").pipe(select(selectActiveMekko), take(1))),
    filter(Boolean),
    map((mekko: Mekko) => new actions.SaveMekko(mekko)), );

  @Effect()
  destroyMarket$ = this.actions$.pipe(
    ofType(actions.DestroyMarket.type),
    withLatestFrom(this.store.select("mekkos").pipe(select(selectActiveMekko))),
    filter(([action, mekko]) => !!mekko),
    map(([action, mekko]: [actions.DestroyMarket, Mekko]) =>
      new actions.SaveMekko({
        ...mekko,
        markets: mekko.markets.concat({...action.market, _destroy: true} as Market)
      })),
  )

  @Effect()
  updateSubMarketOrder$ = this.actions$.pipe(
    ofType(actions.UpdateSubMarketOrder.type),
    (
      fetchResource(
        action => this.updateSubMarketOrder(action.subMarkets).pipe(map(subMarkets => new actions.LoadSubMarkets(subMarkets)))
      )
    ));

  @Effect()
  updateMarketOrder$ = this.actions$.pipe(
    ofType(actions.UpdateMarketOrder.type),
    (
      fetchResource(action => this.updateMarketOrder(action.markets).pipe(map(mekko => new actions.LoadMekko(mekko))))
    ));

  @Effect()
  changeContext$ = fullContext(this.store).pipe(
    mergeMap(() => {
      return [
        new ResetFetchState(actions.FetchMekkos),
        new actions.ClearSelectedSubMarkets(),
        new ResetFetchState(actions.FetchSubMarkets),
      ]
    })
  )

  @Effect()
  clearSelectedSubMarkets$ = this.actions$.pipe(
    ofType(actions.SetSelectedMekko.type),
    map(() => new actions.ClearSelectedSubMarkets()));

  @Effect()
  getDefaultMekko$ = this.urlService.pathParams$.pipe(
    map(params => params.productSlug),
    filter(Boolean),
    map(() => new actions.FetchDefaultMekko())
  )

  @Effect()
  fetchDefaultMekko$ = this.actions$.pipe(
    ofType(actions.FetchDefaultMekko.type),
    (
      fetchResource(
        () => this.fetchDefaultMekko().pipe(map(defaultMekkoId => new actions.LoadDefaultMekko(defaultMekkoId)))
      )
    ))

  @Effect()
  saveDefaultMekko$ = this.actions$.pipe(
    ofType(actions.SaveDefaultMekko.type),
    (
      fetchResource(
        action => this.saveDefaultMekko(action.defaultMekko).pipe(map(defaultMekkoId => new actions.LoadDefaultMekko(defaultMekkoId)))
      )
    ))

  @Effect()
  defaultSelectedMekko$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    switchMap(() => {
      return observableMerge(
        observableCombineLatest(
          this.actions$.pipe(ofType(actions.LoadMekkos.type)),
          this.actions$.pipe(ofType(actions.LoadDefaultMekko.type))
        ).pipe(take(1)),
        this.actions$.pipe(
          ofType(actions.RemoveMekko.type),
          withLatestFrom(this.store.select('mekkos', 'selectedMekkoId')),
          filter(([action, selectedMekkoId]: [actions.RemoveMekko, number]) => action.mekko.id == selectedMekkoId)
        ),
        this.store.select("mekkos").pipe(
          select(selectMekkos),
          filter(mekkos => mekkos.length == 0),
          switchMap(() => this.actions$.pipe(ofType(actions.LoadMekko.type), take(1)))
        ),
      ).pipe(
        withLatestFrom(
          this.store.select("mekkos").pipe(select(selectMekkos)),
          this.store.select("mekkos", "selectedMekkoId"),
          this.urlService.pathParams$.pipe(map(params => +params.mekkoId))
        ),
        filter(([_, mekkos, selectedMekkoId, paramsMekkoId]) => (!paramsMekkoId || !find(mekkos, {id: paramsMekkoId}) || !selectedMekkoId || !find(mekkos, {id: selectedMekkoId})) && !!mekkos.length),
        map(([_, mekkos, selectedMekkoId, paramsMekkoId]) => new actions.SetSelectedMekko((find(mekkos, {id: paramsMekkoId}) || find(mekkos, {default: true}) || first(mekkos)) as Mekko)),
      )
    })
  )

  @Effect()
  fetchSubMarketsForMekko$ = this.store.select("mekkos", "selectedMekkoId").pipe(
    filter(Boolean),
    map(() => new actions.FetchSubMarkets())
  )

  constructor(private http: HttpClient, private actions$: Actions, private store: Store<AppState>, private urlService: UrlService) { }

  // If a product_slug is not provided the active context will inserted by HierarchyInterceptor
  getMekkosForProduct(product_slug?: string): Observable<Mekko[]> {
    const headers = product_slug ? {'x-context': product_slug} : {}
    return this.http.get(mekkosUrl(), {headers}) as Observable<Mekko[]>;
  }

  getSubMarketsForProduct(): Observable<SubMarket[]> {
    return this.http.get(subMarketsUrl()) as Observable<SubMarket[]>;
  }

  saveSubMarket(subMarket: SubMarket): Observable<SubMarket> {
    return this.http[subMarket.id ? 'put' : 'post'](
      subMarketsUrl(subMarket.id), {sub_market: subMarket}
    ) as Observable<SubMarket>
  }

  destroySubMarket(subMarket: SubMarket): Observable<SubMarket> {
    return this.http.delete(subMarketsUrl(subMarket.id)) as Observable<SubMarket>
  }

  updateSubMarketOrder(subMarkets: Partial<SubMarket[]>): Observable<SubMarket[]> {
    return this.http.post(subMarketsUpdateOrderUrl(), { sub_markets: subMarkets }) as Observable<SubMarket[]>;
  }

  updateMarketOrder(markets: Partial<Market[]>): Observable<Mekko> {
    return this.http.post(mekkosUpdateOrderUrl(), { markets: markets }) as Observable<Mekko>;
  }

  saveMekko(mekko: Mekko): Observable<Mekko> {
    return this.http[mekko.id ? 'put' : 'post'](
      mekkosUrl(mekko.id), {mekko: this.transformMekkoNestedAttributes(mekko)}
    ) as Observable<Mekko>
  }

  copyMekko(mekko: Mekko): Observable<Mekko> {
    return this.http.post(mekkosCopyUrl(mekko.id), {mekko}) as Observable<Mekko>;
  }


  destroyMekko(mekko: Mekko): Observable<Mekko> {
    return this.http.delete(mekkosUrl(mekko.id)) as Observable<Mekko>
  }


  fetchDefaultMekko(): Observable<number> {
    return this.http.get(userPreferencesUrl("grow-v3-default-mekko")) as Observable<number>;
  }

  saveDefaultMekko(mekkoId: number): Observable<number> {
    return this.http.put(userPreferencesUrl("grow-v3-default-mekko"), {config: mekkoId}) as Observable<number>;
  }

  transformMekkoNestedAttributes(mekko: NewMekko): MekkoPayload {
    return {
      ...mekko,
      markets_attributes: mekko.markets.map(market => {
        return {
          ...market,
          sub_markets_attributes: market.sub_markets
        }
      })
    }
  }
}
