<div class="select-box" [class.open]="open">
  <button #trigger class="select-box-trigger" (focus)="toggle()" (click)="toggle()" matRipple>
    <span>
      {{ hasValue() && !multi ? getText() : placeholder + " (" + (value ? value.length:0) + ")" }}
    </span>
    <i class="fa fa-caret-down" style="justify-self: flex-end;"></i>
  </button>
  <div class="select-box-options" [@state]="open ? 'open': 'closed'" [style.width.px]="dropdownWidth">
    <button *ngFor="let item of items" (click)="select($event,item)" [class.selected]="isSelected(item)"
            [tabIndex]="open ? 0 : -1" matRipple>
      <span>{{item[labelPath]}}</span>
      <i class="fa fa-check" *ngIf="isSelected(item)"></i>
    </button>
  </div>
</div>
