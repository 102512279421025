import * as bodybuilder from 'bodybuilder';
import { BuilderAudience, AudienceRuleGroup, AudienceRuleEntry, AudienceBooleanType } from './audience-builder.models';
import { filter as _filter, get, reduce, flatMap } from 'lodash';
import { SegmentV2 } from '../audiences/discover/segment-v2.model';
import { environment } from 'environments/environment';
import { MOLECULA } from 'app/shared/utils/constants';

export function isEntrySegment(entry: AudienceRuleEntry) {
  return "identifier" in entry;
}

export function includesAtLeastOneSegment(entry: AudienceRuleEntry): boolean {
  if (isEntrySegment(entry)) {return true; }
  return (entry as AudienceRuleGroup).items.filter(entry => isEntrySegment(entry) || (entry as AudienceRuleGroup).type !== "not")
    .some(includesAtLeastOneSegment);
}

export function getAllSegments(rules: AudienceRuleGroup): SegmentV2[] {
  return flatMap(rules.items, entry => isEntrySegment(entry) ? entry as SegmentV2 : getAllSegments(entry as AudienceRuleGroup))
}

export function rulesToQuery(rules: AudienceRuleGroup): any {
  const query = bodybuilder();
  query.query("bool", b => {
    reduce(rules.items, (query, group) => {
      return addLevelToQuery(query, group, rules.type)
    }, b)
    return b
  })
  return query.build();
}

export function getBuilderAudienceIdentifiers(entry: AudienceRuleEntry) {
  if (!includesAtLeastOneSegment(entry)) { return []; }
  if (isEntrySegment(entry) ) {
    return (entry as SegmentV2).identifier;
  } else {
    if (!(entry as AudienceRuleGroup).items.length) { return []; }
    return (entry as AudienceRuleGroup).items.map(item => getBuilderAudienceIdentifiers(item));
  }
}

export function buildBuilderAudienceContext(rules: AudienceRuleGroup) {
  const included = getBuilderAudienceIdentifiers(rules.items[0]).filter(group => group.length > 0);
  const excluded = getBuilderAudienceIdentifiers(rules.items[1]).filter(group => group.length > 0);
  const context = {
    included,
    excluded
  }
  return  {
    serviceType: MOLECULA,
    isVendorQuery: false,
    includeIndexValues: false,
    context,
  }
}

export function addLevelToQuery(query: bodybuilder.QuerySubFilterBuilder, entry: AudienceRuleEntry, parentBooleanType: AudienceBooleanType): bodybuilder.QuerySubFilterBuilder {
  if (!includesAtLeastOneSegment(entry)) {return query}
  if (isEntrySegment(entry)) {
    return addSegmentToQuery(query, entry as SegmentV2, parentBooleanType)
  } else {
    if (!(entry as AudienceRuleGroup).items.length) {return query; }
    return addGroupToQuery(query, entry as AudienceRuleGroup, parentBooleanType)
  }
}

export function addGroupToQuery(query: bodybuilder.QuerySubFilterBuilder, group: AudienceRuleGroup, parentBooleanType: AudienceBooleanType): bodybuilder.QuerySubFilterBuilder {
  if (group.type === "not") {
    // Default must_not es queries use OR logic. we force them to use AND here by putting them into a single bool group and ANDing them together
    return query.notQuery("bool", b => {
      return reduce(group.items, (query, childGroup) => {
        return addLevelToQuery(query, childGroup, "and")
      }, b)
    })
  }
  return query[`${parentBooleanType}Query`]("bool", b => {
    return reduce(group.items, (query, childGroup) => {
      return addLevelToQuery(query, childGroup, group.type)
    }, b)
  })
}

export function addSegmentToQuery(query: bodybuilder.QuerySubFilterBuilder, segment: SegmentV2, parentBooleanType: AudienceBooleanType): bodybuilder.QuerySubFilterBuilder {
  return query[`${parentBooleanType}Query`]("term", "segments", segment.identifier);
}

export function getSegmentCount(segment: SegmentV2) {
  const query = environment.isTier3 ? ["count", "matched"] : ["count", "people", "global"];
  return get(segment, query, 0);
}

export function newAudience(prefix?: string): BuilderAudience {
  return {
    name: prefix || "New Audience",
    description: "",
    rules: {
      type: "and",
      items: [
        {
          type: "and",
          acceptsSegments: false,
          items: [
            {
              type: "or",
              acceptsSegments: true,
              items: []
            }
          ]
        },
        {
          type: "not",
          acceptsSegments: false,
          items: [
            {
              type: "or",
              acceptsSegments: true,
              items: []
            }
          ]
        }
      ]
    }
  }
}

export function newPersonaAudience(): BuilderAudience {
  return newAudience("Unnamed Persona");
}

export function saveAudienceDisabled(includesSegment: boolean, canSave: boolean, hasUniqueName: boolean, hasName: boolean, isNameWithinCharacterLimit: boolean, hasMultiCustomGWISurveySegments: boolean): boolean {
  return !(includesSegment && canSave && hasUniqueName && hasName && isNameWithinCharacterLimit && !hasMultiCustomGWISurveySegments);
}

export function customGWISurveySegments(audience) {
  if (!environment.isTier3) {return []; }
  const segments = getAllSegments(audience.rules);
  return _filter(segments, segment => segment.vendor_name !== "ppc" && !["coresurvey", "pace"].includes(segment.vendor_type));
}

export function getCustomSurveyRoot(segment) {
  return get(segment, ['path', 'identifiers', '1']);
}

// TOOLTIP
export const TOOLTIP_REQ_NAME_AND_VALID_SEGMENT = 'Name and Add Segments to your ';
export const TOOLTIP_REQ_NAME = 'Name Your ';
export const TOOLTIP_REQ_VALID_SEGMENT = 'Must have segments in INCLUDE group to save';
export const TOOLTIP_ZERO_COUNT = "Count for one or more member segments has changed to zero.";
export const TOOLTIP_MULTI_CUSTOM_SURVEYS = "You can't have two custom survey sources.";

export function saveAudienceTooltip(includesSegment: boolean, hasName: boolean, hasMultiCustomGWISurveySegments: boolean, tooltipSubject = "Audience"): string {
  if (!hasName && !includesSegment) {
    return TOOLTIP_REQ_NAME_AND_VALID_SEGMENT + tooltipSubject;
  } else if (!hasName) {
    return TOOLTIP_REQ_NAME + tooltipSubject;
  } else if (!includesSegment) {
    return TOOLTIP_REQ_VALID_SEGMENT;
  } else if (hasMultiCustomGWISurveySegments) {
    return TOOLTIP_MULTI_CUSTOM_SURVEYS;
  }
}
