<div class="asset-setup-component">
  <div class="asset-setup-header">
    <div class="associated-assets-title">
      <div class="gold-header">ASSOCIATED CREATIVE ASSETS</div>
      <div class="active-text">{{creatives[activeCompetitor?.id]?.length}} Asset{{creatives[activeCompetitor?.id]?.length === 1 ? "" : "s"}}</div>
    </div>
    <div class="action-buttons">
      <app-competitor-admin></app-competitor-admin>
      <a class="btn" routerLink="../admin">UPLOAD DATA</a>
    </div>
  </div>
  <mat-select [ngModel]="activeCompetitor" (selectionChange)="updateActiveCompetitor($event)">
    <mat-option [value]="clientCompetitor">{{clientCompetitor?.name}}</mat-option>
    <hr>
    <mat-option *ngFor="let competitor of nonClientCompetitorsArray" [value]="competitor">
      {{competitor.name}}
    </mat-option>
  </mat-select>
  <app-creative-upload
    *ngIf="activeCompetitor"
    [creatives]="creatives[activeCompetitor?.id]"
    [competitorId]="activeCompetitor?.id"
    [productId]="productId"
    (creativeClick)="selectedCreative = $event"
  >
  </app-creative-upload>
  <div class="genome-table-container">
    <div class="genome-table-header">
      <div class="gold-header">BRAND GENOME</div>
      <mat-select (selectionChange)="changeModelType($event.value)" [ngModel]="modelType$ | async">
        <mat-option *ngFor="let modelType of modelTypes" [value]="modelType.key">
          {{modelType.display}}
        </mat-option>
      </mat-select>
    </div>
    <app-creative-attribute-list
      *ngIf="genomeHasValidScores$ | async"
      [scoredAttributes$]="scoredAttributes$"
    ></app-creative-attribute-list>
  </div>
  <div *ngIf="isDataLoaded$ | async">
    <div class="no-assets" *ngIf="!hasCreatives">
      <div>You don't have any assets uploaded for this product.</div>
      <div>Please upload assets.</div>
    </div>
    <div class="no-scores" *ngIf="!(genomeHasValidScores$ | async) && hasCreatives">
      <div>No creative attribute data.</div>
      <div>It may take some time for uploaded creatives to finish processing.</div>
    </div>
  </div>
</div>

<app-slide-in-overlay #creativeDetailSlideIn
                      [percentOfParentCovered]="85"
                      [active]="!!selectedCreative"
                      (backgroundClick)="selectedCreative = null"
>
  <ppc-creative-detail-attribute-list
    (closeClicked)="selectedCreative = null"
    [selectedCreative]="selectedCreative"
    [productId]="productId"
  ></ppc-creative-detail-attribute-list>
</app-slide-in-overlay>
