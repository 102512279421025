import {Action} from "@ngrx/store";
import { VendorDisplayName } from "./segments-hierarchy.reducer";

export class FetchVendorDisplayNames implements Action {
  static readonly type = 'FETCH_VENDOR_DISPLAY_NAMES';
  readonly type = FetchVendorDisplayNames.type;

  constructor(public useCases: string[], public fullSlug: string, version?: string) {}
}

export class FetchAdminPermissionTriples implements Action {
  static readonly type = 'FETCH_ADMIN_PERMISSION_TRIPLES';
  readonly type = FetchAdminPermissionTriples.type;

  constructor(public version?: string) {}
}

export class LoadAdminPermissionTriples implements Action {
  static readonly type = 'LOAD_ADMIN_PERMISSION_TRIPLES';
  readonly type = LoadAdminPermissionTriples.type;

  constructor(public adminPermissionTriples) { }
}

export class LoadVendorDisplayNames implements Action {
  static readonly type = 'LOAD_VENDOR_DISPLAY_NAMES';
  readonly type = LoadVendorDisplayNames.type;

  constructor(public vendorDisplayNames: VendorDisplayName[]) { }
}

export type ReducerActions = FetchVendorDisplayNames | FetchAdminPermissionTriples | LoadVendorDisplayNames
