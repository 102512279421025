<div class="error-box" *ngIf="visible">
	<div>
		<img src="assets/icons/warning.svg" class="margin-right-10"/>
	</div>
	<div class="main">
		<div>
			<span *ngIf="!isZeroCount">Error Notification: </span>
			<span class='margin-left-5'>{{topLevelMessage}}</span>
      <a *ngIf="isZeroCount" (click)="openDialog()">Learn More</a>
		</div>
		<div *ngFor="let error of errorMessages" class="hide-unless-hover">
			{{error}}
		</div>
	</div>
	<div>
		<img src="assets/icons/close.svg" class="close-box" (click)="closeNotification()"/>
	</div>
</div>
