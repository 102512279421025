import { Injectable } from '@angular/core';
import { Observable ,  Subscriber } from 'rxjs';

export interface TooltipEvent {
  target?: HTMLElement;
  content?;
  action: string;
  usesTemplate?: boolean;
  delayOpen?: boolean;
  delayTime?: number;
  eventTarget?;
  offset?: {x?: number, y?: number};
  customPosition?: boolean;
  alignment?: string;
}

@Injectable()
export class PpcTooltipService {

  currentTooltipTarget;
  tooltipSubscriber: Subscriber<any>;

  constructor() { }

  getTooltipEventListener(): Observable<TooltipEvent> {
    return new Observable(subscriber => this.tooltipSubscriber = subscriber);
  }

  triggerTooltipEvent(event: TooltipEvent) {
    this.tooltipSubscriber.next(event);
  }
}
