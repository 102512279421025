<div class="persona-dropdown-container" *ngLet="(personas$ | async) as personas">
  <div class="dropdown-label">Personas</div>
  <ppc-dropdown class="persona-dropdown">
    <div currentlySelected>
      <ng-container *ngIf="selectedPersonas?.length">
        <span class="persona-item" *ngFor="let persona of selectedPersonas; index as index">
          <i class="fa fa-circle" [style.color]="compareColors[index]"></i>
          <span class="persona-item-label"
                *ppcCheckOverflow="let hasOverflow=hasXOverflow"
                [ppcTooltip]="persona?.name"
                [tooltipDisabled]="!hasOverflow">{{persona.name}}
          </span>
        </span>
      </ng-container>
    </div>

    <div dropdown>
      <div class="checkboxes" *ngIf="personas?.length">
        <ppc-checkbox *ngFor="let persona of personas; index as indx"
                      (change)="toggleSelectedPersona(persona.id)"
                      [ellipsize]="true"
                      [labelTooltip]="persona?.name"
                      [checked]="isChecked(persona)"
                      [ppcTooltip]="isDisabledTooltip(persona)"
                      [tooltipDisabled]="!isDisabled(persona)"
                      [disabled]="isDisabled(persona)">
          {{persona.name}}
        </ppc-checkbox>
      </div>
    </div>
  </ppc-dropdown>
</div>
