import { DatatableComponent, TableColumn } from "@swimlane/ngx-datatable";
import { AudienceV2 } from "app/audiences-v2/audience-v2.model";
import { Column, Worksheet } from "exceljs";
import { capitalize, get } from "lodash";
import * as moment from "moment";
import { displayPeopleCountEstimate, jobStatusDisplay } from "../shared/audience.utils";
import { AddressableCountMetadataService } from 'app/models/addressable-count-metadata/addressable-count-metadata.service';
import { OverviewV3Component } from "./overview-v3.component";

export class OverviewV3Export {

  table: DatatableComponent;
  addressableCountsService: AddressableCountMetadataService;
  component: OverviewV3Component;

  constructor(table: DatatableComponent, addressableCountsService: AddressableCountMetadataService, component: OverviewV3Component) {
    this.table = table;
    this.addressableCountsService = addressableCountsService;
    this.component = component;
  }

  addAudienceOverviewTable(ws: Worksheet) {
    ws.columns = this.setupHeaders(this.table);
    ws.addRows(this.setupRows(this.table, ws));
  }

  setupHeaders(table: DatatableComponent): Partial<Column>[] {
    const exportableHeaders = [
      'type',
      'name',
      'job_status',
      'created_at',
      'refresh_end_date',
      'expiration',
      'count.people.global',
      'addressable_count',
      'panelist_count'
    ];
    const columns: TableColumn[] = table._internalColumns.filter(column => exportableHeaders.includes(column.prop.toString()));
    return columns.map((column: TableColumn) => {
      return {header: column.name.toUpperCase(), key: column.prop.toString()}
    });
  }

  setupRows(table: DatatableComponent, ws: Worksheet): any[][] {
    const audiences: AudienceV2[] = table.rows;
    const rows = [];

    audiences.forEach((audience) => {
      const peopleCount = get(audience, ['count', 'people', 'global'], undefined);
      const row = [];
      ws.columns.forEach((header) => {

        switch (header.key) {
          case 'type':
            header.width = 10;
            row.push(capitalize(audience[header.key]));
            break;
          case 'name':
            header.width = 60;
            row.push(audience[header.key]);
            break;
          case 'job_status':
            header.width = 10;
            row.push(jobStatusDisplay(audience[header.key]));
            break;
          case 'created_at':
            header.width = 11;
            if (moment(audience[header.key]).isValid()) {
              row.push(moment(audience[header.key]).format('M/D/YY'));
            } else {
              row.push('');
            }
            break;
          case 'refresh_end_date':
          case 'expiration':
            header.width = 11;
            if (moment(audience[header.key]).isValid() && audience.type !== 'outcome') {
              row.push(moment(audience[header.key]).format('M/D/YY'));
            } else {
              row.push('');
            }
            break;
          case 'count.people.global':
            header.width = 13;
            if (audience.job_status !== "complete") {
              row.push(displayPeopleCountEstimate(peopleCount));
            } else {
              row.push(peopleCount);
            }
            break;
          case 'addressable_count':
            header.width = 18;
            row.push(this.addressableCountsService.displayCalculatedAddressableCounts(peopleCount));
            break;
          case 'panelist_count':
            header.width = 12;
            row.push(this.component.tvPanelistsDisplay(audience));
            break;
          default:
            row.push('');
            break;
        }
      });
      rows.push(row);
    });
    return rows
  }
}
