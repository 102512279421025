<mat-accordion>
  <mat-expansion-panel
    *ngFor="let content of drawerContent; index as i"
    [expanded]="i === currentStep"
    (opened)="setStep(i)"
  >
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ content.title }}
    </mat-panel-title>
  </mat-expansion-panel-header>
    <mat-panel-description>
      <ng-container *ngIf="content.type === 'status'">
        <ng-container [ngTemplateOutlet]="statusTemplate" [ngTemplateOutletContext]="{ content: content }"></ng-container>
      </ng-container>
      <ng-container *ngIf="content.type === 'permissions'">
        <ng-template [ngTemplateOutlet]="permissionsTemplate" [ngTemplateOutletContext]="{ content: content }"></ng-template>
      </ng-container>
<!--      <ng-container [ngTemplateOutlet]="content.templateName" [ngTemplateOutletContext]="{ content: content }"></ng-container>-->
    </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>
