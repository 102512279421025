import {Component, EventEmitter, Input, OnInit, OnChanges, Output, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import {MatSnackBar} from "@angular/material/snack-bar";
import {find, every, values, get} from "lodash";
import { Store, select} from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import {AppState} from "../../../reducers";
import {LookalikeV3} from "../../../lookalikes-v3/lookalike-v3.model";
import {AudienceV2} from "../../../audiences-v2/audience-v2.model";
import {ContextSlugs} from "../../../hierarchy/hierarchy.interface";
import {DestinationsAdminService} from "app/admin/destinations-admin/destinations-admin.service";
import { Partner, isTradeDesk, isDV360 } from 'app/models/partners/partner.model';
import { TTDDestinationSpecifics, DV360DestinationSpecifics } from 'app/models/destinations/destination.model'

@Component({
  selector: 'ppc-add-destination',
  templateUrl: './add-destination.component.html',
  styleUrls: ['./add-destination.component.sass']
})
export class AddDestinationComponent implements OnInit, OnChanges {
  @ViewChild('addDialog', { static: true }) dialog: any;
  @ViewChild('destinationForm') destinationForm: NgForm;
  @Output() modalMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter();
  @Output() openDestinationsWithPartner = new EventEmitter<any>();
  @Output() partnerFetchFromStore = new EventEmitter<any>();
  @Input() partner: Partner;
  @Input() clientSlug: string;
  @Input() regionSlug: string;
  @Input() brandSlug: string;
  @Input() productSlug: string;

  ngUnsubscribe: Subject<boolean> = new Subject();
  destLevel: string;
  isTradeDesk: boolean;
  isDV360: boolean;
  destName: string = "";
  contextSlugs: ContextSlugs = null;
  allClients: Array<object>;
  allRegions: Array<object>;
  allBrands: Array<object>;
  allProducts: Array<object>;
  errors: Array<string>;
  partnerSpecifics: TTDDestinationSpecifics | DV360DestinationSpecifics;

  constructor(
    private destinationsAdminService: DestinationsAdminService,
    private store: Store<AppState>,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    this.isTradeDesk = isTradeDesk(this.partner)
    this.isDV360 = isDV360(this.partner)

    if (this.isTradeDesk) {
      this.partnerSpecifics = {
        ttd_partner_id: ''
      }
    } else if (this.isDV360) {
      this.partnerSpecifics = {
        entity_id: '',
        product: '',
        whitelist_confirmation: false
      }
    } else {
      this.partnerSpecifics = null
    }

    this.store.pipe(select('hierarchy', 'contextSlugs'), takeUntil(this.ngUnsubscribe)).subscribe(slugs => {
      this.contextSlugs = slugs;
      this.destLevel = `${this.contextSlugs.clientSlug}(all regions and brands)`
    });

    this.store.pipe(select('hierarchy', 'hierarchy', 'clients'), takeUntil(this.ngUnsubscribe))
      .subscribe(clients => {
        this.allClients = [{slug: 'all', id: null, name: 'All', regions: []}, ...clients]
      });

    const cli = find(this.allClients, ['slug', this.clientSlug]);
    const regions = cli['regions'];

    if (regions.length > 0) {
      this.allRegions = [{slug: 'all', name: 'All', id: null, brands: [], emerging_market: false, currency: ''}, ...cli['regions']];

      const reg =  find(this.allRegions, ["slug", this.regionSlug]);
      const brands = reg['brands'];

      if (brands.length > 0) {
        this.allBrands = [{slug: 'all', name: 'All', id: null, products: []}, ...reg['brands']];

        const fullBrands = find(this.allBrands, ["slug", this.brandSlug]);
        const products = fullBrands['products'];

        if (products.length > 0) {
          this.allProducts = [{slug: 'all', name: 'All', id: null}, ...fullBrands['products']];
        }
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  reset() {
    this.destinationForm.resetForm();
    if (this.isDV360) {
      this.partnerSpecifics['whitelist_confirmation'] = false;
    }
  }

  isFormValid(): boolean {
    if (!this.destName) {
      return false
    }

    if (this.isTradeDesk) {
      return this.partnerSpecifics?.['ttd_partner_id'];
    } else if (this.isDV360) {
      return this.partnerSpecifics?.['entity_id'];
    } else {
      return Boolean(this.destName)
    }
  }

  openDestinations() {
    this.openDestinationsWithPartner.emit({partner: this.partner});
  }

  handleDestinationSuccess() {
    this.partnerFetchFromStore.emit();

    this.closeOverlay();
    this.snackbar.open(`Destination ${this.destName} was created successfully`, null, { duration: 5000 });
    this.openDestinations();
  }

  handleDeleteError(error) {
    this.errors = error.error.error.message.split(':')[1].split(", ") ;
  }

  createDestination(): void {
    const partner_id = this.partner['id'];
    const params = {
      partner_id: partner_id,
      name: this.destName,
      partner_specifics: this.partnerSpecifics,
      product_permission: {
        client: this.clientSlug,
        brand: this.brandSlug,
        region: this.regionSlug,
        product: this.productSlug
      }
    }
    this.destinationsAdminService.createDestination(params).subscribe(() =>
      this.handleDestinationSuccess(),
    error => this.handleDeleteError(error)
    );
  }

  closeOverlay(): void {
    this.onClose.emit();
  }
}
