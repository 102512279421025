<div class="segment-picker" [class.open]="service.open$ | async">
  <div class="segment-picker-header">
    <div class="segment-picker-titles">
      <span class="title1">{{options?.title1}}</span> <span class="title2">{{options?.title2}}</span>
    </div>
    <div class="segment-picker-search">
      <app-select-box
        *ngIf="!isTier3"
        [(ngModel)]="vendorFilters"
        (ngModelChange)="debouncedSearch(searchInput)"
        [multi]="true"
        [items]="vendorOptions"
        class="partner-select"
        placeholder="Select Partners"
        >
      </app-select-box>
      <ppc-input
        [(ngModel)]="searchInput"
        [noLabel]="true"
        [noErrorContainer]="true"
        (input)="debouncedSearch($event.target.value)"
        placeholder="Search"
        >
        <div prefix><i class="fa fa-search"></i></div>
        <div suffix *ngIf="searchInput" (click)="clearSearch()"><i class="fa fa-close"></i></div>
      </ppc-input>
    </div>
    <div class="segment-picker-buttons" *ngIf="service.open$ | async">
      <button (click)="onSaveClick()" class="btn" matRipple [disabled]="!isValid">Save</button>
      <button mat-button (click)="onCancelClick()">Cancel</button>
    </div>
  </div>
  <div class="segment-picker-selections" *ngIf="currentlySelectedLabel">
    <span class="segment-picker-selections-label">Currently Selected: </span><span>{{currentlySelectedLabel}}</span>
  </div>
  <div class="search-results" *ngIf="searchInput?.length > 2">
    <ppc-loading-overlay *ngIf="searchLoading" [fullScreen]="false" [withBackground]="false"></ppc-loading-overlay>
    <ng-container *ngIf="!searchLoading">
      <div class="service-error-banner" *ngIf="searchError">
        <h2>Service Error</h2>
        <p>
          There was an issue with this request. Please try again later or try another search term.
        </p>
      </div>
      <div class="search-results-entry"
        *ngFor="let segment of searchResults; let first=first"
        [class.tier-3]="isTier3"
        >
        <div class="column-headers"
          *ngIf="first"
          >
          <div class="column-header path">Path</div>
          <div class="column-header people-count">{{ isTier3 ? "Matched": "People Count"}}</div>
          <div class="column-header people-count" *ngIf="isTier3">Modeled</div>
        </div>
        <div class="segment-result" [class.selected]="value.has(segment.identifier)" (click)="toggleSegment(segment)">
          <div class="path">
            <ng-container>
              <ppc-checkbox
                *ngIf="options.multi"
                [checked]="value.has(segment.identifier)"
                [stateless]="true"
                [disabled]="getCount(segment, 'matched') == 0 && !value.has(segment.identifier)"
                [showRipple]="false"
                >
                <span [innerHTML]="segment.highlightedPath"></span>
              </ppc-checkbox>

              <ppc-radio-button
                *ngIf="!options.multi"
                [value]="segment.identifier"
                [isSelected]="value.has(segment.identifier)"
                [noRipple]="true"
                [class.disabled]="getCount(segment, 'matched') == 0"
                >
                  <span [innerHTML]="segment.highlightedPath"></span>
              </ppc-radio-button>
            </ng-container>
          </div>
          <div class="people-count">
            <ppc-zero-count-warning-icon
              *ngIf="getCount(segment, 'matched') == 0"
              ppcTooltip="Click to learn why this audience has a zero count."
              >
            </ppc-zero-count-warning-icon>
            {{getCount(segment, "matched") | number}}
          </div>
          <div class="people-count" *ngIf="isTier3">
            <ppc-zero-count-warning-icon
              *ngIf="getCount(segment, 'matched') == 0"
              ppcTooltip="Click to learn why this audience has a zero count."
              >
            </ppc-zero-count-warning-icon>
            {{getCount(segment, "modeled") | number}}
          </div>
        </div>
      </div>
      <paginator
        *ngIf="searchResults?.length"
        [pageSizeOptions]="[10,25,50]"
        [(pageSize)]="pageSize"
        [(offset)]="searchOffset"
        [count]="searchCount"
        (offsetChange)="search()"
        >
      </paginator>
    </ng-container>
  </div>
  <ppc-segment-browser
    [class.hide]="searchInput && searchInput?.length > 2"
    [root]="service.root$ | async"
    [insightsContext]="options?.insightsContext"
    (leafToggled)="toggleSegment($event)"
    [selectedSegmentIds]="selectedSegmentIds"
    >
    <ng-template #leaf let-node="node">
      <ppc-checkbox
        *ngIf="options.multi"
        [checked]="value.has(node.identifier)"
        [stateless]="true"
        [showRipple]="false"
        [disabled]="getCount(node, 'matched') == 0 && !value.has(node.identifier)"
        >
        <div class="node-name">{{node.name}}</div>
      </ppc-checkbox>
      <ppc-radio-button
        *ngIf="!options.multi"
        [value]="node.identifier"
        [isSelected]="value.has(node.identifier)"
        [noRipple]="true"
        [class.disabled]="getCount(node, 'matched') == 0"
        >
        <div class="node-name">{{node.name}}</div>
      </ppc-radio-button>
      <div class="node-details"
        [class.disabled]="getCount(node, 'matched') == 0"
        >
        <div class="node-data-column people-count">
          <div class="node-data-column-header">{{isTier3 ? "Matched" : "People Count"}}</div>
          <div class="node-data-column-number">
            <ppc-zero-count-warning-icon
              *ngIf="getCount(node, 'matched') == 0"
              ppcTooltip="Click to learn why this audience has a zero count.">
            </ppc-zero-count-warning-icon>
            {{ getCount(node, "matched" ) | number }}
          </div>
        </div>
        <div class="node-data-column people-count" *ngIf="isTier3">
          <div class="node-data-column-header">Modeled</div>
          <div class="node-data-column-number">
            <ppc-zero-count-warning-icon
              *ngIf='getCount(node, "modeled") == 0'
              ppcTooltip="Click to learn why this audience has a zero count."
              >
            </ppc-zero-count-warning-icon>
            {{ getCount(node, "modeled" ) | number }}
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #parent let-node="node">
      {{node.name}} <i class="fa fa-refresh fa-spin loading-children-indicator" *ngIf="node.loadingChildren"></i>
    </ng-template>
  </ppc-segment-browser>

  <div *ngIf="!isTier3">
    Please note that custom audiences will only appear after they have successfully run. Please wait 24hrs for newly
    created custom audiences to appear.
  </div>
</div>

<div class="overlay" [class.open]="service.open$ | async"></div>
