import { flatMap, find, reject, fromPairs, get, filter, map, orderBy,
  findIndex, sortBy, keyBy, omit, values, assign } from 'lodash';

import { Tab, Demographic, Filter, WidgetConfig, TopLevelTabType, DemographicConfig, RegionDemographicIdCountType, WidgetType } from "./insights.models";
import * as actions from './insights.actions';
import { createSelector } from '@ngrx/store';
import { selectRegion } from 'app/hierarchy/hierarchy.reducers';
import { AppState } from 'app/reducers';
import { userPreferenceKeys } from './grow-v3/grow.constants';
import { PercentCalculationType, SegmentContexts } from "app/insights/insights.constants";
import { FocusType } from 'app/shared/components/ppc-split-screen/ppc-split-screen.component';
import { Node } from 'app/segment-picker/root-nodes/root-node.interface'
import { MarketLevelDemographic, newSubMarketBucket, MarketLevelDemographicBucket } from 'app/insights/insights-components/market-level-demographics/market-level-demographic.interface';
import { JourneyBrand, JourneyStage, JourneySubMarket } from 'app/journey/journey.models'
import { selectSelectedJourney, selectSelectedJourneySubMarkets, selectSelectedJourneySubMarketIds } from 'app/journey/journey.reducer';
import { selectActiveMekkoSubMarkets, selectActiveMekko, selectSelectedSubMarkets } from 'app/mekko/mekko.reducer';
import { MarketLevelSurvey } from "app/insights/insights-components/market-level-survey/market-level-survey.interface";
import { getIdentifiersFromContext } from './insights.utils';
import { selectActiveStages, selectSelectedBrand } from '../journey/journey.reducer';
import { COMPARE_COLORS } from './insights-components/person-level-compare/person-level-compare.constants';
import { reduce } from 'lodash';
import { environment } from 'environments/environment';
import { selectActivePersona, selectPersonas, selectSelectedPersonas } from 'app/explore/explore.reducer';

export interface RegionDemographicBucket {
  name: string;
  percentage: number;
  id: number;
  count: number;
}

export interface RegionDemographic {
  name: string;
  demographic_type: "census" | "share-this";
  region_demographic_buckets: RegionDemographicBucket[];
  id?: number;
  widget_type: WidgetType;
  visible?: boolean;
  id_count: RegionDemographicIdCountType;
  attribute_provider: string;
}

export interface ResourceSubMarket {
  id?: number;
  name?: string;
  matched_short_id?: string;
  modeled_short_id?: string;
  order?: number;
  webo_cluster_id?: string;
  discussion_cluster_node_id?: number;
  matched_count?: number;
  journey_brand_id?: number;
  journey_stage_id?: number;
  tag?: 'owned' | 'opportunity' | 'none';
  market_id?: number;
  matched_id?: string;
  modeled_id?: string;
  population?: number;
  brand?: JourneyBrand;
  stage?: JourneyStage;
  new_journey_brand_id?: number;
  new_journey_stage_id?: number;
}

export interface CompareTarget {
  id: number;
  identifier: string;
  legendColor: string;
  label: string;
}

export interface IndexBase {
  name: string;
  shortId: string;
}

export interface State {
  tabs?: Tab[];
  demographics?: Demographic[];
  filters: Filter[];
  demoUnderEdit: Demographic;
  activeGWIQuestions: Node[];
  manageOpen: boolean;
  exportOpen: boolean;
  widgetConfig: WidgetConfig;
  sharedInterestSegments: string[];
  personLevelTab?: string;
  customTabUnderEdit: Tab;
  customTabUnderEditParent: Tab;
  topLevelTab?: TopLevelTabType;
  marketLevelTab?: string;
  predictionsLevelTab?: string;
  splitScreenFocus?: FocusType;
  segmentContexts?: SegmentContexts;
  indexMode: boolean;
  percentCalculationType: PercentCalculationType;
  regionDemographics?: RegionDemographic[];
  regionDemographicUnderEdit?: RegionDemographic;
  marketLevelDemographics: {[demoId: number]: MarketLevelDemographic};
  marketLevelDemographicUnderEdit?: MarketLevelDemographic;
  shareThisPeriod: number;
  shareThisData?: any;
  topDomainsData?: any;
  verticalOverlapData?: any;
  marketLevelSurveys: MarketLevelSurvey[];
  verticalOverlapKey?: string;
  indexBase?: IndexBase;
}

const defaultState: State = {
  filters: [],
  demoUnderEdit: null,
  activeGWIQuestions: [],
  manageOpen: false,
  exportOpen: false,
  widgetConfig: {},
  sharedInterestSegments: [],
  customTabUnderEdit: null,
  customTabUnderEditParent: null,
  indexMode: false,
  percentCalculationType: PercentCalculationType.WIDGET,
  topLevelTab: "Person Level",
  splitScreenFocus: null,
  marketLevelDemographics: {},
  shareThisPeriod: 1,
  marketLevelSurveys: [],
  indexBase: defaultIndexBase(),
};

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.LoadDemographics.type: {
      const demoShortIds = new Set(flatMap(action.demographicData, 'buckets').map(bucket => bucket.short_id));
      return {
        ...state,
        demographics: action.demographicData,
        filters: state.filters.filter(filter => demoShortIds.has(filter.shortId))
      };
    }
    case actions.LoadDemographic.type:
      return {
        ...state,
        demographics: reject(state.demographics, { id: action.demographic.id }).concat([action.demographic])
      }
    case actions.LoadMarketLevelDemographics.type:
      const keyedSubMarketBucketEntries = action.marketLevelDemographics.map(marketLevelDemographic => {
        marketLevelDemographic.market_level_demographic_buckets.forEach(marketLevelDemographicBucket => {
          marketLevelDemographicBucket.sub_market_bucket_entries = keyBy(marketLevelDemographicBucket.sub_market_bucket_entries, "resource_id")
        })
        return marketLevelDemographic
      })
      return {...state, marketLevelDemographics: keyBy(keyedSubMarketBucketEntries, "id")}
    case actions.LoadMarketLevelDemographic.type:
      action.marketLevelDemographic.market_level_demographic_buckets.forEach(marketLevelDemographicBucket => {
        marketLevelDemographicBucket.sub_market_bucket_entries = keyBy(marketLevelDemographicBucket.sub_market_bucket_entries, "resource_id")
      })
      return {
        ...state,
        marketLevelDemographics: {
          ...state.marketLevelDemographics,
          [action.marketLevelDemographic.id]: action.marketLevelDemographic
        }
      }
    case actions.EditMarketLevelDemographic.type:
      return {...state, marketLevelDemographicUnderEdit: action.marketLevelDemographic}
    case actions.DestroyMarketLevelDemographic.type:
      return {
        ...state,
        marketLevelDemographics: omit(state.marketLevelDemographics, action.marketLevelDemographicId)
      }
    case actions.AddSubMarketBucketEntries.type:
      const newMarketLevelDemographics = keyBy(values(state.marketLevelDemographics).map((mld: MarketLevelDemographic) => {
        return {
          ...mld,
          market_level_demographic_buckets: mld.market_level_demographic_buckets.map((mldb: MarketLevelDemographicBucket) => {
            return {
              ...mldb,
              sub_market_bucket_entries: assign(mldb.sub_market_bucket_entries, {[action.resource.id]: find(mldb.sub_market_bucket_entries, {resource_id: action.resource.id}) || newSubMarketBucket(action.resource.id, action.resourceType)})
            } as MarketLevelDemographicBucket
          } )
        } as MarketLevelDemographic
      }), "id")
      return {
        ...state,
        marketLevelDemographics: newMarketLevelDemographics
      }
    case actions.RemoveSubMarketBucketEntries.type:
      const strippedMarketLevelDemographics = keyBy(values(state.marketLevelDemographics).map((mld: MarketLevelDemographic) => {
        return {
          ...mld,
          market_level_demographic_buckets: mld.market_level_demographic_buckets.map(mldb => {
            return {
              ...mldb,
              sub_market_bucket_entries: omit(mldb.sub_market_bucket_entries, action.resource.id)
            }
          })
        }
      }), "id") as {[demoId: number]: MarketLevelDemographic}
      return {
        ...state,
        marketLevelDemographics : strippedMarketLevelDemographics
      }
    case actions.ToggleFilter.type:
      return {
        ...state,
        filters: find(state.filters, {shortId: action.filter.shortId}) ?
          reject(state.filters, {shortId: action.filter.shortId}) :
          [action.filter, ...state.filters]
      };
    case actions.ClearFilters.type:
      return { ...state, filters: [] }
    case actions.SetTopLevelTab.type:
      return {...state, topLevelTab: action.topLevelTab}
    case actions.EditDemographic.type:
      return {...state, demoUnderEdit: action.demo};
    case actions.DestroyDemographic.type:
      return {...state, demographics: state.demographics.filter((demo) => demo.id != action.demographic.id) };
    case actions.SetActiveGWIQuestions.type:
      return {...state, activeGWIQuestions: action.activeGWIQuestions}
    case actions.ToggleManage.type:
      return {...state, manageOpen: !state.manageOpen}
    case actions.ToggleExport.type:
      return {...state, exportOpen: !state.exportOpen}
    case actions.LoadDemographicsConfig.type:
      return {
        ...state,
        widgetConfig: {
          ...state.widgetConfig,
          [action.configType]: action.config
        }
      }
    case actions.SetSharedInterestSegments.type:
      return {
        ...state,
        sharedInterestSegments: action.segments
      }
    case actions.SetPersonLevelTab.type:
      return { ...state, personLevelTab: action.personLevelTab }
    case actions.SetMarketLevelTab.type:
      return { ...state, marketLevelTab: action.marketLevelTab }
    case actions.SetPredictionsLevelTab.type:
      return { ...state, predictionsLevelTab: action.predictionsLevelTab }
    case actions.EditCustomTab.type:
      return {...state, customTabUnderEdit: action.customTab, customTabUnderEditParent: action.parent};
    case actions.LoadCustomTabConfig.type:
      if (!action.config) { return state }
      return { ...state, widgetConfig: {...state.widgetConfig, ...fromPairs(action.config.map(userPreferences => [userPreferences.config_type, userPreferences.config]))}}
    case actions.LoadTabs.type:
      return { ...state, tabs: action.tabs };
    case actions.SetSegmentContexts.type:
      return { ...state, segmentContexts: action.segmentContexts }
    case actions.SetSplitScreenFocus.type:
      return { ...state, splitScreenFocus: action.splitScreenFocus }
    case actions.ToggleIndexMode.type:
      return { ...state, indexMode: !state.indexMode }
    case actions.ResetIndexMode.type:
      return { ...state, indexMode: false }
    case actions.SetPercentCalculationType.type:
      return { ...state, percentCalculationType: action.percentCalculationType }
    case actions.LoadRegionDemographics.type:
      return { ...state, regionDemographics: action.regionDemographics }
    case actions.EditRegionDemographic.type:
      return { ...state, regionDemographicUnderEdit: action.regionDemographic }
    case actions.LoadShareThisData.type:
      return { ...state, shareThisData: action.shareThisData}
    case actions.LoadShareThisDataForDomains.type:
      return { ...state, topDomainsData: action.topDomainsData }
    case actions.LoadShareThisVerticalOverlapData.type:
      return { ...state, verticalOverlapData: action.verticalOverlapData }
    case actions.FetchShareThisVerticalOverlap.type:
      return { ...state, verticalOverlapKey: action.verticalKey }
    case actions.SetShareThisPeriod.type:
      return { ...state, shareThisPeriod: action.shareThisPeriod}
    case actions.LoadMarketLevelSurveys.type:
      return { ...state, marketLevelSurveys: action.marketLevelSurveys }
    case actions.SetIndexBase.type:
      return { ...state, indexBase: action.indexBase }
    default:
      return state;

  }
}

export interface MetaInsightsState {
  [insightsContext: string]: State;
}

export const defaultMetaState: MetaInsightsState = {}

export function metaReducer(metaState: MetaInsightsState = defaultMetaState, action: actions.ReducerActions): MetaInsightsState {
  if (!(action as any).insightsContext) {
    return {...metaState};
  }
  const context = get(metaState, (action as any).insightsContext);
  const newContext = reducer(context, action);
  const newState = { ...metaState, [(action as any).insightsContext]: newContext };

  return newState
}

export const selectActiveTabDemographics = insightsContext => {
  return createSelector(
    (state: AppState) => selectRegion(state.hierarchy),
    (state: AppState) => get(state, ["insights", insightsContext, "demographics"], []),
    (state: AppState) => get(state, ["insights", insightsContext, "personLevelTab"]),
    (state: AppState) => get(state, ["insights", insightsContext, "tabs"]),
    (region,  demographics, personLevelTab, tabs) => {
      const topLevelPersonTab = find(tabs, {tab_key: "top_level_person"});
      let activeTab = find(get(topLevelPersonTab, "children", []), {name: personLevelTab});
      if (get(activeTab, "tab_key") == "person_level_demographics") {activeTab = null; }
      const filteredDemographics = filter(demographics, {custom_tab_id: get(activeTab, "id", null)}) as Demographic[];
      demographics = map(filteredDemographics, demo => {
        return {
          ...demo,
          buckets: demo.buckets.map(bucket => {
            return {
              ...bucket,
              short_id: bucket.short_id || `gwi-coresurvey-${region.slug}-${bucket.vendor_segment_identifier}`
            }
          })
        }
      })
      return demographics;
    }
  )
}


export const selectAllDemographics = insightsContext => {
  return createSelector(
    (state: AppState) => selectRegion(state.hierarchy),
    (state: AppState) => get(state, ["insights", insightsContext, "demographics"], []),
    (state: AppState) => get(find(get(state, ["insights", insightsContext, "tabs"]), {tab_key: "top_level_person"}), "children"),
    (state: AppState) => get(state, ["insights", insightsContext, "widgetConfig"], {}),
    (region,  demographics, tabs, widgetConfigs) => {
      const filteredTabs = filter(tabs, t => t.tab_key == "person_level_demographics" || t.tab_key === "person_level_custom");
      const configs = reduce(filteredTabs, (configs, tab) => {
        if (tab.tab_key == "person_level_demographics") {
          return Object.assign(configs, {"standard": widgetConfigs[`standard-demographics-${insightsContext}`]})
        } else {
          return Object.assign(configs, {[tab.id]: widgetConfigs[`custom-tab-${tab.id}-demographics`]})
        }
      }, {})
      demographics = map(demographics, demo => {
        const config = configs[demo.is_standard ? "standard" : demo.custom_tab_id];
        const configEntry = find(config, {id: demo.id});
        return {
          ...demo,
          ...configEntry,
          buckets: demo.buckets.map(bucket => {
            return {
              ...bucket,
              short_id: bucket.short_id || `gwi-coresurvey-${region.slug}-${bucket.vendor_segment_identifier}`
            }
          })
        }
      })
      return demographics;
    }
  )
}

export const selectStandardDemographics: (string) => (AppState) => Demographic[] = insightsContext => {
  return createSelector(
    (state: AppState) => selectAllDemographics(insightsContext)(state),
    (state: AppState) => state.insights[insightsContext].widgetConfig[userPreferenceKeys.standardDemographics(insightsContext)],
    (demographics, config) => {
      return setDemographicsOrder(filter(demographics, "is_standard"), config);
    }
  )
}

export const selectCustomTabDemographics: (number) => (State) => Demographic[] = customTabId => {
  return createSelector(
    (state: State) => state.widgetConfig[userPreferenceKeys.customTab(customTabId)],
    (state: State) => (state.demographics || []),
    (config, demographics) => {
      const customDemographics = demographics.filter((demo) => demo.custom_tab_id === customTabId)
      return setDemographicsOrder(customDemographics, config);
    }
  )
}

export const selectInsightsIdentifiers = insightsContext => {
  return createSelector(
    selectAllDemographics(insightsContext),
    (state: AppState) => get(state, ["insights", insightsContext, "activeGWIQuestions"]),
    (state: AppState) => get(state, ["insights", insightsContext, "sharedInterestSegments"], []),
    (state: AppState) => getIdentifiersFromContext(get(state, ["insights", insightsContext, "segmentContexts", "primary"]) || {}, insightsContext),
    (demographics, activeGwiQuestions, sharedInterestSegments: string[], contextIdentifiers: string[]) => {
      const demographicIdentifiers = flatMap(demographics, demo => flatMap(demo.buckets, "short_id"));
      const gwiIdentifiers = flatMap(activeGwiQuestions, demo => flatMap(demo.children, "identifier"));
      return [
        ...contextIdentifiers,
        ...demographicIdentifiers,
        ...gwiIdentifiers,
        ...sharedInterestSegments,
      ]
    }
  )
}

export const selectCensusDemographics: (InsightsContextType) => (AppState) => RegionDemographic[] = insightsContext => {
  return createSelector(
    (state: AppState) => filter(state.insights[insightsContext].regionDemographics, {demographic_type: "census"}),
    (state: AppState) => state.insights[insightsContext].widgetConfig[userPreferenceKeys.regionLevelDemographics("census")],
    (demographics, config) => {
      return sortBy(demographics, demo => findIndex(config, {id: demo.id})).map((demo) => {
        return {
          ...demo,
          visible: get(find(config, {id: demo.id}), 'visible', true) as boolean,
          widget_type: get(find(config, {id: demo.id}), 'widget_type', demo.widget_type),
          id_count: get(find(config, {id: demo.id}), 'id_count', demo.id_count)
        }
      })
    }
  )
}

export const selectMarketLevelCustomTabDemographics: (InsightsContextType, number) => (AppState) => MarketLevelDemographic[] = (insightsContext, id) => {
  return createSelector(
    (state: AppState) => state.insights[insightsContext].widgetConfig[userPreferenceKeys.customTab(id)],
    (state: AppState) => values(state.insights[insightsContext].marketLevelDemographics),
    (config, demographics) => {
      const customDemographics = demographics.filter((demo) => demo.custom_tab_id === id)
      return sortBy(customDemographics, demo => findIndex(config, {id: demo.id})).map((demo) => {
        return {
          ...demo,
          visible: get(find(config, {id: demo.id}), 'visible', true) as boolean,
          widget_type: get(find(config, {id: demo.id}), 'widget_type', demo.widget_type),
          id_count: get(find(config, {id: demo.id}), 'id_count', demo.id_count),
          description: get(find(config, {id: demo.id}), 'description', demo.description)
        }
      })
    }
  )
}

export function setDemographicsOrder(demographics: (Demographic | MarketLevelDemographic)[], config: DemographicConfig[]) {
  let orderedDemographics;

  if (config === undefined) {
    orderedDemographics = orderBy(demographics, ['created_at'], ['desc'])
  } else {
    const configlessDemos = orderBy(demographics.filter(demo => findIndex(config, {id: demo.id}) === -1), ['created_at'], ['desc']);
    const configDemos = sortBy(demographics.filter(demo => findIndex(config, {id: demo.id}) > -1), demo => findIndex(config, {id: demo.id}));
    orderedDemographics = configlessDemos.concat(configDemos);
  }

  return orderedDemographics.map((demo) => {
    const foundConfig = find(config, {id: demo.id});
    return {
      ...demo,
      visible: get(foundConfig, 'visible', true) as boolean,
      widget_type: get(foundConfig, 'widget_type', demo.widget_type),
      is_id_count: get(foundConfig, 'is_id_count', demo.is_id_count),
      description: get(foundConfig, 'description', demo.description)
    }
  })
}

export const selectRegionAndMarketLevelSurveys = insightsContext => {
  return createSelector(
    (state: AppState) => state.insights[insightsContext].widgetConfig[userPreferenceKeys.surveys],
    (state: AppState) => state.insights[insightsContext].marketLevelSurveys,
    (state: AppState) => selectSelectedResourceSubMarkets(insightsContext)(state),
    (surveyConfig, surveys, selectedSubMarkets) => {
      const selectedSurveys = surveys.filter(s => s.region_slug || selectedSubMarkets.includes(s.resource_id));
      return sortBy(selectedSurveys, s => findIndex(surveyConfig, {id: s.id})).map(survey => {
        return {
          ...survey,
          visible: get(find(surveyConfig, {id: survey.id}), 'visible', true) as boolean
        }
      })
    }
  );
}

export const selectSurveyParentResource = insightsContext => {
  return createSelector(
    (state: AppState) => selectActiveMekko(state.mekkos),
    (state: AppState) => selectSelectedJourney(state.journey),
    (activeMekko, selectedJourney) => {
      return insightsContext == "grow" ? activeMekko : selectedJourney;
    }
  );
}

export const selectActiveResourceSubMarkets = insightsContext => {
  return createSelector(
    (state: AppState) => selectActiveMekkoSubMarkets(state.mekkos),
    (state: AppState) => selectSelectedJourneySubMarkets(state.journey),
    (activeSubMarkets, selectedJourneySubMarkets) => {
      return insightsContext == "grow" ? activeSubMarkets : selectedJourneySubMarkets;
    }
  );
}

export const selectSelectedResourceSubMarkets = insightsContext => {
  return createSelector(
    (state: AppState) => state.mekkos.selectedSubMarketIds,
    (state: AppState) => selectSelectedJourneySubMarketIds(state.journey),
    (selectedSubMarkets, selectedJourneySubMarkets) => {
      return insightsContext == "grow" ? selectedSubMarkets : selectedJourneySubMarkets;
    }
  );
}

export const isCompareMode = createSelector(
  (state: State) => get(state, "personLevelTab"),
  (state: State) => get(state, "topLevelTab"),
  (personLevelTab, topLevelTab) => {
    return topLevelTab === "Person Level" && personLevelTab === "Compare"
  }
);

export const compareTargets: (InsightsContextType) => (AppState) => CompareTarget[] = (insightsContext) => createSelector(
  (state: AppState) => state.mekkos,
  (state: AppState) => state.journey,
  (state: AppState) => state.explore,
  (state: AppState) => isCompareMode(state.insights[insightsContext]),
  (state: AppState) => state.grow.growTabState,
  (state: AppState) => state.journey.journeyTab,
  (mekkoState, journeyState, exploreState, isCompareMode, growTab, journeyTab) => {
    if (!isCompareMode) {return []; }
    switch (insightsContext) {
      case "grow":
        const activeMekko = selectActiveMekko(mekkoState);
        const selectedSubMarkets = selectSelectedSubMarkets(mekkoState);
        if (selectedSubMarkets.length > 3) {return []; }
        return map(selectedSubMarkets, (subMarket, idx) => {
          const market = find(activeMekko.markets, {id: subMarket.market_id})
          return {
            id: subMarket.id,
            identifier: subMarket[growTab == "Modeled Addressable" && !environment.isTier3 ? "modeled_short_id" : "matched_short_id" ],
            legendColor: COMPARE_COLORS[idx],
            label: `${market.name} > ${subMarket.name}`
          }
        });
      case "journey":
        const activeBrand = selectSelectedBrand(journeyState);
        if (!activeBrand) {return []; }
        const journeySubMarkets = selectSelectedJourneySubMarkets(journeyState);
        const selectedStages = selectActiveStages(journeyState);
        if (selectedStages.length > 3) {return []; }
        return map(selectedStages, (stage, idx) => {
          const subMarket = find(journeySubMarkets, {journey_brand_id: activeBrand.id, journey_stage_id: stage.id})
          return {
            id: stage.id,
            identifier: subMarket[journeyTab == "Modeled" && !environment.isTier3 ? "modeled_id" : "matched_id"],
            legendColor: COMPARE_COLORS[idx],
            label: `${activeBrand.name} > ${stage.name}`
          }
        });
      case "explore":
        const activePersona = selectActivePersona(exploreState);
        if (!activePersona) {return []; }
        const personas = selectPersonas(exploreState);
        const selectedPersonas = orderBy(selectSelectedPersonas(exploreState), {id: activePersona.id}, "desc");
        if (selectedPersonas.length > 3) {return []; }
        return map(selectedPersonas, (persona, idx) => {
          return {
            id: persona.id,
            identifier: `persona-compare-${persona.id}`,
            legendColor: COMPARE_COLORS[idx],
            label: persona.name
          }
        });
    }
  }
)

export const canCompare = (insightsContext) => createSelector(
  compareTargets(insightsContext),
  (compareTargets) => compareTargets.length > 0 && compareTargets.length < 4
)

export function defaultIndexBase(): IndexBase {
  return { name: "Region (Default)", shortId: null };
}
