import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from "@ngrx/store";
import { filter } from 'rxjs/operators';
import { flatMap } from 'lodash';
import { UploadAdminService } from "app/admin/upload-admin/upload-admin.service";
import { selectRegion } from "app/hierarchy/hierarchy.reducers";
import { AppState } from 'app/reducers';
import { ResetFetchState } from 'app/shared/utils/fetch-state';
import * as mekkoActions from "app/mekko/mekko.actions";
import { FetchJourneys } from "app/journey/journey.actions";
import { ActivatedRoute } from "@angular/router";
import { FetchCurrentUser } from 'app/users/user.actions';

@UntilDestroy()
@Component({
  selector: 'ppc-surveys-upload-admin',
  templateUrl: './surveys-upload-admin.component.html',
  styleUrls: ['./surveys-upload-admin.component.sass']
})

export class SurveysUploadAdminComponent implements OnDestroy {
  public updatedSurveys = [];
  public errors = [];
  fileName = '';
  errorListCollapsed = false;
  updatesListCollapsed = false;
  surveyType: 'region' | 'audience' | 'journeyAudience' = this.route.snapshot.queryParams.surveyType || 'region';

  public audienceSurveyRules = [
    "The template will only affect your current client and region.",
    "Each row must have at least Brand, Product, Chart Name, Market Segment and Audience.",
    "To add multiple survey questions, answers and values, add a line for each attribute value with matching Brand, Product, Market Segment and Audience names.",
    "Percentage values should be entered into the 'Answer Percent' column.",
    "A list of all updated Market Segments and Audiences will appear after uploading.",
    "If any errors are found, they will appear below the upload section with a corresponding row and column (if applicable). Errors do not stop the upload process (provided file has valid brand(s), product(s) and format), but the field or row in error will not be updated.",
    "When an upload is performed, the new survey will replace the existing survey. If any data is excluded from the upload that currently exists it will be deleted.",
    "All available Brands and Products will appear in columns to the right of your downloaded template.",
    "Enter values into either 'Answer Percent' or 'Answer Count' column. Do not use both columns when adding new data."
  ]

  public regionSurveyRules = [
    "To add multiple survey questions, answers and values, add a line for each attribute value.",
    "Percentage values should be entered into the 'Answer Percent' column.",
    "In any errors are found, they will appear below the upload section with a corresponding row and column (if applicable). Errors do not stop the upload process, but the field or row in error will not be updated.",
    "When an upload is performed, the new survey will replace the existing survey. If any data is excluded from the upload that currently exists it will be deleted."
  ]

  public journeySurveyRules = [
    "The template will only affect your current client and region.",
    "Each row must have at least Brand, Product, Journey Name, Journey Brand Name and Journey Stage Name.",
    "To add multiple survey questions, answers and values, add a line for each attribute value with matching Brand, Product, Journey Name, Journey Brand Name and Journey Stage Name.",
    "Percentage values should be entered into the 'Answer Percent' column.",
    "A list of all updated Journey Brand Names and Stages will appear after uploading.",
    "If any errors are found, they will appear below the upload section with a corresponding row and column (if applicable). Errors do not stop the upload process (provided file has valid brand(s), product(s) and format), but the field or row in error will not be updated.",
    "When an upload is performed, the new survey will replace the existing survey. If any data is excluded from the upload that currently exists it will be deleted.",
    "All available Brands and Products will appear in columns to the right of your downloaded template.",
    "Enter values into either 'Answer Percent' or 'Answer Count' column. Do not use both columns when adding new data."
  ]

  constructor(
    public uploadAdminService: UploadAdminService,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar,
    public store: Store<AppState>,
    public route: ActivatedRoute
  ) {
    this.store.select('hierarchy').pipe(
      select(selectRegion), filter(Boolean), untilDestroyed(this)
    ).subscribe(region => this.clearUploadMessages());
    this.store.dispatch(new FetchCurrentUser({ping: true}));
  }

  downloadTemplate() {
    if (this.isRegionSurvey) {
      this.uploadAdminService.downloadRegionSurveyTemplate().subscribe()
    } else if (this.isAudienceSurvey) {
      this.uploadAdminService.downloadSurveyTemplate().subscribe();
    } else {
      this.uploadAdminService.downloadJourneySurveyTemplate().subscribe();
    }
  }

  uploadTemplate(event, form: HTMLFormElement) {
    if (!event.target.files[0]) {return; }
    this.fileName = event.target.files[0].name;
    this.clearUploadMessages();
    this.uploadAdminService.loading(true);
    if (this.isRegionSurvey) {
      this.uploadAdminService.uploadRegionSurveyTemplate(new FormData(form)).subscribe(
        response => this.handleSuccessResponse(response.surveys, response.errors),
        error => this.snackbar.open(error, null, { duration: 6000, panelClass: ['danger'] })
      );
    } else if (this.isAudienceSurvey) {
      this.uploadAdminService.uploadSurveyTemplate(new FormData(form)).subscribe(
        response => this.handleSuccessResponse(flatMap(response.mekkos, "markets"), response.errors),
        error => this.snackbar.open(error, null, { duration: 6000, panelClass: ['danger'] })
      );
    } else {
      this.uploadAdminService.uploadJourneySurveyTemplate(new FormData(form)).subscribe(
        response => this.handleSuccessResponse(flatMap(response.journeys, "brands"), response.errors),
        error => this.snackbar.open(error, null, { duration: 6000, panelClass: ['danger'] })
      );
    }

    event.target.value = '';
  }

  ngOnDestroy() {}

  handleSuccessResponse(response, errors) {
    if (this.isAudienceSurvey) {
      this.store.dispatch(new ResetFetchState(mekkoActions.FetchMekkos));
      this.store.dispatch(new ResetFetchState(mekkoActions.FetchSubMarkets));
    } else if (this.isJourneySurvey) {
      this.store.dispatch(new ResetFetchState(FetchJourneys));
    }

    this.updatedSurveys = response;
    this.errors = errors;
    this.snackbar.open("Upload Complete", 'ok', { duration: 4000, panelClass: ['check'] });
  }

  getSanitizedValue(val): SafeStyle {
    return this.sanitizer.bypassSecurityTrustHtml(val);
  }

  clearUploadMessages() {
    if (this.updatedSurveys.length) {this.updatedSurveys = []; }
    if (this.errors.length) {this.errors = []; }
  }

  surveyTypeChanged(value) {
    this.surveyType = value;
    this.clearUploadMessages();
  }

  get isRegionSurvey() {
    return this.surveyType == 'region';
  }

  get isAudienceSurvey() {
    return this.surveyType == 'audience';
  }

  get isJourneySurvey() {
    return this.surveyType == 'journeyAudience';
  }
}
