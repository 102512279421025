<app-launch>
  <div class="content layout vertical">
    <ppc-legacy-login-form *ngIf="returnUrl === 'legacy'; else enterTheLion"></ppc-legacy-login-form>
    <ng-template #enterTheLion>
      <ppc-tabs #tabs>
        <ppc-tab name="Current users" #currentTab>
          <br>
          <a class="btn coreUIButton-primary" [href]="lionLoginUrl">Login with LionLogin</a>
          <a class="btn coreUIButton-secondary" [href]="oktaUrl">Login with Okta</a>
        </ppc-tab>
        <ppc-tab name="Request access" #requestTab>
          <ppc-request-access-wizard></ppc-request-access-wizard>
        </ppc-tab>
      </ppc-tabs>
    </ng-template>
  </div>
</app-launch>
