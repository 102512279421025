import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Store, select } from "@ngrx/store";
import { filter as _filter, keyBy } from 'lodash';
import { AppState } from 'app/reducers';
import { InsightsContextType } from 'app/insights/insights.constants';
import * as insightsActions from 'app/insights/insights.actions'
import { fixCounts } from 'app/insights/insights.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { SegmentPickerService } from 'app/segment-picker/segment-picker.service';
import { defaultIndexBase, IndexBase } from 'app/insights/insights.reducer';
import { fullContext, selectRegion } from 'app/hierarchy/hierarchy.reducers';
import { HierarchyRegion } from 'app/hierarchy/hierarchy.interface';
import { isDefined } from 'app/shared/utils/utils';
import { SegmentLike } from 'app/models/segment-like.model';

@UntilDestroy()
@Component({
  selector: 'ppc-index-base',
  templateUrl: './index-base.component.html',
  styleUrls: ['./index-base.component.sass']
})
export class IndexBaseComponent implements OnInit, OnDestroy {
  @Input() useStore = true;
  @Input() insightsContext: string;
  @Input() indexBase: IndexBase;
  @Output() indexBaseChange = new EventEmitter<IndexBase>();
  region: HierarchyRegion;
  segments: {[identifier: string]: SegmentLike} = {};

  constructor (
    public store: Store<AppState>,
    private segmentV2Service: SegmentV2Service,
    private segmentPickerService: SegmentPickerService,
  ) { }

  ngOnInit() {
    if (this.useStore) {
      this.store.select("insights", this.insightsContext, "indexBase").pipe(
        untilDestroyed(this)
      ).subscribe(indexBase => {
        this.indexBase = indexBase;
        this.fetchSegments();
      });

      this.store.select('hierarchy').pipe(
        untilDestroyed(this),
        select(selectRegion),
        filter(isDefined)
      ).subscribe(region => this.region = region);
    } else {
      fullContext(this.store).pipe(untilDestroyed(this)).subscribe(() => {
        this.indexBase = defaultIndexBase();
      });
    }
  }

  ngOnDestroy() { }

  openPickerIndex() {
    const currentlySelected = this.segments[this.indexBase.shortId];
    this.segmentPickerService.open({
      multi: false,
      title1: "Index Base",
      title2: "Select Segments(s)/Audience(s)",
      currentlySelected: currentlySelected ? [currentlySelected] : [],
      insightsContext: this.insightsContext,
    }).subscribe(segments => {
      if (!segments) { return; }
      const [segment] = segments;
      this.segments[segment.identifier] = segment;
      this.setIndexBase({name: segment.name, shortId: segment.identifier});

    })
  }

  fetchSegments() {
    if (!this.indexBase.shortId) { return; }
    this.segmentV2Service.fetchByIdentifiers([this.indexBase.shortId])
      .subscribe(fetchedSegments => {
        const fixedCountSegments = fixCounts(fetchedSegments, this.region);
        this.segments = keyBy(fixedCountSegments, "identifier");
      });
  }

  setIndexBase(indexBase?: IndexBase) {
    // for reporting, we don't want to leverage the store to set the base, adds reusability to component
    if (this.useStore) {
      this.store.dispatch(new insightsActions.SetIndexBase(indexBase || defaultIndexBase(), this.insightsContext as InsightsContextType));
    } else {
      this.indexBase = indexBase || defaultIndexBase(); // currentlySelected dropdown value
      this.indexBaseChange.emit(this.indexBase);
    }
  }

  get tooltipMessage() {
    if (this.insightsContext === 'explore') {
      return "Indices in Explore will appear as 100 when the index base is set to 'Region (Default)' due to the region's data being indexed against itself. Choose a custom index base to view comparative indices against the region's data."
    } else if (this.insightsContext === 'indexReport') {
      return "Changing the index base to a custom base will apply to the entire index report.";
    } else if (this.insightsContext === 'outcome-audience') {
      return "Select a base to be used when calculating indices.";
    } else {
      return "Changing the index base to a custom base only applies to the Insights widgets. The diagram to the left has not been filtered by the new custom index base.";
    }
  }
}
