import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'millions'
})

export class MillionsFormatterPipe implements PipeTransform {

  public transform(value: string | number, fractionDigits: number = 1): string {
    // void accepts a single argument and always returned undefined
    // It's used in place of undefined for backwards-compatibility reasons
    if (value == void(0) || value === "") {return "N/A"; }

    const resultValue: number = (typeof value === 'string') ? parseFloat(value) : value;
    if (Number.isNaN(resultValue)) {return "N/A"; }


    if (Math.abs(resultValue) >= 1000000.0) {
      return (resultValue / 1000000.0).toFixed(fractionDigits) + "MM";
    }

    if (Math.abs(resultValue) >= 1000.0) {
      return (resultValue / 1000.0).toFixed(fractionDigits) + "K";
    }

    let output = resultValue.toFixed(fractionDigits);

    // If fractionDigits >= 1, remove all  values after decimal point
    if (output.endsWith("." + "0".repeat(fractionDigits))) {
      output = output.slice(0, output.length - (1 + fractionDigits));
    }

    return output;
  }

}
