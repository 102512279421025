import { Component, OnDestroy, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { BehaviorSubject, combineLatest as observableCombineLatest } from 'rxjs';
import { InsightsContextType, INSIGHTS_CONTEXT } from 'app/insights/insights.constants';
import { filter, map } from 'rxjs/operators';
import { PersonLevelCompareService } from './person-level-compare.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as insightsActions from 'app/insights/insights.actions'
import { compareTargets } from '../../insights.reducer';
import { selectedPersonasHaveExcludedIds } from 'app/explore/explore.reducer';
import { TOOLTIP_PERSONA_EXCLUDE_INDEX } from 'app/explore/explore.utils';
import { selectRegion } from "app/hierarchy/hierarchy.reducers";
import { isDefined } from 'app/shared/utils/utils';
import { HierarchyRegion } from "app/hierarchy/hierarchy.interface";
import { TOOLTIP_COMPARE_DISABLED_INDEX_MULTI_WIDGET } from "app/insights/insights-components/person-level-compare/person-level-compare.constants";
import { difference } from "lodash";

@UntilDestroy()
@Component({
  selector: 'ppc-person-level-compare',
  templateUrl: './person-level-compare.component.html',
  styleUrls: ['./person-level-compare.component.sass']
})

export class PersonLevelCompareComponent implements OnDestroy {
  isIndexMode: boolean;
  compareTargets$ = this.store.pipe(select(compareTargets(this.insightsContext)))
  widgetCount$: BehaviorSubject<number[]> = new BehaviorSubject([0]);
  region: HierarchyRegion;
  shouldShowIndex$ = observableCombineLatest(
    this.store.select("explore").pipe(select(selectedPersonasHaveExcludedIds)),
    this.widgetCount$
  );
  disableShowIndex$ = this.shouldShowIndex$.pipe(
    map(([activePersonaHasExcludedIds, widgetCount]) => widgetCount.length > 1 || activePersonaHasExcludedIds && this.insightsContext === 'explore')
  );
  disableShowIndexToolip$ = this.shouldShowIndex$.pipe(
    filter((([haveExcludedIds, widgetCount]) => haveExcludedIds || widgetCount.length > 1)),
    map(([haveExcludedIds, widgetCount]) => haveExcludedIds ? TOOLTIP_PERSONA_EXCLUDE_INDEX : TOOLTIP_COMPARE_DISABLED_INDEX_MULTI_WIDGET)
  );
  widgetsVisible: boolean = true;

  constructor(private store: Store<AppState>,
    public compareService: PersonLevelCompareService,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    this.store.select("insights", this.insightsContext, "indexMode").pipe(
      untilDestroyed(this),
    ).subscribe(indexMode => this.isIndexMode = indexMode);

    this.store.select("hierarchy").pipe(
      select(selectRegion),
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(region => this.region = region);
  }

  ngOnDestroy() { }

  toggleIndexMode() {
    this.store.dispatch(new insightsActions.ToggleIndexMode(this.insightsContext));
  }

  addWidget() {
    const newLength = this.widgetCount$.getValue().length + 1;
    const updatedWidgetCounts = this.widgetCount$.getValue();
    difference(Array.from(Array(newLength).keys()), this.widgetCount$.getValue()).forEach(w => updatedWidgetCounts.push(w));
    this.widgetCount$.next(updatedWidgetCounts);
    if (this.isIndexMode) { this.toggleIndexMode(); }
  }

  canAddWidget() {
    return this.widgetCount$.getValue().length < this.region.max_compare_widgets;
  }

  removeWidget(widgetIndex: number) {
    const remainingWidgets = this.widgetCount$.getValue().filter(index => index !== widgetIndex);
    this.widgetCount$.next(remainingWidgets);
  }

  addWidgetTooltip() {
    return `Select up to ${this.region.max_compare_widgets} widgets to display in the Compare tab at a time.`;
  }

  showWidget(event: boolean) {
    this.widgetsVisible = event;
  }
}
