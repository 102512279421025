import {Injectable, OnDestroy} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { AppState } from 'app/reducers';
import { selectSelectedJourney, selectActiveStages } from './journey.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Journey, JourneySubMarket, JourneyTabType, JourneyStage, JourneyBrand } from './journey.models';
import { get, sumBy, filter as _filter, find } from 'lodash';
import { InsightsCountService } from 'app/insights/insights-count.service';
import { journeySubMarketCountKey, journeyBrandQueryKey } from './journey.utils';
import { PersonLevelCompareService } from '../insights/insights-components/person-level-compare/person-level-compare.service';
import { isCompareMode } from '../insights/insights.reducer';
import { environment } from 'environments/environment';

@UntilDestroy()
@Injectable()
export class JourneyCountService implements OnDestroy {
  journey: Journey;
  journeyTab: JourneyTabType;
  counts = {};
  segmentCounts = {};
  totalMatchedCount: number;
  totalModeledCount: number;
  loadingCounts: boolean;
  selectedBrandId: number;
  activeStages: JourneyStage[] = [];
  isCompareMode: boolean;

  constructor(private store: Store<AppState>, private insightsCounts: InsightsCountService, private compareService: PersonLevelCompareService) {
    const journey$ = store.select("journey").pipe(select(selectSelectedJourney));
    const journeyTab$ = store.select("journey", "journeyTab");
    const selectedBrandId$ = store.select("journey", "selectedBrandId");
    const activeStages$ = store.select("journey").pipe(select(selectActiveStages));
    const isCompareMode$ = store.select("insights", "journey").pipe(select(isCompareMode))
    observableCombineLatest(
      insightsCounts.countsChanged$,
      journey$,
      journeyTab$,
      selectedBrandId$,
      activeStages$,
      isCompareMode$
    ).pipe(
      untilDestroyed(this)
    ).subscribe(([countData, journey, journeyTab, selectedBrandId, activeStages, isCompareMode]) => {
      this.segmentCounts = countData;
      this.journey = journey;
      this.journeyTab = journeyTab;
      this.selectedBrandId = selectedBrandId;
      this.activeStages = activeStages;
      this.isCompareMode = isCompareMode;
    })

    this.insightsCounts.loadingCounts$.pipe(
      untilDestroyed(this)).subscribe(loadingCounts => {
      this.loadingCounts = loadingCounts;
    })

    this.insightsCounts.countsChanged$.pipe(
      untilDestroyed(this)).subscribe(() => this.setTotalCounts())
  }

  ngOnDestroy() {

  }

  setTotalCounts() {
    switch (this.journeyTab) {
      case "Total Population":
        this.totalMatchedCount = get(this.segmentCounts, ["matched_id", "total_count"], 0)
        this.totalModeledCount = get(this.segmentCounts, ["modeled_id", "total_count"], 0)
        return;
      case "Matched":
        this.totalMatchedCount = get(this.segmentCounts, ["primary", "total_count"], 0)
        this.totalModeledCount = get(this.segmentCounts, ["modeled_id", "total_count"], 0)
        return;
      case "Modeled":
        this.totalMatchedCount = get(this.segmentCounts, ["matched_id", "total_count"], 0)
        this.totalModeledCount = get(this.segmentCounts, ["primary", "total_count"], 0)
        return;
    }
  }

  get totalPopulation() {
    return this.getPopulationForSubMarkets(_filter(this.subMarkets, subMarket => {
      return this.selectedBrandId === subMarket.journey_brand_id && !!find(this.activeStages, {id: subMarket.journey_stage_id})
    }))
  }

  get totalCount() {
    if (this.isCompareMode) {
      return this.compareService.totalCount;
    }
    switch (this.journeyTab) {
      case "Total Population":
        return this.totalPopulation || 0;
      case "Matched":
        return this.totalMatchedCount || 0;
      case "Modeled":
        return this.totalModeledCount || 0;
      default:
        return 0;
    }
  }

  get subMarkets() {
    return get(this.journey, "sub_markets");
  }

  getPopulationForSubMarkets(subMarkets: JourneySubMarket[]) {
    return sumBy(subMarkets, "population") || 0
  }

  getCountForSubMarket(subMarket: JourneySubMarket, queryKey= "primary"): number {
    switch (this.journeyTab) {
      case "Total Population":
        return subMarket.population || 0;
      default:
        return this.getCount(journeySubMarketCountKey(subMarket), queryKey);
    }
  }

  getCountForBrandStage(brand: JourneyBrand, stage: JourneyStage) {
    if (this.loadingCounts) {return null; }
    const subMarket = find(this.subMarkets, {journey_stage_id: stage.id, journey_brand_id: brand.id});
    if (!subMarket) {return null; }
    return this.getCountForSubMarket(subMarket, brand.id == this.selectedBrandId ? "primary" : journeyBrandQueryKey(brand));
  }

  getCountForStage(stage: JourneyStage): number {
    if (this.loadingCounts) {return null; }
    if (!this.activeStages.includes(stage)) {return 0; }
    const subMarket = find(this.subMarkets, {journey_stage_id: stage.id, journey_brand_id: this.selectedBrandId});
    if (this.isCompareMode) {
      switch (this.journeyTab) {
        case "Matched":
          return this.compareService.getCount(subMarket.matched_id, "total_count");
        case "Modeled":
          return this.compareService.getCount(environment.isTier3 ? subMarket.matched_id : subMarket.modeled_id, "total_count");
      }
    }
    return this.getCountForSubMarket(subMarket);
  }

  getTotalCountForBrand(brand: JourneyBrand) {
    switch (this.journeyTab) {
      case "Total Population":
        return sumBy(_filter(this.subMarkets, {journey_brand_id: brand.id}), "population");
      default:
        return this.getCount("total_count", brand.id == this.selectedBrandId ? "primary" : journeyBrandQueryKey(brand));
    }
  }

  getCount(identifier: string, queryKey= "primary") {
    if (this.segmentCounts[queryKey]) {
      return get(this.segmentCounts, [queryKey, identifier], 0)
    } else {
      return null
    }
  }
}
