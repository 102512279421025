import { BehaviorSubject, combineLatest as observableCombineLatest, Subject } from 'rxjs';
import { takeUntil, filter, map, take } from 'rxjs/operators';
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppState } from "app/reducers";
import { Brand } from "./brand/brand.model";
import { AuthPermission } from "../shared/interfaces/auth-permission";
import { NAV_ADMIN, NAV_ADMIN_BRANDS } from "../shared/utils/constants";
import { HierarchyBrand, HierarchyClient, HierarchyRegion } from "app/hierarchy/hierarchy.interface";
import { find, uniqBy, chain, flatMap } from 'lodash';
import { environment } from 'environments/environment';
import { getRegions } from "app/admin/region/region.reducers";
import { FetchRegions } from "app/admin/region/regions.actions";
import { Region } from "app/admin/region/region.model";
import { selectActiveClients } from 'app/hierarchy/hierarchy.reducers';

@Component({
  selector: 'app-brand-admin',
  templateUrl: './brand-admin.component.html',
  styleUrls: ['./brand-admin.component.sass']
})
export class BrandAdminComponent implements OnInit {
  activeTab = 'Brand Setup';
  client: HierarchyClient;
  clients: HierarchyClient[] = [];
  clientSlug$ = new BehaviorSubject<string>(null);
  clientRegions: HierarchyRegion[] = [];
  region: any;
  regions: Region[] = [];
  regionSlug$ = new BehaviorSubject<string>('*');
  brands: Brand[] = [];
  brandPermission: AuthPermission = { create: false, read: false, update: false, destroy: false };
  isTier3Environment = environment.isTier3;

  private ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private snackbar: MatSnackBar
  ) {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.regionSlug$.next(params.regionSlug || "*")
      this.clientSlug$.next(params.clientSlug)
    });

    observableCombineLatest([
      this.store.select('hierarchy', 'hierarchy').pipe(select(selectActiveClients), select('clients')),
      this.store.select('regions').pipe(map(getRegions)),
      this.regionSlug$,
      this.clientSlug$
    ]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        ([clients, regions, regionSlug, clientSlug]: [HierarchyClient[], Region[], string, string]) => {
          this.clients = clients;
          this.client = this.clients && find(this.clients, {slug: clientSlug}) || this.clients[0];
          this.clientRegions = [{slug: '*', name: 'All', id: null, brands: [], currency: ''}, ...this.client.regions];
          if (this.client && regionSlug) {
            const allBrands = chain(this.client.regions)
              .flatMap('brands')
              .groupBy('id')
              .map((brands, _) => ({
                ...brands[0],
                products: uniqBy(flatMap(brands, 'products'), 'id')
              }))
              .value()

            const targetRegion = regionSlug === "*" ? this.clientRegions[0] : this.client.regions.find((r: any) => r.slug == regionSlug);

            this.brands = (targetRegion && targetRegion['brands'].length && targetRegion['brands'] || allBrands)
              .map((b: HierarchyBrand) => new Brand(b))
          }
        }
      );

    this.store.select('permissions', 'brands').pipe(
      filter(Boolean),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(permission => this.brandPermission = permission);
  }

  ngOnInit() {
    this.store.dispatch( new FetchRegions() );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  clientSlugSelection(client) {
    this.clientSlug$.next(client);
  }

  addBrand(): void {
    const brand = new Brand();
    brand.client_id = this.client.id;
    brand.open();
    this.brands.unshift(brand);
  }

  onDeleteBrand(index: number): void {
    this.brands.splice(index, 1)
  }

  onClientChange(mdSelect): void {
    this.client = this.clients.find(e => e.slug === mdSelect.value);
    if (this.client.regions.find(r => r.slug !== this.regionSlug$.value)) {this.regionSlug$.next("*")}
  }

  onRegionSelect(region: Region): void {
    this.regionSlug$.next(region.slug)
  }

  setRouterUrl(clientSlug: string, regionSlug: string) {
    this.router.navigate([clientSlug, regionSlug, NAV_ADMIN_BRANDS, NAV_ADMIN]);
  }

  resetActiveTab() {
    return this.activeTab;
  }

  get ableToAddBrand(): Boolean {
    return (!this.isAnyBrandOpen && this.brandPermission.create)
  }

  get isAnyBrandOpen(): boolean {
    return this.brands.filter(brand => brand.isAnyProductOpen).length > 0;
  }
}
