import { ApiKeySecret } from 'app/admin/api-keys/api-keys.model';
import { Component, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { AppState } from "app/reducers";
import { Store } from "@ngrx/store";
import { SetApiKeySecret } from './api-keys.actions';
import { copyText } from 'app/shared/utils/utils';

@Component({
  selector: 'app-api-keys-secret',
  templateUrl: './api-keys-secret.component.html',
  styleUrls: ['./api-keys-secret.component.sass']
})
export class ApiKeysSecretComponent implements OnInit, OnDestroy {
  @ViewChild('apiKeySecret', { static: true }) dialog: any;
  @Input() apiKeySecret: ApiKeySecret;
  api_key_id: number;
  api_secret_key: string;
  copyText = copyText;
  copied = [false, false]

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.dialog.show();
    this.api_key_id = this.apiKeySecret.api_key_id;
    this.api_secret_key = this.apiKeySecret.api_secret_key;
  }
  resetApiKeySecret(): void {
    this.store.dispatch(new SetApiKeySecret(null));
  }
  onCancelClick(): void {
    this.dialog.onClose.emit();
  }
  ngOnDestroy(): void {
    this.resetApiKeySecret();
  }
}
