import { Component, Input } from '@angular/core';

@Component({
  selector: 'ppc-icon-button',
  templateUrl: './ppc-icon-button.component.html',
  styleUrls: ['./ppc-icon-button.component.sass']
})
export class PpcIconButtonComponent {
  @Input() icon: string;
  constructor() { }

}
