<div class="page-level">
  <div class="layout horizontal center-center bullseye">
    <div class="ppc-logo">
      <img src="assets/images/epsilon_logo/epc-discovery-dm.svg"
           alt="Epsilon PeopleCloud Discovery Logo">
    </div>
    <ng-content select=".content"></ng-content>
  </div>
  <footer>
    <ng-content select=".baseline"></ng-content>
  </footer>
</div>
