import { Component, OnDestroy, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/reducers";
import { BehaviorSubject, combineLatest, Observable, merge } from 'rxjs';
import { map, filter, take, switchMap } from 'rxjs/operators';
import { isEmpty, capitalize, get } from 'lodash';
import { Actions, ofType } from "@ngrx/effects";

import { getFullProductSlug } from "app/hierarchy/hierarchy.utils";
import { FetchOverviewLookalikes, DestroyLookalike, LoadLookalike } from 'app/lookalikes-v3/lookalike-v3.actions';
import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import { selectLookalikesWithType } from 'app/lookalikes-v3/lookalike-v3.reducers';
import { FetchOverviewAudiences, DestroyAudience, EditAudience } from "app/audiences-v2/audience-v2.actions";
import { AudienceV2, cloneAudience, groupCount, hasGlobalCounts } from 'app/audiences-v2/audience-v2.model';
import { selectAudiencesWithType } from "app/audiences-v2/audience-v2.reducers";
import { SegmentV2, getCount, pathString } from "app/audiences/discover/segment-v2.model";
import { selectSegmentsById } from "app/segments-v2/segment-v2.reducers";
import { isFetchInFlight, isNotYetFetched, fetchOutcome, isLoaded } from "app/shared/utils/fetch-state";
import { NAV_AUDIENCES, AUDIENCES_SUB_NAV_DISCOVER, V3, AUDIENCES_SUB_NAV_BUILDER } from "app/shared/utils/constants";
import {
  snackbarMessageV2,
  tooltipMessageV2 as audienceTooltipMessages,
  audienceType,
  audienceStatus,
  ALL
} from 'app/audiences/shared/audience.constants';
import { SlideInOverlayComponent } from "app/shared/components/slide-in-overlay/slide-in-overlay.component";
import { MaterialModalService } from 'app/shared/components/material-modal/material-modal.service';
import { tooltipMessage as lookalikeTooltipMessages } from 'app/audiences/shared/lookalike.constants';
import { PpcInputComponent } from 'app/shared/components/ppc-input/ppc-input.component';
import { FetchAllDataPermissions } from 'app/data_permissions/data_permissions.actions';
import { DataPermissions } from 'app/data_permissions/data_permissions.model';
import { FetchBarbs } from 'app/insights/grow-v3/grow.actions';
import { Barbs} from 'app/insights/grow-v3/grow.reducer';
import { GrowV3Service } from 'app/insights/grow-v3/grow-v3.service';
import {
  jobStatusClass,
  jobStatusDisplay,
  getTvPanelistTooltip,
  displayTvPanelists,
  canEstimatePanelists,
  hideTVPanelistColumn,
  isPeopleCountEstimated,
  displayPeopleCountEstimate,
  displayAddressableCounts,
  displaySegmentCount,
  expiredDate
} from 'app/audiences/shared/audience.utils';
import { OverviewV3Service } from 'app/audiences/overview-v3/overview-v3.service';
import { AddressableCountMetadataService } from "../../models/addressable-count-metadata/addressable-count-metadata.service";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fullContext } from 'app/hierarchy/hierarchy.reducers';
import { isPermissioned } from 'app/services/data_permissions.service';
import { isDV360, isTradeDesk, Partner } from 'app/models/partners/partner.model';
import { userCan } from 'app/authorization/permissions/permissions.reducers'
import { AudienceV2Service } from '../../audiences-v2/audience-v2.service';
import { ActivationOverlayService } from "./activation-overlay/activation-overlay.service";
import { NewDestinationOverlayComponent } from './new-destination-overlay/new-destination-overlay.component';
import {FetchAddressableCountsMetadata} from "../../models/addressable-count-metadata/addressable-count-metadata.actions";
import { IndexReportResourceType } from 'app/index-report/index-report.model';
import { CreateLookalikeModalComponent } from './create-lookalike/create-lookalike-modal/create-lookalike-modal.component';
import { OutcomeV1 } from 'app/outcomes-v1/outcomes-v1.model';
import { FetchOverviewOutcomes } from 'app/outcomes-v1/outcomes-v1.actions';
import { selectOutcomesWithType } from 'app/outcomes-v1/outcomes-v1.reducers'
import { MotivationV1 } from 'app/models/motivations/motivations-v1.model';
import { selectMotivationsWithType } from 'app/models/motivations/motivations-v1.reducer'
import { DestroyMotivation, FetchOverviewMotivations } from 'app/models/motivations/motivations-v1.actions';
import { tooltipMessage as outcomeTooltipMessages } from 'app/audiences/shared/outcome.constants';
import { tooltipMessage as motivationTooltipMessages } from 'app/audiences/shared/motivation.constants';
import { DependantError } from './audience-dependancy/insights-dependancy/insight-dependancy/insight-dependancy.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { OverviewV3Export } from 'app/audiences/overview-v3/overview-v3-export.model';
import { Workbook } from 'exceljs/dist/exceljs';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { ContextSlugs } from 'app/hierarchy/hierarchy.interface';

@UntilDestroy()
@Component({
  selector: 'ppc-overview-v3',
  templateUrl: './overview-v3.component.html',
  styleUrls: ['./overview-v3.component.sass']
})
export class OverviewV3Component implements OnInit, OnDestroy {
  @ViewChild('dataTable', { static: true }) table: DatatableComponent;
  // TODO: replace
  @ViewChild('lookalikeOverlay', { static: true }) lookalikeOverlay: SlideInOverlayComponent;
  @ViewChild('createLookalikeModal', { static: true }) createLookalikeModal: CreateLookalikeModalComponent;
  @ViewChild('motivationDetailOverlay', { static: true }) motivationDetailOverlay: SlideInOverlayComponent;
  @ViewChild('lookalikeDetailOverlay', { static: true }) lookalikeDetailOverlay: SlideInOverlayComponent;
  @ViewChild('audienceDetailOverlay', { static: true }) audienceDetailOverlay: SlideInOverlayComponent;
  @ViewChild('activationOverlay', { static: true }) activationOverlay: SlideInOverlayComponent;
  @ViewChild('destinationsOverlay', { static: true }) destinationsOverlay: SlideInOverlayComponent;
  @ViewChild('activationDestinationsOverlay', { static: true }) activationDestinationsOverlay: SlideInOverlayComponent;
  @ViewChild('newDestinationOverlay', { static: true }) newDestinationOverlay: SlideInOverlayComponent;
  @ViewChild('newDestinationMenu') newDestinationMenu: NewDestinationOverlayComponent;
  @ViewChild("deleteErrorDialogTemplate") deleteErrorDialogTemplate: TemplateRef<any>;
  @ViewChild("search", { static: true }) search: PpcInputComponent;

  activationObject: (AudienceV2 | LookalikeV3 | MotivationV1 | OutcomeV1);
  audienceDetailAudience: AudienceV2;
  detailObject: (AudienceV2 | LookalikeV3);
  detailMotivation: MotivationV1;
  audienceDetailAudienceIdentifier: string;
  audiences: AudienceV2[];
  panelistConstants: Barbs;
  deleteErrorTitle: string = "";
  deleteErrorBody: string[] = [];
  destinationsObject: (AudienceV2 | LookalikeV3 | MotivationV1 | OutcomeV1);
  destinationsPartner: Partner;
  destinationsPartnerId: string;
  rows: (AudienceV2 | LookalikeV3 | MotivationV1 | OutcomeV1)[] = [];
  editAlias: string = "Edit";
  editDisabled: boolean = false;
  getCount = getCount;
  groupCount = groupCount;
  loading$: Observable<boolean>;
  lookalikeUnderEdit: LookalikeV3;
  pathString = pathString;
  activeRegion: string = "";
  activeClient: string = "";
  segmentsById: {[identifier: string]: SegmentV2};
  snackbarMessage: {[message: string]: string} = snackbarMessageV2;
  lookalikeTooltipMessage: {[message: string]: string} = lookalikeTooltipMessages;
  audienceTooltipMessage: {[message: string]: string} = audienceTooltipMessages;
  searchQuery: string = "";
  permissions: {[identifier: string]: DataPermissions};
  canDisplayAddressableCounts: boolean = false;
  indexReportFormOpen = false;
  indexReportSelection$ = new BehaviorSubject<{ id: number, name: string, identifier: string, type: IndexReportResourceType }>(null);
  motivationAudienceFormOpen = false;
  canViewSegmentsCount: boolean = false;
  activationErrorCode: string;

  overviewContext$ = this.store.select('hierarchy', 'contextSlugs');
  overviewContext: ContextSlugs;
  openInsightsDependancyModal: boolean = false;
  dependantError: DependantError = {};

  jobStatusClass = jobStatusClass;
  jobStatusDisplay = jobStatusDisplay;
  getTvPanelistTooltip = getTvPanelistTooltip;
  isPeopleCountEstimated = isPeopleCountEstimated;
  displayPeopleCountEstimate = displayPeopleCountEstimate;
  hideTVPanelistColumn = hideTVPanelistColumn;
  displaySegmentCount = displaySegmentCount;
  expiredDate = expiredDate;

  types = [ALL, ...Object.values(audienceType)];
  typeFilter$ = new BehaviorSubject(ALL);
  statuses = [ALL, ...Object.values(audienceStatus)];
  statusFilter$ = new BehaviorSubject(ALL);

  constructor(
    private actions$: Actions,
    private materialModalService: MaterialModalService,
    private decimalPipe: DecimalPipe,
    private growV3Service: GrowV3Service, // Necessary if selecting panelist constants from grow v3's store
    private overviewV3Service: OverviewV3Service,
    private activationService: ActivationOverlayService,
    private addressableCountsService: AddressableCountMetadataService,
    private router: Router,
    private snackbar: MatSnackBar,
    private store: Store<AppState>,
    public audienceService: AudienceV2Service
  ) {
  }

  ngOnInit() {
    combineLatest(
      this.store.select('fetchStates', FetchOverviewAudiences.type).pipe(select(isLoaded)),
      this.store.select('audiencesV2').pipe(select(selectAudiencesWithType)),
      this.store.select('fetchStates', FetchOverviewLookalikes.type).pipe(select(isLoaded)),
      this.store.select('lookalikesV3').pipe(select(selectLookalikesWithType)),
      this.store.select('fetchStates', FetchOverviewOutcomes.type).pipe(select(isLoaded)),
      this.store.select('outcomesV1').pipe(select(selectOutcomesWithType)),
      this.store.select('fetchStates', FetchOverviewMotivations.type).pipe(select(isLoaded)),
      this.store.select('motivationsV1').pipe(select(selectMotivationsWithType)),
    ).pipe(
      untilDestroyed(this))
      .map(
        ([audiencesLoaded, audiences, lookalikesLoaded, lookalikes, outcomesLoaded, outcomes, motivationsLoaded, motivations]) => {
          const loaded = audiencesLoaded && lookalikesLoaded && outcomesLoaded && motivationsLoaded;
          const rowInfo = [...audiences, ...lookalikes, ...outcomes, ...motivations];
          if (loaded) {
            this.audiences = audiences;
            if (this.audienceDetailAudience) {
              this.audienceDetailAudience = this.audiences.find(audience => audience.identifier === this.audienceDetailAudience.identifier);
            }
            const identifiers = rowInfo.map(item => item['identifier']).filter(id => id != null);

            if (identifiers.length !== 0) { this.store.dispatch(new FetchAllDataPermissions(identifiers)); }
          }

          return rowInfo;
        }
      ).subscribe(rows => {
        this.rows = rows
      });

    const dataLoadingActions = [
      FetchOverviewAudiences,
      FetchOverviewLookalikes,
      FetchOverviewOutcomes,
      FetchOverviewMotivations,
      FetchBarbs,
      FetchAddressableCountsMetadata
    ];

    this.loading$ = combineLatest(
      dataLoadingActions.map(({ type }) => this.store.select('fetchStates', type)
        .pipe(select(isFetchInFlight))).concat(this.audienceService.loadingEstimatedCounts$)
    ).pipe(map(fetchStates => fetchStates.some(Boolean)));

    combineLatest(
      fullContext(this.store),
      this.store.select('addressableCountsMetadata')
    ).pipe(untilDestroyed(this)).subscribe(([context, metadata]) => {
      if (context.client) { this.activeClient = context.client.slug; }
      if (context.region) { this.activeRegion = context.region.slug; }
      this.typeFilter$.next(ALL);
      this.statusFilter$.next(ALL);
      this.searchQuery = "";

      this.addressableCountsService.addressableCounts(metadata, this.activeRegion);
      this.canDisplayAddressableCounts = this.addressableCountsService.canGetAddressableCounts();
    });

    fullContext(this.store)
      .pipe(
        switchMap(() => this.overviewV3Service.getActivationsContextInfo()),
        untilDestroyed(this)
      )
      .subscribe((res) => {
        this.activationErrorCode = res.error_code;
      });

    dataLoadingActions.forEach(
      (action: any) => this.store.select('fetchStates', action.type).pipe(
        select(isNotYetFetched),
        filter(Boolean),
        untilDestroyed(this),
      ).subscribe(() => {
        return this.store.dispatch(new action({ urlVersionNumber: V3 }))
      })
    );

    this.store.select('segmentsV2').pipe(
      select(selectSegmentsById),
      untilDestroyed(this),
    ).subscribe(segments => this.segmentsById = segments);

    this.store.select('dataPermissions').pipe(
      untilDestroyed(this),
    ).subscribe(permissions => this.permissions = permissions);

    this.store
      .select("permissions", "view_segment_counts")
      .pipe(untilDestroyed(this))
      .subscribe((segmentViewPermissions) => {
        this.canViewSegmentsCount = segmentViewPermissions.read;
      });

    this.store.select('grow', 'barbs').pipe(
      untilDestroyed(this),
    ).subscribe(panelistConstants => this.panelistConstants = panelistConstants);

    this.overviewV3Service.createLookalike$.pipe(
      untilDestroyed(this)
    ).subscribe(
      lookalike => {
        this.toggleCreateLookalikeModal(lookalike);
      });

    this.mergeDestroyActions();

    this.overviewV3Service.sendToTardiisSuccess$.subscribe(
      results => this.handleActionSuccess(results, 'tardiis')
    );

    this.overviewV3Service.sendToTardiisError$.subscribe(
      error => this.handleActionError(error)
    );

    this.overviewV3Service.sendToPartnerSuccess$.subscribe(
      results => this.handleActionSuccess(results, 'partner')
    );

    this.overviewV3Service.sendToPartnerError$.subscribe(
      error => this.handleActionError(error)
    );

    this.actions$.pipe(
      ofType(LoadLookalike.type),
      untilDestroyed(this)
    ).subscribe(() => {
      this.table.sorts = [{prop: 'created_at', dir: 'desc'}];

      if (this.audienceDetailOverlay.isPositionOn) {
        this.audienceDetailOverlay.toggleState();
      }
      if (this.motivationDetailOverlay.isPositionOn) {
        this.motivationDetailOverlay.toggleState();
      }
    });

    this.overviewContext$.pipe(
      untilDestroyed(this)
    ).subscribe((context) => {
      this.overviewContext = context;
    });
  }

  ngOnDestroy() {}

  mergeDestroyActions(): void {
    merge(
      this.actions$.pipe(fetchOutcome(DestroyLookalike.type)),
      this.actions$.pipe(fetchOutcome(DestroyAudience.type)),
      this.actions$.pipe(fetchOutcome(DestroyMotivation.type))
    ).pipe(untilDestroyed(this)
    ).subscribe(result => {
      const ppcItemType = {
        [DestroyLookalike.type]: audienceType.lookalike,
        [DestroyAudience.type]: audienceType.audience,
        [DestroyMotivation.type]: audienceType.motivation
      }[result.fetchAction.type];
      if (this.audienceDetailOverlay.isPositionOn && ppcItemType === audienceType.audience) {

        this.toggleAudienceDetail();
      }
      if (this.motivationDetailOverlay.isPositionOn && ppcItemType === audienceType.motivation) {
        this.motivationDetailOverlay.toggleState();
      }

      this.handleSnackBarOpen(`${capitalize(ppcItemType)} deleted`, 4000, "check")
    },
    error => this.handleDeleteError(error)
    );
  }

  updateFilter(value: string, filterColumn: string) {
    if (filterColumn === 'type') {
      this.typeFilter$.next(value);
    } else {
      this.statusFilter$.next(value);
    }
  }

  get visibleRows() {
    let rows = this.rows.filter(row => !this.searchQuery || row.name.toLowerCase().includes(this.searchQuery.toLowerCase()));

    if (this.typeFilter$.value !== ALL) {
      rows = rows.filter(row => row.type === this.typeFilter$.value);
    }
    if (this.statusFilter$.value !== ALL) {
      rows = rows.filter(row => jobStatusDisplay(row.job_status).toLowerCase() === this.statusFilter$.value);
    }
    return rows;
  }

  resetSearch(): void {
    this.searchQuery = "";
    this.search.focus();
  }

  get userCanCreateAudience(): boolean {
    return userCan('create', 'audiences', this.store)
  }

  openCreateAudience() {
    this.router.navigate([getFullProductSlug(), NAV_AUDIENCES, AUDIENCES_SUB_NAV_BUILDER]);
  }

  get userCanCreateMotivationAudience(): boolean {
    return userCan('create', 'motivation_audiences', this.store);
  }

  canSeedLookalike(audience: AudienceV2): boolean {
    return !isEmpty(audience) && audience.job_status === audienceStatus.complete && hasGlobalCounts(audience)
    && isPermissioned(audience, this.permissions, 'modeling_seed');
  }

  compareStatus(job_status1: string, job_status2: string): number {
    const statusPriority = {
      "pending": 1,
      "processing": 2,
      "preliminary": 3,
      "complete": 4
    };
    if (statusPriority[job_status1] > statusPriority[job_status2]) {
      return 1;
    } else if (statusPriority[job_status1] < statusPriority[job_status2]) {
      return -1;
    } else {
      return 0;
    }
  }

  editAudience(audience) {
    if (audience.job_status === audienceStatus.pending) {
      this.router.navigate([getFullProductSlug(), NAV_AUDIENCES, AUDIENCES_SUB_NAV_DISCOVER, audience.id]);
    } else {
      this.router.navigate([getFullProductSlug(), NAV_AUDIENCES, AUDIENCES_SUB_NAV_DISCOVER], {queryParams: { audienceCloned: true }});
      this.store.dispatch(new EditAudience(cloneAudience(audience)))
    }
  }

  addressableCountsDisplay(ppcItem) {
    const peopleCount = get(ppcItem, ['count', 'people', 'global'], 0)
    return this.addressableCountsService.displayCalculatedAddressableCounts(peopleCount)
  }

  addressableCountTooltip(ppcItem): string {
    const addressableCount = this.addressableCountsDisplay(ppcItem)
    let messageKey = ""

    if (addressableCount && (addressableCount !== '-')) {
      messageKey = "addressableCount";
    } else {
      messageKey = "addressableCountUnavailable";
    }

    return audienceTooltipMessages[messageKey]
  }

  get hasPanelistConstants(): boolean {
    return canEstimatePanelists(this.panelistConstants, this.activeRegion);
  }

  tvPanelistsDisplay(ppcItem): string {
    return displayTvPanelists(ppcItem, this.hasPanelistConstants, this.panelistConstants, this.decimalPipe);
  }

  compareTvPanelists(panelists1, panelists2, row1, row2, sortDir): number {
    const panelistsComparison = handleTvPanelistsComparison(panelists1, panelists2, sortDir);
    if (panelistsComparison) { return panelistsComparison; }

    const row1Count = get(row1, ['count', 'people', 'global']);
    const row2Count = get(row2, ['count', 'people', 'global']);
    const rowsComparison = handleTvPanelistsComparison(row1Count, row2Count, sortDir);
    if (rowsComparison) { return rowsComparison; }

    return 0;
  }

  compareAddressableCount(_, _2, row1, row2, sortDir) {
    const peopleCount1 = get(row1, ['count', 'people', 'global']);
    const peopleCount2 = get(row2, ['count', 'people', 'global']);

    const rowsComparison = handleTvPanelistsComparison(peopleCount1, peopleCount2, sortDir);
    if (rowsComparison) { return rowsComparison; }

    return 0;
  }

  panelistTooltip(ppcItem): string {
    return getTvPanelistTooltip(ppcItem, this.hasPanelistConstants);
  }

  panelistTooltipDisabled(ppcItem): boolean {
    return !!ppcItem.panelist_count;
  }

  get userCanEditLookalikes(): boolean {
    return userCan('update', 'lookalikes', this.store)
  }

  handleViewDetail(ppcObject) {
    switch (ppcObject.type) {
      case audienceType.lookalike:
        this.toggleLookalikeOverlay(ppcObject)
        break;
      case audienceType.motivation:
        this.toggleMotivationOverlay(ppcObject)
        break;
      // TODO: add the detail pages for outcome when they are active.
    }
  }

  toggleLookalikeOverlay(lookalike) {
    this.detailObject = lookalike;
    this.lookalikeDetailOverlay.toggleState();
  }

  toggleMotivationOverlay(motivation) {
    this.detailMotivation = motivation;
    this.motivationDetailOverlay.toggleState();
  }

  toggleCreateLookalikeModal(lookalike) {
    this.lookalikeUnderEdit = lookalike;
    this.createLookalikeModal.toggleState();
  }

  isAudience(ppcItem) {
    return ppcItem.type === audienceType.audience;
  }

  isLookalike(ppcItem) {
    return ppcItem.type === audienceType.lookalike;
  }

  isMotivation(ppcItem) {
    return ppcItem.type === audienceType.motivation;
  }

  audienceEditAlias(audience): string {
    return (audience.job_status === audienceStatus.complete || audience.job_status === "processing") ? 'Clone' : 'Edit';
  }

  isComplete(ppcItem): boolean {
    return ppcItem.job_status === audienceStatus.complete;
  }

  canEdit(ppcItem): boolean {
    return !this.isProcessing(ppcItem) && this.userCanEditLookalikes;
  }

  editTooltipMessage(ppcItem): string {
    let option = "";
    if (this.isProcessing(ppcItem)) {
      option = "Processing";
    } else if (!this.userCanEditLookalikes) {
      option = "NotUserPermissioned";
    }
    return this.getTooltipMessage(ppcItem.type, `disabledEdit${option}`);
  }

  isProcessing(ppcItem): boolean {
    return ppcItem.job_status === "processing";
  }

  canSendToTardiis(ppcItem): boolean {
    return (!ppcItem.send_to_tardiis && this.isComplete(ppcItem) && isPermissioned(ppcItem, this.permissions, 'tv_planning'));
  }

  get userCanSendToPartner(): boolean {
    return userCan('create', 'send_to_partner', this.store)
  }

  canSendToPartner(ppcItem): boolean {
    return (this.isComplete(ppcItem) && this.userCanSendToPartner && isPermissioned(ppcItem, this.permissions, 'activation'));
  }

  get userCanInitiateIndexReport(): boolean {
    return userCan('create', 'index_report', this.store);
  }

  canInitiateIndexReport(ppcItem): boolean {
    return this.isComplete(ppcItem) && this.userCanInitiateIndexReport;
  }

  reopenPartnerOverlay() {
    this.activationDestinationsOverlay.toggleState();
    this.activationOverlay.toggleState();
  }

  handleSnackBarOpen(message, duration, extraClass) {
    this.snackbar.open(message, null, {
      duration: duration,
      panelClass: [
        extraClass
      ]
    })
  }

  tardiisTooltipMessage(ppcItem): string {
    let option = "";
    if (!this.isComplete(ppcItem)) {
      option = "Incomplete";
    } else if (!isPermissioned(ppcItem, this.permissions, 'tv_planning')) {
      option = "NotPermissioned"
    } else if (ppcItem.send_to_tardiis) {
      option = "AlreadySent";
    }
    return this.getTooltipMessage(ppcItem.type, `disabledTardiis${option}`);
  }

  disabledLookalikeCreationTooltip(ppcItem): string {
    let messageKey = 'lookalikeCreationDisabled';
    if (!isPermissioned(ppcItem, this.permissions, 'modeling_seed')) { messageKey = 'lookalikeNotPermissioned' };
    return this.getTooltipMessage(ppcItem.type, messageKey);
  }

  estimatedPeopleCountTooltipMessage(value, ppcItem): string {
    let messageKey = "";
    if (value || value === 0) {
      messageKey = "estimatedPeopleCount";
    } else {
      messageKey = "failedEstimatedPeopleCount"
    }
    return this.getTooltipMessage(ppcItem.type, messageKey)
  }

  getTooltipMessage(type: string, message: string): string {
    let tooltipMessage
    switch (type) {
      case audienceType.audience:
        tooltipMessage = this.audienceTooltipMessage;
        break;
      case audienceType.lookalike:
        tooltipMessage = this.lookalikeTooltipMessage;
        break;
      case audienceType.outcome:
        tooltipMessage = outcomeTooltipMessages;
        break;
      case audienceType.motivation:
        tooltipMessage = motivationTooltipMessages;
        break;
    }
    return tooltipMessage[message]
  }

  sendToPartnerTooltipMessage(ppcItem): string {
    let option = "";
    if (!this.isComplete(ppcItem)) {
      option = "Incomplete";
    } else if (!this.userCanSendToPartner) {
      option = "NotUserPermissioned";
    } else if (!isPermissioned(ppcItem, this.permissions, 'activation')) {
      option = "NotDataPermissioned";
    }
    return this.getTooltipMessage(ppcItem.type, `sendToPartner${option}`)
  }

  indexReportTooltipMessage(ppcItem): string {
    let option = '';
    if (!this.userCanInitiateIndexReport) {
      option = 'NotUserPermissioned';
    } else if (!this.isComplete(ppcItem)) {
      option = 'CreationDisabled';
    }
    return this.getTooltipMessage(ppcItem.type, `indexReport${option}`);
  }

  get userCanDeleteLookalike(): boolean {
    return userCan('destroy', 'lookalikes', this.store)
  }

  canDelete(ppcItem): boolean {
    let usedAsSeedOrPreliminary;
    if (this.isAudience(ppcItem)) {
      usedAsSeedOrPreliminary = ppcItem.used_as_seed;
    } else if (this.isLookalike(ppcItem)) {
      usedAsSeedOrPreliminary = ppcItem.jobStatus === audienceStatus.preliminary;
    }
    return !(this.isProcessing(ppcItem)) && this.userCanDeleteLookalike &&
           !ppcItem.send_to_tardiis && !usedAsSeedOrPreliminary;
  }

  deleteTooltipMessage(ppcItem): string {
    let option = "";
    if (this.isProcessing(ppcItem)) {
      option = "Processing";
    } else if (!this.userCanDeleteLookalike) {
      option = "NotUserPermissioned";
    } else if (ppcItem.send_to_tardiis) {
      option = "Tardiis";
    } else if (ppcItem.used_as_seed) {
      option = "Seed";
    }

    return this.getTooltipMessage(ppcItem.type, `disabledDelete${option}`);
  }

  openAudienceDetail(audience: AudienceV2) {
    this.audienceDetailAudience = audience;
    this.toggleAudienceDetail();
  }

  toggleAudienceDetail() {
    this.audienceDetailOverlay.toggleState();
  }

  toggleDetailAndActivation(ppcObject) {
    if (ppcObject.type === audienceType.audience) {
      this.toggleAudienceDetail();
    } else if (ppcObject.type === audienceType.lookalike) {
      this.toggleLookalikeOverlay(ppcObject);
    } else if (ppcObject.type === audienceType.motivation) {
      this.toggleMotivationOverlay(ppcObject);
    }

    this.openActivationOverlay(ppcObject);
  }

  openActivationOverlay(ppcObject) {
    this.activationObject = ppcObject;
    this.toggleActivationOverlay();
  }

  toggleActivationOverlay() {
    this.activationOverlay.toggleState();
  }

  openDestinationsOverlay({ppcObject, partner}: {ppcObject: AudienceV2 | LookalikeV3 | MotivationV1 | OutcomeV1, partner: Partner}) {
    this.destinationsObject = ppcObject;
    this.destinationsPartner = partner;

    if (partner.destination_count === 0 && (isTradeDesk(partner) || isDV360(partner))) {
      this.toggleCreateDestOverlay();
    } else {
      this.toggleDestinationsOverlay();
    }
  }

  toggleDestinationsOverlay() {
    this.activationDestinationsOverlay.toggleState();
  }

  createDestinationOverlay($event) {
    this.destinationsPartner = $event.partner;
    this.toggleCreateDestOverlay();
  }

  toggleCreateDestOverlay() {
    this.newDestinationMenu.reset();
    this.newDestinationOverlay.toggleState();
  }

  onNewDestinationClose(event: {partner: Partner} | undefined) {
    if (event) {
      const partner = event.partner;
      if (!(partner.destination_count === 0 && (isTradeDesk(partner) || isDV360(partner)))) {
        this.newDestinationOverlay.toggleState();
      }
    } else {
      this.newDestinationOverlay.toggleState();
    }
  }

  deleteLookalike(lookalike) {
    this.overviewV3Service.deletePpcItem(lookalike);
  }

  deleteConfirmationMessage(ppcItem): string {
    return `Are you sure you want to delete this ${ppcItem.type}? This action cannot be undone.`;
  }

  handleActionSuccess(response, type: 'tardiis' | 'partner'): void {
    let message = '';
    if (type === 'tardiis') {
      message = response['data']['name'] + this.snackbarMessage["sentToTardiis"];
    } else if (type === 'partner') {
      message = response['name'] + this.snackbarMessage["sentToPartner"];

      if (this.lookalikeDetailOverlay.isPositionOn) {
        this.lookalikeDetailOverlay.toggleState();
      }
    }
    this.handleSnackBarOpen(message, 4000, "check");
  }

  handleActionError(error): void {
    this.handleSnackBarOpen(error.error.error.message, 4000, 'danger');
  }

  handleDeleteError(error: HttpErrorResponse) {
    if (error?.error?.error?.['data']
    ) {
      this.dependantError = error;
      this.openInsightsDependancyModal = true;
    } else {
      const message = JSON.parse(error.error.error.message);
      this.deleteErrorTitle = message[0];
      this.deleteErrorBody = message.slice(1);
      this.materialModalService.confirm(this.deleteErrorTitle,
        null,
        null,
        "OK",
        this.deleteErrorDialogTemplate);
    }
    this.mergeDestroyActions();
  }

  sendToPartner(ppcObject) {
    this.openActivationOverlay(ppcObject);
  }

  sendObjectToDestinations($event) {
    this.overviewV3Service.submitPpcObjectToDestination($event.ppcObject, $event.destinationIds, $event.useCases).subscribe(() => {
      this.activationService.fetchPartners(this.activeClient).subscribe()
    });
    this.activationDestinationsOverlay.toggleState();
  }

  sendToTardiis(ppcObject) {
    this.overviewV3Service.sendToTardiis(ppcObject);
  }

  panelistDataSourceMessage() {
    return audienceTooltipMessages.panelistHeaderTooltipAU;
  }

  segmentCountMessage() {
    return audienceTooltipMessages.segmentCount;
  }

  regionIsNotAustralia() {
    return this.activeRegion !== 'au';
  }

  determineRowClickAction(row) {
    if (this.isAudience(row)) {
      this.openAudienceDetail(row)
    } else if (this.isLookalike(row)) {
      this.toggleLookalikeOverlay(row)
    } else if (this.isMotivation(row)) {
      this.toggleMotivationOverlay(row);
    } else {return}
  }

  openIndexReportForm(ppcItem, toggleDetail = false) {
    if (toggleDetail && ppcItem.type === audienceType.audience) {
      this.toggleAudienceDetail();
    } else if (toggleDetail && ppcItem.type === audienceType.lookalike) {
      this.toggleLookalikeOverlay(ppcItem)
    } else if (toggleDetail && ppcItem.type === audienceType.motivation) {
      this.toggleMotivationOverlay(ppcItem);
    }
    const indexReportItemDetails = {
      id: ppcItem.id as number,
      name: ppcItem.name,
      identifier: ppcItem.identifier,
      type: ppcItem.type as IndexReportResourceType,
    }
    this.indexReportSelection$.next(indexReportItemDetails);
    this.toggleIndexReportForm();
  }

  toggleIndexReportForm(): void {
    this.indexReportFormOpen = !this.indexReportFormOpen;
  }

  toggleMotivationAudienceForm(): void {
    this.motivationAudienceFormOpen = !this.motivationAudienceFormOpen;
  }

  handleDialogClose(): void {
    this.openInsightsDependancyModal = false;
  }

  tvPanelistsTooltip() {
    if (this.regionIsNotAustralia()) {
      return this.audienceTooltipMessage['panelistCount']
    } else {
      return this.panelistDataSourceMessage()
    }
  }

  downloadTableAsExcel() {
    const {clientSlug, regionSlug, brandSlug, productSlug} = this.overviewContext;
    const helper = new OverviewV3Export(this.table, this.addressableCountsService, this);
    const wb = new Workbook();
    const audienceOverviewWs = wb.addWorksheet('Audience Overview Table');
    helper.addAudienceOverviewTable(audienceOverviewWs);

    const date = moment(new Date()).format('YYYY-MM-DD');
    const fileName = `${clientSlug}.${regionSlug}.${(brandSlug)}.${(productSlug)}.${date} Audience Table.xlsx`;

    wb.xlsx.writeBuffer().then(d => {
      const blob = new Blob([d], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, fileName);
    });
  }
}

export function handleTvPanelistsComparison(count1, count2, sortDir) {
  if (count1 && count2) {
    if (count1 > count2) { return 1; }
    if (count1 < count2) { return -1; }
    return 0;
  }

  if (!count1 && count2 && sortDir === 'desc') { return -1; }
  if (!count1 && count2 && sortDir === 'asc') { return 1; }
  if (count1 && !count2 && sortDir === 'desc') { return 1; }
  if (count1 && !count2 && sortDir === 'asc') { return -1; }
}
