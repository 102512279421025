
import {of as observableOf,  Observable } from 'rxjs';

import {publishReplay, mergeMap, concat, catchError, refCount, map, filter} from 'rxjs/operators';
import {Action} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";
import {values, get} from 'lodash';
import { FetchState } from "./fetch-state";


export interface BulkFetchState {
  [requestId: string]: FetchState
}

export interface BulkFetchAction extends Action {
  requestId: string;
}

export class StartBulkFetch implements Action {
  static readonly type = "START_BULK_FETCH";
  readonly type = StartBulkFetch.type;

  constructor(public fetchAction: BulkFetchAction) { }
}

export class CompleteBulkFetch implements Action {
  static readonly type = 'COMPLETE_BULK_FETCH';
  readonly type = CompleteBulkFetch.type;

  constructor(public fetchAction: BulkFetchAction, public result = undefined) {}
}

export class ErrorBulkFetch implements Action {
  static readonly type = 'ERROR_BULK_FETCH';
  readonly type = ErrorBulkFetch.type;

  constructor(public fetchAction: BulkFetchAction, public error) {}
}

export class ResetBulkFetchState implements Action {
  static readonly type = 'RESET_BULK_FETCH_STATE';
  readonly type = ResetBulkFetchState.type;

  constructor(public fetchAction: BulkFetchAction) {}
}

type BulkFetchActions = StartBulkFetch | CompleteBulkFetch | ErrorBulkFetch | ResetBulkFetchState

export function reducer(state: BulkFetchState, action: BulkFetchActions) {
  switch (action.type) {
    case StartBulkFetch.type:
      return {...state, [action.fetchAction.requestId]: {isFetchInFlight: true}}
    case CompleteBulkFetch.type:
      return {...state, [action.fetchAction.requestId]: {isFetchInFlight: false, lastLoadTime: Date.now(), error: null}}
    case ErrorBulkFetch.type:
      return {...state, [action.fetchAction.requestId]: {error: action.error, isFetchInFlight: false, lastErrorTime: Date.now()}}
    case ResetBulkFetchState.type:
      return {}
    default:
      return state
  }
}

// // Selectors

export function isBulkFetchInFlight(state: BulkFetchState) {
  return values(state).some(request => request.isFetchInFlight)
}

export function selectAllErrors(state: MetaBulkFetchState, fetchActionType: keyof MetaBulkFetchState): any[] {
  return values(state[fetchActionType])
    .map(fetchState => fetchState.error)
    .filter(Boolean);
}

// // Meta-reducer (delegates to a normal reducer for each fetchActionType)

export interface MetaBulkFetchState { [fetchActionType: string]: BulkFetchState }

export function bulkFetchMetaReducer(state: MetaBulkFetchState = {}, action: any): MetaBulkFetchState {
  const fetchBulkActionType = get(action, 'fetchAction.type') as string;
  if (!fetchBulkActionType) {return state; }

  const fetchState = state[fetchBulkActionType] || {};

  return Object.assign({}, state, {
    [fetchBulkActionType]: reducer(fetchState, action)
  });
}

// Effects helper for emitting all the right fetchState actions during an http call
export function bulkFetchResource(fetcher: (action) => Observable<any>) {
  return function(actions$: Observable<BulkFetchAction>): Observable<BulkFetchAction> {
    return actions$.pipe(mergeMap(action => {
      const response = fetcher(action).pipe(publishReplay(), refCount(), );
      return observableOf(new StartBulkFetch(action)).pipe(
        concat(response.pipe(filter(a => !!a.type))), // Dispatch the result of the fetcher if it is an action
        concat(response.pipe(map(res => new CompleteBulkFetch(action, res)))),
        catchError(err => observableOf(new ErrorBulkFetch(action, err))), )
    }))
  }
}

export function fetchOutcome(type: string, requestId: string) {
  return function(actions$: Actions): Observable<BulkFetchActions> {
    return actions$.pipe(
      ofType(CompleteBulkFetch.type, ErrorBulkFetch.type),
      filter((action: CompleteBulkFetch | ErrorBulkFetch) => action.fetchAction.type === type && action.fetchAction.requestId == requestId),
      map((action: BulkFetchActions) => {
        if (action.type === CompleteBulkFetch.type) {
          return action
        } else {
          throw (<ErrorBulkFetch>action).error
        }
      }
      ), )
  }
}
