<div class="scenario-form-wrapper" >

  <div class="page-title">
    <h1>{{isNewPlan ? "Create" : "Edit"}} Scenario </h1>
    <button class="close-btn fa fa-2x fa-times" (click)="closeForm()" ></button>
  </div>
  <div class="form-validation-msg" *ngIf="!isValid"><i class="fa fa-exclamation-circle"></i>{{errorMsg || "Please correct the errors in the form below."}}</div>

  <plan-inputs
    #planInputsForm
    [planNames]="planNames"
    [currencySymbol]="currencySymbol"
    [planInputs]="planInputs"
    [curveTypes]="curveTypes"
    [goalTypes]="goalTypes"
    [editMode]="!isNewPlan"
    [importedPlan]="importedPlan"
    (madeSelection)="updateOptions()"
  ></plan-inputs>

  <media-type-table
    #mediaTypeTable
    [hidden]="importedPlan"
    [mediaTypes]="mediaTypeGoals"
    [budget]="planInputs.goal"
    [currencySymbol]="currencySymbol"
    [hideMinMax]="isReachGoal"
  ></media-type-table>

  <div class="buttons">

    <button 
      [disabled]="nonEditable" 
      class="btn btn-save" 
      (click)="confirmForm()"
    > 
      Save
    </button>
    <button class="btn cancel-btn" (click)="closeForm()">Cancel</button>

  </div>
</div>

