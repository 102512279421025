<div class="epc-dialog" [class.open]="open">
  <div class="close-button-container">
    <i *ngIf="dismissable" class="close-btn fa fa-close fa-lg hoverable" (click)="close()"></i>
  </div>
  <div class="main-container">
      <div [class]="titleStyle">{{ title }}</div>
      <div [class]="descriptionStyle">{{ description }}</div>
      <ng-content select="[body]"></ng-content>
  </div>
  <div *ngIf="displayFooter" class="footer">
    <button class="epc-button primary" [disabled]="primaryButtonDisabled" (click)="handlePrimaryButtonClick()">{{ primaryButtonTitle }}</button>
    <button *ngIf="secondaryButtonTitle" class="epc-button primary" [disabled]="secondaryButtonDisabled" (click)="handleSecondaryButtonClick()">{{ secondaryButtonTitle }}</button>
    <button class="epc-button textlink" (click)="close()">Cancel</button>
  </div>
</div>

<div class="epc-dialog-overlay" [class.open]="open" (click)="dismissOnBlur && close()">
</div>
