import {Action} from "@ngrx/store";
import {User} from "./user.interface";

export class FetchCurrentUser implements Action {
  static readonly type = 'FETCH_CURRENT_USER';
  readonly type = FetchCurrentUser.type;

  constructor(public ping?: {ping: boolean}) {}
}

export class LoadCurrentUser implements Action {
  static readonly type = 'LOAD_CURRENT_USER';
  readonly type = LoadCurrentUser.type;

  constructor(public user: User) {}
}

export class FetchAllUsers implements Action {
  static readonly type = 'FETCH_ALL_USERS';
  readonly type = FetchAllUsers.type;

  constructor() {}
}

export class LoadUsers implements Action {
  static readonly type = 'LOAD_USERS';
  readonly type = LoadUsers.type;

  constructor(public users: User[]) {}
}

export class AddUser implements Action {
  static readonly type = 'ADD_USER';
  readonly type = AddUser.type;

  constructor(public user: User) { }
}

export class RemoveUser implements Action {
  static readonly type = 'REMOVE_USER';
  readonly type = RemoveUser.type;

  constructor(public id: number) { }
}

export class EditUser implements Action {
  static readonly type = 'EDIT_USER';
  readonly type = EditUser.type;

  constructor(public user: User) { }
}

export class SetEditUser implements Action {
  static readonly type = 'SET_EDIT_USER';
  readonly type = SetEditUser.type;

  constructor(public user: User, public toActivate = false) { }
}

export type All = FetchAllUsers | LoadUsers | AddUser | RemoveUser | EditUser | SetEditUser | FetchCurrentUser
| LoadCurrentUser;
