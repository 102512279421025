import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PpcComponentsModule } from 'app/modules/ppc-components.module';

import { SegmentPermissionsTableComponent } from './segment-permissions-table/segment-permissions-table.component';



@NgModule({
  declarations: [SegmentPermissionsTableComponent],
  imports: [
    CommonModule,
    PpcComponentsModule
  ],
  exports: [SegmentPermissionsTableComponent]
})
export class SegmentPermissionsV2Module { }
