import { Directive, OnDestroy, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { findIndex, sortBy } from 'lodash';

import { PackeryService } from 'app/services/packery.service';

@Directive({
  selector: '[packery]'
})
export class PackeryDirective implements OnDestroy, OnInit {
  @Input("packery") bagId: string;
  @Input() packeryModel: any[];
  @Input() forceLayout$: Subject<{}>;
  @Output() orderChanged = new EventEmitter<any[]>();

  constructor(private element: ElementRef, private packery: PackeryService) { }

  ngOnInit() {
    this.packery.initialize({
      element: this.element.nativeElement,
      forceLayout$: this.forceLayout$,
      id: this.bagId
    }).subscribe((elements: HTMLElement[]) => {
      const newOrder = sortBy(this.packeryModel, item => findIndex(elements, element => +element.dataset.id === item.id));
      this.orderChanged.emit(newOrder);
    });
  }

  ngOnDestroy() {
    this.packery.destroy(this.bagId);
  }

}
