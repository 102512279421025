import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, merge as observableMerge, combineLatest as observableCombineLatest } from "rxjs";
import { map, mergeMap, filter, switchMap, tap, take, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'app/reducers';
import { fetchResource, ResetFetchState } from "app/shared/utils/fetch-state";
import { PpcHttpService } from "app/services/ppc_http.service";
import * as insightsActions from 'app/insights/insights.actions';
import * as actions from "app/explore/explore.actions";
import { activePersonaHasExcludedIds, Persona, selectActivePersona, selectAllDataPersonaId, selectPersonas } from "app/explore/explore.reducer";
import { isDefined } from "app/shared/utils/utils";
import { ChangeContext } from "app/hierarchy/hierarchy.actions";
import { fullContext } from "app/hierarchy/hierarchy.reducers";
import { find, isEmpty } from "lodash";
import { FetchAllDataPermissions } from 'app/data_permissions/data_permissions.actions';
import { UrlService } from 'app/services/url.service';
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { allPersonaIdentifiers } from 'app/explore/explore.utils';
import { userPreferencesUrl, personasExportUrl, personasUrl } from "app/shared/constants/insights.urls";

@Injectable()
export class ExploreService {
  @Effect()
  fetchPersonas$ = this.actions$.pipe(
    ofType(actions.FetchPersonas.type),
    (
      fetchResource(
        () => this.getPersonasForProduct().pipe(map(personas => new actions.LoadPersonas(personas)))
      )
    )
  );

  @Effect()
  savePersona$ = this.actions$.pipe(
    ofType(actions.SavePersona.type),
    fetchResource(
      action => this.savePersona(action.persona).pipe(map(persona => new actions.LoadPersona(persona)))
    )
  );

  @Effect()
  copyPersona$ = this.actions$.pipe(
    ofType(actions.CopyPersona.type),
    fetchResource(
      ({ id }) => this.copyPersona(id).pipe(map(persona => new actions.LoadPersona(persona)))))

  @Effect()
  changeContext$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    mergeMap(() => [
      new ResetFetchState(insightsActions.FetchDemographics),
      new ResetFetchState(insightsActions.FetchRegionDemographics),
      new ResetFetchState(insightsActions.FetchTabs),
      new ResetFetchState(actions.FetchPersonas),
      new actions.ClearSelectedPersonas(),
    ])
  );

  @Effect()
  clearSelectedPersonas$ = this.actions$.pipe(
    ofType(actions.SetSelectedPersona.type),
    map(() => new actions.ClearSelectedPersonas()));

  @Effect()
  fetchPersonaItems$ = this.store.select("explore", "selectedPersonaId").pipe(
    filter(isDefined),
    mergeMap(personaId => [
      new insightsActions.FetchDemographics(personaId, "Persona", "explore"),
      new insightsActions.FetchDemographicsConfig(userPreferenceKeys.standardDemographics("explore"), personaId, "Persona", "explore"),
      new insightsActions.SetTopLevelTab("Person Level", "explore"),
      new insightsActions.FetchMarketLevelSurveys(personaId, "Persona", "explore"),
      new insightsActions.FetchCustomTabsConfig(personaId, "Persona", "explore"),
      new insightsActions.FetchRegionDemographics("explore"),
      new insightsActions.FetchMarketLevelDemographics("explore", personaId, "Persona"),
    ])
  );

  @Effect()
  fetchDataPermissions$ = fullContext(this.store).pipe(
    filter(Boolean),
    switchMap(() =>
      this.store.select("explore").pipe(select(selectActivePersona), filter(isDefined)).pipe(
        map(persona => persona.identifiers),
        map(identifiers => allPersonaIdentifiers(identifiers)),
      ),
    ),
    filter(identifiers => !isEmpty(identifiers)),
    map(identifiers => new FetchAllDataPermissions(identifiers))
  );

  @Effect()
  destroyPersona$ = this.actions$.pipe(
    ofType(actions.DestroyPersona.type),
    fetchResource(
      ({ persona }) => this.destroyPersona(persona).map(response => {
        if (response[`status`] === "Ok") {
          return new actions.RemovePersona(persona)
        }
      })
    )
  );

  @Effect()
  getDefaultPersona$ = this.urlService.pathParams$.pipe(
    map(params => params.productSlug),
    filter(isDefined),
    map(() => new actions.FetchDefaultPersona()),
  )

  @Effect()
  fetchDefaultPersona$ = this.actions$.pipe(
    ofType(actions.FetchDefaultPersona.type),
    (
      fetchResource(
        () => this.fetchDefaultPersona().pipe(map(defaultPersonaId => {
          return new actions.LoadDefaultPersona(defaultPersonaId)
        }))
      )
    )
  );

  @Effect()
  saveDefaultPersona$ = this.actions$.pipe(
    ofType(actions.SaveDefaultPersona.type),
    (
      fetchResource(
        action => this.saveDefaultPersona(action.defaultPersonaId)
          .pipe(map(defaultPersonaId => new actions.LoadDefaultPersona(defaultPersonaId)))
      )
    )
  );

  @Effect()
  defaultSelectedPersona$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    switchMap(() => {
      return observableMerge(
        observableCombineLatest(
          this.actions$.pipe(ofType(actions.LoadPersonas.type)),
          this.actions$.pipe(ofType(actions.LoadDefaultPersona.type))
        ).pipe(take(1)),
        this.actions$.pipe(
          ofType(actions.RemovePersona.type),
          withLatestFrom(this.store.select('explore', 'selectedPersonaId')),
          filter(([action, selectedPersonaId]: [actions.RemovePersona, number]) => action.persona.id === selectedPersonaId)
        ),
        this.store.select("explore").pipe(
          select(selectPersonas),
          switchMap(() => this.actions$.pipe(ofType(actions.LoadPersona.type), take(1)))
        ),
      ).pipe(
        withLatestFrom(
          this.store.select("explore").pipe(select(selectPersonas)),
          this.store.select("explore", "selectedPersonaId"),
          this.store.select("explore").pipe(select(selectAllDataPersonaId)),
          this.urlService.pathParams$.pipe(map(params => +params.personaId)),
          this.store.select("explore", "defaultPersonaId")
        ),
      )
    }),
    filter(([_, personas, selectedPersonaId, allDataPersonaId, paramsPersonaId, defaultPersonaId]) => (!paramsPersonaId || !find(personas, {id: paramsPersonaId}) || !selectedPersonaId || !find(personas, {id: selectedPersonaId})) && !!personas.length),
    map(([_, personas, selectedPersonaId, allDataPersonaId, paramsPersonaId, defaultPersonaId]) => new actions.SetSelectedPersona((find(personas, {id: paramsPersonaId}) || find(personas, {id: defaultPersonaId}) || find(personas, {id: allDataPersonaId})))),
  );

  @Effect()
  disableIndexModeIfPersonaHasExclusions$ = observableCombineLatest(
    this.store.select("explore").pipe(select(activePersonaHasExcludedIds)),
    this.store.select("insights", "explore", "indexMode")
  ).pipe(
    filter(([activePersonaHasExcludedIds, isIndexMode]) => activePersonaHasExcludedIds && isIndexMode),
    map(() => new insightsActions.ToggleIndexMode("explore"))
  );

  constructor(private http: PpcHttpService, private actions$: Actions, private store: Store<AppState>, private urlService: UrlService) { }

  // If a product_slug is not provided the active context will inserted by HierarchyInterceptor
  getPersonasForProduct(product_slug?: string): Observable<Persona[]> {
    const headers = product_slug ? {'x-context': product_slug} : {}
    return this.http.get(personasUrl(), {headers}) as Observable<Persona[]>;
  }

  savePersona(persona: Persona): Observable<Persona> {
    return this.http[persona.id ? 'put' : 'post'](
      personasUrl(persona.id), {persona: persona}
    ) as Observable<Persona>;
  }

  fetchDefaultPersona(): Observable<number> {
    return this.http.get(userPreferencesUrl("explore-default-persona")) as Observable<number>;
  }

  saveDefaultPersona(defaultPersonaId: number): Observable<number> {
    return this.http.put(userPreferencesUrl("explore-default-persona"), {config: defaultPersonaId}) as Observable<number>;
  }

  destroyPersona(persona: Persona): Observable<Persona> {
    return this.http.delete(personasUrl(persona.id)) as Observable<Persona>
  }

  copyPersona(id: number): Observable<Persona> {
    return this.http.post(personasUrl(id) + '/copy', {}) as Observable<Persona>
  }

  downloadExport(tabIds, verticalKey, personaId) {
    const url = personasExportUrl(tabIds, verticalKey, personaId);
    return this.http.getDownload(url).pipe(
      tap(res => this.http.downloadFile(res, 'Explore_Export.csv')));
  }
}
