import * as actions from './client-admin.action';
import { HierarchyClient } from 'app/hierarchy/hierarchy.interface';

export interface State {
  clients: HierarchyClient[]
}

export const initialState: State = {
  clients: []
};

export function reducer(state: State = initialState, action): State {
  switch (action.type) {
    case actions.LoadAdminClients.type:
      return Object.assign({}, state, { clients: action.adminClients });
    default:
      return state;
  }
}
