import {combineLatest as observableCombineLatest, Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {getAllRouteParams} from "app/shared/utils/utils";
import {NAV_ADMIN_BRANDS, NAV_ADMIN, NAV_ADMIN_CLIENTS, NAV_ADMIN_GLOBAL} from "../shared/utils/constants";
import {getContext, getSlugs, validateSlugs} from "app/hierarchy/hierarchy.utils";
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers";
import {FetchHierarchy} from "app/hierarchy/hierarchy.actions";
import {isLoaded} from "app/shared/utils/fetch-state";

@Injectable()
export class RequireFullHierarchyGuard implements CanActivateChild {

  constructor(private router: Router, private store: Store<AppState>) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return observableCombineLatest(
      this.store.select('hierarchy', 'hierarchy'),
      this.store.select('fetchStates', FetchHierarchy.type).pipe(map(isLoaded))
    ).pipe(
      filter(([_, isHierarchyLoaded]) => isHierarchyLoaded),
      map(
        ([hierarchy]) => {
          const slugs = getSlugs(getAllRouteParams(state.root));
          const context = getContext(hierarchy, slugs);

          if (slugs.length == 4 && validateSlugs(hierarchy, slugs)) {
            return true;
          }

          if (slugs.length >= 2 && validateSlugs(hierarchy, slugs.slice(0, 2))) {
            this.router.navigate([...slugs.slice(0, 2), NAV_ADMIN_BRANDS, NAV_ADMIN]);
            return false;
          }

          this.router.navigate([NAV_ADMIN_GLOBAL, NAV_ADMIN, NAV_ADMIN_CLIENTS]);
          return false;
        }
      ), )
  }
}
