import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe, PercentPipe } from "@angular/common";

// NOTE: This eliminates the need for `dataFormatPipeArguments` for `currency` and `percent` dataTypes.
import { Currency } from "../../services/currency.service";
import { CurrencySymbolFormatterPipe } from "../../shared/formatters/currency-symbol-formatter.pipe";
import { ScopeKey } from "./api-constants";

export class MetricMetadata {
  key: string;
  displayName: string;
  chartFormatString: string;
  dataType: "number" | "percent" | "currency";
  dataFormatPipeArguments: string;
  currency: Currency;
  goal: MetricGoal;
  default: boolean;
}

export class MetricGoal {
  goalId: string;
  metricKey: string;
  scopeKey: ScopeKey;
  value: number;
  campaignId?: string;
  siteId?: string;
  placementId?: string;
}

export interface Metric {
  metricKey: string;
  value: number;
}

export class KPI implements Metric {
  metricKey: string;
  value: number;
  goalValue: number;
  percentToGoal: number;
  goalColor: string;

  constructor(
    metricKey: string,
    value: number,
    goalValue: number,
    percentToGoal: number,
    goalColor: string
  ) {
    this.metricKey = metricKey;
    this.goalValue = goalValue;
    this.percentToGoal = percentToGoal;
    this.goalColor = goalColor;
  }
}

export class Fundamental implements Metric {
  metricKey: string;
  value: number;

  constructor(
    metricKey: string,
    value: number
  ) {
    this.metricKey = metricKey;
    this.value = value;
  }
}

@Pipe({name: 'metricformatter'})
export class MetricFormatterPipe implements PipeTransform {
  constructor(
    private percentPipe: PercentPipe,
    private currencyPipe: CurrencySymbolFormatterPipe,
    private decimalPipe: DecimalPipe
  ) {}

  transform(value: number, meta: MetricMetadata): string {
    switch (meta.dataType) {
      case "number":
        if (value == null) {return "N/A"; }

        return this.decimalPipe.transform(value, meta.dataFormatPipeArguments);
      case "percent":
        if (value == null) {return "N/A"; }

        return this.percentPipe.transform(value.toString(), meta.dataFormatPipeArguments);
      case "currency":
        if (value == null) {return "N/A"; }

        const decimalFormatted = this.decimalPipe.transform(value, "1.2-2");
        return this.currencyPipe.transform(decimalFormatted, meta.currency);
    }
    return value.toString();
  }
}

export function getGoalForMetricKey(metricGoals: MetricGoal[], metricKey: string): MetricGoal {
  const filteredGoals = metricGoals.filter(g => g.metricKey == metricKey);
  return filteredGoals && filteredGoals.length > 0 ? filteredGoals[0] : <MetricGoal>{};
}
