import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'app/reducers/index';

import { V3, V1 } from 'app/shared/utils/constants';
import { CSVService } from "app/services/csv/csv.service";
import { CSVFile } from "app/services/csv/csv-file";
import { downloadToClient } from "app/shared/utils/utils";
import { LookalikeV2, convertConfidenceSizesToArray, getLookalikeCurrentPeople } from 'app/lookalikes-v2/lookalike-v2.model';
import * as actions from 'app/lookalikes-v3/lookalike-v3.actions';
import * as fetchState from 'app/shared/utils/fetch-state'
import { isEmpty, startCase } from 'lodash';

@Component({
  selector: 'app-lookalike-overlay',
  templateUrl: './lookalike-overlay.component.html',
  styleUrls: ['./look-a-like-overlay.component.sass']
})
export class LookalikeOverlayComponent implements OnInit, OnDestroy {
  @Input() lookalikeUnderEdit: LookalikeV2;
  @Output() onClose = new EventEmitter();

  originalLookalike: LookalikeV2;
  lookalike: LookalikeV2;
  modeledAddressable: number;
  lookalikeNamePrefix: string;
  ngUnsubscribe: Subject<boolean> = new Subject();

  confirmMessage = "This will create a clone of the lookalike with new values. The original lookalike cannot be changed."
  processingTooltip = "Cannot save while lookalike is processing";

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private csvService: CSVService,
  ) {
    actions$
      .pipe(
        ofType(actions.LoadLookalike.type, actions.LoadUpdatedLookalike.type, fetchState.ErrorFetch.type),
        takeUntil(this.ngUnsubscribe)
      ).subscribe((res: actions.LoadLookalike | actions.LoadUpdatedLookalike | fetchState.ErrorFetch) => this.handleLookalikeActionResponse(res));
  }

  ngOnInit() {
    this.lookalike = Object.assign({}, this.lookalikeUnderEdit);
    this.originalLookalike = Object.assign({}, this.lookalikeUnderEdit);
    if (this.hasConfidenceSizes) {
      this.modeledAddressable = getLookalikeCurrentPeople(this.lookalike);
    }
    if (!this.hasIdentifier) {
      this.lookalikeNamePrefix = this.lookalike.name;
    }
    if (this.isNewLookalike) {
      this.lookalike.name = this.lookalike.name + '_LAL'
    }
  }

  get hasIdentifier(): boolean {
    if (this.lookalike) {this.generateDownloadData(); }
    return !!this.lookalike.identifier;
  }

  get isNewLookalike(): boolean {
    return this.jobStatus === "new";
  }

  get isProcessing(): boolean {
    return this.jobStatus === "processing";
  }

  get isProcessed(): boolean {
    return this.jobStatus === "complete" || this.jobStatus === "preliminary" || this.jobStatus === "fail";
  }

  get lookalikeWillClone(): boolean {
    return this.originalLookalike.current_confidence !== this.lookalike.current_confidence || this.originalLookalike.include_seed_in_model !== this.lookalike.include_seed_in_model;
  }

  get jobStatus(): string {
    return this.lookalike.job_status;
  }

  onUpdate() {
    this.store.dispatch(new actions.UpdateLookalike(this.lookalike, {urlVersionNumber: V1}));
  }

  onSave() {
    this.resetLookalikeFieldsToDefault();
    this.store.dispatch(new actions.CreateLookalike(this.lookalike, {urlVersionNumber: V3}));
  }

  resetLookalikeFieldsToDefault() {
    this.lookalike.identifier = '';
    this.lookalike.id = null;
    this.lookalike.job_status = "pending";
    this.lookalike.confidence_sizes = {};
  }

  handleLookalikeActionResponse(res) {
    if (res.type === actions.LoadLookalike.type) {
      this.closeOverlay();
      this.sendSnackbarMessage(`${res.lookalike.name} successfully created`, 'check')
    } else if (res.type === actions.LoadUpdatedLookalike.type) {
      this.closeOverlay();
      this.sendSnackbarMessage(`${res.lookalike.name} successfully updated`, 'check')
    } else {
      const error = res.error.error.error;
      const message = res.error.error.full_error_message;
      this.sendSnackbarMessage(`${error}: ${message}`, 'danger')
    }
  }

  get hasConfidenceSizes(): boolean {
    return !isEmpty(this.lookalike.confidence_sizes);
  }

  get matchedAddressable(): number {
    return this.lookalike.seed_counts.people["global"];
  }

  updateCountAndConfidence(graphData) {
    this.lookalike.current_confidence = graphData.confidence;
    this.lookalike.current_confidence_pid_count = graphData.people;
    this.modeledAddressable = graphData.people;
  }

  sendSnackbarMessage(message: string, panelClass: string) {
    this.snackbar.open(message, null, { duration: 5000, panelClass: [panelClass]});
  }

  closeOverlay() {
    this.onClose.emit();
  }

  onDownloadDataClicked(event: MouseEvent) {
    const csvData = this.generateDownloadData()
    downloadToClient(csvData.data, csvData.fileName);
  }

  private generateDownloadData(): CSVFile {
    const lookalikeData: Object[] = convertConfidenceSizesToArray(this.lookalike);
    const lookalikeCountFields: string[] = ["reach", "confidence"];
    const lookalikeCountDisplayFields: string[] = lookalikeCountFields.map(field => startCase(field));

    return this.csvService.generateCsvFile(
      lookalikeData,
      lookalikeCountFields,
      lookalikeCountDisplayFields,
      this.lookalike.name,
      {generatingPage: "Lookalike"}
    )
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
