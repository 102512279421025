
import { BehaviorSubject, combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';

import { debounceTime, map, takeUntil } from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {AttributeAnalysis, CreativeAttribute} from "../creative.reducers";

export interface ScoredAttribute extends CreativeAttribute {
  scores: {color: string; val: number}[]
}

@Component({
  selector: 'app-creative-attribute-list',
  templateUrl: './creative-attribute-list.component.html',
  styleUrls: ['./creative-attribute-list.component.sass']
})
export class CreativeAttributeListComponent implements OnDestroy {

  searchQuery$ = new BehaviorSubject<string>("");

  filteredList$: Observable<ScoredAttribute[]>;

  ngUnsubscribe = new Subject();

  @Output()
  selectAttribute = new EventEmitter<AttributeAnalysis>(true);

  @Output()
  hoverAttribute = new EventEmitter<string>();

  @Input()
  scoredAttributes$: Observable<ScoredAttribute[]>;

  @Input()
  clearSearch$: Observable<null>;

  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef;

  constructor() { }

  ngOnInit() {
    this.filteredList$ = observableCombineLatest(
      this.scoredAttributes$,
      this.searchQuery$.pipe(debounceTime(300)),
    ).pipe(
      map(
        ([analyses, query]: [ScoredAttribute[], string]) =>
          analyses.filter(analysis =>
            analysis.description.toLowerCase().includes(query.toLowerCase()))));

    this.clearSearch$ && this.clearSearch$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.clearInput());
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  clearInput() {
    this.searchInput.nativeElement.value = "";
    this.searchQuery$.next("");
  }
}
