import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { truncate } from 'lodash';
import { Store } from '@ngrx/store';

import { Node } from 'app/segment-picker/root-nodes/root-node.interface';
import {sortBy} from "app/shared/utils/utils";
import { GrowCountService } from 'app/insights/grow-v3/grow-count.service';
import { AppState } from 'app/reducers';
import { toMillions } from 'app/insights/insights.models';
import * as insightsActions from 'app/insights/insights.actions';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { InsightsCountService } from 'app/insights/insights-count.service';

@Component({
  selector: 'ppc-gwi-survey-question',
  templateUrl: './gwi-survey-question.component.html',
  styleUrls: ['./gwi-survey-question.component.sass']
})
export class GwiSurveyQuestionComponent {
  @Output() setActiveGWIQuestions = new EventEmitter<null>();
  @Input() categoryName: string;
  @Input() question: Node;
  @Input() index: number;
  @Input() showCounts: boolean;

  expanded = false;
  loading = false;

  constructor(private counts: InsightsCountService,
    private store: Store<AppState>,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {
  }

  get isResponseSelected(): boolean {
    return this.question.children && this.isChildSelected(this.question);
  }

  isGWISegmentSelected(segment: Node): boolean {
    return this.counts.hasFilter({ shortId: segment.identifier })
  }

  isChildSelected(segment: Node) {
    if (segment.children) {
      return segment.children.some(s => this.isChildSelected(s))
    } else {
      return this.counts.hasFilter({ shortId: segment.identifier })
    }
  }

  get totalResponses(): number {
    return this.counts.getSegmentCount(this.question.identifier);
  }

  getCount(segment): number {
    return this.counts.getSegmentCount(segment.identifier);
  }

  getCountLabel(segment): string {
    return toMillions(this.getCount(segment));
  }

  getPercentage(segment): number {
    const percentage = this.counts.getSegmentCount(segment.identifier) / this.totalResponses * 100;
    return isNaN(percentage) ? 0 : percentage;
  }

  expandQuestion() {
    this.expanded = true;
    if (!this.question.children) {
      this.loading = true;
      this.question.children$.subscribe(
        segments => {
          this.question.children = sortBy(segments, "name");
          this.loading = false;
          this.setActiveGWIQuestions.emit();
        },
        error => {
          this.loading = false;
        }
      )
    }
  }

  toggleSegment(segment: Node) {
    this.store.dispatch(new insightsActions.ToggleFilter({
      pathParts: ['GWI', this.categoryName, this.question.name, segment.name],
      truncatedDisplay: this.getDisplayKey(segment),
      type: "GWI",
      shortId: segment.identifier,
      demographicId: this.question.identifier,
      tab: "Survey",
    }, this.insightsContext))
  }

  getDisplayKey(segment): string {
    const category = truncate(this.categoryName, {length: 15, separator: ' '}),
      question = truncate(this.question.name, {length: 25, separator: ' '}),
      response = truncate(segment.name, {length: 35, separator: ' '});
    return `GWI > ${category} > ${question} > ${response}`;
  }

  getLetter(idx: number) {
    return String.fromCharCode(97 + (idx % 26));
  }

}
