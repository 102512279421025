<tr class="border_bottom hoverable row-hover" (click)="navigateTo()">
  <td
    [ppcTooltip]="chartTooltipText"
    [tooltipDelayTime]="600">
    <img src="assets/icons/{{fileName}}" class="gray-icon" />
  </td>
  <td class="ultra-wide">{{chart.name}}</td>
  <td>
    <span *ngIf="isMekko">{{(chart?.total_population_count || 0) | number}}</span>
    <span *ngIf="!isMekko">-</span>
  </td>
  <td>
    <span *ngIf="isMekko">{{(chart?.total_matched_count || 0) | number}}</span>
    <span *ngIf="!isMekko">-</span>
  </td>
  <td>
    <span *ngIf="isMekko">{{(chart?.total_modeled_count || 0) | number}}</span>
    <span *ngIf="!isMekko">-</span>
  </td>
  <td>{{chart.created_at | date: 'shortDate'}}</td>
  <td>{{chart.updated_at | date: 'shortDate'}}</td>
  <td>
    <img src="assets/icons/baseline-arrow_forward-24px.svg" class="blue-icon" />
  </td>
</tr>
