import { Pipe, PipeTransform } from "@angular/core";
import { Currency, GBP } from "../../services/currency.service";

@Pipe({
  name: "currencySymbol"
})

export class CurrencySymbolFormatterPipe implements PipeTransform {

  public transform(value: string, currency: Currency = GBP): string {
    if (currency == void(0)) {
      throw new Error('currency cannot be null');
    }

    let output = value;
    if (currency.symbol_first) {
      output = currency.symbol + value;
    } else {
      output = value + " " + currency.symbol;
    }

    return output;
  }

}
