import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { find } from 'lodash';
import { RegionDemographic, selectCensusDemographics } from 'app/insights/insights.reducer';
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { AppState } from 'app/reducers';
import * as insightsActions from 'app/insights/insights.actions';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { isDefined } from 'app/shared/utils/utils';
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';
import { ChartTypes } from 'app/insights/insights.models';
import { ToolTips } from 'app/app.common-messages';

@Component({
  selector: 'ppc-region-demographic-form',
  templateUrl: './region-demographic-form.component.html',
  styleUrls: ['./region-demographic-form.component.sass']
})
export class RegionDemographicFormComponent extends InsightsResourceTracker implements OnInit, OnDestroy {
  destroyed$ = new Subject();
  demographic: RegionDemographic;
  regionDemographics: RegionDemographic[];
  mekkoId: number;
  journeyId: number;
  percentageTooltip: string;

  chartTypes = ChartTypes;
  toolTips = ToolTips;

  constructor(public store: Store<AppState>, @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super(store, insightsContext);
  }

  ngOnInit() {
    this.setPercentageTooltip();

    this.store.select("insights", this.insightsContext, "regionDemographicUnderEdit").pipe(
      filter(isDefined),
      takeUntil(this.destroyed$)
    ).subscribe(demo => this.demographic = demo);

    this.store.pipe(
      select(selectCensusDemographics(this.insightsContext)),
      takeUntil(this.destroyed$)
    ).subscribe(regionDemographics => this.regionDemographics = regionDemographics)

    this.store.select("mekkos", "selectedMekkoId").pipe(
      takeUntil(this.destroyed$)
    ).subscribe(mekkoId => this.mekkoId = mekkoId)
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  save() {
    const changedWidget = find(this.regionDemographics, {id: this.demographic.id})
    changedWidget.widget_type = this.demographic.widget_type;
    changedWidget.id_count = this.demographic.id_count;

    this.store.dispatch(new insightsActions.SaveDemographicsConfig(this.regionDemographics.map(demo => {
      return {
        id: demo.id,
        visible: demo.visible,
        widget_type: demo.widget_type,
        id_count: demo.id_count
      }
    }), userPreferenceKeys.regionLevelDemographics("census"), this.resourceId, this.resourceType, this.insightsContext));
    this.cancel();
  }

  cancel() {
    this.store.dispatch(new insightsActions.EditRegionDemographic(this.insightsContext, null));
  }

  setPercentageTooltip() {
    if (this.resourceType == 'Persona') {
      this.percentageTooltip = this.toolTips.PersonaPercentage;
    } else {
      this.percentageTooltip = this.toolTips.NonPersonaPercentage;
    }
  }

}
