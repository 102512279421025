<div class="audience-builder">
  <div class="audience-builder-header">
    <div class="back-to-audiences-container">
      <div class="back-to-audiences" (click)="routeToReturnUrl()">
        <i class="fa fa-arrow-left"></i> {{ queryParams.referer || "Audiences"}}
      </div>
    </div>
    <div class="warning-container">
      <ppc-error
        [isZeroCount]="true"
        [audienceCopy]="true"
        [topLevelMessage]="warningText"
        [visible]="zeroCountWarningActive"
        (closeAlert)="closeWarning()">
      </ppc-error>
      <ppc-error
        [topLevelMessage]="personaService.deletedSegmentsError"
        [visible]="personaService.errorMessageActive"
        (closeAlert)="personaService.errorMessageActive = false">
      </ppc-error>
    </div>
    <div class="save-audience-container" *ngIf="builderService.audience">
      <div class="page-title">{{ pageTitle }}</div>
      <span *ngIf="!params?.id && !params?.personaId" [class.hide-subtitle]="motivationService.isMotivation$ | async" class="edit-context">(Build New)</span>
      <span *ngIf="params?.id && !builderService.audience.id || queryParams?.audienceCloned" class="edit-context">(Clone)</span>
      <span *ngIf="params?.id && builderService.audience.id && !queryParams?.audienceCloned || params?.personaId" [class.hide-subtitle]="motivationService.isMotivation$ | async" class="edit-context">(Edit)</span>
      <div class="buttons-container" *ngIf="builderService.audience">
        <button *ngIf="!(personaService.showMoveExploreFilters$ | async)"
                class="pds-btn create-audience-btn"
                matRipple
                ppcConfirmation
                [kontinue]="save.bind(this)"
                modalTitle="Are you sure?"
                modalContent="The audience will be saved and will take up to 48 hours to process."
                buttonContinueLabel="Save"
                [class.disabled]="saveAudienceDisabled$ | async"
                [ppcTooltip]="saveAudienceTooltip$ | async"
                [tooltipDisabled]="saveAudienceTooltipDisabled$ | async">
          Save Audience
        </button>
        <button *ngIf="(personaService.showMoveExploreFilters$ | async)"
                class="pds-btn"
                matRipple
                [class.create-audience-btn]="personaService.moveFiltersDisabled$ | async"
                (click)="personaService.filtersMoved$.next(true)"
                [ppcTooltip]="personaService.moveFiltersTooltip$ | async"
                [class.disabled]="personaService.moveFiltersDisabled$ | async">
          Move Explore Filters
        </button>
        <button *ngIf="personaService.isPersonaAudience$ | async"
                class="pds-btn create-audience-btn"
                type="submit"
                matRipple
                (click)="save()"
                [ppcTooltip]="saveAudienceTooltip$ | async"
                [tooltipDisabled]="saveAudienceTooltipDisabled$ | async"
                [class.disabled]="saveAudienceDisabled$ | async">
          {{ (personaService.isPersonaContext$ | async) ? (params?.personaId ? 'Update' : 'Create') +  ' Persona' : 'Request' }}
        </button>
      </div>
      <div class="spacer"></div>
    </div>
  </div>
  <div class="audience-builder-container">
    <ppc-audience-builder-form
      (zeroCountSegmentDetection)="setWarningToActive()"
    ></ppc-audience-builder-form>
    <ppc-segment-picker-v2></ppc-segment-picker-v2>
  </div>
</div>
