<div class="asset-drop">
  <mat-spinner *ngIf="isLoading$ | async"></mat-spinner>
  <form #dropForm class="drop-form">
    <label class="display-drop-zone" [class.file-over]="overDropZone">
      <div class="drop-label">
        To upload, drop assets here
      </div>
      <input
        name="images[]"
        class="drop-zone"
        type="file"
        multiple
        (dragover)="fileOver($event, true)"
        (dragleave)="fileOver($event, false)"
        (change)="handleDrop()" />
      <input *ngIf="competitorId" type="hidden" name="competitor_id" [value]="competitorId" />
    </label>
  </form>
</div>
<div class="carousel-container">
  <ngu-carousel
    #carousel
    class="asset-list-carousel"
    [inputs]="carouselTile"
    (carouselLoad)="carouselLoad()"
    [dataSource]="visibleCreatives"
  >
    <div NguCarouselPrev class="carousel-button button-left">
      <div class="carousel-button-inner">
        <i class="fa fa-chevron-left"></i>
      </div>
    </div>
    <ngu-tile class="ngx-tile" NguCarouselItem *nguCarouselDef="let creative; let j = index">
      <img class="carousel-img"
           [src]="imageUrl(creative.id, productId)"
           (click)="creativeClick.emit(creative)" />
      <div *ngIf="creative.is_being_processed" class="score-loading-indicator">
        <mat-spinner [diameter]="20"></mat-spinner>
        Processing
      </div>
      <i *ngIf="creative.processing_errors?.length"
         class="fa fa-warning"
         ppcTooltip="There were errors while processing this creative's genome"
      ></i>
      <app-confirmation
        isIcon="true"
        buttonLabel=""
        buttonClass="fa fa-times"
        [modalTitle]="'Are you sure you want to delete ' + creative.filename + '?  '"
        modalContent="This action cannot be undone."
        [kontinue]="deleteCreative.bind(this, creative.id)"
      ></app-confirmation>
    </ngu-tile>
    <div NguCarouselNext class="carousel-button button-right">
      <div class="carousel-button-inner">
        <i class="fa fa-chevron-right"></i>
      </div>
    </div>
  </ngu-carousel>
</div>
