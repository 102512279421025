export const tooltipMessage = {
  processing: "Motivations currently running",
  disabledTardiisIncomplete: 'Cannot send to Tardiis unless motivation is complete',
  disabledTardiisAlreadySent: 'This motivation has been sent to Tardiis',
  disabledTardiisNotUserPermissioned: "Action Not Permitted. Contact Product Support",
  disabledTardiisNotPermissioned: 'This motivation is not allowed for TV Planning and cannot be sent to Tardiis',
  disabledDownloadExcel: "Not Permitted for Download at this time",
  disabledDeleteProcessing: "Cannot delete a processing motivation",
  disabledDeleteNotUserPermissioned: "Action Not Permitted. Contact Product Support.",
  disabledDeleteTardiis: "Cannot delete a motivation that has been sent to Tardiis",

  estimatedPeopleCount: "Estimated",
  fail: "Motivation process failed",
  failedEstimatedPeopleCount: "Cannot Estimate People Count",
  sendToPartnerIncomplete: 'Cannot send to partner unless motivation is complete',
  sendToPartnerNotUserPermissioned: "Action Not Permitted. Contact Product Support.",
  sendToPartnerNotDataPermissioned: "This motivation is not permitted for activation",
  sendToPartnerMissingCounts: 'Must have People Count greater than 0 to Send to Partner',
  // index report
  indexReportCreationDisabled: 'Cannot run index report unless motivation is complete.',
  indexReportNotUserPermissioned: 'Action Not Permitted. Contact Product Support.',
  // Lookalike Messages
  lookalikeCreationDisabled: 'Cannot create lookalike unless motivation is complete',
  lookalikeNotUserPermissioned: 'Action Not Permitted. Contact Product Support.',
  lookalikeNotDataPermissioned: 'This motivation is not allowed to be used to create a lookalike',
  lookalikeMissingCounts: 'Must have People Count greater than 0 to create a lookalike audience',
};
