<div class="edit-pane">
    <div class="edit-pane-header">
      <div class="edit-pane-header-text header-container">
        <div class="edit-pane-title">
          Edit Mapping
        </div>
      </div>
      <div class="edit-pane-header-close" (click)="builder.editSubMarket({})">
        <img src="assets/icons/close.svg" />
      </div>
    </div>

    <div class="edit-pane-section">
      <div class="edit-pane-section-header">Brand</div>
      <ppc-searchable-dropdown [(ngModel)]="builder.brandUnderEdit" [noLabel]="true" [items]="builder.brands" itemLabelProp="name" [allowClear]="false"></ppc-searchable-dropdown>
    </div>

    <div class="edit-pane-section">
      <div class="edit-pane-section-header">Stage</div>
      <ppc-searchable-dropdown [(ngModel)]="builder.stageUnderEdit" [noLabel]="true" [items]="builder.stages" itemLabelProp="name" [allowClear]="false"></ppc-searchable-dropdown>
    </div>

    <div class="edit-pane-section audiences-container"
      *ngIf="builder.subMarketUnderEdit && !(marketLevelOnly$ | async)">
      <div class="edit-pane-section-header">
        <h4 class="edit-pane-section-header-text">Attach Audiences</h4>
      </div>

      <div class="matched-audience-container">
        <div class="layout horizontal center-center">
          <label>Matched Audience</label>
          <button class="btn ml-auto" (click)="openSegmentPicker('matched_id')">SELECT</button>
        </div>
        <div class="audience-input-container">
          <ppc-input [value]="builder.segments[builder.subMarketUnderEdit.matched_id]?.name" (focusin)="openSegmentPicker('matched_id')" (input)="$event.preventDefault()">
            <label>{{getSegmentPath(builder.segments[builder.subMarketUnderEdit.matched_id])}}</label>
            <div suffix *ngIf="builder.subMarketUnderEdit.matched_id" (click)="builder.subMarketUnderEdit.matched_id = null"><i class="fa fa-close"></i></div>
          </ppc-input>
          <ppc-zero-count-warning-icon
            *ngIf="hasZeroCount(builder.subMarketUnderEdit.matched_id, 'matched')"
            ppcTooltip="This audience has a zero count. Select another audience to replace it.">
          </ppc-zero-count-warning-icon>
        </div>
      </div>

      <div class="modeled-audience-container" *ngIf="!isTier3">
        <div class="layout horizontal center-center">
          <label>Modeled Audience</label>
          <button class="btn ml-auto" (click)="openSegmentPicker('modeled_id')">SELECT</button>
        </div>
        <div class="audience-input-container">
          <ppc-input [value]="builder.segments[builder.subMarketUnderEdit.modeled_id]?.name" (focusin)="openSegmentPicker('modeled_id')" (input)="$event.preventDefault()">
            <label>{{getSegmentPath(builder.segments[builder.subMarketUnderEdit.modeled_id])}}</label>
            <div suffix *ngIf="builder.subMarketUnderEdit.modeled_id" (click)="builder.subMarketUnderEdit.modeled_id = null"><i class="fa fa-close"></i></div>
          </ppc-input>
          <ppc-zero-count-warning-icon
            *ngIf="hasZeroCount(builder.subMarketUnderEdit.modeled_id, 'modeled')"
            ppcTooltip="This audience has a zero count. Select another audience to replace it.">
          </ppc-zero-count-warning-icon>
        </div>
      </div>
    </div>

    <div class="edit-pane-section" *ngIf="builder.subMarketUnderEdit">
      <div class="edit-pane-section-header">
        <h4 class="edit-pane-section-header-text">Enter Data (for Total Population tab)</h4>
      </div>
      <ppc-input [(ngModel)]="builder.subMarketUnderEdit.population" type="number" [min]="0">
        <label>Audience Population</label>
        <div help-text>Count to display for Total Population.</div>
      </ppc-input>

      <div class="dropdown-container">
        <ppc-searchable-dropdown
          #clusterNodeDropdown
          *ngIf="(clusterNodes$ | async)?.length > 0"
          showSearch="false"
          label="Discussion"
          itemLabelProp="name"
          mapFromProperty="id"
          [showInputUnderline]="true"
          [(ngModel)]="builder.subMarketUnderEdit.discussion_cluster_node_id"
          [items]="clusterNodes$ | async"
          >
        </ppc-searchable-dropdown>
        <span class="dropdown-help-text" *ngIf="(clusterNodes$ | async)?.length">Discussion to map to Discussions tab for Audience</span>
        <span class="dropdown-help-text no-discussions" *ngIf="!(clusterNodes$ | async)?.length">
          This client has no discussion uploaded for it. To upload a Discussion via the bulk uploader, <a (click)="navigateToDiscussionsBulkUpload()">click here.</a>
        </span>
      </div>
    </div>
  </div>
