import { createSelector } from '@ngrx/store';
import { keyBy, omit, uniqBy } from 'lodash';
import * as actions from 'app/insights/insights-components/market-level-discussions/discussion.actions';
import { DiscussionClusterNode } from 'app/insights/insights-components/market-level-discussions/discussion-cluster-nodes.interface';
import { AppState } from 'app/reducers';
import { selectSelectedSubMarkets, selectActiveWeboramaId } from 'app/mekko/mekko.reducer';
import { selectSelectedJourneySubMarkets, selectSelectedStages } from 'app/journey/journey.reducer';
import { JourneySubMarket } from 'app/journey/journey.models';
import { SubMarket } from 'app/mekko/mekko.reducer';

export interface State {
  clusterNodes: {[clusterNodeId: number]: DiscussionClusterNode};
  discussionClusterNodeUnderEdit: DiscussionClusterNode;
}

const defaultState: State = {
  clusterNodes: {},
  discussionClusterNodeUnderEdit: null
};

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.LoadClusterNode.type:
      return {
        ...state,
        clusterNodes: {
          ...state.clusterNodes,
          [action.clusterNode.id]: action.clusterNode
        }
      }
    case actions.RemoveDiscussionClusterNode.type:
      return {
        ...state,
        clusterNodes: omit(state.clusterNodes, [action.clusterNode.id])
      }
    case actions.LoadClusterNodes.type:
      return { ...state, clusterNodes: keyBy(action.clusterNodes, "id") }
    case actions.EditDiscussionClusterNode.type:
      return { ...state, discussionClusterNodeUnderEdit: action.discussionClusterNode }
    default:
      return state;
  }
}

export const selectActiveDiscussionClusterNodeId = insightsContext => {
  return createSelector(
    (state: AppState) => selectSelectedSubMarkets(state.mekkos),
    (state: AppState) => selectSelectedJourneySubMarkets(state.journey),
    (selectedSubMarkets,  selectedJourneySubMarkets) => {
      const subMarkets = insightsContext == "grow" ? selectedSubMarkets : selectedJourneySubMarkets;
      if (uniqBy(subMarkets as [], "discussion_cluster_node_id").length == 1) {
        return subMarkets[0].discussion_cluster_node_id;
      }
    }
  )
}
