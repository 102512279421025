import { Role } from 'app/admin/role-admin/role.interface';
export interface User {
  id: number;
  lion_login: string;
  okta_login: string;
  email: string;
  first_name: string;
  last_name: string;
  agency_id: number;
  roles: Role[];
  role_ids?: number[];
  is_admin?: boolean;
  deleted_at?: string;
  updated_at?: string;
  created_at?: string;
  last_login_date?: Date;
}

export function newUser(): User {
  return {
    email: "",
    roles: [],
    agency_id: null,
    first_name: "",
    last_name: "",
    lion_login: "",
    okta_login: "",
    id: null
  }
}

export function fullName(user: User): string {
  return `${user.first_name} ${user.last_name}`;
}


export function uniqUserHash(user: User) {
  return `${user.id}-${user.first_name}-${user.last_name}`.replace(/\s+/g, '');
}
