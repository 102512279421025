import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { keys, difference, values, filter, map as _map, cloneDeep, reverse } from 'lodash';

import { AppState } from 'app/reducers';
import { OutcomeTimeframe, newOutcomeTimeframe, selectCmsOutcomeTimeframes } from 'app/measure-v3/measure-v3.reducer';
import * as actions from 'app/measure-v3/measure-v3.actions';
import { Mekko, selectActiveMekko, selectActiveMekkoSubMarkets, SubMarket } from 'app/mekko/mekko.reducer';

@UntilDestroy()
@Component({
  selector: 'ppc-measure-v3-cms',
  templateUrl: './measure-v3-cms.component.html',
  styleUrls: ['./measure-v3-cms.component.sass']
})
export class MeasureV3CmsComponent implements OnInit, OnDestroy {
  @Output() closeCms = new EventEmitter();
  yearOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(delta => {
    return {
      label: moment().year() - delta,
      value: moment().year() - delta
    }
  })
  subMarkets: SubMarket[];
  activeYear: number = moment().year();
  newTimeframe: OutcomeTimeframe;
  activeYear$ = new Subject<number>();
  outcomeTimeframes$ = this.store.select("measureV3").pipe(
    select(selectCmsOutcomeTimeframes),
    map(values),
    map(reverse),
  )

  availableMonths$ = this.outcomeTimeframes$.map((yearGroup: OutcomeTimeframe[]) => {
    return difference([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], _map(yearGroup, "month"))
      .filter(month => isPast(month, this.activeYear))
      .map(month => {
        return {
          label: moment().month(month).format("MMMM"),
          value: month
        }
      })
  })

  firstAvailableMonth = 0;
  activeMekko: Mekko;


  constructor(private store: Store<AppState>) {
    store.select('mekkos').pipe(select(selectActiveMekkoSubMarkets), untilDestroyed(this))
      .subscribe((subMarkets) => this.subMarkets = subMarkets)
  }

  ngOnInit() {
    this.availableMonths$.pipe(untilDestroyed(this)).subscribe(availableMonths => {
      this.firstAvailableMonth = availableMonths[0] && availableMonths[0].value;
      if (this.newTimeframe) {
        this.newTimeframe.month = this.firstAvailableMonth;
        this.newTimeframe.year = this.activeYear;
      }
    })
    this.store.select("mekkos").pipe(
      select(selectActiveMekko),
      untilDestroyed(this))
      .subscribe(activeMekko => this.activeMekko = activeMekko)
  }

  ngOnDestroy() {
  }

  cancel() {
    this.closeCms.emit();
  }

  activeYearChanged(year) {
    this.store.dispatch(new actions.SetActiveCmsYear(year));
  }

  addNewTimeframe() {
    this.newTimeframe = newOutcomeTimeframe(this.activeYear, this.activeMekko, this.subMarkets);
    this.newTimeframe.month = this.firstAvailableMonth;
  }

  saveNewTimeframe() {
    this.store.dispatch(new actions.SaveOutcomeTimeframe({...this.newTimeframe, mekko_id: this.activeMekko.id}));
    this.newTimeframe = null;
  }

  trackById(item, index) {
    return item.id
  }

}

function isPast(month, year) {
  const currentMonth = moment().month();
  const currentYear = moment().year();
  return year < currentYear || month < currentMonth;
}
