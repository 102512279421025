<div class="form-container layout vertical">
  <h3>Create Index Report</h3>
  <p>Create an index report from custom personas built in the Explore dashboard. Attribute filters in Explore will not be considered part of the custom persona unless they are added to the persona through the builder.</p>

  <ng-container>
    <div class="resource-container">
      <div>
        <ppc-input
          [(ngModel)]="uiName"
          [validators]="nameValidations"
          >
          <label>Name <span class="sub-text">(Optional)</span></label>
        </ppc-input>
      </div>

      <ng-container *ngIf="originator === 'persona'" [ngTemplateOutlet]="personaSelector"></ng-container>
      <ng-container *ngIf="originator === 'audience'" [ngTemplateOutlet]="audienceSelector"></ng-container>
    </div>
    <ppc-index-base
      [class.realign]="true"
      [useStore]="false"
      [insightsContext]="'indexReport'"
      [(indexBase)]="indexBase"
    ></ppc-index-base>

    <div class="skew-options"  *ngIf="canAccessIndexVariations$ | async">
      <div class="skew-options-label">
        Advanced Options
      </div>
      <ppc-dropdown>
        <div currentlySelected class="currently-selected">
          {{currentSkewSelection}}
        </div>
        <div dropdown>
          <div class="skew-options-container">
            <div class="split-container">
              <ppc-checkbox class="option" [(ngModel)]="allow_dataset_skew" (change)="checkBoxChangeEvent()">
                Dataset Skew
              </ppc-checkbox>
            </div>
            <div class="split-container">
              <ppc-checkbox class="option" [(ngModel)]="allow_unknown_rate_skew" (change)="checkBoxChangeEvent()">
                Unknown Rate Skew
              </ppc-checkbox>
            </div>
          </div>
        </div>
      </ppc-dropdown>
      <ng-template #skewDef>
        <div class="tooltip">
          <p><strong>{{unknownRateSkew.title}}</strong>{{unknownRateSkew.definition}}</p>
          <p><strong>{{datasetSkew.title}}</strong>{{datasetSkew.definition}}</p>
        </div>
      </ng-template>
      <div class="learn-more-icon" ppcTooltip [tooltipTemplate]="skewDef">
        <i class="fa fa-question-circle-o"></i>
      </div>
    </div>

    <div class="edit-pane-section layout vertical report-layout-container">
      <div class="edit-pane-section-header">
        <div class="report-layout-header-label">Report Layout</div>
        <ng-template #layoutInfo>
          <div class="tooltip">
            <p>Simplified: An index report that includes only the most necessary variables and formulas.</p>
            <p>Advanced: An index report that includes all variables and formulas.</p>
          </div>
        </ng-template>
          <div class="learn-more-icon" ppcTooltip [tooltipTemplate]="layoutInfo">
            <i class="fa fa-question-circle-o"></i>
          </div>
        </div>
      <div ppcRadioGroup [(ngModel)]="layoutType" class="report-layout-radio">
        <ppc-radio-button value="simplified">Simplified</ppc-radio-button>
        <ppc-radio-button value="advanced">Advanced</ppc-radio-button>
      </div>
    </div>
  </ng-container>
  <div class="bottom-container layout vertical flex-1 end-justified">

    <div class="note">
      <b>Note</b>: Once the index report has been initiated, it will complete within the next 3 hours. You will be notified of its completion in the notification center and be able to download it there.
    </div>

    <div class="layout horizontal">
      <span
        [ppcTooltip]="tooltipMessage"
        [tooltipDisabled]="!isNotValid()">
        <button
          type="submit"
          class="pds-btn pds-btn--primary"
          [disabled]="isNotValid()"
          ppcThrottleClick
          [throttleTime]="1000"
          (throttledClick)="create()">
          Create
        </button>
      </span>

      <button
        class="pds-btn pds-btn--tertiary"
        ppcThrottleClick
        (throttledClick)="cancel()"
        >
        Cancel
      </button>
    </div>
  </div>
</div>

<ng-template #personaSelector>
  <span class="resource-header">Select Persona(s)</span>

  <ng-container *ngFor="let resource$ of selections; let i = index">
    <div class="resource-selector">
      <p>{{ resourceHeaderArr[i].main }} <span class="sub-text">({{ resourceHeaderArr[i].sub }})</span></p>

      <div class="dropdown-container">
        <ppc-dropdown #resourceDropdown class="resource-dropdown">
          <div
            currentlySelected
            class="currently-selected"
          >
            {{ (resource$ | async)?.name }}
          </div>
          <div dropdown>
            <div class="options">
              <button mat-button *ngFor="let item of filteredList" (click)="updateSelection(item, i); resourceDropdown.open = false">
                {{ item.name }}
              </button>
            </div>
          </div>
        </ppc-dropdown>
        <img *ngIf="i !== 0 && (resource$ | async)?.id" class="remove-button" src="assets/icons/close.svg" (click)="clearSelection(i)" />
      </div>
    </div>
  </ng-container>
  <ppc-add-button [allowAdd]="allowAdd" [originator]="originator" (addSelectionEvent)="addSelection()"></ppc-add-button>
</ng-template>


<ng-template #audienceSelector>
  <span class="resource-header">Select Audience(s)</span>
  <ng-container *ngFor="let resource$ of selections; let i = index">
    <div class="resource-selector">
      <p>{{ resourceHeaderArr[i].main }} <span class="sub-text">({{ resourceHeaderArr[i].sub }})</span></p>

      <div class="audience-selector">
        <ppc-input [value]="(resource$ | async)?.name" (focusin)="openSegmentPicker(i)" (input)="$event.preventDefault()">
          <label>{{ getSegmentPath(segments[(resource$ | async)?.identifier]) }}</label>
          <div suffix *ngIf="i !== 0 && (resource$ | async)?.id" (click)="clearSelection(i)"><i class="fa fa-close"></i></div>
        </ppc-input>
        <button class="btn btn-select" (click)="openSegmentPicker(i)">SELECT</button>
      </div>
    </div>
  </ng-container>
  <ppc-add-button [allowAdd]="allowAdd" [originator]="originator" (addSelectionEvent)="addSelection()"></ppc-add-button>
</ng-template>
