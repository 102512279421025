import {
  Component,
  ViewChild,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit
} from "@angular/core";
import { keys } from 'lodash';
import * as d3 from "d3";
import { LookalikeV2 } from "app/lookalikes-v2/lookalike-v2.model";

@Component({
  selector: 'app-lookalike-graph-v2',
  templateUrl: './lookalike-graph-v2.component.html',
  styleUrls: ['./lookalike-graph-v2.component.sass'],
  encapsulation: ViewEncapsulation.None
})

export class LookALikeGraphV2Component implements OnChanges {
  @ViewChild('svg', { static: true }) svg;
  @ViewChild('slider', { static: true }) slider;
  @ViewChild('reachLine', { static: true }) reachLine;

  // In the future, this could theoretically be device counts or cookies
  current_field: string = 'people';
  y;
  x;
  data: {confidence: number, [field: string]: number}[];
  confidences: number[];
  people_counts: number[];
  focus;
  level: number = 0;
  scaleModifier: number = 0;
  dataLength: number = 100;

  @Input() lookalike: LookalikeV2;
  @Output() updateCountAndConfidence = new EventEmitter<any>();

  ngOnChanges(): void {
    this.data = this.convertGraphData(this.current_field);
    this.dataLength = this.data.length - 1;
    this.clearGraph();
    this.drawGraph();
  }

  drawGraph(): void {
    if (this.dataLength <= 90) { this.scaleModifier = 100 - this.dataLength }

    this.svg = d3.select(`.graph-container svg`);

    const margin = {top: 0, right: 20, bottom: 80, left: 50},
      width = +this.svg.attr("width") - margin.left - margin.right,
      height = +this.svg.attr("height") - margin.top - margin.bottom,
      g = this.svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    this.x = d3.scaleLinear()
      .rangeRound([0, width]);

    this.y = d3.scaleLinear()
      .rangeRound([height, 0]);
    const area = d3.area()
      .x((d) => { return this.x(d.confidence) })
      .y1((d) => { return this.y(d[this.current_field]) })
      .y0(this.y(0));

    this.x.domain(d3.extent(this.data, (d) => { return d.confidence }));
    this.y.domain(d3.extent(this.data, (d) => { return d[this.current_field] }));

    // x axis text
    g.append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(this.x).ticks(5).tickSize(-height).tickFormat(d => `${d} %`))
      .append("text")
      .attr("transform", `translate(${width / 2}, 0)`)
      .attr("y", 60)
      .attr("text-anchor", "end")
      .style("fill", "#909090")
      .style("font-size", "12px")
      .text("Confidence (%)");

    // y axis text
    g.append("g")
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -30)
      .attr("x", -60)
      .attr("text-anchor", "end")
      .style("fill", "#5A5A5A")
      .style("font-size", "12px")
      .text('Reach');

    // fixed graph
    g.append("path")
      .datum(this.data)
      .attr("fill", "rgba(41,76,100,.5)")
      .attr("stroke-width", "3")
      .attr("d", area);

    // dynamic graph overlay
    g.append("path")
      .datum(this.data)
      .attr("fill", "rgba(41,76,100,1)")
      .attr("clip-path", "url(#myClip)")
      .attr("d", area);

    // invisible rect used to size the dynamic graph overlay
    this.svg.append("clipPath")
      .attr("id", "myClip")
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 0)
      .attr("height", 500);

    this.focus = this.svg.append("g")
      .attr("class", "focus")
      .style("display", "none");

    this.focus.append("text")
      .attr("x", 15)
      .attr("dy", ".35em");

    this.reachLine = d3.select(".reach-line")
    this.reachLine.style("height", `${height + margin.top + margin.bottom}px`);
    this.reachLine.style("margin-left", `${margin.left}px`);
    this.reachLine.style("margin-top", `-${height + margin.bottom}px`);

    this.slider = d3.select(".example-margin");
    this.slider.style("width", `${width + 16}px`);
    this.slider.style("margin-left", `${margin.left - 8 }px`);

    this.getCountByConfidence(this.lookalike.current_confidence);
  }

  onDrag(event): void {
    const confidence = event.value + this.scaleModifier;
    this.updateGraph(confidence);
  }

  getCountByConfidence(confidence): void {
    this.updateGraph(confidence);
    this.level = confidence - this.scaleModifier;
    const xValue = this.x(confidence);
    this.reachLine.style("margin-left", `${xValue + 49}px`);
  }

  updateGraph(confidence): void {
    const bisectData = d3.bisector((d) => d.confidence).left;
    const myClipRect = d3.select("#myClip rect");
    let xAxisPercentPos;
    let yAxisPercentPos;
    const index = bisectData(this.data, confidence);

    const currentValue = this.roundValue(index, confidence);

    if (currentValue.confidence <= 25) {
      yAxisPercentPos = this.y.domain()[1] * 0.90;
    }

    xAxisPercentPos = currentValue.confidence;
    yAxisPercentPos = yAxisPercentPos || currentValue[this.current_field];

    this.focus.style("display", null);
    this.focus.attr("transform", `translate(${this.x(xAxisPercentPos)},${this.y(yAxisPercentPos)})`);
    this.focus.select("text").text(currentValue.confidence + "%");


    const xValue = this.x(currentValue.confidence);
    myClipRect.attr("width", xValue);
    this.reachLine.style("margin-left", `${xValue + 49}px`);

    this.updateMetrics(currentValue);
  }

  private convertGraphData(field): {confidence: number, [field: string]: number}[] {
    const confidences = Object.keys(this.lookalike.confidence_sizes).map(key => parseInt(key));
    return confidences
      .map(confidence => ({confidence: confidence, [field]: this.lookalike.confidence_sizes[confidence][field]}))
      .sort((a, b) => a.confidence - b.confidence)
  }

  private roundValue(index: number, newConfidence: number): {confidence: number, [field: string]: number} {
    const datum1 = this.data[index - 1] || this.data[index];
    const datum2 = this.data[index];
    return newConfidence - datum1.confidence > datum2.confidence - newConfidence ? datum2 : datum1;
  }

  private clearGraph() {
    d3.selectAll("svg > *").remove();
  }

  updateMetrics(d): void {
    this.updateCountAndConfidence.emit(d);
  }
}
