<div class="audience-builder-form"  *ngIf="builderService.audience">
  <div class="audience-builder-form-header name">
    <div class="name-container layout horizontal center-start">
      <ppc-input [(ngModel)]="builderService.audience.name"
                 [placeholder]="placeholder"
                 [maxLength]="maxNameLength"
                 [softEnforceMaxLength]="true"
                 [errorMessage]="maxNameLengthErrorMessage"
                 [validators]="nameValidations"
                 showErrorsIfUntouched=true
                 noLabel=true
                 spellcheck=false
                 (focusin)="nameInputDefaultCheck($event)"
                 (input)="debouncedCheckAudienceChanges()">
        <div suffix>
          <i class="fa fa-pencil"></i>
        </div>
      </ppc-input>
      <ppc-checkbox *ngIf="isPersonaBuilder" [(ngModel)]="builderService.audience.default">
        Make this my default persona
      </ppc-checkbox>
      <div class="learn-more" (click)="openHelpCenter()">
        <img src="assets/icons/question-circle.svg" />
        <span>Learn More</span>
      </div>
    </div>
  </div>
  <div class="audience-builder-form-header">
    <div class="counts-container" *ngLet="(builderService.count$ | async) as count" [ppcTooltip]="estimatedPeopleCountTooltip">
      <div class="counts-container-header">Estimated People Count</div>
      <div class="counts-container-count" *ngIf="builderService.loading$ | async">
        <mat-spinner [diameter]="19"></mat-spinner>
      </div>
      <div class="counts-container-count" *ngIf="!(builderService.loading$ | async)">
        <i class="fa fa-user"></i>{{ count | number }}
      </div>
    </div>
    <ng-container *ngIf="canDisplayAddressableCounts">
      <div class="addressable counts-container" *ngLet="(builderService.count$ | async) as count" ppcTooltip="Cannot Estimate Digital Addressable Count" [tooltipDisabled]="(addressableCountTooltipDisabled$ | async)">
        <div class="counts-container-header">Estimated Addressable Count</div>
        <div class="counts-container-count" *ngIf="builderService.loading$ | async">
          <mat-spinner [diameter]="19"></mat-spinner>
        </div>
        <div class="counts-container-count" *ngIf="!(builderService.loading$ | async)">
          <i class="fa fa-user"></i>{{ addressableCount$ | async }}
        </div>
      </div>
    </ng-container>
    <div class="utils-container">
        <div class="groups-container">
          <div class="groups-container-hint">Drag and drop to {{subject}} Builder</div>
          <div class="new-group-container" cdkDropList [cdkDropListData]="createService.draggableGroups"
            [cdkDropListConnectedTo]="dragService.allDropLists$ | async" [cdkDropListSortingDisabled]="true">
            <div class="group" *ngFor="let group of createService.draggableGroups" cdkDrag [cdkDragData]="group"
              (cdkDragDropped)="onDrop($event)">
              <div class="group-text">{{group.type}}</div>
              <div class="audience-builder-rule-group-bracket" *cdkDragPreview>
                <div class="bracket">
                </div>
                <div class="group-info">
                  <div class="group-info-label">
                    <div class="drag-icon"><i class="fa fa-ellipsis-v" *ngFor="let i of [1,2]"></i></div>
                    <div class="label-text">{{group.type}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="history-container">
          <div class="undo-button" [class.disabled]="!(builderService.canUndo$ | async)" (click)="builderService.undo$.next()">
            <i class="fa fa-chevron-left"></i> Undo
          </div>
          <div class="redo-button" [class.disabled]="!(builderService.canRedo$ | async)" (click)="builderService.redo$.next()">
              Redo <i class="fa fa-chevron-right"></i>
          </div>
        </div>
    </div>
  </div>
  <div class="audience-builder-form-groups">
    <div class="audience-builder-form-groups-header">
      <div class="member-segments">Member Segments</div>
      <div class="people-counts">People Count</div>
    </div>
    <ppc-audience-builder-group
      [ruleGroup]="builderService.audience.rules"
      path="rules">
    </ppc-audience-builder-group>
  </div>
</div>
