import * as actions from "./creative.actions";
import {createSelector} from "@ngrx/store";
import {filter, get, groupBy, keyBy, map, mapValues, omit, reduce, reject, sortBy, values} from "lodash";
import {TemplateUploadReport} from "../services/creative.service";
import {isNumeric} from "app/shared/utils/utils";

export const modelTypes = [
  {key: 'all', display: 'All'},
  {key: 'labeled', display: 'Labels'},
  {key: 'object_detection', display: 'Detected Objects'}
];
export type ModelType = 'all' | 'labeled' | 'object_detection';

export interface CreativeAttribute {
  id: string;
  description?: string;
  short_code: string;
  model_type: ModelType;
}

export interface Creative {
  id: string;
  filename: string;
  competitor_id: string;
  sent_to_genome_at?: string; // Timestamp
  processed_at?: string; // Timestamp
  is_being_processed: boolean;
  processing_errors?: string[];
}

export interface Competitor {
  id: string;
  name: string;
  is_client?: boolean; // indicates if this competitor represents the client's product
}
export interface EditableCompetitor extends Competitor {
  editing: boolean;
}

export interface AttributeAverage {
  average_score: number;
  creative_attribute_id: string;
}

export interface Score {
  creative_id: string;
  creative_attribute_id: string;
  score: number; // in range [0, 100]
}

export interface AttributeScore extends CreativeAttribute {
  score: number;
}

export interface AttributeAnalysis extends CreativeAttribute {
  avgScore: number;
  competitorScore: number;
  brandScore: number;
}

export interface ScoredCreative extends Creative {
  scores: { [attrId: string]: number }
}

export interface State {
  competitorId: string;
  attrModelType: ModelType;
  attributes: {[id: string]: CreativeAttribute};
  competitors: {[id: string]: Competitor};
  creatives: {[id: string]: Creative};
  scores: {[id: string]: Score};
  average_scores: {[competitorId: string]: { [creativeAttributeId: string]: number} };
  templateUploadReport: TemplateUploadReport;
}

export const initialState: State = {
  competitorId: null,
  attrModelType: 'all',
  attributes: {},
  competitors: {},
  creatives: {},
  scores: {},
  templateUploadReport: null,
  average_scores: {}
};

export function reducer(state: State = initialState, action: actions.All): State {
  switch (action.type) {
    case actions.LoadScores.type:
      return {
        ...state,
        scores: {...state.scores, ...keyBy(action.scores, 'id')}
      };
    case actions.LoadCompetitors.type:
      return {...state, competitors: keyBy(action.competitors, 'id')};
    case actions.LoadCompetitor.type:
      return {
        ...state,
        competitors: {...state.competitors, [action.competitor.id]: action.competitor},
        competitorId: action.competitor.id
      };
    case actions.LoadCreatives.type:
      return {
        ...state,
        creatives: {...state.creatives, ...keyBy(action.creatives, 'id')}
      };
    case actions.DeleteCreative.type:
      return {
        ...state,
        creatives: omit(state.creatives, action.id) as {[id: string]: Creative},
        scores: keyBy(filter(state.scores, score => score.creative_id !== action.id), 'id')
      };
    case actions.DeleteCompetitor.type:
      const activeCompetitorId = (state.competitorId === action.id)
        ? selectClientCompetitorId(state)
        : selectCompetitorId(state);
      return {
        ...state,
        competitorId: activeCompetitorId,
        competitors: omit(state.competitors, action.id) as { [id: string]: Competitor }
      };
    case actions.LoadCreativeAttributes.type:
      return {...state, attributes: keyBy(action.creativeAttributes, 'id')};
    case actions.ChangeCompetitor.type:
      return {...state, competitorId: action.competitorId};
    case actions.LoadTemplateUploadReport.type:
      return {...state, templateUploadReport: action.report};
    case actions.ResetCreativesData.type:
      return {...initialState, attributes: state.attributes}; // Attributes are global, no need to reset
    case actions.ChangeModelType.type:
      return {...state, attrModelType: action.modelType};
    case actions.LoadAttributeAverages.type:
      return {
        ...state,
        average_scores: { ...state.average_scores, [action.competitorId]: mapValues(keyBy(action.averages, 'creative_attribute_id'), a => a.average_score) }
      }
    default:
      return state;
  }
}

export const selectAttributes = (state: State) => state.attributes;

export const selectScores = (state: State) => state.scores;

export const selectCreatives = (state: State) => state.creatives;

export const selectCompetitors = (state: State) => state.competitors;

export const selectCompetitorId = (state: State) => state.competitorId;

export const selectTemplateUploadReport = (state: State) => state.templateUploadReport;

export const selectAttributeAverages = (state: State) => state.average_scores;

export const selectAttributeAveragesForCurrentCompetitor = (state: State) => state.average_scores[state.competitorId || "all"];

export const selectAttributesForActiveModelType = createSelector(
  selectAttributes,
  (state: State) => state.attrModelType,
  (attrs, type) => type === 'all' ? attrs : keyBy(filter(attrs, {model_type: type}), 'id')
);

export const selectClientCompetitor = createSelector(
  selectCompetitors,
  competitors => {
    return values(competitors).find(competitor => competitor.is_client);
  }
);

export const selectClientCompetitorId = createSelector(
  selectClientCompetitor,
  clientCompetitor => clientCompetitor && clientCompetitor.id
);

// returns an object of the form {attrId: {creativeId: number}}
export const selectScoresIndex = createSelector(
  selectScores,
  (scores) => reduce(scores,
    (index, {creative_attribute_id, creative_id, score}) => {
      index[creative_attribute_id] = index[creative_attribute_id] || {};
      index[creative_attribute_id][creative_id] = score;
      return index;
    },
    {}
  )
);

export const selectNonClientCompetitors = createSelector(
  selectCompetitors,
  selectClientCompetitorId,
  (competitors, brandId) =>  reject(sortBy(competitors, c => c.name.toLowerCase()),
    { id: brandId })
);

export const selectCompetitorsForGraph = createSelector(
  selectNonClientCompetitors,
  (competitors) => [{name: "All Brands", id: null}, ...values(competitors)]
);

export const selectActiveCompetitor = createSelector(
  selectCompetitors,
  selectCompetitorId,
  (competitors, id) => competitors[id]
);

export const selectCreativesByCompetitor = createSelector(
  selectCreatives,
  (creatives) => groupBy(values(creatives), c => c.competitor_id) as {[competitorId: string]: Creative[]}
);


// returns an AttributeScore[]
export const selectAvgAttributeScoresForActiveCompetitor = createSelector(
  selectAttributesForActiveModelType,
  selectAttributeAveragesForCurrentCompetitor,
  (attrs, averages) =>
    map(attrs, attr => {
      return {
        ...attr,
        score: averages ? averages[attr.id] : 0
      }
    })
);

// returns an object of the form {attrId: avgScore}
// This treats all brands equally in the average regardless of how many creatives they have
export const selectAvgScoresByAttributeForAllCompetitors = createSelector(
  selectAttributeAverages,
  (avgAttrScoresByCompetitor) => {
    return avgAttrScoresByCompetitor['all']
  }
);

export const selectAvgScoresByAttributeForBrandCompetitor = createSelector(
  selectAttributeAverages,
  selectClientCompetitorId,
  (averages, brandId) => averages[brandId]
)

export const selectAvgScoresByAttributeForSelectedCompetitor = createSelector(
  selectAttributeAverages,
  selectCompetitorId,
  (averages, competitorId) => averages[competitorId]
)

// This treats all brands equally in the average regardless of how many creatives they have
export const selectAvgNonClientScoreByAttribute = createSelector(
  selectAttributeAverages,
  (avgAttrScores) => avgAttrScores['nonClient']
);

// {competitor_id: {attr_id: avg_score} }
export const selectAvgAttributeScoresByCompetitor = createSelector(
  selectAttributeAverages,
  (avgAttrScores) => mapValues(avgAttrScores,
    competitorScores => competitorScores)
);

export const selectAttributeAnalysesForOneCompetitor = createSelector(
  selectAttributesForActiveModelType,
  selectAvgAttributeScoresByCompetitor,
  selectAvgScoresByAttributeForAllCompetitors,
  selectAvgScoresByAttributeForSelectedCompetitor,
  selectAvgScoresByAttributeForBrandCompetitor,
  (attrs, scoresByCompetitor, avgScores, selectedCompetitorAvgScores, brandAvgScores) =>
    map(attrs, attr => ({
      ...attr,
      avgScore: get(avgScores, attr.id, 0),
      competitorScore: get(selectedCompetitorAvgScores,  attr.id, 0),
      brandScore: get(brandAvgScores, attr.id, 0)
    }))
);

export const selectAttributeAnalyses = createSelector(
  selectAttributesForActiveModelType,
  selectAvgAttributeScoresByCompetitor,
  selectAvgScoresByAttributeForAllCompetitors,
  selectAvgNonClientScoreByAttribute,
  selectAvgScoresByAttributeForBrandCompetitor,
  (attrs, scoresByCompetitor, avgScores, nonBrandAverageScores, brandAvgScores) =>
    map(attrs, attr => ({
      ...attr,
      avgScore: get(avgScores, attr.id, 0),
      competitorScore: get(nonBrandAverageScores, attr.id, 0),
      brandScore: get(brandAvgScores, attr.id, 0)
    }))
      .filter(({avgScore, competitorScore, brandScore}) => [avgScore, competitorScore, brandScore].every(isNumeric))
);

export const selectScoredCreatives = createSelector(
  selectScoresIndex,
  selectAttributes,
  selectCreatives,
  (scoresByAttr, attrs, creatives) =>
    map(creatives, (creative, id) => ({
      ...creative,
      scores: reduce(attrs,
        (acc, attr) => ({...acc,
          [attr.id]: get(scoresByAttr, [attr.id, id], 0) }),
        {})
    })
    ) as ScoredCreative[]
);

