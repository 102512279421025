export enum ErrorMessages {

  RadarChartLimits = 'Need attributes between 3 to 7 for any widget with graph type: Radar',
  BarChartVerticalLimits = 'No more than 9 attributes can be part of any widget with graph type: Bar (Vertical)',
}

export enum ToolTips {
  PersonaPercentage = 'Display the percentage of each attribute that is divided by the total widget percentage',
  NonPersonaPercentage = 'Display a scaled percentage of each attribute that is divided by the total chart percentage',
  NonPersonaScaled = 'Display a scaled count of each attribute that is divided by the total chart count',
  NonPersonaAbsolute = 'Display the exact count that was added to each attribute'
}
