import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {combineLatest, Observable, Subject, fromEvent} from "rxjs";
import {catchError, map, filter} from "rxjs/operators";
import {Store, select} from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {find, get} from 'lodash';
import {AppState} from "app/reducers";
import {activeContext} from "app/hierarchy/hierarchy.reducers";
import {AuthenticationService} from "app/services/authentication.service";
import {ToolboxService} from 'app/services/toolbox.service'
import {bufferUntil, toProperty} from "app/shared/utils/utils";
import {apiUrl} from "app/services/ppc_http.service";

@UntilDestroy()
@Component({
  selector: 'ppc-iframe-page',
  templateUrl: './iframe-page.component.html',
  styleUrls: ['./iframe-page.component.sass']
})
export class IframePageComponent implements OnInit, OnDestroy {
  @ViewChild('frame', { static: true }) iframe;
  iFrameLoaded$ = new Subject();
  fullyLoaded = false;

  url$ = this.toolboxService.iFramedUnit$.pipe(
    filter(unit => Boolean(unit && unit.url)),
    map(unit => new URL(unit.url).toString()),
    toProperty()
  )

  safeUrl$ = this.url$.pipe(map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url)))

  messageQueue$ = new Subject<IFrameMessage>();

  constructor(public route: ActivatedRoute,
    public store: Store<AppState>,
    private sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private toolboxService: ToolboxService) { }

  ngOnInit() {
    combineLatest(
      bufferUntil(this.messageQueue$, this.iFrameLoaded$),
      this.url$
    )
      .pipe(untilDestroyed(this))
      .subscribe(([ msg, url ]) => this.iframe.nativeElement.contentWindow.postMessage(msg, url))

    this.sendSsoToken()

    activeContext(this.store).pipe(untilDestroyed(this))
      .subscribe(context => this.messageQueue$.next({type: 'clientContextChange', payload: context}))

    combineLatest(fromEvent(window, 'message'), this.url$)
      .pipe(untilDestroyed(this),
        filter(([ message, url ]) => url.startsWith(message['origin'])),
        filter(([ message ]) => get(message, ['data', 'type']) === 'request-sso-token'))
      .subscribe(() => this.sendSsoToken())
  }

  loaded(evt): void {
    if (get(evt, 'type') === 'load') {
      this.fullyLoaded = true;
    }
  }

  ngOnDestroy() {
    this.messageQueue$.complete();
  }

  sendSsoToken() {
    this.authenticationService.getSsoToken()
      .pipe(catchError(err => {
        // todo show lion login account link prompt here
        console.warn('You must be logged in using lion login to receive an SSO token from Publicis PeopleCloud.')
        return Observable.of()
      }))
      .subscribe(token => this.messageQueue$.next({type: 'sso',
        payload: {token,
          validationUrl: apiUrl('/sso-token')}}))
  }
}

interface IFrameMessage {
  type: string;
  payload: any;
}
