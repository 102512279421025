
import {takeUntil} from 'rxjs/operators';

import {map} from 'rxjs/operators';
import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { AppState } from "app/reducers/index";
import {Curve} from "../curve";
import { CppValidator } from '../cpp-validator';

@Component({
  selector: 'curve-parameter-table',
  templateUrl: './curve-parameter-table.component.html',
  styleUrls: ['./curve-parameter-table.component.sass']
})
export class CurveParameterTableComponent implements OnInit, OnDestroy {
  numberValidations =
  [{
    isValid: (x) => Curve.validateParam(x),
    errorMessage: "Please enter a number"
  }];

  cppValidators = [
    new CppValidator(),
  ];

  @Input() curveErrors: { [key: string]: string} = {};
  @Input() productId = "";
  @Input("curves") uploadedCurves: Curve[] = [];
  @Input() curveTypeNames: string[] = [];

  @Output() onDeleteCurve = new EventEmitter<number>();

  ngUnsubscribe: Subject<boolean> = new Subject();
  hasDeletePermission: boolean;
  COST_METHODS = [
    {value: 1, display: 'CPM'},
    {value: 0, display: 'CPP'}
  ];

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    if (this.uploadedCurves.length < 1) {
      this.addEmptyCurveParameters();
    }
    this.store.select("permissions").pipe(
      map(permission => permission.curves),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(
        (permission) => {
          if (permission) {this.hasDeletePermission = permission.destroy; }
        })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addEmptyCurveParameters() {
    this.uploadedCurves.push(
      new Curve()
    )
  }

  deleteCurve(curveIdx: number) {
    if (!this.hasDeletePermission) {return; }
    this.onDeleteCurve.emit(curveIdx);
  }

  tableClass(curve: Curve): string {
    const curveError = this.curveErrors[curve.id];
    return curveError != void(0) && curveError.length > 0 ? "error" : ""
  }

  deleteDisabled(curve: Curve, i: number): boolean {
    return (i < 1 && !curve.anyFilled || !this.hasDeletePermission)
  }

  get showAddNew(): Boolean {
    const notGeneric = this.uploadedCurves.filter(c => !c.is_generic);
    return notGeneric.length < 1 || notGeneric[notGeneric.length - 1].isValid
  }
}
