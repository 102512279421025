<tr class="row-hover">
  <td class="hoverable"
  [ppcTooltip]="tooltipTex"
  [tooltipDelayTime]="600">
    <i class="{{icon}}"></i>
  </td>
  <td class="left-align hoverable" routerLink="{{hierarchyPath}}/audiences/overview">{{row.name}}</td>
  <td class="right-text">{{(count || 0) | number}}</td>
  <td class="center-text">{{row.created_at | date: 'shortDate'}}</td>
  <td routerLink="{{hierarchyPath}}/audiences/overview">
    <img src="assets/icons/baseline-arrow_forward-24px.svg" class="blue-icon" />
  </td>
</tr>
