<app-popup
  #popupComponent
  class="popup"
  [class.detail-view]="isDetailView"
  [neverOpenUpwards]="isDetailView"
  >

  <i *ngIf="!isDetailView; else actionsButton" popupTrigger class="trigger fa fa-ellipsis-v"></i>
  <ng-template #actionsButton>
    <button class="trigger" mat-button popupTrigger>
      <span class="action-label">Actions</span>
      <span [class]="'action-arrow fa fa-angle-' + (popupComponent.isOpen ? 'up' : 'down')"></span>
    </button>
  </ng-template>

  <div popupBody class="popup-items">
    <ng-container *ngFor="let item of menuItems" >
      <div
        *ngIf="!item.confirmation else confirmation"
        class="mat-button action-menu-item"
        [ngClass]="{'disabled': item.disabled}"
        [ppcTooltip]="item.tooltip"
        [tooltipDisabled]="!item.disabled"
        (click)="item.action(ppcItem)">
        <span>
          {{ item.title }}
        </span>
      </div>

      <ng-template #confirmation>
        <app-confirmation
          class="mat-button confirmation-button"
          [ngClass]="{'disabled': item.disabled}"
          [ppcTooltip]="item.tooltip"
          [tooltipDisabled]="!item.disabled"
          [isCustomTemplate]="true"
          [kontinue]="handleDelete.bind(this)"
          [modalTitle]="deleteConfirmationMessage(ppcItem)"
        >
          {{ item.title }}
        </app-confirmation>
      </ng-template>
    </ng-container>
  </div>
</app-popup>

