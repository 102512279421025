import { map, switchMap, filter, take } from 'rxjs/operators';
import { iif, combineLatest as observableCombineLatest, Observable, of as observableOf, BehaviorSubject } from 'rxjs';
import { Component, OnDestroy, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { chain, compact, concat, each, every, filter as _filter, find, flatMap, flatten, forEach, keyBy, keys, last, map as _map, max, pickBy, sortBy, sumBy, values, without, difference, reduce, groupBy, get, some, mapValues } from 'lodash';
import { Workbook } from 'exceljs/dist/exceljs';
import { HttpClient } from "@angular/common/http";

import { MarketLevelDemographic } from 'app/insights/insights-components/market-level-demographics/market-level-demographic.interface';
import { AppState } from 'app/reducers';
import { Tab, Demographic, Filter, ResourceDefinedSegments } from 'app/insights/insights.models';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';
import { Mekko, SubMarket, selectActiveMekko, selectActiveMekkoSubMarkets, selectSelectedSubMarkets } from 'app/mekko/mekko.reducer';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { SegmentLike } from 'app/models/segment-like.model';
import { prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { SubMarketBucketEntry, MarketLevelDemographicBucket } from 'app/insights/insights-components/market-level-demographics/market-level-demographic.interface';
import { GrowCountService } from 'app/insights/grow-v3/grow-count.service';
import { RegionDemographic, selectCensusDemographics, selectStandardDemographics } from 'app/insights/insights.reducer';
import { InsightsCountService } from "app/insights/insights-count.service";
import { environment } from 'environments/environment';
import { selectAllDemographics, IndexBase } from 'app/insights/insights.reducer';
import { buildPrimaryContext, buildSubMarketCountAggregations } from 'app/insights/grow-v3/grow.utils';
import { buildSegmentContextsFromJourney, journeySubMarketCountKey } from 'app/journey/journey.utils';
import { buildMoleculaContext, buildQueryFromContext, getVendorTypeAggregationKey, buildIndexingVendorTypesQuery, buildMoleculaIndexContext, isMolecula } from 'app/insights/insights.utils';
import { getRuleSegmentIdentifiers } from 'app/audiences-v2/audience-v2.model';
import { GrowTabState } from 'app/insights/grow-v3/grow.reducer';
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { selectSelectedBrand, selectSelectedJourney, selectActiveJourneySubMarkets, selectSelectedJourneySubMarkets, selectSelectedStages, selectActiveStagesForSelectedBrand } from 'app/journey/journey.reducer';
import { Journey, JourneySubMarket, JourneyStage, JourneyBrand, JourneyTabType } from 'app/journey/journey.models';
import { JourneyCountService } from 'app/journey/journey-count.service';
import { selectAllCells } from 'app/comparisons/comparisons.reducer';
import { OverlapInsightsExportService } from 'app/insights/insights-components/insights-export/overlap-insights-export.service';
import { InsightsExportService } from 'app/insights/insights-components/insights-export/insights-export.service';
import { selectClient } from 'app/hierarchy/hierarchy.reducers';
import { HierarchyClient } from 'app/hierarchy/hierarchy.interface';
import { SegmentsHierarchyService } from 'app/segments-hierarchy/segments-hierarchy.service';
import { INDEX_VALUES, PERMISSION_INSIGHTS } from 'app/shared/utils/constants';
import { allPersonaIdentifiers, buildSegmentContext, groupTransformWS, TOOLTIP_PERSONA_EXCLUDE_INDEX } from 'app/explore/explore.utils';
import { activePersonaHasExcludedIds, Persona, selectActivePersona } from 'app/explore/explore.reducer';
import { isDefined } from 'app/shared/utils/utils';
import { BrowsingDataExport } from './browsing-data-export.model';
import { topCountsAllVerticalDataUrl } from 'app/shared/constants/insights.urls';
import { isFetchInFlight } from 'app/shared/utils/fetch-state';
import * as insightsActions from 'app/insights/insights.actions';
import { CLIENT_VERTICALS } from 'app/admin/client/client.constants';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';

@UntilDestroy()
@Component({
  selector: 'ppc-insights-export',
  templateUrl: './insights-export.component.html',
  styleUrls: ['./insights-export.component.sass']
})
export class InsightsExportComponent extends InsightsResourceTracker implements OnDestroy {
  marketLevelDemographics: MarketLevelDemographic[] = [];
  allPersonLevelDemographicIds: string[];
  standardDemographics: Demographic[] = [];
  personLevelCustomDemographics: Demographic[] = [];
  personLevelDemographicsTabVisible: Boolean;
  mekko: Mekko;
  journey: Journey;
  selectedSubMarkets: SubMarket[];
  activeSubMarkets: SubMarket[];
  selectedJourneySubMarkets: JourneySubMarket[];
  activeJourneySubMarkets: JourneySubMarket[];
  selectedStages: JourneyStage[];
  activeStages: JourneyStage[];
  activeBrand: JourneyBrand;
  activePersona: Persona;
  censusDemographics: RegionDemographic[];
  marketLevelBrowsingData: any;
  segments: {[identifier: string]: SegmentLike} = {};
  segmentCounts: {[identifier: string]: number};
  demographicShortIds: string[];
  marketLevelTabs: Tab[];
  personLevelTabs: Tab[];
  exportOptions: {};
  filters: Filter[];
  canExport = true;
  isTier3 = environment.isTier3;
  topLevelPersonLevelTab$ = this.store.select("insights", this.insightsContext, "tabs").pipe(
    map(tabs => find(tabs, {tab_key: "top_level_person", visible: true}))
  );
  topLevelMarketLevelTab$ = this.store.select("insights", this.insightsContext, "tabs").pipe(
    map(tabs => find(tabs, {tab_key: "top_level_market", visible: true}))
  );
  loading$ = new BehaviorSubject(false);
  includeIndexes = false;
  counts: {[exportOption: string]: {[identifier: string]: number}};
  visibleTabs: string[];
  visibleTabsShareThis: string[];
  verticalKey = 'Automotive';
  allCellIdentifiers: string[];
  indexBase: IndexBase;
  vendors: string[];
  fetchingSegments$ = new BehaviorSubject(false);
  fetchingShareThis$ = new BehaviorSubject(false);
  browsingDataExportHelper: BrowsingDataExport;
  personas: Persona[];
  personaHasExcludedSegments$ = this.store.select("explore").pipe(
    select(activePersonaHasExcludedIds),
    map(activePersonaHasExcludedIds => activePersonaHasExcludedIds && this.insightsContext === 'explore')
  );
  personaHasExcludedSegmentsTooltip = TOOLTIP_PERSONA_EXCLUDE_INDEX;
  vendorDisplayNames: VendorDisplayName[];

  constructor(private http: HttpClient,
    public store: Store<AppState>,
    private segmentV2Service: SegmentV2Service,
    public growCounts: GrowCountService,
    public journeyCounts: JourneyCountService,
    public insightsCounts: InsightsCountService,
    public overlapExport: OverlapInsightsExportService,
    public insightsExportService: InsightsExportService,
    private segmentsHierarchyService: SegmentsHierarchyService,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super(store, insightsContext)

    store.select('segmentsHierarchy', 'vendorDisplayNames').pipe(
      untilDestroyed(this)
    ).subscribe(vendorDisplayNames => this.vendorDisplayNames = vendorDisplayNames);

    observableCombineLatest(
      this.store.pipe(select(selectAllDemographics(this.insightsContext))),
      this.store.pipe(
        select(selectStandardDemographics(this.insightsContext)),
        map(demographics => _filter(demographics, 'visible'))
      ),
      this.store.select("insights", this.insightsContext, "demographics").pipe(
        map(demographics => _filter(demographics, d => d.custom_tab_id != null))
      ),
      this.store.select("mekkos").pipe(select(selectActiveMekkoSubMarkets)),
      this.store.select("journey").pipe(select(selectActiveJourneySubMarkets)),
      this.store.select("insights", this.insightsContext, "marketLevelDemographics").pipe(map(values)),
      this.store.select("insights", this.insightsContext, "widgetConfig"),
      this.store.select("comparisons").pipe(select(selectAllCells)),
      this.store.select("insights", this.insightsContext, "indexBase"),
      this.store.select("explore").pipe(select(selectActivePersona)),
      this.store.select("explore", "personas"),
      this.store.select('insights', this.insightsContext, 'filters')
    ).pipe(untilDestroyed(this))
      .subscribe(([
        allDemographics,
        standardDemographics,
        personLevelCustomDemographics,
        activeMekkoSubMarkets,
        activeJourneySubMarkets,
        marketLevelDemographics,
        widgetConfig,
        allCells,
        indexBase,
        activePersona,
        personas,
        filters
      ]) => {
        this.personas = personas;
        this.allPersonLevelDemographicIds = chain(allDemographics).flatMap("buckets")
          .map("short_id")
          .compact()
          .value();
        this.standardDemographics = standardDemographics;
        this.personLevelCustomDemographics = this.getVisibleCustomDemographics(personLevelCustomDemographics, widgetConfig);
        this.marketLevelDemographics = this.getVisibleCustomDemographics(marketLevelDemographics, widgetConfig);
        const activeSubMarkets = this.insightsContext === "grow" ? activeMekkoSubMarkets : activeJourneySubMarkets;
        this.allCellIdentifiers = flatMap(allCells, cell => [cell.x, cell.y]);
        this.indexBase = indexBase;
        this.filters = filters;
        const personaIdentifiers: string[] = activePersona ? allPersonaIdentifiers(activePersona.identifiers) : [];
        const identifiers = concat(this.getIdentifiers(activeSubMarkets), this.allPersonLevelDemographicIds, this.allCellIdentifiers, this.indexBase.shortId, personaIdentifiers, ...this.filterIdentifiers);

        if (identifiers.length) {
          this.fetchingSegments$.next(true);
          this.segmentV2Service.fetchByIdentifiers(identifiers).pipe(
            switchMap(segments => {
              if (some(segments, {type: "Audience"})) {
                // Need to fetch segments that are a part of audience rules too
                const ruleIdentifiers = chain(segments as any[])
                  .filter({type: "Audience"})
                  .flatMap(getRuleSegmentIdentifiers)
                  .uniq()
                  .value();

                return observableCombineLatest(
                  observableOf(segments),
                  this.segmentV2Service.fetchByIdentifiers(ruleIdentifiers)
                ).pipe(
                  map(([segments, ruleSegments]) => [...segments, ...ruleSegments]),
                )
              } else {
                return observableOf(segments)
              }
            }),
          ).subscribe(segments => {
            this.segments = keyBy(segments, "identifier");
            this.fetchingSegments$.next(false);
          });
        };
      })

    if (this.insightsContext === "explore") {
      this.store.dispatch(new insightsActions.FetchShareThisData(1, 'all', 'explore'));
      this.store.dispatch(new insightsActions.FetchShareThisTopDomains(1, 'all', 'explore'));
      this.store.dispatch(new insightsActions.FetchShareThisVerticalOverlap(1, this.verticalKey, 'explore'));
      this.fetchingShareThis$.next(true);

      observableCombineLatest(
        this.store.select("fetchStates", insightsActions.FetchShareThisData.type),
        this.store.select("fetchStates", insightsActions.FetchShareThisTopDomains.type),
        this.store.select("fetchStates", insightsActions.FetchShareThisVerticalOverlap.type)
      ).pipe(
        map(isFetching => _map(isFetching, isFetchInFlight)),
        filter(isFetching => !some(isFetching, Boolean)),
        untilDestroyed(this)
      ).subscribe(() => {
        this.fetchingShareThis$.next(false)
      });

      this.store.select("explore").pipe(
        select(selectActivePersona), filter(isDefined), untilDestroyed(this)
      ).subscribe(activePersona => this.activePersona = activePersona);

      this.store.select('insights', 'explore', 'topLevelTab').pipe(
        untilDestroyed(this)
      ).subscribe((topLevelTab: string) => {
        this.exportOptions = {
          "Total Population": topLevelTab === "Market Level",
          "Matched Addressable": topLevelTab === "Person Level"
        }
      });

      this.store.select('insights', 'explore', 'verticalOverlapKey').pipe(
        filter(Boolean),
        untilDestroyed(this)
      ).subscribe((key: string) => {
        this.verticalKey = key === 'all' ? get(CLIENT_VERTICALS[0], 'key') : key;
      });

      this.store.select('insights', 'explore', 'tabs').pipe(
        untilDestroyed(this)
      ).subscribe((tabs) => {
        if (tabs && tabs.length) {
          const ml_tab = tabs.find((t) => t.tab_key === 'top_level_market');
          const children = ml_tab.children.filter((t) => t.visible);
          this.visibleTabs = _map(children, 'tab_key');
          this.visibleTabsShareThis = chain(children)
            .find(t => t.tab_key === 'market_level_share_this')
            .defaultTo({} as Tab)
            .get('children', [])
            .map('name')
            .value();
        }
      });

      // fetch census data
      this.store.pipe(
        select(selectCensusDemographics(this.insightsContext)),
        untilDestroyed(this)
      ).subscribe((censusDemographics) => {
        this.censusDemographics = _filter(censusDemographics, 'visible');
      });

      this.store.select("hierarchy").pipe(
        select(selectClient),
        filter(Boolean),
        switchMap((client: HierarchyClient) => this.segmentsHierarchyService.getListOfVendors([PERMISSION_INSIGHTS], client.slug)),
        map(vendors => _map(vendors, cv => `${cv.vendor_name}-${cv.vendor_type}${cv.owner_name ? "-" + cv.owner_name : ''}`)),
        untilDestroyed(this)
      ).subscribe(vendors => this.vendors = vendors);
    }

    if (this.insightsContext === "grow") {
      observableCombineLatest(
        this.store.select("mekkos").pipe(select(selectSelectedSubMarkets)),
        this.store.select("mekkos").pipe(select(selectActiveMekkoSubMarkets)),
        this.store.select("mekkos").pipe(select(selectActiveMekko)),
      ).subscribe(([selectedSubMarkets, activeSubMarkets, mekko]) => {
        this.selectedSubMarkets = sortBy(selectedSubMarkets, "market_id", "order");
        this.activeSubMarkets = activeSubMarkets;
        this.mekko = mekko;
      });

      this.store.select("grow", "growTabState").pipe(untilDestroyed(this)
      ).subscribe((string: string) => {
        this.exportOptions = {
          "Total Population": string === "Total Population",
          "Matched Addressable": string === "Matched Addressable",
          "Modeled Addressable": string === "Modeled Addressable",
        };
      });
    }

    if (this.insightsContext === "journey") {
      observableCombineLatest(
        this.store.select("journey").pipe(select(selectSelectedJourneySubMarkets)),
        this.store.select("journey").pipe(select(selectActiveJourneySubMarkets)),
        this.store.select("journey").pipe(select(selectSelectedJourney)),
        this.store.select("journey").pipe(select(selectSelectedStages)),
        this.store.select("journey").pipe(select(selectActiveStagesForSelectedBrand)),
        this.store.select('journey').pipe(select(selectSelectedBrand)),
      ).subscribe(([selectedSubMarkets, activeSubMarkets, journey, selectedStages, activeStages, activeBrand]) => {
        this.selectedJourneySubMarkets = sortBy(selectedSubMarkets, "brand_id", "order");
        this.activeJourneySubMarkets = activeSubMarkets;
        this.activeStages = sortBy(activeStages, "brand_id", "priority");
        this.selectedStages = selectedStages.length ? sortBy(selectedStages, "brand_id", "priority") : this.activeStages;
        this.journey = journey;
        this.activeBrand = activeBrand;
      });

      this.store.select("journey", "journeyTab").pipe(untilDestroyed(this)
      ).subscribe((string: string) => {
        this.exportOptions = {
          "Total Population": string === "Total Population",
          "Matched Addressable": string === "Matched",
          "Modeled Addressable": string === "Modeled",
        };
      })
    }

    if (this.insightsContext === "compare") {
      this.exportOptions = this.overlapExport.exportOptions;
    }

    observableCombineLatest(
      this.topLevelMarketLevelTab$,
      this.topLevelPersonLevelTab$,
    ).pipe(untilDestroyed(this)).subscribe(([marketLevelTab, personLevelTab]) => {
      this.marketLevelTabs = marketLevelTab ? marketLevelTab.children.filter(t => t.tab_key === "market_level_custom" && t.visible) : [];
      this.personLevelTabs = personLevelTab ? personLevelTab.children.filter(t => t.tab_key === "person_level_custom" && t.visible) : [];
      this.personLevelDemographicsTabVisible = personLevelTab && !!find(personLevelTab.children, t => t.tab_key === "person_level_demographics" && t.visible);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  export() {
    const selectedOptions = keys(pickBy(this.exportOptions, Boolean)) as string[];

    observableCombineLatest(
      this.fetchingSegments$,
      this.fetchingShareThis$,
    ).pipe(
      filter(([fetchingSegments, fetchingShareThis]) => !fetchingSegments && !fetchingShareThis),
      take(1),
      switchMap(() => this.fetchForContexts(difference(selectedOptions, ["Total Population"]))),
    ).subscribe(
      (counts) => {
        this.counts = counts;
        let chartData;
        if (this.insightsContext === "grow" || this.insightsContext === "journey") {
          selectedOptions.forEach(option => {
            chartData = this.setChartData(option);
            if (option === "Total Population") { this.marketLevelExport(chartData) };
            if (option === "Matched Addressable") { this.personLevelExport(chartData, option) };
            if (option === "Modeled Addressable") { this.personLevelExport(chartData, option) };
          });

          this.insightsExportService.finishExport();
        } else if (this.insightsContext === "compare") {
          this.overlapExport.setUnfilteredCount(this.getPersonLevelCount("unfiltered:Person Level", "total_count"));
          const segmentPaths = keyBy(_map(this.allCellIdentifiers, identifier => {
            return { identifier: identifier, path: this.getSegmentPath(this.segments[identifier]) }
          }), "identifier");
          segmentPaths[this.indexBase.shortId] = {
            identifier: this.indexBase.shortId,
            path: this.getSegmentPath(this.segments[this.indexBase.shortId])
          };
          this.overlapExport.setIdentifiers(segmentPaths);
          chartData = this.setChartData("Person Level");
          this.personLevelExport(chartData, "Person Level", this.overlapExport.personLevelWorkBookName);
          this.insightsExportService.finishExport();
        } else if (this.insightsContext === 'explore') {

          iif(
            () => this.exportOptions["Total Population"],
            observableCombineLatest(
              this.store.select('insights', 'explore', 'shareThisData'),
              this.http.get(topCountsAllVerticalDataUrl(1)),
              this.store.select('insights', 'explore', 'verticalOverlapData'),
            ).pipe(
              take(1),
              map(([shareThisData, topDomainsData, overlapData]) => ({
                ...shareThisData,
                "Top Domains": topDomainsData,
                "Overlap": overlapData
              })),
            ),
            observableOf({})
          ).pipe(
            untilDestroyed(this)
          ).subscribe(browsingData => {
            this.marketLevelBrowsingData = browsingData;

            this.browsingDataExportHelper = new BrowsingDataExport(this.marketLevelBrowsingData, this.verticalKey);

            selectedOptions.forEach(option => {
              if (option === "Total Population") {
                chartData = this.setChartData(option);
                const workBookName = 'marketlevel_export';
                const tabName = 'Persona Details';
                this.marketLevelExport(chartData, workBookName, tabName);
              }
              if (option === "Matched Addressable") {
                chartData = this.setChartData(option);
                const workBookName = 'personlevel_export';
                const tabName = 'Persona Details';
                this.personLevelExport(chartData, option, workBookName, tabName);
              }
            });
            this.insightsExportService.finishExport();
          });
        }
      }
    )
  }

  setChartData(exportOption: string) {
    if (this.insightsContext === "compare") {
      const chartData = this.overlapExport.generateOverlapChartDetailsData();
      return this.insightsExportService.addFiltersToChartDetails(this.filters, false, { "Widget Filters": [] }, chartData);
    }
    const countType = exportOption.split(" ")[0];
    const isMarketLevel = exportOption === "Total Population";
    const filteredChartCount = this.getPersonLevelCount(exportOption, "total_count");
    const isModeledTier3 = countType === "Modeled" && this.isTier3;
    const countLabel = isMarketLevel ? exportOption : countType + (isModeledTier3 ? " Weight" : " Count");
    const chartArgs: [string, string, string, boolean, number] = [exportOption, countType, countLabel, isMarketLevel, filteredChartCount];

    if (this.insightsContext === "grow") {
      return this.generateGrowChartDetailsData(...chartArgs);
    } else if (this.insightsContext === "journey") {
      return this.generateJourneyChartDetailsData(...chartArgs);
    } else {
      return this.generateExploreChartDetailsData(...chartArgs);
    }
  }

  marketLevelExport(contextChartDetails, workBookName?: string, tabName?: string) {
    const chartDetails = concat(this.insightsExportService.chartDetailsSharedHeaders(), contextChartDetails);
    const fileName = this.insightsExportService.setFileName(workBookName || 'total_population_marketlevel_export');
    const wb: Workbook = this.addChartDetailsSheet(chartDetails, tabName);
    this.generateMarketLevelWorksheets(wb);
    this.insightsExportService.writeFile(wb, fileName);
  }

  generateMarketLevelWorksheets(workbook) {
    if (this.insightsContext === "explore") {
      if (this.visibleTabs.includes('market_level_census')) {
        this.generateMarketLevelCensusWorksheet(workbook);
      }
      if (this.visibleTabs.includes('market_level_share_this')) {
        this.generateMarketLevelBrowsingWorksheets(workbook);
      }
      // show custom tabs worksheets only on 'All Data' persona
      if (this.activePersona.name !== 'All Data') { return; }
    }
    this.marketLevelTabs.forEach(tab => {
      const tabWorksheet = this.insightsExportService.setWorksheet(workbook, tab.name);
      tabWorksheet.addRow(["Widget Name", "Attribute Name", "Attribute Count", "Attribute Percent of Widget"])
      forEach(_filter(this.marketLevelDemographics, mld => mld.custom_tab_id === tab.id), mld => {
        mld.market_level_demographic_buckets.map(mldb => {
          if (this.insightsContext === "grow" || this.insightsContext === "journey" || this.insightsContext === "explore") {
            let selectedSubMarkets;
            if (this.insightsContext === "grow") {
              selectedSubMarkets = this.selectedSubMarkets
            } else if (this.insightsContext === "journey") {
              selectedSubMarkets = this.selectedJourneySubMarkets;
            } else {
              selectedSubMarkets = [this.activePersona]
            }
            const count = this.getCount(mldb, selectedSubMarkets);
            const percent = this.getPercent(mld, mldb, selectedSubMarkets);
            tabWorksheet.addRow([
              mld.name,
              mldb.name,
              isNaN(count) ? 0 : count,
              isNaN(percent) || !isFinite(percent) ? 0 : percent,
            ]);
          }
        });

        tabWorksheet.addRow([])
      });
    });
  }

  generateMarketLevelCensusWorksheet(workbook) {
    const tabWorksheet = this.insightsExportService.setWorksheet(workbook, "Census");
    tabWorksheet.addRow(["Widget Name", "Attribute Name", "Attribute Count", "Attribute Percent of Widget", "Attibute Provider"]);
    forEach(this.censusDemographics, demographic => {
      demographic.region_demographic_buckets.map(rdb => {
        tabWorksheet.addRow([
          demographic.name,
          rdb.name,
          rdb.count,
          +(rdb.percentage / 100).toFixed(2),
          demographic.attribute_provider
        ]);
      });
      tabWorksheet.addRow([]);
    });
  }

  generateMarketLevelBrowsingWorksheets(workbook) {
    let tabWorksheet;
    const browsingSheets = _filter(this.visibleTabsShareThis, t => t !== 'Overlap');

    forEach(browsingSheets, entry => {
      const columns = this.browsingDataExportHelper.getBrowsingWorksheetData(entry);
      tabWorksheet = this.insightsExportService.setWorksheet(workbook, `Browsing - ${entry}`);

      each(columns, (columnValues, idx) => {
        const columnNumber = idx + 1;
        const column = tabWorksheet.getColumn(columnNumber);

        column.values = columnValues;
        column.width = 18;
      });
    });

    if (!this.visibleTabsShareThis.includes('Overlap')) {
      return;
    }
    // overlap sheet is an exception and can go by row
    tabWorksheet = this.insightsExportService.setWorksheet(workbook, 'Browsing - Overlap');
    tabWorksheet.addRow(['Category Overlap', 'Attribute Count', 'Attribute Provider', 'Attribute Selected']);
    forEach(this.browsingDataExportHelper.overlapData, overlap => {
      const isSelected = this.browsingDataExportHelper.verticalKey === overlap.name;
      const vertical = find(CLIENT_VERTICALS, v => v.key === overlap.name);
      const rowData = [
        vertical.display,
        overlap.count,
        'ShareThis',
        isSelected ? 'X' : '',
      ];
      const row = tabWorksheet.addRow(rowData)

      if (isSelected) { this.insightsExportService.boldRow(row); }
    });
  }

  personLevelExport(chartData, exportType: string, workBookName?: string, tabName?: string) {
    const chartDetails = concat(this.insightsExportService.chartDetailsSharedHeaders(), chartData);
    const fileName = this.insightsExportService.setFileName(workBookName || (exportType.split(" ")[0].toLowerCase() + '_personlevel_export'));
    const wb: Workbook = this.addChartDetailsSheet(chartDetails, tabName);
    this.generatePersonLevelWorksheets(wb, exportType);
    this.insightsExportService.writeFile(wb, fileName);
  }

  generatePersonLevelWorksheets(workbook, exportOption) {
    let headers;
    if (this.insightsContext === "compare") {
      headers = this.overlapExport.widgetHeaders;
    } else {
      const countLabel = exportOption === "Modeled Addressable" && this.isTier3 ? "Attribute GWI Weight" : "Attribute Count";
      headers = ["Widget Name", "Attribute Name", countLabel, "Attribute Percent of Widget",
        "Attribute Percent of Base", "Index", "Attribute Provider", "Attribute Selected"];
    }

    if (this.personLevelDemographicsTabVisible) {
      const tabWorksheet = this.insightsExportService.setWorksheet(workbook, 'Demographics');
      tabWorksheet.addRow(headers)
      this.generatePersonLevelDemographicSheets(this.standardDemographics, tabWorksheet, exportOption);
    }
    this.personLevelTabs.forEach(tab => {
      const personLevelDemographics = _filter(this.personLevelCustomDemographics, demo => demo.custom_tab_id === tab.id);
      const tabWorksheet = this.insightsExportService.setWorksheet(workbook, tab.name);
      tabWorksheet.addRow(headers)
      this.generatePersonLevelDemographicSheets(personLevelDemographics, tabWorksheet, exportOption);
    });
  }

  generatePersonLevelDemographicSheets(demographics, tabWorksheet, exportOption) {
    flatMap(demographics, demo => {
      const bucketCounts = _map(demo.buckets, bucket => this.getPersonLevelCount(exportOption, bucket.short_id));
      const totalDemoCount = sumBy(bucketCounts) || 0;
      let key = exportOption === 'Matched Addressable' ? 'matched' : 'modeled';
      if (this.insightsContext === 'journey') {
        key += '_id';
      }

      const totalDemoBaseCount = this.insightsCounts.getTotalCountByKey(key);
      demo.buckets.map((bucket, i) => {
        const count = bucketCounts[i];
        const index = this.hasIndex(exportOption, bucket.short_id) ? Math.round(this.getIndex(exportOption, bucket.short_id)) : null;
        const row = tabWorksheet.addRow([
          demo.name,
          bucket.name,
          count,
          totalDemoCount === 0 ? 0 : Math.round((count / totalDemoCount) * 1e2) / 1e2,
          (totalDemoBaseCount === 0 || !totalDemoBaseCount) ? 0 : Math.round((count / totalDemoBaseCount) * 1e2) / 1e2,
          index ? index : 'N/A',
          prettyPathParts(this.segments[bucket.short_id], this.vendorDisplayNames)[0],
          flatten(this.filterIdentifiers).includes(bucket.short_id) ? 'X' : null
        ]);

        if (last(row.values) === 'X') { this.insightsExportService.boldRow(row) }
        if (index) { this.insightsExportService.handleIndexColor(row, 5, index) }
      });

      tabWorksheet.addRow([])
    });
  }

  generateGrowChartDetailsData(exportType: string, countType: string, countLabel: string, isMarketLevel: boolean, filteredChartCount: number) {
    const unfilteredChartCount = isMarketLevel ? sumBy(this.activeSubMarkets, "population") : this.getPersonLevelCount(`unfiltered:${exportType}`, "total_count");
    const filteredPeopleCount = isMarketLevel ? sumBy(this.selectedSubMarkets, "population") : filteredChartCount;
    const populationLabel = countType === "Modeled" && this.isTier3 ? countLabel : "Audience Population";
    const isPanelistCount = this.mekko.count_type === "tv_panelists";
    const chartData = without([
      ["Chart Name", this.mekko.name],
      ["Count Type", isPanelistCount ? "People Count + Estimated TV Panelists" : "People Count"],
      ["Index Base", this.indexBase.shortId ? this.getSegmentPath(this.segments[this.indexBase.shortId]) : this.indexBase.name],
      ["Chart " + countLabel, unfilteredChartCount],
      isPanelistCount ? ["Chart " + countType + " Estimated TV Panelists", this.getPanelistCount(this.activeSubMarkets, exportType)] : null,
      ["Filtered " + countLabel, filteredPeopleCount],
      isPanelistCount ? ["Filtered " + countType + " Estimated TV Panelists", this.getPanelistCount(this.selectedSubMarkets, exportType)] : null,
      [],
    ], null);
    const data = {};
    forEach(without([
      "Selected Audiences",
      "Chart Market",
      "Identifier",
      isMarketLevel ? null : countType + " Audience",
      populationLabel,
      isPanelistCount ? "Estimated TV Panelists" : null,
      "",
      isMarketLevel ? "" : "Widget Filters"
    ], null), k => data[k] = []);
    forEach(this.selectedSubMarkets, sm => {
      data["Selected Audiences"].push(sm.name);
      data["Chart Market"].push(find(this.mekko.markets, m => m.id === sm.market_id).name);
      data["Identifier"].push(sm.tag);
      const identifier = countType === "Matched" || this.isTier3 ? sm.matched_short_id : sm.modeled_short_id;
      if (!isMarketLevel) {
        data[countType + " Audience"].push(this.getSegmentPath(this.segments[identifier]));
      }
      const subMarketPopulation = isMarketLevel ? sm.population : this.getPersonLevelCount(exportType, identifier);
      data[populationLabel].push(subMarketPopulation);
      if (isPanelistCount) {
        const panelistCount = this.growCounts.barbTransform(subMarketPopulation);
        data["Estimated TV Panelists"].push(this.growCounts.isBelowTvCountMinimum(panelistCount) ? "N/A" : panelistCount);
      }
    });
    return this.insightsExportService.addFiltersToChartDetails(this.filters, isMarketLevel, data, chartData);
  }

  generateJourneyChartDetailsData(exportType: string, countType: string, countLabel: string, isMarketLevel: boolean, filteredChartCount: number) {
    const unfilteredChartCount = isMarketLevel ? sumBy(this.activeJourneySubMarkets, "population") : this.getPersonLevelCount(`unfiltered:${exportType}`, "total_count");
    const populationLabel = "Stage Population";
    const countsForStage = sumBy(this.selectedStages, stage => this.journeyCounts.getCountForStage(stage));
    const chartData = [
      ["Journey Name", this.journey.name],
      ["Index Base", this.indexBase.shortId ? this.getSegmentPath(this.segments[this.indexBase.shortId]) : this.indexBase.name],
      ["Journey " + countLabel, unfilteredChartCount],
      ["Filtered " + countLabel, isMarketLevel ? countsForStage : filteredChartCount],
      [],
    ];
    const data = {};
    forEach(without(["Selected Stages", "Journey Brand", isMarketLevel ? null : countType + " Audience", populationLabel, "", isMarketLevel ? "" : "Widget Filters"], null), k => data[k] = []);
    forEach(this.selectedJourneySubMarkets, sm => {
      data["Selected Stages"].push(find(this.selectedStages, stage => stage.id === sm.journey_stage_id).name);
      data["Journey Brand"].push(find(this.journey.brands, b => b.id === sm.journey_brand_id).name);
      const identifier = countType === "Matched" || this.isTier3 ? sm.matched_id : sm.modeled_id;
      if (!isMarketLevel) {
        data[countType + " Audience"].push(this.getSegmentPath(this.segments[identifier]));
      }
      data[populationLabel].push(isMarketLevel ? sm.population : this.getPersonLevelCount(exportType, journeySubMarketCountKey(sm)));
    });

    return this.insightsExportService.addFiltersToChartDetails(this.filters, isMarketLevel, data, chartData);
  }

  setupTransform(activePersona, segments): ResourceDefinedSegments {
    const groups = { include: [], exclude: [] };
    if (get(activePersona, 'identifiers')) {
      forEach(["include", "exclude"], type => {
        activePersona.identifiers[`${type}d_ids`].map(i => {
          if (Array.isArray(i) && i.length > 1) {
            const ors = [];
            i.map(o => ors.push(this.getSegmentPath(segments[o])));
            groups[type].push(ors);
          } else {
            groups[type].push([this.getSegmentPath(segments[i])]);
          }
        })
      });
    }
    return groups;
  }

  generateExploreChartDetailsData(exportType: string, countType: string, countLabel: string, isMarketLevel: boolean, filteredChartCount: number) {
    const chartData: [(string|number)[]] = [["Persona Name", this.activePersona.name]];

    if (isMarketLevel) {
      if (this.visibleTabsShareThis.length) {
        chartData.push(
          ["ShareThis Browsing Data Last Updated", this.browsingDataExportHelper.getLastUpdate()]
        );
      }
    } else {
      const unfilteredChartCount = this.getPersonLevelCount(`unfiltered:${exportType}`, "total_count");
      const indexBase = this.indexBase.shortId ?
        this.getSegmentPath(this.segments[this.indexBase.shortId]) : this.indexBase.name;

      chartData.push(
        ["Index Base", indexBase],
        ["Total Matched Count", unfilteredChartCount],
        ["Filtered Matched Count", filteredChartCount],
        [],
      );

      if (get(this.activePersona, 'name') !== "All Data") {
        const setup = this.setupTransform(this.activePersona, this.segments);
        const includeExcludeWsReady = groupTransformWS(setup);

        const personaRows = [
          [
            'Persona Definition'
          ],
          ...includeExcludeWsReady,
          [],
        ];

        chartData.push(...personaRows);
      }
    }
    const data = isMarketLevel ? {} : { "Widget Filters": [] }

    return this.insightsExportService.addFiltersToChartDetails(this.filters, isMarketLevel, data, chartData);
  }

  addChartDetailsSheet(chartDetails, tabName?: string) {
    const wb: Workbook = new Workbook();
    const ws = this.insightsExportService.setWorksheet(wb, tabName || "Chart Details");
    forEach(chartDetails, row => {
      const wsRow = ws.addRow(row);
      if (this.insightsContext === "compare" && row === this.overlapExport.axisHeaders) {
        this.insightsExportService.boldRow(wsRow);
      }
      if (tabName === "Persona Details") {
        if (row[0] === "Include" || row[0] === "Exclude") {
          this.insightsExportService.boldRow(wsRow);
        }
      }
    });
    return wb;
  }

  setCanExport() {
    this.canExport = !every(values(this.exportOptions), option => option === false);
  }

  getPercent(marketLevelDemographic: MarketLevelDemographic, bucket: MarketLevelDemographicBucket, selectedSubMarkets) {
    const count = this.getCount(bucket, selectedSubMarkets);
    const isAbsoluteCount = marketLevelDemographic.id_count === 'absolute';

    if (isAbsoluteCount) {
      const maxCount = max(marketLevelDemographic.market_level_demographic_buckets.map((b) => this.getCount(b, selectedSubMarkets)));
      return Math.round((count / maxCount) * 1e2) / 1e2
    } else if (this.insightsContext === 'explore') {
      const totalCount = sumBy(marketLevelDemographic.market_level_demographic_buckets.map((b) => this.getCount(b, selectedSubMarkets)));
      return Math.round((count / totalCount) * 1e2) / 1e2
    } else {
      return Math.round((count / sumBy(selectedSubMarkets, "population")) * 1e2) / 1e2
    }
  }

  getCount(bucket: MarketLevelDemographicBucket, selectSelectedSubMarkets) {
    return sumBy(_map(selectSelectedSubMarkets, sm => {
      return bucket.sub_market_bucket_entries[sm.id];
    }) as SubMarketBucketEntry[], smbe => smbe.value || 0);
  }

  getShortIds(demographics) {
    return flatMap(demographics, demo => _map(demo.buckets, "short_id"));
  }

  getSegmentPath(segment: SegmentLike): string {
    if (!segment) { return; }
    return prettyPathParts(segment, this.vendorDisplayNames).join(" > ");
  }

  getIdentifiers(subMarkets) {
    return concat(compact(_map(subMarkets, `matched_${this.insightsContext === "grow" ? 'short_' : ''}id`)),
      compact(_map(subMarkets, `modeled_${this.insightsContext === "grow" ? 'short_' : ''}id`)));
  }

  get filterIdentifiers() {
    return _map(values(groupBy(this.filters, "demographicId")), filterGroup => _map(filterGroup, "shortId"))
  }

  getPanelistCount(subMarkets: SubMarket[], exportType: string): "N/A" | number {
    const totalPanelistCount = this.growCounts.barbTransform(sumBy(subMarkets, subMarket => this.growCounts.subMarketCount(subMarket, exportType as GrowTabState, false)));
    return this.growCounts.isBelowTvCountMinimum(totalPanelistCount) ? "N/A" : totalPanelistCount;
  }

  getPersonLevelCount(exportOption: string, identifier: string): number {
    return get(this.counts, [exportOption, identifier], 0)
  }

  getSegmentTypeByIdentifier(identifier: string) {
    const segment = get(this.segments, identifier);
    return getVendorTypeAggregationKey(segment);
  }

  getVendorTypesForIdentifiers(identifiers: string[]) {
    return chain(identifiers)
      .map(identifier => this.segments[identifier])
      .filter(Boolean)
      .map(segment => getVendorTypeAggregationKey(segment))
      .uniq()
      .value();
  }

  hasIndex(exportType: string, identifier: string): boolean {
    const index = this.getIndex(exportType, identifier);
    return index != null && !Number.isNaN(index) && Number.isFinite(index);
  }

  getIndex(exportType: string, identifier: string): number {
    const segment = get(this.segments, identifier);
    if (!segment) { return null; }
    const vendorType = getVendorTypeAggregationKey(segment);
    if (isMolecula()) {
      // Molecula: API service calculates and returns index value
      const segmentIndexValue = this.getPersonLevelCount(`${INDEX_VALUES}:${exportType}`, identifier);
      return segmentIndexValue;
    }
    const segmentCountInContext = this.getPersonLevelCount(exportType, identifier);
    const vendorCountInContext = this.getPersonLevelCount(exportType, vendorType)
    if (segmentCountInContext == null) { return null; }
    const segmentCountAgainstContextTypes = this.getPersonLevelCount(`context-vendor-types:${exportType}`, identifier);
    const segmentTypeAgainstContextTypes = this.getPersonLevelCount(`context-vendor-types:${exportType}`, vendorType);
    return ((segmentCountInContext / vendorCountInContext) / (segmentCountAgainstContextTypes / segmentTypeAgainstContextTypes)) * 100;
  }

  fetchForContexts(exportOptions: string[]): Observable<{[queryKey: string]: {[segmentIdentifier: string]: number}}> {
    this.loading$.next(true);
    if (!exportOptions.length) { return observableOf(null); }
    let context, unfilteredContext, query, aggregations, newQueries, unfilteredQuery;
    const queries = reduce(exportOptions, (queries, exportOption) => {
      if (this.insightsContext === "grow") {
        context = buildPrimaryContext(this.selectedSubMarkets, exportOption as GrowTabState, this.activeSubMarkets);
        unfilteredContext = buildPrimaryContext(this.activeSubMarkets, exportOption as GrowTabState);
        aggregations = buildSubMarketCountAggregations(this.activeSubMarkets, exportOption as GrowTabState);
      } else if (this.insightsContext === "journey") {
        const exportType = exportOption.split(" ")[0];
        context = get(buildSegmentContextsFromJourney(this.journey, this.activeBrand, this.selectedStages, exportType as JourneyTabType), "primary");
        unfilteredContext = get(buildSegmentContextsFromJourney(this.journey, this.activeBrand, this.activeStages, exportType as JourneyTabType), "primary");
      } else if (this.insightsContext === "compare") {
        const segmentContext = this.overlapExport.context();
        const unfilteredSegmentContext = this.overlapExport.context(false);
        context = get(segmentContext, "primary");
        unfilteredContext = get(unfilteredSegmentContext, "primary");
      } else if (this.insightsContext === "explore") {
        const segmentContext = buildSegmentContext(this.vendors, this.filters, this.activePersona);
        const unfilteredSegmentContext = buildSegmentContext(this.vendors, [], this.activePersona);
        context = get(segmentContext, "primary");
        unfilteredContext = get(unfilteredSegmentContext, "primary");
      }
      const indexMode = this.includeIndexes;
      if (isMolecula()) {
        const key = exportOption;
        query = buildMoleculaContext(
          context,
          this.allPersonLevelDemographicIds,
          this.filterIdentifiers,
          [],
          indexMode,
          this.segments,
          this.insightsCounts.modelingUniverseProviders,
          this.insightsContext,
          key
        )

        const unfilteredKey = `unfiltered:${key}`;
        unfilteredQuery = buildMoleculaContext(unfilteredContext, [], [], [], false, this.segments, this.insightsCounts.modelingUniverseProviders, this.insightsContext, unfilteredKey);
        newQueries = { ...queries, [key]: query, [unfilteredKey]: { ...unfilteredQuery, key: unfilteredKey } };
        if (this.insightsContext === 'journey') {
          newQueries = mapValues(newQueries, (query, queryKey) => {
            if (queryKey.includes(INDEX_VALUES)) {
              return query;
            }
            return {
              ...query,
              rootKey: queryKey,
              contexts: query.contexts.map(context => ({ ...context, allowIdentifiers: true }))
            }
          });
        }
        if (indexMode) {
          const indexKey = `${INDEX_VALUES}:${exportOption}`;
          newQueries[indexKey] = buildMoleculaIndexContext(query, this.insightsContext);
          if (this.insightsContext === 'journey') {
            newQueries[indexKey] = {
              ...newQueries[indexKey],
              rootKey: exportOption,
              contexts: query.contexts.map((context, i) => ({ ...context, allowIdentifiers:  i === 0 }))
            }
          };
        }
        return newQueries;
      }

      query = buildQueryFromContext(context,
        this.allPersonLevelDemographicIds,
        this.filterIdentifiers,
        [],
        aggregations,
        this.includeIndexes,
        this.segments,
        this.insightsCounts.modelingUniverseProviders);

      unfilteredQuery = buildQueryFromContext(unfilteredContext, [], [], [], {}, false, this.segments, this.insightsCounts.modelingUniverseProviders);
      newQueries = {...queries, [exportOption]: query, [`unfiltered:${exportOption}`]: unfilteredQuery};

      if (this.includeIndexes) {
        newQueries[`context-vendor-types:${exportOption}`] = buildIndexingVendorTypesQuery(context,
          this.allPersonLevelDemographicIds,
          this.segments,
          this.filterIdentifiers,
          this.insightsCounts.modelingUniverseProviders,
          this.indexBase);
      }
      return newQueries;
    }, {})

    return this.insightsCounts.fetchInsights(queries)
  }

  getVisibleCustomDemographics(demographics, widgetConfig) {
    return _filter(demographics, demographic => {
      const config = widgetConfig[userPreferenceKeys.customTab(demographic.custom_tab_id)];
      return get(find(config, {id: demographic.id}), 'visible', true);
    });
  }

  exportOptionsHas(option) {
    return keys(this.exportOptions).includes(option);
  }
}
