import {PermissionOption} from "./permission-option.model";

export class ClientRegionRules {
  static readonly ALL = 'all';

  client: string;
  region: string;
  permissions: PermissionOption[];
  isDefault: boolean;

  persisted: boolean;

  // used for sorting
  clientDisplay?: string;
  regionDisplay?: string;

  constructor(permissions: PermissionOption[], client: string, region: string) {
    this.client = client;
    this.region = region;
    this.permissions = permissions;
    this.isDefault = ClientRegionRules.isDefault(client, region);
  }

  get valid(): boolean {
    return true;
  }

  get filled(): boolean {
    const clientRegionFilled = this.client != void(0) && this.region != void(0);
    return clientRegionFilled && this.permissions.every( p => p.value != void(0) );
  }

  public getOption(permission: string): PermissionOption {
    return this.permissions.find(p => p.permission == permission);
  }

  static  isDefault(client_id: string, region_id: string): boolean {
    return client_id === ClientRegionRules.ALL && region_id == ClientRegionRules.ALL;
  }

}
