import { Component, OnInit, Input, ViewChildren, QueryList, AfterViewInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AppState } from 'app/reducers';
import { Store } from '@ngrx/store';
import { AudienceRuleGroup, AudienceBooleanType } from 'app/audience-builder/audience-builder.models';
import { SegmentV2, prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { CdkDragEnter, CdkDropList, CdkDragDrop, transferArrayItem, CdkDragStart, moveItemInArray, CdkDrag } from '@angular/cdk/drag-drop';
import { PpcDragService } from 'app/shared/drag/ppc-drag.service';
import { asapScheduler } from 'rxjs';
import { difference, cloneDeep, set, get } from 'lodash';
import { AudienceBuilderService } from 'app/audience-builder/audience-builder.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getSegmentCount, isEntrySegment } from '../../audience-builder.utils';
import { CustomCreateService } from '../../custom-create.service';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';

@UntilDestroy()
@Component({
  selector: 'ppc-audience-builder-group',
  templateUrl: './audience-builder-group.component.html',
  styleUrls: ['./audience-builder-group.component.sass']
})
export class AudienceBuilderGroupComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(CdkDropList) dropLists: QueryList<CdkDropList>;
  @Input() ruleGroup: AudienceRuleGroup;
  @Input() nestLevel = 0;
  @Input() parent: AudienceRuleGroup;
  @Input() path; // the unique path to to this group from the root audience object
  @Output() delete = new EventEmitter();
  vendorDisplayNames: VendorDisplayName[];
  isEntrySegment = isEntrySegment;
  getSegmentCount = getSegmentCount;

  constructor(public dragService: PpcDragService,
    private audienceBuilderService: AudienceBuilderService,
    private createService: CustomCreateService,
    private store: Store<AppState>) {
    store.select('segmentsHierarchy', 'vendorDisplayNames').pipe(
      untilDestroyed(this)
    ).subscribe(vendorDisplayNames => this.vendorDisplayNames = vendorDisplayNames);
  }

  ngOnInit() {}

  ngOnDestroy() { }

  ngAfterViewInit() {
    this.dragService.dropListsWithLevels$.next(this.dropLists.toArray().map(dl => ({level: this.nestLevel, dl})));
    this.dropLists.changes.pipe(untilDestroyed(this))
      .subscribe(
        dropLists => {
          this.dragService.dropListsWithLevels$.next(dropLists.toArray().map(dl => ({level: this.nestLevel, dl})));
        }
      )
  }

  getSegmentPath(segment: SegmentV2) {
    return prettyPathParts(segment, this.vendorDisplayNames).join(" > ");
  }

  dropListEntered(event: CdkDragEnter) {
    this.dragService.hoveredDropListWidth$.next(event.container.element.nativeElement.clientWidth);
  }

  dropListDropped(event: CdkDragDrop<any, any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
    }
    this.audienceBuilderService.checkChanges();
  }

  ruleGroupDropped(event: CdkDragDrop<any>) {
    // reordered inside same group
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex)
    }
  }

  dropListMouseEnter(dropList: CdkDropList) {
    this.dragService.hoveredDropListWidth$.next(dropList.element.nativeElement.clientWidth);
  }

  dragEntered(event: CdkDragEnter) {
    this.enforceDragToSelf(event.container);
  }

  dragStarted(event: CdkDragStart) {
    this.enforceDragToSelf(event.source.dropContainer);
  }

  remove(idx) {
    this.ruleGroup.items.splice(idx, 1);
    this.audienceBuilderService.checkChanges();
  }

  changeType(group: AudienceRuleGroup, type: AudienceBooleanType) {
    group.type = type;
    this.audienceBuilderService.checkChanges();
  }

  getOtherBooleanTypesForGroup(group: AudienceRuleGroup) {
    if (!this.createService.groupTypeChangeAllowed) {return []; }
    return difference(["and", "or", "not"], [group.type])
  }

  isGroupDeleteAllowed(groupType: string) {
    return this.createService.deletableGroupTypes.includes(groupType);
  }

  isNodeAllowed(node): boolean {
    const clone = set(cloneDeep(this.audienceBuilderService.audience), `${this.path}.items.${this.ruleGroup.items.length}`, node);
    return this.createService.isAudienceValid(clone);
  }

  isDropAllowed(drag: CdkDrag, drop: CdkDropList): boolean {
    const newGroup = drag.data;
    if (newGroup?.length > 1) {
      return newGroup.every((node) => this.isNodeAllowed(node));
    } else {
      return this.isNodeAllowed(newGroup);
    }
  }

  // Ensures that we're dragging to the innermost nested group and not its parent
  protected enforceDragToSelf(dl: CdkDropList) {
    const siblings  = dl.connectedTo as CdkDropList<any>[];

    const ref =  dl._dropListRef;
    asapScheduler.schedule(() => {
      ref.connectedTo(siblings.map(list => list._dropListRef));
    });
  }

}
