<div class="edit-pane" *ngIf="demographic">
  <div class="edit-pane-header">
    <div class="edit-pane-header-text">
      <div class="edit-pane-title">{{demographic.id ? "Edit" : "Create New"}} Widget</div>
    </div>
  </div>

  <ng-container *ngIf="demographic.is_standard">
    <div class="edit-pane-section">
      <h3 class="edit-pane-section-header">{{demographic.name}}</h3>
    </div>
    <div class="edit-pane-section">
      <ppc-textarea [(ngModel)]="demographic.description"><label>Description</label></ppc-textarea>
    </div>

    <div class="error" *ngIf="isBarVerticalOutOfLimits">
      {{errorMessages.BarChartVerticalErrorMessage}}
    </div>
    <div class="error" *ngIf="isRadarOutOfLimits">
      {{errorMessages.RadarChartLimits}}
    </div>
  </ng-container>

  <ng-container *ngIf="!demographic.is_standard">
    <div class="edit-pane-section">
      <ppc-input [(ngModel)]="demographic.name" [validators]="nameValidators" [forceValidation]="true">
        <label>Name</label>
      </ppc-input>
    </div>
    <div class="edit-pane-section">
      <ppc-textarea [(ngModel)]="demographic.description"><label>Description</label></ppc-textarea>
    </div>
    <div class="edit-pane-section">
      <div class="edit-pane-section-header">Select Segment(s)</div>
      <div class="widget-form-error-container" *ngIf="!demographic.buckets.length">
        <div>Please select a segment for this widget</div>
      </div>
      <div class="layout horizontal">
        <div class="edit-pane-section-instructions">Reorder and/or rename your selected segments below.</div>
        <button class="btn select-segments-btn" (click)="openSegmentPicker()">SELECT</button>
      </div>
      <div class="axis-form-header">
        <div class="node-data-column-header">Segment Name</div>
        <div class="node-details" [class.people-count]="!isTier3" *ngIf="demographic.buckets.length">
          <div class="node-data-column">
            <div class="node-data-column-header" [ppcTooltip]="renderTooltip()">
              {{isTier3 ? "Matched" : "People Count"}}
            </div>
          </div>
          <div class="node-data-column" *ngIf="isTier3">
            <div class="node-data-column-header" ppcTooltip="Modeled weights on panel IDs">Modeled</div>
          </div>
        </div>
      </div>

      <div class="edit-pane-list-section no-error-containers">
        <div class="edit-pane-list-section-items" [dragula]="bagName" [dragulaModel]="demographic.buckets">
          <div *ngFor="let bucket of demographic.buckets" class="edit-pane-list-section-item">
            <i class="fa fa-bars drag-handle"></i>
            <ppc-input [(ngModel)]="bucket.name" [noErrorContainer]="true">
              <label>{{getPathStringByShortId(bucket.short_id)}}</label>
            </ppc-input>
            <div class="node-details">
              <div class="node-data-column">
                <div class="node-data-column-number" *ngIf="(getSegmentCount(bucket, 'matched') | number); let segmentCount">
                  <ppc-zero-count-warning-icon
                    *ngIf="segmentCount == 0"
                    ppcTooltip="The attribute has a zero count. Select another attribute to replace it."
                    ></ppc-zero-count-warning-icon>
                  {{ segmentCount }}
                </div>
              </div>
              <div class="node-data-column" *ngIf="isTier3">
                <div class="node-data-column-number" *ngIf="(getSegmentCount(bucket, 'modeled') | number); let segmentCount">
                  <ppc-zero-count-warning-icon
                    *ngIf="segmentCount == 0"
                    ppcTooltip="The attribute has a zero count. Select another attribute to replace it."
                    ></ppc-zero-count-warning-icon>
                  {{ segmentCount }}
                </div>
              </div>
            </div>
            <img class="remove-button" src="assets/icons/close.svg" (click)="removeBucket(bucket)"/>
          </div>
        </div>
      </div>

      <div class="error" *ngIf="isBarVerticalOutOfLimits && attributeCountInTab <= tabBucketLimit">
        {{errorMessages.BarChartVerticalLimits}}
      </div>
      <div class="error" *ngIf="isDoughnutOutOfLimits && attributeCountInTab <= tabBucketLimit">
        No more than {{ maxAttributeLimit }} attributes can be part of any widget with graph type: Pie Chart
      </div>
      <div class="error" *ngIf="attributeCountInTab > tabBucketLimit">
        You cannot assign more than {{ tabBucketLimit }} attributes to a single {{ contextTitle() | titlecase }} widget tab.
        Please select fewer attributes.
      </div>
      <div class="error" *ngIf="isRadarOutOfLimits && attributeCountInTab <= tabBucketLimit">
        {{errorMessages.RadarChartLimits}}
      </div>
    </div>

  </ng-container>

  <div class="edit-pane-section">
    <div class="edit-pane-section-header">Graph Type</div>
    <div class="error" *ngIf="isCompareMode$ | async">Cannot change while in compare mode</div>
    <div class="error" *ngIf="indexMode$ | async">Cannot change while in index mode</div>
    <div ppcRadioGroup [(ngModel)]="demographic.widget_type" [disabled]="(indexMode$ | async) || (isCompareMode$ | async)">
      <ppc-radio-button value="{{chartTypes.BarHorizontal}}">
        Bar (Horizontal)
      </ppc-radio-button>
      <ppc-radio-button value="{{chartTypes.BarVertical}}">
        Bar (Vertical)
      </ppc-radio-button>
      <ppc-radio-button value="{{chartTypes.Doughnut}}">
        Pie Chart
      </ppc-radio-button>
      <ppc-radio-button *ngIf="radarChartAccess | hasPermission | async" value="{{chartTypes.Radar}}" >
        Radar Chart
      </ppc-radio-button>
    </div>
  </div>

  <div class="edit-pane-section">
    <div class="edit-pane-section-header">Metric</div>
    <div ppcRadioGroup [(ngModel)]="demographic.is_id_count">
      <ppc-radio-button [value]="false">
        Percentage
      </ppc-radio-button>
      <ppc-radio-button [value]="true">
        ID Count
      </ppc-radio-button>
    </div>
  </div>
  <div class="edit-pane-section-note"
    *ngIf="demographic?.id && demographic?.data_provider"
    >
    <span class="edit-pane-section-note-label">Data Source</span>: Modeled from {{ demographic.data_provider }}
  </div>
  <div class="edit-pane-actions">
    <button class="btn btn-save" (click)="upsertDemographic()" [disabled]="!isValid">SAVE</button>
    <button mat-button (click)="cancelEdit()">Cancel</button>
  </div>
</div>
