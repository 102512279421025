<div class="media-type-table-container">
  <table class="media-type-table">

    <tr>
      <td>Media Types</td>
      <td *ngIf="!hideMinMax">Media Allocation</td>
    </tr>

    <tr>
      <td>
        <!-- put validation messages on the left if min max is hidden -->
        <ng-container  *ngIf="hideMinMax">
          <div class="validation-message" *ngFor="let validationMessage of globalValidationMessages">{{validationMessage}}</div>
        </ng-container>
      </td>
      <td align="right" *ngIf="!hideMinMax">
          <label class="pointer" >
            {{currencySymbol}}
            <mat-slide-toggle
              name="unitToggle"
              [(ngModel)]="isPercent"
              color="red"
              (change)="toggleGoalType()"
            >
              %
            </mat-slide-toggle>
          </label>
      <div class="validation-message" *ngFor="let validationMessage of globalValidationMessages">{{validationMessage}}</div>
      </td>
    </tr>

      <tr *ngFor="let mediaType of mediaTypes">
        <td>
          <mat-checkbox
            [name]="mediaType.name"
            [(ngModel)]="mediaType.selected"
            color="primary"
            (change)="validate(mediaType)"
          >
            {{mediaType.name}}
          </mat-checkbox>
        </td>
        <td>
          <div [className]="'min-max-inputs' + (mediaType.selected ? '' :  ' disabled')" *ngIf="!hideMinMax">
            <label>
              Min


              <mat-form-field>
                <span *ngIf="!isPercent" matPrefix>{{currencySymbol}}</span>
                <input matInput
                       name="mediaType.name + 'min'"
                       [(ngModel)]="mediaType.min"
                       [value]="mediaType.min | number:'.0-2'"
                       type="number"
                       (change)="validate(mediaType)"
                       [disabled]="!mediaType.selected"
                       step=".01"
                       required>
                <span *ngIf="isPercent" matSuffix>%</span>
              </mat-form-field>

            </label>
            <label>
              Max
              <mat-form-field>
                <span *ngIf="!isPercent" matPrefix>{{currencySymbol}}</span>
                <input matInput
                       name="mediaType.name + 'max'"
                       [(ngModel)]="mediaType.max"
                       type="number"
                       (change)="validate(mediaType)"
                       [disabled]="!mediaType.selected"
                       step=".01"
                       required
                >
                <span *ngIf="isPercent" matSuffix>%</span>
              </mat-form-field>

            </label>
          </div>
          <div class="validation-message input" *ngFor="let validationMessage of mediaType.validationMessages">
            {{validationMessage}}
          </div>
        </td>
      </tr>

  </table>
  <div class="no-data" *ngIf="!mediaTypes || mediaTypes.length < 1">
    No curves available
  </div>
</div>
