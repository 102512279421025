import {Component, Input} from '@angular/core';
import {MediaTypeGoal} from "./media-type-goal";

@Component({
  selector: 'media-type-table',
  templateUrl: './media-type-table.component.html',
  styleUrls: ['./media-type-table.component.sass']
})
export class MediaTypeTableComponent {
  @Input() mediaTypes: MediaTypeGoal[] = [];
  @Input() budget: number = 0;
  @Input() currencySymbol: string = "$";
  @Input() hideMinMax: boolean = false;
  public isPercent: boolean = true;
  globalValidationMessages = [];

  toggleGoalType() {
    this.resetGoals();
    this.validateAll();
  }

  public validate(mediaTypeGoal: MediaTypeGoal) {
    if ( mediaTypeGoal.max != null ) {
      mediaTypeGoal.max = parseFloat(mediaTypeGoal.max.toFixed(2));
    }
    if ( mediaTypeGoal.min != null ) {
      mediaTypeGoal.min = parseFloat(mediaTypeGoal.min.toFixed(2));
    }
    if (!this.hideMinMax) {
      mediaTypeGoal.validate(this.isPercent, this.budget);
    }
  }

  public validateAll() {
    this.mediaTypes.forEach( mtg => this.validate(mtg) );
  }

  public isValid(): boolean {
    if (!this.hideMinMax) {
      const allValidate = this.mediaTypes.reduce((result, mtg) => result && mtg.validate(this.isPercent, this.budget), true);
      this.globalValidationMessages = this.globalValidations(allValidate);
      return allValidate && this.globalValidationMessages.length === 0;
    } else {
      const anySelected = MediaTypeGoal.validateTotalSelections(this.mediaTypes);
      anySelected || (this.globalValidationMessages = [MediaTypeGoal.VALIDATION_MESSAGES.nothing_selected]);
      return anySelected;
    }
  }

  public resetGoals() {
    const goal = this.budget || 0;
    const max = this.isPercent ? 100 : goal;

    this.mediaTypes.forEach(goal => {
      goal.min = 0;
      goal.max = max;
    })
  }

  private globalValidations(allValidate: boolean): string[] {
    return allValidate ? MediaTypeGoal.validateTotals(this.mediaTypes, this.isPercent, this.budget) : [];
  }

}
