import {Component, OnDestroy, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";

import {HierarchyService} from "../services/hierarchy.service";
import {SlideInOverlayComponent} from "../shared/components/slide-in-overlay/slide-in-overlay.component";
import {ChannelsService} from "../plans/channels.service";
import {Channel} from "../plans/tardiis-channel.model";
import {PlanService} from "../plans/plan.service";
import {Plan} from "../plans/plan.model";

@Component({
  selector: 'app-channels-summary',
  templateUrl: './channels-summary.component.html',
  styleUrls: ['./channels-summary.component.sass']
})
export class ChannelsSummaryComponent implements OnDestroy {

  channels: Channel[] = [];
  channelsReceived: boolean = false;
  searchQuery: string;
  selectedChannel: any;
  showImportDialog: boolean;
  tardiisProjectId: string;
  hierarchySub: Subscription;
  productId: string;
  currency: string = "gbp";
  tardiisErrorsByChannelId: {[channelId: string]: any} = {};
  importError;
  errorMessage: string = "Something went wrong, please try again.";
  error: {isError: boolean, errorMessage: string, hasButton: boolean, buttonTitle?: string, buttonFunction?: () => void}
  = {isError: false, errorMessage: this.errorMessage, hasButton: false};
  errorsByChannelId: {[channelId: string]: {errorMessage: string}} = {};

  attachedPlanIds: {[channelId: string]: string} = {};
  loading: boolean = false;

  @ViewChild('overlay', { static: true }) private overlay: SlideInOverlayComponent;

  constructor(
    private channelsService: ChannelsService,
    private hierarchyService: HierarchyService,
    private planService: PlanService
  ) {
    this.hierarchySub = this.hierarchyService.fullContext$.subscribe(
      ({client, region, product}) => {
        this.productId = product.id;
        this.currency = region.currency;

        this.getChannels()
      },
      console.error
    );
  }

  ngOnDestroy() {
    this.hierarchySub.unsubscribe()
  }

  getChannels() {
    this.loading = true;
    return this.channelsService.getChannels()
      .subscribe(
        channels => {
          this.channels = channels;
          this.channelsReceived = true;
          this.getAttachedPlanIds();
        },
        err => {
          console.error(err);
          this.error.isError = true;
          this.error.hasButton = true;
          this.error.buttonTitle = "RELOAD";
          this.error.buttonFunction = () => location.reload();
        },
        () => {
          this.loading = false;
        }
      );
  }

  getAttachedPlanIds() {
    this.planService.getProductPlans()
      .subscribe(
        (plans: Plan[]) => {
          const attachedPlanMap = {};
          plans
            .filter(plan => plan.channelId != void(0))
            .map(plan => ({channelId: plan.channelId, planId: plan._id}))
            .forEach(attachedPlanId => attachedPlanMap[attachedPlanId.channelId] = attachedPlanId.planId);

          this.attachedPlanIds = attachedPlanMap;
        }
      )
  }

  getFilteredChannels() {
    if (!this.searchQuery) {return this.channels; }

    const lowerCaseQuery = this.searchQuery.toLowerCase();
    return this.channels.filter(c => c.name && c.name.toLowerCase().includes(lowerCaseQuery))
  }

  onEditClick(id) {
    this.selectedChannel = this.channels.find(x => x._id == id);
    if (this.selectedChannel) {
      this.showImportDialog = false;
      this.overlay.toggleState();
    }
  }

  onDeleteClick(id) {
    this.channelsService.deleteChannel(id).subscribe(result => {
      delete this.errorsByChannelId[id];
      this.getChannels();
    },
    error => {
      this.errorsByChannelId[id] = {
        errorMessage: this.errorMessage
      };
    });
  }

  onEditClose() {
    this.overlay.toggleState();
  }

  openImportChannelDialog() {
    this.showImportDialog = true
  }

  closeImportChannelDialog() {
    this.importError = null;
    this.tardiisProjectId = null;
    this.showImportDialog = false
  }

  importProject() {
    this.importError = null;

    this.channelsService.importProject(this.tardiisProjectId)
      .subscribe(
        _ => {
          this.closeImportChannelDialog();
          this.getChannels();
        },
        error => {
          this.importError = error;
        }
      )
  }

  handleTardiisError({error, channel}) {
    this.tardiisErrorsByChannelId[channel._id] = error;
  }

  handleUpdate(channel) {
    this.tardiisErrorsByChannelId[channel._id] = null;
    this.getChannels()
  }

  updateChannel(channel) {
    const channelIndex = this.channels.findIndex(x => x._id === channel._id);
    if (channelIndex > -1) {
      this.channels[channelIndex] = channel
    }
  }

  onErrorButtonClick() {
    this.error.buttonFunction && this.error.buttonFunction();
  }

}
