import {Action} from "@ngrx/store";
import {AudienceHierarchy} from "../shared/segments/audience_hierarchy.interface";

export class FetchAudienceHierarchies implements Action {
  static readonly type = 'FETCH_AUDIENCE_HIERARCHIES';
  readonly type = FetchAudienceHierarchies.type;

  constructor(public productId: string) {}
}

export class LoadAudienceHierarchies implements Action {
  static readonly type = 'LOAD_AUDIENCE_HIERARCHIES';
  readonly type = LoadAudienceHierarchies.type;

  constructor(public audienceHierarchies: AudienceHierarchy[]) {}
}

export class ClearAudienceHierarchies implements Action {
  static readonly type = 'CLEAR_AUDIENCE_HIERARCHIES';
  readonly type = ClearAudienceHierarchies.type;
}

export type All = FetchAudienceHierarchies | LoadAudienceHierarchies | ClearAudienceHierarchies
