import {
  Component,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { isDV360, Partner } from 'app/models/partners/partner.model';

@Component({
  selector: 'activation-tile',
  templateUrl: './activation-tile.component.html',
  styleUrls: ['./activation-tile.component.sass'],
})

export class ActivationTile implements OnChanges {

  @Input() partner: Partner;
  @Output() toggleOverlayEmitter = new EventEmitter;
  @Output() partnerSelection = new EventEmitter;
  @Output() addDestinationEvent = new EventEmitter;

  buttonConfirmText = 'Send';
  logoLetter: string;

  logoMap = {
    dv360: 'assets/images/audiences/activation/logo-display-video-360.png',
    conversant: 'assets/images/audiences/activation/logo-conversant.png',
    tradeDesk: 'assets/images/audiences/activation/logo-TradeDesk.png',
  };

  constructor() { }

  ngOnChanges(changes) {
    if (changes.partner) {
      switch (changes.partner.currentValue.name) {
        case "Conversant": {
          this.partner.logo_url = this.logoMap.conversant;
          break;
        }
        case "The Trade Desk": {
          this.partner.logo_url = this.logoMap.tradeDesk;
          break;
        }
        default:
          if (isDV360(this.partner)) {
            this.partner.logo_url = this.logoMap.dv360;
          } else {
            this.logoLetter = this.defaultLogo();
          }
          break;
      }
    }
  }

  get statusColorClass(): string {
    let color = 'yellow-color-band';
    if (this.partner.destination_status_color) {
      color = `${this.partner.destination_status_color}-color-band`
    }
    if (this.partner.removed) {
      color = 'gray-color-band';
    }
    return color;
  }

  defaultLogo() {
    return this.partner.name.slice(0, 1)
  }

  sendToPartner(partner) {
    this.partnerSelection.emit(partner);
    this.toggleOverlay()
  }

  addDestination(partner) {
    this.addDestinationEvent.emit(partner);
    this.toggleOverlay()
  }

  toggleOverlay() {
    this.toggleOverlayEmitter.emit();
  }
}

