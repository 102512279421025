import { Component, OnChanges, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {Store, select} from "@ngrx/store";
import {every, values, get} from 'lodash';
import {ContextSlugs} from "app/hierarchy/hierarchy.interface";
import { ActivationOverlayService } from '../activation-overlay/activation-overlay.service';
import {AppState} from "app/reducers/index";
import { MatSnackBar } from '@angular/material/snack-bar';
import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import { AudienceV2 } from 'app/audiences-v2/audience-v2.model';
import { Partner, isTradeDesk, isDV360 } from 'app/models/partners/partner.model';
import { TTDDestinationSpecifics, DV360DestinationSpecifics } from 'app/models/destinations/destination.model'
import {Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ppc-new-destination-overlay',
  templateUrl: './new-destination-overlay.component.html',
  styleUrls: ['./new-destination-overlay.component.sass']
})
export class NewDestinationOverlayComponent implements OnChanges, OnInit {
  @Output() onClose = new EventEmitter();
  @Output() openDestinationsWithPartner = new EventEmitter<any>();

  @Input() ppcObject: (LookalikeV3 | AudienceV2);
  @Input() partner: Partner;

  @ViewChild('destinationForm') destinationForm: NgForm;

  isTradeDesk: boolean;
  isDV360: boolean;

  ngUnsubscribe: Subject<boolean> = new Subject();

  destName: string = "";
  partnerSpecifics: TTDDestinationSpecifics | DV360DestinationSpecifics;

  contextSlugs: ContextSlugs = {
    clientSlug: 'all',
    regionSlug: 'all',
    brandSlug: 'all',
    productSlug: 'all'
  };

  errors: Array<string>;

  constructor(
    private activationService: ActivationOverlayService,
    private store: Store<AppState>,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select('hierarchy', 'contextSlugs'), takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.contextSlugs = res
    });
  }

  ngOnChanges(): void {
    this.isTradeDesk = isTradeDesk(this.partner)
    this.isDV360 = isDV360(this.partner)

    if (this.isTradeDesk) {
      this.partnerSpecifics = {
        ttd_partner_id: ''
      }
    } else if (this.isDV360) {
      this.partnerSpecifics = {
        entity_id: '',
        product: '',
        whitelist_confirmation: false
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  reset() {
    this.destinationForm.resetForm();
    if (this.isDV360) {
      this.partnerSpecifics['whitelist_confirmation'] = false;
    }
  }

  isFormValid(): boolean {
    if (get(this.destName, ["length"]) === 0) {
      return false
    }

    return every(values(this.partnerSpecifics),
      v => typeof v === 'string' ? (v as string).length
        : true)
  }

  createDestination(): void {
    const params = {
      partner_id: this.partner.id,
      name: this.destName,
      partner_specifics: this.partnerSpecifics,
      product_permission: {client: this.contextSlugs.clientSlug,
        brand: this.contextSlugs.brandSlug,
        region: this.contextSlugs.regionSlug,
        product: this.contextSlugs.productSlug}
    };
    this.activationService.createDestination(params).subscribe(
      () => {
        this.onClose.emit({partner: this.partner});
        this.openDestinationsWithPartner.emit({partner: this.partner,
          ppcObject: this.ppcObject});
        this.snackbar.open(`Destination ${params.name} was created successfully`,
          null,
          { duration: 5000, panelClass: 'check' });
      },
      error => {
        this.errors = error.error.error.message.split(':')[1].split(", ")
      }
    );
  }

}
