import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { DragulaModule } from 'ng2-dragula';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgLetModule } from 'ng-let';

import { ComparisonsComponent } from 'app/comparisons/comparisons.component';
import { ComparisonChartComponent } from './comparison-chart/comparison-chart.component';
import { ComparisonsService } from 'app/comparisons/comparisons.service';
import { ComparisonChartCellDisplayComponent } from './comparison-chart/comparison-chart-cell-display/comparison-chart-cell-display.component';
import { SegmentPickerModule } from 'app/segment-picker/segment-picker.module';
import { InsightsModule } from 'app/insights/insights.module';
import { AxisEditFormComponent } from './axis-edit-form/axis-edit-form.component';
import { EpcComponentsModule } from 'app/modules/epc-components.module';

@NgModule({
  imports: [
    CommonModule,
    DragulaModule,
    HttpClientModule,
    PpcComponentsModule,
    RouterModule,
    SegmentPickerModule,
    NgLetModule,
    InsightsModule,
    EpcComponentsModule
  ],
  declarations: [
    ComparisonsComponent,
    ComparisonChartComponent,
    ComparisonChartCellDisplayComponent,
    AxisEditFormComponent
  ],
  exports: [
    ComparisonsComponent
  ],
  providers: [
    ComparisonsService
  ]
})
export class ComparisonsModule { }
