import { Component, ElementRef, Input, ViewChild, AfterViewChecked } from '@angular/core';
import { toMillions } from "app/insights/insights.models";
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import * as actions from 'app/comparisons/comparisons.actions';
import { isBulkFetchInFlight } from '../../../shared/utils/bulk-fetch-state';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ppc-comparison-chart-cell-display',
  templateUrl: './comparison-chart-cell-display.component.html',
  styleUrls: ['./comparison-chart-cell-display.component.sass']
})
export class ComparisonChartCellDisplayComponent implements AfterViewChecked {
  @ViewChild("content") element: ElementRef;
  @Input() percent: number;
  @Input() count: number;
  @Input() index: number;
  @Input() hideCount: boolean;
  @Input() isIndex: boolean;
  fetchingCounts$ = this.store.select("bulkFetchStates", actions.FetchComparisonCounts.type)
    .pipe(select(isBulkFetchInFlight));
  hasOverflow$ = new BehaviorSubject(false);

  constructor(private store: Store<AppState>) { }

  ngAfterViewChecked() {
    const {clientWidth, clientHeight, scrollHeight, scrollWidth } = this.element.nativeElement;
    this.hasOverflow$.next((clientWidth < scrollWidth) || (clientHeight < scrollHeight));
  }

  get countLabel(): string {
    return toMillions(this.count);
  }

}
