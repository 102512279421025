<div class="compare-container" *ngIf="(tabsWithDemographics$ | async)?.length > 0 && activeTab">
<div class="person-level-compare-widget-header">
  <div class="person-level-compare-header-instructions" *ngIf="!isMultiWidget">
    Go To Widget
  </div>
  <div class="person-level-compare-widget-select" #trigger (click)="menuOpen = !menuOpen" *ngLet="(compareService.demographic$ | async) as demographic">
    <button mat-button
            *ppcCheckOverflow="let hasOverflow=hasXOverflow"
            [ppcTooltip]="demographic?.name"
            [tooltipDisabled]="!hasOverflow"
           [matMenuTriggerFor]="tabs">{{ demographic?.name }} ({{ demographic?.tab?.name }})</button>
    <i class="fa fa-caret-down" [class.rotate-180]="menuOpen"></i>
    <mat-menu #tabs="matMenu" class="person-level-compare-widget-select-menu" (closed)="menuOpen = false">
      <span *ngFor="let tab of tabsWithDemographics$ | async;">
        <button mat-menu-item
                class="tab-name"
                [matMenuTriggerFor]="demographics"
                *ppcCheckOverflow="let hasOverflow=hasXOverflow"
                [ppcTooltip]="!tab.demographics?.length ? 'Add custom widgets to view them in Compare' : tab?.name"
                [tooltipDisabled]="tab.demographics?.length && !hasOverflow"
                [class.disabled]="!tab.demographics?.length"
                [disableRipple]="!tab.demographics?.length"
                [style.width.px]="trigger.clientWidth">
          <i class="fa fa-check" [class.invisible]="tab.id !== activeTab.id"></i>
          {{ tab.name }}
          <mat-menu #demographics="matMenu" class="person-level-compare-widget-demographic-select-menu">
            <span *ngFor="let demographic of tab.demographics">
              <button mat-menu-item
                      (click)="setCompareDemographic(demographic, tab)"
                      *ppcCheckOverflow="let hasOverflow=hasXOverflow"
                      [ppcTooltip]="demographic?.name"
                      [tooltipDisabled]="!hasOverflow">
                        <i class="fa fa-check"
                           [class.invisible]="demographic.id !== activeDemographic.id"></i>
                       {{ demographic.name }}
              </button>
            </span>
          </mat-menu>
        </button>
      </span>
      <button mat-menu-item
              *ngIf="isMultiWidget"
              class="remove-widget-button"
              (click)="removeWidget()">
        Remove Widget
      </button>
    </mat-menu>
  </div>
</div>
<div class="person-level-compare-body" *ngLet="(compareService.canCompare$ | async) as canCompare">
  <div class="instructions" *ngIf="!canCompare">Select 1-3 {{ instructionsText() }} to begin comparing their attribute makeup.</div>
  <div class="person-level-compare-container" *ngIf="activeDemographic && canCompare" [class.shift-right]="isLastWidget()">
      <ppc-person-level-compare-widget [tab]="activeTab"></ppc-person-level-compare-widget>
  </div>
  <div class="compare-nav left" *ngLet="(previousDemographic$ | async) as previousDemographic"  [class.hide]="!previousDemographic || !canCompare || isMultiWidget" (click)="setCompareDemographic(previousDemographic, previousDemographic.tab)">
    <div class="compare-nav-button" matRipple>
      <i class="fa fa-chevron-left"></i>
    </div>
    <div class="compare-nav-label">
      <div>{{ previousDemographic?.name }}</div>
      <div>({{ previousDemographic?.tab.name }})</div>
    </div>
  </div>
  <div class="compare-nav right" *ngLet="(nextDemographic$ | async) as nextDemographic" [class.hide]="!nextDemographic || !canCompare || isMultiWidget" (click)="setCompareDemographic(nextDemographic, nextDemographic.tab)">
    <div class="compare-nav-button" matRipple>
      <i class="fa fa-chevron-right"></i>
    </div>
    <div class="compare-nav-label">
      <div>{{ nextDemographic?.name }}</div>
      <div>({{ nextDemographic?.tab.name }})</div>
    </div>
  </div>
</div>
</div>