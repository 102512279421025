import { Component, OnInit, OnDestroy } from '@angular/core';
import { keys, pickBy, sortBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { SegmentsHierarchyService } from 'app/segments-hierarchy/segments-hierarchy.service';
import { SegmentPickerV2Service } from 'app/segment-picker-v2/segment-picker-v2.service';
import { PERMISSION_CREATE_AUDIENCE_SINGLE } from '../../../shared/utils/constants';
import { AppState } from 'app/reducers';
import { Store, select } from '@ngrx/store';
import { fullContext } from '../../../hierarchy/hierarchy.reducers';
import { switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface Vendor {
  label: string,
  value: string
}

@UntilDestroy()
@Component({
  selector: 'ppc-vendor-filter-picker',
  templateUrl: './vendor-filter-picker.component.html',
  styleUrls: ['./vendor-filter-picker.component.sass']
})
export class VendorFilterPickerComponent implements OnInit, OnDestroy {
  vendorOptions: Vendor[] = [];
  vendorSelected: { [key: string]: boolean; };
  selectedFilter$ = new BehaviorSubject<string[]>([]);

  constructor(
    private segmentsHierarchyService: SegmentsHierarchyService,
    private segmentPickerService: SegmentPickerV2Service,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    fullContext(this.store).pipe(
      switchMap(() => this.segmentsHierarchyService.getVendor([this.segmentPickerService.useCase$.getValue()])),
      untilDestroyed(this),
    ).subscribe(response => {
      this.vendorOptions = sortBy(response, ['label'], ['asc']);
      this.resetSelectedVendors();
    }
    )
  }

  ngOnDestroy() { }

  resetSelectedVendors() {
    this.vendorSelected = this.vendorOptions.reduce((result, vendor) => {
      result[vendor.value] = false;
      return result;
    }, {});
  }

  get isSelectionCleared(): boolean {
    if (!this.vendorSelected) {
      return true;
    }
    for (const key of Object.keys(this.vendorSelected)) {
      if (this.vendorSelected[key]) {
        return false;
      }
    }
    return true;
  }

  selectVendor(vendor?: Vendor) {
    if (vendor) {
      this.vendorSelected[vendor.value] = !this.vendorSelected[vendor.value];
    } else {
      this.resetSelectedVendors();
    }
    this.selectedFilter$.next(this.selectedVendorValues);
  }

  get selectedVendorValues(): string[] {
    return keys(pickBy(this.vendorSelected, Boolean));
  }
}
