<div *ngIf="rootBuckets?.length" class="widget">
    <div class="widget-title">
      <span>
        {{title}}
        <i class="fa fa-info-circle" [ppcTooltip]="widgetType == 'Base' ? 'Each unique ID is counted once' : 'Total count for all IDs'"></i>
      </span>
      <span class="entry-date" [ppcTooltip]="'Date of last data file received'">{{rootBuckets[0]?.entry_date | date}}
        <span class="sort-data">
          <ppc-sort [sortData]="bucketsWithPercent"></ppc-sort>
        </span>
      </span>
    </div>
    <div class="widget-bucket" *ngFor="let bucket of rootBuckets">
        <div class="widget-bucket-container">
            <div class="widget-bucket-label" #parentLabel>
                <i class="fa fa-chevron-right"
                    [class.invisible]="!getBucketChildren(bucket)?.length"
                    [class.rotate-90]="bucket.expanded"
                    (click)="bucket.expanded = !bucket.expanded"></i>
              </div>
              <ppc-progress-bar [percentage]="getPercentage(bucket)" 
                                colorOverride="#82CAD0" 
                                [showCounts]="true" 
                                [countLabel]="getCountLabel(bucket)" 
                                [count]="getCount(bucket)" 
                                [label]="bucket.name" 
                                [inlineLabel]="false"></ppc-progress-bar>
        </div>
        <div class="widget-bucket-children" *ngIf="bucket.expanded && widgetType == 'Categories'">
          <div class="widget-child-bucket" *ngFor="let childBucket of getBucketChildren(bucket);">
            <ppc-progress-bar [percentage]="getChildPercentage(childBucket, bucket)" 
                              colorOverride="#82CAD0" 
                              [showCounts]="true" 
                              [countLabel]="getCountLabel(childBucket)" 
                              [count]="getCount(childBucket)" 
                              [label]="childBucket.name" 
                              [inlineLabel]="false"></ppc-progress-bar>
          </div>
        </div>
        <div class="widget-bucket-domain-children" *ngIf="bucket.expanded && widgetType == 'Domain'" [style.padding-left.px]="parentLabel.clientWidth + 5">
            <div class="widget-child-domain" *ngFor="let childBucket of getBucketChildren(bucket);">
              <div class="widget-child-bucket-label">
                <div class="vertical-center">
                  {{childBucket.name}}
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>
