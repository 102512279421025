<div class="checkbox-container"
  [class.disabled]="disabled"
  [class.disable-filter]="showDisableFilter"
  (click)="toggle($event);playRipple()"
 >
  <div class="checkbox" matRipple [matRippleCentered]="true" [matRippleDisabled]="!showRipple">
    <div class="checkbox-square"
         [class.checked]="checked">
      <i class="fa fa-check" *ngIf="!indeterminate"></i>
      <i class="fa fa-minus fa-fw" aria-hidden="true" *ngIf="indeterminate"></i>
    </div>
  </div>

  <div class="checkbox-label"
       *ppcCheckOverflow="let hasOverflow=hasXOverflow"
       [ppcTooltip]="labelTooltip"
       [tooltipDisabled]="!ellipsize || !hasOverflow"
       [class.ellipsize]="ellipsize">
    <ng-content></ng-content>
  </div>

</div>
