import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'epc-modal',
  templateUrl: './epc-modal.component.html',
  styleUrls: ['./epc-modal.component.sass']
})
export class EpcModalComponent implements OnInit {
  @Input() title: string;
  @Input() titleStyle: string = 'default-title';
  @Input() description: string;
  @Input() descriptionStyle: string = 'default-description';
  @Input() dismissOnBlur: boolean = true;
  @Input() dismissable: boolean = true;
  @Output() onShow = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input() primaryButtonTitle = 'Save';
  @Input() primaryButtonDisabled = false;
  @Output() onPrimaryButtonClick = new EventEmitter();
  @Input() secondaryButtonTitle = null;
  @Input() secondaryButtonDisabled = false;
  @Output() onSecondaryButtonClick = new EventEmitter();
  @Input() displayFooter: boolean = true;

  open: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  show() {
    this.open = true;
    this.onShow.emit();
  }

  close() {
    this.open = false;
    this.onClose.emit();
  }

  handlePrimaryButtonClick() {
    this.onPrimaryButtonClick.emit();
  }

  handleSecondaryButtonClick() {
    this.onSecondaryButtonClick.emit();
  }
}
