<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>

<app-slide-in-overlay [active]="!!(weboramaDiscussionUnderEdit$ | async)" [width]="650">
  <ppc-weborama-discussion-form *ngIf="weboramaDiscussionUnderEdit$ | async" [weboramaDiscussion]="weboramaDiscussionUnderEdit$ | async" [weboramaNodes]="weboramaNodes$ | async"></ppc-weborama-discussion-form>
</app-slide-in-overlay>

<app-slide-in-overlay [width]="650" [active]="!!(regionDemographicUnderEdit$ | async)">
    <ppc-region-demographic-form></ppc-region-demographic-form>
</app-slide-in-overlay>

<div class="grow-v3-header">
  <div class="header-text">
    <div class="header">
      <span class="gold">Insights </span><span>Grow</span>
    </div>
    <div class="mekko-dropdown layout horizontal">
      <ppc-searchable-dropdown #mekkoDropdown *ngIf="hasMekkos"
                                itemLabelProp="name"
                                mapFromProperty="id"
                                [allowClear]="false"
                                [(ngModel)]="selectedMekkoId"
                                [items]="mekkos">
        <ng-template #itemTemplate let-item="item">
            <div class="mekko-item" [class.selected]="activeMekko === item">
            <button class="single-select-item"
              (click)="onMekkoChange(item); mekkoDropdown.open = false"
              *ppcCheckOverflow="let hasOverflow=hasXOverflow"
              [ppcTooltip]="item?.name"
              [tooltipDisabled]="!hasOverflow"
              tabIndex="0">
              <i class="fa fa-flag-o" [class.invisible]="!item.default"></i>{{ item?.name }}
            </button>
            <button class="edit-select-item"
              *ngIf="canCreateMekko$ | async"
              (click)="onEditMekkoClick(item); mekkoDropdown.open = false">
              <i class="fa fa-pencil"></i>
            </button>
          </div>
        </ng-template>
      </ppc-searchable-dropdown>
      <div class="mekko-actions" *ngLet="(permissions$ | async) as permissions">
        <ppc-icon-button icon="plus" ppcTooltip="Create" *ngIf="permissions?.create" (click)="onCreateMekkoClick()"></ppc-icon-button>
        <ppc-icon-button icon="pencil" ppcTooltip="Edit" *ngIf="activeMekko && permissions?.update" (click)="onEditMekkoClick(activeMekko)"></ppc-icon-button>
        <ppc-icon-button icon="clone" ppcTooltip="Copy" *ngIf="activeMekko && permissions?.create" (click)="copyMekko()"></ppc-icon-button>
        <ppc-icon-button ppcConfirmation modalContent="This chart will also be deleted from: Outcomes Grow Chart Selector and Past Timeframes" icon="trash" ppcTooltip="Delete" *ngIf="activeMekko && permissions?.destroy" [kontinue]="destroyMekko.bind(this)"></ppc-icon-button>
      </div>
    </div>
  </div>
</div>

<ppc-filter-bar
  [filters]="selectedSubMarketsAndFilters"
  [class.show]="selectedSubMarketsAndFilters.length"
  (clearFilters)="clearFilters()"
  (toggleFilter)="toggleFilter($event)"
  *ngLet="(saveAudienceDisabled$ | async) as saveAudienceDisabled">
  <button saveAudienceButton
          matRipple
          *ngIf="selectedSubMarketsAndFilters.length && ((growTabState$ | async) != 'Total Population') && !isTier3"
          class="header-btn"
          (click)="!saveAudienceDisabled && onSaveAudienceClick()"
          [class.disabled]="saveAudienceDisabled"
          [ppcTooltip]="saveAudienceTooltip$ | async"
          [tooltipDisabled]="!saveAudienceDisabled">
    <div class="header-btn-container">
        Save Audience
    </div>
  </button>
</ppc-filter-bar>

<div class="grow-v3 mekko-focused"
     [class.insights-focused]="(focusedComponent$ | async) == 'insights'"
     [class.mekko-focused]="(focusedComponent$ | async) == 'mekko'">
  <ppc-grow-v3-mekko [class.collapsed]="(focusedComponent$ | async) == 'insights'"></ppc-grow-v3-mekko>
  <div class="grow-v3-gutter">
    <div *ngIf="tooltipText"
          class="insights-tooltips"
          [class.hide]="(focusedComponent$ | async) != 'mekko'"
          (click)="tooltipText = ''">
      <div class="tooltip-content">{{tooltipText}}</div>
      <i class="fa fa-close hoverable"></i>
      <span class="tooltip-triangle"></span>
    </div>
    <div *ngIf="(focusedComponent$ | async) != 'mekko'" class="mekko-button" (click)="setFocus('insights')" >
      <div class="rotated" [class.down]="!(focusedComponent$ | async)" [class.up]="focusedComponent$ | async">
        <span>{{(focusedComponent$ | async) == 'insights' ? 'Show' : 'Hide'}} Chart</span>
      </div>
    </div>
    <div *ngIf="(focusedComponent$ | async) != 'insights'" class="insights-button" (click)="setFocus('mekko')" >
      <div class="rotated" [class.up]="!(focusedComponent$ | async)" [class.down]="focusedComponent$ | async">
        <span>{{(focusedComponent$ | async) == 'mekko' ? 'Show' : 'Hide'}} Insights</span>
      </div>
    </div>
  </div>
  <ppc-insights [class.collapsed]="(focusedComponent$ | async) == 'mekko'" [segmentContexts$]="segmentContexts$"></ppc-insights>
</div>

<app-slide-in-overlay
  [active]="!!(mekkoUnderEdit$ | async)"
  [width]="550">
  <ppc-mekko-form *ngIf="mekkoUnderEdit$ | async"></ppc-mekko-form>
</app-slide-in-overlay>

<app-slide-in-overlay [active]="!!(marketLevelDemographicUnderEdit$ | async)" [width]="800">
  <ppc-market-level-demographic-form *ngIf="marketLevelDemographicUnderEdit$ | async" [marketLevelDemographic]="marketLevelDemographicUnderEdit$ | async"></ppc-market-level-demographic-form>
</app-slide-in-overlay>
