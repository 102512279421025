<div class="container">
  <div id="nav" [class.expanded]="expanded">
    <div id="sidenav-logo">
      <img class="logo" src="/assets/images/epsilon_logo/epc-discovery-dm.svg">
      <img class="logo collapsed" id="sidenav-ppc-logo-collapsed" src="/assets/images/epsilon_logo/eLogo_white.svg">
    </div>

    <ul id="primary-nav" class="nav-font-style">
      <li class="top-level-nav-list-item" [class.active]="!activeFeature">
        <a (click)="homeClick()"
           [class.selected]="!activeFeature">
          <img src="assets/icons/icon_home.png" />
          <span class="text">
            Home
          </span>
          <span class="toggle-icon fa fa-caret-right"></span>
        </a>
      </li>
      <li *ngFor="let nav of clientNavOptions$ | async; let navIdx = index;"
        class="top-level-nav-list-item"
        [class.posrel]="!expanded"
        [class.selected]="nav.category === selectedCategory"
        [class.active]="nav.category == activeCategory"
        (mouseenter) ="onNavHover(navIdx)"
        (mouseleave) ="onNavHover(-1)"
      >
        <a (click)="onCategoryClick(nav)">
          <span class="nav-icon fa fa-{{ nav.icon }} {{getFaClassName(nav.icon)}}"></span>
          <span class="text">
            {{ nav.category }}
          </span>
          <span class="toggle-icon fa fa-caret-{{ nav.category === selectedCategory ? 'down' : 'right' }}"></span>
        </a>
        <ul class="sub-nav-list"
          [class.hovering]="!expanded && (navIdx == navHoverIdx)"
          [class.slide-down]="expanded && (nav.category === selectedCategory)"
          [class.slide-up]="expanded && (nav.category != selectedCategory)"
        >
          <li *ngIf="!expanded" class="sub-nav sub-nav-header">{{nav.category}}</li>
          <ng-container *ngFor="let feature of nav.features">
            <li
              class="sub-nav"
              [class.selected]="feature === activeFeature"
              >
              <a (click)="onFeatureClick(nav, feature)">
                {{ getDisplayName(feature) }}
              </a>
            </li>
          </ng-container>
          <ng-container *ngIf="nav.iFramedUnits?.length">
            <li class="sub-nav" *ngFor="let unit of nav.iFramedUnits">
              <a routerLink="{{hierarchySlug}}/toolbox-app/{{unit.name}}">{{unit.name}}</a>
            </li>
          </ng-container>
          <ng-container *ngIf="nav.externalUnits?.length">
            <li class="sub-nav divider">&nbsp;</li>
            <li class="sub-nav" *ngFor="let unit of nav.externalUnits">
              <a target="_blank" [href]="unit.url">{{unit.name}}</a>
            </li>
          </ng-container>
        </ul>
      </li>
    </ul>

    <div id="secondary-nav">
      <hr>
      <div class="action-btn-container">
        <button class="action-btn-solid"
                (click)="toggleNotificationCenter()">
          <span class="fa fa-bell-o fa-lg"
                [matBadge]="reportTaskService.badgeCount$ | async"
                [matBadgeHidden]="(reportTaskService.badgeCount$ | async) === '0'"
                matBadgeSize="small">
          </span>
          <span class="text nav-font-style">Notifications</span>
        </button>
        <div
          class="arrow-left"
          [class.ex]="expanded" [class.hidden]="!showNotificationCenter">
        </div>
      </div>
      <button
        class="collapse-btn action-btn-solid"
        (click)="expanded = !expanded">
        <span [class.ex]="expanded" class="fa fa-chevron-left"></span>
        <span [class.ex]="expanded" class="fa fa-chevron-right"></span>
        <span class="text nav-font-style">Collapse</span>
      </button>
    </div>
  </div>

  <div *ngIf="!!showNotificationCenter">
    <ppc-notification-center
      (onClose)="toggleNotificationCenter()">
    </ppc-notification-center>
  </div>
</div>
