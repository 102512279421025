import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import * as actions from 'app/models/motivations/motivations-v1.actions';
import { fetchResource, ResetFetchState } from "app/shared/utils/fetch-state";
import { MotivationV1 } from "./motivations-v1.model";
import { objToHttpParams } from "app/shared/utils/http-utils";
import { ChangeContext } from 'app/hierarchy/hierarchy.actions';
import { MotivationAudienceRequest } from 'app/motivation-audience/motivation-audience.model';
import { motivationsOverviewUrl, motivationsUrl } from "app/shared/constants/segments.urls";

@Injectable()
export class MotivationsV1Service {
  @Effect()
  fetchMotivations$ = this.actions$.pipe(
    ofType(actions.FetchMotivations.type),
    fetchResource(
      ({ useCases }) => this.getAllMotivations(useCases).pipe(map(res => new actions.LoadMotivations(res.data)))
    )
  )

  @Effect()
  fetchOverviewMotivations$ = this.actions$.pipe(
    ofType(actions.FetchOverviewMotivations.type),
    (
      fetchResource(
        () => this.getAllOverviewMotivations().pipe(
          map(motivations => {
            const updated_mtv = motivations.data.map(motivation => {
              if (motivation.attributes.job_status === "pending" || motivation.attributes.job_status === "processing") {
                motivation.attributes.count.people = null
              }
              return motivation.attributes
            })

            return new actions.LoadMotivations(updated_mtv)})
        )
      )
    )
  )

  @Effect()
  saveMotivation$ = this.actions$.pipe(
    ofType(actions.SaveMotivation.type),
    fetchResource(
      action => this.saveMotivation(action.motivation).pipe(map(motivation => new actions.LoadMotivation(motivation)))
    )
  );

  @Effect()
  contextChanges$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    mergeMap(() => [
      new ResetFetchState(actions.FetchOverviewMotivations),
      new actions.ClearMotivations()
    ]));

  constructor(private http: HttpClient, private actions$: Actions) { }

  @Effect()
  destroyMotivation$ = this.actions$.pipe(
    ofType(actions.DestroyMotivation.type),
    fetchResource(
      ({ motivation, options }) => this.destroy(motivation, options).map(res => new actions.RemoveMotivation(res.data))
    )
  );

  getAllMotivations(use_cases: string[] = ['insights']) {
    const params = objToHttpParams({ use_cases });
    return this.http.get<{ count: number, data: MotivationV1[] }>(motivationsUrl(), { params });
  }

  getAllOverviewMotivations() {
    return this.http.get<{ data }>
    (motivationsOverviewUrl(), {});
  }

  saveMotivation(motivationAudienceRequest: MotivationAudienceRequest): Observable<any> {
    return this.http.post(
      motivationsUrl(), {motivation_audience: motivationAudienceRequest}
    ) as Observable<any>;
  }

  destroy(motivation: MotivationV1, options): Observable<any> {
    return this.http.delete<MotivationV1>(motivationsUrl() + `/${motivation.id}`);
  }
}

