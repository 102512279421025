import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest as observableCombineLatest } from 'rxjs';
import { fetchOutcome } from 'app/shared/utils/fetch-state';
import { Unit } from 'app/toolbox/unit.interface';
import { ResetEditUnit } from 'app/toolbox/toolbox.actions';
import { Category } from './../category.interface';
import { Actions } from '@ngrx/effects';
import { CreateUnit, UpdateUnit, DestroyUnit, HideUnit } from 'app/toolbox/toolbox.actions';
import { FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { values } from 'lodash';
import { toHTML, compareKey } from 'app/shared/utils/utils';
import { MatOption } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HierarchyRegion } from 'app/hierarchy/hierarchy.interface';
import { fullContext } from "app/hierarchy/hierarchy.reducers";
import { NAV_ORDER } from "app/feature-access/feature-access.reducers";
import { AuthPermission } from 'app/shared/interfaces/auth-permission';
import { UNIT_ICONS } from 'app/toolbox/unit/custom-unit.model';

@Component({
  selector: 'app-unit-form',
  templateUrl: './unit-form.component.html',
  styleUrls: ['./unit-form.component.sass']
})
export class UnitFormComponent implements OnInit, OnDestroy {
  @Input() prnt: any;
  @Input() mode: string;
  @Output() modeChange = new EventEmitter<string>();
  @Output() toggleThis = new EventEmitter<string>();
  @Input() soloModal: boolean;

  unitForm: FormGroup = null;
  categories: Category[];
  unitUnderEdit: Unit = null;
  toHtml = toHTML;
  ngUnsubscribe = new Subject();
  // https://gist.github.com/dperini/729294
  urlValidatorPattern: RegExp = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(localhost)|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  clientRegions: Partial<HierarchyRegion>[] = null;
  clientRegionsSansAll: Partial<HierarchyRegion>[] = null;
  clientNavCategories = NAV_ORDER;
  toolboxNavConfigPerms: AuthPermission = null;
  UNIT_ICONS = UNIT_ICONS;

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar
  ) {
    this.actions$.pipe((fetchOutcome(DestroyUnit.type)),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(
        res => {
          this.alertSuccess({message: 'Component deleted!'});
          this.prnt.close();
        },
        err => this.alertFailure({message: 'Unable to delete! Does it exist?'})
      );

    this.actions$.pipe((fetchOutcome(UpdateUnit.type)),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(
        res => {
          this.alertSuccess({message: 'Component updated!'});
          this.prnt.close();
        },
        err => this.alertFailure({message: 'Unable to update! Make sure there is a unique name!'})
      );

    this.actions$.pipe((fetchOutcome(CreateUnit.type)),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(
        res => {
          this.alertSuccess({message: 'Component created!'});
          this.prnt.close();
        },
        err => this.alertFailure({message: 'Unable to create component! Make sure there is a unique name!'})
      );
  }

  ngOnInit() {
    this.store.select('toolbox', 'categories').pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(categories => {
        this.categories = values(categories).filter(cat => !!cat.name).sort(compareKey('priority'));
      });

    observableCombineLatest(
      this.store.select('toolbox', 'unitUnderEdit'),
      fullContext(this.store)
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([unit, hierarchy]) => {
        this.unitUnderEdit = unit;
        if (hierarchy.client) {
          this.clientRegionsSansAll = hierarchy.client.regions;
          this.clientRegions = [{id: 'all', name: 'All'}, ...hierarchy.client.regions];
        }
        if (unit) {
          this.unitForm = this.createUnitFormGroup(unit);
          if (unit.region_ids) {this.setRegionValues(unit.region_ids); }
          if (!unit.feature_module_category) {this.disableIframe(); }
        }
        this.populateRegionIds();
      })

    this.store.select('permissions').pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(permissions => {
        this.toolboxNavConfigPerms = permissions['toolbox_nav_configuration'];
      });
  }

  populateRegionIds() {
    if (this.clientRegions && this.unitUnderEdit && this.unitUnderEdit.region_ids.includes('all')) {
      this.unitUnderEdit.region_ids = this.clientRegions.map(c => c.id);
    }
  }

  onDestroy(): void {
    this.store.dispatch(new DestroyUnit(this.unitForm.value));
    this.resetMultiModalForm();
  }

  onHide(): void {
    this.store.dispatch(new HideUnit(this.unitForm.value));
    this.resetMultiModalForm();
  }

  onSave(): void {
    if (this.unitForm.valid) {
      this.store.dispatch(new UpdateUnit(this.unitForm.value));
    }
    this.resetMultiModalForm();
  }

  onCreate(): void {
    if (this.unitForm.valid) {
      this.store.dispatch(new CreateUnit(this.unitForm.value));
    }
    this.resetMultiModalForm();
  }

  onCancel(solo): void {
    if (solo) {
      this.prnt.dialog.close();
    }
    this.store.dispatch(new ResetEditUnit());
    this.resetMultiModalForm();
  }

  private resetMultiModalForm(): void {
    if (!this.soloModal && this.mode === 'edit') {
      this.modeChange.emit('create');
      this.toggleThis.emit('unit-null');
    }
  }

  onUrlInput(e: Event): void {
    const unitFormUrl = this.unitForm.controls['url']
    const unitFormFeatureModuleCategory = this.unitForm.controls['feature_module_category']

    const urlInvalid: boolean = (!unitFormUrl.valid || !unitFormUrl.value);

    if (urlInvalid) {
      unitFormFeatureModuleCategory.setValue(null);
      this.disableIframe();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleAllRegions($evt: MatOption) {
    if ($evt.selected == false) {
      this.unitForm.controls['region_ids'].setValue([]);
    }
    if ($evt.selected == true) {
      const clientRegionIds = this.clientRegions.map(c => c.id);
      this.unitForm.controls['region_ids'].setValue(clientRegionIds);
    }
  }

  setFeatureModuleValue(value: string) {
    const unitUrl = this.unitForm.controls['url'];

    if (unitUrl.value && unitUrl.valid) {
      this.unitForm.controls['feature_module_category'].setValue(value);
      this.enableCheckbox('iframed');
      this.unitForm.controls['use_client_hierarchy'].enable()
    } else {
      this.unitForm.controls['feature_module_category'].setValue(null);
    }
    if (!value) {this.disableIframe(); }
  }

  setUseClientHierarchyValue(checked) {
    if (!checked) {
      this.unitForm.controls['use_client_hierarchy'].setValue(checked);
    }
  }

  setShowInApplicationsValue(checked) {
    this.unitForm.controls['show_in_applications'].setValue(!checked);
  }

  private setRegionValues(value) {
    if (this.clientRegions && value.includes('all')) {
      const clientRegionIds = this.clientRegions.map(c => c.id);
      this.unitForm.controls['region_ids'].setValue(clientRegionIds);
    } else {
      this.unitForm.controls['region_ids'].setValue(value);
    }
  }

  private createUnitFormGroup(unit): FormGroup {
    return new FormGroup({
      'id': new FormControl(unit.id),
      'name': new FormControl(unit.name, [Validators.required, Validators.minLength(3), Validators.maxLength(36), maxThreeSpaces]),
      'status': new FormControl(unit.status),
      'priority': new FormControl(unit.priority),
      'description': new FormControl(unit.description, [Validators.required, Validators.maxLength(500)]),
      'image_slug': new FormControl(unit.image_slug),
      'category_id': new FormControl(unit.category_id),
      'custom': new FormControl(unit.custom),
      'url': new FormControl(unit.url, [optionalValidator([Validators.pattern(this.urlValidatorPattern)])]),
      'doc_url': new FormControl(unit.doc_url, [optionalValidator([Validators.pattern(this.urlValidatorPattern)])]),
      'client_id': new FormControl(unit.client_id),
      'unit_type_id': new FormControl(unit.unit_type_id),
      'region_ids': new FormControl(unit.region_ids, [Validators.required]),
      'feature_module_category': new FormControl(unit.feature_module_category),
      'iframed': new FormControl(unit.iframed),
      'use_client_hierarchy': new FormControl(unit.use_client_hierarchy),
      'show_in_applications': new FormControl(unit.show_in_applications),
    })
  }

  private disableIframe(): void {
    this.disableCheckbox('iframed');
    this.disableCheckbox('use_client_hierarchy');
  }

  private disableCheckbox(controlName: string): void {
    const unitFormCheckbox = this.unitForm.controls[controlName]
    if (!unitFormCheckbox) {return; }
    unitFormCheckbox.setValue(false);
    unitFormCheckbox.disable();
  }

  private enableCheckbox(controlName: string): void {
    const unitFormCheckbox = this.unitForm.controls[controlName]
    unitFormCheckbox.setValue(true);
    unitFormCheckbox.enable();
  }

  private alertSuccess(success): void {
    this.snackbar.open(`Success! ${success.message}`, null, {
      duration: 4000,
      panelClass: ['check']
    });
  }

  private alertFailure(error): void {
    this.snackbar.open(`Failure! ${error.message}`, null, {
      duration: 4000,
      panelClass: ['danger']
    });
  }
}

export function maxThreeSpaces(control: AbstractControl) {
  if ((control.value.split(' ').length - 1) >= 4) {
    return { maxThreeSpaces: true };
  }
  return null;
}

export function optionalValidator(validators?: (ValidatorFn | null | undefined)[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return control.value ? Validators.compose(validators)(control) : null;
  };
}
