import { Component, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

/*
  Simple radio button with a a little more flexibility than angular material radio buttons.

  Two different ways to use it:
  1. Use ppc radio group
    <div ppcRadioGroup [(ngModel)]="radioVariable">
      <ppc-radio-button *ngFor="let option of options" [value]="option.value">{{option.label}}</ppc-radio-button>
    </div>

  2. ppcRadioGroup relies on ContentChildren and due to some limitations of it (possibly a bug), sometimes
     deeply nested radio buttons don't register correctly. In this case you can
     just bind directly to the isSelected option:
    <ppc-radio-button *ngFor="let option of options"
                      [value]="option.value"
                      [isSelected]="option.value == selectedOption"
                      (selected)="selectedOption = $event"></ppc-radio-button>
*/

@Component({
  selector: 'ppc-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.sass']
})
export class RadioButtonComponent implements OnDestroy {
  @Output() selected = new EventEmitter<any>();
  @Input() value: any;
  @Input() noRipple: boolean;
  @Input() ellipsize: boolean;
  @Input() isSelected: boolean;
  @Input() disabled: boolean;
  value$: Observable<any>;
  disabled$: Observable<boolean> = new BehaviorSubject(false);
  unsubscribeFn: Function;

  constructor() { }

  ngOnDestroy() {
    this.unsubscribeFn && this.unsubscribeFn();
  }

  onSelected(event) {
    this.selected.emit(this.value);
  }

  registerWithRadioGroup(value$: Observable<any>, unsubscribeFn: Function, disabled$: Observable<boolean>) {
    this.value$ = value$;
    this.unsubscribeFn = unsubscribeFn;
    this.disabled$ = disabled$;
  }

}
