<div class="blockHero">
  <div class="anima">
    <svg class="circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="#006cf9" />
          <stop offset="100%" stop-color="#12e7a5" />
        </linearGradient>
      </defs>
      
      <circle cx="50" cy="50" r="45" stroke="url(#gradient)"/>
    </svg>
  </div>
  <div class="loadingHeader">Loading</div>
</div>
