import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep, find } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientMapping} from 'app/admin/client-mappings/client-mapping.model';
import { HierarchyClient } from 'app/hierarchy/hierarchy.interface';
import { PpcDialogComponent } from 'app/shared/components/ppc-dialog/ppc-dialog.component';
import { ClientMappingsService } from './client-mappings.service';


@UntilDestroy()
@Component({
  selector: 'ppc-client-mappings',
  templateUrl: './client-mappings.component.html',
  styleUrls: ['./client-mappings.component.sass']
})
export class ClientMappingsComponent implements OnInit, OnDestroy {
  @ViewChild(PpcDialogComponent, { static: true }) dialog: PpcDialogComponent;

  clientMappings$: BehaviorSubject<ClientMapping[]> = this.clientMapService.clientMappings$;
  clientSlug$ = this.clientMapService.clientSlug$;
  clientMappings: ClientMapping[];
  clientMappingUnderEdit: ClientMapping;
  tableData: ClientMapping[];
  client: HierarchyClient;
  clients: HierarchyClient[];
  tableSorts = [{prop: 'client_name', dir: 'asc'}];
  isLoading = false;

  
  constructor(private clientMapService: ClientMappingsService, private snackbar: MatSnackBar) {
    this.clientMapService.getClientMappings()
    .pipe(untilDestroyed(this))
      .subscribe(clientMappings => {
        this.clientMappings$.next(clientMappings);
      });
  }

  ngOnInit() {
    this.clientMapService.getClientMappingsOnInit()
    .pipe(untilDestroyed(this))
      .subscribe(([clientMappings, clients, clientSlug]: [ClientMapping[], HierarchyClient[], string]) => {
        this.clientMappings = clientMappings;
        this.clients = clients;
        this.client = this.clients && find(this.clients, {slug: clientSlug}) || this.clients[0];
        this.tableData = this.getTableData();
      });
  }

  ngOnDestroy() {

  }

  getTableData(): ClientMapping[] {
    return this.clientMappings.map(clientMapping => ({
      ...clientMapping,
      client_name: this.getClient(clientMapping).name,
      client_slug: this.getClient(clientMapping).slug,
    }));
  }

  addClientMapping() {
    const clientMapping: ClientMapping = {
      app_brand: '',
      app_company_id: '',
      app_company_name: '',
      client_id: this.client.id,
      instance_id: '',
    }

    this.clientMappingUnderEdit = cloneDeep(clientMapping);
    this.dialog.show();
  }

  isNotValid() {
    const form = this.clientMappingUnderEdit;
    return form ? !(form.app_company_id && form.instance_id): true 
  }

  saveClientMapping() {
    this.isLoading = true;
    this.clientMappingUnderEdit.client_id = this.client.id;

    this.clientMapService.createClientMapping(this.clientMappingUnderEdit)
    .pipe(untilDestroyed(this))
      .subscribe(
        (clientMapping: ClientMapping) => {
          this.isLoading = false;
          this.clientMappings.push(clientMapping);
          this.tableData = this.getTableData();
          this.clientMappingUnderEdit = null;
          this.dialog.close();
          this.snackbar.open('Client mapping was successfully added.', null, { duration: 2000, panelClass: "check" });
        },
        (error) => {
          this.isLoading = false;
          this.snackbar.open('Error when trying to save client mapping.', null, { duration: 4000, panelClass: "danger" });
        }
      );
  }

  deleteClientMapping(row: ClientMapping) {
    this.isLoading = true;
    this.clientMapService.deleteClientMapping(row.id)
    .pipe(untilDestroyed(this))
      .subscribe(
        (clientMapping: ClientMapping) => {
          this.isLoading = false;
          this.snackbar.open('Client mapping was successfully deleted.', null, {duration: 2000, panelClass: "check"});
          this.clientMappings$.next(this.clientMappings$.value.filter(clientMapping => clientMapping.id !== row.id));
        },
        (error) => {
          this.isLoading = false;
          this.snackbar.open('Error when trying to delete client mapping.', null, {duration: 4000, panelClass: "danger"});
        }
      );
  }

  cancelEdit() {
    this.clientMappingUnderEdit = null;
    this.dialog.close();
  }

  getClient(clientMapping: ClientMapping) {
    return find(this.clients, { id: clientMapping.client_id })
  }

  clientSlugSelection(client) {
    this.clientSlug$.next(client);
  }

}