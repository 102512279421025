import {ActivatedRouteSnapshot} from "@angular/router";
import { mapKeys, memoize, flatMap as _flatMap } from 'lodash'
import {concat, Observable, of as observableOf} from "rxjs";
import {buffer, distinctUntilChanged, mergeMap, publishReplay, refCount, take, tap} from "rxjs/operators";
import { Params } from "@angular/router";
import { Store} from '@ngrx/store'
import { LocalStorageService } from '../../services/local-storage.service';

const he = require('he');
export default class Utils {
  static uuid(): string {
    return this.uuidS4() + this.uuidS4() + '-' + this.uuidS4() + '-' + this.uuidS4() + '-' +
      this.uuidS4() + '-' + this.uuidS4() + this.uuidS4() + this.uuidS4();
  }

  static uuidS4(): string {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }
}

export function anyTruthyLeaves(x) {
  if (["number", "string", "boolean"].indexOf(typeof x) > -1) {
    return !!x
  } else if (Array.isArray(x)) {
    return x.some(anyTruthyLeaves)
  } else {
    return Object.keys(x).some(k => anyTruthyLeaves(x[k]))
  }
}

export function sortBy(objs: any[], field: string, asc: boolean = true): any[] {
  return objs.sort((a: any, b: any) => {
    return a[field] > b[field] ? (asc ? 1 : -1) : (asc ? -1 : 1);
  });
}

// Returns a function that will sort objects based on the keys provided
// If comparison on the first key produces 0 (i.e. they are tied) it will try the next key and so on
export function sortByMultiKeys(keys: string[], asc: boolean = true) {
  const comparisons = keys.map(key => compareKey(key, asc));
  return (a, b) => {
    const comparison = comparisons.find(comp => comp(a, b) !== 0);
    return comparison ? comparison(a, b) : 0
  }
}

// Returns a function that will sort objects based on the key provided
// Only works on numeric and string fields
export function compareKey(key: string, asc = true): (a, b) => number {
  const direction = asc ? 1 : -1;
  return (a, b) => {
    if (isString(a[key])) {return a[key].localeCompare(b[key]) * direction; }
    if (isString(b[key])) {return b[key].localeCompare(a[key]) * direction; }
    if (isNumeric(a[key]) || isNumeric(b[key])) {
      return compareMaybeNullNumbers(a[key], b[key]) * direction
    }
    if (isNullish(a[key]) && isNullish(b[key])) {return 0; }

    throw `compareKey can only compare numbers and strings. ${key} is ${typeof a[key]} for ${JSON.stringify(a)}`
  }
}

function compareMaybeNullNumbers(a, b) {
  if (!isNumeric(a) && !isNumeric(b)) {return 0; }
  if (!isNumeric(a)) {return 1; }
  if (!isNumeric(b)) {return -1; }
  return a - b;
}

export function compareStrings(key: string, caseSensitive = false, asc = true): (a, b) => number {
  const direction = asc ? 1 : -1;
  return (a, b) => {
    if ( isString(a[key]) ) {
      const stringA = caseSensitive ? a[key] : a[key].toUpperCase();
      const stringB = ( caseSensitive || isNullish(b[key]) ) ? b[key] : b[key].toUpperCase();

      return stringA.localeCompare(stringB) * direction;
    }

    throw `compareStrings can only compare strings. ${key} is ${typeof a[key]} for ${JSON.stringify(a)}`
  }
}

export function isNumeric(x): boolean {
  return typeof x === "number" && !isNaN(x)
}

function isString(x): boolean {
  return typeof x === "string"
}

// Checks if a value is undefined, null, or NaN
export function isNullish(x): boolean {
  return x === void(0)
         || typeof x === "number" && isNaN(x)
         || x === null
}

export function isBlank(s: string): boolean {
  return /^\s*$/.test(s)
}

export function flatMap<T, U>(f: (t: T) => U[], objs: T[]): U[] {
  return [].concat(...objs.map(f))
}

export function toMap<T>(keyFn: (t: T) => string, arr: T[]): {[key: string]: T} {
  return Object.assign({}, ...arr.map(t => ({ [keyFn(t)]: t })))
}

export function values<T>(map: {[key: string]: T}): T[] {
  return Object.keys(map).map(id => map[id])
}

export function entries<T>(obj: {[k: string]: T}): [string, T][] {
  return Object.keys(obj).map((k: string) => [k, obj[k]] as [string, T])
}

// This is mutative
export function remove<T>(t: T, ts: T[]) {
  const index = ts.indexOf(t);
  ts.splice(index, 1)
}

export function updateIndex<T>(ts: T[], i: number, f: (t: T) => T): T[] {
  return [...ts.slice(0, i), f(ts[i]), ...ts.slice(i + 1)]
}

export function getAllRouteParams(snapshot: ActivatedRouteSnapshot, params = {}) {
  const result = Object.assign({}, params, snapshot.params);
  return snapshot.children.length > 0 ? Object.assign({}, ...snapshot.children.map(c => getAllRouteParams(c, result)))
    : result
}

export function capitalizeString(input: string) {
  if (input.length === 0) {return ''; }
  return input[0].toUpperCase() + input.slice(1);
}

// Generates an array of numbers in the range [0 .. n-1]:
export function range(n: number): Array<number> {
  return Array.from({length: n}, (v, i) => i);
}

// Takes an object and returns a new object with the specified keys removed
// e.g. omit(['x', 'y'], {x: 3, y: 4, z: 5});
// -> {z: 5}
export function omit<T>(props: (keyof T)[], obj: T): Partial<T> {
  return Object.assign(
    {},
    ...Object.keys(obj).filter(p => props.indexOf(p as keyof T) < 0)
      .map(p => ({[p]: obj[p]})))
}

export function downloadToClient(data: string, fileName: string): void {
  const link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', fileName);
  link.click();
}

export function simulateLinkClick(linkAttrs: {[key: string]: string}) {
  const link = document.createElement('a');
  Object.assign(link, linkAttrs)
  link.click()
}

export function removeSpaces(string): string {
  return string.replace(new RegExp(" ", 'g'), "_");
}

export function removeSpecialCharacters(string): string {
  return string.replace(new RegExp(/[^A-Za-z0-9_.]/, 'g'), '');
}

export function removeSpacesAndSpecialCharacters(string): string {
  return removeSpecialCharacters(removeSpaces(string));
}

export function renameKeys(nameMap: {[oldName: string]: string}, obj) {
  return mapKeys(obj, (v, k) => nameMap[k] || k)
}

export function formatUtcDateString(date: Date): string {
  return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
}

export function isValidEmail(str: string): boolean {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(str)
}

export const invalidFileRegex = /[%\&\{\}\\\<\>\*\?\/\$\!\'\"\:\@\|]/;

export function isNotValidFileName(fileName: string): boolean {
  return invalidFileRegex.test(fileName)
}

export function matchInvalidFileCharacters(str: string): string[] {
  return Array.from(new Set(str.match(new RegExp(invalidFileRegex, invalidFileRegex.flags + 'g'))))
}

export function getYOffset(element) {
  if (element.offsetParent) {
    return element.offsetTop + getYOffset(element.offsetParent);
  } else {
    return element.offsetTop;
  }
}

export function toHTML(input: string): any {
  return he.decode(input);
}

export function getXOffsetToTarget(target, element) {
  if (element == target || !element.offsetParent || !element.offsetTop) {return 0; }
  if (element.offsetParent == target) {
    return element.offsetLeft;
  } else {
    return element.offsetLeft + getXOffsetToTarget(target, element.offsetParent)
  }
}

export function getYOffsetToTarget(target, element) {
  if (element == target || !element.offsetParent || !element.offsetTop) {return 0; }
  if (element.offsetParent == target) {
    return element.offsetTop;
  } else {
    return element.offsetTop + getXOffsetToTarget(target, element.offsetParent)
  }
}

export function leaves<T>(tree: T, getChildren: (t: T) => T[] = t => t['children']): T[] {
  const children = getChildren(tree) || [];
  return children.length ? _flatMap(children, child => leaves(child, getChildren))
    : [tree];
}

export function ancestors<T>(tree: T, getParent: (t: T) => T = t => t['parent']): T[] {
  const parent = getParent(tree);
  return parent ? [...ancestors(parent, getParent), parent]
    : []
}

export function descendants<T>(tree: T, getChildren: (t: T) => T[] = t => t['children']): T[] {
  const children = getChildren(tree) || [];
  return children.concat(_flatMap(children, child => descendants(child, getChildren)));
}

export function visitTreeNodes<T>(
  tree: T,
  f: (t: T, depth: number, parent?: T) => any,
  getChildren: (t: T) => T[] = t => t['children'],
  depth = 0,
  parent?: T
) {
  f(tree, depth, parent);
  (getChildren(tree) || []).forEach(child => visitTreeNodes(child, f, getChildren, depth + 1, tree))
}

export function mapTree<T>(
  tree: T,
  f: (t: T, depth: number, parent?: T) => Partial<T>,
  getChildren: (t: T) => T[] = t => t['children'],
  setChildren: (t: T, children: T[]) => T = (t, children) => Object.assign({}, t, {children}),
  depth = 0,
  parent?: T
): T {
  const children = getChildren(tree) || [];
  const mappedNode = f(tree, depth, parent);
  return setChildren(
    mappedNode as T,
    children.map(child => mapTree(child, f, getChildren, setChildren, depth + 1, mappedNode)) as T[]
  )
}

// Stores events from the source observable until the trigger emits at which point it emits them
// in order on the returned observable
export function bufferUntil(source, trigger) {
  return source.pipe(
    buffer(trigger),
    take(1),
    mergeMap((xs: any[]) => concat(observableOf(...xs), source))
  )
}

// TODO: Delete this once reach and penetration are separate curve types PR#1490
export function curveLabelOutput(override: boolean, capitalization: string): string {
  let output = '';
  switch (capitalization) {
    case 'lower':
      output = (override) ? 'penetration' : 'reach';
      break;
    case 'upper':
      output = (override) ? 'PENETRATION' : 'REACH';
      break;
    case 'caps':
      output = (override) ? 'Penetration' : 'Reach';
      break;
  }
  return output;
}


export function rectanglesOverlap(r1: DOMRect, r2: DOMRect) {
  return !(r2.left > r1.right ||
           r2.right < r1.left ||
           r2.top > r1.bottom ||
           r2.bottom < r1.top);
}

export function rxLog<T>(tag: string): (o: Observable<T>) => Observable<T> {
  return (o: Observable<T>) => o.pipe(tap(x => console.log(tag, x)))
}

// Does the same thing as BehaviorSubject.getValue()
// Believe it or not this runs synchronously and returns the current state of the store
export function getStoreState<T>(store: Store<T>): T {
  let state: T;
  store.pipe(take(1)).subscribe(x => state = x)
  return state;
}

// Pipe-able operator that takes an input stream and turns it into a multicast observable that replays the last event
// on subscription and only emits when the value changes (as determined by reference equality.)
// This is useful when you want to share a computed value between multiple different observable streams
export function toProperty<T>(eq?): (o: Observable<T>) => Observable<T> {
  return (o: Observable<T>) => o.pipe(distinctUntilChanged(eq), publishReplay(1), refCount())
}

// Takes a function that returns an observable and memoizes it for performance, and then pipes to toProperty() so the
// computed result can be shared
export function memoAndShare<T>(fn: T & Function): T {
  return memo((...args) => fn(...args).pipe(toProperty()))
}

// _.memoize only considers the first argument when checking the cache. We need to check all arguments, so we tell
// _.memoize to use the full argument list as the cache key and hook up a cache that handles that correctly
export const memo = (fn) => {
  const mFn = memoize(fn, (...args) => args)
  const cache = new Map();
  mFn.cache = {
    has: path => hasInCache(cache, ...path),
    get: path => getInCache(cache, ...path),
    set: (path, x) => setInCache(cache, x, ...path),
    delete: path => deleteInCache(cache, ...path),
    clear: () => cache.clear(),
  }
  return mFn
}

function hasInCache(map, key?, ...path) {
  if (map == null || key === null) {return false}
  if (path.length === 0) {return map.has(key)}
  return hasInCache(map.get(key), ...path)
}

function getInCache(map, key?, ...path) {
  if (map == null || key === null) {return null}
  if (path.length === 0) {return map.get(key)}
  return getInCache(map.get(key), ...path)
}

function setInCache(map, x, key?, ...path) {
  if (path.length === 0) {
    map.set(key, x)
    return
  }
  if (!map.has(key)) {map.set(key, new Map())}
  return setInCache(map.get(key), x, ...path)
}

function deleteInCache(map, key?, ...path) {
  if (path.length === 0) {return map.delete(key)}
  return deleteInCache(map.get(key), ...path)
}

export function copyText(val: string) {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = val;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
}

export const isDefined = <T>(v: T): v is Exclude<T, undefined> => new Boolean(v).valueOf();

export function buildUrlRelative(currentParams: Params, newPath: string): string[] {
  const {clientSlug, regionSlug, brandSlug, productSlug} = currentParams;
  return `/${clientSlug}/${regionSlug}/${brandSlug}/${productSlug}/${newPath}`.split("/")
}

export function dataServiceType() {
  const localStorage = new LocalStorageService();
  return localStorage.getValue('serviceType');
}

export function setDataServiceType(serviceType: string) {
  const localStorage = new LocalStorageService();
  localStorage.setValue('serviceType', serviceType);
}
