import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { select, Store } from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { flatMap } from 'lodash';
import { AppState } from 'app/reducers';
import { UploadAdminService } from "app/admin/upload-admin/upload-admin.service";
import { selectRegion } from "app/hierarchy/hierarchy.reducers";
import { ResetFetchState } from 'app/shared/utils/fetch-state';
import * as mekkoActions from 'app/mekko/mekko.actions';

@Component({
  selector: 'ppc-mekkos-upload-admin',
  templateUrl: './mekkos-upload-admin.component.html',
  styleUrls: ['./mekkos-upload-admin.component.sass']
})

@UntilDestroy()
export class MekkosUploadAdminComponent implements OnDestroy {
  public updatedMekkos = [];
  public errors = [];
  fileName = '';
  errorListCollapsed = false;
  updatesListCollapsed = false;

  public rules = [
    "The template will only affect your current client and region.",
    "Each row must contain a value in each column (A-K), except column G.",
    "To add multiple demographics to a Audience, add a line for each attribute value with matching Brand, Market and Audience names.",
    "A list of all updated Markets and Audiences will appear after uploading.",
    "If any errors are found, they will appear below the upload section with a corresponding row and column (if applicable).  Errors do not stop the upload process (provided file has valid brand(s), product(s) and format), but the field or row in error will not be updated.",
    "All available Brands and Products will appear in columns to the right of your downloaded template.",
    "Position columns are numerical. Markets are left to right starting with 1. Audiences and Attributes are top to bottom starting with 1.",
    "Percentage values should be entered into the ‘Attribute Percent’ column.",
    "Enter values into either 'Attribute Value' or 'Attribute Percent' column. Do not use both columns when adding new data."
  ]

  constructor(
    public uploadAdminService: UploadAdminService,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar,
    public store: Store<AppState>
  ) {
    this.store.select('hierarchy').pipe(
      select(selectRegion), filter(Boolean), untilDestroyed(this)
    ).subscribe(region => this.clearUploadMessages());
  }

  ngOnDestroy() {}

  downloadTemplate() {
    this.uploadAdminService.downloadTemplate().subscribe();
  }

  uploadTemplate(event, form: HTMLFormElement) {
    if (!event.target.files[0]) {return; }
    this.fileName = event.target.files[0].name;
    this.clearUploadMessages();
    this.uploadAdminService.loading(true);
    this.uploadAdminService.uploadTemplate(new FormData(form)).subscribe(
      response => this.handleSuccessResponse(flatMap(response.mekkos, "markets"), response.errors),
      error => this.snackbar.open(error, null, { duration: 6000, panelClass: ['danger'] })
    );

    event.target.value = '';
  }

  handleSuccessResponse(response, errors) {
    this.store.dispatch(new ResetFetchState(mekkoActions.FetchMekkos));
    this.store.dispatch(new ResetFetchState(mekkoActions.FetchSubMarkets));
    this.updatedMekkos = response;
    this.errors = errors;
    this.snackbar.open("Upload Complete", 'ok', { duration: 4000, panelClass: ['check'] });
  }

  getSanitizedValue(val): SafeStyle {
    return this.sanitizer.bypassSecurityTrustHtml(val);
  }

  clearUploadMessages() {
    if (this.updatedMekkos.length) {this.updatedMekkos = []; }
    if (this.errors.length) {this.errors = []; }
  }
}
