import { Component, OnDestroy, Inject } from '@angular/core';
import {combineLatest as observableCombineLatest} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {Store, select} from '@ngrx/store';
import {filter} from 'rxjs/operators';

import {CLIENT_VERTICALS} from "app/admin/client/client.constants";
import * as actions from 'app/insights/insights.actions';
import {toMillions} from 'app/insights/insights.models';
import {selectRegion} from 'app/hierarchy/hierarchy.reducers';
import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';

@UntilDestroy()
@Component({
  selector: 'ppc-share-this-top-domains',
  templateUrl: './share-this-top-domains.component.html',
  styleUrls: ['./share-this-top-domains.component.sass']
})
export class ShareThisTopDomainsComponent implements OnDestroy {
  verticals = CLIENT_VERTICALS;
  domains: Array<string>;
  topDomains: Array<string> = [];
  activeVertical: string;
  period: number;
  count = 25;
  countOptions = [25, 15, 10];

  constructor(private store: Store<AppState>, @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {
    store.select("insights", insightsContext, "topDomainsData").pipe(untilDestroyed(this)).subscribe((domains) => {
      this.domains = domains && domains.sort((a, b) => b.count - a.count)
      this.topDomains = this.domains && this.domains.slice(0, this.count)
    })

    observableCombineLatest(
      store.select("insights", insightsContext, "shareThisPeriod"),
      store.select("hierarchy").pipe(select(selectRegion), filter(Boolean)),
    ).pipe(untilDestroyed(this)).subscribe(
      ([shareThisPeriod]) => {
        this.period = shareThisPeriod
        this.activeVertical = this.activeVertical || this.verticals[0].key;
        store.dispatch(new actions.FetchShareThisTopDomains(this.period, this.activeVertical, insightsContext));
      }
    )
  }

  ngOnDestroy() {}

  prettyCount(count) {
    return toMillions(count)
  }

  setVertical(vertical) {
    this.activeVertical = vertical
    this.store.dispatch(new actions.FetchShareThisTopDomains(this.period, vertical, this.insightsContext))
  }

  onCountChange(count) {
    if (count) {
      this.count = count;
      this.topDomains = this.domains && this.domains.slice(0, this.count)
    }
  }
}
