import { HttpClient } from "@angular/common/http";
import { User, fullName } from "app/users/user.interface";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { Subject, Observable, of as observableOf, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { get, map as _map} from 'lodash';
import { LookalikeV3, newLookalikeV3 } from "app/lookalikes-v3/lookalike-v3.model";
import { Partner } from "app/models/partners/partner.model";
import { AudienceV2 } from "app/audiences-v2/audience-v2.model";
import { PpcObjectActivation } from 'app/models/activation.model';
import { V3 } from 'app/shared/utils/constants';
import { AppState } from "app/reducers";
import { DestroyAudience, UpdateAudience } from "app/audiences-v2/audience-v2.actions";
import { DestroyLookalike } from 'app/lookalikes-v3/lookalike-v3.actions';
import { LoadUpdatedLookalike } from "app/lookalikes-v3/lookalike-v3.actions";
import { loggedInUser } from 'app/users/user.reducer';
import { OutcomeV1 } from "app/outcomes-v1/outcomes-v1.model";
import { LoadUpdatedOutcome } from "app/outcomes-v1/outcomes-v1.actions";
import { MotivationV1 } from "app/models/motivations/motivations-v1.model";
import { DestroyMotivation, LoadUpdatedMotivation } from "app/models/motivations/motivations-v1.actions";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  activationsContextUrl,
  activationUrl,
  getActivationsUrl,
  postActivationsUrl,
  sendToPartnerAudienceUrl,
  sendToPartnerLookalikeUrl,
  sendToTardiisUrl,
  updatePermissionsUrl,
} from 'app/shared/constants/segments.urls';
import { handleError } from '../../shared/utils/errors';

@Injectable()
export class OverviewV3Service {
  private createLookalikeSource = new Subject<LookalikeV3>();
  createLookalike$ = this.createLookalikeSource.asObservable();

  private sendToTardiisSourceSuccess = new Subject<any>();
  private sendToTardiisSourceError = new Subject<any>();
  private sendToPartnerSourceSuccess = new Subject<any>();
  private sendToPartnerSourceError = new Subject<any>();
  sendToTardiisSuccess$: Observable<any> = this.sendToTardiisSourceSuccess.asObservable();
  sendToTardiisError$: Observable<any> = this.sendToTardiisSourceError.asObservable();
  sendToPartnerSuccess$: Observable<any> = this.sendToPartnerSourceSuccess.asObservable();
  sendToPartnerError$: Observable<any> = this.sendToPartnerSourceError.asObservable();
  user: User;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private snackbar: MatSnackBar
  ) {
    store.select('users').pipe(map(loggedInUser)).subscribe(context => {
      this.user = context;
    });
  }

  sendToTardiis(ppcObject: AudienceV2 | LookalikeV3 | MotivationV1 | OutcomeV1) {
    return this.http.post(sendToTardiisUrl(), {identifier: ppcObject.identifier, id_space: ppcObject.id_space})
      .subscribe(response => {
        switch (ppcObject?.type) {
          case "audience":
            this.store.dispatch(new UpdateAudience(response['data']));
            break;
          case "lookalike":
            this.store.dispatch(new LoadUpdatedLookalike(response['data']));
            break;
          case "outcome":
            this.store.dispatch(new LoadUpdatedOutcome(response['data']));
            break;
          case "motivation":
            this.store.dispatch(new LoadUpdatedMotivation(response['data']));
            break;
        }
        this.sendToTardiisSourceSuccess.next(response);
      },
      error => {
        this.sendToTardiisSourceError.next(error);
      }
      );
  }

  sendToPartner(ppcObject: AudienceV2 | LookalikeV3) {
    let sendToPartnerUrl = sendToPartnerLookalikeUrl();
    if (get(ppcObject, 'type') === "audience") {
      sendToPartnerUrl = sendToPartnerAudienceUrl()
    }

    return this.http.post(sendToPartnerUrl, {identifier: ppcObject.identifier, username: fullName(this.user), email: this.user.email})
      .subscribe(response => {
        if (ppcObject && ppcObject.type && ppcObject.type === "audience") {
          this.store.dispatch(new UpdateAudience(response as AudienceV2));
        } else {
          this.store.dispatch(new LoadUpdatedLookalike(response as LookalikeV3));
        }
        this.sendToPartnerSourceSuccess.next(response);
      },
      error => {
        this.sendToPartnerSourceError.next(error);
      }
      );
  }

  // TODO evaluate removal of this function
  sendToPartnerV4(partner: Partner, ppcObject: AudienceV2 | LookalikeV3) {
    return this.http.post(activationUrl(), {
      identifier: ppcObject.identifier,
      username: this.user.lion_login,
      full_name: fullName(this.user),
      email: this.user.email,
      partner_id: partner.id
    })
      .subscribe(response => {
        ppcObject.activated = (response as PpcObjectActivation)
        if (ppcObject && ppcObject.type && ppcObject.type === "audience") {
          this.store.dispatch(new UpdateAudience(ppcObject as AudienceV2));
        } else {
          this.store.dispatch(new LoadUpdatedLookalike(ppcObject as LookalikeV3));
        }
        this.sendToPartnerSourceSuccess.next(ppcObject);
      },
      error => {
        this.sendToPartnerSourceError.next(error);
      }
      );
  }

  submitPpcObjectToDestination(ppcObject: AudienceV2 | LookalikeV3 | MotivationV1 | OutcomeV1, destination_ids, useCases) {
    return this.http.post(postActivationsUrl(), {
      username: this.user.lion_login,
      email: this.user.email,
      full_name: fullName(this.user),
      identifier: ppcObject.identifier,
      destination_ids: destination_ids,
      use_cases: useCases
    }).pipe(
      map(response => {
        ppcObject.deletable = false;
        const deleteTooltip = {
          'audience': 'Cannot Delete an Audience that has been activated',
          'lookalike': 'Cannot Delete a Lookalike that has been activated',
          'motivation': 'Cannot Delete a Motivation that has been activated',
          'outcome': 'Cannot Delete an Outcome that has been activated'
        };
        ppcObject.delete_disallow_reasons = [deleteTooltip[ppcObject.type]];
        ppcObject.activated = (response as PpcObjectActivation)
        const actionOptions = {
          'audience': new UpdateAudience(ppcObject as AudienceV2),
          'lookalike': new LoadUpdatedLookalike(ppcObject as LookalikeV3),
          'motivation': new LoadUpdatedMotivation(ppcObject as MotivationV1),
          'outcome': new LoadUpdatedOutcome(ppcObject as OutcomeV1)
        };

        const action = actionOptions[ppcObject.type];
        this.store.dispatch(action);
        this.sendToPartnerSourceSuccess.next(ppcObject);
      }),
      catchError(
        error => {
          this.sendToPartnerSourceError.next(error);
          return handleError(error);
        })
    );
  }

  createLookalike(audience: AudienceV2) {
    const lookalike: LookalikeV3 = newLookalikeV3(audience);
    this.createLookalikeSource.next(lookalike);
  }

  deletePpcItem(ppcItem) {
    const actionOptions = {
      'audience': new DestroyAudience(ppcItem, {urlVersionNumber: V3}),
      'lookalike': new DestroyLookalike(ppcItem, {urlVersionNumber: V3}),
      'motivation': new DestroyMotivation(ppcItem, {})
    };
    const action = actionOptions[ppcItem.type];
    this.store.dispatch(action);
  }

  getActivationsForAudience(identifier: string): Observable<PpcObjectActivation[]> {
    return this.http.get<{data: PpcObjectActivation[]}>(getActivationsUrl(identifier)).pipe(
      map(res => res.data),
      catchError(res => res.status === 400 && res.error.count === 0 ? observableOf([]) : throwError(res)))
  }

  updatePpcObjectPermissions(ppcItem: AudienceV2 | LookalikeV3 | MotivationV1) {
    return this.http.put<{data: AudienceV2 | LookalikeV3 | MotivationV1}>(updatePermissionsUrl(ppcItem.type), {id: ppcItem.id, permissions: ppcItem.permissions})
      .pipe(map(res => res), catchError(err => throwError(err) ))
  }

  displaySnackBar(content: string, duration: number) {
    this.snackbar.open(content, null, { duration: duration });
  }

  getActivationsContextInfo() {
    return this.http.get<{error_code: string}>(activationsContextUrl());
  }
}

export const PERMISSIONS_SAVED_SUCCESSFULLY: string = 'Permissions saved successfully';
export const CUSTOM_AUDIENCE_CREATION: string = 'Custom Audience Creation';

export interface AccordianTemplate {
  title: string,
  type: string,
  templateName: string
}

export interface DisplayName {
  displayName: string,
  isPermissible: boolean
}
