<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
</header>
<main>
  <div class="selectors-container">
    <div class="filter-selector" *ngIf="filters?.length">
      <mat-form-field>
        <mat-select [ngModel]="filter.value"
          (selectionChange)="onFilterChange($event)" placeholder="Filter">
          <mat-option *ngFor="let filter of filters" [value]="filter.value">
            {{ filter.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="mainDiv">
    <!-- Counts for Insight Entities -->
    <ngx-datatable class="data-table" [rowHeight]="33" columnMode="standard" sortType="single" [reorderable]="false"
                   [headerHeight]="33" [footerHeight]="33" [messages]="messageTable" [rows]="entitiesCount">
      <ngx-datatable-column [width]="150" prop="name" headerClass="text" cellClass="text" name="Insights Entities"></ngx-datatable-column>
      <ngx-datatable-column [width]="75" name="Count" headerClass="numeric centerAlign" cellClass="numeric rightAlign"></ngx-datatable-column>
    </ngx-datatable>

  </div>

  <div class="mainDiv">
    <span *ngFor="let entityList of entitiesCountByProductSlug | keyvalue">
      <ngx-datatable class="data-table" [rowHeight]="33" columnMode="standard" sortType="single" [reorderable]="false"
                     [headerHeight]="33" [footerHeight]="33" [messages]="messageTable" [rows]="entityList.value">
        <ngx-datatable-column [width]="300" prop="name" headerClass="text" cellClass="text" name="{{entityList.key}}: Top 5 Clients"></ngx-datatable-column>
        <ngx-datatable-column [width]="75" name="Count" headerClass="numeric centerAlign" cellClass="numeric rightAlign"></ngx-datatable-column>
      </ngx-datatable>
    </span>
  </div>

  <div class="mainDiv">
    <span *ngFor="let entityList of entitiesCountByUser | keyvalue">
      <ngx-datatable class="data-table" [rowHeight]="33" columnMode="standard" sortType="single" [reorderable]="false"
                     [headerHeight]="33" [footerHeight]="33" [messages]="messageTable" [rows]="entityList.value">
        <ngx-datatable-column [width]="300" prop="name" headerClass="text" cellClass="text" name="{{entityList.key}}: Top 5 Users"></ngx-datatable-column>
        <ngx-datatable-column [width]="75" name="Count" headerClass="numeric centerAlign" cellClass="numeric rightAlign"></ngx-datatable-column>
      </ngx-datatable>
    </span>
  </div>
</main>
