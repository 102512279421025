import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "app/reducers";
import { get, filter as _filter, map as _map } from 'lodash';

import * as actions from 'app/comparisons/comparisons.actions';
import { Comparison, ComparisonCountResponse, ComparisonCountRequest } from 'app/comparisons/comparisons.reducer';
import { ChangeContext } from "app/hierarchy/hierarchy.actions";
import { fetchResource, ResetFetchState } from "app/shared/utils/fetch-state";
import { bulkFetchResource } from "app/shared/utils/bulk-fetch-state";
import { UrlService } from 'app/services/url.service';
import { comparisonCountsUrl } from '../shared/constants/id_analytics.urls';
import { userPreferencesUrl, comparisonsUrl, copyComparisonsUrl, updateComparisonsUrl } from 'app/shared/constants/insights.urls';

@Injectable()
export class ComparisonsService {

  @Effect()
  fetchComparisons$ = this.actions$.pipe(
    ofType(actions.FetchComparisons.type),
    (
      fetchResource(
        () => this.getComparisons().pipe(map(comparisons => new actions.LoadComparisons(comparisons)))
      )
    ))

  @Effect()
  saveComparison$ = this.actions$.pipe(
    ofType(actions.SaveComparison.type),
    (
      fetchResource(
        action => this.saveComparison(action.comparison).pipe(map(comparison => new actions.LoadComparison(comparison)))
      )
    ))

  @Effect()
  copyComparison$ = this.actions$.pipe(
    ofType(actions.CopyComparison.type),
    (
      fetchResource(
        action => this.copyComparison(action.comparisonId).pipe(map(comparison => new actions.LoadComparison(comparison)))
      )
    ))

  @Effect()
  destroyComparison$ = this.actions$.pipe(
    ofType(actions.DestroyComparison.type),
    (
      fetchResource(
        action => this.destroyComparison(action.comparison).pipe(map(() => new actions.RemoveComparison(action.comparison)))
      )
    ))

  @Effect()
  fetchComparisonCounts$ = this.actions$.pipe(
    ofType(actions.FetchComparisonCounts.type),
    (
      bulkFetchResource(
        action => this.getComparisonCounts(action.request).pipe(map(response => new actions.LoadComparisonCounts(action.requestId, response)))
      )
    ))

  @Effect()
  changeContext$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    mergeMap(() => [
      new ResetFetchState(actions.FetchComparisons),
      new ResetFetchState(actions.FetchDefaultComparison),
      new actions.ClearFocusedCells(),
      new actions.SetSelectedComparison(null),
    ]));


  @Effect()
  fetchDefaultComparison$ = this.actions$.pipe(
    ofType(actions.FetchDefaultComparison.type),
    (
      fetchResource(
        () => this.fetchDefaultComparison().pipe(map(defaultComparisonId => new actions.LoadDefaultComparison(defaultComparisonId)))
      )
    ))

  @Effect()
  saveDefaultComparison$ = this.actions$.pipe(
    ofType(actions.SaveDefaultComparison.type),
    (
      fetchResource(
        action => this.saveDefaultComparison(action.comparisonId).pipe(map(defaultComparisonId => new actions.LoadDefaultComparison(defaultComparisonId)))
      )
    ))

  @Effect()
  clearFocusedCell$ = this.actions$.pipe(
    ofType(actions.SetSelectedComparison.type),
    map(() => new actions.ClearFocusedCells())
  )

  private comparisonCounts: {[comparisonId: number]: ComparisonCountResponse} = {};

  constructor(private http: HttpClient,
    private actions$: Actions,
    private urlService: UrlService,
    private store: Store<AppState>) {
    this.store.select("comparisons", "comparisonCounts")
      .subscribe(comparisonCounts => this.comparisonCounts = comparisonCounts);
  }

  // If no product_slug is provided, the active context will be inserted by HierarchyInterceptor
  getComparisons(product_slug?: string): Observable<Comparison[]> {
    const headers = product_slug ? {'x-context': product_slug} : {}
    return this.http.get(comparisonsUrl(), {headers}) as Observable<Comparison[]>;
  }

  saveComparison(comparison: Comparison): Observable<Comparison> {
    return this.http[comparison.id ? 'put' : 'post'](comparisonsUrl(comparison.id), { comparison: comparison }) as Observable<Comparison>;
  }

  destroyComparison(comparison: Comparison): Observable<boolean> {
    return this.http.delete(comparisonsUrl(comparison.id)) as Observable<boolean>;
  }

  updateComparisons(comparisons: Partial<Comparison>[]): Observable<Partial<Comparison>[]> {
    return this.http.post(updateComparisonsUrl(), { comparisons }) as Observable<Partial<Comparison>[]>;
  }

  copyComparison(comparisonId: number): Observable<Comparison> {
    return this.http.post(copyComparisonsUrl(comparisonId), null) as Observable<Comparison>;
  }

  getComparisonCounts(request: ComparisonCountRequest): Observable<ComparisonCountResponse> {
    return this.http.post(comparisonCountsUrl(), request) as Observable<ComparisonCountResponse>;
  }

  getCountData(comparisonId, xShortId, yShortId): {index_value: number; percent_value: number; count: number} {
    return get(this.comparisonCounts, [comparisonId, "matrix", `${xShortId}:${yShortId}`], {index_value: null, percent_value: null, count: null})
  }

  fetchDefaultComparison(): Observable<number> {
    return this.http.get(userPreferencesUrl("default-comparison")) as Observable<number>;
  }

  saveDefaultComparison(comparisonId: number): Observable<number> {
    return this.http.put(userPreferencesUrl("default-comparison"), {config: comparisonId}) as Observable<number>;
  }
}
