import { DestinationManagementComponent } from './destination-management.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';

import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { DestinationManagementService } from './destination-management.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    PpcComponentsModule
  ],
  declarations: [
    DestinationManagementComponent
  ],
  providers: [
    DestinationManagementService
  ]
})
export class DestinationManagementModule { }
