<div class="ppc-input">
  <div *ngIf="!noLabel" class="ppc-input-label-container" #label
      [style.transform]="getTransform()"
      [class.click-thru]="!input.value || noLabelFloat"
      [class.floating]="(input.value || alwaysFloatLabel)"
      [class.focused]="focused"
      (click)="!focused && input.focus()"
      >
    <ng-content select="label"></ng-content>
  </div>
  <div class="ppc-input-container" [class.focused]="focused" [class.disabled]="disabled">
    <div class="ppc-input-prefix" #prefix (click)="!focused && input.focus()">
      <ng-content select="[prefix]"></ng-content>
    </div>
    <input #input [(ngModel)]="value"
            ngControl="input"
            [min]="min"
            [max]="max"
            [placeholder]="placeholder ? placeholder : ''"
            [type]="type"
            (input)="onInput($event)"
            (focus)="focused=true"
            (blur)="onBlur()"
            (keypress)="onKeypress($event)"
            [spellcheck]="spellcheck"
            [tabindex]="tabindex"
            [disabled]="disabled"/>
    <div class="ppc-input-suffix" #suffix>
      <ng-content select="[suffix]"></ng-content>
    </div>
    <div class="ppc-input-underline" [class.focused]="focused" [class.error]="hasErrors">

    </div>
  </div>
  <div class="ppc-input-help-text-container" *ngIf="!hasErrors">
    <ng-content select="[help-text]"></ng-content>
  </div>
  <div class="ppc-input-error-container" *ngIf="!noErrorContainer" [class.invisible]="shouldHideErrors">
    <div class="ppc-input-error" *ngFor="let error of errors">
      {{ error }}
    </div>
  </div>
</div>
