import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store, select } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { filter, findIndex, find } from "lodash";
import { isDV360, Partner } from "../../models/partners/partner.model";
import { snackbarMessageV2 } from "../../audiences/shared/audience.constants";
import { ContextSlugs } from "../../hierarchy/hierarchy.interface";
import { DestinationsAdminService } from "./destinations-admin.service";
import { AppState } from "../../reducers";
import { SlideInOverlayComponent } from "app/shared/components/slide-in-overlay/slide-in-overlay.component";
import { getPartners } from 'app/models/partners/partner.reducer';
import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import { AudienceV2 } from 'app/audiences-v2/audience-v2.model';
import { OverviewV3Service } from 'app/audiences/overview-v3/overview-v3.service';
import { AddDestinationComponent } from 'app/admin/destinations-admin/add-destination/add-destination.component';

@Component({
  selector: 'ppc-destinations-admin',
  templateUrl: './destinations-admin.component.html',
  styleUrls: ['./destinations-admin.component.sass']
})
export class DestinationsAdminComponent implements OnInit, OnDestroy {
  @ViewChild('addDestinationOverlay', { static: true }) addDestinationOverlay: SlideInOverlayComponent;
  @ViewChild('addDestinationMenu', { static: true }) addDestinationMenu: AddDestinationComponent;
  @ViewChild('activationDestinationsOverlay', { static: true }) activationDestinationsOverlay: SlideInOverlayComponent;
  @Output() onClose = new EventEmitter();
  @Output() openDestinationsWithPartner = new EventEmitter<any>();
  @Output() addNewDestination = new EventEmitter<any>();
  @Input() itemLabelProp: string = "label";

  ngUnsubscribe: Subject<boolean> = new Subject();
  client: string = "all";

  partners: Partner[] = [];
  weightedPartners: Partner[] = [];
  snackbarMessage: { [name: string]: string } = snackbarMessageV2;
  query: string = '';
  searchResults: Partner[];
  serviceError = false;
  searchInput: string = "";
  currentPartner: Partner;
  isSearching = false;
  contextSlugs: ContextSlugs = null;
  destinationsObject: (AudienceV2 | LookalikeV3);

  allClients: any;
  allRegions: Array<object>;
  allBrands: Array<object>;
  allProducts: Array<object>;

  clientSlug: string = "all";
  regionSlug: string = "all";
  brandSlug: string = "all";
  productSlug: string = "all";

  selectedClient: string;
  selectedRegion: string;
  selectedBrand: string;

  constructor(
    private destinationsAdminService: DestinationsAdminService,
    private snackbar: MatSnackBar,
    private store: Store<AppState>,
    private router: Router,
    private overviewV3Service: OverviewV3Service,
  ) {}

  ngOnInit() {
    this.store.select('hierarchy', 'contextSlugs').subscribe(slugs => {
      this.contextSlugs = slugs;
    });

    this.store.select('hierarchy', 'hierarchy', 'clients').subscribe(clients => {
      this.allClients = [...clients]

      if (this.allClients.length !== 0) {
        this.clientSlug = this.allClients[0].slug;
        this.setAllRegions();
      }
    });

    this.fetchPartners();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  fetchPartners() {
    this.destinationsAdminService.fetchAdminPartners({
      client: this.clientSlug,
      region: this.regionSlug,
      brand: this.brandSlug,
      product: this.productSlug
    }).subscribe(() => this.partnerFetchFromStore());
  }

  partnerFetchFromStore() {
    this.store.select('partners').pipe(select(getPartners), takeUntil(this.ngUnsubscribe)).subscribe((partners) => {
      this.partners = partners;
      this.weightedPartners = this.determineWeightedPartners();
    });
  }

  determineWeightedPartners(): Partner[] {
    return filter(this.partners, function(partner) {
      return partner.weight >= 10 || partner.destination_count > 0;
    });
  }

  get filteredItems() {
    const input = this.searchInput;
    return filter(this.partners, (item) => {
      return !!input && item['name'].toLowerCase().match(input.toLowerCase()) && !item.removed;
    })
  }

  selectPartner(searchResult: Partner) {
    this.searchInput = searchResult.name;
    this.currentPartner = searchResult;
    this.searchOff();
  }

  setupPartner(): void {
    const index = findIndex(this.partners, { id: this.currentPartner.id });
    const updatedPartner = this.partners[index];
    updatedPartner.weight = 10;

    this.partners.splice(index, 1, updatedPartner);
    this.weightedPartners = this.determineWeightedPartners();
  }

  isAudience(ppcItem) {
    return ppcItem.type === "audience";
  }

  isLookalike(ppcItem) {
    return ppcItem.type === "lookalike";
  }

  searchOn() {
    this.isSearching = true;
  }

  searchOff() {
    this.isSearching = false;
  }

  returnToAdmin() {
    this.router.navigateByUrl('/admin-dashboard')
  }

  openDestinations(partner) {
    this.openDestinationsWithPartner.emit({partner: partner });
  }

  closeDestinationsOpenAddDestination(partner) {
    this.activationDestinationsOverlay.toggleState();
    this.addDestinationMenu.reset();
    this.addDestinationOverlay.toggleState();
  }

  toggleAddDestinationOverlay(partner) {
    this.currentPartner = partner;
    if (this.currentPartner.destination_count === 0) {
      this.addDestinationMenu.reset();
      this.addDestinationOverlay.toggleState();
    } else {
      this.activationDestinationsOverlay.toggleState();
    }
  }

  addDestination(partner) {
    if (partner.name === "The Trade Desk" || isDV360(partner)) {
      this.addNewDestination.emit({partner: partner});
    } else {
      this.destinationsAdminService.createDestination(this.destData(partner)).subscribe()
    }
  }

  destData(partner) {
    const result = {
      partner_id: partner.id,
      name: partner.name,
      product_permission: {
        client: this.contextSlugs.clientSlug,
        brand: this.contextSlugs.brandSlug,
        region: this.contextSlugs.regionSlug,
        product: this.contextSlugs.productSlug,
      }
    };
    return result;
  }

  setAllRegions() {
    const cli = find(this.allClients, ['slug', this.clientSlug]);
    const regions = cli['regions'];
    if (this.clientSlug !== this.selectedClient || regions.length === 0) {
      this.allRegions = [];
      this.allBrands = [];
      this.allProducts = [];
      this.regionSlug = 'all';
      this.brandSlug = "all";
      this.productSlug = "all";
      this.selectedClient = this.clientSlug
    }
    if (regions.length > 0) {
      this.allRegions = [{slug: 'all', name: 'All', id: null, brands: [], emerging_market: false, currency: ''}, ...cli['regions']];
    }
    this.fetchPartners()
  }

  setAllBrands() {
    const reg =  find(this.allRegions, ["slug", this.regionSlug]);
    const brands = reg['brands'];
    if (this.regionSlug !== this.selectedRegion || brands.length === 0) {
      this.allBrands = [];
      this.allProducts = [];
      this.brandSlug = "all";
      this.productSlug = "all";
      this.selectedRegion = this.regionSlug;
    }
    if (brands.length > 0) {
      this.allBrands = [{slug: 'all', name: 'All', id: null, products: []}, ...reg['brands']];
    }
    this.fetchPartners()
  }

  setAllProducts() {
    const fullBrands = find(this.allBrands, ["slug", this.brandSlug]);
    const products = fullBrands['products'];
    if (this.brandSlug !== this.selectedBrand || products.length === 0) {
      this.allProducts = [];
      this.productSlug = "all"
      this.selectedBrand = this.brandSlug
    }
    if (products.length > 0) {
      this.allProducts = [{slug: 'all', name: 'All', id: null}, ...fullBrands['products']];
    }
    this.fetchPartners()
  }

  setProduct() {
    this.fetchPartners()
  }

  sendObjectToDestinations($event) {
    this.overviewV3Service.submitPpcObjectToDestination($event.ppcObject, $event.destinationIds, $event.useCases).subscribe(() => {
      this.fetchPartners();
    });
    this.activationDestinationsOverlay.toggleState();
  }

  createDestinationOverlay($event) {
    this.currentPartner = $event.partner;
    this.addDestinationOverlay.toggleState();
  }

  reopenPartnerOverlay() {
    this.activationDestinationsOverlay.toggleState();
  }
}
