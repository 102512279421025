import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { orderBy } from 'lodash';
import { SegmentPickerV2Service } from '../segment-picker-v2.service';
import {
  Observable,
  combineLatest as observableCombineLatest,
  fromEvent,
  BehaviorSubject,
  of as observableOf,
  merge as observableMerge
} from 'rxjs';
import { SegmentV2, prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { map, tap, switchMap, debounceTime, share } from 'rxjs/operators';
import { CdkDragStart, CdkDrag, CdkDropList, CdkDragEnter, CdkDragDrop } from '@angular/cdk/drag-drop';
import { PpcDragService } from 'app/shared/drag/ppc-drag.service';
import { MatPaginator } from '@angular/material/paginator';
import { pathString } from '../../audiences/discover/segment-v2.model';
import { VendorFilterPickerComponent } from 'app/segment-picker-v2/segment-picker-search/vendor-filter-picker/vendor-filter-picker.component';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { fullContext, selectRegion } from 'app/hierarchy/hierarchy.reducers';
import { getSegmentCount } from 'app/audience-builder/audience-builder.utils';
import { fixCounts } from 'app/segment-picker/segment-picker.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';

export interface SegmentSearchResult extends SegmentV2 {
  headline: string;
}

@UntilDestroy()
@Component({
  selector: 'ppc-segment-picker-search',
  templateUrl: './segment-picker-search.component.html',
  styleUrls: ['./segment-picker-search.component.sass']
})
export class SegmentPickerSearchComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("searchInputElement", {static: true}) input: ElementRef<HTMLInputElement>;
  @ViewChild(CdkDropList, {static: true}) dropList: CdkDropList;
  @ViewChild("vendorPicker", {static: true}) vendorPicker: VendorFilterPickerComponent;
  searchResults$: Observable<SegmentSearchResult[]>;
  selectedSegments$ = new BehaviorSubject([]);
  selectedSegments: SegmentSearchResult[];
  searchInput: string;
  pageSizeOptions: number[] = [20, 40];
  pageSize = 20;
  searchCount;
  pageEvents$ = new BehaviorSubject<{pageSize: number, pageIndex: number}>({pageSize: this.pageSize, pageIndex: 0});
  loading = false;
  vendorDisplayNames: VendorDisplayName[];
  dragInstructions = this.segmentPickerService.dragInstructions;
  hideDragInstructionsTooltip = this.segmentPickerService.hideDragInstructionsTooltip;

  constructor(private segmentPickerService: SegmentPickerV2Service, public dragService: PpcDragService, private store: Store<AppState>) {
    store.select('segmentsHierarchy', 'vendorDisplayNames').pipe(
      untilDestroyed(this)
    ).subscribe(vendorDisplayNames => this.vendorDisplayNames = vendorDisplayNames);
  }

  ngOnInit() {
    this.searchResults$ = observableCombineLatest(
      observableMerge(
        fromEvent(this.input.nativeElement, "input").pipe(
          debounceTime(300),
          map((event: any) => event.target.value),
          tap((searchInput) => this.searchInput = searchInput),
          tap(() => this.paginator && this.paginator.firstPage())
        ),
        fullContext(this.store).pipe(
          tap(() => this.input.nativeElement.value = ""),
          map(() => "")
        )
      ),
      this.pageEvents$,
      this.vendorPicker.selectedFilter$,
      this.store.select('hierarchy').pipe(select(selectRegion))
    ).pipe(
      tap(() => this.loading = true),
      switchMap(([searchInput, {pageSize, pageIndex}, selectedFilter, region]) => {
        if (!searchInput) {
          this.loading = false;
          this.searchCount = null;
          return observableOf([]);
        }
        return this.segmentPickerService.search(searchInput, pageSize, pageIndex * pageSize, selectedFilter).pipe(
          tap(({searchCount}) => this.searchCount = searchCount),
          tap(() => this.loading = false),
          map(({segments, hits}) => fixCounts(segments, region).map(s => this.highlightSearchHits(s, hits))),
        )
      }),
      share(),
    );

    this.selectedSegments$.pipe(
      untilDestroyed(this)
    ).subscribe(selectedSegments => this.selectedSegments = selectedSegments);
  }

  ngOnDestroy() { }

  getSegmentPath(segment) {
    return prettyPathParts(segment, this.vendorDisplayNames).join(" > ");
  }

  toggleSelectedSegment(isSelected: boolean, segment: SegmentSearchResult) {
    this.segmentPickerService.toggleSelectedSegment(isSelected, segment, this.selectedSegments$);
  }

  segmentIsSelected(segment: SegmentSearchResult): boolean {
    return this.segmentPickerService.segmentIsSelected(segment, this.selectedSegments);
  }

  selectDraggedSegment(segment: SegmentSearchResult): void {
    this.segmentPickerService.selectDraggedSegment(segment, this.selectedSegments, this.selectedSegments$);
  }

  hasMultipleSelectedSegments(segment: SegmentSearchResult): boolean {
    return this.segmentPickerService.hasMultipleSelectedSegments(segment, this.selectedSegments);
  }

  clearSelectedSegments(): void {
    this.segmentPickerService.clearSelectedSegments(this.selectedSegments$);
  }

  dragEntered(event: CdkDragEnter) {
    this.segmentPickerService.dragEntered(event);
  }

  dragStarted(event: CdkDragStart, segment: SegmentSearchResult) {
    this.segmentPickerService.dragStarted(event, segment, this.selectedSegments, this.selectedSegments$);
  }

  setContainerData(event: CdkDragDrop<any>, segment: SegmentSearchResult, index: number) {
    this.segmentPickerService.setContainerData(event, segment, index, this.selectedSegments$);
  }

  dragDropped(event: CdkDragDrop<any>) {
    this.segmentPickerService.dragDropped(event, this.selectedSegments$);
  }

  getDragData(segment: SegmentSearchResult) {
    return this.segmentPickerService.getDragData(segment, this.selectedSegments);
  }

  canSelectSegment(segment: SegmentSearchResult): boolean {
    return this.segmentPickerService.canSelectSegment(segment);
  }

  multiSelectSegment(segment: SegmentSearchResult) {
    this.segmentPickerService.multiSelectSegment(segment, this.selectedSegments, this.selectedSegments$);
  }

  highlightSearchHits(segment: SegmentV2, hits: string[]): SegmentSearchResult {
    const path = pathString(segment, this.vendorDisplayNames);
    const hitsString = orderBy(hits, "length", "desc").join("|"); // match longer terms first
    return {
      ...segment,
      headline: path.replace(new RegExp(`(${hitsString})`, "gi"), "<span class='search-hit'>$1</span>")
    }
  }

  getSegmentCount(segment: SegmentV2) {
    return getSegmentCount(segment);
  }

  noEnterPredicate(element: CdkDrag, list: CdkDropList) {
    return false;
  }

  resetFilters() {
    this.vendorPicker.selectVendor(null);
  }
}
