import { Component, OnChanges, OnDestroy, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Store } from "@ngrx/store";
import { AppState } from 'app/reducers';
import { get } from 'lodash'

import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import { OutcomeV1 } from 'app/outcomes-v1/outcomes-v1.model';
import { PopupComponent } from 'app/shared/components/popup/popup.component';
import { filterDetailView,
  filterJobStatus,
  disableCreateLookalike,
  disableSendToTardiis,
  disableSendToPartner,
  disableCreateIndexReport,
  disableDownloadExcel,
  disableDelete,
  createLookalikeTooltipMessage,
  sendToPartnerTooltipMessage,
  sendToTardiisTooltipMessage,
  indexReportTooltipMessage,
  downloadExcelTooltipMessage,
  deleteTooltipMessage
} from 'app/audiences/action-menu/action-menu.utils'
import { OverviewV3Service } from 'app/audiences/overview-v3/overview-v3.service';
import { downloadToClient } from "app/shared/utils/utils";
import { hasGlobalCounts } from 'app/audiences-v2/audience-v2.model';
import { convertConfidenceSizesToArray } from 'app/lookalikes-v2/lookalike-v2.model';
import { CSVService } from "app/services/csv/csv.service";
import { CSVFile } from "app/services/csv/csv-file";
import { startCase, isEmpty, cloneDeep} from 'lodash';
import { userCan } from 'app/authorization/permissions/permissions.reducers'
import { DataPermissions } from 'app/data_permissions/data_permissions.model';
import { MotivationV1 } from 'app/models/motivations/motivations-v1.model';
import { RESOURCE } from './action-menu.constants';

@Component({
  selector: 'ppc-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.sass']
})

export class ActionMenuComponent implements OnChanges, OnDestroy {
  @ViewChild('popupComponent', { static: true }) popupComponent: PopupComponent;
  @Input() ppcItem: LookalikeV3 | MotivationV1 | OutcomeV1;
  @Input() isDetailView: boolean;
  @Input() activeRegion: string;
  @Input() itemPermissions: DataPermissions;
  @Output() openDetail = new EventEmitter<LookalikeV3 | MotivationV1 | OutcomeV1>();
  @Output() openActivationOverlay = new EventEmitter();
  @Output() sendToTardiis = new EventEmitter();
  @Output() openIndexReportForm = new EventEmitter();

  menuItems;

  constructor(
    public store: Store<AppState>,
    private csvService: CSVService,
    private overviewV3Service: OverviewV3Service
  ) {}

  ngOnChanges() {
    this.menuItems = this.buildActionMenu(this.isDetailView, this.ppcItem)
  }

  deleteConfirmationMessage(ppcItem): string {
    return `Are you sure you want to delete this ${ppcItem.type}? This action cannot be undone.`;
  }

  onDownloadDataClicked(lookalike: LookalikeV3) {
    const csvData = this.generateDownloadData(lookalike)
    downloadToClient(csvData.data, csvData.fileName);
  }

  // CLICK HANDLERS  ======================================

  handleViewDetail(ppcItem) {
    this.openDetail.emit(ppcItem)
  }

  handleCreateLookalike(ppcItem) {
    this.overviewV3Service.createLookalike(ppcItem);
  }

  handleSendToPartner(ppcItem) {
    this.openActivationOverlay.emit(ppcItem)
  }

  handleSendToTardiis(ppcItem) {
    this.sendToTardiis.emit(ppcItem)
  }

  handleCreateIndexReport(ppcItem) {
    this.openIndexReportForm.emit(ppcItem)
  }

  handleDownloadExcel(ppcItem) {
    this.onDownloadDataClicked(ppcItem)
  }

  handleDelete() {
    const ppcItem = this.ppcItem

    this.overviewV3Service.deletePpcItem(ppcItem);
  }

  // BUILD MENU  ======================================

  customizedMenuItem(menu, ppcItem) {
    const cloneMenu = cloneDeep(menu)
    const newMenu = cloneMenu.map(item => {
      switch (item.type) {
        case "ViewDetail":
          item.action = this.handleViewDetail.bind(this);
          break
        case "CreateLookalike":
          item.action = this.handleCreateLookalike.bind(this)
          item.disabled = this.isDisabledCreateLookalike(ppcItem)
          item.tooltip = this.createLookalikeTooltip(ppcItem)
          break
        case "SendToPartner":
          item.action = this.handleSendToPartner.bind(this)
          item.disabled = this.isDisabledSendToPartner(ppcItem)
          item.tooltip = this.sendToPartnerTooltip(ppcItem)
          break
        case "SendToTardiis":
          item.action = this.handleSendToTardiis.bind(this)
          item.disabled = this.isDisabledSendToTardiis(ppcItem)
          item.tooltip = this.sendToTardiisTooltip(ppcItem)
          break
        case "CreateIndexReport":
          item.action = this.handleCreateIndexReport.bind(this)
          item.disabled = this.isDisabledCreateIndexReport(ppcItem)
          item.tooltip = this.createIndexReportTooltip(ppcItem)
          break
        case "DownloadExcel":
          item.action = this.handleDownloadExcel.bind(this)
          item.disabled = this.isDisabledDownloadExcel(ppcItem)
          item.tooltip = this.downloadExcelTooltip(ppcItem)
          break
        case "Delete":
          item.action = this.handleDelete.bind(this)
          item.disabled = this.isDisabledDelete(ppcItem)
          item.tooltip = this.deleteTooltip(ppcItem)
          break
        default:
          break
      }
      return item
    })

    return newMenu
  }

  buildActionMenu(isDetailView, ppcItem) {
    const menu = filterDetailView(isDetailView, ppcItem)
    const filteredJobStatusMenu = filterJobStatus(menu, ppcItem.job_status)

    return this.customizedMenuItem(filteredJobStatusMenu, ppcItem)
  }

  isDisabledCreateLookalike(ppcItem) {
    const isPpcItemEmpty = isEmpty(ppcItem)
    const jobStatus = ppcItem.job_status
    const ppcItemHasGlobalCounts = hasGlobalCounts(ppcItem);
    const userPermissions = userCan('create', 'lookalikes', this.store)
    const dataPermissions = ppcItem.permissions.modeling_seed

    return disableCreateLookalike(isPpcItemEmpty, jobStatus, ppcItemHasGlobalCounts, userPermissions, dataPermissions)
  }

  createLookalikeTooltip(ppcItem) {
    const jobStatus = ppcItem.job_status
    const ppcItemHasGlobalCounts = hasGlobalCounts(ppcItem);
    const userPermissions = userCan('create', 'lookalikes', this.store)
    const dataPermissions = ppcItem.permissions.modeling_seed
    const type = ppcItem.type

    return createLookalikeTooltipMessage(jobStatus, ppcItemHasGlobalCounts, userPermissions, dataPermissions, type)
  }

  isDisabledSendToPartner(ppcItem) {
    const jobStatus = ppcItem.job_status
    const userPermissions = userCan('create', 'send_to_partner', this.store)
    const dataPermissions = ppcItem.permissions.activation
    const counts = get(ppcItem, ["count", "people", "global"], 0)

    return disableSendToPartner(jobStatus, userPermissions, dataPermissions, counts)
  }

  sendToPartnerTooltip(ppcItem) {
    const jobStatus = ppcItem.job_status
    const type = ppcItem.type
    const userPermissions = userCan('create', 'send_to_partner', this.store)
    const dataPermissions = ppcItem.permissions.activation
    const counts = get(ppcItem, ["count", "people", "global"], 0)

    return sendToPartnerTooltipMessage(jobStatus, userPermissions, dataPermissions, type, counts)
  }

  isDisabledSendToTardiis(ppcItem) {
    const jobStatus = ppcItem.job_status
    const sendToTardiis = !!ppcItem.send_to_tardiis
    const userPermissions = userCan('create', 'send_to_tardiis', this.store)
    const dataPermissions = ppcItem.permissions.tv_planning

    return disableSendToTardiis(jobStatus, sendToTardiis, userPermissions, dataPermissions)
  }

  sendToTardiisTooltip(ppcItem) {
    const jobStatus = ppcItem.job_status
    const sendToTardiis = !!ppcItem.send_to_tardiis
    const userPermissions = userCan('create', 'send_to_tardiis', this.store)
    const dataPermissions = ppcItem.permissions.tv_planning

    return sendToTardiisTooltipMessage(jobStatus, sendToTardiis, userPermissions, dataPermissions, ppcItem.type)
  }

  isDisabledCreateIndexReport(ppcItem) {
    const jobStatus = ppcItem.job_status
    const userPermissions = userCan('create', 'index_report', this.store)

    return disableCreateIndexReport(jobStatus, userPermissions)
  }

  createIndexReportTooltip(ppcItem) {
    const jobStatus = ppcItem.job_status
    const userPermissions = userCan('create', 'index_report', this.store)

    return indexReportTooltipMessage(jobStatus, userPermissions, ppcItem.type)
  }

  isDisabledDownloadExcel(ppcItem) {
    const jobStatus = ppcItem.job_status
    const emptyConfidenceSize = isEmpty(ppcItem.confidence_sizes)

    return disableDownloadExcel(jobStatus, emptyConfidenceSize)
  }

  downloadExcelTooltip(ppcItem) {
    const jobStatus = ppcItem.job_status
    const type = ppcItem.type
    const emptyConfidenceSize = isEmpty(ppcItem.confidence_sizes)

    return downloadExcelTooltipMessage(jobStatus, emptyConfidenceSize, type)
  }

  isDisabledDelete(ppcItem) {
    const resource = RESOURCE[ppcItem.type]
    const jobStatus = ppcItem.job_status
    const userPermissions = userCan('destroy', resource, this.store)
    const alreadySentToTardiis = !!ppcItem.send_to_tardiis

    return disableDelete(ppcItem, jobStatus, userPermissions, alreadySentToTardiis)
  }

  deleteTooltip(ppcItem) {
    const resource = RESOURCE[ppcItem.type]
    const jobStatus = ppcItem.job_status
    const userPermissions = userCan('destroy', resource, this.store)
    const alreadySentToTardiis = !!ppcItem.send_to_tardiis

    return deleteTooltipMessage(ppcItem, jobStatus, userPermissions, alreadySentToTardiis, ppcItem.type)
  }

  ngOnDestroy() {}

  private generateDownloadData(lookalike): CSVFile {
    const lookalikeData: Object[] = convertConfidenceSizesToArray(lookalike);
    const lookalikeCountFields: string[] = ["reach", "confidence"];
    const lookalikeCountDisplayFields: string[] = lookalikeCountFields.map(field => startCase(field));

    return this.csvService.generateCsvFile(
      lookalikeData,
      lookalikeCountFields,
      lookalikeCountDisplayFields,
      lookalike.name,
      { generatingPage: "Lookalike" }
    )
  }
}
