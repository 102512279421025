<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>
<div class="form-container layout vertical">
  <h3>Motivation Audience</h3>
  <div class="edit-pane-section layout vertical motivation-audience-layout-container">
    <div class="edit-pane-section-header">
      <div class="motivation-audience-textarea-container">
        <ppc-textarea class="motivation-audience-textarea" [(ngModel)]="motivationAudienceJSON">
          <label>JSON from Requester</label>
        </ppc-textarea>
        <div class="motivation-error-container" *ngIf="!isValid()">
          There is an issue with the Motivation information provided
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-container layout vertical flex-1 end-justified">
    <div class="layout horizontal">
      <button
        ppcThrottleClick
        type="submit"
        class="pds-btn pds-btn--primary"
        [class.disabled]="!isValid()"
        [throttleTime]="1000"
        [disabled]="!isValid()"
        (throttledClick)="create()">
        Create
      </button>

      <button class="pds-btn pds-btn--tertiary" (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
