import { Directive, Input, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[ppcExpandable]',
  exportAs: "ppcExpandable"
})
export class PpcExpandableDirective implements OnInit, OnDestroy {
  @Input("ppcExpandableTrigger") trigger: HTMLElement;
  @Input("ppcExpandableDisabled") disabled: boolean = false;
  public open = false;

  constructor(private element: ElementRef) { }

  ngOnInit() {
    fromEvent(document, "click").pipe(untilDestroyed(this)).subscribe(event => {
      if (!this.element.nativeElement.contains(event.target)) {
        this.open = false;
      }
    })
    fromEvent(this.trigger || this.element.nativeElement, "click").pipe(untilDestroyed(this)).subscribe(event => {
      if (!this.disabled) {
        this.open = !this.open;
      }
    })

  }

  ngOnDestroy() {

  }

}
