import { Component, OnInit, OnDestroy } from '@angular/core';
import { SegmentPickerV2Service } from '../segment-picker-v2.service';
import { SegmentV2 } from '../../audiences/discover/segment-v2.model';
import { PpcDragService } from '../../shared/drag/ppc-drag.service';
import { CdkDragEnter, CdkDragStart, CdkDragDrop } from '@angular/cdk/drag-drop';
import { BehaviorSubject } from 'rxjs';
import { getSegmentCount } from 'app/audience-builder/audience-builder.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ppc-segment-picker-browse',
  templateUrl: './segment-picker-browse.component.html',
  styleUrls: ['./segment-picker-browse.component.sass']
})
export class SegmentPickerBrowseComponent implements OnInit, OnDestroy {
  selectedNodes$ = new BehaviorSubject([]);
  selectedNodes: SegmentV2[];
  hideDragInstructionsTooltip = this.pickerService.hideDragInstructionsTooltip;
  dragInstructions = this.pickerService.dragInstructions;
  root$ = this.pickerService.root$;

  constructor(public pickerService: SegmentPickerV2Service, public dragService: PpcDragService) { }

  ngOnInit() {
    this.selectedNodes$.pipe(
      untilDestroyed(this)
    ).subscribe(selectedNodes => this.selectedNodes = selectedNodes);
  }

  ngOnDestroy() {}

  toggleSelectedNode(isSelected: boolean, node: SegmentV2) {
    this.pickerService.toggleSelectedSegment(isSelected, node, this.selectedNodes$);
  }

  nodeIsSelected(node: SegmentV2): boolean {
    return this.pickerService.segmentIsSelected(node, this.selectedNodes);
  }

  selectDraggedNode(node: SegmentV2): void {
    this.pickerService.selectDraggedSegment(node, this.selectedNodes, this.selectedNodes$);
  }

  hasMultipleSelectedNodes(node: SegmentV2): boolean {
    return this.pickerService.hasMultipleSelectedSegments(node, this.selectedNodes);
  }

  clearSelectedNodes(): void {
    this.pickerService.clearSelectedSegments(this.selectedNodes$);
  }

  getCount(segment: SegmentV2) {
    return getSegmentCount(segment);
  }

  dragEntered(event: CdkDragEnter) {
    this.pickerService.dragEntered(event);
  }

  dragStarted(event: CdkDragStart, node: SegmentV2) {
    this.pickerService.dragStarted(event, node, this.selectedNodes, this.selectedNodes$);
  }

  setContainerData(event: CdkDragDrop<any>, node: SegmentV2, index: number) {
    this.pickerService.setContainerData(event, node, index, this.selectedNodes$);
  }

  dragDropped(event: CdkDragDrop<any>) {
    this.pickerService.dragDropped(event, this.selectedNodes$);
  }

  getDragData(node: SegmentV2): SegmentV2[] | SegmentV2 {
    return this.pickerService.getDragData(node, this.selectedNodes);
  }

  canSelectSegment(node: SegmentV2): boolean {
    return this.pickerService.canSelectSegment(node);
  }

  multiSelectNode(node: SegmentV2) {
    this.pickerService.multiSelectSegment(node, this.selectedNodes, this.selectedNodes$);
  }
}
