import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest as observableCombineLatest, fromEvent as observableFromEvent, Observable } from 'rxjs';
import { filter, tap, map, switchMap, combineLatest } from 'rxjs/operators';
import { AppState } from 'app/reducers';
import { PpcDragService } from 'app/shared/drag/ppc-drag.service';
import { AudienceBuilderService } from './audience-builder.service';
import { SegmentPickerV2Service } from 'app/segment-picker-v2/segment-picker-v2.service';
import { CustomCreateService } from './custom-create.service';
import { BuilderAudience } from './audience-builder.models';
import { AudienceV2Service } from '../audiences-v2/audience-v2.service';
import { Go } from '../router/router.actions';
import { ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { get } from 'lodash';
import { LoadAudiences } from '../audiences-v2/audience-v2.actions';
import { CAN_SELECT_SEGMENT_PROVIDER, DRAG_INSTRUCTIONS_PROVIDER } from '../segment-picker-v2/segment-picker-v2.constants';

import { V3 } from "app/shared/utils/constants";
import { FetchOverviewAudiences } from "app/audiences-v2/audience-v2.actions";
import { isLoaded, isNotYetFetched } from "app/shared/utils/fetch-state";
import { AudienceMapper } from './audience-mappers/audience-mapper';
import { AudienceMapper as SegmentsApiAudienceMapper } from './audience-mappers/segments-api';
import { buildUrlRelative } from '../shared/utils/utils';
import { selectAudience } from '../audiences-v2/audience-v2.reducers';
import { fullContext } from '../hierarchy/hierarchy.reducers';
import {
  includesAtLeastOneSegment,
  saveAudienceDisabled,
  saveAudienceTooltip,
  TOOLTIP_ZERO_COUNT
} from './audience-builder.utils';
import { PersonaBuilderService } from 'app/explore/persona-builder.service';
import { MotivationAudienceService } from 'app/motivation-audience/motivation-audience.service';
import { AudienceV2 } from 'app/audiences-v2/audience-v2.model';

@UntilDestroy()
@Component({
  selector: 'ppc-audience-builder',
  templateUrl: './audience-builder.component.html',
  styleUrls: ['./audience-builder.component.sass'],
  providers: [
    PpcDragService,
    AudienceBuilderService,
    PersonaBuilderService,
    MotivationAudienceService,
    {
      provide: AudienceMapper,
      useClass: SegmentsApiAudienceMapper,
    },
    {
      provide: CustomCreateService,
      useFactory: (audienceService, builderService) => {
        return new CustomCreateService(audienceService, builderService)
      },
      deps: [AudienceV2Service, AudienceBuilderService]
    },
    {
      provide: CAN_SELECT_SEGMENT_PROVIDER,
      useFactory: (createService: CustomCreateService) => {
        return createService.canSelectSegment.bind(createService)
      },
      deps: [CustomCreateService]
    },
    {
      provide: DRAG_INSTRUCTIONS_PROVIDER,
      useValue: "Drag the segment and drop to audience builder",
    },
    SegmentPickerV2Service,
  ],
})
export class AudienceBuilderComponent implements OnInit, OnDestroy {
  audience: BuilderAudience;
  params: Params;
  queryParams: Params;
  hasChanges: boolean;
  audienceCount: number;
  saveAudienceDisabled$: Observable<boolean>;
  saveAudienceTooltipDisabled$: Observable<boolean>;
  saveAudienceTooltip$: Observable<string>;
  zeroCountWarningActive = false;
  warningText = TOOLTIP_ZERO_COUNT;
  pageTitle: string;

  constructor(public builderService: AudienceBuilderService,
    public personaService: PersonaBuilderService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private createService: CustomCreateService,
    private audienceMapper: AudienceMapper,
    private segmentPickerService: SegmentPickerV2Service,
    public motivationService: MotivationAudienceService,
    private snackbar: MatSnackBar) {
    builderService.audience$.pipe(
      untilDestroyed(this)).subscribe(audience => {
      this.audience = audience
    });

    observableCombineLatest(
      this.route.queryParams,
      this.route.params
    ).pipe(untilDestroyed(this)).subscribe(([queryParams, params]) => {
      this.queryParams = queryParams;
      this.params = params;
    })

    // Remove id from params if audience cannot be found
    this.route.params.pipe(
      map(({id}) => +id),
      combineLatest(
        this.store.select("fetchStates", FetchOverviewAudiences.type).pipe(select(isLoaded)),
      ),
      filter(([audienceId, audiencesAreLoaded]) => audienceId && audiencesAreLoaded),
      switchMap(([audienceId]) => this.store.select("audiencesV2").pipe(select(selectAudience(audienceId)))),
      filter(audience => !audience),
      untilDestroyed(this),
    ).subscribe(() => this.store.dispatch(new Go({path: buildUrlRelative(this.params, "audiences/builder")})))

    builderService.count$.pipe(untilDestroyed(this)).subscribe(count => {
      this.audienceCount = count
    });
  }

  ngOnInit() {
    fullContext(this.store).pipe(
      switchMap(() => this.store.select('fetchStates', FetchOverviewAudiences.type)
        .pipe(
          filter(isNotYetFetched)
        )
      ),
      untilDestroyed(this)
    ).subscribe(() => this.store.dispatch(new FetchOverviewAudiences({ urlVersionNumber: V3 })));

    observableFromEvent(window, "keydown").pipe(
      filter((event: KeyboardEvent) => event.code === "KeyZ" && (event.metaKey || event.ctrlKey) && !event.shiftKey),
      filter((event: KeyboardEvent) => event.target === document.body),
      tap((event: KeyboardEvent) => event.preventDefault()),
      untilDestroyed(this),
    ).subscribe(() => this.builderService.undo$.next())

    observableFromEvent(window, "keydown").pipe(
      filter((event: KeyboardEvent) => event.code === "KeyZ" && (event.metaKey || event.ctrlKey) && event.shiftKey),
      filter((event: KeyboardEvent) => event.target === document.body),
      tap((event: KeyboardEvent) => event.preventDefault()),
      untilDestroyed(this),
    ).subscribe(() => this.builderService.redo$.next())

    this.builderService.hasChanges$.pipe(untilDestroyed(this)).subscribe(hasChanges => this.hasChanges = hasChanges)

    const audienceValidations$ = observableCombineLatest(
      this.builderService.audience$.pipe(
        map(audience => includesAtLeastOneSegment(audience.rules)),
      ),
      this.builderService.audience$.pipe(
        map(audience => this.createService.canSave(audience)),
      ),
      this.builderService.hasUniqueName$,
      this.builderService.hasName$,
      this.builderService.hasChanges$,
      this.builderService.isNameWithinCharacterLimit$,
      this.builderService.hasMultiCustomGWISurveySegments$
    );

    this.saveAudienceDisabled$ = audienceValidations$.pipe(
      map(([includesSegment, canSave, hasUniqueName, hasName, hasChanges, isNameWithinCharacterLimit, hasMultiCustomGWISurveySegments]) => {
        return saveAudienceDisabled(
          includesSegment,
          canSave,
          hasUniqueName,
          hasName,
          isNameWithinCharacterLimit,
          hasMultiCustomGWISurveySegments
        );
      }),
    );

    this.saveAudienceTooltip$ = audienceValidations$.pipe(
      map(([includesSegment, canSave, hasUniqueName, hasName, hasChanges, isNameWithinCharacterLimit, hasMultiCustomGWISurveySegments]) => {
        return saveAudienceTooltip(includesSegment, hasName, hasMultiCustomGWISurveySegments, this.builderService.subject);
      })
    );

    this.saveAudienceTooltipDisabled$ = this.saveAudienceTooltip$.pipe(map(tooltip => !tooltip));

    this.builderService.useCase$.pipe(untilDestroyed(this)).subscribe(useCase => this.segmentPickerService.setUseCase(useCase));

    this.pageTitle = this.builderService.pageTitle;
  }

  ngOnDestroy() {}

  save() {
    if (this.audienceCount === 0) {
      this.snackbar.open(`This ${this.builderService.subject} has an estimated People Count of 0. Unable to Save`, 'OK', {
        panelClass: ["danger"]
      });
      return;
    }

    let payload = this.audienceMapper.toJson(this.audience);

    if (this.queryParams.action === "savePersona") {
      // payload will not have default(Persona) context, this is necessary to save from prebuilt method
      payload = {...payload, default: this.builderService.audience.default};
      this.personaService.savePersonaFromPrebuiltAudience(payload, this.routeToReturnUrl.bind(this));
    } else if (this.queryParams.action === "requestMotivationAudience") {
      this.motivationService.requestMotivationAudience(payload, this.audience, this.routeToReturnUrl.bind(this))
    } else {
      this.saveAudience(
        {
          ...payload,
          creation_source: this.queryParams.referer?.toLowerCase() || 'audiences',
          estimated_count: this.audienceCount
        }
      );
    }
  }

  saveAudience(audience: AudienceV2) {
    this.createService.saveAudience(audience, this.queryParams.audienceCloned).subscribe(
      response => {
        let updatedCreated = '';
        if (this.queryParams.audienceCloned) {
          updatedCreated = 'created';
        } else {
          updatedCreated = audience.id ? 'updated' : 'created';
        }

        this.snackbar.open(`${get(response, "name")} has been ${updatedCreated}! Please allow up to 48 hours for processing.`, "OK", {
          panelClass: ["success"],
          duration: 5000
        })
        this.store.dispatch(new LoadAudiences([response]));
        this.routeToReturnUrl();
      },
      error => {
        const message = get(error, ["error", "error", "message"], "Something went wrong saving this audience. Please try again later.");
        this.snackbar.open(message, "OK", {
          panelClass: ["danger"]
        })
      }
    );
  }

  setWarningToActive() {
    this.zeroCountWarningActive = true;
  }

  closeWarning() {
    this.zeroCountWarningActive = false;
  }

  routeToReturnUrl(id?: number) {
    const redirectUrl = this.queryParams.returnUrl ? (this.queryParams.returnUrl + `${id ? "/" + id : ""}`) : "audiences/overview";
    this.store.dispatch(new Go({path: buildUrlRelative(this.params, redirectUrl)}));
  }
}
