import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { assign, cloneDeep, concat, filter, forEach, get, head, keys, mapValues, reduce } from 'lodash';

import {
  PERMISSION_ACTIVATION,
  PERMISSION_CLIENT_SHARING,
  PERMISSION_CREATE_AUDIENCE_MULTIPLE,
  PERMISSION_CREATE_AUDIENCE_SINGLE,
  PERMISSION_INSIGHTS,
  PERMISSION_MEASUREMENT,
  PERMISSION_MODELING_SEED,
  PERMISSION_MODELING_UNIVERSE,
  PERMISSION_TV_PLANNING
} from 'app/shared/utils/constants';
import { PublicPermission } from 'app/admin/segment-permissions-v2/segment-permissions-v2.interface';

@Component({
  selector: 'ppc-segment-permissions-table',
  templateUrl: './segment-permissions-table.component.html',
  styleUrls: ['./segment-permissions-table.component.sass']
})
export class SegmentPermissionsTableComponent implements OnInit, OnChanges {
  static readonly useCases = [
    { key: PERMISSION_INSIGHTS,                 header: 'Insights' },
    { key: PERMISSION_MODELING_SEED,            header: 'Modeling Seed' },
    { key: PERMISSION_MODELING_UNIVERSE,        header: 'Modeling Universe' },
    { key: PERMISSION_CREATE_AUDIENCE_SINGLE,   header: 'Custom Audience Single' },
    { key: PERMISSION_CREATE_AUDIENCE_MULTIPLE, header: 'Custom Audience Multiple' },
    { key: PERMISSION_TV_PLANNING,              header: 'TV Planning' },
    { key: PERMISSION_ACTIVATION,               header: 'Activation' },
    { key: PERMISSION_MEASUREMENT,              header: 'Measurement' },
    { key: PERMISSION_CLIENT_SHARING,           header: 'Client Data Lake' }
  ];

  @Input() clients: object[];
  @Input() set selectedDataSource(value: object) {
    const rawJSONAPIObject = cloneDeep(value);
    const permissions = get(rawJSONAPIObject, ['attributes', 'permissions'], {});
    this.originalPermissions = cloneDeep(permissions);
    const permissionRows = this.permissionRowsFromPermissions(permissions);
    this.editableRows = reduce(permissionRows, (map, row) => {
      map[row.clientSlug] = false;
      return map;
    }, {});
    this.permissionRows = permissionRows;
  }
  @Output() newRowEvent = new EventEmitter<void>();
  @Output() clientSelectedEvent = new EventEmitter<void>();
  @Output() newRowDeletedEvent = new EventEmitter<void>();

  readonly classReference = SegmentPermissionsTableComponent;

  disableNewEntry: boolean = false;
  disableCopy: boolean = false;
  editableRows: { [key: string]: boolean; };
  permissionRows = [];
  publicPermission: PublicPermission;

  private originalPermissions: object;

  constructor() {
    this.publicPermission = {
      name: 'Public',
      slug: 'public'
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(change) {
    if (change.selectedDataSource) {
      this.disableNewEntry = false
      this.disableCopy = false
    }
  }

  addNewEntry(permissionRow = null) {
    const newEntry = this.setNewEntry(permissionRow)

    this.permissionRows.push(newEntry)
    this.disableNewEntry = true
    this.disableCopy = true

    this.newRowEvent.emit()
  }

  clientAssigned(client): boolean {
    return this.permissionRows.some((permissionRow) =>  permissionRow.clientSlug === client.slug)
  }

  clientName(clientSlug: string) {
    if (clientSlug === 'public') { return 'Public'; }

    const mappedClient = head(filter(this.clients, (client) => { return client['slug'] === clientSlug }));
    if (mappedClient) { return mappedClient['name']; }

    return clientSlug;
  }

  isNewClient(permissionRow): boolean {
    return permissionRow.newEntry
  }

  newEntrydisabled(): boolean {
    return this.disableNewEntry
  }

  permissionRowsFromPermissions(permissions) {
    const rowGenerator = (clientSlug) => {
      return {
        "clientSlug": clientSlug,
        "dataPermissions": permissions[clientSlug],
        "deleted": false
      };
    };
    const publicKeys = filter(keys(permissions), (key) => { return key === 'public' });
    const publicRows = publicKeys.map(rowGenerator);
    const otherKeys = filter(keys(permissions), (key) => { return key !== 'public' });
    const otherRows = otherKeys.sort().map(rowGenerator);

    return concat(publicRows, otherRows);
  }

  resetPermissions(dataPermissions, clientSlug) {
    assign(dataPermissions, this.originalPermissions[clientSlug]);
    this.editableRows[clientSlug] = false;
  }

  setNewClient(event) {
    const newEntry = this.permissionRows.find((row) => row.newEntry)
    const entryToFind = newEntry ? newEntry : this.permissionRows.find((row) => row.newEntry || row['clientSlug'] === 'newEntry')

    entryToFind['clientSlug'] = event.value.slug
    this.editableRows[entryToFind.clientSlug] = true

    this.clientSelectedEvent.emit()
  }

  setNewEntry(permissionRow) {
    if (!permissionRow) {
      return {
        'clientSlug': 'newEntry',
        'dataPermissions': {
          activation: false,
          client_sharing: false,
          create_audience_multiple: false,
          create_audience_single: false,
          insights: false,
          measurement: false,
          modeling_seed: false,
          modeling_universe: false,
          tv_planning: false
        },
        'newEntry': true
      }
    }

    return {
      'clientSlug': 'newEntry',
      'dataPermissions': permissionRow.dataPermissions,
      'newEntry': true
    }
  }

  toggleEditable(clientSlug: string) {
    this.editableRows[clientSlug] = !this.editableRows[clientSlug];
  }

  toggle(dataPermissions, clientSlug, key, newEntry) {
    if (!this.editableRows[clientSlug]) { return }

    const newPermissions = {}
    Object.assign(newPermissions, dataPermissions)
    newPermissions[key] = !newPermissions[key]

    const entryToFind = this.permissionRows.find((row) => row['clientSlug'] === clientSlug)
    entryToFind['dataPermissions'] = newPermissions
  }

  get payloadPermissions() {
    return reduce(this.permissionRows, (map, row) => {
      if (!row.deleted) {
        map[row.clientSlug] = row.dataPermissions;
      }
      return map;
    }, {});
  }

  handleDataSourceDidUpdate(updatedDataSource) {
    forEach(this.permissionRows, (row) => { row.newEntry = false });

    const rawJSONAPIObject = cloneDeep(updatedDataSource);
    const permissions = get(rawJSONAPIObject, ['data', 'attributes', 'permissions'], {});
    this.permissionRows = this.permissionRowsFromPermissions(permissions);
    this.originalPermissions = cloneDeep(permissions);

    this.editableRows = mapValues(this.editableRows, () => false);

    this.disableNewEntry = false;
    this.disableCopy = false;
  }

  isPublicClient(permissionRow) {
    return permissionRow.clientSlug === 'public'
  }

  toggleDelete(permissionRow) {
    if (permissionRow.newEntry) {
      this.permissionRows = this.permissionRows.filter(row => row.clientSlug !== permissionRow.clientSlug);
      this.newRowDeletedEvent.emit();
    } else {
      permissionRow.deleted = !permissionRow.deleted;
      this.editableRows[permissionRow.clientSlug] = false;
    }
  }

  getToggleDeleteText(permissionRow) {
    return permissionRow.deleted ? 'Undo' : 'Delete';
  }
}
