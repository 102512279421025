<div>
  <div>
    <h4>Import Scenario Plan</h4>
  </div>
  <mat-form-field>
    <input name="upload-scenario-input" matInput [(ngModel)]="scenarioPlannerId" type="text" placeholder="Project ID">
  </mat-form-field>

  <button class="btn btn-save" (click)="importPlan()" [disabled]="!canImport">
    Import
  </button>

  <div class="imported-list">
    <div class="import-status" *ngFor="let plan of importedPlans" [ppcTooltip]="tooltipText(plan)" [tooltipOffset]="{y: -10}" >
      <span>{{plan.scenario_planner_id}}</span>
      <span [class]="statusClass(planImportStatus(plan))">
        {{planImportStatus(plan)}}
      </span>
      <app-confirmation
        [kontinue]="deleteImport.bind(this,plan.id)"
        [isIcon]="true"
        [disabled]="!hasDeletePermission"
        [isButton]="false"
        buttonClass="fa fa-times hoverable"
      >
      </app-confirmation>
    </div>
  </div>

</div>
