import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { ActivatedRoute, Params } from '@angular/router';
import { buildSegmentContextsFromJourney } from './journey.utils';
import { buildUrlRelative } from "app/shared/utils/utils";
import { map, filter, switchMap, tap, take, catchError } from 'rxjs/operators';
import { of as observableOf, combineLatest as observableCombineLatest, Observable } from "rxjs";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as actions from 'app/journey/journey.actions';
import { find, first, get, map as _map, cloneDeep } from 'lodash'
import { fetchIfUnfetched, isNotYetFetched, isFetchInFlight, isLoaded } from 'app/shared/utils/fetch-state';
import { fullContext } from 'app/hierarchy/hierarchy.reducers';
import { Actions } from '@ngrx/effects';
import { Go } from 'app/router/router.actions';
import { INSIGHTS_CONTEXT } from 'app/insights/insights.constants';
import { InsightsCountService } from 'app/insights/insights-count.service';
import { JourneyCountService } from './journey-count.service';
import { GrowCountService } from 'app/insights/grow-v3/grow-count.service';
import { selectSelectedJourney, selectSelectedBrand, selectJourneys, selectSelectedStages, selectActiveStages } from './journey.reducer';
import { Journey, JourneyBrand, JourneyTabType, JourneyStage } from './journey.models';
import * as insightsActions from "app/insights/insights.actions";
import { Filter, TopLevelTabType } from 'app/insights/insights.models';
import { FocusType, PpcSplitScreenComponent } from 'app/shared/components/ppc-split-screen/ppc-split-screen.component';
import { fetchOutcome } from 'app/shared/utils/fetch-state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import {getIconTemplate} from "../insights/insights.utils";
import { PersonLevelCompareService } from '../insights/insights-components/person-level-compare/person-level-compare.service';
import { OverlapInsightsExportService } from 'app/insights/insights-components/insights-export/overlap-insights-export.service';
import { InsightsExportService } from 'app/insights/insights-components/insights-export/insights-export.service';

@UntilDestroy()
@Component({
  selector: 'ppc-journey',
  templateUrl: './journey.component.html',
  styleUrls: ['./journey.component.sass'],
  providers: [
    {provide: INSIGHTS_CONTEXT, useValue: "journey"},
    InsightsCountService,
    JourneyCountService,
    PersonLevelCompareService,
    GrowCountService,
    OverlapInsightsExportService,
    InsightsExportService,
  ]
})
export class JourneyComponent implements OnInit, OnDestroy {
  @ViewChild(PpcSplitScreenComponent) splitScreen: PpcSplitScreenComponent;
  params: Params;
  journeys$ = this.store.select("journey").pipe(select(selectJourneys));
  permissions$ = this.store.select("permissions", "journeys");
  selectedJourneyId: number;
  areJourneysFetched$ = this.store.select("fetchStates", actions.FetchJourneys.type).pipe(select(isLoaded))
  regionDemographicUnderEdit$ = this.store.select("insights", "journey", "regionDemographicUnderEdit");
  loading$ = observableCombineLatest(
    this.counts.loadingCounts$,
    this.store.select("fetchStates", actions.FetchJourneys.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.FetchDefaultJourney.type).pipe(select(isFetchInFlight)),
    this.compareService.loading$
  ).map(fetchStates => fetchStates.some(Boolean));
  segmentContexts$ = observableCombineLatest(
    this.store.select('journey').pipe(select(selectSelectedJourney), filter(Boolean)),
    this.store.select('journey').pipe(select(selectSelectedBrand), filter(Boolean)),
    this.store.select("journey", "journeyTab"),
    this.store.select("journey").pipe(select(selectActiveStages))
  ).pipe(
    filter((items: [Journey, JourneyBrand, JourneyTabType, JourneyStage[]]) => items.every(Boolean) && !!find(items[0].brands, items[1])),
    map(([selectedJourney, activeBrand, journeyTab, activeStages]: [Journey, JourneyBrand, JourneyTabType, JourneyStage[]]) => buildSegmentContextsFromJourney(selectedJourney, activeBrand, activeStages, journeyTab)),
    catchError(error => {
      // Shouldn't ever get here but included since any error would stop any subsequent counts, even if the state stabilizes
      console.error(error);
      return null;
    }),
  );
  splitScreenFocus$ = this.store.select("insights", "journey", "splitScreenFocus");
  marketLevelDemographicUnderEdit$ = this.store.select("insights", "journey", "marketLevelDemographicUnderEdit").pipe(map(cloneDeep));
  journeyTab: JourneyTabType;
  selectedStages: JourneyStage[];
  selectedBrand: JourneyBrand;

  constructor(private store: Store<AppState>,
    private route: ActivatedRoute,
    public counts: InsightsCountService,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private compareService: PersonLevelCompareService) { }

  ngOnInit() {
    // These subscriptions may route, so they must remain in ngOnInit instead of the constructor
    fetchIfUnfetched(this.store, new actions.FetchJourneys(), this);

    this.route.params.pipe(
      untilDestroyed(this)
    ).subscribe(params => this.params = params)

    const selectedJourneyId$: Observable<number> = this.route.params.pipe(
      map(({journeyId}) => +journeyId),
      switchMap(journeyId => journeyId ? observableOf(journeyId) : this.store.select("journey", "selectedJourneyId")),
    )

    selectedJourneyId$.pipe(
      untilDestroyed(this)
    ).subscribe((journeyId: number) => {
      this.onJourneySelect(journeyId);
    });

    fullContext(this.store).pipe(
      tap(() => {
        this.store.dispatch(new actions.FetchDefaultJourney());
        this.store.dispatch(new insightsActions.FetchRegionDemographics("journey"));
      }),
      switchMap(() => {
        return observableCombineLatest(
          this.journeys$,
          this.store.select("journey", "defaultJourneyId"),
          selectedJourneyId$,
          this.store.select("fetchStates", actions.FetchJourneys.type).pipe(
            filter(fetchState => !isNotYetFetched(fetchState) && !isFetchInFlight(fetchState))
          ),
          this.store.select("fetchStates", actions.FetchDefaultJourney.type).pipe(
            filter(fetchState => !isNotYetFetched(fetchState) && !isFetchInFlight(fetchState))
          ),
        ).pipe(
          filter(([journeys, defaultJourneyId, selectedJourneyId]) => {
            return !find(journeys, { id: selectedJourneyId }) || !find(journeys, { id: defaultJourneyId });
          }),
        )
      }),
      untilDestroyed(this),
    ).subscribe(([journeys, defaultJourneyId, selectedJourneyId]) => {
      const defaultJourney = find(journeys, { id: selectedJourneyId }) || find(journeys, { id: defaultJourneyId }) || first(journeys);
      this.onJourneySelect(get(defaultJourney, "id"));
    });

    this.store.select("journey", "journeyTab").pipe(
      untilDestroyed(this)
    ).subscribe(journeyTab => this.journeyTab = journeyTab)

    this.store.select("journey").pipe(
      select(selectSelectedStages),
      untilDestroyed(this),
    ).subscribe(selectedStages => {
      this.selectedStages = selectedStages;
    })

    this.store.select("journey").pipe(
      select(selectSelectedBrand),
      untilDestroyed(this),
    ).subscribe(selectedBrand => {
      this.selectedBrand = selectedBrand;
    })

  }

  ngOnDestroy() {
    this.store.dispatch(new insightsActions.EditRegionDemographic("journey", null));
  }

  onJourneySelect(journeyId: number) {
    if (journeyId == this.selectedJourneyId) {return; }
    this.selectedJourneyId = journeyId;
    this.store.dispatch(new actions.SetSelectedJourneyId(journeyId));
    this.store.dispatch(new Go({path: buildUrlRelative(this.params, `insights/journey${journeyId ? "/" + journeyId : ""}`)}))
    if (this.selectedJourneyId) {
      this.store.dispatch(new insightsActions.FetchTabs(journeyId, "Journey", "journey"))
      this.store.dispatch(new insightsActions.FetchDemographics(journeyId, "Journey", "journey"))
      this.store.dispatch(new insightsActions.FetchDemographicsConfig(userPreferenceKeys.standardDemographics("journey"), journeyId, "Journey", "journey"));
      this.store.dispatch(new insightsActions.FetchCustomTabsConfig(journeyId, "Journey", "journey"));
      this.store.dispatch(new insightsActions.FetchMarketLevelDemographics("journey", journeyId, "Journey"));
    }
  }

  changeTabs(tab: TopLevelTabType) {
    switch (tab) {
      case "Person Level":
        if (this.journeyTab == "Total Population") {
          this.store.dispatch(new actions.SetJourneyTab("Matched"))
        }
        break;
      case "Market Level":
        if (this.journeyTab != "Total Population") {
          this.store.dispatch(new actions.SetJourneyTab("Total Population"))
        }
        break;
    }
  }

  get stageFilters() {
    return _map(this.selectedStages, stage => {
      return {
        toggleSelf: () => this.store.dispatch(new actions.ToggleStage(stage)),
        name: getIconTemplate(stage.icon) + `&nbsp;${this.selectedBrand.name} > ${stage.name}`,
        type: "Stage",
        tab: "Stage"
      }
    })
  }

  get filters() {
    return [...this.stageFilters, ...this.counts.filters]
  }

  create() {
    this.store.dispatch(new Go({path: buildUrlRelative(this.params, "insights/journey-builder")}))
  }

  duplicate() {
    this.store.dispatch(new actions.DuplicateJourney(this.selectedJourneyId));
    this.actions$.pipe(
      fetchOutcome(actions.DuplicateJourney.type),
      take(1)
    ).subscribe(
      ({result}) => {
        this.snackbar.open("Journey successfully duplicated!", null, {
          panelClass: "success",
          duration: 5000
        })
        this.store.dispatch(new Go({path: buildUrlRelative(this.params, `insights/journey/${result.journey.id}`)}))
      },
      (error) => {
        let message;
        if (error.error && error.error.name) {
          message = "There is already a duplicate for this journey. Please rename or delete it and try again.";
        } else {
          message = "Something went wrong duplicating this journey. Please try again in a few minutes.";
        }

        this.snackbar.open(message, null, {
          panelClass: "danger",
          duration: 5000
        })
      }
    )
  }

  edit() {
    this.store.dispatch(new Go({path: buildUrlRelative(this.params, `insights/journey-builder/${this.selectedJourneyId}`)}))
  }

  delete() {
    this.store.dispatch(new actions.DestroyJourney(this.selectedJourneyId))
  }

  toggleFilter(filter: Filter) {
    if (filter.toggleSelf) { return filter.toggleSelf() };
    this.store.dispatch(new insightsActions.ToggleFilter(filter, "journey"))
  }

  clearFilters() {
    this.store.dispatch(new insightsActions.ClearFilters("journey"));
    this.store.dispatch(new actions.ClearSelectedStages());
  }

  setSplitScreenFocus(splitScreenFocus: FocusType) {
    this.store.dispatch(new insightsActions.SetSplitScreenFocus(splitScreenFocus, "journey"));
  }
}
