import { NgModule } from '@angular/core';
import { UnitFormComponent } from './unit-form.component';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';

@NgModule({
  imports: [
    PpcComponentsModule
  ],
  declarations: [
    UnitFormComponent
  ],
  exports: [
    UnitFormComponent
  ]
})
export class UnitFormModule { }
