<div class="demographics-header">
  <ppc-index-base *ngIf="isIndexMode" [insightsContext]="insightsContext"></ppc-index-base>
  <span class="new-widget-plus"
        *ngIf="customDemographics?.length > 0 && (demographicsPermission$ | async)?.create"
        (click)="addWidget()">
    <i class="fa fa-plus"></i> New Widget
  </span>
  <ppc-percent-calculation-select></ppc-percent-calculation-select>
  <div
    class="index-option"
    [ppcTooltip]="personaHasExcludedSegmentsTooltip"
    [tooltipDisabled]="!(disableShowIndex$ | async)">
    <ppc-checkbox [(ngModel)]="isIndexMode" (change)="toggleIndexMode()" [disabled]="disableShowIndex$ | async">Show Indices</ppc-checkbox>
  </div>
</div>

<div class="create-new-widget-container" *ngIf="customDemographics?.length == 0 && (demographicsPermission$ | async)?.create">
  <div class="create-new-widget header-btn" (click)="addWidget()">
    <img src="assets/icons/add-white.svg"/>
    New Widget
  </div>
  Click above to create a new custom widget.
</div>

<div
  class="packery-container"
  [packery]="bagName"
  [packeryModel]="customDemographics"
  [forceLayout$]="forceLayout$"
  (orderChanged)="saveDemographicsConfig($event)"
>
 <ppc-grow-v3-demographic-widget class="packery-item"
    *ngFor="let demographic of visibleCustomDemographics; trackBy: trackByDemographicId"
    [demographic]="demographic"
    [segmentCounts]="getSegmentCounts(demographic)"
    (hideDemographic)="hideDemographic(demographic)"
    [attr.data-id]="demographic.id"
  ></ppc-grow-v3-demographic-widget>
</div>
