import { Component, Input, ElementRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { map, distinctUntilChanged} from 'rxjs/operators';
import { sumBy } from 'lodash';

@Component({
  selector: 'ppc-virtual-scroll-item',
  templateUrl: './ppc-virtual-scroll-item.component.html',
  styleUrls: ['./ppc-virtual-scroll-item.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PpcVirtualScrollItemComponent implements AfterViewInit {
  @Input() item: any;
  height$: Observable<number>;

  constructor(public element: ElementRef) { }

  ngAfterViewInit() {
    this.height$ = interval(50).pipe(
      map(() => sumBy(this.element.nativeElement.children, "clientHeight")),
      distinctUntilChanged(),
    )
  }

}
