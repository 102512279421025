import { mergeMap, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store, select } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Observable, of as observableOf } from 'rxjs';
import { flatten, values, mapValues, groupBy, filter as _filter, flatMap, get, map as _map, sumBy, compact } from 'lodash';
import * as moment from 'moment';

import { AppState } from 'app/reducers';
import { fetchResource, ResetFetchState } from "app/shared/utils/fetch-state";
import * as actions from './measure-v3.actions';
import { OutcomeTimeframe } from './measure-v3.reducer';
import { Tab } from 'app/insights/insights.models';
import { SegmentSearchResponse } from 'app/insights/grow-v3/grow-count.service';
import { withLatestFrom } from 'rxjs/operators';
import { SubMarket, selectActiveMekko, selectActiveMekkoSubMarkets } from 'app/mekko/mekko.reducer';
import * as mekkoActions from 'app/mekko/mekko.actions';
import { environment } from 'environments/environment';
import { segmentSearchCountUrl } from '../shared/constants/id_analytics.urls';
import { dataServiceType } from '../shared/utils/utils';
import { tabsUrl, outcomeTimeframeUrl } from 'app/shared/constants/insights.urls';

@Injectable()
export class MeasureV3Service {

  @Effect()
  fetchOutcomeTimeframes$ = this.actions$.pipe(
    ofType(actions.FetchOutcomeTimeframes.type),
    (
      fetchResource(
        action => this.fetchOutcomeTimeframes(action.mekkoId).pipe(map(outcomeTimeframes => new actions.LoadOutcomeTimeframes(outcomeTimeframes)))
      )
    ))

  @Effect()
  saveOutcomeTimeframe$ = this.actions$.pipe(
    ofType(actions.SaveOutcomeTimeframe.type),
    (
      fetchResource(
        action => this.saveOutcomeTimeframe(action.outcomeTimeframe).pipe(map(outcomeTimeframe => new actions.LoadOutcomeTimeframe(outcomeTimeframe)))
      )
    ))

  @Effect()
  destroyOutcomeTimeframe$ = this.actions$.pipe(
    ofType(actions.DestroyOutcomeTimeframe.type),
    (
      fetchResource(
        action => this.destroyOutcomeTimeframe(action.outcomeTimeframe).pipe(map(() => new actions.RemoveOutcomeTimeframe(action.outcomeTimeframe)))
      )
    ))

  @Effect()
  loadCurrentTimeframe$ = this.actions$.pipe(
    ofType(actions.LoadOutcomeTimeframes.type),
    (
      fetchResource(
        () => {
          return this.fetchCurrentTimeframe().pipe(map(outcomeTimeframe => new actions.LoadOutcomeTimeframe(outcomeTimeframe)))
        }
      )
    ))

  @Effect()
  resetSelectedMekko$ = this.actions$.pipe(
    ofType(mekkoActions.SetSelectedMekko.type),
    map(() => new actions.ResetSelectedMekko()))

  @Effect()
  resetMeasureV3$ = this.actions$.pipe(
    ofType(actions.ResetMeasureV3.type),
    mergeMap(() => {
      return [
        new ResetFetchState(actions.FetchOutcomeTimeframes),
      ]
    }))

  @Effect()
  fetchTabs$ = this.actions$.pipe(
    ofType(actions.FetchTabs.type),
    fetchResource(
      action => this.fetchTabs(action.mekkoId).pipe(map(tabs => new actions.LoadTabs(tabs)))
    )
  )

  constructor(private store: Store<AppState>, private actions$: Actions, private http: HttpClient) {}

  fetchOutcomeTimeframes(mekkoId: number): Observable<OutcomeTimeframe[]> {
    return this.http.get(outcomeTimeframeUrl({mekkoId})) as Observable<OutcomeTimeframe[]>;
  }

  fetchTabs(mekkoId): Observable<Tab[]> {
    return this.http.get(tabsUrl() + `?resource_id=${mekkoId}&resource_type=Mekko&insights_context=grow`) as Observable<Tab[]>;
  }

  saveOutcomeTimeframe(outcomeTimeframe: OutcomeTimeframe): Observable<OutcomeTimeframe> {
    outcomeTimeframe.submarket_outcomes_attributes = outcomeTimeframe.submarket_outcomes;
    outcomeTimeframe.market_outcomes_attributes = outcomeTimeframe.market_outcomes;
    return this.http[outcomeTimeframe.id ? 'put' : 'post'](
      outcomeTimeframeUrl({mekkoId: outcomeTimeframe.mekko_id, id: outcomeTimeframe.id}),
      {outcome_timeframe: outcomeTimeframe}
    ) as Observable<OutcomeTimeframe>;
  }

  destroyOutcomeTimeframe(outcomeTimeframe: OutcomeTimeframe): Observable<OutcomeTimeframe> {
    return this.http.delete(outcomeTimeframeUrl({
      id: outcomeTimeframe.id
    })) as Observable<OutcomeTimeframe>;
  }

  fetchCurrentTimeframe(): Observable<OutcomeTimeframe> {
    return this.store.select("mekkos").pipe(select(selectActiveMekkoSubMarkets), take(1), mergeMap(subMarkets => {
      return (this.getCounts(subMarkets)).pipe(
        withLatestFrom(
          this.store.select("mekkos").pipe(select(selectActiveMekko))
        ),
        map(([response, mekko]) => {
          const {
            segment_counts: segmentCounts,
            market_counts: marketCounts,
            total_matched_count: totalMatched,
            total_modeled_count: totalModeled
          } = response;
          return {
            current: true,
            year: moment().year(),
            month: moment().month(),
            total: sumBy(subMarkets, "population"),
            matched: totalMatched || 0,
            modeled: totalModeled || 0,
            market_outcomes: _map(mekko.markets, market => {
              return {
                market_id: market.id,
                total: sumBy(_filter(subMarkets, subMarket => subMarket.market_id == market.id), "population"),
                matched: get(marketCounts, ["matched", market.id], 0),
                modeled: get(marketCounts, ["modeled", market.id], 0)
              }
            }),
            submarket_outcomes: subMarkets.map((subMarket: SubMarket) => {
              return {
                total: subMarket.population,
                matched: get(segmentCounts, subMarket.matched_short_id, 0),
                modeled: get(segmentCounts, environment.isTier3 ? subMarket.matched_short_id + '_weights' : subMarket.modeled_short_id, 0),
                sub_market_id: subMarket.id,
                market_id: subMarket.market_id
              }
            })
          }
        })) as Observable<OutcomeTimeframe>;
    }), )

  }

  getCounts(subMarkets: SubMarket[]): Observable<{segment_counts: {}, market_counts: {}, total_matched_count: number, total_modeled_count: number}> {

    const matchedIds = getIds(subMarkets, "matched");
    const modeledIds = getIds(subMarkets, "modeled");

    const allShortIds = flatMap(matchedIds, values).concat(flatMap(modeledIds, values))
    if (allShortIds.length === 0) {
      return observableOf({segment_counts: {}, market_counts: {}, total_matched_count: null, total_modeled_count: null})
    } else {
      let serviceType = dataServiceType();
      console.log('serviceType = ', serviceType);

      const payload = {
        matched_short_ids: matchedIds,
        modeled_short_ids: modeledIds,
        focused_matched_short_ids: compact(flatten(values(getIds(subMarkets, "matched")))),
        focused_modeled_short_ids: compact(flatten(values(getIds(subMarkets, "modeled")))),
        demo_as_segments: true,
        current_view: "All",
        feature: "measure-v3",
        group_short_ids: {},
        service_type: serviceType
      }

      return this.http.post<SegmentSearchResponse>(segmentSearchCountUrl(), payload).pipe(
        map(res => ({
          segment_counts: res.segment_counts, market_counts: res.market_counts,
          total_matched_count: res.total_matched_count, total_modeled_count: res.total_modeled_count
        })))
    }
  }
}


function getIds(subMarkets: SubMarket[], idType: "matched" | "modeled"): {[marketId: number]: string[]} {
  return mapValues(groupBy(subMarkets, 'market_id'), subMarkets => compact(_map(subMarkets, `${idType}_short_id`)))
}
