import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { handleError } from "app/shared/utils/http-utils";
import { Observable } from "rxjs";
import {
  adminActivationProductUrl,
  adminAudienceProductUrl,
  adminLookalikeProductUrl,
  adminUnfusedAudUrl,
  adminUnfusedLalUrl,
} from 'app/shared/constants/segments.urls';

@Injectable()
export class AudiencesAdminService {

  constructor(private http: HttpClient) { }

  fetchAudiences(): Observable<any> {
    return this.http.get(adminAudienceProductUrl())
      .pipe(
        map((res: any) => res),
        catchError(handleError), );
  }

  fetchLaLs(): Observable<any> {
    return this.http.get(adminLookalikeProductUrl())
      .pipe(
        map((res: any) => res),
        catchError(handleError), );
  }

  fetchActivations(): Observable<any> {
    return this.http.get(adminActivationProductUrl())
      .pipe(
        map((res: any) => res),
        catchError(handleError), );
  }

  fetchUnfusedLals(): Observable<any> {
    return this.http.get(adminUnfusedLalUrl())
      .pipe(
        map((res: any) => res),
        catchError(handleError), );
  }


  fetchUnfusedAuds(): Observable<any> {
    return this.http.get(adminUnfusedAudUrl())
      .pipe(
        map((res: any) => res),
        catchError(handleError), );
  }

}
