<div class="ppc-tabs-group">
  <div class="ppc-tabs-group-fixed-header">
    <div class="ppc-tab-group-header-window" (mousewheel)="hasOverflow && onScroll($event)">
      <div #leftPaginator
        *ngIf="hasOverflow && canPaginateLeft"
        (click)="paginate('left')"
        class="ppc-tab-group-paginator left"
      >
          <i class="fa fa-chevron-left"></i>
        </div>
      <div #rightPaginator
        *ngIf="hasOverflow && canPaginateRight"
        (click)="paginate('right')"
        class="ppc-tab-group-paginator right"
      >
        <i class="fa fa-chevron-right"></i>
      </div>
      <div #tabGroupHeader
        class="ppc-tab-group-header"
        [style.transform]="tabGroupHeaderTransform"
      >
        <ng-container  *ngFor="let tab of tabs; let idx=index">
          <ng-container *ngIf="!tab.labelTemplate">
            <button #header
                    class="ppc-tab-group-header-tab"
                    [disabled]="tab.disabled"
                    [class.disabled]="tab.disabled"
                    (click)="setActive(tab.name, $event)"
                    [id]="tab.name"
                    [style.margin-right.px]="idx == tabs.length - 1 ? 0 : tabSpacing"
                    [class.selected]="isTabActive(tab)"
                    [ppcTooltip]="!tab.tooltipTemplate && (tab.tooltipContent || tab.name)"
                    [tooltipTemplate]="tab.tooltipTemplate"
                    [tooltipDisabled]="!tab.tooltipTemplate && !tab.tooltipContent && !overflow"
                    *ppcCheckOverflow="let overflow=hasXOverflow"
                    matRipple>
                {{tab.name}}
            </button>
          </ng-container>

          <button #header *ngIf="tab.labelTemplate"
                          class="ppc-tab-group-header-tab"
                          [disabled]="tab.disabled"
                          [class.disabled]="tab.disabled"
                          (click)="setActive(tab.name, $event)"
                          [id]="tab.name"
                          [style.margin-right.px]="idx == tabs.length - 1 ? 0 : tabSpacing"
                          [class.selected]="isTabActive(tab)"
                          matRipple
          >
            <ng-container [ngTemplateOutlet]="tab.labelTemplate"></ng-container>
          </button>
        </ng-container>

        <div class="ppc-tab-group-header-underline"
          [style.width.px]="underlineWidth"
          [style.transform]="underlineTransform"
          [style.display]="underlineDisplay"
        ></div>
      </div>
    </div>
    <div
      *ngIf="fixedRightHeaderTemplate"
      class="ppc-tabs-group-fixed-right-header" 
      [ngTemplateOutlet]="fixedRightHeaderTemplate"
    ></div>
  </div>

  <div class="ppc-tabs-fixed-header">
    <ng-content select="[fixed]"></ng-content>
  </div>
  <div 
    class="ppc-tabs-group-tabs" 
    [style.width.%]="tabs.length * 100" 
    [style.transform]="tabsTransform" 
    (cdkObserveContent)="projectedTabsChanged()"
  >
    <ng-content select="ppc-tab,ng-container"></ng-content>
  </div>
</div>
