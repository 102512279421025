import { ActionMenuItem } from 'app/audiences/action-menu/action-menu-item.model'

export const RESOURCE = {
  lookalike: "lookalikes",
  motivation: "motivation_audiences"
}

export const LALACTIONMENU: ActionMenuItem[] = [
  {
    title: 'View Detail',
    type: "ViewDetail",
    viewableJobStatus: ["pending", "processing"],
    disabled: false
  },
  {
    title: 'View Detail & Edit',
    type: "ViewDetail",
    viewableJobStatus: ["preliminary", "complete"],
    disabled: false
  },
  {
    title: 'Send to Partner',
    type: "SendToPartner",
    disabledJobStatus: ["pending", "processing", "preliminary"],
    disabled: true
  },
  {
    title: 'Send to Tardiis',
    type: "SendToTardiis",
    disabledJobStatus: ["pending", "processing", "preliminary"],
    disabled: true
  },
  {
    title: 'Create Index Report',
    type: "CreateIndexReport",
    disabledJobStatus: ["pending", "processing", "preliminary"],
    disabled: true,
  },
  {
    title: 'Download Excel',
    type: "DownloadExcel",
    disabledJobStatus: ["pending", "processing"],
    disabled: true
  },
  {
    title: 'Delete',
    type: "Delete",
    disabledJobStatus: ["processing", "preliminary"],
    disabled: true,
    confirmation: true
  }
];

export const MTVACTIONMENU: ActionMenuItem[] = [
  {
    title: 'View Detail',
    type: "ViewDetail",
    disabled: false
  },
  {
    title: 'Create Lookalike',
    type: "CreateLookalike",
    disabledJobStatus: ["processing"],
    disabled: true
  },
  {
    title: 'Send to Partner',
    type: "SendToPartner",
    disabledJobStatus: ["processing"],
    disabled: true
  },
  {
    title: 'Send to Tardiis',
    type: "SendToTardiis",
    disabledJobStatus: ["processing"],
    disabled: true
  },
  {
    title: 'Create Index Report',
    type: "CreateIndexReport",
    disabledJobStatus: ["processing"],
    disabled: true,
  }
];

export const OUTACTIONMENU: ActionMenuItem[] = [
  {
    title: 'Send to Partner',
    type: "SendToPartner",
    disabledJobStatus: ["processing"],
    disabled: true
  },
  {
    title: 'Send to Tardiis',
    type: "SendToTardiis",
    disabledJobStatus: ["processing"],
    disabled: true
  },
  {
    title: 'Create Index Report',
    type: "CreateIndexReport",
    disabledJobStatus: ["processing"],
    disabled: true,
  }
];
