<div class="instructions">
  Visualize the common segments for your selected audience(s).
</div>

<div class="shared-interests-search-bar" [class.insights-focused]="insightsFocused$ | async">
    <div class="color-scale">
        <div class="gradient"></div>
        <div class="legends">
          <div class="legend" *ngFor="let legend of [0,100]">
            {{legend}}%
          </div>
        </div>
      </div>
  <app-select-box [(ngModel)]="focus" [items]="focusItems" (ngModelChange)="saveFocusSetting()"></app-select-box>
</div>
<div class="shared-interests" [class.insights-focused]="insightsFocused$ | async">
  
  <div class="overlap-label">
    % OF OVERLAP
  </div>
  <div class="shared-interests-segments">
      

    <ppc-shared-interest-segment *ngFor="let segment of slicedTree; trackBy: trackByShortId" [segment]="segment"
      (childrenLoaded)="childrenLoaded()"></ppc-shared-interest-segment>
  </div>
  <div class="shared-interests-background">
    <div class="border-box" *ngFor="let idx of [0,1,2,3,4,5,6,7,8]">

    </div>
  </div>
</div>
