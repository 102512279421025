<div class="partner-destination-wrapper">
  <div class="page-title">
    <h1 class="partner-destination-header">Partner Destination</h1>
    <button class="close-btn fa fa-2x fa-times" (click)="closeOverlay()"></button>
  </div>

  <div class="partner-source">
    <span>In order to send an audience, the first step is to setup a destination. To setup and send an audience, choose from a Frequently Used Partner below. More partners coming soon!</span>
  </div>

  <div class="audience-selection">
    <div class="audience-selection-header">
      <span class="selection-label">Audience</span>
    </div>
    <div class="shadow-block">
      <div [ngSwitch]="ppcObject?.type" class="margin-left-10">
        <span *ngSwitchCase="'audience'" ppcTooltip="Audience">
          <i class="fa fa-user"></i>
        </span>
        <span *ngSwitchCase="'lookalike'" ppcTooltip="Lookalike">
          <i class="fa fa-users"></i>
          </span>
        <span *ngSwitchCase="'outcome'" ppcTooltip="Outcome">
          <i class="fa fa-user-circle-o"></i>
        </span>
        <span *ngSwitchCase="'motivation'" ppcTooltip="Motivation">
          <i class="fa fa-trophy"></i>
        </span>
        <span>
          {{ppcObjectName}}
        </span>
      </div>
    </div>
  </div>

  <div class="section-header">
    <h4 class="partner-list-title">Frequently Used Partners</h4>
  </div>

  <div class="tiles-container">
    <activation-tile
      *ngFor="let partner of weightedPartners"
      [partner]="partner"
      (toggleOverlayEmitter)="closeOverlay()"
      (partnerSelection)="openDestinations(partner)"
      (addDestinationEvent)="addDestination(partner)"
    >
    </activation-tile>
  </div>

  <div class="section-header">
    <h4 class="partner-list-title">Other Partner Search</h4>
  </div>

  <div class="search-container">
    <span>Start typing a partner name:</span>

    <div class="partner-search">
      <div class="ppc-searchable-dropdown-search-box">
        <ppc-input #search [(ngModel)]="searchInput"
                   [noLabel]="true"
                   [noErrorContainer]="true"
                   (focusin)="searchOn()"
        >
          <div prefix>
            <i class="fa fa-search"></i>
          </div>
          <div suffix [class.hide]="!searchInput"
               (click)="clearSearch()">
            <i class="fa fa-close"></i>
          </div>
          <input type="text" />
        </ppc-input>
      </div>
      <div class="results-container" [ngClass]="{'searchable-dropdown-empty': searchInput === '' && filteredItems.length === 0}" *ngIf="isSearching">
        <div class="ppc-searchable-dropdown-error" *ngIf="filteredItems.length === 0">
          <span class="list-item">No Matches!</span>
        </div>
        <ul class="ppc-searchable-dropdown-items" *ngIf="filteredItems.length > 0">
          <li *ngFor="let item of filteredItems">
            <div class="list-item" (click)="selectPartner(item)">
              <span>{{item.name}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="setup-button-container">
      <button class="pds-btn pds-btn--primary" [disabled]="!currentPartner" (click)="setupPartner()">Set up selected</button>
    </div>
  </div>
</div>
