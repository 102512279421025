import {combineLatest, Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, CanActivateChild} from "@angular/router";
import {getAllRouteParams} from "app/shared/utils/utils";
import {CUSTOMER_SUPPORT_EXTERNAL_URL} from "app/shared/utils/constants";
import {validateSlugs, getContext, getSlugs, extendContext} from "app/hierarchy/hierarchy.utils";
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers";
import {FetchHierarchy} from "app/hierarchy/hierarchy.actions";
import {isLoaded} from "app/shared/utils/fetch-state";
import { Go } from "app/router/router.actions";
import { get } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import {simulateLinkClick} from 'app/shared/utils/utils'
import {HierarchyService} from 'app/services/hierarchy.service'

@Injectable()
export class ActiveClientGuard implements CanActivate, CanActivateChild {

  constructor(private store: Store<AppState>,
    private snackbar: MatSnackBar,
    private hierarchyService: HierarchyService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest(
      this.hierarchyService.loadedHierarchy$,
      this.hierarchyService.validContextSlugs$
    ).pipe(
      map(([ hierarchy, validContextSlugs ]) => {
        const slugs = getSlugs(route.params);
        const fallbackSlugs = getSlugs(extendContext(getContext(hierarchy, validContextSlugs)))

        const [ clientSlug ] = slugs
        if (!clientSlug) {
          this.openSnackbar(`Routing Error: The URL did not specify an active client`)
          setTimeout(() => this.store.dispatch(new Go({ path: [...fallbackSlugs, 'home'] })))
          return false
        }

        if (!validateSlugs(hierarchy, slugs)) {
          this.openSnackbar(`Invalid client: ${clientSlug}`)
          setTimeout(() => this.store.dispatch(new Go({ path: [...fallbackSlugs, 'home'] })))
          return false
        }

        return true
      })
    )
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }

  openSnackbar(message: string) {
    this.snackbar.open(message,
      'Contact Support',
      { duration: 10000, panelClass: ['warning'] })
      .onAction().subscribe(() =>
        simulateLinkClick({ target: '_blank',
          href: CUSTOMER_SUPPORT_EXTERNAL_URL }));
  }
}
