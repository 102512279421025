import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Actions, Effect, ofType } from "@ngrx/effects";

import * as actions from 'app/outcomes-v1/outcomes-v1.actions';
import { fetchResource, ResetFetchState } from "app/shared/utils/fetch-state";
import { OutcomeV1 } from "./outcomes-v1.model";
import { map, mergeMap } from 'rxjs/operators';
import { objToHttpParams } from "app/shared/utils/http-utils";
import { ChangeContext } from 'app/hierarchy/hierarchy.actions';
import { outcomesOverviewUrl, outcomesUrl } from "app/shared/constants/segments.urls";

@Injectable()
export class OutcomesV1Service {
  @Effect()
  fetchOutcomes$ = this.actions$.pipe(
    ofType(actions.FetchOutcomes.type),
    fetchResource(
      ({ useCases }) => this.getAllOutcomes(useCases).pipe(map(res => new actions.LoadOutcomes(res.data)))
    )
  )

  @Effect()
  fetchOverviewOutcomes$ = this.actions$.pipe(
    ofType(actions.FetchOverviewOutcomes.type),
    (
      fetchResource(
        () => this.getAllOverviewOutcomes().pipe(
          map(outcomes => {
            const updated_out = outcomes.data.map(outcome => {
              if (outcome.attributes.job_status === "pending") {
                outcome.attributes.count.people = null
              }
              return outcome.attributes
            })

            return new actions.LoadOutcomes(updated_out)})
        )
      )
    )
  )

  @Effect()
  contextChanges$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    mergeMap(() => [
      new ResetFetchState(actions.FetchOverviewOutcomes),
      new actions.ClearOutcomes()
    ]));

  constructor(private http: HttpClient, private actions$: Actions) { }

  getAllOutcomes(use_cases: string[] = ['insights']) {
    const params = objToHttpParams({ use_cases });
    return this.http.get<{ count: number, data: OutcomeV1[] }>(outcomesUrl(), { params });
  }

  getAllOverviewOutcomes() {
    return this.http.get<{ data }>
    (outcomesOverviewUrl(), {});
  }
}

