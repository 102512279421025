import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudiencesAdminService } from './audiences-admin.service';
import { AudiencesAdminComponent } from "./audiences-admin.component";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import {RouterLink, RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    AudiencesAdminComponent,
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    PpcComponentsModule,
    RouterModule
  ],
  exports: [ AudiencesAdminComponent ],
  providers: [ AudiencesAdminService ]
})

export class AudiencesAdminModule { }
