import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter, combineLatest, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Node } from './root-nodes/root-node.interface';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { isEmpty } from 'lodash';
import { AppState } from 'app/reducers';
import { fullContext } from 'app/hierarchy/hierarchy.reducers';
import { PERMISSION_INSIGHTS } from 'app/shared/utils/constants';
import { SegmentLike } from '../models/segment-like.model';
import {BaseSegmentPicker} from "../base-components/base-segment-picker";

@Injectable({
  providedIn: 'root'
})
export class SegmentPickerService extends BaseSegmentPicker {
  open$: Subject<boolean> = new Subject<boolean>()
  options$: Subject<SegmentPickerOptions> = new Subject();
  onClose$: Subject<SegmentLike[]>;
  root$: Observable<Node[]>;

  constructor(public http: HttpClient, private router: Router, private store: Store<AppState>) {
    super(http);
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => this.open$.next(false))
    this.root$ = fullContext(this.store).pipe(
      combineLatest(this.open$),
      filter(([context, isOpen]) => !isEmpty(context) && isOpen),
      switchMap(([{client, region}]) => this.getRoot(region, [PERMISSION_INSIGHTS]))
    );
  }

  open(options: SegmentPickerOptions): Subject<SegmentLike[]> {
    this.onClose$ = new Subject();
    this.open$.next(true);
    this.options$.next(options);
    return this.onClose$
  }

  close(value: SegmentLike[]) {
    this.open$.next(false);
    this.onClose$.next(value);
    this.onClose$.complete();
  }
}

export interface SegmentPickerOptions {
  multi: boolean;
  title1: string;
  title2: string;
  currentlySelected: SegmentLike[];
  insightsContext: string;
}
