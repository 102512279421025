import { Component, OnDestroy, Inject, Input, EventEmitter, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { Observable, combineLatest as observableCombineLatest, fromEvent, of } from 'rxjs';
import { Tab } from 'app/insights/insights.models';
import { InsightsContextType, INSIGHTS_CONTEXT } from 'app/insights/insights.constants';
import { map, switchMap, tap, filter, withLatestFrom } from 'rxjs/operators';
import { find, filter as _filter, chain, compact, last, map as _map, first, flatMap, findIndex, get, isEmpty } from 'lodash';
import { selectStandardDemographics, selectCustomTabDemographics } from 'app/insights/insights.reducer';
import { Demographic } from 'app/insights/insights.models';
import * as actions from 'app/insights/insights.actions';
import { isLoaded } from 'app/shared/utils/fetch-state';
import { PersonLevelCompareService } from 'app/insights/insights-components/person-level-compare/person-level-compare.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Persona, selectPersonas } from 'app/explore/explore.reducer';
import { isDefined } from "app/shared/utils/utils";

interface TabWithDemographics extends Tab {
  demographics: Demographic[];
}

interface DemographicWithTab extends Demographic {
  tab: TabWithDemographics;
}

@UntilDestroy()
@Component({
  selector: 'ppc-person-level-compare-widget-container',
  templateUrl: './person-level-compare-widget-container.component.html',
  styleUrls: ['./person-level-compare-widget-container.component.sass'],
  providers: [PersonLevelCompareService]
})
export class PersonLevelCompareWidgetContainerComponent implements OnDestroy {
  fetchObservables$ = observableCombineLatest(
    this.store.select("fetchStates", actions.FetchDemographics.type).pipe(select(isLoaded)),
    this.store.select("fetchStates", actions.FetchCustomTabsConfig.type).pipe(select(isLoaded)),
    this.store.select("fetchStates", actions.FetchTabs.type).pipe(select(isLoaded))
  ).pipe(filter(fetchStates => fetchStates.every(Boolean)))
  allDemographics: Demographic[];
  personas: Persona[];

  tabsWithDemographics$: Observable<TabWithDemographics[]> = this.fetchObservables$.pipe(
    switchMap(() => {
      return this.store.select("insights", this.insightsContext, "tabs").pipe(
        filter(tabs => !!find(tabs, {tab_key: "top_level_person"})),
        map(tabs => {
          const topLevelPersonTab = find(tabs, {tab_key: "top_level_person"});
          return _filter(topLevelPersonTab.children, tab => tab.tab_key === "person_level_demographics" || tab.tab_key === "person_level_custom")
        }),
        map(tabs => _filter(tabs, "visible")),
        switchMap(personLevelTabs => {
          const customTabIds = chain(personLevelTabs).filter({tab_key: "person_level_custom"}).map("id").value();
          const standardDemographicsVisbile = find(personLevelTabs, {tab_key: "person_level_demographics"});
          const demographicsObservables$ = compact([
            standardDemographicsVisbile ? this.store.select(selectStandardDemographics(this.insightsContext)) : null,
            ..._map(customTabIds, tabId => this.store.select("insights", this.insightsContext).pipe(select(selectCustomTabDemographics(tabId))))
          ])
          return isEmpty(demographicsObservables$) ? of([]) : observableCombineLatest(demographicsObservables$).pipe(
            map((groupedDemographics: Demographic[][]) => {
              return _map(personLevelTabs, tab => {
                let demographics;
                let name = tab.name;
                switch (tab.tab_key) {
                  case "person_level_demographics":
                    demographics = find(groupedDemographics, demographics => demographics.length && demographics.every(d => !!d.is_standard));
                    break;
                  case "person_level_custom":
                    demographics = find(groupedDemographics, demographics => demographics.length && demographics.every(d => d.custom_tab_id === tab.id));
                    if (this.insightsContext === "explore") {
                      const persona = find(this.personas, {id: tab.resource_id});
                      name = persona ? `${persona.name} > ${name}` : name;
                    };
                    break;
                }
                return {...tab, demographics: _filter(demographics, "visible")}
              })
            })
          )
        }),
        tap((tabsWithDemographics) => {
          this.allDemographics = flatMap(tabsWithDemographics, "demographics");
          // Reset active demographic if they hid it
          if (this.activeDemographic && !find(this.allDemographics, {id: this.activeDemographic.id})) {
            this.activeDemographic = null;
            this.activeTab = null;
          }
          // Set default active tab/demographic
          if (!this.activeDemographic) {
            this.activeTab = find(tabsWithDemographics, tab => tab.demographics.length) as TabWithDemographics;
            if (this.activeTab) {
              this.activeDemographic = first(this.activeTab.demographics);
            }
            this.tabHasDemographic.emit(!!this.activeTab);
          }
        })
      )
    })
  )
  nextDemographic$: Observable<Demographic> = observableCombineLatest(
    this.tabsWithDemographics$.pipe(map(tabsWithDemographics => {
      return flatMap(tabsWithDemographics, tab => _map(tab.demographics, demo => ({...demo, tab})))
    })),
    this.compareService.demographic$,
  ).pipe(
    map(([allDemographics, activeDemographic]) => {
      const idx = findIndex(allDemographics, {id: activeDemographic.id});
      return allDemographics[idx + 1]
    })
  )
  previousDemographic$: Observable<Demographic> = observableCombineLatest(
    this.tabsWithDemographics$.pipe(map(tabsWithDemographics => {
      return flatMap(tabsWithDemographics, tab => _map(tab.demographics, demo => ({...demo, tab})))
    })),
    this.compareService.demographic$,
  ).pipe(
    map(([allDemographics, activeDemographic]: [DemographicWithTab[], Demographic]) => {
      const idx = findIndex(allDemographics, {id: activeDemographic.id});
      return allDemographics[idx - 1]
    })
  )
  _activeDemographic: Demographic;
  @Input() isMultiWidget: boolean = false;
  @Input() widget: number;
  @Input() widgetCount;
  @Output() onRemoveWidget = new EventEmitter<number>();
  @Output() tabHasDemographic = new EventEmitter<boolean>();
  set activeDemographic(activeDemographic: Demographic) {
    this._activeDemographic = activeDemographic;
    this.compareService.activeDemographicId$.next(get(activeDemographic, "id"))
  }

  get activeDemographic() {
    return this._activeDemographic;
  }

  activeTab: TabWithDemographics;

  constructor(private store: Store<AppState>,
    public compareService: PersonLevelCompareService,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    this.store.select("explore").pipe(
      select(selectPersonas),
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(personas => this.personas = personas);

    fromEvent(window, "keydown").pipe(
      withLatestFrom(this.previousDemographic$, this.nextDemographic$),
      untilDestroyed(this)
    ).subscribe(([event, previousDemographic, nextDemographic]: [KeyboardEvent, DemographicWithTab, DemographicWithTab]) => {
      if (event.code === "ArrowRight" && nextDemographic) {
        this.setCompareDemographic(nextDemographic, nextDemographic.tab);
        event.stopImmediatePropagation();
      } else if (event.code === "ArrowLeft" && previousDemographic) {
        this.setCompareDemographic(previousDemographic, previousDemographic.tab);
        event.stopImmediatePropagation();
      }
    })
  }

  ngOnDestroy() { }

  setCompareDemographic(demographic: Demographic, tab: TabWithDemographics) {
    this.activeDemographic = demographic;
    this.activeTab = tab;
  }

  isLastWidget() {
    return last(this.widgetCount) === this.widget;
  }

  instructionsText() {
    switch (this.insightsContext) {
      case "grow":
        return "audiences";
      case "journey":
        return "stages";
      case "explore":
        return "personas";
    }
  }

  removeWidget() {
    this.onRemoveWidget.emit(this.widget);
  }
}
