import { chain, concat, compact, filter as _filter, find, flatten, flatMap, groupBy, isEmpty, keys, last, map as _map, flattenDepth, get, reduce } from 'lodash';
import { Filter, ResourceDefinedSegments } from 'app/insights/insights.models';
import { Persona } from 'app/explore/explore.reducer';
import { AudienceRule } from 'app/audiences-v2/audience-v2.model';
import { BuilderAudience, AudienceRuleGroup } from 'app/audience-builder/audience-builder.models';
import { dataServiceType } from 'app/shared/utils/utils';
import { MOLECULA } from 'app/shared/utils/constants';
import { isMolecula } from 'app/insights/insights.utils';

export type GroupType = "include" | "exclude";
export interface PersonaIdsType {included_ids: string[][], excluded_ids: string[][]}
export interface RuleGroupType {include: {}[], exclude: {}[]}

function buildIndividualContext(vendors: string[], filters: Filter[], persona: Persona) {
  const personaId = persona.id;
  if (isMolecula()) {
    let query;
    if (persona.is_all_data) {
      query = {
        isVendorQuery: true,
        context: { included: [vendors] },
        personaId
      }
    } else {
      query = {
        isVendorQuery: false,
        context: {
          included: persona.identifiers["included_ids"],
          excluded: persona.identifiers["excluded_ids"],
        },
        personaId
      }
    }
    return { primary: query };
  }
  let orQuery, field, isNestedQuery;
  if (persona.is_all_data) {
    orQuery = filters && filters.length ? [] : _map(vendors, id => ({ include: [[id]] }));
    field = filters.length ? field : "vendor_type_owner_list";
  } else {
    orQuery = [{
      include: persona.identifiers["included_ids"],
      exclude: persona.identifiers["excluded_ids"]
    }];
    isNestedQuery = true;
  }

  return {
    primary: {
      or: orQuery,
      isModeled: false,
      field: field,
      isNestedQuery: isNestedQuery,
      personaId
    },
    secondary: {}
  };
}

export function buildSegmentContext(vendors: string[], filters: Filter[], activePersona: Persona, selectedPersonas?: Persona[]) {
  const segmentContext = buildIndividualContext(vendors, filters, activePersona);
  const compareContext = _map(selectedPersonas, selectedPersona => buildIndividualContext(vendors, filters, selectedPersona));
  segmentContext["primary"]["compareContext"] = compareContext;
  return segmentContext;
}

export function getFilterIdentifiers(filters: Filter[]): string[][] {
  return _map(groupBy(filters, "demographicId"), group => _map(group, "shortId"));
}

export function buildOrGroups(identifiers: PersonaIdsType): RuleGroupType {
  const include = identifiers.included_ids.map(group => ({or: group}));
  const exclude = isEmpty(identifiers.excluded_ids) ? [{or: []}] : identifiers.excluded_ids.map(group => ({or: group}));
  return {include, exclude};
}

export function combineIdentifiers(filterIdentifiers: string[][], persona: Persona, audienceRules: AudienceRuleGroup): RuleGroupType {
  // existing segment logic in persona builder takes precedent with this logic. Duplicate identifiers are excluded from imported filters and persona identifiers
  const includeAudienceRuleGroups = ((find(audienceRules.items, {type: 'and'})) as AudienceRuleGroup).items as AudienceRuleGroup[];
  const excludeAudienceRuleGroups = ((find(audienceRules.items, {type: 'not'})) as AudienceRuleGroup).items as AudienceRuleGroup[];
  const allBuilderRuleGroups = concat(includeAudienceRuleGroups, excludeAudienceRuleGroups);
  const include = combineIds(filterIdentifiers, persona, includeAudienceRuleGroups, allBuilderRuleGroups, "include");
  const exclude = combineIds(filterIdentifiers, persona, excludeAudienceRuleGroups, allBuilderRuleGroups, "exclude");
  return {include, exclude};
}

export function combineIds(filterIdentifiers: string[][], persona: Persona, audienceRuleGroups: AudienceRuleGroup[], allBuilderRuleGroups: AudienceRuleGroup[], groupType: GroupType): {}[] {
  const allPersonaIds = compact(flatten(concat(persona.identifiers.included_ids, persona.identifiers.excluded_ids)));
  const audienceRuleGroupIds = flatMap(allBuilderRuleGroups, group => _map(group.items, "identifier"));
  const personaIds = chain(get(persona.identifiers, `${groupType}d_ids`))
    .map(group => _filter(group, personaId => !flatten(audienceRuleGroupIds).includes(personaId)))
    .filter(i => !isEmpty(i))
    .value();

  const filterAndPersonaIds = chain(filterIdentifiers)
    .map(group => _filter(group, filterId => !flatten(audienceRuleGroupIds.concat(allPersonaIds)).includes(filterId) && groupType !== "exclude"))
    .concat(personaIds)
    .filter(f => !isEmpty(f))
    .map(group => ({or: group}))
    .value();

  const builderAudienceIds = chain(audienceRuleGroups)
    .map(group => _map(group.items, "identifier"))
    .filter(i => !isEmpty(i))
    .map(group => ({or: group}))
    .value();

  const groupedIds = builderAudienceIds.concat(filterAndPersonaIds);
  return isEmpty(groupedIds) ? [{or: []}] : groupedIds;
}

export function setPersonaAudienceRules(name: string, isDefault: boolean, groups: RuleGroupType) {
  return {
    name: name,
    description: "",
    default: isDefault,
    rules: {
      include: {and: groups.include} as AudienceRule,
      exclude: {and: groups.exclude} as AudienceRule
    }
  }
}

export function getAudienceIdentifiers(audience: BuilderAudience, groupType: GroupType) {
  return chain(get(audience, `rules.${groupType}.and`))
    .map("or")
    .filter(i => !isEmpty(i))
    .value();
}

export function allPersonaIdentifiers(identifiers: PersonaIdsType) {
  return flatten(!isEmpty(identifiers) ? identifiers["included_ids"].concat(identifiers["excluded_ids"]) : []).sort();
}

export function allSegmentIdentifiers(segments): string[] {
  return compact(_map(segments, "identifier"));
}

export function fetchRowIdxByLastCellValue(rows: any[], value: string): number[] {
  return reduce(rows, (indices, row, index) => {
    if (last(row) === value) { indices.push(index + 1); }
    return indices;
  }, []);
}

export function groupTransformWS(resourceDefinedSegments: ResourceDefinedSegments) {
  if (!resourceDefinedSegments) { return; }
  const groups = resourceDefinedSegments;
  const includeGroup = get(groups, 'include');
  const excludeGroup = get(groups, 'exclude');
  let includeWsChunk = [], excludeWsChunk = [];

  const groupMapping = (item, idx, lastIdx) => {
    const wsChunk = []
    const row = (val) => val ? ['OR', val] : ['AND'];
    if (item.length > 1) {
      const segChunk = []
      // OR row (multiple)
      item.forEach(segment => segChunk.push(row(segment)));
      // AND row (more segments)
      if (idx < lastIdx) {
        segChunk.push(row(null));
      }
      wsChunk.push(...segChunk)
    } else if (item.length === 1) {
      // OR row (single)
      wsChunk.push(row(item.pop()));
      // AND row (more segments)
      if (idx < lastIdx) { // && wsChunk[wsChunk.length - 1] !== ['AND']
        wsChunk.push(row(null));
      }
    }
    return wsChunk;
  }

  if (Array.isArray(includeGroup) && includeGroup.length) {
    includeWsChunk = includeGroup.map((include, idx) => groupMapping(include, idx, includeGroup.length - 1));
    includeWsChunk = flattenDepth(includeWsChunk, 1);
    // INCLUDE HEADERS
    includeWsChunk.unshift(['Include', 'Segment Path']);
  }
  if (Array.isArray(excludeGroup) && excludeGroup.length) {
    excludeWsChunk = excludeGroup.map((exclude, idx) => groupMapping(exclude, idx, excludeGroup.length - 1));
    excludeWsChunk = flattenDepth(excludeWsChunk, 1);
    // INCLUDE HEADERS
    excludeWsChunk.unshift(['Exclude']);
  }
  return [...includeWsChunk, ...excludeWsChunk];
}

export function hasExcludedSegments(persona: Persona): boolean {
  return !isEmpty(compact(flatten(get(persona, ["identifiers", "excluded_ids"], []))));
}

export function removeDeletedSegments(persona: Persona, deletedSegmentIds: string[]): Persona {
  const identifiers = persona.identifiers;
  // remove deleted segments from persona
  keys(identifiers).forEach((identifierKey) => {
    const idSet = get(identifiers, identifierKey); // includedIds or excludedIds
    const extantIds: string[][] = idSet.map((ids) => ids.filter((id) => !deletedSegmentIds.includes(id))).filter(ids => !isEmpty(ids));
    identifiers[identifierKey] = !isEmpty(extantIds) ? extantIds : [];
  });
  return { ...persona, identifiers };
}

export const SEGMENTS_UNAVAILABLE = "segment(s) no longer available from the provider were automatically removed from the persona.";

export function deletedSegmentsError(deletedActivePersonaSegmentIds: string[]): string {
  return `${deletedActivePersonaSegmentIds?.length} ${SEGMENTS_UNAVAILABLE}`;
}

export const TOOLTIP_PERSONA_EXCLUDE_INDEX = "In order to view indices for this persona that includes an exclude group, create an index report from the persona Actions button.";

export const CREATE_EDIT_TOOLTIP_TEXT = "Personas cannot contain existing custom audiences, look-a-likes or outcome audiences. Remove the existing custom audience, look-a-like or outcome audience to edit this Persona.";

export const ALL_DATA_CANNOT_BE_EDITED = "All data cannot be edited";

export const ALL_DATA_CANNOT_BE_COPIED = "All data cannot be copied";

export const ALL_DATA_CANNOT_BE_DELETED = "All data cannot be deleted";

export const PERSONA_COPIED = "Persona copied.";

export const PERSONA_DELETED = "Persona deleted!";

export const UNABLE_TO_COPY_PERSONA = "Unable to copy Persona.";

export const UNABLE_TO_DELETE_PERSONA = "Unable to delete Persona!";

export const AUDIENCE_INCLUDES_NON_SEGMENTS = "Custom audiences cannot contain existing custom audiences, look-a-likes or outcome audiences. Remove the existing custom audience, look-a-like or outcome audience to save a new custom audience.";

export const IS_ALL_DATA_PERSONA = "All data cannot be converted to an audience.";

export const ACTION_NOT_PERMITTED = "Action Not Permitted. Contact Product Support.";

export const IS_ALL_DATA = "is_all_data";
