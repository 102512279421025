import {Directive, Inject, Input, Optional, Self} from "@angular/core";
import {Subject} from "rxjs";
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {delay, distinctUntilChanged} from "rxjs/operators";

@Directive({ selector: '[subjectModel]' })
export class SubjectModelDirective {

  valueAccessor: ControlValueAccessor;
  _subject: Subject<any>;

  constructor(@Self() @Optional() @Inject(NG_VALUE_ACCESSOR) valueAccessors: ControlValueAccessor[],
    @Self() @Optional() ngControl: NgControl) {
    this.valueAccessor = valueAccessors && valueAccessors[0]
                         || ngControl && ngControl.valueAccessor

    if (!this.valueAccessor) {throw `
      No ControlValueAccessor available for [subjectModel]. Try adding ngModel to the element like so:
      <mat-select ngModel [subjectModel]="mySubj$">...</mat-select>
    `}
  }

  @Input() set subjectModel(subject: Subject<any>) {
    // cleanup if this was already set before
    if (this._subject) {this._subject.complete()}

    this._subject = subject

    // Delay by one event loop tick to let children fill in.
    // e.g. MatSelect ignores `.writeValue()` if its child MatOptions aren't rendered yet
    this._subject.pipe(
      delay(0),
      distinctUntilChanged((a, b) => a === b || Number.isNaN(a) && Number.isNaN(b)) // `NaN === NaN` is false be careful
    ).subscribe(newValue =>
      this.valueAccessor.writeValue(newValue)
    );
    this.valueAccessor.registerOnChange(newValue => this._subject.next(newValue));
  }
}
