import {Action} from "@ngrx/store";

export class FetchCurveTypes implements Action {
  static readonly type = 'FETCH_CURVE_TYPES';
  readonly type = FetchCurveTypes.type;
}

export class LoadCurveTypes implements Action {
  static readonly type = 'LOAD_CURVE_TYPES';
  readonly type = LoadCurveTypes.type;

  constructor(public curveTypes: {[curveTypeName: string]: number}) {}
}

export type All = FetchCurveTypes | LoadCurveTypes
