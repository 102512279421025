<main class="card" [style.display]="show ? 'flex' : 'none' ">
  <div class="card-wrapper">
    <button mat-icon-button [matMenuTriggerFor]="menu" class="card_menu">
      <i class="fa fa-ellipsis-v"></i>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button *ngIf="plan.isComplete" mat-menu-item (click)="onSelectPlan(plan)" class="view_button">
        View Scenario
      </button>
      <button *ngIf="(isEditable())" mat-menu-item (click)="openCreateDialog(this.plan)" class="edit_button">
        Edit Scenario
      </button>
      <app-confirmation
        [isButton]="true"
        [disabled]="!(canDeleteScenario$ | async)"
        [buttonClass]="'edit_button mat-menu-item'"
        [buttonLabel]="'Delete Scenario'"
        [kontinue]="destroyPlan"
        [ppcTooltip]="deleteDisabledTooltip"
        [tooltipDisabled]="canDeleteScenario$ | async" >
      </app-confirmation>
      <ng-container *checkFeatureAccess="let canAccessChannels=canAccess; feature: 'channels'">
        <button mat-menu-item
                *ngIf="canAccessChannels && plan.isComplete && plan.channelId"
                (click)="onSelectChannel(plan)"
                class="view_scenario_button"
        >
          View Channels
        </button>
      </ng-container>
    </mat-menu>

    <div class="plan-dates">
      <div>
        <div class="plan-info-block-header">
          {{plan.getStartDateString() | date: 'MM/dd/yyyy' : 'UTC'}} -
        </div>
      </div>
      <div>
        <div class="plan-info-block-header end-date">
          {{plan.getEndDateString() | date: 'MM/dd/yyyy' : 'UTC'}}
        </div>
      </div>
    </div>

    <div class="title" [class.title-link]="plan.isComplete" (click)="gotoAllocationsPage()">
      <i *ngIf="plan.imported"  ppcTooltip="Plan was imported from Scenario Planner"></i>
      <span [title]="plan.planName">{{plan.planName}}</span>
    </div>

    <div *ngIf="(plan.hasError)">
      <div>
        <div class="plan-info-block-text text-center">
          <mat-error class="error-card text-center">{{planErrorMessage()}}</mat-error>
        </div>
      </div>
    </div>
    <div *ngIf="(plan.isPending)" class="pending-plan">
      <mat-spinner class="spinner text-center"></mat-spinner>
      <div>
        <div class="plan-name">
          <div class="plan-info-block-text small text-center">
            Please Wait...{{message}}
          </div>
        </div>
        <div>
          <div class="plan-info-block-header end-date text-center">
            It might take a minute or two, but we'll <br/> resume light speed once its ready.
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(plan.isComplete)">
      <div class="info-row">
        <div>
          <div class="plan-info-block-header">
            Budget
          </div>
          <div class="plan-info-block-text large subtle_text">
            {{plan.totalBudget | currency : currencyCode : 'symbol-narrow'}}
          </div>
        </div>

        <div *ngIf="!displayValues.computed">
          <div class="plan-info-block-header">
            Sales
          </div>
          <div  class="plan-info-block-text large subtle_text">
            {{plan.sales | currency : currencyCode : 'symbol-narrow'}}
          </div>
        </div>

        <div *ngIf="displayValues.computed">
          <div class="plan-info-block-header">
            {{curveLabelOutput(curveOverride, 'caps')}}
          </div>
          <div *ngIf="displayValues.computed" class="plan-info-block-text large subtle_text">
            {{plan.aggregatedReach / 100 | percent: '1.0-2'}}
          </div>
        </div>

        <div *ngIf="displayValues.frequency">
          <div class="plan-info-block-header">
            Frequency
          </div>
          <div *ngIf="displayValues.computed" class="plan-info-block-text large subtle_text">
            {{plan.frequency | number: '1.0-2'}}
          </div>
        </div>

      </div>

      <div class="bar-chart-card">
        <div class="plan-info-block-header">Media Tree</div>
        <div *ngFor="let planChannel of filteredChannels; let i = index" class="allocation-breakdown">
          <span class="color-id-circle" [style.background]="colors[i]"></span>
          <div class="allocation-name"
               [title]="planChannel.name">
            {{planChannel.name}}
          </div>
          <div class="allocation-pbar">
            <app-progress-bar
              [percent]="planChannel.allocationShare"
              [hideText]="'true'"
              [color]="colors[i]">
            </app-progress-bar>
          </div>
          <div class="allocation-percent">
            {{planChannel.allocationShare | number}}%
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="errorMsg" class="plan-info-block-text text-center">
      <br/>
      <mat-error class="error-card text-center">{{errorMsg}}</mat-error>
    </div>
  </div>
</main>

