<div class="person-level-compare-header" *ngIf="widgetsVisible; else warningMessage">
  <ppc-persona-compare-dropdown *ngIf="insightsContext === 'explore'"></ppc-persona-compare-dropdown>
  <ppc-index-base *ngIf="isIndexMode" [insightsContext]="insightsContext"></ppc-index-base>
  <div class="action-item-container">
    <span class="new-widget-plus"
          [class.disabled]="!canAddWidget()"
          [ppcTooltip]="addWidgetTooltip()"
          *ngIf="insightsContext === 'grow' && region?.max_compare_widgets > 1"
          (click)="canAddWidget() && addWidget()">
      <i class="fa fa-plus"></i> Add Widget
    </span>
    <ppc-percent-calculation-select></ppc-percent-calculation-select>
    <div class="person-level-compare-index-toggle">
      <ppc-checkbox [(ngModel)]="isIndexMode"
                    (change)="toggleIndexMode()"
                    [ppcTooltip]="disableShowIndexToolip$ | async"
                    [tooltipDisabled]="!(disableShowIndex$ | async)"
                    [disabled]="disableShowIndex$ | async">Show Indices</ppc-checkbox>
    </div>
    <div class="person-level-compare-legend-toggle" [matMenuTriggerFor]="legend" *ngIf="(compareService.canCompare$ | async)">
      <i class="fa fa-list"></i>
    </div>
    <mat-menu #legend="matMenu" class="person-level-compare-legend-menu">
      <div class="header-label">Legend</div>
      <div class="person-level-compare-legend-item" *ngFor="let target of compareTargets$ | async">
        <div class="color-bubble" [style.background-color]="target.legendColor"></div>
        <div class="legend-label">{{ target.label }}</div>
      </div>
    </mat-menu>
  </div>
</div>
  <ppc-person-level-compare-widget-container *ngFor="let widget of (widgetCount$ | async)"
                                             [isMultiWidget]="(widgetCount$ | async)?.length > 1"
                                             [widget]="widget"
                                             [widgetCount]="(widgetCount$ | async)"
                                             (onRemoveWidget)="removeWidget($event)"
                                             (tabHasDemographic)="showWidget($event)"></ppc-person-level-compare-widget-container>
<ng-template #warningMessage>
  <div class="warning-message">
    <h3>No Visible Widgets Available To Compare</h3>
    <p>Please unhide or create widgets to compare them.</p>
  </div>
</ng-template>
