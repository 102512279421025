import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PpcHttpService } from '../../services/ppc_http.service';
import { Region } from './region.model';
import {Actions, Effect, ofType} from "@ngrx/effects";
import {FetchRegions, LoadRegions} from "./regions.actions";
import {fetchResource} from "../../shared/utils/fetch-state";
import { regionsUrl } from '../../shared/constants/clients.urls';

@Injectable()
export class RegionService {

  @Effect()
  fetchRegions$ = this.actions$.pipe(
    ofType(FetchRegions.type),
    (fetchResource(
      () => this.getAllRegions().pipe(map(regions => new LoadRegions(regions)))
    )));

  constructor(private http: PpcHttpService, private actions$: Actions) { }

  getAllRegions(): Observable<Region[]> {
    return this.http.get(regionsUrl());
  }

  saveRegion(region: Region): Observable<Region> {
    const method = region.id ? 'put' : 'post';
    const url = region.id ? `${regionsUrl()}/${region.id}` : regionsUrl();
    return this.http[method](url, region);
  }

}
