import { combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { DragulaService } from "ng2-dragula";
import { cloneDeep } from 'lodash';
import { MatSnackBar } from "@angular/material/snack-bar";

import { selectActiveResourceSubMarkets, selectSurveyParentResource, selectRegionAndMarketLevelSurveys } from 'app/insights/insights.reducer';
import { MarketLevelSurvey, getMarketLevelSurveyPath } from 'app/insights/insights-components/market-level-survey/market-level-survey.interface';
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { Mekko, SubMarket } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';
import { Demographic, Tab } from 'app/insights/insights.models';
import { selectStandardDemographics, selectCensusDemographics, RegionDemographic } from 'app/insights/insights.reducer';
import {
  InsightsContextType,
  INSIGHTS_CONTEXT,
  ResourceType,
  CONTEXT_WITH_CUSTOM_TABS
} from 'app/insights/insights.constants';
import * as insightsActions from 'app/insights/insights.actions';
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';
import { Journey, JourneySubMarket } from 'app/journey/journey.models';

@Component({
  selector: 'insights-tab-config',
  templateUrl: './insights-tab-config.component.html',
  styleUrls: ['./insights-tab-config.component.sass']
})
export class InsightsTabConfigComponent extends InsightsResourceTracker implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  topLevelTabsBag = "top-level-tab-bag";
  marketLevelTabsBag = "market-level-tabs-bag";
  personLevelTabsBag = "person-level-tabs-bag";
  personLevelDemographicsBag = "person-level-demographics-bag";
  marketLevelSurveysBag = "manage-market-level-surveys-bag";
  censusDemographicsBag = "census-demographics-bag";
  shareThisDemographicsBag = "share-this-demographics-bag";
  marketLevelSurveys: MarketLevelSurvey[];
  getMarketLevelSurveyPath = getMarketLevelSurveyPath;
  standardDemographics: Demographic[];
  censusDemographics: RegionDemographic[];
  resourceId: number;
  resourceType: ResourceType;
  customTabUnderEdit$ = this.store.select("insights", this.insightsContext, 'customTabUnderEdit');
  parentResource$: Observable<Mekko | Journey>;
  tabs: Tab[];
  oldTabs: Tab[];
  canCreateCustomTabs$ = this.store.select('permissions', 'custom_tabs', 'create');
  subMarkets$: Observable<(SubMarket | JourneySubMarket)[]>;

  constructor(public store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private dragulaService: DragulaService,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super(store, insightsContext);
  }

  ngOnInit() {
    this.store.select("insights", this.insightsContext, 'tabs').pipe(
      map(cloneDeep),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(
      tabs => {
        this.tabs = tabs;
        this.oldTabs = cloneDeep(tabs);
      }
    )

    this.parentResource$ = this.store.pipe(select(selectSurveyParentResource(this.insightsContext)));
    this.subMarkets$ = this.store.pipe(select(selectActiveResourceSubMarkets(this.insightsContext)));

    this.dragulaService.setOptions(this.topLevelTabsBag, {
      moves: (el, container, handle) => /top-drag/.test(handle.className)
    })
    this.dragulaService.setOptions(this.marketLevelTabsBag, {
      moves: (el, container, handle) => /bottom-drag/.test(handle.className)
    })
    this.dragulaService.setOptions(this.personLevelTabsBag, {
      moves: (el, container, handle) => /bottom-drag/.test(handle.className)
    })
    this.dragulaService.setOptions(this.personLevelDemographicsBag, {
      moves: (el, container, handle) => /widget-drag-handle/.test(handle.className)
    })
    this.dragulaService.setOptions(this.marketLevelSurveysBag, {
      moves: (el, container, handle) => /widget-drag-handle/.test(handle.className)
    })

    this.dragulaService.dropModel.pipe(filter(([bagName]) => {
      return this.marketLevelSurveysBag === bagName
    }), takeUntil(this.ngUnsubscribe)).subscribe(() => this.saveMarketLevelSurveys());

    this.dragulaService.dropModel.pipe(filter(([bagName]) => {
      return this.censusDemographicsBag === bagName
    }), takeUntil(this.ngUnsubscribe)).subscribe(() => this.saveCensusDemographics());

    this.dragulaService.dropModel.pipe(filter(([bagName]) => {
      return this.personLevelDemographicsBag === bagName
    }), takeUntil(this.ngUnsubscribe)).subscribe(() => this.saveStandardDemographics());

    this.dragulaService.dropModel.pipe(filter(([bagName]) => {
      return [this.topLevelTabsBag, this.marketLevelTabsBag, this.personLevelTabsBag].includes(bagName)
    }), takeUntil(this.ngUnsubscribe)).subscribe(() => this.saveTopLevelTabs());

    observableCombineLatest(
      this.store.pipe(select(selectStandardDemographics(this.insightsContext)), map(cloneDeep)),
      this.store.pipe(select(selectRegionAndMarketLevelSurveys(this.insightsContext)), map(cloneDeep)),
      this.store.pipe(select(selectCensusDemographics(this.insightsContext)), map(cloneDeep)),
    ).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([standardDemographics, surveys, censusDemographics]) => {
        this.standardDemographics = standardDemographics;
        this.marketLevelSurveys = surveys;
        this.censusDemographics = censusDemographics;
      })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.dragulaService.destroy(this.topLevelTabsBag);
    this.dragulaService.destroy(this.marketLevelTabsBag);
    this.dragulaService.destroy(this.personLevelTabsBag);
    this.dragulaService.destroy(this.personLevelDemographicsBag);
    this.dragulaService.destroy(this.marketLevelSurveysBag);
    super.ngOnDestroy();
  }

  canCreateCustomTabForParent(parentTab: Tab) {
    if (parentTab.tab_key == "top_level_market" && !CONTEXT_WITH_CUSTOM_TABS.includes(this.insightsContext)) {
      return false;
    }
    return true;
  }

  saveTopLevelTabs() {
    this.reOrderTabs(this.tabs);
    this.store.dispatch(new insightsActions.SaveTabConfig(this.tabs, this.resourceId, this.resourceType, this.insightsContext))
  }

  toggleVisibility(tab) {
    tab.visible = !tab.visible
    if (!this.isInvalid) {
      this.store.dispatch(new insightsActions.SaveTabConfig(this.tabs, this.resourceId, this.resourceType, this.insightsContext))
    }
  }

  toggleWidgetVisibility(widget, tab) {
    widget.visible = !widget.visible
    switch (tab.tab_key) {
      case "person_level_demographics":
        this.saveStandardDemographics();
      case "market_level_census":
        this.saveCensusDemographics();
      case "market_level_survey":
        this.saveMarketLevelSurveys();
    }
  }

  saveMarketLevelSurveys() {
    this.store.dispatch(new insightsActions.SaveDemographicsConfig(this.marketLevelSurveys.map((survey, idx) => {
      return {
        id: survey.id,
        visible: !!survey.visible
      }
    }), userPreferenceKeys.surveys, this.resourceId, this.resourceType, this.insightsContext))
  }

  saveStandardDemographics() {
    this.store.dispatch(new insightsActions.SaveDemographicsConfig(this.standardDemographics.map((demographic) => {
      return {
        id: demographic.id,
        visible: !!demographic.visible,
        widget_type: demographic.widget_type,
        is_id_count: demographic.is_id_count,
      }
    }), userPreferenceKeys.standardDemographics(this.insightsContext), this.resourceId, this.resourceType, this.insightsContext))
  }

  saveCensusDemographics() {
    this.store.dispatch(new insightsActions.SaveDemographicsConfig(this.censusDemographics.map(regionDemographic => {
      return {
        id: regionDemographic.id,
        visible: !!regionDemographic.visible,
        widget_type: regionDemographic.widget_type,
        id_count: regionDemographic.id_count
      }
    }), userPreferenceKeys.regionLevelDemographics("census"), this.resourceId, this.resourceType, this.insightsContext))
  }

  cancel() {
    this.store.dispatch(new insightsActions.ToggleManage(this.insightsContext));
  }

  cantUnselect(tabs: Tab[], visible: boolean) {
    return visible && tabs.filter((tab) => tab.visible ).length <= 1
  }

  getBagName(topLevelTab: Tab) {
    return topLevelTab.tab_key == "top_level_person" ? this.personLevelTabsBag : this.marketLevelTabsBag;
  }

  getWidgets(tab: Tab) {
    switch (tab.tab_key) {
      case "person_level_demographics":
        return this.standardDemographics;
      case "market_level_census":
        return this.censusDemographics;
      case "market_level_survey":
        return this.marketLevelSurveys;
    }
  }

  notPredictions(topLevelTab: Tab) {
    return topLevelTab.tab_key !== 'top_level_predictions';
  }

  getWidgetBagName(topLevelTab: Tab, bottomLevelTab: Tab) {
    switch (topLevelTab.name) {
      case "Person Level":
        switch (bottomLevelTab.name) {
          case "Demographics":
            return this.personLevelDemographicsBag;
        }
        break;
      case "Market Level":
        switch (bottomLevelTab.name) {
          case "Census":
            return this.censusDemographicsBag;
          case "Share This":
            return this.shareThisDemographicsBag;
          case "Survey":
            return this.marketLevelSurveysBag;
        }
    }
  }

  onAddCustomTabClick(topLevelTab: Tab) {
    this.store.dispatch(new insightsActions.EditCustomTab({
      tab_key: topLevelTab.tab_key === "top_level_market" ? 'market_level_custom' : 'person_level_custom',
      resource_id: this.resourceId,
      resource_type: this.resourceType,
      parent_id: topLevelTab.id,
      name: "",
      insights_context: this.insightsContext
    }, topLevelTab, this.insightsContext));
  }

  get isInvalid() {
    if (!this.tabs) {return true; }
    return this.tabs.filter((t) => t.name !== 'Predictions').every(tabConfig => !tabConfig.visible)
  }

  isTabCustom(tab: Tab): boolean {
    return /custom/.test(tab.tab_key);
  }

  private reOrderTabs(tabs: Tab[]): void {
    tabs.forEach((tab, index) => {
      tab.order = index;
      if (tab.children?.length > 0) {
        this.reOrderTabs(tab.children);
      }
    });
  }
}
