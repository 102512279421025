import { takeUntil, take, filter, map } from 'rxjs/operators';
import { Component, OnDestroy, Inject } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { Subject, combineLatest as observableCombineLatest } from "rxjs";
import { Actions } from '@ngrx/effects';
import { values, cloneDeep, filter as _filter } from 'lodash';

import { fetchOutcome } from "app/shared/utils/fetch-state";

import { Tab } from "app/insights/insights.models";
import * as actions from 'app/insights/insights.actions';
import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { isDefined } from 'app/shared/utils/utils';

@Component({
  selector: 'ppc-custom-tab-form',
  templateUrl: './insights-custom-tab-form.component.html',
  styleUrls: ['./insights-custom-tab-form.component.sass']
})
export class CustomTabFormComponent implements OnDestroy  {
  customTab: Tab;
  oldCustomTab: Tab;
  parentTab: Tab;
  ngUnsubscribe = new Subject();
  personLevelTab: string;
  marketLevelTab: string;

  canDelete$ = this.store.select('permissions', 'custom_tabs', 'destroy')

  constructor(public store: Store<AppState>, public actions$: Actions, private snackbar: MatSnackBar, @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {
    observableCombineLatest(
      store.select("insights", this.insightsContext, 'customTabUnderEdit').pipe(filter(isDefined), map(cloneDeep)),
      store.select("insights", this.insightsContext, "customTabUnderEditParent").pipe(filter(isDefined)),
      store.select("insights", this.insightsContext, 'personLevelTab'),
      store.select("insights", this.insightsContext, 'marketLevelTab'),
    ).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([customTab, parentTab, personLevelTab, marketLevelTab]) => {
        this.customTab = customTab;
        this.oldCustomTab = cloneDeep(customTab);
        this.parentTab = parentTab;
        this.personLevelTab = personLevelTab;
        this.marketLevelTab = marketLevelTab;
      })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSaveClick() {
    this.store.dispatch(new actions.SaveCustomTab(this.customTab, this.insightsContext));
    this.actions$.pipe((fetchOutcome(actions.SaveCustomTab.type)),
      take(1), )
      .subscribe(
        saveOutcome => {
          this.store.dispatch(new actions.EditCustomTab(null, null, this.insightsContext));
          if (this.parentTab.tab_key == "top_level_person" && this.personLevelTab == this.oldCustomTab.name) {
            this.store.dispatch(new actions.SetPersonLevelTab(this.customTab.name, this.insightsContext))
          } else if (this.parentTab.tab_key == "top_level_market" && this.marketLevelTab == this.oldCustomTab.name) {
            this.store.dispatch(new actions.SetMarketLevelTab(this.customTab.name, this.insightsContext))
          }
        },
        error => this.alertWarn('Custom Tab could not be saved. Please try again.')
      );
  }

  alertWarn(message: string): void {
    this.snackbar.open(message, null, {
      duration: 2500,
      panelClass: ['danger']
    });
  }

  get reservedNames() {
    return this.parentTab.children.filter((tab) => tab.id != this.customTab.id).map(tab => tab.name.toLowerCase().trim());
  }

  nameValidators = [{
    isValid: text => this.hasUniqueName(),
    errorMessage: "Please provide a unique name for this Custom Tab"
  }];

  hasName() {
    return !!(this.customTab.name || "").trim();
  }

  hasUniqueName() {
    return this.hasName() && !this.reservedNames.includes(this.customTab.name.toLowerCase().trim());
  }

  get isValid(): boolean {
    return this.hasUniqueName();
  }

  cancelTab() {
    this.store.dispatch(new actions.EditCustomTab(null, null, this.insightsContext));
  }

  deleteCustomTab() {
    this.store.dispatch(new actions.DestroyCustomTab(this.customTab.id, this.insightsContext));
    this.actions$.pipe((fetchOutcome(actions.DestroyCustomTab.type)),
      take(1), )
      .subscribe(
        saveOutcome => {
          this.cancelTab();
          this.store.dispatch(new actions.FetchTabs(this.customTab.resource_id, this.customTab.resource_type, this.insightsContext))
        },
        error =>  this.alertWarn('Custom Tab could not be deleted. Please try again.')

      );
  }
}
