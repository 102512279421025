export class MediaTypeGoal {
  name: string;
  min: number;
  max: number;
  selected: boolean;
  validationMessages?: string[];

  constructor(args?) {
    this.validationMessages = [];
    if (args) {
      this.name = args.name;
      this.min = args.min;
      this.max = args.max;
      this.selected = args.selected;
    }
  }

  public toMediaType(isPercent = false): MediaTypeAllocation {
    return  {
      MediaType: this.name,
      Minimum: isPercent ? this.min / 100 : this.min,
      Maximum: isPercent ? this.max / 100 : this.max
    }
  }

  public validate( isPercentGoal: boolean, budget = 0): boolean {
    this.validationMessages = [];

    return (
      !this.selected ||
      (
        this.validateValueEntered()
        && this.validateMinLessThanMax()
        && ( isPercentGoal ? this.validatePercent() : this.validateBudget(budget) )
      )
    )
  }

  private validatePercent(): boolean {
    return this.runValidation(
      this.min >= 0 && this.max <= 100,
      MediaTypeGoal.VALIDATION_MESSAGES.percent_out_of_bounds
    )
  }

  private validateMinLessThanMax(): boolean {
    return this.runValidation(
      this.min <= this.max,
      MediaTypeGoal.VALIDATION_MESSAGES.min_greater_than_max
    )
  }

  private validateBudget(budget: number): boolean {
    return this.runValidation(
      this.min >= 0 && this.max <= budget,
      MediaTypeGoal.VALIDATION_MESSAGES.budget_out_of_bounds
    )
  }

  private validateValueEntered(): boolean {
    return this.runValidation(
      !this.selected || (this.isEntered(this.min) && this.isEntered(this.max)),
      MediaTypeGoal.VALIDATION_MESSAGES.values_not_entered
    )
  }

  private runValidation(validation: boolean, invalidMessage: string ): boolean {
    if (!validation) {
      this.validationMessages.push(invalidMessage);
    }
    return validation;
  }

  private isEntered(field): boolean {
    return field == 0 || (field != void(0) && field != "");
  }

  static validateTotals(mediaTypeGoals: MediaTypeGoal[], isPercent: boolean, budget = 0): string[] {
    const validationMessages = [];

    if ( MediaTypeGoal.validateTotalSelections(mediaTypeGoals) ) {
      const selectedMediaTypeGoals = mediaTypeGoals.filter( mtg => mtg.selected );

      if (isPercent) {
        MediaTypeGoal.validateTotalPercent(selectedMediaTypeGoals)
        || validationMessages.push(MediaTypeGoal.VALIDATION_MESSAGES.total_percent_out_of_bounds);
      } else {
        MediaTypeGoal.validateTotalBudgetAllocation(selectedMediaTypeGoals, budget)
        || validationMessages.push(MediaTypeGoal.VALIDATION_MESSAGES.total_budget_out_of_bounds);
      }

    } else {
      validationMessages.push(MediaTypeGoal.VALIDATION_MESSAGES.nothing_selected)
    }

    return validationMessages;
  }

  static validateTotalPercent(mediaTypeGoals: MediaTypeGoal[]): boolean {
    return mediaTypeGoals.reduce( (result, mtg) => result += mtg.max, 0 ) >= 100
  }

  static validateTotalBudgetAllocation(mediaTypeGoals: MediaTypeGoal[], budget): boolean {
    return mediaTypeGoals.reduce( (result, mtg) => result += mtg.max, 0 ) >= budget
  }

  static validateTotalSelections(mediaTypeGoals: MediaTypeGoal[]): boolean {
    return mediaTypeGoals.reduce( (result, mtg) => result || mtg.selected, false);
  }

  static VALIDATION_MESSAGES = {
    nothing_selected: "Select at least one media type",
    total_percent_out_of_bounds: "Max goal allocations must add up to 100% or greater",
    total_budget_out_of_bounds: "Max goal allocation must add up to the budget or greater",
    percent_out_of_bounds: "Adjust media allocations to fall between 0 and 100%",
    budget_out_of_bounds: "Adjust media allocations to be greater than 0 and less than the total budget",
    values_not_entered: "Enter allocations for all selected media types",
    min_greater_than_max: "Min allocation must be less than the Max allocation"
  }

}

export interface MediaTypeAllocation {
  MediaType: string,
  Minimum: number,
  Maximum: number
}
