import { ElementRef, Injectable } from '@angular/core';
import { CdkDropList } from '@angular/cdk/drag-drop';
import { get, reject, reverse, sortBy, keyBy, values, uniqBy, filter, map as _map, orderBy } from 'lodash';
import { Subject, BehaviorSubject } from 'rxjs';
import { scan, tap, share, map, publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class PpcDragService {
  public hoveredDropListWidth$ = new Subject();

  public dropListsWithLevels$ = new BehaviorSubject([] as {dl: CdkDropList, level: number}[]).pipe(
    scan((acc, newVals) => {
      return uniqBy([ ...newVals, ...acc, ], item => get(item, ["dl", "id"]))
    }, []),
    map(dropLists => filter(dropLists, (dropList: {dl: CdkDropList, level: number}) => get(dropList, ["dl", "element", "nativeElement", "isConnected"]))),
    publishReplay(1),
    refCount(),
  ) as BehaviorSubject<{dl: CdkDropList, level: number}[]>;

  public allDropLists$ = this.dropListsWithLevels$.pipe(
    map((entries: {dl: CdkDropList, level: number}[]) => orderBy(entries, ["level"], ["desc"]).map(entry => entry.dl))
  )

  constructor() { }

}
