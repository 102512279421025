<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>

<div class="journey-builder" *ngIf="builder">
    <div class="journey-builder-header">
        <div class="page-title">
          <span class="category">Insights</span> Journey Builder
        </div>
      </div>
  <div class="journey-builder-form" [class.edit-pane-open]="builder.isEditing">
      <div class="journey-builder-form-header">
          <div class="journey-builder-form-header-name">
              <ppc-input #nameInput [(ngModel)]="builder.name" [validators]="nameValidators$ | async" [autofocus]="true">
                <label>Journey Name</label>
              </ppc-input>
          </div>
          <ppc-checkbox
            [(ngModel)]="builder.default"
            class="checkbox-field"
            >
            Make this my default chart
          </ppc-checkbox>
          <ppc-checkbox
            [(ngModel)]="builder.has_transition_data"
            class="checkbox-field"
            >
            Display Transition Data
          </ppc-checkbox>
          <div class="journey-builder-form-header-actions">
            <button mat-button (click)="cancel()" *ngIf="!builder.isChanged">Cancel</button>
            <button mat-button ppcConfirmation modalTitle="You have unsaved changes"
                                               modalContent="Are you sure you want to discard these changes?"
                                               buttonContinueLabel="Discard"
                                               [kontinue]="cancel.bind(this)" *ngIf="builder.isChanged">Cancel</button>
            <button mat-stroked-button (click)="save()" [disabled]="!builder.valid || !nameValid">Save and Close</button>
          </div>
      </div>
      <div class="journey-builder-table">
        <div class="journey-builder-table-header">
          <div></div>
          <div *ngFor="let stage of builder.stages; last as isLast; first as isFirst; index as idx" class="stage-header">
            <div class="stage-header-name">
              <span *ngIf="builder.stageNameUnderEdit !== stage" (click)="builder.editStageName(stage)" [ppcTooltip]="nameTemplate(stage.name)" [tooltipDisabled]="isDisabled(stage.name)">{{truncate(stage.name)}}</span>
              <input #stageNameInput *ngIf="builder.stageNameUnderEdit === stage"
                      [(ngModel)]="builder.stageNameUnderEdit.name"
                      ppc-autofocus
                      (focusout)="builder.editStageName(null); validateNewStageName(stage);" />
              <span class="edit-stage-button" [matMenuTriggerFor]="appStageMenu">
                <i class="fa fa-pencil"></i>
              </span>
            </div>
            <div class="stage-header-count">
              <ng-container *ngIf="builder.getCountForStage(stage)">
                ({{ builder.getCountForStage(stage) | number }})
              </ng-container>
              <ng-container *ngIf="!builder.getCountForStage(stage)">
                  (No mappings)
              </ng-container>
            </div>
            <mat-menu #appStageMenu="matMenu">
              <button mat-menu-item (click)="builder.editStageName(stage)">Edit name</button>
              <button mat-menu-item *ngIf="!isFirst" (click)="builder.moveStageLeft(idx)">Move left</button>
              <button mat-menu-item *ngIf="!isLast" (click)="builder.moveStageRight(idx)">Move right</button>
              <button ppcConfirmation [kontinue]="builder.removeStage(stage)" mat-menu-item *ngIf="!isFirst || !isLast">
                Delete stage
              </button>
            </mat-menu>
          </div>
          <div class="learn-more-icon"
               ppcTooltip="Learn More"
               (click)="openCarousel()"
               [tooltipOffset]="{x: 2}">
            <i class="fa fa-question-circle-o"></i>
          </div>
        </div>
        <div *ngFor="let brand of builder.brands; first as isFirst; last as isLast; index as brandIdx" class="journey-brand">
          <div class="brand-label">
            <span *ngIf="builder.brandNameUnderEdit !== brand" (click)="builder.editBrandName(brand)" [ppcTooltip]="nameTemplate(brand.name)" [tooltipDisabled]="isDisabled(brand.name)">{{ truncate(brand.name) }}</span>
            <input *ngIf="builder.brandNameUnderEdit === brand"
                   [(ngModel)]="builder.brandNameUnderEdit.name"
                   ppc-autofocus
                   (focusout)="builder.editBrandName(null); validateNewBrandName(brand)" />
            <span class="edit-brand-button" [matMenuTriggerFor]="appBrandMenu">
              <i class="fa fa-pencil"></i>
            </span>
            <mat-menu #appBrandMenu="matMenu">
              <button mat-menu-item (click)="builder.editBrandName(brand)">Edit name</button>
              <button mat-menu-item *ngIf="!isFirst" (click)="builder.moveBrandUp(brandIdx)">Move up</button>
              <button mat-menu-item *ngIf="!isLast" (click)="builder.moveBrandDown(brandIdx)">Move down</button>
              <button ppcConfirmation [kontinue]="builder.removeBrand(brand)" mat-menu-item *ngIf="!isFirst || !isLast">
                Delete brand
              </button>
            </mat-menu>
          </div>
          <div *ngFor="let stage of builder.stages; last as isLast; index as idx;" class="journey-sub-market" [style.background]="getBackgroundForSubMarket(brand, stage, brandIdx)" [class.white-text]="!builder.noSegmentsForSubMarket(brand, stage)">
            <div class="add-stage-button" (click)="builder.addStage(idx)" *ngIf="builder.stages.length < 10">
              <div>+</div>
            </div>
            <div class="journey-sub-market-container" *ngIf="builder.getSubMarket(brand, stage)">
              <div class="journey-stage-icon-picker">
                <ppc-icon-picker [(ngModel)]="stage.icon" *ngIf="isFirst && !builder.isEditing" [icons]="icons"></ppc-icon-picker>
                <img src="assets/icons/{{stage.icon}}" *ngIf="isSvg(stage.icon) && (!isFirst || builder.isEditing)" class="svg" />
                <i class="fa fa-{{stage.icon}} fa-{{stage.icon}}-o" *ngIf="!isSvg(stage.icon) && (!isFirst || builder.isEditing)"></i>
              </div>
              <div class="priority-background">{{idx+1}}</div>
              <div class="map-segments-info" *ngIf="builder.noSegmentsForSubMarket(brand, stage)">
                Click <span class="open-sub-market-edit" (click)="builder.editSubMarket(builder.getSubMarket(brand, stage))">insert</span> to map a segment to {{truncate(brand.name)}} / {{truncate(stage.name)}}
              </div>
              <div class="map-segments-info" *ngIf="!builder.noSegmentsForSubMarket(brand, stage)">
                  {{truncate(brand.name)}} - {{truncate(stage.name)}}
              </div>
              <div class="overlay-background" (click)="builder.editSubMarket(builder.getSubMarket(brand, stage))"></div>
              <div class="edit-sub-market-button-container" *ngIf="showSubMarketIcons(brand, stage)">
                <ppc-zero-count-warning-icon
                  *ngIf="hasZeroCount(builder.getSubMarket(brand, stage))"
                  ppcTooltip="Click to learn why this stage has a zero count.">
                </ppc-zero-count-warning-icon>
                <button mat-icon-button
                  (click)="builder.editSubMarket(builder.getSubMarket(brand, stage))">
                  <i class="fa fa-pencil"></i>
                </button>
              </div>
            </div>
            <div class="add-stage-button right" *ngIf="isLast && builder.stages.length < 10" (click)="builder.addStage(idx + 1)">
              <div>+</div>
            </div>
          </div>
        </div>
        <div class="add-brand-row">
          <div><button mat-button (click)="builder.addBrand()" *ngIf="builder.brands.length < 4">Add Brand</button></div>
        </div>
      </div>
  </div>
</div>

<app-slide-in-overlay *ngIf="builder" [active]="!!builder.isEditing" [width]="400" (backgroundClick)="builder.editSubMarket({})">
  <ppc-journey-sub-market-form [builder]="builder"></ppc-journey-sub-market-form>
</app-slide-in-overlay>
