import { Action } from "@ngrx/store";
import { LookalikeV3 } from "app/lookalikes-v3/lookalike-v3.model";

export class CreateLookalike implements Action {
  static readonly type = 'CREATE_LOOKALIKE_V3';
  readonly type = CreateLookalike.type;

  constructor(public lookalike: LookalikeV3, public options) { }
}

export class CreateLookalikeFailure implements Action {
  static readonly type = 'CREATE_LOOKALIKE_FAILURE_V3';
  readonly type = CreateLookalikeFailure.type;

  constructor(public error) { }
}

export class FetchLookalikes implements Action {
  static readonly type = 'FETCH_LOOKALIKES_V3';
  readonly type = FetchLookalikes.type;

  constructor(public options) {}
}

export class FetchOverviewLookalikes implements Action {
  static readonly type = 'FETCH_OVERVIEW_LOOKALIKES';
  readonly type = FetchOverviewLookalikes.type;

  constructor(public options) {}
}

export class LoadLookalike implements Action {
  static readonly type = 'LOAD_LOOKALIKE_V3';
  readonly type = LoadLookalike.type;

  constructor(public lookalike: LookalikeV3) { }
}

export class LoadLookalikes implements Action {
  static readonly type = 'LOAD_LOOKALIKES_V3';
  readonly type = LoadLookalikes.type;

  constructor(public lookalikes: LookalikeV3[]) {}
}

export class ClearLookalikes implements Action {
  static readonly type = 'CLEAR_LOOKALIKES_V3';
  readonly type = ClearLookalikes.type;
}

export class UpdateLookalike implements Action {
  static readonly type = 'UPDATE_LOOKALIKE_V3';
  readonly type = UpdateLookalike.type;

  constructor(public lookalike: LookalikeV3, public options) {}
}

export class LoadUpdatedLookalike implements Action {
  static readonly type = 'LOAD_UPDATED_LOOKALIKE_V3';
  readonly type = LoadUpdatedLookalike.type;

  constructor(public lookalike: LookalikeV3) {}
}

export class DestroyLookalike implements Action {
  static readonly type = 'DESTROY_LOOKALIKE_V3';
  readonly type = DestroyLookalike.type;

  constructor(public lookalike: LookalikeV3, public options) {}
}

export class RemoveLookalike implements Action {
  static readonly type = 'REMOVE_LOOKALIKE_V3';
  readonly type = RemoveLookalike.type;

  constructor(public data) {}
}

export type All = CreateLookalike | CreateLookalikeFailure | FetchLookalikes
| FetchOverviewLookalikes | LoadLookalike | LoadLookalikes
| ClearLookalikes | UpdateLookalike | LoadUpdatedLookalike
| DestroyLookalike | RemoveLookalike;
