import { NgModule } from '@angular/core';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { PpcActivationOverlay } from "app/audiences/overview-v3/activation-overlay/activation-overlay.component";
import { ActivationOverlayService } from 'app/audiences/overview-v3/activation-overlay/activation-overlay.service';
import { DestinationModalModule } from "app/audiences/overview-v3/destination-modal/destination-modal.module";
import {ActivationTileModule} from "app/audiences/overview-v3/activation-tile/activation-tile.module";

@NgModule({
  imports: [
    PpcComponentsModule,
    DestinationModalModule,
    ActivationTileModule
  ],
  exports: [
    PpcActivationOverlay,
    DestinationModalModule,
  ],
  declarations: [
    PpcActivationOverlay,
  ],
  providers: [
    ActivationOverlayService,
  ]
})

export class ActivationOverlayModule { }
