import * as actions from 'app/segments-v2/segment-v2.actions';
import {keyBy, uniq, map} from 'lodash';
import {SegmentV2} from "app/audiences/discover/segment-v2.model";

export type State = SegmentV2[]

export function reducer(state: State = [], action: actions.All): State {
  switch (action.type) {
    case actions.LoadSegments.type:
      return state.concat(action.segments)
    default:
      return state;
  }
}

export function selectAllIdentifiers(segments: State): string[] {
  return uniq(map(segments, 'identifier'))
}

export function selectSegmentsById(segments: State): {[identifier: string]: any} {
  return keyBy(segments, 'identifier')
}
