import {catchError} from 'rxjs/operators';

import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import {handleError} from '../../../shared/utils/errors';
import { PpcHttpService } from "app/services/ppc_http.service";
import {Product} from "./product.model";
import { productsUrl } from '../../../shared/constants/clients.urls';

@Injectable()
export class ProductService {

  constructor(private http: PpcHttpService) { }

  deleteProduct(id: string): Observable<any> {
    return this.http.delete(`${productsUrl()}/${id}`).pipe(
      catchError(handleError));
  }

  getAllProducts(): Observable<Product[]> {
    return this.http.get(productsUrl()).pipe(
      catchError(handleError), );
  }
}
