<app-admin-breadcrumb></app-admin-breadcrumb>
<main>
<div *ngFor="let entityList of listOfEntities | keyvalue">
<div class="table-header">{{entityList.key}}</div>
<ngx-datatable class="data-table"
                [rows]="entityList.value"
                [rowHeight]="33"
                columnMode="force"
                sortType="single"
                [sorts]="tableSorts"
                (sort)="tableSortChanged($event)"
                [reorderable]="false"
                [headerHeight]="33"
                [footerHeight]="33"
                [messages]="messageTable"
>

  <ngx-datatable-column name="Id"
                        prop="id"
                        headerClass="numeric center"
                        cellClass="numeric center"
                        [minWidth]="75"
                        [maxWidth]="75">
  </ngx-datatable-column>

  <ngx-datatable-column name="Name"
                        headerClass="text"
                        cellClass="text"
                        [minWidth]="200"
                        [flexGrow]="2"
                        [comparator]="textComparator">
  </ngx-datatable-column>

  <ngx-datatable-column name="Slug"
                        headerClass="text"
                        cellClass="text"
                        [minWidth]="200"
                        [flexGrow]="2"
                        [comparator]="textComparator">
  </ngx-datatable-column>

  <ngx-datatable-column name="Created By"
                        prop="created_by"
                        headerClass="text"
                        cellClass="text"
                        [minWidth]="50"
                        [flexGrow]="2"
                        [comparator]="textComparator">
  </ngx-datatable-column>

  <ngx-datatable-column name="Updated By"
                        prop="updated_by"
                        headerClass="text"
                        cellClass="text"
                        [minWidth]="50"
                        [flexGrow]="2"
                        [comparator]="textComparator">
  </ngx-datatable-column>

  <ngx-datatable-column name="Created At"
                        prop="created_at"
                        headerClass="text"
                        cellClass="text"
                        [minWidth]="50"
                        [flexGrow]="2"
                        [comparator]="dateComparator">
  </ngx-datatable-column>

  <ngx-datatable-column name="Updated At"
                        prop="updated_at"
                        headerClass="text"
                        cellClass="text"
                        [minWidth]="50"
                        [flexGrow]="2"
                        [comparator]="dateComparator">
  </ngx-datatable-column>

</ngx-datatable>
</div>

</main>
