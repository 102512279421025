<ppc-tabs class="tabs" [tabSpacing]="10" [activeTab]="activeTab$ | async" (tabChange)="tabChange($event)" *ngLet="(isCompareMode$ | async) as isCompareMode">
  <ng-template #totalPopulationTabLabelTemplate>
    <div ppcTooltip="Total amount of consumers from data provider" [tooltipDelayTime]="500">
      <div class="tab-label top">
        Total Population
      </div>
      <div class="tab-label bottom" *ngIf="!isCompareMode">
        <i class="fa fa-user"></i>
        <span>{{ counts.totalPopulation | number }}</span>
      </div>
    </div>
  </ng-template>
  <ppc-tab name="Total Population" [labelTemplate]="totalPopulationTabLabelTemplate"  *ngIf="isTabTypeAllowed('Market Level')"></ppc-tab>
  <ng-template #matchedAddressableTabLabelTemplate>
    <div ppcTooltip="Unique PID matches" [tooltipDelayTime]="500">
      <div class="tab-label top">
        Matched
      </div>
      <div class="tab-label bottom" *ngIf="!isCompareMode">
        <i class="fa fa-user"></i>
        <span>{{ counts.totalMatchedCount | number }}</span>
      </div>
    </div>
  </ng-template>
  <ppc-tab name="Matched" [labelTemplate]="matchedAddressableTabLabelTemplate" *ngIf="isTabTypeAllowed('Person Level')"></ppc-tab>
  <ng-template #modeledAddressableTabLabelTemplate>
    <div ppcTooltip="Lookalike modeling on PIDs" [tooltipDelayTime]="500">
      <div class="tab-label top">
        Modeled
      </div>
      <div class="tab-label bottom" *ngIf="!isCompareMode">
        <i class="fa fa-user"></i>
        <span>{{ counts.totalModeledCount | number }}</span>
      </div>
    </div>
  </ng-template>
  <ppc-tab name="Modeled" [labelTemplate]="modeledAddressableTabLabelTemplate" *ngIf="isTabTypeAllowed('Person Level')"></ppc-tab>
</ppc-tabs>
<div class="journey-chart" *ngIf="journey && selectedBrand">
  <div class="brand-select-overlay">
    <div class="brand-select-overlay-info" *ngIf="!brandSelectInfoTooltipDismissed && journey.brands.length > 1">
      <div class="brand-select-overlay-info-caret"></div>
      <div class="brand-select-overlay-info-content">
        <div class="brand-select-overlay-info-text">Select primary product to display</div>
        <div class="brand-select-overlay-info-dismiss" (click)="brandSelectInfoTooltipDismissed = true"><i class="fa fa-close"></i></div>
      </div>
    </div>
  </div>
  <div class="journey-brand-selector" ppcRadioGroup [(ngModel)]="selectedBrandId" (ngModelChange)="selectBrandId($event)">
    <ppc-radio-button *ngFor="let brand of journey.brands; first as isFirst; index as idx" [value]="brand.id" (selected)="brandSelectInfoTooltipDismissed = true" [ppcTooltip]="nameTemplate(brand.name)" [tooltipDisabled]="isDisabled(brand.name)">
      <div class="swatch" [style.background-color]="brandColors[idx]"></div>
      {{truncate(brand.name)}}
    </ppc-radio-button>
    <div class="learn-more-icon"
         ppcTooltip="Learn More"
         (click)="openCarousel()"
         [tooltipOffset]="{x: 2}">
      <i class="fa fa-question-circle-o"></i>
    </div>
  </div>
  <div class="journey-stages" [class.column]="!(focusedComponent$ | async)" [ngClass]="getClassName()">
    <ppc-journey-stage *ngFor="let stage of journey.stages; index as stageIdx;"
                      [stage]="stage"
                      [nextStage]="journey.stages[stageIdx + 1]"
                      [color]="brandColors[selectedBrand?.order]"
                      [value]="getPercentageForGauge(stage)"
                      [transitionElements]="stagePairs[stage.id]"
                      [horizontal]="!!(focusedComponent$ | async)"
                      [journeyTab]="activeTab$ | async"
                      [brands]="journey.brands"></ppc-journey-stage>
  </div>


</div>
