import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { combineLatest as observableCombineLatest, Subject, Observable, of as observableOf } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Tab } from 'app/insights/insights.models';
import { ShareThisDayOfWeekComponent } from 'app/insights/insights-components/share-this/share-this-day-of-week/share-this-day-of-week.component';
import { ShareThisDaypartComponent } from 'app/insights/insights-components/share-this/share-this-daypart/share-this-daypart.component';
import { ShareThisDeviceComponent } from 'app/insights/insights-components/share-this/share-this-device/share-this-device.component';
import { ShareThisBrowserComponent } from 'app/insights/insights-components/share-this/share-this-browser/share-this-browser.component';
import { ShareThisTopDomainsComponent } from 'app/insights/insights-components/share-this/share-this-top-domains/share-this-top-domains.component';
import { ShareThisOverlapComponent } from 'app/insights/insights-components/share-this/share-this-overlap/share-this-overlap.component';

import { selectRegion } from 'app/hierarchy/hierarchy.reducers';
import * as actions from 'app/insights/insights.actions';
import { selectActiveMekko } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isDefined } from 'app/shared/utils/utils';

@UntilDestroy()
@Component({
  selector: 'ppc-share-this',
  templateUrl: './share-this.component.html',
  styleUrls: ['./share-this.component.sass']
})
export class ShareThisComponent implements OnInit, OnDestroy {
  @Input() tab: Tab; // the parent share-this tab
  iconFolder = "assets/icons/share-this/";
  destroyed$ = new Subject();
  isActiveTab$: Observable<boolean>;
  verticalType$: Observable<string>;
  currentTabKey: string;

  tabIcons = {
    share_this_day_of_week: "Calendar",
    share_this_daypart: "Clock",
    share_this_device: "Devices",
    share_this_browser: "Browser",
    share_this_top_domains: "Domains",
    share_this_overlap: "Mixers",
  }

  componentConfig = {
    share_this_day_of_week: ShareThisDayOfWeekComponent,
    share_this_daypart: ShareThisDaypartComponent,
    share_this_device: ShareThisDeviceComponent,
    share_this_browser: ShareThisBrowserComponent,
    share_this_top_domains: ShareThisTopDomainsComponent,
    share_this_overlap: ShareThisOverlapComponent,
  }

  constructor(private store: Store<AppState>, @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) { }

  ngOnInit() {
    this.isActiveTab$ = this.store.select("insights", this.insightsContext, "marketLevelTab").pipe(
      map(marketLevelTab => marketLevelTab == this.tab.name)
    )
    this.verticalType$ = this.insightsContext != "grow" ?
      observableOf("all") :
      this.store.select("mekkos").pipe(
        select(selectActiveMekko),
        filter(isDefined),
        select(mekko => mekko.vertical_type || "all"));
    observableCombineLatest(
      this.verticalType$,
      this.isActiveTab$,
      this.store.select("hierarchy").pipe(select(selectRegion), filter(Boolean)),
    ).pipe(
      filter(([verticalType, shareThisActive]) => shareThisActive),
      untilDestroyed(this)
    ).subscribe(
      ([verticalType]) => {
        this.store.dispatch(new actions.FetchShareThisData(1, verticalType, this.insightsContext))
      }
    )
  }

  ngOnDestroy() {
  }

  setShareThisTab(tabKey) {
    this.currentTabKey = tabKey;
  }

}
