import {catchError} from 'rxjs/operators';

import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { PpcHttpService, apiUrl } from "./ppc_http.service";
import { handleError } from "app/shared/utils/http-utils";
import { V3 } from '../shared/utils/constants';

export interface Language {
  value: string;
  viewValue: string;
}

@Injectable()
export class LanguageService {
  constructor(private http: PpcHttpService) { }

  languagesUrl(): string {
    return apiUrl(`/api/${V3}/languages`);
  }

  getLanguages(): Observable<Language[]> {
    return this.http.get(this.languagesUrl()).pipe(
      map((res: any) => {
        const body = res;
        return body.data.map(lang => {
          return { value: lang.value, viewValue: lang.text };
        });
      }),
      catchError(handleError), );
  }
}
