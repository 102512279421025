<div class="graph-container">
  <svg width="540" height="235" #svg>
  </svg>
  <div
    class="reach-line"
    #reachLine>
  </div>
  <mat-slider
    class="example-margin"
    [max]="dataLength"
    [min]="0"
    [step]="1"
    [value]="level"
    (input)="onDrag($event)"
    #slider>
  </mat-slider>
</div>
