import { UserService } from 'app/users/user.service';
import { Component, OnDestroy } from "@angular/core";
import {select, Store} from "@ngrx/store";
import { AppState } from "app/reducers";
import { logout } from "app/shared/utils/auth-utils";
import { Subject } from "rxjs";
import { Router } from '@angular/router';
import {loggedInUser} from "app/users/user.reducer";
import { filter, map } from "rxjs/operators";
import { isDefined } from '../utils/utils';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SupportLinksModalComponent } from 'app/shared/user-info/support-links-modal/support-links-modal.component'
import { SERVICE_URL } from 'app/shared/user-info/user-info-urls'
import { DataSourceService } from 'app/services/data-source.service';

@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.sass']
})

export class UserInfoComponent implements OnDestroy {

  ngUnsubscribe = new Subject();
  logout = logout;
  userFirstName$ = this.store.select('users').pipe(select(loggedInUser), filter(isDefined), map(u => u.first_name));
  dialogRef: MatDialogRef<SupportLinksModalComponent>;

  constructor(
    private store: Store<AppState>,
    public userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private dataSourceService: DataSourceService,
  ) { }

  redirectToAdminDashboard(): void {
    this.router.navigate(["admin-dashboard"]);
  }

  redirectToServiceUrl(): void {
    window.open(SERVICE_URL, '_blank');
  }

  toggleService(): void {
    this.dataSourceService.toggleService();
  }

  get serviceTypeText() {
    return this.dataSourceService.serviceTypeText;
  }

  get canToggleDataSource() {
    return this.dataSourceService.canToggleDataSource;
  }

  stopViewAs(): void {
    this.userService.revertUserSwitch().subscribe()
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openSupportLinksModal(linkSet: string) {
    this.dialog.closeAll();
    this.dialogRef = this.dialog.open(SupportLinksModalComponent,
      { data: { linkSet },
        panelClass: "no-padding" })
  }
}
