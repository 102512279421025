import { SegmentContext, Aggregation, ContextGroup, MoleculaSegmentContext, InsightsContextType, INSIGHTS_CONTEXT_EXPLORE, JourneyMoleculaSegmentContext } from './insights.constants';
import * as bodybuilder from 'bodybuilder';
import { flatMap, uniq, reduce, filter, get, chain, map, isEqual, find, first, isArray, flatten } from 'lodash';
import { Node } from "app/segment-picker/root-nodes/root-node.interface";
import { AudienceRule } from '../audiences-v2/audience-v2.model';
import { SegmentLike } from 'app/models/segment-like.model';
import { switchMap, map as rxMap } from 'rxjs/operators';
import { IndexBase } from './insights.reducer';
import { Observable } from 'rxjs';
import { MOLECULA, PRIMARY } from 'app/shared/utils/constants';
import { dataServiceType } from 'app/shared/utils/utils';
import { buildMoleculaJourneyContext } from 'app/journey/journey.utils';

export function buildMoleculaContext(
  segmentContexts: MoleculaSegmentContext | JourneyMoleculaSegmentContext,
  demographicIdentifiers: string[],
  filterIdentifiers: string[][],
  activeGWIQuestions: Node[], // TODO: Implement GWI questions into filters?
  indexMode: boolean,
  segments: {[identifier: string]: SegmentLike}, // might not need
  modelingUniverseProviders: string[], // might not need
  insightsContext: InsightsContextType,
  key: string = PRIMARY,
): MoleculaSegmentContext {
  const demoIdentifiers = map(demographicIdentifiers, id => ({[id]: id}));
  const identifiers = [
    ...map(flatten(filterIdentifiers), id => ({[id]: id})),
    ...demoIdentifiers,
    ...(segmentContexts?.["identifiers"]?.length ? segmentContexts?.["identifiers"] : [])
  ];
  const filters = map(filterIdentifiers);

  if (insightsContext === 'journey') {
    return buildMoleculaJourneyContext(segmentContexts as JourneyMoleculaSegmentContext, filters, identifiers, indexMode);
  }

  const context = { ...segmentContexts["context"], filters, key };

  const query = {
    serviceType: MOLECULA,
    isVendorQuery: segmentContexts["isVendorQuery"],
    includeIndexValues: false,
    context,
    identifiers
  }
  return query;
}

export function buildMoleculaIndexContext(context: MoleculaSegmentContext | JourneyMoleculaSegmentContext, insightsContext: InsightsContextType, indexBase?: IndexBase) {
  if (insightsContext === "journey") {
    return {...context, contexts: (context as JourneyMoleculaSegmentContext).contexts.map(context => ({ ...context, includeIndexValues: true, indexBase: indexBase?.shortId })) }
  } else {
    return { ...context, includeIndexValues: true, indexBase: indexBase?.shortId }
  }
}

export function buildQueryFromContext(segmentsContext: SegmentContext,
  demographicIdentifiers: string[],
  filterIdentifiers: string[][],
  activeGWIQuestions: Node[],
  additionalAggregations: {[identifier: string]: Aggregation} = {},
  indexMode: boolean,
  segments: {[identifier: string]: SegmentLike},
  modelingUniverseProviders: string[]) {
  let query: any = buildBaseContext(segmentsContext, !filterIdentifiers.length);

  addDemographicAggregations(query, demographicIdentifiers);

  if (filter(activeGWIQuestions, "children").length) {
    query = addAggregationsForGWIGroups(query, filter(activeGWIQuestions, "children"));
  }

  // Add filters
  // Inside demographic they're OR'd (ie male OR female)
  // then all demographics are AND'd together (ie (male OR female) AND (18-35 OR 35-54))
  reduce(filterIdentifiers, (query, filterGroup) => {
    return query.andQuery("bool", b => {
      return reduce(filterGroup, (bool, identifier) => {
        return bool.orQuery("term", "segments", identifier)
      }, b)
    })
  }, query);

  // Add any additional aggregations pass via segment context (ie subMarket counts for grow or stage counts for journey)
  reduce(segmentsContext.aggregations, (query, aggregation, aggregationKey) => {
    return query.aggregation("filter", aggregation.filter, aggregationKey);
  }, query)

  // Add any additional aggregations passed via param (anything extra from InsightsCountService)
  reduce(additionalAggregations, (query, aggregation, aggregationKey) => {
    return query.aggregation("filter", aggregation.filter, aggregationKey);
  }, query)

  if (indexMode) {
    addDemographicVendorTypeAggregations(query, demographicIdentifiers, segments, modelingUniverseProviders)
  }

  query.rawOption("is_modeled", segmentsContext.isModeled)

  return query.build();
}

export function buildBaseContext(segmentsContext: SegmentContext, matchNoneIfEmptyContext = true) {
  // Builds the "query" based solely on the segmentContext
  const query: any = bodybuilder();

  // If there is a situation where we're passing an empty context (new mekko etc) don't query anything
  if (!segmentsContext.or.length && matchNoneIfEmptyContext) {return query.andQuery("match_none")}

  query.andQuery("bool", b => {
    return reduce(segmentsContext.or, (query, contextGroup) => {
      const inclusions = contextGroup.include;
      const exclusions = contextGroup.exclude;
      if ((!exclusions || !exclusions.length) && !segmentsContext.isNestedQuery) {
        // This is for grow or comparisons primarily but also used for a top priority journey stage (nothing excluded)
        return reduce(contextGroup.include, (query, andGroup) => {
          return query.orQuery("bool", query => {
            return reduce(andGroup, (query, identifier) => {
              return query.andQuery("term", segmentsContext.field || "segments", identifier)
            }, query)
          })
        }, query)
      }

      if (segmentsContext.isNestedQuery) {
        // Persona queries
        return buildPrimaryNestedQuery(contextGroup, query, contextGroup.exclude);
      } else {
        // If there are exclusions; ie journey
        return query.orQuery("bool", b => {
          return reduce(contextGroup.include, (b, andGroup) => {
            b.andQuery("term", "segments", first(andGroup))
            if (exclusions && exclusions.length) {
              b.notQuery("bool", b => {
                return reduce(exclusions, (b, exclusion) => {
                  return b.orQuery("term", "segments", exclusion)
                }, b)
              })
            }
            return b
          }, b)
        })
      }
    }, b)
  })
  return query;
}

export function buildPrimaryNestedQuery(contextGroup, query, exclusions) {
  if (!contextGroup.include && contextGroup.length == 1) {
    return query.andQuery("bool", b => {
      return b.orQuery("term", "segments", contextGroup[0])
    })
  } else {
    return query.orQuery("bool", b => {
      return reduce(contextGroup.include, (b, andGroup) => {
        b.andQuery("bool", b => {
          return reduce(andGroup, (b, identifier) => {
            return b.orQuery("term", "segments", identifier)
          }, b)
        })
        if (exclusions && exclusions.length) {
          b.notQuery("bool", b => {
            return reduce(exclusions, (b, exclusion) => {
              return reduce(exclusion, (b, identifier) => {
                return b.orQuery("term", "segments", identifier)
              }, b)
            }, b)
          })
        }
        return b
      }, b)
    })
  }
}

export function addDemographicAggregations(query: bodybuilder.Bodybuilder, identifiers: string[]) {
  // Adds simple demographic aggregations keyed by the identifier
  return reduce(identifiers, (query, identifier) => {
    return query.aggregation("filter", {term: {segments: identifier}}, identifier)
  }, query)
}

export function addAggregationsForGWIGroups(query: bodybuilder.Bodybuilder, activeGWIQuestions: Node[]) {
  return reduce(activeGWIQuestions, (query, question) => {
    return query.aggregation("filter", {
      bool: {
        should: question.children.map(child => ({term: {segments: child.identifier}}))
      }
    }, question.identifier)
  }, query)
}

export function buildIndexingVendorTypesQuery(segmentContext: SegmentContext,
  demographicIdentifiers: string[],
  segments: {[identifier: string]: SegmentLike},
  filterIdentifiers: string[][],
  modelingUniverseProviders: string[],
  indexBase: IndexBase) {
  const query = bodybuilder();
  let topLevelBool;

  if (indexBase.shortId) {
    // Non Standard index base
    topLevelBool = {bool: {must: [{term: {segments: indexBase.shortId}}]}}
  } else {
    if (segmentContext.or && segmentContext.or.length > 0 && !segmentContext.or[0].include) {
      // Custom Persona context group is an array of identifiers
      // Otherwise identifiers are in include group objects
      topLevelBool = buildUniqBool("must", segmentContext.or, contextGroup => buildPersonaIndexingVendorTypesQuery(contextGroup, segments, modelingUniverseProviders, segmentContext))
    } else {
      topLevelBool = buildUniqBool("should", segmentContext.or, contextGroup => {
        return buildUniqBool("must", contextGroup.include, orGroup => {
          return buildUniqBool("should", orGroup, identifier => vendorTypeBoolForSegment(identifier, segments, modelingUniverseProviders, segmentContext))
        })
      })
    }
  }

  query.andQuery("bool", topLevelBool["bool"])

  if (filterIdentifiers.length) {
    // Add vendor type logic for filters as well
    const filterBool = buildUniqBool("must", filterIdentifiers, filterGroup => {
      return buildUniqBool("should", filterGroup, identifier => vendorTypeBoolForSegment(identifier, segments, modelingUniverseProviders, segmentContext))
    })

    query.andQuery("bool", filterBool["bool"]);
  }

  addDemographicVendorTypeAggregations(query, demographicIdentifiers, segments, modelingUniverseProviders)
  addDemographicAggregations(query, demographicIdentifiers);

  query.rawOption("is_modeled", segmentContext.isModeled)

  return query.build();
}

export function buildPersonaIndexingVendorTypesQuery(identifierGroup, segments, modelingUniverseProviders, segmentContext) {
  const mustOrShould: "must"|"should" = (!identifierGroup.include && identifierGroup.length == 1) ? "must" : "should";
  return buildUniqBool(mustOrShould, identifierGroup, identifier => vendorTypeBoolForSegment(identifier, segments, modelingUniverseProviders, segmentContext))
}

export function addDemographicVendorTypeAggregations(query: bodybuilder.Bodybuilder, demographicIdentifiers: string[], segments: {[identifier: string]: SegmentLike}, modelingUniverseProviders: string[]) {
  return reduce(demographicIdentifiers, (query, identifier) => {
    const segment = segments[identifier];
    if (!segment) {return query}
    if (segment.type === "Audience") {
      return query.aggregation("filter", vendorTypeBoolForAudience(segment, segments), getVendorTypeAggregationKey(segment))
    } else if (segment.type === "Lookalike") {
      // xinfer-lookalike
      return query.aggregation("filter", vendorTypeBoolForLookalike(modelingUniverseProviders), getVendorTypeAggregationKey(segment))
    } else {
      const vendorType = getVendorTypeAggregationKey(segments[identifier]);
      return query.aggregation("filter", {term: { vendor_type_owner_list: vendorType }}, vendorType);
    }
  }, query)
}

// Builds an query bool and ensures that no logic is duplicated
function buildUniqBool(type: "must"|"should", items: any[], reducer: Function) {
  const uniqItems = uniqByIsEqual(map(items, reducer));
  return {
    bool: {
      [type]: uniqItems
    }
  }
}

function vendorTypeBoolForSegment(identifier: string, segments: {[identifier: string]: SegmentLike}, modelingUniverseProviders: string[], segmentContext: SegmentContext) {
  if (segmentContext.field === "vendor_type_owner_list") {
    // Identifier is actually already a vendor_type (from Explore without filters)
    return {
      "term": {vendor_type_owner_list: identifier}
    }
  }
  const segment = segments[identifier];
  if (get(segment, "type") === "Audience") {
    return vendorTypeBoolForAudience(segments[identifier], segments);
  } else if (get(segment, "type") === "Lookalike") {
    return vendorTypeBoolForLookalike(modelingUniverseProviders)
  } else {
    return {
      "term": {vendor_type_owner_list: getVendorTypeAggregationKey(segment)}
    }
  }
}

function vendorTypeBoolForLookalike(modelingUniverseProviders: string[]) {
  return buildUniqBool("should", modelingUniverseProviders, provider => {
    return {
      term: {
        vendor_type_owner_list: provider
      }
    }
  })
}

function vendorTypeBoolForAudience(audience: SegmentLike, segments, or = false) {
  const uniqVendorTypesForAudience = getUniqVendorTypesForAudience(audience, segments)

  return buildUniqBool("must", uniqVendorTypesForAudience, orGroup => {
    return buildUniqBool("should", orGroup, vendorType => ({term: {vendor_type_owner_list: vendorType}}))
  })
}

function getUniqVendorTypesForAudience(audience: SegmentLike, segments): string[][] {
  return chain(audience.rules.include.and).map(orGroup => {
    return chain((orGroup as AudienceRule).or)
      .map((identifier: string) => segments[identifier])
      .map(getVendorTypeAggregationKey)
      .uniq()
      .sortBy()
      .value()

  }).sortBy(arr => arr[0]).uniqBy(arr => arr.join(",")).value()
}

export function getIdentifiersFromContext(context: SegmentContext | MoleculaSegmentContext | JourneyMoleculaSegmentContext, insightsContext: InsightsContextType) {
  let contextIdentifiers;
  if (isMolecula()) {
    contextIdentifiers = chain((context as MoleculaSegmentContext))
      .map(c => [get(c, "included"), get(c, "excluded")])
      .flattenDeep()
      .compact()
      .value()
  } else {
    contextIdentifiers = chain((context as SegmentContext).or)
      .map((group: ContextGroup) => [group.include, group.exclude])
      .flattenDeep()
      .compact()
      .value()
  }

  // Get identifiers from selected personas for explore person level compare for indexing
  if (context.compareContext && context.compareContext.length) {
    const compareContextIdentifiers = uniq(flatMap(context.compareContext, ctxt => getIdentifiersFromContext(ctxt.primary, insightsContext)));
    compareContextIdentifiers.forEach(id => contextIdentifiers.push(id));
  }
  return contextIdentifiers;
}

export function getVendorTypeAggregationKey(segment: SegmentLike) {
  if (segment.type == "Audience" || segment.type == "Lookalike") {
    return `${segment.identifier}-types`
  }
  return `${segment.vendor_name}-${segment.vendor_type}${segment.owner_name ? "-" + segment.owner_name : ''}`;
}

export function getIconTemplate(icon: string) {
  return icon.includes('.svg') ?
    '<img src="assets/icons/' + icon + '" class="svg" />' :
    '<i class="fa fa-' + icon + ' fa-' + icon + '-o"></i>'
}

export function rxWaterfall(observables: Observable<any>[]) {
  // Runs observables in series
  if (observables.length == 1) {return observables[0].pipe(rxMap(result => [result]))} else {
    return observables[0].pipe(
      switchMap(result => rxWaterfall(observables.slice(1, observables.length)
      ).pipe(rxMap(otherResults => [result, ...(otherResults as any[])]))))
  }
}

export function uniqByIsEqual(items) {
  const uniqItems = [];
  items.forEach(i => {
    if (!find(uniqItems, item => isEqual(item, i))) {
      uniqItems.push(i)
    }
  })
  return uniqItems
}

export const validForCustomAudience = (segments? : any) => {
  try {
    return !(segments.every((segmentLike: { type: string; }) => (segmentLike.type === "Segment" || segmentLike.type === "Motivation")));
  } catch (err) {
    throw new Error("Error occurred while validating custom audience");
  }
}

export const insightsContextIsExplore = (insightsContext: InsightsContextType): boolean => {
  return insightsContext === INSIGHTS_CONTEXT_EXPLORE;
}

export const isMolecula = (): boolean => dataServiceType() === MOLECULA;

export const ACTION_NOT_PERMITTED = "Action Not Permitted. Contact Product Support.";

export const AUDIENCE_INCLUDES_NON_SEGMENTS = "Custom audiences cannot contain existing custom audiences, look-a-likes or outcome audiences. Remove the existing custom audience, look-a-like or outcome audience to save a new custom audience.";
