
import { map } from 'rxjs/operators';
import {Component, Input, OnInit, Output, EventEmitter, Inject} from '@angular/core';
import { sortBy, get } from 'lodash';
import { Store } from '@ngrx/store';

import { chartColors } from 'app/insights/grow-v3/grow-v3.constants';
import { Filter } from 'app/insights/insights.models';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { TreeSegment } from 'app/insights/insights-components/person-level-shared-interests/person-level-shared-interests.component';
import { AppState } from 'app/reducers';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import * as insightsActions from "app/insights/insights.actions"
import {InsightsCountService} from "app/insights/insights-count.service";
import { getStoreState } from 'app/shared/utils/utils';

@Component({
  selector: 'ppc-shared-interest-segment',
  templateUrl: './shared-interest-segment.component.html',
  styleUrls: ['./shared-interest-segment.component.sass']
})
export class SharedInterestSegmentComponent implements OnInit {
  @Input() segment: TreeSegment;
  @Output() childrenLoaded = new EventEmitter();
  loadingChildren = false;
  expanded = false;

  constructor(private counts: InsightsCountService,
    private store: Store<AppState>,
    private segmentService: SegmentV2Service,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) { }

  ngOnInit() { }

  toggleSegment() {
    this.expanded = !this.expanded;
    if (!this.segment.children) {
      this.loadingChildren = true
      this.segmentService.fetchSegments({parent_id: this.segment.identifier, id_space: "metagraph"}).pipe(
        map(segments => sortBy(segments, "name")))
        .subscribe(
          segments => {
            this.segment.children = segments as TreeSegment[];
            this.loadingChildren = false;
            this.childrenLoaded.emit();
          }
        )
    }
  }

  get percent(): number {
    const percent = this.count / this.counts.totalCount;
    return isNaN(percent) ? 0 : percent;
  }

  get count(): number {
    return this.counts.getSegmentCount(this.segment.identifier);
  }

  get progressBarWidth(): string {
    return `${this.percent * 100}%`
  }

  get progressBarColor(): string {
    return chartColors(this.percent).css();
  }

  get children() {
    if (this.segment.children) {
      return sortBy(this.segment.children, [
        (segment: TreeSegment) => 0 - this.counts.getSegmentCount(segment.identifier),
        (segment: TreeSegment) => !this.counts.hasFilter({shortId: segment.identifier}),
        "name"
      ])
    }
  }

  toFilter(): Filter {
    const vendorDisplayNames = get(getStoreState(this.store), ['segmentsHierarchy', 'vendorDisplayNames']);
    return {
      type: "Interests",
      pathParts: prettyPathParts(this.segment, vendorDisplayNames),
      demographicId: this.segment.identifier,
      shortId: this.segment.identifier,
      tab: "Interests"
    }
  }

  toggleFilter() {
    this.store.dispatch(new insightsActions.ToggleFilter(this.toFilter(), this.insightsContext))
  }

  get isFiltered() {
    return this.counts.hasFilter(this.toFilter())
  }

}

