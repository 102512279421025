import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {AddressableCountMetadata} from "./addressable-count-metadata.model";
import * as actions from "./addressable-count-metadata.actions";
import {LoadAddressableCountsMetadata} from "./addressable-count-metadata.actions";
import {keyBy} from "lodash";

export type State = EntityState<AddressableCountMetadata>

const adapter = createEntityAdapter<AddressableCountMetadata>();

const initialState = adapter.getInitialState();

export function reducer(state: State = initialState, action: actions.All) {
  switch (action.type) {
    case actions.LoadAddressableCountsMetadata.type:
      const newMetadata = action.addressableCountsMetadata;
      return { ...state, ...newMetadata };
    default:
      return state
  }
}

const { selectEntities, selectAll } = adapter.getSelectors();

export const getAddressableCountMetadata = selectAll;
