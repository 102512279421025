import {Subject, combineLatest as observableCombineLatest} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {apiUrl} from "../services/ppc_http.service";
import {UserService} from "app/users/user.service";
import {environment} from "environments/environment";
import {PpcTabsComponent} from "app/shared/components/ppc-tabs/ppc-tabs.component"
import {Store, select} from "@ngrx/store";
import {AppState} from "app/reducers/index";
import { isLoaded } from 'app/shared/utils/fetch-state';
import { FetchHierarchy } from './../hierarchy/hierarchy.actions';

@Component({
  styleUrls: [ './login.component.sass' ],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  showLionLogin = environment.showLionLogin

  @ViewChild(PpcTabsComponent) tabs: PpcTabsComponent;

  model: any = {};
  loading: boolean = false;
  error: string = '';
  wasPasswordReset: boolean = false;
  lionLoginUrl: string;
  oktaUrl: string;
  returnUrl: string;
  isRequestingAccess: boolean;

  ngUnsubscribe = new Subject();

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {

    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ returnUrl = '', llUsername }) => {
        this.returnUrl = returnUrl;
        this.lionLoginUrl = `${apiUrl('/lion-login')}?RelayState=${btoa(JSON.stringify({returnUrl}))}`
        this.oktaUrl = `${apiUrl('/okta-login')}?RelayState=${btoa(JSON.stringify({returnUrl}))}`
        this.isRequestingAccess = !!llUsername
      })

    this.wasPasswordReset = this.route.snapshot.queryParams.passwordReset
  }

  ngAfterViewInit() {
    if (this.isRequestingAccess) {this.tabs.setActive("Request access")}

    observableCombineLatest(
      this.userService.loginAttempts().pipe(filter(Boolean)),
      this.store.select('hierarchy', 'hierarchy', 'clients'),
      this.store.select('fetchStates', FetchHierarchy.type).pipe(select(isLoaded), filter(Boolean))
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        ([_, clients]) => {
          if (clients.length) {
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.tabs.setActive("Request access");
          }
        }
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
