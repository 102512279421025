import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { ShareThisWidgetsComponent } from 'app/insights/insights-components/share-this/share-this-widgets.component';
import { AppState } from 'app/reducers';
import { InsightsContextType, INSIGHTS_CONTEXT } from 'app/insights/insights.constants';

@Component({
  selector: 'ppc-share-this-browser',
  templateUrl: '../share-this-widgets.template.html',
  styleUrls: ['./share-this-browser.component.sass']
})
export class ShareThisBrowserComponent extends ShareThisWidgetsComponent implements OnInit {

  constructor(@Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType, public store: Store<AppState>) {
    super("Browser", store, insightsContext)
  }

  ngOnInit() {
  }

}
