import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ppc-invalid-submarket',
  templateUrl: './invalid-submarket.component.html',
  styleUrls: ['./invalid-submarket.component.sass']
})

export class InvalidSubmarketComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    data = this.data;
  }
}
