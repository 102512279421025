import { BehaviorSubject, combineLatest } from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppState} from "app/reducers";
import {select, Store} from "@ngrx/store";
import {
  FetchHierarchyPermissions,
  SaveHierarchyPermissions
} from "app/hierarchy-permissions/hierarchy-permissions.actions";
import {HierarchyPermissions, HierarchyPermissionsSlugs} from "app/hierarchy-permissions/hierarchy-permissions.interface";
import {fetchOutcome} from "app/shared/utils/fetch-state";
import {Actions} from "@ngrx/effects";
import { MatSnackBar } from "@angular/material/snack-bar";
import {UserService} from 'app/users/user.service';
import {getChildren} from "app/hierarchy/hierarchy.utils";
import {toProperty, visitTreeNodes} from "app/shared/utils/utils";
import { filter, switchMap, take, tap } from "rxjs/operators";
import { selectActiveClients } from 'app/hierarchy/hierarchy.reducers';
import { User, uniqUserHash } from 'app/users/user.interface';
import { flatMap } from 'lodash'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.sass']
})

export class UserDetailComponent implements OnDestroy {

  user$ = this.route.params.pipe(
    tap(({ user_id }) => this.store.dispatch(new FetchHierarchyPermissions(user_id))),
    switchMap(({ user_id }) => this.userService.getUser(user_id)),
    toProperty()
  )

  hierarchyPermissions$ = combineLatest(this.user$, this.store.select('hierarchyPermissions')).pipe(
    select(([ user, permissionsByUser ]) => permissionsByUser[user && user.id]),
    filter(Boolean),
    toProperty()
  )

  permissionSlugs$ = new BehaviorSubject<Set<string>>(new Set())

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private store: Store<AppState>,
    private actions$: Actions,
    private userService: UserService
  ) {
    this.actions$.pipe(fetchOutcome(SaveHierarchyPermissions.type), untilDestroyed(this)).subscribe(
      () => this.snackbar.open(`Hierarchy Permissions Saved`,
        null,
        { duration: 4000,
          panelClass: ["check"] }),
      () => this.snackbar.open(`Something went wrong. Please try again.`,
        null,
        { duration: 5000,
          panelClass: ["danger"] })
    );

    this.hierarchyPermissions$.pipe(
      select((permSlugsByType: {[entityType: string]: string[]}) =>
        new Set(flatMap(permSlugsByType, x => x))),
      untilDestroyed(this)
    )
      .subscribe(this.permissionSlugs$)
  }

  ngOnDestroy() {}

  updateHierarchyPerms(slugs: Set<string>) {
    this.permissionSlugs$.next(slugs)
  }

  savePermissions() {
    const permissionSlugs = Array.from(this.permissionSlugs$.getValue())
    this.user$.pipe(take(1)).subscribe(user =>
      this.store.dispatch(new SaveHierarchyPermissions(user.id, permissionSlugs))
    )
  }

  goBack(user: User = null) {
    this.router.navigate(['../../user-settings'], { relativeTo: this.route, queryParams: { uniqUser: uniqUserHash(user) } });
  }

}
