export interface Region {
  id: string;
  name: string;
  slug: string;
  countries?: string[];
  country_ids: string[];
  feature_exclusions: string[];
  currency: string;
  es_index: string;
  molecula_index: string;
  max_compare_widgets: number;
  tab_bucket_limit: number;
}

export function emptyRegion(): Region {
  return {
    id: '',
    name: '',
    slug: '',
    countries: [],
    country_ids: [],
    feature_exclusions: [],
    currency: 'usd',
    es_index: '',
    molecula_index: '',
    max_compare_widgets: 1,
    tab_bucket_limit: 50 // 50 is the default size given at region level.
  };
}

export function exampleRegion(): Region {
  return {
    id: 'a',
    name: 'Example Region',
    slug: 'example-region',
    countries: ['US'],
    country_ids: ['100'],
    feature_exclusions: [],
    currency: 'usd',
    es_index: 'people-*',
    molecula_index: '',
    max_compare_widgets: 1,
    tab_bucket_limit: 50 // 50 is the default size given at region level.
  };
}
