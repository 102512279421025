import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PpcHttpService } from "../services/ppc_http.service";
import {Channel} from "./tardiis-channel.model";
import { channelsUrl, channelPath } from '../shared/constants/plans.urls';

@Injectable()
export class ChannelsService {

  static tardiisEndpoint = "apps.publicismedia.com/Tardiis/api";

  constructor(private http: PpcHttpService) {}

  channelURL(channelId: string = null): string {
    let url = channelPath();
    if (channelId) {
      url = url + `/${channelId}`
    }

    return url;
  }

  getChannels(): Observable<Channel[]> {
    return this.http.get(channelsUrl()).pipe(
      map(json => json.data.map(c => new Channel(c))),
      catchError(ChannelsService.parseError), );
  }

  getUnassignedChannelNames(): Observable<{name: string, id: string}[]> {
    return this.http.get(`${channelsUrl()}/unassigned`).pipe(
      map(res => res.data),
      catchError(err => { console.error(err); return observableThrowError(err) }), );
  }

  getChannel(channelId: string): Observable<Channel> {
    return this.http.get(this.channelURL(channelId)).pipe(
      map(json => new Channel(json.data)),
      catchError(ChannelsService.parseError), );
  }

  deleteChannel(channelId: string): Observable<any> {
    return this.http.delete(this.channelURL(channelId)).pipe(
      catchError(ChannelsService.parseError), );
  }

  importProject(tardiisProjectId): Observable<Channel> {
    return this.http.post(this.channelURL(), {template_id: tardiisProjectId}).pipe(
      map(json => new Channel(json.data)),
      catchError(ChannelsService.parseError), );
  }

  updateProject(channel: Channel): Observable<Channel> {
    return this.http.put(this.channelURL(channel._id), channel.toJSON()).pipe(
      map(json => new Channel(json.data)),
      catchError(ChannelsService.parseError), );
  }

  runProject(channelId: string): Observable<Channel> {
    return this.http.post(`${this.channelURL(channelId)}/run`, {}).pipe(
      map(json => new Channel(json.data)),
      catchError(ChannelsService.parseError), );
  }

  downloadTardiisReportCSV(channelId: string) {
    const downloadUrl = `${this.channelURL(channelId)}/tardiis_report.csv`;
    this.http.getDownload(downloadUrl).subscribe(
      res => this.http.downloadFile(res, 'tardiis_report.csv')
    );
  }

  static parseError(err) {
    if (err.json) {
      const parsedError = err.error;

      parsedError.isTardiisError = parsedError.message
                                   && parsedError.message.includes(ChannelsService.tardiisEndpoint);

      return observableThrowError(parsedError)
    }
    return observableThrowError(err)
  }

}
