import { Component, EventEmitter, Input, Output } from '@angular/core';
import {getChildren, assignFullSlugs, addHierarchyLevelTags, assignUniqueKeys, addParentLinks, assignFullNames} from "app/hierarchy/hierarchy.utils";
import {CheckboxStatus} from "app/shared/components/ppc-checkbox/ppc-checkbox.component";
import {ancestors, descendants} from "app/shared/utils/utils";
import {notInternalClient} from 'app/hierarchy/hierarchy.utils'
import {HierarchyEntity} from "app/hierarchy/hierarchy.interface";
import {AppState} from "app/reducers";
import {Store, select} from "@ngrx/store";
import {HttpClient, HttpParams} from "@angular/common/http";
import { map, take, tap } from 'rxjs/operators';
import { selectActiveClients } from 'app/hierarchy/hierarchy.reducers';
import { hierarchyTreeUrl } from '../shared/constants/clients.urls';

type HierarchyEntityPlus = HierarchyEntity & {parent: HierarchyEntity, fullSlug: string, fullName: string}

@Component({
  selector: 'ppc-hierarchy-selector',
  templateUrl: './hierarchy-selector.component.html',
  styleUrls: ['./hierarchy-selector.component.sass']
})
export class HierarchySelectorComponent {

  hierarchy$ = this.http.get<{data: {clients: HierarchyEntityPlus[]}}>(hierarchyTreeUrl(), {
    params: new HttpParams().set('available', '1')
  }).pipe(
    // get necessary tree information for active clients
    select(selectActiveClients),
    map(json => ({ clients: json['clients'].map(addHierarchyLevelTags)
      .map(assignFullSlugs)
      .map(assignUniqueKeys)
      .map(addParentLinks)
      .map(assignFullNames)
      .filter(this.hideInternalClients ? notInternalClient
        : () => true) }),
    )
  )

  @Input() authorizedNodes = new Set();
  @Input() hideInternalClients: boolean = false;
  @Input() byFullSlug: boolean = false;

  ngOnInit() { }

  @Output()
  change = new EventEmitter()

  constructor(public store: Store<AppState>, private http: HttpClient) {}

  getChildren = getChildren;

  getStatus = (path: HierarchyEntityPlus[]): CheckboxStatus => {
    const node = path[path.length - 1];
    return this.isChecked(node)                ? 'checked' :
      this.hasAnyCheckedDescendants(node) ? 'indeterminate' :
        'unchecked';
  };

  isChecked = (node: HierarchyEntityPlus): boolean => {
    return ancestors(node).concat(node).some(this.isExplicitlyAuthed);
  };

  hasAnyCheckedDescendants = (node: HierarchyEntityPlus): boolean =>
    descendants(node, getChildren).some(this.isChecked);

  checkNode = ({checked, path}) => {
    const node: HierarchyEntityPlus = path[path.length - 1];
    if (checked) {
      this.authorize(node)
    } else {
      ancestors(node).forEach(ancestor => {
        if (this.isExplicitlyAuthed(ancestor)) {
          this.deauthorize(ancestor);
          getChildren(ancestor).forEach(this.authorize)
        }
      });
      descendants(node, getChildren).concat(node).forEach(this.deauthorize);
    }
    this.change.emit(this.authorizedNodes)
  };

  isExplicitlyAuthed = (node: HierarchyEntityPlus) => this.authorizedNodes.has(this.byFullSlug ? node.fullSlug : node)

  authorize = (node: HierarchyEntityPlus) => this.authorizedNodes.add(this.byFullSlug ? node.fullSlug : node);

  deauthorize = (node: HierarchyEntityPlus) => this.authorizedNodes.delete(this.byFullSlug ? node.fullSlug : node);
}
