import { Injectable, OnDestroy } from "@angular/core";
import { BuilderAudience, AudienceRuleGroup } from './audience-builder.models';
import { get, map as _map, find, uniq } from 'lodash';
import { isEntrySegment, getAllSegments } from "app/audience-builder/audience-builder.utils";
import { SegmentV2 } from '../audiences/discover/segment-v2.model';
import { AudienceV2 } from 'app/audiences-v2/audience-v2.model';
import { AudienceV2Service } from '../audiences-v2/audience-v2.service';
import { AudienceBuilderService } from './audience-builder.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { getSegmentCount, includesAtLeastOneSegment } from './audience-builder.utils';

@UntilDestroy()
@Injectable()
export class CustomCreateService implements OnDestroy {
  public draggableGroups = [
    {
      type: "or",
      acceptsSegments: true,
      items: []
    },
  ]
  public groupTypeChangeAllowed = false;
  public deletableGroupTypes = ["or"];
  private audienceSegments: SegmentV2[];

  constructor(private audienceService: AudienceV2Service,
    private builderService: AudienceBuilderService,
  ) {
    this.builderService.audience$.pipe(
      map(audience => getAllSegments(audience.rules)),
      untilDestroyed(this),
    ).subscribe(audienceSegments => this.audienceSegments = audienceSegments)
  }

  ngOnDestroy() { }

  public isAudienceValid(audience: BuilderAudience): boolean {
    if (!audience || !audience.rules || audience.rules.items.length !== 2) {return false; }
    return audience.rules.items.every(group => this.isGroupValid(group as AudienceRuleGroup));
  }

  public saveAudience(audience: AudienceV2, audienceCloned: boolean = false) {
    if (audienceCloned) {
      const { id, ...noIdAudience } = audience;

      return this.audienceService.clone(noIdAudience);
    }
    return this.audienceService.save(audience);
  }

  public canSelectSegment(segment: SegmentV2) {
    return !find(this.audienceSegments, {identifier: segment.identifier}) && getSegmentCount(segment) > 0
  }

  public canSave(audience: BuilderAudience): boolean {
    return this.isAudienceValid(audience) &&
            !this.hasDuplicateSegments(audience) &&
            includesAtLeastOneSegment(audience.rules);
  }

  private isGroupValid(group: AudienceRuleGroup): boolean {
    if (isEntrySegment(group)) {return false; }
    return group.items.every(group => get(group, "type") === "or") && group.items.every((group: AudienceRuleGroup) => group.items.every(isEntrySegment))
  }

  private hasDuplicateSegments(audience: BuilderAudience): boolean {
    const segments = _map(getAllSegments(audience.rules), "identifier");
    return uniq(segments).length !== segments.length
  }
}
