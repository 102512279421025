import { merge as observableMerge, fromEvent as observableFromEvent, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Store } from "@ngrx/store";
import { AppState } from "app/reducers";

export abstract class ShareThisWidgetsComponent {
  widgetData$: Observable<any>;
  rootWidgetData$: Observable<any>;
  bagName: string;
  insightsFocused$: Observable<boolean>;
  forceLayout$: Observable<any>;

  constructor(public rootWidgetName: string, public store: Store<AppState>, public insightsContext: string) {
    this.widgetData$ = this.store.select("insights", insightsContext, "shareThisData", this.rootWidgetName);
    this.rootWidgetData$ = this.store.select("insights", insightsContext, "shareThisData", this.rootWidgetName, this.rootWidgetName);
    this.bagName = `share-this-${this.rootWidgetName.split(" ").join("-")}`;
    this.insightsFocused$ = this.store.select("grow", "growV3Focus").pipe(map(focus => focus == "insights"))

    this.forceLayout$ = observableMerge(
      this.widgetData$.pipe(debounceTime(100)),
      observableFromEvent(document, "click"),
      this.insightsFocused$.pipe(debounceTime(500))
    );
  }
}
