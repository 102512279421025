<div class="destinations-page-wrapper">
  <div class="destinations-upper-page">
    <div class="destinations-overlay-controls">
      <div class="return-to" (click)="returnToPreviousPage()">
        <i class="fa fa-arrow-left"></i>
        <span class="partners-text">Partners</span>
      </div>

      <button class="close-btn fa fa-2x fa-times" (click)="closeOverlay()"></button>
    </div>

    <div class="page-title" *ngIf="isAdmin && partner">
      <h1 class="destinations-header">{{partner.name}} Destinations</h1>
    </div>

    <div class="page-title" *ngIf="!isAdmin">
      <h1 class="destinations-header">Select Destinations</h1>
    </div>

    <div class="partner-warning" *ngIf="isPartnerRemoved()">
      <h3>THIS PARTNER HAS BEEN REMOVED</h3>
      <h5>PLEASE CONTACT PRODUCT SUPPORT WITH ANY QUESTIONS</h5>
    </div>

    <div *ngIf="isAdmin">
      <span>
        <mat-form-field appearance="outline">
          <span>Client</span>
          <mat-select [(ngModel)]="clientSlug" [disabled]="true">
            <mat-option *ngFor="let client of allClients" [value]="client.slug">
              {{client.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </span>
      <span>
        <mat-form-field *ngIf="allRegions?.length > 0" appearance="outline">
          <span>Regions</span>
          <mat-select [(ngModel)]="regionSlug" [disabled]="true">
            <mat-option *ngFor="let region of allRegions" [value]="region.slug">
              {{region.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </span>
      <span>
        <mat-form-field *ngIf="allBrands?.length > 0" appearance="outline">
          <span>Brand</span>
          <mat-select [(ngModel)]="brandSlug" [disabled]="true">
            <mat-option *ngFor="let brand of allBrands" [value]="brand.slug">
              {{brand.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </span>
      <span>
        <mat-form-field *ngIf="allProducts?.length > 0" appearance="outline">
          <span>Products</span>
          <mat-select [(ngModel)]="productSlug" [disabled]="true">
            <mat-option *ngFor="let product of allProducts" [value]="product.slug">
              {{product.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </span>
    </div>

    <div class="ppc-object-container" *ngIf="!isAdmin">
      <div class="object-selection-header">
        <span class="selection-label">Audience</span>
      </div>
      <div class="shadow-block">
        <div [ngSwitch]="ppcObject?.type" class="margin-left-10">
          <span *ngSwitchCase="'audience'" ppcTooltip="Audience">
            <i class="fa fa-user"></i>
          </span>
          <span *ngSwitchCase="'lookalike'" ppcTooltip="Lookalike">
            <i class="fa fa-users"></i>
          </span>
          <span *ngSwitchCase="'outcome'" ppcTooltip="Outcome">
            <i class="fa fa-user-circle-o"></i>
          </span>
          <span *ngSwitchCase="'motivation'" ppcTooltip="Motivation">
            <i class="fa fa-trophy"></i>
          </span>
          <span>
            {{ppcObjectName}}
          </span>
        </div>
      </div>
    </div>

    <div class="destinations-container" [class.disabled]="isPartnerRemoved()">
      <div class="destination-partner-header">

      </div>
      <ppc-activation-destination
        *ngFor="let destination of destinations$ | async"
        [isAdmin]="isAdmin"
        [destination]="destination"
        (unselectDestination)="removeDestinationFromSelected($event)"
        (selectDestination)="addDestinationFromSelected($event)"
      ></ppc-activation-destination>
    </div>
  </div>

  <div class="destinations-actions-container" *ngIf="!isAdmin">
    <ng-container *ngIf="isPartnerWithAdditionalDetails; else genericPartner">
      <button class="pds-btn pds-btn--secondary" (click)="redirectToNewDestination()">
        Add Destination
      </button>
    </ng-container>
    <ng-template #genericPartner>
      <destination-modal
        [class.disabled]="isPartnerRemoved()"
        [partner]="partner"
        [destinations]="destinations$ | async"
        (createDestination)="createDestination($event)"
      ></destination-modal>
    </ng-template>
    <app-confirmation
      class="mat-button delete-button full-tile"
      title="SendToPartner"
      [isCustomTemplate]="true"
      [kontinue]="sendToPartner.bind(this)"
      [modalTitle]="modalTitle"
      [buttonContinueLabel]="buttonConfirmText"
    >
      <button [disabled]="sendToPartnerDisabled$ | async" class="pds-btn pds-btn--primary">
        {{sendToPartnerText$ | async}}
      </button>
    </app-confirmation>
  </div>

  <div [class.disabled]="isPartnerRemoved()" class="destinations-actions-container" *ngIf="isAdmin">
    <ng-container>
      <button class="pds-btn pds-btn--tertiary" (click)="redirectToNewDestination()">
        Add Destination
      </button>
    </ng-container>
  </div>
</div>
