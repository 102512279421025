import {Component, ElementRef, OnDestroy} from "@angular/core";
import {Router, NavigationEnd} from "@angular/router";
import {animate, style, transition, trigger} from "@angular/animations";
import {Observable, Subscription, BehaviorSubject} from "rxjs";
import {map, take, distinctUntilChanged} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {BrandCategoryBucket} from "./brand-category-bucket.interface";
import {
  NAV_ADMIN,
  NAV_ADMIN_BRANDS,
  NAV_ADMIN_CLIENTS,
  NAV_ADMIN_GLOBAL,
  NAV_ADMIN_REGIONS,
  NAV_PLANS
} from "../shared/utils/constants";
import {AppState} from "../reducers/index";
import {Store, select} from "@ngrx/store";
import {activeContext, selectActiveClients} from "../hierarchy/hierarchy.reducers";
import {ChangeContext} from "app/hierarchy/hierarchy.actions";
import {groupBy, entries, sortBy, compact, chain} from 'lodash';
import {
  ContextSlugs,
  Hierarchy, HierarchyBrand, HierarchyClient, HierarchyContext, HierarchyProduct,
  HierarchyRegion
} from "app/hierarchy/hierarchy.interface";
import {getPath, getContext, getSlugs, getContextSlugs} from "app/hierarchy/hierarchy.utils";
import {ToolboxService} from 'app/services/toolbox.service'
import { UrlService } from 'app/services/url.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { toProperty } from 'app/shared/utils/utils'

@UntilDestroy()
@Component({
  selector: 'app-brand-selector',
  templateUrl: './brand-selector.component.html',
  styleUrls: ['./brand-selector.component.sass'],
  host: {
    '(document:click)': 'onClick($event)'
  },
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('225ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('175ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})

export class BrandSelectorComponent implements OnDestroy {
  isOpen$ = new BehaviorSubject(false);
  selectedPath$ = new BehaviorSubject('')

  hierarchy$ = this.store.select('hierarchy', 'hierarchy').pipe(
    select(selectActiveClients));

  selectedContext$ = combineLatest(this.hierarchy$, this.selectedPath$).pipe(
    map(([hierarchy, path]) => getContext(hierarchy, path)),
    toProperty())

  brandCategories$ = this.selectedContext$.pipe(
    map(({ region }) => region),
    distinctUntilChanged(),
    map(region => region ? this.putBrandsInCategories(region.brands) : []))

  canCreateBrands$   = this.store.select('permissions', 'brands',   'create');
  canCreateClients$  = this.store.select('permissions', 'clients',  'create');
  canCreateProducts$ = this.store.select('permissions', 'products', 'create');
  canCreateRegions$  = this.store.select('permissions', 'regions',  'create');

  hideBrandAndProduct$ = this.toolboxService.iFramedUnit$.pipe(
    map(unit => unit && !unit.use_client_hierarchy))

  isDisabled$ = this.urlService.currentUrl$.pipe(
    map(url => url.startsWith("/admin-dashboard") || url.startsWith("/global/admin") || url.startsWith("/brands/admin")));

  constructor(
    private eref: ElementRef,
    private router: Router,
    private store: Store<AppState>,
    private toolboxService: ToolboxService,
    private urlService: UrlService
  ) {
    combineLatest(activeContext(store), this.isOpen$).pipe(untilDestroyed(this)).subscribe(
      ([context, _]: [HierarchyContext, boolean]) => this.selectedPath$.next(getPath(context)))
  }

  ngOnDestroy() {}

  onClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.isOpen$.next(false)
    }
  }

  navigateToClientsAdmin() {
    this.isOpen$.next(false)
    this.router.navigate([NAV_ADMIN_GLOBAL, NAV_ADMIN, NAV_ADMIN_CLIENTS]);
  }

  navigateToRegionsAdmin() {
    this.isOpen$.next(false)
    this.router.navigate([NAV_ADMIN_GLOBAL, NAV_ADMIN, NAV_ADMIN_REGIONS]);
  }

  navigateToBrandsAdmin() {
    this.isOpen$.next(false)
    this.selectedContext$.pipe(take(1)).subscribe(({client, region}) => {
      const regionSlug = region && region.slug
      this.router.navigate((compact([client.slug, regionSlug, NAV_ADMIN_BRANDS, NAV_ADMIN])))
    })
  }

  putBrandsInCategories(brands: HierarchyBrand[]): BrandCategoryBucket[] {
    return chain(brands).groupBy(b => b.category || '')
      .map((brands, categoryName) => ({ categoryName,
        brands: sortBy(brands, 'name') }))
      .sortBy('categoryName')
      .value()

  }

  selectProduct(product) {
    this.isOpen$.next(false)

    combineLatest(this.urlService.currentUrl$, activeContext(this.store)).pipe(take(1)).subscribe(
      ([url, context]) => {
        this.store.dispatch(new ChangeContext(getContextSlugs(product.fullSlug)))
        if (url.endsWith('/home')) {
          return this.router.navigateByUrl(product.fullSlug + '/home')
        }
        const oldSlugs = getSlugs(context)
        const newSlugs = getSlugs(product.fullSlug).slice(0, oldSlugs.length)
        this.router.navigateByUrl(url.replace(getPath(oldSlugs), getPath(newSlugs)))
      })
  }

  selectRegion(region) {
    this.isOpen$.next(false)
    this.store.dispatch(new ChangeContext(getContextSlugs(region.fullSlug)))
    this.router.navigateByUrl(region.fullSlug + '/home')
  }

}
