import { Component, OnInit, ViewChild } from '@angular/core';
import { values, get, first } from 'lodash';
import * as moment from 'moment';
import { Actions } from "@ngrx/effects";
import { map, take } from 'rxjs/operators';
import { MatSnackBar } from "@angular/material/snack-bar";

import {Store} from "@ngrx/store";
import { AppState } from "app/reducers"
import { Snapshot, selectActiveSnapshot } from 'app/snapshots/snapshots.reducer';
import { PpcDropdownComponent } from 'app/shared/components/ppc-dropdown/ppc-dropdown.component';
import * as actions from "app/snapshots/snapshots.actions";
import { select } from '@ngrx/store';
import { fetchOutcome } from "app/shared/utils/fetch-state";
import { LocalStorageService } from 'app/services/local-storage.service';

const statuses = {
  in_progress: {value: 0, icon: "refresh", tooltip: "In Progress"},
  succeeded: {value: 1, icon: "check", tooltip: "Completed"},
  failed: {value: 2, icon: "warning", tooltip: "Failed"},
  canceled: {value: 3, icon: "ban", tooltip: "Canceled"},
}

const TABLE_SORTS_KEY = "grow-snapshot-library-table-sorts";

@Component({
  selector: 'ppc-snapshot-library',
  templateUrl: './snapshot-library.component.html',
  styleUrls: ['./snapshot-library.component.sass']
})
export class SnapshotLibraryComponent implements OnInit {
  @ViewChild(PpcDropdownComponent, { static: true }) dropdown: PpcDropdownComponent;

  snapshots$ = this.store.select("snapshots", "snapshotLibrary").pipe(map(values))
  activeSnapshot$ = this.store.select("snapshots").pipe(select(selectActiveSnapshot));
  canDeleteSnapshot$ = this.store.select('permissions', 'caas', 'destroy');

  tableSorts = [{prop: 'status', dir: 'asc'}, {prop: 'created_at', dir: 'desc'}];

  constructor(private store: Store<AppState>,
    private localStorage: LocalStorageService,
    private actions$: Actions,
    private snackbar: MatSnackBar) { }

  ngOnInit() {
  }

  delete(row: Snapshot) {
    this.store.dispatch(new actions.DestroySnapshot(row));
    this.actions$.pipe((fetchOutcome(actions.DestroySnapshot.type)),
      take(1), )
      .subscribe(
        () => this.alertSuccess("Deleted"),
        () => this.alertWarn(`Snapshot could not be deleted. Please try again.`)
      );
  }

  alertSuccess(message: string): void {
    this.snackbar.open(message, null, {
      duration: 2500,
      panelClass: ['check']
    })
  }

  alertWarn(message: string): void {
    this.snackbar.open(message, null, {
      duration: 2500,
      panelClass: ['danger']
    })
  }

  select(event: {selected: Snapshot[]}) {
    const selectedSnapshot = first(event.selected)
    if (selectedSnapshot.status == "in_progress") {return; }

    this.store.dispatch(new actions.FetchSnapshot(selectedSnapshot.id));
    this.actions$.pipe((fetchOutcome(actions.FetchSnapshot.type)), take(1), )
      .subscribe(fetched => {
        this.store.dispatch(new actions.SetActiveSnapshot(selectedSnapshot))
        this.dropdown.close();
      });
  }

  tableSortChanged(event) {
    this.localStorage.setValue(TABLE_SORTS_KEY, event.sorts)
  }

  formatDate(date: string) {
    return moment(date).format("MM/DD/YY")
  }

  getRowClasses(row: Snapshot) {
    return {
      pending: get(row, "status") == "in_progress",
    }
  }

  getStatusIcon(status: string) {
    return get(statuses, [status, "icon"])
  }

  getStatusTooltip(status: string) {
    return get(statuses, [status, "tooltip"])
  }

  dateComparator(date1: string, date2: string) {
    const date1Moment = moment(date1).startOf("day").valueOf();
    const date2Moment = moment(date2).startOf("day").valueOf();
    return date1Moment - date2Moment;
  }

  statusComparator(status1: string, status2: string) {
    const s1 = statuses[status1].value;
    const s2 = statuses[status2].value;
    return s1 - s2
  }

}
