import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ppc-slide-toggle',
  templateUrl: './ppc-slide-toggle.component.html',
  styleUrls: ['./ppc-slide-toggle.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PpcSlideToggleComponent),
      multi: true
    }
  ]
})
export class PpcSlideToggleComponent implements OnInit, ControlValueAccessor {
  value = false;
  disabled = false;

  constructor() { }

  ngOnInit() {
  }

  // CONTROL VALUE ACCESSOR IMPLEMENTATION

  // Function to call when the value changes.
  onChange = (value: any) => {};

  // Function to call when the input is touched.
  onTouched = () => {};

  // Allows Angular to update the model.
  // Update the model and changes needed for the view here.
  writeValue(value: boolean): void {
    this.value = value;
    this.onChange(this.value);
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input. Not currently used to this component but necessary to implement ControlValueAccessor
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // END CONTROL VALUE ACCESSOR IMPLEMENTATION

}
