import { combineLatest as observableCombineLatest } from 'rxjs'
import { take, takeUntil, delay, map, filter } from 'rxjs/operators';
import { Component, OnInit, OnChanges, Input, OnDestroy, HostBinding, ElementRef } from '@angular/core';
import { Store, select } from "@ngrx/store";
import { Actions } from "@ngrx/effects";
import { fetchOutcome, ResetFetchState } from 'app/shared/utils/fetch-state';
import { DragulaService } from "ng2-dragula";
import { sumBy, filter as _filter, cloneDeep, keyBy, mapValues, sortBy, compact, map as _map, concat, get, find } from 'lodash';
import { Observable } from "rxjs";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as chroma from 'chroma-js';

import { GrowTabState, Barbs } from "app/insights/grow-v3/grow.reducer";
import { MekkoColors } from 'app/insights/grow-v3/grow.constants';
import { Subject } from "rxjs";
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { InvalidSubmarketComponent } from './invalid-submarket.component'
import { Market, SubMarket, isVerticalMekko, selectActiveMekko, newSubMarket } from 'app/mekko/mekko.reducer';
import * as mekkoActions from 'app/mekko/mekko.actions';
import { AppState } from 'app/reducers';
import { InsightsCountService } from "app/insights/insights-count.service";
import { GrowCountService } from 'app/insights/grow-v3/grow-count.service';
import { isDefined } from 'app/shared/utils/utils';
import { selectRegion } from "app/hierarchy/hierarchy.reducers";
import { environment } from 'environments/environment';
import { SegmentLike } from 'app/models/segment-like.model';
import { isCompareMode, compareTargets } from 'app/insights/insights.reducer';
import { COMPARE_COLORS } from 'app/insights/insights-components/person-level-compare/person-level-compare.constants';
import { selectedSubMarkets, selectSelectedSubMarkets } from 'app/mekko/mekko.reducer';
import { CompareTarget } from '../../../../insights.reducer';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';

@Component({
  selector: 'ppc-mekko-column',
  templateUrl: './mekko-column.component.html',
  styleUrls: ['./mekko-column.component.sass']
})
export class MekkoColumnComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding("class.no-sub-markets") get noSubMarkets(): boolean {
    return !this.subMarkets || this.subMarkets.length == 0;
  }
  @Input() market: Market;
  market$ = new Subject<Market>();
  @HostBinding("class.editing-mekko") editingMekko: boolean;
  editToggle$ = new Subject<boolean>();
  mekkoColors = MekkoColors;
  subMarkets: SubMarket[];
  segments: {[identifier: string]: SegmentLike} = {}
  barbs: Barbs;
  regionSlug: string;
  ngUnsubscribe = new Subject();
  filteredSubMarketIds: number[];
  selectedSubMarketIds: number[];
  subMarketHeights: {[id: number]: number} = {};
  dragulaBagName: string;
  activeTab: string;
  isPeopleCount$: Observable<boolean>;
  growTabState: GrowTabState;
  dialogRef: MatDialogRef<any>;
  canEditMekkos$: Observable<boolean> = this.store.select("permissions", "mekkos").pipe(
    filter(isDefined),
    map(permission => permission.update), )
  isTier3: boolean = environment.isTier3;
  isVerticalMekko$ = this.store.select("mekkos").pipe(select(isVerticalMekko));
  isCompareMode$ = this.store.select("insights", "grow").pipe(select(isCompareMode));
  compareColors = COMPARE_COLORS;
  compareTargets: CompareTarget[];
  vendorDisplayNames: VendorDisplayName[];

  constructor(private store: Store<AppState>,
    private counts: InsightsCountService,
    public hostRef: ElementRef,
    private dragula: DragulaService,
    private segmentService: SegmentV2Service,
    private growCounts: GrowCountService,
    private actions$: Actions,
    private dialog: MatDialog) {

    store.select("mekkos", "editMekko")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(editingMekko => this.editingMekko = editingMekko);

    store.select("mekkos").pipe(
      select(selectSelectedSubMarkets),
      takeUntil(this.ngUnsubscribe))
      .subscribe(selectedSubMarkets => this.selectedSubMarketIds = _map(selectedSubMarkets, "id"));

    store.select("mekkos").pipe(
      select(selectedSubMarkets),
      takeUntil(this.ngUnsubscribe))
      .subscribe(selectedSubMarkets => this.filteredSubMarketIds = _map(selectedSubMarkets, "id"));

    store.select("grow", "growTabState").pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((activeTab) => this.activeTab = activeTab)

    store.select('hierarchy').pipe(
      select(selectRegion), takeUntil(this.ngUnsubscribe))
      .subscribe(region => this.regionSlug = region.slug);

    store.select("grow", "barbs").pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(barbs => this.barbs = barbs);

    store.pipe(select(compareTargets("grow")), takeUntil(this.ngUnsubscribe))
      .subscribe(compareTargets => this.compareTargets = compareTargets)

    store.select('segmentsHierarchy', 'vendorDisplayNames').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(vendorDisplayNames => this.vendorDisplayNames = vendorDisplayNames);

    this.isPeopleCount$ = store.select("mekkos").pipe(
      select(selectActiveMekko),
      map(mekko => mekko && (mekko.count_type == 'people_count')), )

    observableCombineLatest(
      counts.countsChanged$,
      store.select('mekkos', 'subMarkets'),
      this.editToggle$
    ).pipe(
      takeUntil(this.ngUnsubscribe),
      delay(201), ) // have to wait out the animation to get accurate heights
      .subscribe(() => {
        const subMarketEls = Array.from(document.querySelectorAll('.sub-market-container .sub-market'))
        this.subMarketHeights = mapValues(keyBy(subMarketEls, 'dataset.smId'), 'clientHeight')
      })

    observableCombineLatest(
      this.store.select('mekkos', 'subMarkets'),
      this.market$
    ).pipe(
      map(([subMarkets, market]) => _filter(subMarkets, sm => sm.market_id == market.id)),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(subMarkets => {
        this.subMarkets = sortBy(subMarkets, "order");
        const ids = compact(concat(_map(this.subMarkets, 'matched_short_id'),
          _map(this.subMarkets, 'modeled_short_id')));
        if (ids.length) {
          this.segmentService.fetchByIdentifiers(ids)
            .subscribe(segments => {
              this.segments = keyBy(segments, 'identifier')
            });
        }

      })

    this.store.select("grow", "growTabState").pipe(takeUntil(this.ngUnsubscribe)).subscribe(growTabState => this.growTabState = growTabState)
  }

  ngOnInit() {
    this.dragulaBagName = 'sub-markets-' + this.market.id;
    this.dragula.setOptions(this.dragulaBagName, {
      moves: (el, container, handle) => handle.classList.contains("sub-market-bars")
    });

    this.dragula.dropModel.pipe(filter(([bagName]) => bagName === this.dragulaBagName), takeUntil(this.ngUnsubscribe), ).subscribe(([dragulaBagName, target, bag]) => {
      this.subMarkets.forEach((subMarket, idx) => subMarket.order = idx);
      this.store.dispatch(new mekkoActions.UpdateSubMarketOrder(this.subMarkets));
      this.actions$.pipe(
        fetchOutcome(mekkoActions.UpdateSubMarketOrder.type),
        take(1)
      ).subscribe(() => this.store.dispatch(new ResetFetchState(mekkoActions.FetchSubMarkets)));
    });

  }

  ngOnChanges(changes) {
    if (changes.market) {this.market$.next(this.market); }
    if (changes.editingMekko) {this.editToggle$.next(this.editingMekko)}
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.dragula.find(this.dragulaBagName) && this.dragula.destroy(this.dragulaBagName);
  }

  getCount(subMarket: SubMarket): number {
    return this.growCounts.subMarketCount(subMarket, this.growTabState);
  }

  getSubmarketAudiencePath(subMarket: SubMarket): string {
    if (this.activeTab === "Matched Addressable" && this.segments[subMarket.matched_short_id]) {
      return prettyPathParts(this.segments[subMarket.matched_short_id], this.vendorDisplayNames).join(" > ");
    } else if (this.activeTab === "Modeled Addressable" && this.segments[subMarket.modeled_short_id]) {
      return prettyPathParts(this.segments[subMarket.modeled_short_id], this.vendorDisplayNames).join(" > ");
    }
  }

  getHeightForSubMarket(subMarket: SubMarket): number {
    const getSumOfMekkoPopulation = sumBy(this.subMarkets, s => this.getCount(s));

    if (!this.editingMekko && getSumOfMekkoPopulation !== 0) {
      return this.getCount(subMarket) / getSumOfMekkoPopulation;
    } else {
      return 1 / this.subMarkets.length;
    }
  }

  getBackgroundColor(subMarket: SubMarket): string {
    return chroma(this.mekkoColors[subMarket.tag]).alpha(0.5).css();
  }

  getBorderColor(subMarket: SubMarket): string {
    return chroma(this.mekkoColors[subMarket.tag]).css();
  }

  isSubMarketSelected(subMarket) {
    return this.selectedSubMarketIds.includes(subMarket.id);
  }

  editMarket() {
    this.store.dispatch(new mekkoActions.EditMarket(cloneDeep(this.market)));
  }

  addSubMarket() {
    this.store.dispatch(new mekkoActions.EditSubMarket(newSubMarket(this.market.id, this.subMarkets.length)))
  }

  editSubMarket(subMarket: SubMarket) {
    this.store.dispatch(new mekkoActions.EditSubMarket(cloneDeep(subMarket)));
  }

  toggleSubMarket(subMarket: SubMarket) {
    if (
      ((this.growTabState == "Matched Addressable" && !subMarket.matched_short_id) ||
      (this.growTabState == "Modeled Addressable" && !subMarket.modeled_short_id && !this.isTier3)) &&
      !this.filteredSubMarketIds.includes(subMarket.id)
    ) {
      this.dialogRef = this.dialog.open(InvalidSubmarketComponent,
        { data: { subMarket: subMarket, growTabState: this.growTabState } }
      )
      this.dialogRef.afterClosed().pipe(filter(Boolean)).subscribe(() => this.store.dispatch(new mekkoActions.EditSubMarket(subMarket)));
      return
    }
    this.store.dispatch(new mekkoActions.ToggleSubMarket(subMarket.id));
  }

  closeDialog(result: boolean) {
    this.dialogRef && this.dialogRef.close(result);
  }

  canCompareSubMarket(subMarket: SubMarket): boolean {
    return !!find(this.compareTargets, {id: subMarket.id})
  }

  hasCount(subMarket: SubMarket): boolean {
    switch (this.growTabState) {
      case "Matched Addressable":
        return !!subMarket.matched_short_id;
      case "Modeled Addressable":
        return this.isTier3 ? !!subMarket.matched_short_id : !!subMarket.modeled_short_id;
      default:
        return true;
    }
  }

  getActiveSegId(subMarket: SubMarket): string {
    switch (this.growTabState) {
      case "Matched Addressable":
        return subMarket.matched_short_id;
      case "Modeled Addressable":
        return this.isTier3 ? subMarket.matched_short_id : subMarket.modeled_short_id;
      default:
        return null;
    }
  }

  hasZeroCount(subMarket: SubMarket): boolean {
    const seg = this.segments[this.getActiveSegId(subMarket)];
    const hasCount = !!get(seg, 'count.people', 0);
    const countRegion = environment.isTier3 ? this.regionSlug : 'global';
    return hasCount && get(seg, ['count', 'people', countRegion], 0) === 0;
  }

  tvCountMinimum(subMarket: SubMarket): boolean {
    return this.growCounts.checkMinimumTvCount() && this.hasCount(subMarket) && (this.getCount(subMarket) < this.barbs.minimum);
  }

  marketIsBelowTvCountMinimum(market: Market): boolean {
    const totalCount = this.growCounts.getMarketCount(market.id);
    return this.growCounts.isBelowTvCountMinimum(totalCount);
  }

  tvCountMinimumTooltip(subMarket: SubMarket): string {
    if (this.regionSlug == 'au') {
      return "Warning: Lower than 210 sample size. This is below the threshold which OzTAM recommends for statistical reliability. The All-industry Technical Committee of OzTAM regards audience measures based on such small samples, unreliable for the planning, buying and post-analysis of television advertising. When using these results the sample size must be shown at all times. Data source: C28 TSV";
    } else {
      return "Estimated TV panelist results are not shown for small audiences that produce less accurate results from the model."
    }
  }

  getCompareColor(subMarket: SubMarket) {
    return get(find(this.compareTargets, {id: subMarket.id}), "legendColor")
  }

}
