<div class="product-container">
  <ng-template #conversantIdTooltip>
    <div class="conversant-id-tooltip">
      <div class="name">
        Conversant Company Id
      </div>
      <div class="description">
        <div>An id value set here may override a value assigned higher in the hierarchy if this product's hierarchy is passed to PVE</div>
      </div>
    </div>
  </ng-template>
  <div *ngIf="product.isOpen" class="form-container">
    <div class="product-header">
      Product
      <span (click)="product.close(); $event.stopPropagation()" class="fa fa-close hoverable"></span>
    </div>

    <div class="product-body">
      <div class="product-field">
        <span class="label">Name:</span>
        <span>
          <mat-form-field>
            <input matInput
            #productName="ngModel"
            [(ngModel)]="product.name"
            required
            name="productName"
            (input)="onNameChange($event)"
            />
          <mat-hint>
            <div [hidden]="productName.valid" class="error-message">
              Product name is required.
            </div>
            <div [hidden]="!isProductNameDuplicate" class="error-message">
              This product already exists.
            </div>
          </mat-hint>
          </mat-form-field>
        </span>
      </div>

      <div class="product-field">
        <span class="label">Conversant Company Id:</span>
        <span>
          <mat-form-field floatLabel="never">
            <input matInput
              ppcNumericRange
              type="number"
              name="productConversantId"
              [min]="CONVERSANT_COMPANY_ID_MIN"
              [max]="CONVERSANT_COMPANY_ID_MAX"
              #productConversantId="ngModel"
              [(ngModel)]="product.conversant_company_id"
              placeholder="{{(brand.conversant_company_id || client.conversant_company_id) | wrapParenthesis}}"
              (keypress)="onConversantIdUpdate($event)"
              />
            <mat-hint>
              <div [hidden]="!(productConversantId.errors?.min || productConversantId.errors?.max)" class="error-message">
                {{ ERROR_MSG_CONVERSANT_COMPANY_ID_INVALID }}
              </div>
            </mat-hint>
          </mat-form-field>
        </span>
        <img src="assets/icons/question-circle.svg" ppcTooltip [tooltipTemplate]="conversantIdTooltip"/>
      </div>

      <div class="product-field">
        <span class="label">Region Selector</span>
        <div id="region-picker">
          <ppc-searchable-dropdown *ngIf="regionOptions?.length"
            #productRegionPicker
            [(ngModel)]="productRegions"
            (ngModelChange)="onUpdate()"
            [items]="regionOptions"
            itemLabelProp="name"
            [allowClear]="false"
            multiSelect="true"
            >
          </ppc-searchable-dropdown>
        </div>
      </div>

    </div>

  </div>

  <div *ngIf="product.isClosed" class="non-form-container layout horizontal">
    <div class="flex-1">
      <div class="label">Product Name</div>
      <div class="text">{{product.name}}</div>
      <mat-hint>
        <div [hidden]="!isProductNameDuplicate" class="error-message ">
          This product already exists.
        </div>
      </mat-hint>
    </div>

    <div class="flex-2 collapsed-product-conversant-id-container" *ngIf="product.conversant_company_id || brand.conversant_company_id || client.conversant_company_id">
      <div class="conversant-id-fields">
        <div class="label">Conversant Company Id</div>
        <div class="text" [ngClass]="{ 'collapsed-parent-conversant-id' : !product.conversant_company_id }">
          {{product.conversant_company_id
            || (brand.conversant_company_id || client.conversant_company_id | wrapParenthesis)}}
        </div>
      </div>
      <img src="assets/icons/question-circle.svg" ppcTooltip [tooltipTemplate]="conversantIdTooltip"/>
    </div>

    <div *ngIf="responseMessage" class="plan-info-block-text text-center">
      <br/>
      <mat-card-title>{{responseMessage}}</mat-card-title>
    </div>

    <div>
      <span (click)="editProduct()" class="fa fa-pencil hoverable pad-5" title="Edit Product"></span>
      <app-confirmation
        *ngIf="brand.products.length > 1"
        [isIcon]="true"
        [buttonClass]="'fa fa-trash-o hoverable pad-5'"
        [kontinue]="destroyProduct"
      ></app-confirmation>
    </div>
  </div>
</div>
