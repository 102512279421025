import { reduce, compact, filter, map, keyBy, difference, groupBy, flatten } from 'lodash';
import { SubMarket } from 'app/mekko/mekko.reducer';
import { GrowTabState } from './grow.reducer';
import { environment } from 'environments/environment';
import { MoleculaSegmentContext, SegmentContext } from '../insights.constants';
import { isMolecula } from '../insights.utils';

export function buildMarketCountAggregations(groupedSubMarkets: {[marketId: number]: SubMarket[]}, growTabState: GrowTabState) {
  const segmentField = tabStateSegmentFieldMap[growTabState]
  const filteredGroups = keyBy(filter(groupedSubMarkets, group => group.some(subMarket => subMarket[segmentField])), group => group[0].market_id);
  return reduce(filteredGroups, (aggregations, marketGroup, marketId) => {
    return {
      ...aggregations,
      [marketAggregationKey(+marketId)]: {
        filter: {
          bool: {
            should: compact(map(marketGroup, subMarket => {
              const identifier = subMarket[segmentField];
              if (!identifier) {return null; }
              return {
                term: {segments: identifier}
              }
            }))
          }
        }
      }
    }
  }, {})
}

export function buildSubMarketCountAggregations(subMarkets: SubMarket[], growTabState: GrowTabState) {
  const segmentField = tabStateSegmentFieldMap[growTabState];
  return reduce(filter(subMarkets, subMarket => subMarket[segmentField]), (aggregations, subMarket) => {
    return {
      ...aggregations,
      [`${subMarket[segmentField]}`]: {
        filter: {
          term: {
            segments: subMarket[segmentField]
          }
        }
      }
    }
  }, {})
}

export function buildPrimaryMoleculaContext(selectedSubMarkets: SubMarket[], growTabState: GrowTabState, activeSubMarkets: SubMarket[] = []): SegmentContext | MoleculaSegmentContext {
  const segmentField = growTabState === "Modeled Addressable" && !environment.isTier3 ? "modeled_short_id" : "matched_short_id";
  const included = [map(filter(selectedSubMarkets, segmentField), segmentField)];
  const activeIncluded = map(filter(activeSubMarkets, segmentField), segmentField);
  const activeGroupedSubMarkets = groupBy(activeSubMarkets, "market_id");
  const activeMarketGroups = map(activeGroupedSubMarkets, (subMarkets, marketId) => ({
    [marketAggregationKey(+marketId)]: map(subMarkets, sm => sm[segmentField])
  }))
  const query = {
    isVendorQuery: false,
    context: { included, },
    identifiers: [...map(flatten(activeIncluded), id => ({[id]: id})), ...activeMarketGroups],
  }
  return query;
}

export function buildPrimaryContext(selectedSubMarkets: SubMarket[], growTabState: GrowTabState, activeSubMarkets: SubMarket[] = []): SegmentContext | MoleculaSegmentContext {
  if (growTabState === "Total Population") {return null; }
  const segmentField = growTabState === "Modeled Addressable" && !environment.isTier3 ? "modeled_short_id" : "matched_short_id";
  if (isMolecula()) {
    return buildPrimaryMoleculaContext(selectedSubMarkets, growTabState, activeSubMarkets) as MoleculaSegmentContext;
  }
  const includedSubMarkets = compact(map(selectedSubMarkets, subMarket => subMarket[segmentField] ? [subMarket[segmentField]] : null))
  return {
    or: includedSubMarkets.length ? includedSubMarkets.map(identifier => ({include: [identifier]})) : [],
    isModeled: !!(growTabState == "Modeled Addressable" && environment.isTier3)
  } as SegmentContext;
}

export function buildSecondaryContexts(selectedSubMarkets: SubMarket[], growTabState: GrowTabState): {[contextKey: string]: SegmentContext | MoleculaSegmentContext} {
  const neededContexts = difference(["Matched Addressable", "Modeled Addressable"], [growTabState]);
  return reduce(neededContexts, (contexts, tabState: GrowTabState) => {
    const segmentField = tabStateSegmentFieldMap[tabState];
    const filteredSubMarkets = filter(selectedSubMarkets, segmentField);
    if (!filteredSubMarkets.length) {return contexts; }
    return {
      ...contexts,
      [tabState.split(" ")[0].toLowerCase()]: buildPrimaryContext(selectedSubMarkets, tabState)
    }
  }, {})
}

const tabStateSegmentFieldMap = {
  "Matched Addressable": "matched_short_id",
  "Modeled Addressable": environment.isTier3 ? "matched_short_id" : "modeled_short_id"
}

export function marketAggregationKey(id: number) {
  return `market-count-${id}`
}

export const IS_COMPARE_MODE = "Custom audiences cannot be created from the Compare tab. Navigate to a different tab in Grow to save a new custom audience.";
