<div class="card">
  <div class="fields">
    <div class="name">{{market?.name}}: {{subMarket.name}}</div>
    <div class="field grey">
      <span class="field-label">Confidence:</span>
      <span>{{confidence}}%</span>
    </div>
    <div class="field">
      <span class="field-label grey" [ppcTooltip]="'IDs that exist only in this Audience'" [tooltipDelayTime]="500">Matched Unique:</span>
      <span><i class="fa fa-user"></i>{{matchedUniqueCount(subMarket.id) | millions }}</span>
    </div>
    <div class="field">
      <span class="field-label grey" [ppcTooltip]="'IDs that exist in this audience and at least one other in the selected seed Audiences'" [tooltipDelayTime]="500">Matched Overlap:</span>
      <span><i class="fa fa-user"></i>{{matchedOverlap(subMarket.id) | millions }}</span>
    </div>
    <div class="field reach">
      <span class="field-label grey" [ppcTooltip]="'IDs in the modeled audience but not in the seed audience'" [tooltipDelayTime]="500">Incremental Reach:</span>
      <span><i class="fa fa-user"></i>{{reach | millions}}</span>
    </div>
  </div>
  <div class="lookalike">
    <div class="field modeled">
      <span class="field-label grey" [ppcTooltip]="'IDs the model determined are most similar to the seed including and above the selected confidence. The Modeled ID count includes the seed audience'" [tooltipDelayTime]="500">Modeled:</span>
      <span> <i class="fa fa-user"></i>{{this.count | millions }}</span>
    </div>
    <propensity-overview-graph [confidence]="confidence"
                               [confidenceSizes]="confidenceSizes"
                               [audienceCount]="snapshot.snapshots_sub_markets.length"
                               [id]="subMarket.matched_short_id"
                               (updateCountAndConfidence)="updateCountAndConfidence($event)"
                               (saveCurrentConfidence)="saveCurrentConfidence()">
    </propensity-overview-graph>
  </div>
</div>
