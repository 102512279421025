<i
  *ngIf="mode === 'edit'"
  class="fa fa-pencil muted white"
  (click)="unitDialog.show();editUnit(unit);"
  ></i>
<button
  *ngIf="mode === 'create'"
  mat-button
  class="action-btn white add-component"
  (click)="unitDialog.show();editUnit(unit);"
  >
  ADD COMPONENT
</button>

<ppc-dialog #unitDialog class="hide-overflow"
  (onShow)="handleModalMode(true)"
  (onClose)="handleModalMode(false)"
  >
  <div body class="unit-dialog-body">
    <app-unit-form
      *ngIf="unitDialog.open"
      [prnt]="cmpPrnt"
      [mode]="mode"
      [soloModal]="solo"
    ></app-unit-form>
  </div>
</ppc-dialog>
