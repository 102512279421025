import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InsightsModule } from 'app/insights/insights.module';
import { IndexReportFormComponent } from 'app/index-report/index-report-form/index-report-form.component';
import { IndexReportService } from 'app/index-report/index-report.service';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { AddButtonComponent } from './index-report-form/add-button/add-button.component';

@NgModule({
  imports: [
    CommonModule,
    InsightsModule,
    PpcComponentsModule,
  ],
  declarations: [
    IndexReportFormComponent,
    AddButtonComponent,
  ],
  exports: [
    IndexReportFormComponent,
  ],
  providers: [
    IndexReportService,
  ]
})
export class IndexReportModule { }
