import { take, takeUntil, filter, map } from 'rxjs/operators';
import {ActivatedRoute} from "@angular/router";
import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store, select } from "@ngrx/store";
import { Actions } from "@ngrx/effects";
import { combineLatest as observableCombineLatest, Subject } from "rxjs";
import { find, get, sortBy, values, cloneDeep } from 'lodash';
import {CLIENT_VERTICALS} from 'app/admin/client/client.constants';

import { fetchOutcome } from "app/shared/utils/fetch-state";
import * as actions from 'app/mekko/mekko.actions';
import { Mekko, newMekko, selectMekkos } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';
import { Barbs } from 'app/insights/grow-v3/grow.reducer';
import { selectClient } from "app/hierarchy/hierarchy.reducers";
import {HierarchyClient} from "app/hierarchy/hierarchy.interface";

@Component({
  selector: 'ppc-mekko-form',
  templateUrl: './grow-v3-mekko-form.component.html',
  styleUrls: ['./grow-v3-mekko-form.component.sass']
})
export class MekkoFormComponent implements OnDestroy  {
  mekko: Mekko;
  mekkoList: Mekko[] = [];
  barbs: Barbs;
  canUpdateMekkos: boolean;
  creationType: string = 'blank';
  clientVerticals: {value: string, display: string, key: string}[];
  canDestroyMekkos$ = this.store.select('permissions', 'mekkos', 'destroy');
  ngUnsubscribe = new Subject();
  nameValidators = [
    {
      errorMessage: 'Name cannot be blank',
      isValid: text => this.hasName()
    },
    {
      errorMessage: 'Chart name already used',
      isValid: text => this.hasUniqueName()
    }
  ];
  wasDefault: boolean;
  isGWI: boolean;

  constructor(private actions$: Actions,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private store: Store<AppState>) {

    store.select('hierarchy').pipe(
      select(selectClient), filter(Boolean), takeUntil(this.ngUnsubscribe),
    ).subscribe((client: HierarchyClient) => {
      this.clientVerticals = CLIENT_VERTICALS.filter((cv) => client.emerging_market_verticals.includes(cv.value));
    });

    store.select("insights", "grow", "tabs").pipe(takeUntil(this.ngUnsubscribe), )
      .subscribe(tabs => this.isGWI = tabs && tabs.some((t) => t.tab_key == 'top_level_person'))


    store.select("grow", "barbs").pipe(takeUntil(this.ngUnsubscribe), )
      .subscribe(barbs => this.barbs = barbs)

    store.select("mekkos", "mekkoUnderEdit").pipe(
      filter(Boolean),
      map(cloneDeep),
      takeUntil(this.ngUnsubscribe),
    )
      .subscribe((mekko: Mekko) => {
        this.mekko = mekko;
        this.wasDefault = mekko.default;
      });

    store.select("mekkos").pipe(
      select(selectMekkos),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(mekkos => this.mekkoList = sortBy(values(mekkos), 'name'));

    this.store.select('permissions').pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(permissions => {
        this.canUpdateMekkos = permissions['mekkos'].update;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  saveMekko() {
    this.store.dispatch(new actions.SaveMekko(this.mekko));
    this.actions$.pipe((fetchOutcome(actions.SaveMekko.type)),
      take(1), )
      .subscribe(
        saveOutcome => {
          if (this.mekko.id) {
            this.alertSuccess("Saved");
          } else {
            this.store.dispatch(new actions.SetSelectedMekko(get(saveOutcome, ['result', 'mekko'])));
            this.alertSuccess("Created");
          }
          this.onSaveOrCopyResponse(saveOutcome)
          this.cancel();
        },
        error => this.alertWarn('Grow chart could not be saved.  Please try again.')
      );
  }

  onSaveOrCopyResponse(response) {
    // Default mekko logic
    if (this.mekko.default && !this.wasDefault) {
      this.store.dispatch(new actions.SaveDefaultMekko(get(response, ['result', 'mekko', 'id'])))
    } else if (!this.mekko.default && this.wasDefault) {
      // User preferences has a NOT NULL constraint on :config so we need to pass 0 instead
      this.store.dispatch(new actions.SaveDefaultMekko(0))
    }
  }

  cancel() {
    this.store.dispatch(new actions.EditMekko(null));
  }

  hasUniqueName(): boolean {
    return !find(this.mekkoList, mekko => {
      return this.mekko
        && mekko.id !== this.mekko.id
        && !mekko.name.toUpperCase().trim().localeCompare(this.mekko.name.toUpperCase().trim());
    });
  }

  hasName(): boolean {
    return !!(this.mekko.name || "").trim();
  }

  get isValid(): boolean {
    return this.hasUniqueName() && this.hasName();
  }

  get deleteTitle(): string {
    return `Are you sure you want to delete '${this.mekko && this.mekko.name}' ?`;
  }

  get canUpdateCountType(): boolean {
    return this.barbs && this.barbs.n_pids && this.barbs.const && this.canUpdateMekkos;
  }

  get isPrePopulatedChart(): boolean {
    return this.creationType === "prePopulated";
  }

  setVerticalType(creationType: string): void {
    if (this.creationType == 'blank') {
      this.mekko.vertical_type = null
    }
  }

  setMekkoName(): void {
    this.mekko.name = this.clientVerticals.find((cv) => cv.value === this.mekko.vertical_type).display
  }

  alertSuccess(message: string): void {
    this.snackbar.open(message, null, {
      duration: 2500,
      panelClass: ['check']
    });
  }

  alertWarn(message: string): void {
    this.snackbar.open(message, null, {
      duration: 2500,
      panelClass: ['danger']
    });
  }
}
