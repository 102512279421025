<div class="edit-pane" *ngIf="demographic">
  <div class="edit-pane-header">
    <div class="edit-pane-header-text">
      <div class="edit-pane-title">
        Edit Demographic
      </div>
    </div>
  </div>
  <div class="edit-pane-section">
    <div class="edit-pane-section-header">Graph Type</div>
    <div ppcRadioGroup [(ngModel)]="demographic.widget_type">
      <ppc-radio-button value="{{chartTypes.BarHorizontal}}">
        Bar (Horizontal)
      </ppc-radio-button>
      <ppc-radio-button value="{{chartTypes.BarVertical}}" *ngIf="demographic?.region_demographic_buckets.length < 10" >
        Bar (Vertical)
      </ppc-radio-button>
      <ppc-radio-button value="{{chartTypes.Doughnut}}">
        Pie Chart
      </ppc-radio-button>
    </div>
  </div>
  <div class="edit-pane-section">
    <div class="edit-pane-section-header">{{ resourceType == 'Persona' ? 'Metric' : 'Count Type'}}</div>
    <div ppcRadioGroup [(ngModel)]="demographic.id_count">
      <ppc-radio-button value="percentage" [ppcTooltip]="percentageTooltip">
        Percentage
      </ppc-radio-button>
      <ppc-radio-button value="absolute" [ppcTooltip]="toolTips.NonPersonaAbsolute">
        {{ resourceType == 'Persona' ? 'Count' : 'Absolute' }}
      </ppc-radio-button>
    </div>
    <div class="edit-pane-section-note"><span class="edit-pane-section-note-label">Data Source</span>: {{ demographic.attribute_provider }}</div>
  </div>
  <div class="edit-pane-actions">
    <button class="btn" (click)="save()">SAVE</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </div>
</div>
