import {Injectable} from "@angular/core";

// TODO all of this should be static
@Injectable()
export class LocalStorageService {
  constructor() { }

  // ///////////////////////////////
  // Raw (string) value accessors

  getRawValue(key: string): string {
    return localStorage.getItem(key);
  }

  setRawValue(key: string, value: string): string {
    localStorage.setItem(key, value);
    return value;
  }

  removeRawValue(key: string): string {
    const value = localStorage.getItem(key);
    localStorage.removeItem(key);
    return value;
  }

  // Raw (string) value accessors
  // ///////////////////////////////

  // ///////////////////////////////
  // Typed serialized accessors

  getValue<T>(key: string): T {
    const rawValue = this.getRawValue(key);
    const parsedValue: any = this.deserializeValue(rawValue);
    return <T>parsedValue;
  }

  setValue<T>(key: string, value: T): T {
    const rawValue = JSON.stringify(value);
    this.setRawValue(key, rawValue);
    return value;
  }

  removeValue<T>(key: string): T {
    const rawValue = this.removeRawValue(key);
    const parsedValue: any = this.deserializeValue(rawValue);
    return <T>parsedValue;
  }

  private deserializeValue(rawValue: string): any {
    if (rawValue == void(0)) {return rawValue; }

    let parsedValue: any;
    try {
      parsedValue = JSON.parse(rawValue);
    } catch (e) {
      parsedValue = void(0);
    }
    return parsedValue;
  }
}
