<div class="header layout horizontal center-center">
  <div class="title">
    <h1>Toolbox<span *ngIf="isConfig">&nbsp;Configuration</span></h1>
  </div>
  <div class="aside text-right layout horizontal" *ngIf="canEdit(toolboxClientPerms) || canEdit(toolboxCorePerms)">
    <div *ngIf="isConfig">
      <mat-select [ngModel]="unitState$ | async" (ngModelChange)="unitState$">
        <mat-option *ngFor="let state of unitStates" [value]="state"><em>{{state}}</em></mat-option>
      </mat-select>
    </div>
    <a routerLink="./configuration" *ngIf="!isConfig">
      <i class="fa fa-cog" data-fa-transform="down-1"></i>
    </a>
    <a class="action-btn cancel-btn" mat-button routerLink="./../" *ngIf="isConfig">
      Exit Configuration
    </a>
  </div>
</div>
<ppc-loading-overlay *ngIf="isLoading$ | async"></ppc-loading-overlay>
<ng-container *ngLet="(units$ | async) as accessibleUnits">
  <section
    class="slice"
    *ngIf="!hasRenderedUnits(accessibleUnits) && !isConfig"
    >
    <p
      class="warn"
      [class.visible]="(unitState$ | async) == 'Embedded Components'"
      >
      There are currently no components to view.</p>
  </section>
</ng-container>
<div [class.config]="isConfig"
  *ngFor="let cat of categories; let i=index; let last=last; let first=first; trackBy: categoryById"
  [id]="'category-'+cat.id"
  >
  <section class="slice" *ngIf="isConfig || availableUnits(cat.id).length > 0">
    <div
      class="has-text-accent-color cat-name layout horizontal end"
      >
      <span
        *ngIf="!isConfig && cat.name"
        [id]="'cat-heading-'+cat.id"
        >
        {{renderHtml(cat.name)}}
      </span>
      <ng-container *ngIf="isConfig">
        <div class="toggles">
          <i class="fa fa-caret-down pointer muted" *ngIf="!last" (click)="moveCategoryPriority(cat, 1)"></i>
          <i class="fa fa-caret-up pointer muted" *ngIf="!first" (click)="moveCategoryPriority(cat, -1)"></i>
        </div>
        <ppc-input
          #catNameInput
          class="category-name-input"
          [class.error-state]="cat.name == categoryNameRequest"
          [(ngModel)]="cat.name"
          (keypress)="onCategoryKeydown($event, cat)"
          (focusin)="onCategoryFocus($event)"
          (focusout)="onUpdateCategory($event, cat)"
          [autofocus]="last && !first && !cat.name"
          >
          <label>Category Name</label>
        </ppc-input>
        <div class="tools">
          <i class="fa fa-pencil pointer muted" (click)="catNameInput.focus(true)"></i>
          <app-confirmation
            class="pointer muted"
            isIcon="true"
            buttonClass="fa fa-trash"
            buttonContinueLabel="DELETE"
            modalTitle="Are you sure you want to delete this category and hide its components?"
            [kontinue]="onDestroyCategory.bind(this, cat)"
            >
            </app-confirmation>
        </div>
      </ng-container>
    </div>
    <div class="unit-wrapper layout horizontal wrap"
      *ngIf="isConfig || (!isConfig && cat.name)"
      #unitWrapper
      [dragula]="BAG"
      [dragulaModel]="units"
      [attr.data-bagcat]="cat.id"
      >
      <app-unit
        *ngFor="let unit of availableUnits(cat.id)"
        [class.hide-unit]="!unit.show_in_applications && !isConfig"
        [class.sansdrag]="!isConfig || modalView"
        [attr.data-priority]="unit.priority"
        (modalMode)="modalView = $event"
        [attr.data-unit]="unit.id"
        (toggled)="onToggled($event)"
        [unit]="unit"
        [width]="unitWrapper.getBoundingClientRect().width - 70"
        [category]="cat"
        [collapsed]="selectedUnit !== unit"
        [editMode]="isConfig"
        [unitIndex]="nonHiddenIndexes(cat.id)[unit.id]"
        >
      </app-unit>
    </div>
    <app-multi-unit-dialog
      *ngIf="isConfig"
      (modalMode)="modalView = $event"
      class="pointer"
      [unit]="customUnit(cat.id, clientId, unitsTypeId)"
      [category]="cat"
      [categoryNameRequest]="categoryNameRequest"
      ></app-multi-unit-dialog>
    <div id="create-category" *ngIf="(last && isConfig)">
      <button mat-button class="action-btn gold"
        (click)="createCustomCategory('', categories[i].priority)"
        [disabled]="invalidCategoryName"
        >
        Create New Category
        </button>
    </div>
  </section>
</div>
<section class="slice" *ngIf="isConfig">
  <div id="create-category" *ngIf="!categories.length">
    <button mat-button class="action-btn gold"
      (click)="createCustomCategory('', 0)"
      [disabled]="invalidCategoryName"
      >
      Create New Category
      </button>
  </div>
</section>
