import {Inject, Injectable, OnDestroy} from '@angular/core';
import { BehaviorSubject, Observable, combineLatest as observableCombineLatest, ReplaySubject, of as observableOf } from 'rxjs';
import { debounceTime, filter, tap, map, switchMap, mergeMap } from 'rxjs/operators';
import {Demographic, Filter} from "app/insights/insights.models";
import {HttpClient} from "@angular/common/http";
import { Store, select } from "@ngrx/store";
import {AppState} from "app/reducers";
import {INSIGHTS_CONTEXT, InsightsContextType, SegmentContext, SegmentContexts } from "app/insights/insights.constants";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map as _map, flatMap, get, find, groupBy, filter as _filter, isEqual, reduce, values, forEach, chain, keys, sortBy, flatten, debounce, uniq, some, startsWith } from 'lodash';
import { buildQueryFromContext, buildIndexingVendorTypesQuery, getVendorTypeAggregationKey, rxWaterfall, buildMoleculaContext, buildMoleculaIndexContext, isMolecula } from './insights.utils';
import { Node } from 'app/segment-picker/root-nodes/root-node.interface';
import { selectActiveTabDemographics, selectInsightsIdentifiers, isCompareMode } from './insights.reducer';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import * as actions from 'app/insights/insights.actions';
import { isFetchInFlight, isLoaded } from 'app/shared/utils/fetch-state';
import { getRuleSegmentIdentifiers } from '../audiences-v2/audience-v2.model';
import { SegmentLike } from '../models/segment-like.model';
import { SegmentsHierarchyService } from '../segments-hierarchy/segments-hierarchy.service';
import { INDEX_VALUES, PERMISSION_MODELING_UNIVERSE, PRIMARY } from '../shared/utils/constants';
import { activeContext } from '../hierarchy/hierarchy.reducers';
import { HierarchyClient } from '../hierarchy/hierarchy.interface';
import { segmentCountsUrl, segmentCountsV1Url, segmentCountsV3Url, segmentJourneyCountsUrl } from '../shared/constants/id_analytics.urls';

@UntilDestroy()
@Injectable()
export class InsightsCountService implements OnDestroy {
  public countsChanged$ = new ReplaySubject(1);
  private fetchingContext$ = new BehaviorSubject(false);
  public fetchingSegments$ = new BehaviorSubject(false);
  public loadingCounts$ = observableCombineLatest(this.fetchingContext$,
    this.fetchingSegments$).pipe(map(fetchStates => fetchStates.some(Boolean)));
  public filters: Filter[] = [];
  public demographics: Demographic[];
  public personLevelTab: string;
  public activeGWIQuestions: Node[];
  public sharedInterestSegments: string[];
  segmentContexts: SegmentContexts;
  public filters$ = this.store.select("insights", this.insightsContext, "filters");

  private counts: {[queryIdentifier: string]: {[segmentIdentifier: string]: number}};
  public segments: {[identifier: string]: SegmentLike} = {};
  public modelingUniverseProviders: string[];

  requestCache: Array<[any, {[segmentIdentifier: string]: number}]> = [];
  queryInFlight: any;

  constructor(private http: HttpClient,
    private store: Store<AppState>,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType,
    private segmentService: SegmentV2Service,
    private segmentHierarchyService: SegmentsHierarchyService) {
    observableCombineLatest(
      this.store.pipe(select(selectInsightsIdentifiers(this.insightsContext))),
      this.store.select("insights", this.insightsContext, "indexMode"),
      this.store.select("fetchStates", actions.FetchDemographics.type).pipe(select(isLoaded))
    ).pipe(
      filter(([_, indexMode, demographicsLoaded]) => indexMode && demographicsLoaded),
      map(([identifiers]) => _filter(identifiers, identifier => get(this.segments, identifier) === undefined)),
      filter(identifiers => !!identifiers.length),
      tap(() => this.fetchingSegments$.next(true)),
      tap(identifiers => forEach(identifiers, identifier => this.segments[identifier] = null)),
      mergeMap(identifiers => this.segmentService.fetchByIdentifiers(uniq(identifiers))),
      switchMap(segments => {
        if (some(segments, {type: "Audience"})) {
          // Need to fetch segments that are a part of audience rules too
          const ruleIdentifiers = chain(segments as any[])
            .filter({type: "Audience"})
            .flatMap(getRuleSegmentIdentifiers)
            .uniq()
            .value();
          return observableCombineLatest(
            observableOf(segments),
            this.segmentService.fetchByIdentifiers(ruleIdentifiers)
          ).pipe(
            map(([segments, ruleSegments]) => [...segments, ...ruleSegments]),
          )
        } else {
          return observableOf(segments)
        }
      }),
      untilDestroyed(this),
    ).subscribe(segments => {
      forEach(segments, segment => this.segments[segment.identifier] = segment)
      this.fetchingSegments$.next(false)
    })

    const modelingUniverseProviders$ = activeContext(this.store).pipe(
      map(({ client }) => client),
      filter(Boolean),
      switchMap((client: HierarchyClient) => this.segmentHierarchyService.getListOfVendors([PERMISSION_MODELING_UNIVERSE], client.slug)),
      map(vendors => _map(vendors, cv => `${cv.vendor_name}-${cv.vendor_type}${cv.owner_name ? "-" + cv.owner_name : ''}`))
    )

    observableCombineLatest(
      this.store.select("insights", this.insightsContext, "segmentContexts"),
      this.filters$,
      this.store.pipe(select(selectActiveTabDemographics(this.insightsContext))),
      this.store.select("insights", this.insightsContext, "activeGWIQuestions"),
      this.store.select("insights", this.insightsContext, "personLevelTab"),
      this.store.select("insights", this.insightsContext, "sharedInterestSegments"),
      this.store.select("insights", this.insightsContext, "indexMode"),
      this.fetchingSegments$,
      this.store.select("insights", this.insightsContext).pipe(select(isCompareMode)),
      modelingUniverseProviders$,
      this.store.select("insights", this.insightsContext, "indexBase"),
      this.store.select("fetchStates", actions.FetchTabs.type).pipe(select(isFetchInFlight))
    ).pipe(
      debounceTime(50),
      filter(([segmentContexts, filters, activeDemographics, activeGWIQuestions, personLevelTab, sharedInterestSegments, indexMode, fetchingSegments, isCompareMode, modelingUniverseProviders, indexBase, fetchingTabs]) => !fetchingSegments && !fetchingTabs),
      untilDestroyed(this)
    ).subscribe(([segmentContexts, filters, activeDemographics, activeGWIQuestions, personLevelTab, sharedInterestSegments, indexMode, fetchingSegments, isCompareMode, modelingUniverseProviders, indexBase, fetchingTabs]) => {
      this.filters = filters;
      this.demographics = activeDemographics;
      this.segmentContexts = segmentContexts;
      this.activeGWIQuestions = activeGWIQuestions;
      this.personLevelTab = personLevelTab;
      this.sharedInterestSegments = sharedInterestSegments;
      this.modelingUniverseProviders = modelingUniverseProviders;
      if (segmentContexts && !isCompareMode) {
        this.getInsights(indexMode, indexBase);
      }
    })
  }

  ngOnDestroy() { }

  getInsights = debounce((indexMode, indexBase) => {
    this.fetchingContext$.next(true);
    let queries;
    if (isMolecula()) {
      queries = reduce(this.segmentContexts.secondary, (secondaries, context, key) => {
        return {
          ...secondaries,
          [key]: buildMoleculaContext(context, [], this.filterIdentifiers, [], false, this.segments, this.modelingUniverseProviders, this.insightsContext, key)
        }
      }, {})

      if (this.segmentContexts.primary) {
        const key = PRIMARY;
        const query = buildMoleculaContext(
          this.segmentContexts.primary,
          this.demographicIdentifiers,
          this.filterIdentifiers,
          this.activeGWIQuestions,
          indexMode,
          this.segments,
          this.modelingUniverseProviders,
          this.insightsContext,
        );

        queries[key] = query;
        if (indexMode) {
          queries[`${INDEX_VALUES}:${key}`] = buildMoleculaIndexContext(query, this.insightsContext, indexBase);
        }
      }
    } else {
      queries = reduce(this.segmentContexts.secondary, (secondaries, context, key) => {
        return {
          ...secondaries,
          [key]: buildQueryFromContext(context as SegmentContext, [], this.filterIdentifiers, [], {}, false, this.segments, this.modelingUniverseProviders)
        }
      }, {})

      if (this.segmentContexts.primary) {
        const query = buildQueryFromContext(
          this.segmentContexts.primary as SegmentContext,
          this.demographicIdentifiers,
          this.filterIdentifiers,
          this.activeGWIQuestions,
          this.segmentContexts.aggregations,
          indexMode,
          this.segments,
          this.modelingUniverseProviders,
        );
        queries["primary"] = query;
        if (indexMode) {

          queries["context-vendor-types"] = buildIndexingVendorTypesQuery(this.segmentContexts.primary as SegmentContext,
            flatten(this.demographicIdentifiers),
            this.segments,
            this.filterIdentifiers,
            this.modelingUniverseProviders,
            indexBase);
        }
      }
    }
    if (!values(queries).length) {
      this.counts = {};
      this.countsChanged$.next(this.counts);
      this.fetchingContext$.next(false);
      return;
    };

    if (isEqual(queries, this.queryInFlight)) { return; }

    this.queryInFlight = queries;

    this.fetchInsights(queries).subscribe(
      data => {
        this.counts = data;
        this.countsChanged$.next(data);
        this.fetchingContext$.next(false);
        this.queryInFlight = null;
      },
      error => {
        this.counts = {};
        this.countsChanged$.next(this.counts);
        this.fetchingContext$.next(false);
        this.queryInFlight = null;
      },
    )
  }, 200)

  fetchInsights(queries: {[queryKey: string]: any}): Observable<{[queryIdentifier: string]: {[segmentIdentifier: string]: number}}> {
    const cachedQueries = reduce(queries, (cache, query, queryKey) => {
      const cached = find(this.requestCache, ([cacheQuery]) => isEqual(cacheQuery, query));
      if (cached) {
        delete queries[queryKey]
        return {...cache, [queryKey]: cached[1]}
      }
      return cache
    }, {});
    if (!keys(queries).length) {
      return observableOf(cachedQueries)
    } else {
      return observableCombineLatest(
        observableOf(cachedQueries),
        rxWaterfall(_map(queries, (query, key) => {
          let payload, url;
          if (isMolecula()) {
            if (this.insightsContext === 'journey') {
              payload = query;
              url = segmentJourneyCountsUrl();
            } else {
              payload = query;
              url = segmentCountsV3Url();
            }
          } else {
            payload = { queries: { [key]: query } };
            url = segmentCountsUrl();
          }
          return this.http.post<{ [queryIdentifier: string]: { [segmentIdentifier: string]: number } }>(url, payload)
        })).pipe(
          map((results: {[queryIdentifier: string]: {[segmentIdentifier: string]: number}}[]) => reduce(results, (result, query) => ({...result, ...query}), {})),
          tap((response: {[queryIdentifier: string]: {[segmentIdentifier: string]: number}}) => {
            // Insert into cache
            const cacheEntries: [any, {[segmentIdentifier: string]: number}][] = _map(queries, (query, queryKey) => [query, response[queryKey]] as [any, {[segmentIdentifier: string]: number}])
            this.requestCache.unshift(...cacheEntries)
          })
        ) as Observable<{[queryIdentifier: string]: {[segmentIdentifier: string]: number}}>
      ).pipe(
        map(([cachedQueries, response]) => {
          return {...cachedQueries, ...response}
        })
      )
    }

  }

  getSegmentCount(identifier: string): number {
    if (get(this.counts, ["primary", identifier, "error"])) { return 0; }

    return get(this.counts, ["primary", identifier], 0)
  }

  getVendorTypesForIdentifiers(identifiers: string[]) {
    return chain(identifiers)
      .map(identifier => this.segments[identifier])
      .filter(Boolean)
      .map(segment => getVendorTypeAggregationKey(segment))
      .uniq()
      .value();
  }

  getSegmentTypeByIdentifier(identifier: string) {
    const segment = get(this.segments, identifier);
    return getVendorTypeAggregationKey(segment);
  }


  getIndex(identifier: string): number {
    const segment = get(this.segments, identifier);
    if (!segment) { return null; }
    if (isMolecula()) {
      // Molecula: API service calculates and returns index value
      const segmentIndex = get(this.counts, [`${INDEX_VALUES}:${PRIMARY}`, identifier]);
      return segmentIndex;
    }
    const vendorType = getVendorTypeAggregationKey(segment);
    const segmentCountInContext = get(this.counts, ["primary", identifier]);
    const vendorCountInContext = get(this.counts, ["primary", vendorType])
    if (segmentCountInContext == null) { return null; }
    const segmentCountAgainstContextTypes = get(this.counts, ["context-vendor-types", identifier]);
    const segmentTypeAgainstContextTypes = get(this.counts, ["context-vendor-types", vendorType]);
    return ((segmentCountInContext / vendorCountInContext) / (segmentCountAgainstContextTypes / segmentTypeAgainstContextTypes)) * 100;
  }

  hasIndex(identifier: string): boolean {
    const index = this.getIndex(identifier);
    return index != null && !Number.isNaN(index) && Number.isFinite(index);
  }

  getCountByKeys(keys: string[] | string) {
    return get(this.counts, keys, 0)
  }

  hasFilter(filter: Partial<Filter>): boolean {
    return !!find(this.filters, filter)
  }

  get totalCount(): number {
    if (get(this.counts, ["primary", "total_count", "error"])) { return 0; }

    return get(this.counts, ["primary", "total_count"]);
  }

  getTotalCountByKey(key: string) {
    if (!this.counts[key]) {
      key = "primary";
    }
    return get(this.counts, [key, "total_count"], 0);
  }

  get filterIdentifiers(): string[][] {
    // we sort the filters first to make sure we're generating the query in the same order for the same combination of queries
    // it ensures we hit cached queries regardless of the order that a user adds filters
    return _map(groupBy(sortBy(this.filters, "shortId"), "demographicId"), group => _map(group, "shortId"));
  }

  get demographicIdentifiers(): string[] {
    switch (this.personLevelTab) {
      case "Survey":
        return _map(flatMap(_filter(this.activeGWIQuestions, "children"), "children"), "identifier");
      case "Interests":
        return this.sharedInterestSegments;
      default:
        // we sort the demographics first to make sure we're generating the query in the same order for the same combination of demographics
        // it ensures we hit cached queries regardless of the order of a user's demographics
        return _map(flatMap(sortBy(this.demographics, "id"), "buckets"), "short_id");
    }
  }

  getSegmentCounts(payload: {}): Observable<{counts: {[identifier: string]: number}}> {
    return this.http.post(segmentCountsV1Url(), payload) as Observable<{counts: {[identifier: string]: number}}>
  }
}
