import { merge as observableMerge, empty, Observable } from 'rxjs';
import { map, startWith, scan } from 'rxjs/operators';
import { first, last } from 'lodash';

const emptyObservable = empty();

function undo () {
  return ({past, present, future}) => {
    if (past.length === 0) {
      return {past, present, future};
    }

    return {
      past: past.slice(0, past.length - 1),
      present: last(past),
      future: [present].concat(future)
    };
  };
}

function redo () {
  return ({past, present, future}) => {
    if (future.length === 0) {
      return {past, present, future};
    }

    return {
      past: past.concat([present]),
      present: first(future),
      future: future.slice(1)
    };
  };
}

function sourceEvent (f, eventValue, historySize) {
  return ({past, present, future}) => {
    return {
      past: past.concat([present]).slice(-historySize),
      present: f(present, eventValue),
      future: []
    };
  };
}

export function undoableScan (source$, f, defaultValue, undo$, redo$: Observable<any> = emptyObservable, options: any = {}) {
  if (undo$ === undefined) {
    throw new Error('Must pass a stream of undo$ intent');
  }

  const historySize = options.historySize || Infinity;

  const action$ = observableMerge(
    source$.pipe(map(event => sourceEvent(f, event, historySize))),
    undo$.pipe(map(undo)),
    redo$.pipe(map(redo))
  );

  const initialState = {
    past: [],
    present: defaultValue,
    future: []
  };

  return action$.pipe(
    startWith(initialState),
    scan((state, action: Function) => action(state)),
  )
};
