import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Partner } from 'app/models/partners/partner.model';

@Component({
  selector: 'destination-modal',
  templateUrl: './destination-modal.component.html',
  styleUrls: ['./destination-modal.component.sass']
})
export class DestinationModalComponent implements OnInit {
  @ViewChild('addDialog', { static: true }) dialog: any;
  @Input() partner: Partner;
  @Input() destinations: any[];
  @Output() modalMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() createDestination: EventEmitter<any> = new EventEmitter<any>();

  destName$ = new BehaviorSubject<string>('');
  isSubmitDisabled$ = this.destName$.pipe(map(name => !name))
  duplicateName: Boolean = false;

  constructor() { }

  ngOnInit() {}

  updateDestName(destName) {
    this.destName$.next(destName);
  }

  handleModalMode(mode: boolean) {
    this.modalMode.emit(mode);
  }

  closeDialog() {
    this.dialog.close();
  }

  createDestinationForPartner() {
    const destinationName = this.destName$.getValue();
    const currentDestNames = this.destinations.map((destination) => destination.name);
    const dupDestName = currentDestNames.includes(destinationName);

    if (dupDestName) { return this.duplicateName = true }

    this.createDestination.emit({ name: destinationName });
    this.destName$.next('');
    this.dialog.close();
  }
}
