import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, merge as observableMerge } from "rxjs";
import { map, mergeMap, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'app/reducers';
import { fetchResource, ResetFetchState } from "app/shared/utils/fetch-state";
import { PpcHttpService } from "app/services/ppc_http.service";
import * as insightsActions from 'app/insights/insights.actions';
import * as actions from "app/outcome-audience/outcome-audience.actions";
import { OutcomeAudience, selectOutcomeAudiences, PveRequest } from "app/outcome-audience/outcome-audience.reducer";
import { isDefined } from "app/shared/utils/utils";
import { ChangeContext } from "app/hierarchy/hierarchy.actions";
import { find, first } from "lodash";
import { UrlService } from 'app/services/url.service';
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { outcomeAudiencesUrl, pveRequestUrl } from "app/shared/constants/insights.urls";

@Injectable()
export class OutcomeAudienceService {
  @Effect()
  fetchOutcomeAudiences$ = this.actions$.pipe(
    ofType(actions.FetchOutcomeAudiences.type),
    (
      fetchResource(
        () => this.getOutcomeAudiencesForProduct().pipe(map(outcomeAudiences => new actions.LoadOutcomeAudiences(outcomeAudiences)))
      )
    )
  );

  @Effect()
  changeContext$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    mergeMap(() => [
      new ResetFetchState(insightsActions.FetchDemographics),
      new ResetFetchState(insightsActions.FetchTabs),
      new ResetFetchState(actions.FetchOutcomeAudiences),
    ])
  );

  @Effect()
  fetchOutcomeAudienceItems$ = this.store.select("outcomeAudiences", "selectedOutcomeAudienceId").pipe(
    filter(isDefined),
    mergeMap(outcomeAudienceId => [
      new insightsActions.FetchDemographics(outcomeAudienceId, "Outcome Audience", "outcome-audience"),
      new insightsActions.FetchDemographicsConfig(userPreferenceKeys.standardDemographics("outcome-audience"), outcomeAudienceId, "Outcome Audience", "outcome-audience"),
      new insightsActions.SetTopLevelTab("Person Level", "outcome-audience"),
      new insightsActions.FetchTabs(outcomeAudienceId, "Outcome Audience", "outcome-audience"),
      new insightsActions.FetchCustomTabsConfig(outcomeAudienceId, "Outcome Audience", "outcome-audience"),
    ])
  );

  @Effect()
  defaultSelectedOutcomeAudience$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    switchMap(() => {
      return observableMerge(
        this.actions$.pipe(ofType(actions.LoadOutcomeAudiences.type)),
      ).pipe(
        withLatestFrom(
          this.store.select("outcomeAudiences").pipe(select(selectOutcomeAudiences)),
          this.store.select("outcomeAudiences", "selectedOutcomeAudienceId"),
          this.urlService.pathParams$.pipe(map(params => +params.outcomeAudienceId)),
        ),
      )
    }),
    filter(([_, outcomeAudiences, selectedOutcomeAudienceId, paramsOutcomeAudienceId]) => (!paramsOutcomeAudienceId || !find(outcomeAudiences, {id: paramsOutcomeAudienceId}) || !selectedOutcomeAudienceId || !find(outcomeAudiences, {id: selectedOutcomeAudienceId})) && !!outcomeAudiences.length),
    map(([_, outcomeAudiences, selectedOutcomeAudienceId, paramsOutcomeAudienceId]) => new actions.SetSelectedOutcomeAudience((find(outcomeAudiences, {id: paramsOutcomeAudienceId}) || first(outcomeAudiences)) as OutcomeAudience)),
  );

  constructor(private http: PpcHttpService, private actions$: Actions, private store: Store<AppState>, private urlService: UrlService) { }

  // If a product_slug is not provided the active context will inserted by HierarchyInterceptor
  getOutcomeAudiencesForProduct(product_slug?: string): Observable<OutcomeAudience[]> {
    const headers = product_slug ? {'x-context': product_slug} : {}
    return this.http.get(outcomeAudiencesUrl(), {headers}) as Observable<OutcomeAudience[]>;
  }

  sendPveRequest(params: PveRequest) {
    // TODO: Requires PVE/CAM request
    return this.http.post(pveRequestUrl(), params);
  }
}
