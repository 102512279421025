import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.sass']
})
export class NoticeComponent {

  constructor() { }
}
