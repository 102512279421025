<div class="page-container">
  <div class="header">
    <div class="input-section">
      <div class="input-label">View</div>
      <mat-select class="dropdown" [(ngModel)]="selectedCompetitor" (selectionChange)="selectCompetitor($event)">
        <mat-option *ngFor="let competitor of competitorBrands" [value]="competitor">{{competitor.name}}</mat-option>
      </mat-select>
    </div>
    <div class="input-section">
      <div class="input-label">Attribute Type</div>
      <mat-select class="dropdown" [ngModel]="modelType$ | async" (selectionChange)="changeModelType($event.value)">
        <mat-option *ngFor="let modelType of modelTypes" [value]="modelType.key">{{modelType.display}}</mat-option>
      </mat-select>
    </div>
    <ng-container *checkFeatureAccess="let canAccessAssets=canAccess; feature: 'assets'">
      <button *ngIf="canAccessAssets"
              routerLink="../assets"
              class="action-btn"
              mat-button
      >EDIT ASSETS</button>
    </ng-container>

  </div>
  <ng-container *ngIf="(competitorHasData$ | async); else noData">
    <app-differentiators-chart
      (selectAttribute)="selectAttribute($event)"
      (hoverAttribute)="hoverAttribute($event)"
      [attributeAnalyses$]="attributeAnalyses$"
      [selectedAttributes]="highlightedAttributes"
    ></app-differentiators-chart>

    <div class="tabs">
      <div
        (click)="selectedTab$.next('all'); clearSearch$.next();"
        class="tab"
        [class.selected]="(selectedTab$ | async) === 'all'"
      >All</div>
      <div
        (click)="selectedTab$.next('top')"
        class="tab"
        [class.selected]="(selectedTab$ | async) === 'top'"
      >Brand Top Attributes</div>
      <div
        (click)="selectedTab$.next('differentiated')"
        class="tab"
        [class.selected]="(selectedTab$ | async) === 'differentiated'"
      >Top Differentiated</div>
      <div
        (click)="selectedTab$.next('shared')"
        class="tab"
        [class.selected]="(selectedTab$ | async) === 'shared'"
      >Top Shared</div>
    </div>
    <app-creative-attribute-list
      (selectAttribute)="selectAttribute($event)"
      (hoverAttribute)="hoverAttribute($event)"
      (selectAttributeFilter)="selectAttributes($event)"
      [scoredAttributes$]="scoredAttributeList$"
      [clearSearch$]="clearSearch$"
    ></app-creative-attribute-list>

    <app-genome-attribute-detail
      *ngIf="clientCompetitor && selectedCompetitor"
      [class.on]="selectedGenomeDetail.show"
      [class.off]="!selectedGenomeDetail.show"
      (closeClick)="closeGenomeDetail()"
      [attributeAnalyses$]="attributeAnalyses$"
      [attributeId]="selectedGenomeDetail.attributeId"
      [competitorName]="selectedCompetitor.name"
      [clientName]="clientCompetitor.name"
      [clientCreatives]="clientCreatives"
      [competitorCreatives]="competitorCreatives"
    ></app-genome-attribute-detail>
  </ng-container>
  <ng-template #noData>
    <div class="no-data">
      <ng-container *ngIf="isDataLoaded$ | async">
        <div>You have no data for the selected competitor</div>
        <div>
          Click on "Edit Assets" to upload creatives for this competitor or
          switch to a different competitor to view that data
        </div>
      </ng-container>
      <ppc-loading-overlay *ngIf="!(isDataLoaded$ | async)"></ppc-loading-overlay>
    </div>
  </ng-template>
</div>
