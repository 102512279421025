<div id="container">
<!-- <div id="container" theme> -->
  <!-- <span (click)="themeService.cycleTheme()" class="toggle-theme fa fa-lightbulb-o fa-2x"></span> -->
  <app-left-hand-nav *ngIf="hasClients$ | async"></app-left-hand-nav>

  <div id="main">
    <div *ngIf="loggedIn$ | async" id="brand-selector-container">
      <div *ngIf="hasClients$ | async" id="brand-selector-wrapper">
        <app-brand-selector></app-brand-selector>
      </div>

      <div id="user-info-wrapper">
        <user-info></user-info>
      </div>
    </div>

    <div #sidenavContainer id="sidenav-content" [class.has-iframe]="hasIframe$ | async">
      <div *ngIf="errorMsg" class="global-error">{{errorMsg}}</div>
      <router-outlet></router-outlet>
      <app-revision-info></app-revision-info>
      <ppc-segment-picker *ngIf="hasClients$ | async"></ppc-segment-picker>
    </div>
  </div>
</div>
<ppc-tooltip></ppc-tooltip>

