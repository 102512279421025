import { SegmentV2 } from "app/audiences/discover/segment-v2.model";
import { HierarchyRegion } from "app/hierarchy/hierarchy.interface";
import { environment } from 'environments/environment';

import { map, merge, get } from 'lodash';
import { InsightsContextType, ResourceType } from "./insights.constants";
import { SegmentLike } from '../models/segment-like.model';


export const RadarChartAccess = {radar_charts_access : 'CRUD'};
export interface Tab {
  name: string;
  id?: number;
  resource_id?: number;
  resource_type?: ResourceType;
  children?: Tab[];
  tab_key?: string;
  description?: string;
  parent_id?: number;
  visible?: boolean;
  expanded?: boolean;
  excluded_regions?: string[];
  insights_context: InsightsContextType;
  order?: number;
}

export interface Bucket {
  id?: number;
  name: string;
  short_id: string;
  order?: number;
  count?: any
  _destroy?: boolean;
  vendor_segment_identifier?: string;
  percent?: number;
  individualBucketCount?: number;
  individualBucketCountLabel?: string;
}

/**
 * Enum representing chart types with specific constant values to keep original naming
 */
export enum ChartTypes {
  BarHorizontal= "bar-horizontal",
  BarVertical= "bar-vertical",
  Doughnut= "donut",
  Radar= "radar"
}


// extract type Value union from enum
// example - "donut" | "bar-horizontal" | "bar-vertical" | "radar"
export type WidgetType = `${ChartTypes}`;

export interface Demographic {
  name: string;
  buckets: Bucket[];
  widget_type: WidgetType;
  id: number;
  is_standard?: boolean;
  visible?: boolean;
  resource_id?: number;
  resource_type?: ResourceType;
  is_id_count?: boolean;
  id_count?: DemographicIdCountType;
  custom_tab_id?: number;
  description?: string;
  created_at?: string;
  data_provider?: string;
}

export interface DemographicConfig {
  id: number;
  visible: boolean;
  widget_type?: WidgetType;
  is_id_count?: boolean;
  id_count?: RegionDemographicIdCountType & DemographicIdCountType;
}

export interface ResourceDefinedSegments { include: string[][]; exclude?: string[][] };

export interface WidgetConfig {
  [key: string]: DemographicConfig[];
}


export type TopLevelTabType = "Person Level" | "Market Level";

export type RegionDemographicIdCountType = 'percentage' | 'absolute';

export type DemographicIdCountType = 'percentage' | 'absolute' | 'scaled';

export interface Filter {
  pathParts?: string[];
  type: string;
  shortId?: string;
  demographicId?: number | string;
  subMarketId?: number;
  truncatedDisplay?: string;
  tab: string;
  name?: string;
  toggleSelf?: Function;
}

export function newDemographic(customTabId: number = null, resourceId: number, resourceType: ResourceType): Demographic {
  return {
    id: null,
    buckets: [],
    name: '',
    widget_type: 'bar-horizontal',
    is_id_count: false,
    custom_tab_id: customTabId,
    resource_type: resourceType,
    resource_id: resourceId,
  }
}


export function toMillions(value: string | number, fractionDigits = 1): string {
  // void accepts a single argument and always returned undefined
  // It's used in place of undefined for backwards-compatibility reasons
  // eslint-disable-next-line eqeqeq
  if (value == void(0) || value === "") { return "N/A"; }

  let resultValue: number = (typeof value === 'string') ? parseFloat(value) : value;
  if (Number.isNaN(resultValue)) {return "N/A"; }

  if (resultValue >= 999950.0) {
    resultValue = (resultValue < 1000000.0) ? 1000000.0 : resultValue;
    return (resultValue / 1000000.0).toFixed(fractionDigits) + "MM";
  }

  if (resultValue >= 1000.0) {
    return (resultValue / 1000.0).toFixed(fractionDigits) + "K";
  }

  let output = resultValue.toFixed(fractionDigits);

  // If fractionDigits >= 1, remove all  values after decimal point
  if (output.endsWith("." + "0".repeat(fractionDigits))) {
    output = output.slice(0, output.length - (1 + fractionDigits));
  }

  return output;
}

export function fixCounts(segments: SegmentLike[], region: HierarchyRegion): SegmentLike[] {
  return map(segments, segment => {
    if (environment.isTier3) {
      return merge(segment, {
        count: {
          matched: get(segment, ['count', 'people', region.slug.toLowerCase()], 0),
          modeled: get(segment, ['count', 'people', `${region.slug.toLowerCase()}_scaled`], 0),
        }
      })
    } else {
      return merge(segment, {
        count: {
          matched: get(segment, ["count", "people", "global"], 0),
          modeled: get(segment, ["count", "people", "global"], 0)
        }
      })
    }
  })
}
