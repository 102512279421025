
import {mergeMap} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Channel} from "../tardiis-channel.model";
import * as moment from "moment";
import {ChannelsService} from "../channels.service";
import {anyTruthyLeaves, omit} from "app/shared/utils/utils";
import {cloneDeep, isEqual} from 'lodash';

@Component({
  selector: 'app-channel-form',
  templateUrl: './channel-form.component.html',
  styleUrls: ['./channel-form.component.sass']
})
export class ChannelFormComponent implements OnChanges {

  @Input() channel: Channel;

  @Output() closeEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() runEvent = new EventEmitter();
  @Output() tardiisErrorEvent = new EventEmitter();

  apiError;
  validationErrors: any = {};
  originalChannelCopy: Channel;
  sortedDayParts;

  dayPartNameValidators = [
    { errorMessage: 'Duplicate daypart name',
      isValid: text => this.channel.template.day_parts.filter(dayPart => dayPart.name == text).length <= 1 },
    { errorMessage: 'Name cannot be blank',
      isValid: text => text.length > 0 }
  ];

  constructor(private channelService: ChannelsService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.channel) {
      this.originalChannelCopy = cloneDeep(this.channel);
      this.sortedDayParts = this.getSortedDayParts();
    }
  }

  getSurveyDateRanges() {
    const {survey_dates, reach_period} = this.channel.template;

    return survey_dates.map(date => ({
      raw: date,
      start: moment.utc(date).format("MM/DD/YYYY"),
      end: moment.utc(date).add(reach_period - 1, 'days').format("MM/DD/YYYY")
    }))
  }

  getSortedDayParts() {
    return this.channel.template.day_parts.sort((a, b) => a.name.localeCompare(b.name))
  }

  resetBlankDaypartName(daypart) {
    if (daypart.name == "") {
      daypart.name = daypart.original_tardiis_name;
    }
  }

  // Performs a deep equality check between originalChannelCopy and the channel being edited
  // omitting the properties that don't necessitate a rerun of the channel
  channelNeedsRerun() {
    const propsThatDontMatter: (keyof Channel)[] = ['name', 'output', 'template'];

    return !isEqual(
      omit(propsThatDontMatter, this.originalChannelCopy),
      omit(propsThatDontMatter, this.channel)
    )
  }

  updateChannel() {
    this.apiError = null;

    this.validate();
    if (anyTruthyLeaves(this.validationErrors)) {return; }

    const update$ = this.channelService.updateProject(this.channel);
    update$.subscribe(
      channel => {
        this.updateEvent.emit(channel);
        this.closeEvent.emit();
      },
      error => {
        this.apiError = error;
      }
    );

    if (this.channelNeedsRerun()) {
      update$.pipe(
        mergeMap(_ => this.channelService.runProject(this.channel._id)))
        .subscribe(
          _ => this.runEvent.emit(this.channel),
          error => {
            error.isTardiisError && this.tardiisErrorEvent.emit({error, channel: this.channel})
          }
        )
    }
  }

  removeSplit(i) {
    this.channel.input.splits.splice(i, 1);
    this.validate()
  }

  addSplit() {
    this.channel.input.splits.push({
      day_part_id: -1,
      media_type_id: -1,
      min: 0,
      max: 100
    })
  }

  isSameIndex(i, x) {
    return i
  }

  allDayPartsUsed() {
    return this.channel.input.day_parts.size == this.channel.template.day_parts.length;
  }

  toggleSelectAllDayParts() {
    if (this.allDayPartsUsed()) {
      this.channel.input.day_parts = new Set();
    } else {
      this.channel.input.day_parts = new Set(this.channel.template.day_parts.map(x => x._id));
    }
    this.validate();
  }

  toggleInput(id: number, input_type: string, input_type_id: string): void {
    if (this.channel.input[input_type].has(id)) {
      this.channel.input[input_type].delete(id);
      this.channel.input.splits.forEach(split => {
        if (split[input_type_id] === id) { split[input_type_id] = -1; }
      });
    } else {
      this.channel.input[input_type].add(id);
    }
    this.validate();
  }

  toggleMediaType(id: number): void {
    this.toggleInput(id, "media_types", "media_type_id");
  }

  toggleDayPart(id: number): void {
    this.toggleInput(id, "day_parts", "day_part_id");
  }

  toggleSurveyDate(date: string): void {
    if (this.channel.input.survey_dates.has(date)) {
      this.channel.input.survey_dates.delete(date);
    } else {
      this.channel.input.survey_dates.add(date);
    }
    this.validate();
  }

  getFilteredMediaTypes() {
    return this.channel.template.media_types.filter(({_id}) => this.channel.input.media_types.has(_id))
  }

  getFilteredDayparts() {
    return this.channel.template.day_parts
      .filter(({_id}) => this.channel.input.day_parts.has(_id))
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  saveDisabled(): boolean {
    const dup = this.dayPartNameValidators.some(validator => {
      return this.channel.template.day_parts.some(dayPart => {
        return !validator.isValid(dayPart.name);
      });
    });
    return dup || anyTruthyLeaves(this.validationErrors);
  }

  validate() {
    const {name, input: {splits, media_types, day_parts, survey_dates}} = this.channel;
    this.validationErrors = {
      name: (!name || name.length < 1 || name.length > 256) && "Name must be between 1 and 256 characters long",
      media_types: media_types.size < 1 && "You must select at least 1 Media Type",
      day_parts: day_parts.size < 1 && "You must select at least 1 Daypart",
      survey_dates: survey_dates.size < 1 && "You must select at least 1 Campaign",
      splits: splits.some(split =>
        split.day_part_id === -1
        || split.min < 0
        || split.max > 100
        || split.max < split.min
      ) && "Each split must have a valid daypart. Also the min must be greater than 0 and less than the max which must be less than 100"
    }
  }
}
