import * as actions from 'app/explore/explore.actions';
import { createSelector } from '@ngrx/store';
import { find, get, keyBy, map as _map, sortBy, values, omit, some } from "lodash";
import { hasExcludedSegments, IS_ALL_DATA, PersonaIdsType } from 'app/explore/explore.utils';

export interface Persona {
  id?: number;
  product_slug?: string;
  name?: string;
  is_all_data?: boolean;
  identifiers?: PersonaIdsType;
  default?: boolean;
  deletedSegmentIds?: string[];
  deletedSegmentsChecked?: boolean;
}

export function newPersona() {
  return {
    name: "Unnamed Persona",
    icon: "crosshairs",
    is_all_data: false,
    default: false,
    identifiers: {included_ids: [], excluded_ids: []},
    deletedSegmentIds: null,
    deletedSegmentsChecked: false,
  };
}

export interface State {
  personas: {[id: number]: Persona};
  selectedPersonaId?: number;
  allDataPersonaId?: number;
  personaUnderEdit?: Persona;
  defaultPersonaId?: number;
  selectedPersonaIds?: number[];
}

export const defaultState: State = {
  personas: {},
  selectedPersonaId: null,
  defaultPersonaId: null,
  selectedPersonaIds: []
}

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.LoadPersonas.type:
      const newPersonas = keyBy(action.personas, 'id');
      const allDataPersonaId = get(find(action.personas, IS_ALL_DATA), "id", null);
      return {...state, personas: newPersonas, allDataPersonaId: allDataPersonaId};
    case actions.LoadPersona.type:
      return {
        ...state,
        personas: {...state.personas, [action.persona.id]: action.persona},
        selectedPersonaId: state.selectedPersonaId ? state.selectedPersonaId : action.persona.id
      };
    case actions.SetSelectedPersona.type:
      return {
        ...state,
        selectedPersonaId: action.persona && action.persona.id,
      };
    case actions.EditPersona.type:
      return { ...state, personaUnderEdit: action.persona }
    case actions.RemovePersona.type:
      return {...state,
        personas: omit(state.personas, action.persona.id)
      };
    case actions.LoadDefaultPersona.type:
      return { ...state, defaultPersonaId: action.defaultPersonaId }
    case actions.TogglePersona.type:
      return {
        ...state,
        selectedPersonaIds: state.selectedPersonaIds.includes(action.id) ?
          state.selectedPersonaIds.filter(id => id != action.id) :
          state.selectedPersonaIds.concat([action.id])
      };
    case actions.ClearSelectedPersonas.type:
      return {...state, selectedPersonaIds: []}
    case actions.SetActivePersonaDeletedSegmentIds.type:
      return { ...state, personas: {
        ...state.personas,
        [action.persona.id]: {
          ...action.persona,
          deletedSegmentIds: action.deletedSegmentIds,
        }
      }};
    case actions.SetActivePersonaDeletedSegmentsChecked.type:
      return { ...state, personas: {
        ...state.personas,
        [action.persona.id]: {
          ...action.persona,
          deletedSegmentsChecked: action.deletedSegmentsChecked
        }
      }};
    default:
      return state;
  }
}

export const selectPersonas = createSelector(
  (state: State) => state.personas,
  (state: State) => state.defaultPersonaId,
  (personas, defaultPersonaId) => sortBy(values(personas).map(persona => {
    return {
      ...persona,
      default: persona.id === defaultPersonaId
    }
  }), persona => persona.name.toLowerCase())
)

export const selectDefaultPersona = createSelector(
  (state: State) => state.personas,
  (state: State) => state.defaultPersonaId,
  (personas, defaultPersonaId) => {
    if (personas[defaultPersonaId]) {
      return personas[defaultPersonaId];
    }
  }
)

export const selectActivePersona = createSelector(
  (state: State) => state.personas,
  (state: State) => state.selectedPersonaId,
  (state: State) => state.defaultPersonaId,
  (personas, selectedPersonaId, defaultPersonaId) => {
    if (personas[selectedPersonaId]) {
      return { ...personas[selectedPersonaId], default: selectedPersonaId === defaultPersonaId }
    }
  }
)

export const selectSelectedPersonas = createSelector(
  (state: State) => state.selectedPersonaIds,
  (state: State) => state.personas,
  selectActivePersona,
  (ids, personas, activePersona) => {
    return ids.length && _map(ids, id => personas[id]) || (activePersona ? [activePersona] : []);
  }
);


export const activePersonaIsAllData = createSelector(
  (state: State) => state.selectedPersonaId,
  (state: State) => state.allDataPersonaId,
  (selectedPersonaId, allDataPersonaId) => selectedPersonaId === allDataPersonaId
)

export const selectAllDataPersona = createSelector(
  (state: State) => state.personas,
  (personas) => find(personas, IS_ALL_DATA)
)

export const selectAllDataPersonaId = createSelector(
  selectAllDataPersona,
  (allDataPersona) => get(allDataPersona, "id", null)
)

export const activePersonaHasExcludedIds = createSelector(
  selectActivePersona,
  (activePersona) => activePersona && hasExcludedSegments(activePersona)
)

export const selectedPersonasHaveExcludedIds = createSelector(
  selectSelectedPersonas,
  (selectedPersonas) => some(selectedPersonas, persona => hasExcludedSegments(persona))
)
