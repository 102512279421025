<ppc-loading-overlay *ngIf="loading"></ppc-loading-overlay>
<div class="plans-top-row">
  <div class="info-left">
    <div class="page-title">
      <span class="gold-title">Scenarios</span> Summary
    </div>
  </div>

  <div class="info-right">
    <div class="search-inputs">
      <div class="text-search">
        <i class="fa fa-search"></i>
        <input type="text" name="searchQuery" [(ngModel)]="searchQuery" placeholder="Search by name"/>
      </div>
      <app-date-range-picker #dateRangePicker
        [options]="datePickerOptions"
        [(ngModel)]="dateModel"
        tense="plansSummaryDefault"
        placeholder="Select a date range">
      </app-date-range-picker>
    </div>

    <button class="action-btn" mat-button (click)="openCreateScenarioDialog()">Create Scenario</button>
  </div>
</div>

<div class="cards-container">

  <div *ngIf="error.isError" class="error-card">
    <div class="error-message">Something went wrong, please try again.</div>
    <div class="error-button" (click)="onErrorButtonClick()">RELOAD</div>
  </div>

  <div *ngFor="let plan of searchResultPlans">
    <app-plan-summary-card
      [plan]="plan" [openCreateDialog]="openEditScenarioDialog.bind(this)"
      [show]="!isFormOpen" [currencyCode]="currencyCode"
      (deletePlan)="onDeletePlan($event)">
    </app-plan-summary-card>
  </div>

</div>

<div class="mid-message" *ngIf="plansLoaded && (plans.length < 1)">Please Create a Scenario</div>
<div class="mid-message" *ngIf="plansLoaded && (plans.length > 0) && searchResultPlans.length < 1">
  Your search criteria does not match any existing plans
</div>


<!-- overlay with form -->
<app-slide-in-overlay #overlay>
  <app-scenario-dialog #scenarioForm
                       (onClose)="onFormClose()"
                       [isNewPlan]="newScenario"
                       [planNames]="planNames"
                       (completedSave)="$event && onPlanSave($event)"
  ></app-scenario-dialog>
</app-slide-in-overlay>
