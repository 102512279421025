import {mergeMap, filter, delay, map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {
  CreateCompetitor,
  EditCompetitor,
  DeleteCompetitor,
  DeleteCreative,
  FetchCompetitors,
  FetchCreativeAttributes,
  FetchCreatives,
  FetchScores,
  LoadCompetitor,
  LoadCompetitors,
  LoadCreativeAttributes,
  LoadCreatives,
  LoadScores,
  LoadTemplateUploadReport,
  ResetCreativesData,
  UploadCreatives,
  UploadCreativeScoreTemplate,
  ImportCreatives,
  FetchAttributeAverages,
  LoadAttributeAverages,
  ChangeCompetitor,
  FetchCreativesByAttribute
} from "../creative/creative.actions";
import {fetchResource, ResetFetchState} from "../shared/utils/fetch-state";
import {Observable} from "rxjs";
import {PpcHttpService, apiUrl} from "./ppc_http.service";
import {Competitor, Creative, CreativeAttribute, Score, AttributeAverage} from "../creative/creative.reducers";
import {ChangeContext} from "app/hierarchy/hierarchy.actions";
import {isFullContextSlugs} from "app/hierarchy/hierarchy.utils";
import {ProcessStatus} from "app/shared/interfaces/process-status.interface";
import { V3 } from '../shared/utils/constants';

export interface TemplateUploadReport {
  bad_creative_ids: string[]
  bad_attribute_ids: string[]
  total_creative_cols: number;
  total_attribute_rows: number;
  bad_score_numbers: {creative_id: string, attribute_id: string}[]
}

@Injectable()
export class CreativeService {

  constructor(private actions$: Actions, private http: PpcHttpService) { }

  creativesUrl(id?: string): string {
    return apiUrl(`/api/${V3}/creatives/${id || ""}`)
  }

  creativeScoreTemplateUrl(): string {
    return apiUrl(`/api/${V3}/creative_attribute_scores/template`);
  }

  competitorUrl(id: string): string {
    return apiUrl(`/api/${V3}/competitors/${id}`)
  }

  competitorsUrl(): string {
    return apiUrl(`/api/${V3}/competitors/`)
  }

  creativeAttributesUrl(): string {
    return apiUrl(`/api/${V3}/creative_attributes`)
  }

  creativeAttributeScoresUrl(): string {
    return apiUrl(`/api/${V3}/creative_attribute_scores`)
  }

  creativeImportUrl(): string {
    return apiUrl(`/api/${V3}/creatives/import`)
  }

  creativeAttributeAverageScoresUrl(competitorId: string): string {
    return apiUrl(`/api/${V3}/creative_attribute_scores/averages/${competitorId}`)
  }

  creativesForAttributeUrl(attributeId: string, competitorIds: string[]): string {
    const joinedCompetitorIds = competitorIds.join('&competitor_ids[]=');
    return apiUrl(`/api/${V3}/creatives/for_attribute/${attributeId}?competitor_ids[]=${joinedCompetitorIds}`)
  }

  creativeImportStatusUrl(): string {
    return apiUrl(`/api/${V3}/creatives/import_status`)
  }

  @Effect()
  fetchCompetitors$ = this.actions$.pipe(
    ofType(FetchCompetitors.type),
    (fetchResource(
      () => this.getCompetitors().pipe(map(competitors => new LoadCompetitors(competitors)))
    )));

  @Effect()
  loadCompetitors$ = this.actions$.pipe(
    ofType(LoadCompetitors.type),
    map((action: LoadCompetitors) => new FetchAttributeAverages(action.competitors.find(comp => comp.is_client).id)))

  @Effect()
  fetchScores$ = this.actions$.pipe(
    ofType(FetchScores.type),
    (fetchResource(
      (action: FetchScores) => this.getCreativeAttributeScores(action.creativeIds).pipe(map(scores => new LoadScores(scores)))
    )));

  @Effect()
  fetchCreatives$ = this.actions$.pipe(
    ofType(FetchCreatives.type),
    (fetchResource(
      (action: FetchCreatives) => this.getCreatives(action.ids).pipe(map(creatives => new LoadCreatives(creatives)))
    )));

  @Effect()
  fetchCreativeAttributes$ = this.actions$.pipe(
    ofType(FetchCreativeAttributes.type),
    (fetchResource(
      () => this.getCreativeAttributes().pipe(map(attrs => new LoadCreativeAttributes(attrs)))
    )));

  @Effect()
  createCompetitor$ = this.actions$.pipe(
    ofType(CreateCompetitor.type),
    (fetchResource(
      action => this.createCompetitor(action.name).pipe(map(competitor => new LoadCompetitor(competitor)))
    )));

  @Effect()
  editCompetitor$ = this.actions$.pipe(
    ofType(EditCompetitor.type),
    (fetchResource(
      action => this.editCompetitor(action.competitor).pipe(map(competitor => new LoadCompetitor(competitor)))
    )));

  @Effect()
  deleteCompetitor$ = this.actions$.pipe(
    ofType(DeleteCompetitor.type),
    (fetchResource(
      action => this.deleteCompetitor(action.id).pipe(map(_ => new FetchCompetitors()))
    )));

  @Effect()
  uploadTemplate$ = this.actions$.pipe(
    ofType(UploadCreativeScoreTemplate.type),
    (fetchResource(
      action => this.uploadDataTemplate(action.formData).pipe(mergeMap(report => [
        new LoadTemplateUploadReport(report)
      ]))
    )));

  @Effect()
  uploadCreatives$ = this.actions$.pipe(
    ofType(UploadCreatives.type),
    (fetchResource(
      action => this.uploadCreatives(action.formData).pipe(mergeMap(({ creatives, competitor }) => [
        new LoadCreatives(creatives),
        new LoadCompetitor(competitor)
      ]))
    )));

  @Effect()
  deleteCreative$ = this.actions$.pipe(
    ofType(DeleteCreative.type),
    (fetchResource(
      action => this.deleteCreative(action.id).pipe(map(_ => new FetchCreatives()))
    )));

  @Effect()
  contextChange$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    filter(({ contextSlugs }: ChangeContext) => isFullContextSlugs(contextSlugs)),
    mergeMap(() => [
      new ResetFetchState(FetchCompetitors),
      new ResetFetchState(FetchAttributeAverages),
      new ResetCreativesData()
    ]), );

  @Effect()
  pollForScoreUpdates$ = this.actions$.pipe(
    ofType(LoadCreatives.type),
    delay(5000),
    map(({ creatives }: LoadCreatives) => creatives.filter(c => c.is_being_processed)),
    filter(creativesToPoll => creativesToPoll.length  > 0),
    map(creatives => new FetchCreatives(creatives.map(c => c.id))), );

  @Effect()
  importCreatives$ = this.actions$.pipe(
    ofType(ImportCreatives.type),
    (fetchResource(
      _ => this.importCreatives()
    )));

  @Effect()
  fetchCreativeAttributesAverages$ = this.actions$.pipe(
    ofType(FetchAttributeAverages.type),
    (fetchResource(
      action => this.getCreativeAttributeAverages(action.competitorId).pipe(map(averages => new LoadAttributeAverages(averages, action.competitorId)))
    )));

  @Effect()
  fetchAttributeAveragesOnCompetitorChange$ = this.actions$.pipe(
    ofType(ChangeCompetitor.type),
    map((action: ChangeCompetitor) => new FetchAttributeAverages(action.competitorId || 'nonClient')));

  @Effect()
  fetchCreativesByAttribute$ = this.actions$.pipe(
    ofType(FetchCreativesByAttribute.type),
    ( fetchResource(
      action => this.getCreativesForAttribute(action.attributeId, action.competitorIds).pipe(
        mergeMap(creativesWithScores => [
          new LoadCreatives(creativesWithScores.creatives),
          new LoadScores(creativesWithScores.scores)
        ]
        ))
    )));

  getCreative(creativeId: string): Observable<Creative> {
    return this.http.get(this.creativesUrl(creativeId));
  }

  getCreatives(ids?: string[]): Observable<Creative[]> {
    const request = ids ? this.http.post(this.creativesUrl() + 'by_ids', {ids})
      : this.http.get(this.creativesUrl());
    return request;
  }

  getCompetitors(): Observable<Competitor[]> {
    return this.http.get(this.competitorsUrl());
  }

  createCompetitor(name: string): Observable<Competitor> {
    return this.http.post(this.competitorsUrl(), {name});
  }

  editCompetitor(competitor: Competitor): Observable<Competitor> {
    return this.http.put(this.competitorUrl(competitor.id), competitor);
  }

  deleteCompetitor(id: string): Observable<any> {
    return this.http.delete(this.competitorUrl(id));
  }

  getCreativeAttributes(): Observable<CreativeAttribute[]> {
    return this.http.get(this.creativeAttributesUrl());
  }

  getCreativeAttributeScores(ids?: string[]): Observable<Score[]> {
    const request = ids ? this.http.post(this.creativeAttributeScoresUrl() + '/for_creatives', {creative_ids: ids})
      : this.http.get(this.creativeAttributeScoresUrl());
    return request;
  }

  uploadCreatives(formData: FormData): Observable<{creatives: Creative[], competitor: Competitor}> {
    return this.http.upload(this.creativesUrl(), formData);
  }

  deleteCreative(id: string): Observable<any> {
    return this.http.delete(this.creativesUrl(id));
  }

  uploadDataTemplate(formData: FormData): Observable<TemplateUploadReport> {
    return this.http.upload(this.creativeScoreTemplateUrl(), formData)
  }

  downloadDataTemplate() {
    return this.http.getDownload(this.creativeScoreTemplateUrl())
      .subscribe(res => this.http.downloadFile(res, 'template.csv') )
  }

  importCreatives() {
    return this.http.post(this.creativeImportUrl(), {});
  }

  getCreativeAttributeAverages(competitorId: string): Observable<AttributeAverage[]> {
    return this.http.get(this.creativeAttributeAverageScoresUrl(competitorId));
  }

  getCreativesForAttribute(attributeId: string, competitorIds: string[]): Observable<{creatives: Creative[], scores: Score[]}> {
    return this.http.get(this.creativesForAttributeUrl(attributeId, competitorIds));
  }

  getCreativeImportStatus(): Observable<ProcessStatus> {
    return this.http.get(this.creativeImportStatusUrl());
  }
}

export function creativeImageUrl(creativeId: string, productId: string): string {
  return(apiUrl(`/api/${V3}/creatives/${creativeId}/image?product_id=${productId}`));
}
