export class Channel {
  name: string;
  allocationShare: number;
  budget: number;
  sales: number;
  acceleration: number;
  asymmetry: number;
  asymptote: number;
  base: number;
  slope: number;
  curveType: string;
  reachAllocations: Array<any>;
  reach: number;
  costPerPoint: number;
  isTrp: boolean;
  isCPM;

  constructor(data) {
    this.name = data.name;
    this.allocationShare = data.allocation_share.toFixed(2);
    this.acceleration = data.acceleration;
    this.asymmetry = data.asymmetry;
    this.asymptote = data.asymptote; // this val incorrect
    this.base = data.base;
    this.slope = data.slope;
    this.budget = data.budget;
    this.sales = this.forecastSales(data.budget);
    this.curveType = data.curveType || "sales";
    this.reachAllocations = data.reach_allocations || [];
    this.isTrp = this.curveType.toLowerCase().indexOf('trp') > -1;
    this.reach = this.forecastSales(data.reach);
    this.isCPM = data.cpp_or_cpm;
    this.costPerPoint = this.getCostPerPoint(data.cost_per_point, data.universe, data.target_population);
  }

  getCostPerPoint(cpp: number|null, universe: number|null, target: number|null): any {
    let cost = cpp;
    const audienceSize = target || universe;
    if (this.isCPM) {
      cost = (cpp * audienceSize) / 100000;
    }
    return cost;
  }

  forecastSales(budget: number, checkForTrp: boolean = false): any {
    const cpp = (checkForTrp && this.isTrp) ? this.costPerPoint : 1;
    return (this.asymptote +
      (this.base - this.asymptote) / Math.pow(1 + Math.pow((budget / cpp) / this.acceleration, this.slope), this.asymmetry));
  };
}
