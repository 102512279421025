import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { mergeMap, map } from 'rxjs/operators';
import { capitalize, filter, map as _map, merge, get, find, orderBy, pick } from 'lodash';

import { objToHttpParams } from "app/shared/utils/http-utils";
import { Node } from "./root-nodes/root-node.interface";
import { SegmentFetchOptionsV4 } from "app/segments-v2/segment-v2.service";
import { SegmentV2 } from "app/audiences/discover/segment-v2.model";
import { PERMISSION_INSIGHTS } from "app/shared/utils/constants";
import { environment } from 'environments/environment';
import { segmentToNode as tier3SegmentToNode } from './root-nodes/tier-3-root';
import { audiencesV3Url, lookalikesV3Url, motivationsUrl, segmentsHierarchyUrl, segmentsUrl } from "app/shared/constants/segments.urls";

export function flattenJsonApiObjects(nodes: any[]) {
  return _map(nodes, node => {
    return merge(node.attributes, pick(node, ['type', 'id']));
  });
}

export const makeLeaves = nodes => nodes.map(node => merge(node, {path: {is_leaf: true}}));

function formAsSegmentLike(nodes, type: string) {
  return _map(nodes, node => {
    const { vendor } = node;
    const segmentLikeValues = {
      vendor_name: (vendor && vendor.name) || 'ppc',
      vendor_type: (vendor && capitalize(vendor.type)) || type,
    };
    return { ...node, ...segmentLikeValues };
  });
}

export function getRootChildren(vendorCode: string, http: HttpClient, regionSlug: string): Observable<Node[]> {
  return fetchSegments(http, {vendors: [{vendor_name: vendorCode}], use_cases: [PERMISSION_INSIGHTS]}, regionSlug)
}

export function getRootChildrenAndFlattenRootNode(vendorCode: string, http: HttpClient, regionSlug: string): Observable<Node[]> {
  return fetchSegments(http, {vendors: [{vendor_name: vendorCode}], use_cases: [PERMISSION_INSIGHTS]}, regionSlug).pipe(
    mergeMap(segments => {
      return fetchSegments(http, {parent_id: get(find(segments, {path: {is_leaf: false, is_root: true}}), "identifier"),
        use_cases: [PERMISSION_INSIGHTS]}, regionSlug)
    }),
  )
}

export function segmentToNode(segment: SegmentV2, http: HttpClient, use_cases: string[], regionSlug: string): Node {
  return {
    ...segment,
    count: { ...get(segment, "count"), matched: get(segment, ["count", "people", "global"]) },
    children$: fetchSegments(http, {parent_id: segment.identifier, use_cases}, regionSlug)
  }
}

export function fetchSegments(http: HttpClient, opts: SegmentFetchOptionsV4, regionSlug: string): Observable<Node[]> {
  return http.post<{data: SegmentV2[]}>(segmentsUrl(), opts).pipe(
    map(({ data }) => data),
    map(segments => orderBy(segments, ["path.is_leaf", "name"], ["asc", "asc"])),
    map(segments => _map(segments, segment => ((environment.isTier3 && segment.path && segment.path.is_leaf) ?
      tier3SegmentToNode(segment, http, regionSlug) : segmentToNode(segment, http, opts.use_cases, regionSlug))))
  )
}

export function fixHierarchyCounts(nodes: SegmentV2[]) {
  return _map(nodes, node => {
    return {...node, count: {...get(node, "count"), matched: get(node, ["count", "people", "global"], 0)}}
  })
}

export function fixCounts(segments, region) {
  return _map(segments, segment => {
    if (environment.isTier3) {
      return merge(segment, {
        count: {
          matched: get(segment, ['count', 'people', region.slug.toLowerCase()], 0),
          modeled: get(segment, ['count', 'people', `${region.slug.toLowerCase()}_scaled`], 0),
        }
      })
    } else {
      return segment;
    }
  })
}

// Segment Hierarchy Stuff

export interface SegmentHierarchy {
  node_name: string;
  ppc_object_type?: "Audience" | "Segment" | "Lookalike" | "Motivation";
  vendor_name?: string;
  vendor_type?: string;
  hierarchy_leaf: boolean;
  identifier: string;
  parent_id_for_query: string;
}

export function segmentHierarchyToNode(node: SegmentHierarchy, http: HttpClient, use_cases: string[], regionSlug: string) {
  return {
    name: node.node_name,
    children$: getSegmentsHierarchyChildren(node, http, use_cases, regionSlug)
  }
}

export function getSegmentsHierarchyChildren({hierarchy_leaf, vendor_name, vendor_type, ppc_object_type, identifier, parent_id_for_query}: SegmentHierarchy, http: HttpClient, use_cases: string[], regionSlug: string) {
  if (!hierarchy_leaf) {
    const params = objToHttpParams({parent_identifier: identifier, use_cases})
    return http.get<{data: SegmentHierarchy[]}>(segmentsHierarchyUrl(), {params}).pipe(
      map(response => response.data),
      map(segmentHierarchies => _map(segmentHierarchies,
        segmentHierarchy => segmentHierarchyToNode(segmentHierarchy, http, use_cases, regionSlug)))
    )
  }
  switch (ppc_object_type) {
    case "Segment":
      return fetchSegments(http, {vendors: [{vendor_name, vendor_type}], use_cases,
        parent_id: parent_id_for_query ? parent_id_for_query.replace('{0}', regionSlug) : parent_id_for_query}, regionSlug)
    case "Audience":
      return http.get<{data: Node[]}>(audiencesV3Url(), {params: objToHttpParams({use_cases})}).pipe(
        map(response => response.data),
        map(makeLeaves),
        map(audiences => filter(audiences, "identifier")),
        map(fixHierarchyCounts),
        map(audiences => orderBy(audiences, audience => audience.name.toLowerCase())),
        map(audiences => formAsSegmentLike(audiences, 'Audience'))
      )
    case "Lookalike":
      return http.get<{data: Node[]}>(lookalikesV3Url(), {params: objToHttpParams({use_cases})}).pipe(
        map(response => response.data),
        map(makeLeaves),
        map(lookalikes => filter(lookalikes, "identifier")),
        map(fixHierarchyCounts),
        map(lookalikes => orderBy(lookalikes, lookalike => lookalike.name.toLowerCase())),
        map(audiences => formAsSegmentLike(audiences, 'Lookalike'))
      )
    case "Motivation":
      return http.get<{data: Node[]}>(motivationsUrl(), {params: objToHttpParams({use_cases})}).pipe(
        map(response => response.data),
        map(flattenJsonApiObjects),
        map(makeLeaves),
        map(motivations => filter(motivations, "identifier")),
        map(fixHierarchyCounts),
        map(motivations => orderBy(motivations, motivation => motivation.name.toLowerCase())),
        map(audiences => formAsSegmentLike(audiences, 'Motivation'))
      )
  }
}
