import {of as observableOf, combineLatest as observableCombineLatest} from 'rxjs';
import {filter, map, switchMap, take, tap, delay} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { get, values, find, first, sortBy } from 'lodash'
import { ActivatedRoute, Params } from '@angular/router';
import { Go } from 'app/router/router.actions';

import { AppState } from 'app/reducers';
import * as actions from 'app/comparisons/comparisons.actions';
import {isNotYetFetched, isFetchInFlight} from "app/shared/utils/fetch-state";
import { Comparison, newComparison, comparisons, selectedComparison, buildComparisonContext  } from 'app/comparisons/comparisons.reducer';
import { isBulkFetchInFlight } from 'app/shared/utils/bulk-fetch-state';
import { fetchOutcome } from 'app/shared/utils/fetch-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fullContext } from 'app/hierarchy/hierarchy.reducers';
import {COUNT_TYPE_MODELED, INSIGHTS_CONTEXT, SegmentContexts} from 'app/insights/insights.constants';
import { InsightsCountService } from 'app/insights/insights-count.service';
import * as insightsActions from 'app/insights/insights.actions';
import { Filter } from 'app/insights/insights.models';
import { FocusType, PpcSplitScreenComponent } from 'app/shared/components/ppc-split-screen/ppc-split-screen.component';
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { buildUrlRelative, isDefined } from 'app/shared/utils/utils';
import { PersonLevelCompareService } from 'app/insights/insights-components/person-level-compare/person-level-compare.service';
import { JourneyCountService } from 'app/journey/journey-count.service';
import { GrowCountService } from 'app/insights/grow-v3/grow-count.service';
import { OverlapInsightsExportService } from 'app/insights/insights-components/insights-export/overlap-insights-export.service';
import { InsightsExportService } from 'app/insights/insights-components/insights-export/insights-export.service';

@UntilDestroy()
@Component({
  selector: 'ppc-comparisons',
  templateUrl: './comparisons.component.html',
  styleUrls: ['./comparisons.component.sass'],
  providers: [
    {provide: INSIGHTS_CONTEXT, useValue: "compare"},
    InsightsCountService,
    GrowCountService,
    PersonLevelCompareService,
    OverlapInsightsExportService,
    JourneyCountService,
    InsightsExportService,
  ]
})
export class ComparisonsComponent implements OnInit, OnDestroy {
  @ViewChild(PpcSplitScreenComponent) splitScreen: PpcSplitScreenComponent;
  comparisons: Comparison[] = [];
  comparisonUnderEdit$ = this.store.select("comparisons", "comparisonUnderEdit");
  loading$: Observable<boolean> = observableCombineLatest([
    this.store.select("fetchStates", actions.FetchComparisons.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.FetchComparisonCounts.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.FetchDefaultComparison.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.SaveComparison.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.DestroyComparison.type).pipe(select(isFetchInFlight)),
    this.store.select("bulkFetchStates", actions.FetchComparisonCounts.type).pipe(select(isBulkFetchInFlight)),
    this.counts.loadingCounts$,
    this.insightsExportService.loading$,
  ]).pipe(map(loadingStates => loadingStates.some(Boolean)));
  permissions$ = this.store.select("permissions", "comparisons");
  selectedComparison: Comparison;
  selectedComparisonId: number;
  focusedCells$ = this.store.select("comparisons", "focusedCells");
  segmentContexts$: Observable<SegmentContexts> = observableCombineLatest(
    this.focusedCells$,
    this.store.select("comparisons").pipe(select(selectedComparison))
  ).pipe(
    filter(([focusedCells, comparison]) => focusedCells.length > 0 && !!comparison),
    map(([focusedCells, comparison]) => {
      if (focusedCells.length) {
        return buildComparisonContext(comparison, focusedCells);
      } else {
        return null
      }
    })
  )
  focus = "left";
  filters$: Observable<Filter[]> = observableCombineLatest(
    this.focusedCells$,
    this.counts.filters$
  ).pipe(
    map(([focusedCells, filters]) => {
      if (!focusedCells.length) {return []; }
      return [...focusedCells.map(focusedCell => {
        return {
          name: focusedCell.name,
          tab: "Comparisons",
          type: "comparison-cell",
          toggleSelf: () => this.store.dispatch(new actions.ToggleFocusedCell(focusedCell))
        }
      }), ...filters]
    })
  )
  insightsInfoTooltipDismissed = false;
  params: Params;

  constructor(public store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    public counts: InsightsCountService,
    public insightsExportService: InsightsExportService) {}

  ngOnInit() {
    this.store.select('fetchStates', actions.FetchComparisons.type).pipe(
      select(isNotYetFetched),
      filter(Boolean),
      untilDestroyed(this),
    ).subscribe(() => this.store.dispatch(new actions.FetchComparisons()));

    this.route.params.pipe(
      untilDestroyed(this)
    ).subscribe(params => this.params = params)

    const selectedComparisonId$: Observable<number> = this.route.params.pipe(
      map(({comparisonId}) => +comparisonId),
      switchMap(comparisonId => comparisonId ? observableOf(comparisonId) : this.store.select("comparisons", "selectedComparisonId")),
    )

    selectedComparisonId$.pipe(
      untilDestroyed(this)
    ).subscribe((comparisonId: number) => {
      this.onComparisonSelect(comparisonId);
    });

    fullContext(this.store).pipe(
      tap(() => this.store.dispatch(new actions.FetchDefaultComparison())),
      delay(1),
      switchMap(() => {
        return observableCombineLatest(
          this.store.select("comparisons", "comparisons"),
          selectedComparisonId$,
          this.store.select("comparisons", "defaultComparisonId"),
          this.store.select("fetchStates", actions.FetchComparisons.type).pipe(
            filter(fetchState => !isNotYetFetched(fetchState) && !isFetchInFlight(fetchState))
          ),
          this.store.select("fetchStates", actions.FetchDefaultComparison.type).pipe(
            filter(fetchState => !isNotYetFetched(fetchState) && !isFetchInFlight(fetchState))
          ),
        ).pipe(
          filter(([comparisons, comparisonId, defaultComparisonId]) => {
            return !find(comparisons, { id: comparisonId }) || !find(comparisons, { id: defaultComparisonId });
          }),
        )
      }),
      untilDestroyed(this)
    ).subscribe(([comparisons, comparisonId, defaultComparisonId]) => {
      const defaultComparison = find(comparisons, { id: comparisonId }) || find(comparisons, {id: defaultComparisonId}) || first(sortBy(values(comparisons), "name"))
      this.onComparisonSelect(get(defaultComparison, "id"))
    });

    this.store.select("comparisons").pipe(
      select(selectedComparison),
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(selectedComparison => this.selectedComparison = selectedComparison)

    observableCombineLatest([
      this.store.select("comparisons").pipe(select(comparisons)),
    ]).pipe(untilDestroyed(this))
      .subscribe(([comparisons]) => {
        this.comparisons = comparisons;
      });

    this.focusedCells$.pipe(
      filter(focusedCells => focusedCells.length == 0),
      untilDestroyed(this)
    ).subscribe(() => {
      if (this.splitScreen) {
        this.splitScreen.overrideFocus("left");
      }
    });
  }
  ngOnDestroy() {}

  create() {
    this.store.dispatch(new actions.EditComparison(newComparison()));
  }

  edit() {
    this.store.dispatch(new actions.EditComparison(this.selectedComparison));
  }

  duplicate() {
    this.store.dispatch(new actions.CopyComparison(this.selectedComparison.id));
    this.actions$.pipe((fetchOutcome(actions.CopyComparison.type)),
      take(1), )
      .subscribe(
        ({result}) => {
          this.onComparisonSelect(get(result, ["comparison", "id"]));
        },
        () => this.snackbar.open("Something went wrong saving this comparison. Please try again in a few minutes", "OK", {
          panelClass: ["danger"],
          duration: 6000
        })
      )
  }

  onComparisonSelect(comparisonId: number) {
    if (comparisonId == this.selectedComparisonId) {return; }
    this.selectedComparisonId = comparisonId;
    this.store.dispatch(new actions.SetSelectedComparison(comparisonId));
    this.store.dispatch(new Go({path: buildUrlRelative(this.params, `insights/overlap${comparisonId ? "/" + comparisonId : ""}`)}));

    if (this.selectedComparisonId) {
      this.store.dispatch(new insightsActions.FetchTabs(comparisonId, 'Comparison', "compare"));
      this.store.dispatch(new insightsActions.FetchDemographics(comparisonId, 'Comparison', "compare"));
      this.store.dispatch(new insightsActions.FetchDemographicsConfig(userPreferenceKeys.standardDemographics("compare"), comparisonId, 'Comparison', "compare"));
      this.store.dispatch(new insightsActions.FetchCustomTabsConfig(comparisonId, 'Comparison', "compare"));
    }

  }

  deleteComparison = () => this.store.dispatch(new actions.DestroyComparison(this.selectedComparison))

  clearFilters(filters: Filter[]) {
    filters.forEach(filter => {
      this.toggleFilter(filter);
    })
  }

  toggleFilter(filter: Filter) {
    if (filter.type == "comparison-cell") {
      filter.toggleSelf();
    } else {
      this.store.dispatch(new insightsActions.ToggleFilter(filter, "compare"));
    }
  }

  setSplitScreenFocus(splitScreenFocus: FocusType) {
    this.store.dispatch(new insightsActions.SetSplitScreenFocus(splitScreenFocus, "compare"));
  }

  get countLabel() {
    return this.selectedComparison && this.selectedComparison.mode == COUNT_TYPE_MODELED ? "Modeled" : "Matched";
  }
}
