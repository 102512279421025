<ng-template #editTabConfig>
  <div matRipple class="export-icon-container" *ngIf="insightsContext !== 'outcome-audience'">
    <i class="fa fa-sign-out" (click)="toggleExportPane()"></i>
  </div>
  <div matRipple class="gear-icon-container" ppcTooltip="Manage your Insights Tabs and Widgets">
    <i class="fa fa-cog" (click)="toggleEditTabConfig()"></i>
  </div>
</ng-template>
<ppc-tabs
  *ngIf="tabs && tabs.length"
  class="yellow top"
  [activeTab]="topLevelTab$ | async"
  (tabChange)="changeTab($event)"
  [fixedRightHeaderTemplate]="editTabConfig"
>
  <ppc-tab
    *ngFor="let topLevelTab of tabs; trackBy: trackById"
    class="no-padding"
    [name]="topLevelTab.name"
    [tooltipContent]="renderTooltip(topLevelTab.name)"
  >
    <ppc-tabs
      class="bottom yellow"
      (tabChange)="changeBottomLevelTab(topLevelTab, $event)"
      [activeTab]="getBottomLevelTab(topLevelTab)"
    >
      <ppc-tab
        *ngFor="let bottomLevelTab of visibleChildren(topLevelTab); trackBy: trackById"
        class="bottom-level-insights-tab"
        [name]="bottomLevelTab.name"
        [tooltipTemplate]="tabInfo"
        [labelTemplate]="canShowTabMenu(bottomLevelTab) && tabHeaderWithMenu"
      >
        <ndc-dynamic
          *ngIf="getBottomLevelTab(topLevelTab) == bottomLevelTab.name"
          [ndcDynamicComponent]="componentConfig[bottomLevelTab.tab_key]"
          [ndcDynamicInputs]="getInputs(bottomLevelTab)"
        ></ndc-dynamic>
        <ng-template #tabInfo>
          <div class="tabinfo-tooltip">
            <div class="name" [style.fontWeight]="bottomLevelTab.description ? '700' : '300'">
              {{bottomLevelTab.name}}
            </div>
            <div class="description" *ngIf="bottomLevelTab.description">
              <div [innerHTML]="bottomLevelTab.description"></div>
            </div>
          </div>
        </ng-template>
        <ng-template #tabHeaderWithMenu>
          <div class="layout horizontal center-justified">
            <span class="ellipsize"
                  ppcTooltip
                  [tooltipTemplate]="tabInfo"
            >
              {{bottomLevelTab.name}}
            </span>
            <i class="fa fa-ellipsis-v tab-menu-trigger"
               [matMenuTriggerFor]="tabMenu"
            ></i>
          </div>
          <mat-menu #tabMenu="matMenu">
            <button mat-menu-item
                    (click)="copyTab(bottomLevelTab)"
                    [disabled]="!(canCreateTab$ | async) || insightsContext === 'outcome-audience'">
              Copy to...
            </button>
          </mat-menu>
        </ng-template>
      </ppc-tab>
    </ppc-tabs>
  </ppc-tab>
</ppc-tabs>
<app-slide-in-overlay [width]="650" [active]="isEditingWidget$ | async">
  <ppc-widget-form *ngIf="isEditingWidget$ | async"></ppc-widget-form>
</app-slide-in-overlay>

<app-slide-in-overlay [active]="!!(manageOpen$ | async)" [width]="650" (backgroundClick)="toggleEditTabConfig()">
  <insights-tab-config *ngIf="(manageOpen$ | async)"></insights-tab-config>
</app-slide-in-overlay>

<app-slide-in-overlay [active]="!!(exportOpen$ | async)" [width]="650" (backgroundClick)="toggleExportPane()">
  <ppc-insights-export *ngIf="(exportOpen$ | async)"></ppc-insights-export>
</app-slide-in-overlay>

<app-slide-in-overlay
  *ngIf="canCreateTab$ | async"
  [active]="isCopyTabOpen"
  [width]="850"
  (backgroundClick)="isCopyTabOpen = false"
>
  <ppc-tab-copy-form *ngIf="isCopyTabOpen"
                     (close)="isCopyTabOpen = false"
                     [selectedTab]="customTabToCopy">
  </ppc-tab-copy-form>
</app-slide-in-overlay>
