import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { clamp } from 'lodash';
import { toMillions } from 'app/insights/insights.models';

@Component({
  selector: 'ppc-index-bar',
  templateUrl: './index-bar.component.html',
  styleUrls: ['./index-bar.component.sass']
})
export class IndexBarComponent implements OnInit {
  @Input() index: number;
  ready = false;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    setTimeout(() => {
      this.ready = true;
    }, 50)
  }

  get transformOrigin() {
    if (this.index > 100) {
      return "center left"
    } else {
      return "center right"
    }
  }

  get transform() {
    if (this.index < 100) {
      return this.sanitizer.bypassSecurityTrustStyle(`translate(-100%) scaleX(${this.ready ? 1 : 0})`)
    } else {
      return this.sanitizer.bypassSecurityTrustStyle(`scaleX(${this.ready ? 1 : 0})`);
    }
  }

  get width() {
    return !this.ready ? 0 : clamp(Math.abs(this.index - 100) / 2, 0, 50);
  }

  get indexLabel() {
    return toMillions(this.index, this.index < 1000 ? 0 : 1);
  }

}
