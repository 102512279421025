import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { DynamicModule } from "ng-dynamic-component";
import { DragulaModule } from "ng2-dragula";
import { NgLetModule } from 'ng-let';
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { InsightsService } from "./insights.service";
import { InsightsComponent } from "./insights.component";
import { metaReducer } from "./insights.reducer";
import { PpcComponentsModule } from "app/modules/ppc-components.module";
import { PersonLevelDemographicsComponent } from "./insights-components/person-level-demographics/person-level-demographics.component";
import { PersonLevelSharedInterestsComponent } from "./insights-components/person-level-shared-interests/person-level-shared-interests.component";
import { PersonLevelSurveyComponent } from "./insights-components/person-level-survey/person-level-survey.component";
import { PredictionsLevelPropensityComponent } from "app/snapshots/predictions-level-propensity/predictions-level-propensity.component";
import { CustomTabWidgetsComponent } from "./insights-components/custom-tab-widgets/custom-tab-widgets.component";
import { WidgetFormComponent } from "./insights-components/widget-form/widget-form.component";
import { MarketLevelCustomTabDemographicsComponent } from "./insights-components/market-level-custom-tab-demographics/market-level-custom-tab-demographics.component";
import { MarketLevelDiscussionsComponent } from "./insights-components/market-level-discussions/market-level-discussions.component";
import { MarketLevelSurveyComponent } from "./insights-components/market-level-survey/market-level-survey.component";
import { RegionLevelDemographicsComponent } from "./insights-components/region-level-demographics/region-level-demographics.component";
import { ShareThisComponent } from "./insights-components/share-this/share-this.component";
import { ShareThisDayOfWeekComponent } from "./insights-components/share-this/share-this-day-of-week/share-this-day-of-week.component";
import { ShareThisDaypartComponent } from "./insights-components/share-this/share-this-daypart/share-this-daypart.component";
import { ShareThisDeviceComponent } from "./insights-components/share-this/share-this-device/share-this-device.component";
import { ShareThisBrowserComponent } from "./insights-components/share-this/share-this-browser/share-this-browser.component";
import { ShareThisTopDomainsComponent } from "./insights-components/share-this/share-this-top-domains/share-this-top-domains.component";
import { ShareThisOverlapComponent } from "./insights-components/share-this/share-this-overlap/share-this-overlap.component";
import { DemographicWidgetComponent } from "app/insights/insights-components/person-level-demographic-widget/demographic-widget.component";
import { CustomTabFormComponent } from "app/insights/insights-components/insights-custom-tab-form/insights-custom-tab-form.component";
import { DonutChartComponent } from "app/insights/insights-components/person-level-demographic-widget/donut-chart/donut-chart.component";
import { BarChartComponent } from "app/insights/insights-components/person-level-demographic-widget/bar-chart/bar-chart.component";
import { InsightsTabConfigComponent } from "app/insights/insights-components/insights-tab-config/insights-tab-config.component";
import { InsightsExportComponent } from "app/insights/insights-components/insights-export/insights-export.component";
import { InsightsCustomTabConfigComponent } from "app/insights/insights-components/insights-tab-config/insights-custom-tab-config/insights-custom-tab-config.component";
import { MarketLevelDemographicFormComponent } from "./insights-components/market-level-demographics/market-level-demographic-form/market-level-demographic-form.component";
import { MarketLevelDemographicWidgetComponent } from "./insights-components/market-level-demographics/market-level-demographic-widget/market-level-demographic-widget.component";
import { MarketLevelDiscussionsChartComponent } from "./insights-components/market-level-discussions/market-level-discussions-chart/market-level-discussions-chart.component";
import { WeboramaDiscussionFormComponent } from "./insights-components/market-level-discussions/weborama-discussion-form/weborama-discussion-form.component";
import { SharedInterestSegmentComponent } from "./insights-components/person-level-shared-interests/shared-interest-segment/shared-interest-segment.component";
import { RegionLevelDemographicWidgetComponent } from "./insights-components/region-level-demographics/region-level-demographic-widget/region-level-demographic-widget.component";
import { RegionDemographicFormComponent } from "app/insights/insights-components/region-demographic-form/region-demographic-form.component";
import { ShareThisWidgetComponent } from "./insights-components/share-this/share-this-widget/share-this-widget.component";
import { SnapshotLibraryComponent } from "app/snapshots/predictions-level-propensity/snapshot-library/snapshot-library.component";
import { PropensityFormComponent } from "app/snapshots/predictions-level-propensity/propensity-form/propensity-form.component";
import { SnapshotDetailComponent } from "app/snapshots/predictions-level-propensity/snapshot-detail/snapshot-detail.component";
import { FeatureImportanceComponent } from "app/snapshots/predictions-level-propensity/snapshot-detail/feature-importance/feature-importance.component";
import { PropensityOverviewComponent } from "app/snapshots/predictions-level-propensity/snapshot-detail/propensity-overview/propensity-overview.component";
import { SubMarketConfidenceComponent } from "app/snapshots/predictions-level-propensity/snapshot-detail/propensity-overview/sub-market-confidence/sub-market-confidence.component";
import { PropensityOverviewGraphComponent } from "app/snapshots/predictions-level-propensity/snapshot-detail/propensity-overview/propensity-overview-graph/propensity-overview-graph.component";
import { SegmentsInfoComponent } from "app/snapshots/predictions-level-propensity/segments-info.component";
import { GwiWidgetModule } from "./insights-components/gwi-widget/gwi-widget.module";
import { DiscussionClusterNodeLibraryComponent } from "app/insights/insights-components/market-level-discussions/discussion-cluster-node-library/discussion-cluster-node-library.component";
import { IndexBarComponent } from "./insights-components/person-level-demographic-widget/bar-chart/index-bar/index-bar.component";
import { MarketLevelSurveyQuestionComponent } from "./insights-components/market-level-survey/market-level-survey-question/market-level-survey-question.component";
import { PersonLevelCompareComponent } from "./insights-components/person-level-compare/person-level-compare.component";
import { PersonLevelCompareWidgetContainerComponent } from "./insights-components/person-level-compare/person-level-compare-widget-container/person-level-compare-widget-container.component";
import { PersonLevelCompareWidgetComponent } from "./insights-components/person-level-compare/person-level-compare-widget/person-level-compare-widget.component";
import { IndexBaseComponent } from "app/insights/insights-components/index-base/index-base.component";
import { TabCopyFormComponent } from "./insights-components/tab-copy-form/tab-copy-form.component";
import { PercentCalculationSelectComponent } from "./insights-components/percent-calculation-select/percent-calculation-select.component";
import { HasPermissionPipe } from "app/pipes/has-permission.pipe";

@NgModule({
  declarations: [
    InsightsComponent,
    DemographicWidgetComponent,
    CustomTabFormComponent,
    PersonLevelDemographicsComponent,
    DonutChartComponent,
    BarChartComponent,
    WidgetFormComponent,
    InsightsTabConfigComponent,
    InsightsExportComponent,
    InsightsCustomTabConfigComponent,
    CustomTabWidgetsComponent,
    PersonLevelSurveyComponent,
    MarketLevelCustomTabDemographicsComponent,
    MarketLevelDemographicWidgetComponent,
    MarketLevelDemographicFormComponent,
    MarketLevelDiscussionsComponent,
    MarketLevelSurveyComponent,
    MarketLevelDiscussionsChartComponent,
    WeboramaDiscussionFormComponent,
    PersonLevelSharedInterestsComponent,
    PredictionsLevelPropensityComponent,
    SharedInterestSegmentComponent,
    RegionLevelDemographicsComponent,
    RegionLevelDemographicWidgetComponent,
    RegionDemographicFormComponent,
    ShareThisComponent,
    ShareThisDayOfWeekComponent,
    ShareThisDaypartComponent,
    ShareThisDeviceComponent,
    ShareThisBrowserComponent,
    ShareThisTopDomainsComponent,
    ShareThisOverlapComponent,
    ShareThisWidgetComponent,
    SnapshotLibraryComponent,
    PropensityFormComponent,
    SnapshotDetailComponent,
    FeatureImportanceComponent,
    PropensityOverviewComponent,
    SubMarketConfidenceComponent,
    PropensityOverviewGraphComponent,
    SegmentsInfoComponent,
    DiscussionClusterNodeLibraryComponent,
    IndexBarComponent,
    MarketLevelSurveyQuestionComponent,
    PersonLevelCompareComponent,
    PersonLevelCompareWidgetContainerComponent,
    PersonLevelCompareWidgetComponent,
    IndexBaseComponent,
    TabCopyFormComponent,
    PercentCalculationSelectComponent,
    HasPermissionPipe
  ],
  exports: [
    InsightsComponent,
    InsightsTabConfigComponent,
    InsightsExportComponent,
    WeboramaDiscussionFormComponent,
    RegionDemographicFormComponent,
    MarketLevelDemographicFormComponent,
    DiscussionClusterNodeLibraryComponent,
    IndexBaseComponent,
  ],
  imports: [
    CommonModule,
    DragulaModule,
    NgLetModule,
    HttpClientModule,
    RouterModule,
    StoreModule.forFeature("insights", metaReducer),
    EffectsModule.forFeature([InsightsService]),
    PpcComponentsModule,
    DynamicModule,
    GwiWidgetModule,
  ],
  providers: [InsightsService],
})
export class InsightsModule {}
