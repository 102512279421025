import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppState } from 'app/reducers';
import { fetchIfUnfetched, ResetFetchState } from 'app/shared/utils/fetch-state';
import * as outcomesV1Actions from 'app/outcomes-v1/outcomes-v1.actions';
import { OutcomeV1 } from 'app/outcomes-v1/outcomes-v1.model';
import { OutcomeAudienceService } from 'app/outcome-audience/outcome-audience.service';
import { PveConsumerType, PveGoal, PveRequest } from 'app/outcome-audience/outcome-audience.reducer';
import { consumerTypeRequestMap, goalRequestMap } from '../outcome-audience.utils';

@UntilDestroy()
@Component({
  selector: 'ppc-outcome-audience-form',
  templateUrl: './outcome-audience-form.component.html',
  styleUrls: ['./outcome-audience-form.component.sass']
})
export class OutcomeAudienceFormComponent implements OnInit, OnDestroy {
  @Input() stateChange: boolean;
  @Output() close = new EventEmitter<boolean>();

  goalList: PveGoal[] = ['Increase Buyers', 'Increase Conversions', 'Increase Revenue'];
  consumerTypes: PveConsumerType[] = ['Anyone', 'Loyal Buyers', 'Lapsed Buyers', 'New to file'];

  goalSelection$ = new BehaviorSubject<PveGoal|''>('');
  consumerTypeSelection$ = new BehaviorSubject<PveConsumerType>(this.consumerTypes[0]);
  loading$ = new BehaviorSubject<Boolean>(false);

  outcomesV1: OutcomeV1[] = [];
  markAsUntouched = false;

  formGroup = this.formBuilder.group({
    name: ['', [Validators.required, Validators.maxLength(255), this.uniqueNameValidator(), this.trimNameValidator()]],
    audienceSize: [0, [Validators.required, Validators.min(1)]],
    seenDays: [0, []],
    notSeenDays: [0, []]
  }, {
    validator: this.consumerTypeDayValidations()
  });

  nameValidator = [
    {
      isValid: name => !this.outcomesV1.map(o => o.name.toLowerCase()).includes(name.trim().toLowerCase()),
      errorMessage: 'Must use unique name'
    },
    {
      isValid: name => name.trim().length !== 0,
      errorMessage: 'This field is required'
    }
  ];

  numberValidator = [
    {
      isValid: x => x > 0,
      errorMessage: 'Please enter a number greater than 0'
    }
  ];

  notSeenValidator = [];

  constructor(
    public formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private store: Store<AppState>,
    private outcomeAudienceService: OutcomeAudienceService
  ) { }

  ngOnInit() {
    fetchIfUnfetched(this.store, new outcomesV1Actions.FetchOutcomes(), this);

    this.store.select('outcomesV1', 'outcomesV1').pipe(
      untilDestroyed(this)
    ).subscribe(outcomesV1 => {
      this.outcomesV1 = outcomesV1;
    });

    this.formGroup.controls['seenDays'].valueChanges.subscribe(seenDays => {
      this.notSeenValidator = [
        ...this.numberValidator,
        {
          isValid: x => x < seenDays,
          errorMessage: 'Please enter a number less than seen days'
        }
      ]
    });

    this.consumerTypeSelection$.subscribe(consumerType => {
      this.formGroup.controls['seenDays'].setValidators([]);
      this.formGroup.controls['notSeenDays'].setValidators([]);
      switch (consumerType) {
        case 'Lapsed Buyers':
          this.formGroup.controls['notSeenDays'].setValidators([Validators.required, Validators.min(1)]);
          // eslint-disable-line no-fallthrough
        case 'Loyal Buyers':
          this.formGroup.controls['seenDays'].setValidators([Validators.required, Validators.min(1)]);
          break;
      }

      this.formGroup.controls['seenDays'].updateValueAndValidity();
      this.formGroup.controls['notSeenDays'].updateValueAndValidity();
    });
  }

  ngOnDestroy() {}

  create() {
    this.loading$.next(true);

    const formValues = this.formGroup.getRawValue();
    const goal = this.goalSelection$.getValue();
    const consumerType = this.consumerTypeSelection$.getValue();

    const params = {
      name: formValues.name.trim(),
      audience_size: formValues.audienceSize,
      goal: goalRequestMap(goal as PveGoal),
      consumer_type: consumerTypeRequestMap(consumerType),
    } as PveRequest;

    if (consumerType === 'Lapsed Buyers' || consumerType === 'Loyal Buyers') {
      params['range_in_days_seen'] = formValues.seenDays;
    }
    if (consumerType === 'Lapsed Buyers') {
      params['range_in_days_not_seen'] = formValues.notSeenDays;
    }

    this.outcomeAudienceService.sendPveRequest(params).subscribe(
      result => this.snackbar.open('Create outcome audience request sent', 'ok', { duration: 6000, panelClass: ['check'] }),
      error => {
        this.snackbar.open('Something went wrong, outcome audience request cancelled. Please try again.', null, { duration: 6000, panelClass: ['danger'] });
        this.cancel();
      },
      () => {
        this.store.dispatch(new ResetFetchState(outcomesV1Actions.FetchOutcomes));
        this.cancel();
      }
    );
  }

  cancel() {
    this.close.emit();
    this.reset();
  }

  reset() {
    this.formGroup.reset({ name: '', audienceSize: 0, seenDays: 0, notSeenDays: 0 });
    Object.keys(this.formGroup.controls).forEach(control => {
      this.formGroup.controls[control].markAsUntouched();
    });
    this.updateGoalSelection('');
    this.updateConsumerTypeSelection(this.consumerTypes[0]);
    this.loading$.next(false);
  }

  updateGoalSelection(goal) {
    this.goalSelection$.next(goal);
  }

  updateConsumerTypeSelection(consumerType) {
    this.consumerTypeSelection$.next(consumerType);
  }

  goalSelectIsValid() {
    const goal = this.goalSelection$.getValue();
    return this.goalList.includes(goal as any);
  }

  consumerSelectIsValid() {
    return this.consumerTypes.includes(this.consumerTypeSelection$.getValue());
  }

  requiredFieldsSet() {
    return this.goalSelectIsValid() && this.consumerSelectIsValid();
  }

  uniqueNameValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const nameTaken = this.outcomesV1.map(o => (o.name).toLowerCase()).includes(
        (control.value.trim()).toLowerCase()
      );
      return nameTaken ? { nameTaken: true } : null;
    }
  }

  trimNameValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const nameBlank = control.value.trim().length === 0;
      return nameBlank ? { nameBlank: true } : null;
    }
  }

  consumerTypeDayValidations() {
    return (formGroup: FormGroup) => {
      const seen = formGroup.controls['seenDays'];
      const notSeen = formGroup.controls['notSeenDays'];
      const consumerType = this.consumerTypeSelection$.getValue();

      if (notSeen.errors && !notSeen.errors.invalid) {
        return;
      }
      if (consumerType !== 'Lapsed Buyers') {
        return;
      }

      if (seen.value <= notSeen.value) {
        notSeen.setErrors({ invalid: true });
      } else {
        notSeen.setErrors(null);
      }
    }
  }

}

