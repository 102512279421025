import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "app/reducers";
import { fetchResource } from "app/shared/utils/fetch-state";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import * as actions from "app/data_permissions/data_permissions.actions";
import { DataPermissions } from "app/data_permissions/data_permissions.model";
import { isEmpty, get } from 'lodash';
import { allPermissionsUrl } from "app/shared/constants/segments.urls";

@Injectable()
export class DataPermissionsService {

  @Effect()
  fetchAllDataPermissions$ = this.actions$.pipe(
    ofType(actions.FetchAllDataPermissions.type),
    (
      fetchResource(
        ({ identifiers }) => this.getAll(identifiers).pipe(
          map(permissions => new actions.LoadDataPermissions(permissions))
        )
      )
    ))

  constructor(private http: HttpClient, private actions$: Actions, private store: Store<AppState>) { }

  // If no productSlug is provided, the active context will be attached by the HierarchyInterceptor
  getAll(identifiers: string[], productSlug?: string): Observable<DataPermissions[]> {
    const headers = productSlug ? {'x-context': productSlug} : {}
    const body = { ppc_object_identifiers: identifiers }
    return this.http.post<{data: DataPermissions[], count: number}>(
      allPermissionsUrl(),
      body,
      { headers },
    ).pipe(
      map(({ data }) => data))
  }
}

export function getPermissionDisplayName(permission): string {
  return PERMISSIONS_MAP[permission];
}

export function getPermissionRawDataName(value): string {
  return Object.keys(PERMISSIONS_MAP).find( key => PERMISSIONS_MAP[key] === value);
}

export function isPermissioned(ppcItem, permissions, permission: string): boolean {
  if (isEmpty(permissions)) { return false };

  return get(permissions, [ppcItem.identifier, 'permissions', permission], false)
}

export const PERMISSIONS_MAP = {
  "insights": "Insights & Analytics",
  "activation": "Activation",
  "measurement": "Advanced Measurement",
  "tv_planning": "TV Planning",
  "modeling_seed": "Modeling – Seed",
  "client_sharing": "Client Data Sync",
  "modeling_universe": "Modeling – Universe",
  "create_audience_single": "Custom Audience Creation – Single Partner",
  "create_audience_multiple": "Custom Audience Creation – Multiple Partners",
};
