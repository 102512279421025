import {Action} from "@ngrx/store";
import {SegmentFetchOptionsV4} from "app/segments-v2/segment-v2.service";

export class FetchSegments implements Action {
  static readonly type = 'FETCH_SEGMENTS';
  readonly type = FetchSegments.type;

  constructor(public options: SegmentFetchOptionsV4) {}
}

export class LoadSegments implements Action {
  static readonly type = 'LOAD_SEGMENTS';
  readonly type = LoadSegments.type;

  // todo use same type here as in State
  constructor(public segments: any[]) {}
}

export type All = FetchSegments | LoadSegments
