import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { sortBy, get } from 'lodash';
import { Observable } from 'rxjs';

import { objToHttpParams } from 'app/shared/utils/http-utils';
import { SegmentV2 } from 'app/audiences/discover/segment-v2.model';
import { Node } from "./root-node.interface";
import { PERMISSION_INSIGHTS } from "app/shared/utils/constants";
import { segmentsUrl } from "app/shared/constants/segments.urls";

export function fetchChildrenByParentId(http: HttpClient, parentId: string, regionSlug: string) {
  return http.post<{data: SegmentV2[]}>(segmentsUrl(),
    {params: objToHttpParams({parent_id: parentId, use_cases: [PERMISSION_INSIGHTS]})}).pipe(
    map(({ data }) => data),
    map(segments => sortBy(segments.map(segment => segmentToNode(segment, http, regionSlug)),
      [node => node.path && node.path.is_leaf, "name"]))
  ) as Observable<Node[]>
}

export function segmentToNode(segment: SegmentV2, http: HttpClient, regionSlug: string): Node {
  return {
    ...segment,
    count: {
      matched: get(segment, ['count', 'people', regionSlug.toLowerCase()], 0),
      modeled: get(segment, ['count', 'people', `${regionSlug.toLowerCase()}_scaled`], 0),
    },
    children$: fetchChildrenByParentId(http, segment.identifier, regionSlug)
  }
}
