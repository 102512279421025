import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PpcHttpService } from '../../services/ppc_http.service';
import { Country } from './country.interface';
import { countriesUrl } from '../../shared/constants/clients.urls';

@Injectable()
export class CountriesService {

  constructor(private http: PpcHttpService) { }

  getCountries(): Observable<Country[]> {
    return this.http.get(countriesUrl())
  }
}
