import { Component, OnInit, OnDestroy, Input, forwardRef, Renderer2, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ALL_ICONS } from './icon-picker.constants';

@UntilDestroy()
@Component({
  selector: 'ppc-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IconPickerComponent),
      multi: true
    }
  ]
})
export class IconPickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() icons: string[] = ALL_ICONS;
  value: string;
  open = false;

  onChange: (value) => void = () => {};
  onTouched: (value) => void = () => {};

  constructor(private renderer: Renderer2, private element: ElementRef) { }

  ngOnInit() {
    fromEvent(document, "click").pipe(
      untilDestroyed(this)
    ).subscribe(event => {
      if (!this.element.nativeElement.contains(event.target)) {
        this.open = false;
      }
    })
  }

  ngOnDestroy() { }

  selectIcon(icon: string) {
    this.open = false;
    this.writeValue(icon);
  }

  writeValue(value: string) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(onChange) {
    this.onChange = onChange
  }

  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }
}
