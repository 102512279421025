import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { isValidEmail } from 'app/shared/utils/utils';
import {UserService} from "../../users/user.service";

const NOT_FOUND = "Email address not found";
const INVALID = "Please input a valid email address";

@Component({
  selector: 'ppc-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.sass']
})
export class RequestPasswordResetComponent implements OnInit {
  errors = new Set<string>();
  userEmail: string;

  success = false;

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() { }

  checkEmailValidity() {
    if (isValidEmail(this.userEmail)) {
      this.errors.delete(INVALID)
    } else {
      this.errors.add(INVALID)
    }
  }

  submit() {
    this.userService.requestPasswordReset({user: {email: this.userEmail}})
      .subscribe(
        () => {
          this.errors.delete(NOT_FOUND);
          this.success = true;
        },
        () => {
          this.errors.add(NOT_FOUND);
        }
      )
  }

  ableToSubmit() {
    return this.userEmail && isValidEmail(this.userEmail);
  }
}
