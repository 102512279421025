import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {UserService} from "app/users/user.service";
import { map, take } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivateChild {

  constructor(private router: Router, private userService: UserService) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLoggedIn$ = this.userService.loginAttempts().pipe(map(Boolean))

    isLoggedIn$.pipe(take(1)).subscribe(
      isLoggedIn => (state.url !== '/') ? !isLoggedIn && this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }})
        : !isLoggedIn && this.router.navigate(['/login'])
    )

    return isLoggedIn$;
  }
}
