import { Component, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppState } from 'app/reducers';
import { select, Store } from "@ngrx/store";
import { take } from 'rxjs/operators';
import { Actions } from "@ngrx/effects";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { flatMap } from 'lodash';
import { UploadAdminService } from "app/admin/upload-admin/upload-admin.service";
import { PpcInputComponent } from 'app/shared/components/ppc-input/ppc-input.component';
import { PpcTextareaComponent } from 'app/shared/components/ppc-textarea/ppc-textarea.component';
import { fetchOutcome } from "app/shared/utils/fetch-state";
import { EditDiscussionClusterNode, FetchClusterNodes, SaveDiscussionClusterNode, DestroyDiscussionClusterNode } from 'app/insights/insights-components/market-level-discussions/discussion.actions';
import { DiscussionClusterNode } from 'app/insights/insights-components/market-level-discussions/discussion-cluster-nodes.interface';

@UntilDestroy()
@Component({
  selector: 'ppc-discussions-upload-admin-form',
  templateUrl: './discussions-upload-admin-form.component.html',
  styleUrls: ['./discussions-upload-admin-form.component.sass']
})

export class DiscussionsUploadAdminFormComponent implements OnDestroy {
  @ViewChild('clusterName') nodeNameInput: PpcInputComponent;
  @ViewChild('clusterDescription') nodeDescriptionInput: PpcTextareaComponent;
  public updatedClusterNodes: DiscussionClusterNode[] = [];
  public errors: { column: string, row: string, message: string }[] = [];
  fileName = '';
  errorListCollapsed = false;
  clusterNodes$ = this.store.select("discussions", "clusterNodes");
  clusterUnderEdit: DiscussionClusterNode;
  rulesVisible = false;

  public rules = [
    "The template will only affect your current client.",
    "Each row must contain a value in each column except column C.",
    "To add additional word bubbles, add a row for each one.",
    "Name (column C) values are used to calculate the Reachable People for each cluster, values cannot be greater than 100.",
    "Size (column D) values can be between 1 and 100, this will determine the size of the individual word bubbles.",
    "If errors are found, they will appear below the upload section with a corresponding row and column (if applicable).",
    "After upload is completed, your Discussions file will appear in the table with the corresponding ID that can be used in the Grow uploader."
  ]

  constructor(
    private uploadAdminService: UploadAdminService,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar,
    private actions$: Actions,
    public store: Store<AppState>
  ) {
    this.store.select("discussions", "discussionClusterNodeUnderEdit").pipe(untilDestroyed(this))
      .subscribe(clusterUnderEdit => this.clusterUnderEdit = clusterUnderEdit);
  }

  ngOnDestroy() {}

  downloadTemplate() {
    this.uploadAdminService.downloadDiscussionTemplate(this.clusterUnderEdit.id).subscribe();
  }

  saveFilename(event) {
    if (!event.target.files[0]) {return; }
    this.fileName = event.target.files[0].name;
  }

  uploadTemplate(event, form: HTMLFormElement) {
    this.clearUploadMessages();
    this.uploadAdminService.loading(true);
    const formData = new FormData(form);
    formData.append("cluster_name", this.nodeNameInput.value);
    formData.append("cluster_description", this.nodeDescriptionInput.value);
    if (this.fileName) {
      this.uploadAdminService.uploadDiscussionTemplate(formData, this.clusterUnderEdit.id).subscribe(
        response => {
          if (response.errors.length == 0)  {
            this.store.dispatch(new FetchClusterNodes());
            this.snackbar.open("Upload Complete", 'ok', { duration: 4000, panelClass: ['check'] });
            this.closeEditPane();
          } else {
            this.handleSuccessResponse(flatMap(response.clusters, "clusters"), response.errors);
          }
        },
        error => this.snackbar.open(error, null, { duration: 6000, panelClass: ['danger'] })
      );
    } else {
      this.saveDiscussionClusterNode();
    }

    event.target.value = '';
  }

  handleSuccessResponse(response, errors) {
    this.updatedClusterNodes = response;
    this.errors = errors;
    this.snackbar.open("Upload Complete", 'ok', { duration: 4000, panelClass: ['check'] });
  }

  getSanitizedValue(val): SafeStyle {
    return this.sanitizer.bypassSecurityTrustHtml(val);
  }

  clearUploadMessages() {
    if (this.updatedClusterNodes.length) {this.updatedClusterNodes = []; }
    if (this.errors.length) {this.errors = []; }
  }

  closeEditPane() {
    this.store.dispatch(new EditDiscussionClusterNode(null));
    this.clearUploadMessages();
    this.fileName = '';
  }

  saveDiscussionClusterNode() {
    this.store.dispatch(new SaveDiscussionClusterNode(this.clusterUnderEdit));
    this.actions$.pipe(fetchOutcome(SaveDiscussionClusterNode.type), take(1))
      .subscribe(
        success => this.handleSaveComplete(),
        error => this.snackbar.open('Discussion Cluster could not be saved. Please try again.', null, { duration: 6000, panelClass: ['danger'] }
        )
      );
  }

  destroyDiscussionClusterNode() {
    this.store.dispatch(new DestroyDiscussionClusterNode(this.clusterUnderEdit.id));
    this.actions$.pipe(fetchOutcome(DestroyDiscussionClusterNode.type), take(1), )
      .subscribe(
        success => this.handleSaveComplete(),
        error => this.snackbar.open('Discussion Cluster could not be saved. Please try again.', null, { duration: 6000, panelClass: ['danger'] }
        )
      );
  }

  handleSaveComplete() {
    this.snackbar.open("Save Complete", 'ok', { duration: 4000, panelClass: ['check'] });
    this.uploadAdminService.loading(false);
    this.closeEditPane();
  }

  get nodeHasSubMarkets() {
    return this.clusterUnderEdit && (this.clusterUnderEdit.sub_markets && this.clusterUnderEdit.sub_markets.length > 0);
  }

  get nodeHasJourneySubMarkets() {
    return this.clusterUnderEdit && (this.clusterUnderEdit.journey_sub_markets && this.clusterUnderEdit.journey_sub_markets.length > 0);
  }
}
