import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SegmentPickerV2Component } from './segment-picker-v2.component';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { SegmentPickerSearchComponent } from './segment-picker-search/segment-picker-search.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SegmentPickerModule } from '../segment-picker/segment-picker.module';
import { SegmentPickerBrowseComponent } from './segment-picker-browse/segment-picker-browse.component';
import { VendorFilterPickerComponent } from './segment-picker-search/vendor-filter-picker/vendor-filter-picker.component';

@NgModule({
  declarations: [
    SegmentPickerV2Component,
    SegmentPickerSearchComponent,
    SegmentPickerBrowseComponent,
    VendorFilterPickerComponent
  ],
  exports: [
    SegmentPickerV2Component
  ],
  imports: [
    CommonModule,
    PpcComponentsModule,
    MatPaginatorModule,
    SegmentPickerModule,
  ]
})
export class SegmentPickerV2Module { }
