<!-- Form control syntax https://github.com/angular/angular/issues/14057#issuecomment-308270313 -->
<form *ngIf="unitUnderEdit"
  [formGroup]="unitForm"
  (ngSubmit)="onSave()"
  >
  <div class="unit-edit-form layout vertical">
    <div [class.custom-icon-wrapper]="unitUnderEdit.custom">
      <div [ngClass]="{'layout horizontal center' : unitUnderEdit.custom}">
        <mat-form-field *ngIf="unitUnderEdit.custom" class="icon-container">
          <mat-select
            id="image_slug"
            placeholder="Select Icon"
            [formControl]="unitForm.controls['image_slug']"
            class="custom-icon"
            panelClass="customized"
            >
            <mat-select-trigger>
              <span>
                <img [src]="'assets/images/toolbox/'+unitForm.controls['image_slug'].value+'.png'">
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let i of UNIT_ICONS" [value]="i.value" trackBy="$value">
              <span>
                <img [src]="'assets/images/toolbox/'+i.value+'.png'" [alt]="i.display" style="position: absolute;left: -30px;top: -30px;width: 120px">
              </span>
              <span>
                {{i.display}}
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ppc-input
          class="full-width"
          [formControl]="unitForm.controls['name']"
          >
          <label class="ppc-input-label">
            Name
          </label>
          <span suffix class="required-suffix"
            *ngIf="unitForm.get('name').invalid"
            >*</span>
          <span help-text class="alerted"
            *ngIf="(unitForm.get('name').value.length < 3)"
            >
            Required field. Minimum 3 characters.
          </span>
          <span help-text class="alerted"
            *ngIf="(unitForm.get('name').value.length > 39) || ((unitForm.get('name').value.split(' ').length - 1) > 3)"
            >
            Max length of 36 characters, 3 spaces.
          </span>
        </ppc-input>
      </div>
    </div>
    <ppc-textarea
      class="full-width"
      [formControl]="unitForm.controls['description']"
      >
      <label class="ppc-input-label">Description</label>
      <span suffix class="required-suffix"
        *ngIf="unitForm.get('description').invalid"
        >*</span>
      <span help-text class="alerted"
        *ngIf="!unitForm.get('description').value"
        >
        Required field.
      </span>
      <span help-text class="alerted"
        *ngIf="(unitForm.get('description').value.length > 500)"
        >
        Max length of 500 characters.
      </span>
    </ppc-textarea>
    <mat-form-field>
      <mat-select placeholder="Select Category"
        [formControl]="unitForm.controls['category_id']"
        >
        <mat-option *ngFor="let cat of categories" [value]="cat.id" [innerHTML]="toHtml(cat.name)"></mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select [multiple]="true"
        [formControl]="unitForm.controls['region_ids']"
        placeholder="Select Region(s)"
        >
        <mat-option #allSelected
          (click)="toggleAllRegions(allSelected)"
          value="all"
          >
            All
          </mat-option>
        <mat-option *ngFor="let region of clientRegionsSansAll"
          [disabled]="allSelected.selected"
          [ngClass]="{'disabled': allSelected.selected}"
          [value]="region.id + ''"
          >
            {{region.name}}
          </mat-option>
      </mat-select>
      <mat-error *ngIf="!unitForm.get('region_ids').valid && unitForm.get('region_ids').touched">
        Please assign at least 1 region to this unit.
      </mat-error>
    </mat-form-field>
    <ppc-input
      class="full-width"
      [formControl]="unitForm.controls['url']"
      (input)="onUrlInput($event)"
      >
      <label class="ppc-input-label">Url</label>
      <span help-text class="alerted"
        *ngIf="!unitForm.get('url').valid"
        >
        The url must be formatted correctly.
      </span>
    </ppc-input>
    <mat-form-field
      *ngIf="toolboxNavConfigPerms && toolboxNavConfigPerms.update"
      >
      <mat-select placeholder="Display in PeopleCloud navigation module"
        [formControl]="unitForm.controls['feature_module_category']"
        (selectionChange)="setFeatureModuleValue($event.value)"
        >
        <mat-option [value]="null">NONE</mat-option>
        <mat-option *ngFor="let category of clientNavCategories"
                  [value]="category">
          {{ category | uppercase }}
        </mat-option>
      </mat-select>
      <mat-hint
        *ngIf="(!unitForm.get('url').value || !unitForm.get('url').valid)"
        align="start"
        >A Url is required to add this application to a navigation module!</mat-hint>
    </mat-form-field>
    <div
      *ngIf="toolboxNavConfigPerms && toolboxNavConfigPerms.update && unitForm.get('feature_module_category').value"
      class="full-width"
      >
      <mat-checkbox
        [formControl]="unitForm.controls['iframed']"
        (change)="setUseClientHierarchyValue($event.checked);setShowInApplicationsValue($event.checked)"
        >
        View within Publicis PeopleCloud
      </mat-checkbox>
      <div *ngIf="unitForm.get('iframed').value">
        <mat-checkbox
          [formControl]="unitForm.controls['use_client_hierarchy']"
          >
          Use Client Hierarchy
        </mat-checkbox>
      </div>
      <div *ngIf="unitForm.get('iframed').value == true">
        <mat-checkbox
          [formControl]="unitForm.controls['show_in_applications']"
          >
          Show Application Icon
        </mat-checkbox>
      </div>
    </div>
    <ppc-input
      class="full-width"
      [formControl]="unitForm.controls['doc_url']"
      >
      <label class="ppc-input-label">Document Url</label>
      <span help-text class="alerted"
        *ngIf="!unitForm.get('doc_url').valid"
        >
        The url must be formatted correctly.
      </span>
    </ppc-input>
  </div>
</form>
<div class="action-row layout horizontal nowrap" *ngIf="unitUnderEdit">
  <button *ngIf="mode === 'edit'"  mat-button [disabled]="!unitForm.valid" class="action-btn" (click)="onSave();">Save</button>
  <button *ngIf="mode === 'create'" mat-button [disabled]="!unitForm.valid" class="action-btn" (click)="onCreate()">Create</button>
  <button *ngIf="!soloModal" mat-button class="action-btn white" (click)="onCancel(false)">Cancel</button>
  <button *ngIf="soloModal" mat-button class="action-btn white" (click)="onCancel(true)">Cancel</button>
  <button [disabled]="mode === 'create' || !unitUnderEdit.status" mat-button class="action-btn white" (click)="onHide()">Hide</button>
  <app-confirmation
    class="pointer"
    isButton="true"
    buttonContinueLabel="Delete"
    [disabled]="mode === 'create' || !unitUnderEdit.custom"
    [buttonClass]="'action-btn white confirmation-btn'"
    [modalTitle]="'Are you sure you want to delete this component?'"
    [kontinue]="onDestroy.bind(this, unitUnderEdit)"
    >
  </app-confirmation>
</div>
