import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, NavigationEnd, RoutesRecognized } from '@angular/router'
import { filter, map } from 'rxjs/operators'
import { toProperty, getAllRouteParams } from 'app/shared/utils/utils'

@Injectable()
export class UrlService {

  currentUrl$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    map((e: NavigationEnd) => e.url),
    toProperty())

  pathParams$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    map(() => getAllRouteParams(this.router.routerState.snapshot.root)),
    toProperty())

  constructor(private router: Router) {
    // Subscribe immediately so that we don't miss any router events
    this.currentUrl$.subscribe()
    this.pathParams$.subscribe()
  }
}
