import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppState } from 'app/reducers/index';
import { EpcModalComponent } from 'app/shared/components/epc-modal/epc-modal.component';
import { PpcInputComponent } from 'app/shared/components/ppc-input/ppc-input.component';
import { V5 } from 'app/shared/utils/constants';

import { LookalikeV2 } from 'app/lookalikes-v2/lookalike-v2.model';
import * as actions from 'app/lookalikes-v3/lookalike-v3.actions';
import * as fetchState from 'app/shared/utils/fetch-state'
import { MAX_AUDIENCE_NAME_LENGTH } from 'app/audience-builder/audience-builder.service';

import { CreateLookalikeService } from '../create-lookalike.service';

@Component({
  selector: 'ppc-create-lookalike-modal',
  templateUrl: './create-lookalike-modal.component.html',
  styleUrls: ['./create-lookalike-modal.component.sass']
})
export class CreateLookalikeModalComponent implements OnInit, OnDestroy {
  @ViewChild('modal', { static: true }) modal: EpcModalComponent;
  @ViewChild('nameInput', { static: true }) nameInput: PpcInputComponent;

  @Input()
  set lookalikeUnderEdit(val: LookalikeV2) {
    const lal = Object.assign({}, val);
    lal.name = lal.name + '_LAL';

    this.createLookalikeService.lookalike = lal;
  }

  get lookalike(): LookalikeV2 {
    return this.createLookalikeService.lookalike;
  }

  get isCreateDisabled() {
    return this.nameInput.errors && this.nameInput.errors.length > 0;
  }

  maxNameLength = MAX_AUDIENCE_NAME_LENGTH;
  maxNameLengthErrorMessage = `Cannot have more than ${this.maxNameLength} characters`;
  nameValidations = this.createLookalikeService.nameValidations;
  isLookalikeInvalid$: Observable<boolean>;
  ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    actions$: Actions,
    private store: Store<AppState>,
    private snackbar: MatSnackBar,
    public createLookalikeService: CreateLookalikeService,
  ) {
    actions$
      .pipe(
        ofType(actions.LoadLookalike.type, actions.LoadUpdatedLookalike.type, fetchState.ErrorFetch.type),
        takeUntil(this.ngUnsubscribe)
      ).subscribe((res: actions.LoadLookalike | actions.LoadUpdatedLookalike | fetchState.ErrorFetch) => {
        this.handleLookalikeActionResponse(res);
      });
  }

  ngOnInit() {
    this.isLookalikeInvalid$ = this.nameInput.hasErrors$;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleState() {
    if (this.modal.open) {
      this.modal.close();
    } else {
      this.modal.show();
    }
  }

  onSave() {
    this.resetLookalikeFieldsToDefault();
    this.store.dispatch(new actions.CreateLookalike(this.lookalike, {urlVersionNumber: V5}));
  }

  resetLookalikeFieldsToDefault() {
    this.lookalike.identifier = '';
    this.lookalike.id = null;
    this.lookalike.job_status = "pending";
    this.lookalike.confidence_sizes = {};
  }

  handleLookalikeActionResponse(res) {
    if (res.type === actions.LoadLookalike.type) {
      this.closeOverlay();
      this.sendSnackbarMessage(`${res.lookalike.name} successfully created`, 'check')
    } else if (res.type === actions.LoadUpdatedLookalike.type) {
      this.closeOverlay();
      this.sendSnackbarMessage(`${res.lookalike.name} successfully updated`, 'check')
    } else {
      // Don't show snackbar for unrelated errors
      if (this.modal.open) {
        const error = res.error.error.error;
        const message = res.error.error.full_error_message;
        this.sendSnackbarMessage(`${error}: ${message}`, 'danger')
      }
    }
  }

  sendSnackbarMessage(message: string, panelClass: string) {
    this.snackbar.open(message, null, { duration: 5000, panelClass: [panelClass]});
  }

  closeOverlay() {
    this.modal.close();
  }
}
