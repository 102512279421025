<main>
  <table>
    <thead>
    <tr class="header-row"><th>Env</th><th>Status</th></tr>
    </thead>
    <tbody>
    <tr><td>API gateway</td><td [ngClass]="{'error-text': (gateway) != 'OK'}">{{gateway}}</td></tr>
    <tr><td>Auth Service</td><td [ngClass]="{'error-text': auth_service != 'OK'}">{{auth_service}}</td></tr>
    <tr><td>Clients Service</td><td [ngClass]="{'error-text': clients_service != 'OK'}">{{clients_service}}</td></tr>
    <tr><td>Insights Service</td><td [ngClass]="{'error-text': insights_service != 'OK'}">{{insights_service}}</td></tr>
    <tr><td>ID Analytics Service</td><td [ngClass]="{'error-text': id_service != 'OK'}">{{id_service}}</td></tr>
    <tr><td>Toolkit Service</td><td [ngClass]="{'error-text': (toolkit_service ) != 'OK'}">{{toolkit_service }}</td></tr>
    <tr><td>Plans Service</td><td [ngClass]="{'error-text': plans_service != 'OK'}">{{plans_service}}</td></tr>
    <tr><td>Segments Service</td><td [ngClass]="{'error-text': segments_service != 'OK'}">{{segments_service}}</td></tr>
    </tbody>
  </table>

</main>
