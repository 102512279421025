<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
  <h1 class="object-name">Admin</h1>
</header>

<main>
  <section>
    <ppc-tabs #tabs>
      <ppc-tab name="Edit" #editTab>
        <div class="tab-body">

          <div class="source-col">
            <label>Source</label>
            <div class="scroll-col">
              <mat-radio-group name="source select" [(ngModel)]="selectedSource" labelPosition="start" (change)="selectSource(selectedSource)">
                <div *ngFor="let source of vendorOptions; trackBy: trackByVendor">
                  <mat-radio-button [value]="source">{{source.name}}</mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
            <app-confirmation #confirm></app-confirmation>
          </div>

          <div class="summary-col">
          <label>Summary</label><span *ngIf="unsavedChanges" class="save-hint">*changes haven't been saved</span>
            <div *ngIf="!selectedSource">Select a source</div>
            <div *ngIf="selectedSource">

            <ng-template #clientRegionDropdowns let-row="ruleRow" let-isDefault="isDefault">
              <div class="client-region">
                <div *ngIf="isDefault">Default Rule</div>
                <div>
                  <mat-form-field>
                      <mat-select [(ngModel)]="isDefault? ALL : row.client" placeholder="Client" [disabled]="isDefault">
                          <mat-option *ngIf="isDefault || row.region != ALL" [value]="ALL">All</mat-option>
                          <mat-option *ngFor="let client of clientOptions" [value]="client.id">{{client.name}}</mat-option>
                        </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field>
                      <mat-select [(ngModel)]="isDefault? ALL : row.region" placeholder="Region" [disabled]="isDefault">
                          <mat-option *ngIf="isDefault || row.client != ALL" [value]="ALL">All</mat-option>
                          <mat-option *ngFor="let region of regionOptions" [value]="region.id" >{{region.name}}</mat-option>
                        </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </ng-template>

              <ng-template #optionDropdown let-isDefault="isDefault" let-permission="permission" let-client="client" let-region="region">
                  <input [style.display]="'none'" matInput>
                  <mat-form-field>
                      <mat-select [(ngModel)]="permission.value" >
                          <ng-container *ngFor="let option of PERMISSION_DROPDOWN_OPTIONS" >
                            <mat-option *ngIf="!(isDefault && option.notOnDefault)" [value]="option.value">{{option.display}}</mat-option>
                          </ng-container>
                        </mat-select>
                  </mat-form-field>
                <div class="hint" *ngIf="permission.value == 'inherit'">inherited: <b>{{determineInheritedValue(client, region, permission)}}</b></div>
              </ng-template>

            <ng-template #permissionsRow let-options="permissions" let-isDefault="isDefault" let-client="client" let-region="region">
                <div class="options-row">
                 <div class="permission-option" *ngFor="let option of options">
                    <label>{{option.name}}</label>
                   <div>
                     <ng-container *ngTemplateOutlet="optionDropdown; context: {isDefault: isDefault, permission: option, client: client, region: region }"></ng-container>
                   </div>
                 </div>
                </div>
              </ng-template>

              <section *ngIf="usageCtx.defaultRow" name="default-rules-row">
                <label>{{selectedSource.name}}</label>
                <div class="options-row-container default">
                   <ng-container *ngTemplateOutlet="clientRegionDropdowns; context: {ruleRow: row, isDefault: true}"></ng-container>

                    <div class="options-category">
                      <label>USAGE</label>
                      <ng-container *ngTemplateOutlet="permissionsRow; context: usageCtx.defaultRow"></ng-container>
                    </div>
                    <div class="options-category">
                      <label>MODELING</label>
                      <ng-container *ngTemplateOutlet="permissionsRow; context: modelingCtx.defaultRow"></ng-container>
                    </div>

                </div>
              </section>

              <section *ngIf="usageCtx.rows.length > 0" name="addition-rules">
                <div >Additional Rules</div>
                <ng-container *ngFor="let row of usageCtx.rows; let i = index">
                <div class="options-row-container">
                    <ng-container *ngTemplateOutlet="clientRegionDropdowns; context: {ruleRow: row}"></ng-container>
                    <div class="options-category">
                      <ng-container *ngTemplateOutlet="permissionsRow; context: row"></ng-container>
                    </div>
                  <div class="remove-container">
                    <i class="fa fa-times" title="Remove Rule" (click)="deleteRuleRow(i)"></i>
                    <div class="error-message">
                      {{ruleMessage(row.client, row.region).error}}
                    </div>
                    <div class="warning-message">
                      {{ruleMessage(row.client, row.region).warning}}
                    </div>
                  </div>
                </div>
                </ng-container>

              </section>

                <div class="add-rule" [class.disabled]="!canAddRow">
                  <span (click)="addRuleRow(usageCtx, selectedSource)"><i class="fa fa-2x fa-plus"></i> Add Rule</span>
                </div>

                <div class="pull-right" [class.disabled]="!canSave"><button mat-button (click)="savePermissions()">Save</button></div>

            </div>
          </div>
        </div>
      </ppc-tab>
      <ppc-tab name="View">
        <div>
          <vendor-permission-table
            [permissionOptions]="permissionOptions"
            [vendors]="vendorOptions"
            [clients]="clientOptions"
            [regions]="regionOptions"
            (selectSource)="selectSourceFromView($event)"
          ></vendor-permission-table>
        </div>
      </ppc-tab>
    </ppc-tabs>
  </section>
</main>
