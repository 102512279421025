import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { switchMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { combineLatest as observableCombineLatest, timer as rxTimer, BehaviorSubject } from 'rxjs';
import { Workbook } from 'exceljs/dist/exceljs';
import * as moment from 'moment';
import { saveAs } from "file-saver";

import * as indexReportActions from 'app/index-report/index-report.actions';
import { isFullContextSlugs } from 'app/hierarchy/hierarchy.utils';
import { selectIndexReportTasks } from 'app/index-report/index-report.reducer';
import { orderBy, reject, forEach, findIndex } from 'lodash';
import { IndexReportTask, TaskType, OutcomeTask } from './report-tasks.model';
import { taskInProgress } from './report-tasks.utils';
import { PpcHttpService } from 'app/services/ppc_http.service';
import { environment } from 'environments/environment';
import { taskHistoryAdminUrl } from '../shared/constants/id_analytics.urls';
import { notificationsUrl } from 'app/shared/constants/segments.urls';

// 5 minutes
export const CRON_TIMER = 5 * 1000 * 60;

@Injectable({
  providedIn: 'root'
})
export class ReportTaskService {
  // SAPI notifications
  private notifications$: BehaviorSubject<OutcomeTask[]> = new BehaviorSubject([]);

  // cron-like task
  @Effect()
  timedFetch$ = rxTimer(5000, CRON_TIMER).pipe(
    switchMap(() => this.requiredContext$),
    tap(() => this.getNotifications()),
    mergeMap(() => [
      // add additional cron fetches here
      new indexReportActions.FetchIndexReports(),
    ])
  )

  requiredContext$ = observableCombineLatest(
    this.store.select('users', 'loginUserId'),
    this.store.select('hierarchy', 'contextSlugs'),
  ).pipe(
    filter(([loginUserId, slug]) => !!loginUserId && isFullContextSlugs(slug)),
  )

  // all notification items to be listed
  tasks$ = observableCombineLatest(
    this.store.select('indexReports').pipe(select(selectIndexReportTasks)),
    this.notifications$,
  ).pipe(
    map(([indexReports, notifications]) => {
      // Task objects should have all data a BaseTask requires
      // concat the list and order by created_at date
      return orderBy([...indexReports, ...notifications], 'created_at', 'desc')
    })
  )

  // count displayed on left-hand-nav bell icon
  badgeCount$ = this.tasks$.pipe(
    map(tasks => {
      const count = reject(tasks, task => {
        if (task.report_type === TaskType.TASK_TYPE_INDEX_REPORT) {
          const t = task as IndexReportTask;
          return t.downloaded_at || taskInProgress(t);
        }
        if (task.report_type === TaskType.TASK_TYPE_OUTCOME_COMPLETED) {
          return (task as OutcomeTask).read_at;
        }
      }).length;

      return (count > 9) ? '9+' : count.toString();
    })
  )

  constructor(
    private store: Store<AppState>,
    private http: PpcHttpService,
  ) { }

  generatePastDownloadsWorkbook(data, createFile = true) {
    const wb = new Workbook();
    const date = moment(new Date()).format('YYYY-MM-DD');
    const envName = environment.envName;
    const fileName = `${date}_${envName}_past_downloads_report`;

    const ws = wb.addWorksheet('Past Downloads');

    const headers = ['Date', 'Time', 'Email', 'Hierarchy', 'Asset Name', 'Asset Type'];
    ws.addRow(headers);
    forEach(data, entry => {
      ws.addRow([
        moment(entry.created_at).format('YYYY-MM-DD'),
        moment(entry.created_at).format('LTS'),
        entry.created_by,
        entry.product_slug,
        entry.ui_name || entry.name,
        entry.resource_type,
      ]);
    });
    forEach(ws.columns, (column, index: number) => {
      column.width = index === 3 ? 40 : 15;
    });

    if (createFile) {
      wb.xlsx.writeBuffer().then(d => {
        const blob = new Blob([d], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, fileName)
      });
    }
  }

  getPastDownloads() {
    return observableCombineLatest(
      this.http.get(taskHistoryAdminUrl())
    ).pipe(
      map(([indexReportHistory]) => {
        return orderBy([...indexReportHistory], 'created_at', 'desc');
      })
    )
  }

  getNotifications() {
    this.http.get(notificationsUrl()).subscribe(notifications => {
      this.notifications$.next(notifications);
    });
  }

  markNotificationAsRead(id: number) {
    return this.http.post(`${notificationsUrl()}/${id}/read`, {}, { responseType: 'text' }).subscribe(
      () => {
        const notifications = this.notifications$.getValue();
        const idx = findIndex(notifications, { id });
        notifications.splice(idx, 1, {...notifications[idx], read_at: new Date().toString() });
        this.notifications$.next(notifications);
      }
    );
  }
}
