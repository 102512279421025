import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { ActivationDestinationsOverlayComponent } from './activation-destinations-overlay.component';
import { ActivationDestinationComponent } from './activation-destination/activation-destination.component';
import { PpcCheckboxComponent } from 'app/shared/components/ppc-checkbox/ppc-checkbox.component';
import { DestinationModalModule } from "../destination-modal/destination-modal.module";

@NgModule({
  declarations: [
    ActivationDestinationsOverlayComponent,
    ActivationDestinationComponent
  ],
  imports: [
    CommonModule,
    PpcComponentsModule,
    FormsModule,
    DestinationModalModule
  ],
  exports: [
    ActivationDestinationsOverlayComponent,
    PpcCheckboxComponent,
    DestinationModalModule
  ],
  providers: [
    HttpClientModule,
  ]
})

export class ActivationDestinationsOverlayModule { }
