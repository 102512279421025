<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
  <h1>
    <span class="object-name">Brand Management</span>
  </h1>
  <p>After creating a brand and product, select a left navigation module to begin.</p>
</header>

<main *ngIf="brandPermission.read; else permissionDenied">
  <div id="selectors-container" class="set-height" *ngIf="activeTab != 'Data Upload'; else setHeight">
    <div id="client-selector">
      <mat-form-field *ngIf="clients?.length">
        <mat-select
          [ngModel]="client.slug"
          (ngModelChange)="clientSlugSelection($event)"
          (selectionChange)="onClientChange($event)"
          placeholder="Client"
          >
          <mat-option *ngFor="let cl of clients" [value]="cl.slug">
            {{ cl.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div id="region-picker">
      <ppc-searchable-dropdown *ngIf="clientRegions?.length"
        #regionPicker
        itemLabelProp="name"
        mapFromProperty="slug"
        [allowClear]="false"
        [subjectModel]="regionSlug$"
        [items]="clientRegions"
        >
        <ng-template #itemTemplate let-item="item">
          <div class="region-item" [class.selected]="region === item">
            <button class="single-select-item"
              (click)="onRegionSelect(item); regionPicker.open = false"
              *ppcCheckOverflow="let hasOverflow=hasXOverflow"
              [ppcTooltip]="item?.name"
              [tooltipDisabled]="!hasOverflow"
              tabIndex="0">
              <i class="fa fa-flag-o" [class.invisible]="!item.default"></i>{{ item?.name }}
            </button>
          </div>
        </ng-template>
      </ppc-searchable-dropdown>
    </div>
  </div>
  <ng-template #setHeight>
    <div class="set-height">&nbsp;</div>
  </ng-template>
  <div class="tab-body brand-setup">
    <div [hidden]="brands.length > 0" id="empty-placeholder">
      Please Create a Brand
    </div>

    <div class="header-actions">
      <button (click)="addBrand()"
        [disabled]="!ableToAddBrand"
        class="btn btn-save">Add Brand</button>
    </div>

    <ul class="brand-list">
      <li *ngFor="let brand of brands; index as i" class="brand-list-item">
        <app-brand
          [client]="client"
          [region]="region"
          [brandIndex]="i"
          [brand]="brand"
          (deleteBrand)="onDeleteBrand($event)">
        </app-brand>
      </li>
    </ul>
  </div>
</main>
<ng-template #permissionDenied>
  <h3>You do not have access to this page.</h3>
</ng-template>
