import { keyBy, omit, merge, values, map, sortBy, reject, find as _find, flatMap, flatten } from 'lodash';
import {createSelector} from "@ngrx/store";

import * as actions from 'app/comparisons/comparisons.actions';
import { dataServiceType } from 'app/shared/utils/utils';
import { MOLECULA } from 'app/shared/utils/constants';
import { COUNT_TYPE_MODELED } from 'app/insights/insights.constants';

export interface Comparison {
  x_segments: ComparisonSegment[];
  y_segments: ComparisonSegment[];
  x_axis_name: string;
  y_axis_name: string;
  name: string;
  id?: number;
  visible?: boolean;
  is_people_count?: boolean;
  mode: string;
  created_at?: string;
  updated_at?: string;
  default?: boolean;
}

export interface ComparisonSegment {
  name: string;
  short_id: string;
  pathParts?: string[];
}

export interface ComparisonCountRequest {
  x_axis_ids: string[];
  y_axis_ids: string[];
  is_index: boolean;
  is_modeled: boolean;
  service_type?: string;
}

export interface ComparisonCountResponse {
  matrix: {
    [segmentTuple: string]: {
      count: number;
      percent_value: number;
      index_value: number;
    }
  };
  segments: {
    [shortId: string]: number
  };
}

export interface FocusedCellType {
  x: string;
  y: string;
  name: string;
}

export interface State {
  comparisons: {[id: number]: Comparison}
  comparisonCounts: {[comparisonId: number]: ComparisonCountResponse};
  comparisonUnderEdit?: Comparison;
  selectedComparisonId?: number;
  defaultComparisonId?: number;
  focusedCells?: FocusedCellType[];
}

const defaultState: State = {
  comparisons: {},
  comparisonCounts: {},
  focusedCells: [],
}

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.LoadComparisons.type:
      return {...state, comparisons: keyBy(action.comparisons, "id")}
    case actions.LoadComparison.type:
      return {
        ...state,
        comparisons: {...state.comparisons, [action.comparison.id]: action.comparison}
      }
    case actions.RemoveComparison.type:
      return {
        ...state,
        comparisons: omit(state.comparisons, [action.comparison.id])
      }
    case actions.EditComparison.type:
      return {
        ...state,
        comparisonUnderEdit: action.comparison
      }
    case actions.LoadComparisonCounts.type:
      return {
        ...state,
        comparisonCounts: {
          ...state.comparisonCounts,
          [action.comparisonId]: merge(state.comparisonCounts[action.comparisonId], action.comparisonCounts)
        }
      }
    case actions.SetSelectedComparison.type:
      return {
        ...state, selectedComparisonId: action.comparisonId
      }
    case actions.LoadDefaultComparison.type:
      return {
        ...state, defaultComparisonId: action.comparisonId
      }
    case actions.ToggleFocusedCell.type:
      return {
        ...state, focusedCells: (_find(state.focusedCells, action.focusedCell) ? reject(state.focusedCells, action.focusedCell) : [...state.focusedCells, action.focusedCell]) as FocusedCellType[]
      }
    case actions.ClearFocusedCells.type:
      return {...state, focusedCells: []}
    default:
      return state;
  }
}

export const comparisons: (State) => Comparison[] = createSelector(
  (state: State) => state.comparisons,
  (state: State) => state.defaultComparisonId,
  (comparisons, defaultComparisonId) => sortBy(
    map(
      values(comparisons),
      comparison => ({
        ...comparison,
        default: comparison.id == defaultComparisonId
      })
    ),
    [comparison => comparison.name.toLowerCase()]
  )
);

export const selectedComparison = createSelector(
  (state: State) => state.comparisons,
  (state: State) => state.selectedComparisonId,
  (state: State) => state.defaultComparisonId,
  (comparisons, selectedId, defaultId) => {
    if (comparisons[selectedId]) {return {...comparisons[selectedId], default: selectedId == defaultId}}
  }
)

export const selectAllCells = createSelector(
  selectedComparison,
  (comparison) => {
    if (!comparison) {return []; }
    return flatMap(comparison.x_segments, xSeg => {
      return map(comparison.y_segments, ySeg => {
        return { x: xSeg.short_id, y: ySeg.short_id };
      });
    });
  }
);

export function newComparison(): Comparison {
  return {
    x_segments: [],
    y_segments: [],
    x_axis_name: "",
    y_axis_name: "",
    name: "",
    mode: "matched",
    is_people_count: true
  }
}

export function buildComparisonContext(comparison: Comparison, cells: Partial<FocusedCellType>[] | FocusedCellType[]): {} {
  if (dataServiceType() === MOLECULA) {
    const included = (cells.map(({x, y}) => ([x, y])));
    return {
      primary: {
        context: { included, useAnds: true },
        isVendorQuery: false,
      }
    }
  } else {
    return {
      primary: {
        or: cells.map(({x, y}) => {
          return {
            include: [[x, y]]
          }
        }),
        isModeled: comparison.mode === COUNT_TYPE_MODELED
      }
    }
  }
}
