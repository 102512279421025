
import { map } from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {AppState} from "app/reducers";
import {Store} from "@ngrx/store";
import {fullContext} from "app/hierarchy/hierarchy.reducers";
import {HierarchyProduct} from "app/hierarchy/hierarchy.interface";
import {UploadCreativeScoreTemplate} from "../creative.actions";
import {CreativeService, TemplateUploadReport} from "app/services/creative.service";
import {selectTemplateUploadReport} from "../creative.reducers";
import {isFetchInFlight} from "app/shared/utils/fetch-state";

@Component({
  selector: 'ppc-creative-cms',
  templateUrl: './creative-cms.component.html',
  styleUrls: ['./creative-cms.component.sass']
})
export class CreativeCmsComponent implements OnInit {

  product: HierarchyProduct;

  uploadReport: TemplateUploadReport;
  isUploading: boolean;

  constructor(private store: Store<AppState>, private creativeService: CreativeService) { }

  ngOnInit() {
    fullContext(this.store).subscribe(
      ({ product }) => this.product = product
    );

    this.store.select('creative').pipe(map(selectTemplateUploadReport)).subscribe(
      report => this.uploadReport = report
    );

    this.store.select('fetchStates').pipe(map(fetchStates => fetchStates[UploadCreativeScoreTemplate.type])).subscribe(
      uploadState => {
        this.isUploading = isFetchInFlight(uploadState)
      }
    )
  }

  downloadDataTemplate(e) {
    e.preventDefault();
    return this.creativeService.downloadDataTemplate()
  }

  uploadTemplate(e) {
    e.preventDefault();
    this.store.dispatch(new UploadCreativeScoreTemplate(new FormData(e.target)));
  }

  hasErrors(): boolean {
    return this.uploadReport
           && !!(this.uploadReport.bad_creative_ids.length
                 || this.uploadReport.bad_attribute_ids.length
                 || this.uploadReport.bad_score_numbers.length)
  }

}
