
export const colorScheme = [
  "#BE5773",
  "#F7B5A2",
  "#DA9F3D",
  "#F3E692",
  "#BDDE94",
  "#82CAD0",
  "#2262B1",
  "#A55894",
  "#9360B2"
]

export const HEIGHT = 900;
export const WIDTH = 800;
export const GRAVITY = 0.005;
export const RADIUS_THRESHOLD = 15;

export const MAX_RADIUS = 15;
export const CLUSTER_PADDING = 10;
export const PADDING = 3;
