import {Action} from "@ngrx/store";
import {ApiKey, ApiKeySecret} from "./api-keys.model";

export class FetchAllApiKeys implements Action {
  static readonly type = 'FETCH_ALL_API_KEYS';
  readonly type = FetchAllApiKeys.type;

  constructor() {}
}

export class LoadApiKeys implements Action {
  static readonly type = 'LOAD_API_KEYS';
  readonly type = LoadApiKeys.type;

  constructor(public apiKeys: ApiKey[]) {}
}

export class AddApiKey implements Action {
  static readonly type = 'ADD_API_KEY';
  readonly type = AddApiKey.type;

  constructor(public apiKey: ApiKey) { }
}

export class EditApiKey implements Action {
  static readonly type = 'EDIT_API_KEY';
  readonly type = EditApiKey.type;

  constructor(public apiKey: ApiKey) { }
}

export class RemoveApiKey implements Action {
  static readonly type = 'REMOVE_API_KEY';
  readonly type = RemoveApiKey.type;

  constructor(public id: number) { }
}
export class ReissueApiKey implements Action {
  static readonly type = 'REISSUE_API_KEY';
  readonly type = ReissueApiKey.type;

  constructor(public id: number) { }
}

export class SetApiKeySecret implements Action {
  static readonly type = 'SET_API_KEY_SECRET';
  readonly type = SetApiKeySecret.type;

  constructor(public apiKeySecret: ApiKeySecret) { }
}

export type All = FetchAllApiKeys | LoadApiKeys | AddApiKey | RemoveApiKey | EditApiKey | ReissueApiKey | SetApiKeySecret
