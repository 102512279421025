import {Action} from "@ngrx/store";
import { Mekko, SubMarket, Market, NewMekko } from "./mekko.reducer";

export class FetchMekkos implements Action {
  static readonly type = "MEKKOS:FETCH_MEKKOS";
  readonly type = FetchMekkos.type;

  constructor(public product_slug?: string) {}
}

export class LoadMekkos implements Action {
  static readonly type = "MEKKOS:LOAD_MEKKOS";
  readonly type = LoadMekkos.type;

  constructor(public mekkos: Mekko[]) {}
}

export class LoadMekko implements Action {
  static readonly type = "MEKKOS:LOAD_MEKKO";
  readonly type = LoadMekko.type;

  constructor(public mekko: Mekko) {}
}

export class SetSelectedMekko implements Action {
  static readonly type = "MEKKOS:SET_SELECTED_MEKKO";
  readonly type = SetSelectedMekko.type;

  constructor(public mekko: Mekko) {}
}

export class EditMekko implements Action {
  static readonly type = "MEKKOS:EDIT_MEKKO";
  readonly type = EditMekko.type;

  constructor(public mekko: NewMekko) {}
}

export class DestroyMekko implements Action {
  static readonly type = "MEKKOS:DESTROY_MEKKO";
  readonly type = DestroyMekko.type;

  constructor(public mekko: Mekko) {}
}

export class RemoveMekko implements Action {
  static readonly type = "MEKKOS:REMOVE_MEKKO";
  readonly type = RemoveMekko.type;

  constructor(public mekko: Mekko) {}
}

export class SaveMekko implements Action {
  static readonly type = "MEKKOS:SAVE_MEKKO";
  readonly type = SaveMekko.type;

  constructor(public mekko: NewMekko) {}
}

export class CopyMekko implements Action {
  static readonly type = "MEKKOS:COPY_MEKKO";
  readonly type = CopyMekko.type;

  constructor(public mekko: Mekko) {}
}

export class FetchSubMarkets implements Action {
  static readonly type = "MEKKOS:FETCH_SUB_MARKETS";
  readonly type = FetchSubMarkets.type;
}

export class SaveSubMarket implements Action {
  static readonly type = "MEKKOS:SAVE_SUB_MARKET";
  readonly type = SaveSubMarket.type;

  constructor(public subMarket: SubMarket) {}
}

export class LoadSubMarket implements Action {
  static readonly type = "MEKKOS:LOAD_SUB_MARKET";
  readonly type = LoadSubMarket.type;

  constructor(public subMarket: SubMarket) {}
}

export class LoadSubMarkets implements Action {
  static readonly type = "MEKKOS:LOAD_SUB_MARKETS";
  readonly type = LoadSubMarkets.type;

  constructor(public subMarkets: SubMarket[]) {}
}

export class EditSubMarket implements Action {
  static readonly type = "MEKKOS:EDIT_SUB_MARKET";
  readonly type = EditSubMarket.type;

  constructor(public subMarket: SubMarket) {}
}

export class UpdateSubMarketUnderEdit implements Action {
  static readonly type = "MEKKOS:UPDATE_SUBMARKET_UNDER_EDIT";
  readonly type = UpdateSubMarketUnderEdit.type;

  constructor(public subMarket: Partial<SubMarket>) {}
}

export class UpdateSubMarket implements Action {
  static readonly type = "MEKKOS:UPSERT_SUB_MARKET";
  readonly type = UpdateSubMarket.type;

  constructor(public subMarket: SubMarket) {}
}

export class DestroySubMarket implements Action {
  static readonly type = "MEKKOS:DESTROY_SUB_MARKET";
  readonly type = DestroySubMarket.type;

  constructor(public subMarket: SubMarket) {}
}

export class UpdateSubMarketOrder implements Action {
  static readonly type = "MEKKOS:UPDATE_SUB_MARKET_ORDER";
  readonly type = UpdateSubMarketOrder.type;

  constructor(public subMarkets: Partial<SubMarket>[]) {}
}

export class UpdateMarketOrder implements Action {
  static readonly type = "MEKKOS:UPDATE_MARKET_ORDER";
  readonly type = UpdateMarketOrder.type;

  constructor(public markets: Market[]) {}
}

export class UpsertMarket implements Action {
  static readonly type = "MEKKOS:UPSERT_MARKET";
  readonly type = UpsertMarket.type;

  constructor(public market: Market) {}
}

export class EditMarket implements Action {
  static readonly type = "MEKKOS:EDIT_MARKET";
  readonly type = EditMarket.type;

  constructor(public market: Market) { }
}

export class DestroyMarket implements Action {
  static readonly type = "MEKKOS:DESTROY_MARKET";
  readonly type = DestroyMarket.type;

  constructor(public market: Market) {}
}


export class CancelSubMarketEdit implements Action {
  static readonly type = "MEKKOS:CANCEL_SUB_MARKET_EDIT";
  readonly type = CancelSubMarketEdit.type;
}

export class ToggleEditMekko implements Action {
  static readonly type = "MEKKOS:TOGGLE_EDIT_MEKKO";
  readonly type = ToggleEditMekko.type;
}

export class SetEditMekko implements Action {
  static readonly type = "MEKKOS:SET_EDIT_MEKKO";
  readonly type = SetEditMekko.type;

  constructor(public editMekko: boolean) { }
}

export class FetchDefaultMekko implements Action {
  static readonly type = "MEKKOS:FETCH_DEFAULT_MEKKO";
  readonly type = FetchDefaultMekko.type;
}

export class LoadDefaultMekko implements Action {
  static readonly type = "MEKKOS:LOAD_DEFAULT_MEKKO";
  readonly type = LoadDefaultMekko.type;

  constructor(public defaultMekko: number) { }
}

export class SaveDefaultMekko implements Action {
  static readonly type = "MEKKOS:SAVE_DEFAULT_MEKKO";
  readonly type = SaveDefaultMekko.type;

  constructor(public defaultMekko: number) { }
}

export class ToggleSubMarket implements Action {
  static readonly type = "MEKKOS:TOGGLE_SUB_MARKET";
  readonly type = ToggleSubMarket.type;

  constructor(public id: number) { }
}

export class ClearSelectedSubMarkets implements Action {
  static readonly type = "MEKKOS:CLEAR_SELECTED_SUB_MARKETS";
  readonly type = ClearSelectedSubMarkets.type;
}

export type ReducerActions = LoadMekkos | LoadMekko | SetSelectedMekko | EditMekko |
RemoveMekko | LoadSubMarket | LoadSubMarkets | EditSubMarket |
UpdateSubMarketUnderEdit | UpdateSubMarket | DestroySubMarket |
UpdateSubMarketOrder | UpdateMarketOrder | UpsertMarket |
EditMarket | DestroyMarket | CancelSubMarketEdit | ToggleEditMekko |
SetEditMekko | LoadDefaultMekko | SaveDefaultMekko | ToggleSubMarket |
ClearSelectedSubMarkets
