import {emptyHierarchyProduct} from "app/hierarchy/hierarchy.utils";
import {HierarchyProduct} from "app/hierarchy/hierarchy.interface";

export class Product implements HierarchyProduct {
  editMode: boolean = false;
  id: string;
  name: string;
  slug: string;
  slug_full: string;
  brand_id: string;
  region_ids: number[];
  conversant_company_id: number;

  constructor(data: HierarchyProduct = emptyHierarchyProduct()) {
    this.id = data.id || data['_id'];
    this.name = data.name;
    this.slug = data.slug;
    this.slug_full = data.slug_full;
    this.brand_id = data.brand_id;
    this.region_ids = data.region_ids;
    this.conversant_company_id = data.conversant_company_id;
  }

  open(): void {
    this.editMode = true;
  }

  close(): void {
    this.editMode = false;
  }

  get isOpen(): boolean {
    return this.editMode;
  }

  get isClosed(): boolean {
    return !this.editMode;
  }
}
