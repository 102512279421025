<div class="col-4" *ngLet="clientContext$ | async as clientContext">
  <mat-form-field>
    <mat-label>Client</mat-label>
    <mat-select [ngModel]="clientContext.clientSlug" [disabled]="lockedLevels.includes('client')">
      <mat-option *ngFor="let client of clients$ | async"
                  (click)="setClientContext(client)"
                  [value]="client.slug"
      >
        {{ client.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Region</mat-label>
    <mat-select [ngModel]="clientContext.regionSlug" [disabled]="lockedLevels.includes('region')">
      <mat-option *ngFor="let region of regions$ | async"
                  (click)="setClientContext(region)"
                  [value]="region.slug"
      >
        {{ region.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Brand</mat-label>
    <mat-select [ngModel]="clientContext.brandSlug" [disabled]="lockedLevels.includes('brand')">
      <mat-option *ngFor="let brand of brands$ | async"
                  (click)="setClientContext(brand)"
                  [value]="brand.slug"
      >
        {{ brand.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Product</mat-label>
    <mat-select [ngModel]="clientContext.productSlug" [disabled]="lockedLevels.includes('product')">
      <mat-option *ngFor="let product of products$ | async"
                  (click)="setClientContext(product)"
                  [value]="product.slug"
      >
        {{ product.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>