import { apiUrl } from '../../services/ppc_http.service';
import { SEGMENTS_API_PATH, V1, V2, V3, V4, V5, V6 } from '../utils/constants';

export function adminAudienceProductUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/audiences/admin_product`);
}

export function adminLookalikeProductUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/lookalikes/admin_product`);
}

export function adminActivationProductUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/activations/admin_product`);
}

export function adminUnfusedAudUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/audiences/unfused_identifiers`);
}

export function adminUnfusedLalUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/lookalikes/unfused_identifiers`);
}

export function fetchPartnerUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/partners`);
}

export function fetchAdminPartnersUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/partners/admin`);
}

export function destinationsUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/destinations`);
}

export function baseSegmentPermissionsSetsUrl(urlVersionNumber): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${urlVersionNumber}/segment_permissions_sets`);
}

export function segmentPermissionsSetsV1Url(path: string): string {
  return baseSegmentPermissionsSetsUrl(V1) + path;
}

export function segmentPermissionsSetsV2Url(path: string): string {
  return baseSegmentPermissionsSetsUrl(V2) + path;
}

export function sendToTardiisV1Url(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V1}/send_to_tardiis`);
}

export function audiencePermissionsUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V3}/audiences/permissions`);
}

export function lookalikePermissionsUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V3}/lookalikes/permissions`);
}

export function motivationPermissionsUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V1}/motivations/permissions`);
}

export function sendToPartnerAudienceUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/audiences/activation`);
}

export function sendToPartnerLookalikeUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/lookalikes/activation`);
}

export function sendToTardiisUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V3}/send_to_tardiis`);
}

export function activationUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/activations`);
}

export function postActivationsUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/activations`);
}

export function activationsContextUrl() {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/activations/context_info`);
}

export function updatePermissionsUrl(ppcObjectType: string): string {
  let updateApiUrl: string;
  switch (ppcObjectType) {
    case 'audience':
      updateApiUrl = audiencePermissionsUrl();
      break;
    case 'lookalike':
      updateApiUrl =  lookalikePermissionsUrl();
      break;
    case 'motivation':
      updateApiUrl =  motivationPermissionsUrl();
      break;
    default:
      updateApiUrl = audiencePermissionsUrl();
      break;
  }
  return updateApiUrl;
}

export function getActivationsUrl(identifier: string): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/activations/${identifier}`);
}

export function baseAudiencesUrl({ urlVersionNumber, singular }): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${urlVersionNumber}/audience${singular ? '' : 's'}`);
}

export function audienceUrl(urlVersionNumber, id = ''): string {
  return baseAudiencesUrl({ urlVersionNumber: urlVersionNumber, singular: true }) + `/${id}`;
}

export function audiencesUrl(urlVersionNumber): string {
  return baseAudiencesUrl({ urlVersionNumber, singular: false });
}

export function audiencesOverviewUrl(urlVersionNumber): string {
  return baseAudiencesUrl({ urlVersionNumber: urlVersionNumber, singular: false }) + '/product';
}

export function audienceV3Url(id = ''): string {
  return audienceUrl(V3, id);
}

export function segmentsHierarchyUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V6}/segments_hierarchy`);
}

export function lookalikesV1Url(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V1}/lookalikes`);
}

export function lookalikeV1Url(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V1}/lookalike`);
}

export function baseLookalikesUrl({ urlVersionNumber, singular }): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${urlVersionNumber}/lookalike${singular ? '' : 's'}`)
}


export function lookalikesUrl(urlVersionNumber): string {
  return baseLookalikesUrl({ urlVersionNumber: urlVersionNumber, singular: false });
}

export function lookalikeUrl(urlVersionNumber): string {
  return baseLookalikesUrl({ urlVersionNumber: urlVersionNumber, singular: true });
}

export function lookalikesOverviewUrl(urlVersionNumber): string {
  return baseLookalikesUrl({ urlVersionNumber: urlVersionNumber, singular: false })  + `/product`;
}

export function addressableCountMetadataUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V1}/addressable_counts_metadata`);
}

export function motivationsUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V1}/motivations`);
}

export function motivationsOverviewUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V1}/motivations/product`);
}

export function outcomesUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V1}/outcomes`);
}

export function outcomesOverviewUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V1}/outcomes/product`);
}

export function notificationsUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V1}/notifications`);
}

export function segmentsUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V5}/segments`);
}

export function audiencesV3Url(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V3}/audiences`);
}

export function lookalikesV3Url(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V3}/lookalikes`);
}

export function adminPermissionsTripleUrl(versionNumber): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${versionNumber}/segment_permissions_sets/admin`);
}

export function vendorDropDownUrl(versionNumber): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${versionNumber}/vendors_for_dropdown`);
}

export function vendorListUrl(versionNumber): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${versionNumber}/vendors`);
}

export function segmentsByShortIdsURL(shortIds: string[]): string {
  return apiUrl(`/api/${V3}/segments/fetch_segments_by_short_ids/${shortIds}`);
}

export function ppcObjectsUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V3}/metadata/ppc_objects`);
}

export function searchUrl(
  api_version: string,
  endpoint: string,
  keyword: string,
  limit = 0,
  offset = 0,
  vendors = [],
  use_case: string
): string {
  return apiUrl(
    `${SEGMENTS_API_PATH}/api/${api_version}${endpoint}?use_cases[]=${use_case}&term=${encodeURIComponent(
      keyword
    )}&limit=${limit}&offset=${offset}&vendors=${vendors}`
  );
}

export function segmentsV1Url(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V1}/segments`);
}

export function segmentsV3Url(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V3}/segments`);
}

export function segmentsV4Url(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/segments`);
}

export function allPermissionsUrl(): string {
  return apiUrl(`${SEGMENTS_API_PATH}/api/${V4}/permissions`);
}
