import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import { PpcHttpService } from "../../../services/ppc_http.service";
import {Observable} from "rxjs";
import {PlanImportStatus} from "./plan-import-status";
import { scenarioPlannerUrl } from '../../../shared/constants/plans.urls';

@Injectable()
export class ScenarioImportService {

  constructor (private http: PpcHttpService) {}

  planImportStatusURL(productId: string, id?: string): string {
    if (id) {
      return `${scenarioPlannerUrl()}/${id}`;
    }
    return `${scenarioPlannerUrl()}/status_import`;
  }

  getImports(productId: string): Observable<PlanImportStatus[]> {
    return this.http.get(this.planImportStatusURL(productId)).pipe(
      map(json => json.data.map(status => status)), );
  }

  deleteImport(productId: string, planId: string): Observable<any> {
    return this.http.delete(this.planImportStatusURL(productId, planId))
  }

  importPlan(spPlanId): Observable<PlanImportStatus> {
    return this.http.post(`${scenarioPlannerUrl()}/import_scenario`, {sp_plan_id: spPlanId}).pipe(
      map(json => {return json.data}), )
  }


}
