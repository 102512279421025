import { Injectable } from '@angular/core';
import { fetchResource } from 'app/shared/utils/fetch-state';
import { ApiKey, ApiKeySecret } from 'app/admin/api-keys/api-keys.model'
import { FetchAllApiKeys, LoadApiKeys, RemoveApiKey, EditApiKey, ReissueApiKey } from 'app/admin/api-keys/api-keys.actions'
import { Store } from "@ngrx/store";
import {Observable} from 'rxjs';
import { AppState } from "app/reducers";
import { PpcHttpService } from 'app/services/ppc_http.service';
import {map} from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { apiKeysUrl } from 'app/shared/constants/auth.urls';


@Injectable({
  providedIn: 'root'
})
export class ApiKeysService {

  @Effect()
  fetchApiKeys$ = this.actions$.pipe(
    ofType(FetchAllApiKeys.type),
    fetchResource(
      () => this.getAllApiKeys().pipe(map(apiKeys => new LoadApiKeys(apiKeys)))
    ));

  @Effect()
  editApiKey$ = this.actions$.pipe(
    ofType(EditApiKey.type),
    fetchResource(
      action => this.editApiKey(action.apiKey)
    ));

  @Effect()
  removeApiKey$ = this.actions$.pipe(
    ofType(RemoveApiKey.type),
    fetchResource(
      action => this.removeApiKey(action.id)
    ));

  @Effect()
  reissueApiKey$ = this.actions$.pipe(
    ofType(ReissueApiKey.type),
    fetchResource(
      action => this.reissueApiKey(action.id)
    ));

  constructor(
    private http: PpcHttpService,
    private actions$: Actions,
    private store: Store<AppState>
  ) { }

  getAllApiKeys(): Observable<ApiKey[]> {
    return this.http.get(apiKeysUrl());
  }

  addApiKey(apiKey): Observable<ApiKeySecret> {
    return this.http.post(apiKeysUrl(), apiKey)
  }

  editApiKey(apiKey): Observable<ApiKey> {
    return this.http.put(apiKeysUrl(apiKey.id), apiKey);
  }

  removeApiKey(id): Observable<ApiKey> {
    return this.http.delete(apiKeysUrl(id));
  }

  reissueApiKey(id): Observable<ApiKeySecret> {
    return this.http.put(`${apiKeysUrl(id)}/reissue`, null);
  }

}
