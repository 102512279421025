import { Action } from "@ngrx/store";

import { IndexReportTask } from 'app/report-tasks/report-tasks.model';


export class FetchIndexReports implements Action {
  static readonly type = 'INDEX_REPORT:FETCH_INDEX_REPORTS';
  readonly type = FetchIndexReports.type;

  constructor() { }
}

export class FetchIndexReport implements Action {
  static readonly type = 'INDEX_REPORT:FETCH_INDEX_REPORT';
  readonly type = FetchIndexReports.type;

  constructor(public id: number) { }
}

export class InitiateIndexReport implements Action {
  static readonly type = 'INDEX_REPORT:INITIATE_INDEX_REPORT';
  readonly type = InitiateIndexReport.type;

  constructor(public resources: Partial<IndexReportTask>[]) { }
}

export class LoadIndexReport implements Action {
  static readonly type = 'INDEX_REPORT:LOAD_INDEX_REPORT';
  readonly type = LoadIndexReport.type;

  constructor(public task: IndexReportTask) { }
}

export class LoadIndexReports implements Action {
  static readonly type = 'INDEX_REPORT:LOAD_INDEX_REPORTS';
  readonly type = LoadIndexReports.type;

  constructor(public tasks: IndexReportTask[]) { }
}

export class AddActionInProgress implements Action {
  static readonly type = 'INDEX_REPORT:ADD_ACTION_IN_PROGRESS';
  readonly type = AddActionInProgress.type;

  constructor(public taskId: number) { }
}

export class RemoveActionInProgress implements Action {
  static readonly type = 'INDEX_REPORT:REMOVE_ACTION_IN_PROGRESS';
  readonly type = RemoveActionInProgress.type;

  constructor(public taskId: number) { }
}

export type ReducerActions = FetchIndexReports | FetchIndexReport | InitiateIndexReport | LoadIndexReport |
LoadIndexReports | AddActionInProgress | RemoveActionInProgress
