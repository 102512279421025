<div class="wizard-container">
  <div step>
    <br>
    <p>
      Thank you for your interest in Epsilon PeopleCloud Discovery! Before you can access the Epsilon PeopleCloud Discovery platform,
      we need to collect some information in order to set up your account.
    </p>
    <p>
      First, please log in with your LionLogin credentials. This will allow us to properly provision
      (and simplify the process of requesting) your access to Epsilon PeopleCloud Discovery.
    </p>

    <a class="btn coreUIButton-primary" [href]="lionLoginUrl" mat-button>Login using Lion Login</a>
  </div>

  <div step>
    <div class="step-title">
      Nice to meet you, {{requestForm.firstName}}
    </div>
    <p>Please confirm the information below.</p>

    <ppc-input
      [(ngModel)]="requestForm.firstName"
      placeholder="Enter your first name..."
      alwaysFloatLabel="true"
      minLength="2"
    >
      <label>First name</label>
    </ppc-input>

    <ppc-input
      [(ngModel)]="requestForm.lastName"
      placeholder="Enter your last name..."
      alwaysFloatLabel="true"
      minLength="2"
    >
      <label>Last name</label>
    </ppc-input>

    <ppc-input
      [(ngModel)]="requestForm.email"
      placeholder="Enter your email address..."
      alwaysFloatLabel="true"
      minLength="2"
    >
      <label>E-mail address</label>
    </ppc-input>

    <ppc-input
      [(ngModel)]="requestForm.llUsername"
      placeholder="Enter your LionLogin username..."
      alwaysFloatLabel="true"
      minLength="2"
    >
      <label>LionLogin username</label>
    </ppc-input>

    <button class="btn action-btn-solid" (click)="nextStep()" [disabled]="lastValidStep() < 1">
       Next <i class="fa fa-arrow-right"></i>
    </button>
  </div>

  <div step>
    <div class="step-title">
      What kind of work do you do?
    </div>

    <label class="agency-label">Agency</label>
    <mat-select
      [(ngModel)]="requestForm.agency"
      placeholder="Select an agency..."
      required="true"
    >
      <mat-option *ngFor="let agency of agencies$ | async" [value]="agency">{{agency.name}}</mat-option>
    </mat-select>

    <ppc-input
      [(ngModel)]="requestForm.jobTitle"
      placeholder="Enter your job title..."
      alwaysFloatLabel="true"
      minLength="2"
    >
      <label>Job title</label>
    </ppc-input>

    <button class="btn" (click)="prevStep()">
      <i class="fa fa-arrow-left"></i> Back
    </button>
    <button class="btn action-btn-solid" (click)="nextStep()" [disabled]="lastValidStep() < 2">
       Next <i class="fa fa-arrow-right"></i>
    </button>
  </div>

  <div step>
    <div class="step-title">What access do you need?</div>
    <p *ngIf="loggedIn$ | async">It looks like you do not currently have access to any clients in Epsilon PeopleCloud Discovery. Fill out this form to request access.</p>
    <label>Clients / regions / brands / products</label>
    <div class="hierarchy-selector-container">
      <div class="selected-slugs clickable" (click)="isHierarchySelectorOpen = !isHierarchySelectorOpen">
        <div *ngFor="let node of requestForm.hierarchyNodes">
          {{node.fullName}}<i class="fa fa-close clickable" (click)="$event.stopPropagation(); requestForm.hierarchyNodes.delete(node)"></i>
        </div>
        <div *ngIf="requestForm.hierarchyNodes.size === 0" class="hierarchy-selector-placeholder">
          Select one or more options...
        </div>
      </div>

      <div class="hierarchy-selector-dropdown" *ngIf="isHierarchySelectorOpen">
        <ppc-checkbox
          [checked]="requestForm.hierarchyNodes.has(iDontKnowHierarchyNode)"
          (change)="toggleNode($event, iDontKnowHierarchyNode)"
          class="all-clients-toggle"
          >
          {{iDontKnowHierarchyNode.fullName}}
        </ppc-checkbox>

        <ppc-hierarchy-selector
          [authorizedNodes]="requestForm.hierarchyNodes"
          (change)="requestForm.hierarchyNodes = $event"
          [hideInternalClients]='true'
        ></ppc-hierarchy-selector>
      </div>
    </div>

    <button class="btn" (click)="prevStep()" *ngIf="!(loggedIn$ | async)">
      <i class="fa fa-arrow-left"></i> Back
    </button>
    <button class="btn action-btn-solid" (click)="nextStep()" [disabled]="lastValidStep() < 3">
       Next <i class="fa fa-arrow-right"></i>
    </button>
  </div>

  <div step>
    <div class="step-title">We're listening!</div>
    <p>
      Please add additional details regarding your request, if any. Your thoughtful response will help to expedite the processing of your request.
    </p>

    <label>Request context</label>
    <textarea
      [(ngModel)]="requestForm.extraInfo"
      placeholder="Provide any additional context for your access request...">
    </textarea>

    <button class="btn" (click)="prevStep()">
      <i class="fa fa-arrow-left"></i> Back
    </button>
    <button class="btn action-btn-solid" (click)="submit()">Submit request</button>
  </div>

  <div step>
    <div class="step-title">Thank you!</div>

    <p>
      Your access request has been sent to our Product Support team. Please allow up to 2 business days
      for your request to be processed and completed.
    </p>

    <a class="btn action-btn-solid" href="/login">Return to Login</a>
  </div>
</div>
