// @Deprecated

import {map, filter} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {environment} from "environments/environment";
import {Observable} from "rxjs";
import {NavigationEnd, Params, Router, RoutesRecognized, ActivatedRouteSnapshot} from "@angular/router";
import {getAllRouteParams} from "app/shared/utils/utils";

import * as FileSaver from "file-saver";
import {get} from 'lodash';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PpcHttpService {

  constructor(private http: HttpClient) {}

  get(path, options?): Observable<any> {
    return this.http.get(path, options)
  }

  post(path, body, options?): Observable<any> {
    return this.http.post(path, body, options);
  }

  put(path, body): Observable<any> {
    return this.http.put(path, body);
  }

  patch(path, body): Observable<any> {
    return this.http.patch(path, body);
  }

  delete(path): Observable<any> {
    return this.http.delete(path);
  }

  upload(path, body): Observable<any> {
    return this.http.post(path, body)
  }

  getDownload(path) {
    return this.http.get(path, {responseType: "blob", observe: "response"})
  }

  downloadFile(responseData, defaultFilename) {
    const disposition = responseData.headers.get("content-disposition");
    if (disposition != null) {
      const filenameMatch = disposition.match(/filename=\"?([^;"]*)\"?;?/)
      var filename = filenameMatch && filenameMatch[1]
    }

    FileSaver.saveAs(responseData.body,
      filename || defaultFilename,
      { type: responseData.headers.get('Content-Type') });
  }
}

export function apiUrl(path: string): string {
  let url = environment['apiUrl']
  if (!url) {
    const host = get(environment, 'api.host')
    const protocol = get(environment, 'api.protocol')
    url = protocol && host && `${protocol}://${host}` || ''
  }
  return url + path
}
