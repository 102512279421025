import * as actions from 'app/outcome-audience/outcome-audience.actions';
import { createSelector } from '@ngrx/store';
import { find, get, isEmpty, keyBy, sortBy, values, omit } from "lodash";

export type PveGoal = 'Increase Buyers' | 'Increase Conversions' | 'Increase Revenue';
export type PveGoalRequestParam = 'binary' | 'number' | 'value';
export type PveConsumerType = 'Anyone' | 'Loyal Buyers' | 'Lapsed Buyers' | 'New to file';
export type PveConsumerTypeRequestParam = 'anyone' | 'loyal_buyers' | 'lapsed_buyers' | 'new_file';

export interface OutcomeAudience {
  id: number;
  product_slug: string;
  name: string;
  identifier: string;
}

export interface PveRequest {
  id?: number;
  name: string;
  audience_size: number;
  goal: PveGoalRequestParam;
  consumer_type: PveConsumerTypeRequestParam;
  range_in_days_seen?: number;
  range_in_days_not_seen?: number;
}

export interface State {
  outcomeAudiences: {[id: number]: OutcomeAudience};
  selectedOutcomeAudienceId?: number;
}

export function newOutcomeAudience(): OutcomeAudience {
  return {
    id: null,
    name: "None",
    identifier: null,
    product_slug: null
  };
}

export const defaultState: State = {
  outcomeAudiences: {},
  selectedOutcomeAudienceId: null,
}

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.LoadOutcomeAudiences.type:
      const newOutcomeAudiences = keyBy(action.outcomeAudiences, 'id');
      return {...state, outcomeAudiences: newOutcomeAudiences};
    case actions.SetSelectedOutcomeAudience.type:
      return {
        ...state,
        selectedOutcomeAudienceId: action.outcomeAudience && action.outcomeAudience.id,
      };
    default:
      return state;
  }
}

export const selectOutcomeAudiences = createSelector(
  (state: State) => state.outcomeAudiences,
  (outcomeAudiences) => {
    if (isEmpty(outcomeAudiences)) {
      return [newOutcomeAudience()];
    } else {
      return sortBy(values(outcomeAudiences).map(outcomeAudience => outcomeAudience), outcomeAudience => outcomeAudience.name.toLowerCase());
    };
  }
)

export const selectActiveOutcomeAudience = createSelector(
  (state: State) => state.outcomeAudiences,
  (state: State) => state.selectedOutcomeAudienceId,
  (outcomeAudiences, selectedOutcomeAudienceId) => {
    if (outcomeAudiences[selectedOutcomeAudienceId]) {
      return outcomeAudiences[selectedOutcomeAudienceId];
    } else {
      return newOutcomeAudience();
    }
  }
)
