<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
</header>
<main>
  <div class="header gold" style="cursor: pointer">
    User Settings
  </div>
  <button mat-fab
    *ngIf="{ value: user$ | async } as user"
    class="user-action"
    title="Go Back"
    (click)="goBack(user.value)"
    >&larr;</button>
  <div *ngIf="user$ | async" class="user-detail">
    <div class="row">
      <span class="user-info-label">Username: </span>
      <span class="user-info" *ngIf="(user$ | async)?.email">{{(user$ | async).email}}</span>
    </div>
    <button class="btn btn-save" (click)="savePermissions()">Save Hierarchy Permissions</button>
    <ppc-hierarchy-selector
      [byFullSlug]="true"
      [authorizedNodes]="permissionSlugs$ | async"
      (change)="updateHierarchyPerms($event)"
    ></ppc-hierarchy-selector>
  </div>
</main>
