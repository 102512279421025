import { Injectable } from "@angular/core";
import { BuilderAudience, AudienceRuleGroup, AudienceRuleEntry } from '../audience-builder.models';
import { Observable, of as observableOf } from 'rxjs';
import { newAudience } from '../audience-builder.utils';

@Injectable()
export class AudienceMapper {

  constructor() {
    throw new Error("Generic class Audience Mapper instantianted instead of an environment specific version");
  }

  public toJson(audience: BuilderAudience): any {
    return { }
  }

  public fromJson(audience: any): Observable<BuilderAudience> {
    return observableOf(newAudience("audience-prefix"))
  }

  public fromJsonPersona(audience: any): Observable<BuilderAudience> {
    return observableOf(newAudience("audience-prefix"))
  }

  private groupItemsFromJson(rule: any, segments: {[identifier: string]: any}): AudienceRuleEntry[] {
    return [ ]
  }

  private groupToJson(group: AudienceRuleGroup): any {
    return { }
  }

}
