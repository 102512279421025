import {Component, TemplateRef} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-material-modal',
  templateUrl: './material-modal.component.html',
  styleUrls: ['./material-modal.component.sass']
})
export class MaterialModalComponent {

  public dialogTitleText: string;
  public dialogBodyText: string;
  public dialogCancelText: string;
  public dialogConfirmText: string;
  public dialogTemplate: TemplateRef<any>;

  constructor(public dialogRef: MatDialogRef<MaterialModalComponent>) {
  }

}
