<div class="popup-container">

  <div #popupTrigger class="popup-trigger" (click)="toggle()">
    <ng-content select="[popupTrigger]"></ng-content>
  </div>

  <div #popupContent class="popup-content"
       [style.right.%]="rightPct"
       [class.openUpwards]="openUpwards$ | async"
       [class.open]="isOpen">

    <div class="popup-header">
      <ng-content select="[popupHeader]"></ng-content>
    </div>

    <div class="popup-body"
         [style.width.px]="widthPx"
         [style.height.px]="heightPx">
      <ng-content select="[popupBody]"></ng-content>
    </div>

  </div>

</div>
