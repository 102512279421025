<div class="overview">
  <div *ngIf="snapshot?.filters?.length" class="filters"
       ppcTooltip [tooltipTemplate]="tooltip" tooltipAlignment="left"[tooltipOffset]="{x: 150}">
    Filters: {{ displayPathParts }}

    <ng-template #tooltip>
      <div>Filters:</div>
       {{ fullPathParts }}
    </ng-template>
  </div>

  <div class="audiences">
    <div class="audience-card" *ngFor="let subMarket of subMarkets">
      <sub-market-confidence [subMarket]="subMarket" [confidenceSizes]="getConfidenceSizes(subMarket.id)">
      </sub-market-confidence>
    </div>
  </div>
</div>
