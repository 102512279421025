import {Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild, ElementRef} from '@angular/core';

export interface Option {
  name: string,
  id: string
}

@Component({
  selector: 'app-dropdown-with-buttons',
  templateUrl: './dropdown-with-buttons.component.html',
  styleUrls: ['./dropdown-with-buttons.component.sass']
})
export class DropdownWithButtonsComponent implements OnInit, OnChanges {

  @Input() options: Option[];
  @Input() noOptionsMessage: string;
  @Input() noOptionsLink: string;
  @Input() filterTitle = "Filter";
  @Input() onMessageClick: () => void;
  @Input() onSave: (optionId: string, callBack: (isSuccess) => void) => void;
  @Input() onCancel: (callBack: (isSuccess) => void) => void;
  @Input() onOptionSelect: (optionId: string, callBack: (isSuccess) => void) => void;

  filterQuery: string;
  filteredOptions: Option[];
  selectedOptionId: string;

  dropdownHidden;

  constructor() { }

  ngOnInit() {
    this.filteredOptions = this.options;
    this.dropdownHidden = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["options"] && changes["options"].currentValue != changes["options"].previousValue) {
      this.filterQuery = "";
      this.filteredOptions = changes["options"].currentValue
    }
  }

  onSaveClick() {
    this.onSave && this.onSave(this.selectedOptionId, this.closeIf.bind(this));
  }

  onCancelClick() {
    this.onCancel ? this.onCancel(this.closeIf.bind(this)) : this.closeDropdown();
  }

  onOptionClick(optionId: string) {
    this.onOptionSelect
      ? this.onOptionSelect(optionId,
        isSuccess => isSuccess && this.selectOption.bind(this, optionId))
      : this.selectOption(optionId);
  }

  openDropdown() {
    this.dropdownHidden = false;
  }

  closeDropdown() {
    this.filterQuery = "";
    this.filterOptions();
    this.dropdownHidden = true;
  }

  selectOption(optionId: string) {
    this.selectedOptionId = optionId;
  }

  closeIf(isSuccess) {
    isSuccess && this.closeDropdown();
  }

  filterOptions() {
    // clear selection so user can't accidentally select an option that's been filtered out
    this.selectOption("");

    if (this.filterQuery === "") {
      this.filteredOptions = this.options;
    } else {
      this.filteredOptions = this.options
        .filter(option => (option.name != null) && option.name.trim().toLowerCase().includes(this.filterQuery.trim().toLowerCase()))
    }

  }

}
