import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { chain, find, get, keyBy, mapValues } from 'lodash';

import { Demographic, Bucket, Tab, toMillions, fixCounts } from 'app/insights/insights.models';
import { PersonLevelCompareService } from '../person-level-compare.service';
import { COMPARE_COLORS } from '../person-level-compare.constants';
import { INSIGHTS_CONTEXT, InsightsContextType } from '../../../insights.constants';
import * as insightsActions from 'app/insights/insights.actions'
import { InsightsCountService } from 'app/insights/insights-count.service';
import { CompareTarget, compareTargets } from '../../../insights.reducer';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { isDefined } from 'app/shared/utils/utils';
import { selectRegion } from 'app/hierarchy/hierarchy.reducers';
import { HierarchyRegion } from "app/hierarchy/hierarchy.interface";

@UntilDestroy()
@Component({
  selector: 'ppc-person-level-compare-widget',
  templateUrl: './person-level-compare-widget.component.html',
  styleUrls: ['./person-level-compare-widget.component.sass']
})
export class PersonLevelCompareWidgetComponent implements OnInit, OnDestroy {
  demographic: Demographic;
  @Input() tab: Tab;
  compareColors = COMPARE_COLORS;
  isIndexMode$ = this.store.select("insights", this.insightsContext, "indexMode");
  compareTargets: CompareTarget[];
  segmentCounts: {[identifier: string]: number} = {};
  region: HierarchyRegion;
  canEditWidget$ = this.store.select('permissions', 'demographics', 'update');

  constructor(public compareService: PersonLevelCompareService,
    private store: Store<AppState>,
    private counts: InsightsCountService,
    private segmentService: SegmentV2Service,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {
    this.store.select('hierarchy').pipe(
      select(selectRegion),
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(region => this.region = region);

    compareService.demographic$.pipe(
      untilDestroyed(this)
    ).subscribe(demographic => {
      this.demographic = demographic;

      const ids = chain(get(this.demographic, 'buckets'))
        .map('short_id')
        .compact()
        .uniq()
        .value();
      if (ids.length) {
        this.segmentService.fetchByIdentifiers(ids).subscribe(segments => {
          const fixedSegments = fixCounts(segments, this.region);

          this.segmentCounts = mapValues(keyBy(ids), id => {
            const segment = find(fixedSegments, s => s.identifier === id);
            return get(segment, ['count', 'matched']);
          });
        });
      }
    });

    this.store.pipe(select(compareTargets(this.insightsContext)), untilDestroyed(this))
      .subscribe(compareTargets => this.compareTargets = compareTargets)

  }

  ngOnInit() { }

  ngOnDestroy() { }

  toFilter(bucket: Bucket) {
    return {
      shortId: bucket.short_id,
      pathParts: [this.demographic.name, bucket.name],
      type: "demographic-segment",
      demographicId: this.demographic.id,
      tab: this.tab.name
    }
  }

  toggleFilter(bucket: Bucket) {
    this.store.dispatch(new insightsActions.ToggleFilter(this.toFilter(bucket), this.insightsContext));
  }

  isFiltered(bucket: Bucket) {
    return this.counts.hasFilter(this.toFilter(bucket));
  }

  get isIdCount() {
    return this.demographic.is_id_count;
  }

  getCountLabel(compareIdentifier: string, bucketIdentifier: string) {
    return toMillions(this.compareService.getCount(compareIdentifier, bucketIdentifier));
  }

  editDemographic() {
    this.store.dispatch(new insightsActions.EditDemographic(this.demographic, this.insightsContext))
  }

  bucketHasZeroCount(bucket: Bucket) {
    return get(this.segmentCounts, bucket.short_id) === 0;
  }

  get demographicHasZeroCount() {
    return chain(this.demographic)
      .get('buckets')
      .map(b => this.segmentCounts[b.short_id])
      .some(count => count === 0)
      .value();
  }
}
