import { NgModule } from "@angular/core";
import { NgLetModule } from 'ng-let';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { DragulaModule } from "ng2-dragula";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";

import { PpcComponentsModule } from "app/modules/ppc-components.module";
import { GrowV3Component } from "app/insights/grow-v3/grow-v3.component";
import { GrowV3MekkoComponent } from "app/insights/grow-v3/grow-v3-mekko/grow-v3-mekko.component";
import { MekkoBuilderComponent } from "app/insights/grow-v3/grow-v3-mekko/mekko-builder/mekko-builder.component";
import { MekkoColumnComponent } from "app/insights/grow-v3/grow-v3-mekko/mekko-builder/mekko-column/mekko-column.component";
import { InvalidSubmarketComponent } from "app/insights/grow-v3/grow-v3-mekko/mekko-builder/mekko-column/invalid-submarket.component";
import { SubMarketFormComponent } from "app/insights/grow-v3/sub-market-form/sub-market-form.component";
import { MekkoFormComponent } from "./grow-v3-mekko-form/grow-v3-mekko-form.component";
import { MarketFormComponent } from "./grow-v3-mekko/mekko-builder/market-form/market-form.component";
import { SegmentsInfoComponent } from "app/snapshots/predictions-level-propensity/segments-info.component";
import { reducer } from "./grow.reducer";
import { GrowV3Service } from "./grow-v3.service";
import { InsightsModule } from "../insights.module";

@NgModule({
  imports: [
    CommonModule,
    NgLetModule,
    DragulaModule,
    HttpClientModule,
    PpcComponentsModule,
    RouterModule,
    StoreModule.forFeature("grow", reducer),
    EffectsModule.forFeature([GrowV3Service]),
    InsightsModule,
  ],
  declarations: [
    GrowV3Component,
    GrowV3MekkoComponent,
    MekkoBuilderComponent,
    MekkoColumnComponent,
    InvalidSubmarketComponent,
    SubMarketFormComponent,
    MekkoFormComponent,
    MarketFormComponent,
  ],
  exports: [GrowV3Component],
})
export class GrowV3Module {}
