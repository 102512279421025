import {combineLatest as observableCombineLatest} from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { flatMap, keyBy, values, filter, reduce, cloneDeep, find, merge } from 'lodash';
import * as moment from 'moment';
import {Actions} from "@ngrx/effects";
import { MatSnackBar } from "@angular/material/snack-bar";

import { AppState } from 'app/reducers';
import { isRegionMarketLevelOnly, OutcomeTimeframe, SubmarketOutcome, newSubmarketOutcome, MarketOutcome, newMarketOutcome, sortByOrder } from '../measure-v3.reducer';
import * as actions from '../measure-v3.actions';
import {fetchOutcome} from "app/shared/utils/fetch-state";
import { SubMarket, Market, selectActiveMekko, selectActiveMekkoSubMarkets } from 'app/mekko/mekko.reducer';

@UntilDestroy()
@Component({
  selector: 'ppc-outcome-timeframe',
  templateUrl: './outcome-timeframe.component.html',
  styleUrls: ['./outcome-timeframe.component.sass']
})
export class OutcomeTimeframeComponent implements OnInit, OnDestroy {
  @Input() outcomeTimeframe: OutcomeTimeframe;
  activeMekko$ = this.store.select("mekkos").pipe(select(selectActiveMekko));
  subMarkets: {[subMarketId: number]: SubMarket};
  markets: {[marketId: number]: Market};
  expandedMarkets: {[marketId: number]: boolean} = {};
  expanded = false;
  submarketsMap: {[marketId: number]: {[subMarketId: number]: SubmarketOutcome}}
  marketMap: {[marketId: number]: MarketOutcome}
  editMode = false;
  marketLevelOnly$ = this.store.select("measureV3").pipe(select(isRegionMarketLevelOnly));
  canDestroyOutcomes$ = this.store.select('permissions', 'outcome_timeframes', 'destroy');
  backup: OutcomeTimeframe;

  constructor(private store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar) {
    observableCombineLatest([
      this.activeMekko$.pipe(map(activeMekko => keyBy(activeMekko.markets, "id"))),
      this.store.select("mekkos").pipe(select(selectActiveMekkoSubMarkets))
    ]).pipe(untilDestroyed(this))
      .subscribe(([markets, subMarkets]) => {
        this.markets = sortByOrder(markets);
        this.subMarkets = sortByOrder(keyBy(subMarkets, "id"));
      })
  }

  ngOnChanges() {
    const newSubmarketOutcomes = filter(this.subMarkets, subMarket => {
      return !find(this.outcomeTimeframe.submarket_outcomes, {sub_market_id: subMarket.id})
    }).map(newSubmarketOutcome);

    const newMarketOutcomes = filter(this.markets, market => {
      return !find(this.outcomeTimeframe.market_outcomes, {market_id: market.id})
    }).map(newMarketOutcome)

    this.outcomeTimeframe.submarket_outcomes = this.outcomeTimeframe.submarket_outcomes.concat(newSubmarketOutcomes)
    this.outcomeTimeframe.market_outcomes = this.outcomeTimeframe.market_outcomes.concat(newMarketOutcomes);

    this.submarketsMap = reduce(this.markets, (submarketsMap, market) => {
      submarketsMap[market.id] = keyBy(filter(this.outcomeTimeframe.submarket_outcomes, { market_id: market.id }), "sub_market_id")
      return submarketsMap
    }, {})

    this.marketMap = keyBy(this.outcomeTimeframe.market_outcomes, "market_id");
  }

  ngOnInit() {
    this.backup = cloneDeep(this.outcomeTimeframe);
  }

  ngOnDestroy() {}

  save() {
    this.store.dispatch(new actions.SaveOutcomeTimeframe(this.outcomeTimeframe))
    this.actions$.pipe((fetchOutcome(actions.SaveOutcomeTimeframe.type)),
      take(1), )
      .subscribe(
        () => this.editMode = false,
        () => this.snackbar.open("Something went wrong saving this outcome. Please refresh the page and try again.", "OK", {
          panelClass: ["danger"],
          duration: 6000
        })
      )
  }

  cancel() {
    this.editMode = false;
    merge(this.outcomeTimeframe, this.backup);
  }

  getSubMarketsForMarket(market: Market) {
    return filter(values(this.subMarkets), { market_id: market.id })
  }

  destroy() {
    this.store.dispatch(new actions.DestroyOutcomeTimeframe(this.outcomeTimeframe));
  }

  get month() {
    return moment().month(this.outcomeTimeframe.month).format("MMMM")
  }

  get marketsArray() {
    return values(this.markets);
  }

}
