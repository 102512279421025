<div class="client-container">
  <ng-template #conversantIdTooltip>
    <div class="conversant-id-tooltip">
      <div class="name">
        Conversant Company Id
      </div>
      <div class="description">
        <div>A value set here may be overridden by a value set lower in the hierarchy if that id's hierarchy context is passed to PVE</div>
      </div>
    </div>
  </ng-template>
  <div *ngIf="isEditing" class="edit-container" [formGroup]="clientForm">
    <i class="fa fa-close" title="Cancel" (click)="cancelEdit.emit()"></i>
    <div class="flex">
      <ppc-input [minLength]="2"
                 #name
                 formControlName="name"
                 [(ngModel)]="client.name"
                 errorMessage="Please supply a name at least 2 characters long">
        <label>Name</label>
        <span suffix class="required-suffix" *ngIf="!name.value">*</span>
      </ppc-input>
      <ppc-input #slug [disabled]="true" [value]="client.slug">
        <label>Slug</label>
      </ppc-input>

      <ppc-input [disabled]="true" [value]="regionNamesList">
        <label>Regions</label>
      </ppc-input>
    </div>
    <div class="flex">
      <div class="flex">
        <ppc-input #contact_email
          type="email"
          formControlName="contact_email"
          [(ngModel)]="client.contact_email"
          [validators]="clientEmailInputValidators">
          <label>Contact Email</label>
        </ppc-input>
      </div>
      <div class="flex">
        <div class="flex conversant-id-container">
          <ppc-input #conversant_id
            type="number"
            formControlName="conversant_id"
            [min]="CONVERSANT_COMPANY_ID_MIN"
            [max]="CONVERSANT_COMPANY_ID_MAX"
            [(ngModel)]="client.conversant_company_id"
            [validators]="conversantIdInputValidators"
            showErrorsIfUntouched=true>
            <label>Conversant Company Id</label>
          </ppc-input>
        </div>
        <div class="flex">
          <img src="assets/icons/question-circle.svg"
            class="conversant-id-helper-icon"
            ppcTooltip [tooltipTemplate]="conversantIdTooltip"/>
        </div>
      </div>
    </div>

    <div class="flex">
      <div class="left-column">
        <div class="block-label">Tab Access</div>
        <div *ngIf="isTier3" class="warning">
          The Audiences module will not appear for regions labeled one-to-many
        </div>
        <ppc-client-feature-access [clientId]="client.id"></ppc-client-feature-access>
      </div>
      <div class="flex right-column">
        <div class="insights-demographics block" *ngIf="isEmergingMarketAndNotMarketLevelOnly">
          <div class="block-label">
            Industry Vertical(s)
          </div>
          <mat-select [multiple]="true" [(ngModel)]="client.emerging_market_verticals" placeholder="Select Industry Vertical(s)" formControlName="emerging_market_verticals">
            <mat-option *ngFor="let clientVertical of clientVerticals" [value]="clientVertical.value">{{clientVertical.display}}</mat-option>
          </mat-select>
        </div>
        <div class="curves block">
          <div class="block-label">Curve Label&nbsp;is&nbsp;
            <span *ngIf="client.curve_label_override">
              <del>Reach</del>&nbsp;<b>Penetration</b>
            </span>
            <b *ngIf="!client.curve_label_override">Reach</b>
          </div>
          <ppc-checkbox
            formControlName="curve_label_override"
            [(ngModel)]="client.curve_label_override"
            >
            Overrid<span *ngIf="client.curve_label_override">den.</span><span *ngIf="!client.curve_label_override">e?</span>
          </ppc-checkbox>
        </div>
        <div class="id-space block" [class.edit-id-space]="client.id" ppcRadioGroup
             [(ngModel)]="client.id_space" [ngModelOptions]="{standalone: true}">
          <div class="block-label">ID Space</div>
          <ppc-radio-button *ngFor="let idSpace of clientIdSpaces"
            [value]="idSpace.value"
            >
            {{idSpace.display}}
          </ppc-radio-button>
        </div>
        <div class="client-level block">
          <div class="block-label">Level</div>
          <ppc-radio-button *ngFor="let level of clientLevels"
            [value]="level.value"
            [isSelected]="level.value == client.visibility_level"
            (selected)="selectClientLevel($event)"
            >
            {{level.display}}
          </ppc-radio-button>
        </div>
        <div
          class="block">
          <div class="block-label">Status</div>
          <div>
            <p *ngIf="client.active">Activated</p>
            <p *ngIf="!client.active">Deactivated</p>
            <div *ngIf="client.active;else inactive"></div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-save" title="Apply" (click)="saveClient($event)" [disabled]="!clientForm.valid">
      Apply
    </button>
  </div>

  <div *ngIf="!isEditing" class="display-container layout horizontal center-center justified-around">
    <div class="collapsed-client-container flex-1">
      <div class="flex-1">
        <div class="block-label">Client Name</div>
        <div>{{client.name}}</div>
      </div>
      <div class="flex-1">
        <div class="block-label">Slug</div>
        <div>{{client.slug}}</div>
      </div>
      <div class="region-list flex-1">
        <div class="block-label">Regions</div>
        <div>{{regionNamesList}}</div>
      </div>
      <div class="flex-1 collapsed-conversant-id-container">
        <div *ngIf="client.conversant_company_id">
          <div class="block-label">Conversant Company Id</div>
          <div>{{client.conversant_company_id}}</div>
        </div>
        <img *ngIf="client.conversant_company_id" src="assets/icons/question-circle.svg"
          class="helper-icon" ppcTooltip [tooltipTemplate]="conversantIdTooltip"/>
      </div>
    </div>
    <div>
      <ng-container *ngIf="canDeactivateClient$ | async">
        <app-confirmation
          *ngIf="client.active;else inactive"
          [modalTitle]="'Are you sure you want to deactivate ' + client.name + '?'"
          [modalContent]="''"
          [isButton]="true"
          [buttonClass]="'btn activation'"
          [buttonLabel]="'Deactivate'"
          [buttonContinueLabel]="'Deactivate'"
          [kontinue]="toActivate.bind(this)"
        ></app-confirmation>
      </ng-container>
      <button class="btn" title="Edit" (click)="beginEdit($event)">
        Edit
      </button>
    </div>
  </div>
  <ng-template #inactive>
    <app-confirmation
      [modalTitle]="'Are you sure you want to activate ' + client.name + '?'"
      [modalContent]="''"
      [isButton]="true"
      [buttonClass]="'btn activation'"
      [buttonLabel]="'Activate'"
      [buttonContinueLabel]="'Activate'"
      [kontinue]="toActivate.bind(this)"
      ></app-confirmation>
  </ng-template>
</div>
