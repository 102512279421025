import { Component, Input, OnDestroy } from '@angular/core';
import { Store, select } from "@ngrx/store";
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {AppState} from "app/reducers";
import { Router } from "@angular/router";
import { filter as _filter } from 'lodash';
import { AudienceV2 } from '../../../audiences-v2/audience-v2.model';
import { compareKey } from 'app/shared/utils/utils';
import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import { FetchOverviewAudiences } from 'app/audiences-v2/audience-v2.actions';
import { isLoaded } from 'app/shared/utils/fetch-state';
import { selectAudiencesWithType } from 'app/audiences-v2/audience-v2.reducers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FetchAllDataPermissions } from 'app/data_permissions/data_permissions.actions';
import { FetchOutcomes } from 'app/outcomes-v1/outcomes-v1.actions';
import { selectOutcomesWithType } from 'app/outcomes-v1/outcomes-v1.reducers';
import { OutcomeV1 } from 'app/outcomes-v1/outcomes-v1.model';
import { FetchOverviewLookalikes } from 'app/lookalikes-v3/lookalike-v3.actions';
import { selectLookalikesWithType } from 'app/lookalikes-v3/lookalike-v3.reducers';

@UntilDestroy()
@Component({
  selector: 'ppc-audiences-box',
  templateUrl: './audiences-box.component.html',
  styleUrls: ['../../home.component.sass']
})
export class AudiencesBoxComponent implements OnDestroy {
  @Input() hierarchyPath: string;
  @Input() isFullContext: boolean;

  rows: (AudienceV2 | LookalikeV3 | OutcomeV1)[] = [];
  selectOptions = [{label: "Last 5", value: 5}, {label: "Last 10", value: 10}];
  tableFilterLength$ = new BehaviorSubject(5);

  constructor(private store: Store<AppState>, private router: Router) {
    combineLatest(
      store.select('fetchStates', FetchOverviewAudiences.type).pipe(select(isLoaded)),
      store.select('audiencesV2').pipe(select(selectAudiencesWithType)),
      store.select('fetchStates', FetchOverviewLookalikes.type).pipe(select(isLoaded)),
      store.select('lookalikesV3').pipe(select(selectLookalikesWithType)),
      store.select('fetchStates', FetchOutcomes.type).pipe(select(isLoaded)),
      store.select('outcomesV1').pipe(select(selectOutcomesWithType)),
      this.tableFilterLength$
    ).pipe(
      map(
        ([audiencesLoaded, audiences, lookalikesLoaded, lookalikes, outcomesLoaded, outcomes, filter]) => {
          const rowInfo = [...audiences, ...lookalikes, ..._filter(outcomes, ['job_status', 'complete'])];
          const loaded = audiencesLoaded && lookalikesLoaded && outcomesLoaded;

          if (loaded) {
            const identifiers = rowInfo.map(i => i['identifier']).filter(id => id !== null);
            if (identifiers.length !== 0) { store.dispatch(new FetchAllDataPermissions(identifiers)); }
          }

          return rowInfo.sort(compareKey('created_at', false)).slice(0, filter);
        }
      ),
      untilDestroyed(this)
    ).subscribe(rows => {
      this.rows = rows
    });
  }

  ngOnDestroy() {
  }

  filterLengthSelect(value) {
    this.tableFilterLength$.next(value);
  }

  navigateToAudiences() {
    this.router.navigate([this.hierarchyPath, 'audiences', 'overview'])
  }
}
