import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wrapParenthesis'
})
export class WrapParenthesisPipe implements PipeTransform {

  transform(value: string): string {
    let output = '';
    if (value !== undefined && value !== null && value !== '') {
      output = `(${value})`;
    }
    return output;
  }

}
