import { Component, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { AudienceV2 } from 'app/audiences-v2/audience-v2.model';
import { jobStatusClass,
  jobStatusDisplay,
  displayTvPanelists,
  canEstimatePanelists,
  getTvPanelistTooltip,
  expiredDate } from 'app/audiences/shared/audience.utils';
import { tooltipMessageV2 } from 'app/audiences/shared/audience.constants';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, BehaviorSubject, Observable } from 'rxjs';
import { filter as _filter, sortBy, map as _map, get } from 'lodash';
import { getPermissionDisplayName } from 'app/services/data_permissions.service';
import { Barbs } from 'app/insights/grow-v3/grow.reducer';
import { fullContext } from 'app/hierarchy/hierarchy.reducers';
import { filter } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { isPeopleCountEstimated,
  hideTVPanelistColumn } from '../../shared/audience.utils';
import { isDefined } from 'app/shared/utils/utils';
import {TOOLTIP_ZERO_COUNT} from "../../../audience-builder/audience-builder.utils";
import { AddressableCountMetadataService } from "../../../models/addressable-count-metadata/addressable-count-metadata.service";
import { AccordianTemplate, CUSTOM_AUDIENCE_CREATION } from '../overview-v3.service';

@UntilDestroy()
@Component({
  selector: 'ppc-audience-detail',
  templateUrl: './audience-detail.component.html',
  styleUrls: ['./audience-detail.component.sass']
})

export class AudienceDetailComponent implements OnDestroy {
  @Input() public set audience(value: AudienceV2) {
    if (value !== this.audience) {
      this.audienceSubject.next(value);
    }
  }
  @Input() panelistDataSourceMessage;
  @Input() regionIsNotAustralia;
  @Input() canDisplayAddressableCounts;
  @Output() onClose = new EventEmitter();
  @Output() openActivationOverlay = new EventEmitter<AudienceV2>();
  @Output() openIndexReportForm = new EventEmitter<AudienceV2>();

  zeroCountWarningActive: boolean = false;
  warningText = TOOLTIP_ZERO_COUNT;
  initialStep: number = 0;
  resetAccordionState: boolean = false;
  expiredDate = expiredDate;

  public get audience(): AudienceV2 {
    return this.audienceSubject.getValue();
  }

  audience$: Observable<AudienceV2>;
  private audienceSubject = new BehaviorSubject<AudienceV2>(undefined);

  jobStatusClass = jobStatusClass;
  jobStatusDisplay = jobStatusDisplay;
  displayPermissions: object[];
  tooltipMessages = tooltipMessageV2;
  panelistConstants: Barbs;
  canEstimate: boolean = false;
  activeRegion: string;
  isPeopleCountEstimated = isPeopleCountEstimated;
  hideTVPanelistColumn = hideTVPanelistColumn;
  accordionData: Array<AccordianTemplate> = [{
    title: "Status",
    type: "status",
    templateName: 'statusTemplate'
  }, {
    title: "Permissions",
    type: "permissions",
    templateName: 'permissionsTemplate'
  }];

  constructor(
    private store: Store<AppState>,
    private decimalPipe: DecimalPipe,
    private addressableCountsService: AddressableCountMetadataService
  ) {
    this.audience$ = this.audienceSubject.asObservable();

    this.audience$.pipe(
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(audience => {
      this.displayPermissions = this.setDisplayPermissions(audience.permissions)
    })

    combineLatest(
      fullContext(store),
      store.select('grow', 'barbs')
    ).pipe(
      untilDestroyed(this)
    ).subscribe(([context, panelistConstants]) => {
      this.activeRegion = get(context, ['region', 'slug']);
      this.panelistConstants = panelistConstants;
      this.canEstimate = canEstimatePanelists(this.panelistConstants, this.activeRegion);
    });
  }

  ngOnDestroy() {}

  addressableCountDisplay() {
    const peopleCount = get(this.audience, ['count', 'people', 'global'], 0)
    return this.addressableCountsService.displayCalculatedAddressableCounts(peopleCount)
  }

  get panelistCount(): string {
    return displayTvPanelists(
      this.audience,
      this.canEstimate,
      this.panelistConstants,
      this.decimalPipe
    );
  }

  get tvPanelistTooltip(): string {
    return getTvPanelistTooltip(this.audience, this.canEstimate);
  }

  get panelistTooltipDisabled(): boolean {
    return !!get(this.audience, ['panelist_count']);
  }

  setDisplayPermissions(permissions): object[] {
    return _filter(sortBy(_map(Object.keys(permissions), (permissionKey) => {
      return { displayName: getPermissionDisplayName(permissionKey), isPermissible: permissions[permissionKey] }
    }), 'displayName'), permission => permission.displayName && !permission.displayName.includes(CUSTOM_AUDIENCE_CREATION));
  }

  hasZeroCount() {
    this.zeroCountWarningActive = true;
  }

  closeWarning() {
    this.zeroCountWarningActive = false;
  }

  triggerAccordionReset() {
    this.resetAccordionState = !this.resetAccordionState;
  }

  closeSelf() {
    this.onClose.emit();
    this.closeWarning();
    this.triggerAccordionReset();
  }
}
