import {combineLatest, Observable} from 'rxjs';
import {filter as rxFilter, map as rxMap } from 'rxjs/operators';
import {ContextSlugs, HierarchyContext} from 'app/hierarchy/hierarchy.interface';
import {Component, OnDestroy} from "@angular/core";
import {select, Store} from "@ngrx/store"
import {NavigationEnd} from "@angular/router"
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {AppState} from "app/reducers";
import {activeContext} from "app/hierarchy/hierarchy.reducers";
import {Router} from "@angular/router";
import {getPath} from "app/hierarchy/hierarchy.utils";
import {toProperty, getStoreState} from 'app/shared/utils/utils'
import {chain, filter, intersection, map, reject, values, get, find} from 'lodash';
import {NAV_ORDER} from "app/feature-access/feature-access.reducers";
import {Unit} from "app/toolbox/unit.interface";
import {accessibleFeatures} from "app/feature-access/feature-access.reducers";
import {UrlService} from 'app/services/url.service';
import { IndexReportTask } from 'app/report-tasks/report-tasks.model';
import { ReportTaskService } from 'app/report-tasks/report-tasks.service';

const NAV_ICONS = {
  insights: 'eye',
  audiences: 'user-o',
  plans: 'handshake-o',
  outcomes: 'line-chart',
  creative: 'lightbulb-o',
  toolbox: 'wrench',
  analytics: 'magic',
  home: 'home'
};

@UntilDestroy()
@Component({
  selector: 'app-left-hand-nav',
  templateUrl: './left-hand-nav.component.html',
  styleUrls: [ './left-hand-nav.component.sass' ],
})
export class LeftHandNavComponent implements OnDestroy {

  hierarchySlug: string;
  activeCategory: string;
  activeFeature: string;
  selectedCategory: string;
  contextSlugs: ContextSlugs;
  expanded = false;
  navHoverIdx = -1;
  showNotificationCenter = false;
  tasks: IndexReportTask[] = [];

  regionId$ = activeContext(this.store).pipe(rxMap(({ region }) => region && region.id),
    rxFilter(Boolean))

  hierarchyPath$ = activeContext(this.store).pipe(
    rxMap((context: HierarchyContext) => getPath(context)),
    toProperty())

  units$: Observable<{[category: string]: Unit[]}> = combineLatest(
    this.store.select('toolbox', 'units').pipe(select(values)),
    this.regionId$,
    activeContext(this.store)
  ).pipe(
    rxMap(([ units, regionId, context ]) =>
      chain(units)
        .filter(u => u.status && !!intersection(u.region_ids, ['all', regionId + '']).length) // need to cast regionId to string
        .filter(u => !u.use_client_hierarchy || !!context.product)
        .sortBy(u => u.name.toLowerCase())
        .groupBy('feature_module_category')
        .value()))

  features$ = combineLatest(accessibleFeatures(this.store), activeContext(this.store)).pipe(
    rxMap(([ features, context ]) => context.product ? features : [])
  );

  clientNavOptions$ = combineLatest(this.features$, this.units$).pipe(
    rxMap(([ features, unitsByCategory ]) =>
      NAV_ORDER
        .map(category => ({
          category,
          icon: NAV_ICONS[category],
          features: map(filter(features, {category}), 'name'),
          iFramedUnits: filter(unitsByCategory[category] || [], 'iframed'),
          externalUnits: reject(unitsByCategory[category] || [], 'iframed')
        }))
        .filter(nav => !!(nav.features.length || nav.iFramedUnits.length || nav.externalUnits.length))))

  constructor(private store: Store<AppState>,
    private router: Router,
    private urlService: UrlService,
    public reportTaskService: ReportTaskService,
  ) {
    this.router.events.pipe(
      rxFilter(e => e instanceof NavigationEnd && this.showNotificationCenter),
      untilDestroyed(this),
    ).subscribe(() => this.showNotificationCenter = false);

    this.hierarchyPath$.pipe(untilDestroyed(this))
      .subscribe(hierarchyPath => this.hierarchySlug = hierarchyPath)

    combineLatest(this.hierarchyPath$, urlService.currentUrl$).pipe(untilDestroyed(this))
      .subscribe(
        ([contextPath, url]) => {
          const relativePath = url.replace(contextPath, '');
          [this.activeCategory, this.activeFeature] = relativePath.split("/").filter(e => !!e)
        }
      );
  }

  onCategoryClick(nav) {
    this.selectedCategory = nav.category;
    this.activeCategory = nav.category;

    if (this.expanded) { return };

    if (nav.features[0]) {
      this.activeFeature = nav.features[0];
      this.router.navigate([this.hierarchySlug, nav.category, nav.features[0]])
    } else if (nav.iFramedUnits[0]) {
      this.router.navigate([this.hierarchySlug, 'toolbox-app', nav.iFramedUnits[0].name])
    } else if (nav.externalUnits[0]) {
      window.open(nav.externalUnits[0].url, '_blank')
    }
  }

  onFeatureClick(nav, feature) {
    this.activeCategory = nav.category;
    this.activeFeature = feature;
    this.router.navigate([this.hierarchySlug, nav.category, feature]);
  }

  homeClick() {
    this.activeFeature = null;
    this.activeCategory = null;
    this.router.navigate([this.hierarchySlug, 'home'])
  }

  getFaClassName(iconName: string) {
    return iconName === 'lightbulb-o' ? 'fa-2x' : 'fa-lg';
  }

  onNavHover(i: number) {
    this.navHoverIdx = i;
  }

  ngOnDestroy() { }

  toggleNotificationCenter() {
    this.showNotificationCenter = !this.showNotificationCenter;
  }

  getDisplayName(feature: string): string {
    return feature.split('-').join(' ');
  }
}
