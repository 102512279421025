
import {combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';

import {filter, map, takeUntil } from 'rxjs/operators';

import {Component, OnDestroy, OnInit} from "@angular/core";
import {Store, select} from "@ngrx/store";
import {
  Competitor,
  Creative,
  ModelType,
  modelTypes,
  selectActiveCompetitor,
  selectAvgAttributeScoresForActiveCompetitor,
  selectClientCompetitor,
  selectClientCompetitorId,
  selectCompetitors,
  selectCreativesByCompetitor,
  selectNonClientCompetitors
} from "../creative.reducers";
import {AppState} from "app/reducers";
import {
  ChangeCompetitor,
  ChangeModelType,
  FetchCompetitors,
  FetchCreativeAttributes,
  ImportCreatives,
  FetchCreatives
} from "../creative.actions";
import {brandColor} from "../creative.component";
import {fullContext} from "app/hierarchy/hierarchy.reducers";
import {isLoaded, isNotYetFetched} from "app/shared/utils/fetch-state";
import {ScoredAttribute} from "app/creative/creative-attribute-list/creative-attribute-list.component";
import * as chroma from "chroma-js";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, ofType } from '@ngrx/effects';
import {get} from "lodash";
import {ProcessStatus} from "app/shared/interfaces/process-status.interface";
import {CreativeService} from "app/services/creative.service";
import {Product} from "app/brand-admin/brand/product/product.model";

const dataLoadingActions = [FetchCreatives, FetchCompetitors, FetchCreativeAttributes];

@Component({
  selector: 'app-asset-setup',
  templateUrl: './asset-setup.component.html',
  styleUrls: ['./asset-setup.component.sass']
})
export class AssetSetupComponent implements OnInit, OnDestroy {
  activeCompetitor: Competitor;
  creatives: { [competitorId: string]: Creative[] };
  clientCompetitor: Competitor;
  nonClientCompetitorsArray: Competitor[];
  genomeHasValidScores$: Observable<boolean>;
  isDataLoaded$: Observable<boolean>;
  selectedCreative: Creative;

  ngUnsubscribe: Subject<boolean> = new Subject();

  product: Product;
  importStatus: ProcessStatus;

  scoredAttributes$: Observable<ScoredAttribute[]>;
  modelType$: Observable<ModelType>;
  modelTypes = modelTypes;

  private pollId;

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private service: CreativeService,
    private snackbar: MatSnackBar
  ) {
    dataLoadingActions.forEach(
      action => this.store.select('fetchStates', action.type).pipe(
        filter(isNotYetFetched),
        takeUntil(this.ngUnsubscribe), )
        .subscribe(() => this.store.dispatch(new action())));

    this.modelType$ = this.store.select('creative', 'attrModelType')
  }

  ngOnInit() {
    observableCombineLatest(
      fullContext(this.store),
      this.store.select('creative').pipe(map(selectCompetitors)),
      this.store.select('creative').pipe(map(selectClientCompetitor)),
      this.store.select('creative').pipe(map(selectNonClientCompetitors)),
      this.store.select('creative').pipe(map(selectActiveCompetitor)),
      this.store.select('creative').pipe(map(selectCreativesByCompetitor))
    ).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(([context, competitors, clientCompetitor, nonClientCompetitors, activeCompetitor, creatives]) => {
        if (context.product && (context.product.id !== this.productId)) {
          this.product = new Product(context.product);
          this.fetchImportStatus();
        }
        this.clientCompetitor = clientCompetitor;
        this.nonClientCompetitorsArray = nonClientCompetitors;
        this.creatives = creatives;
        this.activeCompetitor = activeCompetitor;
      });

    observableCombineLatest(
      this.store.select('creative').pipe(map(selectActiveCompetitor)),
      this.store.select('creative').pipe(map(selectClientCompetitorId))
    ).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(([activeCompetitor, clientCompetitorId]) =>
        !activeCompetitor && clientCompetitorId && this.store.dispatch(new ChangeCompetitor(clientCompetitorId)
        ));

    this.genomeHasValidScores$ = this.store.select('creative').pipe(
      select(selectAvgAttributeScoresForActiveCompetitor),
      select(attrScores => attrScores.some(attr => !!attr.score)),
    );

    this.isDataLoaded$ = this.store.select('fetchStates').pipe(select(
      fetchStates => dataLoadingActions.every(action => isLoaded(fetchStates[action.type]))));

    this.scoredAttributes$ = this.store.select('creative').pipe(
      select(selectAvgAttributeScoresForActiveCompetitor),
      select(attrs => attrs.map(
        attr => ({
          ...attr,
          scores: [{
            color: chroma(brandColor).alpha(Math.max(.3, attr.score)).css(),
            val: attr.score
          }]
        }))),
    )


    this.actions$.pipe(
      ofType(ImportCreatives.type),
      takeUntil(this.ngUnsubscribe))
      .subscribe( _ => this.startImportStatusPoll() );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.stopImportStatusPoll();
  }

  updateActiveCompetitor(e) {
    if (e.value.id !== this.activeCompetitor.id) {
      this.store.dispatch(new ChangeCompetitor(e.value.id))
    }
  }

  importAssets() {
    if (this.canImport) {
      this.store.dispatch(new ImportCreatives());
      this.snackbar.open(`starting asset import`, "OK", {duration: 3000, panelClass: ['check']});
      this.importStatus = {
        type: 'creative_asset_import',
        running: true,
        started: true,
        success: false
      };
    }
  }

  get hasCreatives(): boolean {
    return this.activeCompetitor ? get(this.creatives, [this.activeCompetitor.id, 'length'], 0) > 0 : false
  }

  changeModelType(modelType: ModelType) {
    return this.store.dispatch(new ChangeModelType(modelType))
  }

  get canImport(): boolean {
    return !this.importStatus || !this.importStatus.running
  }

  get productId(): string {
    return this.product ? this.product.id : null;
  }

  private startImportStatusPoll() {
    this.stopImportStatusPoll(); // don't let two instances run at the same time

    this.pollId = setInterval(_ => {
      this.fetchImportStatus(true);
    }, 10000);
  }

  private stopImportStatusPoll() {
    if (this.pollId) {
      clearInterval(this.pollId);
    }
  }

  private fetchImportStatus(fetchCreatives = false) {
    this.service.getCreativeImportStatus().subscribe(importStatus => {
      this.importStatus = importStatus;
      if (importStatus && !importStatus.running && this.pollId) {
        this.stopImportStatusPoll();
        if (importStatus.success) {
          this.snackbar.open(`Import finished`, "OK", {duration: 3000, panelClass: ['check']});
          fetchCreatives && this.store.dispatch(new FetchCreatives());
        } else {
          this.snackbar.open(`Import Failed: ${importStatus.process_errors.join(". ")}`, "OK", {duration: 3000, panelClass: ['danger']});
        }
      } else if (importStatus && !this.pollId) {
        // import is running, and we're not polling, so start polling
        this.startImportStatusPoll();
      }
    });
  }
}
