import { Component, Input, EventEmitter, Output, OnInit, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ZeroCountInfoComponent} from "../../../zero-counts/zero-count-info/zero-count-info.component";

@Component({
  selector: 'ppc-error',
  templateUrl: './ppc-error.component.html',
  styleUrls: ['./ppc-error.component.sass']
})

export class PpcErrorComponent implements OnChanges {
  @Input() visible: boolean;
  @Input() topLevelMessage: string;
  @Input() errorMessages: string[] = [];
  private defaultZeroCount = false;
  @Output() closeAlert = new EventEmitter();
  @Input() isZeroCount: boolean;
  @Input() audienceCopy: boolean = false;

  constructor(public dialog: MatDialog) {}

  closeNotification() {
  	this.closeAlert.emit();
  }

  openDialog() {
    this.dialog.open(ZeroCountInfoComponent, { panelClass: 'no-padding', data: { audienceCopy: this.audienceCopy } })
  }

  ngOnChanges(): void {
    this.isZeroCount = this.defaultZeroCount || this.isZeroCount;
  }

}
