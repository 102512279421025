import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ZeroCountInfoComponent } from 'app/zero-counts/zero-count-info/zero-count-info.component'

@Component({
  selector: 'ppc-zero-count-warning-icon',
  templateUrl: './zero-count-warning-icon.component.html',
  styleUrls: ['./zero-count-warning-icon.component.sass']
})
export class ZeroCountWarningIconComponent {
  @Input() skewed: boolean = false;
  @Input() shift: string = null;
  @Input() flipped: boolean = false;
  @Input() audienceCopy: boolean = false;

  constructor(public dialog: MatDialog) { }

  openDialog() {
    this.dialog.open(ZeroCountInfoComponent, { panelClass: 'no-padding', data: {audienceCopy: this.audienceCopy} })
  }
}
