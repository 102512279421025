<ul class="list">
  <li *ngFor="let entry of featureModules$ | async">
    <div>
      <div>
        <ppc-checkbox
          [stateless]="true"
          [checked]="isCategoryChecked(entry[0])"
          [indeterminate]="isCategoryIndeterminate(entry[0])"
          (change)="toggleCategory(entry[0])"
        >
          {{entry[0]}}
        </ppc-checkbox>
      </div>
      <div *ngFor="let feature of entry[1]" class="category-features">
        <span class="arrow">&#x21b0;</span>
        <ppc-checkbox
          [stateless]="true"
          [checked]="accessibleFeatureIds.has(feature.id)"
          (change)="toggleAccess(feature.id)"
        >
          {{feature.name}}
        </ppc-checkbox>
      </div>
    </div>
  </li>
</ul>
