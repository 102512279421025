import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivationManagementService } from './activation-management.service';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { ActivationManagementComponent } from './activation-management.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [CommonModule, PpcComponentsModule, MatSelectModule],
  declarations: [ActivationManagementComponent],
  exports: [ActivationManagementComponent],
  providers: [ActivationManagementService],
})
export class ActivationManagementModule {}
