<main>
  <div *ngIf="showESCounts">
    <mat-expansion-panel (opened)="esCountPanelOpenState = true"
                         (closed)="esCountPanelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>ES Statistics</mat-panel-title>
      </mat-expansion-panel-header>

      <div>
        <table class="splitSpace">
          <thead>
          <tr class="header-row">
            <th></th>
            <th>ENV</th>
            <th>ID Count</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let item of esCounts | keyvalue; let i = index" [attr.data-index]="i">
            <tr >
              <td [style.background-color]="getColor(i)"></td>
              <td ><b>{{item.key}}</b></td>
              <td class="tdNumber"><b>{{ item.value | number }}</b></td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      <svg id="viz_area" height=250 width=1000></svg>

    </mat-expansion-panel>
  </div>
  <br/>
  <div *ngIf="showAllIndexReports">
    <mat-expansion-panel (opened)="allIndexReportPanelOpenState = true"
                         (closed)="allIndexReportPanelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>Index Reports Statistics</mat-panel-title>
      </mat-expansion-panel-header>

      <div>
        <table class="splitSpace">
          <thead>
          <tr class="header-row">
            <th>ENV</th>
            <th>Count</th>
            <th>Count (Excluding internal users)</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let item of uniqEnvs | keyvalue">
            <tr >
              <td ><b>{{item.key}}</b></td>
              <td class="tdNumber"><b>{{ item.value['total'] }}</b></td>
              <td class="tdNumber"><b>{{ item.value['external'] }}</b></td>
            </tr>
          </ng-container>
          <ng-container *ngFor="let item of missingEnvs">
            <tr >
              <td ><b>{{item}}</b></td>
              <td class="tdNumber"><b>0 (Error)</b></td>
              <td class="tdNumber"><b>0 (Error)</b></td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <table class="splitSpace">
          <thead>
          <tr class="header-row">
            <th>Resource Type</th>
            <th>Count (Excluding internal users)</th>
          </tr>
          </thead>
          <tbody *ngFor="let item of uniqTypes | keyvalue">
          <ng-container>
            <tr >
              <td ><b>{{item.key}}</b></td>
              <td class="tdNumber"><b>{{ item.value['total'] }}</b></td>
            </tr>
          </ng-container></tbody>
        </table>

        <table class="addMarginBottom">
          <thead>
          <tr class="header-row">
            <th>User</th>
            <th>Envs</th>
            <th>Count</th>
          </tr>
          </thead>
          <tbody *ngFor="let item of uniqUsers | keyvalue">
          <ng-container>
            <tr >
              <td ><b>{{item.key}}</b></td>
              <td ><b>{{ toCsv(item.value['ens']) }}</b></td>
              <td class="tdNumber"><b>{{ item.value['total'] }}</b></td>
            </tr>
          </ng-container></tbody>
        </table>
      </div>

      <div *ngIf="showPendingIndexReports">
        <mat-expansion-panel (opened)="pendingIndexReportPanelOpenState = true"
                             (closed)="pendingIndexReportPanelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>Details (Incomplete)</mat-panel-title>
          </mat-expansion-panel-header>
          <table>
            <thead>
            <tr class="header-row">
              <th (click)="sortPendingIndexReportTable('resource_type')"></th>
              <th (click)="sortPendingIndexReportTable('env')">Env</th>
              <th (click)="sortPendingIndexReportTable('id')">Id</th>
              <th (click)="sortPendingIndexReportTable('product_slug')">Product Slug</th>
              <th (click)="sortPendingIndexReportTable('resource_type')">Resource Type</th>
              <th (click)="sortPendingIndexReportTable('resource_id')">Resource Id</th>
              <th (click)="sortPendingIndexReportTable('name')">Name</th>
              <th (click)="sortPendingIndexReportTable('request_id')">Request ID</th>
              <th (click)="sortPendingIndexReportTable('status')">Status</th>
              <th (click)="sortPendingIndexReportTable('created_by')">Created By</th>
              <th (click)="sortPendingIndexReportTable('created_at')">Created At</th>
              <th (click)="sortPendingIndexReportTable('pending_since')">Pending Since</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="tasks && tasks.length == 0"><td colspan="12">No records found</td></tr>
            <tr *ngFor="let task of tasks">
              <td><span prefix class="icon-prefix">
                <ppc-icon value="{{task.resource_type == 'persona' ? 'crosshairs' : 'user' }}"></ppc-icon>
              </span>
              </td>
              <td >{{task.env}}</td>
              <td class="idClass">{{task.id}}</td>
              <td>{{task.product_slug}}</td>
              <td>{{task.resource_type}}</td>
              <td>{{task.resource_id}}</td>
              <td>{{task.name}}</td>
              <td>{{task.request_id}}</td>
              <td>{{task.status}}</td>
              <td>{{task.created_by}}</td>
              <td class="dateClass">{{task.created_at}}</td>
              <td>{{task.pending_since}}</td>
            </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </div>
      <br/>

      <div>
        <mat-expansion-panel (opened)="detailIndexReportPanelOpenState = true"
                             (closed)="detailIndexReportPanelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>Details (All)</mat-panel-title>
        </mat-expansion-panel-header>
        <table>
          <thead>
          <tr class="header-row">
            <th (click)="sortIndexReportTable('resource_type')"></th>
            <th (click)="sortIndexReportTable('env')">Env</th>
            <th (click)="sortIndexReportTable('id')">Id</th>
            <th (click)="sortIndexReportTable('product_slug')">Product Slug</th>
            <th (click)="sortIndexReportTable('resource_type')">Resource Type</th>
            <th (click)="sortIndexReportTable('resource_id')">Resource Id</th>
            <th (click)="sortIndexReportTable('name')">Name</th>
            <th (click)="sortIndexReportTable('created_by')">Created By</th>
            <th (click)="sortIndexReportTable('created_at')">Created At</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="allTasks && filteredTasks.length == 0"><td colspan="9">No records found</td></tr>
          <tr *ngFor="let task of filteredTasks">
            <td><span prefix class="icon-prefix">
                <ppc-icon value="{{task.resource_type == 'persona' ? 'crosshairs' : 'user' }}"></ppc-icon>
              </span>
            </td>
            <td >{{task.env}}</td>
            <td class="idClass">{{task.id}}</td>
            <td>{{task.product_slug}}</td>
            <td>{{task.resource_type}}</td>
            <td>{{task.resource_id}}</td>
            <td>{{task.name}}</td>
            <td>{{task.created_by}}</td>
            <td class="dateClass">{{task.created_at}}</td>
          </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
      </div>
    </mat-expansion-panel>
  </div>
  <br/>
  <div *ngIf="showAllClients">
    <p>Note: Table lists clients</p>
    <table>
      <thead>
      <tr class="header-row">
        <th (click)="sortClientsTable('env')">Env</th>
        <th (click)="sortClientsTable('id')">Id</th>
        <th (click)="sortClientsTable('slug')">Slug</th>
        <th (click)="sortClientsTable('slug_full')">Slug Full</th>
        <th (click)="sortClientsTable('name')">Name</th>
        <th (click)="sortClientsTable('active')">Active</th>
        <th (click)="sortClientsTable('contact_email')">Contact Email</th>
        <th (click)="sortClientsTable('visibility_level')">Visibility Level</th>
        <th (click)="sortClientsTable('created_by')">Created By</th>
        <th (click)="sortClientsTable('created_at')">Created At</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="tasks && clients.length == 0"><td colspan="10">No records found</td></tr>
      <tr *ngFor="let task of clients">
        <td >{{task.env}}</td>
        <td class="idClass">{{task.id}}</td>
        <td>{{task.slug}}</td>
        <td>{{task.slug_full}}</td>
        <td>{{task.name}}</td>
        <td>{{task.active}}</td>
        <td>{{task.contact_email}}</td>
        <td>{{task.visibility_level}}</td>
        <td>{{task.created_by}}</td>
        <td class="dateClass">{{task.created_at}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</main>
