import { Component, OnInit, OnDestroy } from '@angular/core';
import { AudiencesAdminService } from './audiences-admin.service';
import { AdminPpcObject, ActivationObject } from './admin-ppc-object.model';
import {combineLatest as observableCombineLatest, Subscription} from "rxjs";
import { jobStatusClass, jobStatusDisplay } from 'app/audiences/shared/audience.utils';
import {sortBy} from "../../shared/utils/utils";
import {SnapshotDetail} from "../../snapshots/snapshots.reducer";
import {User} from "../../users/user.interface";
import {UserService} from "../../users/user.service";
import {HttpClient} from "@angular/common/http";
import { IndexReportTask } from "app/report-tasks/report-tasks.model";
import { map } from 'lodash';
import { pendingTasksAdminUrl } from '../../shared/constants/id_analytics.urls';
import { pendingSnapshotsUrl } from '../../shared/constants/insights.urls';

@Component({
  selector: 'ppc-audiences-admin',
  templateUrl: './audiences-admin.component.html',
  styleUrls: ['./audiences-admin.component.sass']
})
export class AudiencesAdminComponent implements OnInit, OnDestroy {

  actSubscription: Subscription;
  activations: Array<ActivationObject>;
  audiences: Array<AdminPpcObject>;
  lookalikes: Array<AdminPpcObject>;
  unFusedAuds: Array<AdminPpcObject>;
  unFusedLals: Array<AdminPpcObject>;
  concatUnfusedAuds: Array<AdminPpcObject>;
  jobStatusClass = jobStatusClass;
  jobStatusDisplay = jobStatusDisplay;

  snapshots: SnapshotDetail[];
  tasks: IndexReportTask[];
  users: User[];
  sortedBy: string = 'time_diff';

  // mat-panel states
  indexReportPanelOpenState = false;
  propensityPanelOpenState = false;
  pendingFusionAudiencePanelOpenState = false;
  pendingActivationPanelOpenState = false;
  pendingAudiencePanelOpenState = true;
  pendingLookalikePanelOpenState = true;

  statusText = '';

  constructor(
    private audiencesAdminService: AudiencesAdminService,
    private userService: UserService,
    private http: HttpClient,
  ) {
    observableCombineLatest([this.audiencesAdminService.fetchAudiences(),
      this.audiencesAdminService.fetchLaLs()])
      .subscribe(
        ([auds, lals]) => {
          this.audiences = auds.data;
          this.lookalikes = lals.data;
        },
        error => console.log('error fetching pending lookalikes and audiences', error)
      );

    this.actSubscription = this.audiencesAdminService.fetchActivations().subscribe((response) => {
      this.activations = response.data.map((object) => {
        const newObj: ActivationObject = {
          client: object.client,
          destination_names: object.destination_names,
          partner_name: object.partner_name,
          status: object.status,
          hours_in_current_status: object.hours_in_current_status,
          pending_since: object.pending_since,
          color_status: object.color_status,
          identifier: object.identifier,
          email: object.email,
        };

        return newObj
      });
    });


    observableCombineLatest([this.audiencesAdminService.fetchUnfusedLals(),
      this.audiencesAdminService.fetchUnfusedAuds()])
      .subscribe(
        ([lals, auds]) => {
          this.unFusedLals = lals.data;
          this.unFusedAuds = auds.data;
          this.concatUnfusedAuds = this.unFusedLals.concat(this.unFusedAuds)
        },
        error => console.log('error fetching unfused identifiers', error)
      );
  }

  adminJobStatus(value) {
    return `status-${value.color_status}`;
  }

  ngOnInit() {
    observableCombineLatest([this.userService.getAllUsers(),
      this.http.get(pendingSnapshotsUrl())])
      .subscribe(
        ([users, data]: [User[], SnapshotDetail[]]) => {
          this.users = users;
          this.snapshots = sortBy(data['snapshots'], this.sortedBy, false);
        },
        error => console.log('error fetching pending snapshots', error)
      );

    this.http.get(pendingTasksAdminUrl())
      .subscribe(
        (tasks: IndexReportTask[]) => {
          const updatedTasks = map(tasks, task => {
            // show ui_name in list of pending tasks
            task.name = task.ui_name || task.name
            return task;
          });

          this.tasks = sortBy(updatedTasks, this.sortedBy, false);
          this.setIndexReportStatusText();
        },
        error => console.log('error fetching pending index reports', error)
      );
  }

  ngOnDestroy() {
    this.actSubscription.unsubscribe();
  }

  sortTable(fieldName: string) {
    this.snapshots = sortBy(this.snapshots, fieldName, fieldName !== this.sortedBy);
    this.sortedBy = fieldName !== this.sortedBy ? fieldName : '';
  }

  getUserName(user_id) {
    return user_id ? this.users.find(x => x.id === user_id).email : "";
  }

  //TODO: we can do the same for other tables as well in future.
  setIndexReportStatusText() {
    let receivedCount = this.tasks.filter(x => x.status == 'received').length;
    let errorCount = this.tasks.filter(x => x.status == 'error').length;
    let progressCount = this.tasks.filter(x => x.status == 'progress').length;

    this.statusText = 'Received: ' + receivedCount + ' | Error: ' + errorCount;
    this.statusText += progressCount > 0 ? ' | In Progress: ' + progressCount : '';

    return this.statusText;
  }
}
