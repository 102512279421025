import { reduce, capitalize, get, round } from 'lodash';
import { SegmentPermissionService } from "app/admin/segment-permissions/service/segment-permissions.service";
import { TreeNode } from "app/shared/components/tree-browser/tree-node";
import * as permission from "app/admin/segment-permissions/models/segment-permissions.model";
import { AudienceV2, AudienceRule, hasTvPanelists } from 'app/audiences-v2/audience-v2.model';
import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import { Barbs } from 'app/insights/grow-v3/grow.reducer';
import { tooltipMessageV2 as audienceTooltipMessages } from 'app/audiences/shared/audience.constants';
import { DecimalPipe } from '@angular/common';
import * as bodybuilder from 'bodybuilder';
import { OutcomeV1 } from 'app/outcomes-v1/outcomes-v1.model';
import {MotivationV1} from "app/models/motivations/motivations-v1.model";
import { MOLECULA } from 'app/shared/utils/constants';

export type AudienceCountOptions = 'people_count' | 'device_id' | 'cookies';

export function getLeafCount(node: TreeNode, attribute: string): number {
  return +node[attribute] || 0
}

export function combinePathParts(pathParts: string[]): string {
  return pathParts.join(" > ");
}

export function permissionedVendor(vendor, vendorPermissions): boolean {
  let vendorPermission;
  if (vendor === "custom") { return false; }
  if (vendor) { vendorPermission = vendorPermissions.find(vp => vp.vendor_name === vendor); }
  // return false only when vendor permission is specificied and denied
  return vendorPermission ? vendorPermission.isAllowed : true
}

export function fullPath(segment: any): string {
  let output = '';
  const subpath = segment.subPath();
  const regex = new RegExp('Other|Insights|3rd Party|Modeled Audience', 'gi');
  if (regex.test(subpath)) {
    output = segment.subPath(1);
  } else {
    output = segment.subPath(2);
  }
  // Strange lowercase bug in audience overview: adobe/krux
  output = output.charAt(0).toUpperCase() + output.slice(1);
  // Custom Segments
  if (segment.segmentType === 'Custom' && segment.segment.segmentType) {
    // types are snake case by default
    // ex. ninth_decimal => Ninth Decimal, nielsen => Nielsen
    const segmentSegmentSegmentTypeName = segment.segment.segmentType.split('_').map(v => v.charAt(0).toUpperCase() + v.slice(1)).join(' ');
    output = output.replace('Custom', segmentSegmentSegmentTypeName);
  }
  // Liveramp+NCS Exception
  if (segment.segment && segment.segment.segmentType === 'liveramp' && segment.segment.name.includes('NCS')) {
    output = output.replace('Liveramp', 'NCS');
  }
  // LAL Exception
  if (output.startsWith('Xinfer')) {
    output = output.replace('Xinfer > ', '');
  }
  // Ninth_decimal Exception
  if (output.startsWith('Ninth_decimal > NinthDecimal')) {
    output = output.replace('Ninth_decimal > NinthDecimal', 'Ninth Decimal');
  }
  return output;
}

export function jobStatusClass(job_status: string): string {
  return (job_status === "complete") ? "status-complete" : "status-incomplete";
}

export function jobStatusDisplay(job_status: string): string {
  if (job_status === "processing") {
    return "In Progress";
  }
  return capitalize(job_status);
}

export function getTvPanelistTooltip(ppcItem: AudienceV2 | LookalikeV3 | OutcomeV1, canEstimate: boolean): string {
  const peopleCount = get(ppcItem, ['count', 'people', 'global'], 0)
  const option = ((!canEstimate || !peopleCount)
    ? 'unavailable'
    : 'estimated');
  return audienceTooltipMessages[`${option}PanelistCount`];
}

export function calculatePanelists(panelistConstants: Barbs, peopleCount: number): number {
  if (!panelistConstants) {
    throw new Error('Needs panelist constants');
  }

  let output = (panelistConstants.n_pids * peopleCount) + ((panelistConstants.n_pids ** 2) * panelistConstants.n_pids_sqr);
  if (peopleCount > 0) {
    output += panelistConstants.const;
  }

  if (panelistConstants.maximum && output > panelistConstants.maximum) {
    output = panelistConstants.maximum;
  }

  return Math.round(output);
}

export function displayTvPanelists(
  ppcItem: AudienceV2 | LookalikeV3 | OutcomeV1 | MotivationV1,
  canEstimate: boolean,
  panelistConstants: Barbs,
  decimalPipe: DecimalPipe
): string {
  const peopleCount = get(ppcItem, ['count', 'people', 'global'], 0)
  if (!ppcItem) {return ""}
  if (ppcItem.send_to_tardiis && ppcItem.panelist_count) {
    return `${decimalPipe.transform(ppcItem.panelist_count, '1.0-0')}`;
  } else if (!canEstimate || !peopleCount) {
    return "-";
  } else {
    let panelistCount = calculatePanelists(panelistConstants, peopleCount);
    if (panelistCount > 100) {panelistCount = round(panelistCount, -2)}

    return `~${decimalPipe.transform(panelistCount, '1.0-0')}`;
  }
}

export function canEstimatePanelists(panelistConstants: Barbs, region: string): boolean {
  return !!(panelistConstants &&
            panelistConstants.n_pids &&
            panelistConstants.const &&
            panelistEstimateableRegions.includes(region));
}

export const panelistEstimateableRegions: string[] = ["uk", "us", "na", "au"]

export const regionsWithoutFusion: string[] = ["ru"]

export function hideTVPanelistColumn(region): boolean {
  return regionsWithoutFusion.includes(region)
}

export function peopleCountQueryFromAudience(audience: AudienceV2) {
  const query = bodybuilder();
  query.andQuery("bool", b => {
    return reduce(audience.rules.include.and, (query, orGroup: AudienceRule) => {
      return query.andQuery("bool", b => {
        return reduce(orGroup.or as string[], (query, identifier) => {
          return query.orQuery("term", "segments", identifier);
        }, b)
      })
    }, b)
  })
  if (includesASegment(audience.rules.exclude)) {
    query.notQuery("bool", b => {
      return reduce(audience.rules.exclude.and, (query, orGroup: AudienceRule) => {
        return query.andQuery("bool", b => {
          return reduce(orGroup.or as string[], (query, identifier) => {
            return query.orQuery("term", "segments", identifier);
          }, b)
        })
      }, b)
    })
  }
  return query.build();
}

export function moleculaContextFromAudience(audience: AudienceV2) {
  const included = audience.rules.include.and.map(x => x.or);
  const excluded = includesASegment(audience.rules.exclude) ? audience.rules.exclude.and.map(x => x.or) : [];
  const context = {
    included,
    excluded,
    key: audience.id
  }
  return  {
    serviceType: MOLECULA,
    isVendorQuery: false,
    includeIndexValues: false,
    context,
  }
}

function includesASegment(rules: AudienceRule) {
  return (rules.and || rules.or).some((entry: AudienceRule | string) => {
    return (typeof entry === "string" && entry.length) || includesASegment(entry as AudienceRule)
  })
}

export function isPeopleCountEstimated(ppcObject: AudienceV2 | LookalikeV3 | MotivationV1) {
  return ppcObject.job_status !== "complete";
}

export function displayPeopleCountEstimate(count: number | null) {
  if (count || count === 0) {
    if (count > 100) {count = round(count, -2)}
    return `~${Math.round(count).toLocaleString()}`;
  } else {
    return "-"
  }
}

export function displaySegmentCount(ppcItem: any): string {

  if (ppcItem.type !== 'audience') { return '-'; }

  const uniqueSegments = new Set<string>();
  const rules = ppcItem.rules;
  const addSubsequentSegments = (segments: { or: string[]; }) => {
    const subsequentSegments = segments?.or;
    subsequentSegments.forEach(segment => uniqueSegments.add(segment));
  };

  const excludedSegments = rules?.exclude?.and || [];
  excludedSegments.forEach(addSubsequentSegments);

  const includedSegments = rules?.include?.and || [];
  includedSegments.forEach(addSubsequentSegments);

  return uniqueSegments.size.toLocaleString();
}

const addressableCountsFormulas = {
  exponential: calculateExponentialAddressableCounts,
  linear: calculateLinearAddressableCounts
}

export function canDisplayAddressableCounts(metaData): boolean {
  if (!metaData) { return false }
  if (metaData.coefficient_1 === null || metaData.coefficient_1 === undefined) { return false }
  if (metaData.coefficient_2 === null || metaData.coefficient_2 === undefined) { return false }

  const formula = addressableCountsFormulas[metaData.formula_name]

  if (!formula) { return false }

  return true
}

export function addressableCountsFormulaSelector(formulaName) {
  return addressableCountsFormulas[formulaName]
}

export function calculateExponentialAddressableCounts(metaData, pids: number): number {
  if (!metaData) { return NaN }
  if (metaData.coefficient_1 === null || metaData.coefficient_2 === null) { return NaN }

  return metaData.coefficient_1 * (1 - Math.exp(-metaData.coefficient_2 * pids))
}

export function calculateLinearAddressableCounts(metaData, pids: number): number {
  if (!metaData) { return NaN }
  if (metaData.coefficient_1 === null || metaData.constant === null) { return NaN }

  return (metaData.coefficient_1 * pids) + metaData.constant
}

export function displayAddressableCounts(addressableCounts: number): string {
  if (!addressableCounts) { return  '-' }
  if (addressableCounts > 100) {addressableCounts = round(addressableCounts, -2)}

  return `~${Math.round(addressableCounts).toLocaleString()}`
}

export function selectRegionMetaData(storeMetaData, region: string) {
  if (!storeMetaData || !region) { return undefined }

  return storeMetaData[region]
}

export function isMotivation(ppcObject) {
  return ppcObject.type === "motivation";
}

export function isLookalike(ppcObject) {
  return ppcObject.type === "lookalike";
}

export function isAudience(ppcObject) {
  return ppcObject.type === "audience";
}

export function expiredDate(date: string): boolean {
  return new Date(date) < new Date();
}
