import {HttpClient} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {Injectable} from "@angular/core";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {map} from "rxjs/operators";
import {fetchResource} from "../../shared/utils/fetch-state";
import {Observable} from "rxjs";
import * as actions from './addressable-count-metadata.actions';
import {
  canDisplayAddressableCounts,
  selectRegionMetaData,
  addressableCountsFormulaSelector,
  displayAddressableCounts,
} from '../../audiences/shared/audience.utils';
import { addressableCountMetadataUrl } from 'app/shared/constants/segments.urls';

@Injectable()
export class AddressableCountMetadataService {

  metadataFromRegion;

  @Effect()
  fetchAddressableCountsMetadata$ = this.actions$.pipe(
    ofType(actions.FetchAddressableCountsMetadata.type),
    (fetchResource(
      ({ options }) => {
        return this.fetchAddressableCountsMetadata().pipe(
          map(metadata => new actions.LoadAddressableCountsMetadata(metadata))
        )
      }
    )
    ));

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private actions$: Actions
  ) {}

  fetchAddressableCountsMetadata(): Observable<any> {
    return this.http.get(addressableCountMetadataUrl())
  }

  addressableCounts(allStoreMetaData, region) {
    this.metadataFromRegion = selectRegionMetaData(allStoreMetaData, region)
  }

  canGetAddressableCounts(): boolean {
    return canDisplayAddressableCounts(this.metadataFromRegion)
  }

  calculateAddressableCounts(pids) {
    const formula = addressableCountsFormulaSelector(this.metadataFromRegion.formula_name);
    return formula(this.metadataFromRegion, pids);
  }

  displayCalculatedAddressableCounts(pids) {
    const formula = addressableCountsFormulaSelector(this.metadataFromRegion.formula_name);
    const calculatedAddressableCounts = formula(this.metadataFromRegion, pids);
    return displayAddressableCounts(calculatedAddressableCounts)
  }

}
