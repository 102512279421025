
import {fromEvent as observableFromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Component, OnChanges, Input, ViewChild, ElementRef, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { map, uniq, reduce, sumBy, clamp, min, meanBy, mean } from 'lodash';
import * as chroma from 'chroma-js';
import { Simulation, SimulationNodeDatum } from "d3-force";

import { WeboramaNode } from 'app/insights/insights-components/market-level-discussions/weborama-nodes.interface';
import * as Constants from 'app/insights/insights-components/market-level-discussions/market-level-discussions.constants';
import { Cluster } from "app/insights/insights-components/market-level-discussions/cluster.model"
import { DiscussionSubcluster } from 'app/insights/insights-components/market-level-discussions/discussion-cluster-nodes.interface';

interface GraphNode extends SimulationNodeDatum {
  x: number;
  y: number;
  r: number;
  id: string;
  targetX: number;
  data: WeboramaNode;
  fill: string;
}

@Component({
  selector: 'ppc-market-level-discussions-chart',
  templateUrl: './market-level-discussions-chart.component.html',
  styleUrls: ['./market-level-discussions-chart.component.sass']
})

export class MarketLevelDiscussionsChartComponent implements OnChanges, OnInit, OnDestroy {
  @Output() clusterChange = new EventEmitter<string>();
  @ViewChild("svg", { static: true }) svg: ElementRef;
  @Input() nodes: { names: {[clusterId: string]: string}, nodes: Array<WeboramaNode | DiscussionSubcluster>, reachable_people: {[clusterId: string]: number}, discussion_cluster_node_id: string, cluster_id?: number };
  ngUnsubscribe = new Subject();

  cluster: Cluster;
  graphNodes: GraphNode[];
  circles: d3.Selection;
  forceSim: Simulation<GraphNode, undefined>;

  width = 0;
  height = 0;

  colors: {[clusterId: string]: string};

  constructor() { }

  ngOnInit() {
    observableFromEvent(window, "resize").pipe(
      debounceTime(100),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(() => this.drawChart())
    observableFromEvent(window, "click").pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(event => this.unhighlight())
  }

  ngOnChanges() {
    this.drawChart();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  drawChart() {
    d3.select(this.svg.nativeElement).selectAll("g").remove();
    this.forceSim && this.forceSim.stop();
    this.setDimensions();
    this.setNodes();
    this.drawCluster();
  }

  setDimensions() {
    this.width = this.svg.nativeElement.getBoundingClientRect().width;
    this.height = this.svg.nativeElement.getBoundingClientRect().height;
  }

  setNodes() {
    // use the dimensions to calculate a rough max radius for the nodes
    const length = min([this.height, this.width]);
    const sqFeet = Math.pow(length, 2) * 0.78;

    const averageRadius = Math.sqrt((sqFeet / this.nodes.nodes.length) / Math.PI) / 1.9;

    const averageSize = meanBy(this.nodes.nodes, "sz");

    this.cluster = new Cluster(this.nodes, { sortByLargest: true, nodeLimit: Number.POSITIVE_INFINITY, maxAllowedRadius: averageRadius, averageSize});
    const uniqClusterIds = uniq(this.cluster.nodes.map(node => node.cluster)).sort();
    const scale = chroma.scale(Constants.colorScheme).colors(uniqClusterIds.length);

    this.colors = reduce(uniqClusterIds, (colors, clusterId: string, idx) => {
      colors[clusterId] = scale[idx];
      return colors
    }, {})

    const pack = d3.pack()
      .size([this.width, this.height])
      .radius(d => d.data.radius)
      .padding(d => d.children ? 10 : 4);

    const data = d3.nest()
      .key(d => d.cluster)
      .entries(this.cluster.nodes);

    const root = d3.hierarchy({ values: data }, d => d.values)
      .sum(d => d.radius * d.radius);

    this.graphNodes = pack(root).descendants();
  }


  private drawCluster() {
    const clusterGraphSVG = "#cluster-graph-svg";
    const svg = d3.select(clusterGraphSVG);

    const forceX = d3.forceX(this.width / 2).strength(0.015);
    const forceY = d3.forceY(this.height / 2).strength(0.015);

    this.forceSim = d3.forceSimulation(this.graphNodes)
      .force("link", d3.forceLink().id(function(d) { return d.cluster }))
      .force("collide", d3.forceCollide(function(d) { return d.children ? 0 : d.r + 1 }).iterations(3))
      .force("charge", d3.forceManyBody().strength(0.05)
        .distanceMax(this.width)
        .distanceMin(1))
      .force("center", d3.forceCenter(this.width / 2, this.height / 2))
      .force("y", forceX)
      .force("x", forceY)

    const clusters = svg.selectAll("g")
      .data(this.graphNodes)
      .enter().append("g")
      .classed("cluster", d => !!d.children)
      .classed("node", d => !d.children)
      .style("transition", "opacity 1s")
      .style("cursor", "pointer")
      .attr("transform", d => `translate(${d.x}, ${d.y})`);


    this.circles = clusters.append("circle")
      .style("fill", d => d.children ? 'transparent' : this.colors[d.data.cluster])
      .style("pointer-events", d => d.children ? 'none' : 'all')
      .attr("title", d => d.data.name)
      .on("click", d => {
        this.highlightCluster(d.data.cluster);
        d3.event.stopPropagation();
      })

    const ticked = function() {
      clusters
        .attr("transform", function(d) { return `translate(${d.x},${d.y})` });
    }

    this.forceSim
      .nodes(this.graphNodes)
      .on("tick", ticked);



    this.circles.transition()
      .duration(function(d, i) {
        return d.data.sz * 10
      })
      .delay(function(d, i) { return (Number(d.data.cluster) || 0) * 10; })
      .attrTween("r", function(d) {
        const i = d3.interpolate(0, d.r);
        return function(t) { return d.r = i(t); };
      });

    const text = clusters.append("text")
      .text(d => d.r > Constants.RADIUS_THRESHOLD ? d.data.name : "")
      .attr("dy", ".3em")
      .style("pointer-events", "none")
      .style("text-anchor", "middle")
      .style("fill", "black")
      .style("opacity", 0)
      .attr("font-size", function(d) {
        return (2 * d.r - 8) / this.getComputedTextLength() * 12 + "px";
      })
      .transition()
      .duration(1)
      .delay(function(d) { return (Number(d.data.cluster) || 0) * 10 + d.data.sz * 10; })
      .style("opacity", 1)

    setTimeout(() => {
      this.forceSim && this.forceSim.stop();
    }, 5000)

  }

  highlightCluster(clusterId: string) {
    this.clusterChange.emit(clusterId);
    this.circles.style("opacity", d => d.data.cluster === clusterId ? 1.0 : 0.7)
      .style("filter", d => d.data.cluster === clusterId ? 'none' : `url("#grayscale")`)
  }

  unhighlight() {
    this.clusterChange.emit(null);
    this.circles.style("opacity", 1)
      .style("filter", "none")
  }

}
