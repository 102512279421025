<ppc-loading-overlay *ngIf="isLoading$ | async"></ppc-loading-overlay>
<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
</header>
<main>
  <div class="header gold">Api Keys</div>
  <div *ngIf="(apiKeysList$ | async)">
    <table class="api-keys">
      <thead>
        <tr class="header-row">
          <th>Api Key ID</th>
          <th>Name</th>
          <th>Role</th>
          <th>Created By</th>
          <th>Creation Date</th>
          <th>
            Status
            <mat-select [ngModel]="apiKeysState$ | async" (ngModelChange)="updateStatusSelect($event)">
              <mat-option *ngFor="let key of apiKeysStates" [value]="key"><em>{{key}}</em></mat-option>
            </mat-select>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let key of apiKeysList$ | async; let i=index;">
          <td>{{key?.id}}</td>
          <td>{{key?.name}}</td>
          <td><a (click)="navigateToRoles()">{{key?.roleName}}</a></td>
          <td>{{key?.userFullName}}</td>
          <td>{{key?.created_at | date: 'M/d/yy HH:mm' }}</td>
          <div class="button-row">
            <button *ngIf="!key.active" class="action-btn" mat-button (click)="onActivateKey(key)">Activate Key</button>
            <button *ngIf="key.active" class="action-btn" mat-button (click)="onDisableKey(key)">Disable Key&nbsp;</button>
            <app-confirmation
              [modalTitle]="'Are you certain you want to reisssue this api key? The old api key secret will be lost.'"
              [modalContent]="'Reissuing a key generates a new api_secret_key. Your api_key_id will remain the same.'"
              [isButton]="true"
              [buttonClass]="'btn action-btn'"
              [buttonLabel]="'Reissue Key'"
              [buttonContinueLabel]="'Yes'"
              [kontinue]="onConfirmReissueKey.bind(this, key)"
              ></app-confirmation>
            <app-confirmation
              [modalTitle]="'Are you certain you want to delete this api key?'"
              [modalContent]="'This key and it\'s associated role will be deleted.'"
              [isButton]="true"
              [buttonClass]="'btn action-btn'"
              [buttonLabel]="'Delete Key'"
              [buttonContinueLabel]="'Yes'"
              [kontinue]="onConfirmDeleteKey.bind(this, key)"
              ></app-confirmation>
          </div>
        </tr>
      </tbody>
    </table>

    <app-api-keys-secret
      *ngIf="apiKeySecret$ | async"
      [apiKeySecret]="apiKeySecret$ | async"
      >
    </app-api-keys-secret>
  </div>
</main>
