import {Action} from "@ngrx/store";
import {Vendor} from "../../admin/vendors/vendor";
import {VendorPermission} from "../../admin/vendors/vendor-permission.model";
import {PermissionOption} from "../../admin/segment-permissions/models/permission-option.model";

export class LoadVendors implements Action {
  static readonly type = 'LOAD_VENDORS';
  readonly type = LoadVendors.type;

  constructor(public vendors: Vendor[]) {}
}

export class FetchVendors implements Action {
  static readonly type = 'FETCH_VENDORS';
  readonly type = FetchVendors.type;
}

export class FetchVendorPermissionOptions implements Action {
  static readonly type = 'FETCH_VENDOR_PERMISSION_OPTIONS';
  readonly type = FetchVendorPermissionOptions.type;
}

export class LoadVendorPermissionOptions implements Action {
  static readonly type = 'LOAD_VENDOR_PERMISSION_OPTIONS';
  readonly type = LoadVendorPermissionOptions.type;

  constructor(public permissionOptions: PermissionOption[]) {}
}

export class FetchVendorPermissions implements Action {
  static readonly type = 'FETCH_VENDOR_PERMISSIONS';
  readonly type = FetchVendorPermissions.type;

  constructor(public vendorId: string) {}
}

export class FetchVendorPermissionsByClientRegion implements Action {
  static readonly type = 'FETCH_VENDOR_PERMISSIONS_BY_CLIENT_REGION';
  readonly type = FetchVendorPermissionsByClientRegion.type;

  constructor(public clientId: string, regionId: string) {}
}

export class LoadVendorPermissions implements Action {
  static readonly type = 'LOAD_VENDOR_PERMISSIONS';
  readonly type = LoadVendorPermissions.type;

  constructor(public vendorPermissions: VendorPermission[]) {}
}

export class UpdateVendorPermissions implements Action {
  static readonly type = 'UPDATE_VENDOR_PERMISSIONS';
  readonly type = UpdateVendorPermissions.type;

  constructor( public vendorId: string, public permissions: VendorPermission[]) {}
}

export class UpdateVendorPermissionsComplete implements Action {
  static readonly type = 'UPDATE_VENDOR_PERMISSIONS_COMPLETE';
  readonly type = UpdateVendorPermissionsComplete.type;
}

export class UpdateVendorPermissionsFail implements Action {
  static readonly type = 'UPDATE_VENDOR_PERMISSIONS_FAIL';
  readonly type = UpdateVendorPermissionsComplete.type;
}


