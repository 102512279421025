import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'ppc-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.sass']
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  scrollTop: number;
  unsubscribe: () => void;

  constructor(
    private _scrollToService: ScrollToService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    const sideNav = document.querySelector("#sidenav-content");
    if (sideNav) {
      this.scrollTop = sideNav.scrollTop;
      this.unsubscribe = this.renderer.listen(sideNav, "scroll", event => {
        this.scrollTop = (event.target as any).scrollTop;
      })
    }
  }

  ngOnDestroy() {
    if (this.unsubscribe) { this.unsubscribe(); }
  }

  triggerScrollTo(target: string) {
    const config: ScrollToConfigOptions = {
      target: target
    };
    this._scrollToService.scrollTo(config);
  }
}
