<div class="market-level-survey-question-container">
    <div class="market-level-survey-question"
         #questionElement
         (click)="question.expanded = !question.expanded">
      <div class="market-level-survey-question-icon" matRipple [matRippleCentered]="true">
        <i class="fa fa-caret-right" [class.rotate-90]="question.expanded"></i>
      </div>
      <span class="market-level-survey-question-text">{{question.idx + 1}}. <span [innerHtml]="highlightSearchHits(question.name)"></span></span>
    </div>
    <div class="market-level-survey-question-responses" *ngIf="question.expanded">
      <div class="market-level-survey-question-response" *ngFor="let response of question?.survey_responses">
        <ppc-progress-bar [percentage]="response.percent" round="1.1-1" [reverse]="true" colorOverride="#82CAD0" [animate]="false"></ppc-progress-bar>
        <span [innerHtml]="highlightSearchHits(response.name)"></span>
      </div>
    </div>
  </div>