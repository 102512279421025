export class PermissionOption {
  static USAGE = 'usage';
  static MODELING = 'modeling';

  name: string;
  value: string;
  permission: string;
  option_category: string;

  constructor(args) {
    this.name = args.name;
    this.permission = args.permission;
    this.option_category = args.category;
    this.value = args.value;
  }

}
