import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { objToHttpParams } from "app/shared/utils/http-utils";
import { map } from "rxjs/operators";
import { assign, map as _map } from "lodash";
import { SegmentV2Service } from "app/segments-v2/segment-v2.service";
import { Effect, Actions, ofType } from "@ngrx/effects";
import {
  FetchVendorDisplayNames,
  LoadVendorDisplayNames,
  FetchAdminPermissionTriples,
  LoadAdminPermissionTriples,
} from './segments-hierarchy.action';
import { fetchResource } from "app/shared/utils/fetch-state";
import { V1, V5 } from '../shared/utils/constants';
import { adminPermissionsTripleUrl, vendorDropDownUrl, vendorListUrl } from "app/shared/constants/segments.urls";

@Injectable()
export class SegmentsHierarchyService {

  @Effect()
  fetchVendorDisplayNames$ = this.actions$.pipe(
    ofType(FetchVendorDisplayNames.type),
    (
      fetchResource(
        action => this.getVendor(action.useCases, action.fullSlug, action.version).pipe(
          map(vendorDisplayNames => new LoadVendorDisplayNames(vendorDisplayNames))
        )
      )
    )
  )

  @Effect()
  fetchAdminPermissionTriples$ = this.actions$.pipe(
    ofType(FetchAdminPermissionTriples.type),
    (
      fetchResource(
        action => this.getAdminPermissionTriple(action.version).pipe(
          map(adminPermissionTriples => new LoadAdminPermissionTriples(adminPermissionTriples))
        )
      )
    )
  )

  constructor(private http: HttpClient, private segmentService: SegmentV2Service, private actions$: Actions) { }

  getVendor(useCases: string[], contextHeader?: string, versionNumber?: string) {
    return getVendorDisplayName(this.http, useCases, contextHeader, versionNumber);
  }

  getAdminPermissionTriple(versionNumber?: string) {
    return getAdminPermissionTriple(this.http, versionNumber);
  }

  getListOfVendors(useCases: string[], client?: string) {
    return getVendorList(this.http, useCases, client);
  }
}

export interface SegmentsHierarchy {
  node_name?: string;
  ppc_object_type?: "Audience" | "Segment" | "Lookalike" | "Motivation";
  hierarchy_leaf?: boolean;
  vendor_name?: string;
  vendor_type?: string;
  identifier: string;
  parent_identifier?: string;
  segment_owner_name?: string;
}

export const makeLeaves = nodes => nodes.map(node => {
  return {...node, path: { is_leaf: true }}
});

export function getAdminPermissionTriple(http: HttpClient, versionNumber?: string) {
  return http.get<{data: any[]}>(adminPermissionsTripleUrl(versionNumber || V1)).pipe(
    map(response => response.data)
  );
};

export function getVendorDisplayName(http: HttpClient, usesCases: string[], contextHeader?: string, versionNumber?: string) {
  const params = objToHttpParams({use_cases: usesCases})
  const headers = contextHeader ? { 'x-context': contextHeader } : {};
  return http.get<{data: any[]}>(vendorDropDownUrl(versionNumber || V5), {params, headers}).pipe(
    map(response => response.data)
  );
}

export function getVendorList(http: HttpClient, useCases: string[], client?: string) {
  const params = {use_cases: useCases}
  if (client) {
    assign(params, {client})
  }
  return http.get<{data: any[]}>(vendorListUrl(V5), {params: objToHttpParams(params)}).pipe(
    map(response => response.data)
  );
}
