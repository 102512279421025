<div>
  <div class="search-container">
    <input #searchInput
      type="text"
      placeholder="Begin typing to search"
      (input)="searchQuery$.next($event.target.value)" />
  </div>
  <div class="list-container" (mouseleave)="hoverAttribute.emit()">
    <div
      *ngFor="let attr of filteredList$ | async"
      class="list-row"
      (mouseenter)="hoverAttribute.emit(attr.id)"
      (click)="selectAttribute.emit(attr)"
    >
      <span>{{attr.description}}</span>
      <div>
        <div *ngFor="let score of attr.scores" class="score">
          <span>{{score.val | percent:'1.2-2'}}</span>
          <app-progress-bar
            [percent]="score.val * 100"
            [color]="score.color"
            [hideText]="true"
            [shouldAnimate]="false"
            [thickness]="8"
          ></app-progress-bar>
        </div>
      </div>
    </div>
  </div>
</div>
