<div class="background"  [style.clip-path]="clipPath" >
</div>

<div class="change" #change
    [class.negative]="!isPositive"
    [class.invisible]="visiblePercentChange == 0"
    [style.height]="height"
    [style.top]="isPositive ? 0 : '100%'">
  <div class="change-label"
      [style.top.%]="100"
      [class.hide]="!absolutePercentChange || hasRoomForLabelOutside || !hasRoomForLabelInside">
    <i class="fa"
      [class.fa-caret-up]="isPositive"
      [class.fa-caret-down]="isNegative"></i>
      {{+actualPercentChange?.toFixed(1) | number:'1.0-1'}}%
  </div>
</div>

<div class="change-label"
    [class.negative]="!isPositive"
    [class.invisible]="!absolutePercentChange || !hasRoomForLabelOutside"
    [style.top.%]="isPositive ? absolutePercentChange : 100 - absolutePercentChange">
  <div #changeLabel>
    <i class="fa"
      [class.fa-caret-up]="isPositive"
      [class.fa-caret-down]="isNegative"></i>
      {{+actualPercentChange?.toFixed(1) | number:'1.0-1'}}%
  </div>
</div>