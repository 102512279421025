<div class="channel-form-container">
  <span class="fa fa-close" (click)="closeEvent.emit()"></span>

  <div *ngIf="apiError" class="error-message">
     We've run into a problem. Please double check your inputs, or try again later. If your problem
     persists contact customer support.
  </div>

  <h3 class="form-title">Edit Channel</h3>

  <section>
    <mat-form-field class="edit-name-container">
      <input matInput type="text" name="name" placeholder="Name" title="Name"
             [(ngModel)]="channel.name"
             (blur)="validationErrors.name && validate()">
    </mat-form-field>
    <div *ngIf="validationErrors.name" class="validation-error">{{validationErrors.name}}</div>
  </section>
  <section>
    <div class="section-title">Tardiis ID: {{channel.template._id}}</div>
  </section>
  <section>
    <div class="section-title">Media Types</div>
    <span  class="media-types">
      <ppc-checkbox *ngFor="let mediaType of channel.template.media_types"
                  color="primary"
                  (change)="toggleMediaType(mediaType._id)"
                  [checked]="channel.input.media_types.has(mediaType._id)">
        {{mediaType.name}}
      </ppc-checkbox>
    </span>
    <div *ngIf="validationErrors.media_types" class="validation-error">{{validationErrors.media_types}}</div>
  </section>
  <section>
    <div class="section-title">Dayparts</div>

    <ppc-checkbox class="select-all" [checked]="allDayPartsUsed()" color="primary" (change)="toggleSelectAllDayParts()">Select All</ppc-checkbox>

    <div class="checkbox-group">
      <div class="daypart" *ngFor="let daypart of sortedDayParts">
        <ppc-checkbox color="primary"
                     (change)="toggleDayPart(daypart._id)"
                     [checked]="channel.input.day_parts.has(daypart._id)">
        </ppc-checkbox>
        <ppc-input class="daypartNameInput"
                    [(ngModel)]="daypart.name"
                    [placeholder]="daypart.original_tardiis_name"
                    [noLabel]="true"
                    [validators]="dayPartNameValidators"
                    [forceValidation]="true"
                    (focusout)="resetBlankDaypartName(daypart)">
        </ppc-input>
      </div>
    </div>
    <div *ngIf="validationErrors.day_parts" class="validation-error">{{validationErrors.day_parts}}</div>
  </section>
  <section>
    <div class="section-title">Goals</div>
    <div>Splits</div>
    <div class="split" *ngFor="let split of channel.input.splits; let i = index">
      <span class="fa fa-trash" (click)="removeSplit(i)"></span>
      <div>
        <label>Daypart</label>
        <mat-select [(ngModel)]="split.day_part_id" (blur)="validationErrors.splits && validate()">
          <mat-option *ngFor="let dayPart of getFilteredDayparts()"
                     [value]="dayPart._id">
            {{dayPart.name}}
          </mat-option>
        </mat-select>
      </div>
      <div>
        <label>Min Budget Percentage</label>
        <mat-form-field>
          <input matInput [(ngModel)]="split.min" min="0" max="100" type="number" (blur)="validationErrors.splits && validate()">
          <span class="units">%</span>
        </mat-form-field>
      </div>
      <div>
        <label>Max Budget Percentage</label>
        <mat-form-field>
          <input matInput [(ngModel)]="split.max" min="0" max="100" type="number" (blur)="validationErrors.splits && validate()">
          <span class="units">%</span>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="validationErrors.splits" class="validation-error">{{validationErrors.splits}}</div>
    <div class="add-split" (click)="addSplit()">
      <span class="fa fa-plus"></span> Add Split
    </div>
  </section>
  <section>
    <div class="section-title">Campaigns</div>
    <div *ngFor="let range of getSurveyDateRanges(); trackBy: isSameIndex; let i = index">
      <ppc-checkbox color="primary"
                    (change)="toggleSurveyDate(range.raw)"
                    [checked]="channel.input.survey_dates.has(range.raw)">
        {{range.start}} - {{range.end}}
      </ppc-checkbox>
    </div>
    <div *ngIf="validationErrors.survey_dates" class="validation-error">{{validationErrors.survey_dates}}</div>
  </section>
  <div class="button-row">
    <button mat-button (click)="updateChannel()"
                      class="save-btn"
                      [disabled]="saveDisabled()">
      Save
    </button>
    <button mat-button (click)="closeEvent.emit()">Cancel</button>
  </div>
</div>
