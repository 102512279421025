import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubMarket, Market, selectActiveMekkoMarkets } from 'app/mekko/mekko.reducer';
import {ConfidenceSizes} from "app/snapshots/predictions-level-propensity/snapshot-detail/propensity-overview/propensity-overview.component";
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/reducers';
import * as actions from 'app/snapshots/snapshots.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectActiveSnapshot, Snapshot } from 'app/snapshots/snapshots.reducer';
import { get, find } from 'lodash';
import { filter } from 'rxjs/operators';
import { isDefined } from 'app/shared/utils/utils';

@UntilDestroy()
@Component({
  selector: 'sub-market-confidence',
  templateUrl: './sub-market-confidence.component.html',
  styleUrls: ['./sub-market-confidence.component.sass']
})

export class SubMarketConfidenceComponent implements OnInit, OnDestroy {
  @Input() subMarket: SubMarket;
  @Input() confidenceSizes: ConfidenceSizes;

  confidence: number;
  count: number;
  reach: number;
  snapshot: Snapshot;
  market: Market;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store.select("snapshots").pipe(
      select(selectActiveSnapshot),
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(snapshot => {
      this.snapshot = snapshot;
      this.confidence = get(find(snapshot.snapshots_sub_markets, {sub_market_id: this.subMarket.id}), "current_confidence");
    })

    this.store.select("mekkos").pipe(
      select(selectActiveMekkoMarkets),
      untilDestroyed(this)
    ).subscribe(markets => this.market = markets.find((m) => m.id == this.subMarket.market_id))
  }

  ngOnDestroy() { }

  matchedCount(subMarketId: number): number {
    return get(find(this.snapshot.snapshots_sub_markets, {sub_market_id: subMarketId}), "matched_count");
  }

  matchedOverlap(subMarketId: number): number {
    return this.matchedCount(subMarketId) - this.matchedUniqueCount(subMarketId);
  }

  updateCountAndConfidence(graphData: {confidence: number, people: number}) {
    this.confidence = graphData.confidence
    this.count = graphData.people

    this.reach = this.count - this.matchedUniqueCount(this.subMarket.id);
  }

  saveCurrentConfidence() {
    this.store.dispatch(new actions.SaveCurrentConfidence(this.snapshot.id, this.subMarket.id, this.confidence))
  }

  matchedUniqueCount(subMarketId: number) {
    return get(find(this.snapshot.snapshots_sub_markets, {sub_market_id: subMarketId}), "unique_count");
  }

  percentageChange(subMarketId: number): number {
    return (this.reach / this.matchedCount(subMarketId));
  }
}
