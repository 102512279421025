<button
  mat-button
  class="action-btn white add-component"
  (click)="multiUnitDialog.show()"
  [disabled]="!category.name || category.name == categoryNameRequest"
  >
  ADD COMPONENT
</button>

<ppc-dialog #multiUnitDialog class="multi-unit-dialog hide-overflow"
  (onShow)="handleModalMode(true)"
  (onClose)="handleModalMode(false)"
  >
  <div body class="multi-unit-dialog-body">
    <div class="layout horizontal">
      <div class="layout vertical left-pane">
        <p class="multi-unit-dialog-header golden">
          <span *ngIf="toggle==='unit-null' || !toggle">
            Create Custom
          </span>
          <span *ngIf="toggle && toggle!=='unit-null'">
            Edit
          </span>
          Component
        </p>
        <app-unit-form
          *ngIf="multiUnitDialog.open"
          [prnt]="cmpPrnt"
          [(mode)]="modus"
          (toggleThis)="setToggle($event)"
          ></app-unit-form>
        <button
          *ngIf="!unitUnderEdit"
          mat-button
          class="action-btn gold add-component"
          (click)="editUnit(unit);setToggle('unit-'+unit.id)"
          >Create Custom Component</button>
      </div>
      <div class="layout vertical right-pane">
        <p class="multi-unit-dialog-header golden">Available Components</p>
        <ul class="hidden-list">
          <li *ngIf="!units$.length">There are no other existing components.</li>
          <li *ngFor="let unit of units$; let i = index">
            <ppc-checkbox [disabled]="modus === 'edit'" [(ngModel)]="unit.status" class="width-auto">
              {{unit.name}}
            </ppc-checkbox>
            &nbsp;
            <i class="pointer fa fa-pencil muted" (click)="editUnit(unit, 'edit');setToggle('unit-'+unit.id)"></i>
          </li>
        </ul>
        <button
          [disabled]="!units$.length || !componentSelected()"
          mat-button
          class="action-btn"
          (click)="updateHiddenUnits()"
          >Add Selected Components To Category</button>
      </div>
    </div>
  </div>
  <div footer class="multi-unit-dialog-footer">
    <div class="action-buttons layout vertical nowrap">
      <button
        mat-button
        class="action-btn white"
        (click)="close()"
        >Close</button>
    </div>
  </div>
</ppc-dialog>
