import { tap, map, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from 'rxjs';
import { AudienceV2, removeEmptyRules } from "app/audiences-v2/audience-v2.model";
import { Actions, Effect, ofType } from "@ngrx/effects";
import * as actions from "app/audiences-v2/audience-v2.actions";
import { fetchResource, ResetFetchState } from "app/shared/utils/fetch-state";
import { objToHttpParams } from "app/shared/utils/http-utils";
import { Store, select } from '@ngrx/store';
import { AppState } from "app/reducers";
import { ChangeContext } from "app/hierarchy/hierarchy.actions";
import { V1 } from 'app/shared/utils/constants'
import { LoadAudiences } from './audience-v2.actions';
import { selectAudiencesWithType } from './audience-v2.reducers';
import { forEach, reduce, get } from 'lodash';
import { moleculaContextFromAudience, peopleCountQueryFromAudience } from '../audiences/shared/audience.utils';
import { segmentCountsUrl, segmentSimpleCountsUrl } from '../shared/constants/id_analytics.urls';
import { isMolecula } from 'app/insights/insights.utils';
import { audiencesOverviewUrl, audiencesUrl, audienceUrl, audienceV3Url } from 'app/shared/constants/segments.urls';

@Injectable()
export class AudienceV2Service {

  @Effect()
  fetchAudiences$ = this.actions$.pipe(
    ofType(actions.FetchAudiences.type),
    (
      fetchResource(
        ({ options }) => this.getAll(options).pipe(map(audiences => new actions.LoadAudiences(audiences)))
      )
    ));

  @Effect()
  fetchOverviewAudiences$ = this.actions$.pipe(
    ofType(actions.FetchOverviewAudiences.type),
    (
      fetchResource(
        ({ options }) => this.getAllOverviewAudiences(options).pipe(map(audiences => new actions.LoadAudiences(audiences)))
      )
    ));

  @Effect()
  contextChanges$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    mergeMap(() => [
      new ResetFetchState(actions.FetchAudiences),
      new ResetFetchState(actions.FetchOverviewAudiences),
      new actions.ClearAudiences()
    ]));

  @Effect()
  destroyAudience$ = this.actions$.pipe(
    ofType(actions.DestroyAudience.type),
    fetchResource(
      ({ audience }) => this.destroy(audience).map(res => new actions.RemoveAudience(res.data))
    )
  );

  @Effect({ dispatch: false })
  fetchEstimatedAudienceCounts$ = this.actions$.pipe(
    ofType(LoadAudiences.type),
    withLatestFrom(this.store.select('audiencesV2').pipe(select(selectAudiencesWithType))),
    map(([action, audiences]) => audiences.filter((row) => row.job_status !== "complete" && row.count.people.global === 0)),
    filter(incompleteAudiences => !!incompleteAudiences.length),
    tap(incompleteAudiences => this.getEstimatedPeopleCounts(incompleteAudiences))
  )

  loadingEstimatedCounts$ = new BehaviorSubject(false);

  constructor(private http: HttpClient, private actions$: Actions, private store: Store<AppState>) { }

  create(audience: AudienceV2): Observable<AudienceV2> {
    return this.http.post<AudienceV2>(audienceV3Url(), removeEmptyRules(audience))
  }

  get(id: string, urlVersionNumber: string): Observable<AudienceV2> {
    return this.http.get<AudienceV2>(audienceUrl(urlVersionNumber, id))
  }

  getAll(opts: AudienceFetchOptions): Observable<AudienceV2[]> {
    return this.http.get<{ data: AudienceV2[], count: number }>(audiencesUrl(V1), { params: objToHttpParams(opts) }).pipe(
      map(({ data }) => data))
  }

  getAllOverviewAudiences(opts: AudienceFetchOptions): Observable<AudienceV2[]> {
    return this.http.get<{ data: AudienceV2[], count: number }>(audiencesOverviewUrl(opts.urlVersionNumber), { params: objToHttpParams(opts) }).pipe(
      map(({ data }) => data));
  }

  update(audience: AudienceV2): Observable<AudienceV2> {
    return this.http.put<AudienceV2>(audienceV3Url(`${audience.id || ''}`), removeEmptyRules(audience))
  }

  clone(audience: AudienceV2): Observable<AudienceV2> {
    return this.create(audience).pipe(
      tap(audience => this.store.dispatch(new actions.LoadAudiences([audience]))))
  }

  save(audience: AudienceV2): Observable<AudienceV2> {
    return (audience.id ? this.update(audience) : this.create(audience)).pipe(
      tap(audience => this.store.dispatch(new actions.LoadAudiences([audience]))))
  }

  destroy(audience: AudienceV2): Observable<any> {
    return this.http.delete<AudienceV2>(audienceV3Url(`${audience.id || ''}`));
  }

  getEstimatedPeopleCounts(audiences: AudienceV2[]) {
    this.loadingEstimatedCounts$.next(true);
    let queries, url, payload;
    if (isMolecula()) {
      queries = audiences.map(moleculaContextFromAudience);
      url = segmentSimpleCountsUrl();
      payload = queries;
    } else {
      queries = reduce(audiences, (queries, audience) => {
        return {
          ...queries,
          [audience.id]: peopleCountQueryFromAudience(audience)
        }
      }, {});
      url = segmentCountsUrl();
      payload = { queries };
    }
    this.http.post(url, payload).subscribe(response => {
      forEach(response, (countResponse, audienceId) => {
        if (isMolecula()) {
          const count = response[audienceId] as number;
          return this.store.dispatch(new actions.ChangeAudiencePeopleCount(+audienceId, count));
        }

        const error = get(countResponse, ["total_count", "error"])
        if (error) { countResponse["total_count"] = null }
        return this.store.dispatch(new actions.ChangeAudiencePeopleCount(+audienceId, countResponse["total_count"]))
      })
      this.loadingEstimatedCounts$.next(false);
    })
  }

  fetchCount(response) {
    forEach(response, (countResponse, audienceId) => {
      const error = get(countResponse, ["total_count", "error"])
      if (error) { countResponse["total_count"] = null }

      return this.store.dispatch(new actions.ChangeAudiencePeopleCount(+audienceId, countResponse["total_count"]))
    })
    this.loadingEstimatedCounts$.next(false);
  }
}

export interface AudienceFetchOptions {
  id_space?: string;
  urlVersionNumber?: string;
}
