import {Component, Input, OnDestroy, Output, EventEmitter} from '@angular/core'
import {filter} from "rxjs/operators";
import {isDefined} from "app/shared/utils/utils";
import {filter as _filter, get, map as _map, sortBy} from "lodash";
import {constructPermissionsFromDisplay} from "app/audiences/overview-v3/audience-detail/audience-detail.utils";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {LookalikeV3} from "app/lookalikes-v3/lookalike-v3.model";
import {fullContext} from "app/hierarchy/hierarchy.reducers";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {canEstimatePanelists, displayTvPanelists} from "app/audiences/shared/audience.utils";
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers";
import {DecimalPipe} from "@angular/common";
import {getPermissionDisplayName} from "app/services/data_permissions.service";
import {Barbs} from "app/insights/grow-v3/grow.reducer";
import { AccordianTemplate, CUSTOM_AUDIENCE_CREATION } from '../overview-v3.service';

@UntilDestroy()
@Component({
  selector: 'ppc-lookalike-detail',
  templateUrl: './lookalike-detail.component.html',
  styleUrls: ['./lookalike-detail.component.sass']
})

export class LookalikeDetailComponent implements OnDestroy {

  @Input() panelistDataSourceMessage;
  @Input() regionIsNotAustralia;
  @Input() canDisplayAddressableCounts;
  @Output() onClose = new EventEmitter();
  @Output() openActivationOverlay = new EventEmitter()
  @Output() sendToTardiis = new EventEmitter()
  @Output() openIndexReportForm = new EventEmitter()
  displayPermissions: object[];
  lookalikeSubject = new BehaviorSubject<LookalikeV3>(undefined);
  activeRegion: string;
  canEstimate: boolean = false;
  panelistConstants: Barbs;
  lookalike$: Observable<LookalikeV3>;
  resetAccordionState: boolean = false;
  initialStep: number = 0;

  @Input() public set ppcObject(value: LookalikeV3) {
    if (value !== this.ppcObject) {
      this.lookalikeSubject.next(value);
    }
  }

  public get ppcObject(): LookalikeV3 {
    return this.lookalikeSubject.getValue();
  }

  accordionData: Array<AccordianTemplate> = [{
    title: "Status",
    type: "status",
    templateName: 'statusTemplate'
  }, {
    title: "Permissions",
    type: "permissions",
    templateName: 'permissionsTemplate'
  }];


  constructor(
    private store: Store<AppState>,
    private decimalPipe: DecimalPipe,
  ) {
    this.lookalike$ = this.lookalikeSubject.asObservable();

    this.lookalike$.pipe(
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(lookalike => {
      this.displayPermissions = this.setDisplayPermissions(lookalike.permissions)
    });

    combineLatest(
      fullContext(store),
      store.select('grow', 'barbs')
    ).pipe(
      untilDestroyed(this)
    ).subscribe(([context, panelistConstants]) => {
      this.activeRegion = get(context, ['region', 'slug']);
      this.panelistConstants = panelistConstants;
      this.canEstimate = canEstimatePanelists(this.panelistConstants, this.activeRegion);
    });
  }

  get panelistCount(): string {
    return displayTvPanelists(
      this.ppcObject,
      this.canEstimate,
      this.panelistConstants,
      this.decimalPipe
    );
  }


  ngOnDestroy() {}

  handleSendToPartner() {
    this.openActivationOverlay.emit(this.ppcObject)
  }

  handleSendToTardiis() {
    this.sendToTardiis.emit(this.ppcObject)
  }

  handleCreateIndexReport() {
    this.openIndexReportForm.emit(this.ppcObject)
  }

  setDisplayPermissions(permissions): object[] {
    return _filter(sortBy(_map(Object.keys(permissions), (permissionKey) => {
      return { displayName: getPermissionDisplayName(permissionKey), isPermissible: permissions[permissionKey] }
    }), 'displayName'), permission => permission.displayName && !permission.displayName.includes(CUSTOM_AUDIENCE_CREATION));
  }

  triggerAccordionReset() {
    this.resetAccordionState = !this.resetAccordionState;
  }

  closeSelf() {
    this.onClose.emit();
    this.triggerAccordionReset();
  }
}
