<div class="layout vertical wrapper">
  <h2>Copy To</h2>
  <mat-spinner *ngIf="requestsInFlight.size > 0" [diameter]="10"></mat-spinner>
  <p>
    Select which tabs to copy and where to copy them to. Note that if a tab of the same name exists
    in the target chart, 'Duplicate' will be appended to the name of the new tab to prevent overwriting.
  </p>
  <h3>What Custom Tabs to Copy</h3>
  <ul class="checklist">
    <li *ngFor="let tab of tabs$ | async;">
      <ppc-checkbox
        [stateless]="true"
        [checked]="tab.checked"
        [disabled]="tab.disabled"
        ppcTooltip="Tab contains widgets with segment permissions that cannot be copied to the target Product."
        [tooltipDisabled]="!tab.disabled"
        (click)="toggleTab(tab)"
        [ellipsize]="true"
        [showDisableFilter]="false"
      >
        <div class="layout horizontal">
          <img class="warning-icon revert-filter" *ngIf="tab.disabled" src="assets/icons/warning-orange.svg" />
          <div class="tab-description ellipsize"
            *ppcCheckOverflow="let hasOverflowedName=hasXOverflow"
            [ppcTooltip]="tab?.name"
            [tooltipDisabled]="!hasOverflowedName"
            >
            {{ tab.name }}
            <div class="small-description ellipsize"
              *ppcCheckOverflow="let hasOverflowedDesc=hasXOverflow"
              [ppcTooltip]="tab?.description"
              [tooltipDisabled]="!hasOverflowedDesc"
              >
              {{ tab.description }}
            </div>
          </div>
        </div>
      </ppc-checkbox>
    </li>
  </ul>
  <div class="warning-container">
    <span class="warning-text" *ngIf="warningMessage">
      <img class="warning-icon" src="assets/icons/warning-orange.svg" />
      {{ warningMessage }}
    </span>
  </div>

  <h3>Where to Copy</h3>
  <ppc-client-context-selector
    [clientContext]="activeContext$ | async"
    (change)="setClientContext($event)"
    [autoSelectLoneChildren]="true"
    [autoOpenNext]="true"
    [lockedLevels]="lockedContextLevels"
  >
  </ppc-client-context-selector>

  <div class="layout horizontal justified">
    <h4>Charts</h4>
    <label for="my-charts">
      <ppc-checkbox id="my-charts" [checked]="selectedMyCharts$ | async" (click)="toggleCheckboxFromStream('selectedMyCharts$')">
        Show only charts I created
      </ppc-checkbox>
    </label>
  </div>
  <ppc-tabs *ngIf="(groupedCharts$ | async)?.length"
            (tabChange)="selectedChartType$.next($event)"
            class="flex-grow-1">
    <ng-container *ngLet="fullContextSelected$ | async as isFullContext">
      <ppc-tab
        *ngFor="let group of groupedCharts$ | async; trackBy: trackByContextName"
        [name]="group.contextName"
        class="col-2"
      >
        <ppc-checkbox
          *ngFor="let chart of group.charts; trackBy: trackByUid"
          [stateless]="true"
          [checked]="chart.checked"
          [disabled]="!isFullContext"
          (click)="isFullContext && toggleChart(chart)"
          [ellipsize]="true"
        >
          {{ chart.name }}
        </ppc-checkbox>
      </ppc-tab>
    </ng-container>
  </ppc-tabs>

  <div class="layout horizontal">
    <button class="pds-btn--tertiary" (click)="selectAllCharts()">Select All</button>
    <button class="pds-btn--tertiary" (click)="deselectAllCharts()">Deselect All</button>
    <span class="ml-auto">
      Total selected in all Chart Types: {{ (selectedCharts$ | async).size }}
    </span>
  </div>

  <div class="action-footer">
    <div class="layout horizontal">
      <button class="pds-btn" (click)="doCopy()" [disabled]="isCopyDisabled$ | async">Copy</button>
      <button class="pds-btn pds-btn--tertiary" (click)="cancel()">Cancel</button>
      <label class="ml-auto" for="stay-open" *ngIf="(groupedCharts$ | async)?.length">
        <ppc-checkbox id="stay-open" [checked]="selectedStayOpen$ | async" (click)="toggleCheckboxFromStream('selectedStayOpen$')">
          Keep dialog open on Copy to copy more
        </ppc-checkbox>
      </label>
    </div>
  </div>
</div>
