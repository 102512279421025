import { Component, OnInit, ViewChild, Output, Input, EventEmitter , OnChanges, ViewChildren, QueryList} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Store } from "@ngrx/store";
import { find } from 'lodash';
import { AppState } from "app/reducers";
import { switchMap, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { LookalikeV3 } from "app/lookalikes-v3/lookalike-v3.model";
import { AudienceV2 } from "app/audiences-v2/audience-v2.model";
import { isDV360, Partner } from 'app/models/partners/partner.model';
import { ActivationOverlayService } from '../activation-overlay/activation-overlay.service';
import { ContextSlugs } from "app/hierarchy/hierarchy.interface";
import { ActivationDestinationComponent } from './activation-destination/activation-destination.component';
import { MotivationV1 } from 'app/models/motivations/motivations-v1.model';
import { OutcomeV1 } from 'app/outcomes-v1/outcomes-v1.model';
import { fullContext } from 'app/hierarchy/hierarchy.reducers';
import { userCan } from 'app/authorization/permissions/permissions.reducers'

@Component({
  selector: 'ppc-activation-destinations-overlay',
  templateUrl: './activation-destinations-overlay.component.html',
  styleUrls: ['./activation-destinations-overlay.component.sass']
})
export class ActivationDestinationsOverlayComponent implements OnInit, OnChanges {
  @ViewChild('addDialog', { static: true }) dialog: any;
  @ViewChild('destination') destinationRow: any;
  @ViewChildren(ActivationDestinationComponent) destinationRows: QueryList<ActivationDestinationComponent>;

  @Output() onClose = new EventEmitter();
  @Output() onSendObjectToPartner = new EventEmitter();
  @Output() reopenSendToPartnerOverlay = new EventEmitter();
  @Output() onRedirectToNewDestination = new EventEmitter();

  @Input() ppcObject: AudienceV2 | LookalikeV3 | MotivationV1 | OutcomeV1;
  @Input() isAdmin = false;
  @Input() clientSlug: string;
  @Input() regionSlug: string;
  @Input() brandSlug: string;
  @Input() productSlug: string;
  @Input() set partner(value) {
    if (value !== this.partner) {
      this.partnerSubject.next(value);
    }
  };

  modalView: boolean = false;
  selected$ = new BehaviorSubject<Array<any>>([]);
  destinations$: Observable<any>;
  buttonConfirmText: string = "Send";
  sendToPartnerText$;
  sendToPartnerDisabled$;
  isPartnerWithAdditionalDetails: boolean = false;
  isAudience: boolean = true;
  ppcObjectName: string = "";
  adminContext: Object;
  allClients: Array<object>;
  allRegions: Array<object>;
  allBrands: Array<object>;
  allProducts: Array<object>;

  get partner(): Partner {
    return this.partnerSubject.getValue();
  }

  get modalTitle(): string {
    return `Would you like to send ${this.ppcObject ? this.ppcObject.name : ''} to the following partner?\
            ${this.partner ? this.partner.name : ''}`;
  }

  private partnerSubject = new BehaviorSubject<Partner>(undefined);
  contextSlugs: ContextSlugs = null;

  constructor(
    private activationService: ActivationOverlayService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.store.select('hierarchy', 'contextSlugs').subscribe(slugs => {
      this.contextSlugs = slugs;
    });
  }

  ngOnChanges(changes) {
    if (this.partner) {
      this.isPartnerWithAdditionalDetails = (this.partner.name === 'The Trade Desk' || isDV360(this.partner));

      if (this.isAdmin) {
        this.store.select('hierarchy', 'hierarchy', 'clients')
          .subscribe(clients => {
            this.allClients = [{slug: 'all', id: null, name: 'All', regions: []}, ...clients]
          });

        this.adminContext = {
          client: this.clientSlug,
          region: this.regionSlug,
          brand: this.brandSlug,
          product: this.productSlug
        }

        const cli = find(this.allClients, ['slug', this.clientSlug]);
        const regions = cli['regions'];

        if (regions.length > 0) {
          this.allRegions = [{slug: 'all', name: 'All', id: null, brands: [], emerging_market: false, currency: ''}, ...cli['regions']];

          const reg =  find(this.allRegions, ["slug", this.regionSlug]);
          const brands = reg['brands'];

          if (brands.length > 0) {
            this.allBrands = [{slug: 'all', name: 'All', id: null, products: []}, ...reg['brands']];

            const fullBrands = find(this.allBrands, ["slug", this.brandSlug]);
            const products = fullBrands['products'];

            if (products.length > 0) {
              this.allProducts = [{slug: 'all', name: 'All', id: null}, ...fullBrands['products']];
            }
          }
        }

        this.destinations$ = this.partnerSubject.pipe(
          switchMap((partner) =>  this.activationService.fetchAdminActivationDestinations(this.adminContext, partner.id)),
          map((destinations) => this.sortDestinations(destinations))
        );
      } else {
        this.destinations$ = this.partnerSubject.pipe(
          switchMap((partner) =>  this.activationService.fetchActivationDestinations(partner.id, this.ppcObject.identifier)),
          map((destinations) => this.sortDestinations(destinations))
        );
      }

      this.sendToPartnerText$ = combineLatest([this.selected$, this.destinations$]).pipe(
        map(([selected, destinations]) => {
          const STPSet = new Set;

          selected.forEach((selectedDest) => {
            const found = destinations.find((dest) => selectedDest.destinationId === dest.id);
            switch (true) {
              case !!(found.status === "Destination Ready for Audience"):
                STPSet.add('Send To');
                break;
              case !!found.status.match('Sent'):
                STPSet.add('Refresh');
                break;
              default:
                break;
            }
          });

          if (STPSet.size > 1) {
            return Array.from(STPSet.values()).sort().join(' & ') + " Partner";
          } else if (STPSet.size === 1 && STPSet.has("Refresh")) {
            return "Refresh Selected"
          }

          return 'Send To Partner'
        }
        ));

      this.sendToPartnerDisabled$ = this.selected$.pipe(
        map(selected => {
          if (selected.length !== 0) {
            return selected.some(selectedObj => {
              const values = Object.values(selectedObj)
              const invalidValues = values.includes(null)
              return values.length !== 2 || invalidValues
            })
          } else {
            return selected.length == 0
          }
        })
      )
    }

    if (this.ppcObject) {
      this.isAudience = this.ppcObject.type === "audience"
      this.ppcObjectName = this.ppcObject.name
    }
  }

  sortDestinations(destinations) {
    return destinations.sort((a, b) => {
      const activationStatusPriority = ['sent', 'ready', 'activation', 'creation', 'failed'];
      const aIndex = activationStatusPriority.findIndex(str => a.status.toLowerCase().includes(str));
      const bIndex = activationStatusPriority.findIndex(str => b.status.toLowerCase().includes(str));

      if (aIndex > bIndex) {return 1; }
      if (bIndex > aIndex) {return -1; }
      // Sort the sent destinations by date, newest to oldest
      if (a.status > b.status) {return -1; }
      if (b.status > a.status) {return 1; }

      return 0;
    })
  }

  sendToPartner() {
    const selectedArray = this.selected$.getValue()
    const destinationIds = selectedArray.map(selectedObject => selectedObject.destinationId)
    const formattedObject = {}
    selectedArray.map(selectedObject => {
      formattedObject[`${selectedObject.destinationId}`] = [selectedObject.useCase]
    })

    this.onSendObjectToPartner.emit({
      ppcObject: this.ppcObject,
      destinationIds: destinationIds,
      useCases: formattedObject
    });
    this.selected$.next([]);
  }

  redirectToNewDestination() {
    this.onRedirectToNewDestination.emit({partner: { name: this.partner.name, id: this.partner.id }})
  }

  removeDestinationFromSelected($event) {
    const selectedArray = this.selected$.getValue();
    const filteredDestinations = selectedArray.filter(dest => dest.destinationId !== $event.destinationId)

    this.selected$.next(filteredDestinations);
  }

  addDestinationFromSelected($event) {
    const selectedArray = this.selected$.getValue();
    const updateDestination = selectedArray.find(dest => dest.destinationId === $event.destinationId)

    if (updateDestination) {
      updateDestination.useCase = $event.useCase
    } else {
      selectedArray.push($event);
    }
    this.selected$.next(selectedArray);
  }

  createDestination($event) {
    const name = $event.name;
    this.activationService.createDestination(this.destData(name)).subscribe(() => {
      this.destinations$ = this.destinations$.map((array) => array);
      this.selected$.next([])
    });
  }

  destData(name) {
    const result = {
      partner_id: this.partner.id,
      name: name,
      product_permission: {
        client: this.contextSlugs.clientSlug,
        brand: this.contextSlugs.brandSlug,
        region: this.contextSlugs.regionSlug,
        product: this.contextSlugs.productSlug,
      }
    };
    return result;
  }

  isPartnerRemoved(): boolean {
    if (this.partnerSubject.getValue()) {
      return this.partner.removed;
    }
  }

  returnToPreviousPage(): void {
    this.destinationRows.forEach((row) => row.reset());
    this.selected$.next([]);
    this.reopenSendToPartnerOverlay.emit(this.partner);
  }

  closeOverlay(): void {
    this.destinationRows.forEach((row) => row.reset());
    this.selected$.next([]);
    this.onClose.emit();
  }
}
