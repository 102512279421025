
import {map} from 'rxjs/operators';
import {Component, Input, ViewChild, Output, EventEmitter, OnDestroy} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';

import {InputValidator, PpcInputComponent} from "../../shared/components/ppc-input/ppc-input.component";
import {PlanInputs} from "./plan-inputs";
import {DateRangePickerComponent} from "../../shared/components/date-range-picker/date-range-picker.component";
import {GoalTypeDetails} from "../scenario-dialog.service";
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers/index";
import {fullContext} from "app/hierarchy/hierarchy.reducers";
import {curveLabelOutput} from 'app/shared/utils/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'plan-inputs',
  templateUrl: './plan-inputs.component.html',
  styleUrls: ['./plan-inputs.component.sass']
})
export class PlanInputsComponent implements OnDestroy {

  dateRangePickerOptions = {
    opens: 'left'
  };

  @ViewChild('nameInput', { static: true }) nameInput: PpcInputComponent;
  @ViewChild('goalInput', { static: true }) goalInput: PpcInputComponent;
  @ViewChild('universeInput') universeInput: PpcInputComponent;
  @ViewChild('dateRangePicker', { static: true }) dateRangePicker: DateRangePickerComponent;

  @Input() planNames: string[] = [];
  @Input() curveTypes: {name: string, id: number}[] = [];
  @Input() currencySymbol: string = "$";
  @Input() planInputs: PlanInputs;
  @Input() editMode: boolean = false;
  @Input() goalTypes: {[goalTypeName: string]: GoalTypeDetails} = {};
  @Input() importedPlan: boolean = false;

  @Output() madeSelection = new EventEmitter();
  name: string = "";
  curveOverride: boolean = false;
  curveLabelOutput = curveLabelOutput;

  // flags used to show/hide validation messages
  validations = {
    curveSelected: true,
    datesSelected: true,
    goalTypeSelected: true
  };

  constructor(private store: Store<AppState>) {
    fullContext(this.store).pipe(untilDestroyed(this)).subscribe(
      ({client}) => this.curveOverride = client.curve_label_override
    );
  }

  ngOnDestroy() {}

  public isValid(): boolean {
    const nameValid = this.nameValid;
    const goalValid = this.testInput(this.planInputs.goal, this.numberInputValidators('goal'));
    const universeValid = !this.isTRP || this.testInput(this.planInputs.universe, this.numberInputValidators('universe'));
    this.validateCurve(false);
    this.validateDates(this.planInputs.flightDates);
    this.validateGoalType();

    // show validation errors on ppc input components
    this.nameInput.touched = true;
    this.goalInput.touched = true;
    if (!this.importedPlan && this.isTRP) {this.universeInput.touched = true; }

    const validatedCondition = nameValid
      && goalValid
      && universeValid
      && this.validations.curveSelected
      && this.validations.datesSelected
      && this.validations.goalTypeSelected

    return validatedCondition
  }

  public resetValidations() {
    this.nameInput.touched = false;
    this.goalInput.touched = false;
    this.validations = {
      curveSelected: true,
      datesSelected: true,
      goalTypeSelected: true
    };
    this.dateRangePicker.clear();
  }

  onSelectItem() {
    this.madeSelection.emit();
  }

  parseInputNumber(goal: number, inputField: string, parseDigits = 2) {
    if (goal != null) {
      const goalNum = new Number(goal);
      this.planInputs[inputField] = parseFloat(goalNum.toFixed(parseDigits));
    }
  }

  validateCurve(emitSelection = true) {
    this.validations.curveSelected = !!this.planInputs.curveType;
    if (emitSelection && this.validations.curveSelected) {this.madeSelection.emit(); }
  }

  validateDates(dateEvent: {start: Moment, end: Moment}) {
    this.validations.datesSelected = dateEvent != null
      && (dateEvent.start != null && dateEvent.end != null);
  }

  validateGoalType() {
    this.validations.goalTypeSelected = !!this.planInputs.goalType;
  }

  get nameValidators(): InputValidator[] {
    return [
      {
        isValid: (name) => name != void(0) && name != "",
        errorMessage: "Please enter a scenario name"
      },
      {
        isValid: (name) => name == this.planInputs.oldName || (this.planNames == void(0) || this.planNames.indexOf(name.trim()) < 0),
        errorMessage: "Scenario name is already taken"
      }
    ]
  }

  numberInputValidators(fieldTitle: string): InputValidator[] {
    const exists = goal => goal != void(0) && goal != "";
    return [
      {
        isValid: exists,
        errorMessage: `Please enter a ${fieldTitle}`
      },
      {
        isValid: goal => !exists(goal) || goal > 0,
        errorMessage: `${fieldTitle} must be greater than 0`
      }
    ];
  }

  get goalValidators(): InputValidator[] {
    const validators = this.numberInputValidators('goal');
    if (this.isReach) {
      validators.push({
        isValid: goal => goal <= 100,
        errorMessage: 'The goal value must be less than or equal to 100%'
      })
    }
    return validators;
  }

  public get nameValid(): boolean {
    return this.testInput(this.planInputs.name, this.nameValidators );
  }

  get isReach(): boolean {
    return this.planInputs.goalType
      && (this.planInputs.goalType == 'Reach' || this.planInputs.goalType == 'Penetration');
  }

  get goalTypeOptions(): string[] {
    return Object.keys(this.goalTypes)
      .filter(goalType => this.goalTypes[goalType].curve_types.indexOf(this.planInputs.curveType.id) > -1 )
  }

  get selectedGoalType(): GoalTypeDetails {
    return this.goalTypes[this.planInputs.goalType];
  }

  get isTRP(): boolean {
    return this.planInputs.curveType.name && this.planInputs.curveType.name.indexOf('TRP') > -1;
  }

  private testInput(input: any, validators: InputValidator[]): boolean {
    return validators.reduce((result, v) => result && v.isValid(input), true);
  }

}
