export const stateColors = {
  complete: "#96F201",
  pending: "#F59A23",
  error: '#FF0000',
  archived: '#B0C4DE',
};

export enum SelectableCompany {
  ALL = 'all'
}
