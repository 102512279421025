import {Action} from "@ngrx/store";

import { Tab } from 'app/insights/insights.models';
import { OutcomeTimeframe, MeasureTabType } from 'app/measure-v3/measure-v3.reducer';
import { Mekko, SubMarket, Market } from "app/mekko/mekko.reducer";

export class ResetMeasureV3 implements Action {
  static readonly type = "MEASURE_V3:RESET_MEASURE_V3";
  readonly type = ResetMeasureV3.type;
}

export class FetchOutcomeTimeframes implements Action {
  static readonly type = "MEASURE_V3:FETCH_OUTCOME_TIMEFRAMES";
  readonly type = FetchOutcomeTimeframes.type;

  constructor(public mekkoId: number) { }
}

export class LoadOutcomeTimeframes implements Action {
  static readonly type = "MEASURE_V3:LOAD_OUTCOME_TIMEFRAMES";
  readonly type = LoadOutcomeTimeframes.type;

  constructor(public outcomeTimeframes: OutcomeTimeframe[]) { }
}

export class SetActiveCmsYear implements Action {
  static readonly type = "MEASURE_V3:SET_ACTIVE_CMS_YEAR";
  readonly type = SetActiveCmsYear.type;

  constructor(public activeYear: number) { }
}

export class SaveOutcomeTimeframe implements Action {
  static readonly type = "MEASURE_V3:SAVE_OUTCOME_TIMEFRAME";
  readonly type = SaveOutcomeTimeframe.type;

  constructor(public outcomeTimeframe: OutcomeTimeframe) { }
}

export class LoadOutcomeTimeframe implements Action {
  static readonly type = "MEASURE_V3:LOAD_OUTCOME_TIMEFRAME";
  readonly type = LoadOutcomeTimeframe.type;

  constructor(public outcomeTimeframe: OutcomeTimeframe) { }
}

export class DestroyOutcomeTimeframe implements Action {
  static readonly type = "MEASURE_V3:DESTROY_OUTCOME_TIMEFRAME";
  readonly type = DestroyOutcomeTimeframe.type;

  constructor(public outcomeTimeframe: OutcomeTimeframe) { }
}

export class RemoveOutcomeTimeframe implements Action {
  static readonly type = "MEASURE_V3:REMOVE_OUTCOME_TIMEFRAME";
  readonly type = RemoveOutcomeTimeframe.type;

  constructor(public outcomeTimeframe: OutcomeTimeframe) { }
}

export class SetMeasureTab implements Action {
  static readonly type = "MEASURE_V3:SET_MEASURE_TAB";
  readonly type = SetMeasureTab.type;

  constructor(public tab: MeasureTabType) { }
}

export class SetCurrentTimeframe implements Action {
  static readonly type = "MEASURE_V3:SET_CURRENT_TIMEFRAME";
  readonly type = SetCurrentTimeframe.type;

  constructor(public outcomeTimeframe: OutcomeTimeframe) { }
}

export class SetPreviousTimeframe implements Action {
  static readonly type = "MEASURE_V3:SET_PREVIOUS_TIMEFRAME";
  readonly type = SetPreviousTimeframe.type;

  constructor(public outcomeTimeframe: OutcomeTimeframe) { }
}

export class ToggleSelectedSubMarket implements Action {
  static readonly type = "MEASURE_V3:TOGGLE_SELECTED_SUB_MARKET";
  readonly type = ToggleSelectedSubMarket.type;

  constructor(public subMarket: SubMarket) { }
}

export class ToggleSelectedMarket implements Action {
  static readonly type = "MEASURE_V3:TOGGLE_SELECTED_MARKET";
  readonly type = ToggleSelectedMarket.type;

  constructor(public market: Market) {}
}

export class SetMeasureV3Active implements Action {
  static readonly type = "MEASURE_V3:SET_MEASURE_V3_ACTIVE";
  readonly type = SetMeasureV3Active.type;

  constructor(public measureV3Active: boolean) { }
}

export class FetchTabs implements Action {
  static readonly type = "MEASURE_V3:FETCH_TABS";
  readonly type = FetchTabs.type;

  constructor(public mekkoId: number) { }
}

export class LoadTabs implements Action {
  static readonly type = "MEASURE_V3:LOAD_TABS";
  readonly type = LoadTabs.type;

  constructor(public tabs: Tab[]) { }
}

export class ResetSelectedMekko implements Action {
  static readonly type = "MEASURE_V3:RESET_SELECTED_MEKKO"
  readonly type = ResetSelectedMekko.type; apply

  constructor() {}
}

export type ReducerActions = LoadOutcomeTimeframes | ResetMeasureV3
| SetActiveCmsYear | LoadOutcomeTimeframe | RemoveOutcomeTimeframe
| SetMeasureTab | SetCurrentTimeframe | SetPreviousTimeframe
| ToggleSelectedSubMarket | SetMeasureV3Active
| LoadTabs | ToggleSelectedMarket | ResetSelectedMekko
