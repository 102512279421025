import {map} from 'rxjs/operators';
import {catchError} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { PpcHttpService } from "app/services/ppc_http.service";
import {Brand} from "./brand.model";
import { handleError } from "app/shared/utils/errors";
import { brandsUrl } from '../../shared/constants/clients.urls';

@Injectable()
export class BrandService {

  constructor(private http: PpcHttpService) { }

  createBrand(payload): Observable<Brand> {
    return this.http.post(brandsUrl(), payload).pipe(
      map(res => new Brand(res)),
      catchError(handleError), );
  }

  updateBrand(id: string, payload): Observable<Brand> {
    return this.http.put(`${brandsUrl()}/${id}`, payload).pipe(
      map(res => new Brand(res)),
      catchError(handleError), );
  }

  deleteBrand(id: string): Observable<any> {
    return this.http.delete(`${brandsUrl()}/${id}`).pipe(
      catchError(handleError));
  }

}
