import {Component, OnInit, OnDestroy, ViewChild, Renderer2} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import * as d3 from "d3";
import { Observable } from 'rxjs';
import { fullContext } from 'app/hierarchy/hierarchy.reducers';
import {HierarchyService} from "app/services/hierarchy.service";
import {Channel, PointDrillDown, TardiisPoint} from "../tardiis-channel.model";
import {ChannelsService} from "../channels.service";
import {getScales, getYAxisWidth, Point} from "app/shared/utils/charts";
import {SlideInOverlayComponent} from "app/shared/components/slide-in-overlay/slide-in-overlay.component";
import {PlanService} from "../plan.service";
import {Plan} from "../plan.model";
import Utils from "app/shared/utils/utils";
import {MediaTypeColors} from "app/plans/plans.constants";
import {PLANS_SUB_NAV_CHANNELS, NAV_PLANS, PLANS_SUB_NAV_SCENARIOS} from "app/shared/utils/constants";
import { AutoUnsubscribeBaseComponent } from '../../base-components/auto-unsubscribe';
import { map, takeUntil, filter } from 'rxjs/operators';
import { get, sortBy, uniqBy } from 'lodash';
import { canAccessFeature } from "app/feature-access/feature-access.reducers";
import { Store } from "@ngrx/store";
import { AppState } from "app/reducers";
import { isDefined } from 'app/shared/utils/utils';
import { canShowChannelInfoForRegion } from "../plans.utils";

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.sass']
})
export class ChannelComponent extends AutoUnsubscribeBaseComponent implements OnInit, OnDestroy {

  channel: Channel;
  selectedMetric = "budget";
  expandedMediaTypes: Set<string>;
  selectedPoint: TardiisPoint;
  currency = "gbp";
  productId: string;
  attachedPlan: Plan;
  unassignedPlans: {name: string, id: string}[];
  errorMessage: string;
  drillDownIndex: {
    [pointId: string]: {
      drillDowns: {[mediaType: string]: PointDrillDown[]};
      totals: {[mediaType: string]: number}
    }
  };
  eventListeners: (() => void)[];
  daypartDisplayNames: { [daypartName: string]: string };
  mediaTypes: string[];
  canAccessScenarios: boolean;
  canShowChannelInfo$: Observable<boolean>;

  @ViewChild('overlay', { static: true }) private overlay: SlideInOverlayComponent;

  constructor(
    private channelService: ChannelsService,
    private planService: PlanService,
    private route: ActivatedRoute,
    private router: Router,
    private hierarchyService: HierarchyService,
    private renderer: Renderer2,
    private store: Store<AppState>,
  ) {
    super();
    this.hierarchyService.fullContext$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      ({client, region, product}) => {
        if (this.productId && this.productId !== product.id) {
          // Prevent making API call when navigating away from the page
          return;
        }
        this.productId = product.id;
        this.currency = region.currency;

        this.getChannel();
      },
      console.error
    );

    canAccessFeature(this.store, 'scenarios').pipe(filter(isDefined), takeUntil(this.destroyed$))
      .subscribe(
        canAccess => this.canAccessScenarios = canAccess
      );

    this.canShowChannelInfo$ = fullContext(this.store).pipe(
      map(context => {
        const region = get(context, ['region', 'slug'], undefined);
        return canShowChannelInfoForRegion(region);
      })
    );

    const nav = document.querySelector("#nav");
    if (nav) {
      this.eventListeners = ["mouseenter", "mouseleave"].map(event => {
        return this.renderer.listen(nav, event, () => {
          setTimeout(() => {
            this.drawLine();
          }, 601);
        }) as () => void
      });
    }
  }

  ngOnInit() {
    this.drawLine();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.eventListeners) {
      this.eventListeners.forEach(unsubscribe => unsubscribe());
    }
  }

  getChannel() {
    this.channelService.getChannel(this.route.snapshot.params['id'])
      .subscribe(
        c => {
          this.channel = c;

          if (this.channel.output.points) {
            this.channel.output.points.forEach(p => p.uuid = Utils.uuid());
            this.daypartDisplayNames = this.makeDaypartNameMapping();
            this.drillDownIndex = this.indexDrillDowns();
          }

          this.selectedPoint = this.channel.lastPoint();
          this.mediaTypes = this.getMediaTypesSortedByBudgetTotal();
          this.expandedMediaTypes = new Set([this.mediaTypes[0]]);
          if (!this.channel.hasBudgetInfo()) { this.selectedMetric = "trp"; }

          this.loadAttachedPlan();
          if (!this.attachedPlan) { this.loadUnassignedPlans(); }

          this.drawLine();
        },
        console.error
      );
  }

  getChannelInfo() {
    this.channelService.downloadTardiisReportCSV(this.channel._id);
  }

  openEditDialog() {
    this.overlay.toggleState();
  }

  closeEditDialog() {
    this.overlay.toggleState();
  }

  navigateToIndexPage() {
    this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_CHANNELS]);
  }

  cleanDrillDowns(point: TardiisPoint): any {
    const {drill_downs} = point;
    // dedupe based on day_part_name + budget
    return uniqBy(drill_downs, v => [v.day_part_name, v.budget].join());
  }

  getDrillDownsByMediaType(point: TardiisPoint): {[mediaType: string]: PointDrillDown[]} {
    const drill_downs = this.cleanDrillDowns(point);
    const groupedDrillDowns = drill_downs.reduce((acc, x) => {
      acc[x.survey_type_name] = (acc[x.survey_type_name] || []).concat(x);
      return acc
    }, {});
    Object.keys(groupedDrillDowns).map(key => {
      groupedDrillDowns[key] = sortBy(groupedDrillDowns[key], a => this.daypartDisplayNames[a.day_part_name])
    })
    return groupedDrillDowns;
  }

  getTotalBudgetPctByMediaType(point: TardiisPoint): {[mediaType: string]: number} {
    const drill_downs = this.cleanDrillDowns(point);
    return drill_downs.reduce((acc, x) => {
      acc[x.survey_type_name] = (acc[x.survey_type_name] || 0) + x.budget;
      return acc;
    }, {});
  }

  indexDrillDowns() {
    return Object.assign({}, ...this.channel.output.points.map(p => ({
      [p.uuid]: {
        drillDowns: this.getDrillDownsByMediaType(p),
        totals: this.getTotalBudgetPctByMediaType(p)
      }
    })))
  }

  makeDaypartNameMapping() {
    return Object.assign({}, ...this.channel.template.day_parts.map(daypart => ({
      [daypart.original_tardiis_name]: daypart.name
    })));
  }

  getMediaTypesSortedByBudgetTotal() {
    return this.drillDownIndex
      ? this.channel.output.media_types.sort(
        (a, b) => this.drillDownIndex[this.selectedPoint.uuid].totals[b] - this.drillDownIndex[this.selectedPoint.uuid].totals[a])
      : this.channel.output.media_types;
  }

  toggleMediaType(mediaType: string) {
    const action = this.expandedMediaTypes.has(mediaType) ? 'delete' : 'add';
    this.expandedMediaTypes[action](mediaType);
  }

  mediaTypeUsed(mediaType: string): boolean {
    return mediaType in this.drillDownIndex[this.selectedPoint.uuid].totals;
  }

  openMediaType(mediaType: string): boolean {
    return this.channel.hasBudgetInfo() &&
           this.expandedMediaTypes.has(mediaType) &&
           this.mediaTypeUsed(mediaType);
  }

  metricDisplayName(metric: string): string {
    switch (metric) {
      case "budget": return "Budget";
      case "impression": return "Impressions";
      case "spot": return "Spot/Insertions";
      case "trp": return "TRP";
    }
  }

  selectMetric({value}) {
    this.selectedMetric = value;
    this.drawLine();
  }

  drawLine() {
    // Make sure the data and container exist
    if (!this.channel || !this.channel.output) {return; }
    const {output: {points, pdr}} = this.channel;

    if (points.length === 0) {return; }

    const graphContainer = document.querySelector(`.graph-container`);
    if (!graphContainer) {return; }

    // Clear previous drawing
    const svg = d3.select('.graph-container svg');
    svg.selectAll("*").remove();

    // Prepare the data
    const lineData = points.map(p => Object.assign({}, p, {x: p[this.selectedMetric], y: p.reach}));

    // Other variables
    const {width, height} = graphContainer.getBoundingClientRect();
    const color = '#4898c8';

    // create yAxis and yScale first so that we can adjust the xAxis by the width of the yAxis
    const {yScale} = getScales(lineData, graphContainer, {yMax: 100});
    const yAxis = d3.axisLeft(yScale)
      .tickValues([25, 50, 75, 100])
      .tickFormat(x => x + "%");

    const yAxisSvg = svg.append("g")
      .attr("class", "axis")
      .call(yAxis);

    const yAxisWidth = getYAxisWidth(yAxisSvg);
    yAxisSvg.attr('transform', `translate(${yAxisWidth}, 0)`);
    yAxisSvg.selectAll('line').attr('x2', width - yAxisWidth);
    yAxisSvg.selectAll('text').attr('x', 0);

    // Now create the xAxis leaving room for the yAxis
    const {xScale} = getScales(lineData, graphContainer, {leftPadding: yAxisWidth, yMax: 100});
    const xAxis = d3.axisBottom(xScale)
      .tickSize(0)
      .ticks(5)
      .tickFormat(d3.format(',.2s'));

    svg.append("g")
      .attr("class", "axis xAxis")
      .attr('transform', `translate(0, ${yScale(0) + 5})`)
      .call(xAxis);

    svg.append('text')
      .attr('x', width / 2)
      .attr('y', height + 40)
      .text(this.metricDisplayName(this.selectedMetric))
      .style('text-anchor', 'middle')
      .attr('stroke', '#9e9e9d');

    // Draw the curve and area
    const line = d3.line<Point>()
      .curve(d3.curveBasis)
      .x(p => xScale(p.x))
      .y(p => yScale(p.y));

    const area = d3.area<Point>()
      .curve(d3.curveBasis)
      .x(p => xScale(p.x))
      .y0(yScale(0))
      .y1(p => yScale(p.y));

    svg.append("path")
      .datum(lineData)
      .attr("fill", "none")
      .attr("stroke", color)
      .attr("stroke-width", 3)
      .attr("d", line);

    svg.append("path")
      .datum(lineData)
      .attr("fill", color)
      .attr("opacity", .5)
      .attr("d", area);

    // Draw the points
    lineData.forEach(p => {
      svg.append("circle")
        .attr("fill", color)
        .attr("cx", xScale(p.x))
        .attr("cy", yScale(p.y))
        .attr("r", 5)
    });

    // Draw the hover point indicator and hook up listeners to make it work
    const focusPoint = svg.append("g")
      .append("circle")
      .attr("fill", color)
      .attr("r", 10)
      .style("display", "none");

    const channelComponent = this;
    svg.append("rect")
      .attr("width", width)
      .attr("height", height)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mouseover", function() { focusPoint.style("display", null); })
      .on("mouseout", function() {
        const lastLinePoint = lineData[lineData.length - 1];
        channelComponent.selectedPoint = channelComponent.channel.lastPoint();
        focusPoint.attr('transform', `translate(${xScale(lastLinePoint.x)}, ${yScale(lastLinePoint.y)})`)
      })
      .on("mousemove", function() {
        const mouseCoords = d3.mouse(this);
        const mouseBudget = xScale.invert(mouseCoords[0]);

        const bisect = d3.bisector(p => p['x']).left;
        let bisectIdx = bisect(lineData, mouseBudget);
        const mouseClosestToBisectIdx = bisectIdx === 0
                                            || mouseBudget - lineData[bisectIdx - 1].x > lineData[bisectIdx].x - mouseBudget;
        if (!mouseClosestToBisectIdx) {bisectIdx = bisectIdx - 1; }

        const highlightPoint = lineData[bisectIdx];
        channelComponent.selectedPoint = points[bisectIdx];
        focusPoint.attr("transform", `translate(${xScale(highlightPoint.x)}, ${yScale(highlightPoint.y)})`);
      });

    // Don't draw pdr if it is outside the bounds of the graph
    if (pdr[this.selectedMetric].x > lineData[lineData.length - 1].x) {return; }

    svg.append("circle")
      .attr("fill", "black")
      .attr("stroke", color)
      .attr("stroke-width", 1.5)
      .attr("cx", xScale(pdr[this.selectedMetric].x))
      .attr("cy", yScale(pdr[this.selectedMetric].reach))
      .attr("r", 5);

    svg.append("text")
      .attr("text-anchor", "middle")
      .attr("x", xScale(pdr[this.selectedMetric].x))
      .attr("y", yScale(pdr[this.selectedMetric].reach) - 10)
      .text("PDR")
      .attr("fill", "white");
  }

  loadAttachedPlan() {
    this.planService.getPlanForChannelId(this.channel._id)
      .subscribe(
        plan => this.attachedPlan = plan,
        err => this.attachedPlan = null
      );
  }

  loadUnassignedPlans() {
    this.planService.getUnassignedPlans()
      .subscribe(
        plans => {
          this.unassignedPlans = plans
            .filter(plan => plan.status === "Complete")
            .map(plan => ({name: plan.planName, id: plan._id}))
            .sort((a, b) => a.name.localeCompare(b.name))
        },
        console.error
      );
  }

  onSave(planId, callback) {
    this.planService.associateChannelToPlan(planId, this.channel._id)
      .subscribe(
        success => {
          callback(true);
          this.channelService.getChannel(this.channel._id);
          this.loadAttachedPlan();
        },
        err => this.errorMessage = err.error_messages
      );
  }

  gotoScenarioSummaryPage() {
    this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_SCENARIOS]);
  }

  gotoScenarioPage() {
    this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_SCENARIOS, this.attachedPlan._id]);
  }

  isSameIndex(i) {
    return i;
  }

  determineColor(mediaType: string): string {
    return MediaTypeColors[mediaType];
  }
}
