import {
  AbstractControl,
  Validator,
  ValidationErrors,
  ValidatorFn,
  NG_VALIDATORS,
} from "@angular/forms";
import { Directive, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[ppcNumericRange]",
  providers: [
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    { provide: NG_VALIDATORS, useExisting: NumericRangeDirective, multi: true },
  ],
})
export class NumericRangeDirective implements Validator, OnInit {
  @Input() min: number;
  @Input() max: number;

  constructor() {}

  ngOnInit(): void {
    /**
     * If min and max attributes are not defined
     * relying on min / max values that are supported for arithematic operations
     */
    this.min = this.min !== undefined ? this.min : Number.MIN_SAFE_INTEGER;
    this.max = this.max !== undefined ? this.max : Number.MAX_SAFE_INTEGER;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.numericRangeValidator()(control);
  }

  private numericRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      return value
        ? value < this.min
          ? { min: { expected: this.min, actual: value } }
          : value > this.max
            ? { max: { expected: this.max, actual: value } }
            : null
        : null;
    };
  }
}
