import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ASC, DEFAULT, DESC } from 'app/insights/insights.service';
import { isEmpty } from 'lodash';
import { PpcSortService, SortDirection } from './ppc-sort.service';

@Component({
  selector: 'ppc-sort',
  templateUrl: './ppc-sort.component.html',
  styleUrls: ['./ppc-sort.component.sass']
})
export class PpcSortComponent implements OnInit {

  @Input() sortData: any[] = [];
  @Output() emitSortDirection = new EventEmitter();

  @Input() preservedSortDirection: SortDirection = DEFAULT;

  sortDirection: SortDirection = DEFAULT;

  constructor(private ppcSortService: PpcSortService) {}

  ngOnInit(): void {
    if ( !isEmpty(this.preservedSortDirection) ) {
      this.sortDirection = this.preservedSortDirection;
    }
  }

  sort() {
    if ( this.sortDirection === DEFAULT ) {
      this.sortDirection = DESC;
    } else {
      if ( this.sortDirection === ASC ) {
        this.sortDirection = DEFAULT;
      } else {
        this.sortDirection = ASC;
      }
    }
    this.ppcSortService.sortBarChartData(this.sortDirection, this.sortData);
    this.emitSortDirection.emit(this.sortDirection);
  }
}
