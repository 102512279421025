import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ppc-dialog',
  templateUrl: './ppc-dialog.component.html',
  styleUrls: ['./ppc-dialog.component.sass']
})
export class PpcDialogComponent {
  @Input() dismissOnBlur: boolean = true;
  @Output() onShow = new EventEmitter();
  @Output() onClose = new EventEmitter();
  open: boolean = false;
  constructor() { }

  show() {
    this.open = true;
    this.onShow.emit();
  }

  close() {
    this.open = false;
    this.onClose.emit();
  }

}
