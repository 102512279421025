

<div class="widget-body" [class.hide]="!hasData" [class.vertical]="vertical" [class.is-id-count]="isIdCount" [class.is-index-mode]="indexMode">
  <div *ngFor="let bucket of demographicData; trackBy: trackById"
        class="bucket"
        (click)="toggleFilter(bucket)"
        [class.progress-bar-vertical-wrapper]="vertical"
        [class.progress-bar-wrapper]="!vertical">
        <ppc-progress-bar
          [percentage]="bucket.percent"
          [label]="bucket.name"
          [inlineLabel]="false"
          colorOverride="rgba(130,202,208,0.8)"
          [rotateText]="vertical"
          [vertical]="vertical"
          [class.vertical]="vertical"
          [selected]="isSelected(bucket)"
          round="1.0-0"
          [showCounts]="isIdCount"
          [count]="isIdCount ? bucket.individualBucketCount : null"
          [countLabel]="isIdCount ? bucket.individualBucketCountLabel : null"
          [hasZeroCountBool]="hasZeroCount(bucket)"
          >
      </ppc-progress-bar>
      <ppc-index-bar [class.selected]="isSelected(bucket)" [index]="counts.getIndex(bucket.short_id)" *ngIf="indexMode && counts.hasIndex(bucket.short_id)"></ppc-index-bar>
  </div>
</div>
<div class="no-data" [class.hide]="hasData">{{ isTier3 ? 'Data is unavailable in this market' : 'Data Unavailable' }}</div>
