<div class="edit-pane flex">
  <div class="edit-pane-header">
    <div class="edit-pane-header-text">
      <div class="edit-pane-title">
        {{clusterUnderEdit?.id ? "Edit" : "Create"}} Cluster
      </div>
    </div>
    <button (click)="rulesVisible = !rulesVisible" class="btn">{{rulesVisible ? 'Hide' : 'Show'}} Rules</button>
  </div>
  <div class="instructions-rules-container">
    <div class="instructions">
      <div class="edit-pane-section">
        <ppc-input #clusterName *ngIf="clusterUnderEdit" [(ngModel)]="clusterUnderEdit.name" name="cluster_name">
          <label class="ppc-input-label">Name</label>
          <div suffix class="required-suffix" *ngIf="!clusterName.value">*</div>
        </ppc-input>
      </div>
      <div class="edit-pane-section">
        <ppc-textarea #clusterDescription *ngIf="clusterUnderEdit" [(ngModel)]="clusterUnderEdit.description" name="cluster_description">
          <label>Description</label>
        </ppc-textarea>
      </div>
      <div class="download flex-column">
        <h2>Step 1: Download Template</h2>
        Download a template with all of your current discussion cluster data.
        <div>
          <button (click)="downloadTemplate()" class="btn">Download</button>
        </div>
      </div>
      <div class="edit flex-column">
        <h2>Step 2: Edit</h2>
        <div class="edit-instruction">
          Open template in Excel or other .xls compatible program. Edit the fields to modify or add discussions data.
        </div>
      </div>
      <div class="upload flex-column">
        <h2>Step 3: Upload Edited File</h2>
        <div class="flex">
          <div class="half-col">
            <form #templateForm>
              <span>Upload your modified template.</span>
              <div class="file-upload-container">
                <label for="file-upload" class="btn">Choose File</label>
                <input type="file" name="template" id="file-upload" accept=".xls" (change)="saveFilename($event)"/>
                <span *ngIf="fileName" class="file-name">{{fileName}}</span>
              </div>
            </form>
            <div *ngIf="errors.length" class="err-col">
              <div class="errors-found-container">
                <div class="exclamation-container"><i class="fa fa-exclamation-circle"></i></div>
                <div class="errors-found">Errors found in Uploaded Template</div>
                <div class="caret-container" (click)="errorListCollapsed = !errorListCollapsed"><i class="fa fa-caret-down"></i></div>
              </div>
              <table *ngIf="!errorListCollapsed" class="template">
                <tr>
                  <th>Column</th><th>Row</th><th>Error</th>
                </tr>
                <tr *ngFor="let error of errors">
                  <td>{{error.column || '-'}}</td>
                  <td>{{error.row || '-'}}</td>
                  <td [innerHTML]="getSanitizedValue(error.message)"></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rules-list" *ngIf="rulesVisible">
      <h3>Rules for Discussions Bulk Uploader</h3>
      <ul>
        <li *ngFor="let rule of rules">{{rule}}</li>
      </ul>
    </div>
  </div>
  <div class="edit-pane-actions">
    <button type="submit" class="btn" (click)="uploadTemplate($event, templateForm)" [disabled]="!clusterUnderEdit?.name">Save</button>
    <span ppcTooltip="Discussion Clusters with associated audiences or journeys cannot be deleted." [tooltipDisabled]="!nodeHasSubMarkets || !nodeHasJourneySubMarkets">
      <button ppcConfirmation
              *ngIf="clusterUnderEdit?.id"
              class="delete-button btn"
              modalTitle="Are you sure you want to delete '{{clusterUnderEdit?.name}}'?"
              [disabled]="nodeHasSubMarkets || nodeHasJourneySubMarkets"
              [kontinue]="destroyDiscussionClusterNode.bind(this)"
      >Delete</button>
    </span>
    <button mat-button (click)="closeEditPane()">Cancel</button>
  </div>
</div>
