import {startWith, distinctUntilChanged, withLatestFrom, take} from 'rxjs/operators';
import {switchMap, map, filter, tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http'
import {ChangeContext, FetchHierarchy, LoadHierarchy} from "app/hierarchy/hierarchy.actions";
import {fullContext, activeContext} from "app/hierarchy/hierarchy.reducers";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {fetchResource, isLoaded} from "app/shared/utils/fetch-state";
import {AppState} from "app/reducers";
import {Store, select} from '@ngrx/store'
import {LocalStorageService} from "./local-storage.service";
import {Go} from "app/router/router.actions";
import {Hierarchy, HierarchyContext, ContextSlugs} from "app/hierarchy/hierarchy.interface";
import {
  getDefaultHierarchyContext,
  getPath,
  getSlugs,
  getContextSlugs,
  validateSlugs,
  isPrefix,
  getFullProductSlug
} from "app/hierarchy/hierarchy.utils";
import {LoadCurrentUser} from "app/users/user.actions";
import {currentUser} from "app/users/user.reducer";
import {combineLatest, Observable} from "rxjs";
import {UrlService} from 'app/services/url.service'
import { toProperty } from 'app/shared/utils/utils'
import {isEqual} from 'lodash'
import { isDefined } from 'app/shared/utils/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PERMISSION_ACTIVATION, V4 } from 'app/shared/utils/constants';
import { FetchVendorDisplayNames } from 'app/segments-hierarchy/segments-hierarchy.action';
import { hierarchyTreeUrl } from '../shared/constants/clients.urls';


@Injectable()
export class HierarchyService {

  @Effect()
  fetchHierarchy$ = this.actions$.pipe(
    ofType(FetchHierarchy.type),
    (fetchResource(
      () => this.fetchHierarchy().pipe(map(hierarchy => new LoadHierarchy(hierarchy)))
    )));

  @Effect()
  newCurrentUser$ = this.actions$.pipe(
    ofType(LoadCurrentUser.type),
    map(() => new FetchHierarchy()))

  loadedHierarchy$ = this.store.select('fetchStates', FetchHierarchy.type).pipe(
    filter(isLoaded),
    switchMap(() => this.store.select('hierarchy', 'hierarchy')),
    toProperty())

  lastValidContextSlugs$ = combineLatest(
    this.store.select('hierarchy', 'contextSlugs'),
    this.loadedHierarchy$
  ).pipe(
    filter(([ contextSlugs, hierarchy ]) =>
      validateSlugs(hierarchy, getSlugs(contextSlugs))),
    map(([ contextSlugs ]) => contextSlugs),
    toProperty())

  savedContextKey$ = currentUser(this.store).pipe(
    filter(isDefined),
    map(user => `default-context:${btoa(user.email)}`),
    toProperty())

  @Effect({dispatch: false})
  saveLastContext$ = combineLatest(
    this.savedContextKey$,
    this.lastValidContextSlugs$
  ).pipe(
    tap(([key, contextSlugs]) =>
      localStorage.setItem(key, JSON.stringify(contextSlugs))
    ))

  savedContextSlugs$ = this.savedContextKey$.pipe(
    map(key => new LocalStorageService().getValue(key)),
    toProperty())

  defaultContextSlugs$ = this.loadedHierarchy$.pipe(
    map(getDefaultHierarchyContext),
    map<HierarchyContext, ContextSlugs>(getContextSlugs),
    toProperty())

  // Guaranteed to always be a valid context
  // falls back on saved and default contexts
  // Useful for navigating
  validContextSlugs$ = combineLatest(
    this.lastValidContextSlugs$.pipe(startWith(null)),
    this.savedContextSlugs$,
    this.defaultContextSlugs$,
    this.loadedHierarchy$
  ).pipe(
    map(([ lastValidContextSlugs, savedContextSlugs, defaultContextSlugs, hierarchy ]) =>
      [lastValidContextSlugs || {}, savedContextSlugs, defaultContextSlugs]
        .find(slugs => validateSlugs(hierarchy, getSlugs(slugs)))
    ),
    filter(isDefined)
  )

  urlContextSlugs$ = this.urlService.pathParams$.pipe(map(getContextSlugs), toProperty(isEqual))

  @Effect()
  contextChanges$ = this.urlContextSlugs$.pipe(
    distinctUntilChanged(isEqual),
    withLatestFrom(activeContext(this.store)),
    tap(() => this.store.dispatch(new FetchVendorDisplayNames([PERMISSION_ACTIVATION], getFullProductSlug(), V4))),
    filter(([ url, active ]) => !isPrefix(url, active)),
    map(([ urlContext ]) => urlContext),
    map(urlContext => new ChangeContext(urlContext)));

  @Effect({dispatch: false})
  emptyHierarchy$ = this.actions$.pipe(
    ofType(LoadHierarchy.type),
    map((action: LoadHierarchy) => action.hierarchy.clients.length === 0),
    toProperty())

  @Effect()
  redirectOnEmptyHierarchy$ = this.emptyHierarchy$.pipe(
    filter(Boolean),
    switchMap(() => combineLatest(
      this.store.select('permissions', 'own_hierarchy_perms', 'update'),
      currentUser(this.store).pipe(select('id'), filter(isDefined), toProperty())
    )),
    map(([canUpdateOwnHierarcyPerms, userId]) => {
      this.snackbar.open('You have been redirected here because you do not have access to any clients',
        'Ok', {duration: 10000, panelClass: ['warning']})

      return new Go({
        path: canUpdateOwnHierarcyPerms ? ['global', 'admin', 'user-settings', userId]
          : ['login'] // Request access wizard should trigger on login
      })
    }))

  constructor(private http: HttpClient,
    private actions$: Actions,
    private store: Store<AppState>,
    private urlService: UrlService,
    private snackbar: MatSnackBar) { }

  fetchHierarchy(): Observable<Hierarchy> {
    return this.http.get<Hierarchy>(hierarchyTreeUrl())
  }

  fullContext$ = fullContext(this.store)

  getHierarchySlugs(): string {
    let params;
    this.urlService.pathParams$.pipe(take(1)).subscribe(ps => params = ps)
    return getPath(params);
  }

}
