<div class="destinations-page">
  <div class="destinations-upper-page">
    <div class="destinations-overlay-controls">
      <h1 class="destinations-header">Add Destinations</h1>

      <button class="close-btn fa fa-2x fa-times" (click)="closeOverlay()"></button>
    </div>

    <div class="destination-body">
      <p>Fill out the fields below and submit your request to Product Support</p>

      <div class="section-header">
        <h4 *ngIf="partner">{{partner.name}}</h4>
      </div>
      <form #destinationForm="ngForm" class="margin-top-10">
        <mat-label>Destination Setup Level</mat-label>
        <div>
          <span>
            <mat-form-field appearance="outline">
              <span>Client</span>
              <mat-select [(ngModel)]="clientSlug" [disabled]="true" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let client of allClients" [value]="client.slug">
                  {{client.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span>
            <mat-form-field *ngIf="allRegions?.length > 0" appearance="outline">
              <span>Regions</span>
              <mat-select [(ngModel)]="regionSlug" [disabled]="true" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let region of allRegions" [value]="region.slug">
                  {{region.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span>
            <mat-form-field *ngIf="allBrands?.length > 0" appearance="outline">
              <span>Brand</span>
              <mat-select [(ngModel)]="brandSlug" [disabled]="true" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let brand of allBrands" [value]="brand.slug">
                  {{brand.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span>
            <mat-form-field *ngIf="allProducts?.length > 0" appearance="outline">
              <span>Products</span>
              <mat-select [(ngModel)]="productSlug" [disabled]="true" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let product of allProducts" [value]="product.slug">
                  {{product.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        </div>
        <ng-container *ngIf="isDV360">
          <div>
            <mat-label>Entity ID</mat-label>
            <p>
              <mat-form-field appearance="outline" class="material-color">
                <input matInput required
                  (change)="partnerSpecifics.entity_id = partnerSpecifics.entity_id.trim()"
                  [(ngModel)]="partnerSpecifics.entity_id"
                  name="entityId"
                >
                <mat-error *ngIf="partnerSpecifics.entity_id?.length == 0">
                  Entity ID is required
                </mat-error>
              </mat-form-field>
              <span class="margin-left-20">
                Do not include hyphens.
              </span>
            </p>
          </div>
          <div>
            <mat-label>Product</mat-label>
            <p>
              <mat-form-field appearance="outline">
                <input matInput
                  (change)="partnerSpecifics.product = partnerSpecifics.product.trim()"
                  [(ngModel)]="partnerSpecifics.product"
                  name="product">
              </mat-form-field>
            </p>
          </div>
          <div>
            <mat-label>Whitelist Confirmation</mat-label>
            <p>
              <mat-form-field appearance="outline" ppcTooltip="Google has to whitelist LiveRamp to enable us to send data to particular Google DV360 account. Usually your Google Account Manager would be able to help with this request">
                <mat-select [(ngModel)]="partnerSpecifics.whitelist_confirmation" [ngModelOptions]="{standalone: true}">
                  <mat-option [value]=true>Yes</mat-option>
                  <mat-option [value]=false>No</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="isTradeDesk">
          <div>
            <mat-label>The Trade Desk Partner ID</mat-label>
            <p>
              <mat-form-field appearance="outline" class="material-color">
                <input matInput required [(ngModel)]="partnerSpecifics.ttd_partner_id" (change)="partnerSpecifics.ttd_partner_id = partnerSpecifics.ttd_partner_id.trim()" name="ttdPartnerId">
                <mat-error *ngIf="partnerSpecifics.ttd_partner_id?.length == 0">
                  The Trade Desk Partner ID is required </mat-error>
              </mat-form-field>
              <span class="side-detail">
                Do not include hyphens.
              </span>
            </p>
          </div>
          <div>
            <mat-label>The Trade Desk Partner Name</mat-label>
            <div class="flex-direction">
              <mat-form-field appearance="outline">
                <input matInput required [(ngModel)]="destName" (change)="destName=destName.trim()" name="name">
                <mat-error *ngIf="destName?.length == 0"> The Trade Desk Partner Name is required </mat-error>
              </mat-form-field>
              <span class="side-detail">
                The Trade Desk Partner names must be less than 50 characters.
              </span>
            </div>
          </div>
        </ng-container>
        <div *ngIf="!isTradeDesk">
          <mat-label>Destination Name</mat-label>
          <div class='flex-direction'>
            <mat-form-field appearance="outline">
              <input matInput required type="text" [(ngModel)]="destName" (change)="destName=destName.trim()"
                name="destination_name">
              <mat-error *ngIf="destName?.length == 0"> Destination Name is required </mat-error>
            </mat-form-field>
            <span class="side-detail">
              Destination Account name may not be editable if it had been set on a different level. Account names must be less
              than 50 characters.
            </span>
          </div>
        </div>
      </form>
    </div>
    <ng-container *ngIf="errors?.length > 0">
      <div class="error-message">
        <mat-error> Could not create destination. Please find the errors below: </mat-error>
        <div *ngFor="let error of errors">
          <mat-error>* {{ error }}</mat-error>
        </div>
      </div>
    </ng-container>

    <div class='footer'>
      <button  (click)="closeOverlay()" class="pds-btn pds-btn--secondary" >Cancel</button>
      <button  (click)="createDestination()" class="pds-btn pds-btn--primary" [disabled]="!isFormValid()">Add Destination</button>
    </div>
  </div>
</div>
