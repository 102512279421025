<div class="plans-top-row">
  <div class="info-left">
    <div class="page-title">
      <span class="gold-title">Plans</span> Channels
    </div>
    <div *ngIf="channel && channel.name" class="name">{{channel.name}}</div>
  </div>

  <div class="info-right">
    <div class="error-message" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="buttons">
      <button mat-button class="text-button" *ngIf="canShowChannelInfo$ | async" (click)="getChannelInfo()">
        <span class="button-content" ppcTooltip="Download CSV">Channel Info</span>
      </button>
      <span id="import_button">
        <ng-container *checkFeatureAccess="let canAccessScenarios=canAccess; feature: 'scenarios'">
          <button mat-button class="attach-button" *ngIf="canAccessScenarios && !attachedPlan" (click)="dropdown.openDropdown()">
            ATTACH SCENARIO
          </button>
          <button mat-button class="attach-button" *ngIf="canAccessScenarios && attachedPlan" (click)="gotoScenarioPage()">
            VIEW SCENARIO
          </button>
        </ng-container>
        <app-dropdown-with-buttons #dropdown
                                  [options]="unassignedPlans" noOptionsMessage="There are no unassigned scenarios available."
                                  noOptionsLink="Please create a scenario to attach." [onMessageClick]="gotoScenarioSummaryPage.bind(this)"
                                  [onSave]="onSave.bind(this)" filterTitle="Filter Scenarios"
        >
        </app-dropdown-with-buttons>
      </span>
      <button mat-button class="edit-button" (click)="openEditDialog()">
        EDIT CHANNEL
      </button>
    </div>
  </div>
</div>

<div class="page-container">
  <ng-template #showNA> N/A </ng-template>
  <div class="info-wrapper">
    <div class="grid-list margin-btm-10">
      <div class="grid-list-tile">
        <div class="grid-list-tile-header">Audience Name</div>
        <div class="grid-list-tile-body">
          <span *ngIf="channel">{{channel.output.demographic_name}}</span>
        </div>
      </div>
    </div>

    <div class="grid-list">
      <div class="grid-list-tile">
        <div class="grid-list-tile-header">Budget</div>
        <div class="grid-list-tile-body">
          <span *ngIf="selectedPoint" class="stat">
            <span *ngIf="channel.hasBudgetInfo(); else showNA">
              {{selectedPoint.budget | currency:currency?.toUpperCase():'symbol-narrow'}}
            </span>
          </span>
        </div>
      </div>
      <div class="grid-list-tile">
        <div class="grid-list-tile-header">Reach</div>
        <div class="grid-list-tile-body">
          <span *ngIf="selectedPoint" class="stat">{{selectedPoint.reach / 100 | percent: '1.2-2'}}</span>
        </div>
      </div>
      <div class="grid-list-tile" >
        <div class="grid-list-tile-header">Spot/Insertions</div>
        <div class="grid-list-tile-body">
          <span *ngIf="selectedPoint" class="stat">{{selectedPoint.spot | number: '1.0-0'}}</span>
        </div>
      </div>
      <div class="grid-list-tile">
        <div class="grid-list-tile-header">Impressions</div>
        <div class="grid-list-tile-body">
          <span *ngIf="selectedPoint" class="stat">{{selectedPoint.impression | number: '1.0-0'}}</span>
        </div>
      </div>
      <div class="grid-list-tile">
        <div class="grid-list-tile-header">TRP</div>
        <div class="grid-list-tile-body">
          <span *ngIf="selectedPoint" class="stat">{{selectedPoint.trp | number: '1.0-0'}}</span>
        </div>
      </div>
    </div>

    <div class="graph-info">
      <div class="graph-info-item">
        <div>Reach Curve - </div>
        <mat-select (selectionChange)="selectMetric($event)" [(ngModel)]="selectedMetric">
          <mat-option *ngIf="channel && channel.hasBudgetInfo()" value="budget">{{ metricDisplayName("budget") }}</mat-option>
          <mat-option value="impression">{{ metricDisplayName("impression") }}</mat-option>
          <mat-option value="spot">{{ metricDisplayName("spot") }}</mat-option>
          <mat-option value="trp">{{ metricDisplayName("trp") }}</mat-option>
        </mat-select>
      </div>

      <div class="graph-info-item">
        View By <span class="metric_display">Reach</span>
      </div>
    </div>

    <div class="graph-container">
      <div *ngIf="channel && channel.output.points.length === 0" class="no-data">There are no data points for this channel</div>
      <svg></svg>
    </div>
  </div>

  <div class="side-card">
    <div class="grid-list margin-btm-10">
      <div class="grid-list-tile">
        <div class="grid-list-tile-body side-card-header"
             [ngClass]="{'show-error': channel && !channel.hasBudgetInfo()}"
        >
          Media Types and Daypart Percentages
          <div *ngIf="channel && !channel.hasBudgetInfo()"
                class="budget-error-message"
          >
            Daypart percentages are based off budget. Please upload a project from Tardiis with budgets.
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="channel" class="side-card-body accordion-container">
      <div *ngFor="let mediaType of mediaTypes"
            class="accordion-item"
            [class.open]="openMediaType(mediaType)">
        <div class="accordion-item-header" (click)="toggleMediaType(mediaType)">
          <div class="media-type" [style.color]="determineColor(mediaType)">{{mediaType}}</div>
          <div class="total-budget-pct">
            <span *ngIf="channel.hasBudgetInfo() && mediaTypeUsed(mediaType); else showNA">
              {{(selectedPoint.cost === 0 ? 0 : drillDownIndex[selectedPoint.uuid].totals[mediaType] / selectedPoint.cost) | percent:'1.2-2'}}
            </span>
          </div>
        </div>
        <div class="accordion-item-body">
          <div *ngFor="let drillDown of drillDownIndex[selectedPoint.uuid].drillDowns[mediaType]; trackBy: isSameIndex" class="drillDown">
            <span class="dayPartName">{{ daypartDisplayNames[drillDown.day_part_name] }}</span>
            <app-progress-bar
              [hideText]="true"
              [color]="determineColor(mediaType)"
              [percent]="selectedPoint.cost === 0 ? 0 : 100 * (drillDown.budget / selectedPoint.cost)"
              [waitToAnimate]="false">
            </app-progress-bar>
            <span class="dayPartBudgetPct">{{(selectedPoint.cost === 0 ? 0 : drillDown.budget / selectedPoint.cost) | percent:'1.2-2'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- overlay with form -->
<app-slide-in-overlay #overlay>
  <app-channel-form
    *ngIf="channel"
    [channel]="channel"
    (updateEvent)="navigateToIndexPage()"
    (closeEvent)="closeEditDialog()">
  </app-channel-form>
</app-slide-in-overlay>
