import {Component, EventEmitter, Input, Output} from "@angular/core";
import {LookalikeV3} from "../../../../lookalikes-v3/lookalike-v3.model";
import {AudienceV2} from "../../../../audiences-v2/audience-v2.model";
import {isMotivation, isLookalike, isAudience} from "../../audience.utils";

@Component({
  selector: 'ppc-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.sass']
})

export class DetailHeaderComponent {
  @Output() onClose = new EventEmitter();

  @Input() ppcObject: (AudienceV2 | LookalikeV3);
  @Input() ppcObjectType;
  isAudience = isAudience;
  isLookalike = isLookalike;
  isMotivation = isMotivation;

  constructor() {
  }

  closeSelf() {
    this.onClose.emit();
  }

}
