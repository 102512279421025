import { Injectable } from '@angular/core';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { select } from "@ngrx/store";
import * as actions from './journey.actions';
import { fetchResource, ResetFetchState } from 'app/shared/utils/fetch-state';
import { Journey } from './journey.models';
import { MarketLevelDemographic, marketLevelDemographicHasSubMarketEntry } from 'app/insights/insights-components/market-level-demographics/market-level-demographic.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap } from 'rxjs/operators';
import { fullContext } from 'app/hierarchy/hierarchy.reducers';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { selectSelectedJourney } from 'app/journey/journey.reducer';
import * as insightsActions from 'app/insights/insights.actions';
import { UrlService } from 'app/services/url.service';
import { userPreferencesUrl, journeyUrl } from 'app/shared/constants/insights.urls';

@Injectable()
export class JourneyService {

  @Effect()
  fetchJourneys$ = this.actions$.pipe(
    ofType(actions.FetchJourneys.type),
    fetchResource(
      () => this.fetchJourneys().pipe(map(journeys => new actions.LoadJourneys(journeys)))
    )
  )

  @Effect()
  fetchDefaultJourney$ = this.actions$.pipe(
    ofType(actions.FetchDefaultJourney.type),
    fetchResource(
      () => this.fetchDefaultJourney().pipe(map(defaultJourneyId => new actions.LoadDefaultJourney(defaultJourneyId)))
    )
  )

  @Effect()
  saveJourney$ = this.actions$.pipe(
    ofType(actions.SaveJourney.type),
    fetchResource(
      action => this.saveJourney(action.journey).pipe(map(journey => new actions.LoadJourney(journey)))
    )
  )

  @Effect()
  saveDefaultJourney$ = this.actions$.pipe(
    ofType(actions.SaveDefaultJourney.type),
    fetchResource(
      action => this.saveDefaultJourney(action.journeyId).pipe(map(defaultJourneyId => new actions.LoadDefaultJourney(defaultJourneyId)))
    )
  )

  @Effect()
  destroyJourney$ = this.actions$.pipe(
    ofType(actions.DestroyJourney.type),
    fetchResource(
      action => this.destroyJourney(action.journeyId).pipe(map(journey => new actions.RemoveJourney(action.journeyId)))
    )
  )

  @Effect()
  duplicateJourney$ = this.actions$.pipe(
    ofType(actions.DuplicateJourney.type),
    fetchResource(
      action => this.duplicateJourney(action.journeyId).pipe(map(journey => new actions.LoadJourney(journey)))
    )
  )

  @Effect()
  changeContext$ = fullContext(this.store).pipe(
    mergeMap(() => [
      new ResetFetchState(actions.FetchJourneys),
      new ResetFetchState(actions.FetchDefaultJourney),
      new actions.SetJourneyTab(null),
      new actions.SetSelectedJourneyId(null),
      new actions.SetSelectedBrandId(null),
      new insightsActions.SetSegmentContexts(null, "journey"),
    ])
  )

  @Effect()
  addSubMarketBucketEntries$ = this.actions$.pipe(
    ofType(insightsActions.LoadMarketLevelDemographics.type),
    withLatestFrom(this.store.select("journey").pipe(select(selectSelectedJourney))),
    filter(([action, journey]: [insightsActions.LoadMarketLevelDemographics, Journey]) => {
      return journey && !journey.sub_markets.every(subMarket => {
        return action.marketLevelDemographics.every(mld => marketLevelDemographicHasSubMarketEntry(mld, subMarket))
      })
    }),
    mergeMap(([action, journey]: [insightsActions.LoadMarketLevelDemographics, Journey]) => {
      const newSubMarkets = journey.sub_markets.filter(subMarket => {
        return !action.marketLevelDemographics.every((mld: MarketLevelDemographic) => marketLevelDemographicHasSubMarketEntry(mld, subMarket))
      })
      return newSubMarkets.map(subMarket => new insightsActions.AddSubMarketBucketEntries(action.insightsContext, subMarket, 'Mekko'));
    }), )

  @Effect()
  clearSelectedStages$ = this.store.select("journey", "selectedJourneyId").pipe(
    map(() => new actions.ClearSelectedStages())
  )

  @Effect()
  fetchMarketLevelSurveys$ = this.store.select("journey").pipe(
    select(selectSelectedJourney),
    filter(Boolean),
    map(({id: journeyId}) => new insightsActions.FetchMarketLevelSurveys(journeyId, "Journey", "journey"))
  )

  constructor(private actions$: Actions,
    private http: HttpClient,
    private store: Store<AppState>,
    private urlService: UrlService) { }

  // If no product_slug is provided, the active context will be inserted by HierarchyInterceptor
  fetchJourneys(product_slug?: string): Observable<Journey[]> {
    const headers = product_slug ? {'x-context': product_slug} : {}
    return this.http.get(journeyUrl(), {headers}) as Observable<Journey[]>;
  }

  fetchDefaultJourney(): Observable<number> {
    return this.http.get(userPreferencesUrl("default-journey")) as Observable<number>;
  }

  saveJourney(journey: Journey): Observable<Journey> {
    return this.http[journey.id ? "put" : "post"](journeyUrl(journey.id), {journey}) as Observable<Journey>;
  }

  saveDefaultJourney(journeyId: number): Observable<number> {
    return this.http.put(userPreferencesUrl("default-journey"), {config: journeyId}) as Observable<number>;
  }

  destroyJourney(journeyId: number): Observable<null> {
    return this.http.delete(journeyUrl(journeyId)) as Observable<null>;
  }

  duplicateJourney(journeyId: number): Observable<Journey> {
    return this.http.post(journeyUrl(`${journeyId}/duplicate`), null) as  Observable<Journey>;
  }
}
