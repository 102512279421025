import { DataPermissions } from "app/data_permissions/data_permissions.model";
import * as actions from "app/data_permissions/data_permissions.actions";
import { keyBy } from "lodash";

export interface State {[identifier: string]: DataPermissions}

const defaultState = {};

export function reducer(state: State = defaultState, action: actions.All): State {
  switch (action.type) {
    case actions.LoadDataPermissions.type:
      const new_permissions = keyBy(action.permissions, "identifier")
      return {...state, ...new_permissions}
    case actions.ClearDataPermissions.type:
      return defaultState;
    default:
      return state;
  }
}
