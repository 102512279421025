<ppc-loading-overlay *ngIf="uploadAdminService.loading$ | async"></ppc-loading-overlay>
<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
  <h1><span class="object-name">Discussions Upload</span></h1>
</header>
<div class="upload-container">
  <div class="flex">
    <div class="library-container" [class.has-discussions]="(clusterNodes$ | async).length">
      <div class="discussions-upload-header" [class.has-discussions]="(clusterNodes$ | async).length">
        <div *ngIf="!(clusterNodes$ |async).length">
          <h1>No Discussions to Show</h1>
          <div>To Create Your first Discussion, click the button below.</div>
        </div>
        <div class="create-new-discussion-btn header-btn" (click)="openEditPane()">
          <img src="assets/icons/add-white.svg"/>
          New Discussion
        </div>
      </div>
      <ppc-discussion-cluster-node-library></ppc-discussion-cluster-node-library>
    </div>
  </div>
</div>
<app-slide-in-overlay [width]="1100" [active]="clusterUnderEdit">
  <ppc-discussions-upload-admin-form *ngIf="clusterUnderEdit"></ppc-discussions-upload-admin-form>
</app-slide-in-overlay>
