import { DataDogService } from './../../scripts/datadog.service';
import uuidv4 from 'uuid/v4';
import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpClient, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class XRequestIDInterceptor implements HttpInterceptor {

  constructor(private dataDogService: DataDogService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request_id = uuidv4();
    if (this.dataDogService.navigationLogger) {
      this.dataDogService.DD_LOGS.addLoggerGlobalContext('request_id', request_id);
    }
    request = request.clone({ setHeaders: { 'X-Request-ID': request_id }});

    return next.handle(request);
  }
}
