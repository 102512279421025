import { Action } from '@ngrx/store';

export class FetchAdminClients implements Action {
  static readonly type = 'FETCH_ADMIN_CLIENTS';
  readonly type = FetchAdminClients.type;

  constructor(public version?: string) {}
}

export class LoadAdminClients implements Action {
  static readonly type = 'LOAD_ADMIN_CLIENTS';
  readonly type = LoadAdminClients.type;

  constructor(public adminClients) {}
}

export type ReducerActions = FetchAdminClients | LoadAdminClients;
