import { OnChanges, SimpleChanges, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AudienceV2 } from 'app/audiences-v2/audience-v2.model';
import { constructPermissionsFromDisplay, displayFusion } from 'app/audiences/overview-v3/audience-detail/audience-detail.utils';
import { AccordianTemplate, CUSTOM_AUDIENCE_CREATION, DisplayName, OverviewV3Service, PERMISSIONS_SAVED_SUCCESSFULLY } from 'app/audiences/overview-v3/overview-v3.service';
import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import { PpcObjectActivation } from 'app/models/activation.model';
import { MotivationV1 } from 'app/models/motivations/motivations-v1.model';
import { Transaction } from 'app/models/transaction.model';
import { AppState } from 'app/reducers';
import { getPermissionDisplayName } from 'app/services/data_permissions.service';
import { isDefined } from 'app/shared/utils/utils';
import { filter as _filter, sortBy, map as _map, find, cloneDeep} from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter, first, flatMap, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ppc-detail-permissions',
  templateUrl: './detail-permissions.component.html',
  styleUrls: ['./detail-permissions.component.sass']
})
export class DetailPermissionsComponent implements OnInit, OnChanges {

  @Input() public set ppcObject(value: AudienceV2 | LookalikeV3 | MotivationV1) {
    if (value !== this.ppcObject) {
      this.ppcSubject.next(value);
    }
  }

  @Input() drawerContent: Array<AccordianTemplate>;
  @Input() initialStep: number;
  @Input() resetAccordionState: boolean;

  displayPermissions: DisplayName[] = [];
  defaultDisplayPermissions: DisplayName[] = [];
  isEditingPermissions: boolean = false;
  ppcSubject = new BehaviorSubject<AudienceV2 | LookalikeV3 | MotivationV1>(undefined);
  ppcObjectEntity: AudienceV2 | LookalikeV3 | MotivationV1;

  constructPermissionsFromDisplay = constructPermissionsFromDisplay;

  canEditAudiencePermissions$ = this.store.select("permissions", "manage_audience_permissions", "update");

  statusHistory$ = this.ppcSubject.pipe(
    filter(isDefined),
    flatMap((aud: AudienceV2 | LookalikeV3 | MotivationV1) =>
      this.overviewService.getActivationsForAudience(aud.identifier).pipe(
        map(activations => {
          const createTx = find(aud.transactions, {action: 'created_at'})
                         || {created_at: aud.created_at} as Transaction
          const creation = this.createActivity(createTx, 'Created');

          const updates = (aud.transactions || [])
            // This is the list of all fields that users can edit
            .filter(tx => ['name', 'description', 'rules'].includes(tx.action))
            .map(tx => (this.createActivity(tx, 'Updated')))
            // transactions aren't grouped so creation also leaves records for each field set
            // we filter those out here by ignoring transactions within 1.5 seconds of creation
            .filter(tx => Math.abs(+tx.date - +creation.date) > 1500)

          const tardiisSends = (aud.transactions || [])
            .filter(tx => tx.action === 'send_to_tardiis')
            .map(tx => (this.createActivity(tx, 'Sent to Tardiis')))

          const acts = activations.map(act => (this.createActivationActivity(act, `Sent to ${act.partner_name}`)
          ))

          const fusion = displayFusion(aud);

          return [creation, ...updates, ...tardiisSends, ...acts, fusion].filter((obj) => obj).sort((a, b) => +b.date - +a.date)
        }))));

  constructor(private store: Store<AppState>,
    private overviewService: OverviewV3Service) {
  }

  ngOnInit(): void {
    this.ppcSubject.pipe(
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(ppcObj => {
      this.ppcObjectEntity = ppcObj;
      this.displayPermissions = this.setDisplayPermissions(ppcObj.permissions);
      this.refreshDisplayPermissions(this.displayPermissions);
      this.isEditingPermissions = false;
    })
  }

  toggleEditingStatus() {
    this.isEditingPermissions = !this.isEditingPermissions;
  }

  setDisplayPermissions(permissions): DisplayName[] {
    return _filter(sortBy(_map(Object.keys(permissions), (permissionKey) => {
      return { displayName: getPermissionDisplayName(permissionKey), isPermissible: permissions[permissionKey] }
    }), 'displayName'), permission => permission.displayName && !permission.displayName.includes(CUSTOM_AUDIENCE_CREATION));
  }

  updateObjectPermissions() {
    this.ppcObjectEntity['permissions'] = {...this.ppcObjectEntity['permissions'], ...this.constructPermissionsFromDisplay(this.displayPermissions)};
    this.overviewService.updatePpcObjectPermissions(this.ppcObjectEntity)
      .pipe(first())
      .subscribe((data) => {
        this.displayPermissions = !!data ? this.setDisplayPermissions(data['permissions']) : this.displayPermissions;
        this.refreshDisplayPermissions(this.displayPermissions);
        this.toggleEditingStatus();
        this.overviewService.displaySnackBar(PERMISSIONS_SAVED_SUCCESSFULLY, 3000);
      });
  }

  cancel() {
    this.displayPermissions = cloneDeep(this.defaultDisplayPermissions);
    this.toggleEditingStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!!changes || !!!changes['ppcObject'] || !!!changes['currentValue']) {
      this.isEditingPermissions = false;
    }
  }

  createActivity(transaction: Transaction, action: string): DisplayTransaction {
    return {
      date: new Date(transaction.created_at ),
      user: transaction.first_name ? `by ${transaction.first_name} ${transaction.last_name}`
        : `Username not available`,
      action: action
    }
  }

  createActivationActivity(transaction: PpcObjectActivation, action: string): DisplayTransaction {
    return {
      date: new Date(transaction.activated_at),
      user: transaction.full_name ? `by ${transaction.full_name}` : `Username not available`,
      action: `Sent to ${transaction.partner_name}`
    }
  }

  refreshDisplayPermissions(displayPermissions: DisplayName[]): void {
    this.defaultDisplayPermissions = cloneDeep(displayPermissions);
  }
}


export interface DisplayTransaction {
  date: Date;
  user: string;
  action: string;
}
