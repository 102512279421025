<p *ngIf="zeroDefaultSurveys">
  Please select {{ insightsContext == 'grow' ? 'an audience within your chart' : 'a stage within your journey' }} to view associated custom surveys.
</p>

<p *ngIf="!zeroDefaultSurveys && (selectedSurveys && selectedSurveys.length === 0)">
  {{ noCustomSurveySubject }}<ng-container *ngIf="canUploadSurveys$ | async"> To create a new survey via the bulk uploader, <a (click)="navigateToSurveysBulkUpload()">click here.</a></ng-container>
</p>

<p *ngIf="selectedSurveys && selectedSurveys.length > 0">
  View custom survey responses.
</p>
<div class="market-level-survey-search">
  <div class="market-level-survey-search-container">
      <i class="fa fa-search" (click)="input.focus()"></i>
      <input #input ngModel (ngModelChange)="updateSearch($event)" placeholder="Search">
      <i class="fa fa-close" *ngIf="searchInput?.length" (click)="input.value = ''; updateSearch('');"></i>
  </div>
</div>
<div class="no-search-results" *ngIf="searchInput?.length && !selectedQuestions?.length">
  <i class="fa fa-warning"></i> No results found
</div>

<div #scrollWindow class="scroll-window">
    <ppc-virtual-scroll #scroller
                        class="market-level-survey-container"
                        [items]="selectedQuestions"
                        [class.hide]="selectedQuestions?.length == 0"
                        [getStartingItemHeight]="getStartingItemHeight.bind(this)"
                        [isItemSticky]="isFirstSurveyQuestion.bind(this)"
                        [itemMargin]="5">
      <ppc-virtual-scroll-item *ngFor="let question of (scroller.viewPortItems$ | async); trackBy: trackById" [item]="question">
          <div class="market-level-survey-header" *ngIf="isFirstSurveyQuestion(question)">
              <div class="survey-name" [innerHtml]="highlightSearchHits(getSurveyNameFromQuestion(question))"></div>
              <span *ngIf="getSurveyFromQuestion(question)?.resource_id" class="survey-path">
                  {{ getPathName(getSurveyFromQuestion(question)) }}
                  <button class="fa fa-ellipsis-v menu-icon hoverable" mat-icon-button [matMenuTriggerFor]="appMenu"></button>
                  <mat-menu #appMenu="matMenu">
                    <button (click)="hideSurvey(getSurveyFromQuestion(question))" mat-menu-item>Hide</button>
                  </mat-menu>
                </span>
            </div>
            <ppc-market-level-survey-question [question]="question" [highlightSearchHits]="highlightSearchHits.bind(this)"></ppc-market-level-survey-question>
      </ppc-virtual-scroll-item>
    </ppc-virtual-scroll>
</div>
