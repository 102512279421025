<div class="shared-interest-segment">
  <div class="shared-interest-segment-name">
    {{ segment.name }}
    <div class="shared-interest-segment-expand" (click)="toggleSegment()" [class.invisible]="segment.path?.is_leaf">
      <i class="fa fa-fw fa-chevron-right" [class.rotate-90]="!!expanded" *ngIf="!loadingChildren"></i>
      <i class="fa fa-fw fa-refresh fa-spin" *ngIf="loadingChildren"></i>
    </div>
  </div>

  <div class="shared-interest-segment-progress-bar-container" (click)="toggleFilter()">
    <div class="shared-interest-segment-progress-bar">
      <ng-template #tooltip>
        <div class="shared-interest-tooltip">
            <span>{{this.count | number}} | {{(this.percent * 100) | number:"1.0-1"}}%</span>
        </div>
      </ng-template>
      <div class="shared-interest-segment-progress-bar-progress" ppcTooltip [tooltipTemplate]="tooltip" [style.width]="progressBarWidth"
        [style.background]="progressBarColor" [class.selected]="isFiltered">

      </div>
    </div>
  </div>
</div>
<div class="shared-interest-segment-children" *ngIf="segment.children && expanded">
  <ppc-shared-interest-segment *ngFor="let child of children" [segment]="child" (childrenLoaded)="childrenLoaded.emit()"></ppc-shared-interest-segment>
</div>
