import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'ppc-carousel',
  templateUrl: './ppc-carousel.component.html',
  styleUrls: ['./ppc-carousel.component.sass']
})
export class PpcCarouselComponent {
  currentSlide = 0;

  constructor(public dialogRef: MatDialogRef<PpcCarouselComponent>, @Inject(MAT_DIALOG_DATA) public slides: Slide[]) { }

  close() {
    this.dialogRef.close();
  }

  next(index) {
    this.currentSlide = this.currentSlide + 1;
  }

  previous(index) {
    this.currentSlide = this.currentSlide - 1;
  }
}

interface Link {
  classNames?: string,
  text?: string,
  click?: () => void,
  openInNewTab?: boolean
}

export interface Slide {
  title: string,
  description?: string,
  listDescription?: string,
  image?: string,
  listItems?: string[],
  links?: Link[],
  imageHeight?: string,
  imageWidth?: string,
  height?: string
}

