<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>

<div class="comparisons" *ngLet="{
  permissions: (permissions$ | async),
  filters: (filters$ | async),
  focusedCells: (focusedCells$ | async)} as opts" left>
    <div class="comparisons-header">
      <div class="comparisons-header-left">
        <div class="page-title">
          <span class="category">Insights</span> Overlap
        </div>
      </div>
    </div>
    <div class="comparison-picker-row" *ngIf="comparisons.length">
      <ppc-searchable-dropdown #comparisonPicker *ngIf="comparisons?.length && selectedComparison" itemLabelProp="name" mapFromProperty="id"
        [allowClear]="false" [(ngModel)]="selectedComparison.id" [items]="comparisons">
        <ng-template #itemTemplate let-item="item">
          <div class="comparison-item" [class.selected]="selectedComparison === item">
            <button class="single-select-item" (click)="onComparisonSelect(item.id); comparisonPicker.open = false"
              *ppcCheckOverflow="let hasOverflow=hasXOverflow" [ppcTooltip]="item?.name" [tooltipDisabled]="!hasOverflow"
              tabIndex="0">
              <i class="fa fa-flag-o" [class.invisible]="!item.default"></i>{{ item?.name }}
            </button>
          </div>
        </ng-template>
      </ppc-searchable-dropdown>
      <div class="comparison-actions">
        <ppc-icon-button icon="plus" ppcTooltip="Create" *ngIf="opts.permissions?.create" (click)="create()"></ppc-icon-button>
        <ppc-icon-button icon="pencil" ppcTooltip="Edit" *ngIf="selectedComparison && opts.permissions?.update" (click)="edit()"></ppc-icon-button>
        <ppc-icon-button icon="clone" ppcTooltip="Copy" *ngIf="selectedComparison && opts.permissions?.create" (click)="duplicate()"></ppc-icon-button>
        <ppc-icon-button ppcConfirmation icon="trash" ppcTooltip="Delete" *ngIf="selectedComparison && opts.permissions?.destroy" [kontinue]="deleteComparison.bind(this)"></ppc-icon-button>
      </div>
      <div class="context-count-container">
        <div class="context-count" *ngIf="opts.focusedCells.length && counts.totalCount != null">
          <div class="context-count-label">{{ countLabel }}</div>
          <div class="context-count-count">
            <i class="fa fa-user"></i>
           <span>{{ counts.totalCount | number }}</span>
          </div>
        </div>
      </div>
    </div>
    <ppc-filter-bar
      [filters]="filters$ | async"
      [class.show]="opts.filters.length"
      (clearFilters)="clearFilters(opts.filters)"
      (toggleFilter)="toggleFilter($event)">
    </ppc-filter-bar>
    <div class="comparison-chart-container" *ngIf="selectedComparison && comparisons?.length">
      <ppc-split-screen  leftLabel="Chart"
                         rightLabel="Insights"
                         (focusChange)="setSplitScreenFocus($event)">
          <ppc-comparison-chart left [comparison]="selectedComparison"></ppc-comparison-chart>
          <ppc-insights right [segmentContexts$]="segmentContexts$"></ppc-insights>
          <div gutterOverlay class="gutter-overlay" *ngIf="!opts.focusedCells.length">
            <div class="gutter-overlay-info" *ngIf="!insightsInfoTooltipDismissed">
              <div class="gutter-overlay-info-dismiss" (click)="insightsInfoTooltipDismissed = true"><i class="fa fa-close"></i></div>
              <div class="gutter-overlay-info-text">Click one or more cells to View Insights</div>
              <div class="gutter-overlay-info-caret"></div>
            </div>
          </div>
      </ppc-split-screen>

    </div>
    <div *ngIf="comparisons?.length == 0 && opts.permissions?.create" class="create-new-comparison-container">
      <div class="create-new-comparison header-btn" (click)="create()">
        <img src="assets/icons/add-white.svg" />
        New Chart
      </div>
      Click above to build your overlap chart.
    </div>
  </div>

<app-slide-in-overlay [active]="!!(comparisonUnderEdit$ | async)" [width]="1100">
  <ppc-axis-edit-form *ngIf="comparisonUnderEdit$ | async" [comparisonList]="comparisons">
  </ppc-axis-edit-form>
</app-slide-in-overlay>
