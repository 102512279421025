import { NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgLetModule } from 'ng-let';

import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { OverviewV3Component } from 'app/audiences/overview-v3/overview-v3.component';
import { LookalikeOverlayModule } from 'app/audiences/lookalike-overlay/lookalike-overlay.module';
import { AudienceDetailComponent } from 'app/audiences/overview-v3/audience-detail/audience-detail.component';
import { AudienceActionMenuComponent } from './audience-action-menu/audience-action-menu.component';
import { OverviewV3Service } from './overview-v3.service';
import { ActivationOverlayModule } from "app/audiences/overview-v3/activation-overlay/activation-overlay.module";
import { ActivationDestinationsOverlayModule } from "app/audiences/overview-v3/activation-destinations-overlay/activation-destinations-overlay.module";
import { AudienceBuilderModule } from 'app/audience-builder/audience-builder.module';
import { NewDestinationOverlayModule } from 'app/audiences/overview-v3/new-destination-overlay/new-destination-overlay.module';
import { AudienceRulesDetailComponent } from './audience-rules-detail/audience-rules-detail.component';
import { AudienceRulesDetailGroupComponent } from './audience-rules-detail/audience-rules-detail-group/audience-rules-detail-group.component';
import { DetailHeaderComponent } from "../shared/details-components/header/detail-header.component";
import { DetailInfoPaneComponent } from "app/audiences/shared/details-components/info-pane/detail-info-pane.component";
import { LookalikeDetailGraphComponent } from "app/audiences/overview-v3/lookalike-detail/lookalike-detail-graph/lookalike-detail-graph.component";
import { AudienceMapper } from '../../audience-builder/audience-mappers/segments-api';
import {AddressableCountMetadataService} from "../../models/addressable-count-metadata/addressable-count-metadata.service";
import { IndexReportModule } from 'app/index-report/index-report.module';
import { CreateLookalikeModule } from './create-lookalike/create-lookalike.module';
import {LookalikeDetailComponent} from "./lookalike-detail/lookalike-detail.component";
import {MotivationDetailComponent} from "./motivation-detail/motivation-detail.component";
import { ActionMenuComponent } from '../action-menu/action-menu.component';
import { MotivationAudienceFormComponent } from 'app/motivation-audience/motivation-audience-form/motivation-audience-form.component';
import { DetailPermissionsComponent } from '../shared/details-components/detail-permissions/detail-permissions.component';
import { EpcComponentsModule } from 'app/modules/epc-components.module';
import { InsightDependancyComponent } from './audience-dependancy/insights-dependancy/insight-dependancy/insight-dependancy.component';

@NgModule({
  imports: [
    PpcComponentsModule,
    LookalikeOverlayModule,
    NgLetModule,
    ActivationOverlayModule,
    ActivationDestinationsOverlayModule,
    NewDestinationOverlayModule,
    AudienceBuilderModule,
    IndexReportModule,
    CreateLookalikeModule,
    EpcComponentsModule
  ],
  declarations: [
    AudienceDetailComponent,
    LookalikeDetailComponent,
    MotivationDetailComponent,
    OverviewV3Component,
    AudienceActionMenuComponent,
    AudienceRulesDetailComponent,
    AudienceRulesDetailGroupComponent,
    DetailHeaderComponent,
    DetailInfoPaneComponent,
    LookalikeDetailGraphComponent,
    ActionMenuComponent,
    MotivationAudienceFormComponent,
    DetailPermissionsComponent,
    InsightDependancyComponent
  ],
  providers: [
    OverviewV3Service,
    AddressableCountMetadataService,
    DecimalPipe,
    AudienceMapper
  ]
})

export class OverviewV3Module { }
