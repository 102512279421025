import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Component, OnInit, Input, Output, ViewChild, EventEmitter  } from '@angular/core';
import { Category } from 'app/toolbox/category.interface';
import { Unit } from '../unit.interface';
import { AppState } from 'app/reducers';
import { selectHiddenUnits } from 'app/toolbox/toolbox.reducers';
import { EditUnit, LoadUnit, ResetEditUnit } from "app/toolbox/toolbox.actions";
import { compareKey } from 'app/shared/utils/utils';
import { groupBy, sortBy, cloneDeep, values } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PpcHttpService, apiUrl } from 'app/services/ppc_http.service';

@Component({
  selector: 'app-multi-unit-dialog',
  templateUrl: './multi-unit-dialog.component.html',
  styleUrls: ['./multi-unit-dialog.component.sass']
})
export class MultiUnitDialogComponent implements OnInit {
  @ViewChild('multiUnitDialog', { static: true }) dialog: any;
  @Input() unit: Unit;
  @Input() category: Category;
  @Input() categoryNameRequest: string;
  @Output() modalMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  cmpPrnt = this; // to allow closing modal from form context
  units$: Unit[];
  modus: string = 'create';
  toggle = null
  unitUnderEdit = null;
  unitUnderEdit$: Observable<Unit>;

  constructor(
    private store: Store<AppState>,
    private http: PpcHttpService
  ) {
    this.unitUnderEdit$ = this.store.select('toolbox', 'unitUnderEdit');
  }

  ngOnInit() {
    this.unitUnderEdit$.subscribe(unit => { this.unitUnderEdit = unit; });
    this.store.select('toolbox').pipe(select(selectHiddenUnits))
      .subscribe(units => { this.units$ = cloneDeep(units.sort(compareKey('name')))});
  }

  setToggle(t) {
    return this.toggle = t;
  }

  editUnit(unit: Unit, modus: string = 'create') {
    this.modus = modus;
    this.store.dispatch(new EditUnit(cloneDeep(unit)))
  }

  componentSelected() {
    const hasStatus = (c) => c.status === true;
    return this.units$.some(hasStatus);
  }

  updateHiddenUnits() {
    const unitIds = this.units$
      .filter(unit => unit.status)
      .map(unit => unit.id);
    const params = {
      category: this.category,
      switch: 1
    }
    // PUT collection of checked units
    this.http.put(this.unitsStatusURL(params), unitIds).pipe(
      tap(() => this.close()), )
      .subscribe(
        (res) => res.forEach((u) => this.store.dispatch(new LoadUnit(u))),
        (err) => console.error(err)
      );
  }

  close() {
    this.dialog.close();
    this.modalMode.emit(false);
  }

  unitsStatusURL(params): string {
    return apiUrl(`/toolbox/units/status/${ params.switch || 1}/category/${params.category.id}`);
  }

  handleModalMode(mode: boolean) {
    this.modalMode.emit(mode);
    if (mode === false) {
      this.modus = 'create';
    }
  }
}
