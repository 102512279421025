import {User} from "./user.interface";
import * as Actions from './user.actions'
import {keyBy, omit} from 'lodash';
import {createSelector, Store} from "@ngrx/store";
import {memoAndShare} from "app/shared/utils/utils";
import {map} from 'rxjs/operators'
import {Observable} from 'rxjs'
import {AppState} from "app/reducers"

export interface State {
  loginUserId: number;
  users: { [userId: string]: User };
  userUnderEdit?: User;
  toActivate?: boolean;
}

const defaultState: State = {
  loginUserId: null,
  users: {}
}

export function reducer(state: State = defaultState, action: Actions.All): State {
  switch (action.type) {
    case Actions.LoadUsers.type:
      return { ...state, users: keyBy(action.users, 'id') };
    case Actions.LoadCurrentUser.type:
      return { ...state,
        loginUserId: action.user.id,
        users: {...state.users, [action.user.id]: action.user} }
    case Actions.AddUser.type:
    case Actions.EditUser.type:
      return { ...state, users: { ...state.users, [action.user.id]: action.user } }
    case Actions.RemoveUser.type:
      return { ...state, users: omit(state.users, [action.id]) }
    case Actions.SetEditUser.type:
      return {...state, userUnderEdit: action.user, toActivate: action.toActivate}
    default:
      return state;
  }
}

export const loggedInUser = createSelector(
  (state: State) => state.loginUserId,
  (state: State) => state.users,
  (id, users) => users[id]
)

export const currentUser = memoAndShare((store: Store<AppState>): Observable<User> =>
  store.select('users').pipe(map(loggedInUser)))
