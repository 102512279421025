<ppc-accordion
  [drawerContent]="drawerContent"
  [initialStep]="initialStep"
  [resetAccordionState]="resetAccordionState"
  >
  <ng-template #statusTemplate let-content="content">
    <ul id="status" class="mat-panel-scrollable" >
        <li class="status-item" *ngFor="let status of statusHistory$ | async;">
            {{ status.date | date: 'MM/dd/yy' }}
            {{ status.action }}
            {{ status.user }}
        </li>
    </ul>
  </ng-template>
  <ng-template #permissionsTemplate let-content="content">
      <div class="permissions-pane">
            <table class="permissions-pane">
                <tr *ngFor="let displayPermission of displayPermissions; let i = index">
                    <td class="permission-item">
                        <div *ngIf="isEditingPermissions; then dispCheckBox else dispIcons" ></div>
                        <ng-template #dispIcons>
                            <i [ngClass]="{ 'fa fa-ban': !displayPermission.isPermissible }"></i>
                            <span [ngClass]="{ 'permissible': displayPermission.isPermissible }">{{ displayPermission.displayName }}</span>
                        </ng-template>
                        <ng-template #dispCheckBox>
                            <ppc-checkbox [(ngModel)]="displayPermission.isPermissible">{{ displayPermission.displayName }}</ppc-checkbox>
                        </ng-template>
                    </td>
                    <td>
                        <button *ngIf="(canEditAudiencePermissions$ | async) && !isEditingPermissions && i==0" class="button primary" (click)="toggleEditingStatus()">Edit</button>
                    </td>
                </tr>
                <tr>
                    <td *ngIf="isEditingPermissions">
                        <button class="button primary" (click)="updateObjectPermissions()">Save</button>
                        <button class="button secondary" (click)="cancel()">Cancel</button>
                    </td>
                </tr>
            </table>
        </div>
  </ng-template>
</ppc-accordion>

