import {Component, OnDestroy, OnInit} from '@angular/core';
import { Subject } from "rxjs";
import {filter, takeUntil} from 'rxjs/operators';
import { Store, select } from "@ngrx/store";
import { AppState } from "app/reducers"
import { flatMap, map as _map, truncate, get, sortBy } from "lodash"
import { Snapshot, selectActiveSnapshot } from 'app/snapshots/snapshots.reducer';
import { isDefined } from 'app/shared/utils/utils';

export interface ConfidenceSizes {[confidence: number]: {people: number}}

@Component({
  selector: 'ppc-propensity-overview',
  templateUrl: './propensity-overview.component.html',
  styleUrls: ['./propensity-overview.component.sass']
})
export class PropensityOverviewComponent implements OnInit, OnDestroy {
  snapshot: Snapshot;
  ngUnsubscribe: Subject<boolean> = new Subject();
  confidence: number;
  count: number;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store.select("snapshots").pipe(select(selectActiveSnapshot), filter(isDefined)).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((snapshot: Snapshot) => this.snapshot = snapshot);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get fullPathParts(): string {
    const pathParts = flatMap(this.snapshot.filters, f => _map(f, "pathParts"));
    return pathParts.filter(Boolean).map(f => f.join(" > ")).join(", ");
  }

  get displayPathParts(): string {
    return truncate(this.fullPathParts, {length: 200});
  }

  get subMarkets() {
    return sortBy(this.snapshot.sub_markets, "name")
  }

  getConfidenceSizes(id: number): ConfidenceSizes {
    const confidence =  this.snapshot.confidence_counts.label_confidence_summary.find((lc) => lc.label_id == id)
    return get(confidence, "confidence_counts");
  }
}
