import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createSelector } from "@ngrx/store";

import * as actions from "app/admin/destination-management/destination-management.actions";
import { Destination } from "app/admin/destination-management/destination.model";
import { PageInfo } from 'app/models/paginable.model';

export interface State extends EntityState<Destination> {
  pageInfo: PageInfo;
}

const adapter: EntityAdapter<Destination> = createEntityAdapter<Destination>({
  selectId: destination => destination.id,
  sortComparer: false
});

const initialState: State = adapter.getInitialState({
  pageInfo: {
    limit: 0,
    offset: 0,
    totalCount: 0
  }
});

export function reducer(state: State = initialState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.LoadDestinations.type:
      return adapter.setAll(action.destinations, {
        ...state,
        pageInfo: action.pageInfo
      });
    case actions.LoadPartialDestination.type:
      return adapter.updateOne({id: action.destination.id, changes: action.destination}, state);
    default:
      return state;
  }
}

const { selectAll } = adapter.getSelectors();

export const getDestinationsList = createSelector(
  selectAll,
  (destinationsList) => destinationsList.map(destination => destination as Destination)
);
