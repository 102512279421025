import { Component, OnInit, ChangeDetectorRef, Input, ViewChild, ElementRef, OnChanges, forwardRef, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ppc-input',
  templateUrl: './ppc-input.component.html',
  styleUrls: ['./ppc-input.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => PpcInputComponent),
      multi: true
    }
  ]
})
export class PpcInputComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild('prefix', { static: true }) prefix: ElementRef;
  @ViewChild('suffix', { static: true }) suffix: ElementRef;
  @ViewChild('label') label: ElementRef;
  @ViewChild('input', { static: true }) input: ElementRef;
  @Input() noLabelFloat: boolean = false;
  @Input() type: string;
  @Input() validators: InputValidator[] = [];
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() softEnforceMaxLength: boolean;
  @Input() min: number;
  @Input() max: number;
  @Input() errorMessage: string;
  @Input() placeholder: string;
  @Input() spellcheck: boolean = true;
  @Input() value: string;
  @Input() disabled: boolean = false;
  @Input() noLabel: boolean = false;
  @Input() noErrorContainer: boolean = false;
  @Input() alwaysFloatLabel: boolean = false;
  @Input() showErrorsIfUntouched: boolean = false;
  @Input() forceValidation: boolean = false;
  @Input() autofocus: boolean = false;
  @Input() tabindex: number;
  @Input() errors: string[] = [];
  @Input() markAsUntouched: boolean = false;

  public focused: boolean = false;
  public touched: boolean = false;
  hasErrors$ = new BehaviorSubject(false);

  constructor(private sanitizer: DomSanitizer, private changeDetector: ChangeDetectorRef, public elem: ElementRef) { }

  ngOnInit() {
    if (this.maxLength) {
      this.validators.unshift({
        errorMessage: this.errorMessage || `This field can have at a maximum ${this.maxLength} character${this.maxLength > 1 ? 's' : ''}.`,
        isValid: value => {
          return value.length <= this.maxLength
        }
      });
    }

    if (this.minLength) {
      this.validators.unshift({
        errorMessage: this.errorMessage || "This field is required",
        isValid: value => {
          if (this.type === "number") {
            return typeof value === "number" || typeof parseFloat(value) === "number"
          } else {
            return value.length >= this.minLength
          }
        }
      });
    }

    this.validate();
    if (this.autofocus) {
      this.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.validators) {
      this.validate();
    }

    if (changes.markAsUntouched) {
      this.touched = false;
    }
  }

  onKeypress(event: any) {
    // This prevents the clearing the input due to strange behavior with
    // input type="number". We don't currently support exponential notation anyway
    if (event.code === "KeyE" && this.type === "number") {
      event.preventDefault();
    }

    // Prevent user from inputting minus signs (charCode == 45) if input type is
    // number and min (if defined) is a positive or zero value
    if (this.type === "number" && event.charCode === 45 && this.min >= 0) {
      event.preventDefault();
    }

    // Allows the user to type or paste in a string beyond the max length (so
    // that the error message appears) but prevents further typing past the
    // maximum once that happens.
    if (this.softEnforceMaxLength
      && this.value.length > this.maxLength
      && event.key !== 'Delete'
      && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }

  validate(): void {
    this.errors = (this.validators || [])
      .filter(validator => !validator.isValid(this.value || ""))
      .map((validator: {errorMessage: string}) => validator.errorMessage);
    this.hasErrors$.next(this.hasErrors);
  }

  onBlur(): void {
    this.validate();
    this.focused = false;
    this.touched = true;
  }

  getTransform(): SafeStyle {
    let transform;
    if ((!this.input.nativeElement.value && !this.alwaysFloatLabel) || this.noLabelFloat) {
      transform = `translate3d(${this.prefix.nativeElement.clientWidth}px, ${this.input.nativeElement.clientHeight}px,0)`
    } else {
      transform = 'scale(0.7)';
    }
    return this.sanitizer.bypassSecurityTrustStyle(transform);
  }

  onInput(event: any): void {
    if (this.type === "number") {
      this.writeValue(event.target.valueAsNumber);
    } else {
      this.writeValue(event.target.value);
    }
  }

  public clear(): void {
    this.writeValue(null)
  }

  public focus(selectAll = false): void {
    if (selectAll) {
      this.input.nativeElement.select();
    } else {
      this.input.nativeElement.focus();
    }
  }

  get hasErrors(): boolean {
    return this.errors && this.errors.length > 0;
  }

  get shouldHideErrors(): boolean {
    return (!this.errors || !this.errors.length || (!this.touched && !this.showErrorsIfUntouched)) && !this.forceValidation;
  }

  // CONTROL VALUE ACCESSOR IMPLEMENTATION

  // Function to call when the value changes.
  onChange = (value: any) => {};

  // Function to call when the input is touched.
  onTouched = () => {};

  // Allows Angular to update the model.
  // Update the model and changes needed for the view here.
  writeValue(value: string): void {
    this.value = value;
    this.onChange(this.value);
    this.validate();
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input. Not currently used to this component but necessary to implement ControlValueAccessor
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // END CONTROL VALUE ACCESSOR IMPLEMENTATION

}

export interface InputValidator {
  errorMessage: string;
  isValid: Function;
}
