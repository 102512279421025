import { filter, take, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, Inject, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { keyBy, concat, compact, get, map as _map, merge, reject, find, cloneDeep } from 'lodash';
import { DragulaService } from "ng2-dragula";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Params } from '@angular/router';
import { Go } from 'app/router/router.actions';
import { buildUrlRelative } from 'app/shared/utils/utils';

import { AppState } from 'app/reducers';
import { Comparison, ComparisonSegment } from 'app/comparisons/comparisons.reducer';
import * as actions from 'app/comparisons/comparisons.actions';
import { Actions } from "@ngrx/effects";
import { fetchOutcome, isFetchInFlight } from "app/shared/utils/fetch-state";
import { SegmentV2Service } from '../../segments-v2/segment-v2.service';
import { prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { SegmentPickerService } from 'app/segment-picker/segment-picker.service';
import { selectRegion } from 'app/hierarchy/hierarchy.reducers';
import { HierarchyRegion } from 'app/hierarchy/hierarchy.interface';
import { environment } from 'environments/environment';
import { fixCounts } from 'app/insights/insights.models';
import { SegmentLike } from '../../models/segment-like.model';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';
import { EpcModalComponent } from 'app/shared/components/epc-modal/epc-modal.component';

@UntilDestroy()
@Component({
  selector: 'ppc-axis-edit-form',
  templateUrl: './axis-edit-form.component.html',
  styleUrls: ['./axis-edit-form.component.sass']
})
export class AxisEditFormComponent implements OnDestroy, OnInit {
  @Input() comparisonList: Comparison[];
  @ViewChild('modal', { static: true }) modal: EpcModalComponent;
  comparison: Comparison;
  params: Params;
  dragulaBagName = "edit-axis-bag";
  segments: {[identifier: string]: SegmentLike} = {};
  loading$ = this.store.select('fetchStates', actions.SaveComparison.type).pipe(select(isFetchInFlight))
  isTier3 = environment.isTier3;
  region: HierarchyRegion;
  wasDefault: boolean;
  vendorDisplayNames: VendorDisplayName[];

  constructor(public store: Store<AppState>,
    public actions$: Actions,
    private route: ActivatedRoute,
    private segmentV2Service: SegmentV2Service,
    private segmentPickerService: SegmentPickerService,
    private snackbar: MatSnackBar,
    private dragulaService: DragulaService,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {

    this.dragulaService.setOptions(this.dragulaBagName, {
      moves: (el, container, handle) => /drag/.test(handle.className)
    })

    this.store.select('hierarchy').pipe(
      select(selectRegion), filter(Boolean), untilDestroyed(this)
    ).subscribe((region: HierarchyRegion) => this.region = region);

    this.store.select('comparisons', 'comparisonUnderEdit')
      .pipe(map(cloneDeep))
      .subscribe(comparison => this.comparison = comparison);

    store.select('segmentsHierarchy', 'vendorDisplayNames').pipe(
      untilDestroyed(this)
    ).subscribe(vendorDisplayNames => this.vendorDisplayNames = vendorDisplayNames);
  }

  ngOnInit() {
    this.route.params.pipe(
      untilDestroyed(this)
    ).subscribe(params => this.params = params)
    this.fetchSegments();
    this.wasDefault = this.comparison.default;
  }

  ngOnDestroy() {
    this.dragulaService.destroy(this.dragulaBagName);
  }

  openPickerForAxis(axis: "x" | "y") {
    this.segmentPickerService.open({
      multi: true,
      title1: "Overlap",
      title2: "Select Segments(s)/Audience(s)",
      currentlySelected: compact(_map(this.comparison[`${axis}_segments`], comparisonSegment => this.segments[comparisonSegment.short_id])),
      insightsContext: this.insightsContext,
    }).subscribe((segments) => {
      if (!segments) {return; }
      this.segments = merge(this.segments, keyBy(segments, 'identifier'))
      const comparisonSegments = _map(segments, segment => {
        return find(this.comparison[`${axis}_segments`], {short_id: segment.identifier}) || {
          short_id: segment.identifier,
          name: segment.name
        }
      })
      this.comparison[`${axis}_segments`] = comparisonSegments;
    })
  }

  fetchSegments() {
    if (this.allSegmentIdentifiers.length) {
      this.segmentV2Service.fetchByIdentifiers(this.allSegmentIdentifiers)
        .subscribe(fetchedSegments => {
          const fixedCountSegments = fixCounts(fetchedSegments, this.region);
          this.segments = keyBy(fixedCountSegments, "identifier");
        })
    }
  }

  removeSegment(segment: ComparisonSegment, type: "x" | "y") {
    this.comparison[`${type}_segments`] = reject(this.comparison[`${type}_segments`], segment);
  }

  getPathString(comparisonSegment: ComparisonSegment) {
    const segment = this.segments[comparisonSegment.short_id]
    if (segment) {
      return prettyPathParts(segment, this.vendorDisplayNames).join(" > ")
    }
  }

  saveAxes() {
    this.store.dispatch(new actions.SaveComparison(this.comparison));
    this.actions$.pipe((fetchOutcome(actions.SaveComparison.type)),
      take(1), )
      .subscribe(
        ({result}) => {
          this.store.dispatch(new actions.EditComparison(null));
          this.store.dispatch(new actions.SetSelectedComparison(get(result, ["comparison", "id"])))

          if (this.wasDefault && !this.comparison.default) {
            this.store.dispatch(new actions.SaveDefaultComparison(0))
          } else if (!this.wasDefault && this.comparison.default) {
            this.store.dispatch(new actions.SaveDefaultComparison(get(result, ["comparison", "id"])))
          }

          this.store.dispatch(new Go({path: buildUrlRelative(this.params, `insights/overlap/${result.comparison.id}`)}));
        },
        () => this.snackbar.open("Something went wrong saving this comparison. Please try again in a few minutes", "OK", {
          panelClass: ["danger"],
          duration: 6000
        })
      )
  }

  cancel() {
    this.store.dispatch(new actions.EditComparison(null));
  }

  get comparisonSegments(): ComparisonSegment[] {
    return concat(this.comparison.x_segments, this.comparison.y_segments);
  }

  get allSegmentIdentifiers(): string[] {
    return compact(_map(this.comparisonSegments, "short_id"));
  }

  get isInvalid() {
    return (this.xHasTooManySegments || this.yHasTooManySegments || this.notUniqueName
              || this.noName || this.noYSegments || this.noXSegments)
            || concat(this.comparison.x_segments, this.comparison.y_segments).some(segment => !segment.name)
  }

  get xHasTooManySegments() {
    return this.comparison.x_segments.length > 12;
  }

  get yHasTooManySegments() {
    return this.comparison.y_segments.length > 12;
  }

  get noName() {
    return this.comparison.name.length === 0
  }

  get noYSegments() {
    return this.comparison.y_segments.length === 0;
  }

  get noXSegments() {
    return this.comparison.x_segments.length === 0;
  }

  get notUniqueName() {
    const comparisons = reject(this.comparisonList, {id: get(this.comparison, "id")})
    return !this.noName && find(comparisons, comparison => {
      return this.comparison
          && !comparison.name.toUpperCase().trim().localeCompare(this.comparison.name.toUpperCase().trim());
    });
  }

  getCount(segment, countType: "matched" | "modeled") {
    return get(segment, ["count", countType]);
  }

  getSegmentCount(segment, countType) {
    return this.getCount(this.getSegment(segment), countType);
  }

  getSegment(segment) {
    return find(this.segments, {identifier: segment.short_id});
  }

  renderTooltip(): string {
    return this.isTier3 ? 'Unique panel IDs' : 'PIDs that belong to the segment/audience and have a LiveRamp IDL';
  }

  copyXAxisSegmentsToYAxis() {
    this.comparison['y_segments'] = this.comparison['x_segments'];
    this.modal.close();
  }

  openCopyModal() {
    this.modal.show();
  }
}
