import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { select, Store } from "@ngrx/store";
import { filter } from 'rxjs/operators';
import { combineLatest as observableCombineLatest, of as observableOf, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as actions from 'app/insights/insights.actions';
import { toMillions } from 'app/insights/insights.models';
import { CLIENT_VERTICALS } from 'app/admin/client/client.constants';
import { find, get, sortBy } from 'lodash';
import { selectActiveMekko } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';
import { isDefined } from 'app/shared/utils/utils';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';

@UntilDestroy()
@Component({
  selector: 'ppc-share-this-overlap',
  templateUrl: './share-this-overlap.component.html',
  styleUrls: ['./share-this-overlap.component.sass']
})
export class ShareThisOverlapComponent implements OnInit, OnDestroy {
  activeVertical: string;
  buckets: { count: number, entry_date: string, total_count: number, name: string, percent: number, order: number }[];
  period = 1;
  verticals = CLIENT_VERTICALS;
  verticalType$: Observable<string>;

  constructor(private store: Store<AppState>, @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {

  }
  ngOnInit(): void {
    this.store.select("insights", this.insightsContext, "verticalOverlapData")
      .pipe(filter(isDefined), untilDestroyed(this))
      .subscribe(buckets => {
        this.buckets = sortBy(buckets, 'name');
        this.buckets.forEach( (bucket, index) => {
          bucket.percent = this.getPercent(bucket);
          bucket.order = index;
        });
      });

    this.verticalType$ = this.insightsContext != "grow" ?
      observableOf("all") :
      this.store.select("mekkos").pipe(
        select(selectActiveMekko),
        filter(isDefined),
        select(mekko => mekko.vertical_type || "all"));

    observableCombineLatest(
      this.verticalType$,
      this.store.select("insights", this.insightsContext, "shareThisPeriod"),
    ).pipe(untilDestroyed(this)).subscribe(([verticalType, period]) => {
      this.period = period;
      this.activeVertical = this.activeVertical || get(find(this.verticals, ['value', verticalType]), 'key') || this.verticals[0].key;
      this.store.dispatch(new actions.FetchShareThisVerticalOverlap(this.period, this.activeVertical, this.insightsContext));
    });
  }

  getCountLabel(bucket) {
    return toMillions(get(bucket, "count", 0));
  }

  isVerticalBucket(bucketName: string) {
    return this.activeVertical == bucketName;
  }

  displayVerticalName(bucketName: string) {
    const bucket = find(this.verticals, ['key', bucketName])
    return bucket && bucket.display;
  }

  setVertical(verticalKey: string) {
    if (verticalKey === this.activeVertical) {return; }
    this.activeVertical = get(find(this.verticals, ['key', verticalKey]), 'key');
    this.store.dispatch(new actions.FetchShareThisVerticalOverlap(this.period, verticalKey, this.insightsContext))
  }

  ngOnDestroy() {
  }

  getPercent(bucket: { count: number, entry_date: string, total_count: number, name: string }): number {
    return ( bucket.count / bucket.total_count ) * 100;
  }
}
