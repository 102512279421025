import { reduce, uniq, map, sortBy } from 'lodash';

export interface WeboramaNodes {
  nodes: WeboramaNode[];
  reachable_people: {[clusterId: string]: number};
  weborama_discussion?: WeboramaDiscussion;
  weborama_id: number;
}

export interface WeboramaNode {
  ct: number;
  id: number;
  sz: number;
  nm: string;
  cluster_id: string;
}

export interface WeboramaSubcluster {
  name: string;
  cluster_id: string;
}

export interface WeboramaDiscussion {
  weborama_id: number;
  name: string;
  description: string;
  weborama_subclusters: WeboramaSubcluster[]
}

export function newWeboramaDiscussion(weboramaNodes: WeboramaNodes): WeboramaDiscussion {
  return {
    name: "",
    description: "",
    weborama_id: weboramaNodes.weborama_id,
    weborama_subclusters: sortBy(map(uniq(map(weboramaNodes.nodes, "cluster_id")) as string[] , (clusterId) => {
      return {
        name: "",
        cluster_id: clusterId
      }
    }), "cluster_id")
  }
}
