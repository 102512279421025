<div class="ppc-progress-bar-horizontal" *ngIf="!vertical && inlineLabel" [class.reverse]="reverse" [class.selected]="selected">
  <div class="ppc-progress-bar-label" *ngIf="label">
    {{label}}
  </div>
  <div class="ppc-progress-bar">
    <div class="ppc-progress-bar-progress" [style.width.%]="getPercentage()" [style.background]="colorOverride"
      [class.selected]="selected">

    </div>
  </div>
  <div class="ppc-progress-bar-percentage-label">
    <ppc-zero-count-warning-icon
      [shift]="'left'"
      *ngIf="hasZeroCountBool"
      ppcTooltip="Click to learn why this attribute has a zero count."
      [tooltipOffset]="{x: -10}">
    </ppc-zero-count-warning-icon>
    <span *ngIf="!showCounts">{{percentage | number: round}}%</span>
    <span *ngIf="showCounts" [ppcTooltip]="(count | number:'1.0-0')">
      {{ countLabel || count }}
    </span>
  </div>
</div>

<div class="ppc-progress-bar-horizontal label-above" *ngIf="!vertical && !inlineLabel" [class.selected]="selected">
  <div class="ppc-progress-bar-label" *ngIf="label" [title]="label">
    {{label}}
  </div>
  <div class="bar-container" [class.reverse]="reverse">
    <div class="ppc-progress-bar">
      <div class="ppc-progress-bar-progress" [style.width.%]="getPercentage()" [style.background]="colorOverride"
        [class.selected]="selected">
      </div>
    </div>
    <div class="ppc-progress-bar-percentage-label">
      <ppc-zero-count-warning-icon
        [shift]="'left'"
        *ngIf="hasZeroCountBool"
        ppcTooltip="Click to learn why this attribute has a zero count."
        [tooltipOffset]="{x: -10}"
        ></ppc-zero-count-warning-icon>
      <span *ngIf="!showCounts">{{percentage | number: round}}%</span>
      <span *ngIf="showCounts" [ppcTooltip]="(count | number:'1.0-0')">
        {{ countLabel }}
      </span>
    </div>
  </div>
</div>

<div class="ppc-progress-bar-vertical" *ngIf="vertical" [class.selected]="selected">
  <div class="ppc-progress-bar-container">
    <div class="ppc-progress-bar-vertical-text">
      <div class="count">
        <ppc-zero-count-warning-icon
          [flipped]="true"
          *ngIf="hasZeroCountBool"
          ppcTooltip="Click to learn why this attribute has a zero count."
          [tooltipOffset]="{x: -3, y: 2}"
          ></ppc-zero-count-warning-icon>
        <div *ngIf="!showCounts" [class.indent]="percentage == 0 && hasZeroCountBool">{{percentage | number: round}}%</div>
        <div *ngIf="showCounts" [class.indent]="count == 0 && hasZeroCountBool" [ppcTooltip]="(count | number:'1.0-0')">
          {{ countLabel }}
        </div>
      </div>
      <div class="ppc-progress-bar-label" [title]="label">
        {{label}}
      </div>

    </div>
    <div class="ppc-progress-bar-vertical-container">

      <div class="ppc-progress-bar" #progressBar>
        <div class="ppc-progress-bar-progress" [style.height.%]="getPercentage()" [style.background]="colorOverride"></div>
      </div>

    </div>
  </div>
</div>
