import { Injectable } from '@angular/core';
import * as Json2Csv from "json2csv";
import { CSVFile } from './csv-file';
import { capitalizeString, removeSpacesAndSpecialCharacters } from 'app/shared/utils/utils';

@Injectable()
export class CSVService {

  constructor(
  ) {}

  generateCsvFile(dataObjects: Object[], fields: string[], fieldNames: string[], slugs: string, options: Object): CSVFile {
    if (!dataObjects.length) {return {data: "", fileName: ""}}
    const data = this.generateDownloadData(dataObjects, fields, fieldNames);
    const fileName = this.generateFileName(slugs, options);
    return {data, fileName};
  }

  private generateDownloadData(data: Object[], fields, fieldNames): string {
    if (!fieldNames.length) {fieldNames = Object.keys(data[0]); }

    const newArry = [];
    for (let i = 0; i < fields.length; i++) {
      newArry.push({ label: fieldNames[i], value: fields[i]});
    }

    let csv = Json2Csv.parse(data, { fields: newArry });
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,\ufeff' + csv;
    }
    return encodeURI(csv);
  }

  private generateFileName(slugs: string, options: Object): string {
    const slugParts = slugs.split("/")
    const productName = capitalizeString(slugParts[slugParts.length - 1]);
    const date = new Date();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    // We can use this if node is updated
    // let optionsString = Object.values(options).join("_");
    const optionsString = Object.keys(options).map(option => options[option]).join("_");
    const unsanitizedString = `${productName}_${optionsString}_${day}${month}${year}.csv`;
    return removeSpacesAndSpecialCharacters(unsanitizedString);
  }
}
