import * as actions from './regions.actions';
import {EntityState, EntityAdapter, createEntityAdapter} from "@ngrx/entity";
import {Region} from "./region.model";
import {compareKey} from "app/shared/utils/utils";
import {createSelector} from "@ngrx/store";

export type State = EntityState<Region>

const adapter: EntityAdapter<Region> = createEntityAdapter<Region>({
  selectId: region => region.id,
  sortComparer: compareKey('name')
});

const initialState: State = adapter.getInitialState();

export function reducer(state = initialState, action: actions.All): State {
  switch (action.type) {
    case actions.LoadRegions.type:
      return adapter.addMany(action.regions, state);
    default:
      return state;
  }
}

const { selectEntities, selectAll } = adapter.getSelectors();

export const getRegions = createSelector(
  selectAll,
  (regionsList) => regionsList.map(region => region as Region)
)
