<ppc-loading-overlay *ngIf="isLoading$ | async"></ppc-loading-overlay>
<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
</header>
<main>
  <div class="header gold" style="cursor: pointer">
    User Settings
  </div>
  <button *ngIf="canViewUserReport$ | async" class="btn action-btn pull-right" (click)="downloadUserReport()">Download User Report</button>
  <button *ngIf="canViewLoginReport$ | async" class="btn action-btn pull-right" (click)="downloadLoginReport()">Download Login Report</button>
  <button class="user-action" mat-fab (click)="onCreateUserClick()">+</button>
  <div *ngIf="(userList$ | async)?.length">
    <table class="auth-users" style="width: 100%">
      <thead>
        <tr class="header-row">
          <th>Name</th>
          <th>Email</th>
          <th>Lion&nbsp;Login</th>
          <th>Okta&nbsp;Login</th>
          <th>Last Login On</th>
          <th>Role(s)</th>
          <th>Agency</th>
          <th class="status-col">
            Status
            <mat-select [ngModel]="userState$ | async" (ngModelChange)="userStateUpdate($event)">
              <mat-option *ngFor="let state of userStates" [value]="state"><em>{{state}}</em></mat-option>
            </mat-select>
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of userList$ | async; let i=index;" [id]="uniqUserHash(user)">
          <td>{{user.first_name}} {{user.last_name}}</td>
          <td><a [href]="'mailto:' + user.email">{{user.email}}</a></td>
          <td>{{user.lion_login}}</td>
          <td>{{user.okta_login}}</td>
          <td>{{user.last_login_date}}</td>
          <td>
            <span *ngFor="let role of user.roles; let last = last">
              {{role.name}}<span *ngIf="!last">,</span>
            </span>
          </td>
          <td>{{user.agencyName}}</td>
          <td>{{!!user.deleted_at ? 'Disabled' : 'Active' }}</td>
          <td>
            <div class="button-row">
              <button class="action-btn" mat-button (click)="onEditUserClick(user)">Edit User</button>
              <button class="action-btn" mat-button (click)="editPermissions(user)">Edit Permissions</button>
              <div *ngIf="canViewAs$ | async" class="view-as">
                <app-confirmation
                  [modalTitle]="'Are you sure you want to view as?'"
                  [modalContent]="'Any action you take while viewing the platform as another user can have impact on that user\'s data.'"
                  [isButton]="true"
                  [buttonClass]="'btn action-btn'"
                  [buttonLabel]="'View As'"
                  [buttonContinueLabel]="'Yes'"
                  [disabled]="user.deleted_at"
                  [kontinue]="confirmViewAs.bind(this, user)"
                ></app-confirmation>
              </div>
              <div *ngIf="!user.deleted_at; else canActivate" class="delete-login">
                <app-confirmation
                  [ppcTooltip]="'You do not have permission to delete users'"
                  [tooltipDisabled]="canDestroyLogin$ | async"
                  [isButton]="true"
                  [buttonClass]="'btn action-btn'"
                  [buttonLabel]="'Disable'"
                  [modalTitle]="'Are you sure you want to disable this user?'"
                  [buttonContinueLabel]="'Yes'"
                  [disabled]="!canDestroyLogin$ | async"
                  [kontinue]="confirmDisableUser.bind(this, user)"
                ></app-confirmation>
              </div>
              <ng-template #canActivate>
                <button
                  *ngIf="user.deleted_at"
                  class="btn action-btn"
                  mat-button
                  (click)="onActivateUserClick(user)"
                  >
                  Activate
                </button>
              </ng-template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</main>

<app-user-form
  *ngIf="(user$ | async)"
  [targetUser]="(user$ | async)"
  [roles]="roles$ | async"
  [agencies]="agencies$ | async">
</app-user-form>
