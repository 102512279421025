import { Component, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppState } from 'app/reducers';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { assign, filter, forEach, get } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { SegmentPermissionsV2Service } from 'app/admin/segment-permissions-v2/segment-permissions-v2.service';
import { FetchAdminPermissionTriples, LoadAdminPermissionTriples } from 'app/segments-hierarchy/segments-hierarchy.action';
import { SegmentPermissionsTableComponent } from './segment-permissions-table/segment-permissions-table.component';
import { FetchAdminClients, LoadAdminClients } from 'app/admin/client/client-admin/client-admin.action';
import { V1 } from '../../shared/utils/constants';

@Component({
  selector: 'app-segment-permissions-v2',
  templateUrl: './segment-permissions-v2.component.html',
  styleUrls: ['./segment-permissions-v2.component.sass'],
  providers: [
    SegmentPermissionsV2Service
  ]
})
export class SegmentPermissionsV2Component {
  @ViewChild('permissionsTable') permissionsTable: SegmentPermissionsTableComponent;

  clients: Array<any>;
  dataSources: Array<any>;
  selectedDataSource;
  ngUnsubscribe = new Subject();

  private clientSelectionRequired = false;

  constructor(
    private actions$: Actions,
    private segment_permissions_service: SegmentPermissionsV2Service,
    private snackbar: MatSnackBar,
    private store: Store<AppState>
  ) {
    this.store.dispatch(new FetchAdminPermissionTriples(V1));
    this.store.dispatch(new FetchAdminClients(V1));

    this.actions$.pipe(
      ofType(LoadAdminClients.type),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: any) => {
      this.clients = data.adminClients;
    });

    this.actions$.pipe(
      ofType(LoadAdminPermissionTriples.type),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: any) => {
      this.dataSources = data.adminPermissionTriples;
    });
  };

  handleDataSourceSelectedEvent() {
    this.clientSelectionRequired = false;
  }

  handleNewRowEvent() {
    this.clientSelectionRequired = true;
  }

  handleClientSelectedEvent() {
    this.clientSelectionRequired = false;
  }

  handleNewRowDeletedEvent() {
    this.clientSelectionRequired = false;
  }

  canSubmit(): boolean {
    if (this.clientSelectionRequired) { return false }
    if (this.selectedDataSource) { return true }
    return false
  };

  resetState(): void {
    this.selectedDataSource = undefined;
  }

  save(): void {
    this.segment_permissions_service.updateDataSource(this.selectedDataSource, this.permissionsTable.payloadPermissions).subscribe(
      (response) => {
        const dataSourceAttributes = this.selectedDataSource.attributes
        const dataSourceName = dataSourceAttributes.segment_owner_name ?
          `${dataSourceAttributes.vendor_name} - ${dataSourceAttributes.vendor_type} - ${dataSourceAttributes.segment_owner_name}` :
          `${dataSourceAttributes.vendor_name} - ${dataSourceAttributes.vendor_type}`
        const message = `Data Source has been updated: ${dataSourceName}!`;
        this.handleSnackbar(message, ['success']);
        this.updateLocalDataSources(response);
        this.permissionsTable.handleDataSourceDidUpdate(response);
      },
      error => {
        const message = `Something went wrong saving. Please try again later.`;
        this.handleSnackbar(message, ['danger']);
      }
    );
  };

  handleSnackbar(message: string, panelClasses: string[]): void {
    this.snackbar.open(message, 'OK', {
      panelClass: panelClasses,
      duration: 5000
    });
  };

  private updateLocalDataSources(response) {
    const attributes = get(response, ['data', 'attributes'], {});
    const dataSourcesToUpdate = filter(this.dataSources, (dataSource) => {
      return dataSource.attributes.vendor_name === attributes.vendor_name &&
      dataSource.attributes.vendor_type === attributes.vendor_type &&
      dataSource.attributes.segment_owner_name === attributes.segment_owner_name
    });
    forEach(dataSourcesToUpdate, (dataSource) => {
      assign(dataSource.attributes, { permissions: attributes.permissions });
    });
  }
};
