import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { SurveyQuestion } from '../market-level-survey.interface';

@Component({
  selector: 'ppc-market-level-survey-question',
  templateUrl: './market-level-survey-question.component.html',
  styleUrls: ['./market-level-survey-question.component.sass']
})
export class MarketLevelSurveyQuestionComponent implements OnInit {
  @Input() question: SurveyQuestion;
  @Input() highlightSearchHits: (text) => string;

  constructor(public element: ElementRef) { }

  ngOnInit() {
  }

}
