import { combineLatest } from 'rxjs';
import { switchMap, map, filter } from 'rxjs/operators';
import { AppState } from "../reducers";
import { Store } from "@ngrx/store";
import { FetchPermissions } from "../authorization/permissions/permissions.actions";
import { isLoaded } from "../shared/utils/fetch-state";
import { checkPermissions, RequiredPermissions } from '../authorization/permissions/permissions.reducers';
import { currentUser } from '../users/user.reducer';
import { Injectable } from '@angular/core';
import { User } from 'app/users/user.interface';
import { FullPermissions } from 'app/shared/interfaces/auth-permission';


@Injectable({providedIn: "root"})
export class PermissionsService {

  constructor(private store: Store<AppState>) { }

  canAccess(requiredPermissions: RequiredPermissions) {

    return this.store.select('fetchStates').pipe(
      filter(fetchStates => isLoaded(fetchStates[FetchPermissions.type])),
      switchMap(() => combineLatest(this.store.select('permissions'), currentUser(this.store)).pipe(
        map(([perms, user]:[FullPermissions,User]) => {
          return checkPermissions(perms, requiredPermissions, user.is_admin); ;
        })
      )
      ));

  }

}
