import { cloneDeep, groupBy, keyBy, reduce, flatMap, get, map, find, sortBy, values, filter } from 'lodash';
import {createSelector} from "@ngrx/store";
import * as moment from 'moment';

import { Tab } from 'app/insights/insights.models';

import * as actions from './measure-v3.actions';
import { Mekko, SubMarket, Market } from 'app/mekko/mekko.reducer';

export interface OutcomeTimeframe {
  submarket_outcomes: SubmarketOutcome[];
  submarket_outcomes_attributes?: SubmarketOutcome[];
  market_outcomes: MarketOutcome[];
  market_outcomes_attributes?: MarketOutcome[];
  year: number;
  month: number;
  mekko_id?: number;
  id?: number;
  current?: boolean;
  label?: string;
  total: number;
  matched: number;
  modeled: number;
}

export interface Timeframe {
  date: string;
  subMarketsOutcomes: SubmarketOutcome[];
  subMarketComboCount: number;
}

export interface MarketOutcome {
  market_id: number;
  outcome_timeframe_id?: number;
  total: number;
  matched: number;
  modeled: number;
}

export interface SubmarketOutcome {
  id?: number,
  sub_market_id: number;
  market_id: number;
  outcome_timeframe_id?: number;
  total: number;
  matched: number;
  modeled: number;
}

export type MeasureTabType = "Total Population" | "Matched Addressable" | "Modeled Addressable";
export type TimeframeFieldType = "total" | "matched" | "modeled";

export interface State {
  outcomeTimeframes: {[year: number]: {[month: number]: OutcomeTimeframe}};
  activeCmsYear?: number;
  measureTab: MeasureTabType;
  currentTimeframe?: OutcomeTimeframe;
  previousTimeframe?: OutcomeTimeframe;
  selectedSubMarket?: SubMarket;
  selectedMarket?: Market;
  measureV3Active?: boolean;
  tabs?: Tab[];
}

const defaultState: State = {
  outcomeTimeframes: {},
  measureTab: "Matched Addressable"
}

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.LoadOutcomeTimeframes.type:
      return {
        ...state,
        outcomeTimeframes: reduce(groupBy(action.outcomeTimeframes, "year"), (result, yearGroup: OutcomeTimeframe[], year: string) => {
          result[year] = keyBy(yearGroup, "month")
          return result
        }, {})
      }
    case actions.SetActiveCmsYear.type:
      return {...state, activeCmsYear: action.activeYear}
    case actions.LoadOutcomeTimeframe.type:
      var outcomeTimeframes = {...state.outcomeTimeframes};
      var { month, year } = action.outcomeTimeframe;
      if (!outcomeTimeframes[year]) {outcomeTimeframes[year] = {}; }
      outcomeTimeframes[year][month] = action.outcomeTimeframe;
      return {
        ...state,
        outcomeTimeframes
      }
    case actions.RemoveOutcomeTimeframe.type:
      var outcomeTimeframes = {...state.outcomeTimeframes};
      var {month, year} = action.outcomeTimeframe;
      delete outcomeTimeframes[year][month]
      return {
        ...state,
        outcomeTimeframes
      }
    case actions.SetMeasureTab.type:
      return {...state, measureTab: action.tab}
    case actions.SetCurrentTimeframe.type:
      return {...state, currentTimeframe: action.outcomeTimeframe}
    case actions.SetPreviousTimeframe.type:
      return {...state, previousTimeframe: action.outcomeTimeframe}
    case actions.ToggleSelectedSubMarket.type:
      const selectedSubMarket = action.subMarket && state.selectedSubMarket &&
        state.selectedSubMarket["id"] == action.subMarket.id ? null : action.subMarket
      return {...state, selectedSubMarket, selectedMarket: null}
    case actions.ToggleSelectedMarket.type:
      const market = action.market && state.selectedMarket &&
        state.selectedMarket["id"] == action.market.id ? null : action.market
      return {...state, selectedMarket: market, selectedSubMarket: null}
    case actions.ResetSelectedMekko.type:
      return {...state, selectedSubMarket: null, selectedMarket: null}
    case actions.SetMeasureV3Active.type:
      return {...state, measureV3Active: action.measureV3Active}
    case actions.LoadTabs.type:
      return {...state, tabs: action.tabs}
    default:
      return state
  }
}

export const selectTimeframeField = createSelector(
  (state: State) => state.measureTab,
  measureTab => tabMap[measureTab]
)

export const selectCmsOutcomeTimeframes = createSelector(
  (state: State) => state.outcomeTimeframes,
  (state: State) => state.activeCmsYear,
  (outcomeTimeframes, activeYear) => {
    return cloneDeep(outcomeTimeframes)[activeYear];
  }
)

export function selectCurrentSum(fieldName: string): (state: State) => number {
  return createSelector(
    (state: State) => state.currentTimeframe,
    (state: State) => state.selectedSubMarket,
    (state: State) => state.selectedMarket,
    (currentTimeframe, selectedSubMarket, selectedMarket) => {
      if (currentTimeframe) {
        if (selectedSubMarket) {return get(find(currentTimeframe.submarket_outcomes, {sub_market_id: selectedSubMarket.id}), fieldName, 0)}
        if (selectedMarket) {return get(find(currentTimeframe.market_outcomes, {market_id: selectedMarket.id}), fieldName, 0)}
        return currentTimeframe[fieldName];
      }
    }
  )
}

export const selectPreviousPopulation = createSelector(
  (state: State) => state.previousTimeframe,
  selectTimeframeField,
  (state: State) => state.selectedSubMarket,
  (state: State) => state.selectedMarket,
  (previousTimeframe, timeframeField, selectedSubMarket, selectedMarket) => {
    if (previousTimeframe) {
      if (selectedSubMarket) {return get(find(previousTimeframe.submarket_outcomes, {sub_market_id: selectedSubMarket.id}), timeframeField, 0)}
      if (selectedMarket) {return get(find(previousTimeframe.market_outcomes, {market_id: selectedMarket.id}), timeframeField, 0)}
      return previousTimeframe[timeframeField]
    }
  }
)

export const selectCurrentPopulation = createSelector(
  (state: State) => state.currentTimeframe,
  selectTimeframeField,
  (state: State) => state.selectedSubMarket,
  (state: State) => state.selectedMarket,
  (currentTimeframe, timeframeField, selectedSubMarket, selectedMarket) => {
    if (currentTimeframe) {
      if (selectedSubMarket) {return get(find(currentTimeframe.submarket_outcomes, {sub_market_id: selectedSubMarket.id}), timeframeField, 0)}
      if (selectedMarket) {return get(find(currentTimeframe.market_outcomes, {market_id: selectedMarket.id}), timeframeField, 0)}
      return currentTimeframe[timeframeField]
    }
  }
)


export const selectTimeframesInRange = createSelector(
  (state: State) => state.currentTimeframe,
  (state: State) => state.previousTimeframe,
  (state: State) => flatMap(values(state.outcomeTimeframes), values),
  (currentTimeframe, previousTimeframe, outcomeTimeframes: OutcomeTimeframe[]) => {
    if (!currentTimeframe || !previousTimeframe) {return []; }

    const previousMoment = moment().year(previousTimeframe.year).month(previousTimeframe.month);
    const currentMoment = moment().year(currentTimeframe.year).month(currentTimeframe.month);
    return sortBy(filter(outcomeTimeframes, (timeframe: OutcomeTimeframe) => {
      const timeframeMoment = moment().year(timeframe.year).month(timeframe.month);
      return timeframeMoment.valueOf() - previousMoment.valueOf() > -10 && currentMoment.valueOf() - timeframeMoment.valueOf() > -10
    }), ["year", "month"]) as OutcomeTimeframe[]
  }
)

export function sortByOrder(collection) {
  return sortBy(collection, c => c.order != null ? c.order : Number.NEGATIVE_INFINITY);
}

export function newOutcomeTimeframe(year: number, activeMekko: Mekko, subMarkets: SubMarket[]): OutcomeTimeframe {
  return {
    market_outcomes: map(activeMekko.markets, newMarketOutcome),
    submarket_outcomes: subMarkets.map(newSubmarketOutcome),
    year: year,
    month: 0,
    total: 0,
    matched: 0,
    modeled: 0
  }
}

export function newSubmarketOutcome(subMarket: SubMarket): SubmarketOutcome {
  return {
    sub_market_id: subMarket.id,
    market_id: subMarket.market_id,
    total: 0,
    matched: 0,
    modeled: 0
  }
}

export function newMarketOutcome(market: Market): MarketOutcome {
  return {
    market_id: market.id,
    total: 0,
    matched: 0,
    modeled: 0
  }
}

export const tabMap: {[tab: string]: TimeframeFieldType} = {
  "Total Population": "total",
  "Matched Addressable": "matched",
  "Modeled Addressable": "modeled"
}

export const isRegionMarketLevelOnly = createSelector(
  (state: State) => state.tabs,
  (tabs) => {
    return !find(tabs, {tab_key: "top_level_person"})
  }
)
