<p *ngIf="!buckets?.length">Data is temporarily unavailable. Please check back in a few days.</p>
<div *ngIf="buckets?.length" class="widget">
  <div class="widget-title">
    <span>
      Category Overlap
      <i class="fa fa-info-circle" ppcTooltip="Percentage of Unique People who visited the current category from all People who visited the selected category on the left"></i>
    </span>
    <span class="entry-date" ppcTooltip="Date of last data file received">{{buckets[0]?.entry_date | date}}
      <span class="sort-data">
        <ppc-sort [sortData]="buckets"></ppc-sort>
      </span>
    </span>
  </div>
  <div class="widget-bucket" *ngFor="let bucket of buckets; let i=index">
    <div class="widget-bucket-container">
      <div class="widget-bucket-label-container" (click)="setVertical(bucket.name)">
        <span class="widget-bucket-label-text">{{displayVerticalName(bucket.name)}}</span>
        <div class="overlap-icon">
          <img *ngIf="isVerticalBucket(bucket.name)" [src]="'assets/icons/share-this/Mixers.png'">
        </div>
      </div>
      <ppc-progress-bar [percentage]="bucket.percent"
                        [colorOverride]="isVerticalBucket(bucket.name) ? '#FFFFFF' : '#82CAD0'"
                        [showCounts]="true"
                        [countLabel]="getCountLabel(bucket)"
                        [count]="bucket.count"></ppc-progress-bar>
    </div>
  </div>
</div>
