<ppc-loading-overlay *ngIf="uploadAdminService.loading$ | async"></ppc-loading-overlay>
<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
  <h1><span class="object-name">Explore/Widgets Upload</span></h1>
</header>
<div class="upload-container">
  <div class="flex">
    <div class="download flex-column">
      <h2>Step 1: Download Template</h2>
      Download a template with all of your current Explore data.
      <div>
        <button (click)="downloadTemplate()" class="btn">Download</button>
      </div>
    </div>
    <div class="edit flex-column">
      <h2>Step 2: Edit</h2>
      <div class="edit-instruction">
        Open the template in Excel or other .xls compatible program. Edit the fields to modify or add custom tabs and widgets.
      </div>
    </div>
    <div class="upload flex-column">
      <h2>Step 3: Upload Edited File</h2>
      <div class="flex">
        <div class="half-col">
          <form #templateForm>
            <span>Upload your modified template.</span>
            <div class="file-upload-container">
              <label for="file-upload" class="btn">Choose File</label>
              <input type="file" name="template" id="file-upload" accept=".xls" (change)="uploadTemplate($event, templateForm)"/>
              <span *ngIf="fileName" class="file-name">{{fileName}}</span>
            </div>
          </form>
          <div *ngIf="errors.length" class="err-col">
            <div class="errors-found-container">
              <div class="exclamation-container"><i class="fa fa-exclamation-circle"></i></div>
              <div class="errors-found">Errors found in Uploaded Template</div>
              <div class="caret-container" (click)="errorListCollapsed = !errorListCollapsed"><i class="fa fa-caret-down"></i></div>
            </div>
            <table *ngIf="!errorListCollapsed" class="template">
              <tr>
                <th>Column</th><th>Row</th><th>Error</th>
              </tr>
              <tr *ngFor="let error of errors">
                <td>{{error.column || '-'}}</td>
                <td>{{error.row || '-'}}</td>
                <td [innerHTML]="getSanitizedValue(error.message)"></td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="!!updatedWidgets.length" class="table-col">
          <div class="updated-markets-container">
            <div class="check-container"><i class="fa fa-check"></i></div>
            <div class="updated-markets">Updated Widgets</div>
            <div class="caret-container" (click)="updatesListCollapsed = !updatesListCollapsed"><i class="fa fa-caret-down"></i></div>
          </div>
          <table *ngIf="!updatesListCollapsed">
            <tr>
              <th>Tab</th>
              <th>Widget</th>
              <th>Attribute</th>
            </tr>
            <tr *ngFor="let widget of updatedWidgets">
              <td>{{widget.tab_name}}</td>
              <td>{{widget.name}}</td>
              <td>
                <ul>
                  <li *ngFor="let attributeName of widget.demo_attributes">{{attributeName}}</li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="rules-list">
    <h3>Rules for Bulk Uploader</h3>
    <ul>
      <li *ngFor="let rule of rules">{{rule}}</li>
    </ul>
  </div>
</div>
