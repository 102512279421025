<div class="market-name-header" ppcTooltip [tooltipTemplate]="marketInfo">
  <div class="market-name">
    <div class="ellipsize" [class.has-max-width]="editingMekko">
      {{ market?.name }}
    </div>
    <span  *ngIf="editingMekko && !(isVerticalMekko$ | async)" class="market-name-edit hoverable" (click)="editMarket()">
      <i class="fa fa-pencil"></i>
      <i class="fa fa-bars mekko-market-bars"></i>
    </span>
  </div>
  <ng-container *ngIf="!(isCompareMode$ | async) && !marketIsBelowTvCountMinimum(market)">
      <div class="market-count" *ppcCheckOverflow="let hasOverflow=hasXOverflow">
        <i class="fa {{ (isPeopleCount$ | async) ? 'fa-user' : 'fa-tv' }}"></i>
        <span *ngIf="hasOverflow">...</span>
        <span [class.hide-but-keep-width]="hasOverflow">{{ growCounts.getMarketCount(this.market?.id) | number }}</span>
      </div>
  </ng-container>

  <ng-template #marketInfo>
    <div class="count-tooltip">
      <div>{{market?.name}}</div>
      <div *ngIf="!(isCompareMode$ | async) && !marketIsBelowTvCountMinimum(market)">
        <i class="fa {{ (isPeopleCount$ | async) ? 'fa-user' : 'fa-tv' }}"></i>
        {{ growCounts.getMarketCount(this.market?.id) | number }}
      </div>
    </div>
  </ng-template>
</div>
<div class="sub-markets" [dragula]="dragulaBagName" [dragulaModel]="subMarkets">
  <div *ngFor="let subMarket of subMarkets"
       class="sub-market-container"
       [style.flex]="getHeightForSubMarket(subMarket)">
    <div class="sub-market"
        [attr.data-sm-id]="subMarket.id"
        [style.background]="getBackgroundColor(subMarket)"
        [style.border-color]="getBorderColor(subMarket)"
        [class.not-selected]="!editingMekko && selectedSubMarketIds.length > 0 && !isSubMarketSelected(subMarket)"
        (click)="editingMekko ? editSubMarket(subMarket) : toggleSubMarket(subMarket)">
      <div class="sub-market-edit" *ngIf="editingMekko">
        <ppc-zero-count-warning-icon
          *ngIf="hasZeroCount(subMarket); else pencil"
          ppcTooltip="Click to learn why this audience has a zero count."
        >
        </ppc-zero-count-warning-icon> Edit
        <ng-template #pencil><i class="fa fa-pencil"></i></ng-template>
        <i class="fa fa-bars sub-market-bars"></i>
      </div>
      <div class="sub-market-info"
           [class.short]="subMarketHeights[subMarket.id] <= 50"
           [class.skinny]="hostRef.nativeElement.clientWidth <= 40"
           [style.border-color]="getBorderColor(subMarket)"
           ppcTooltip
           [tooltipTemplate]="subMarketInfo"
      >

        <div *ngIf="getCompareColor(subMarket)"
             class="compare-color-bubble"
             [style.background-color]="getCompareColor(subMarket)">
        </div>

        <div class="sub-market-name">
          <i *ngIf="!(isPeopleCount$ | async) && tvCountMinimum(subMarket)"
             class="fa fa-exclamation-triangle"
             [ppcTooltip]="tvCountMinimumTooltip(subMarket)"
          ></i>
          <ppc-zero-count-warning-icon
            *ngIf="hasZeroCount(subMarket)"
            ppcTooltip="Click to learn why this audience has a zero count."
            (click)="!editingMekko"
          >
          </ppc-zero-count-warning-icon>
          {{ subMarket.name }}
        </div>
        <ng-container *ngIf="!(isCompareMode$ | async) || isSubMarketSelected(subMarket)">
          <ng-container *ngIf="!hasZeroCount(subMarket) && !tvCountMinimum(subMarket)">
            <div class="sub-market-count" *ppcCheckOverflow="let xOverflow=hasXOverflow;">
              <i class="fa {{ (isPeopleCount$ | async) ? 'fa-user' : 'fa-tv' }}"></i>
              <span *ngIf="xOverflow">...</span>
              <span *ngIf="(isCompareMode$ | async)"
                    [class.hide-but-keep-width]="xOverflow">
                {{canCompareSubMarket(subMarket) ? (getCount(subMarket) | number) : "N/A"}}
              </span>
              <span *ngIf="!(isCompareMode$ | async)"
                    [class.hide-but-keep-width]="xOverflow">
                {{hasCount(subMarket) ? (getCount(subMarket) | number) : "N/A"}}
              </span>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <ng-template #subMarketInfo>
        <div class="count-tooltip">
          <div>{{subMarket.name}}</div>
          <div *ngIf="(!(isCompareMode$ | async) || canCompareSubMarket(subMarket)) && !tvCountMinimum(subMarket)">
            <i class="fa {{ (isPeopleCount$ | async) ? 'fa-user' : 'fa-tv' }}"></i>
            {{hasCount(subMarket) ? (getCount(subMarket) | number) : "N/A"}}
          </div>
          <div>{{getSubmarketAudiencePath(subMarket)}}</div>
        </div>
      </ng-template>
  </div>
</div>
<button class="add-audience-button" (click)="addSubMarket()" *ngIf="editingMekko && !(isVerticalMekko$ | async)" matRipple>
  ADD AUDIENCE
</button>
