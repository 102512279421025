
import { map } from 'rxjs/operators';
import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {NguCarousel, NguCarouselConfig} from '@ngu/carousel';
import {Creative} from '../creative.reducers';
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers";
import {DeleteCreative, UploadCreatives} from "app/creative/creative.actions";
import {PpcHttpService} from "app/services/ppc_http.service";
import {creativeImageUrl} from 'app/services/creative.service';
import {isFetchInFlight} from "app/shared/utils/fetch-state";
import {Observable} from "rxjs";

@Component({
  selector: 'app-creative-upload',
  templateUrl: './creative-upload.component.html',
  styleUrls: ['./creative-upload.component.sass']
})
export class CreativeUploadComponent implements OnInit, OnChanges {
  @ViewChild('dropForm', { static: true }) dropForm: ElementRef;
  @ViewChild('carousel', { static: true }) carousel: NguCarousel<any>;

  @Input() creatives: Creative[];
  @Input() competitorId: string;
  @Input() productId: string;

  @Output() creativeClick = new EventEmitter<Creative>();

  imageUrl = creativeImageUrl

  public carouselTile: NguCarouselConfig;
  public visibleCreatives: Creative[] = [];

  private maximumTrackerCount: number;

  overDropZone: boolean = false;
  isLoading$: Observable<boolean>;

  creativesPerPage: number = 10;

  constructor(private store: Store<AppState>, public http: PpcHttpService) {
    this.isLoading$ = this.store.select('fetchStates').pipe(map(fetchStates =>
      isFetchInFlight(fetchStates[UploadCreatives.type])))
  }

  ngOnInit() {
    this.maximumTrackerCount = this.creativesPerPage;
    this.carouselTile = {
      grid: {
        xs: this.maximumTrackerCount,
        sm: this.maximumTrackerCount,
        md: this.maximumTrackerCount,
        lg: this.maximumTrackerCount,
        all: 0
      },
      slide: 1,
      speed: 400,
      point: {
        visible: false
      },
      load: 2,
      touch: true,
      easing: 'ease'
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.creatives && this.carousel) {
      const {currentValue, previousValue} = changes.creatives;

      this.visibleCreatives = currentValue ? currentValue.slice(0, Math.max(this.visibleCreatives.length,
        this.creativesPerPage + 1))
        : [];

      const currentLength = currentValue && currentValue.length || 0;
      const previousLength = previousValue && previousValue.length || 0;
      if (currentLength - previousLength > 0 || this.carousel.currentSlide > currentLength - this.maximumTrackerCount) {
        if (currentLength) {
          setTimeout(() => this.carousel.moveTo(Math.max(0, this.creatives.length - this.maximumTrackerCount)))
        }
      }
    }
  }

  handleDrop(): void {
    this.overDropZone = false;
    if (this.dropForm.nativeElement.querySelector('input[type=file]').files.length) {
      this.store.dispatch(new UploadCreatives(new FormData(this.dropForm.nativeElement)));
    }
  }

  deleteCreative(id: string): void {
    this.store.dispatch(new DeleteCreative(id));
  }

  fileOver(e: Event, isOver: boolean): void {
    this.overDropZone = isOver;
  }

  carouselLoad() {
    this.visibleCreatives = this.creatives.slice(0, this.visibleCreatives.length + this.creativesPerPage)
  }
}
