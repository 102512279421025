<div class="form-container" (submit)="upsertMarket()" *ngIf="market">

  <h3 class="section-header">{{market.id ? "Edit" : "Create"}} Market</h3>

  <div class="name-container">
    <ppc-input [(ngModel)]="market.name">
      <label>Name</label>
      <span suffix class="required-suffix" [hidden]="market.name?.trim() && !duplicateNames()">*</span>
    </ppc-input>
    <div class="name-error-container" *ngIf="duplicateNames()">Market name already used for this chart</div>
  </div>

  <div class="button-container">
    <button type="submit"
            class="btn"
            (click)="upsertMarket()"
            [disabled]="!market.name?.trim() || duplicateNames()">
      Save
    </button>

    <ng-template #dialogTemplate>
      <div class="delete-message">
        <h4>Are you sure you want to delete '{{this.market?.name}}' ?</h4>
        <div class="delete-message-body">
          <div class="list-title">This market and its audience(s) will also be deleted from:</div>
          <ul>
            <li>Outcomes: Past Timeframes</li>
          </ul>
        </div>
      </div>
    </ng-template>
    <app-confirmation
      *ngIf="market.id && (canDestroyMekkos$ | async)"
      [isButton]="true"
      modalTitle="Delete Market"
      [kontinue]="destroyMarket.bind(this)"
      [dialogTemplate]="dialogTemplate"
    ></app-confirmation>
    <button mat-button (click)="cancel()">Cancel</button>
  </div>
</div>
