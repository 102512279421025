import {map, mergeMap, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {HttpClient} from '@angular/common/http';
import {Observable, combineLatest as observableCombineLatest} from "rxjs";
import * as actions from "app/insights/grow-v3/grow.actions";
import {fetchResource, isLoaded, ResetFetchState} from "app/shared/utils/fetch-state";
import {select, Action, Store} from "@ngrx/store";
import {size, values, filter as _filter, flatMap, isEmpty, map as _map, uniq} from 'lodash';
import {ChangeContext} from "app/hierarchy/hierarchy.actions";
import { MarketLevelDemographic, marketLevelDemographicHasSubMarketEntry } from 'app/insights/insights-components/market-level-demographics/market-level-demographic.interface';
import { WeboramaNodes, WeboramaDiscussion } from 'app/insights/insights-components/market-level-discussions/weborama-nodes.interface';
import { fullContext } from "app/hierarchy/hierarchy.reducers";
import { userPreferenceKeys } from './grow.constants';
import * as mekkoActions from 'app/mekko/mekko.actions';
import * as snapshotActions from 'app/snapshots/snapshots.actions'
import { newMekko, selectMekkos, selectActiveMekkoSubMarkets, SubMarket, selectActiveMekko, selectSelectedSubMarkets, selectShortIds } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';
import { RegionDemographic } from 'app/insights/insights.reducer';
import * as insightsActions from 'app/insights/insights.actions';
import { FetchAllDataPermissions } from 'app/data_permissions/data_permissions.actions';
import { barbsUrl, marketLevelDemographicUrl, regionDemographicsUrl, weboramaGroupsUrl, weboramaNodesUrl } from 'app/shared/constants/insights.urls';

@Injectable()
export class GrowV3Service {
  @Effect()
  defaultMekko$ = observableCombineLatest(
    this.store.select("mekkos").pipe(select(selectMekkos), select(size)),
    this.store.select('fetchStates', mekkoActions.FetchMekkos.type).pipe(select(isLoaded)),
    this.store.select("permissions", "mekkos")
  ).pipe(
    filter(([hasMekkos, mekkosAreLoaded, mekkoPermissions]) => !hasMekkos && mekkosAreLoaded && mekkoPermissions.create),
    map(() => new mekkoActions.SaveMekko(newMekko())),
  )

  @Effect()
  shouldEdit$ = observableCombineLatest([
    this.store.select("mekkos").pipe(select(selectActiveMekkoSubMarkets)),
    this.store.select("permissions", "mekkos"),
    this.store.select('fetchStates', mekkoActions.FetchMekkos.type).pipe(select(isLoaded)),
    this.store.select('fetchStates', mekkoActions.FetchSubMarkets.type).pipe(select(isLoaded)),
    this.store.select("grow", "growV3Active")
  ]).pipe(
    filter(([subMarkets, permissions, mekkosAreLoaded, subMarketsAreLoaded]) => {
      return mekkosAreLoaded &&
            subMarketsAreLoaded &&
            permissions &&
            permissions.update &&
            !subMarkets.length
    }),
    map(() => new mekkoActions.SetEditMekko(true)), )

  @Effect()
  shouldView$ = observableCombineLatest([
    this.store.select("grow", "growV3Active"),
    this.store.select("mekkos", "selectedMekkoId"),
  ]).pipe(
    withLatestFrom(this.store.select("mekkos").pipe(select(selectActiveMekkoSubMarkets))),
    filter(([[growV3Active, _], subMarkets]) => growV3Active && !!subMarkets.length),
    map(() => new mekkoActions.SetEditMekko(false)), )

  @Effect()
  changeContext$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    mergeMap(() => [
      new ResetFetchState(insightsActions.FetchDemographics),
      new actions.ResetWeboramaDiscussions()
    ]));

  @Effect()
  addSubMarketBucketEntries$ = this.actions$.pipe(
    ofType(insightsActions.LoadMarketLevelDemographics.type),
    withLatestFrom(this.store.select("mekkos").pipe(select(selectActiveMekkoSubMarkets))),
    filter(([action, subMarkets]: [insightsActions.LoadMarketLevelDemographics, SubMarket[]]) => {
      return !subMarkets.every(subMarket => {
        return action.marketLevelDemographics.every(mld => marketLevelDemographicHasSubMarketEntry(mld, subMarket))
      })
    }),
    mergeMap(([action, subMarkets]: [insightsActions.LoadMarketLevelDemographics, SubMarket[]]) => {
      const newSubMarkets = subMarkets.filter(subMarket => {
        return !action.marketLevelDemographics.every((mld: MarketLevelDemographic) => marketLevelDemographicHasSubMarketEntry(mld, subMarket))
      })
      return newSubMarkets.map(subMarket => new insightsActions.AddSubMarketBucketEntries(action.insightsContext, subMarket, 'Mekko'));
    }), )

  @Effect()
  fetchBarbs$ = this.actions$.pipe(
    ofType(actions.FetchBarbs.type),
    (
      fetchResource(
        action => this.getBarbs().pipe(map(barbs => new actions.LoadBarbs(barbs)))
      )
    ))

  @Effect()
  addNewSubMarketBucketEntry$ = this.actions$.pipe(
    ofType(mekkoActions.LoadSubMarket.type),
    withLatestFrom(this.store.select("insights", "grow", "marketLevelDemographics").pipe(map(values))),
    filter(([action, marketLevelDemographics]: [mekkoActions.LoadSubMarket, MarketLevelDemographic[]]) => {
      return !marketLevelDemographics.every(mld => marketLevelDemographicHasSubMarketEntry(mld, action.subMarket))
    }),
    map(([action, marketLevelDemographics]: [mekkoActions.LoadSubMarket, MarketLevelDemographic[]]) => {
      return new insightsActions.AddSubMarketBucketEntries("grow", action.subMarket, 'Mekko');
    }), )

  @Effect()
  fetchWeboramaNodes$ = this.actions$.pipe(
    ofType(actions.FetchWeboramaNodes.type),
    (
      fetchResource(
        action => this.fetchWeboramaNodes(action.weboramaId).pipe(map(response => new actions.LoadWeboramaNodes(action.weboramaId, response)))
      )
    ))

  @Effect()
  saveWeboramaDiscussion$ = this.actions$.pipe(
    ofType(actions.SaveWeboramaDiscussion.type),
    (
      fetchResource(
        action => this.saveWeboramaDiscussion(action.weboramaDiscussion).pipe(map(response => new actions.LoadWeboramaDiscussion(response)))
      )
    ))

  @Effect()
  closeEditPanes$ = this.store.select("grow", "growV3Active").pipe(
    filter(growV3Active => !growV3Active),
    withLatestFrom(
      this.store.select("insights", "grow", "manageOpen"),
    ),
    mergeMap(([growV3Active, manageOpen]) => {
      const actionsToDispatch: Action[] =  [
        new mekkoActions.EditMarket(null),
        new insightsActions.EditDemographic(null, "grow"),
        new actions.EditWeboramaDiscussion(null),
        new insightsActions.EditMarketLevelDemographic("grow", null),
        new mekkoActions.EditMekko(null),
      ]
      if (manageOpen) {
        actionsToDispatch.push(new insightsActions.ToggleManage("grow"))
      }

      return actionsToDispatch;
    }), )

  @Effect()
  fetchRegionDemographics$ = this.actions$.pipe(
    ofType(insightsActions.FetchRegionDemographics.type),
    fetchResource(
      action => this.fetchRegionDemographics().pipe(map(regionDemographics => new insightsActions.LoadRegionDemographics(action.insightsContext, regionDemographics)))
    )
  )

  @Effect()
  getRegionDemographics$ = observableCombineLatest(
    fullContext(this.store),
    this.store.select("grow", "growV3Active"),
  ).pipe(
    filter(([{region}, growV3Active]) => growV3Active && !!region),
    map(() => new insightsActions.FetchRegionDemographics("grow"))
  )

  @Effect()
  fetchDataPermissions$ = fullContext(this.store).pipe(
    filter(Boolean),
    switchMap(() =>
      observableCombineLatest(
        this.store.select("mekkos").pipe(select(selectSelectedSubMarkets)).pipe(
          map(subMarkets => uniq(flatMap(selectShortIds(subMarkets))))
        ),
        this.store.select("insights", "grow", "filters").pipe(map(filters => _map(filters, "shortId"))),
      )
    ),
    filter(([subMarketIds, filterIds]) => !isEmpty(subMarketIds) || !isEmpty(filterIds)),
    map(([subMarketIds, filterIds]) => new FetchAllDataPermissions(subMarketIds.concat(filterIds)))
  )

  // This needs to be the last effect
  @Effect()
  fetchMekkoItems$ = this.store.select("mekkos").pipe(
    select(selectActiveMekko),
    filter(Boolean),
    mergeMap(({id: mekkoId}) => [
      new insightsActions.FetchDemographicsConfig(userPreferenceKeys.standardDemographics("grow"), mekkoId, "Mekko", "grow"),
      new insightsActions.FetchDemographicsConfig(userPreferenceKeys.surveys, mekkoId, "Mekko", "grow"),
      new insightsActions.FetchCustomTabsConfig(mekkoId, "Mekko", "grow"),
      new insightsActions.FetchMarketLevelDemographics("grow", mekkoId, "Mekko"),
      new snapshotActions.FetchSnapshots(mekkoId),
      new insightsActions.FetchMarketLevelSurveys(mekkoId, "Mekko", "grow"),
      new ResetFetchState(insightsActions.FetchTabs),
    ])
  )

  @Effect()
  fetchRegionPanelistConstants$ = fullContext(this.store).pipe(
    filter(Boolean),
    select(({region}) => region.slug),
    map(() => new actions.FetchBarbs())
  )

  constructor(private http: HttpClient, private actions$: Actions, private store: Store<AppState>) { }

  saveMarketLevelDemographic(marketLevelDemographic: MarketLevelDemographic): Observable<MarketLevelDemographic> {
    return this.http[marketLevelDemographic.id ? "put" : "post"](marketLevelDemographicUrl(marketLevelDemographic.id), marketLevelDemographic) as Observable<MarketLevelDemographic>;
  }

  fetchWeboramaNodes(weboramaId: string): Observable<WeboramaNodes> {
    return this.http.get(weboramaNodesUrl(weboramaId)) as Observable<WeboramaNodes>;
  }

  saveWeboramaDiscussion(weboramaDiscussion: WeboramaDiscussion): Observable<WeboramaDiscussion> {
    return this.http.put(weboramaGroupsUrl(weboramaDiscussion.weborama_id), { weborama_discussion: weboramaDiscussion }) as Observable<WeboramaDiscussion>;
  }

  getBarbs() {
    return this.http.get(barbsUrl())
  }

  fetchRegionDemographics(): Observable<RegionDemographic[]> {
    return this.http.get(regionDemographicsUrl()) as Observable<RegionDemographic[]>
  }
}
