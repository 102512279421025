import { Action } from '@ngrx/store';
import { PageInfo } from 'app/models/paginable.model';
import { Activation } from './activation.model';

export interface FetchOptions {
  sort?: string;
  limit?: number;
  offset?: number;
  state?: string;
  companyId?: number;
  sourceSystem?: string;
  searchTerm?: string;
}

export class FetchActivations implements Action {
  static readonly type = 'ACTIVATION_MANAGEMENT:FETCH_ACTIVATIONS';
  readonly type = FetchActivations.type;
  constructor(public fetchOptions?: FetchOptions) {}
}

export class LoadActivations implements Action {
  static readonly type = 'ACTIVATION_MANAGEMENT:LOAD_ACTIVATIONS';
  readonly type = LoadActivations.type;

  constructor(public activations: Activation[], public pageInfo: PageInfo) {}
}

export class UpdateActivationState implements Action {
  static readonly type = 'ACTIVATION_MANAGEMENT:UPDATE_ACTIVATION_STATE';
  readonly type = UpdateActivationState.type;

  constructor(public activationId: number, public state: string) {}
}

export class LoadUpdatedActivation implements Action {
  static readonly type = 'ACTIVATION_MANAGEMENT:LOAD_UPDATED_ACTIVATION';
  readonly type = LoadUpdatedActivation.type;

  constructor(public activation: Partial<Activation>) {}
}

export type ActivationActions = FetchActivations | LoadActivations | UpdateActivationState | LoadUpdatedActivation;
