import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { get, cloneDeep, orderBy, isEmpty } from 'lodash';
import { toMillions } from 'app/insights/insights.models';
import { SortDirection } from 'app/shared/components/ppc-sort/ppc-sort.service';
import { BarChartData, DEFAULT } from 'app/insights/insights.service';

@Component({
  selector: 'ppc-share-this-widget',
  templateUrl: './share-this-widget.component.html',
  styleUrls: ['./share-this-widget.component.sass']
})
export class ShareThisWidgetComponent implements OnInit, OnChanges {
  @Input() widgetType: "Base" | "Categories" | "Domain";
  @Input() rootWidgetName: string;
  @Input() widgetData: any;
  copiedWidgetData: any;
  barChartSortDirection: SortDirection = DEFAULT;
  bucketsWithPercent: BarChartData[] = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.copiedWidgetData = cloneDeep(this.widgetData);

    if (isEmpty(this.bucketsWithPercent)) {
      this.bucketsWithPercent = this.rootBuckets;
      this.bucketsWithPercent.forEach(( bucket, index )  => {
        bucket['percent'] = this.getPercentage(bucket);
        bucket['order'] = index;
      });
    }
  }


  get rootBuckets() {
    return get(this.copiedWidgetData, this.rootWidgetName)
  }

  getBucketChildren(bucket) {
    let childrenBuckets = get(this.copiedWidgetData, [this.widgetType, bucket.name], [])
    if (this.widgetType == "Domain") {
      childrenBuckets = orderBy(childrenBuckets, ["count"], ["desc"]).slice(0, 10)
    }
    return childrenBuckets
  }

  getCountLabel(bucket) {
    return toMillions(this.getCount(bucket))
  }

  get title(): string {
    switch (this.widgetType) {
      case "Base":
        return "Total Unique Visitors";
      case "Categories":
        return "Top Categories";
      case "Domain":
        return "Top Domains";
    }
  }

  getPercentage(bucket): number {
    switch (this.widgetType) {
      case "Base":
        return bucket.unique_audience_percentage * 100;
      default:
        return bucket.count / bucket.total_count * 100;
    }
  }

  getChildPercentage(bucket, parent): number {
    return bucket.count / parent.count * 100
  }

  getCount(bucket): number {
    switch (this.widgetType) {
      case "Base":
        return bucket.unique_audience;
      default:
        return bucket.count;
    }
  }

}
