export interface TardiisPoint {
  uuid?: string;
  grps: number;
  cost: number;
  spot: number;
  impression: number;
  reach: number;
  frequency: number;
  drill_downs: PointDrillDown[];
}

export interface PointDrillDown {
  survey_type_name: string;
  day_part_name: string;
  trp: number;
  budget: number;
  impression: number;
  spot: number;
}

export class Channel {
  _id: string;
  advertiser_id: string;
  created_at: string;
  updated_at: string;
  status: string;
  name: string;
  tardiis_project_request_id: string;
  output: {
    name: string;
    period: string;
    number_of_days: number;
    demographic_name: string;
    demographic_name_secondary: string;
    campaign_start_date: string;
    frequency: number;
    status: string;
    goal_type: string;
    market_code: string;
    points: TardiisPoint[];
    rate_cards: string[];
    survey_dates: string[];
    media_types: string[];
    pdr: {
      budget: {x: number, reach: number};
      impression: {x: number, reach: number};
      spot: {x: number, reach: number};
      trp: {x: number, reach: number};
    }
  };
  template: {
    _id: string;
    reach_period: number;
    day_parts: {
      _id: number;
      name: string;
      original_tardiis_name: string;
    }[];
    media_types: {
      _id: number;
      name: string;
    }[];
    survey_dates: string[];
  };
  input: TardiisInput;

  constructor(props = {} as any) {
    this._id = props.id;
    props.output
    && props.output.points // Normalize metric names
    && props.output.points.forEach(p => Object.assign(p, {budget: p.cost, trp: p.grps}));

    const propsOutput = props.output || {};
    const output = Object.assign(
      propsOutput,
      {
        name: propsOutput.name || "",
        number_of_days: propsOutput.number_of_days || 0,
        demographic_name: propsOutput.demographic_name || "",
        demographic_name_secondary: propsOutput.demographic_name_secondary || "",
        frequency: propsOutput.frequency || 0,
        points: propsOutput.points || [],
        rate_cards: propsOutput.rate_cards || [],
        survey_dates: propsOutput.survey_dates || [],
        media_types: propsOutput.media_types || []
      });

    const propsTemplate = props.template || {};
    const template = Object.assign(
      propsTemplate,
      {
        reach_period: propsTemplate.reach_period || 0,
        day_parts: propsTemplate.day_parts || [],
        media_types: propsTemplate.media_types || [],
        survey_dates: propsTemplate.survey_dates || [],
      });
    Object.assign(
      this,
      props,
      {
        name: props.name || output.name
      },
      {
        template,
        output,
        input: {
          media_types: new Set(
            props.input && props.input.media_types
            || props.template.media_types.map(x => x._id)
          ),
          day_parts: new Set(
            props.input && props.input.day_parts
            || props.template.day_parts.map(x => x._id)
          ),
          survey_dates: new Set(
            props.input && props.input.survey_dates
            || props.template.survey_dates
          ),
          splits: props.input && props.input.splits || []
        }
      }
    );
  }

  lastPoint() {
    if (this.output.points.length > 0) {
      return this.output.points.slice(-1)[0];
    }
    return null;
  }

  toJSON() {
    return Object.assign(
      {},
      this,
      {
        input: {
          media_types: Array.from(this.input.media_types),
          day_parts: Array.from(this.input.day_parts),
          survey_dates: Array.from(this.input.survey_dates),
          splits: this.input.splits.map(({day_part_id, max, min}) => ({day_part_id, max, min})) // TODO update if we add back media_types
        }
      }
    )
  }

  isRunning() {
    return this.status === "running";
  }

  isAvailable() {
    return this.status === "available";
  }

  hasBudgetInfo() {
    return this.output.rate_cards.length > 0;
  }
}

export interface Split {
  day_part_id: number;
  media_type_id: number;
  min: number;
  max: number;
}

export interface TardiisInput {
  media_types: Set<number>;
  day_parts: Set<number>;
  survey_dates: Set<string>;
  splits: Split[]
}
