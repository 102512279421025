<div class="edit-pane">
  <div class="edit-pane-header">
    <div class="edit-pane-header-text header-container">
      <div class="edit-pane-title">
        {{ comparison?.id ? 'Edit' : 'Create New' }} Overlap Chart
      </div>
      <div class="edit-pane-header-close" (click)="cancel()">
        <img src="assets/icons/close.svg" />
      </div>
    </div>
  </div>

  <div class="edit-pane-section">
    <ppc-input [(ngModel)]="comparison.name" [noErrorContainer]="true">
      <label>Chart Title</label>
    </ppc-input>

    <mat-error *ngIf="noName">
      Chart Title is required.
    </mat-error>

    <mat-error *ngIf="notUniqueName">
      Chart Title must be unique.
    </mat-error>
  </div>

  <div class="flex">
    <div class="edit-pane-section">
      <div class="edit-pane-section-header">Chart Type</div>
      <div ppcRadioGroup [(ngModel)]="comparison.mode">
        <ppc-radio-button value="matched">Matched</ppc-radio-button>
        <ppc-radio-button *ngIf="isTier3" value="modeled">Modeled</ppc-radio-button>
        <ppc-radio-button value="index">Index</ppc-radio-button>
      </div>
    </div>
    <div class="edit-pane-section default-checkbox">
      <ppc-checkbox [(ngModel)]="comparison.default">Make this my default chart
      </ppc-checkbox>
    </div>
  </div>

  <div class="edit-pane-section">
    <label>People Count</label>
    <ppc-checkbox [(ngModel)]="comparison.is_people_count">
      Show regional people count on chart for each segment/audience
    </ppc-checkbox>
  </div>

  <div class="segments-info">
    <button 
      class="btn" 
      (click)="openCopyModal()" 
      [disabled]="noXSegments"
      ppcTooltip="Copy X-Axis segments to Y-Axis">COPY</button>
  </div>

  <div class="axes">
    <div class="edit-pane-section">
      <div class="edit-pane-section-header">X-Axis</div>
      <ppc-input [(ngModel)]="comparison.x_axis_name" [noErrorContainer]="true">
        <label>X-Axis Label</label>
      </ppc-input>

      <div class="edit-pane-section-header">
        Select Segment(s)
        <span class="segment-count-indicator">
          <div [class.red]="xHasTooManySegments">
            {{comparison.x_segments.length}} of 12
          </div>
        </span>
      </div>

      <mat-error *ngIf="noXSegments">
        Must select at least one segment for X-Axis.
      </mat-error>

      <div class="segments-info">
        <button class="btn" (click)="openPickerForAxis('x')">SELECT</button>
      </div>
      <div class="axis-form-header">
        <div class="edit-pane-section-instructions">Reorder and/or rename your selected segments below.</div>
        <div class="node-details" [class.people-count]="!isTier3" *ngIf="comparison?.x_segments.length">
          <div class="node-data-column">
            <div class="node-data-column-header" [ppcTooltip]="renderTooltip()">
              {{isTier3 ? "Matched" : "People Count"}}
            </div>
          </div>
          <div class="node-data-column" *ngIf="isTier3">
            <div class="node-data-column-header" ppcTooltip="Modeled weights on panel IDs">Modeled</div>
          </div>
        </div>
      </div>

      <div class="edit-pane-list-section no-error-containers">
        <div class="edit-pane-list-section-items" [dragula]="dragulaBagName" [dragulaModel]="comparison.x_segments">
          <div class="edit-pane-list-section-item" *ngFor="let segment of comparison?.x_segments">
            <i class="fa fa-bars drag-handle"></i>
            <ppc-input #xInput [noErrorContainer]="true" [alwaysFloatLabel]="true" [minLength]="1"
              [(ngModel)]="segment.name">
              <label>{{getPathString(segment)}}</label>
              <div suffix class="required-suffix" *ngIf="!xInput.value">
                *
              </div>
            </ppc-input>
            <div class="node-details" [class.people-count]="!isTier3">
              <div class="node-data-column-number-header">
                <div class="node-data-column-number">
                  <ppc-zero-count-warning-icon
                    *ngIf="getSegmentCount(segment, 'matched') == 0"
                    ppcTooltip="The audience has a zero count. Select another audience to replace it."
                    ></ppc-zero-count-warning-icon>
                  {{ getSegmentCount(segment, "matched") | number }}
                </div>
              </div>
              <div class="node-data-column-number-header" *ngIf="isTier3">
                <div class="node-data-column-number">
                  <ppc-zero-count-warning-icon
                    *ngIf="getSegmentCount(segment, 'modeled') == 0"
                    ppcTooltip="The audience has a zero count. Select another audience to replace it."
                    ></ppc-zero-count-warning-icon>
                  {{ getSegmentCount(segment, "modeled") | number }}
                </div>
              </div>
            </div>
            <img class="remove-button" src="assets/icons/close.svg" (click)="removeSegment(segment, 'x')" />
          </div>
        </div>
      </div>

    </div>

    <div class="edit-pane-section">
      <div class="edit-pane-section-header">Y-Axis</div>
      <ppc-input [(ngModel)]="comparison.y_axis_name" [noErrorContainer]="true">
        <label>Y-Axis Label</label>
      </ppc-input>
      <div class="edit-pane-section-header">
        Select Segment(s)
        <span class="segment-count-indicator">
          <div [class.red]="yHasTooManySegments">
            {{comparison.y_segments.length}} of 12
          </div>
        </span>
      </div>

      <mat-error *ngIf="noYSegments">
        Must select at least one segment for Y-Axis.
      </mat-error>

      <div class="segments-info">
        <button class="btn" (click)="openPickerForAxis('y')">SELECT</button>
      </div>

      <div class="axis-form-header">
        <div class="edit-pane-section-instructions">Reorder and/or rename your selected segments below.</div>
        <div class="node-details" [class.people-count]="!isTier3" *ngIf="comparison?.y_segments.length">
          <div class="node-data-column">
            <div class="node-data-column-header" [ppcTooltip]="renderTooltip()">
              {{isTier3 ? "Matched" : "People Count"}}
            </div>
          </div>
          <div class="node-data-column" *ngIf="isTier3">
            <div class="node-data-column-header" ppcTooltip="Modeled weights on panel IDs">Modeled</div>
          </div>
        </div>
      </div>

      <div class="edit-pane-list-section no-error-containers">
        <div class="edit-pane-list-section-items" [dragula]="dragulaBagName" [dragulaModel]="comparison.y_segments">
          <div class="edit-pane-list-section-item" *ngFor="let segment of comparison?.y_segments">
            <i class="fa fa-bars drag-handle"></i>
            <ppc-input #yInput [noErrorContainer]="true" [alwaysFloatLabel]="true" [minLength]="1"
              [(ngModel)]="segment.name">
              <label>{{getPathString(segment)}}</label>
              <div suffix class="required-suffix" *ngIf="!yInput.value">
                *
              </div>
            </ppc-input>
            <div class="node-details" [class.people-count]="!isTier3">
              <div class="node-data-column-number-header">
                <div class="node-data-column-number">
                  <ppc-zero-count-warning-icon
                    *ngIf="getSegmentCount(segment, 'matched') == 0"
                    ppcTooltip="The audience has a zero count. Select another audience to replace it."
                    ></ppc-zero-count-warning-icon>
                  {{ getSegmentCount(segment, "matched") | number }}
                </div>
              </div>
              <div class="node-data-column-number-header" *ngIf="isTier3">
                <div class="node-data-column-number">
                  <ppc-zero-count-warning-icon
                    *ngIf="getSegmentCount(segment, 'modeled') == 0"
                    ppcTooltip="The audience has a zero count. Select another audience to replace it."
                    ></ppc-zero-count-warning-icon>
                  {{ getSegmentCount(segment, "modeled") | number }}
                </div>
              </div>
            </div>
            <img class="remove-button" src="assets/icons/close.svg" (click)="removeSegment(segment, 'y')" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="edit-pane-actions">
    <button matRipple class="btn" (click)="saveAxes()" [disabled]="isInvalid">
      Save
    </button>
    <button mat-button (click)="cancel()">
      Cancel
    </button>
  </div>

  <epc-modal
    #modal
    title="Copy X-Axis segments to the Y-Axis?"
    primaryButtonTitle="OK"
    (onPrimaryButtonClick)="copyXAxisSegmentsToYAxis()"
  ></epc-modal>
</div>
