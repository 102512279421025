import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { filter, map, switchMap, tap, take, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { MatDialog } from "@angular/material/dialog";

import { builderSlides } from "app/journey/journey.constants";
import { PpcCarouselComponent } from "app/shared/components/ppc-carousel/ppc-carousel.component";
import { selectJourney, selectJourneys } from '../journey.reducer';
import { newJourney, JourneyBrand, JourneyStage, JourneySubMarket, STAGE_ICONS } from '../journey.models';
import { JourneyBuilder } from "app/journey/journey-builder/journey-builder.model";
import { AppState } from 'app/reducers';
import { buildUrlRelative } from "app/shared/utils/utils";
import * as actions from 'app/journey/journey.actions';
import * as insightsActions from 'app/insights/insights.actions';
import { fetchIfUnfetched, fetchOutcome, isFetchInFlight, ResetFetchState } from 'app/shared/utils/fetch-state';
import { compact, get, cloneDeep, find, isEmpty, reject, truncate } from 'lodash';
import { Actions } from '@ngrx/effects';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Go } from 'app/router/router.actions';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { fullContext } from 'app/hierarchy/hierarchy.reducers';
import { JOURNEY_BRAND_COLORS } from '../journey-chart/journey-chart.component';
import * as chroma from 'chroma-js'
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { InputValidator, PpcInputComponent } from 'app/shared/components/ppc-input/ppc-input.component';
import { environment } from 'environments/environment';

@UntilDestroy()
@Component({
  selector: 'ppc-journey-builder',
  templateUrl: './journey-builder.component.html',
  styleUrls: ['./journey-builder.component.sass']
})
export class JourneyBuilderComponent implements OnInit, OnDestroy {
  @ViewChild("nameInput") nameInput: PpcInputComponent;

  params: Params;
  builder: JourneyBuilder;
  icons = STAGE_ICONS;
  nameValidators$: Observable<InputValidator[]>;
  wasDefault: boolean;
  loading$ = observableCombineLatest(
    this.store.select("fetchStates", actions.FetchJourneys.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", insightsActions.FetchTabs.type).pipe(select(isFetchInFlight)),
  ).map(fetchStates => fetchStates.some(Boolean));
  isTier3 = environment.isTier3;

  constructor(private route: ActivatedRoute,
    private store: Store<AppState>,
    private segmentService: SegmentV2Service,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private matDialog: MatDialog) { }

  ngOnInit() {
    this.route.params.pipe(
      tap(params => this.params = params),
      map(({journeyId}) => journeyId),
      switchMap(journeyId => this.store.select("journey").pipe(select(selectJourney(journeyId)))),
      map(cloneDeep),
      withLatestFrom(fullContext(this.store)),
      untilDestroyed(this),
    ).subscribe(([journeyUnderEdit, {region, product}]) => {
      if (!journeyUnderEdit) {
        journeyUnderEdit = newJourney(product.name);
        this.store.dispatch(new ResetFetchState(insightsActions.FetchTabs));
      }
      fetchIfUnfetched(this.store, new insightsActions.FetchTabs(get(journeyUnderEdit, "id"), "Journey", "journey"), this);
      this.builder = new JourneyBuilder(journeyUnderEdit, region, this.segmentService);
      this.wasDefault = this.builder.default;
      this.nameValidators$ = this.store.select("journey").pipe(
        select(selectJourneys),
        map(journeys => {
          return [
            {
              isValid: value => value && value.length > 0,
              errorMessage: "Please provide a name for this journey"
            },
            {
              isValid: value => {
                return !find(reject(journeys, {id: get(this.builder, "id")}), journey => journey.name.trim().toLowerCase() === value.trim().toLowerCase())
              },
              errorMessage: "This name is already used."
            },
          ]
        })
      )
    })

    fetchIfUnfetched(this.store, new actions.FetchJourneys(), this);
  }

  ngOnDestroy() { }

  openCarousel() {
    this.matDialog.open(PpcCarouselComponent, {data: builderSlides, panelClass: "no-padding"})
  }

  cancel() {
    this.navigateToJourney(this.builder.id);
  }

  save() {
    const payload = this.builder.toJson();
    this.store.dispatch(new actions.SaveJourney(payload));
    this.actions$.pipe(
      fetchOutcome(actions.SaveJourney.type),
      take(1)
    ).subscribe(
      ({result}) => {
        this.snackbar.open("Changes saved!", null, {
          panelClass: "success",
          duration: 5000
        });
        if (this.wasDefault && !payload.default) {
          this.store.dispatch(new actions.SaveDefaultJourney(0))
        } else if (!this.wasDefault && payload.default) {
          this.store.dispatch(new actions.SaveDefaultJourney(get(result, ["journey", "id"])))
        }
        this.navigateToJourney(get(result, ["journey", "id"]));
      },
      (error) => {
        this.snackbar.open("Something went wrong saving your changes. Please try again in a few minutes.", null, {
          panelClass: "danger",
          duration: 5000
        })
      }
    )
  }

  navigateToJourney(journeyId?: number) {
    this.store.dispatch(new Go({path: buildUrlRelative(this.params, `insights/journey${journeyId ? "/" + journeyId : ""}`)}))
  }

  getBackgroundForSubMarket(brand: JourneyBrand, stage: JourneyStage, brandIdx: number) {
    return this.builder.noSegmentsForSubMarket(brand, stage) ? null : chroma(JOURNEY_BRAND_COLORS[brandIdx]).alpha(0.4).css();
  }

  validateNewStageName(stage: JourneyStage) {
    if (this.builder.isStageNameUsedMoreThanOnce(stage.name)) {
      this.snackbar.open("All stages require a unique name before saving.", "Ok", {
        panelClass: "danger",
        duration: 10000
      });
    } else if (this.builder.isStageNameEmpty(stage.name)) {
      this.snackbar.open("All stages require a name before saving.", "Ok", {
        panelClass: "danger",
        duration: 10000
      });
    }
  }

  validateNewBrandName(brand: JourneyBrand) {
    if (this.builder.isBrandNameUsedMoreThanOnce(brand.name)) {
      this.snackbar.open("All brands require a unique name before saving.", "Ok", {
        panelClass: "danger",
        duration: 10000
      });
    } else if (this.builder.isBrandNameEmpty(brand.name)) {
      this.snackbar.open("All brands require a name before saving.", "Ok", {
        panelClass: "danger",
        duration: 10000
      });
    }
  }

  truncate(name: string): string {
    return truncate(name, {length: 20});
  }

  nameTemplate(name: string): string {
    if (name.length > 20) {
      return name;
    }
  }

  isDisabled(name: string): boolean {
    return name.length <= 20
  }

  isSvg(icon: string): boolean {
    return icon && icon.includes('.svg');
  }

  get nameValid(): boolean {
    if (!this.nameInput) {return false; }
    return !this.nameInput.errors.length
  }

  showSubMarketIcons(brand: JourneyBrand, stage: JourneyStage): boolean {
    return (!this.builder.noSegmentsForSubMarket(brand, stage) && !this.builder.isEditing) ||
            this.builder.subMarketUnderEdit === this.builder.getSubMarket(brand, stage);
  }

  hasZeroCount(subMarket: JourneySubMarket): boolean {
    if (isEmpty(this.builder.segments) || !subMarket.matched_id && !subMarket.modeled_id) {return false; }
    if (this.isTier3) {
      return this.builder.hasZeroCount(subMarket.matched_id, "matched");
    } else {
      return this.builder.hasZeroCount(subMarket.matched_id, "matched") ||
             this.builder.hasZeroCount(subMarket.modeled_id, "modeled");
    }
  }
}
