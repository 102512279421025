import { Action } from '@ngrx/store';
import { OutcomeV1 } from './outcomes-v1.model';

export class FetchOutcomes implements Action {
  static readonly type = 'FETCH_OUTCOMES_V1';
  readonly type = FetchOutcomes.type;

  constructor(public useCases?: Array<string>) { }
}

export class LoadOutcomes implements Action {
  static readonly type = 'LOAD_OUTCOMES_V1';
  readonly type = LoadOutcomes.type;

  constructor(public outcomes: OutcomeV1[]) { }
}

export class LoadUpdatedOutcome implements Action {
  static readonly type = 'LOAD_UPDATED_OUTCOME';
  readonly type = LoadUpdatedOutcome.type;

  constructor(public outcome: OutcomeV1) { }
}

export class FetchOverviewOutcomes implements Action {
  static readonly type = 'FETCH_OVERVIEW_OUTCOMES_V1';
  readonly type = FetchOverviewOutcomes.type;

  constructor() { }
}

export class ClearOutcomes implements Action {
  static readonly type = 'CLEAR_OUTCOMES';
  readonly type = ClearOutcomes.type;
}

export type All = FetchOutcomes | LoadOutcomes | FetchOverviewOutcomes | ClearOutcomes | LoadUpdatedOutcome;
