import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { get } from 'lodash';
import { fromEvent as observableFromEvent, merge as observableMerge } from 'rxjs';

import { Node } from '../root-nodes/root-node.interface';
import { SegmentPickerContextStateService } from 'app/segment-picker/segment-picker-context-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, tap, filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ppc-segment-browser',
  templateUrl: './segment-browser.component.html',
  styleUrls: ['./segment-browser.component.sass']
})
export class SegmentBrowserComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() root: Node[];
  @Input() insightsContext: string;
  @Input() showHeaders = true;

  @ContentChild('leaf') leafTemplate: TemplateRef<ElementRef>;
  @ContentChild('parent') parentTemplate: TemplateRef<ElementRef>;

  @ViewChild("browserLevels") browserLevels: ElementRef;
  @ViewChild("scrollbar") scrollbar: ElementRef;

  @Input() selectedSegmentIds: string[];

  @Output() leafToggled = new EventEmitter<Node>();

  levels: Node[][];
  selections: Partial<Node>[];

  constructor(private service: SegmentPickerContextStateService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit() { }

  ngAfterViewInit() {
    observableMerge(
      observableFromEvent(this.browserLevels.nativeElement, "wheel").pipe(
        filter((event: any) => Math.abs(event.deltaX) > Math.abs(event.deltaY)),
        tap((event: any) => this.browserLevels.nativeElement.scrollLeft > 0 && event.deltaX < 0 && event.preventDefault()),
        map(({deltaX}) => this.browserLevels.nativeElement.scrollLeft + deltaX)
      ),
      observableFromEvent(this.scrollbar.nativeElement, "scroll").pipe(
        map(({target}) => target.scrollLeft)
      ),
      observableFromEvent(this.browserLevels.nativeElement, "scroll").pipe(
        map(({target}) => target.scrollLeft)
      )
    ).pipe(
      untilDestroyed(this)
    ).subscribe(newScrollLeft => {
      this.browserLevels.nativeElement.scrollLeft = newScrollLeft;
      this.scrollbar.nativeElement.scrollLeft = newScrollLeft;
    })
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.root) {
      this.service.insightsContext$.next(this.insightsContext);
      const existingContext = this.service.getContextState();
      // Always default to root audience/persona builder segment picker position for now
      if (existingContext && this.insightsContext !== "builder") {
        this.levels = existingContext.levels;
        this.selections = existingContext.selections;
      } else {
        this.levels = [this.root];
        this.selections = [{childrenLabel: "Sources"}];
      }
    }
  }

  ngOnDestroy() { }

  getLevelHeader(idx: number): string {
    return get(this.selections[idx], "childrenLabel");
  }

  onOptionClick(node: Node, newIndex: number) {
    if (!node.path || !node.path.is_leaf) {
      this.selections = this.selections.slice(0, newIndex);
      this.selections[newIndex] = node;
      if (!node.children) {
        node.loadingChildren = true;
        return node.children$.subscribe(
          children => {
            node.children = children;
            this.levels = this.levels.slice(0, newIndex);
            this.levels[newIndex] = node.children;
            node.loadingChildren = false;
            setTimeout(() => this.browserLevels.nativeElement.scrollTo(this.browserLevels.nativeElement.scrollWidth, 0));
          },
          error => {
            node.loadingChildren = false;
            node.children = [];
            this.levels = this.levels.slice(0, newIndex);
            this.levels[newIndex] = node.children;
          },
          () => this.sendUpdateContext(),
        )
      } else {
        this.levels = this.levels.slice(0, newIndex);
        this.levels[newIndex] = node.children;
        setTimeout(() => this.browserLevels.nativeElement.scrollTo(this.browserLevels.nativeElement.scrollWidth, 0));
      }
    } else if (node.path.is_leaf) {
      this.leafToggled.emit(node);
    }
    this.sendUpdateContext();
  }

  sendUpdateContext() {
    this.service.updateContextState(this.levels, this.selections);
  }

  hasLeaves(level): boolean {
    if (Array.isArray(level)) {
      return level.some(node => node.path && node.path.is_leaf)
    }
  }

  sliceLevelsTo(idx: number) {
    this.levels = this.levels.slice(0, idx);
    this.selections = this.selections.slice(0, idx);
  }

  get breadcrumbs(): Partial<Node>[] {
    if (!this.selections) {return []; }
    const levels = this.selections.slice(1);
    return levels.filter((node, idx) => (levels.length < 4 || (idx === 0 || idx >= levels.length - 2)));
  }

  get truncatedBreadcrumbs(): Partial<Node>[] {
    if (!this.selections) {return []; }
    const levels = this.selections.slice(1);
    const breadcrumbs = this.breadcrumbs;
    return levels.filter(level => !breadcrumbs.includes(level))
  }

}
