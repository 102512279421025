<div>
  <div class="menu">
    <button mat-button [matMenuTriggerFor]="view">View <i class="fa fa-caret-down"></i></button>

    <mat-menu #view="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="group">Group By</button>
    </mat-menu>

    <mat-menu #group="matMenu">
      <button mat-menu-item (click)="setGroupBy('none')">
        None
        <i class="fa fa-check" *ngIf="groupBy == 'none'"></i>
      </button>
      <button mat-menu-item (click)="setGroupBy('market')">
        Market
        <i class="fa fa-check" *ngIf="groupBy == 'market'"></i>
      </button>
    </mat-menu>
  </div>

  <ppc-measure-v3-stacked-legend [markets]="markets"
                                 [legendSubMarkets]="subMarkets"
                                 [groupBy]="groupBy">
  </ppc-measure-v3-stacked-legend>

  <ppc-measure-v3-stacked *ngIf="groupBy == 'none'"
                          [markets]="markets"
                          [legendSubMarkets]="subMarkets"
                          [groupBy]="groupBy">
  </ppc-measure-v3-stacked>


  <div *ngIf="groupBy == 'market'" class="grouped-stacked">
    <ppc-measure-v3-stacked *ngFor="let market of markets"
                            [markets]="[market]"
                            [legendSubMarkets]="subMarkets"
                            [groupBy]="groupBy">
    </ppc-measure-v3-stacked>
  </div>
</div>
