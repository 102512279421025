import { InjectionToken } from '@angular/core';

export const THEMES = new InjectionToken('THEMES');
export const ACTIVE_THEME = new InjectionToken('ACTIVE_THEME');

// http://chir.ag/projects/name-that-color
export const COLORS = {
  white: '#FFF',
  black: '#000',
  pictonBlue: '#51C0E8',
  curiousBlue: '#16ABE0',
  silver: '#CCC',
  mineShaft: '#2B2B2B',
  scorpion: '#5A5A5A',
  seagull: '#8AD5EF',
  gallery: '#EFEFEF',
  diSierra: '#D4A659',
  mercury: '#E9E9E9',
  gray: '#7F7F7F',
  alto: '#D6D6D6',
  tundora: '#424242',
  window: 'trasparent'
}

export interface Theme {
  name: string;
  properties: any;
}

export interface ThemeOptions {
  themes: Theme[];
  active: string;
}
