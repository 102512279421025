import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store, select } from "@ngrx/store";
import { map, filter } from 'rxjs/operators';
import { filter as _filter, find, get, orderBy, first } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';

import { JourneyBuilder } from 'app/journey/journey-builder/journey-builder.model';
import { prettyPathParts, SegmentV2 } from 'app/audiences/discover/segment-v2.model';
import { SegmentPickerService } from 'app/segment-picker/segment-picker.service';
import { environment } from 'environments/environment';
import { AppState } from 'app/reducers';
import { NAV_ADMIN, NAV_ADMIN_UPLOAD, NAV_ADMIN_DISCUSSIONS_UPLOAD } from "app/shared/utils/constants";
import { getStoreState, isDefined } from 'app/shared/utils/utils';
import { HierarchyRegion } from 'app/hierarchy/hierarchy.interface';
import { selectRegion } from 'app/hierarchy/hierarchy.reducers';
import { fixCounts } from 'app/insights/insights.models';

@UntilDestroy()
@Component({
  selector: 'ppc-journey-sub-market-form',
  templateUrl: './journey-sub-market-form.component.html',
  styleUrls: ['./journey-sub-market-form.component.sass']
})
export class JourneySubMarketFormComponent implements OnInit, OnDestroy {
  @Input() builder: JourneyBuilder;
  region: HierarchyRegion;
  isTier3 = environment.isTier3;
  marketLevelOnly$ = this.store.select("insights", "journey", "tabs").pipe(
    map(tabs => !find(tabs, {tab_key: "top_level_person"}))
  );

  clusterNodes$ = this.store.select("discussions", "clusterNodes").pipe(
    map(nodes => _filter(orderBy(nodes, node => node.name.toLowerCase()), node => node.discussion_clusters && node.discussion_clusters.length)));

  constructor(public store: Store<AppState>,
    private segmentPickerService: SegmentPickerService,
    private router: Router) {

    this.store.select('hierarchy').pipe(
      select(selectRegion),
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(region => {
      this.region = region;
    });
  }

  ngOnInit() { }

  ngOnDestroy() { }

  getSegmentPath(segment: SegmentV2): string {
    if (!segment) {return; }
    const vendorDisplayNames = get(getStoreState(this.store), ['segmentsHierarchy', 'vendorDisplayNames']);
    return prettyPathParts(segment, vendorDisplayNames).join(" > ")
  }

  openSegmentPicker(type: "matched_id" | "modeled_id") {
    const currentlySelected = this.builder.segments[this.builder.subMarketUnderEdit[type]];
    this.segmentPickerService.open({
      multi: false,
      title1: `Audience`,
      title2: type == "matched_id" ? "Matched" : "Modeled",
      currentlySelected:  currentlySelected ? [currentlySelected] : [],
      insightsContext: "journey",
    }).subscribe((segments) => {
      if (segments) {
        const [segment] = segments;
        this.builder.segments[segment.identifier] = first(fixCounts([segment], this.region));
        this.builder.subMarketUnderEdit[type] = segment.identifier;
      }
    })
  }

  navigateToDiscussionsBulkUpload() {
    const [ _ , clientSlug, brandSlug] = window.location.pathname.split("/");
    this.router.navigate([clientSlug, brandSlug, NAV_ADMIN_UPLOAD, NAV_ADMIN_DISCUSSIONS_UPLOAD, NAV_ADMIN]);
  }

  hasZeroCount(identifier: string, countType: "matched" | "modeled"): boolean {
    return identifier && this.builder.hasZeroCount(identifier, countType);
  }
}
