import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';

import * as actions from 'app/lookalikes-v3/lookalike-v3.actions';
import { fetchResource, ResetFetchState } from 'app/shared/utils/fetch-state';
import { LookalikeV3 } from 'app/lookalikes-v3/lookalike-v3.model';
import { ChangeContext } from 'app/hierarchy/hierarchy.actions';
import { V3 } from '../shared/utils/constants';
import { lookalikesOverviewUrl, lookalikesUrl, lookalikeUrl } from 'app/shared/constants/segments.urls';

@Injectable()
export class LookalikeV3Service {
  @Effect()
  createLookalike$ = this.actions$.pipe(
    ofType(actions.CreateLookalike.type),
    (
      fetchResource(
        ({ lookalike, options }) => this.create(lookalike, options).pipe(
          map(lookalike => new actions.LoadLookalike(lookalike))
        )
      )
    ));

  @Effect()
  updateLookalike$ = this.actions$.pipe(
    ofType(actions.UpdateLookalike.type),
    (
      fetchResource(
        ({ lookalike, options }) => this.update(lookalike, options).pipe(
          map(lookalike => new actions.LoadUpdatedLookalike(lookalike))
        )
      )
    ));

  @Effect()
  fetchLookalikes$ = this.actions$.pipe(
    ofType(actions.FetchLookalikes.type),
    (
      fetchResource(
        ({ options }) => this.getAll(options).pipe(map(resp => new actions.LoadLookalikes(resp.data)))
      )
    ));

  @Effect()
  fetchOverviewLookalikes$ = this.actions$.pipe(
    ofType(actions.FetchOverviewLookalikes.type),
    (
      fetchResource(
        ({ options }) => this.getAllOverviewLookalikes(options).pipe(map(lookalikes => new actions.LoadLookalikes(lookalikes.data)))
      )
    ));

  @Effect()
  contextChange$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    mergeMap(() => [
      new ResetFetchState(actions.FetchOverviewLookalikes),
      new actions.ClearLookalikes()
    ]));

  @Effect()
  destroyLookalike$ = this.actions$.pipe(
    ofType(actions.DestroyLookalike.type),
    fetchResource(
      ({ lookalike, options }) => this.destroy(lookalike, options).map(res => new actions.RemoveLookalike(res.data))
    )
  );

  constructor(private http: HttpClient, private actions$: Actions) { }

  create(lookalike: LookalikeV3, options): Observable<LookalikeV3> {
    // In V3 and below, "lookalike" (without the 's') is the path component for the create method
    if (options.urlVersionNumber === V3) {
      return this.http.post<LookalikeV3>(lookalikeUrl(options.urlVersionNumber), lookalike);
    }
    // In V5, this changes to "lookalikes" (with the 's') to be more RESTful
    return this.http.post<LookalikeV3>(lookalikesUrl(options.urlVersionNumber), lookalike);
  }

  getAll(options) {
    return this.http.get<{count: number, data: LookalikeV3[]}>(lookalikesUrl(options.urlVersionNumber));
  }

  getAllOverviewLookalikes(options) {
    return this.http.get<{data: LookalikeV3[], count: number}>(lookalikesOverviewUrl(options.urlVersionNumber));
  }

  update(lookalike: LookalikeV3, options): Observable<LookalikeV3> {
    return this.http.put<LookalikeV3>(lookalikeUrl(options.urlVersionNumber) + `/${lookalike.id}`, lookalike);
  }

  destroy(lookalike: LookalikeV3, options): Observable<any> {
    return this.http.delete<LookalikeV3>(lookalikeUrl(options.urlVersionNumber) + `/${lookalike.id}`);
  }
}

