import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import { NewDestinationOverlayComponent } from './new-destination-overlay.component';
import { PpcCheckboxComponent } from 'app/shared/components/ppc-checkbox/ppc-checkbox.component';

@NgModule({
  declarations: [
    NewDestinationOverlayComponent,
  ],
  imports: [
    CommonModule,
    PpcComponentsModule,
  ],
  exports: [
    NewDestinationOverlayComponent,
    PpcCheckboxComponent
  ],
  providers: [
    HttpClientModule,
  ]
})

export class NewDestinationOverlayModule { }
