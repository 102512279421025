<epc-modal
  #modal
  titleStyle= 'title-bold-20px-center'
  title="Error deleting {{entityName}}"
  descriptionStyle= 'description-17px-center'
  [displayFooter]= false
  description="This {{entityType}} is being used in following insight entities. Please remove it from these insight entities to delete."
  primaryButtonTitle="OK"
  (onClose)="handleOnClose()"

>
<div body>
    <div class="dependency-view">
    <div class="scrollable-table"> 
        <table >
            <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Sub Market</th>
                <th>Widget</th>
                <th>Attribute</th>
            </tr>
                <tr *ngFor="let entityNodeItem of entityNodeList">
                    <td>
                        <img *ngIf="entityNodeItem.type === 'journeys'" ppcTooltip="Journey" alt="" _ngcontent-kvv-c97="" class="gray-icon" src="assets/icons/outline-journey_chart-24px.svg">
                        <img *ngIf="entityNodeItem.type === 'comparisons'" ppcTooltip="Overlay" alt="" _ngcontent-kvv-c97="" class="gray-icon" src="assets/icons/outline-compare_arrows-24px.svg">
                        <i *ngIf="entityNodeItem.type === 'personas'" ppcTooltip="Persona" class="fa fa-user"></i>
                        <img *ngIf="entityNodeItem.type === 'mekkos'" ppcTooltip="Mekko" alt="" _ngcontent-kvv-c97="" class="gray-icon" src="assets/icons/outline-table_chart-24px.svg">
                    </td>
                    <td ppcTooltip="{{entityNodeItem.name}}">
                        {{entityNodeItem.name}}
                    </td>
                    <td ppcTooltip="{{entityNodeItem.submarket}}">
                        {{entityNodeItem.submarket}}
                    </td>
                    <td ppcTooltip="{{entityNodeItem.widget}}">
                        {{entityNodeItem.widget}}
                    </td>
                    <td ppcTooltip="{{entityNodeItem.attribute}}">
                        {{entityNodeItem.attribute}}
                    </td>
                </tr>
        </table>
    </div>
    </div>
</div>
</epc-modal>
