import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { Location } from '@angular/common';

@Component({
  selector: 'ppc-zero-count-info',
  templateUrl: './zero-count-info.component.html',
  styleUrls: ['./zero-count-info.component.sass']
})
export class ZeroCountInfoComponent implements OnInit {
  audienceCopy = false;

  constructor (
    private location: Location,
    public dialog: MatDialogRef<ZeroCountInfoComponent>,
  ) { }

  ngOnInit(): void {
    if (this.location.path().includes('/audiences/')) {
      this.audienceCopy = true;
    }
  }

}
