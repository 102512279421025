import { Action } from "@ngrx/store";
import { Unit } from "./unit.interface";
import { Category } from "./category.interface";

export class FetchAllUnits implements Action {
  static readonly type = 'FETCH_ALL_UNITS';
  readonly type = FetchAllUnits.type;

  constructor(public params: {clientSlug: string, type: string}) {}
}

export class FetchAllCategories implements Action {
  static readonly type = 'FETCH_ALL_CATEGORIES';
  readonly type = FetchAllCategories.type;

  constructor(public params: {clientSlug: string}) {}
}

export class LoadUnit implements Action {
  static readonly type = 'LOAD_UNIT';
  readonly type = LoadUnit.type;

  constructor(public unit: Unit) {}
}

export class LoadUnits implements Action {
  static readonly type = 'LOAD_UNITS';
  readonly type = LoadUnits.type;

  constructor(public units: Unit[]) {}
}

export class LoadCategory implements Action {
  static readonly type = 'LOAD_CATEGORY';
  readonly type = LoadCategory.type;

  constructor(public category: Category) {}
}

export class LoadCategories implements Action {
  static readonly type = 'LOAD_CATEGORIES';
  readonly type = LoadCategories.type;

  constructor(public categories: Category[]) {}
}

export class CreateUnit implements Action {
  static readonly type = 'CREATE_UNIT';
  readonly type = CreateUnit.type;

  constructor(public unit: Unit) {}
}

export class EditUnit implements Action {
  static readonly type = 'EDIT_UNIT';
  readonly type = EditUnit.type;

  constructor(public unit: Unit) {}
}

export class ResetEditUnit implements Action {
  static readonly type = 'RESET_EDIT_UNIT';
  readonly type = ResetEditUnit.type;

  constructor() {}
}

export class UpdateUnit implements Action {
  static readonly type = 'UPDATE_UNIT';
  readonly type = UpdateUnit.type;

  constructor(public unit: Partial<Unit>) {}
}

export class DestroyUnit implements Action {
  static readonly type = 'DESTROY_UNIT';
  readonly type = DestroyUnit.type;

  constructor(public unit: Unit) {}
}

export class HideUnit implements Action {
  static readonly type = 'HIDE_UNIT';
  readonly type = HideUnit.type;

  constructor(public unit: Unit) {}
}

export class CreateCategory implements Action {
  static readonly type = 'CREATE_CATEGORY';
  readonly type = CreateCategory.type;

  constructor(public category: Category) {}
}

export class EditCategory implements Action {
  static readonly type = 'EDIT_CATEGORY';
  readonly type = EditCategory.type;

  constructor(public category: Category) {}
}

export class UpdateCategory implements Action {
  static readonly type = 'UPDATE_CATEGORY';
  readonly type = UpdateCategory.type;

  constructor(public category: Category) {}
}

export class UpdateCategoryPriorities implements Action {
  static readonly type = 'UPDATE_CATEGORY_PRIORITIES';
  readonly type = UpdateCategoryPriorities.type;

  constructor(public categories: Category[]) {}
}

export class DestroyCategory implements Action {
  static readonly type = 'DESTROY_CATEGORY';
  readonly type = DestroyCategory.type;

  constructor(public category: Category) {}
}

export class ResetToolbox implements Action {
  static readonly type = 'RESET_TOOLBOX';
  readonly type = ResetToolbox.type;
}

export type All
          = FetchAllUnits
          | LoadUnit
          | LoadUnits
          | LoadCategory
          | LoadCategories
          | CreateUnit
          | EditUnit
          | ResetEditUnit
          | UpdateUnit
          | DestroyUnit
          | HideUnit
          | CreateCategory
          | EditCategory
          | UpdateCategory
          | UpdateCategoryPriorities
          | DestroyCategory
          | ResetToolbox
