import {Component, HostListener, OnInit} from '@angular/core';
import {versionInfo} from "../../../../environments/versions";

@Component({
  selector: 'app-revision-info',
  templateUrl: './revision-info.component.html',
  styleUrls: ['./revision-info.component.sass']
})
export class RevisionInfoComponent {

  showRevisionInfo: boolean = false;
  branch: string = versionInfo.branch;
  revision: string = versionInfo.revision;
  datetime: string = versionInfo.datetime;

  private konami: number[] = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
  private actual: number[] = this.konami.map(_ => 0);

  @HostListener('document:keydown', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    this.actual.shift();
    this.actual.push(event.keyCode);

    for (let i = 0; i < this.actual.length; i++) {
      if (this.actual[i] != this.konami[i]) {
        return;
      }
    }

    this.showRevisionInfo = true;
    setTimeout(() => {
      this.showRevisionInfo = false;
    }, 5000);
  }

}
