import { AppState } from "app/reducers/index";
import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-breadcrumb',
  templateUrl: './admin-breadcrumb.component.html',
  styleUrls: ['./admin-breadcrumb.component.sass']
})

export class AdminBreadcrumbComponent {

  constructor() { }
}
