import * as actions from 'app/models/motivations/motivations-v1.actions';
import { MotivationV1 } from 'app/models/motivations/motivations-v1.model';
import { createSelector } from '@ngrx/store';
import { findIndex, indexOf } from 'lodash';

export interface State {
  motivationsV1: MotivationV1[];
}

const defaultState = {
  motivationsV1: []
};

export function reducer(state: State = defaultState, action: actions.All): State {
  switch (action.type) {
    case actions.LoadMotivations.type:
      return {
        ...state,
        motivationsV1: action.motivations,
      }
    case actions.ClearMotivations.type:
      return {
        ...state,
        motivationsV1: []
      };
    case actions.LoadUpdatedMotivation.type:
      const indexOfItem = findIndex(state.motivationsV1, {identifier: action.motivation.identifier})
      return { ...state,
        motivationsV1: [...state.motivationsV1.slice(0, indexOfItem), action.motivation, ...state.motivationsV1.slice(indexOfItem + 1)]
      }
    case actions.LoadMotivation.type:
      const motivation = action.motivationData.data.attributes;
      return {
        ...state,
        motivationsV1: [motivation, ...state.motivationsV1],
      };
    case actions.RemoveMotivation.type:
      const indexToDelete = findIndex(state.motivationsV1, { id: action.data.id });
      return { ...state,
        motivationsV1: [...state.motivationsV1.slice(0, indexToDelete), ...state.motivationsV1.slice(indexToDelete + 1)]
      }
    default:
      return state;
  }
}

export const selectMotivationsWithType = createSelector(
  (state: State) => state.motivationsV1,
  (motivations) => motivations.map(motivation => ({...motivation, type: "motivation"}))
)
