<div class="form-container" (submit)="saveMekko()" *ngIf="mekko">
  <h3 class="section-header">{{mekko.id ? "Edit" : "Create"}} Chart</h3>
  <div *ngIf="isGWI && clientVerticals.length > 0 && !mekko.id"
       class="count-type-container"
       ppcRadioGroup [(ngModel)]="creationType"
       (ngModelChange)="setVerticalType($event)">

    <div><label>Method of Creation</label></div>

    <ppc-radio-button value="blank">
      Blank Chart
    </ppc-radio-button>

    <ppc-radio-button value="prePopulated">
      Pre-populated Chart
    </ppc-radio-button>
  </div>

  <mat-select class="vertical-type" [(ngModel)]="mekko.vertical_type" placeholder="Choose an Industry Vertical" *ngIf="isPrePopulatedChart" (selectionChange)="setMekkoName()">
    <mat-option *ngFor="let clientVertical of clientVerticals" [value]="clientVertical.value">{{clientVertical.display}}</mat-option>
  </mat-select>
  <i class="fa fa-question-circle-o"
     *ngIf="isPrePopulatedChart"
     ppcTooltip="Select an industry vertical to create a pre-populated chart within Grow."></i>

  <div class="name-container">
    <ppc-input #mekkoName
      [(ngModel)]="mekko.name"
      [alwaysFloatLabel]="true"
      [validators]="nameValidators"
      [forceValidation]="true"
    >
      <label>Name</label>
    </ppc-input>
  </div>
  <div class="name-container">
      <ppc-input #mekkoLabel
        [(ngModel)]="mekko.measurement_label"
        [alwaysFloatLabel]="true"
      >
        <label>Measurement Label</label>
      </ppc-input>
    </div>

  <div class="count-type-container" ppcRadioGroup [(ngModel)]="mekko.count_type" *ngIf="canUpdateCountType">
      <div><label>Count Type</label></div>
      <ppc-radio-button value="people_count">
        <i class="fa fa-user"></i>
        People Count
      </ppc-radio-button>

      <ng-template #tooltipTemplate>
        <div class="panelist-tooltip">
            <div>Displays estimated TV panelist counts</div>
            <i *ngIf="barbs.maximum">Max count size is {{ barbs.maximum}}</i>
        </div>
      </ng-template>

      <ppc-radio-button value="tv_panelists" ppcTooltip [tooltipTemplate]="tooltipTemplate">
        <i class="fa fa-tv"></i>
        TV Panelists
      </ppc-radio-button>

      <div *ngIf="mekko.count_type === 'tv_panelists'">
        <span><b>Note</b>: Small audiences can produce less accurate results from the TV panelist model.</span>
      </div>
  </div>
  <div class="default-mekko-container">
    <ppc-checkbox [(ngModel)]="mekko.default">Make this my default chart</ppc-checkbox>
  </div>

  <div class="button-container">
    <button
      type="submit"
      class="btn"
      (click)="saveMekko()"
      [disabled]="!isValid"
    >
      Save
    </button>
    <button mat-button (click)="cancel()">Cancel</button>
  </div>
</div>
