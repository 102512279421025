<app-popup
  #popupComponent
  class="popup"
  [class.detail-view]="isDetailView"
  [neverOpenUpwards]="!canOpenUpwards"
  [rightPct]="0"
  [widthPx]="getWidthPixels()"
  >

  <i *ngIf="!isDetailView; else actionsButton" popupTrigger class="trigger fa fa-ellipsis-v"></i>
  <ng-template #actionsButton>
    <button class="trigger" mat-button popupTrigger>
      <span class="action-label">Actions</span>
      <span [class]="'action-arrow fa fa-angle-' + (popupComponent.isOpen ? 'up' : 'down')"></span>
    </button>
  </ng-template>
  <div *ngIf="audience; else loading" popupBody class="popup-items">
    <ng-container>
      <span class="mat-button"
            *ngIf="!isDetailView"
            (click)="openAudienceDetail.emit(audience)"
      >
        View Detail
      </span>

      <span class="mat-button"
        *ngIf="canEdit"
        [class.detail-view]="isDetailView"
        [ngClass]="{'disabled': editDisabled}"
        (click)="editAudience(audience)"
        [ppcTooltip]="editTooltipMessage"
        [tooltipDisabled]="!editDisabled"
      >
        Edit
      </span>

      <span class="mat-button"
        [ngStyle]="{display: 'block'}"
        [class.detail-view]="isDetailView"
        [ngClass]="{'disabled': editDisabled}"
        (click)="cloneAudience(audience)"
        [ppcTooltip]="cloneTooltipMessage"
        [tooltipDisabled]="!editDisabled"
      >
        Clone
      </span>

      <div *ngIf="!isDetailView" class="separator"></div>
    </ng-container>

      <span [ngClass]="{'disabled': !canSeedLookalike}"
          class="mat-button"
          (click)="createLookalike(audience)"
          [ppcTooltip]="lookalikeTooltipMessage"
          [tooltipDisabled]="canSeedLookalike"
      >
      Create Lookalike
    </span>

    <span [ngClass]="{'disabled': !canSendToPartner}"
          class="mat-button"
          (click)="sendToPartner(audience)"
          [ppcTooltip]="sendToPartnerTooltipMessage"
          [tooltipDisabled]="canSendToPartner"
    >
      Send to Partner
    </span>

    <ng-container>
      <div *ngIf="regionIsNotAustralia(); then disabledTardiisWarning else enabledTardiisWarning"></div>

      <ng-template #enabledTardiisWarning>
        <app-confirmation
          [ngClass]="{'disabled': !canSendToTardiis}"
          class="mat-button send-to-tardiis-button"
          [ppcTooltip]="tardiisTooltipMessage"
          [tooltipDisabled]="canSendToTardiis"
          [isCustomTemplate]="true"
          [buttonContinueLabel]="buttonContinueLabel"
          [kontinue]="sendToTardiis.bind(this, audience)"
          title="SendToTardiis"
          [modalTitle]="sendToTardiisWarningMessage"
          >
          Send to Tardiis
        </app-confirmation>
      </ng-template>

      <ng-template #disabledTardiisWarning>
        <span
          [ngClass]="{'disabled': !canSendToTardiis}"
          (click)="sendToTardiis(row);"
          class="mat-button"
          [ppcTooltip]="tardiisTooltipMessage"
          [tooltipDisabled]="canSendToTardiis"
        >
          Send to Tardiis
        </span>
      </ng-template>
    </ng-container>

    <span
      [ngClass]="{'disabled': !canInitiateIndexReport}"
      class="mat-button"
      (click)="createIndexReport(audience)"
      [ppcTooltip]="indexReportTooltipMessage"
      [tooltipDisabled]="canInitiateIndexReport"
    >
      Create Index Report
    </span>

    <div *ngIf="!isDetailView" class="separator"></div>
    <ng-container>
      <div *ngIf="canDelete; then enabledDelete else disabledDelete"></div>
      <ng-template #enabledDelete>
        <app-confirmation
          class="mat-button delete-button"
          title="Delete"
          [isCustomTemplate]="true"
          [kontinue]="delete.bind(this)"
          [modalTitle]="deleteConfirmationMessage"
        >
          Delete
        </app-confirmation>
      </ng-template>

      <ng-template #disabledDelete>
        <span
          [ppcTooltip]="deleteTooltipMessage(audience)"
          class="mat-button disabled"
        >
          Delete
        </span>
      </ng-template>
    </ng-container>
  </div>
  <ng-template #loading popupBody>
    <div></div>
  </ng-template>
</app-popup>
