<div class="info">
  <div class="info-pane" *ngIf="ppcObject">
    <div class="info-set">
      <div class="info-container">
        <div
          class="info-title"
          [ppcTooltip]="tooltipMessages['peopleCount']"
        >
          People Count
        </div>
        <div class="info-value">
          {{calculatedPeopleCount}}
        </div>
      </div>
      <div
        class="info-container"
        *ngIf="canDisplayAddressableCounts">
        <div class="info-title">
          Addressable Count
        </div>
        <div class="info-value">
          {{addressableCountDisplay()}}
        </div>
      </div>
      <div class="info-container" *ngIf="!hideTVPanelistColumn(activeRegion)">
        <div
          class="info-title"
          [ppcTooltip]="panelistDataSourceMessage()"
          [tooltipDisabled]="regionIsNotAustralia"
        >
          TV Panelists
        </div>
        <div class="info-value">
          {{panelistCount}}
        </div>
      </div>
      <div class="info-container" *ngIf="canShowConfidence()">
        <div
          class="info-title"
        >
          Confidence
        </div>
        <div class="info-value">
          {{lookalikeConfidence}}
        </div>
      </div>
      <div class="info-container">
        <div class="info-title">
          Status
        </div>
        <div class="info-value">
          <span [class]="jobStatusClass(ppcObject.job_status)"></span>
          {{jobStatusDisplay(ppcObject.job_status)}}
        </div>
      </div>
      <div class="info-container">
        <div class="info-title">
          Created
        </div>
        <div class="info-value">
          {{ppcObject.created_at | date: 'shortDate'}}
        </div>
      </div>
      <div class="info-container">
        <div class="info-title">
          Refresh End
        </div>
        <div class="info-value">
          <div [class.red]="expiredDate(ppcObject.refresh_end_date)">{{ppcObject.refresh_end_date | date: 'shortDate'}}</div>
        </div>
      </div>
      <div class="info-container">
        <div class="info-title">
          Expiration
        </div>
        <div class="info-value">
          <div [class.red]="expiredDate(ppcObject.expiration)">{{ppcObject.expiration | date: 'shortDate'}}</div>
        </div>
      </div>
      <div class="action-menu-container">
        <ppc-action-menu
          [ppcItem]="ppcObject"
          [isDetailView]="true"
          [activeRegion]="activeRegion"
          (openActivationOverlay)="handleSendToPartner()"
          (sendToTardiis)="handleSendToTardiis()"
          (openIndexReportForm)="handleCreateIndexReport()"
        >
        </ppc-action-menu>
      </div>
    </div>
  </div>
</div>
