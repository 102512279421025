import { Injectable, OnDestroy } from "@angular/core";
import { BuilderAudience } from "app/audience-builder/audience-builder.models";
import { Store, select } from "@ngrx/store";
import {
  BehaviorSubject,
  Observable,
  of as observableOf,
  combineLatest as observableCombineLatest,
} from "rxjs";
import { combineLatest, distinctUntilChanged, map, switchMap, filter, take } from "rxjs/operators";
import {
  cloneDeep,
  difference,
  get,
  find,
  isEmpty,
  isEqual,
  map as _map,
  reject,
} from "lodash";
import {
  newPersonaAudience,
} from "app/audience-builder/audience-builder.utils";
import { AppState } from "app/reducers";
import { fullContext } from "app/hierarchy/hierarchy.reducers";
import { Go } from "app/router/router.actions";
import { ActivatedRoute, Params } from "@angular/router";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  fetchOutcome,
  isLoaded,
  isNotYetFetched,
} from "app/shared/utils/fetch-state";
import {
  Persona,
  newPersona,
  selectActivePersona,
  selectPersonas,
} from "app/explore/explore.reducer";
import * as actions from "app/explore/explore.actions";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions } from "@ngrx/effects";
import { buildUrlRelative, isDefined } from "app/shared/utils/utils";
import { SetPrebuiltAudience } from "app/audience-builder/audience-builder.actions";
import { AudienceMapper } from "app/audience-builder/audience-mappers/segments-api";
import { ClearFilters } from "app/insights/insights.actions";
import {
  allPersonaIdentifiers,
  allSegmentIdentifiers,
  buildOrGroups,
  combineIdentifiers,
  deletedSegmentsError,
  getFilterIdentifiers,
  getAudienceIdentifiers,
  setPersonaAudienceRules,
  removeDeletedSegments
} from "app/explore/explore.utils";
import { MotivationAudienceService } from "app/motivation-audience/motivation-audience.service";
import { HttpClient } from "@angular/common/http";
import { SegmentV2Service } from "app/segments-v2/segment-v2.service";
import { SegmentLike } from 'app/models/segment-like.model';
import { topLevelTabsUrl } from "app/shared/constants/insights.urls";

@UntilDestroy()
@Injectable()
export class PersonaBuilderService implements OnDestroy {
  public audience: BuilderAudience;
  audience$: Observable<BuilderAudience>;
  isPersonaAudience$ = this.route.queryParams.pipe(
    map(
      (queryParams) =>
        queryParams &&
        (queryParams.action === "savePersona" ||
          queryParams.action === "requestMotivationAudience")
    )
  );
  isPersonaContext$ = this.route.queryParams.pipe(
    map((queryParams) => queryParams && queryParams.action === "savePersona")
  );
  showMoveExploreFilters$ = observableCombineLatest(
    this.isPersonaAudience$,
    this.motivationService.isMotivation$
  ).map(
    ([isPersonaAudience, isMotivation]) => isPersonaAudience || isMotivation
  );
  persona$ = observableCombineLatest(
    this.isPersonaAudience$,
    this.route.params,
    this.store.select("explore").pipe(select(selectActivePersona)),
    this.store.select("explore", "personaUnderEdit")
  ).pipe(
    map(([isPersonaAudience, params, activePersona, personaUnderEdit]) => {
      return !isEmpty(personaUnderEdit)
        ? personaUnderEdit
        : isPersonaAudience
          ? params.personaId
            ? activePersona
            : newPersona()
          : null;
    })
  );
  persona: Persona;
  otherPersonas: Persona[];
  params: Params;
  queryParams: Params;
  filterIdentifiers: string[][];
  filtersMoved$ = new BehaviorSubject<boolean>(false);
  moveFiltersDisabled$ = observableCombineLatest(
    this.store.select("insights", "explore", "filters"),
    this.store
      .select("explore")
      .pipe(select(selectActivePersona), filter(isDefined)),
    this.route.params,
    this.filtersMoved$
  ).pipe(
    map(
      ([filters, persona, params, filtersMoved]) =>
        filtersMoved ||
        (isEmpty(filters) && (params.personaId || isEmpty(persona.identifiers)))
    )
  );
  moveFiltersTooltip$ = this.moveFiltersDisabled$.pipe(
    map((isDisabled) => {
      return isDisabled
        ? "No filters were previously selected in Explore."
        : "Auto-populate the previously selected filters from Explore into the Persona builder.";
    })
  );
  audienceBuilderAudience$ = new BehaviorSubject<BuilderAudience>(null);
  deletedSegmentsError: string;
  errorMessageActive = false;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private actions$: Actions,
    private audienceMapper: AudienceMapper,
    private motivationService: MotivationAudienceService,
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private segmentService: SegmentV2Service
  ) {
    fullContext(this.store)
      .pipe(
        switchMap(() =>
          this.store.select("fetchStates", actions.FetchPersonas.type)
        ),
        filter(isNotYetFetched),
        untilDestroyed(this)
      )
      .subscribe(() => this.store.dispatch(new actions.FetchPersonas()));

    const segments$: Observable<SegmentLike[]> = this.persona$.pipe(
      filter(isDefined),
      map(persona => persona.deletedSegmentsChecked ? [] : allPersonaIdentifiers(persona.identifiers)),
      distinctUntilChanged(isEqual),
      switchMap(identifiers => isEmpty(identifiers) ? observableOf([]) as Observable<SegmentLike[]> : this.segmentService.fetchByIdentifiers(identifiers)),
    ).pipe(map(segments => segments))

    const deletedSegments$: Observable<string[]> = observableCombineLatest([
      this.persona$.pipe(
        filter(isDefined),
        map((persona) => persona.identifiers),
        map((identifiers) => allPersonaIdentifiers(identifiers))
      ),
      segments$.pipe(map((segments) => allSegmentIdentifiers(segments)), filter((segments) => !isEmpty(segments))),
    ]).pipe(map(([activePersonaIdentifiers, segmentIdentifiers]) => difference(activePersonaIdentifiers, segmentIdentifiers)));

    this.persona$.pipe(
      filter(isDefined),
      untilDestroyed(this)
    ).subscribe(persona => {
      if (persona.deletedSegmentIds?.length > 0) {
        this.setDeletedSegmentIdsErrorMessage(persona.deletedSegmentIds);
      }
    });

    const editPersonaAudience$ = observableCombineLatest(
      this.route.params,
      this.persona$.pipe(filter(isDefined)),
      this.store.select("audienceBuilder", "prebuiltAudience"),
      this.audienceBuilderAudience$,
      deletedSegments$
    ).pipe(
      filter(
        ([params, persona, prebuiltAudience, audienceBuilderAudience]) =>
          params.personaId
      ),
      map(([params, persona, prebuiltAudience, audienceBuilderAudience, deletedSegmentIds]) => {
        if (deletedSegmentIds?.length > 0) {
          if (!persona.deletedSegmentIds) {
            this.store.dispatch(new actions.SetActivePersonaDeletedSegmentIds(persona, deletedSegmentIds));
          }
          this.setDeletedSegmentIdsErrorMessage(deletedSegmentIds);
          removeDeletedSegments(persona, deletedSegmentIds);
        }

        return setPersonaAudienceRules(
          persona.name,
          persona.default,
          buildOrGroups(persona.identifiers)
        )
      })
    );

    editPersonaAudience$
      .pipe(
        take(1),
        switchMap((audience) => this.audienceMapper.fromJsonPersona(audience)),
        untilDestroyed(this)
      )
      .subscribe((builderAudience) => {
        return this.store.dispatch(
          new SetPrebuiltAudience({
            ...builderAudience,
            name: builderAudience.name,
            id: builderAudience.id,
          })
        );
      });

    this.audience$ = observableCombineLatest(
      this.isPersonaAudience$,
      this.store.select("audienceBuilder", "prebuiltAudience"),
      fullContext(store)
    ).pipe(
      map(
        ([isPersonaAudience, prebuiltAudience]) =>
          prebuiltAudience || newPersonaAudience()
      )
    );

    const addedFiltersAudience$ = observableCombineLatest(
      this.filtersMoved$,
      this.store.select("explore").pipe(select(selectActivePersona)),
      this.store.select("audienceBuilder", "prebuiltAudience"),
      this.store
        .select("insights", "explore", "filters")
        .pipe(map((filters) => getFilterIdentifiers(filters))),
      this.audienceBuilderAudience$
    ).pipe(
      filter(([moveFilters]) => moveFilters),
      map(
        ([
          moveFilters,
          persona,
          prebuiltAudience,
          filters,
          audienceBuilderAudience,
        ]) => {
          const ids = combineIdentifiers(
            filters,
            persona,
            audienceBuilderAudience.rules
          );
          return setPersonaAudienceRules(
            audienceBuilderAudience.name,
            audienceBuilderAudience.default,
            ids
          );
        }
      )
    );

    addedFiltersAudience$
      .pipe(
        take(1),
        switchMap((audience) => this.audienceMapper.fromJsonPersona(audience)),
        untilDestroyed(this)
      )
      .subscribe((builderAudience) => {
        return this.store.dispatch(
          new SetPrebuiltAudience({
            ...builderAudience,
            name: builderAudience.name,
            id: builderAudience.id,
          })
        );
      });

    observableCombineLatest(this.route.queryParams, this.route.params)
      .pipe(untilDestroyed(this))
      .subscribe(([queryParams, params]) => {
        this.queryParams = queryParams;
        this.params = params;
      });

    observableCombineLatest(
      this.isPersonaAudience$,
      fullContext(this.store).pipe(map(({ client }) => client.slug))
    )
      .pipe(
        filter(
          ([isPersonaAudience, contextClientSlug]) =>
            isPersonaAudience &&
            !isEqual(contextClientSlug, this.params.clientSlug)
        ),
        untilDestroyed(this)
      )
      .subscribe(([isPersonaAudience, contextClientSlug]) =>
        this.store.dispatch(new SetPrebuiltAudience(newPersonaAudience()))
      );

    observableCombineLatest(
      this.http.get(topLevelTabsUrl()),
      fullContext(this.store).pipe(map(({ region }) => region.slug)),
      this.route.params
    )
      .pipe(untilDestroyed(this))
      .subscribe(([tabs, regionSlug]) => {
        const personLevelTab = find(tabs, {
          tab_key: "top_level_person",
          insights_context: "explore",
        });
        const marketLevelOnlyRegions = get(personLevelTab, "excluded_regions");
        if (marketLevelOnlyRegions.includes(regionSlug)) {
          this.store.dispatch(
            new Go({ path: buildUrlRelative(this.params, "insights/explore") })
          );
        }
      });

    // Remove id from params if persona cannot be found
    this.route.params
      .pipe(
        map(({ personaId }) => +personaId),
        combineLatest(
          this.store
            .select("fetchStates", actions.FetchPersonas.type)
            .pipe(select(isLoaded))
        ),
        filter(
          ([personaId, PersonasAreLoaded]) => personaId && PersonasAreLoaded
        ),
        switchMap(([personaId]) =>
          this.store
            .select("explore", "personas")
            .pipe(map((personas) => find(personas, { id: personaId })))
        ),
        filter((persona) => !persona || persona.is_all_data),
        untilDestroyed(this)
      )
      .subscribe((persona: Persona) => {
        if (persona && persona.is_all_data) {
          this.store.dispatch(
            new Go({ path: buildUrlRelative(this.params, "insights/explore") })
          );
        } else {
          this.store.dispatch(
            new Go({
              path: buildUrlRelative(this.params, "insights/explore/builder"),
              query: { ...this.queryParams, returnUrl: "insights/explore" },
            })
          );
        }
      });

    this.audience$
      .pipe(map(cloneDeep), untilDestroyed(this))
      .subscribe((audience) => {
        this.audience = audience;
        this.audienceBuilderAudience$.next(this.audience);
      });

    observableCombineLatest(
      this.persona$.pipe(map(cloneDeep)),
      this.store.select("explore").pipe(select(selectPersonas))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([persona, personas]) => {
        this.persona = persona;
        this.otherPersonas = reject(personas, { id: get(persona, "id") });
      });

    this.store
      .select("insights", "explore", "filters")
      .pipe(untilDestroyed(this))
      .subscribe(
        (filters) => (this.filterIdentifiers = getFilterIdentifiers(filters))
      );
  }

  ngOnDestroy() {
    this.store.dispatch(new actions.EditPersona(null));
  }

  savePersonaFromPrebuiltAudience(audience, returnAction) {
    const audienceIds = {
      included_ids: getAudienceIdentifiers(audience, "include"),
      excluded_ids: getAudienceIdentifiers(audience, "exclude"),
    };
    const builderPersona = this.persona;
    this.store.dispatch(
      new actions.SavePersona({
        ...builderPersona,
        name: audience.name,
        identifiers: audienceIds,
      })
    );
    this.actions$
      .pipe(fetchOutcome(actions.SavePersona.type), take(1))
      .subscribe(
        (response) => {
          const persona = get(response, ["result", "persona"]);
          this.snackbar.open(
            `${persona.name} has been ${
              builderPersona.id ? "updated" : "created"
            }!`,
            "OK",
            {
              panelClass: ["success"],
              duration: 5000,
            }
          );
          // dispatch this persona as default
          if (audience.default) {
            this.store.dispatch(new actions.SaveDefaultPersona(persona.id));
          } else if (builderPersona.id && !audience.default) {
            // default persona has been edited to unset default distinction
            this.store.dispatch(new actions.SaveDefaultPersona(0));
          }
          this.store.dispatch(new actions.SetActivePersonaDeletedSegmentIds(persona, null));
          this.store.dispatch(new actions.SetSelectedPersona(persona));
          this.store.dispatch(new ClearFilters("explore"));
          returnAction(builderPersona.id ? null : persona.id);
        },
        (error) => {
          const message = get(
            error,
            ["error", "error", "message"],
            "Something went wrong saving this Persona. Please try again later."
          );
          this.snackbar.open(message, "OK", {
            panelClass: ["danger"],
          });
        }
      );
  }

  public hasUniqueName(audience) {
    return !find(
      this.otherPersonas,
      (persona) =>
        persona.name.trim().toLowerCase() === audience.name.trim().toLowerCase()
    );
  }

  public get subject() {
    return this.motivationService.isMotivation
      ? this.motivationService.subject
      : this.persona
        ? "Persona"
        : null;
  }

  public get pageTitle() {
    return this.motivationService.isMotivation
      ? this.motivationService.pageTitle
      : this.persona
        ? "Persona Builder"
        : null;
  }

  public get estimatedPeopleCountTooltip() {
    return this.motivationService.isMotivation
      ? this.motivationService.estimatedPeopleCountTooltip
      : this.persona
        ? "Count of unique people in this persona"
        : null;
  }

  public get placeholder() {
    return this.persona ? this.persona.name : null;
  }

  setDeletedSegmentIdsErrorMessage(deletedSegmentIds: string[]) {
    this.errorMessageActive = true;
    this.deletedSegmentsError = deletedSegmentsError(deletedSegmentIds);
  }
}
