import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable, combineLatest } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { Store } from "@ngrx/store"
import { AppState } from 'app/reducers'
import { HierarchyService } from 'app/services/hierarchy.service'
import { validateSlugs, getSlugs, getContext, extendContext } from 'app/hierarchy/hierarchy.utils'
import { Go } from "app/router/router.actions";

@Injectable()
export class HomeContextGuard implements CanActivate {

  constructor(private store: Store<AppState>, private hierarchyService: HierarchyService) {}

  // Select a client context to navigate to if we land on `/home` without one
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest(
      this.hierarchyService.validContextSlugs$,
      this.hierarchyService.loadedHierarchy$
    ).pipe(
      map(([ validContextSlugs, hierarchy ]) => {
        const nextSlugs = getSlugs(next.params);
        const validSlugs = nextSlugs.length === 0 ? getSlugs(validContextSlugs) : nextSlugs

        // If the next context could be more specific, redirect to the more specific context
        const extendedContext = extendContext(getContext(hierarchy, validSlugs));
        const extendedSlugs = getSlugs(extendedContext)
        if (extendedSlugs.length > nextSlugs.length) {
          setTimeout(() => this.store.dispatch(new Go({path: [...extendedSlugs, 'home']})))
          return false
        }

        return true
      })
    )
  }
}
