import { Action } from "@ngrx/store";
import { HierarchyPermissions, PermissionSlugs } from "./hierarchy-permissions.interface";

export class FetchHierarchyPermissions implements Action {
  static readonly type = 'FETCH_HIERARCHY_PERMISSIONS';
  readonly type = FetchHierarchyPermissions.type;

  constructor(public userId: number | string) {}
}

export class LoadHierarchyPermissions implements Action {
  static readonly type = 'LOAD_HIERARCHY_PERMISSIONS';
  readonly type = LoadHierarchyPermissions.type;

  constructor(public userId: string, public permissions: HierarchyPermissions) {}
}

export class SaveHierarchyPermissions implements Action {
  static readonly type = 'SAVE_HIERARCHY_PERMISSIONS';
  readonly type = SaveHierarchyPermissions.type;

  constructor(public userId: string | number, public slugs: string[]) {}
}

export type All = FetchHierarchyPermissions | LoadHierarchyPermissions | SaveHierarchyPermissions;
