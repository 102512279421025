import { Theme, COLORS } from './symbols';

export const darkTheme: Theme = {
  name: 'dark',
  properties: {
    // COLORS
    '--background': COLORS.mineShaft,
    '--on-background': COLORS.white,
    '--primary': COLORS.pictonBlue,
    '--primary-alt': COLORS.curiousBlue,
    '--primary-alt-2': COLORS.seagull,
    '--on-primary': COLORS.white,
    '--secondary': COLORS.window,
    '--on-secondary': COLORS.pictonBlue,
    '--tertiary': COLORS.window,
    '--on-tertiary': COLORS.window,
    '--accent': COLORS.diSierra,
    '--disabled': COLORS.scorpion,
    '--on-disabled': COLORS.silver,
    '--line': `1px solid ${COLORS.scorpion}`,
    // MISC
    '--ppc-logo-svg-path': 'url(/assets/images/ppc_logo/ppc_logo_white.svg)',
    // COMPONENT SPECIFIC
    // naming convention (follow sass hierarchy with _)
    // class_(subclass|psuedo)_css-property
    '--pds-tab-group-header-window_border-color': COLORS.white,
    '--pds-tab-group-header-tab_color': COLORS.silver,
    '--pds-tab-group-header-tab_hover_border-color': COLORS.tundora,
    '--pds-tab-group-header-tab_selected_color': COLORS.white,
    '--pds-tab-group-header-tab_selected_border-color': COLORS.white,
  }
};
