import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';

import * as actions from 'app/lookalikes-v2/lookalike-v2.actions';
import { fetchResource, ResetFetchState } from 'app/shared/utils/fetch-state';
import { LookalikeV2 } from 'app/lookalikes-v2/lookalike-v2.model';
import { ChangeContext } from 'app/hierarchy/hierarchy.actions';
import { lookalikesV1Url, lookalikeV1Url } from 'app/shared/constants/segments.urls';

@Injectable()
export class LookalikeV2Service {
  @Effect()
  createLookalike$ = this.actions$.pipe(
    ofType(actions.CreateLookalike.type),
    (
      fetchResource(
        ({ lookalike }) => this.create(lookalike).pipe(
          map(lookalike => new actions.LoadLookalike(lookalike))
        )
      )
    ));

  @Effect()
  updateLookalike$ = this.actions$.pipe(
    ofType(actions.UpdateLookalike.type),
    (
      fetchResource(
        ({ lookalike }) => this.update(lookalike).pipe(
          map(lookalike => new actions.LoadUpdatedLookalike(lookalike))
        )
      )
    ));

  @Effect()
  fetchLookalikes$ = this.actions$.pipe(
    ofType(actions.FetchLookalikes.type),
    (
      fetchResource(
        () => this.getAll().pipe(map(resp => new actions.LoadLookalikes(resp.data)))
      )
    ));

  @Effect()
  contextChange$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    mergeMap(() => [
      new ResetFetchState(actions.FetchLookalikes),
      new actions.ClearLookalikes()
    ]));

  @Effect()
  destroyLookalike$ = this.actions$.pipe(
    ofType(actions.DestroyLookalike.type),
    fetchResource(
      ({ lookalike }) => this.delete(lookalike).map(res => new actions.RemoveLookalike(res.data))
    )
  );

  constructor(private http: HttpClient, private actions$: Actions) { }

  create(lookalike: LookalikeV2): Observable<LookalikeV2> {
    return this.http.post<LookalikeV2>(lookalikeV1Url(), lookalike);
  }

  getAll() {
    return this.http.get<{count: number, data: LookalikeV2[]}>(lookalikesV1Url());
  }

  update(lookalike: LookalikeV2): Observable<LookalikeV2> {
    return this.http.put<LookalikeV2>(lookalikeV1Url() + `/${lookalike.identifier}`, lookalike);
  }

  delete(lookalike: LookalikeV2): Observable<any> {
    return this.http.delete<LookalikeV2>(lookalikeV1Url() + `/${lookalike.id}`);
  }
}
