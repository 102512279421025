import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Directive, Input, ElementRef, OnDestroy, OnChanges, TemplateRef, SimpleChanges, AfterViewInit } from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { PpcTooltipService, TooltipEvent } from './ppc-tooltip.service';

@UntilDestroy()
@Directive({
  selector: '[ppcTooltip]'
})
export class PpcTooltipDirective implements OnDestroy, OnChanges, AfterViewInit {

  @Input() tooltipAlignment?: string;
  @Input() tooltipCustomPosition: boolean = false;
  @Input() tooltipDelayOpen?: boolean;
  @Input() tooltipShowEvent: string = 'mouseover';
  @Input() tooltipHideEvent: string = 'mouseleave';
  @Input() tooltipTemplate?: string | TemplateRef<string>;
  @Input('ppcTooltip') tooltipText?: string | TemplateRef<string>;
  @Input() tooltipDelayTime?: number;
  @Input() tooltipDisabled: boolean;
  @Input() tooltipOffset: {x?: number, y?: number};

  usesTemplate: boolean;
  isTooltipDisplayed: boolean;
  constructor(
    private element: ElementRef,
    private tooltipService: PpcTooltipService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.interpretVariables();
  }

  ngAfterViewInit(): void {
    this.bindShowTooltipEvent();
    this.bindHideTooltipEvent();
  }

  ngOnDestroy() {}

  private interpretVariables() {
    if (this.tooltipDelayOpen === undefined) {
      if (this.tooltipDelayTime) { this.tooltipDelayOpen = true; }
    }
    this.usesTemplate = false;
    if (this.tooltipText && this.tooltipText.constructor.name === "TemplateRef_") {
      this.tooltipTemplate = this.tooltipText;
    }
    if (this.tooltipTemplate) {
      this.usesTemplate = true;
    }
  }

  private bindShowTooltipEvent() {
    const eventName = this.tooltipShowEvent === 'click' ? 'click' : 'mouseenter';
    const showTooltipTrigger$ = fromEvent(this.element.nativeElement, eventName);

    showTooltipTrigger$
      .pipe(untilDestroyed(this))
      .subscribe((e: MouseEvent) => {
        if (!this.tooltipDisabled) { this.displayTooltip(e); }
      });
  }

  private bindHideTooltipEvent() {
    let hideTooltipTrigger$;
    const shouldCloseOnClick = this.tooltipHideEvent === 'click';
    if (shouldCloseOnClick) {
      hideTooltipTrigger$ = merge(
        fromEvent(this.element.nativeElement, 'click', {capture: true}),
        fromEvent(this.element.nativeElement, 'mouseleave'));
    } else {
      hideTooltipTrigger$ = fromEvent(this.element.nativeElement, 'mouseleave', {capture: true});
    }

    hideTooltipTrigger$
      .pipe(untilDestroyed(this))
      .subscribe((e: MouseEvent) => this.hideTooltip(e, shouldCloseOnClick));
  }

  private displayTooltip(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const tooltipEvent: TooltipEvent = {
      alignment: this.tooltipAlignment,
      target: this.element.nativeElement,
      content: this.tooltipTemplate || this.tooltipText,
      action: 'open',
      usesTemplate: this.usesTemplate,
      delayOpen: this.tooltipDelayOpen,
      delayTime: this.tooltipDelayTime,
      eventTarget: this.element.nativeElement,
      offset: this.tooltipOffset
    }
    this.tooltipService.triggerTooltipEvent(tooltipEvent);
  }

  private hideTooltip(event: MouseEvent, allowPropagation: boolean = false): void {
    if (!allowPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.tooltipService.triggerTooltipEvent({action: 'close'});
  }
}
