<div class="inputs-container">
  <div class="input-row">
    <div class="eight-col">
      <ppc-input #nameInput [(ngModel)]="planInputs.name" [alwaysFloatLabel]="true" [validators]="nameValidators"><label>Name</label></ppc-input>
    </div>
    <div class="four-col" [class.disabled]="importedPlan">
      <div class="date-range-picker-group">
        <label class="date-range-picker-label">Flight Dates</label>
        <app-date-range-picker #dateRangePicker tense="future" placeholder="Select" class="date-range-picker"
          [(ngModel)]="planInputs.flightDates" [options]="dateRangePickerOptions" (selected)="validateDates($event)"
          (cancel)="validateDates(planInputs.flightDates)">
        </app-date-range-picker>
      </div>
      <mat-error *ngIf="!validations.datesSelected">Please select flight dates</mat-error>


    </div>
  </div>

  <div class="input-row" [class.disabled]="importedPlan">
    <div class="two-col">
      <ng-container *ngIf="editMode; else curveDropdown">
        <label>Curve</label>
        <div>{{(planInputs.curveType.name)}}</div>
      </ng-container>
      <ng-template #curveDropdown>
        <mat-form-field>
          <mat-select placeholder="Curve" [(ngModel)]="planInputs.curveType" name="curveType" (onClose)="validateCurve()">
            <mat-option [value]="curveType" *ngFor="let curveType of curveTypes" (click)="onSelectItem()">{{(curveType.name)}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
      <mat-error *ngIf="!validations.curveSelected">Please select a curve</mat-error>

    </div>
    <div class="two-col">
      <ng-container *ngIf="editMode; else goalTypeDropdown">
        <label>Goal Type</label>
        <div>{{(planInputs.goalType)}}</div>
      </ng-container>
      <ng-template #goalTypeDropdown>
        <mat-form-field>
          <mat-select placeholder="Goal Type" [(ngModel)]="planInputs.goalType" name="goalType" (onClose)="validateGoalType()"
            [disabled]="goalTypeOptions.length < 1">
            <mat-option [value]="goalType" *ngFor="let goalType of goalTypeOptions">{{(goalType)}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
      <mat-error *ngIf="!validations.goalTypeSelected">Please select a goal type</mat-error>
    </div>
    <div [class.four-col]="importedPlan || !isTRP" [class.two-col]="!importedPlan && isTRP" style="position: relative">
      
      
      <ppc-input #goalInput name="goal" [(ngModel)]="planInputs.goal" (change)="parseInputNumber(planInputs.goal, 'goal')"
        type="number" step="0.01" [alwaysFloatLabel]="true" [validators]="goalValidators">
        <label>{{(planInputs.goalType)}} Goal</label>
        <span suffix *ngIf="selectedGoalType && selectedGoalType.unit == 'Percentage'">%</span>
        <span prefix *ngIf="selectedGoalType && selectedGoalType.unit == 'Currency'" >{{currencySymbol}}</span>
      </ppc-input>
    </div>

    <div class="two-col" *ngIf="!importedPlan && isTRP">
      <ppc-input #universeInput name="universe" [(ngModel)]="planInputs.universe" type="number" step="0.01" (change)="parseInputNumber(planInputs.universe, 'universe', 0)"
        [alwaysFloatLabel]="true" [validators]="numberInputValidators('universe')">
        <label>Universe</label>
      </ppc-input>
    </div>

  </div>
</div>
