<ng-container *ngIf="(accessibleProjects$ | async)?.length">
  <span class="header">What type of project would you like to start?</span>
  <a class="hide-list" (click)="toggleHide()">{{ hide ? 'Show' : 'Hide' }} List</a>

  <div class="layout horizontal wrap" *ngIf="!hide">

    <div class="layout horizontal wrap justified projects-container"
         [class.highlight]="project.highlight"
         *ngFor="let project of accessibleProjects$ | async; let i = index">

      <div class="layout vertical project">

        <div class="title">{{ project.title }}</div>
        <div class="flex-grow-1 body">{{ project.body }}</div>
        <div class="layout horizontal center-justified">
          <a class="button"
             (click)="navigateTo(project)"
             (mouseover)="project.highlight = true"
             (mouseout)="project.highlight = false">
            Start
          </a>
        </div>

      </div>

    </div>

  </div>
</ng-container>
