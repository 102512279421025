import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { cloneDeep, keyBy, sortBy, values } from 'lodash';

import { RegionService } from './region.service';
import { getCurrencies } from 'app/currencies/currencies.reducers'
import { CountriesService } from '../countries/countries.service';

import { emptyRegion, Region } from './region.model';
import { Country } from '../countries/country.interface';
import { FeatureModule } from 'app/feature-access/feature-access.reducers';
import { FetchHierarchy } from 'app/hierarchy/hierarchy.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { takeUntil, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.sass']
})
export class RegionComponent implements OnInit, OnDestroy {

  regions: { [id: string]: Region};
  regionUnderEdit: Region;
  countries: Country[] = [];
  featureModules: FeatureModule[];
  currencies$ = this.store.select('currencies').pipe(map(getCurrencies))
  destroyed$ = new Subject();

  regionName = new FormControl('', [Validators.required, Validators.minLength(2)]);
  esIndex = new FormControl('', [Validators.required]);
  moleculaIndex = new FormControl('', [Validators.required]);
  maxCompareWidgets = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1), Validators.max(2)]);
  tabBucketLimit = new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1), Validators.max(200)]);

  constructor(
    private regionService: RegionService,
    private countriesService: CountriesService,
    private store: Store<AppState>,
    private snackbar: MatSnackBar) {}

  ngOnInit() {
    // Converts array of region objects into single object
    // Each region's id is used as the property name on the final object
    this.regionService.getAllRegions()
      .subscribe(regionArray => {
        const regionObject = keyBy(regionArray, region => region.id);
        this.regions = regionObject;
      });

    this.countriesService.getCountries()
      .subscribe(countries => {
        this.countries = sortBy(countries, 'name') as Country[];
      });

    this.store.select("featureAccess", "features").pipe(
      map(values),
      takeUntil(this.destroyed$)
    ).subscribe(featureModules => this.featureModules = featureModules);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  addRegion() {
    this.regionUnderEdit = emptyRegion();
  }

  editRegion(region: Region) {
    this.regionUnderEdit = cloneDeep(region);
  }

  saveRegion() {
    this.regionService.saveRegion(this.regionUnderEdit).subscribe(
      region => {
        this.regions[region.id] = region;
        this.regionUnderEdit = null;
        this.store.dispatch(new FetchHierarchy())
        this.snackbar.open('Region saved successfully', "OK", {panelClass: ['success'], duration: 6000})
      },
      () => this.snackbar.open('Region failed to save', "OK", {panelClass: ['danger'], duration: 6000})
    );
  }

  displayFeatureExclusions(featureId: string) {
    const featureModule = this.featureModules.find(feature => Number(feature.id) === Number(featureId));
    if (featureModule) {return `${featureModule.category}: ${featureModule.name}`}
  }

  get regionsList() {
    return values(this.regions || {});
  }

  getCountriesString(country_ids: number[]): string {
    const countryObjs = this.countries.filter(country => {
      return country_ids.includes(country.id);
    });

    const countryNames = countryObjs.map(country => country.name);
    return countryNames.join(', ');
  }
}
