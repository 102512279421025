import {Component, ContentChild, TemplateRef, Input} from "@angular/core";
import {MaterialModalService} from "../material-modal/material-modal.service";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.sass']
})
export class ConfirmationComponent {

  @Input() isIcon: boolean = false;
  @Input() isButton: boolean = false;
  @Input() isCustomTemplate: boolean = false;
  @Input() disabled: boolean;
  @Input() buttonClass: string = 'btn btn-save';
  @Input() buttonLabel: string = 'DELETE';
  @Input() modalTitle: string = 'Are you sure you want to delete this?';
  @Input() modalContent: string = "";
  @Input() buttonCancelLabel: string = 'CANCEL';
  @Input() buttonContinueLabel: string = 'DELETE';
  @Input() kontinue: ($event) => void;
  @Input() dialogTemplate: TemplateRef<any>;

  @ContentChild(TemplateRef)
  public itemTemplate: TemplateRef<any>;

  constructor(private materialModalService: MaterialModalService) { }

  openDialog($event) {
    this.materialModalService.confirm(this.modalTitle, this.modalContent, this.buttonCancelLabel, this.buttonContinueLabel, this.dialogTemplate).subscribe(
      kontinue => kontinue && this.kontinue($event)
    )
  }

}
