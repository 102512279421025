import { NgModule } from '@angular/core';
import { UserFormComponent } from './user-form.component';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';

@NgModule({
  imports: [
    PpcComponentsModule
  ],
  declarations: [
    UserFormComponent
  ],
  exports: [
    UserFormComponent
  ]
})
export class UserFormModule { }
