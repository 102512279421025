<header>
  <app-admin-breadcrumb></app-admin-breadcrumb>
</header>
<main>
  <div class="wrapper">
    <h1 class="header">
      Regions Admin
    </h1>

    <button class="btn" (click)="addRegion();dialog.show()">
      Add Region
      <i class="fa fa-plus"></i>
    </button>

    <ppc-dialog #dialog class="edit-region">
      <h3 header  *ngIf="regionUnderEdit">
        {{ regionUnderEdit.id ? "Edit Region" : "Create Region"}}
      </h3>

      <div body *ngIf="regionUnderEdit">

        <ppc-input [(ngModel)]="regionUnderEdit.name"
                   [formControl]="regionName">
          <label>Name</label>
          <div help-text>
            Must be at least 2 characters long
          </div>
        </ppc-input>

        <mat-form-field>
          <mat-select placeholder="Countries"
                     [multiple]="true"
                     [(ngModel)]="regionUnderEdit.country_ids">
            <mat-option *ngFor="let country of countries"
                       [value]="country.id">
                      {{country.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select [(ngModel)]="regionUnderEdit.currency"
                      placeholder="Currency">
            <mat-option *ngFor="let currency of currencies$ | async"
                        [value]="currency.id">
              {{currency.name}} ({{currency.iso_code}})
            </mat-option>
          </mat-select>
        </mat-form-field>
        
        <div class="db-search">
          <ppc-input [(ngModel)]="regionUnderEdit.es_index"
                     [formControl]="esIndex">
            <label>ES Index</label>
            <div help-text>
              The ElasticSearch index to query for segment data in this region
            </div>
          </ppc-input>
          
          <ppc-input [(ngModel)]="regionUnderEdit.molecula_index"
                     [formControl]="moleculaIndex">
            <label>Molecula Index</label>
            <div help-text>
              The Molecula index to query for segment data in this region
            </div>
          </ppc-input>
        </div>
        
        <mat-form-field>
          <mat-select placeholder="Excluded Features"
                      [multiple]="true"
                      [(ngModel)]="regionUnderEdit.feature_exclusions">
            <mat-option *ngFor="let feature of featureModules" [value]="feature.id+''">
              {{feature.category?.toUpperCase()}}: {{feature.name?.toUpperCase()}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ppc-input [(ngModel)]="regionUnderEdit.max_compare_widgets"
                   [formControl]="maxCompareWidgets"
                   type="number"
                   [min]="1"
                   [max]="2"
                   required>
          <label>Max Compare Widgets</label>
          <div help-text>
            Choose how many person level compare widgets are allowed for this region
          </div>
        </ppc-input>

        <ppc-input [(ngModel)]="regionUnderEdit.tab_bucket_limit"
                   [formControl]="tabBucketLimit"
                   type="number"
                   required>
          <label>Attributes per Insights Tab Limit</label>
          <div help-text>
            Choose how many attributes are allowed in each tab for this region
          </div>
        </ppc-input>
      </div>

      <div footer *ngIf="regionUnderEdit">
        <button mat-button
                class="cancel"
                (click)="dialog.close()">
                Cancel
        </button>

        <button mat-button
                class="save-button"
                (click)="saveRegion();dialog.close()"
                [disabled]="!(regionName.valid && (esIndex.valid || moleculaIndex.valid) && maxCompareWidgets.valid)">{{regionUnderEdit.id ? "Save" : "Create"}}
        </button>
      </div>

    </ppc-dialog>

    <table class="regions">
      <thead>
        <tr>
          <th>Name</th>
          <th>Slug</th>
          <th>Countries</th>
          <th>Currency</th>
          <th>ES Index</th>
          <th>Molecula Index</th>
          <th>Excluded Features</th>
          <th>Max Compare Widgets</th>
          <th>Tab Attribute Limit</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let region of regionsList">
          <td>{{region.name}}</td>
          <td>{{region.slug}}</td>

          <td class="countries-column"
              [ppcTooltip]="getCountriesString(region.country_ids)"
              [tooltipDelayTime]="600">
              {{ getCountriesString(region.country_ids) }}
          </td>

          <td>{{region.currency}}</td>
          <td>{{region.es_index}}</td>
          <td>{{region.molecula_index}}</td>
          <td>
            <p *ngFor="let featureId of region.feature_exclusions">
              {{displayFeatureExclusions(featureId)}}
            </p>
          </td>
          <td>{{region.max_compare_widgets}}</td>
          <td>{{region.tab_bucket_limit}}</td>

          <td>
            <button class="btn"
                    (click)="editRegion(region); dialog.show()">
              Edit
              <i class="fa fa-pencil edit-region"></i>
            </button>
          </td>
        </tr>
      </tbody>

    </table>

  </div>
</main>
