
import { withLatestFrom, filter, map, take } from 'rxjs/operators';
import { Component, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { sortBy, sumBy } from 'lodash';
import { Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { DragulaService } from "ng2-dragula";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { GrowTabState } from "app/insights/grow-v3/grow.reducer";

import { isNotYetFetched } from "app/shared/utils/fetch-state";
import { AuthPermission } from "app/shared/interfaces/auth-permission";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as mekkoActions from 'app/mekko/mekko.actions';
import { Mekko, SubMarket, Market, selectActiveMekko, selectSubMarketsByMarketId, newMarket } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';
import { InsightsCountService } from "app/insights/insights-count.service";
import { GrowCountService } from 'app/insights/grow-v3/grow-count.service';
import { isDefined } from 'app/shared/utils/utils';
import { environment } from 'environments/environment';

const dataLoadingActions = [mekkoActions.FetchMekkos, mekkoActions.FetchSubMarkets];

@UntilDestroy()
@Component({
  selector: 'ppc-mekko-builder',
  templateUrl: './mekko-builder.component.html',
  styleUrls: ['./mekko-builder.component.sass']
})
export class MekkoBuilderComponent implements OnDestroy {
  @ViewChild("invalidSubMarketsSelected", { static: true }) invalidSubMarketsSelectedTemplate: TemplateRef<any>;
  mekko: Mekko;
  subMarketsByMarketId: {[id: number]: SubMarket[]};
  subMarketUnderEdit$: Observable<SubMarket>;
  marketUnderEdit$: Observable<Market>;
  editingMekko: boolean;
  dragulaBagName = 'mekko-markets';
  permissions: AuthPermission;
  growTabState$ = this.store.select("grow", "growTabState");
  dialogRef: MatDialogRef<any>;
  growTabStateText: GrowTabState;

  constructor(private store: Store<AppState>,
    private counts: InsightsCountService,
    private growCounts: GrowCountService,
    private dragula: DragulaService,
    private dialog: MatDialog) {

    this.dragula.setOptions(this.dragulaBagName, {
      moves: (el, container, handle) => handle.classList.contains("mekko-market-bars"),
      direction: "horizontal"
    });

    this.dragula.dropModel.pipe(filter(([bagName]) => bagName === this.dragulaBagName), untilDestroyed(this)).subscribe(([dragulaBagName, target, bag]) => {
      this.mekko.markets.forEach((market, idx) => market.order = idx);
      this.store.dispatch(new mekkoActions.UpdateMarketOrder(this.mekko.markets));
    });

    store.select("mekkos").pipe(
      select(selectActiveMekko),
      untilDestroyed(this),
      filter(isDefined),
      map(mekko => {
        mekko.markets = sortBy(mekko.markets, market => market.order != null ? market.order : Number.NEGATIVE_INFINITY);
        return mekko
      }),
    ).subscribe(mekko => this.mekko = mekko );

    store.select("mekkos").pipe(
      select(selectSubMarketsByMarketId),
      untilDestroyed(this),
    ).subscribe(subMarkets => this.subMarketsByMarketId = subMarkets);

    this.subMarketUnderEdit$ = store.select("mekkos", "subMarketUnderEdit");
    this.marketUnderEdit$ = store.select("mekkos", "marketUnderEdit");

    store.select("mekkos", "editMekko").pipe(untilDestroyed(this))
      .subscribe(editingMekko => this.editingMekko = editingMekko);

    dataLoadingActions.forEach(action =>
      store.select('fetchStates', action.type).pipe(
        select(isNotYetFetched),
        untilDestroyed(this),
        filter(Boolean),
      ).subscribe(() => store.dispatch(new action()))
    );

    store.select("mekkos", "subMarketUnderEdit").pipe(take(1)).subscribe(subMarketUnderEdit => {
      if (subMarketUnderEdit) {this.store.dispatch(new mekkoActions.EditSubMarket(null)); }
    });

    store.select("permissions", "mekkos").pipe(
      untilDestroyed(this))
      .subscribe(permission => this.permissions = permission)

    store.select("grow", "growTabState").pipe(
      withLatestFrom(
        this.store.select("mekkos", "selectedSubMarketIds"),
        this.store.select("mekkos", "subMarkets"),
      ),
      untilDestroyed(this))
      .subscribe(([growTabState, selectedSubMarketIds, subMarketsById]) => {
        this.growTabStateText = growTabState;
        const field = growTabState == "Matched Addressable" ? "matched_short_id" : "modeled_short_id";
        if (growTabState == "Total Population" || environment.isTier3 && field === 'modeled_short_id') {return}
        const subMarkets = selectedSubMarketIds.map(id => subMarketsById[id]);
        if (!subMarkets.every(subMarket => subMarket[field])) {
          this.dialogRef = dialog.open(this.invalidSubMarketsSelectedTemplate, {
            width: '450px'
          })
        }
      })


  }

  toggleEditMode() {
    this.store.dispatch(new mekkoActions.ToggleEditMekko())

  }

  getWidthForMarket(market: Market): number {
    const getSumOfMekkoPopulation = sumBy(this.mekko.markets, market => { return this.getMarketPopulation(market); })

    if (!this.editingMekko && getSumOfMekkoPopulation !== 0) {
      return this.getMarketPopulation(market) / getSumOfMekkoPopulation * 100
    } else {
      return 100 / this.mekko.markets.length;
    }
  }

  getMarketPopulation(market: Market): number {
    return sumBy(this.subMarketsByMarketId[market.id], subMarket => this.growCounts.subMarketCount(subMarket, this.growTabStateText, false))
  }

  getYLegendPosition(number): {[name: string]: SafeStyle} {
    return {
      "top": `${100 - number}%`,
      "transform": `translateY(-${100 - number}%)`
    }
  }

  getXLegendPosition(number): {[name: string]: SafeStyle} {
    return {
      "left": `${number}%`,
      "transform": `translateX(-${number}%)`
    }
  }

  ngOnDestroy() {
    this.dragula.find(this.dragulaBagName) && this.dragula.destroy(this.dragulaBagName);
  }

  addMarket() {
    this.store.dispatch(new mekkoActions.EditMarket(newMarket(this.mekko.markets.length)))
  }

  closeInvalidSubMarketsDialog() {
    this.dialogRef && this.dialogRef.close();
  }

}
