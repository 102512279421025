import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { merge as observableMerge, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { takeUntil, filter, debounceTime, map } from 'rxjs/operators';
import { filter as _filter } from 'lodash';

import { RegionDemographic, selectCensusDemographics } from 'app/insights/insights.reducer';
import { DragulaService } from 'ng2-dragula';
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { AppState } from 'app/reducers';
import * as insightsActions from "app/insights/insights.actions";
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';
import { InsightsCountService } from 'app/insights/insights-count.service';

@Component({
  selector: 'ppc-region-level-demographics',
  templateUrl: './region-level-demographics.component.html',
  styleUrls: ['./region-level-demographics.component.sass']
})
export class RegionLevelDemographicsComponent extends InsightsResourceTracker implements OnInit, OnDestroy {
  regionLevelDemographics: RegionDemographic[];
  destroyed$ = new Subject();
  bagName: string;
  jumbo$ = this.store.select("insights", this.insightsContext, "splitScreenFocus").pipe(map(focus => focus == "right"))
  forceLayout$ = observableMerge(
    this.store.pipe(select(selectCensusDemographics(this.insightsContext))),
    this.store.select("insights", this.insightsContext, "splitScreenFocus"),
    this.counts.countsChanged$
  ).pipe(debounceTime(450))

  constructor(public store: Store<AppState>,
    private dragula: DragulaService,
    private counts: InsightsCountService,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super(store, insightsContext);
  }

  ngOnInit() {
    this.resourceId$.pipe(takeUntil(this.destroyed$)).subscribe(resourceId => {
      this.store.dispatch(new insightsActions.FetchDemographicsConfig(userPreferenceKeys.regionLevelDemographics("census"), this.resourceId, this.resourceType, this.insightsContext))
    })


    this.store.pipe(
      select(selectCensusDemographics(this.insightsContext)),
      takeUntil(this.destroyed$)
    ).subscribe(regionLevelDemographics => {
      this.regionLevelDemographics = regionLevelDemographics
    })

    this.bagName = `region-level-demographics-census`
    this.dragula.setOptions(this.bagName, {
      moves: (el, container, handle) => /drag-handle/.test(handle.className)
    })
    this.dragula.dropModel.pipe(
      filter(([bagName]) => bagName === this.bagName),
      takeUntil(this.destroyed$)
    )
      .subscribe(() => {
        this.saveConfig();
      })

  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.dragula.destroy(this.bagName);
    super.ngOnDestroy();
  }

  saveConfig(regionDemographicsFromPackery?: RegionDemographic[]) {
    this.store.dispatch(new insightsActions.SaveDemographicsConfig((regionDemographicsFromPackery || this.regionLevelDemographics).map(regionDemographic => {
      return {
        id: regionDemographic.id,
        visible: regionDemographic.visible,
        widget_type: regionDemographic.widget_type,
        id_count: regionDemographic.id_count
      }
    }), userPreferenceKeys.regionLevelDemographics("census"), this.resourceId, this.resourceType, this.insightsContext))
  }

  hideDemographic(regionDemographic: RegionDemographic) {
    regionDemographic.visible = false;
    this.saveConfig()
  }

  trackById(index, regionDemographic: RegionDemographic) {
    return regionDemographic.id;
  }

  get visibleDemographics() {
    return _filter(this.regionLevelDemographics, "visible")
  }

}
