<div class="index-bar-container">
  <div class="index-bar-bar" 
      [class.animate]="ready"
      [style.transform-origin]="transformOrigin" 
      [style.transform]="transform"
      [style.width.%]="width"
      [style.background-color]="index < 100 ? '#be5873' : '#a9c783'">
  </div>
  <div class="index-bar-zero"></div>
</div>
<div class="index-bar-label">
  {{ indexLabel }} 
</div>
