import { combineLatest as observableCombineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { SafeStyle } from '@angular/platform-browser';
import { sumBy, find, get, clamp, sortBy, flatMap, filter as _filter, cloneDeep, keyBy, concat, map as _map, compact } from 'lodash';
import * as chroma from 'chroma-js';

import { AppState } from 'app/reducers';
import { OutcomeTimeframe, selectTimeframeField, TimeframeFieldType, newMarketOutcome, newSubmarketOutcome, sortByOrder } from '../measure-v3.reducer';
import { MekkoColors } from 'app/insights/grow-v3/grow.constants';
import * as actions from 'app/measure-v3/measure-v3.actions';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { prettyPathParts } from 'app/audiences/discover/segment-v2.model';
import { SubMarket, Mekko, Market, NewMarket, selectActiveMekko, selectActiveMekkoMarkets, selectActiveMekkoSubMarkets } from 'app/mekko/mekko.reducer';
import { SegmentLike } from 'app/models/segment-like.model';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';

@Component({
  selector: 'ppc-measure-v3-mekko',
  templateUrl: './measure-v3-mekko.component.html',
  styleUrls: ['./measure-v3-mekko.component.sass']
})
export class MeasureV3MekkoComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  mekko: Mekko;
  marketsWithSubMarkets: NewMarket[];
  selectedMarket: Market;
  currentTimeframe: OutcomeTimeframe;
  previousTimeframe: OutcomeTimeframe;
  timeframeField: TimeframeFieldType;
  mekkoColors = MekkoColors;
  selectedSubMarket: SubMarket;
  segments: {[identifier: string]: SegmentLike} = {}
  subMarkets: SubMarket[];
  vendorDisplayNames: VendorDisplayName[];

  constructor(private store: Store<AppState>, private segmentService: SegmentV2Service) {
    store.select("mekkos").pipe(
      select(selectActiveMekkoSubMarkets),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(subMarkets => {
      this.subMarkets = subMarkets

      const identifiers = compact(concat(_map(this.subMarkets, "matched_short_id"),
        _map(this.subMarkets, "modeled_short_id")));
      if (identifiers.length) {
        this.segmentService.fetchByIdentifiers(identifiers).subscribe(segments => {
          this.segments = keyBy(segments, "identifier");
        })
      }
    });

    store.select("mekkos").pipe(
      select(selectActiveMekkoMarkets),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(markets => this.marketsWithSubMarkets = markets)

    store.select('segmentsHierarchy', 'vendorDisplayNames').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(vendorDisplayNames => this.vendorDisplayNames = vendorDisplayNames);

    observableCombineLatest([
      store.select("mekkos").pipe(select(selectActiveMekko)),
      store.select("measureV3", "currentTimeframe"),
      store.select("measureV3", "previousTimeframe"),
      store.select("measureV3").pipe(select(selectTimeframeField)),
      store.select("measureV3", "selectedSubMarket"),
      store.select("measureV3", "selectedMarket"),
    ]).pipe(takeUntil(this.ngUnsubscribe),
      filter(([mekko, currentTimeframe, previousTimeframe, timeframeField, selectedSubMarket, selectedMarket]) => mekko && !!currentTimeframe), )
      .subscribe(([mekko, currentTimeframe, previousTimeframe, timeframeField, selectedSubMarket, selectedMarket]) => {
        this.mekko = mekko;
        this.currentTimeframe = currentTimeframe;
        this.previousTimeframe = previousTimeframe;
        this.addMissingOutcomes(this.currentTimeframe);
        this.timeframeField = timeframeField;
        this.selectedSubMarket = selectedSubMarket;
        this.selectedMarket = selectedMarket;
      })
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAudienceName(subMarket: SubMarket): string {
    if (this.timeframeField == "total") {return; }
    const segment = get(this.segments, subMarket[this.timeframeField + "_short_id"]);
    if (segment) {return prettyPathParts(segment, this.vendorDisplayNames).join(" > ")} else {return "N/A"}
  }

  getMarketWidth(market: Market): SafeStyle {
    try {
      if (!this.currentTimeframe) {return}
      const totalSum = sumBy(this.currentTimeframe.market_outcomes, this.timeframeField)
      const marketSum = find(this.currentTimeframe.market_outcomes, {market_id: market.id})[this.timeframeField]
      if (totalSum == 0 && marketSum == 0) {return 1}
      return marketSum / totalSum
    } catch (e) {
      return 1
    }
  }

  getSubMarketHeight(subMarket: SubMarket, market: Market): SafeStyle {
    if (!this.currentTimeframe) {return; }
    const marketSubMarkets = this.subMarkets.filter((sm) => sm.market_id == market.id)
    const marketSum = sumBy(marketSubMarkets, subMarket => this.getCount(subMarket));
    if (!marketSum) {return 1; }
    const count = this.getCount(subMarket);
    return count / marketSum;
  }

  get markets() {
    if (!this.mekko) {return}
    this.marketsWithSubMarkets.forEach(m => m.sub_markets = sortByOrder(m.sub_markets));
    return sortByOrder(this.marketsWithSubMarkets)
  }

  getBackgroundColor(subMarket: SubMarket): SafeStyle {
    return chroma(this.mekkoColors[subMarket.tag]).alpha(0.5).css();
  }

  getBorderColor(subMarket: SubMarket): SafeStyle {
    return chroma(this.mekkoColors[subMarket.tag]).css();
  }

  getCount(subMarket: SubMarket): number {
    if (!this.currentTimeframe) {return}
    return get(find(this.currentTimeframe.submarket_outcomes, {sub_market_id: subMarket.id}), this.timeframeField, 0)
  }

  getPreviousCount(subMarket: SubMarket): number {
    if (!this.previousTimeframe) {return}
    return get(find(this.previousTimeframe.submarket_outcomes, {sub_market_id: subMarket.id}), this.timeframeField, 0)
  }

  getMarketCount(market: Market): number {
    if (!this.currentTimeframe) {return}
    return get(find(this.currentTimeframe.market_outcomes, {market_id: market.id}), this.timeframeField, 0);
  }

  getSubMarketPercentChange(subMarket: SubMarket): number {
    if (!this.currentTimeframe || !this.previousTimeframe) {return; }
    const current = get(find(this.currentTimeframe.submarket_outcomes, {sub_market_id: subMarket.id}), this.timeframeField, 0);
    const previous = get(find(this.previousTimeframe.submarket_outcomes, {sub_market_id: subMarket.id}), this.timeframeField, 0);
    const result = ((current / previous) * 100 ) - 100;
    return isNaN(result) || !Number.isFinite(result) ? 0 : result;
  }

  getClampedSubMarketPercentChange(subMarket: SubMarket): number {
    return clamp(this.getSubMarketPercentChange(subMarket), -100, 100);
  }

  toggleSelectedSubMarket(subMarket: SubMarket) {
    this.store.dispatch(new actions.ToggleSelectedSubMarket(subMarket))
  }

  toggleSelectedMarket(market: Market) {
    this.store.dispatch(new actions.ToggleSelectedMarket(market))
  }

  notSelected(subMarket: SubMarket) {
    return (this.selectedSubMarket && this.selectedSubMarket !== subMarket) ||
      (this.selectedMarket && this.selectedMarket.id !== subMarket.market_id);
  }

  marketSubMarkets(market: Market) {
    return _filter(this.subMarkets, sm => sm.market_id == market.id);
  }

  addMissingOutcomes(outcomeTimeframe: OutcomeTimeframe) {
    const newSubmarketOutcomes = _filter(this.subMarkets, subMarket => {
      return !find(outcomeTimeframe.submarket_outcomes, {sub_market_id: subMarket.id})
    }).map(newSubmarketOutcome);

    outcomeTimeframe.submarket_outcomes = outcomeTimeframe.submarket_outcomes.concat(newSubmarketOutcomes)

    const newMarketOutcomes = _filter(this.markets, market => {
      return !find(outcomeTimeframe.market_outcomes, {market_id: market.id})
    }).map(newMarketOutcome)

    outcomeTimeframe.market_outcomes = outcomeTimeframe.market_outcomes.concat(newMarketOutcomes);
  }

}
