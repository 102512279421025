import { Action } from "@ngrx/store";
import { ContextSlugs, Hierarchy, HierarchyClient } from "app/hierarchy/hierarchy.interface";

export class LoadHierarchy implements Action {
  static readonly type = 'LOAD_HIERARCHY';
  readonly type = LoadHierarchy.type;

  constructor(public hierarchy: Hierarchy) { }
}

export class FetchHierarchy implements Action {
  static readonly type = 'FETCH_HIERARCHY';
  readonly type = FetchHierarchy.type;
}

export class ChangeContext implements Action {
  static readonly type = 'CONTEXT_CHANGED';
  readonly type = ChangeContext.type;

  constructor(public contextSlugs: ContextSlugs) { }
}

export class SaveClient implements Action {
  static readonly type = 'SAVE_CLIENT';
  readonly type = SaveClient.type;

  constructor(public client: HierarchyClient) { }
}

export class LoadClient implements Action {
  static readonly type = 'LOAD_CLIENT';
  readonly type = LoadClient.type;

  constructor(public client: HierarchyClient) { }
}

export type All = LoadHierarchy | FetchHierarchy | ChangeContext | SaveClient | LoadClient
