import * as actions from './hierarchy.actions'
import {createSelector, select, Store} from "@ngrx/store";
import {ContextSlugs, Hierarchy, HierarchyContext, HierarchyRegion, HierarchyClient} from "app/hierarchy/hierarchy.interface";
import {
  emptyHierarchy,
  getContext,
  getContextSlugs,
  isFullContextSlugs,
  getDefaultHierarchyContext,
  isFullContext,
  addHierarchyLevelTags,
  addParentLinks,
  assignFullSlugs,
  assignUniqueKeys
} from "app/hierarchy/hierarchy.utils";
import {distinctUntilChanged, filter, pluck} from "rxjs/operators";
import {Observable} from "rxjs";
import {AppState} from "app/reducers";
import {memoAndShare} from "app/shared/utils/utils";
import {isEqual} from 'lodash'

export interface State {
  contextSlugs: ContextSlugs;
  hierarchy: Hierarchy
}

export const initialState: State = {
  contextSlugs: {},
  hierarchy: { clients: [] }
};

export function reducer(state = initialState, action: actions.All): State {
  switch (action.type) {
    case actions.LoadHierarchy.type:
      return Object.assign({}, state, {
        hierarchy: { clients: (action.hierarchy.clients || []).map(assignFullSlugs)
          .map(addHierarchyLevelTags)
          .map(assignUniqueKeys)
          .map(addParentLinks) }
      });
    case actions.ChangeContext.type:
      return {...state, contextSlugs: action.contextSlugs};
    default:
      return state
  }
}

export const selectHierarchy = (state: State) => state.hierarchy;

export const selectContextSlugs = (state: State) => state.contextSlugs;

export const selectActiveContext = createSelector(
  selectHierarchy,
  selectContextSlugs,
  getContext
);

export const selectActiveClients = (hierarchy) => {
  return {clients: hierarchy.clients.filter(c => c.active)};
}

export const selectRegion = createSelector(
  selectActiveContext,
  ({region}) => region
)

export const selectClient = createSelector(
  selectActiveContext,
  ({client}) => client
)

export const activeContext = memoAndShare((store: Store<AppState>): Observable<HierarchyContext> =>
  store.select('hierarchy').pipe(select(selectActiveContext),
    filter(Boolean),
    distinctUntilChanged(isEqual)))

export const fullContext = memoAndShare((store: Store<AppState>): Observable<HierarchyContext> =>
  activeContext(store).pipe(filter(isFullContext),
    distinctUntilChanged(isEqual)))
