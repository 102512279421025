import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Actions, Effect, ROOT_EFFECTS_INIT, ofType } from "@ngrx/effects";
import {
  FetchClientFeatureAccess,
  FetchFeatureModules,
  LoadClientFeatureAccess,
  LoadFeatureModules,
  PutClientFeatureAccess,
} from "app/feature-access/feature-access.actions";
import { PpcHttpService } from "app/services/ppc_http.service";
import { fetchResource } from "app/shared/utils/fetch-state";
import { Observable } from "rxjs";
import { ClientFeatureAccess } from "app/feature-access/feature-access.reducers";
import { LoadCurrentUser } from "app/users/user.actions";
import { reject } from "lodash";
import { clientFeaturePermissionsUrl, featureModulesUrl } from '../shared/constants/clients.urls';

@Injectable()
export class FeatureAccessService {
  @Effect()
  effectsReady$ = this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    map(() => new FetchFeatureModules())
  );

  @Effect()
  newCurrentUser$ = this.actions$.pipe(
    ofType(LoadCurrentUser.type),
    map(() => new FetchClientFeatureAccess())
  );

  @Effect()
  fetchFeatureModules$ = this.actions$.pipe(
    ofType(FetchFeatureModules.type),
    fetchResource(() =>
      this.getFeatureModules().pipe(
        map(
          (features) =>
            new LoadFeatureModules(reject(features, ["category", "briefs"]))
        )
      )
    )
  );

  @Effect()
  fetchClientAccess$ = this.actions$.pipe(
    ofType(FetchClientFeatureAccess.type),
    fetchResource(() =>
      this.getClientAccess().pipe(
        map((access) => new LoadClientFeatureAccess(access))
      )
    )
  );

  @Effect()
  postClientAccess$ = this.actions$.pipe(
    ofType(PutClientFeatureAccess.type),
    fetchResource(({ clientId, featureIds }: PutClientFeatureAccess) =>
      this.putClientAccess(clientId, Array.from(featureIds)).pipe(
        map((accesses) => new LoadClientFeatureAccess(accesses))
      )
    )
  );

  constructor(private http: PpcHttpService, private actions$: Actions) {}

  getFeatureModules() {
    return this.http.get(featureModulesUrl());
  }

  getClientAccess(): Observable<ClientFeatureAccess[]> {
    return this.http.get(clientFeaturePermissionsUrl());
  }

  putClientAccess(
    clientId: string,
    featureIds: string[]
  ): Observable<ClientFeatureAccess[]> {
    return this.http.put(clientFeaturePermissionsUrl(), {
      client_id: clientId,
      feature_module_ids: featureIds,
    });
  }
}
