import { JourneyTabType, Journey, JourneySubMarket, JourneyBrand, JourneyStage } from "./journey.models";
import { JourneyMoleculaSegmentContext, SegmentContexts } from "app/insights/insights.constants";
import { map, find, filter, flatMap, flatten, compact, keyBy, reduce, difference } from 'lodash';
import { environment } from "environments/environment";
import { isMolecula } from "app/insights/insights.utils";
import { MOLECULA } from 'app/shared/utils/constants';

export function buildMoleculaJourneyContext(segmentContexts: JourneyMoleculaSegmentContext, filters: string[][], identifiers, includeIndexValues: boolean) {
  const contexts = segmentContexts.contexts.map(context => {
    return {
      ...context,
      serviceType: MOLECULA,
      isVendorQuery: false,
      includeIndexValues,
      context: {
        ...context.context,
        filters,
      },
      identifiers,
    }
  })

  return { ...segmentContexts, contexts };
}

export function buildSegmentContextsFromJourney(journey: Journey, activeBrand: JourneyBrand, activeStages: JourneyStage[], journeyTab?: JourneyTabType): SegmentContexts | any {
  const segmentField = journeyTab.toLowerCase() + "_id";
  const additionalContexts = difference(["matched_id", "modeled_id"], [segmentField]).filter(segmentField => {
    const actualSegmentField = environment.isTier3 ? "matched_id" : segmentField;
    return brandHasSegments(activeBrand, journey, actualSegmentField)
  });
  const additionalBrands = difference(journey.brands, [activeBrand]).filter(brand => {
    const actualSegmentField = environment.isTier3 ? "matched_id" : segmentField;
    return brandHasSegments(brand, journey, actualSegmentField)
  });

  return {
    primary: journeyTab == "Total Population" ? null : buildContextForSegmentField(journey, activeBrand, segmentField, activeStages, "primary"),
    secondary: {
      ...reduce(additionalContexts, (contexts, segmentField) => {
        return {...contexts, [segmentField]: buildContextForSegmentField(journey, activeBrand, segmentField, activeStages)}
      }, {}),
      ...reduce(additionalBrands, (contexts, brand) => {
        return {...contexts, [journeyBrandQueryKey(brand)]: buildContextForSegmentField(journey, brand, segmentField, activeStages, journeyBrandQueryKey(brand))}
      }, {}),
    },
  }
}

export function buildContextForSegmentField(journey: Journey, brand: JourneyBrand, segmentField: string, activeStages: JourneyStage[], rootKey?: string) {
  const stages = keyBy(journey.stages, "id");
  const actualSegmentId = environment.isTier3 ? "matched_id" : segmentField;

  if (isMolecula()) {
    const journeyContexts = compact(map(activeStages, stage => {
      const stageSubMarket = find(journey.sub_markets, {journey_brand_id: brand.id, journey_stage_id: stage.id});
      if (!stageSubMarket[actualSegmentId]) {return}
      const brandSubMarkets = filter(journey.sub_markets, subMarket => {
        return subMarket.journey_brand_id === brand.id &&
                subMarket !== stageSubMarket &&
                stages[subMarket.journey_stage_id].priority > stage.priority &&
                subMarket[actualSegmentId]

      }) as any[]
      const jsmContext = {
        serviceType: MOLECULA,
        context: {
          included: stageSubMarket[actualSegmentId] ? [[stageSubMarket[actualSegmentId]]] : [],
          excluded: compact(map(brandSubMarkets, actualSegmentId)).length > 0 ? [compact(map(brandSubMarkets, actualSegmentId))] : [],
          key: journeySubMarketCountKey(stageSubMarket)
        }
      }

      return jsmContext;
    }))

    const ors = journeyContexts.length > 0 ? journeyContexts.map(journeyContext => ({
      included: journeyContext.context.included,
      excluded: journeyContext.context.excluded
    })) : [{ included: [], excluded: [] }];
    const totalCountContext = {
      serviceType: MOLECULA,
      context: {
        ors,
        key: "total_count",
      }
    }

    const context = {
      rootKey: rootKey || segmentField,
      contexts: [
        totalCountContext,
        ...journeyContexts
      ]
    }

    return context;
  }
  return {
    or: compact(map(activeStages, stage => {
      const stageSubMarket = find(journey.sub_markets, {journey_brand_id: brand.id, journey_stage_id: stage.id});
      if (!stageSubMarket[actualSegmentId]) {return}
      const brandSubMarkets = filter(journey.sub_markets, subMarket => {
        return subMarket.journey_brand_id === brand.id &&
                subMarket !== stageSubMarket &&
                stages[subMarket.journey_stage_id].priority > stage.priority &&
                subMarket[actualSegmentId]

      })
      return {
        include: [[stageSubMarket[actualSegmentId]]],
        exclude: compact(map(brandSubMarkets, actualSegmentId)),
      }
    })),
    aggregations: buildAggregationsForBrand(brand, journey, actualSegmentId, activeStages),
    isModeled: environment.isTier3 && segmentField == "modeled_id",
  }
}

export function buildAggregationsForBrand(brand: JourneyBrand, journey: Journey, segmentField: string, activeStages: JourneyStage[]) {
  const stages = keyBy(journey.stages, "id");

  return reduce(activeStages, (aggs, stage) => {
    const stageSubMarket = find(journey.sub_markets, {journey_brand_id: brand.id, journey_stage_id: stage.id});
    if (!stageSubMarket[segmentField]) {return aggs}
    const brandSubMarkets = filter(journey.sub_markets, subMarket => {
      return subMarket.journey_brand_id === brand.id &&
            subMarket !== stageSubMarket &&
            stages[subMarket.journey_stage_id].priority > stage.priority &&
            subMarket[segmentField]
    })
    return {
      ...aggs,
      [journeySubMarketCountKey(stageSubMarket)]: {
        filter: {
          bool: {
            must: {
              term: {segments: stageSubMarket[segmentField]}
            },
            must_not: {
              terms: {segments: compact(map(brandSubMarkets, segmentField))}
            }
          }
        }
      }
    }
  }, {})
}

export function brandHasSegments(brand: JourneyBrand, journey: Journey, segmentField: string): boolean {
  return filter(journey.sub_markets, {journey_brand_id: brand.id}).some(subMarket => subMarket[segmentField])
}

export function journeyHasSegments(journey: Journey, segmentField: string): boolean {
  return journey.sub_markets.some(subMarket => subMarket[segmentField])
}

export function journeyBrandQueryKey(brand: JourneyBrand) {
  return `brand-${brand.id}`
}

export function journeySubMarketCountKey(subMarket: JourneySubMarket) {
  return `journey-sub-market-${subMarket.id}`
}
