<div id="selectors-container" 
     [class.disabled]="isDisabled$ | async" 
     *ngLet="(isOpen$ | async) as isOpen;">
  <ng-container *ngLet="(selectedContext$ | async) as context">
    <div id="client-selector" class="selector-container">
      <div class="selector-toggle" (click)="isOpen$.next(!isOpen)">
        <div class="selector-label">Client</div>

        <div class="selector-text hoverable">
          <span [class.list-open]="isOpen">{{context?.client?.name}}</span>
          <span [class]="'fa fa-caret-' + (isOpen ? 'down' : 'right')"></span>
        </div>
      </div>

      <div *ngIf="isOpen" class="selector-list-container" [@enterAnimation]="isOpen">
        <div class="selector-list-label">Select a Client</div>

        <div class="selector-list">
          <ul>
            <li *ngIf="canCreateClients$ | async" (click)="navigateToClientsAdmin()" class="hoverable">
              <i class="fa fa-plus"></i>
              Add clients
            </li>
            <li *ngFor="let client of (hierarchy$ | async).clients"
              (click)="selectedPath$.next(client.fullSlug)"
              [class]="'hoverable ' + (context.client?.id === client.id ? 'selected' : '')">
              {{client.name}}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div id="region-selector" class="selector-container">
      <div class="selector-toggle" (click)="isOpen$.next(!isOpen)">
        <div class="selector-label">Region</div>

        <div class="selector-text hoverable">
          <span [class.list-open]="isOpen">{{context?.region?.name}}</span>
          <span *ngIf="context?.region?.name"
            [class]="'fa fa-caret-' + (isOpen ? 'down' : 'right')"></span>
        </div>
      </div>

      <div class="selector-list-container" *ngIf="isOpen" [@enterAnimation]="isOpen">
        <div class="vertical-line"></div>

        <div class="selector-list-label">Select a Region</div>

        <div class="selector-list">
          <ul>
            <li *ngIf="canCreateRegions$ | async"
              (click)="navigateToRegionsAdmin()" class="hoverable">
              <i class="fa fa-plus"></i>
              Add regions
            </li>
            <li *ngFor="let region of context?.client?.regions || []"
              (click)="region.brands.length > 0 ? selectedPath$.next(region.fullSlug) : selectRegion(region)"
              [class]="'hoverable ' + (context?.region?.id === region.id ? 'selected' : '')">
              {{region.name}}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div id="brand-selector" class="selector-container">
      <div class="selector-toggle" 
           (click)="isOpen$.next(!isOpen)" 
           [class.hide]="!isOpen && (hideBrandAndProduct$ | async)">
        <div class="selector-label">Brand</div>

        <div class="selector-text hoverable">
          <span [class.list-open]="isOpen">{{context?.brand?.name}}</span>
          <span *ngIf="context?.brand?.name"
            [class]="'fa fa-caret-' + (isOpen ? 'down' : 'right')"></span>
        </div>
      </div>

      <div class="selector-list-container" *ngIf="isOpen" [@enterAnimation]="isOpen">
        <div class="vertical-line"></div>

        <div class="selector-list-label">Select a Brand</div>

        <div class="selector-list">
          <ul>
            <li *ngIf="canCreateBrands$ | async" (click)="navigateToBrandsAdmin()" class="hoverable">
              <i class="fa fa-plus"></i>
              Add brands
            </li>
            <ng-container *ngFor="let category of (brandCategories$ | async)">
              <!-- This is the normal case where categories are displayed -->
              <ng-container *ngIf="category.categoryName; else uncategorized">
                <li>
                  <span class="label">
                    {{category.categoryName}}
                  </span>
                  <ul>
                    <li *ngFor="let brand of category.brands"
                      (click)="selectedPath$.next(brand.fullSlug)"
                      [class]="'hoverable ' + (context?.brand?.id === brand.id ? 'selected' : '')">
                      {{brand.name}}
                    </li>
                  </ul>
                </li>
              </ng-container>
              <!-- This avoids unnecessary padding when there are no explicit brand categories -->
              <ng-template #uncategorized>
                <li *ngFor="let brand of category.brands"
                    (click)="selectedPath$.next(brand.fullSlug)"
                    [class]="'hoverable ' + (context?.brand?.id === brand.id ? 'selected' : '')">
                    {{brand.name}}
                </li>
              </ng-template>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <div id="product-selector" class="selector-container">
      <div class="selector-toggle" 
           (click)="isOpen$.next(!isOpen)" 
           [class.hide]="!isOpen && (hideBrandAndProduct$ | async)">
        <div class="selector-label">Product</div>

        <div class="selector-text hoverable">
          <span [class.list-open]="isOpen">{{context?.product?.name}}</span>
          <span *ngIf="context?.product?.name"
            [class]="'fa fa-caret-' + (isOpen ? 'down' : 'right')"></span>
        </div>
      </div>

      <div class="selector-list-container" *ngIf="isOpen" [@enterAnimation]="isOpen">
        <div class="vertical-line"></div>

        <div class="selector-list-label">Select a Product</div>

        <div class="selector-list">
          <ul>
            <li *ngIf="canCreateProducts$ | async" (click)="navigateToBrandsAdmin()" class="hoverable">
              <i class="fa fa-plus"></i>
              Add products
            </li>
            <li *ngFor="let product of context?.brand?.products"
              (click)="selectProduct(product)"
              [class]="'hoverable ' + (context?.product?.id === product.id ? 'selected' : '')">
              {{product.name}}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="selector-container">
      <div class="selector-toggle" (click)="isOpen$.next(!isOpen)"></div>
      <div class="selector-list-container" *ngIf="isOpen"
        [@enterAnimation]="isOpen">
        <span class="close-icon fa fa-close hoverable" (click)="this.isOpen$.next(false)"></span>
      </div>
    </div>
  </ng-container>
</div>
