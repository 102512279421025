<div class="show-header">
  <ppc-dropdown>
    <div currentlySelected>
      <ng-container>Show: {{ visibleSubMarkets.length }} market segments</ng-container>
    </div>

    <div dropdown>
      <div class="checkboxes">
        <ppc-checkbox *ngFor="let subMarket of subMarkets" [(ngModel)]="subMarket.visible" (ngModelChange)="unsortBySubMarket(subMarket)" [ellipsize]="true">
            {{subMarket.name}}
        </ppc-checkbox>
      </div>
    </div>
  </ppc-dropdown>

  <span class="segments-count">{{visibleRows?.length | number}} Segments</span>
</div>

<ng-template let-value="value" ngx-datatable-cell-template #scoreTemplate>
  {{ value | number:'1.3-3' }}
</ng-template>

<ng-template let-row="row" ngx-datatable-cell-template #nameTemplate>
  <div class="cell-content-overflow"
        *ppcCheckOverflow="let hasOverflow=hasXOverflow"
        [ppcTooltip]="displayPath(row.segment_identifier)"
        [tooltipDisabled]="!hasOverflow">
    {{ displayPath(row.segment_identifier) }}
</div>
</ng-template>


<ngx-datatable  [class.invisible]="!visibleRows.length"
                [rows]="visibleRows"
                [rowHeight]="33"
                columnMode="flex"
                sortType="single"
                [reorderable]="false"
                [sorts]="tableSorts"
                (sort)="tableSortChanged($event)"
                [headerHeight]="33"
                [footerHeight]="33"
                [limit]="rowLimit">

  <ngx-datatable-column *ngFor="let subMarket of visibleSubMarkets"
                        [name]="subMarket.name"
                        [prop]="'label_scores.' + subMarket.id"
                        cssClass="bubble"
                        headerClass="amount border-right bubble"
                        cellClass="amount border-right"
                        [cellTemplate]="bubbleTemplate"
                        [minWidth]="60"
                        [maxWidth]="60"
                        [flexGrow]="0.5">
    <ng-template let-row="row" ngx-datatable-cell-template #bubbleTemplate>
      <div class="bubble-cell"
           [ppcTooltip]="rowScore(row, subMarket)"
           [tooltipOffset]="{y: -20}">
             <div class="bubble"
                  [class.red]="rowScore(row, subMarket) <= red"
                  [class.green]="rowScore(row, subMarket) >= green"
                  [style.height.px]="bubbleScore(row, subMarket) * 33"
                  [style.width.px]="bubbleScore(row, subMarket) * 33">
            </div>
           </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Importance"
                        prop="segment_score"
                        headerClass="numeric center"
                        cellClass="numeric center"
                        ppcTooltip="Cumulative Importance for Propensity Classification of all Audiences"
                        [cellTemplate]="scoreTemplate"
                        [minWidth]="100"
                        [maxWidth]="100"
                        [flexGrow]="0.5">
    <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
      <span class="datatable-header-cell-label"
            ppcTooltip="Cumulative Importance for Propensity Classification of all Audiences"
            (click)="sort()">
        {{column.name}}
      </span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Segment"
                        headerClass="text"
                        cellClass="text"
                        [cellTemplate]="nameTemplate"
                        [comparator]="nameComparator"
                        [flexGrow]="3">
  </ngx-datatable-column>

</ngx-datatable>
