import { Component, Input, AfterViewInit, OnChanges, ElementRef, OnDestroy, ContentChildren, QueryList, ChangeDetectionStrategy} from '@angular/core';
import { BehaviorSubject, combineLatest as observableCombineLatest, interval, fromEvent as observableFromEvent, Observable, merge as observableMerge, of as observableOf } from 'rxjs';
import { reduce, isEqual, sumBy, findIndex, findLast, map as _map } from 'lodash';
import { map, distinctUntilChanged, startWith, switchMap} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PpcVirtualScrollItemComponent } from './ppc-virtual-scroll-item/ppc-virtual-scroll-item.component';

@UntilDestroy()
@Component({
  selector: 'ppc-virtual-scroll',
  templateUrl: './ppc-virtual-scroll.component.html',
  styleUrls: ['./ppc-virtual-scroll.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PpcVirtualScrollComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ContentChildren(PpcVirtualScrollItemComponent, {descendants: true}) scrollItems: QueryList<PpcVirtualScrollItemComponent>;
  @Input() items: {id: number}[];
  @Input() getStartingItemHeight: (item) => number;
  @Input() isItemSticky: (item) => boolean;
  @Input() itemMargin: number;
  items$ = new BehaviorSubject({});
  public viewPortItems$: Observable<any[]>;
  private itemHeights: {[id: number]: number} = {};
  contentWindowMargin$: Observable<number>;

  constructor(public element: ElementRef) { }

  ngOnDestroy() { }

  ngOnChanges(changes) {
    if (changes.items) {
      this.itemHeights = { // set starting heights but don't overwrite any existing ones
        ...reduce(this.items, (heights, item) => ({[item.id]: this.getStartingItemHeight(item) + this.itemMargin, ...heights}) , {}),
        ...this.itemHeights
      }
      this.items$.next(this.items);
    }
  }

  ngAfterViewInit() {
    // check periodically for bounding box resize
    const boundingBox$ = interval(500).pipe(
      startWith(this.element.nativeElement.getBoundingClientRect().toJSON()),
      map(() => this.element.nativeElement.getBoundingClientRect().toJSON()),
      distinctUntilChanged(isEqual),
    )

    const scrollTop$ = observableFromEvent(this.element.nativeElement, "scroll").pipe(
      startWith(0),
      map(() => this.element.nativeElement.scrollTop),
    )

    const heightChanges$ = observableMerge(this.scrollItems.changes, observableOf(this.scrollItems)).pipe(
      switchMap(scrollItems => observableMerge(..._map(scrollItems.toArray(), "height$"))),
      startWith(0),
    )

    // Keep track of item sizes
    observableMerge(
      observableOf(this.scrollItems),
      this.scrollItems.changes,
      heightChanges$
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => this.scrollItems.forEach((scrollItem) => {
          this.itemHeights[scrollItem.item.id] = scrollItem.element.nativeElement.getBoundingClientRect().height + this.itemMargin;
        })
      )

    const viewPortChanges$ = observableCombineLatest(scrollTop$, boundingBox$, this.items$, heightChanges$);

    const startIndex$ = viewPortChanges$.pipe(
      map(([scrollTop]) => {
        let accumulatedHeight = 0;
        return findIndex(this.items, item => {
          const itemHeight = this.itemHeights[item.id];
          accumulatedHeight += itemHeight;
          return accumulatedHeight > scrollTop + 10// small buffer for sticky items;
        })
      }),
    )

    const endIndex$ = viewPortChanges$.pipe(
      map(([scrollTop, {height: contentHeight, top: contentTop}]) => {
        let accumulatedHeight = 0;
        const endIndex = findIndex(this.items, item => {
          accumulatedHeight += this.itemHeights[item.id];
          return accumulatedHeight > scrollTop + contentHeight;
        });
        // we won't hit at the very end of the list
        return endIndex > -1 ? endIndex : this.items.length - 1;
      }),
    )

    const lastStickyItem$ = observableCombineLatest(
      startIndex$, this.items$
    ).pipe(
      map(([startIndex, items]: [number, any[]]) => {
        try {
          return findLast(items, item => this.isItemSticky(item), startIndex)
        } catch {
          return null
        }
      })
    )


    this.viewPortItems$ = observableCombineLatest(
      startIndex$, endIndex$, this.items$, lastStickyItem$
    ).pipe(
      map(([startIndex, endIndex, items, lastStickyItem]: [number, number, any[], any]) => items.filter((item, index) => {
        // the last sticky item should always be rendered
        return  item === lastStickyItem || (index >= startIndex && index <= endIndex)
      })),
    )

    this.contentWindowMargin$ = observableCombineLatest(
      this.items$, startIndex$, this.viewPortItems$
    ).pipe(
      map(([items, startIndex, viewPortItems]: [any[], number, any[]]) => {
        // if the slice of items before the start index is included in viewPortItems then it must be sticky,
        // don't include it in the margin
        return sumBy(items.slice(0, startIndex), item => viewPortItems.includes(item) ? 0 : this.itemHeights[item.id])
      })
    )
  }

  get totalHeight() {
    return sumBy(this.items, item => this.itemHeights[item.id]);
  }

}
