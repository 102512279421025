import { Action } from '@ngrx/store';
import { MotivationV1 } from 'app/models/motivations/motivations-v1.model';
import { MotivationAudienceRequest } from 'app/motivation-audience/motivation-audience.model';

export class FetchMotivations implements Action {
  static readonly type = 'FETCH_MOTIVATIONS_V1';
  readonly type = FetchMotivations.type;

  constructor(public useCases?: Array<string>) { }
}

export class LoadMotivations implements Action {
  static readonly type = 'LOAD_MOTIVATIONS_V1';
  readonly type = LoadMotivations.type;

  constructor(public motivations: MotivationV1[]) { }
}

export class LoadUpdatedMotivation implements Action {
  static readonly type = 'LOAD_UPDATED_MOTIVATION';
  readonly type = LoadUpdatedMotivation.type;

  constructor(public motivation: MotivationV1) { }
}

export class FetchOverviewMotivations implements Action {
  static readonly type = 'FETCH_OVERVIEW_MOTIVATIONS_V1';
  readonly type = FetchOverviewMotivations.type;

  constructor() { }
}

export class ClearMotivations implements Action {
  static readonly type = 'CLEAR_MOTIVATIONS';
  readonly type = ClearMotivations.type;
}

export class LoadMotivation implements Action {
  static readonly type = "LOAD_MOTIVATION_V1";
  readonly type = LoadMotivation.type;

  constructor(public motivationData) {}
}

export class SaveMotivation implements Action {
  static readonly type = "SAVE_MOTIVATION_V1";
  readonly type = SaveMotivation.type;

  constructor(public motivation: MotivationAudienceRequest) {}
}

export class DestroyMotivation implements Action {
  static readonly type = 'DESTROY_MOTIVATION_V1';
  readonly type = DestroyMotivation.type;

  constructor(public motivation: MotivationV1, public options) { }
}

export class RemoveMotivation implements Action {
  static readonly type = 'REMOVE_MOTIVATION_V1';
  readonly type = RemoveMotivation.type;

  constructor(public data) { }
}

export type All = FetchMotivations | LoadMotivations | FetchOverviewMotivations | ClearMotivations | SaveMotivation | LoadMotivation | LoadUpdatedMotivation | DestroyMotivation | RemoveMotivation;
