<div class="content-container"
     #content
     [class.invisible]="(fetchingCounts$ | async) || hideCount">
  <ng-container *ngIf="!isIndex">
    {{ percent | number:'1.0-0' }}% | {{ countLabel }}
  </ng-container>
  <ng-container *ngIf="isIndex">
    {{ index | number:'1.0-0' }}
  </ng-container>
</div>
