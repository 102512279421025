<!-- class="tooltip" -->
<div #tooltip
  class="tooltip"
  [class.open]="isOpen"
  [class.default-styling]="!usesTemplate"
  [style.transform]="tooltipTransform$ | async">
  <div *ngIf="!usesTemplate; else tooltipTemplate" class="tooltip-content">
    {{content}}
  </div>

  <ng-template #tooltipTemplate>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-template>

  <div class="tooltip-triangle"
       [class.flip-y]="flipTriangleY$ | async"
       [style.left.px]="triangleLeft$ | async"
       [style.top.px]="triangleTop$ | async"
  ></div>
</div>
