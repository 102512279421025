<div *ngFor="let market of markets" class="measure-v3-mekko-market" [style.flex]="getMarketWidth(market)"
    [class.no-sub-markets]="!marketSubMarkets(market)?.length">
  <div class="measure-v3-mekko-market-header" ppcTooltip [tooltipTemplate]="marketInfo">
    <div class="market-name" (click)="toggleSelectedMarket(market)">
      <div class="ellipsize">
        {{ market?.name }}
      </div>
    </div>
    <div class="market-count" *ppcCheckOverflow="let hasOverflow=hasXOverflow">
      <i class="fa fa-user"></i>
      <div *ngIf="hasOverflow">...</div>
      <div [class.hide-but-keep-width]="hasOverflow">{{ getMarketCount(market) | number }}</div>
    </div>

    <ng-template #marketInfo>
      <div class="count-tooltip">
        <div>{{market?.name}}</div>
        <div>
          <i class="fa fa-user"></i> {{ getMarketCount(market) | number }}
        </div>
      </div>
    </ng-template>
  </div>
  <div *ngFor="let subMarket of marketSubMarkets(market)" class="measure-v3-mekko-sub-market-container" [style.flex]="getSubMarketHeight(subMarket, market)"
    (click)="toggleSelectedSubMarket(subMarket)" [class.not-selected]="notSelected(subMarket)">
    <div class="measure-v3-mekko-sub-market" [style.background-color]="getBackgroundColor(subMarket)" *ppcCheckOverflow="let xOverflow=hasXOverflow;let yOverflow=hasYOverflow;"
      ppcTooltip #subMarketContainer [tooltipTemplate]="subMarketInfo"
      [style.border-color]="getBorderColor(subMarket)"
      [tooltipOffset]="{y: -subMarketContainer.clientHeight, x: subMarketContainer.clientWidth > 150 ? -(((subMarketContainer.clientWidth) / 2 ) -150 ) : 0 }">
      <ppc-measure-v3-outcome-change-overlay [visiblePercentChange]="getClampedSubMarketPercentChange(subMarket)"
        [actualPercentChange]="getSubMarketPercentChange(subMarket)" *ngIf="getSubMarketPercentChange(subMarket)"></ppc-measure-v3-outcome-change-overlay>
      <ng-template #subMarketInfo>
        <div class="count-tooltip">
          <div>{{subMarket.name}}</div>
          <div>
            <span class="sub-market-count">
              <i class="fa fa-user"></i>
              <span>
                {{getCount(subMarket) | number}}
              </span>
            </span>
            <ng-container *ngIf="getSubMarketPercentChange(subMarket)"> | <i class="fa fa-caret-{{getSubMarketPercentChange(subMarket) > 0 ? 'up': 'down'}}"
                [class.green]="getSubMarketPercentChange(subMarket) > 0" [class.red]="getSubMarketPercentChange(subMarket) < 0"></i>
              {{getSubMarketPercentChange(subMarket) | number:'1.0-1'}}%</ng-container>
          </div>
          <div *ngIf="timeframeField != 'total'">{{getAudienceName(subMarket)}}</div>
        </div>
      </ng-template>
      <div class="sub-market-info" [style.border-color]="getBorderColor(subMarket)" [class.invisible]="subMarketContainer.clientWidth < 90 || subMarketContainer.clientHeight < 65">
        <div class="sub-market-name">
          {{ subMarket.name }}
        </div>
        <div class="sub-market-count" *ppcCheckOverflow="let xOverflow=hasXOverflow;">
          <i class="fa fa-user"></i>
          <span *ngIf="xOverflow">...</span>
          <span [class.hide-but-keep-width]="xOverflow">
            {{getCount(subMarket) | number}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
