import {AudienceV2} from "app/audiences-v2/audience-v2.model";
import * as actions from 'app/audiences-v2/audience-v2.actions';
import {differenceBy, findIndex, find} from 'lodash';
import {createSelector} from "@ngrx/store";

export interface State {
  audienceUnderEdit?: AudienceV2;
  audiences: AudienceV2[];
}

const defaultState = {
  audiences: []
};

export function reducer(state: State = defaultState, action: actions.All): State {
  switch (action.type) {
    case actions.EditAudience.type:
      return {...state, audienceUnderEdit: action.audience};
    case actions.LoadAudiences.type:
      return {
        ...state,
        audiences: differenceBy(state.audiences, action.audiences, 'id').concat(action.audiences),
        audienceUnderEdit: null
      };
    case actions.ClearAudiences.type:
      return defaultState;
    case actions.UpdateAudience.type:
      const indexOfItem = findIndex(state.audiences, {identifier: action.audience.identifier});
      const newAudiences = state.audiences.slice();
      newAudiences.splice(indexOfItem, 1, action.audience);
      return {
        ...state,
        audiences: newAudiences,
      };
    case actions.RemoveAudience.type:
      const indexToDelete = findIndex(state.audiences, {id: action.data.id});
      const nonDeletedAudiences = state.audiences.slice();
      nonDeletedAudiences.splice(indexToDelete, 1);
      return {
        ...state,
        audiences: nonDeletedAudiences,
      };
    case actions.ChangeAudiencePeopleCount.type:
      const audience = state.audiences.find(audience => audience.id == action.id)

      audience.count.people.global = action.peopleCount

      return { ...state, audiences: [...state.audiences] }
    default:
      return state;
  }
}

export const selectAudience = (audienceId: number) => {
  return createSelector(
    selectAudiences,
    (audiences) => {
      return find(audiences, { id: audienceId });
    }
  )
}

export const selectAudiences = (state: State) => state.audiences;

export const selectPendingAudiences = createSelector(
  selectAudiences,
  (audiences) => audiences.filter(audience => audience.job_status === "pending")
);

export const selectAudiencesWithType = createSelector(
  selectAudiences,
  (audiences) => audiences.map(audience => ({...audience, type: "audience"}))
);
