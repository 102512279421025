<div class="page-container">
  <div class="notice">
      <h1>Oops...</h1>
      <p>It looks like this client and region does not have a brand or product 
        associated within Publicis PeopleCloud.
      </p>
      <p>
        Please reach out to your Agency Admin for assistance.
      </p>
      <br />
      <p>For general client inquiries, please reach out to <a target="_blank" href="https://jira.publicismedia.com/servicedesk/customer/portal/3/group/29">Product Support</a>.</p>
  </div>
</div>
