import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ppc-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.sass']
})
export class AddButtonComponent implements OnInit {

  @Output() addSelectionEvent: EventEmitter<any> = new EventEmitter();

  @Input() allowAdd: boolean;
  @Input() originator: string;

  constructor() { }

  ngOnInit(): void {
  }

  addSelection() {
    this.addSelectionEvent.emit();
  }

  toolTipText(): string {
    return `Add ${this.originator}`;
  }
}
