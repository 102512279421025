<div class="widget" *ngIf="marketLevelDemographic" [class.jumbo]="jumbo$ | async">
  <div class="widget-header">
    <i class="fa fa-bars drag-handle"></i>
    <span class="widget-name"
      *ppcCheckOverflow="let hasOverflowedName=hasXOverflow"
      [ppcTooltip]="marketLevelDemographic?.name"
      [tooltipDisabled]="!hasOverflowedName"
      >
      {{marketLevelDemographic?.name}}
    </span>
    <span class="widget-type">
      <ppc-sort *ngIf="marketLevelDemographic.chart_type === chartTypes.BarHorizontal" [sortData]="barChartData"></ppc-sort>
    </span>
    <i *ngIf="marketLevelDemographic?.description" class="fa fa-info-circle menu-icon hoverable" [ppcTooltip]="marketLevelDemographic.description"></i>
    <button class="fa fa-ellipsis-v menu-icon hoverable" mat-icon-button [matMenuTriggerFor]="options" [ppcTooltip]="idCountTooltip"></button>
    <mat-menu #options>
      <button mat-menu-item (click)="edit()" *ngIf="permissions?.update">Edit</button>
      <button mat-menu-item (click)="hide()">Hide</button>
      <button mat-menu-item ppcConfirmation [kontinue]="delete.bind(this)" *ngIf="permissions?.destroy">Delete</button>
    </mat-menu>
  </div>

  <ng-container [ngSwitch]="marketLevelDemographic.chart_type">
    <ng-container *ngSwitchCase="chartTypes.Doughnut">
      <div class="grow-v3-donut-chart" [class.jumbo]="jumbo">
        <div class="donut-chart-container">
          <ppc-donut-chart [donutChartData]="donutChartData" [showCounts]="marketLevelDemographic?.id_count !== 'percentage'"></ppc-donut-chart>
        </div>
        <div class="legends">
          <div class="legend" *ngFor="let bucket of donutChartData; let i=index">
            <div class="legend-color">
              <div class="background"  [style.background-color]="getColor(i)"></div>
            </div>
            <div class="legend-name" *ppcCheckOverflow="let hasXOverflow=hasXOverflow;" [ppcTooltip]="bucket.label"
              [tooltipDisabled]="!hasXOverflow">
              {{ bucket.label }}
            </div>
            <div class="legend-percent">
              <div *ngIf="marketLevelDemographic?.id_count == 'percentage'">
                {{ bucket.percent | number:'1.0-0'}}%
              </div>
              <div *ngIf="marketLevelDemographic?.id_count !== 'percentage'">
                {{ bucket.countLabel }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>



    <ng-container *ngSwitchDefault>
      <ppc-bar-chart [data]="barChartData" [displayCounts]="marketLevelDemographic.id_count !== 'percentage'" colorOverride="rgba(130,202,208,0.8)"
        [vertical]="vertical" [class.vertical]="vertical" round="1.1-1"></ppc-bar-chart>
    </ng-container>
  </ng-container>

</div>
