
import {fromEvent as observableFromEvent} from 'rxjs';

import {filter, takeUntil, map} from 'rxjs/operators';
import { Component, OnInit, Input, forwardRef, OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { sortBy, find, uniqBy, filter as _filter, last, orderBy, get } from 'lodash';

import { AppState } from 'app/reducers';
import { OutcomeTimeframe } from '../measure-v3.reducer';
import { AuthPermission } from 'app/shared/interfaces/auth-permission';

@Component({
  selector: 'ppc-timeframe-selector',
  templateUrl: './timeframe-selector.component.html',
  styleUrls: ['./timeframe-selector.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeframeSelectorComponent),
      multi: true
    }
  ]
})
export class TimeframeSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() timeframes: OutcomeTimeframe[];
  @Output() openCms = new EventEmitter();
  year = moment().year();
  months = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11 ]
  value: SelectedTimeframes;
  open = false;
  ngUnsubscribe = new Subject();
  isRangeMode = false;
  rangeError = false;

  intermediateSelection: {month: number, year: number}; // the first timeframe that a user selects
  hoveredSelection: {month: number, year: number}; // the last hovered timeframe _if_ there is an intermediate selection
  canEditOutcomes$ = this.store.select("permissions", "outcome_timeframes").pipe(
    filter(Boolean),
    map((permission: AuthPermission) => !!permission.create), )

  presets = [
    {
      name: "Year to date",
      getEndpoints: () => {
        const thisYear = moment().year();
        const thisYearTimeframes = sortBy(_filter(this.timeframes, {year: thisYear}), "month");
        return { currentTimeframe: last(thisYearTimeframes), previousTimeframe: thisYearTimeframes[0] }
      }
    },
    {
      name: "Last 6 months",
      getEndpoints: () => {
        const monthCount = moment().year() * 12 + moment().month() + 1;
        const last6MonthsTimeframes = sortBy(_filter(this.timeframes, timeframe => {
          const timeframeMonthCount = timeframe.year * 12 + timeframe.month + 1;
          return monthCount - timeframeMonthCount < 6
        }), ["year", "month"])
        return { currentTimeframe: last(last6MonthsTimeframes), previousTimeframe: last6MonthsTimeframes[0]}
      }
    },
    {
      name: "Last quarter",
      getEndpoints: () => {
        let start: {year: number, month: number}, end: {year: number, month: number};
        const year = moment().year();
        switch (moment().month()) {
          case 0: case 1: case 2:
            start = {month: 9, year: year - 1}; end = {month: 11, year: year - 1};
            break;
          case 3: case 4: case 5:
            start = {month: 0, year}; end = {month: 2, year};
            break;
          case 6: case 7: case 8:
            start = {month: 3, year}; end = {month: 5, year};
            break;
          default:
            start = {month: 6, year}; end = {month: 8, year};
            break;
        }
        return { currentTimeframe: find(this.timeframes, end), previousTimeframe: find(this.timeframes, start) }
      }
    },
    {
      name: "Last month",
      getEndpoints: () => {
        const sortedTimeframes = orderBy(this.timeframes, ["year", "month"], ["desc", "desc"]);
        return { currentTimeframe: sortedTimeframes[0], previousTimeframe: sortedTimeframes[1] || sortedTimeframes[0]}
      }
    }
  ]

  constructor(private element: ElementRef, private store: Store<AppState>) { }

  ngOnInit() {
    observableFromEvent(window, "click").pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((event: Event) => {
        if (!this.element.nativeElement.contains(event.target)) {
          this.reset();
        }
      })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setHoveredSelection(month: number, year: number) {
    if (this.intermediateSelection) {
      this.hoveredSelection = {month, year}
    }
  }

  setYear(year: number) {
    this.year = year;
  }

  setRangeMode(isRangeMode: boolean) {
    this.isRangeMode = isRangeMode;
    this.writeValue({...this.value, isRangeMode})
  }

  isPresetValid(endpoints: {currentTimeframe: OutcomeTimeframe, previousTimeframe: OutcomeTimeframe}): boolean {
    return !!(endpoints.currentTimeframe && endpoints.previousTimeframe);
  }

  selectFromPreset(endpoints: {currentTimeframe: OutcomeTimeframe, previousTimeframe: OutcomeTimeframe}) {
    this.writeValue({...endpoints, isRangeMode: this.isRangeMode});
    this.reset();
  }

  selectMonth(month: number, year: number) {
    if (this.hasCompleteValue) {
      // This is the first timeframe they've selected
      this.intermediateSelection = {
        month,
        year
      }
    } else {
      // They've selected a second timeframe
      const first = moment().year(this.intermediateSelection.year).month(this.intermediateSelection.month);
      const second = moment().year(year).month(month);
      const [previousTimeframe, currentTimeframe] = sortBy([first, second], moment => moment.valueOf()).map(moment => {
        return find(this.timeframes, {month: moment.month(), year: moment.year()})
      })
      this.writeValue({
        previousTimeframe,
        currentTimeframe,
        isRangeMode: this.isRangeMode
      })
      this.intermediateSelection = null;
      this.hoveredSelection = null;
    }
  }

  isMonthDisabled(month: number, year: number): boolean {
    return !this.timeframes.find(timeframe => this.isSameTimeframe(month, year, timeframe))
  }

  getMonthLabel(month: number): string {
    return moment().month(month).format("MMM")
  }

  isMonthSelectedOrHovered(month: number, year: number): boolean {
    if (this.hasCompleteValue) {
      return this.isSameTimeframe(month, year, this.value.previousTimeframe) ||
              this.isSameTimeframe(month, year, this.value.currentTimeframe)
    }

    if (this.intermediateSelection && this.isSameTimeframe(month, year, this.intermediateSelection)) {
      return true
    }

    if (this.hoveredSelection && this.isRangeMode) {
      return this.isSameTimeframe(month, year, this.hoveredSelection)
    }
  }

  isMonthPrevious(month: number, year: number): boolean {
    // Determines if the month in the UI is the "previous" timeframe
    if (this.hasCompleteValue) {
      return this.isSameTimeframe(month, year, this.value.previousTimeframe)
    } else if (this.hoveredSelection) {
      const [previous, current] = sortBy([this.intermediateMoment, this.hoveredMoment], moment => moment.valueOf())
      return previous.year() == year && previous.month() == month;
    }
  }

  isMonthCurrent(month: number, year: number): boolean {
    // Determines if the month in the UI is the "current" timeframe
    if (this.hasCompleteValue) {
      return this.value.currentTimeframe.month == month && this.value.currentTimeframe.year == year;
    } else if (this.hoveredSelection) {
      const [previous, current] = sortBy([this.intermediateMoment, this.hoveredMoment], moment => moment.valueOf())
      return current.year() == year && current.month() == month;
    }
  }

  isMonthBetweenSelections(month: number, year: number): boolean {
    if (!this.isRangeMode) {return false}
    const monthMoment = moment().month(month).year(year);
    if (this.hasCompleteValue) {
      return monthMoment > this.previousMoment && monthMoment < this.currentMoment
    } else if (this.hoveredSelection) {
      const intermediateMoment = moment().month(this.intermediateSelection.month).year(this.intermediateSelection.year);
      const hoveredMoment = moment().month(this.hoveredSelection.month).year(this.hoveredSelection.year);
      const [previous, current] = sortBy([intermediateMoment, hoveredMoment], moment => moment.valueOf());
      return monthMoment > previous && monthMoment < current
    }
  }

  isSameTimeframe(month: number, year: number, timeframe: {month: number, year: number}): boolean {
    return timeframe.month == month && timeframe.year == year
  }

  get hasCompleteValue(): boolean {
    // The user is not in the middle of selecting two timeframes
    return  this.value &&
            this.value.currentTimeframe &&
            this.value.previousTimeframe &&
            !this.intermediateSelection
  }

  get hasTwoUniqueTimeframes(): boolean {
    // Since a user can select the same timeframe this just checks to see if they're different
    if (this.hasCompleteValue) {
      return this.value.previousTimeframe != this.value.currentTimeframe;
    } else if (this.hoveredSelection) {
      return Math.abs(this.hoveredMoment.valueOf() - this.intermediateMoment.valueOf()) > 2e9 // about a month in milliseconds
    }
  }

  get hoveredMoment(): moment.Moment {
    if (this.hoveredSelection) {
      return moment().month(this.hoveredSelection.month).year(this.hoveredSelection.year);
    }
  }

  get intermediateMoment(): moment.Moment {
    if (this.intermediateSelection) {
      return moment().month(this.intermediateSelection.month).year(this.intermediateSelection.year);
    }
  }

  get previousMoment(): moment.Moment {
    if (this.hasCompleteValue) {
      return moment().year(this.value.previousTimeframe.year).month(this.value.previousTimeframe.month);
    }
  }

  get currentMoment(): moment.Moment {
    if (this.hasCompleteValue) {
      return moment().year(this.value.currentTimeframe.year).month(this.value.currentTimeframe.month);
    }
  }

  get previousTimeframeString(): string {
    if (this.intermediateSelection) {
      const {month, year} = this.intermediateSelection;
      return moment().month(month).year(year).format("MMM YYYY")
    }
    if (!this.hasCompleteValue) {return ""; }
    const {previousTimeframe} = this.value;
    const previousString = moment().year(previousTimeframe.year).month(previousTimeframe.month).format("MMM YYYY")
    return previousString
  }

  get availableTimeframeYears(): number[] {
    return uniqBy(this.timeframes, "year").map(timeframe => timeframe.year)
  }

  outcomeTimeframesByYear(year: number): OutcomeTimeframe[] {
    return _filter(this.timeframes, {year})
  }

  get currentTimeframeString(): string {
    if (!this.hasCompleteValue) {return ""}
    const {currentTimeframe} = this.value;
    const currentString = moment().year(currentTimeframe.year).month(currentTimeframe.month).format("MMM YYYY")
    return currentString
  }

  get canGoBack(): boolean {
    return this.timeframes.some(timeframe => timeframe.year < this.year - 1)
  }

  get canGoForward(): boolean {
    return this.timeframes.some(timeframe => timeframe.year > this.year)
  }

  openCmsClicked() {
    this.reset()
    this.openCms.emit();
  }

  reset() {
    this.open = false;
    this.intermediateSelection = null;
    this.hoveredSelection = null;
  }

  isEndMonth(month): boolean {
    return [0, 3, 4, 7, 8, 11].includes(month);
  }

  // Control Value Accessor Implementation

  onChange = (SelectedTimeframes) => {};
  onTouched = () => {};

  writeValue(value: SelectedTimeframes) {
    if (value) {
      const { currentTimeframe, previousTimeframe, isRangeMode } = value;
      const currentTimeframeMonths = 12 * currentTimeframe.year + currentTimeframe.month + 1
      const previousTimeframeMonths = 12 * previousTimeframe.year + previousTimeframe.month + 1
      if (currentTimeframeMonths - previousTimeframeMonths > 13 && isRangeMode) {
        this.rangeError = true;
        this.isRangeMode = false;
        value = {...value, isRangeMode: this.isRangeMode}
      } else {
        this.rangeError = false;
      }
    }

    this.value = value;
    this.isRangeMode = get(value, "isRangeMode");
    this.onChange(value);
  }

  registerOnChange(onChange) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }
  // End Control Value Accessor Implementation

}

export interface SelectedTimeframes {
  currentTimeframe: OutcomeTimeframe;
  previousTimeframe: OutcomeTimeframe;
  isRangeMode: boolean;
}
