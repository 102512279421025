<div class="dropdown" [hidden]="dropdownHidden">
  <div  *ngIf="(!options || options.length < 1)" >
    <div>{{ noOptionsMessage}}</div>
    <p class="link" (click)="onMessageClick()">{{ noOptionsLink }}</p>
  </div>
  <div *ngIf="(options && options.length > 0)" class="filter-container">
    <div>{{filterTitle}}</div>
    <input type="text" [(ngModel)]="filterQuery" (keyup)="filterOptions()" />
  </div>

  <div class="optionsList" >
    <div *ngFor="let option of filteredOptions" (click)="onOptionClick(option.id)" [ngClass]="{'option':true, 'selected':option.id === selectedOptionId}"  [title]="option.name">{{option.name}}</div>
  </div>
  <div class="buttons">
    <div (click)="onCancelClick()" class="cancel_btn">
      Cancel
    </div>
    <div *ngIf="(options && options.length > 0)" (click)="onSaveClick()" class="save_btn">
      Save
    </div>
  </div>
</div>
