import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'paginator',
  templateUrl: 'paginator.component.html',
  styleUrls: ['./paginator.component.sass']
})

export class Paginator {

  @Input() pageSizeOptions: number[];
  @Input() pageSize: number;
  @Input() offset: number;
  @Input() count: number;

  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() offsetChange = new EventEmitter<number>();

  nextPage(): void {
    if (!this.hasNextPage()) { return }
    this.offset += this.pageSize
    this.offsetChange.emit(this.offset)
  }

  hasNextPage(): boolean {
    return !((this.offset + this.pageSize) >= this.count);
  }

  previousPage(): void {
    if (!this.hasPreviousPage()) { return }
    this.offset -= this.pageSize
    this.offsetChange.emit(this.offset)
  }

  hasPreviousPage(): boolean {
    return this.offset !== 0;
  }

  get rangeLabel(): string {
    const upperBound = Math.min(this.count, this.offset + this.pageSize)
    return `${this.offset + 1} - ${upperBound} of ${this.count}`
  }

  changePageSize(newPageSize: number): void {
    this.offset = 0
    this.pageSizeChange.emit(newPageSize)
    this.offsetChange.emit(this.offset)
  }
}
