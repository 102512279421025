import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from "@ngrx/store";

import { Observable, of } from 'rxjs';
import { isEqual } from 'lodash';

import { getContextSlugs, getSlugs } from 'app/hierarchy/hierarchy.utils';
import { AppState } from 'app/reducers';
import { Go } from 'app/router/router.actions';

@Injectable()
export class ResourceContextGuard implements CanActivateChild {

  constructor(private store: Store<AppState>, private router: Router) {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.isClientContextUnchanged(state) ||
      this.isLoadingPageDirectly() ||
      this.isGoingFromSummaryToDetail(state) ||
      this.isGoingToSummary(next)) {
      return of(true);
    } else {
      this.goToSummaryInstead(next);
      return of(false);
    }
  }

  private isClientContextUnchanged(state: RouterStateSnapshot) {
    const currentUrlComponents = this.router.url.split('/');
    const nextUrlComponents = state.url.split('/');
    const currentClientContext = currentUrlComponents.slice(1, 5);
    const nextClientContext = nextUrlComponents.slice(1, 5);
    return isEqual(currentClientContext, nextClientContext);
  }

  private isLoadingPageDirectly() {
    const currentUrlComponents = this.router.url.split('/');
    const currentClientContext = currentUrlComponents.slice(1, 5);
    return currentClientContext.length < 4
  }

  private isGoingToSummary(next: ActivatedRouteSnapshot) {
    return next.url.length === 0;
  }

  private isGoingFromSummaryToDetail(state: RouterStateSnapshot) {
    const currentUrlComponents = this.router.url.split('/');
    const nextUrlComponents = state.url.split('/');
    return nextUrlComponents.length > currentUrlComponents.length;
  }

  private goToSummaryInstead(next: ActivatedRouteSnapshot) {
    const contextSlugs = getContextSlugs(next.params);
    const parentModule = next.parent.url.map(segment => segment.toString());
    this.store.dispatch(new Go({ path: getSlugs(contextSlugs).concat(parentModule) }));
  }
}
