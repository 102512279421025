import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-slide-in-overlay',
  templateUrl: './slide-in-overlay.component.html',
  styleUrls: ['./slide-in-overlay.component.sass'],
})

export class SlideInOverlayComponent implements OnChanges {

  position: string = "init";
  @Input() percentOfParentCovered;
  @Input() width;
  @Input() active: boolean = false;

  @Output() backgroundClick = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.active) {
      const isNowActive = changes.active.currentValue;
      if (isNowActive || !isNowActive && this.position !== 'init') {
        this.position = isNowActive ? 'on' : 'off'
      }
    }
  }

  toggleState() {
    this.position = ((this.position === "off" || this.position === "init") ? "on" : "off");
  }

  get calculatedWidth() {
    if (this.width) {
      return `${this.width}px`
    } else {
      return `${this.percentOfParentCovered}%`
    }
  }

  get isPositionOn() {
    return this.position === 'on'
  }

  get contentCssClass(): { [className: string]: boolean } {
    return {
      [this.position]: true
    }
  }
}
