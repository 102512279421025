import { Demographic, Filter, TopLevelTabType, DemographicConfig, Tab } from "./insights.models";
import { MarketLevelDemographic } from 'app/insights/insights-components/market-level-demographics/market-level-demographic.interface';
import { SubMarket } from "app/mekko/mekko.reducer";
import { InsightsContextType, PercentCalculationType, ResourceType, SegmentContexts } from "./insights.constants";
import { RegionDemographic } from "./insights.reducer";
import { BulkFetchAction } from "app/shared/utils/bulk-fetch-state";
import { Node } from "app/segment-picker/root-nodes/root-node.interface";
import { FocusType } from "app/shared/components/ppc-split-screen/ppc-split-screen.component";
import { MarketLevelSurvey } from "app/insights/insights-components/market-level-survey/market-level-survey.interface";
import { IndexBase } from "app/insights/insights.reducer";

export interface InsightsAction {
  type: string;
  insightsContext: InsightsContextType;
}

export class FetchDemographics implements InsightsAction {
  static readonly type = "INSIGHTS:FETCH_DEMOGRAPHICS";
  readonly type = FetchDemographics.type;

  constructor(public resourceId: number, public resourceType: ResourceType, public insightsContext: InsightsContextType) { }
}

export class LoadDemographics implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_DEMOGRAPHICS";
  readonly type = LoadDemographics.type;

  constructor(public demographicData: Demographic[], public insightsContext: InsightsContextType) { }
}

export class LoadDemographic implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_DEMOGRAPHIC";
  readonly type = LoadDemographic.type;

  constructor(public demographic: Demographic, public insightsContext: InsightsContextType) {}
}

export class ToggleFilter implements InsightsAction {
  static readonly type = "INSIGHTS:TOGGLE_FILTER";
  readonly type = ToggleFilter.type;

  constructor(public filter: Filter, public insightsContext: InsightsContextType) { }
}

export class ClearFilters implements InsightsAction {
  static readonly type = "INSIGHTS:CLEAR_FILTERS";
  readonly type = ClearFilters.type;

  constructor(public insightsContext: InsightsContextType) {}
}

export class SetTopLevelTab implements InsightsAction {
  static readonly type = "INSIGHTS:SET_TOP_LEVEL_TAB";
  readonly type = SetTopLevelTab.type;

  constructor(public topLevelTab: TopLevelTabType, public insightsContext: InsightsContextType) { }
}

export class EditDemographic implements InsightsAction {
  static readonly type = "INSIGHTS:EDIT_DEMOGRAPHIC";
  readonly type = EditDemographic.type;

  constructor(public demo: Demographic, public insightsContext: InsightsContextType) {}
}

export class UpsertDemographic implements InsightsAction {
  static readonly type = "INSIGHTS:UPSERT_DEMOGRAPHIC";
  readonly type = UpsertDemographic.type;

  constructor(public demographic: Demographic, public insightsContext: InsightsContextType) {}
}

export class DestroyDemographic implements InsightsAction {
  static readonly type = "INSIGHTS:DESTROY_DEMOGRAPHIC";
  readonly type = DestroyDemographic.type;

  constructor(public demographic: Demographic, public insightsContext: InsightsContextType) {}
}

export class SetActiveGWIQuestions implements InsightsAction {
  static readonly type = "INSIGHTS:SET_ACTIVE_GWI_QUESTIONS";
  readonly type = SetActiveGWIQuestions.type;

  constructor(public activeGWIQuestions: Node[], public insightsContext: InsightsContextType) { }
}

export class ToggleManage implements InsightsAction {
  static readonly type = "INSIGHTS:TOGGLE_MANAGE";
  readonly type = ToggleManage.type;

  constructor(public insightsContext: InsightsContextType) { }
}

export class ToggleExport implements InsightsAction {
  static readonly type = "INSIGHTS:TOGGLE_EXPORT";
  readonly type = ToggleExport.type;

  constructor(public insightsContext: InsightsContextType) { }
}

export class SaveTabConfig implements InsightsAction {
  static readonly type = "INSIGHTS:SAVE_TAB_CONFIG";
  readonly type = SaveTabConfig.type;

  constructor(public tabConfig: Tab[], public resourceId: number, public resourceType: ResourceType, public insightsContext: InsightsContextType) { }
}

export class FetchDemographicsConfig implements BulkFetchAction, InsightsAction {
  static readonly type = "INSIGHTS:FETCH_DEMOGRAPHICS_CONFIG";
  readonly type = FetchDemographicsConfig.type;

  constructor(public configType: string, public resourceId: number, public resourceType: ResourceType, public insightsContext: InsightsContextType) { }

  get requestId() { return this.configType }
}

export class SaveDemographicsConfig implements BulkFetchAction, InsightsAction {
  static readonly type = "INSIGHTS:SAVE_DEMOGRAPHICS_CONFIG";
  readonly type = SaveDemographicsConfig.type;

  constructor(public config: DemographicConfig[], public configType: string, public resourceId: number, public resourceType: ResourceType, public insightsContext: InsightsContextType) { }

  get requestId() { return this.configType }
}

export class LoadDemographicsConfig implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_DEMOGRAPHICS_CONFIG";
  readonly type = LoadDemographicsConfig.type;

  constructor(public config: DemographicConfig[], public configType: string, public insightsContext: InsightsContextType) { }
}

export class SetSharedInterestSegments implements InsightsAction {
  static readonly type = "INSIGHTS:SET_SHARED_INTEREST_SEGMENTS";
  readonly type = SetSharedInterestSegments.type;

  constructor(public segments: string[], public insightsContext: InsightsContextType) { }
}

export class SetPersonLevelTab implements InsightsAction {
  static readonly type = "INSIGHTS:SET_PERSON_LEVEL_TAB";
  readonly type = SetPersonLevelTab.type;

  constructor(public personLevelTab: string, public insightsContext: InsightsContextType) { }
}

export class SetMarketLevelTab implements InsightsAction {
  static readonly type = "INSIGHTS:SET_MARKET_LEVEL_TAB";
  readonly type = SetMarketLevelTab.type;

  constructor(public marketLevelTab: string, public insightsContext: InsightsContextType) { }
}

export class SetPredictionsLevelTab implements InsightsAction {
  static readonly type = "INSIGHTS:SET_PREDICTIONS_LEVEL_TAB";
  readonly type = SetPredictionsLevelTab.type;

  constructor(public predictionsLevelTab: string, public insightsContext: InsightsContextType) { }
}

export class SaveCustomTab implements InsightsAction {
  static readonly type = "INSIGHTS:SAVE_CUSTOM_TAB";
  readonly type = SaveCustomTab.type;

  constructor(public customTab: Tab, public insightsContext: InsightsContextType) {}
}

export class EditCustomTab implements InsightsAction {
  static readonly type = "INSIGHTS:EDIT_CUSTOM_TAB";
  readonly type = EditCustomTab.type;

  constructor(public customTab: Tab, public parent: Tab, public insightsContext: InsightsContextType) {}
}

export class DestroyCustomTab implements InsightsAction {
  static readonly type = "INSIGHTS:DESTROY_CUSTOM_TAB";
  readonly type = DestroyCustomTab.type;

  constructor(public customTabId: number, public insightsContext: InsightsContextType) {}
}

export class FetchCustomTabsConfig implements InsightsAction {
  static readonly type = "INSIGHTS:FETCH_CUSTOM_TABS_CONFIG";
  readonly type = FetchCustomTabsConfig.type;

  constructor(public resourceId: number, public resourceType: ResourceType, public insightsContext: InsightsContextType) {}
}

export class LoadCustomTabConfig implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_CUSTOM_TAB_CONFIG";
  readonly type = LoadCustomTabConfig.type;

  constructor(public config: {config_type: string, config: DemographicConfig[]}[], public insightsContext: InsightsContextType) {}
}

export class FetchTabs implements InsightsAction {
  static readonly type = "INSIGHTS:FETCH_TABS";
  readonly type = FetchTabs.type;

  constructor(public resourceId: number, public resourceType: ResourceType, public insightsContext: InsightsContextType) { }
}

export class LoadTabs implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_TABS";
  readonly type = LoadTabs.type;

  constructor(public tabs: Tab[], public insightsContext: InsightsContextType) { }
}

export class SetFocus implements InsightsAction {
  static readonly type = "INSIGHTS:SET_FOCUS";
  readonly type = SetFocus.type;

  constructor(public isFocused: boolean, public insightsContext: InsightsContextType) { }
}

export class SetSegmentContexts implements InsightsAction {
  static readonly type = "INSIGHTS:SET_SEGMENT_CONTEXTS";
  readonly type = SetSegmentContexts.type;

  constructor(public segmentContexts: SegmentContexts, public insightsContext: InsightsContextType) { }
}

export class SetSplitScreenFocus implements InsightsAction {
  static readonly type = "INSIGHTS:SET_SPLIT_SCREEN_FOCUS";
  readonly type = SetSplitScreenFocus.type;

  constructor(public splitScreenFocus: FocusType, public insightsContext: InsightsContextType) { }
}

export class ToggleIndexMode implements InsightsAction {
  static readonly type = "INSIGHTS:TOGGLE_INDEX_MODE";
  readonly type = ToggleIndexMode.type;

  constructor(public insightsContext: InsightsContextType) { }
}

export class ResetIndexMode implements InsightsAction {
  static readonly type = "INSIGHTS:RESET_INDEX_MODE";
  readonly type = ResetIndexMode.type;

  constructor(public insightsContext: InsightsContextType) { }
}

export class SetPercentCalculationType implements InsightsAction {
  static readonly type = "INSIGHTS:SET_PERCENT_CALCULATION_TYPE";
  readonly type = SetPercentCalculationType.type;

  constructor(public percentCalculationType: PercentCalculationType, public insightsContext: InsightsContextType) { }
}

export class FetchRegionDemographics implements InsightsAction {
  static readonly type = "INSIGHTS:FETCH_REGION_DEMOGRAPHICS";
  readonly type = FetchRegionDemographics.type;

  constructor(public insightsContext: InsightsContextType) { }
}

export class LoadRegionDemographics implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_REGION_DEMOGRAPHICS";
  readonly type = LoadRegionDemographics.type;

  constructor(public insightsContext: InsightsContextType, public regionDemographics: RegionDemographic[]) { }
}

export class EditRegionDemographic implements InsightsAction {
  static readonly type = "INSIGHTS:EDIT_REGION_DEMOGRAPHIC";
  readonly type = EditRegionDemographic.type;

  constructor(public insightsContext: InsightsContextType, public regionDemographic: RegionDemographic) { }
}

export class FetchMarketLevelDemographics implements InsightsAction {
  static readonly type = "INSIGHTS:FETCH_MARKET_LEVEL_DEMOGRAPHICS";
  readonly type = FetchMarketLevelDemographics.type;

  constructor(public insightsContext: InsightsContextType, public resourceId: number, public resourceType: string) { }
}

export class LoadMarketLevelDemographics implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_MARKET_LEVEL_DEMOGRAPHICS";
  readonly type = LoadMarketLevelDemographics.type;

  constructor(public insightsContext: InsightsContextType, public marketLevelDemographics: MarketLevelDemographic[]) { }
}

export class LoadMarketLevelDemographic implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_MARKET_LEVEL_DEMOGRAPHIC";
  readonly type = LoadMarketLevelDemographic.type;

  constructor(public insightsContext: InsightsContextType, public marketLevelDemographic: MarketLevelDemographic) { }
}

export class SaveMarketLevelDemographic implements InsightsAction {
  static readonly type = "INSIGHTS:SAVE_MARKET_LEVEL_DEMOGRAPHIC";
  readonly type = SaveMarketLevelDemographic.type;

  constructor(public insightsContext: InsightsContextType, public marketLevelDemographic: MarketLevelDemographic) { }
}

export class EditMarketLevelDemographic implements InsightsAction {
  static readonly type = "INSIGHTS:EDIT_MARKET_LEVEL_DEMOGRAPHIC";
  readonly type = EditMarketLevelDemographic.type;

  constructor(public insightsContext: InsightsContextType, public marketLevelDemographic: MarketLevelDemographic) { }
}

export class DestroyMarketLevelDemographic implements InsightsAction {
  static readonly type = "INSIGHTS:DESTROY_MARKET_LEVEL_DEMOGRAPHIC";
  readonly type = DestroyMarketLevelDemographic.type;

  constructor(public insightsContext: InsightsContextType, public marketLevelDemographicId: number) { }
}

export class AddSubMarketBucketEntries implements InsightsAction {
  static readonly type = "INSIGHTS:ADD_SUB_MARKET_BUCKET_ENTRIES";
  readonly type = AddSubMarketBucketEntries.type;

  constructor(public insightsContext: InsightsContextType, public resource: Partial<SubMarket>, public resourceType: string) { }
}

export class RemoveSubMarketBucketEntries implements InsightsAction {
  static readonly type = "INSIGHTS:REMOVE_SUB_MARKET_BUCKET_ENTRIES";
  readonly type = RemoveSubMarketBucketEntries.type;

  constructor(public insightsContext: InsightsContextType, public resource: Partial<SubMarket>) { }
}

export class SetShareThisPeriod implements InsightsAction {
  static readonly type = "INSIGHTS:SET_SHARE_THIS_PERIOD";
  readonly type = SetShareThisPeriod.type;

  constructor(public shareThisPeriod: number, public insightsContext: InsightsContextType) { }
}

export class FetchShareThisData implements InsightsAction {
  static readonly type = "INSIGHTS:FETCH_SHARE_THIS_DATA";
  readonly type = FetchShareThisData.type;

  constructor(public shareThisPeriod: number, public verticalName: string, public insightsContext: InsightsContextType) { }
}

export class FetchShareThisTopDomains implements InsightsAction {
  static readonly type = "INSIGHTS:FETCH_SHARE_THIS_DATA_BY_VERTICAL";
  readonly type = FetchShareThisTopDomains.type;

  constructor(public shareThisPeriod: number, public verticalKey: string, public insightsContext: InsightsContextType) { }
}

export class LoadShareThisDataForDomains implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_SHARE_THIS_DATA_FOR_DOMAINS";
  readonly type = LoadShareThisDataForDomains.type;

  constructor(public topDomainsData: any, public insightsContext: InsightsContextType) { }
}

export class LoadShareThisData implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_SHARE_THIS_DATA";
  readonly type = LoadShareThisData.type;

  constructor(public shareThisData: any, public insightsContext: InsightsContextType) { }
}

export class FetchShareThisVerticalOverlap implements InsightsAction {
  static readonly type = "INSIGHTS:FETCH_SHARE_THIS_VERTICAL_OVERLAP_DATA_BY_VERTICAL";
  readonly type = FetchShareThisVerticalOverlap.type;

  constructor(public shareThisPeriod: number, public verticalKey: string, public insightsContext: InsightsContextType) { }
}

export class LoadShareThisVerticalOverlapData implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_SHARE_THIS_VERTICAL_OVERLAP_DATA";
  readonly type = LoadShareThisVerticalOverlapData.type;

  constructor(public verticalOverlapData: any, public insightsContext: InsightsContextType) { }
}

export class FetchMarketLevelSurveys implements InsightsAction {
  static readonly type = "INSIGHTS:FETCH_MARKET_LEVEL_SURVEYS";
  readonly type = FetchMarketLevelSurveys.type;

  constructor(public resourceId: number, public resourceType: string, public insightsContext: InsightsContextType) {}
}

export class LoadMarketLevelSurveys implements InsightsAction {
  static readonly type = "INSIGHTS:LOAD_MARKET_LEVEL_SURVEYS";
  readonly type = LoadMarketLevelSurveys.type;

  constructor(public marketLevelSurveys: MarketLevelSurvey[], public insightsContext: InsightsContextType) { }
}

export class SetIndexBase implements InsightsAction {
  static readonly type = "INSIGHTS:SET_INDEX_BASE";
  readonly type = SetIndexBase.type;

  constructor(public indexBase: IndexBase, public insightsContext: InsightsContextType) {}
}

export type ReducerActions = LoadDemographics
| ToggleFilter | SetTopLevelTab | EditDemographic
| DestroyDemographic | SetActiveGWIQuestions | RemoveSubMarketBucketEntries
| LoadDemographic | SaveMarketLevelDemographic
| SaveCustomTab | EditCustomTab | DestroyMarketLevelDemographic
| LoadCustomTabConfig | ClearFilters | AddSubMarketBucketEntries
| SetSharedInterestSegments | SetPersonLevelTab
| LoadDemographicsConfig | EditMarketLevelDemographic
| LoadCustomTabConfig | LoadMarketLevelDemographic
| SetSharedInterestSegments | SetPersonLevelTab
| SetMarketLevelTab | SetPredictionsLevelTab | SetIndexBase
| LoadTabs | SetSplitScreenFocus | SetSegmentContexts
| ToggleManage | ToggleExport | ToggleIndexMode | ResetIndexMode
| SetPercentCalculationType | EditRegionDemographic
| LoadRegionDemographics | LoadMarketLevelDemographics
| SetShareThisPeriod | LoadShareThisData | FetchShareThisData | FetchShareThisTopDomains
| LoadShareThisDataForDomains | FetchShareThisVerticalOverlap
| LoadShareThisVerticalOverlapData | FetchMarketLevelSurveys | LoadMarketLevelSurveys
