<div class="edit-pane">
  <div class="edit-pane-header">
    <div class="edit-pane-header-text">
      <div class="edit-pane-title">
        Admin Outcomes
      </div>
    </div>
    <div class="edit-pane-header-close" (click)="cancel()">
      <img src="assets/icons/close.svg" />
    </div>
  </div>
  <div class="edit-pane-section year-selector">
    <div class="select">
      <label>Select year to view</label>
      <app-select-box [items]="yearOptions" [(ngModel)]="activeYear" (ngModelChange)="activeYearChanged($event)"></app-select-box>
    </div>
    <div class="add-timeframe">
      <button class="btn" (click)="addNewTimeframe()" *ngIf="firstAvailableMonth != null"><div>
        Add Timeframe
      </div></button>
    </div>
  </div>
  <div class="edit-pane-section new-timeframe" *ngIf="newTimeframe && firstAvailableMonth != null">
    <div class="new-timeframe-header">New Timeframe</div>
    <div class="new-timeframe-row">
      <div class="select">
        <label>Select Month</label>
        <app-select-box [items]="availableMonths$ | async" [(ngModel)]="newTimeframe.month" [multi]="false"></app-select-box>
      </div>
      <div class="new-timeframe-actions">
        <button class="btn" (click)="saveNewTimeframe()">Apply</button>
        <button mat-button (click)="newTimeframe = null">Cancel</button>
      </div>
    </div>
  </div>
  <div class="edit-pane-section">
    <ppc-outcome-timeframe *ngFor="let outcomeTimeframe of outcomeTimeframes$ | async; trackBy: trackById" [outcomeTimeframe]="outcomeTimeframe"></ppc-outcome-timeframe>
  </div>


</div>
