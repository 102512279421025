import { Product } from './product/product.model';
import { emptyHierarchyBrand} from "app/hierarchy/hierarchy.utils";
import {HierarchyBrand} from "app/hierarchy/hierarchy.interface";

export class Brand implements HierarchyBrand {
  editMode: boolean = false;
  id: string;
  name: string;
  category: string;
  slug: string;
  slug_full: string;
  products: Product[];
  client_id: string;
  region_id: string;
  conversant_company_id: number;


  constructor(data: HierarchyBrand = emptyHierarchyBrand()) {
    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug;
    this.slug_full = data.slug_full;
    this.category = data.category;
    this.conversant_company_id = data.conversant_company_id;
    this.products = (data.products || []).map(p => new Product(p));
    this.client_id = data.client_id;
    this.region_id = data.region_id;
  }

  open(): void {
    this.editMode = true;
  }

  close(): void {
    this.editMode = false;
  }

  openAllProducts(): void {
    this.products.forEach((product) => {
      product.open();
    });
  }

  closeAllProducts(): void {
    this.products.forEach((product) => {
      product.close();
    });
  }

  get invalid(): boolean {
    return this.products.filter(product => !product.name).length > 0;
  }

  get isOpen(): boolean {
    return this.editMode;
  }

  get isClosed(): boolean {
    return !this.editMode;
  }

  get isAnyProductOpen() {
    return this.products.filter(product => product.editMode).length > 0;
  }
}
