<ppc-loading-overlay *ngIf="loading"></ppc-loading-overlay>
<div class="plans-top-row">
  <div class="info-left">
    <div class="info">
      <div class="page-title">
        <span class="gold-title">Plans</span> Scenarios
      </div>
      <div class="name">
        <i *ngIf="plan.imported" ppcTooltip="Plan was imported from Scenario Planner"></i>
        {{plan.planName}}
      </div>
    </div>

    <div id="plan-info">
      <div class="plan-info-block">
        <div class="plan-info-block-text">
          <span class="dates">
            {{plan.getStartDateString() | date: 'MM/dd/yyyy' : 'UTC'}} - {{plan.getEndDateString() | date: 'MM/dd/yyyy' : 'UTC'}}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="info-right">
    <span id="import_button" *ngIf="!plan.imported">
      <ng-container *checkFeatureAccess="let canAccessChannels=canAccess; feature: 'channels'">
        <button mat-button *ngIf="canAccessChannels && !plan.channelId" (click)="dropdown.openDropdown()" class="attach-button" id="import_trigger">
          ATTACH CHANNELS
        </button>
        <button mat-button *ngIf="canAccessChannels && plan.channelId" class="attach-button" (click)="gotoChannelPage()">
          VIEW CHANNELS
        </button>
      </ng-container>
      <app-dropdown-with-buttons #dropdown
                                  [options]="channelNames" noOptionsMessage="There are no unassigned channels available."
                                  noOptionsLink="Please create channels to attach." [onMessageClick]="gotoChannelSummaryPage.bind(this)"
                                  [onSave]="importChannelsSave.bind(this)" filterTitle="Filter Channels"
      >
      </app-dropdown-with-buttons>
    </span>
    <button mat-button id="edit_button" class="edit-button" (click)="openEditScenarioDialog()">
      EDIT SCENARIO
    </button>
  </div>
</div>

<div class="page-container">
  <div class="info-wrapper">

    <div class="grid-list">
      <div class="grid-list-tile">
        <div class="grid-list-tile-header">Budget</div>
        <div class="grid-list-tile-body">
          <span class="stat">{{plan.totalBudget | currency: currencyCode : 'symbol-narrow'}}</span>
        </div>
      </div>
      <div *ngIf="!isReachCurve" class="grid-list-tile">
        <div class="grid-list-tile-header">Sales</div>
        <div class="grid-list-tile-body">
          <span class="stat">{{plan.sales | currency: currencyCode : 'symbol-narrow'}}</span>
        </div>
      </div>
      <div *ngIf="isReachCurve" class="grid-list-tile">
        <div class="grid-list-tile-header">Reach</div>
        <div class="grid-list-tile-body">
          <span class="stat">{{plan.aggregatedReach / 100 | percent:'1.0-2'}}</span>
        </div>
      </div>
      <div *ngIf="!isReachCurve" class="grid-list-tile">
        <div class="grid-list-tile-header">ROI</div>
        <div class="grid-list-tile-body">
          <span class="stat">{{this.plan.roi}}</span>
        </div>
      </div>
      <div *ngIf="isReachCurve && isTRP" class="grid-list-tile">
        <div class="grid-list-tile-header">Frequency</div>
        <div class="grid-list-tile-body">
          <span class="stat">{{this.plan.frequency | number:'1.0-2'}}</span>
        </div>
      </div>
      <div class="grid-list-tile">
        <div class="grid-list-tile-header">Channels</div>
        <div class="grid-list-tile-body">
          <span class="stat">{{plan.numChannels}}</span>
        </div>
      </div>
    </div>

    <div id="graph-container" *ngIf="!graphError">
      <div id="segment-view-by">
        <div>
          View By <span class="metric_display">{{isReachCurve ? curveLabelOutput(curveOverride, 'caps') : "Sales"}}</span>
        </div>
      </div>

      <div id="curve-graph-container"></div>
    </div>

    <div class="graph-error" *ngIf="graphError">
      {{graphError}}
    </div>
  </div>

  <div class="side-card">
    <div class="error-message" *ngIf="channelErrorMessage != null">{{channelErrorMessage}}</div>

    <div class="grid-list margin-btm-10">
      <div class="grid-list-tile">
          <div class="grid-list-tile-body side-card-header">
            Media Types Allocation
          </div>
      </div>
    </div>

    <div class="grid-list">
      <div class = "grid-list-tile">
        <div class="donut-chart-container">
          <ppc-donut-chart [donutChartData]="donutChartData"></ppc-donut-chart>
        </div>

        <div *ngFor="let planChannel of filteredChannels; let i = index" class="allocation-breakdown">
          <span class="color-id-circle" [style.background]="determineColor(i)"></span>
          <span class="allocation-name">{{planChannel.name}}</span>
          <app-progress-bar
            [percent]="planChannel.allocationShare"
            [hideText]="true"
            [color]="determineColor(i)"
            [waitToAnimate]="false">
          </app-progress-bar>
          <span class="allocation-percent">{{planChannel.allocationShare}}%</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- overlay with form -->
<app-slide-in-overlay #overlay>
  <app-scenario-dialog #scenarioForm
                       (onClose)="onFormClose()"
                       [isNewPlan]="false"
                       [planNames]="planNames"
                       (completedSave)="$event && redirectToScenariosSummary()"
  ></app-scenario-dialog>
</app-slide-in-overlay>

