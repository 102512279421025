import { Injectable } from '@angular/core';
import { ASC, DEFAULT } from 'app/insights/insights.service';

@Injectable({
  providedIn: 'root'
})
export class PpcSortService {

  constructor() { }

  sortBarChartData(sortDirection: SortDirection, sortData: any[]): void {
    if ( sortDirection === DEFAULT ) {
      sortData.sort( (a, b) => a.order - b.order );
    } else {
      if ( sortDirection === ASC ) {
        sortData.sort( (a, b) => a.percent - b.percent )
      } else {
        sortData.sort( (a, b) => b.percent - a.percent )
      };
    }
  }
}

export type SortDirection = 'asc' | 'desc' | 'default'
