<div class="comparison" [class.is-people-count]="isPeopleCount">
  <div class="comparison-header">
    <div class="comparison-description" *ngIf="isTier3 && isMatched">
      Matched <span class="subdescription">Unique overlap</span>
    </div>
    <div class="comparison-description" *ngIf="isModeled">
      Modeled <span class="subdescription">Sum of Weights</span>
    </div>
    <div class="comparison-description" ppcTooltip="% of X also appears in Y" *ngIf="!isTier3 && isMatched">
      Matched <span class="subdescription">Percentages based on (X<span class="intersection">&cap;</span>Y) / X</span>
    </div>
    <div class="comparison-description" ppcTooltip="Composition of Ys within X vs the composition of Ys within the total population"
      *ngIf="isIndex">
      Index <span class="subdescription">Counts based off overlap to total population</span>
    </div>
    <div class="comparison-legend" *ngIf="isOverlap">
      <div class="color-scale" [style.background]="overlapGradient"></div>
      <div class="number-scale">
        <div class="scale" *ngFor="let number of [0,100]">
          {{number}}%
        </div>
      </div>
    </div>
    <div class="comparison-legend" *ngIf="isIndex">
      <div class="color-scale" [style.background]="indexGradient"></div>
      <div class="number-scale">
        <div class="scale" *ngFor="let sign of ['-','100', '+']">
          {{sign}}
        </div>
      </div>
    </div>
    <div matRipple [matRippleCentered]="true" class="export-icon-container">
      <i class="fa fa-sign-out" ppcTooltip="Click to export this overlap chart" (click)="export()"></i>
    </div>
  </div>
  <div class="comparison-body">
    <div class="comparison-body-top">
      <div class="y-axis-name-container">
        <div class="y-axis-name">
          <div [ppcTooltip]="comparison.y_axis_name"  [tooltipDisabled]="!comparison.y_axis_name">{{ comparison.y_axis_name }}</div>
        </div>
      </div>

      <div class="y-axis-labels">
        <div class="y-axis-label" *ngFor="let row of yValues; let y=index" [class.dim]="(hoverCoords && hoverCoords.y != y)" (click)="toggleRow(row)">
          <div [ppcTooltip]="row?.name">{{ row?.name }}</div>
          <div class="y-axis-count">
            <ppc-zero-count-warning-icon
              *ngIf="getSegmentCount(row?.short_id) == 0"
              ppcTooltip="Click to learn why this audience has a zero count."
              ></ppc-zero-count-warning-icon>
            <span *ngIf="isPeopleCount">&nbsp;{{ getSegmentCount(row?.short_id) | number }}</span>
          </div>
        </div>
      </div>
      <div class="comparison-chart" (mouseleave)="clearHoverCoords()" [class.insights-active]="focusedCells.length">
        <div *ngFor="let column of xValues; let x=index" class="comparison-chart-row">
          <div *ngFor="let cell of yValues; let y=index"
                class="comparison-chart-cell"
                [class.dim]="focusedCells.length"
                [class.insights-focused]="cellFocused(column, cell, focusedCells)"
                (click)="toggleFocusedCell(column, cell)"
                (mouseenter)="setHoverCoords(x,y)"
                [style.background-color]="getColor(column?.short_id, cell?.short_id)"
                ppcTooltip
                [tooltipTemplate]="tooltip"
                [tooltipDelayTime]="0"
                [tooltipDisabled]="!canRender">
                <div class="comparison-chart-cell-focus">
                  <i class="fa fa-check"></i>
                </div>
                <ppc-comparison-chart-cell-display
                  *ngIf="canRender"
                  [percent]="getPercent(column?.short_id, cell?.short_id)"
                  [count]="getCount(column?.short_id, cell?.short_id)"
                  [index]="getIndex(column?.short_id, cell?.short_id) * 100"
                  [hideCount]="cellsOverflow$ | async"
                  [isIndex]="isIndex">
                </ppc-comparison-chart-cell-display>
                <ng-template #tooltip>
                  <div class="comparison-chart-cell-hover">
                    <div class="light-grey">{{ column?.name }} <span class="intersection">&cap;</span> {{ cell?.name }}</div>
                    <div *ngIf="canRender && isOverlap" class="light-grey">
                      {{ getPercent(column?.short_id, cell?.short_id)| number:'1.0-0'}}% | {{ getCount(column?.short_id, cell?.short_id) | number}}
                    </div>
                    <div *ngIf="canRender && isIndex" class="light-grey">
                      {{ getIndex(column?.short_id, cell?.short_id) * 100| number:'1.0-0'}}
                    </div>
                  </div>
                </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="comparison-body-bottom">
      <div class="x-axis-labels">
        <div class="x-axis-label" *ngFor="let column of xValues; let x=index" [class.dim]="(hoverCoords && hoverCoords.x != x)" (click)="toggleColumn(column)">
          <div [ppcTooltip]="column?.name">
            {{ column?.name }}
          </div>
          <div class="x-axis-count">
            <ppc-zero-count-warning-icon
              [skewed]="true"
              *ngIf="getSegmentCount(column?.short_id) == 0"
              ppcTooltip="Click to learn why this audience has a zero count."
              ></ppc-zero-count-warning-icon>
            <span *ngIf="isPeopleCount">&nbsp;{{ getSegmentCount(column?.short_id) | number}}</span>
          </div>
        </div>
      </div>
      <div class="x-axis-name-container" [class.no-x-axis-name]="!comparison.x_axis_name">
        <div class="x-axis-name">
          {{ comparison.x_axis_name }}
        </div>
      </div>
    </div>
  </div>
</div>
