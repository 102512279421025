import { RichText } from "exceljs";

export default class ColorUtil {
  static COLORS: string[] = [
    "#07D179", "#C86CEA", "#D3AE11", "#8D07D3", "#2FB0D3",
    "#09BA16", "#1C7DA0", "#DB799F", "#0429AD", "#B23D43"
  ];
  static COLORS_LIGHT: string[] = [
    "#01F794", "#48CAFA", "#B400FF", "#E689FF", "#07E220",
    "#002AEB", "#1D99CF", "#FB88AF", "#F7CA0F", "#E24B4D"
  ];
  static MEKKO_CHART_COLORS: string[] = [
    "#48CAFA", "#B400FF", "#bec7ce", "#E690FF", "#07E220",
    "#002AEB", "#1D99CF", "#FB88AF", "#F7CA0F", "#E24B4D"
  ];

  static getMap(arr: string[]): {[key: string]: string} {
    const colorHash = {};
    let index = 0;
    const newSortedUniqueArr = Array.from(new Set(arr)).sort();

    newSortedUniqueArr.forEach(e => {
      if (!colorHash[e]) {
        colorHash[e] = ColorUtil.MEKKO_CHART_COLORS[index % ColorUtil.COLORS_LIGHT.length];
        index += 1;
      }
    });

    return colorHash;
  }

  private static MEDIA_TYPE_COLORS: string[] = [
    "#29f597", "#e68dfc", "#0e39e8", "#b445fb", "#279acd",
    "#f98aaf", "#f7ca31", "#e14c51", "#50cbf9", "#25e135", "#bec7ce"
  ];

  static getMediaTypeColor(index: number): string {
    // colorIndex is which of the 11 colors to base our new color on:
    const colorIndex = index % ColorUtil.MEDIA_TYPE_COLORS.length;
    // colorGroup is the nth cycle around the 11 colors:
    const colorGroup = (index / ColorUtil.MEDIA_TYPE_COLORS.length) | 0;

    // Parse out the hex values of the RGB components:
    const hexcode = ColorUtil.MEDIA_TYPE_COLORS[colorIndex].slice(1);
    const r = parseInt(hexcode.slice(0, 2), 16);
    const g = parseInt(hexcode.slice(2, 4), 16);
    const b = parseInt(hexcode.slice(4, 6), 16);

    // Create a scalar to scale components brightness by 2^-(colorGroup*0.5):
    // Effectively:
    //   0 -> 1.0
    //   1 -> 0.7071067811865476
    //   2 -> 0.5
    //   3 -> 0.3535533905932738
    //   etc.
    const scalar = Math.pow(2, -(colorGroup * 0.5));

    // Multiply RGB components by the scalar: (using `x | 0` pattern to coerce float to 32-bit integer)
    const newR = (r * scalar) | 0;
    const newG = (g * scalar) | 0;
    const newB = (b * scalar) | 0;

    const newHexcode = "#" +
      ("0" + newR.toString(16)).slice(-2) +
      ("0" + newG.toString(16)).slice(-2) +
      ("0" + newB.toString(16)).slice(-2);

    return newHexcode;
  }
}

export interface RichTextObj {
  richText: RichText[];
}

export interface ColorObj {
  text: string;
  color: string;
}


export function colorText(richTextArr: ColorObj[]): RichTextObj {
  const richTextObj: RichTextObj = {richText: []};
  richTextArr.forEach( ele => {
    const rt: RichText = { text: ele.text, font: { color: { argb: ele.color}} };
    richTextObj.richText.push(rt);
  });
  return richTextObj;
}

export const RED = '00FF0000';
export const DEFAULT = '00000000';
export const GREEN = '003CB371';
export const PURPLE = '00800080';
export const BLUE = '001E90FF';
