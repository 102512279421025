<button matRipple class="scroll-top-button" [class.show]="scrollTop > 400" (click)="triggerScrollTo('#top')">
  Back to Top
</button>
<div class="privacy-policy" id="top">
  <img width="300" src="assets/images/ppc_logo/ppc_logo_white.png" />
  <div class="section border-bottom">
    <div class="main-header">
      Privacy Policy
    </div>
    <p>
      Publicis Media ("[Publicis Media]," "we," "us" or "our") is a technology solutions provider in the digital media marketplace. Our clients are primarily advertisers, ad agencies, Website and app publishers, data providers and other businesses that utilize
      our services. We provide an advanced technology suite of flexible media planning applications, data collection, optimization, analytics, advertising and digital services, which use a variety of technology solutions, including a Publicis Groupe ID
      (PID), an anonymous identifier that helps advertisers gain a holistic understanding of their consumers regardless of platform, channel and device ("Technology"). Our Technology improves advertisers’ ability to use audience-defining insights from
      hundreds of data sources to find and communicate with their consumers across all media. By analyzing consumer information and targeting ad placement, we are able to optimize the performance of an ad campaign for an advertiser while providing a more
      relevant and meaningful online experience for consumers who view the ads we deliver.
    </p>
    <p>
      This Privacy Policy describes our privacy practices with respect to the information we collect on behalf of our clients through our Technology, as well as the information collected on our own Website. This Privacy Policy outlines the kinds of information
      we collect, how we collect information, how we use and disclose collected information, and how you can control, correct and/or update the information.
    </p>
  </div>
  <ul>
    <div class="section-header">
      Table of Contents
    </div>
    <li>
      <a (click)="triggerScrollTo('#1')">1. Information We Collect Through Our Services and Technology</a>
    </li>
    <li>
      <a (click)="triggerScrollTo('#2')">2. How We Collect Information Through Our Technology</a>
    </li>
    <li>
      <a (click)="triggerScrollTo('#3')">3. How We Use Information Collected Through Our Technology</a>
    </li>
    <li>
      <a (click)="triggerScrollTo('#4')">4. Your Choices: Opting Out of Publicis Media Technology and Reviewing Your Ad Preferences</a>
    </li>
    <li>
      <a (click)="triggerScrollTo('#5')">5. Information Sharing and Disclosures</a>
    </li>
    <li>
      <a (click)="triggerScrollTo('#6')">6. Cross-Border Data Transfers</a>
    </li>
    <li>
      <a (click)="triggerScrollTo('#7')">7. Security</a>
    </li>
    <li>
      <a (click)="triggerScrollTo('#8')">8. Third-Party Advertisers and Links</a>
    </li>
    <li>
      <a (click)="triggerScrollTo('#9')">9. Children's Privacy</a>
    </li>
    <li>
      <a (click)="triggerScrollTo('#10')">10. How to Contact Us</a>
    </li>
    <li>
      <a (click)="triggerScrollTo('#11')">11. Changes to this Privacy Policy and Additional Information</a>
    </li>
  </ul>
  <div class="section" id="1">
    <div class="section-header">
      Information We Collect through our Services and Technology
    </div>
    <p>
      Publicis Media does not provide services directly to consumers. Instead, Publicis Media has technology solutions and services that help businesses – marketers, advertising agencies and publishers – engage in digital media activities and interest-based
      advertising. Interest-based advertising is the practice of delivering relevant content and advertising to people based on their interests, activities, and behaviors. Note that when providing interest-based advertising related services to its clients,
      Publicis Media does not use information that we consider to be personal information, which is information that identifies you as a specific person (such as your name, address, email address, phone number, precise geolocation), but we may use de-identified
      personal information as further described below.
    </p>
    <p>
      In order to receive services from Publicis Media, Publicis Media's clients provide Publicis Media with information about their customers. In addition, Publicis Media uses tracking technologies such as the PID to collect information about consumers' interactions
      with the websites of advertisers, clients, publishers, partners or other content that Publicis Media controls, manages or is authorized to access. Publicis Media may also work with third-party vendors to acquire additional information about consumers'
      preferences and interests. Together, this information includes non-PII, such as clickstream data, anonymous IDs, offline and online purchase activity, demographic and geographic information. We collect information about your interaction with our
      Technology, your web browsing activity and your device. This information may include information such as your device type (e.g., a computer, tablet, smartphone, gaming console or smart/connected TV), your IP address, device identifiers, the date
      and time of your visit, browser type, web pages visited when viewing an ad, and information about your interaction with our Technology when viewing content on your device, including whether you interacted with the advertisement (viewed a video ad
      to completion or clicked on an ad. For example, if you visit a number of automobile-related Websites, you may be flagged as being interested in purchasing an automobile.
    </p>
    <p>
      Publicis Media also obtains non-PII such as behavioral (e.g., based on websites and content viewed, mobile apps used, search terms, engagement with ads), contextual and demographic (e.g., age, gender, income levels) information through our Technology
      and from third-party providers that we can use to serve interest-based ads. Publicis Media may also collect Mobile Ad IDs (MAIDs) such as a Google Advertising ID (GAID) or an Apple Advertising Identifier (IDFA) as a result of running digital advertisements
      on behalf of our clients. Audience data from tagged media or publisher direct tags is also collected, including, data from campaigns, creative, insertion orders, channels, ad exchanges, platforms and inventory sources.
    </p>
    <p>
      Publicis Media collects cookies and device IDs as described below, which are then fed into our proprietary cross-device algorithm, allowing Publicis Media to map multiple devices and cookies to one individual for the purpose of serving that user another
      ad on a different device. In order to provide content-relevant ads to mobile users, we may also collect information about the apps you use and the location of your device if you have elected to provide that information to the publishers with whom
      we have partnered.
    </p>
    <p>
      As described in the next section, since this information is collected through cookies, we require our clients and data providers to obtain any legally required consents to allow us to place cookies on their Websites and collect information from you. Even
      if you consent, you can revoke your consent at any time by opting out as described below.
    </p>
  </div>
  <div class="section" id="2">
    <div class="section-header">
      How We Collect Information Through Our Technology
    </div>
    <p>
      Our Technology joins data from across various sources including the PID tag itself, device IDs, cookie IDs, marketing cloud partners, media impressions, clicks, conversions and cost data, third-party data partners, Demand Side Platforms, Ad Servers and
      deterministic data sources. For information that we collect, directly or indirectly, we typically employ the use of "pixel tags" and/or "cookies," which are commonly used on the Internet. Pixel tags and cookies help us understand how you use a publisher's
      Website and the effectiveness of an ad so that we can more accurately deliver the types of ads that are relevant to your interests. Pixel tags or cookies do not in any way damage your operating system or files. You can refuse Publicis Media cookies,
      but doing so may result in the receipt of the same ads or ads that do not cater to your interests. To permanently object to the placing of Publicis Media cookies on your computer, you can use the opt-out process described below.
    </p>
    <p>
      Pixel tags are small bits of code that reside on a Website and provide a method of information delivery allowing the operator of the page or a third party to collect information about the visitors to the page. Cookies are small text files that are stored
      on the browser of your computer that assign an anonymous identifier to your browser and provide information to the cookie sender. In addition to cookies, Publicis Media also uses mobile identifiers such as device IDs that are specific to an individual’s
      hardware, as well as statistical methods for identifying an individual across multiple devices with a high degree of accuracy.
    </p>
    <p>
      Publicis Media also receives information from third-party data providers who collect and make available information such as behavioral, contextual, and demographic information, for us and our clients to use for analytics, segmentation, targeting ads and
      reporting.
    </p>
  </div>
  <div class="section" id="3">
    <div class="section-header">
      How We Use Information Collected Through Our Technology
    </div>
    <p>
      Our Technology creates an ID graph organized around people, devices, and partners with data feeding into the ID graph for optimization, reporting, segmentation, targeting and activation. Publicis Media uses information it receives and collects through
      its Technology to tailor, target, and assess the effectiveness of the ads delivered to you.
    </p>
    <p>
      Publicis Media leverages data collected to bridge multiple platforms together so that all aspects of the digital campaign can be more effectively executed: planning, analysis, targeting, optimization and reporting. Publicis Media also leverages data in
      an anonymized, aggregated fashion to understand macro-level norms and trends within the advertising industry.
    </p>
    <p>
      Our analysis includes contextual and demographic elements, behaviors and viewing preferences, and we use this information to predict the characteristics and preferences of the viewers on our client’s Websites or on those that use our Technology. We report
      this information to our advertiser clients in an aggregated, anonymous form to assist them in targeting their ad campaigns to a relevant Internet audience, and to our Website owner clients to gain an understanding of the general make-up of individuals
      who visit their site(s). Collectively, this information assists advertisers and Website owners in delivering effective ad campaigns to their audience and allows for a more relevant and meaningful ad experience for the consumer.
    </p>
    <p>
      Publicis Media's Technology may process information to target or retarget an individual across multiple devices (e.g., you may receive advertisements about automobiles on your computer because based on your browsing activity on your mobile device, you
      have been flagged as being interested in purchasing an automobile).
    </p>
  </div>

  <div class="section" id="4">
    <div class="section-header">
      Your Choices: Opting Out of Publicis Media Technology and Reviewing Your Ad Preferences
    </div>
    <p>
      Our clients and partners determine, manage and are responsible for the privacy practices and choices available to their customers related to the information they provide Publicis Media. For information that Publicis Media collects directly, Publicis Media
      provides consumers the choice to opt-out of the use of that information for interest-based online advertising. To opt-out of our tracking, please go to <a target="_blank" href="http://www.runads.com/privacy-policy">RUN's privacy policy</a>      and click the Opt-out link. This will also opt you out of our tracking.
    </p>
    <p>
      <span class="italics underline">Cookies & Tracking:</span> To learn how you may be able to reduce the number of cookies you receive from us or third parties, or delete cookies that have already been installed in your browser’s cookie folder, please
      refer to your browser’s help menu or other instructions related to your browser to see if you can reject or disable such cookies.
    </p>
    <p>
      You can also learn more about cookies by visiting: <a target="_blank" href="http://www.allaboutcookies.org">www.allaboutcookies.org</a> which includes additional useful information on cookies and how to block cookies using different types of browser.
      Removing or rejecting browser cookies does not necessarily affect third-party flash cookies used in connection with our Website. To delete or disable flash cookies, please visit: <a href="https://www.adobe.com/support/documentation/en/flashplayer/help/settings_manager.html"
        target="_blank">https://www.adobe.com/support/documentation/en/flashplayer/help/settings_manager.html</a> for more information. If you do disable or opt-out of receiving cookies, please be aware that some features and services on our Website may
      not work properly because we may not be able to recognize and associate you with your account(s). In addition, the offers we provide when you visit us may not be as relevant to you or tailored to your interests.
    </p>
    <p>
      As described above, we, or other parties we do business with, may place or recognize unique cookies or other technologies on your browser when we use our Technology or to collect information about your online activities over time and across different
      websites and apps, and may use that information to serve interest-based advertisements to you as you browse the Internet. To learn more about such interest-based advertising, and to opt-out of such collection and use for online behavioral advertising
      by Digital Advertising Alliance (DAA) participating companies in the United States, please visit: <a href="http://www.aboutads.info/choices/" target="_blank">http://www.aboutads.info/choices/</a> and DAA AppChoices: <a target="_blank" href="http://www.aboutads.info/appchoices/">http://www.aboutads.info/appchoices/</a>.
      To opt-out from the use of information about your online activities for online behavioral advertising by NAI member companies, visit: <a href="http://www.networkadvertising.org/choices/" target="_blank">http://www.networkadvertising.org/choices/</a>.
    </p>
    <p>
      Canadian users can also visit: <a href="http://www.youradchoices.ca/choices/" target="_blank">http://www.youradchoices.ca/choices/</a>.
    </p>
    <p>
      European users can also visit: <a href="http://www.youronlinechoices.eu/" target="_blank">http://www.youronlinechoices.eu/</a> to learn how to opt-out of having their data used for online behavioral advertising by European Interactive Digital Advertising
      Alliance (EDAA) member companies.
    </p>
    <p>
      Please note that even if you choose to remove your information (opt-out), you will still see advertisements while you’re browsing online, however they may be less relevant to you and your interests. Additionally, many network advertising programs allow
      you to view and manage the interest categories they have compiled from your online browsing activities. These interest categories help determine the types of targeted advertisements you may receive. Please note that if you opt-out of targeted advertising,
      we may still track your visits to the Website for our own analytics, operations and security purposes.
    </p>
    <p>
      Publicis Media also uses a DAA-approved platform to comply with the ‘Enhanced Notice' standard as dictated by the cross-industry Self-Regulation Program for Online Behavioral Advertising. As part of this service, advertisements served by Publicis Media's
      platform or other parties we do business with, are sometimes delivered with icons that serve as a signal to consumers that behavioral information has been used. Through the icon, consumers can learn how their information is being used and by what
      companies, and access tools to control the collection and use of their information.
    </p>
    <p>
      When you opt-out of receiving interest-based ads from Publicis Media, we will place a cookie in your web browser that identifies you as opted-out. Our Technology will recognize your opt-out cookie and will preclude advertisers using Publicis Media Technology
      from displaying interest-based ads to you. However, the choice to opt-out is unique to the browser in which you set it and you may need to repeat your opt-out election if you use a different computer or change browsers. Also, the opt-out cookie
      cannot be set if your browser is configured to block third-party cookies. Opting out via this method does not prevent the use of cookies or similar means that are necessary for the operation of the Website or other legitimate purposes unrelated
      to serving interest-based ads. Information may still be collected about your web browsing activities for these other purposes. While you will not receive ads based on your interests derived from your online behavior, you will still see advertising,
      including contextual ads that are based on the content of the web page that you visit.
    </p>
    <p>
      To learn how to change your Device Identifier, please refer to your device’s help menu or other instructions.
    </p>
    <p>
      Some browsers have a “Do Not Track” feature that lets you tell websites that you do not want to have your online activities tracked. These features are not yet uniform, so note that our systems are not configured to recognize Do Not Track headers or signals
      from some or all browsers.
    </p>
    <p>
      <span class="italics underline">Data Sharing and Communications:</span> You may always direct us not to share your personal information with third parties, not to use your information to provide you with information or offers, or not to send you
      newsletters, e-mails or other communications by:
    </p>
    <p>
      sending us an e-mail at <a href="mailto://productsupport@publicisgroupe.com">productsupport@publicisgroupe.com</a>;
    </p>
    <p>
      contacting us by mail at 35 West Wacker Drive, 4th Floor, Chicago, IL 60601, or
    </p>
    <p>
      following the removal instructions in the communication that you receive. Your opt-out request will be processed within 30 days of the date on which we receive it.
    </p>
    <p>
      <span class="italics underline">Your California Privacy Rights:</span> California law permits residents of California to request certain details about how their information is shared with third parties for direct marketing purposes. If you are a
      California resident and would like to make such a request, please contact us at: <a href="mailto://productsupport@publicisgroupe.com">productsupport@publicisgroupe.com</a>
    </p>
    <p>
      <span class="italics underline">Access to Your Information:</span> If you wish to verify, correct, or update any of your personal information collected through the Website, you may contact us at the above address or e-mail. The information associated
      with you that is made publically available (such as your photo or user name) depends on your login method. For example, if you do not want your photo to be made publically available in connection with your email address, you can login by phone or
      email. In accordance with our routine record keeping, we may delete certain records that contain personal information you have submitted through the Website. We are under no obligation to store such personal information indefinitely and disclaim
      any liability arising out of, or related to, the destruction of such personal information. In addition, you should be aware that it is not always possible to completely remove or delete all of your information from our databases without some residual
      data because of backups and other reasons.
    </p>
  </div>
  <div class="section" id="5">
    <div class="section-header">
      Information Sharing and Disclosures
    </div>
    <p>
      In providing its Technology services, Publicis Media works with a variety of third-party vendors and partners. These include advertisers, publishers, data providers, content providers, and other service providers. Publicis Media may share non-PII with
      them, including statistical, demographic, contextual, behavioral and predictive information, about our users and their use of the Technology, to the extent necessary for Publicis Media to provide the Technology services. We may also enhance the
      non-PII collected via our Technology with non-PII collected from trusted business partners. Publicis Media endeavors to keep such information in an anonymous non-PII format, which cannot be used to contact or identify any person individually.
    </p>
    <p>
      Publicis Media may share information about individuals as it deems reasonably necessary in order to comply with applicable law, to exercise and protect the legal rights of Publicis Media or its personnel and representatives, and to investigate, prevent
      or respond to suspected illicit activities, including fraud and threats of harm to individuals.
    </p>
    <p>
      If Publicis Media sells all or part of its business, or if any of its departments or technology is acquired or merged with another company, we may transfer information about you, including PII submitted by you on our Website, to the successor company
      as part of that transaction. To the extent consent is required under applicable law for such a data transfer, we will obtain your consent.
    </p>
  </div>
  <div class="section" id="6">
    <div class="section-header">
      Cross-Border Data Transfers
    </div>
    <p>
      Our Technology and Website is operated in accordance with the laws of the United States. Given that we are an international business, our use of your information necessarily involves the transmission of data on an international basis. If you are located
      in Mexico, the European Union, Canada or elsewhere outside of the United States, please be aware that information we collect may be transferred to and processed in the United States and other applicable territories in which the privacy laws may
      not be as comprehensive as or equivalent to those in the country where you reside and/or are a citizen.
    </p>
  </div>
  <div class="section" id="7">
    <div class="section-header">
      Security
    </div>
    <p>
      Publicis Media has established appropriate technical and organizational safeguards consistent with industry standards and applicable law to help prevent unauthorized access, loss, disclosure, modification or destruction of the information we collect.
      While we are committed to ensuring the security of such information, you should be aware that no data protection measures can provide 100% guarantee of security. Consequently, we -- like everyone else -- cannot warrant or guarantee the complete
      security of the information in our possession.
    </p>
  </div>
  <div class="section" id="8">
    <div class="section-header">
      Third-Party Advertisers and Links
    </div>
    <p>
      Publicis Media's Privacy Policy does not apply to information collected by third-party advertisers, Website owners, data providers, or other parties that we may do business with, or other parties linked to our company Website or otherwise accessible via
      our Technology. This Privacy Policy applies only to information collected through Publicis Media Technology and by this Website. The information collected or received by any third-party or provider is subject to that company's own privacy policy.
    </p>
    <p>
      This Website contains links to other sites that are not owned or controlled by Publicis Media. Please be aware that we are not responsible for the privacy practices of such third parties or the privacy policies governing their Websites.
    </p>
  </div>
  <div class="section" id="9">
    <div class="section-header">
      Children's Privacy
    </div>
    <p>
      We do not knowingly collect PII from, and do not create user segments targeted for children under 13 years of age. If you believe your child or someone else has provided Publicis Media with PII about your child and you would like to have the information
      removed, please contact us and we will use reasonable efforts to remove such information from our records.
    </p>
  </div>
  <div class="section" id="10">
    <div class="section-header">
      How to Contact Us
    </div>
    <p>
      If you have any questions or concerns about Publicis Media's Privacy Policy, you may contact us at this email address: <a href="mailto://productsupport@publicisgroupe.com">productsupport@publicisgroupe.com</a>, or you can send correspondence to
      the following address:
    </p>
    <div class="address">
      <div class="line-1">
        Publicis Media
      </div>
      <div class="line-2">
        35 West Wacker Drive, 4th Floor
      </div>
      <div class="line-3">
        Chicago, Il 60601
      </div>

    </div>
  </div>
  <div class="section" id="11">
    <div class="section-header">
      Changes to this Privacy Policy and Additional Information
    </div>
    <p>
      Publicis Media may update this Privacy Policy from time to time, and you will be informed of such changes through a notice posted on our Website. Your continued use of the Website after we make changes to this Privacy Policy is deemed acknowledgement
      of those changes, so please review this Privacy Policy periodically. You may be provided other privacy-related information in connection with your use of our Technology as well as for special features and services not described in this Privacy Policy
      that may be introduced in the future.
    </p>
    <p>
      This Privacy Policy was last updated on April 30, 2018
    </p>
  </div>

</div>
