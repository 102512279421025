import {Action} from "@ngrx/store";
import * as actions from 'app/audiences-v2/audience-v2.actions';
import {AddressableCountMetadata} from "./addressable-count-metadata.model";

export class FetchAddressableCountsMetadata implements Action {
  static readonly type = 'FETCH_ADDRESSABLE_COUNTS_METADATA';
  readonly type = FetchAddressableCountsMetadata.type;

  constructor() {}
}

export class LoadAddressableCountsMetadata implements Action {
  static readonly type = 'LOAD_ADDRESSABLE_COUNTS_METADATA';
  readonly type = LoadAddressableCountsMetadata.type;

  constructor(public addressableCountsMetadata: AddressableCountMetadata[]) {}
}

export type All = FetchAddressableCountsMetadata | LoadAddressableCountsMetadata;
