import {Subject, merge as observableMerge, BehaviorSubject} from 'rxjs';
import { map, takeUntil, debounceTime, filter, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Store, select } from "@ngrx/store";
import { map as _map, filter as _filter, get, keyBy, chain, mapValues, pick, isEqual } from 'lodash';
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { AppState } from 'app/reducers';
import { Demographic, fixCounts } from 'app/insights/insights.models';
import { INSIGHTS_CONTEXT, InsightsContextType, PercentCalculationType } from 'app/insights/insights.constants';
import { selectStandardDemographics } from 'app/insights/insights.reducer';
import * as insightsActions from 'app/insights/insights.actions'
import {InsightsCountService} from "app/insights/insights-count.service";
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';
import { createStandardDemographicsConfig } from '../insights-components.utils';
import { selectRegion } from 'app/hierarchy/hierarchy.reducers';
import { isDefined } from 'app/shared/utils/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { SegmentLike } from 'app/models/segment-like.model';
import { HierarchyRegion } from 'app/hierarchy/hierarchy.interface';
import { activePersonaHasExcludedIds } from 'app/explore/explore.reducer';
import { TOOLTIP_PERSONA_EXCLUDE_INDEX } from 'app/explore/explore.utils';

@UntilDestroy()
@Component({
  selector: 'ppc-person-level-demographics',
  templateUrl: './person-level-demographics.component.html',
  styleUrls: ['./person-level-demographics.component.sass']
})
export class PersonLevelDemographicsComponent extends InsightsResourceTracker implements OnInit, OnDestroy {
  demographics: Demographic[] = [];
  bagName: string;
  ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  selectedMekkoId: number;
  forceLayout$ = observableMerge(
    this.store.pipe(select(selectStandardDemographics(this.insightsContext))),
    this.store.select("insights", this.insightsContext, "splitScreenFocus"),
    this.counts.countsChanged$,
    this.store.select("insights", this.insightsContext, "indexMode"),
  ).pipe(debounceTime(450))
  isIndexMode: boolean;
  instructions: string;
  region: HierarchyRegion
  segments: {[identifier: string]: SegmentLike} = {};
  identifierIds: string[];
  disableShowIndex$ = this.store.select("explore").pipe(
    select(activePersonaHasExcludedIds),
    map(activePersonaHasExcludedIds => activePersonaHasExcludedIds && this.insightsContext === 'explore')
  );
  personaHasExcludedSegmentsTooltip = TOOLTIP_PERSONA_EXCLUDE_INDEX;

  constructor(public store: Store<AppState>,
    public counts: InsightsCountService,
    private segmentService: SegmentV2Service,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super(store, insightsContext);

    this.store.select('hierarchy').pipe(
      select(selectRegion), filter(isDefined), untilDestroyed(this)
    ).subscribe(region => {
      this.region = region;
    });
  }

  ngOnInit() {

    this.store.select("insights", this.insightsContext, "indexMode").pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(indexMode => this.isIndexMode = indexMode);

    this.store.select("mekkos", "selectedMekkoId").pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(selectedMekkoId => this.selectedMekkoId = selectedMekkoId)

    this.store.pipe(
      select(selectStandardDemographics(this.insightsContext)),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(demographics => {
      this.demographics = demographics;
      const ids = chain(this.demographics)
        .map(demographic => get(demographic, 'buckets'))
        .flatten()
        .map('short_id')
        .compact()
        .uniq()
        .value();
      if (ids.length && !isEqual(this.identifierIds, ids)) {
        this.identifierIds = ids;
        this.segmentService.fetchByIdentifiers(this.identifierIds).subscribe(segments => {
          const fixedSegments = fixCounts(segments, this.region);
          this.segments = keyBy(fixedSegments, 'identifier');
        })
      }
    });

    this.bagName = `person-level-widgets-standard`;
    this.instructions = "Visualize demographics based on the region's total available IDs";
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    super.ngOnDestroy();
  }

  saveDemographicConfig(demographics?: Demographic[]) {
    this.store.dispatch(new insightsActions.SaveDemographicsConfig(
      createStandardDemographicsConfig(demographics || this.demographics),
      userPreferenceKeys.standardDemographics(this.insightsContext),
      this.resourceId, this.resourceType, this.insightsContext))
  }

  hideDemographic(demographic: Demographic) {
    demographic.visible = false;
    this.saveDemographicConfig();
  }

  trackByDemographicId(index, demographic: Demographic) {
    return demographic.id
  }

  get visibleDemographics(): Demographic[] {
    return _filter(this.demographics, "visible");
  }

  toggleIndexMode() {
    this.store.dispatch(new insightsActions.ToggleIndexMode(this.insightsContext));
  }

  getSegmentCounts(demographic: Demographic) {
    const demographicIds = _map(demographic.buckets, bucket => get(bucket, 'short_id'));

    return mapValues(keyBy(demographicIds), id => {
      const segment = this.segments[id];
      return get(segment, ['count', 'matched']);
    });
  }

}
