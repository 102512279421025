
import { map, take, takeUntil} from 'rxjs/operators';
import { Component, OnChanges, Input, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { MatSnackBar } from "@angular/material/snack-bar";
import * as chroma from 'chroma-js';
import { uniq, reduce, sumBy } from 'lodash';
import { Subject } from 'rxjs';

import * as actions from 'app/insights/grow-v3/grow.actions';

import { WeboramaDiscussion, WeboramaNodes } from "app/insights/insights-components/market-level-discussions/weborama-nodes.interface";
import {fetchOutcome} from "app/shared/utils/fetch-state";
import { colorScheme } from 'app/insights/insights-components/market-level-discussions/market-level-discussions.constants'
import { selectSelectedSubMarkets } from 'app/mekko/mekko.reducer';
import { AppState } from 'app/reducers';

@Component({
  selector: 'ppc-weborama-discussion-form',
  templateUrl: './weborama-discussion-form.component.html',
  styleUrls: ['./weborama-discussion-form.component.sass']
})
export class WeboramaDiscussionFormComponent implements OnChanges, OnDestroy {
  @Input() weboramaDiscussion: WeboramaDiscussion;
  @Input() weboramaNodes: WeboramaNodes
  colors: {[clusterId: string]: string};
  totalPopulation: number;
  ngUnsubscribe = new Subject();

  constructor(private store: Store<AppState>,
    private actions$: Actions,
    private snackbar: MatSnackBar) { }

  ngOnChanges() {
    const uniqClusterIds = uniq(this.weboramaDiscussion.weborama_subclusters.map(subcluster => subcluster.cluster_id))
    const scale = chroma.scale(colorScheme).colors(uniqClusterIds.length);

    this.colors = reduce(uniqClusterIds, (colors, clusterId: string, idx) => {
      colors[clusterId] = scale[idx];
      return colors
    }, {})

    this.store.select("mekkos").pipe(
      select(selectSelectedSubMarkets),
      map(selectedSubMarkets => sumBy(selectedSubMarkets, "population")),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(totalPopulation => this.totalPopulation = totalPopulation)

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  save() {
    this.store.dispatch(new actions.SaveWeboramaDiscussion(this.weboramaDiscussion))
    this.actions$.pipe((fetchOutcome(actions.SaveWeboramaDiscussion.type)),
      take(1), )
      .subscribe(
        () => this.store.dispatch(new actions.EditWeboramaDiscussion(null)),
        () => this.snackbar.open("Something went wrong saving this discussion. Please try again in a few minutes", "OK", {
          panelClass: ["danger"],
          duration: 6000
        })
      )
  }

  cancel() {
    this.store.dispatch(new actions.EditWeboramaDiscussion(null));
  }

  getReachablePeopleForSubcluster(subcluster) {
    return this.weboramaNodes.reachable_people ? Math.round(this.weboramaNodes.reachable_people[subcluster.cluster_id] * this.totalPopulation) : 0
  }

}
