import * as actions from "./curves.actions";

export interface State {
  curveTypes: {[curveTypeName: string]: number}
}

const initialState: State = {
  curveTypes: {}
};

export function reducer(state = initialState, action: actions.All) {
  switch (action.type) {
    case actions.LoadCurveTypes.type:
      return Object.assign({}, state, {curveTypes: action.curveTypes});
    default:
      return state
  }
}

export function getCurveTypes(state: State): {[curveTypeName: string]: number} {
  return state.curveTypes
}
