<div class="ppc-dialog" [class.open]="open">
  <div class="ppc-dialog-header">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="ppc-dialog-body">
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="ppc-dialog-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>

<div class="ppc-dialog-overlay" [class.open]="open" (click)="dismissOnBlur && close()">

</div>
