import { ActiveClientGuard } from './../../guards/active-client.guard';
// NAVS
export const NAV_INSIGHTS = "insights";
export const NAV_AUDIENCES = "audiences";
export const NAV_PLANS = "plans";
export const NAV_OUTCOMES = "outcomes";
export const NAV_CREATIVES = "creative";

export const NAV_ADMIN = "admin";
export const NAV_ADMIN_GLOBAL = "global";
export const NAV_ADMIN_CLIENTS = "clients";
export const NAV_ADMIN_REGIONS = "regions";
export const NAV_ADMIN_CLIENT_MAPPINGS = "client-mappings";
export const NAV_ADMIN_BRANDS = "brands";
export const NAV_ADMIN_REPORTS = "reports";
export const NAV_ADMIN_ROLES = "roles";
export const NAV_ADMIN_API_KEYS = "api-keys";
export const NAV_ADMIN_UPLOAD = 'upload';
export const NAV_ADMIN_MEKKOS_UPLOAD = 'mekkos_upload';
export const NAV_ADMIN_SURVEYS_UPLOAD = 'surveys_upload';
export const NAV_ADMIN_DISCUSSIONS_UPLOAD = 'discussions_upload';
export const NAV_ADMIN_JOURNEYS_UPLOAD = 'journeys_upload';
export const NAV_ADMIN_PERSONAS_UPLOAD = 'personas_upload';
export const NAV_ADMIN_STATS = 'stats';

// SUB NAVS
export const INSIGHTS_SUB_NAV_EXPLORE = "explore";
export const INSIGHTS_SUB_NAV_GROW = "grow";

export const AUDIENCES_SUB_NAV_BUILD = "build";
export const AUDIENCES_SUB_NAV_OVERVIEW = "overview";
export const AUDIENCES_SUB_NAV_OVERVIEW_V2 = "overview-v2";
export const AUDIENCES_SUB_NAV_DISCOVER = "discover";
export const AUDIENCES_SUB_NAV_DISCOVER_V2 = "discover-v2";
export const AUDIENCES_SUB_NAV_BUILDER = "builder";


export const PLANS_SUB_NAV_SCENARIOS = "scenarios";
export const PLANS_SUB_NAV_CHANNELS = "channels";

// Date Format
export const MONTH_DAY_YEAR_FORMAT = "MMM DD, YYYY";
export const YEAR_MONTH_DAY_FORMAT = "YYYYMMDD";

// Asset folder for images
export const ADMIN_DASHBOARD_IMAGE_FOLDER = 'assets/images/admin/admin-dashboard';
export const ADMIN_DASHBOARD_MENU_ITEMS_IMAGE_FOLDER = 'assets/images/admin/action-menu-items';

// Other pages not in left nav
export const CUSTOMER_SUPPORT_EXTERNAL_URL = 'https://support.epsilon.com/productsupport?id=sc_cat_item&sys_id=e8c4b2351bbbe05078b04261cd4bcb56&t=sc_discovery,kb_discovery'; // 'https://jira.publicismedia.com/servicedesk/customer/portal/3/group/29';

// Generic Error message
export const GENERIC_ERROR_MESSAGE = 'Internal error occurred, please contact productsupport@publicisgroupe.com for assistance.';

// Insights demographic constants
export const TOTAL_TAG = "total";
export const DEMOGRAPHIC_MAPPED_NAMES = {
  GENDER    : "demo1",
  AGE       : "demo2",
  INCOME    : "demo3",
  CHILDREN  : "demo4",
  EDUCATION : "demo5"
};

export const ID_ANALYTICS_PATH = '/id_analytics_api';
export const CLIENTS_API_PATH = '/clients_api';
export const PLANS_API_PATH = '/plans_api';
export const INSIGHTS_API_PATH = '/insights_api';
export const SEGMENTS_API_PATH = '/segments_api';
export const ACTIVATION_API_PATH = '/activation_api';
export const AUTH_API_PATH = '/auth';

// PERMISSIONS
export const PERMISSION_INSIGHTS = 'insights';
export const PERMISSION_CREATE_AUDIENCE_SINGLE = 'create_audience_single';
export const PERMISSION_CREATE_AUDIENCE_MULTIPLE = 'create_audience_multiple';
export const PERMISSION_MODELING_UNIVERSE = 'modeling_universe';
export const PERMISSION_MODELING_SEED = 'modeling_seed';
export const PERMISSION_ACTIVATION = 'activation';
export const PERMISSION_TV_PLANNING = 'tv_planning';
export const PERMISSION_MEASUREMENT = 'measurement';
export const PERMISSION_CLIENT_SHARING = 'client_sharing';

// Url Versions
export const V6 = 'v6';
export const V5 = 'v5';
export const V4 = 'v4';
export const V3 = 'v3';
export const V2 = 'v2';
export const V1 = 'v1';

// Validation Constants
export const CONVERSANT_COMPANY_ID_MIN: number = 0;
export const CONVERSANT_COMPANY_ID_MAX: number = 2147483647;

// Error Messages
export const ERROR_MSG_CONVERSANT_COMPANY_ID_INVALID = `Id must be in between 
  ${CONVERSANT_COMPANY_ID_MIN} and ${CONVERSANT_COMPANY_ID_MAX}.`;

// Counts Query Services
export const ES = 'es';
export const MOLECULA = 'molecula';
export const PRIMARY = 'primary';
export const INDEX_VALUES = 'index_values';
