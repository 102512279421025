
import {map, filter, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import * as d3 from "d3";
import 'd3-selection-multi';
import {Subscription, Subject} from "rxjs";
import {PlanService} from "./plan.service";
import {Plan} from "./plan.model";
import {Channel} from "./channel.model";
import {HierarchyService} from "app/services/hierarchy.service";
import {SlideInOverlayComponent} from "../shared/components/slide-in-overlay/slide-in-overlay.component";
import {ScenarioDialogComponent} from "../scenario-dialog/scenario-dialog.component";
import {ChannelsService} from "./channels.service";
import {getScales, getYAxisWidth} from "../shared/utils/charts";
import {CurrencyPipe, PercentPipe} from "@angular/common";
import {CurrencyService} from "../services/currency.service";
import ColorUtil from "../shared/utils/color-util";
import {NAV_PLANS, PLANS_SUB_NAV_CHANNELS, PLANS_SUB_NAV_SCENARIOS} from "../shared/utils/constants";
import {CurveService} from "../services/curves.service";
import { compareStrings, isDefined } from 'app/shared/utils/utils';
import {HierarchyBrand} from "app/hierarchy/hierarchy.interface";
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers/index";
import {fullContext} from "app/hierarchy/hierarchy.reducers";
import {curveLabelOutput} from 'app/shared/utils/utils';
import {DonutChartData} from 'app/shared/components/ppc-donut-chart/ppc-donut-chart.component';
import { canAccessFeature } from 'app/feature-access/feature-access.reducers';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: [ './plans.component.sass' ],
})

export class PlansComponent implements OnDestroy {
  plan: Plan = new Plan({});
  filteredChannels: Array<Channel>;
  donutChartData: DonutChartData[];

  productId: string;
  brand: HierarchyBrand;
  channelNames: {name: string, id: string}[];
  channelErrorMessage: string;
  graphError: string;
  isReachCurve: boolean;

  // for scenario edit validation
  planNames: string[];

  @ViewChild('overlay', { static: true }) private overlay: SlideInOverlayComponent;
  @ViewChild('scenarioForm', { static: true }) private scenarioForm: ScenarioDialogComponent;

  hierarchySub: Subscription;
  currencySub: Subscription;
  loading: boolean = false;

  currencyCode = 'gbp'; // just a default for now
  curveOverride: boolean = false;
  curveLabelOutput = curveLabelOutput;
  canAccessChannels: boolean;
  destroyed$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hierarchyService: HierarchyService,
    private planService: PlanService,
    private channelsService: ChannelsService,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
    private currencyService: CurrencyService,
    private curveService: CurveService,
    private store: Store<AppState>
  ) {
    this.hierarchySub = this.hierarchyService.fullContext$.subscribe(
      ({ region, product, brand }) => {
        this.loading = true;
        if (this.productId && this.productId !== product.id) {
          // https://github.com/RUNDSP/people-cloud/pull/3301
          return
        }
        this.productId = product.id;
        this.brand = brand;
        this.currencySub = this.currencyService.getCurrency(region.currency)
          .subscribe(
            currency => this.currencyCode = currency.iso_code,
            console.error
          );

        this.loadChannelNames();
        this.fetchPlan();
      },
      console.error
    );

    canAccessFeature(this.store, 'channels').pipe(filter(isDefined), takeUntil(this.destroyed$))
      .subscribe(
        canAccess => this.canAccessChannels = canAccess
      );

    fullContext(this.store).pipe(takeUntil(this.destroyed$)).subscribe(
      ({client}) => this.curveOverride = client.curve_label_override
    );
  }

  ngOnDestroy() {
    this.currencySub.unsubscribe();
    this.hierarchySub.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  fetchPlan() {
    const planId = this.route.snapshot.params['id'];
    // TODO: getting all plans to check names in edit validation, should probably find a more efficient way to get names.
    this.planService.getProductPlans()
      .subscribe(
        data => {
          this.planNames = data.map(plan => plan.planName);
          const currentPlanData = data.find(plan => plan._id == planId);

          currentPlanData && this.setPlan(currentPlanData);
        }
      );
  }

  setPlan(plan) {
    this.plan = plan;

    this.curveService.getCurveTypes().subscribe(
      curveTypes => {
        this.loading = false;
        this.plan.curveTypeName = this.plan.findCurveTypeName(curveTypes);
        this.isReachCurve = this.plan.curveTypeName.includes("Reach") || this.plan.curveTypeName.includes("Penetration");

        // TODO: Delete this once reach and penetration are separate curve types PAUD-1009
        if (this.curveOverride && this.plan.channels) {
          this.plan.channels.map(ch => ch.curveType = ch.curveType.replace('Reach', 'Penetration'));
        }

        this.filteredChannels = this.plan.channels
          .filter(c => c.curveType === this.plan.curveTypeName)
          .sort((a, b) => b.allocationShare - a.allocationShare);

        this.drawGraph();
        this.donutChartData = this.filteredChannels.map((alloc, i) => {
          if (alloc.allocationShare > 0) {
            return {
              label: alloc.name,
              count: alloc.allocationShare,
              color: this.determineColor(i),
              selected: false
            }
          }
        }).filter(percent => !!percent);
      }
    );
  }

  gotoChannelPage() {
    this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_CHANNELS, this.plan.channelId]);
  }

  gotoChannelSummaryPage() {
    this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_CHANNELS]);
  }

  importChannelsSave(channelId, callback) {
    const planId = this.route.snapshot.params['id'];
    this.channelErrorMessage = null;
    this.planService.associateChannelToPlan(planId, channelId)
      .subscribe(
        success => {
          callback(true);
          this.planService.getPlan(planId)
            .subscribe(plan => this.setPlan(plan))
        },
        error => {
          this.channelErrorMessage = error.error_messages;
          // reload channel names so the errored names no longer appear
          this.loadChannelNames();
        }
      )
  }

  drawGraph() {
    const container = document.getElementById('graph-container');
    if (!container) {return; }

    if (this.filteredChannels.length < 1) {
      this.showGraphErrorMessage(container, "This plan has no allocations. There must be at least one allocation to view your plan.");
      return;
    }

    // We will build scales with min and maxes instead of the raw data because there are multiple linesPrepare the data
    const yCriteria = this.isReachCurve ? c => c.forecastSales(c.budget, true) : c => c.sales;
    let yMax = Math.max(...this.filteredChannels.map(yCriteria));
    yMax = this.isReachCurve ? (yMax < 10 ? Math.ceil(yMax) : Math.ceil(yMax / 10) * 10) : yMax;
    const xMax = Math.max(...this.filteredChannels.map(c => c.budget));

    if (xMax <= 0) {
      const xFieldDisplay = this.isReachCurve ? `#{curveLabelOutput(this.curveOverride, 'lower')} goal` : "budget";
      this.showGraphErrorMessage(container, `This plan has a ${xFieldDisplay} of 0. The ${xFieldDisplay} must be greater than 0 to view your scenario.`);
      return;
    }

    // TODO: if/when different y axis are available for the graph, the error wording will need to be updated
    if (yMax <= 0) {
      const yFieldDisplay = this.isReachCurve ? curveLabelOutput(this.curveOverride, 'lower') : "budget";
      this.showGraphErrorMessage(container, `This plan has total ${yFieldDisplay} of 0. The ${yFieldDisplay} must be greater than 0 to view your scenario.`);
      return;
    }

    // Determine width and height
    const {width} = container.getBoundingClientRect();
    const height = width * .4;

    // Clear out old drawing and add an svg container
    d3.select('#curve-graph-container *').remove();
    const svg = d3.select('#curve-graph-container')
      .append('svg')
      .attr('width', width)
      .attr('height', height + 80)
      .styles({margin: '40px 0px', overflow: 'visible'});

    // get yAxis and yScale first so that we can adjust the xAxis and xScale by the width of the yAxis
    const {yScale} = getScales([], container, {height, xMin: 0, xMax, yMin: 0, yMax});
    const tickFormatCriteria =  this.isReachCurve ?
      d => this.percentPipe.transform( d / 100 )
      : d => this.currencyPipe.transform(d, this.currencyCode, "symbol-narrow", '1.0-0');
    const yAxis = d3.axisLeft(yScale)
      .tickFormat(tickFormatCriteria)
      .ticks(5);

    const yAxisSvg = svg.append('g').call(yAxis);
    yAxisSvg.select('path').remove();
    yAxisSvg.select('.tick').remove();
    yAxisSvg.selectAll('.tick').style('stroke', '#9e9e9e');

    const yAxisWidth = getYAxisWidth(yAxisSvg);
    const gapBetweenLabelAndLine = 5;
    yAxisSvg.attr('transform', `translate(${yAxisWidth}, 0)`);
    yAxisSvg.selectAll('line')
      .attr('x2', width - yAxisWidth)
      .attr('x1', gapBetweenLabelAndLine)
      .style('opacity', .3);
    yAxisSvg.selectAll('text').attr('x', 0);

    const titleText = this.isReachCurve ? curveLabelOutput(this.curveOverride, 'upper') : "SALES";
    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -15) // x and y are reversed because of the rotation
      .attr("x", -(height / 2))
      .attr('stroke', '#9e9e9e')
      .style("text-anchor", "middle")
      .text(titleText);

    // Now do the xScale since we can account for the yAxis width
    const {xScale} = getScales([], container, {yMin: 0, yMax, xMin: 0, xMax, leftPadding: yAxisWidth + gapBetweenLabelAndLine});
    const xAxis = d3.axisBottom(xScale)
      .tickFormat(d => this.currencyPipe.transform(d, this.currencyCode, "symbol-narrow", '1.0-0') )
      .ticks(5);

    const xAxisSvg = svg.append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis);

    xAxisSvg.select('path').remove();
    xAxisSvg.select('.tick').remove();
    xAxisSvg.selectAll('.tick line').remove();
    xAxisSvg.style('stroke', '#9e9e9e');

    svg.append('text')
      .text('0')
      .style('stroke', '#9e9e9e')
      .attr('x', xScale(0) - 10)
      .attr('y', yScale(0) + 15);

    svg.append('text')
      .attr('x', xScale(xMax) / 2)
      .attr('y', height + 40)
      .text('BUDGET')
      .style('text-anchor', 'middle')
      .attr('stroke', '#9e9e9d');

    this.filteredChannels.forEach((channel, i) => {
      const xMax = channel.budget;
      const xs = d3.range(0, xMax, xMax / 100);
      const ys = xs.map(x => channel.forecastSales(x, true));
      const points = d3.zip(xs, ys);

      const area: any = d3.area()
        .curve(d3.curveBasis)
        .x(d => xScale(d[0]))
        .y0(yScale(0))
        .y1(d => yScale(d[1]));

      svg.append('path')
        .attr('d', area(points))
        .style('stroke', 'none')
        .style('fill', this.determineColor(i))
        .style('opacity', 0.3);

      const line: any = d3.line()
        .curve(d3.curveLinear)
        .x(d => xScale(d[0]))
        .y(d => yScale(d[1]));

      if (points.length > 0) { points.push([points[points.length - 1][0], 0], [0, 0]); }

      svg.append('path')
        .attr('d', line(points))
        .style('fill', 'none')
        .style('stroke', this.determineColor(i))
        .style('stroke-width', 2);
    });
  }

  showGraphErrorMessage(graphContainer: HTMLElement, errorMessage: string): void {
    this.graphError = errorMessage;
  }

  redirectToScenariosSummary() {
    this.router.navigate([this.hierarchyService.getHierarchySlugs(), NAV_PLANS, PLANS_SUB_NAV_SCENARIOS]);
  }

  get isTRP(): boolean {
    return this.plan.curveTypeName.toLowerCase().indexOf('trp') > -1;
  }

  /** Edit scenario form**/

  openEditScenarioDialog() {
    this.scenarioForm.setPlan(this.plan);
    this.overlay.toggleState();
  }

  onFormClose() {
    this.overlay.toggleState();
  }

  private loadChannelNames() {
    const nameSort = (a, b) => a.name.localeCompare(b.name);

    this.channelsService.getUnassignedChannelNames().subscribe(
      channelsNames => this.channelNames = channelsNames.filter(channelName => channelName.name != void(0)).sort(nameSort)
    );
  }

  determineColor(index: number): string {
    return ColorUtil.getMediaTypeColor(index);
  }

}
