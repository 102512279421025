export class Partner {
  name: string;
  weight: number;
  id: string;
  logo_url: string;
  destination_status_color: string;
  number_of_destinations: string;
  destination_count: number;
  removed: boolean;
}

export function isTradeDesk(partner: Partner): boolean {
  return partner && partner.name === 'The Trade Desk'
}

export function isDV360(partner: Partner): boolean {
  return partner && partner.name.includes('DV360')
}
