<div class="snapshot-detail-header">
  <div class="snapshot-detail-header-instructions">Explore recommended segments.</div>
  <div class="snapshot-detail-header-toggle">
    <ppc-slide-toggle [(ngModel)]="showFeatureImportance">
      <span left>Overview</span>
      <span right>Detail</span>
    </ppc-slide-toggle>
  </div>
</div>

<ppc-propensity-overview *ngIf="!showFeatureImportance"></ppc-propensity-overview>
<ppc-feature-importance *ngIf="showFeatureImportance"></ppc-feature-importance>
