<div class="box component" [class.expando]="!collapsed">
  <div class="box-bg-wrap" (click)="toggleMetaInfo($event)">
    <img class="box-bg" [src]="'assets/images/toolbox/'+unit.image_slug+'.png'" [alt]="unit.name">
  </div>
  <div class="box-inner">
    <div class="box-meta" *ngIf="unit.name">
      <p [innerHTML]="renderHtml(unit.name)"></p>
      <p *ngIf="editMode && collapsed">
        <app-confirmation
          class="pointer"
          isIcon="true"
          buttonContinueLabel="HIDE"
          buttonClass="fa fa-eye-slash muted white"
          modalTitle="Are you sure you want to hide this?"
          [kontinue]="hideUnit.bind(this, unit)"
        ></app-confirmation>
        &nbsp;
        <app-unit-dialog
          class="pointer"
          (modalMode)="passModalState($event)"
          [mode]="'edit'"
          [solo]="true"
          [unit]="unit"
          [category]="category"
          ></app-unit-dialog>
      </p>
    </div>
  </div>
  <div #meta class="box-info" [style.left]="leftCalc(unitIndex)">
    <div class="heading">
      <h3
        class="has-text-accent-color"
        *ngIf="unit.name"
        [innerHTML]="renderHtml(unit.name)"
        >
      </h3>
      <i class="fa fa-times" (click)="toggleMetaInfo($event)"></i>
    </div>
    <div class="split">
      <p
        *ngIf="unit.description"
        [innerHTML]="renderHtml(unit.description)"
        >
      </p>
      <div class="button-row">
        <a
          *ngIf="unit.url && !unit.iframed"
          class="action-btn"
          mat-button
          [href]="unit.url"
          target="_blank"
          >Launch</a>
        <a
          *ngIf="unit.doc_url"
          class="action-btn mute"
          mat-button
          [href]="unit.doc_url"
          target="_blank"
          >Documentation</a>
      </div>
    </div>
  </div>
</div>
