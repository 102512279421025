import { Component } from '@angular/core';

@Component({
  selector: 'ppc-person-level-survey',
  templateUrl: './person-level-survey.component.html',
  styleUrls: ['./person-level-survey.component.sass']
})
export class PersonLevelSurveyComponent {

  constructor() {
  }

}
