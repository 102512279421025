import { Action } from "@ngrx/store";
import { Journey, JourneyTabType, JourneyStage } from "./journey.models";

export class FetchJourneys implements Action {
  static readonly type = "JOURNEY:FETCH_JOURNEYS";
  readonly type = FetchJourneys.type;
}

export class FetchDefaultJourney implements Action {
  static readonly type = "JOURNEY:FETCH_DEFAULT_JOURNEY";
  readonly type = FetchDefaultJourney.type;
}

export class LoadJourneys implements Action {
  static readonly type = "JOURNEY:LOAD_JOURNEYS";
  readonly type = LoadJourneys.type;

  constructor(public journeys: Journey[]) { }
}

export class LoadJourney implements Action {
  static readonly type = "JOURNEY:LOAD_JOURNEY";
  readonly type = LoadJourney.type;

  constructor(public journey: Journey) { }
}

export class LoadDefaultJourney implements Action {
  static readonly type = "JOURNEY:LOAD_DEFAULT_JOURNEY";
  readonly type = LoadDefaultJourney.type;

  constructor(public journeyId: number) { }
}

export class SaveJourney implements Action {
  static readonly type = "JOURNEY:SAVE_JOURNEY";
  readonly type = SaveJourney.type;

  constructor(public journey: Journey) { }
}

export class SaveDefaultJourney implements Action {
  static readonly type = "JOURNEY:SAVE_DEFAULT_JOURNEY";
  readonly type = SaveDefaultJourney.type;

  constructor(public journeyId: number) { }
}

export class DestroyJourney implements Action {
  static readonly type = "JOURNEY:DESTROY_JOURNEY";
  readonly type = DestroyJourney.type;

  constructor(public journeyId: number) { }
}

export class RemoveJourney implements Action {
  static readonly type = "JOURNEY:REMOVE_JOURNEY";
  readonly type = RemoveJourney.type;

  constructor(public journeyId: number) { }
}

export class SetSelectedJourneyId implements Action {
  static readonly type = "JOURNEY:SET_SELECTED_JOURNEY_ID";
  readonly type = SetSelectedJourneyId.type;

  constructor(public journeyId: number) { }
}

export class SetSelectedBrandId implements Action {
  static readonly type = "JOURNEY:SET_SELECTED_BRAND_ID";
  readonly type = SetSelectedBrandId.type;

  constructor(public brandId: number) { }
}

export class SetJourneyTab implements Action {
  static readonly type = "JOURNEY:SET_JOURNEY_TAB";
  readonly type = SetJourneyTab.type;

  constructor(public tab: JourneyTabType) { }
}

export class DuplicateJourney implements Action {
  static readonly type = "JOURNEY:DUPLICATE_JOURNEY";
  readonly type = DuplicateJourney.type;

  constructor(public journeyId: number) { }
}

export class ToggleStage implements Action {
  static readonly type = "JOURNEY:TOGGLE_STAGE";
  readonly type = ToggleStage.type;

  constructor(public stage: JourneyStage) { }
}

export class ClearSelectedStages implements Action {
  static readonly type = "JOURNEY:CLEAR_SELECTED_STAGES";
  readonly type = ClearSelectedStages.type;

}

export type ReducerActions = LoadJourneys | LoadJourney | SetSelectedJourneyId |
RemoveJourney | SetJourneyTab | SetSelectedBrandId |
ToggleStage | ClearSelectedStages | LoadDefaultJourney;
