
import {VendorPermission} from "../vendors/vendor-permission.model";
import {PermissionOption} from "./models/permission-option.model";
import {Vendor} from "../vendors/vendor";
import {ClientRegionRules} from "./models/client-region-rules.model";

export interface RulesRowContext {
  source: Vendor;
  rows: ClientRegionRules[];
  defaultRow: ClientRegionRules;
  options: PermissionOption[];
}

/*
provides data transformation tasks for segment permission admin page
 */
export class SegmentPermissionsAdminService {

  static makePermissionOptions(options: PermissionOption[], permissions?: VendorPermission[], source_id?: string, optionState?: string): PermissionOption[] {
    const permissionsMap = {};
    permissions && permissions.forEach( permission => {
      permissionsMap[permission.permission.concat(permission.vendor_id)] = permission;
    });

    return options.map(option => {
      // default permission value (for option that has no value)
      let matchingPermission = new PermissionOption(option);
      matchingPermission.value = optionState;

      const foundPermission = permissionsMap[option.permission.concat(source_id)];
      if (foundPermission) {
        matchingPermission = new PermissionOption(option);
        matchingPermission.value = foundPermission.state;
      }

      return matchingPermission
    });
  }

  static makeSourceRuleRows(source: Vendor, ruleOptions: PermissionOption[], permissions?: VendorPermission[] ): ClientRegionRules[] {
    if (permissions) {
      const clientRegionPermissions = this.groupPermissionsByClientRegion(permissions);

      const clientRegionCombos = Object.keys(clientRegionPermissions);
      return clientRegionCombos.map(key => {
        const groupedPermissions = clientRegionPermissions[key];

        const permissionSelections = this.makePermissionOptions(ruleOptions, groupedPermissions.permissions, source.id);
        const client = groupedPermissions.client || ClientRegionRules.ALL;
        const region = groupedPermissions.region || ClientRegionRules.ALL;

        return new ClientRegionRules(permissionSelections, client, region);
      })
    } else {
      const defaultPermissions = this.makePermissionOptions(ruleOptions, [], source.id);
      return [new ClientRegionRules(defaultPermissions, ClientRegionRules.ALL, ClientRegionRules.ALL)]
    }
  }

  private static groupPermissionsByClientRegion(permissions: VendorPermission[]): { [clientRegionKey: string]: {client: string, region: string, permissions: VendorPermission[]} } {
    const clientRegionPermissions = {};

    permissions.forEach((p: VendorPermission) => {
      const clientRegionKey = `${p.client_id} ${p.region_id}`;
      const clientRegionPermission = clientRegionPermissions[clientRegionKey];
      if (clientRegionPermission != void(0)) {
        clientRegionPermission.permissions.push(p)
      } else {
        clientRegionPermissions[clientRegionKey] = {client: p.client_id, region: p.region_id, permissions: [p]};
      }
    });
    return clientRegionPermissions;
  }

  static makecontext(source: Vendor, rows: ClientRegionRules[], options: PermissionOption[]): RulesRowContext {
    let defaultRule: ClientRegionRules = new ClientRegionRules(
      this.makePermissionOptions(options, [], source.id, VendorPermission.DENY),
      ClientRegionRules.ALL,
      ClientRegionRules.ALL
    );
    const nonDefault = rows;
    const defaultIdx = rows.findIndex(r => ClientRegionRules.isDefault(r.client, r.region));
    if (defaultIdx > -1 ) {
      defaultRule = rows[defaultIdx];
      defaultRule.persisted = true;
      nonDefault.splice(defaultIdx, 1);
    }
    return {source: source, rows: nonDefault, defaultRow: defaultRule, options: options}
  }

  static rowToPermissions(row: ClientRegionRules, source_id: string): VendorPermission[] {
    return row.permissions.map( permission => {
      return new VendorPermission({
        vendor_id: source_id,
        client_id: this.notAllOrNull(row.client),
        region_id: this.notAllOrNull(row.region),
        permission: permission.permission,
        state: permission.value
      })
    })
  }

  private static notAllOrNull(clientOrRegion: string): string {
    return clientOrRegion == ClientRegionRules.ALL ? null : clientOrRegion;
  }



}
