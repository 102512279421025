import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { Filter } from 'app/insights/insights.models';

const FILTER_BAR_HEIGHT = 54;

@Component({
  selector: 'ppc-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.sass']
})
export class FilterBarComponent implements OnInit, AfterViewChecked {
  @Input() filters: Filter[];
  @Input() allowToggle = true;
  @Output() clearFilters = new EventEmitter<null>();
  @Output() toggleFilter = new EventEmitter<Filter>();
  @ViewChild("bar", { static: true }) barElement: ElementRef;
  expanded = false;
  hasHiddenFilters = false;

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() { }

  ngAfterViewChecked() {
    // If the user removes a filter and the filter-bar no longer wraps
    // then we need to set the state to collapsed
    if (this.barElement.nativeElement.scrollHeight === FILTER_BAR_HEIGHT) {
      this.expanded = false;
    }
    this.hasHiddenFilters = this.barElement.nativeElement.scrollHeight - this.barElement.nativeElement.clientHeight > 2;
    this.changeDetector.detectChanges();

  }

  trackByFilterId(filter: Filter) {
    return `${filter.type}-${filter.type === "audience" ? filter.subMarketId : filter.shortId}`
  }

  getName(filter) {
    return filter.name || filter.truncatedDisplay || filter.pathParts.join(" > ")
  }

  getTooltip(filter) {
    if (!filter.pathParts) { return; }

    return filter.pathParts.join(" > ");
  }

}
