
import {combineLatest} from 'rxjs/operators';
import {Component, Input} from "@angular/core";
import {Subscription} from "rxjs";

import {Plan, GENERIC_MEDIA_TYPES_NAMES} from '../../plans/plan.model';
import {HierarchyService} from "../../services/hierarchy.service";
import {CurveService} from "../../services/curves.service";
import {Curve} from "./curve";
import { MatSnackBar } from "@angular/material/snack-bar";
import {ScenarioImportService} from "./import-scenario-plan/scenario-import-service";
import {PlanImportStatus} from "./import-scenario-plan/plan-import-status";
import {sortBy} from "app/shared/utils/utils";
import { values, compact } from "lodash";

@Component({
  selector: 'app-plans-admin',
  templateUrl: './plans-admin.component.html',
  styleUrls: ['./plans-admin.component.sass']
})
export class PlansAdminComponent {
  @Input() plan: Plan;
  @Input() scenarioPlannerId: string;

  mediaTypes = GENERIC_MEDIA_TYPES_NAMES;
  mediaTypesSelections = this.mediaTypes.map( mt => {return {selected: false, name: mt} });
  useGenericCurves: boolean;
  uploadCurves: boolean;
  genericCurves: Curve[];
  curves: Curve[] = [];
  hierarchySub: Subscription;
  curveService: CurveService;
  errors = {};
  curveErrors: { [key: string]: string } = {};
  curveTypeNames: string[] = [];
  productId: string;
  loading: boolean = false;
  getGenericCurvesSpin: boolean = false;
  getCurvesSpin: boolean = false;
  getCurveTypesSpin: boolean = false;
  setCurvesSpin: boolean = false;
  errorList: string[] = [];
  // imports
  importedPlans: PlanImportStatus[] = [];

  constructor(
    hierarchyService: HierarchyService,
    curveService: CurveService,
    public importsService: ScenarioImportService,
    public snackbar: MatSnackBar
  ) {
    this.curveService = curveService;
    this.hierarchySub = hierarchyService.fullContext$.subscribe(
      context => this.onHierarchyUpdate(context.product.id)
    );
  }

  get showErrorBox(): boolean {
    return this.errorMessages.length > 0
  }

  get willOverrideUploaded(): boolean {
    return this.useGenericCurves && !this.hasGenericCurves;
  }

  get willOverrideGeneric(): boolean {
    return this.uploadCurves && this.hasGenericCurves;
  }

  get hasGenericCurves(): boolean {
    return this.curves.some( curve => curve.is_generic );
  }

  get nonGenericCurves(): Curve[] {
    return this.curves.filter( c => !c.is_generic);
  }

  get errorMessages(): string[] {
    return compact(this.errorList.concat(values(this.errors)));
  }

  get readyToApply(): boolean {
    return this.uploadCurves ? this.nonGenericCurves.length > 0 && this.nonGenericCurves.every(curve => this.validateCurve(curve))
      : this.mediaTypesSelections.some(selection => selection.selected)
  }

  showSpinner(func, tempLoader) {
    this[tempLoader] = true;
    const onComplete = () => {
      this[tempLoader] = false
      this.loading = (this.getGenericCurvesSpin || this.getCurvesSpin || this.getCurveTypesSpin || this.setCurvesSpin);
    }
    func(onComplete);
  }

  onHierarchyUpdate(productId: string) {
    if (this.productId && this.productId !== productId) {
      this.closeErrorBox();
    }
    this.productId = productId;
    this.showSpinner(onComplete => {
      this.curveService.getGenericCurves().subscribe(
        curves => {
          this.genericCurves = curves;
          this.errors["generic_curves"] = null;
        },
        error => this.errors["generic_curves"] = error,
        onComplete
      )}, "getGenericCurvesSpin");
    this.showSpinner(onComplete => {
      this.curveService.getCurves().subscribe(
        (curves) => {
          this.setCurves(curves);
          this.errors["curves"] = null;
        },
        error => this.errors["curves"] = error,
        onComplete
      )}, "getCurvesSpin");
    this.showSpinner(onComplete => {
      this.curveService.getCurveTypes().subscribe(
        curveTypes => {
          this.curveTypeNames = Object.keys(curveTypes);
          this.errors["curveTypes"] = null;
        },
        error => this.errors["curveTypes"] = error,
        onComplete
      )}, "getCurveTypesSpin");
    this.importsService.getImports(productId).subscribe(
      imports => this.importedPlans = sortBy(imports, 'created_at', false)
    )
  }

  toggleCheckboxes(selectedCurve: string, otherCurve: string ) {
    this[otherCurve] = this[otherCurve] && !this[selectedCurve];
  }

  deleteCurve(curveIdx: number) {
    const curve = this.curves[curveIdx];
    if (curve.id) {
      this.curveService.destroyCurve(curve).subscribe(
        _ => {
          this.curves.splice(curveIdx, 1);
          this.openSnackbar("Curve deleted");
        },
        error => {
          curve.error_messages.push(error);
          this.errorList.push("Something went wrong");
        }
      )
    } else {
      this.curves.splice(curveIdx, 1)
    }
  }

  onApply() {
    let uploadCurves = [];
    if (this.useGenericCurves) {
      this.mediaTypesSelections.filter( mts => mts.selected )
        .forEach( selectedMediaType => {
          const genericCurves = this.genericCurves.filter( curve => curve.media_type === selectedMediaType.name );
          uploadCurves.push(...genericCurves);
        })
    } else {
      uploadCurves = this.nonGenericCurves;
    }
    this.showSpinner(onComplete => {
      this.curveService.createCurvesForProduct(uploadCurves).subscribe(
        curves => {
          this.setCurves(curves.curves);
          this.curveErrors = curves.error_messages;
          this.errors["create_curves"] = null;
          const allErrors = Object.keys(this.curveErrors).reduce( (result, e) => result.concat(this.curveErrors[e]), [] );
          if ( allErrors.length < 1) {
            this.openSnackbar("Curves saved!");
          } else {
            this.errorList.push("Some curves could not be saved");
          }
        },
        error => {
          this.errors["create_curves"] = error;
          this.snackbar.open("Something went wrong");
        },
        onComplete
      )}, "setCurvesSpin");
  }

  planImport(sp_id: string) {
    const importedPlan = {scenario_planner_id: sp_id};
    this.importedPlans.unshift(importedPlan);
    this.importsService.importPlan(importedPlan.scenario_planner_id)
      .subscribe(
        planImport => {
          this.importedPlans[this.findSubmittedPlanIndex(sp_id)] = planImport;
          if (planImport.message) {
            const splitMessage = JSON.parse(planImport.message);
            this.errorList = splitMessage;
          }
        },
        (failure) => {
          if (failure.status) {
            this.errorList = [failure.message, failure.error];
          } else {
            this.errorList = ['Network Error. Try Again Later'];
          }
        }
      )
  }

  planImportDelete(import_id: string) {
    const planIdx = this.importedPlans.findIndex(p => p.id == import_id);
    const plan = this.importedPlans[planIdx];
    if (plan.import_success) {
      // this plan is persisted
      this.importsService.deleteImport(this.productId, plan.id).subscribe(
        _ => this.importedPlans.splice(planIdx, 1)
      );
    } else {
      this.importedPlans.splice(planIdx, 1)
    }
  }

  setCurves(curves: Curve[]) {
    this.curves = curves;
    const genericCurveMediaTypes = curves.filter( curve => curve.is_generic).map( curve => curve.media_type );
    this.mediaTypesSelections.forEach( mediaType => mediaType.selected = genericCurveMediaTypes.some( mt => mt === mediaType.name) );

    if (this.curves && this.curves.length > 0) {
      this.useGenericCurves = this.hasGenericCurves;
      this.uploadCurves = !this.hasGenericCurves;
    }
  }

  closeErrorBox() {
    this.errorList = [];
    this.errors = [];
  }

  private validateCurve(curve: Curve): boolean {
    return curve.isValid &&
      values(curve.params).every(param => !isNaN(param))
  }

  private openSnackbar(message: string) {
    this.snackbar.open(message, "OK", {duration: 3000});
  }

  private findSubmittedPlanIndex(sp_id: string): number {
    return this.importedPlans.findIndex(plan => {
      return (plan.scenario_planner_id == sp_id) && plan.id == void(0)
    });
  }

}
