import { Component } from '@angular/core';

import {Store} from "@ngrx/store";
import { AppState } from "app/reducers"
import { select } from '@ngrx/store';
import { MatDialog } from "@angular/material/dialog";

import { selectActiveSnapshot } from 'app/snapshots/snapshots.reducer';
import {PpcCarouselComponent} from "app/shared/components/ppc-carousel/ppc-carousel.component";
import {propensitySlides} from "app/insights/grow-v3/grow-v3.constants";
import * as actions from "app/snapshots/snapshots.actions";

@Component({
  selector: 'ppc-predictions-level-propensity',
  templateUrl: './predictions-level-propensity.component.html',
  styleUrls: ['./predictions-level-propensity.component.sass']
})

export class PredictionsLevelPropensityComponent {

  activeSnapshot$ = this.store.select("snapshots").pipe(select(selectActiveSnapshot));
  canCreateSnapshot$ = this.store.select('permissions', 'caas', 'create');

  constructor(private store: Store<AppState>, private matDialog: MatDialog) { }

  openCarousel() {
    this.matDialog.open(PpcCarouselComponent, {data: propensitySlides, panelClass: "no-padding"})
  }

  goToCreateNewModel() {
    this.store.dispatch(new actions.ClearActiveSnapShot());
  }
}
