import {Currency} from "../services/currency.service";
import {Action} from "@ngrx/store";

export class FetchCurrencies implements Action {
  static readonly type = 'FETCH_CURRENCIES';
  readonly type = FetchCurrencies.type;
}

export class LoadCurrencies implements Action {
  static readonly type = 'LOAD_CURRENCIES';
  readonly type = LoadCurrencies.type;

  constructor(public currencies: Currency[]) {}
}

export type AllActions = LoadCurrencies
