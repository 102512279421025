<div class="widget">
  <div class="widget-header">
    <i class="fa fa-bars drag-handle"></i>
    <span class="widget-name"
      *ppcCheckOverflow="let hasOverflowedName=hasXOverflow"
      [ppcTooltip]="demographic?.name"
      [tooltipDisabled]="!hasOverflowedName"
      >
      {{demographic?.name}}
    </span>
    <i *ngIf="demographic?.description" class="fa fa-info-circle menu-icon hoverable" [ppcTooltip]="demographic.description"></i>
    <span class="menu-icon">
      <ppc-sort *ngIf="displaySortIcons || (indexMode$ | async)"
                [sortData]="demographicWithPercent"
                [preservedSortDirection]="sortDirection"
                (emitSortDirection)="setSortDirection($event)">
      </ppc-sort>
      <button class="fa fa-ellipsis-v hoverable" mat-icon-button [matMenuTriggerFor]="appMenu"></button>
    </span>
    <mat-menu #appMenu="matMenu">
      <button (click)="editDemo()" *ngIf="demographicsPermission?.update" mat-menu-item>
        Edit
        <img class="warning-icon" [class.hide]="!hasZeroCountId(demographic)" src="assets/icons/warning-orange.svg" />
      </button>
      <button (click)="hideDemo()" mat-menu-item>Hide</button>
      <button ppcConfirmation *ngIf="demographic && !demographic.is_standard && demographicsPermission?.destroy" [kontinue]="destroyDemo.bind(this)" mat-menu-item>
        Delete
      </button>
    </mat-menu>
  </div>

  <ppc-grow-v3-donut-chart
    *ngIf="demographic?.widget_type == chartTypes.Doughnut &&
      !(indexMode$ | async) &&
      percentCalculationType !== 'selected'"
    [demographic]="demographic"
    [segmentCounts]="segmentCounts"
    ></ppc-grow-v3-donut-chart>

  <ppc-grow-v3-bar-chart
    class="horizontal"
    *ngIf="demographic?.widget_type == chartTypes.BarHorizontal ||
      (indexMode$ | async) ||
      percentCalculationType == 'selected'"
    [demographic]="demographic"
    [tab]="tab"
    [segmentCounts]="segmentCounts"
    (demographicWithPercentEvent)="setDemographicWithPercent($event)"
    [sortDirection]="sortDirection"
    ></ppc-grow-v3-bar-chart>

  <ppc-grow-v3-bar-chart
    class="vertical"
    *ngIf="demographic?.widget_type == chartTypes.BarVertical &&
      !(indexMode$ | async) &&
      percentCalculationType !== 'selected'"
    [demographic]="demographic"
    [tab]="tab"
    [segmentCounts]="segmentCounts"
    ></ppc-grow-v3-bar-chart>

    <div  *ngIf="(radarChartAccess | hasPermission | async) && demographic?.widget_type == chartTypes.Radar ">

      <p> This is radar chart place holder </p>
    </div>
</div>
