import * as actions from './permissions.actions'
import {map, get} from 'lodash'
import {AuthPermission, FullPermissions} from "app/shared/interfaces/auth-permission";
import { Store } from '@ngrx/store'
import { AppState } from 'app/reducers'
import { getStoreState } from 'app/shared/utils/utils'

export interface State {
  [name: string]: AuthPermission
}

export const initialState: State = {};

export function reducer(state = initialState, action: actions.All): State {
  switch (action.type) {
    case actions.LoadPermissions.type:
      return Object.assign({}, state, action.permissions);
    default:
      return state
  }
}

export type PermissionString = 'C' | 'R' | 'U' | 'D'
| 'CR' | 'CU' | 'CD' | 'RU' | 'RD' | 'UD'
| 'CRU' | 'CRD' | 'CUD' | 'RUD'
| 'CRUD';

export interface RequiredPermissions {
  [resourceName: string]: PermissionString;
}

export function checkPermissions(permissions: FullPermissions,
  requiredPermissions: RequiredPermissions,
  isAdmin: boolean): boolean {
  return isAdmin || map(requiredPermissions, (permissionString: PermissionString, resourceName: string) =>
    permissions[resourceName] && checkPermission(permissionString, permissions[resourceName])
  ).every(Boolean)
}

const permissionStringTranslations = {
  C: 'create',
  R: 'read',
  U: 'update',
  D: 'destroy'
};

function checkPermission(permissionString: PermissionString, permission: AuthPermission): boolean {
  return permissionString.split('').every(char => permission[permissionStringTranslations[char]])
}

export function userCan(action: 'create' | 'read' | 'update' | 'destroy',
  resource: string,
  store: Store<AppState>): boolean {
  return get(getStoreState(store), ['permissions', resource, action], false)
}
