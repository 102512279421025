import { map } from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {PpcHttpService} from "app/services/ppc_http.service";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {Role} from "app/admin/role-admin/role.interface";
import {Observable} from "rxjs";
import {
  FetchRoles,
  LoadRoles,
  UpdateRole,
  FetchUpdateRole,
  DestroyRole, CreateRole
} from "app/admin/role-admin/service/user-role.actions";
import {fetchResource} from "app/shared/utils/fetch-state";
import { rolesUrl, roleUrl } from 'app/shared/constants/auth.urls';

@Injectable()
export class UserRoleService {

  constructor(private actions$: Actions, private http: PpcHttpService) { }

  @Effect()
  fetchRoles$ = this.actions$.pipe(
    ofType(FetchRoles.type),
    (fetchResource(
      () => this.getRoles().pipe(map(roles => new LoadRoles(roles)))
    )));

  @Effect()
  fetchUpdateRole$ = this.actions$.pipe(
    ofType(FetchUpdateRole.type),
    (fetchResource(
      action => this.updateRole(action.role).pipe(map(role => new UpdateRole(role)))
    )));

  @Effect()
  fetchCreateRole$ = this.actions$.pipe(
    ofType(CreateRole.type),
    (fetchResource(
      action => this.createRole(action.role).pipe(map(roles => new LoadRoles(roles)))
    )))

  @Effect()
  fetchDestroyRole$ = this.actions$.pipe(
    ofType(DestroyRole.type),
    (fetchResource(
      action => this.destroyRole(action.role).pipe(map(roles => new LoadRoles(roles)))
    )));

  getRoles(): Observable<Role[]> {
    return this.http.get(rolesUrl());
  }

  updateRole(role: Role): Observable<Role> {
    return this.http.put(roleUrl(role.id), {role: role});
  }

  createRole(role: Role): Observable<Role[]> {
    return this.http.post(rolesUrl(), {role: role});
  }

  destroyRole(role: Role): Observable<Role[]> {
    return this.http.delete(roleUrl(role.id));
  }


}
