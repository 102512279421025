import { Unit } from './../unit.interface';

export class CustomUnit implements Unit {
  id: number;
  name: string;
  status: boolean = false;
  priority: number;
  description: string = '';
  category_id: number;
  image_slug: string;
  meta: string;
  url: string;
  doc_url: string;
  custom: boolean = true;
  client_id: number;
  client_slug: string;
  unit_type_id: number;
  region_ids: string[];
  feature_module_category: string;
  iframed: boolean;
  use_client_hierarchy: boolean;
  show_in_applications: boolean;

  constructor(
    categoryId: number,
    clientId: number,
    unitTypeId: number,
  ) {
    this.id = null;
    this.name = '';
    this.priority = 0;
    this.image_slug = 'custom';
    this.category_id = categoryId;
    this.client_id = clientId;
    this.unit_type_id = unitTypeId;
    this.region_ids = ['all'];
    this.feature_module_category = '';
    this.iframed = false;
    this.use_client_hierarchy = false;
    this.show_in_applications = true;
  }
}
export interface UnitIcon {
  display: string;
  value: string;
}

export const UNIT_ICONS: UnitIcon[] = [
  {
    display: 'Circle Grid',
    value: 'custom'
  },
  {
    display: 'Concentric',
    value: 'concentric'
  },
  {
    display: 'Vortex',
    value: 'dial'
  },
  {
    display: 'Star',
    value: 'star'
  },
  {
    display: 'Mobile Data',
    value: 'mobile-data'
  },
  {
    display: 'Aura',
    value: 'aura'
  }
]
