
import {throwError as observableThrowError,  Observable } from 'rxjs';
import {HttpParams} from "@angular/common/http";
import {reduce} from 'lodash';

export function objToHttpParams(params): HttpParams {
  // Have to append `[]` to the key of array params to indicate that rails should treat it as an array
  return reduce(params, (acc, v, k) => Array.isArray(v) ? reduce(v, (acc, v) => acc.append(`${k}[]`, v), acc)
    : v === null ? acc // Don't append null-valued params
      : acc.append(k, v), new HttpParams())
}

export function handleError (error: any) {
  let errMsg: string;
  const body = error || '';
  const err = body.error || JSON.stringify(body);
  errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
  console.error(errMsg);
  return observableThrowError(errMsg);
}
