import { Component, Input, OnDestroy, OnChanges, Inject, SimpleChanges } from '@angular/core';
import { sumBy } from 'lodash';
import * as chroma from 'chroma-js';
import { Subject, combineLatest as observableCombineLatest } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { find, get } from 'lodash';
import { Demographic, Bucket, Filter, toMillions, Tab } from 'app/insights/insights.models';
import { widgetColors } from 'app/insights/grow-v3/shared/widget-colors.constants';
import { DonutChartData } from 'app/shared/components/ppc-donut-chart/ppc-donut-chart.component';
import { takeUntil, map } from 'rxjs/operators';
import { AppState } from 'app/reducers';
import * as insightsActions from 'app/insights/insights.actions';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { InsightsCountService } from "app/insights/insights-count.service";
import { environment } from 'environments/environment';

@Component({
  selector: 'ppc-grow-v3-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.sass']
})
export class DonutChartComponent implements OnDestroy, OnChanges {
  @Input() demographic: Demographic;
  @Input() tab: Tab;
  @Input() segmentCounts: {[identifer: string]: number} = {};
  donutChartData: DonutChartData[];
  ngUnsubscribe = new Subject();
  inputsChanged$ = new Subject();
  jumbo$ = this.store.select("grow", "growV3Focus").pipe(map(focus => focus == "insights"))
  isTier3 = environment.isTier3;

  constructor(private counts: InsightsCountService, private store: Store<AppState>, @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {

    observableCombineLatest(
      this.counts.countsChanged$,
      this.inputsChanged$
    ).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      const colorScheme = chroma.scale(widgetColors).colors(this.demographic.buckets.length);

      this.donutChartData = this.demographic.buckets.map((bucket, idx) => {
        return {
          label: bucket.name,
          count: this.counts.getSegmentCount(bucket.short_id),
          color: colorScheme[idx],
          selected: this.counts.hasFilter(this.toFilter(bucket)),
          id: bucket.id,
          short_id: bucket.short_id,
          countLabel: toMillions(this.counts.getSegmentCount(bucket.short_id))
        }
      })
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.demographic && changes.demographic.currentValue != changes.demographic.previousValue) {
      this.inputsChanged$.next();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toFilter(bucket: Bucket): Filter {
    return {
      shortId: bucket.short_id,
      pathParts: [this.demographic.name, bucket.name],
      type: "demographic-segment",
      demographicId: this.demographic.id,
      tab: this.tab ? this.tab.name : "Demographics"
    }
  }

  getPercent(bucket: Bucket) {
    const total = sumBy(this.demographic.buckets, (bucket: Bucket) => this.counts.getSegmentCount(bucket.short_id));
    if (total == 0) {return 0; }
    return this.counts.getSegmentCount(bucket.short_id) / total * 100;
  }

  toggleFilter(data: DonutChartData) {
    const bucket = find(this.demographic.buckets, { name: data.label });
    this.store.dispatch(new insightsActions.ToggleFilter(this.toFilter(bucket), this.insightsContext))
  }

  get hasData() {
    if (!this.donutChartData) {return false; }
    return this.donutChartData.some(d => d.count > 0);
  }

  get isIdCount() {
    return this.demographic.is_id_count
  }

  getPercentage(data: DonutChartData) {
    const total = sumBy(this.donutChartData, "count")
    return (data.count / total) * 100
  }

  hasZeroCount(bucket: Bucket) {
    return get(this.segmentCounts, bucket.short_id) === 0;
  }
}
