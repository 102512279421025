<div class="box">
  <div class="box-header">
    <h1 class="box-title">Recently Created Charts</h1>
    <!-- <div class="created-by-filter">
      <span>Created by me</span> |
      <span class="gray-disabled">Team</span>
    </div> -->
    <div class="box-title-right">
      <mat-select class="dropdown mat-dropdown" [ngModel]="tableFilterLength$ | async" (ngModelChange)="filterLengthSelect($event)">
        <mat-option *ngFor="let option of selectOptions" [value]="option.value">{{option.label}}</mat-option>
      </mat-select>
    </div>
  </div>
  <div class="box-container top-border">
    <main class="dark ppc-table">
      <table>
        <thead>
          <tr>
            <th><div>Type</div></th>
            <th class="ultra-wide"><div>Name</div></th>
            <th><div>Total Population</div></th>
            <th><div>Matched</div></th>
            <th><div>Modeled</div></th>
            <th><div>Created</div></th>
            <th>Updated</th>
            <th><!-- action column--></th>
          </tr>
        </thead>
        <ng-container>
          <ng-container *ngIf="isFullContext && (charts$ | async)?.length > 0">
            <!--Have to use attribute components here because otherwise the extra html tag
            that gets injected for normal components would mess up the table formatting-->
            <tbody
              *ngFor="let row of (charts$ | async)"
              ppc-home-mekko-row
              [chart]="row"
              [hierarchyPath]="hierarchyPath">
            </tbody>
            <tbody>
              <tr class="link">
                <td colspan="7">
                  <a routerLink="{{hierarchyPath}}/insights/grow">Go to Grow</a>
                </td>
                <td>
                  <img routerLink="{{hierarchyPath}}/insights/grow" src="assets/icons/baseline-arrow_forward-24px.svg" class="blue-icon" />
                </td>
              </tr>
            </tbody>
          </ng-container>

          <tbody *ngIf="(charts$ | async)?.length === 0 || !isFullContext">
            <tr><td colspan="8" class="data-unavailable">Data unavailable</td></tr>
            <tr class="link">
              <td colspan="7">
                <a routerLink="{{hierarchyPath}}/insights/grow">Go to Grow</a>
              </td>
              <td>
                <img routerLink="{{hierarchyPath}}/insights/grow" src="assets/icons/baseline-arrow_forward-24px.svg" class="blue-icon" />
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </main>
  </div>
</div>
