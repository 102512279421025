<button class="pds-btn pds-btn--secondary" (click)="addDialog.show()">
  Add Destination
</button>

<ng-container>
  <ppc-dialog class="destination-add-dialog dialog-destination" #addDialog
              (onShow)="handleModalMode(true)"
              (onClose)="handleModalMode(false)"
  >
    <div header>
      <h3>{{partner?.name}}</h3>
    </div>
    <div body class="destination-modal-body">
      <p>Please specify a {{partner?.name}} destination name.</p>
      <mat-form-field>
        <input matInput [ngModel]="destName$ | async" (ngModelChange)="updateDestName($event)">
      </mat-form-field>
      <div class="ppc-input-error" *ngIf="duplicateName" [class.invisible]="!duplicateName">
        Duplicate Destination Name
      </div>
    </div>

    <div footer>
      <div class="footer">
        <button class="pds-btn pds-btn--secondary" (click)="closeDialog()">
          Cancel
        </button>
        <button [disabled]="isSubmitDisabled$ | async" class="pds-btn pds-btn--primary" (click)="createDestinationForPartner()">
          Confirm
        </button>
      </div>
    </div>

  </ppc-dialog>
</ng-container>
