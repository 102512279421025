import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {map, filter, distinctUntilChanged} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {Store, select} from "@ngrx/store";
import {AppState} from "app/reducers";
import {getRuleSegmentIdentifiers} from "app/audiences-v2/audience-v2.model";
import {difference, flatMap, isEqual, uniq} from 'lodash';
import {FetchSegments, LoadSegments} from "app/segments-v2/segment-v2.actions";
import {fetchResource} from "app/shared/utils/fetch-state";
import {selectAllIdentifiers} from "app/segments-v2/segment-v2.reducers";
import {objToHttpParams} from "app/shared/utils/http-utils";
import {SegmentV2} from "app/audiences/discover/segment-v2.model";
import {selectAllShortIds} from 'app/mekko/mekko.reducer';
import { SegmentLike } from '../models/segment-like.model';
import { V4, V5 } from '../shared/utils/constants';
import { ppcObjectsUrl, searchUrl, segmentsV1Url, segmentsV3Url, segmentsV4Url } from 'app/shared/constants/segments.urls';

@Injectable()
export class SegmentV2Service {

  @Effect()
  fetchSegments$ = this.actions$.pipe(
    ofType(FetchSegments.type),
    (
      fetchResource(
        (action: FetchSegments) => this.fetchSegmentsV4(action.options).pipe(map(segments => new LoadSegments(segments))),
        false
      )
    ))

  @Effect()
  loadSegmentsForAudiences$ = observableCombineLatest(
    this.store.select('audiencesV2', 'audiences').pipe(select(audiences => flatMap(audiences, getRuleSegmentIdentifiers))),
    this.store.select('segmentsV2').pipe(select(selectAllIdentifiers))
  ).pipe(
    map(([audienceSegmentIds, loadedSegmentIds]: [string[], string[]]) => uniq(difference(audienceSegmentIds, loadedSegmentIds))),
    filter(ids => ids.length > 0),
    distinctUntilChanged(isEqual),
    map(identifiers => new FetchSegments({identifiers, use_cases: ['create_audience_single']})), )

  @Effect()
  loadSegmentsForMarkets$ = observableCombineLatest(
    this.store.select('mekkos').pipe(select(selectAllShortIds)),
    this.store.select('segmentsV2').pipe(select(selectAllIdentifiers))
  ).pipe(
    map(([mekkoSegmentIds, loadedSegmentIds]: [string[], string[]]) => difference(mekkoSegmentIds, loadedSegmentIds)),
    filter(ids => ids.length > 0),
    distinctUntilChanged(isEqual),
    map(identifiers => new FetchSegments({ identifiers, use_cases: ['insights']})), )

  constructor(private http: HttpClient, private store: Store<AppState>, private actions$: Actions) { }

  fetchSegments(opts: SegmentFetchOptions): Observable<SegmentV2[]> {
    const params = objToHttpParams(opts)
    return this.http.get<{data: any}>(segmentsV1Url(), {params}).pipe(map(({ data }) => data))
  }

  fetchSegmentsV3(opts: SegmentFetchOptions): Observable<SegmentV2[]> {
    const params = objToHttpParams(opts)
    return this.http.get<{data: any}>(segmentsV3Url(), {params}).pipe(map(({ data }) => data))
  }

  fetchSegmentsV4(opts: SegmentFetchOptionsV4): Observable<SegmentV2[]> {
    return this.http.post<{data: any}>(segmentsV4Url(), opts).pipe(map(({ data }) => data))
  }

  searchSegments(keyword: string, limit = 0, offset = 0, vendors = [], use_case: string, is_tier3: boolean = false): Observable<{segments: any[], searchCount: number, hits: string[]}> {
    return is_tier3 ? this.searchSegmentsByRelevance(keyword, limit, offset, vendors, use_case) : this.searchV5PpcObjectsByRelevance(keyword, limit, offset, vendors, use_case)
  }

  searchSegmentsByRelevance(keyword: string, limit = 0, offset = 0, vendors = [], use_case: string): Observable<{segments: SegmentV2[], searchCount: number, hits: string[]}> {
    return this.searchByRelevance(V4, '/segments/search', keyword, limit, offset, vendors, use_case)
      .map(({segments, search_count, hits}) => {
        return { segments, searchCount: search_count, hits };
      });
  }

  searchV5PpcObjectsByRelevance(keyword: string, limit = 0, offset = 0, vendors = [], use_case: string): Observable<{segments: any[], searchCount: number, hits: string[]}> {
    return this.searchByRelevance(V5, '/search', keyword, limit, offset, vendors, use_case)
      .map(({data, result_count, hits}) => {
        return { segments: data, searchCount: result_count, hits };
      });
  }

  searchByRelevance(api_version: string, endpoint: string, keyword: string, limit = 0, offset = 0, vendors = [], use_case: string): Observable<any> {
    return this.http.get<{segments: SegmentV2[], search_count: number, hits: string[]}>(searchUrl(api_version, endpoint, keyword, limit, offset, vendors, use_case));
  }

  fetchByIdentifiers(identifiers: string[]): Observable<SegmentLike[]> {
    return this.http.post<{data: SegmentLike[]}> (ppcObjectsUrl(), identifiers).pipe(map(payload => payload.data));
  }

}

export interface SegmentFetchOptions {
  identifiers?: string[];
  id_space?: string;
  vendors?: string[];
  parent_id?: string;
  use_cases?: string[];
}

export interface SegmentRequestByParent {
  use_cases: string[];
  parent_id: string;
}

export interface SegmentRequestByVendors {
  vendors: {vendor_name: string; vendor_type?: string; owner_name?: string}[];
  use_cases: string[];
}

export interface SegmentRequestByIdentifiers {
  use_cases: string[];
  identifiers: string[];
}

export type SegmentFetchOptionsV4 = SegmentRequestByParent | SegmentRequestByVendors
| SegmentRequestByIdentifiers;
