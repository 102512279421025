<div class="container">

  <div class="header">
    <div class="title">
      Notifications
    </div>
    <div class="header-actions">
      <button
        class="download-history"
        (click)="downloadPastDownloadsReport()"
        *ngIf="hasReportTaskAdminCreatePermission$ | async"
        mat-button
      >
        <i class="fa fa-download fa-lg"></i>
        <span>Download Report History</span>
      </button>
      <div class="close-btn" (click)="cancel()">
        <img src="assets/icons/close.svg" />
      </div>
    </div>
  </div>

  <div class="body" *ngIf="list.length; else emptyState">

    <div class="table">
      <div class="row header">
        <div class="column small">
          <!-- no header here -->
        </div>
        <div class="column large">
          Events
        </div>
        <div class="column center">
          Date
        </div>
        <div class="column center">
          Actions
        </div>
      </div>

      <ng-container *ngFor="let item of list">
        <div class="row" [class.highlight]="item.highlight">
          <div class="column small center"
               ppcTooltip="{{ ICON_TOOLTIP_MAP[item.icon] }}">
            <i class="fa fa-{{ item.icon }} fa-lg"></i>
          </div>

          <div class="column large nowrap ellipsize">
            <span
              *ppcCheckOverflow="let hasOverflowedName=hasXOverflow"
              [ppcTooltip]="item.name"
              [tooltipDisabled]="!hasOverflowedName"
            >
              {{ item.name }}
            </span>
            <span
              *ppcCheckOverflow="let hasOverflowedName=hasXOverflow"
              [ppcTooltip]="item.hierarchy"
              [tooltipDisabled]="!hasOverflowedName"
            >
              {{ item.hierarchy }}
            </span>
          </div>

          <div class="center">
            {{ item.date | date: 'MM/dd/yy' }} <i class="fa fa-clock-o" ppcTooltip="Notifications expire after 15 days"></i>
          </div>

          <div class="column center actions">
            <ng-container *ngIf="!actionInProgress(item); else showInProgress">
              <div class="center actions"
                   *ngFor="let action of item.actions">
                <button class="action-btn"
                        [class.disabled]="action.disabled"
                        (click)="!action.disabled && action.callback(item)"
                        ppcTooltip="{{ action.tooltip }}">
                  <i class="fa fa-{{ action.icon }}"></i>
                </button>
              </div>
            </ng-container>
            <ng-template #showInProgress>
              <i class="fa fa-spinner fa-spin"></i>
            </ng-template>
          </div>

        </div>
      </ng-container>
    </div>  <!-- end table -->

  </div> <!-- end body -->
</div> <!-- end container -->
<div class="background" (click)="onClose.emit($event)"></div>

<ng-template #emptyState>
  <div class="empty">
    <p>No new notifications to show.<br/>
      Notifications are automatically removed after 15 days.
    </p>
  </div>
</ng-template>
