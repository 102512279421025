import {HierarchyPermissions} from "./hierarchy-permissions.interface";
import * as Actions from './hierarchy-permissions.actions'
import {LoadHierarchyPermissions} from "./hierarchy-permissions.actions";

export interface State {
  [userId: string]: HierarchyPermissions
}

export function reducer(state: State = {}, action: Actions.All): State {
  switch (action.type) {
    case LoadHierarchyPermissions.type:
      return {...state, [action.userId]: action.permissions};
    default:
      return state;
  }
}
