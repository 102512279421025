import { Inject, Injectable, OnDestroy } from "@angular/core";
import { SegmentV2Service } from "app/segments-v2/segment-v2.service";
import { filter, switchMap } from "rxjs/operators";
import {
  asapScheduler,
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  Observable,
} from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers/index";
import { fullContext } from "../hierarchy/hierarchy.reducers";
import { isEmpty, sortBy, map as _map, cloneDeep } from "lodash";
import { HttpClient } from "@angular/common/http";
import { PERMISSION_CREATE_AUDIENCE_SINGLE } from "../shared/utils/constants";
import {
  CAN_SELECT_SEGMENT_PROVIDER,
  DRAG_INSTRUCTIONS_PROVIDER,
} from "./segment-picker-v2.constants";
import { LocalStorageService } from "../services/local-storage.service";
import { BaseSegmentPicker } from "../base-components/base-segment-picker";
import { SegmentSearchResult } from "./segment-picker-search/segment-picker-search.component";
import { SegmentV2 } from "app/audiences/discover/segment-v2.model";
import { CdkDragDrop, CdkDragEnter, CdkDragStart, CdkDropList } from "@angular/cdk/drag-drop";

const HIDE_PICKER_DRAG_INSTRUCTIONS_TOOLTIP =
  "hide-picker-drag-instructions-tooltip";

@Injectable()
export class SegmentPickerV2Service
  extends BaseSegmentPicker
  implements OnDestroy {
  isDragging$ = new BehaviorSubject(false);
  root$: Observable<Node[]>;
  hideDragInstructionsTooltip: boolean = this.localStorage.getValue(
    HIDE_PICKER_DRAG_INSTRUCTIONS_TOOLTIP
  );
  public useCase$ = new BehaviorSubject(PERMISSION_CREATE_AUDIENCE_SINGLE);

  constructor(
    private segmentService: SegmentV2Service,
    private store: Store<AppState>,
    public http: HttpClient,
    @Inject(CAN_SELECT_SEGMENT_PROVIDER)
    public canSelectSegment: (segment) => boolean,
    @Inject(DRAG_INSTRUCTIONS_PROVIDER) public dragInstructions: string,
    private localStorage: LocalStorageService
  ) {
    super(http);
    this.root$ = observableCombineLatest(
      fullContext(this.store),
      this.useCase$
    ).pipe(
      filter((context) => !isEmpty(context)),
      switchMap(([{ client, region }, useCases]) =>
        this.getRoot(region, [useCases])
      )
    );
  }

  ngOnDestroy() {}

  public setUseCase(useCase: string) {
    this.useCase$.next(useCase);
  }

  search(keyword, limit, offset, vendors) {
    return this.segmentService.searchV5PpcObjectsByRelevance(
      keyword,
      limit,
      offset,
      vendors,
      this.useCase$.getValue()
    );
  }

  setIsDragging(isDragging: boolean) {
    this.isDragging$.next(isDragging);
    if (!this.hideDragInstructionsTooltip) {
      this.hideDragInstructionsTooltip = true;
      this.localStorage.setValue(HIDE_PICKER_DRAG_INSTRUCTIONS_TOOLTIP, true);
    }
  }

  toggleSelectedSegment(isSelected: boolean, segment: PickedSegment, segments$: BehaviorSubject<any[]>) {
    const selectedSegments = segments$.value;
    if (!isSelected) {
      segments$.next(sortBy([...selectedSegments, segment], "name"));
    } else {
      segments$.next(selectedSegments.filter(s => s.identifier !== segment.identifier));
    }
  }

  segmentIsSelected(segment: PickedSegment, selectedSegments: PickedSegment[]): boolean {
    return _map(selectedSegments, "identifier").includes(segment.identifier);
  }

  selectDraggedSegment(segment: PickedSegment, selectedSegments: PickedSegment[], segments$: BehaviorSubject<any[]>): void {
    const isSelected = this.segmentIsSelected(segment, selectedSegments);
    if (!isSelected) {
      this.toggleSelectedSegment(isSelected, segment, segments$);
    }
  }

  hasMultipleSelectedSegments(segment: PickedSegment, selectedSegments: PickedSegment[]): boolean {
    return this.segmentIsSelected(segment, selectedSegments) && selectedSegments?.length > 1;
  }

  clearSelectedSegments(selectedSegments$: BehaviorSubject<any[]>): void {
    selectedSegments$.next([]);
  }

  dragEntered(event: CdkDragEnter) {
    this.enforceDragToSelf(event.container);
  }

  dragStarted(event: CdkDragStart, segment: PickedSegment, selectedSegments: PickedSegment[], segments$: BehaviorSubject<any[]>) {
    this.selectDraggedSegment(segment, selectedSegments, segments$);
    this.enforceDragToSelf(event.source.dropContainer);
    this.setIsDragging(true);
  }

  setContainerData(
    event: CdkDragDrop<any>, segment: PickedSegment, index: number, segments$: BehaviorSubject<any[]>) {
    const clone = cloneDeep(segment);
    event.container.data.splice(index, 0, clone);
    this.toggleSelectedSegment(true, clone, segments$);
  }

  dragDropped(event: CdkDragDrop<any>, segments$: BehaviorSubject<any[]>) {
    if (event.item.data?.length > 1 && event.previousContainer !== event.container) {
      event.item.data.forEach((segment: PickedSegment, i: number) => {
        this.setContainerData(event, segment, event.currentIndex + i, segments$)
      });
    } else if (event.container === event.previousContainer) {
      return;
    } else {
      this.setContainerData(event, event.previousContainer.data[event.previousIndex], event.currentIndex, segments$);
    }
    this.setIsDragging(false);
  }

  getDragData(segment: PickedSegment, selectedSegments: PickedSegment[]): PickedSegment | PickedSegment[] {
    return this.hasMultipleSelectedSegments(segment, selectedSegments) ? selectedSegments : segment;
  }

  multiSelectSegment(segment: PickedSegment, selectedSegments: PickedSegment[], segments$: BehaviorSubject<any[]>) {
    if (!this.canSelectSegment(segment)) { return; };
    this.toggleSelectedSegment(this.segmentIsSelected(segment, selectedSegments), segment, segments$);
  }

  private enforceDragToSelf(dl: CdkDropList) {
    const siblings  = dl.connectedTo as CdkDropList<any>[];

    const ref =  dl._dropListRef;
    asapScheduler.schedule(() => {
      ref.connectedTo(siblings.map(list => list._dropListRef));
    });
  }
}

type PickedSegment = SegmentV2 | SegmentSearchResult;
