import { Component, OnInit, Input, HostBinding, Output, EventEmitter } from '@angular/core';

export type FocusType = "right" | "left" | null;

@Component({
  selector: 'ppc-split-screen',
  templateUrl: './ppc-split-screen.component.html',
  styleUrls: ['./ppc-split-screen.component.sass']
})
export class PpcSplitScreenComponent implements OnInit {
  @Output() focusChange = new EventEmitter<FocusType>();
  @Input() leftLabel: string;
  @Input() rightLabel: string;
  @Input() focus: 'left' | 'right' = "left";
  @Input() showGutter: boolean = true;
  constructor() { }

  ngOnInit() {
  }

  setFocus(focus: "left" | "right") {
    if (this.focus && focus != this.focus) {
      this.focus = null;
    } else {
      // This logic is a little confusing but remember that if there is no
      // current focus the buttons read "Hide <component>" so the behavior flips
      this.focus = focus == "left" ? "right" : "left";
    }
    this.focusChange.emit(this.focus);
  }

  overrideFocus(focus) {
    this.focus = focus;
    this.focusChange.emit(this.focus);
  }

  @HostBinding("class.focus-left")
  get focusLeft() {
    return this.focus == "left" || !this.showGutter;
  }

  @HostBinding("class.focus-right")
  get focusRight() {
    return this.focus == "right";
  }

}
