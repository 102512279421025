import {map} from 'rxjs/operators';

import {takeUntil} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import { PpcHttpService } from "./ppc_http.service";
import {Observable} from "rxjs";
import {Actions, Effect, ofType} from '@ngrx/effects'
import {Curve} from "../admin/plans-admin/curve";
import {fetchResource} from "../shared/utils/fetch-state";
import {FetchCurveTypes, LoadCurveTypes} from "../plans/curves/curves.actions";
import {Store} from "@ngrx/store";
import {AppState} from "app/reducers/index";
import {fullContext} from "app/hierarchy/hierarchy.reducers";
import {HierarchyClient} from 'app/hierarchy/hierarchy.interface';
import { scenarioCurvesUrl } from '../shared/constants/plans.urls';

@Injectable()
export class CurveService {
  // Fetches curve types. Once curve types have been loaded, subsequent FETCH_CURVE_TYPES calls will be ignored
  // (they shouldn't change during a users session, and if they do a full refresh will allow another fetch)
  @Effect()
  fetchCurveTypes$ = this.actions$.pipe(
    ofType(FetchCurveTypes.type),
    takeUntil(this.actions$.pipe(ofType(LoadCurveTypes.type))),
    (fetchResource(
      () => this.getCurveTypes().pipe(map(types => new LoadCurveTypes(types))))
    ), );

  activeClient: HierarchyClient = null;

  constructor (
    private store: Store<AppState>,
    private http: PpcHttpService,
    private actions$: Actions
  ) {
    fullContext(this.store).subscribe(
      ({client}) => this.activeClient = client
    );
  }

  genericCurvesUrl(): string {
    return this.curvesUrl('generic_curves');
  }

  curvesUrl(curveId?: string): string {
    let url = scenarioCurvesUrl();
    if (curveId) {
      url = `${url}/${curveId}`;
    }
    return url;
  }

  getGenericCurves(): Observable<Curve[]> {
    return this.http.get(this.genericCurvesUrl()).pipe(
      map(res => res.data))
  }

  getCurves(): Observable<Curve[]> {
    return this.http.get(this.curvesUrl()).pipe(
      // TODO: Delete this once reach and penetration are separate curve types PR#1490
      map(res => (this.activeClient.curve_label_override)
        ? (res.data as Curve[]).map(curve => new Curve({...curve, override: true}))
        : (res.data as Curve[]).map(curve => new Curve(curve))
      ));
  }

  // TODO: confirm that we can delete these unused api calls, and check the backend for related code we can delete there
  getCurve(curveId: string): Observable<Curve> {
    return this.http.get(this.curvesUrl(curveId)).pipe(
      map(res => {
        return (this.activeClient.curve_label_override)
          ? new Curve({...res.data, override: true})
          : new Curve(res.data);
      }))
  }

  updateCurve(curve: Curve): Observable<Curve> {
    // TODO: Delete this once reach and penetration are separate curve types PR#1490
    const c = (this.activeClient.curve_label_override)
      ? {...curve, override: true}
      : curve;
    return this.http.put(this.curvesUrl(curve.id), c).pipe(
      map(res => new Curve(res.data)))
  }

  destroyCurve(curve: Curve): Observable<Curve> {
    return this.http.delete(this.curvesUrl(curve.id)).pipe(
      map(res => res.data))
  }

  createCurve(curve: Curve): Observable<Curve> {
    return this.http.post(this.curvesUrl(), curve).pipe(
      map(res => new Curve(res.data)))
  }

  // TODO: Delete this once reach and penetration are separate curve types PR#1490
  updateCurveLabel(curves: Curve[], from = 'Reach', to = 'Penetration'): Curve[] {
    return curves.map(c => c.curve_type_name.startsWith(from)
      ? {...c, curve_type_name: `${c.curve_type_name.replace(from, to)}`}
      : c
    ).map(c => new Curve(c))
  }

  createCurvesForProduct(curves: Curve[]): Observable<{curves: Curve[], error_messages: {[key: string]: string} }> {
    if (this.activeClient.curve_label_override) {
      curves = this.updateCurveLabel(curves, 'Penetration', 'Reach');
    }
    return this.http.post(this.curvesUrl("for_product"), {"curves": curves}).pipe(
      map(res => {
        const errors = res.error_messages as {[key: string]: string};
        const curves =  (res.data as Curve[]).map(data => {
          let curve = null;
          // TODO: Delete this once reach and penetration are separate curve types PR#1490
          if (this.activeClient.curve_label_override) {
            curve = new Curve({...data, override: true});
          } else {
            curve = new Curve(data);
          }
          return curve;
        });
        return { curves: curves, error_messages: errors};
      }))
  }

  getCurveTypes(): Observable<{ [curveTypeName: string]: number}> {
    return this.http.get(this.curvesUrl("types")).pipe(
      map(res => {
        const data = res.data;
        // TODO: Delete this once reach and penetration are separate curve types PR#1490
        if (this.activeClient.curve_label_override) {
          Object.keys(data).forEach((key) => {
            if (key.startsWith('Reach')) {
              data[key.replace('Reach', 'Penetration')] = data[key];
              delete data[key];
            }
          });
        }
        return data;
      }))
  }

}
