import { Component, Input, Output, EventEmitter, forwardRef, HostListener, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRipple } from '@angular/material/core';

export type CheckboxStatus = 'indeterminate' | 'checked' | 'unchecked';

/*
  Replacement for MD-Checkbox that allows for the component to have a state or be stateless.
  For a stateless component bind to [stateless]="true" and then bind to the
  [checked] property.
  For a checkbox that keeps track of the state you can use it as normal using
  [(ngModel)] and bind any events to the (change) event.
*/

@Component({
  selector: 'ppc-checkbox',
  templateUrl: './ppc-checkbox.component.html',
  styleUrls: ['./ppc-checkbox.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PpcCheckboxComponent),
      multi: true
    }
  ]
})
export class PpcCheckboxComponent implements ControlValueAccessor {
  @ViewChild(MatRipple, { static: true }) ripple: MatRipple;
  @Input() ellipsize: boolean;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() stateless: boolean = false;
  @Input() indeterminate: boolean = false;
  @Input() showRipple: boolean = true;
  @Input() showDisableFilter: boolean = true;
  @Input() labelTooltip: string = null;

  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  @HostListener("keypress", ["$event"])
  click(event: any) {
    if (event.key == "Enter") {
      this.toggle(event);
    }
  }

  playRipple() {
    if (this.showRipple) {
      this.ripple.launch(0, 0)
    }
  }

  toggle(event: any) {
    // event.cancelBubble = true;
    if (!this.stateless) {
      this.writeValue(!this.checked);
    }
    this.change.emit(!this.checked);

  }

  // CONTROL VALUE ACCESSOR IMPLEMENTATION

  // Function to call when the value changes.
  onChange = (value: any) => {};

  // Function to call when the input is touched.
  onTouched = () => {};

  // Allows Angular to update the model.
  // Update the model and changes needed for the view here.
  writeValue(value: boolean): void {
    this.checked = value;
    this.onChange(this.checked)
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input. Not currently used to this component but necessary to implement ControlValueAccessor
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // END CONTROL VALUE ACCESSOR IMPLEMENTATION

}
