
import {catchError, map, mergeMap} from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { forEach, sortBy } from 'lodash';
import { Observable } from 'rxjs';

import { visitTreeNodes } from 'app/shared/utils/utils';
import { LocalStorageService } from 'app/services/local-storage.service';
import { SegmentV2Service } from 'app/segments-v2/segment-v2.service';
import { SegmentV2 } from 'app/audiences/discover/segment-v2.model';
import { AppState } from 'app/reducers';
import * as insightsActions from 'app/insights/insights.actions';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import {InsightsCountService} from "app/insights/insights-count.service";

export interface TreeSegment extends SegmentV2 {
  children?: TreeSegment[];
}

@Component({
  selector: 'ppc-person-level-shared-interests',
  templateUrl: './person-level-shared-interests.component.html',
  styleUrls: ['./person-level-shared-interests.component.sass']
})
export class PersonLevelSharedInterestsComponent implements OnInit {
  tree: TreeSegment[];
  insightsFocused$: Observable<boolean>;
  focus: string = this.localStorage.getValue("shared-interests-focus") || "all";
  focusItems = [
    {
      label: "View All",
      value: "all"
    },
    {
      label: "View Top 10/Bottom 10",
      value: "top10-bottom10"
    },
    {
      label: "View Top 5/Bottom 5",
      value: "top5-bottom5"
    },
  ]

  constructor(private segmentService: SegmentV2Service,
    private localStorage: LocalStorageService,
    private counts: InsightsCountService,
    private store: Store<AppState>,
    @Inject(INSIGHTS_CONTEXT) private insightsContext: InsightsContextType) {

    this.insightsFocused$ = this.store.select("grow", "growV3Focus").pipe(
      map(focusedComponent => focusedComponent === "insights"));
  }

  ngOnInit() {
    this.segmentService.fetchSegmentsV4({vendors: [{vendor_name: "ppc", vendor_type: "interests"}], use_cases: ["insights"]})
      .pipe(
        mergeMap(([iabRoot]) => {
          return this.segmentService.fetchSegments({parent_id: iabRoot.identifier})
        }),
        catchError(error => {
          console.log(error);
          return [];
        })
      )
      .subscribe(segments => {
        this.tree = segments;
        this.childrenLoaded();
      })
  }

  childrenLoaded() {
    this.store.dispatch(new insightsActions.SetSharedInterestSegments(this.sharedInterestShortIds, this.insightsContext));
  }

  get sharedInterestShortIds() {
    const shortIds = []
    forEach(this.tree, segment => visitTreeNodes<TreeSegment>(segment, (segment: TreeSegment) => shortIds.push(segment.identifier)))
    return shortIds
  }

  get sortedTree() {
    return sortBy(this.tree, [
      (segment: TreeSegment) => 0 - this.counts.getSegmentCount(segment.identifier),
      (segment: TreeSegment) => !this.counts.hasFilter({shortId: segment.identifier}),
      "name"
    ]);
  }

  get slicedTree() {
    switch (this.focus) {
      case "top10-bottom10":
        return this.sortedTree.slice(0, 10).concat(this.sortedTree.slice(this.sortedTree.length - 10, this.sortedTree.length))
      case "top5-bottom5":
        return this.sortedTree.slice(0, 5).concat(this.sortedTree.slice(this.sortedTree.length - 5, this.sortedTree.length));
      default:
        return this.sortedTree
    }
  }

  trackByShortId(index, segment) {
    return segment.identifier;
  }

  saveFocusSetting() {
    this.localStorage.setValue("shared-interests-focus", this.focus);
  }

}


