<div class="audience-builder-rule-groups">
  <div class="audience-builder-rule-group {{group.type}}" *ngFor="let group of ruleGroup.items;">
    <div *ngIf="isEntrySegment(group)" class="audience-builder-group-segment">
      <div class="segment-path">{{ getSegmentPath(group) }}</div>
      <div class='segment-count'>
        <div class="warning-icon-holder" *ngIf="getSegmentCount(group) === 0">
          <ppc-zero-count-warning-icon></ppc-zero-count-warning-icon>
        </div>
        {{ getSegmentCount(group) | number }}
      </div>
    </div>
    <div *ngIf="!isEntrySegment(group)" class="audience-builder-group-container {{group.type}}">
      <div class="audience-builder-rule-group-bracket">
        <div class="bracket"></div>
        <div class="group-info" #groupInfo>
          <div class="group-info-label">
            <div class="label-text">
              {{group.type}}
            </div>
          </div>
        </div>
      </div>
      <ppc-audience-rules-detail-group
        [ruleGroup]="group">
      </ppc-audience-rules-detail-group>
    </div>
  </div>
</div>
