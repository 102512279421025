import { combineLatest as observableCombineLatest, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { capitalize, drop, forEach, filter as _filter, find, flatten, isEqual, isNumber, map as _map } from 'lodash';
import { AppState } from 'app/reducers';
import { InsightsCountService } from 'app/insights/insights-count.service';
import { buildComparisonContext, Comparison, ComparisonCountResponse, FocusedCellType, selectAllCells, selectedComparison } from 'app/comparisons/comparisons.reducer';
import { COUNT_TYPE_INDEX, COUNT_TYPE_MATCHED, COUNT_TYPE_MODELED } from "app/insights/insights.constants";
import { isDefined } from 'app/shared/utils/utils';
import { IndexBase } from 'app/insights/insights.reducer';
import { InsightsExportService } from 'app/insights/insights-components/insights-export/insights-export.service';

@UntilDestroy()
@Injectable()
export class OverlapInsightsExportService implements OnDestroy {
  comparison: Comparison;
  comparisonCounts: {[comparisonId: number]: ComparisonCountResponse};
  exportOptions = { "Person Level": true };
  focusedCells: FocusedCellType[];
  allCells: Partial<FocusedCellType>[];
  unfilteredCount$ = new Subject<number>();
  unfilteredCount: number;
  identifiers$ = new Subject<{}>();
  identifiers: {[shortId: string]: {identifier: string, path: string}};
  defaultAxisHeaders = ["", "X-Axis Audience Name", "X-Axis Attached Audience", "Y-Axis Audience Name", "Y-Axis Attached Audience"];
  axisHeaders: string[];
  widgetHeaders: string[];
  personLevelWorkBookName: string;
  indexBase: IndexBase;
  isIndex: boolean;

  constructor(private store: Store<AppState>,
    private insightsCounts: InsightsCountService,
    private insightsExportService: InsightsExportService) {
    observableCombineLatest(
      this.store.select("comparisons").pipe(select(selectedComparison), filter(isDefined)),
      this.store.select("comparisons", "comparisonCounts"),
      this.store.select("comparisons", "focusedCells"),
      this.store.select("comparisons").pipe(select(selectAllCells)),
      this.store.select("insights", 'compare', "indexBase"),
    ).pipe(untilDestroyed(this)).subscribe(([comparison, comparisonCounts, focusedCells, allCells, indexBase]) => {
      this.comparison = comparison;
      this.comparisonCounts = comparisonCounts;
      this.focusedCells = focusedCells;
      this.allCells = allCells;
      const countType = this.comparison.mode === COUNT_TYPE_MODELED ? "Attribute GWI Weight" : "Attribute Count";
      this.widgetHeaders = [
        "Widget Name",
        "Attribute Name",
        countType,
        "Attribute Percent of Widget",
        "Attribute Percent of Base",
        "Index",
        "Attribute Provider",
        "Attribute Selected"
      ];
      this.personLevelWorkBookName = this.comparison.mode + '_personlevel_export';
      this.axisHeaders = [...this.defaultAxisHeaders];
      this.indexBase = indexBase;
      this.isIndex = this.comparison.mode === COUNT_TYPE_INDEX;
    }
    );

    this.unfilteredCount$.pipe(untilDestroyed(this)).subscribe(count => this.unfilteredCount = count);
    this.identifiers$.pipe(untilDestroyed(this)).subscribe(identifiers => this.identifiers = identifiers);
  }

  ngOnDestroy() {}

  context(filtered = true) {
    const cells = filtered ? this.focusedCells : this.allCells;
    return buildComparisonContext(this.comparison, cells);
  }

  exportOnlyChart() {
    this.insightsExportService.loading$.next(true);
    const workbookName = this.insightsExportService.setFileName(this.comparison.mode + '_chart_export');
    const chartData = this.insightsExportService.addFiltersToChartDetails([], false, {}, this.generateOverlapChartOnlyDetails());
    const workbookData = this.insightsExportService.personLevelExport(chartData, "Person Level", workbookName);
    this.styleWorkbook(workbookData["wb"], workbookData["chartDetails"], "1. Chart Details", true);
    this.insightsExportService.writeFile(workbookData["wb"], workbookName);
    this.insightsExportService.finishExport(false, "Successfully exported overlap chart");
  }

  styleWorkbook(wb, chartDetails, wsName, chartOnly = false) {
    const ws = wb.getWorksheet(wsName);
    forEach(chartDetails, function(row, indx) {
      const wsRow = ws.getRow(indx + 1);
      if (!chartOnly && row === this.axisHeaders) {this.insightsExportService.boldRow(wsRow); }
      if (chartOnly && ["Percentages", "Counts", "Indices"].find(r => row.includes(r))) {this.insightsExportService.boldRow(wsRow); }
    }.bind(this));

    if (this.isIndex && chartOnly) {this.colorIndexOverlapChart(ws); }
  }

  colorIndexOverlapChart(ws) {
    const chartRows = _map(_filter(ws.getSheetValues(), r => r && r.some(v => isNumber(v))), cr => drop(cr, 1));
    const wsRowsToColor = _filter(ws._rows, r => find(chartRows, cr => isEqual(drop(r.values, 1), cr)));

    forEach(wsRowsToColor, wsRow => {
      forEach(wsRow.values, function(v, index) {
        if (isNumber(v)) {this.insightsExportService.handleIndexColor(wsRow, index - 1, v); }
      }.bind(this));
    });
  }

  generateOverlapChartDetailsData() {
    const filteredCount = this.insightsCounts.totalCount;
    const countType = capitalize(this.comparison.mode);
    const countLabel = countType + (countType === "Modeled" ? " Weight" : " Count");
    const subHeader = this.setSubHeader();
    const chartData = [
      ["Chart Name", this.comparison.name],
      ["Chart Type", countType + ' | ' + subHeader],
      ["Index Base", this.indexBase.shortId ? this.getCellPath(this.indexBase.shortId) : this.indexBase.name],
      ["Chart " + countLabel, this.unfilteredCount],
      ["Filtered " + countLabel, filteredCount],
      [],
    ];
    this.axisHeaders = [...this.defaultAxisHeaders];
    if (this.isIndex) {this.axisHeaders.push(`${countType} Value`); }
    if (!this.isIndex) {this.axisHeaders.push("Overlap Count", "Overlap Percentage"); }
    chartData.push(this.axisHeaders);
    forEach(this.focusedCells, function(cell, i) {
      const dataArray = [
        `Selected Overlap Audience ${i + 1}`,
        this.getCellName(this.comparison.x_segments, cell.x),
        this.getCellPath(cell.x),
        this.getCellName(this.comparison.y_segments, cell.y),
        this.getCellPath(cell.y),
      ];
      const matrixCell = this.comparisonCounts[this.comparison.id].matrix[`${cell.x}:${cell.y}`];
      if (this.isIndex) {
        dataArray.push(this.getCellIndex(matrixCell));
      } else {
        dataArray.push(this.getCellCount(matrixCell), this.getCellPercent(matrixCell));
      };

      chartData.push(dataArray);
    }.bind(this));
    chartData.push([]);

    return chartData;
  }

  generateOverlapChartOnlyDetails() {
    const countType = capitalize(this.comparison.mode);
    const subHeader = this.setSubHeader();
    const chartData = [
      ["Chart Name", this.comparison.name],
      ["Chart Type", countType + ' | ' + subHeader],
      [],
    ];

    if (this.isIndex) {
      this.setOverlapChart(chartData, "index", "Indices");
    } else {
      this.setOverlapChart(chartData, "percent", "Percentages");
      this.setOverlapChart(chartData, "count", "Counts");
    }

    return chartData;
  }

  setOverlapChart(chartData, chartType: "percent" | "count" | "index", header) {
    chartData.push([header]);
    const y_axis_name = this.comparison.y_axis_name || "Y-Axis";
    const x_axis_name = this.comparison.x_axis_name || "X-Axis";
    forEach(this.comparison.y_segments, function(yCell, indx) {
      const rowData = [indx === 0 ? y_axis_name : "", yCell.name];
      forEach(this.comparison.x_segments, xCell => {
        const matrixCell = this.comparisonCounts[this.comparison.id].matrix[`${xCell.short_id}:${yCell.short_id}`];
        if (this.isIndex) {
          rowData.push(this.getCellIndex(matrixCell));
        } else if (chartType === "percent") {
          rowData.push(this.getCellPercent(matrixCell));
        } else {
          rowData.push(this.getCellCount(matrixCell));
        };
      })
      chartData.push(flatten(rowData));
    }.bind(this));
    chartData.push(["", ""].concat(_map(this.comparison.x_segments, "name")));
    chartData.push(["", "", x_axis_name]);
    chartData.push([]);
  }

  setSubHeader() {
    switch (this.comparison.mode) {
      case COUNT_TYPE_MATCHED:
        return "Percentages based on (X∩Y)/X";
      case COUNT_TYPE_MODELED:
        return "Sum of Weights";
      case COUNT_TYPE_INDEX:
        return "Counts based off overlap to total population";
    };
  }

  getCellName(axisSegments, cell) {
    return find(axisSegments, {short_id: cell}).name;
  }

  getCellPath(cell) {
    return this.identifiers[cell].path;
  }

  getCellPercent(matrixCell) {
    return Math.round(matrixCell.percent_value) / 1e2;
  }

  getCellCount(matrixCell) {
    return Math.round(matrixCell.count);
  }

  getCellIndex(matrixCell) {
    return Math.round(matrixCell.index_value * 100);
  }

  setUnfilteredCount(count) {
    this.unfilteredCount$.next(count);
  }

  setIdentifiers(identifiers) {
    this.identifiers$.next(identifiers);
  }
}
