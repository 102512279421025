import {Component, ElementRef, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { Observable } from 'rxjs';

import { PopupService } from './popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.sass'],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class PopupComponent implements OnInit, OnDestroy {

  // If these aren't provided the popup body will fit the width of its contents
  // NOTE: these apply to the body, not the entire popup content, if you supply a header it will add to the size
  @Input() widthPx: number;
  @Input() heightPx: number;
  @Input() rightPct: number;
  @Input() neverOpenUpwards: boolean;

  @ViewChild('popupContent', { static: true }) content: ElementRef;
  @ViewChild('popupTrigger', { static: true }) trigger: ElementRef;

  isOpen = false;
  openUpwards$: Observable<boolean>;

  constructor(private popupService: PopupService) { }

  ngOnInit() {
    if (!this.neverOpenUpwards) {
      this.openUpwards$ = this.popupService.register(this.content.nativeElement)
    }
  }

  ngOnDestroy() {
    this.popupService.unregister(this.content.nativeElement);
  }

  onClick(event) {
    const wasClickOutside = !this.trigger.nativeElement.contains(event.target)
                          && !(this.content && this.content.nativeElement.contains(event.target));

    if (wasClickOutside) {
      this.close()
    }
  }

  // Triggered by click on the hamburger stack in the the Popup Component
  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }

  public toggle() {
    this.isOpen = !this.isOpen
  }

}
