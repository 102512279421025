import { NgModule } from '@angular/core';
import { LookalikeOverlayComponent } from 'app/audiences/lookalike-overlay/lookalike-overlay.component';
import { LookALikeGraphV2Component } from 'app/audiences/lookalike-overlay/lookalike-graph-v2/lookalike-graph-v2.component';
import { PpcComponentsModule } from 'app/modules/ppc-components.module';

@NgModule({
  imports: [
    PpcComponentsModule
  ],
  exports: [
    LookALikeGraphV2Component,
    LookalikeOverlayComponent,
  ],
  declarations: [
    LookALikeGraphV2Component,
    LookalikeOverlayComponent,
  ]
})

export class LookalikeOverlayModule { }
