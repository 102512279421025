import { map, takeUntil } from 'rxjs/operators';
import { Component, OnChanges, Input, OnDestroy, Output, EventEmitter, HostBinding, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import * as chroma from 'chroma-js';
import { get, sumBy, clamp, max, map as _map } from 'lodash';
import { Subject } from 'rxjs';

import * as actions from 'app/insights/insights.actions';
import { MarketLevelDemographic, MarketLevelDemographicBucket, SubMarketBucketEntry, marketLevelDemographicHasSubMarketEntry } from "app/insights/insights-components/market-level-demographics/market-level-demographic.interface";
import { widgetColors } from 'app/insights/grow-v3/shared/widget-colors.constants';
import { ChartTypes, toMillions } from "app/insights/insights.models";
import { AuthPermission } from "app/shared/interfaces/auth-permission";
import { DonutChartData } from 'app/shared/components/ppc-donut-chart/ppc-donut-chart.component';
import { AppState } from 'app/reducers';
import { InsightsContextType } from "app/insights/insights.constants";
import { BarChartData } from 'app/insights/insights.service';

@Component({
  selector: 'ppc-market-level-demographic-widget',
  templateUrl: './market-level-demographic-widget.component.html',
  styleUrls: ['./market-level-demographic-widget.component.sass']
})
export class MarketLevelDemographicWidgetComponent implements OnChanges, OnDestroy {
  @Output() hideDemographic = new EventEmitter();
  @Input() marketLevelDemographic: MarketLevelDemographic;
  @Input() selectedSubMarkets: any;
  @Input() insightsContext: InsightsContextType;
  barChartData: BarChartData[];
  donutChartData: DonutChartData[];
  colorScheme: string[];
  ngUnsubscribe = new Subject();
  permissions: AuthPermission;

  chartTypes = ChartTypes;
  @HostBinding("class.jumbo") jumbo: boolean;

  constructor(public store: Store<AppState>) {
    store.select("permissions", "market_level_demographics").pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(permission => this.permissions = permission)

    this.store.select("grow", "growV3Focus").pipe(
      map(focus => focus == "insights"),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(insightsFocused => this.jumbo = insightsFocused)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.marketLevelDemographic && changes.marketLevelDemographic.currentValue) {
      if (!this.selectedSubMarkets.every(subMarket => marketLevelDemographicHasSubMarketEntry(this.marketLevelDemographic, subMarket))) {return; }

      this.colorScheme = chroma.scale(widgetColors).colors(this.marketLevelDemographic.market_level_demographic_buckets.length);
      this.barChartData = this.marketLevelDemographic.market_level_demographic_buckets.map( (bucket)  => {
        const count = this.getCount(bucket);
        const percent = this.getPercent(bucket);
        return {
          bucket: bucket.name,
          getCount: () => isNaN(count) ? 0 : count,
          getCountLabel: () => isNaN(count) ? 0 : toMillions(count),
          percent: isNaN(percent) ? 0 : clamp(percent, 0, 100),
          selected: false,
          id: bucket.id,
          order: bucket.position
        }
      });

      this.donutChartData = this.marketLevelDemographic.market_level_demographic_buckets.map((bucket, idx) => {
        const count = this.getCount(bucket);
        const percent = this.getPercent(bucket);
        return {
          label: bucket.name,
          count,
          percent: percent,
          color: this.colorScheme[idx],
          selected: false,
          countLabel: isNaN(count) ? 0 : toMillions(count),
          id: bucket.id
        }
      })
    }
  }

  edit() {
    this.store.dispatch(new actions.EditMarketLevelDemographic(this.insightsContext, this.marketLevelDemographic));
  }

  delete() {
    this.store.dispatch(new actions.DestroyMarketLevelDemographic(this.insightsContext, this.marketLevelDemographic.id));
  }

  hide() {
    this.hideDemographic.emit();
  }

  getCount(bucket: MarketLevelDemographicBucket) {
    return sumBy(_map(this.selectedSubMarkets, sm => {
      return bucket.sub_market_bucket_entries[sm.id];
    }) as SubMarketBucketEntry[], smbe => smbe.value || 0);
  }

  getPercent(bucket: MarketLevelDemographicBucket) {
    const count = this.getCount(bucket);
    const isAbsoluteCount = this.marketLevelDemographic.id_count == 'absolute';

    if (isAbsoluteCount) {
      const maxCount = max(this.marketLevelDemographic.market_level_demographic_buckets.map((b) => this.getCount(b)));
      return (count / maxCount * 100);
    } else if (this.insightsContext === 'explore') {
      const totalCount = sumBy(this.marketLevelDemographic.market_level_demographic_buckets.map((b) => this.getCount(b)));
      return (count / totalCount * 100);
    } else {
      return ((count / sumBy(this.selectedSubMarkets, "population")) * 100);
    }
  }

  getCountLabel(bucket: MarketLevelDemographicBucket) {
    return toMillions(this.getCount(bucket));
  }

  get vertical(): boolean {
    return /vertical/.test(this.marketLevelDemographic.chart_type);
  }

  get idCountTooltip(): string {
    if (this.marketLevelDemographic.id_count === 'percentage') {
      if (this.insightsContext === 'explore') {
        return 'Widget data shown as percentages. Edit the widget to change.'
      } else {
        return 'Widget percentage data by chart. Edit the widget to change.';
      }
    } else if (this.marketLevelDemographic.id_count === 'scaled') {
      return 'Widget data scaled by chart. Edit the widget to change.';
    } else if (this.marketLevelDemographic.id_count === 'absolute') {
      if (this.insightsContext === 'explore') {
        return 'Widget data shown as counts. Edit the widget to change.'
      } else {
        return 'Widget data shows absolute counts. Edit the widget to change.';
      }
    }
  }

  getColor(i) {
    if (this.colorScheme) {
      return this.colorScheme[i]
    }
  }

  get isIdCount(): string {
    return get(this.marketLevelDemographic, ['id_count'], 'percentage');
  }

}
