import { Unit } from "./unit.interface";
import { Category } from "./category.interface";
import * as Actions from './toolbox.actions'
import { keyBy, omit, values, cloneDeep } from 'lodash';
import { compareKey } from 'app/shared/utils/utils';
import { createSelector } from '@ngrx/store'
import {ResetToolbox} from "./toolbox.actions";

export interface State {
  units: {[id: string]: Unit};
  categories: {[id: string]: Category};
  unitUnderEdit?: Unit;
  categoryUnderEdit?: Category;
  clientId?: number;
  unitTypeId?: number;
  hiddenUnits?: {[id: string]: Unit};
}

const initialState: State = {
  units: null,
  categories: null
};

export function reducer(state = initialState, action: Actions.All ): State {
  switch (action.type) {
    case ResetToolbox.type:
      return initialState;
    case Actions.LoadUnit.type:
      return {
        ...state,
        unitUnderEdit: null,
        units: {...state.units, [action.unit.id]: action.unit}
      };
    case Actions.LoadUnits.type:
      const unit = action.units[0]
      return {
        ...state,
        units: keyBy(action.units, 'id'),
        clientId: unit ? unit.client_id : null,
        unitTypeId: unit ? unit.unit_type_id : null,
      };
    case Actions.LoadCategory.type:
      return {
        ...state,
        categoryUnderEdit: null,
        categories: {...state.categories, [action.category.id]: action.category}
      };
    case Actions.LoadCategories.type:
      return {
        ...state,
        categories: keyBy(action.categories, 'id')
      };
    case Actions.EditUnit.type:
      return {
        ...state,
        unitUnderEdit: action.unit,
      };
    case Actions.ResetEditUnit.type:
      return {
        ...state,
        unitUnderEdit: null,
      };
    case Actions.UpdateUnit.type:
      return {
        ...state,
        unitUnderEdit: null,
        units: {...state.units, [action.unit.id]: {...state.units[action.unit.id], ...action.unit} }
      };
    case Actions.HideUnit.type:
      return {
        ...state,
        units: omit(state.units, action.unit.id),
        unitUnderEdit: null
      };
    case Actions.DestroyUnit.type:
      return {
        ...state,
        units: omit(state.units, action.unit.id),
        unitUnderEdit: null
      };
    case Actions.EditCategory.type:
      return {
        ...state,
        categoryUnderEdit: action.category,
      };
    case Actions.UpdateCategory.type:
      return {
        ...state,
        categoryUnderEdit: null,
        categories: {...state.categories, [action.category.id]: action.category}
      };
    case Actions.UpdateCategoryPriorities.type:
      const prioritizedCategories = keyBy(action.categories, 'id');
      return {
        ...state,
        categories: {...state.categories, ...prioritizedCategories}
      };
    case Actions.DestroyCategory.type:
      const freshUnits: Unit[] = values(cloneDeep(state.units))
        .filter(unit => unit.category_id === action.category.id)
        .map(unit => {
          unit.status = false;
          return unit;
        })
      return {
        ...state,
        units: {...state.units, ...keyBy(freshUnits, 'id') as {[id: string]: Unit}},
        categories: omit(state.categories, action.category.id),
        categoryUnderEdit: null
      };
    default:
      return state;
  }
}

export const selectHiddenUnits = createSelector(
  (state: State) => state.units,
  units => values(units)
    .sort(compareKey('priority'))
    .filter(unit => unit.status === false)
);
