<div class="wrapper" *ngIf="attributeAnalysis" [class.no-scroll]="!!selectedCreative">

  <div class="header">
    <div class="attribute-name" [title]="attributeAnalysis.description">
      {{attributeAnalysis.description}}
    </div>
    <span class="attribute-score">
      Score <span class="value">{{attributeAnalysis.brandScore | percent:'1.2-2'}}</span>
    </span>
    <span class="attribute-score">
      Competitor's Score <span class="value">{{attributeAnalysis.competitorScore | percent:'1.2-2'}}</span>
    </span>
    <i class="fa fa-2x fa-times pull-right" (click)="closeClick.emit()"></i>
  </div>

  <div class="assets-bucket pull-left first">
    <div class="assets-bucket-header">Associated {{clientName}} Assets</div>
    <div *ngFor="let pic of sortedClientCreatives" class="asset-wrapper">
      <img [src]="creativeImageUrl(pic)" [alt]="pic.filename" [title]="pic.filename" (click)="selectCreative(pic)">
      <div class="img-score">{{getCreativeScore(pic) | percent: '1.2-2'}}</div>
    </div>
  </div>

  <div class="assets-bucket pull-left">
    <div class="assets-bucket-header">Associated {{competitorName}} Assets</div>
    <div *ngFor="let pic of sortedCompetitorCreatives" class="asset-wrapper">
      <img  [src]="creativeImageUrl(pic)" [alt]="pic.filename" [title]="pic.filename" (click)="selectCreative(pic)">
      <div class="img-score">{{getCreativeScore(pic) | percent: '1.2-2'}}</div>
    </div>
  </div>

  <app-slide-in-overlay #creativeDetailSlideIn
    [percentOfParentCovered]="85"
    [active]="!!selectedCreative"
    (backgroundClick)="unselectCreative()"
  >
    <ppc-creative-detail-attribute-list
      (closeClicked)="unselectCreative()"
      [selectedCreative]="selectedCreative"
      [productId]="productId"
    ></ppc-creative-detail-attribute-list>
  </app-slide-in-overlay>

</div>
