<div class="gwi-widget">
  <div class="gwi-widget-header light-bg">
    <div class="title">GWI Surveys</div>
    <div class="count-toggle">
        <div>Percentage</div>
        <mat-slide-toggle [(ngModel)]="showCounts" (ngModelChange)="setShowCountsPreference()"></mat-slide-toggle>
        <div>Count</div>
    </div>
  </div>
  <div class="gwi-widget-breadcrumbs  light-bg" *ngIf="breadcrumbs.length > 1">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let i=index">
      <span (click)="goTo(i + 1)" class="gwi-widget-breadcrumb">{{ breadcrumb }}</span><span *ngIf="i != breadcrumbs.length - 1"> &gt; </span>
    </ng-container>
  </div>
  <div class="gwi-widget-body">
    <div class="gwi-widget-category-levels-container light-bg">
      <div class="gwi-widget-category-levels" [style.width.%]="100 * categoryLevels.length" [style.transform]="levelsTransform">
        <div *ngFor="let categoryLevel of categoryLevels; let idx=index;"
             class="gwi-widget-category-level"
             [style.width.%]="100 / categoryLevels.length">
             <div class="gwi-widget-category-level-actions" *ngIf="idx != 0" (click)="goTo(breadcrumbs.length - 1)">
               <i class="fa fa-chevron-left" ></i> <span>Back</span>
             </div>
             <div *ngFor="let category of categoryLevel"
                  class="gwi-widget-category"
                  [class.selected]="activeCategory == category"
                  [class.has-selected-response]="isChildSelected(category)"
                  (click)="setActiveCategory(category)">
                  {{ category.name }}
                  <ppc-context-menu>
                    <button matRipple (click)="clearFilters(category)">
                      Clear Filters
                    </button>
                  </ppc-context-menu>
                </div>
          </div>
      </div>
    </div>
    <div class="gwi-widget-questions light-bg">
      <ppc-gwi-survey-question  *ngFor="let question of questions; let i=index"
                                (setActiveGWIQuestions)="setActiveGWIQuestions()"
                                [categoryName]="activeCategory.name"
                                [question]="question"
                                [showCounts]="showCounts"
                                [index]="i + 1"></ppc-gwi-survey-question>
    </div>
  </div>
</div>
