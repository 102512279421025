import {throwError as observableThrowError, combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PpcHttpService } from 'app/services/ppc_http.service';
import { HttpResponse } from '@angular/common/http';

import {Plan} from "./plan.model";
import {ClearPlans, FetchPlans, LoadPlans, SetCurveTypeNames} from "./plans.actions";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {fetchResource} from "../shared/utils/fetch-state";
import {ChangeContext} from "../hierarchy/hierarchy.actions";
import {LoadCurveTypes} from "./curves/curves.actions";
import {fullContext} from "app/hierarchy/hierarchy.reducers";
import {HierarchyClient} from 'app/hierarchy/hierarchy.interface';
import {Store} from "@ngrx/store";
import {AppState} from '../reducers';
import { handleError } from 'app/shared/utils/errors';
import { scenarioPlannerUrl } from '../shared/constants/plans.urls';

@Injectable()
export class PlanService {

  @Effect()
  fetchPlans$ = this.actions$.pipe(
    ofType(FetchPlans.type),
    (fetchResource(
      () => this.getProductPlans().pipe(map(plans => new LoadPlans(plans)))
    )));

  @Effect()
  contextChanges$ = this.actions$.pipe(
    ofType(ChangeContext.type),
    map(action => new ClearPlans()));

  @Effect()
  loadCurveTypes$ = observableCombineLatest(
    this.actions$.pipe(ofType(LoadCurveTypes.type)),
    this.actions$.pipe(ofType(LoadPlans.type))
  ).pipe(
    map(([curveTypesAction, _]) => new SetCurveTypeNames((curveTypesAction as LoadCurveTypes).curveTypes)));

  activeClient: HierarchyClient = null;

  constructor (
    private store: Store<AppState>,
    private http: PpcHttpService,
    private actions$: Actions
  ) {
    fullContext(this.store).subscribe(
      ({client}) => this.activeClient = client
    );
  }

  plansForProductURL(): string {
    return scenarioPlannerUrl();
  }

  getPlanURL(planId: string): string {
    return `${scenarioPlannerUrl()}/${planId}`;
  }

  getAssociateChannelToPlanURL(planId: string): string {
    return `${scenarioPlannerUrl()}/${planId}/associate_channel`;
  }

  unassignedPlansURL() {
    return `${scenarioPlannerUrl()}/unassigned`;
  }

  planByChannelURL(channelId: string): string {
    return `${scenarioPlannerUrl()}/by_channel?channel_id=${channelId}`;
  }

  transformPlanResponse(res: any, override): Plan[] {
    return (override)
      ? (res.data as Plan[]).map(plan => new Plan({...plan, override: true}))
      : (res.data as Plan[]).map(plan => new Plan(plan));
  }

  getPlan(planId: string): Observable<any> {
    // TODO: Delete this once reach and penetration are separate curve types PAUD-1009
    return this.http.get(this.getPlanURL(planId)).pipe(
      map((json: any) => this.activeClient.curve_label_override
        ? new Plan({...json.data, override: true})
        : new Plan(json.data)));
  }

  getProductPlans(): Observable<any> {
    return this.http.get(this.plansForProductURL()).pipe(
      // TODO: Delete this once reach and penetration are separate curve types PAUD-1009
      map(res => this.transformPlanResponse(res, this.activeClient.curve_label_override)));
  }

  associateChannelToPlan(planId: string, channelId: string): Observable<any> {
    const body = {channel_id: channelId};
    return this.http.post(this.getAssociateChannelToPlanURL(planId), body).pipe(
      catchError(err => observableThrowError(err)), )
  }

  getUnassignedPlans(): Observable<Plan[]> {
    return this.http.get(this.unassignedPlansURL()).pipe(
      // TODO: Delete this once reach and penetration are separate curve types PAUD-1009
      map(res => this.transformPlanResponse(res, this.activeClient.curve_label_override)),
      catchError(handleError), );
  }

  getPlanForChannelId(channelId: string): Observable<Plan> {
    return this.http.get(this.planByChannelURL(channelId)).pipe(
      // TODO: Delete this once reach and penetration are separate curve types PAUD-1009
      map((res: any) => (this.activeClient.curve_label_override)
        ? (new Plan({...res.data, override: true}))
        : (new Plan(res.data))
      ));
  }

  deletePlan(planId: string): Observable<any> {
    return this.http.delete(this.getPlanURL(planId)).pipe(
      map((res: HttpResponse<any>) => {
        const body = res;
        return body;
      }),
      catchError(handleError), );
  }

}
