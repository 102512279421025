import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import {ActivatedRoute, Router} from "@angular/router";
import {PpcHttpService} from '../services/ppc_http.service';
import {AppState} from "../reducers";
import {select, Store} from "@ngrx/store";
import {EditUser, FetchAllUsers, SetEditUser} from "app/users/user.actions";
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {keyBy, values, get} from "lodash";
import { MatSnackBar } from "@angular/material/snack-bar";
import {isFetchInFlight} from "app/shared/utils/fetch-state";
import {compareKey} from "app/shared/utils/utils";
import {Role} from '../admin/role-admin/role.interface';
import {UserService} from '../users/user.service';
import {fullName, newUser, User, uniqUserHash} from '../users/user.interface';
import {map, takeUntil, distinctUntilChanged, debounceTime} from "rxjs/operators";
import {environment} from 'environments/environment'
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { agencyUrl, loginReportUrl, rolesUrl, userReportUrl } from 'app/shared/constants/auth.urls';

export interface UserForm {
  first_name: string;
  last_name: string;
  email: string;
  roles?: Role[];
  role_ids: number[];
  agency_id: number;
  lion_login?: string;
  okta_login?: string;
  product_ids: string[];
  deleted_at?: string;
}

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.sass']
})
export class UserSettingsComponent implements OnDestroy, AfterViewInit {

  ngUnsubscribe = new Subject();
  roles$ = this.http.get(rolesUrl()).pipe(map(roles => roles.filter(r => r.user_type === 'human')));
  agencies$ = this.http.get(agencyUrl())

  // TODO: update these once super_admin is no longer an exceptional case
  // and we have created resources/permissions for these
  canViewUserReport$ =  this.store.select('permissions', 'user_report', 'read')
  canViewLoginReport$ = this.store.select('permissions', 'login_records', 'read')

  canDestroyLogin$ = this.store.select('permissions', 'login', 'destroy');
  canViewAs$ = this.store.select('permissions', 'view_as', 'create').pipe(map(canViewAs => canViewAs && environment.viewAs));
  isLoading$ = this.store.select("fetchStates", FetchAllUsers.type).pipe(select(isFetchInFlight));

  user$ = this.store.select("users").pipe(select("userUnderEdit"));

  userStates = ['Active', 'Disabled', 'All'];
  userState$ = new BehaviorSubject('Active')

  userList$ = combineLatest(this.store.select('users', 'users'), this.userState$, this.agencies$).pipe(
    map(([ users, userState, agencies ]) => {
      const agenciesById = keyBy(agencies, 'id')
      return values(users).map(user => ({...user, agencyName: get(agenciesById, [user.agency_id, 'name'], 'Unknown')}))
        .sort(compareKey('email'))
        .filter(user => userState === 'Active'   ? !user.deleted_at
          : userState === 'Disabled' ? !!user.deleted_at
            : true)
    })
  )
  uniqUserHash = uniqUserHash;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: PpcHttpService,
    private snackbar: MatSnackBar,
    private store: Store<AppState>,
    private userService: UserService,
    private _scrollToService: ScrollToService,
    private location: Location,
  ) {
    this.store.dispatch(new FetchAllUsers());
  }

  ngAfterViewInit(): void {
    combineLatest(
      this.userList$,
      this.route.queryParams
    ).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([_, scrollUser]) => {
      const targetId = get(scrollUser, ['uniqUser']);
      if (targetId) {
        this.triggerScrollTo(scrollUser.uniqUser)
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  userStateUpdate(userState) {
    this.userState$.next(userState);
  }

  confirmViewAs(user: User): void {
    this.userService.switchUser(user.id).subscribe(
      () => {},
      () => this.snackbar.open(`An error occurred during user switching!`, null, {
        duration: 4000,
        panelClass: ['danger']
      })
    )
  }

  confirmDisableUser(user: User) {
    this.userService.disableUser(user.id).subscribe(
      ({ user }) => {
        this.store.dispatch(new EditUser(user));
        this.confirmUserStatusSuccess(`${fullName(user)} disabled successfully.`);
        this.triggerScrollTo(uniqUserHash(user));
      },
      err => this.confirmUserStatusFailure(`Disabling ${fullName(user)} failed!`)
    );
  }

  editPermissions(user: User) {
    this.router.navigate([user.id], { relativeTo: this.route });
  }

  onActivateUserClick(user: User) {
    this.store.dispatch(new SetEditUser(user, true));
  }

  onEditUserClick(user: User) {
    this.store.dispatch(new SetEditUser(user));
  }

  onCreateUserClick() {
    this.store.dispatch(new SetEditUser(newUser()));
  }

  downloadUserReport() {
    this.http.getDownload(userReportUrl()).subscribe(
      res => this.http.downloadFile(res, 'user_report.csv'))
  }

  downloadLoginReport() {
    this.http.getDownload(loginReportUrl()).subscribe(
      res => this.http.downloadFile(res, 'login_records.csv'))
  }

  triggerScrollTo(target: string) {
    const config: ScrollToConfigOptions = {
      target: target
    };
    this._scrollToService.scrollTo(config);
    this.location.replaceState(this.location.path().split('?')[0], '');
  }

  private confirmUserStatusSuccess(msg): void {
    this.snackbar.open(msg, null, {
      duration: 4000,
      panelClass: ['check']
    });
  }

  private confirmUserStatusFailure(msg): void {
    this.snackbar.open(msg, null, {
      duration: 4000,
      panelClass: ['danger']
    });
  }

}
