<ppc-loading-overlay *ngIf="loading$ | async"></ppc-loading-overlay>

<div class="page-container home">
  <div class="home-header">
    <div class="page-title">
      <span class="category">PeopleCloud </span>
      <span *ngIf="userService.isUserPosing$ | async">Viewing as </span>
      <span *ngIf="userFullName$ | async">{{userFullName$ | async}}</span>
    </div>
  </div>

  <div class="home-container" *ngIf="!(loading$ | async)">
    <div class="left-pane">
      <ppc-projects></ppc-projects>
      <ppc-charts-box *ngIf="canAccessGrow$ | async" [hierarchyPath]="hierarchyPath" [isFullContext]="isFullContext"></ppc-charts-box>
      <ppc-audiences-box *ngIf="canAccessAudiences$ | async" [hierarchyPath]="hierarchyPath" [isFullContext]="isFullContext"></ppc-audiences-box>
      <div *ngIf="!(canAccessGrow$ | async) && !(canAccessAudiences$ | async)">
        <p class="welcome-title">Welcome to PeopleCloud Discovery!</p>
        <span class="welcome-body">To begin using the PeopleCloud Discovery functionality that you have access to, please select from the various feature modules available along the left side of the screen.</span>
      </div>
    </div>
    <div class="right-pane">
      <div class="widget">
        <h4>Introducing your Home Page</h4>
        <a class="btn button" (click)="openWidget('home')">Learn more</a>
      </div>
      <div class="widget">
        <h4>Brand and Product Setup</h4>
        <a class="btn button" (click)="openWidget('brandAndProduct')">Learn more</a>
      </div>
      <div class="widget" [ngStyle]="{'display': 'none'}">
        <h4>Accessing the Help Center</h4>
        <a class="btn button" (click)="openWidget('help')">Learn more</a>
      </div>
    </div>
  </div>
</div>
