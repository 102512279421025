import * as actions from "./journey.actions";
import { Journey, JourneyTabType } from "./journey.models";
import { createSelector } from "@ngrx/store";
import { get, keyBy, omit, find, map, sortBy, reject, filter, values, set } from 'lodash';

export interface State {
  journeys: {[journeyId: number]: Journey};
  selectedJourneyId?: number;
  selectedBrandId?: number;
  journeyTab: JourneyTabType;
  selectedStageIds: number[];
  defaultJourneyId?: number;
}

export const defaultState: State = {
  journeys: {},
  journeyTab: "Matched",
  selectedStageIds: [],
}

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.LoadJourneys.type:
      return {...state, journeys: keyBy(action.journeys, "id")}
    case actions.LoadJourney.type:
      return {...state, journeys: {...state.journeys, [action.journey.id]: action.journey}}
    case actions.SetSelectedJourneyId.type:
      return {...state, selectedJourneyId: action.journeyId}
    case actions.SetSelectedBrandId.type:
      return {...state, selectedBrandId: action.brandId}
    case actions.RemoveJourney.type:
      return {...state, journeys: omit(state.journeys, action.journeyId)}
    case actions.SetJourneyTab.type:
      return {...state, journeyTab: action.tab}
    case actions.ToggleStage.type:
      return {...state, selectedStageIds: state.selectedStageIds.includes(action.stage.id) ? reject(state.selectedStageIds, id => id === action.stage.id) : [...state.selectedStageIds, action.stage.id]}
    case actions.ClearSelectedStages.type:
      return {...state, selectedStageIds: []}
    case actions.LoadDefaultJourney.type:
      return {...state, defaultJourneyId: action.journeyId}
    default:
      return state
  }
}

export const selectJourney = journeyId => {
  return createSelector(
    (state: State) => state.journeys,
    (state: State) => state.defaultJourneyId,
    (journeys, defaultJourneyId) => {
      const journey = get(journeys, journeyId);
      return journey && set(journey, 'default', journeyId == defaultJourneyId)
    }
  )
}

export const selectJourneys = createSelector(
  (state: State) => state.journeys,
  (state: State) => state.defaultJourneyId,
  (journeys, defaultJourneyId) => {
    return sortBy(
      map(
        values(journeys),
        journey => ({
          ...journey,
          default: journey.id == defaultJourneyId
        })
      ),
      [journey => journey.name.toLocaleLowerCase()]
    );
  }
)

export const selectSelectedJourney = createSelector(
  (state: State) => state.selectedJourneyId,
  (state: State) => state.journeys,
  (selectedJourneyId, journeys) => {
    return get(journeys, selectedJourneyId)
  }
)

export const selectSelectedBrand = createSelector(
  selectSelectedJourney,
  (state: State) => state.selectedBrandId,
  (journey, id) => {
    if (journey) {
      return find(journey.brands, {id})
    }
  }
)

// These are implicitly selected
export const selectActiveStages = createSelector(
  selectSelectedJourney,
  (state: State) => state.selectedStageIds,
  (journey, stageIds) => {
    if (!journey) {return []; }
    if (stageIds.length) {
      return filter(journey.stages, stage => stageIds.includes(stage.id))
    } else {
      return journey.stages;
    }
  }
)

// These are explicity selected
export const selectSelectedStages = createSelector(
  selectSelectedJourney,
  (state: State) => state.selectedStageIds,
  (journey, stageIds) => {
    if (!journey) {return []; }
    return filter(journey.stages, stage => stageIds.includes(stage.id))
  }
)

export const selectActiveJourneySubMarkets = createSelector(
  selectSelectedJourney,
  (state: State) => state.selectedBrandId,
  (journey, selectedBrandId) => {
    if (!journey) {return []; }
    return filter(journey.sub_markets, { journey_brand_id: selectedBrandId });
  }
)

// All stages for the selected brand regardless of stage selection
export const selectActiveStagesForSelectedBrand = createSelector(
  selectSelectedJourney,
  selectActiveJourneySubMarkets,
  (journey, subMarkets) => {
    if (!journey) {return []; }
    const stageIdsForBrand = map(subMarkets, "journey_stage_id");
    return filter(journey.stages, stage => stageIdsForBrand.includes(stage.id));
  }
)

export const selectSelectedJourneySubMarkets = createSelector(
  selectSelectedJourney,
  (state: State) => state.selectedBrandId,
  (state: State) => state.selectedStageIds,
  (journey, selectedBrandId, stageIds) => {
    if (!journey) {return []; }
    const subMarkets = filter(journey.sub_markets, { journey_brand_id: selectedBrandId });
    return stageIds.length ? subMarkets.filter((sm) => stageIds.includes(sm.journey_stage_id)) : subMarkets;
  }
)

// These are explicity selected
export const selectSelectedJourneySubMarketIds = createSelector(
  selectSelectedJourneySubMarkets,
  (state: State) => state.selectedStageIds,
  (subMarkets, stageIds) => {
    return map(filter(subMarkets, sm => stageIds.includes(sm.journey_stage_id)), "id");
  }
)
