<div class="form-container">
  <div *ngIf="wasPasswordReset">Your password has been reset</div>
  <form (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="model.username" name="username" #username="ngModel" spellcheck="false" required>
      <mat-placeholder>
        USERNAME
      </mat-placeholder>
      <mat-hint class="login-error">
        <div [hidden]="username.valid || ( username.pristine && !f.submitted )">
          Username is required
        </div>
      </mat-hint>
    </mat-form-field>

    <mat-form-field>
      <input matInput type="password" [(ngModel)]="model.password" name="password" #password="ngModel" required>
      <mat-placeholder>
        PASSWORD
      </mat-placeholder>
      <mat-hint class="login-error">
        <div [hidden]="password.valid || ( password.pristine && !f.submitted )">
          Password is required
        </div>
      </mat-hint>
    </mat-form-field>

    <div *ngIf="error" class="login-error-message">{{error}}</div>

    <button mat-button type="submit">
      let's go
    </button>
  </form>
</div>

<p class="support-text">
  <span class="support-email">
    <a routerLink="/request-password-reset">Forgot Password?</a>
  </span>
</p>
