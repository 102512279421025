import { get, capitalize, compact, find } from 'lodash';
import { VendorDisplayName } from 'app/segments-hierarchy/segments-hierarchy.reducer';
import { SegmentLike } from '../../models/segment-like.model';

export interface SegmentV2 {
  id_space: string;
  identifier: string;
  name: string;
  description: string;
  vendor_name: string;
  path?: SegmentPath;
  count?: SegmentCounts;
  created_at: string;
  has_leaf_children: boolean;
  vendor_type?: string;
  owner_name?: string;
}

export interface SegmentPath {
  names: string[];
  identifiers: string[];
  is_leaf: boolean;
  is_root: boolean;
}

// `country` here is usually an ISO country code but can also be 'global'
export interface SegmentCounts {
  people: {[country: string]: number}
  devices: {[country: string]: number}
  cookies: {[country: string]: number}
}

export function getCount(type: 'devices' | 'cookies' | 'people', segment: SegmentV2, region = 'global') {
  return get(segment, ['count', type, region], 0)
}

export function fullPathParts(segment: SegmentV2|SegmentLike): string[] {
  return compact([capitalize(segment.vendor_name), ...get(segment.path, "names", []) as string[], segment.name])
}

export function pathString(segment: SegmentV2, vendorDisplayNames: VendorDisplayName[]): string {
  return prettyPathParts(segment, vendorDisplayNames).join(' > ')
}

export function subPath(segment: SegmentV2, vendorDisplayNames: VendorDisplayName[], start, end?): string {
  return prettyPathParts(segment, vendorDisplayNames).slice(start, end).join(' > ')
}

export function prettyPathParts(segment: SegmentV2|SegmentLike, vendorDisplayNames: VendorDisplayName[]): string[] {
  if (!segment) { return []; }
  const parts = fullPathParts(segment);
  // TODO: add default for outcome audiences
  switch (segment.vendor_name) {
    case "acxiom":
      parts.shift();
      break;
    case "eyeota":
      parts.shift();
      parts[0] = "Eyeota";
      break;
    case "bluekai":
      parts.shift();
      parts[0] = "Bluekai";
      break;
    case "unbranded_st":
      parts[0] = "Web Browsing";
      break;
    case "dunkin-donuts":
      parts[0] = "Dunkin Donuts";
      break;
    case "kellogg-s":
      parts[0] = "Kellogg's";
      break;
    case "macy-s":
      parts[0] = "Macy's";
      break;
    case "epsilon":
      parts[0] = "Epsilon";
      break;
    case "conversant media platform":
      parts.shift();
      parts[0] = "Epsilon";
      break;
    case "custom":
      parts[0] = "Custom Audience";
      break;
    case "xinfer":
      parts[0] = "Lookalikes";
      break;
    case "liveramp":
      parts[0] = "NCS";
      break;
    case "gwi": // Specifically for tier 3
      parts.shift();
      parts[0] = "GWI";
      break;
    case "gwi_core":
      parts.shift();
      parts[0] = "GWI";
      break;
    case "gwi_pace":
      parts.shift();
      parts[0] = "GWI";
      break;
    case "gwi_custom":
      parts.shift();
      parts[0] = "GWI";
      break
    case "nielsen":
      break;
    case "ncs":
      break;
    case "iri":
      break;
    case "toolbox":
      break;
    case "weborama_affinity":
      parts[0] = ("Weborama");
      break;
    case "weborama_ppc_custom":
      parts.unshift("Weborama");
      break;
    case "ninth_decimal":
      parts[0] = "Ninth Decimal";
      break;
    case "krux":
      break
    case "adobe":
      break
    case "ppc":
      switch (segment.vendor_type && segment.vendor_type.toLowerCase()) {
        case "vertical":
          parts[0] = "PPC";
          parts[1] = "Verticals";
          break;
        case "interests":
          parts.shift();
          parts[0] = "Interests";
          break;
        case "audience":
          parts[0] = "Custom Audience";
          break;
        case "lookalike":
          parts[0] = "Lookalikes";
          break;
        case "motivation":
          parts[0] = "Motivation Audience";
          break;
      }
      break;
  }

  const vendor = find(vendorDisplayNames, ['value', segment.vendor_name]);
  if (vendor) {
    parts[0] = get(vendor, 'label');
  }

  return parts
}
