
import * as d3 from 'd3';
import { sortBy, reverse } from 'lodash';

import { ClusterConstant } from 'app/insights/insights-components/market-level-discussions/cluster.constants';


export interface ClusterData {
  nodes: ClusterRawNode[];
  reachable_people: { [id: string]: number };
}

interface ClusterRawNode {
  cluster_id?: string;
  discussion_cluster_id?: string;
  ct: number;
  id: number;
  nm: string;
  sz: number;
}

interface ClusterNode {
  id: number;
  cluster: string;
  radius: number;
  name: string;
  sz: number;
  color: string;
  shortName: string;
  fontSize: string;
}

export interface Opts {
  sortByLargest: boolean,
  nodeLimit?: number,
  averageSize?: number,
  maxAllowedRadius?: number
}

export class Cluster {
  nodes: ClusterNode[] = [];
  reachablePeople: { [id: string]: number };

  private color = d3.scaleOrdinal()
    .domain(ClusterConstant.COLOR_DOMAIN)
    .range(ClusterConstant.COLOR_SCHEME);

  constructor(data: ClusterData, opts: Opts) {
    this.reachablePeople = data.reachable_people;
    if (data.reachable_people) {
      this.reachablePeople["0"] = data.reachable_people[""];
    }
    this.setNodes(data.nodes, opts);
  }

  private setNodes(rawNodes: ClusterRawNode[], opts: Opts) {
    // TODO: limit data number return from api
    if (!rawNodes) {return; }
    const numLimit = opts.nodeLimit || 100;
    if (opts.sortByLargest) {
      rawNodes = reverse(sortBy(rawNodes, 'sz'));
    }
    const limitedRawNodes = rawNodes.slice(0, numLimit);
    const maxSize = this.maxCircleSize(limitedRawNodes);
    this.nodes = limitedRawNodes.map(obj => ({
      id: obj.id,
      cluster: obj.discussion_cluster_id || obj.cluster_id || "0",
      radius: this.normalizedSize(obj.sz, opts.averageSize || maxSize, opts.maxAllowedRadius),
      name: obj.nm,
      sz: obj.sz,
      color: this.color(obj.discussion_cluster_id || obj.cluster_id),
      shortName: this.shortName(obj.nm, +obj.sz),
      fontSize: this.textFontSize(+obj.sz, obj.nm)
    }))
  }

  private maxCircleSize(nodes: ClusterRawNode[]): number {
    return Math.max(0, ...nodes.map(n => n.sz));
  }

  private normalizedSize(size: number, maxSize: number, maximumRadius: number): number {
    return (size / maxSize) * maximumRadius;
  }

  private shortName(name, radius): string {
    return name.substring(0, radius / 3)
  }

  private textFontSize(radius, text): string {
    const len = text.substring(0, radius / 3).length;
    let size = radius / 3;
    size *= 6 / len;
    size += 1;
    return Math.round(size) + 'px';
  }
}
