<div>
  <ppc-checkbox
    [stateless]="true"
    [checked]="status === 'indeterminate' || status === 'checked'"
    [indeterminate]="status === 'indeterminate'"
    (change)="onCheck(status !== 'checked')"
  >
  </ppc-checkbox>
  <span *ngIf="status === 'indeterminate'">(partial)</span>
  <span (click)="open = !open" class="pointer">{{getName(node)}}</span>
  <button *ngIf="getChildren(node).length" (click)="open = !open" matRipple [matRippleCentered]="true">
    <i class="fa fa-caret-right fa-fw fa-2x"  [class.rotate-90]="open"></i>
  </button>
  <ng-container *ngIf="open">
    <div *ngFor="let child of getChildren(node)" class="children">
      <tree-node-select
        [node]="child"
        [getChildren]="getChildren"
        [getName]="getName"
        [path]="nextPath"
        [getStatus]="getStatus"
        (change)="this.change.emit($event)"
      ></tree-node-select>
    </div>
  </ng-container>
</div>
