import { Injectable } from '@angular/core';
import * as actions from "app/snapshots/snapshots.actions";
import * as mekkoActions from "app/mekko/mekko.actions"
import {map} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import {Observable} from "rxjs";
import {HttpClient} from '@angular/common/http';
import {Actions, Effect, ofType} from "@ngrx/effects";
import {fetchResource } from "app/shared/utils/fetch-state";
import {Snapshot} from "app/snapshots/snapshots.reducer";
import { snapshotsUrl, snapshotUrl } from 'app/shared/constants/insights.urls';

@Injectable({
  providedIn: 'root'
})

export class SnapshotsService {
  @Effect()
  fetchSnapshots$ = this.actions$.pipe(
    ofType(actions.FetchSnapshots.type),
    (
      fetchResource(
        action => this.getSnapshots(action.mekko_id).pipe(map(snapshots => new actions.LoadSnapshots(snapshots)))
      )
    ));

  @Effect()
  fetchSnapshot$ = this.actions$.pipe(
    ofType(actions.FetchSnapshot.type),
    fetchResource(
      action => this.getSnapshot(action.id).pipe(map(snapshot => new actions.LoadSnapshot(snapshot)))
    )
  );

  @Effect()
  saveSnapshot$ = this.actions$.pipe(
    ofType(actions.SaveSnapshot.type),
    fetchResource(
      action => this.saveSnapshot(action.snapshot).pipe(map(snapshot => new actions.LoadSnapshot(snapshot)))
    )
  );

  @Effect()
  saveCurrentConfidence$ = this.actions$.pipe(
    ofType(actions.SaveCurrentConfidence.type),
    fetchResource(
      action => this.saveCurrentConfidence(action.snapshotId, action.subMarketId, action.currentConfidence).pipe(
        map(() => new actions.LoadCurrentConfidence(action.snapshotId, action.subMarketId, action.currentConfidence))
      )
    )
  )

  @Effect()
  destroySnapshot$ = this.actions$.pipe(
    ofType(actions.DestroySnapshot.type),
    fetchResource(
      ({ snapshot }) => this.destroySnapshot(snapshot).map(snapshot => new actions.RemoveSnapshot(snapshot))
    )
  );


  @Effect()
  clearActiveSnapshot$ = this.actions$.pipe(
    ofType(mekkoActions.SetSelectedMekko.type),
    map(() => new actions.ClearActiveSnapShot()));

  constructor(private http: HttpClient, private actions$: Actions, private store: Store<AppState>) { }

  saveSnapshot(snapshot: Snapshot): Observable<Snapshot> {
    return this.http.post(
      snapshotsUrl(), {snapshot: snapshot}
    ) as Observable<Snapshot>
  }

  destroySnapshot(snapshot: Snapshot): Observable<Snapshot> {
    return this.http.delete(snapshotUrl(snapshot.id)) as Observable<Snapshot>
  }

  getSnapshot(id: number): Observable<Snapshot> {
    return this.http.get(snapshotUrl(id)) as Observable<Snapshot>
  }

  getSnapshots(mekko_id: number): Observable<Snapshot[]> {
    return this.http.get(snapshotsUrl(mekko_id)) as Observable<Snapshot[]>
  }

  saveCurrentConfidence(snapshot_id: number, sub_market_id: number, current_confidence: number) {
    return this.http.post(snapshotUrl('update_current_confidence'), {
      snapshots_sub_market: { snapshot_id, sub_market_id, current_confidence }
    });
  }

}
