import {distinctUntilChanged, filter, map, takeUntil, tap} from 'rxjs/operators';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {select, Store} from "@ngrx/store";
import {LoggingService} from './services/logging.service';
import {ScrollService} from "./services/scroll.service";
import {AppState} from "./reducers/index";
import {selectAllErrors, isLoaded} from "./shared/utils/fetch-state";
import {DEFAULT_ERROR_MSG} from "./ppc-error-handler";
import {PpcTooltipService} from './shared/components/ppc-tooltip/ppc-tooltip.service';
import {Subject, combineLatest as observableCombineLatest, Observable, of as observableOf} from "rxjs";
import {Location, PopStateEvent} from "@angular/common";
import {loggedInUser} from "app/users/user.reducer";
import {environment} from "environments/environment";
import { UserService } from 'app/users/user.service';
import { ThemeService } from 'app/theme/theme.service';
import { FetchHierarchy } from './hierarchy/hierarchy.actions';
import { UrlService } from 'app/services/url.service'
import { DataDogService } from './scripts/datadog.service';
import { omit } from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.sass'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild("sidenavContainer", { static: true }) private navContainer: ElementRef;

  ngUnsubscribe = new Subject();

  errorMsg: string;

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  hasIframe$ = this.urlService.currentUrl$.pipe(map(url => url.includes('toolbox-app')));

  hasClients$: Observable<boolean>
  loggedIn$ = this.store.select('users').pipe(select(loggedInUser), select(Boolean));

  constructor(
    private router: Router,
    private loggingService: LoggingService,
    private store: Store<AppState>,
    private scrollService: ScrollService,
    private location: Location,
    public userService: UserService,
    public themeService: ThemeService,
    private tooltipService: PpcTooltipService,
    private urlService: UrlService,
    private dataDogService: DataDogService
  ) {
    this.scrollService.scrollSource$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe( ({direction, element}) => this.scroll(direction, element) );

    this.router.events.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(event => event instanceof NavigationStart), )
      .subscribe(() => this.errorMsg = "");

    this.loggingService.getError().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(
        (msg: string) => this.errorMsg = msg
      );

    this.store.select('fetchStates').pipe(
      takeUntil(this.ngUnsubscribe),
      map(selectAllErrors),
      map(errors => errors.length),
      distinctUntilChanged(),
      filter(Boolean), )
      .subscribe(
        () => this.errorMsg = DEFAULT_ERROR_MSG
      );

    observableCombineLatest(
      this.loggedIn$,
      this.store.select('hierarchy', 'hierarchy', 'clients'),
      this.store.select('fetchStates', FetchHierarchy.type).pipe(select(isLoaded), filter(Boolean)),
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        ([_, clients]) => {
          this.hasClients$ = observableOf(!!clients.length)
        }
      );
  }

  ngOnInit() {
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(this.navContainer.nativeElement.scrollTop);
        }
      } else if (ev instanceof NavigationEnd) {
        if (ev.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          this.navContainer.nativeElement.scrollTop =  this.yScrollStack.pop();
        } else {
          this.navContainer.nativeElement.scrollTop = 0;
        }
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private scroll(direction: string, element: HTMLElement = null): void {
    switch (direction) {
      case "bottom":
        return this.scrollToBottom(element);
      case "right":
        return this.scrollToRight(element);
    }
  }

  private scrollToBottom(elementToScroll: HTMLElement = this.navContainer.nativeElement as HTMLElement): void {
    elementToScroll.scrollTop = this.navContainer.nativeElement.scrollHeight;
  }

  private scrollToRight(elementToScroll: HTMLElement = this.navContainer.nativeElement as HTMLElement): void {
    elementToScroll.scrollLeft = this.navContainer.nativeElement.scrollWidth;
  }

}
