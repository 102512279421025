export class VendorPermission {
  static ALL = "all";
  static ALLOW = 'allow';
  static INHERIT = 'inherit';
  static DENY = 'deny';

  vendor_id?: string;
  vendor_name?: string;
  client_id?: string;
  region_id?: string;

  permission: string;
  state: 'allow'|'inherit'|'deny';

  constructor(args) {
    Object.assign(this, args);
  }

  get isAllowed(): boolean {
    return this.state === VendorPermission.ALLOW;
  }
  get isInherited(): boolean {
    return this.state === VendorPermission.INHERIT;
  }
  get isDenied(): boolean {
    return this.state === VendorPermission.DENY;
  }

  get client(): string | 'all' {
    return this.client_id == null ? VendorPermission.ALL : this.client_id;
  }

  get region(): string | 'all' {
    return this.client_id == null ? VendorPermission.ALL : this.region_id;
  }
}
