<div class="ppc-searchable-dropdown" [class.open]="open" [class.show-input-underline]="showInputUnderline">
  <ppc-input #ppcInput class="ppc-searchable-dropdown-trigger"
                    (focusin)="open = !open; search.focus()"
                    [noErrorContainer]="true"
                    [value]="selectedItemsLabel"
                    [alwaysFloatLabel]="alwaysFloatLabel"
                    [noLabel]="noLabel">
    <label>{{label}}</label>
    <div prefix *ngIf="iconPrefix" class="icon-prefix">
      <ppc-icon [value]="iconPrefix"></ppc-icon>
    </div>
    <input type="text"/>
    <div suffix>
      <i class="fa fa-fw fa-close cursor" (click)="$event.stopPropagation();clear()" *ngIf="selectedItemsLabel && allowClear"></i>
      <i class="fa fa-fw fa-chevron-down cursor" [class.rotate-180]="open" (click)="open = !open; open && search.focus()"></i>
    </div>
  </ppc-input>
  <div class="ppc-searchable-dropdown-dropdown">
      <svg class="ppc-searchable-dropdown-caret" viewbox="0 0 15 7.5" [style.left.px]="ppcInput.input.nativeElement.clientWidth + 9">
          <path d="m 0 7.5 l 7.5 -7.5 l 7.5 7.5" stroke="#5a5a5a" fill="#313131"></path>
        </svg>
    <div class="ppc-searchable-dropdown-search-box" [class.hide]="!showSearch">
      <ppc-input #search [(ngModel)]="searchInput"
                          [noLabel]="true"
                          [noErrorContainer]="true">
        <div prefix>
          <i class="fa fa-search"></i>
        </div>
        <div suffix [class.hide]="!searchInput"
                    (click)="searchInput = '';search.focus()">
          <i class="fa fa-close"></i>
        </div>
        <input type="text" />
      </ppc-input>
    </div>
    <div class="ppc-searchable-dropdown-error" *ngIf="filteredItems.length == 0">
      <span>No Matches!</span>
    </div>
    <ul class="ppc-searchable-dropdown-items" *ngIf="filteredItems.length > 0">
      <ng-container *ngIf="buttonTemplate">
          <li *ngTemplateOutlet="buttonTemplate"></li>
      </ng-container>
      <li *ngFor="let item of filteredItems">
        <ng-container *ngIf="itemTemplate" [ngTemplateOutlet]="itemTemplate"  [ngTemplateOutletContext]="{ item: item }"></ng-container>
        <ng-container *ngIf="!itemTemplate">
          <ng-container *ngIf="multiSelect">
            <ppc-checkbox [checked]="isItemSelected(item)"
                          (change)="toggleItem(item)"
                          [showRipple]="false"
                          tabIndex="0"
                          [stateless]="true">{{item[itemLabelProp]}}</ppc-checkbox>
          </ng-container>
          <ng-container *ngIf="!multiSelect">
            <button class="single-select-item" (click)="toggleItem(item)" tabIndex="0" [disabled]="isItemDisabled(item)">
              {{item[itemLabelProp]}}
            </button>
          </ng-container>
        </ng-container>
      </li>
    </ul>
  </div>
</div>
