export type IndexReportResourceType = 'persona' | 'audience' | 'lookalike';
export type IndexReportLayoutType = 'advanced' | 'simplified';

export const DATASET_SKEW_DEF = [
  'Dataset Skew',
  'Some datasets are collected in a way that inherently biases the sample, leading to false results when indexing. Dataset Skew adjusts for these biases to provide more accurate index values.'
];
export const UNKNOWN_RATE_SKEW_DEF = [
  'Unknown Rate Skew',
  'Some attributes that logically should be mutually exclusive and cover the whole ID space are not. Many IDs have "Unknown" attributes and distort the index. Unknown Rate Skew adjusts for these "Unknown" attributes and provides more accurate index values.'
];

export const AUDIENCE_COLUMNS = ['D', 'H', 'L', 'P', 'T', 'X', 'AB', 'AF', 'AJ', 'AN'];
export const AUDIENCE_COLUMNS_2 = ['D', 'K', 'R', 'Y', 'AF', 'AM', 'AT', 'BA', 'BH', 'BO'];
export const MERGE_TO_COLUMN_1 = ['G', 'K', 'O', 'S', 'W', 'AA', 'AE', 'AI', 'AM', 'AQ'];
export const MERGE_TO_COLUMN_2 = ['J', 'Q', 'X', 'AE', 'AL', 'AS', 'AZ', 'BG', 'BN', 'BU'];

export const SIMPLIFIED_NUMBER_FORMAT = [
  ['D', 'F'],
  ['H', 'J'],
  ['L', 'N'],
  ['P', 'R'],
  ['T', 'V'],
  ['X', 'Z'],
  ['AB', 'AD'],
  ['AF', 'AH'],
  ['AJ', 'AL'],
  ['AN', 'AP']
];

export const ADVANCED_NUMBER_FORMAT = [
  ['F', 'I'],
  ['M', 'P'],
  ['T', 'W'],
  ['AA', 'AD'],
  ['AH', 'AK'],
  ['AD', 'AR'],
  ['AV', 'AY'],
  ['BC', 'BF'],
  ['BJ', 'BM'],
  ['BQ', 'BT']
];

export const SIMPLIFIED_COLUMNS_TO_COLOR = [
  ['D', 'E', 'F', 'G'],
  ['H', 'I', 'J', 'K'],
  ['L', 'M', 'N', 'O'],
  ['P', 'Q', 'R', 'S'],
  ['T', 'U', 'V', 'W'],
  ['X', 'Y', 'Z', 'AA'],
  ['AB', 'AC', 'AD', 'AE'],
  ['AF', 'AG', 'AH', 'AI'],
  ['AJ', 'AK', 'AL', 'AM'],
  ['AN', 'AO', 'AP', 'AQ']
];

export const ADVANCED_COLUMNS_TO_COLOR = [
  ['D', 'E', 'F', 'G', 'H', 'I', 'J'],
  ['K', 'L', 'M', 'N', 'O', 'P', 'Q'],
  ['R', 'S', 'T', 'U', 'V', 'W', 'X'],
  ['Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE'],
  ['AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL'],
  ['AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS'],
  ['AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ'],
  ['BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG'],
  ['BH', 'BI', 'BJ', 'BK', 'BL', 'BM', 'BN'],
  ['BO', 'BP', 'BQ', 'BR', 'BS', 'BT', 'BU']
];

export const INDEX_REPORT_COLORS = ["ADD8E6", "98FB98", "FFFF99", "EC99FF", "B699FF", "FF99B8", "F1FF99", "BEFF99", "99FFBE", "99F8FF"];

export interface IndexReportRules {
  ids?: string[];
  index_base?: string[];
}

export interface IndexReportResource {
  type: string;
  matched_count: number;
  name: string;
  id: number;
  product_slug: string;
  request_id: string;
  rules: Array<IndexReportRules>;
  index_base?: string;
  index_base_segment?: IndexReportSegmentDetail;
}

export interface IndexReportSegment {
  count: number;
  percent_value: number;
  nn: number;
  nd: number;
  dn: number;
  dd: number;
  numerator: number;
  denominator: number;
  index_value: number;
  name: string;
  vendor_name: string;
  vendor_type: string;
  owner: string;
  type: string;
  path: string[];
  partialSegmentPath?: string;
}

export interface IndexReportSegmentDetail {
  name: string;
  identifier: string;
  vendor_name: string;
  vendor_type: string;
  owner: string;
  path?: string[];
  type?: string;
  include_segments?: Array<IndexReportSegmentDetail[]>;
  exclude_segments?: Array<IndexReportSegmentDetail[]>;
}

// unique "persona" key in "segment_details" with an additional
// nesting key "included_segments"
// nesting key "excluded_segments" (future)
export interface IndexReportPersonaDetails {
  included_segments: Array<IndexReportSegmentDetail[]>;
  excluded_segments?: Array<IndexReportSegmentDetail[]>;
}

// response object we would see from downloading an index report from id-analytics
export interface IndexReport {
  resource: IndexReportResource;
  segments: { [identifier: string]: IndexReportSegment };
  segment_details: { [identifier: string]: IndexReportSegmentDetail | IndexReportPersonaDetails }
}
