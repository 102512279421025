import { Action } from "@ngrx/store";
import { DiscussionClusterNode } from 'app/insights/insights-components/market-level-discussions/discussion-cluster-nodes.interface';

export class FetchClusterNode implements Action {
  static readonly type = "DISCUSSION:FETCH_ClUSTER_NODE";
  readonly type = FetchClusterNode.type;

  constructor(public clusterNodeId: number) {}
}

export class FetchClusterNodes implements Action {
  static readonly type = "DISCUSSION:FETCH_ClUSTER_NODES";
  readonly type = FetchClusterNodes.type;

  constructor() {}
}

export class LoadClusterNode implements Action {
  static readonly type = "DISCUSSION:LOAD_CLUSTER_NODE";
  readonly type = LoadClusterNode.type;

  constructor(public clusterNode: DiscussionClusterNode) { }
}

export class LoadClusterNodes implements Action {
  static readonly type = "DISCUSSION:LOAD_CLUSTER_NODES";
  readonly type = LoadClusterNodes.type;

  constructor(public clusterNodes: any) { }
}

export class EditDiscussionClusterNode implements Action {
  static readonly type = "DISCUSSION:EDIT_DISCUSSION_CLUSTER_NODE";
  readonly type = EditDiscussionClusterNode.type;

  constructor(public discussionClusterNode: DiscussionClusterNode) {}
}

export class SaveDiscussionClusterNode implements Action {
  static readonly type = "DISCUSSION:SAVE_DISCUSSION_CLUSTER_NODE";
  readonly type = SaveDiscussionClusterNode.type;

  constructor(public clusterNode: DiscussionClusterNode) {}
}

export class DestroyDiscussionClusterNode implements Action {
  static readonly type = "DISCUSSION:DESTROY_DISCUSSION_CLUSTER_NODE";
  readonly type = DestroyDiscussionClusterNode.type;

  constructor(public clusterNodeId: number) {}
}

export class RemoveDiscussionClusterNode implements Action {
  static readonly type = "DISCUSSION:REMOVE_DISCUSSION_CLUSTER_NODE";
  readonly type = RemoveDiscussionClusterNode.type;

  constructor(public clusterNode: DiscussionClusterNode) {}
}

export type ReducerActions =
                | RemoveDiscussionClusterNode | FetchClusterNode | FetchClusterNodes | LoadClusterNode | LoadClusterNodes
                | EditDiscussionClusterNode | SaveDiscussionClusterNode | DestroyDiscussionClusterNode
