import { LookalikeV2 } from "app/lookalikes-v2/lookalike-v2.model";
import * as actions from "app/lookalikes-v2/lookalike-v2.actions";
import { differenceBy, findIndex } from 'lodash';
import { createSelector } from "@ngrx/store";
import { groupBy } from 'lodash';

export interface State {
  lookalikes: LookalikeV2[];
}

const defaultState = {
  lookalikes: []
};

export function reducer(state: State = defaultState, action: actions.All): State {
  switch (action.type) {
    case actions.LoadLookalikes.type:
      return {
        ...state,
        lookalikes: differenceBy(state.lookalikes, action.lookalikes, 'identifier').concat(action.lookalikes),
      };
    case actions.LoadLookalike.type:
      return { ...state, lookalikes: [ ...state.lookalikes, action.lookalike ]}
    case actions.ClearLookalikes.type:
      return defaultState;
    case actions.LoadUpdatedLookalike.type:
      const indexOfItem = findIndex(state.lookalikes, {id: action.lookalike.id});
      const newLals = state.lookalikes.slice();
      newLals.splice(indexOfItem, 1, action.lookalike);
      return {
        ...state,
        lookalikes: newLals,
      };
    case actions.RemoveLookalike.type:
      const indexToDelete = findIndex(state.lookalikes, {id: action.data.id})
      const nonDeletedLookalikes = state.lookalikes.slice();
      nonDeletedLookalikes.splice(indexToDelete, 1);
      return {
        ...state,
        lookalikes: nonDeletedLookalikes,
      };
    default:
      return state;
  }
}

export const selectLookalikes = (state: State) =>  state.lookalikes;

export const selectLookalikesByAudience = createSelector(
  selectLookalikes,
  (lookalikes: LookalikeV2[]) => groupBy(lookalikes, lookalike => lookalike.seed_identifier) as {[seedIdentifier: string]: LookalikeV2[]}
);

export const selectLookalikesWithType = createSelector(
  selectLookalikes,
  (lookalikes) => lookalikes.map(lookalike => ({...lookalike, type: "lookalike"}))
);
