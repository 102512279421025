import { AudienceV2 } from '../audiences-v2/audience-v2.model';
import * as actions from './audience-builder.actions';
import { BuilderAudience } from './audience-builder.models';

export interface State {
  prebuiltAudience: BuilderAudience
}

const defaultState = {
  prebuiltAudience: null,
};

export function reducer(state: State = defaultState, action: actions.ReducerActions): State {
  switch (action.type) {
    case actions.SetPrebuiltAudience.type:
      return {...state, prebuiltAudience: action.audience}
    default:
      return state;
  }
}
