<ppc-loading-overlay *ngIf="loading"></ppc-loading-overlay>
<header>
  <ppc-error
    [visible]="showErrorBox"
    [class.margin-bottom-60]="showErrorBox"
    topLevelMessage="Unable to Import"
    [errorMessages]="errorMessages"
    (closeAlert)="closeErrorBox()"
  ></ppc-error>
  <app-admin-breadcrumb></app-admin-breadcrumb>
  <h1>
    <span class="object-name">Scenario Planner Management</span>
  </h1>
</header>

<main>

  <import-scenario-plan
    [importedPlans]="importedPlans"
    (planImport)="planImport($event)"
    (planImportDelete)="planImportDelete($event)"
  ></import-scenario-plan>

  <div>
    <div>
      <h4>Curves</h4>
    </div>

    <div class="input-block">
      <mat-checkbox name="generic-curve-check" [(ngModel)]="useGenericCurves" (ngModelChange)="toggleCheckboxes('useGenericCurves','uploadCurves')">
        Use Generic Curves
      </mat-checkbox>
      <mat-checkbox name="upload-curve-check" [(ngModel)]="uploadCurves" (ngModelChange)="toggleCheckboxes('uploadCurves', 'useGenericCurves')">
        Upload Curves
      </mat-checkbox>
    </div>

    <!-- generic curves-->
    <div *ngIf="useGenericCurves">
      <h4>Media Types <i>(select one or many)</i></h4>

      <div class="input-block thirds">

        <mat-checkbox [name]="mediaType.name" [(ngModel)]="mediaType.selected"
                     *ngFor="let mediaType of mediaTypesSelections">
          {{mediaType.name}}
        </mat-checkbox>

      </div>
    </div>
    <!--/generic curves-->
    <!--upload curves-->
    <div *ngIf="uploadCurves">

      <curve-parameter-table
        [curves]="curves"
        [curveErrors]="curveErrors"
        [curveTypeNames]="curveTypeNames"
        (onDeleteCurve)="deleteCurve($event)"
      ></curve-parameter-table>

    </div>

    <!--/upload curves-->

    <div *ngIf="useGenericCurves || uploadCurves">
      <button class="btn btn-save" *ngIf="!willOverrideUploaded && !willOverrideGeneric"  (click)="onApply()"
              [disabled]="!readyToApply">Apply
      </button>
     <app-confirmation
        *ngIf="willOverrideUploaded"
        [disabled]="!readyToApply"
        isButton="true"
        buttonLabel="Apply"
        modalTitle="Are you sure?"
        modalContent="By saving generic curves, you will lose your uploaded curves"
        buttonContinueLabel="Apply"
        [kontinue]="onApply.bind(this)"
      ></app-confirmation>
      <app-confirmation
        *ngIf="willOverrideGeneric"
        [disabled]="!readyToApply"
        isButton="true"
        buttonLabel="Apply"
        modalTitle="Are you sure?"
        modalContent="By saving uploaded curves, you will lose selected generic curves"
        buttonContinueLabel="Apply"
        [kontinue]="onApply.bind(this)"
      ></app-confirmation>
    </div>

  </div>



</main>
