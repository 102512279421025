import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

interface Link {
  icon: string
  title: string
  external: boolean
  url: string
}

interface LinkSet {
  title: string
  description: string
  links: Link[]
}

@Component({
  selector: 'ppc-support-links-modal',
  templateUrl: './support-links-modal.component.html',
  styleUrls: ['./support-links-modal.component.sass']
})
export class SupportLinksModalComponent {

  linkSet: LinkSet

  constructor(public dialogRef: MatDialogRef<SupportLinksModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {linkSet: string}) {
    this.linkSet = linkSets[data.linkSet]
  }

}

const linkSets: {[key: string]: LinkSet} = {
  support: {
    title: 'PeopleCloud Discovery Support',
    description: `Welcome to the PeopleCloud Discovery support help center! You can raise a 
                  support request through the PeopleCloud Discovery Jira Portal with the 
                  options provided below.`,

    links: [
      {
        icon: 'fa-question-circle',
        title: 'Question',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/79'
      },
      {
        icon: 'fa-exclamation-circle',
        title: 'Bug & Defect',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/80'
      },
      {
        icon: 'fa-comments',
        title: 'Feedback Request',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/81'
      },
      {
        icon: 'fa-database',
        title: 'Data Request',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/82'
      },
      {
        icon: 'fa-star',
        title: 'Client Setup',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/83'
      },
      {
        icon: 'fa-book',
        title: 'Training Request',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/154'
      },
    ]
  },

  toolbox: {
    title: 'Toolbox Analytics Support',
    description: `Welcome to the Toolbox Analytics support help center! You can raise a support 
                  request through the Toolbox Analytics Support Portal with the options provided 
                  below.`,
    links: [
      {
        icon: 'fa-users',
        title: 'New User Setup',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/127'
      },
      {
        icon: 'fa-wrench',
        title: 'Update Existing User',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/128'
      },
      {
        icon: 'fa-star',
        title: 'Client Setup',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/129'
      },
      {
        icon: 'fa-folder',
        title: 'Project Setup',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/130'
      },
      {
        icon: 'fa-question-circle',
        title: 'Question',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/79'
      },
      {
        icon: 'fa-exclamation-circle',
        title: 'Bug & Defect',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/80'
      },
      {
        icon: 'fa-comments',
        title: 'Feedback Request',
        external: true,
        url: 'https://jira.publicismedia.com/servicedesk/customer/portal/3/create/81'
      },
    ]
  }
}
