<div class="edit-pane" *ngIf="weboramaDiscussion">
  <div class="edit-pane-header">
    <div class="edit-pane-header-text">
      <div class="edit-pane-title">
        Edit Discussions
      </div>
    </div>
    <div class="edit-pane-header-close" (click)="cancel()">
      <img src="assets/icons/close.svg" />
    </div>
  </div>
  <div class="edit-pane-section">
    <ppc-input #input [(ngModel)]="weboramaDiscussion.name">
      <label>Name</label>
    </ppc-input>
  </div>
  <div class="edit-pane-section">
    <ppc-textarea #input [(ngModel)]="weboramaDiscussion.description">
      <label>Description</label>
    </ppc-textarea>
  </div>
  <div class="edit-pane-section">
    <div class="edit-pane-section-header">
      Subclusters
    </div>
    <div class="edit-pane-section-instructions">
      Assign values to subclusters below
    </div>
    <div class="edit-pane-list-section">
      <div class="edit-pane-list-section-header">
        <div class="name">
          Variable Name
        </div>
        <div class="value">
          Webo Value
        </div>
      </div>
      <div class="edit-pane-list-section-items">
        <div *ngFor="let subcluster of weboramaDiscussion.weborama_subclusters" class="edit-pane-list-section-item">
          <div class="color-legend" [style.background]="colors[subcluster.cluster_id]"></div>
          <ppc-input placeholder="Untitled variable" class="name" [(ngModel)]="subcluster.name" [noLabel]="true" [noErrorContainer]="true"></ppc-input>
          <ppc-input class="value" [value]="getReachablePeopleForSubcluster(subcluster) | number:'1.0-0'" [noLabel]="true"  [noErrorContainer]="true" [disabled]="true"></ppc-input>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-pane-actions">
    <button matRipple class="btn" (click)="save()">
      Save
    </button>
    <button mat-button (click)="cancel()">
      Cancel
    </button>
  </div>
</div>
