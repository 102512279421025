import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { Store, select } from '@ngrx/store';
import { Subject, combineLatest as observableCombineLatest, BehaviorSubject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { DragulaService } from "ng2-dragula";
import { NAV_ADMIN, NAV_ADMIN_UPLOAD, NAV_ADMIN_SURVEYS_UPLOAD } from "app/shared/utils/constants";
import { MarketLevelSurvey, SurveyQuestion, getMarketLevelSurveyPath } from "app/insights/insights-components/market-level-survey/market-level-survey.interface";
import { selectActiveResourceSubMarkets, selectSurveyParentResource, selectRegionAndMarketLevelSurveys, selectSelectedResourceSubMarkets } from 'app/insights/insights.reducer';
import { userPreferenceKeys } from 'app/insights/grow-v3/grow.constants';
import { SubMarket, Mekko } from 'app/mekko/mekko.reducer';
import { Journey, JourneySubMarket } from 'app/journey/journey.models';
import { AppState } from 'app/reducers';
import * as insightsActions from 'app/insights/insights.actions';
import { INSIGHTS_CONTEXT, InsightsContextType } from 'app/insights/insights.constants';
import { InsightsResourceTracker } from 'app/insights/shared/insights-resource-tracker';
import { flatMap, find, get, map as _map, filter as _filter, debounce } from 'lodash';

@Component({
  selector: 'ppc-market-level-survey',
  templateUrl: './market-level-survey.component.html',
  styleUrls: ['./market-level-survey.component.sass']
})

export class MarketLevelSurveyComponent extends InsightsResourceTracker implements OnInit, OnDestroy {
  selectedSurveys: MarketLevelSurvey[];
  selectedQuestions: SurveyQuestion[];
  dragulaBagName = "market-level-survey-bag";
  ngUnsubscribe = new Subject();
  zeroDefaultSurveys: boolean;
  resourceSubMarkets: SubMarket[] | JourneySubMarket[];
  parentResource: Mekko | Journey;
  searchInput$ = new BehaviorSubject("");
  searchInput: string;

  canUploadSurveys$ = this.store.select('permissions', 'upload_surveys', 'create');

  constructor(public store: Store<AppState>,
    private router: Router,
    private dragulaService: DragulaService,
    @Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType) {
    super(store, insightsContext);
    observableCombineLatest(
      store.pipe(select(selectRegionAndMarketLevelSurveys(this.insightsContext))),
      this.store.pipe(select(selectSurveyParentResource(this.insightsContext))),
      this.store.pipe(select(selectActiveResourceSubMarkets(this.insightsContext))),
      this.store.pipe(select(selectSelectedResourceSubMarkets(this.insightsContext))),
      this.searchInput$
    ).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([surveys, parentResource, activeResourceSubMarkets, selectedResourceSubMarkets, searchInput]) => {
        this.parentResource = parentResource;
        this.resourceSubMarkets = activeResourceSubMarkets;
        this.selectedSurveys = _filter(surveys, "visible");
        const flatQuestions = flatMap(this.selectedSurveys, survey => _map(survey.survey_questions, (question, idx) => Object.assign(question, {idx})))
        this.selectedQuestions = this.filterQuestions(flatQuestions, searchInput);
        this.zeroDefaultSurveys = !(selectedResourceSubMarkets.length > 0 || this.resourceSubMarkets.length <= 1);
        this.searchInput = searchInput;
      }
      )
  }

  ngOnInit() {
    this.dragulaService.setOptions(this.dragulaBagName, {
      moves: (el, container, handle) => /drag/.test(handle.className)
    });

    this.dragulaService.dropModel.pipe(
      filter(([bagName]) => bagName === this.dragulaBagName),
      takeUntil(this.ngUnsubscribe), ).subscribe((value) =>
      this.saveMarketLevelSurveyConfig()
    );
  }

  ngOnDestroy() {
    this.dragulaService.destroy(this.dragulaBagName);
    super.ngOnDestroy();
  }

  saveMarketLevelSurveyConfig() {
    this.store.dispatch(new insightsActions.SaveDemographicsConfig(this.selectedSurveys.map((survey, idx) => {
      return {
        id: survey.id,
        visible: survey.visible
      }
    }), userPreferenceKeys.surveys, this.resourceId, this.resourceType, this.insightsContext))
  }

  hideSurvey(survey) {
    survey.visible = false;
    this.saveMarketLevelSurveyConfig();
  }

  navigateToSurveysBulkUpload() {
    const [ _ , clientSlug, brandSlug] = window.location.pathname.split("/");
    const surveyType = this.insightsContext === 'grow' ? 'audience' : this.insightsContext === 'journey' ? 'journeyAudience' : 'region';
    this.router.navigate([clientSlug, brandSlug, NAV_ADMIN_UPLOAD, NAV_ADMIN_SURVEYS_UPLOAD, NAV_ADMIN], { queryParams: { surveyType: surveyType } });
  }

  get noCustomSurveySubject() {
    switch (this.insightsContext) {
      case 'grow':
        return "This audience has no associated custom surveys.";
      case 'journey':
        return "This stage has no associated custom surveys.";
      case 'explore':
        return "This dashboard has no associated region surveys.";
    }
  }

  trackById(index, item) {
    return item.id
  }

  isFirstSurveyQuestion(question: SurveyQuestion) {
    const surveyIds = _map(this.getSurveyFromQuestion(question).survey_questions, "id");
    const selectedSurveyQuestions = _filter(this.selectedQuestions, sq => surveyIds.includes(sq.id));
    return get(selectedSurveyQuestions, [0, "id"]) === question.id;
  }

  getSurveyNameFromQuestion(question: SurveyQuestion) {
    return get(this.getSurveyFromQuestion(question), "name");
  }

  getSurveyFromQuestion(question: SurveyQuestion) {
    return find(this.selectedSurveys, {id: question.survey_id})
  }

  getPathName(survey) {
    return getMarketLevelSurveyPath(survey, this.parentResource, this.resourceSubMarkets, this.insightsContext);
  };

  updateSearch = debounce(searchInput => {
    this.searchInput$.next(searchInput);
  }, 200)

  highlightSearchHits(text) {
    if (!text || !this.searchInput || this.searchInput.length < 3) {return text; }
    return text.replace(new RegExp(`(${this.searchInput})`, "gi"), `<span class="highlight">$1</span>`)
  }

  filterQuestions(questions, searchInput) {
    if (searchInput.length < 3) {return questions; }
    return questions.filter(question => {
      return [this.getSurveyNameFromQuestion(question), question.name, ..._map(question.survey_responses, "name")].some(name => new RegExp(searchInput, 'i').test(name))
    })
  }

  getStartingItemHeight(question) {
    return this.isFirstSurveyQuestion(question) ? 90 : 45;
  }
}
