import { Component, Input, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { Mekko } from 'app/mekko/mekko.reducer';
import { Comparison } from 'app/comparisons/comparisons.reducer';
import { Journey } from 'app/journey/journey.models';
import { SetSelectedMekko } from 'app/mekko/mekko.actions';
import { SetSelectedComparison } from 'app/comparisons/comparisons.actions';
import { SetSelectedJourneyId } from 'app/journey/journey.actions';

@Component({
  selector: '[ppc-home-mekko-row]',
  templateUrl: './mekko-row.component.html',
  styleUrls: ['../../home.component.sass']
})

export class MekkoRowComponent implements OnInit {
  @Input() chart: (Mekko | Comparison | Journey) & {total_matched_count: number; total_modeled_count: number, total_population_count: number};
  @Input() hierarchyPath: string;

  fileName: string;
  chartType: string;
  chartTooltipText: string;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.isMekko) {
      this.fileName = 'outline-table_chart-24px.svg';
      this.chartTooltipText = 'Grow Chart';
    } else if (this.isJourney) {
      this.fileName = 'outline-journey_chart-24px.svg';
      this.chartTooltipText = 'Journey Chart';
    } else {
      this.fileName = 'outline-compare_arrows-24px.svg';
      this.chartTooltipText = 'Overlap Chart';
    }
  }

  get isMekko(): boolean {
    return 'markets' in this.chart;
  }

  get isJourney(): boolean {
    return 'stages' in this.chart && 'brands' in this.chart;
  }

  navigateTo() {
    let linkUrl: string;

    if (this.isMekko) {
      this.store.dispatch(new SetSelectedMekko(this.chart as Mekko));
      linkUrl = `${this.hierarchyPath}/insights/grow`;
    } else if (this.isJourney) {
      this.store.dispatch(new SetSelectedJourneyId(this.chart.id));
      linkUrl = `${this.hierarchyPath}/insights/journey`;
    } else {
      this.store.dispatch(new SetSelectedComparison(this.chart.id));
      linkUrl = `${this.hierarchyPath}/insights/overlap`;
    }
    this.router.navigate([linkUrl]);
  }
}
