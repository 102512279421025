import { chain, forEach, get, map as _map, pickBy } from 'lodash';

export class BrowsingDataExport {
  private _data: any;
  private _lastUpdated: string;
  verticalKey: string;

  constructor(data, verticalKey) {
    this._data = data;

    this.setVerticalKey(verticalKey);
    this.setLastUpdated(data)
  }

  getLastUpdate() {
    return this._lastUpdated;
  }

  getBrowsingWorksheetData(name: string) {
    if (name === 'Top Domains') {
      return this.getTopDomainValues(this._data[name]);
    }

    const values = [];
    const sections = [name, 'Domain', 'Categories'];
    const data = this._data[name];
    forEach(sections, section => {
      const sectionValues = this.getSectionValues(data, section);

      values.push(...sectionValues);
      // enter empty separator column
      values.push([]);
    });

    return values;
  }

  // "Browsing - Top Domains" worksheet only
  getTopDomainValues(data) {
    const columns = [
      ['Domain Category'],
      ['Domain'],
      ['Attribute Count'],
      ['Attribute Provider'],
    ];

    return this.getDomainValues(data, columns);
  }

  getSectionValues(data, field) {
    // This determines a square, so multiple columns
    const fieldValues = [];
    const fieldData = data[field];

    if (field === 'Domain') {
      const columns = [
        ['Top Domains'],
        ['Domain'],
      ];
      fieldValues.push(...this.getDomainValues(fieldData, columns));
    } else if (field === 'Categories') {
      fieldValues.push(...this.getCategoryValues(fieldData));
    } else {
      fieldValues.push(...this.getWidgetValues(fieldData));
    }

    return fieldValues;
  }

  getDomainValues(data, columns) {
    const domains = Object.keys(pickBy(data, d => d.length));

    forEach(domains, domain => {
      columns[0].push(...this.fillValue(domain, data[domain].length));
      columns[1].push(..._map(data[domain], 'name'));
      if (columns.length > 2) {
        columns[2].push(..._map(data[domain], 'count'));
        columns[3].push(...this.fillValue('ShareThis', data[domain].length));
      }
    });

    return columns;
  }

  getCategoryValues(data) {
    const categories = Object.keys(pickBy(data, d => d.length));
    const columns = [
      ['Top Categories'],
      ['Category'],
      ['Attribute Count']
    ];

    forEach(categories, category => {
      columns[0].push(...this.fillValue(category, data[category].length));
      columns[1].push(..._map(data[category], 'name'));
      columns[2].push(..._map(data[category], 'count'));
    });

    return columns;
  }

  getWidgetValues(data) {
    const columnHeaders = ['Widget Name', 'Attribute Name', 'Attribute Count', 'Attribute Provider'];

    return columnHeaders.map(header => {
      const column = [header];
      const numOfEntries = data.length;
      let cb, attributes;

      switch (header) {
        case 'Widget Name':
          attributes = ['Total Unique Visitors', 'Top Domains', 'Top Categories'];
          /* falls through */
        case 'Attribute Provider':
          attributes =  attributes || this.fillValue('ShareThis', 3);
          cb = (value) => this.fillValue(value, numOfEntries);
          break;
        case 'Attribute Name':
          attributes = this.fillValue('name', 3);
          /* falls through */
        case 'Attribute Count':
          attributes = attributes || ['unique_audience', 'count', 'count'];
          cb = (value) => _map(data, value);
          break;
      }

      this.setWidgetAttributeValues(column, attributes, cb);
      return column;
    });
  }

  get overlapData() {
    return chain(this._data['Overlap'])
      .filter(o => o.name !== 'all')
      .sortBy('name')
      .value();
  }

  private setWidgetAttributeValues(column, values, cb) {
    forEach(values, value => {
      column.push(...cb(value));
      column.push('');
    });
  }

  private fillValue(value: string, numOfTimes: number) {
    return Array(numOfTimes).fill(value);
  }

  private setVerticalKey(verticalKey) {
    // if vertical key is 'all', we should set to first element
    this.verticalKey = (verticalKey === 'all') ?
      get(this.overlapData[0], 'name') :
      verticalKey;
  }

  private setLastUpdated(data) {
    const entryDate = new Date(get(data, ['Overlap', '0', 'entry_date']));
    const year = entryDate.getUTCFullYear();
    const month = entryDate.getUTCMonth() + 1;
    const day = entryDate.getUTCDate();
    this._lastUpdated = isNaN(year) ? '' : `${month}/${day}/${year}`;
  }
}
