import {Component, EventEmitter, Input, Output, ViewChild, OnChanges, forwardRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from "moment";
import {DaterangepickerComponent, DaterangepickerConfig} from "ng2-daterangepicker";
import {Moment} from "moment";
import { MONTH_DAY_YEAR_FORMAT } from "../../utils/constants";

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true
    }
  ]
})
export class DateRangePickerComponent implements OnChanges, ControlValueAccessor {
  value: {start: Moment, end: Moment};
  disabled: boolean = false;
  @Input() placeholder: string = "";
  @Input() tense: "past" | "future" | "plansSummaryDefault" = "past";

  @Output() selected = new EventEmitter<{start: Moment, end: Moment}>();
  @Output() cancel = new EventEmitter<null>();

  @ViewChild(DaterangepickerComponent, { static: true }) datePickerComponent: DaterangepickerComponent;

  @Input() options; // Should respect most options found here http://www.daterangepicker.com/#options
  label: string;
  @Input() initialRange: string;

  constructor(private globalConfig: DaterangepickerConfig) {
    this.globalConfig.settings = {
      ranges: this.rangeOptions[this.tense],
      alwaysShowCalendars: true,
      showCustomRangeLabel: false
    }
  }

  ngOnChanges(changes) {
    if (changes.tense) {
      this.globalConfig.settings.ranges = this.rangeOptions[this.tense]
    }
    if (changes.options) {
      if (this.options && this.options.startDate && this.options.singleDatePicker) {
        this.label = moment(this.options.startDate).format(MONTH_DAY_YEAR_FORMAT)
      } else if (this.options && this.options.startDate && this.options.endDate) {
        const {startDate, endDate} = this.options;
        // Have to wait for child views to be rendered
        setTimeout(() => {
          this.datePickerComponent.datePicker.calculateChosenLabel();
          this.label = this.datePickerComponent.datePicker.chosenLabel
            || `${startDate.format(MONTH_DAY_YEAR_FORMAT)} - ${endDate.format(MONTH_DAY_YEAR_FORMAT)}`;
          this.datePickerComponent.datePicker.setStartDate(this.options.startDate);
          this.datePickerComponent.datePicker.setEndDate(this.options.endDate);
        });
      } else {
        const initialRange = this.initialRange || "Week to Date";
        const defaultRanges = this.globalConfig.settings.ranges;
        const additionalRanges = this.options && this.options.ranges || {};
        const availableRanges = Object.assign({}, defaultRanges, additionalRanges);

        if (availableRanges[initialRange]) {
          // Have to wait for child views to be rendered
          setTimeout(() => {
            this.label = initialRange;
            const [start, end] = availableRanges[initialRange];
            this.datePickerComponent.datePicker.setStartDate(start);
            this.datePickerComponent.datePicker.setEndDate(end);
          })
        }
      }
    }
  }

  cancelSelection(event) {
    this.cancel.emit();
  }

  selectDateRange(event) {
    if (this.tense == "plansSummaryDefault") { sessionStorage.setItem('plansSummaryDateRange', JSON.stringify(event)); }
    this.setLabel(event);
    this.writeValue(event);
    this.selected.emit(event);
  }

  setLabel(event: {start: Moment, end: Moment, label?: string}) {
    this.label = event.label || (this.options.singleDatePicker ? event.start.format(MONTH_DAY_YEAR_FORMAT)
      : event.start.format(MONTH_DAY_YEAR_FORMAT) + " - " + event.end.format(MONTH_DAY_YEAR_FORMAT));
  }

  setTense(tense) {
    this.tense = tense;
    this.globalConfig.settings.ranges = this.rangeOptions[tense];
  }

  public clear() {
    this.writeValue(null);
    this.label = "";
    this.datePickerComponent.datePicker.setStartDate(new Date());
    this.datePickerComponent.datePicker.setEndDate(new Date());
  }

  // CONTROL VALUE ACCESSOR IMPLEMENTATION

  // Function to call when the value changes.
  onChange = (value: any) => {};

  // Function to call when the input is touched.
  onTouched = () => {};

  // Allows Angular to update the model.
  // Update the model and changes needed for the view here.
  writeValue(value: {start: Moment, end: Moment}): void {
    const dateRange = JSON.parse(sessionStorage.getItem('plansSummaryDateRange'));
    if (dateRange && this.tense == "plansSummaryDefault") { value = {start: moment(dateRange.start), end: moment(dateRange.end)}; }

    this.value = value;
    this.onChange(this.value);
    if (this.datePickerComponent && this.datePickerComponent.datePicker && value) {
      this.datePickerComponent.datePicker.setStartDate(value.start.toDate());
      this.datePickerComponent.datePicker.setEndDate(value.end.toDate());
      this.setLabel(value);
    }
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input. Not currently used to this component but necessary to implement ControlValueAccessor
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // END CONTROL VALUE ACCESSOR IMPLEMENTATION

  rangeOptions = {
    past: {
      'Last 7 Days': [moment().subtract(7, 'days'), moment()],
      'Month to Date': [moment().startOf('month'), moment()],
      'Last 30 Days': [moment().subtract(30, 'days'), moment()],
      'Last 90 Days': [moment().subtract(90, 'days'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Year to Date': [moment().startOf('year'), moment()],
      'Last Year': [moment().subtract(1, 'year'), moment()]
    },
    future: {
      'Next 7 Days': [moment(), moment().add(7, 'days')],
      'Next 30 Days': [moment(), moment().add(30, 'days')],
      'Next 90 Days': [moment(), moment().add(90, 'days')],
      'Until End of year': [moment(), moment().endOf('year')]
    },
    plansSummaryDefault: {
      'Month to Date': [moment().startOf('month'), moment()],
      'Year to Date': [moment().startOf('year'), moment()],
      'Next 30 Days': [moment(), moment().add(30, 'days')],
      'Next 90 Days': [moment(), moment().add(90, 'days')],
      'Next 6 Months': [moment(), moment().add(6, 'months')],
      'Until End of year': [moment(), moment().endOf('year')]
    }
  }
}
