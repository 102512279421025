import { apiUrl } from '../../services/ppc_http.service';
import { PLANS_API_PATH, V1 } from '../utils/constants';

export function scenarioPlannerUrl() {
  return apiUrl(PLANS_API_PATH + `/api/${V1}/scenarios`);
}

export function channelsUrl() {
  return apiUrl(PLANS_API_PATH + `/api/${V1}/channels`);
}

export function channelPath() {
  return apiUrl(PLANS_API_PATH + `/api/${V1}/channel`);
}

export function scenarioCurvesUrl() {
  return apiUrl(PLANS_API_PATH + `/api/${V1}/curves`);
}
