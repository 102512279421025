import { Action } from "@ngrx/store";
import { DataPermissions } from "app/data_permissions/data_permissions.model";

export class FetchAllDataPermissions implements Action {
  static readonly type = 'FETCH_ALL_DATA_PERMISSIONS';
  readonly type = FetchAllDataPermissions.type;

  constructor(public identifiers: string[]) {}
}

export class LoadDataPermissions implements Action {
  static readonly type = 'LOAD_DATA_PERMISSIONS';
  readonly type = LoadDataPermissions.type;

  constructor(public permissions: DataPermissions[]) {}
}

export class ClearDataPermissions implements Action {
  static readonly type = 'CLEAR_DATA_PERMISSIONS';
  readonly type = ClearDataPermissions.type;
}

export type All = FetchAllDataPermissions | LoadDataPermissions | ClearDataPermissions;
