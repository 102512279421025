import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { AudienceMapper } from 'app/audience-builder/audience-mappers/audience-mapper';
import { AudienceMapper as SegmentsApiAudienceMapper } from 'app/audience-builder/audience-mappers/segments-api';
import { Observable } from 'rxjs';
import { AudienceRuleGroup } from '../../../audience-builder/audience-builder.models';
import { pluck } from 'rxjs/operators';
import { getSegmentCount, isEntrySegment } from 'app/audience-builder/audience-builder.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ppc-audience-rules-detail',
  templateUrl: './audience-rules-detail.component.html',
  styleUrls: ['./audience-rules-detail.component.sass'],
  providers: [
    {
      provide: AudienceMapper,
      useClass: SegmentsApiAudienceMapper,
    }
  ]
})
export class AudienceRulesDetailComponent implements OnInit {
  @Input() audience: any;
  rules$: Observable<AudienceRuleGroup>;
  @Output() zeroCountSegmentDetection = new EventEmitter();

  constructor(private audienceMapper: AudienceMapper) { }

  ngOnInit() { }

  ngOnChanges() {
    this.rules$ = this.audience ? this.audienceMapper.fromJsonPersona(this.audience).pipe(pluck("rules")) : null

    this.rules$?.pipe(
      untilDestroyed(this)).subscribe((resp) => {
        this.recursiveZeroCountCheck(resp)
      })
  }

  recursiveZeroCountCheck(item) {
    if (isEntrySegment(item)) {
      if (getSegmentCount(item) === 0) {
        this.zeroCountSegmentDetection.emit()
      }
    } else if (item.items) {
      item.items.forEach((innerItem) => {
        this.recursiveZeroCountCheck(innerItem);
      })
    }
  }

  ngOnDestroy() {}
}
