<div class="bubble-chart">
  <div class="legend">
    <div>Client Differentiators</div>
    <div>Commonalities</div>
    <div>Competitor Differentiators</div>
  </div>
  <svg #svg [attr.height]="height" (click)="fieldClicked()">
    <path
      class="middle-line"
      [attr.d]="getMidline()"
    />

    <g *ngFor="let node of graphNodes"
      [attr.transform]="nodeTransform(node)"
    >
      <title *ngIf="selectedNode !== node">{{node.data.description}}</title>
      <circle
        class="graph-node"
        [class.selected]="selectedAttributes.has(node.id)"
        [attr.r]="node.r"
        (click)="nodeClicked($event, node)"
        (mouseover)="nodeHovered($event, node)"
        (mouseleave)="hoverLeave()"
        [style.fill]="node.fill"
        ppcTooltip
        [tooltipTemplate]="tooltip"
      />
    </g>
  </svg>
  <ng-template #tooltip>
    <div class="bubble-tooltip">
      <div class="tooltip-content" *ngIf="selectedNode">
        <div class="tooltip-header">
          {{selectedNode.data.description}}
        </div>
        <ul class="tooltip-chart">
            <li class="brand-score">
              <span class="percent-score">{{selectedNode.data.brandScore | percent:'1.2-2'}}</span>
                <app-progress-bar
                  [percent]="selectedNode.data.brandScore * 100"
                  [color]="brandColor"
                  [hideText]="true"
                  [shouldAnimate]="true"
                  [thickness]="8"
              ></app-progress-bar>
            </li>
  
            <li class="brand-score">
              <span class="percent-score">{{selectedNode.data.competitorScore | percent:'1.2-2'}}</span>
              <app-progress-bar
                [percent]="selectedNode.data.competitorScore * 100"
                [color]="competitorColor"
                [hideText]="true"
                [shouldAnimate]="true"
                [thickness]="8"
              ></app-progress-bar>
            </li>
        </ul>
      </div>
    </div>
  </ng-template>
</div>
