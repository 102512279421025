import {combineLatest as observableCombineLatest} from 'rxjs';
import { take, switchMap, filter, map, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { values, find, sortBy, flatMap, reverse, get } from 'lodash';
import * as moment from 'moment';
import { ActivatedRoute, Params } from '@angular/router';
import { Go } from 'app/router/router.actions';
import { buildUrlRelative, isDefined } from "app/shared/utils/utils";

import { AppState } from 'app/reducers';
import {isNotYetFetched, isFetchInFlight, isLoaded} from "app/shared/utils/fetch-state";
import * as actions from './measure-v3.actions';
import { OutcomeTimeframe, MeasureTabType, selectCurrentSum, selectPreviousPopulation, selectCurrentPopulation } from './measure-v3.reducer';
import { MekkoColors } from 'app/insights/grow-v3/grow.constants';
import { SafeStyle } from '@angular/platform-browser';
import { SelectedTimeframes } from './timeframe-selector/timeframe-selector.component';
import { combineLatest } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Mekko, selectActiveMekko, selectActiveMekkoSubMarkets, selectMekkos, SubMarket, Market } from 'app/mekko/mekko.reducer';
import * as mekkoActions from 'app/mekko/mekko.actions';
import { environment } from 'environments/environment';

@Component({
  selector: 'ppc-measure-v3',
  templateUrl: './measure-v3.component.html',
  styleUrls: ['./measure-v3.component.sass']
})
export class MeasureV3Component implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  activeMekko: Mekko;
  mekkoColors = MekkoColors;
  activeMekkoId: number;
  getFilterName = (filter) => filter.pathParts.join(' > ');
  selectedSubMarket: SubMarket;
  subMarkets: SubMarket[];
  selectedMarket: Market;
  markets: Market[];
  cmsOpen = false;
  outcomeTimeframes: OutcomeTimeframe[];
  selectedTimeframes: SelectedTimeframes;
  isTier3 = environment.isTier3;

  isCurrentTimeframeDisabled = (timeframe) => {
    if (!this.previousTimeframe) {return false; }
    return moment().month(timeframe.month).year(timeframe.year) <
           moment().month(this.previousTimeframe.month).year(this.previousTimeframe.year)
  }

  isPreviousTimeframeDisabled = (timeframe) => {
    if (!this.currentTimeframe) {return false; }
    return moment().month(timeframe.month).year(timeframe.year) >
           moment().month(this.currentTimeframe.month).year(this.currentTimeframe.year)
  }

  total$ = this.store.select("measureV3").pipe(select(selectCurrentSum("total")));
  matched$ = this.store.select("measureV3").pipe(select(selectCurrentSum("matched")));
  modeled$ = this.store.select("measureV3").pipe(select(selectCurrentSum("modeled")));
  previousPopulation$ = this.store.select("measureV3").pipe(select(selectPreviousPopulation));
  currentPopulation$ = this.store.select("measureV3").pipe(select(selectCurrentPopulation));
  populationChange$ = observableCombineLatest([
    this.previousPopulation$,
    this.currentPopulation$
  ]).pipe(map(([previous, current]) => {
    const result = ((current / previous) * 100 ) - 100;
    return isNaN(result) ? 0 : result
  }))

  loading$ = observableCombineLatest([
    this.store.select("fetchStates", mekkoActions.FetchMekkos.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", mekkoActions.FetchDefaultMekko.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.FetchOutcomeTimeframes.type).pipe(select(isFetchInFlight)),
    this.store.select("fetchStates", actions.SaveOutcomeTimeframe.type).pipe(select(isFetchInFlight)),
  ]).pipe(map(fetchStates => fetchStates.some(Boolean)))

  tabs$ = this.store.select("measureV3", "tabs");
  personLevelTabsAllowed$ = this.tabs$.pipe(
    map(tabs => !tabs || get(find(tabs, {name: "Person Level"}), "visible"))
  )
  marketLevelTabsAllowed$ = this.tabs$.pipe(
    map(tabs => !tabs || get(find(tabs, {name: "Market Level"}), "visible"))
  )
  measureTab$ = this.store.select("measureV3", "measureTab");
  mekkos$ = this.store.select("mekkos").pipe(select(selectMekkos));

  constructor(private store: Store<AppState>,
    private actions$: Actions,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.store.dispatch(new actions.ResetMeasureV3());

    this.store.select("mekkos")
      .pipe(select(selectActiveMekko), filter(Boolean), takeUntil(this.ngUnsubscribe))
      .subscribe((activeMekko: Mekko) => {
        this.activeMekko = activeMekko;
        this.markets = activeMekko.markets;
      });

    observableCombineLatest(
      observableCombineLatest(
        this.store.select("mekkos", "selectedMekkoId").pipe(filter(isDefined)),
        this.store.select("mekkos", "mekkos")
      ).pipe(
        map(([selectedMekkoId, mekkos]) => mekkos[selectedMekkoId] ? selectedMekkoId : null)
      ),
      this.route.params,
      this.store.select("fetchStates", mekkoActions.FetchMekkos.type).pipe(select(isLoaded)),
    ).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(([selectedMekkoId, params, mekkosAreLoaded]) => mekkosAreLoaded),
    ).subscribe(([activeMekkoId, params, mekkosAreLoaded]: [number, Params, boolean]) => {
      this.activeMekkoId = activeMekkoId;
      this.store.dispatch(new Go({path: buildUrlRelative(params, `outcomes/measure${activeMekkoId ? "/" + activeMekkoId : ""}`)}));
      this.store.dispatch(new actions.FetchOutcomeTimeframes(activeMekkoId));
      this.store.dispatch(new actions.FetchTabs(activeMekkoId));
    });

    this.store.select('mekkos').pipe(select(selectActiveMekkoSubMarkets), takeUntil(this.ngUnsubscribe))
      .subscribe((subMarkets) => this.subMarkets = subMarkets)

    this.store.select('fetchStates', mekkoActions.FetchMekkos.type).pipe(
      select(isNotYetFetched),
      takeUntil(this.ngUnsubscribe),
      filter(Boolean),
    ).subscribe(() => this.store.dispatch(new mekkoActions.FetchMekkos()));

    this.store.select('fetchStates', mekkoActions.FetchDefaultMekko.type).pipe(
      select(isNotYetFetched),
      takeUntil(this.ngUnsubscribe),
      filter(Boolean),
    ).subscribe(() => this.store.dispatch(new mekkoActions.FetchDefaultMekko()));

    this.store.select("mekkos").pipe(
      select(selectActiveMekko),
      switchMap(activeMekko => {
        return observableCombineLatest(
          this.actions$.pipe(ofType(actions.LoadOutcomeTimeframes.type)),
          this.actions$.pipe(ofType(actions.LoadOutcomeTimeframe.type))
        ).pipe(
          take(1),
        )
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => this.setDefaultTimeframes())

    this.store.select("measureV3", "outcomeTimeframes").pipe(
      filter(ot => !!ot),
      takeUntil(this.ngUnsubscribe), )
      .subscribe((outcomeTimeframes: {[year: number]: {[month: number]: OutcomeTimeframe}}) => {
        this.outcomeTimeframes = reverse(sortBy(flatMap(outcomeTimeframes, yearGroup => values(yearGroup)), ["year", "month"]))
        if (this.outcomeTimeframes.length &&
                (!this.selectedTimeframes ||
                  !find(this.outcomeTimeframes, {id: this.currentTimeframe.id}) ||
                  !find(this.outcomeTimeframes, {id: this.previousTimeframe.id}))) {
          this.setDefaultTimeframes();
        }
      });

    this.store.select("measureV3", "measureTab").pipe(
      combineLatest(this.personLevelTabsAllowed$, this.marketLevelTabsAllowed$),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([measureTab, personLevelTabsAllowed, marketLevelTabsAllowed]) => {
      switch (measureTab) {
        case "Total Population":
          return marketLevelTabsAllowed ? measureTab : this.store.dispatch(new actions.SetMeasureTab("Matched Addressable"));
        default:
          return personLevelTabsAllowed ? measureTab : this.store.dispatch(new actions.SetMeasureTab("Total Population"));
      }
    }
    );

    this.store.dispatch(new actions.SetMeasureV3Active(true));

    observableCombineLatest(
      this.store.select("measureV3", "selectedMarket"),
      this.store.select("measureV3", "selectedSubMarket"),
    ).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([selectedMarket, selectedSubMarket]) => {
        this.selectedMarket = selectedMarket
        this.selectedSubMarket = selectedSubMarket
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.store.dispatch(new actions.SetMeasureV3Active(false));
  }

  setDefaultTimeframes() {
    this.selectedTimeframes = {
      currentTimeframe: this.outcomeTimeframes[0],
      previousTimeframe: this.outcomeTimeframes[1] || this.outcomeTimeframes[0],
      isRangeMode: false
    }
  }

  setActiveMekko(mekko) {
    if (!mekko) {return; }
    this.store.dispatch(new mekkoActions.SetSelectedMekko(mekko));
  }

  openCms() {
    this.cmsOpen = true;
  }

  closeCms() {
    this.cmsOpen = false;
  }

  changeTab(newTab: MeasureTabType) {
    this.store.dispatch(new actions.SetMeasureTab(newTab));
  }

  setSelectedTimeframes() {
    this.store.dispatch(new actions.SetPreviousTimeframe(this.previousTimeframe));
    this.store.dispatch(new actions.SetCurrentTimeframe(this.currentTimeframe));
  }

  getYLegendPosition(number): {[name: string]: SafeStyle} {
    return {
      "top": `${100 - number}%`,
      "transform": `translateY(-${100 - number}%)`
    }
  }

  getXLegendPosition(number): {[name: string]: SafeStyle} {
    return {
      "left": `${number}%`,
      "transform": `translateX(-${number}%)`,
    }
  }

  clearFilters() {
    if (this.selectedMarket) {
      this.store.dispatch(new actions.ToggleSelectedMarket(this.selectedMarket));
    } else {
      this.store.dispatch(new actions.ToggleSelectedSubMarket(this.selectedSubMarket));
    }
  }

  toggleFilter(filter) {
    this.store.dispatch(new actions.ToggleSelectedSubMarket(this.selectedSubMarket));
  }

  get filters() {
    if (this.selectedSubMarket) {
      const market = this.markets.find((m) => m.id == this.selectedSubMarket.market_id)
      const marketName = market && market.name
      return [{
        pathParts: [marketName, this.selectedSubMarket.name],
        type: "audience",
        subMarketId: this.selectedSubMarket.id,
        tab: "Sub Market"
      }]
    } else if (this.selectedMarket) {
      return this.subMarkets.filter((sm) => sm.market_id == this.selectedMarket.id).map((sm) => {
        return {
          pathParts: [this.selectedMarket.name, sm.name],
          type: "audience",
          subMarketId: sm.id,
          tab: "Sub Market"
        }})
    } else {
      return []
    }
  }

  get previousMonth() {
    if (this.previousTimeframe) {
      return moment().month(this.previousTimeframe.month).format("MMMM")
    }
  }

  get currentTimeframe() {
    return this.selectedTimeframes && this.selectedTimeframes.currentTimeframe;
  }

  get previousTimeframe() {
    return this.selectedTimeframes && this.selectedTimeframes.previousTimeframe;
  }

}
