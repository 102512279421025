import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinationsAdminComponent } from "./destinations-admin.component";
import { PpcComponentsModule } from 'app/modules/ppc-components.module';
import {RouterLink, RouterModule} from "@angular/router";
import {PpcActivationOverlay} from "../../audiences/overview-v3/activation-overlay/activation-overlay.component";
import {ActivationTileModule} from "../../audiences/overview-v3/activation-tile/activation-tile.module";
import {DestinationsAdminService} from "./destinations-admin.service";
import {AddDestinationComponent} from './add-destination/add-destination.component';
import {ActivationDestinationsOverlayModule} from 'app/audiences/overview-v3/activation-destinations-overlay/activation-destinations-overlay.module';

@NgModule({
  declarations: [
    DestinationsAdminComponent,
    AddDestinationComponent,
  ],
  imports: [
    CommonModule,
    PpcComponentsModule,
    RouterModule,
    ActivationTileModule,
    ActivationDestinationsOverlayModule,
  ],
  providers: [
    DestinationsAdminService
  ]
})

export class DestinationsAdminModule { }
