import { Input, Output, EventEmitter, Inject, Component, OnDestroy, OnChanges } from '@angular/core';
import {
  INSIGHTS_CONTEXT, INSIGHTS_CONTEXT_COMPARE,
  INSIGHTS_CONTEXT_EXPLORE, INSIGHTS_CONTEXT_GROW, INSIGHTS_CONTEXT_JOURNEY, INSIGHTS_CONTEXT_OVERLAP,
  InsightsContextType
} from 'app/insights/insights.constants';
import { Store, select } from '@ngrx/store'
import { AppState } from 'app/reducers'
import { of as observableOf, Observable, combineLatest, BehaviorSubject } from 'rxjs'
import { take, tap, map, filter, switchMap, distinctUntilChanged, pluck } from 'rxjs/operators'
import { chain, isEqual, get, map as _map, difference } from 'lodash'
import { leaves, toProperty } from 'app/shared/utils/utils'
import { Tab } from 'app/insights/insights.models'
import { getContext, getContextSlugs, getPath } from 'app/hierarchy/hierarchy.utils'
import { ContextSlugs, HierarchyEntity } from 'app/hierarchy/hierarchy.interface'
import { activeContext } from 'app/hierarchy/hierarchy.reducers'
import { MekkoService } from 'app/mekko/mekko.service'
import { JourneyService } from 'app/journey/journey.service'
import { ComparisonsService } from 'app/comparisons/comparisons.service'
import { InsightsService } from 'app/insights/insights.service'
import { DataPermissionsService } from 'app/services/data_permissions.service'
import { ExploreService } from 'app/explore/explore.service'
import { selectCustomTabDemographics } from 'app/insights/insights.reducer'
import { Demographic } from 'app/insights/insights.models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';
import { loggedInUser } from "app/users/user.reducer";
import { isDefined } from 'app/shared/utils/utils';
import { environment } from 'environments/environment'
import {canAccessFeature} from "../../../feature-access/feature-access.reducers";
import {HttpClient} from "@angular/common/http";
import { topLevelTabsUrl } from 'app/shared/constants/insights.urls';

const addUid = (type: string) => (x: {id?: number}) => ({...x, uid: `${type}:${x.id}`})

const compareNames = (a, b) => a.name.localeCompare(b.name, 'en-u-kn-true', {sensitivity: 'base'})

@UntilDestroy()
@Component({
  selector: 'ppc-tab-copy-form',
  templateUrl: './tab-copy-form.component.html',
  styleUrls: ['./tab-copy-form.component.sass']
})
export class TabCopyFormComponent implements OnDestroy, OnChanges {

  @Output() close = new EventEmitter()
  @Input() selectedTab: Tab;

  // These are strings of the form '<resource_type>:<id>' e.g. 'Journey:3' or 'Mekko:42'
  selectedCharts$ = new BehaviorSubject<Set<string>>(new Set())
  selectedTabs$ = new BehaviorSubject<Map<number, Tab>>(new Map())
  clientContext$ = new BehaviorSubject<ContextSlugs>({})
  requestsInFlight = new Set<string>()
  selectedChartType$ = new BehaviorSubject<string>(null)
  selectedMyCharts$ = new BehaviorSubject<boolean>(false);
  selectedStayOpen$ = new BehaviorSubject<boolean>(false);

  warningMessage: string;

  activeContext$ = this.store.select('hierarchy', 'contextSlugs')

  lockedContextLevels = environment.isTier3 ? ['client', 'region'] : []

  productSlug$ = this.clientContext$.pipe(
    filter(context => !!context.productSlug),
    map(context => getPath(context)))

  fullContextSelected$ = this.clientContext$.pipe(map(context => !!context.productSlug))

  customTabs$ = this.store.select('insights', this.insightsContext, 'tabs').pipe(
    map(allTabs =>
      chain(allTabs).flatMap(tab => leaves(tab))
        .filter({tab_key: 'person_level_custom', visible: true})
        .value()))

  permissionedTabs$ = combineLatest(
    this.customTabs$,
    this.productSlug$
  ).pipe(
    switchMap(([ tabs, slug ]) =>
      combineLatest(
        ...tabs.map(tab =>
          this.isNotPermittedForProduct(tab, slug).pipe(
            map(disabled => ({ ...tab, disabled }))))
      )))

  tabs$ = combineLatest(
    this.selectedTabs$,
    this.permissionedTabs$,
  ).pipe(
    map(([ selectedTabs, customTabs ]) => {
      this.setWarningMessage(customTabs);
      return customTabs.map(tab => ({ ...tab,
        checked: selectedTabs.has(tab.id) }));
    }))

  isCopyDisabled$ = combineLatest(this.selectedTabs$, this.selectedCharts$).pipe(
    map(([ tabs, charts ]) => tabs.size === 0 || charts.size === 0))

  personas$ = this.productSlug$.pipe(
    switchMap(context => {
      this.requestsInFlight.add('personas')
      return this.exploreService.getPersonasForProduct(context)
    }),
    tap(() => this.requestsInFlight.delete('personas')),
    map(personas => personas.map(addUid('Persona')).sort(compareNames)))

  mekkos$ = this.productSlug$.pipe(
    switchMap(context => {
      this.requestsInFlight.add('mekkos')
      return this.mekkoService.getMekkosForProduct(context)
    }),
    tap(() => this.requestsInFlight.delete('mekkos')),
    map(mekkos => mekkos.map(addUid('Mekko')).sort(compareNames)))

  journeys$ = this.productSlug$.pipe(
    switchMap(context => {
      this.requestsInFlight.add('journeys')
      return this.journeyService.fetchJourneys(context)
    }),
    tap(() => this.requestsInFlight.delete('journeys')),
    map(journeys => journeys.map(addUid('Journey')).sort(compareNames)))

  comparisons$ = this.productSlug$.pipe(
    switchMap(context => {
      this.requestsInFlight.add('comparisons')
      return this.comparisonService.getComparisons(context)
    }),
    tap(() => this.requestsInFlight.delete('comparisons')),
    map(comps => comps.map(addUid('Comparison')).sort(compareNames)))

  userEmail$ = this.store.select('users').pipe(select(loggedInUser), filter(isDefined), take(1), map(u => u.email));

  allowedTabContexts$ = combineLatest(
    this.http.get<Tab[]>(topLevelTabsUrl()),
    this.activeContext$.pipe(pluck('regionSlug')),
    (tabs, regionSlug) => tabs.filter(tab => tab.tab_key == 'top_level_person'
                                             && !tab.excluded_regions.includes(regionSlug)))

  groupedCharts$ = combineLatest(
    this.selectedCharts$,
    this.mekkos$,
    this.journeys$,
    this.comparisons$,
    this.personas$,
    this.userEmail$,
    this.selectedMyCharts$,
    this.allowedTabContexts$
  ).pipe(
    map(([ selectedCharts, mekkos, journeys, comparisons, personas, userEmail, selectedMyCharts, allowedTabContexts ]) => {
      const currentChartUid = this.selectedTab
        ? `${this.selectedTab.resource_type}:${this.selectedTab.resource_id}`
        : '';
      const checkAndFilterCharts = charts =>
        charts.map(chart => ({ ...chart, checked: selectedCharts.has(chart.uid) }))
          .filter(chart => chart.uid !== currentChartUid)
          .filter(chart => !selectedMyCharts || chart.created_by === userEmail)

      return [
        { contextName: 'Explore',
          charts: checkAndFilterCharts(personas),
          hasAccess: this.hasAccessToPersonLevelTab(allowedTabContexts, INSIGHTS_CONTEXT_EXPLORE)  },
        { contextName: 'Grow',
          charts: checkAndFilterCharts(mekkos),
          hasAccess: this.hasAccessToPersonLevelTab(allowedTabContexts, INSIGHTS_CONTEXT_GROW)  },
        { contextName: 'Journey',
          charts: checkAndFilterCharts(journeys),
          hasAccess: this.hasAccessToPersonLevelTab(allowedTabContexts, INSIGHTS_CONTEXT_JOURNEY)  },
        { contextName: 'Overlap',
          charts: checkAndFilterCharts(comparisons),
          hasAccess: this.hasAccessToPersonLevelTab(allowedTabContexts, INSIGHTS_CONTEXT_OVERLAP, INSIGHTS_CONTEXT_COMPARE)  }
      ]
        .filter(group => group.hasAccess && group.charts.length)
    }),
    toProperty())

  visibleCharts$ = combineLatest(
    this.groupedCharts$,
    this.selectedChartType$
  )
    .pipe(
      map(([ groups, chartType ]) => chain(groups).find({contextName: chartType})
        .get('charts', [])
        .value()))

  constructor(@Inject(INSIGHTS_CONTEXT) public insightsContext: InsightsContextType,
    public store: Store<AppState>,
    public mekkoService: MekkoService,
    public journeyService: JourneyService,
    public comparisonService: ComparisonsService,
    public dataPermissionsService: DataPermissionsService,
    public insightsService: InsightsService,
    public snackbar: MatSnackBar,
    public exploreService: ExploreService,
    private http: HttpClient) {

    this.clientContext$.pipe(untilDestroyed(this), distinctUntilChanged(isEqual)).subscribe(
      () => this.selectedCharts$.next(new Set()))

    activeContext(store).pipe(
      untilDestroyed(this),
      map(context => getContextSlugs(context))
    ).subscribe(this.clientContext$)

    this.clientContext$.pipe(untilDestroyed(this), distinctUntilChanged(isEqual)).subscribe(
      () => this.selectedCharts$.next(new Set()))
  }

  ngOnChanges(changes) {
    if (changes.selectedTab && this.selectedTab) {
      const selectedTabs = this.selectedTabs$.getValue()
      selectedTabs.set(this.selectedTab.id, this.selectedTab)
      this.selectedTabs$.next(selectedTabs)
    }
  }

  ngOnDestroy() {}

  cancel() {
    this.close.emit()
    this.selectedTabs$.next(new Map())
    this.selectedCharts$.next(new Set())
  }

  toggleCheckboxFromStream(streamVariable: string) {
    this[streamVariable].next(!this[streamVariable].getValue());
  }

  toggleChart(chart) {
    const selectedCharts = this.selectedCharts$.getValue()
    selectedCharts.has(chart.uid) ? selectedCharts.delete(chart.uid) : selectedCharts.add(chart.uid)
    this.selectedCharts$.next(selectedCharts)
  }

  toggleTab(tab) {
    const selectedTabs = this.selectedTabs$.getValue()

    if (selectedTabs.has(tab.id)) {
      selectedTabs.delete(tab.id)
    } else if (!tab.disabled) {
      selectedTabs.set(tab.id, tab)
    }

    this.selectedTabs$.next(selectedTabs)
  }

  setClientContext(entity: HierarchyEntity) {
    this.clientContext$.next(getContextSlugs(getContext(entity)))
  }

  doCopy() {
    const selectedTabs = Array.from(this.selectedTabs$.getValue().values())
      .sort((tabX, tabY) => tabX.order - tabY.order)
      .map(tab => tab.id);
    const selectedCharts = Array.from(this.selectedCharts$.getValue())
    const selectedResources = selectedCharts.map(uid => uid.split(':'))
      .map(([ type, id ]) => ({ type, id: parseInt(id) }))
    this.insightsService.copyCustomTabs(selectedTabs, selectedResources).subscribe(
      () => {
        this.snackbar.open('The selected custom tabs have been successfully copied.',
          'OK',
          {duration: 5000})
        if (!this.selectedStayOpen$.getValue()) { this.cancel() }
      },
      res => {
        const msg = get(res, 'error.error.message', 'Something went wrong while copying your tabs.')
        this.snackbar.open(msg, 'OK', {duration: 5000, panelClass: ['danger']})
      })
  }

  isNotPermittedForProduct(tab: Tab, productSlug: string): Observable<boolean> {
    return this.store.select('insights', this.insightsContext).pipe(
      select(selectCustomTabDemographics(tab.id)),
      switchMap((demos: Demographic[]) => {
        const ids = chain(demos).flatMap('buckets').map('short_id').value()
        if (ids.length === 0) { return observableOf(false) }
        return this.dataPermissionsService.getAll(ids, productSlug).pipe(
          // Check that each id has a matching permission
          map(perms => difference(ids, _map(perms, 'identifier')).length !== 0))
      }),
      tap(isDisabled => {
        const selectedTabs = this.selectedTabs$.getValue()
        selectedTabs.has(tab.id) && isDisabled && this.toggleTab(tab)
      }))
  }

  selectAllCharts() {
    this.visibleCharts$.pipe(take(1)).subscribe(visibleCharts => {
      const selectedCharts = this.selectedCharts$.getValue()
      visibleCharts.forEach(chart => selectedCharts.add(chart.uid))
      this.selectedCharts$.next(selectedCharts)
    })
  }

  deselectAllCharts() {
    this.visibleCharts$.pipe(take(1)).subscribe(visibleCharts => {
      const selectedCharts = this.selectedCharts$.getValue()
      visibleCharts.forEach(chart => selectedCharts.delete(chart.uid))
      this.selectedCharts$.next(selectedCharts)
    })
  }

  trackByUid = (index, chart) => chart.uid
  trackByContextName = (index, chartGroup) => chartGroup.contextName

  hasAccessToPersonLevelTab(allowedTabContexts, featureModuleName, adminTabName = featureModuleName) {
    let clientHasFeatureAcccess = false;
    // Check whether module have access (through client admin & region admin)
    canAccessFeature(this.store, featureModuleName)
      .pipe(take(1))
      .subscribe(hasAccess => clientHasFeatureAcccess = hasAccess);

    // Check whether module is disabled through tab admin
    return clientHasFeatureAcccess && allowedTabContexts.find(t => adminTabName.toLowerCase() == t.insights_context);
  }

  setWarningMessage(tabs) {
    const disabledTabs = tabs.filter(t => t.disabled);
    if (disabledTabs.length === tabs.length) {
      this.warningMessage =  "No tabs available to be copied. Tabs contain widgets with segment permissions that cannot be copied to the target Product."
    } else if (disabledTabs.length) {
      this.warningMessage = "Tab contains widgets with segment permissions that cannot be copied to the target Product."
    } else {
      this.warningMessage = undefined;
    }
  }
}
