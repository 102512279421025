import { flatMap } from 'lodash';
import { Mekko, SubMarket } from "app/mekko/mekko.reducer";
import { Journey, JourneySubMarket } from 'app/journey/journey.models';

export interface MarketLevelSurvey {
  name: string;
  id?: number;
  resource_id?: number;
  resource_type?: string;
  survey_questions?: SurveyQuestion[];
  visible?: boolean;
  region_slug?: string;
}

export interface SurveyQuestion {
  name: string;
  id?: number;
  survey_id: number;
  survey_responses?: SurveyResponse[];
  idx?: number;
  expanded?: boolean;
}

export interface SurveyResponse {
  name: string;
  percent: number;
  survey_question_id?: number;
}

export function getMarketLevelSurveyPath(survey: MarketLevelSurvey, parentResource, subMarkets, insightsContext: string): string {
  const subMarket = subMarkets.find(sm => sm.id === survey.resource_id);
  if (insightsContext == "grow") {
    const market = parentResource.markets.find(market => market.id === subMarket.market_id);
    return `${market.name} > ${subMarket.name}`;
  } else {
    const brand = parentResource.brands.find(brand => brand.id === subMarket.journey_brand_id);
    const stage = parentResource.stages.find(stage => stage.id === subMarket.journey_stage_id);
    return `${brand.name} > ${stage.name}`;
  }
}
