import {ClientFeatureAccess, FeatureModule} from "app/feature-access/feature-access.reducers";

export class FetchFeatureModules {
  static readonly type = 'FETCH_FEATURE_MODULES';
  readonly type = FetchFeatureModules.type;

  constructor() {}
}

export class LoadFeatureModules {
  static readonly type = 'LOAD_FEATURE_MODULES';
  readonly type = LoadFeatureModules.type;

  constructor(public features: FeatureModule[]) {}
}

export class FetchClientFeatureAccess {
  static readonly type = 'FETCH_CLIENT_FEATURE_ACCESS';
  readonly type = FetchClientFeatureAccess.type;

  constructor() {}
}

export class LoadClientFeatureAccess {
  static readonly type = 'LOAD_CLIENT_FEATURE_ACCESS';
  readonly type = LoadClientFeatureAccess.type;

  constructor(public accesses: ClientFeatureAccess[]) {}
}

export class PutClientFeatureAccess {
  static readonly type = 'POST_CLIENT_FEATURE_ACCESS';
  readonly type = PutClientFeatureAccess.type;

  constructor(public clientId: string, public featureIds: Set<string>) {}
}

export type All = FetchFeatureModules | LoadFeatureModules | FetchClientFeatureAccess | LoadClientFeatureAccess
