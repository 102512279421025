import {remove, concat, values, pick, cloneDeep} from 'lodash'
import {HierarchyContext} from "app/hierarchy/hierarchy.interface";
import {SegmentCounts, SegmentV2} from "app/audiences/discover/segment-v2.model";
import {visitTreeNodes} from "app/shared/utils/utils";
import {Transaction} from 'app/models/transaction.model';
import { DataPermissions } from 'app/data_permissions/data_permissions.model';

export interface AudienceV2 {
  id?: number;
  id_space?: string;
  identifier?: string;
  segment?: SegmentV2;
  name: string;
  description: string;
  rules: {
    include: AudienceRule;
    exclude: AudienceRule;
  };
  expiration?: string;
  created_at?: string;
  count?: SegmentCounts;
  send_to_tardiis?: string;
  fusion_complete_date?: string;
  job_status?: 'pending' | 'processing' | 'complete' | 'fail';
  used_as_seed?: boolean;
  panelist_count?: number;
  type?: string;
  activated?: object;
  transactions?: Transaction[];
  default?: boolean;
  permissions?: DataPermissions["permissions"];
  deletable?: boolean;
  delete_disallow_reasons?: string[];
  creation_source?: string;
  estimated_count?: number;
}

export interface AudienceRule {
  and?: AudienceRule[] | string[];
  or?: AudienceRule[] | string[];
}

export function newAudience(): AudienceV2 {
  return {
    name: '',
    description: '',
    rules: {
      include: {and: []},
      exclude: {and: []}
    },
    type: "audience"
  }
}

export function addRule(parentRule: AudienceRule, rule: AudienceRule = {or: []}): AudienceRule {
  (getRuleChildren(parentRule) as AudienceRule[]).push(rule);
  return parentRule
}

export function removeEmptyRules(audience: AudienceV2): AudienceV2 {
  const removeEmpty = (rule: AudienceRule | string) => {
    remove<AudienceRule| string>(getRuleChildren(rule),
      rule => typeof rule !== 'string' && getRuleChildren(rule).length === 0)
  };

  visitTreeNodes(audience.rules.include, removeEmpty, getRuleChildren);
  visitTreeNodes(audience.rules.exclude, removeEmpty, getRuleChildren);
  return audience
}

export function removeSegment(audience: AudienceV2, identifier: string): AudienceV2 {
  const removeIdentifier = (rule: AudienceRule | string) => {
    remove<AudienceRule | string>(getRuleChildren(rule), child => child === identifier)
  };

  visitTreeNodes(audience.rules.include, removeIdentifier, getRuleChildren)
  visitTreeNodes(audience.rules.exclude, removeIdentifier, getRuleChildren)
  return audience
}

export function getAllSegmentIdentifiers(audience: AudienceV2): string[] {
  return concat(audience.identifier, getRuleSegmentIdentifiers(audience)).filter(Boolean)
}

export function getRuleSegmentIdentifiers(audience: AudienceV2): string[] {
  return concat(segmentIdentifiers(audience.rules.include),
    segmentIdentifiers(audience.rules.exclude));
}

export function segmentIdentifiers(rule: AudienceRule): string[] {
  const identifiers = [];
  const addIfString = (rule: AudienceRule | string) => typeof rule === 'string' && identifiers.push(rule)
  visitTreeNodes(rule, addIfString, getRuleChildren)
  return identifiers;
}

export function getNameSuffix(audience: AudienceV2): string {
  const sep = audience.name.split('_');
  if (sep.length >= 5) {
    return sep.slice(5).join('_');
  } else {
    return audience.name;
  }
}

export function getNamePrefix(audience: AudienceV2): string {
  const sep = audience.name.split('_');
  if (sep.length >= 5) {
    return sep.slice(0, 5).join('_');
  } else {
    return '';
  }
}

export function setNamePrefix(audience: AudienceV2, prefix: string): AudienceV2 {
  audience.name = `${prefix}_${getNameSuffix(audience)}`
  return audience;
}

export function setNameSuffix(audience: AudienceV2, suffix: string): AudienceV2 {
  audience.name = `${getNamePrefix(audience)}_${suffix}`
  return audience
}

export function buildAudiencePrefix(hierarchy: HierarchyContext) {
  const {client, region, brand, product} = hierarchy;
  const stripped = `${client.name}<~>${region.slug}<~>${brand.name}<~>${product.name}`.replace(/_/g, '').replace(/<~>/g, "_");
  return `PPC_${stripped}`.toUpperCase().replace(/\s/g, "");
}

export function validateAudience(audience: AudienceV2) {
  return {
    name: getNameSuffix(audience) == '' ? "Name cannot be blank" :
      audience.name.length > 128    ? "Name cannot be longer than 128 characters"
        : void (0),
    includeGroups: segmentIdentifiers(audience.rules.include).length === 0 && "Must include at least one segment" || void (0),
    groupCount: (groupCount(audience.rules.include) + groupCount(audience.rules.exclude)) > 100
                && "No more than one hundred groups are allowed" || void (0)  // Increased for Samsung PoC Jun 2018
  };
}

export function isAudienceValid(audience: AudienceV2): boolean {
  return values(validateAudience(audience)).every(v => !v)
}

export function getRuleChildren(rule: AudienceRule | string): AudienceRule[] | string[] {
  if (typeof rule === 'string') {return []}
  return rule.and || rule.or
}

export function groupCount(rule: AudienceRule): number {
  let count = -1; // don't count the root
  visitTreeNodes<AudienceRule | string>(rule, rule => typeof rule !== 'string' && count++, getRuleChildren)
  return Math.max(count, 0);
}

export function cloneAudience(audience: AudienceV2): AudienceV2 {
  const clone = cloneDeep(pick(audience, ['name', 'description', 'rules']))
  clone['name'] += "_copy";
  return clone;
}

export function hasGlobalCounts(audience: AudienceV2): boolean {
  try {
    return audience.count.people.global > 0;
  } catch (e) {
    return false;
  }
}

export function hasTvPanelists(audience: AudienceV2): boolean {
  return !!audience.panelist_count;
}

export function isAudienceCloneableOnly(audience: AudienceV2): boolean {
  return audience.job_status === 'complete' || audience.job_status === 'processing';
}
