import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { select, Store } from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { flatMap } from 'lodash';
import { AppState } from 'app/reducers';
import { UploadAdminService } from "app/admin/upload-admin/upload-admin.service";
import { selectRegion } from "app/hierarchy/hierarchy.reducers";
import { FetchCurrentUser } from 'app/users/user.actions';

@UntilDestroy()
@Component({
  selector: 'ppc-personas-upload-admin',
  templateUrl: './personas-upload-admin.component.html',
  styleUrls: ['./personas-upload-admin.component.sass']
})

export class PersonasUploadAdminComponent implements OnDestroy {
  public updatedWidgets = [];
  public errors = [];
  fileName = '';
  errorListCollapsed = false;
  updatesListCollapsed = false;

  public rules = [
    "The template will only affect your current client and region.",
    "The template will only affect your custom Market Level widgets under the 'All Data' persona.",
    "Each row must contain a value in columns A-G, except for E.",
    "To add multiple attributes to a demographic category, add a line for each attribute value with matching Brand, Product, Tab Name, and Demographics Category.",
    "A list of all updated Tabs and Widgets will appear after uploading.",
    "If any errors are found, they will appear below the upload section with a corresponding row and column (if applicable). Errors do not stop the upload process (provided file has valid brand(s), product(s) and format), but the field or row in error will not be updated.",
    "All available Brands and Products will appear in columns to the right of your downloaded template.",
    "Position column is numerical. Attributes are in order from top to bottom starting with 1.",
  ]

  constructor(
    public uploadAdminService: UploadAdminService,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    this.store.select('hierarchy').pipe(
      select(selectRegion), filter(Boolean), untilDestroyed(this)
    ).subscribe(region => this.clearUploadMessages());

    this.store.dispatch(new FetchCurrentUser({ping: true}));
  }

  ngOnDestroy() {}

  downloadTemplate() {
    this.uploadAdminService.downloadPersonaTemplate().subscribe();
  }

  uploadTemplate(event, form: HTMLFormElement) {
    if (!event.target.files[0]) {return; }
    this.fileName = event.target.files[0].name;
    this.clearUploadMessages();
    this.uploadAdminService.loading(true);
    this.uploadAdminService.uploadPersonaTemplate(new FormData(form)).subscribe(
      response => this.handleSuccessResponse(flatMap(response.resources, "widgets"), response.errors),
      error => this.snackbar.open(error, null, { duration: 6000, panelClass: ['danger'] })
    );

    event.target.value = '';
  }

  handleSuccessResponse(response, errors) {
    this.updatedWidgets = response;
    this.errors = errors;
    this.snackbar.open("Upload Complete", 'ok', { duration: 4000, panelClass: ['check'] });
  }

  getSanitizedValue(val): SafeStyle {
    return this.sanitizer.bypassSecurityTrustHtml(val);
  }

  clearUploadMessages() {
    if (this.updatedWidgets.length) {this.updatedWidgets = []; }
    if (this.errors.length) {this.errors = []; }
  }
}
