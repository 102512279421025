<main>
  <mat-expansion-panel (opened)="pendingAudiencePanelOpenState = true"
                       (closed)="pendingAudiencePanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>Pending Audiences ({{audiences ? audiences.length : 0}})</mat-panel-title>
    </mat-expansion-panel-header>
    <ngx-datatable
      #dataTable
      class="data-table"
      [columnMode]="'flex'"
      [rows]="audiences"
      [headerHeight]="60"
      [rowHeight]="50"
      [reorderable]="false"
      [cssClasses]="{sortAscending: 'fa fa-sort-asc', sortDescending: 'fa fa-sort-desc'}"
      [scrollbarH]="true"
    >
      <ngx-datatable-column
        name="Object Name"
        prop="name"
        [flexGrow]="5"
        cellClass="name-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{value}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Job Status"
        prop="job_status"
        [flexGrow]="0.5"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span [class]="adminJobStatus(row)"></span>{{jobStatusDisplay(value)}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Date Created"
        prop="created_at"
        [flexGrow]="1"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{value | date: 'shortDate'}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Client Name"
        prop="client"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template  let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Region Name"
        prop="region"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Brand Name"
        prop="brand"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Product Name"
        prop="product"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Pending Since"
        prop="pending_since"
        [flexGrow]="2"
        headerClass="middle-column-header"
        cellClass="time-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </mat-expansion-panel>
  <br>

  <mat-expansion-panel (opened)="pendingLookalikePanelOpenState = true"
                       (closed)="pendingLookalikePanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>Pending Lookalikes ({{lookalikes ? lookalikes.length : 0}})</mat-panel-title>
    </mat-expansion-panel-header>
    <ngx-datatable
      #dataTable
      class="data-table"
      [columnMode]="'flex'"
      [rows]="lookalikes"
      [headerHeight]="60"
      [rowHeight]="50"
      [reorderable]="false"
      [cssClasses]="{sortAscending: 'fa fa-sort-asc', sortDescending: 'fa fa-sort-desc'}"
      [scrollbarH]="true"
    >
      <ngx-datatable-column
        name="Object Name"
        prop="name"
        [flexGrow]="5"
        cellClass="name-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{value}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Job Status"
        prop="job_status"
        [flexGrow]="0.5"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span [class]="adminJobStatus(row)"></span>{{jobStatusDisplay(value)}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Date Created"
        prop="created_at"
        [flexGrow]="1"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{value | date: 'shortDate'}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Client Name"
        prop="client"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template  let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Region Name"
        prop="region"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Brand Name"
        prop="brand"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Product Name"
        prop="product"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Pending Since"
        prop="pending_since"
        [flexGrow]="2"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </mat-expansion-panel>
  <br>

  <mat-expansion-panel (opened)="pendingActivationPanelOpenState = true"
                       (closed)="pendingActivationPanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>Pending Activations ({{activations ? activations.length : 0}})</mat-panel-title>
    </mat-expansion-panel-header>
    <ngx-datatable
    #dataTable
    class="data-table"
    [columnMode]="'flex'"
    [rows]="activations"
    [headerHeight]="60"
    [rowHeight]="50"
    [reorderable]="false"
    [cssClasses]="{sortAscending: 'fa fa-sort-asc', sortDescending: 'fa fa-sort-desc'}"
    [scrollbarH]="true"
  >

    <ngx-datatable-column
      name="Client Name"
      prop="client"
      [flexGrow]="0.4"
      headerClass="middle-column-header"
      cellClass="middle-column-cell"
      [resizeable]="true"
    >
      <ng-template  let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Partner Name"
      prop="partner_name"
      [flexGrow]="1"
      headerClass="middle-column-header"
      cellClass="middle-column-cell"
      [resizeable]="true"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{value}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Destination Name"
      prop="destination_names"
      [flexGrow]="3.5"
      headerClass="middle-column-header"
      cellClass="middle-column-cell"
      [resizeable]="true"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{value}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Identifier"
      prop="identifier"
      [flexGrow]="1"
      headerClass="middle-column-header"
      cellClass="middle-column-cell"
      [resizeable]="true"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{value}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Status"
      prop="status"
      [flexGrow]="0.5"
      headerClass="middle-column-header"
      cellClass="middle-column-cell"
      [resizeable]="true"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <span [class]="adminJobStatus(row)"></span>{{jobStatusDisplay(value)}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Email"
      prop="email"
      [flexGrow]="1"
      headerClass="middle-column-header"
      cellClass="middle-column-cell"
      [resizeable]="true"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{value}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Pending Since"
      prop="pending_since"
      [flexGrow]="2"
      headerClass="middle-column-header"
      cellClass="middle-column-cell"
      [resizeable]="true"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ value }}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
  </mat-expansion-panel>
  <br>

  <mat-expansion-panel (opened)="pendingFusionAudiencePanelOpenState = true"
                       (closed)="pendingFusionAudiencePanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>Pending Audiences for Fusion ({{concatUnfusedAuds ? concatUnfusedAuds.length : 0}})</mat-panel-title>
    </mat-expansion-panel-header>
    <ngx-datatable
      #dataTable
      class="data-table"
      [columnMode]="'flex'"
      [rows]="concatUnfusedAuds"
      [headerHeight]="60"
      [rowHeight]="50"
      [reorderable]="false"
      [cssClasses]="{sortAscending: 'fa fa-sort-asc', sortDescending: 'fa fa-sort-desc'}"
      [scrollbarH]="true"
    >
      <ngx-datatable-column
        name="Type"
        prop="type"
        [flexGrow]="0.5"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{value}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Object Name"
        prop="name"
        [flexGrow]="5"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{value}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Identifier"
        prop="identifier"
        [flexGrow]="0.5"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{value}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Client Name"
        prop="client"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template  let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Region Name"
        prop="region"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Brand Name"
        prop="brand"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Product Name"
        prop="product"
        [flexGrow]="0.4"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Pending Since"
        prop="time_since_fused"
        [flexGrow]="2"
        headerClass="middle-column-header"
        cellClass="middle-column-cell"
        [resizeable]="true"
      >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </mat-expansion-panel>
  <br>

  <mat-expansion-panel (opened)="propensityPanelOpenState = true"
                       (closed)="propensityPanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>Propensity instances ({{snapshots ? snapshots.length : 0}})</mat-panel-title>
    </mat-expansion-panel-header>
    <table class=""divTable>
      <thead>
      <tr class="header-row">
        <th (click)="sortTable('id')">Id</th>
        <th (click)="sortTable('product_slug')">Product Slug</th>
        <th (click)="sortTable('mekko_name')">Grow Chart</th>
        <th (click)="sortTable('name')">Name</th>
        <th (click)="sortTable('request_id')">Request ID</th>
        <th (click)="sortTable('created_by')">Created By</th>
        <th (click)="sortTable('created_at')">Created At</th>
        <th (click)="sortTable('pending_since')">Pending Since</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let snap of snapshots">
        <td class="idClass">{{snap.id}}</td>
        <td>{{snap.product_slug}}</td>
        <td>
          <a routerLink="{{snap.product_slug}}/insights/grow/{{snap.mekko_id}}">{{snap.mekko_name}}</a>
        </td>
        <td>{{snap.name}}</td>
        <td>{{snap.request_id}}</td>
        <td>{{getUserName(snap.created_by)}}</td>
        <td class="dateClass">{{snap.created_at}}</td>
        <td>{{snap.pending_since}}</td>
      </tr>
      </tbody>
    </table>
  </mat-expansion-panel>
  <br>

  <mat-expansion-panel (opened)="indexReportPanelOpenState = true"
                       (closed)="indexReportPanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="divHeader">
          <div class="onLeft">Index Reports ({{tasks ? tasks.length : 0}})</div>
          <div class="onRight">{{statusText}}</div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table class="divTable">
      <thead>
      <tr class="header-row">
        <th (click)="sortTable('id')">Id</th>
        <th (click)="sortTable('product_slug')">Product Slug</th>
        <th (click)="sortTable('resource_type')">Resource Type</th>
        <th (click)="sortTable('resource_id')">Resource Id</th>
        <th (click)="sortTable('name')">Name</th>
        <th (click)="sortTable('request_id')">Request ID</th>
        <th (click)="sortTable('status')">Status</th>
        <th (click)="sortTable('created_by')">Created By</th>
        <th (click)="sortTable('created_at')">Created At</th>
        <th (click)="sortTable('pending_since')">Pending Since</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let task of tasks">
        <td class="idClass">{{task.id}}</td>
        <td>{{task.product_slug}}</td>
        <td>{{task.resource_type}}</td>
        <td>{{task.resource_id}}</td>
        <td>{{task.name}}</td>
        <td>{{task.request_id}}</td>
        <td>{{task.status}}</td>
        <td>{{task.created_by}}</td>
        <td class="dateClass">{{task.created_at}}</td>
        <td>{{task.pending_since}}</td>
      </tr>
      </tbody>
    </table>
  </mat-expansion-panel>
<br>
</main>
