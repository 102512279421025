import { Category } from './../category.interface';

export class CustomCategory implements Category {
  id: number = null;
  name: string = '';
  priority: number = null;
  description: string = '';
  slug: string = '';
  client_id: number;

  constructor(
    name: string,
    clientId: number,
    nextPriority: number
  ) {
    this.name = name;
    this.client_id = clientId;
    this.priority = nextPriority;
  }

}
