import {map, tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AppState} from "app/reducers/";
import {Store} from "@ngrx/store";
import {HttpClient} from "@angular/common/http";
import {apiUrl, PpcHttpService} from "app/services/ppc_http.service";
import {FetchCurrentUser} from "app/users/user.actions";
import {setAuthToken} from "app/shared/utils/auth-utils";
import { directLoginUrl } from 'app/shared/constants/auth.urls';

@Injectable()
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private ppcHttp: PpcHttpService,
    private store: Store<AppState>
  ) { }

  login(email: string, password: string): Observable<boolean> {
    return this.http.post(directLoginUrl(), {email, password}, {observe: 'response'}).pipe(
      tap(res => setAuthToken(res.headers.get('auth-token'))),
      tap(() => this.store.dispatch(new FetchCurrentUser())),
      map(() => true)
    )
  }

  getSsoToken(): Observable<string> {
    return this.http.get(apiUrl('/sso-token'), {observe: 'response', responseType: 'text'}).map(res => {
      return res.headers.get('sso-token')
    })
  }

}
