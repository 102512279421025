import { Component, OnInit, Input, Output, ElementRef, Renderer2, forwardRef, HostListener, ViewChild, ContentChild, TemplateRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, remove, map, find } from 'lodash';

import { PpcInputComponent } from 'app/shared/components/ppc-input/ppc-input.component';

@Component({
  selector: 'ppc-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchableDropdownComponent),
      multi: true
    }
  ]
})
export class SearchableDropdownComponent implements OnInit, ControlValueAccessor {
  @ViewChild("search", { static: true }) searchElement: PpcInputComponent;
  @ViewChild("input") inputElement: PpcInputComponent;
  @ContentChild('buttonTemplate') buttonTemplate: TemplateRef<ElementRef>;
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<ElementRef>;
  @Input() label: string;
  @Input() multiSelect: boolean;
  @Input() items: any[];
  @Input() disabled: boolean = false;
  @Input() itemLabelProp: string = "label";
  @Input() alwaysFloatLabel: boolean = true;
  @Input() showInputUnderline: boolean = false;
  @Input() mapFromProperty: string;
  @Input() allowClear: boolean = true;
  @Input() isItemDisabled: (any) => boolean = () => false;
  @Input() showSearch: boolean = true;
  @Input() noLabel;
  @Input() iconPrefix: string;
  open: boolean = false;
  searchInput: string = "";
  private _value: any;

  constructor(private renderer: Renderer2, private elem: ElementRef) { }

  ngOnInit() {
    this.renderer.listen(window, "click", event => {
      if (!this.elem.nativeElement.contains(event.target)) {
        this.open = false;
      }
    })
    this.renderer.listen(window, "focusin", event => {
      if (!this.elem.nativeElement.contains(event.target)) {
        this.open = false;
      }
    })
  }

  clear() {
    this.writeValue(null);
  }

  getFocusedItem(): ElementRef {
    const focusedElement = this.elem.nativeElement.querySelector(".ppc-searchable-dropdown-items :focus");
    if (!focusedElement) {
      return null;
    }
    return new ElementRef(focusedElement);
  }

  navigate(forward: boolean) {
    const focusedElement = this.getFocusedItem();
    if (focusedElement) {
      let children;
      if (forward) {
        children = focusedElement.nativeElement.parentElement.nextSibling.children;
      } else {
        children = focusedElement.nativeElement.parentElement.previousSibling.children;
      }
      if (children) {
        children[0].focus();
      } else {
        this.searchElement.focus();
      }
    } else {
      this.elem.nativeElement
        .querySelector(`.ppc-searchable-dropdown-items li:${forward ? 'first' : 'last'}-child`)
        .children[0].focus({preventScroll: true});
      const list = this.elem.nativeElement.querySelector(".ppc-searchable-dropdown-items")
      setTimeout(() => {
        list.scrollTop = forward ? 0 : list.scrollHeight - list.clientHeight;
      }, 70)
    }
  }

  @HostListener("window:keydown", ["$event"])
  onKeypress(event: any) {
    if (this.open) {
      switch (event.key) {
        case "ArrowDown":
          return this.navigate(true);
        case "ArrowUp":
          return this.navigate(false);
        default:
          return
      }
    }
  }

  get selectedItemsLabel() {
    if (this.multiSelect) {
      return map(this.selectedItems, item => item[this.itemLabelProp]).join(", ")
    } else {
      return this.selectedItem ? this.selectedItem[this.itemLabelProp] : "";
    }
  }

  get filteredItems() {
    return filter(this.items, (item) => {
      return item[this.itemLabelProp].toLowerCase().includes(this.searchInput.toLowerCase());
    })
  }

  isItemSelected(item) {
    if (this.mapFromProperty) {
      return find(this.selectedItems, {[this.mapFromProperty]: item[this.mapFromProperty]})
    }
    return this.selectedItems.includes(item);
  }

  toggleItem(item) {
    if (this.multiSelect) {
      if (this.isItemSelected(item)) {
        remove(this.selectedItems, i => i === item);
      } else {
        this.selectedItems.push(item);
      }
      this.writeValue(this.selectedItems);
    } else {
      if (this.mapFromProperty) {
        this.writeValue(item[this.mapFromProperty]);
      } else {
        this.writeValue(item);
      }
      this.open = false;
    }
  }

  get selectedItem() {
    if (this.mapFromProperty) {
      return find(this.items, item => item[this.mapFromProperty] == this._value);
    } else {
      return this._value;
    }
  }

  get selectedItems() {
    if (this.mapFromProperty) {
      return map(this._value, property => find(this.items, item => item[this.mapFromProperty] == this._value));
    } else {
      return this._value;
    }
  }

  // CONTROL VALUE ACCESSOR IMPLEMENTATION

  // Function to call when the value changes.
  onChange = (value: any) => {};

  // Function to call when the input is touched.
  onTouched = () => {};

  // Allows Angular to update the model.
  // Update the model and changes needed for the view here.
  writeValue(value: any): void {
    if (this.multiSelect) {
      this._value = value || [];
      this.onChange(this.selectedItems);
    } else {
      this._value = value;
      this.onChange(this._value);
    }
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input. Not currently used to this component but necessary to implement ControlValueAccessor
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // END CONTROL VALUE ACCESSOR IMPLEMENTATION

}
